import { createTheme, adaptV4Theme } from "@mui/material/styles";

//TODO: add these to the theme palette instead
const colors = {
    orange: "#FB8457",
    green: "#2EC674",
    blue: "#2E8EFF",
    red: "#EF6565",
    purple: "#5451FF",
    yellow: "#FEC700",
    lightgray: "#F5F6F8",
    black: "#1F1F1F",
    white: "#FFFFFF",
};

const theme = createTheme(adaptV4Theme({
    palette: {
        primary: { main: colors.blue },
        secondary: { main: colors.orange },
        orange: { main: colors.orange },
        green: { main: colors.green },
        blue: { main: colors.blue },
        red: { main: colors.red },
        purple: { main: colors.purple },
        yellow: { main: colors.yellow },
        lightgray: { main: colors.lightgray },
        black: { main: colors.black },
        white: { main: colors.white },
        error: { main: colors.red },
        success: { main: colors.green },
        warning: { main: colors.yellow },
        hasChanges: { main: "#FFFFE0" },
        background: {
            default: colors.white,
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 4,
    },
    typography: {
        fontFamily: "Inter",
        h1: {
            fontFamily: "Inter Bold",
            fontSize: 24,
            color: colors.black,
        },
        h2: {
            fontSize: 16,
            fontWeight: "bold",
            color: colors.black,
        },
        subtitle1: {
            fontSize: 20,
            color: colors.black,
        },
        body1: {
            color: colors.black,
            fontSize: 16,
        },
        body2: {
            color: colors.black,
            fontSize: 12,
        },
    },
    overrides: {
        MuiButton: {
            root: {
                padding: 0,
                height: 32,
                fontSize: 14,
                textTransform: "none",
            },
            contained: {
                boxShadow: "none",
            },
            outlined: {
                border: "1px solid #E4E4E7",
            },
        },
    },
}));

export default theme;
