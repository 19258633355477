import {
    ButtonBase,
    Collapse,
    Divider,
    Fade,
    Grid,
    Grow,
    IconButton,
    Paper,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, {
    FunctionComponent,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { BeatLoader } from "react-spinners";
import { Action } from "../../../../../models/Actions/Action";
import { EvalReviewActionMetadata } from "../../../../../models/Actions/Evaluation/EvalReviewActionMetadata";
import { AsyncTaskStatus } from "../../../../../stores/BaseStore";
import theme from "../../../../../Theme/AppTheme";
import { useStore } from "../../../../../utils/useStore";
import AcxMainTextField from "../../../../UI/AcxMainTextFieldGrid";
import { EvalStore } from "../../../Stores/EvalStore";
import { RenderActionResponses } from "./Internal/RecursiveThreadRendering";
import RequestDetails from "./RequestDetails";

const useStyles = makeStyles((theme: Theme) => ({
    collapsible: {
        transitionDelay: "350ms",
    },
    paperRoot: {
        paddingBottom: theme.spacing(1),
    },
    root: {
        position: "relative",
        width: "100%",
        height: "auto",
        borderRadius: "8px",
        marginBottom: theme.spacing(3.5),
    },
    addResponseButton: {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightBold as any,
        fontSize: "13px",
        height: "30px",
    },
    addIcon: {
        marginRight: theme.spacing(1),
        fontSize: "14px",
    },
    section: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        marginBottom: "16px",
    },
    padding3: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    mainContent: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    addResponseActions: {
        paddingLeft: theme.spacing(7),
        paddingBottom: theme.spacing(1),
        justifyContent: "flex-start",
    },
    responseDetailsSection: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        paddingLeft: theme.spacing(5.5),
    },
    smallButtons: {
        maxHeight: "36px",
    },

    disablesSend: {
        color: theme.palette.text.disabled,
    },
    enablesSend: {
        color: theme.palette.secondary.main,
    },
    sendButton: {
        fontWeight: "bold",
        textTransform: "none",
        fontSize: "13px",
    },
    sendIcon: {
        marginRight: theme.spacing(2),
        fontSize: "14px",
    },
    noWrapText: {
        whiteSpace: "nowrap",
    },
    dismissButton: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(0.5),
    },
}));
interface OwnProps {
    threads?: Action<EvalReviewActionMetadata>[];
    userId?: string;
    enableComments: boolean;
    navigateBack: () => void;
}

type Props = OwnProps;

const ReviewRequestDetailsCard: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const store = useStore(EvalStore);
    const classes = useStyles();

    useLayoutEffect(() => {
        if (!props.threads?.length) {
            store.dismissActionThread();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.threads, store]);

    const threadList = useMemo(() => {
        return props.threads?.map((value, index) => {
            return (
                <React.Fragment key={`${value.id}${index}`}>
                    <RenderThreads
                        thread={value}
                        userId={props.userId}
                        disableResponse={false}
                        enableComments={props.enableComments}
                        store={store}
                        navigateBack={props.navigateBack}
                    />
                    <div style={{ marginBottom: "21px" }} />
                </React.Fragment>
            );
        });
    }, [
        props.enableComments,
        props.navigateBack,
        props.threads,
        props.userId,
        store,
    ]);

    return (
        <Paper className={clsx(classes.root, classes.paperRoot)} elevation={4}>
            <IconButton
                className={classes.dismissButton}
                onClick={store.dismissActionThread}
                size="large">
                <CloseIcon />
            </IconButton>

            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
            >
                <Grid
                    item
                    xs={12}
                    className={classes.section}
                    container
                    wrap={"nowrap"}
                >
                    <Grid item xs>
                        <Typography variant={"h1"}>Review Actions</Typography>
                        <BeatLoader
                            size={9}
                            color={theme.palette.secondary.main}
                            loading={
                                store.getTaskLoading(
                                    "Loading Latest Action Thread",
                                ) ||
                                store.getTaskLoading(
                                    "Loading Action Thread History",
                                )
                            }
                        />
                    </Grid>
                </Grid>
                {threadList}
            </Grid>
        </Paper>
    );
});

const RenderThreads = observer(
    (
        props: Omit<OwnProps, "threads" | "show"> & {
            children?: React.ReactNode;
            store: EvalStore;
            navigateBack: () => void;
            thread: Action<EvalReviewActionMetadata>;
            disableResponse: boolean;
        },
    ) => {
        const classes = useStyles();
        const [addingResponse, setAddingResponse] = useState(false);
        const [responseNotes, setResponseNotes] = useState<string>("");

        const sortedResponses = useMemo(
            () =>
                props.thread.actionResponses?.sort((a, b) =>
                    (a.createdOn ?? "") < (b.createdOn ?? "") ? -1 : 1,
                ),
            [props.thread.actionResponses],
        );

        async function onSubmitResponse() {
            if (!props.store.currentEval) {
                return;
            }

            const currentEval = props.store.currentEval;

            props.store.clearTaskErrors();
            props.store.saveEvalDialogStore.setErrorMessage("");

            props.store.saveEvalDialogStore.setTitle(
                `Completing Evaluation Review`,
            );
            props.store.saveEvalDialogStore.setLoading();
            props.store.saveEvalDialogStore.open();

            props.store.saveEvalDialogStore.setSubTitle(
                `Updating Evaluation ...`,
            );

            const updateEvalTask: boolean = await props.store.updateEval(
                currentEval.evaluationStatus,
                { pendingAction: true },
            );

            if (!updateEvalTask) {
                props.store.saveEvalDialogStore.setNotLoading();
                props.store.saveEvalDialogStore.close();
            } else {
                props.store.saveEvalDialogStore.setLoading();
                props.store.saveEvalDialogStore.setSubTitle(
                    `Generating Request Response ...`,
                );
                const requestReviewTask =
                    await props.store.generateCompleteReviewAction(
                        props.thread.id,
                        responseNotes,
                        props.thread.actionMetadata.parsedMetadata.reason,
                    );

                if (requestReviewTask === AsyncTaskStatus.Error) {
                    props.store.saveEvalDialogStore.setNotLoading();
                    props.store.saveEvalDialogStore.close();
                } else {
                    props.store.saveEvalDialogStore.close();
                    if (currentEval.isInReviewState) {
                        props.navigateBack();
                    }
                }
            }
        }

        function canRespond() {
            return (
                props.userId &&
                props.thread.isUserRecipient(props.userId) &&
                !props.disableResponse
            );
        }

        function onResponseNotesChange(
            evt: React.ChangeEvent<HTMLInputElement>,
        ) {
            setResponseNotes(evt.target.value);
        }

        return (
            <>
                <div className={classes.mainContent}>
                    <RequestDetails request={props.thread} />

                    {!!(sortedResponses?.length ?? 0) && (
                        <Divider variant={"fullWidth"} />
                    )}

                    <RenderActionResponses
                        enableComments={props.enableComments}
                        responses={sortedResponses}
                        level={1}
                        parentId={props.thread.id}
                        totalResponses={sortedResponses?.length ?? 0}
                    />

                    {addingResponse && <Divider variant={"fullWidth"} />}

                    <Collapse
                        in={addingResponse}
                        timeout={500}
                        style={{ transitionDelay: "210ms" }}
                    >
                        <Grid
                            item
                            xs={12}
                            className={classes.responseDetailsSection}
                            justifyContent={"flex-start"}
                            container
                            wrap={"nowrap"}
                        >
                            <Grid item lg={6} md={8} xs={11}>
                                <AcxMainTextField
                                    onChange={onResponseNotesChange}
                                    labelText={`Reply to ${props.thread.user.userName}`}
                                    multiLine
                                    rows={2}
                                    rowsMax={3}
                                    placeholderText={""}
                                    id="response-notes-action-id"
                                    value={responseNotes ?? ""}
                                />

                                <Grid
                                    container
                                    justifyContent={"flex-end"}
                                    style={{ marginTop: "8px" }}
                                >
                                    <Grid item>
                                        <ButtonBase
                                            className={clsx(
                                                classes.noWrapText,
                                                classes.smallButtons,
                                                classes.sendButton,
                                                Boolean(responseNotes) &&
                                                    classes.enablesSend,
                                                !Boolean(responseNotes) &&
                                                    classes.disablesSend,
                                            )}
                                            disabled={!responseNotes}
                                            onClick={onSubmitResponse}
                                        >
                                            <Grow
                                                in={!!responseNotes}
                                                timeout={400}
                                            >
                                                <SendIcon
                                                    className={classes.sendIcon}
                                                />
                                            </Grow>{" "}
                                            Submit Response
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Collapse>
                </div>

                {canRespond() && (
                    <Fade in={Boolean(!addingResponse)} appear timeout={200}>
                        <div className={clsx(classes.addResponseActions)}>
                            <ButtonBase
                                className={clsx(classes.addResponseButton)}
                                onClick={() => setAddingResponse(true)}
                            >
                                <AddCircleOutlineIcon
                                    className={classes.addIcon}
                                    fontSize={"small"}
                                />{" "}
                                Add Response
                            </ButtonBase>
                        </div>
                    </Fade>
                )}
            </>
        );
    },
);

export default ReviewRequestDetailsCard;
