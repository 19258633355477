import React from "react";
import { Route, Routes } from "react-router";
import TopicDetail from "../TopicDetail/TopicDetail";
import IdentifiedTopicsList from "./IdentifiedTopicsList";
type Props = {
    id: string;
};

const IdentifiedTopicsWrapper: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ id }) => {
    return (
        <Routes>
            <Route path={"/"} element={<IdentifiedTopicsList orgId={id} />} />
            <Route path={`:topicId`} element={<TopicDetail orgId={id} />} />
        </Routes>
    );
};

export default IdentifiedTopicsWrapper;
