import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem as MUITimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@mui/lab";
import MUITimeline from "@mui/lab/Timeline";
import React, { useRef } from "react";

export interface TimelineItem {
    time: string;
    icon: React.ReactNode;
    content: string;
    contentHeader?: string;
    variant?: "default" | "outlined";
    color?: React.CSSProperties["color"];
}

interface TimelineProps {
    items: TimelineItem[];
    header?: React.ReactNode;
}

export default function Timeline(props: TimelineProps) {
    const timelineBottomTarget = useRef<HTMLDivElement>(null);
    return (
        <Grid>
            {props.items.length > 0 && (
                <Typography
                    variant="h5"
                    style={{
                        textAlign: "center",
                    }}
                >
                    {props.header ?? "Timeline"}
                    <IconButton
                        onClick={() =>
                            timelineBottomTarget.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                            })
                        }
                        size="large"
                    >
                        <ArrowDownward />
                    </IconButton>
                </Typography>
            )}
            <MUITimeline>
                {props.items.map(
                    (
                        {
                            time,
                            icon,
                            content,
                            contentHeader,
                            variant = "filled",
                            color = "gray",
                        },
                        idx,
                    ) => {
                        const isFirstItem = idx === 0;
                        const isLastItem = idx === props.items.length - 1;
                        const dotStyles =
                            variant === "outlined"
                                ? { color, borderColor: color }
                                : { background: color };

                        return (
                            <MUITimelineItem key={idx}>
                                <TimelineOppositeContent
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Typography align="right" variant="body2">
                                        {time}
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    {!isFirstItem && (
                                        <TimelineConnector
                                            style={{ background: color }}
                                        />
                                    )}
                                    <TimelineDot
                                        // variant={variant}
                                        style={dotStyles}
                                    >
                                        {icon}
                                    </TimelineDot>
                                    {!isLastItem && (
                                        <TimelineConnector
                                            style={{ background: color }}
                                        />
                                    )}
                                </TimelineSeparator>
                                <TimelineContent
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    {contentHeader && (
                                        <Typography
                                            variant="h6"
                                            component="span"
                                        >
                                            {contentHeader}
                                        </Typography>
                                    )}
                                    <Typography>{content}</Typography>
                                </TimelineContent>
                            </MUITimelineItem>
                        );
                    },
                )}
            </MUITimeline>
            <div ref={timelineBottomTarget}></div>
        </Grid>
    );
}
