import { makeObservable, observable } from "mobx";
import { copyBaseFields } from "../../utils/BaseEntityUtils";
import BaseEntity from "../BaseEntity";

export enum ReportAccessType {
    View = 1,
    Edit = 2,
}
export class ReportRolesAccessControl extends BaseEntity {
    reportId: string;
    @observable roleId: string;
    @observable accessType: ReportAccessType;

    constructor(
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(id, createdBy, modifiedBy);

        makeObservable(this);
    }

    static fromJson(json: ReportRolesAccessControl) {
        const cls = new ReportRolesAccessControl(
            json.id,
            json.createdBy,
            json.modifiedBy,
        );
        copyBaseFields(json, cls);
        cls.reportId = json.reportId;
        cls.roleId = json.roleId;
        cls.accessType = json.accessType;
        return cls;
    }
}
export class ReportUserAccessControl extends BaseEntity {
    reportId: string;
    @observable userId: string;
    @observable accessType: ReportAccessType;

    constructor(
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(id, createdBy, modifiedBy);

        makeObservable(this);
    }

    static fromJson(json: ReportUserAccessControl) {
        const cls = new ReportUserAccessControl(
            json.id,
            json.createdBy,
            json.modifiedBy,
        );
        copyBaseFields(json, cls);
        cls.reportId = json.reportId;
        cls.userId = json.userId;
        cls.accessType = json.accessType;
        return cls;
    }
}

export class ReportHierarchyAccessControl extends BaseEntity {
    reportId: string;
    @observable organizationStructureMemberId: string;
    @observable accessType: ReportAccessType;

    constructor(
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(id, createdBy, modifiedBy);

        makeObservable(this);
    }

    static fromJson(json: ReportHierarchyAccessControl) {
        const cls = new ReportHierarchyAccessControl(
            json.id,
            json.createdBy,
            json.modifiedBy,
        );
        copyBaseFields(json, cls);
        cls.reportId = json.reportId;
        cls.organizationStructureMemberId = json.organizationStructureMemberId;
        cls.accessType = json.accessType;
        return cls;
    }
}
