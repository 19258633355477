import { Theme, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import WidgetCard from "../../components/Widgets/Views/WidgetCard";
import { DashboardStore } from "../../stores/Layout/DashboardStore";
import hexToRGB from "../../utils/hexToRGB";
import DashboardControls from "./DashboardControls";
import DashboardEditor from "./DashboardEditor";
import EmptyDashboard from "./EmptyDashboard";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "calc(100% - 128px)",
        width: "100%",
        position: "relative",
    },

    editModeBanner: {
        backgroundColor: hexToRGB(theme.palette.purple.main, 0.12),
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "46px",
    },
    tableWidgetOutline: {
        border: `1px solid ${theme.palette.purple.main}`,
    },
    editModeOutline: {
        borderRight: `2.7px solid ${hexToRGB(
            theme.palette.primary.main,
            0.75,
        )}`,
        borderLeft: `2.7px solid ${hexToRGB(theme.palette.primary.main, 0.75)}`,
        // borderBottom: `2.7px solid ${hexToRGB(theme.palette.primary.main, .75)}`,
        borderTop: `2.2px solid ${hexToRGB(theme.palette.primary.main, 0.75)}`,
    },
}));

interface OwnProps {
    dashboardStore: DashboardStore;
    width: number;
    height: number;

    containerPadding?: [number, number] | { [P: string]: [number, number] };

    customControls?: () => React.ReactNode;
}

type Props = OwnProps;

const columnsPerBreakpoint = { xl: 4, lg: 4, md: 4, sm: 4, xs: 4 };
const defaultContainerPadding = [32, 16] as [number, number];
const dashboardContainerStyle = { height: "calc(100% - 20px)", width: "100%" };

const BaseDashboard: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles();
    const theme = useTheme();
    useEffect(() => {
        return () => {
            props.dashboardStore.endEditing();
        };
    }, [props.dashboardStore]);

    const breakpoints = useMemo(
        () => ({ ...theme.breakpoints.values }),
        [theme.breakpoints],
    );

    const dashboardItems = props.dashboardStore.dashboardItems ?? [];

    return (
        <>
            <DashboardEditor
                dashboardStore={props.dashboardStore}
                showRefresh={
                    Boolean(props.dashboardStore?.layouts) &&
                    Boolean(dashboardItems)
                }
            />
            <DashboardControls
                dashboardStore={props.dashboardStore}
                customControls={props.customControls}
            />
            {props.height &&
            props.dashboardStore.layouts &&
            dashboardItems.length ? (
                <div className={classes.root}>
                    <div
                        className={clsx({
                            [classes.editModeOutline]: Boolean(
                                props.dashboardStore.editMode,
                            ),
                        })}
                        style={dashboardContainerStyle}
                    >
                        <Scrollbars autoHide>
                            <ResponsiveGridLayout
                                className="layout"
                                layouts={props.dashboardStore.layouts}
                                width={props.width}
                                rowHeight={50}
                                onBreakpointChange={
                                    props.dashboardStore.onBreakpointChange
                                }
                                onLayoutChange={
                                    props.dashboardStore.onLayoutChange
                                }
                                onDragStart={props.dashboardStore.onDragStart}
                                onDragStop={props.dashboardStore.onDragEnd}
                                breakpoints={breakpoints}
                                autoSize={true}
                                containerPadding={
                                    props.containerPadding ??
                                    defaultContainerPadding
                                }
                                useCSSTransforms={true}
                                preventCollision={false}
                                verticalCompact
                                compactType={"vertical"}
                                cols={columnsPerBreakpoint}
                            >
                                {dashboardItems?.map((value) => {
                                    return (
                                        <div
                                            key={value.key}
                                            className={clsx({
                                                [classes.tableWidgetOutline]:
                                                    props.dashboardStore
                                                        ?.editMode,
                                            })}
                                        >
                                            <WidgetCard
                                                content={
                                                    value.widgetDefinition
                                                        .render
                                                }
                                                styles={
                                                    value.widgetDefinition
                                                        .styles
                                                }
                                                dashboardStore={
                                                    props.dashboardStore
                                                }
                                                disableActions={
                                                    props.dashboardStore
                                                        ?.anyWidgetsLoading
                                                }
                                                widgetDefinition={
                                                    value.widgetDefinition
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </ResponsiveGridLayout>
                        </Scrollbars>
                    </div>
                </div>
            ) : (
                <EmptyDashboard
                    parentHeightPx={props.height - 30}
                    dashboardStore={props.dashboardStore}
                />
            )}
        </>
    );
});

export default BaseDashboard;
