import React from "react";
import hexToRGB from "utils/hexToRGB";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar } from "@mui/material";

export function UserAvatar() {
    return (
        <Avatar
            sx={(theme) => ({
                width: 28,
                height: 28,
                backgroundColor: hexToRGB(theme.palette.primary.main, 0.1),
            })}
        >
            <PersonIcon color="primary" />
        </Avatar>
    );
}
