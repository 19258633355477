import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "../../../../../utils/useStore";
import AcxToggleButton from "../../../../UI/AcxToggleButton";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import { QueryFilterOptions } from "../Editor";
import FilterRules from "../FilterRules";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
    selectedQuickFiltersText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    mainSectionTitle: {
        marginTop: theme.spacing(1.5),
    },
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    mainSectionSubtitleText: {
        fontFamily: "Inter",
        opacity: "0.5",
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
        maxWidth: "300px",
    },
    bulletedList: {
        // listStyle: "circle",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },
    leftSubsectionPadding: {
        paddingLeft: theme.spacing(0.5),
    },
}));

interface OwnProps {}

type Props = OwnProps;

const QueryFilterSection: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);

    return (
        <>
            <Grid
                item
                xs={12}
                className={clsx(classes.vertSpace, classes.sectionSpace)}
            >
                <AcxToggleButton
                    id={"queryFilterOptions"}
                    items={[
                        {
                            element: `All ${
                                store.currentReportDataView?.displayName ?? ""
                            } Records`,
                            value: QueryFilterOptions.None,
                        },
                        {
                            element: `Filter ${
                                store.currentReportDataView?.displayName ?? ""
                            } Records`,
                            value: QueryFilterOptions.Custom,
                        },
                    ]}
                    disabled={!Boolean(store.currentReportDataView)}
                    defaultValue={store.queryFilterOption}
                    onChange={store.setQueryFilterOption}
                    exclusive
                />
            </Grid>

            <Grid item xs={12} container>
                {store.queryFilterOption === QueryFilterOptions.Custom && (
                    <FilterRules />
                )}
            </Grid>
        </>
    );
});

export default QueryFilterSection;
