import { Grid } from "@mui/material";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import {
    ApplicationFilterItemsStore,
    FilterKey,
    IApplicationFilterItem,
} from "stores/ApplicationFilters/ApplicationFilterItemsStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { useStore } from "utils/useStore";
import { FilterChip } from "./Chips/FilterChip";

interface AppliedFiltersChipsProps {
    /**
     * A list of filter keys that will not be rendered as chips
     */
    ignoreFilterKeys?: FilterKey[];
    /**
     * A list of filter keys that, while selectable, are not applied
     * to queries in the current context.
     */
    nonApplicableFilterKeys?: FilterKey[];

    disabled?: boolean;
}

function isFilterItemApplicable(
    filterItem: IApplicationFilterItem,
    nonApplicableFilterKeys?: FilterKey[],
) {
    if (!nonApplicableFilterKeys || nonApplicableFilterKeys.length === 0)
        return true;

    const isExtendedMetadataItem =
        filterItem.filterKey.startsWith("extendedMetadata");

    for (const nonApplicableFilterKey of nonApplicableFilterKeys) {
        // Any keys starting with extendedMetadata should be ignored
        // when extendedMetadata is a non applicable filter key
        if (
            isExtendedMetadataItem &&
            nonApplicableFilterKey === "extendedMetadata"
        ) {
            return false;
        } else if (filterItem.filterKey === nonApplicableFilterKey) {
            return false;
        }
    }

    return true;
}

const AppliedFiltersChips = observer(function (
    props: AppliedFiltersChipsProps,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const applicationFilterItemsStore = useStore(ApplicationFilterItemsStore);

    const appliedFilterItems =
        applicationFilterItemsStore.getAppliedFilterItems(
            applicationFiltersStore,
        );

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            gap={0.5}
            flexWrap="wrap"
        >
            {appliedFilterItems
                // remove ignored filter items
                .filter(
                    (item) =>
                        !props.ignoreFilterKeys ||
                        props.ignoreFilterKeys.length === 0 ||
                        !props.ignoreFilterKeys.includes(item.filterKey),
                )
                // sorts non applicable keys/chips to the end
                .sort((_, item) =>
                    props.nonApplicableFilterKeys?.includes(item.filterKey)
                        ? -1
                        : 1,
                )
                .map((item) => {
                    const filterValue = _.get(
                        applicationFiltersStore,
                        item.filterKey,
                    );

                    if (
                        filterValue === undefined ||
                        filterValue === null ||
                        (Array.isArray(filterValue) && filterValue.length === 0)
                    )
                        return null;

                    const isApplicable = isFilterItemApplicable(
                        item,
                        props.nonApplicableFilterKeys,
                    );

                    return (
                        <FilterChip
                            disabled={props.disabled}
                            isApplicable={isApplicable}
                            item={item}
                            key={item.filterKey}
                        />
                    );
                })}
        </Grid>
    );
});

export default AppliedFiltersChips;
