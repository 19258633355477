import { LinearProgress } from "@mui/material";
import AcxControlledTable from "components/UI/AcxTable/AcxControlledTable";
import { IAcxTableColumn } from "components/UI/AcxTable/AcxTable";
import Gutters from "Layouts/Responsive/Gutters";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "utils/useStore";
import SoundClipTranscriptionStore, {
    ClipTranscriptionRow,
} from "./SoundClipTranscriptionStore";

const SoundClipTranscription = observer(() => {
    const store = useStore(SoundClipTranscriptionStore);

    const controls = AcxControlledTable.makeControls(
        "sound-clip-transcription",
        "internal-tools",
        undefined,
        store.datePickerStore,
        store.startTranscriptions,
        "Transcribe",
    );

    function clipIdsFormatter(clipIds: string[]) {
        return clipIds.length.toString();
    }

    function progressFormatter(clipIds: string[], row: ClipTranscriptionRow) {
        if (row.complete) return <span>Complete</span>;
        if (row.running) {
            const total = row.clipIds.length;
            return (
                <LinearProgress
                    variant="determinate"
                    value={(row.completedTranscriptions / total) * 100}
                />
            );
        }
        return <span></span>;
    }

    const columns: IAcxTableColumn[] = [
        { headerLabel: "Blob Container", dataKey: "blobContainer" },
        {
            headerLabel: "Untranscribed Clips",
            dataKey: "clipIds",
            formatter: clipIdsFormatter,
        },
    ];

    if (store.transcriptionStarted) {
        columns.push({
            headerLabel: "Progress",
            dataKey: "transcribed",
            formatter: progressFormatter,
        });
    }

    return (
        <Gutters>
            <AcxControlledTable
                rows={store.tableStore.items}
                columns={columns}
                controls={controls}
                isLoading={store.isLoading}
                rowTerm="Orgs with untranscribed sound clips"
                selectedItems={store.tableStore.selectedItemIds}
                onSelecteditems={store.tableStore.setSelectedItems}
                enableCheck
                keyField="id"
            />
        </Gutters>
    );
});

export default SoundClipTranscription;
