export const injectHtml = (html: string) => {
    return `<!doctype html>
<html lang="en">
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <title>Authenticx | CAPA Alert</title>
        <meta name="description" content="A request for CAPA approval needs attention.">
        <meta name="author" content="Authenticx">
        <meta property="og:title" content="Authenticx, CAPA Approval Request">
        <meta property="og:type" content="email">
        <meta property="og:description" content="A request for CAPA approval needs attention.">
        <link href='https://fonts.googleapis.com/css?family=Inter' rel='stylesheet' type='text/css' />
    </head>

    <body style="font-family: Inter;">
        ${html}

        <footer style="width: 600px; opacity: 0.5; color: #1F1F1F; font-family: Inter; font-size: 13px; letter-spacing: 0; line-height: 20px; text-align: center; margin-left:auto; margin-right:auto">
            <section>
                You are receiving this notification because your account is signed up to receive alerts about activity in your Authenticx workspace.
            </section>

            <address>
                9405 Delegates Row, Indianapolis, IN 46240<br/>
                <a style="color: #3564D5;" href="mailto:helpdesk@authenticx.com">helpdesk@authenticx.com</a> • Phone: <a style="color: #3564D5;" href="tel:+13172966034">317-296-6034</a>
            </address>
        </footer>
    </body>
</html>`;
};
