import React from "react";

const useDialog = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    return {
        isLoading,
        setIsLoading,
        isOpen,
        setIsOpen,
        errorMessage,
        setErrorMessage,
    };
};

export default useDialog;
