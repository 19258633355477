import React from "react";

import {
    ButtonBase,
    Fade,
    Grid,
    Paper,
    Popper,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Link, useNavigate } from "react-router-dom";
import { ClassifierDetails } from "../Table/ClassifierBuilderTableV2";

interface Props {
    open: boolean;
    anchorEl: HTMLElement | null;
    details: ClassifierDetails;
    handleLeave: React.MouseEventHandler<HTMLElement>;
    handleEnter: React.MouseEventHandler<HTMLElement>;
    editable?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    popover: {
        pointerEvents: "none",
    },
    popoverTitle: {
        fontWeight: 600,
        paddingBottom: theme.spacing(0.5),
    },
    popoverDescription: {
        fontSize: theme.typography.fontSize,
    },
    container: {
        maxWidth: "350px",
        padding: theme.spacing(1, 2),
    },
    search: {
        color: "#3564D5",
    },
    button: {
        margin: theme.spacing(0.5, 2),
        padding: theme.spacing(0.5, 1),
    },
    editButton: {
        "&:hover": {
            backgroundColor: theme.palette.grey[100],
        },
        border: "1px solid #E4E4E7",
        width: "55px",
        borderRadius: theme.spacing(0.5),
    },
    hidden: {
        visibility: "hidden",
    },
    italic: {
        fontStyle: "italic",
    },
}));

const ClassifierBuilderV2Popper = ({
    open,
    anchorEl,
    handleLeave,
    details,
    handleEnter,
    editable = true,
}: Props) => {
    const { title, description, id: classifierId, isPublished } = details || {};
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();

    const onSearchInConversations = () => {
        if (classifierId) {
            sessionStorage.setItem(
                "conversationsRbcFilterClassifierId",
                classifierId,
            );
            navigate("/app/conversations");
        }
    };

    const id = open && Boolean(anchorEl) ? "transition-popper" : undefined;
    return (
        <Popper
            placement="top-start"
            open={open && !!classifierId}
            anchorEl={anchorEl}
            transition
            id={id}
            onMouseLeave={handleLeave}
            onMouseEnter={handleEnter}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                        <Grid
                            container
                            direction="column"
                            className={classes.container}
                        >
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontWeight: 600,
                                        paddingBottom: theme.spacing(0.5),
                                    }}
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: theme.typography.fontSize,
                                        fontStyle: !description
                                            ? "italic"
                                            : "normal",
                                    }}
                                >
                                    {description ?? "No description"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container direction="row">
                                <Grid item>
                                    <ButtonBase
                                        disabled={!isPublished}
                                        onClick={onSearchInConversations}
                                        sx={{
                                            margin: theme.spacing(0.5, 2),
                                            padding: theme.spacing(0.5, 1),
                                            visibility: !isPublished
                                                ? "hidden"
                                                : "visible",
                                        }}
                                    >
                                        <Typography sx={{ color: "#3564D5" }}>
                                            Search In Conversations
                                        </Typography>
                                    </ButtonBase>
                                </Grid>
                                {editable && (
                                    <Grid item>
                                        <Link to={`edit/${classifierId}`}>
                                            <ButtonBase
                                                sx={{
                                                    margin: theme.spacing(
                                                        0.5,
                                                        2,
                                                    ),
                                                    padding: theme.spacing(
                                                        0.5,
                                                        1,
                                                    ),
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme.palette
                                                                .grey[100],
                                                    },
                                                    border: "1px solid #E4E4E7",
                                                    width: "55px",
                                                    borderRadius:
                                                        theme.spacing(0.5),
                                                }}
                                            >
                                                <Typography>Edit</Typography>
                                            </ButtonBase>
                                        </Link>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            )}
        </Popper>
    );
};
export default ClassifierBuilderV2Popper;
