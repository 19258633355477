import {
    IMultiLanguageTranscriptionPayload,
    ITranscriptionSegment,
} from "services/TranscriptionService";

export function evaluationNameFromBlobFileKey(blobFileKey?: string) {
    if (!blobFileKey) {
        return "";
    }

    return blobFileKey.substring(
        blobFileKey.indexOf("/") + 1,
        Math.min(
            Math.max(
                blobFileKey.lastIndexOf(".acxMedia"),
                blobFileKey.lastIndexOf(".audio"),
            ),
            blobFileKey.lastIndexOf(".__ACX"),
        ),
    );
}

export function getTranscriptByLangCode(
    langCode: IMultiLanguageTranscriptionPayload["languageCode"],
    multiLangTranscripts?: IMultiLanguageTranscriptionPayload[],
): ITranscriptionSegment[] | undefined {
    return multiLangTranscripts?.find((item) => item.languageCode === langCode)
        ?.transcription.words;
}

export function computeAverageUtteranceForTranscript(
    transcript?: ITranscriptionSegment[],
) {
    if (!transcript) return 0.29;
    return (
        transcript.reduce(
            (p, c) => p + ((c.endTime ?? 0) - (c.startTime ?? 0)),
            0,
        ) / transcript.length
    );
}
