import { GridApiPro } from "@mui/x-data-grid-pro";
import AcxButton from "components/UI/AcxButton";
import React from "react";
import AcxDataGridStore from "../AcxDataGridStore";

interface IFilterIcon {
    onClick: () => void;
    vertIconEnabled: boolean;
    store: AcxDataGridStore;
    gridApiRef?: React.MutableRefObject<GridApiPro>;
    isFiltered?: boolean;
}

const FilterIcon = React.forwardRef<HTMLDivElement, IFilterIcon>(
    (props: IFilterIcon, ref) => {
        return (
            <div ref={ref}>
                <AcxButton
                    disabled={props.store.rows.length < 1}
                    onClick={props.onClick}
                    color="white"
                    style={{
                        padding: "1rem",
                        minWidth: "max-content",
                        margin: `0px ${
                            props.vertIconEnabled ? "0.5rem" : "0px"
                        } 0.5rem 0px`,
                        // backgroundColor: props.isFiltered
                        //     ? theme.palette.blue.main
                        //     : theme.palette.white.main,
                        // color: props.isFiltered
                        //     ? theme.palette.white.main
                        //     : theme.palette.black.main,
                    }}
                >
                    Filter
                </AcxButton>
            </div>
        );
    },
);

export default FilterIcon;
