import { AgentCoaching } from "./AgentModels/AgentCoaching";
import Interaction from "./Interaction";
import { ApplicationUser } from "./Permission/ApplicationUser";
import BaseEntity from "./BaseEntity";
import Organization from "./Organization";
import { AgentStructureMember } from "./AgentModels/AgentStructureMember";
import { Moment } from "moment";
import { AppUser } from "components/Admin/UserManager/UserStore";

export default class Agentv2 extends BaseEntity {
    userId?: string;
    user?: ApplicationUser;
    agentCode?: string;
    agentEmail?: string;
    fullName: string;
    firstName: string;
    lastName: string;
    firstInitial?: string;
    organizationId: string;
    organization: Organization;
    agentCoachings?: AgentCoaching[];
    interactions?: Interaction[];
    agentStructureMembers?: AgentStructureMember[];
    serviceHierarchies?: { label: string; id: string }[];
    startDate?: Moment;
    managerId?: string;
    manager?: AppUser;
}
