import { IWidget } from "../models/Dashboard/Widget";
import BaseService from "./BaseService";

export class WidgetsService extends BaseService {
    protected urlBase: string = "api/Widget";

    public async getPublishedWidgets(organizationId?: string) {
        const urlSearchParams = new URLSearchParams();
        if (organizationId) {
            urlSearchParams.set("organizationId", organizationId);
        }
        const res = await this.get<IWidget[]>(urlSearchParams, "Widgets");
        return res;
    }

    public async publishWidget(reportId: string) {
        const urlSearchParams = new URLSearchParams({ reportId });
        await this.post(null, urlSearchParams, "ReportWidget");
    }

    public async unPublishWidget(orgId: string, reportId: string) {
        const urlSearchParams = new URLSearchParams({ orgId, reportId });
        await this.delete(urlSearchParams, "ReportWidget");
    }
}
