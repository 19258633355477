import { Link, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../Navigation/Routes";

interface IAgentNavigatorProps {
    currentAgentName?: string;
}

const AgentNavigator: React.FC<IAgentNavigatorProps> = (props) => {
    const navigate = useNavigate();

    function onNavigate() {
        navigate(Routes.routePrefix + Routes.AGENT_COACHING);
    }

    return (
        <>
            {props.currentAgentName && (
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="medium" />}
                    aria-label="breadcrumb"
                >
                    <Link
                        style={{
                            textAlign: "left",
                            fontSize: "20px",
                            color: "#3564D5",
                        }}
                        component="button"
                        onClick={onNavigate}
                    >
                        All Agents
                    </Link>
                    <Typography
                        style={{
                            textAlign: "left",
                            fontSize: "20px",
                            color: "#1F1F1F",
                        }}
                    >
                        {props.currentAgentName}
                    </Typography>
                </Breadcrumbs>
            )}
        </>
    );
};

export default AgentNavigator;
