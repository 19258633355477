import {
    LmType,
    Module,
    Question,
} from "components/Admin/Organizations/types/Module.type";
import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";

@AcxStore
export class OrganizationModuleDetailsStore extends BaseStore {
    @observable id: string;
    @observable description?: string;
    @observable isActive: boolean;
    @observable lmType: LmType;
    @observable mediaTypes?: number[];
    @observable name: string;
    @observable order?: number;
    @observable organizationStructureMemberIds: string[];
    @observable questions: Question[];
    @observable renderPlacement: number | null;
    @observable organizationId: string;

    constructor() {
        super("OrganizationModuleStore");
        makeObservable(this);
    }

    @action
    initializeFetchedData = (moduleData: Module) => {
        this.id = moduleData.id;
        this.description = moduleData.description;
        this.isActive = moduleData.isActive;
        this.lmType = moduleData.lmType;
        this.mediaTypes = moduleData.mediaTypes;
        this.name = moduleData.name;
        this.order = moduleData?.order;
        this.organizationStructureMemberIds =
            moduleData.organizationStructureMemberIds;
        this.questions = moduleData.questions;
        this.renderPlacement = moduleData.renderPlacement;
        this.organizationId = moduleData.organizationId;
    };

    @action
    setDescription = (description: string | number | undefined) => {
        if (description) this.description = description.toString();
    };

    @action
    setIsActive = (isActive: boolean) => {
        this.isActive = isActive;
    };

    @action
    setLmType = (lmType: LmType) => {
        this.lmType = lmType;
    };

    @action
    setMediaTypes = (mediaTypes: number[] | undefined) => {
        if (mediaTypes) this.mediaTypes = mediaTypes;
    };

    @action
    setName = (name: string | number | undefined) => {
        if (name) this.name = name.toString();
    };

    @action
    setOrder = (order: string | number | undefined) => {
        if (typeof order === "number") this.order = order;
        else if (typeof order === "string") this.order = parseInt(order);
    };

    @action
    setOrganizationStructureMemberIds = (
        organizationStructureMemberIds: string[],
    ) => {
        this.organizationStructureMemberIds = organizationStructureMemberIds;
    };

    @action
    setQuestions = (questions: Question[]) => {
        this.questions = questions;
    };

    @action
    setRenderPlacement = (renderPlacement: number | null) => {
        this.renderPlacement = renderPlacement;
    };

    @action
    setOrganizationId = (organizationId: string) => {
        this.organizationId = organizationId;
    };
}
