import { Button, ButtonBase, Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EjectIcon from "@mui/icons-material/Eject";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PrintIcon from "@mui/icons-material/Print";
import PublishIcon from "@mui/icons-material/Publish";
import ReplyIcon from "@mui/icons-material/Reply";
import TuneIcon from "@mui/icons-material/Tune";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import useStyles from "Styles/Styles";
import { VizType } from "../../models/Reporting/ReportModel";
import theme from "../../Theme/AppTheme";
import { delay } from "../../utils/helpers";
import { useStore } from "../../utils/useStore";
import MessageStore from "../ManagerInteractions/Stores/MessageStore";
import AcxMenu, { AcxMenuItemProps, StyledMenuLabel } from "../UI/Menu/AcxMenu";
import ReportsStore from "./Stores/ReportsStore";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: "88px",
            width: "100%",
            backgroundColor: theme.palette.white.main,
            padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
            margin: 0,
            boxShadow:
                "0 0 3px 0 rgba(0,0,0,0.05), 0 1px 1px 0 rgba(0,0,0,0.05)",
            zIndex: 10,
        },
        titleText: {
            fontSize: "20px",
            letterSpacing: "0",
            lineHeight: "28px",
        },
        subTitleText: {
            opacity: "0.5",
            fontSize: "13px",
            letterSpacing: "0",
            lineHeight: "20px",
            marginTop: theme.spacing(1),
        },
        moreVertButton: {
            width: "32px",
            height: "32px",
        },
        smallOutlineIconButton: {
            color: theme.palette.secondary.main,
            borderStyle: "solid",
            borderColor: theme.palette.secondary.main,
            borderWidth: "1px",
        },
        editorButtons: {
            textTransform: "none",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold as any,
            height: "32px",
        },
        deleteIcon: {
            color: theme.palette.red.main,
        },
        shareIcon: {
            color: theme.palette.text.disabled,
        },
        menuText: {
            fontSize: "13px",
            letterSpacing: "0",
            lineHeight: "20px",
            color: "inherit",
        },
        importantText: {
            color: theme.palette.red.main,
            "&:hover, &$selected:hover": {
                color: theme.palette.neutral[600],
            },
        },
    });

interface Props {
    reportName: string;
    editedBy: string;
    editedOn: string;
    editedAt: string;
}

const ReportHeader = observer((props: Props) => {
    const classes = useStyles(styles);
    const store = useStore(ReportsStore);
    const msgStore = useStore(MessageStore);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();

    function onEditReport() {
        store.initializeReportEditorForExisting();
        if (
            store.orgStore.selectedOrganization?.id &&
            store.currentReport?.id
        ) {
            navigate(
                `/focus/reporteditor/${store.orgStore.selectedOrganization.id}/${store.currentReport.id}`,
            );
        }
    }

    function handleMoreMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
    }

    function handleMoreMenuClose() {
        setAnchorEl(null);
    }

    function handleSharePublicLink() {
        handleMoreMenuClose();
    }

    function handleOpenChartConfig() {
        handleMoreMenuClose();
        delay(25).then(store.populateChartConfigDrawer);
    }

    function handleDeleteReport() {
        store.deleteReport();
        handleMoreMenuClose();
    }

    function handlePublishAsWidget() {
        store.publishReportAsWidget();
        handleMoreMenuClose();
    }

    function handleUnPublishAsWidget() {
        store.unPublishReportWidget();
        handleMoreMenuClose();
    }

    function onDownloadChartImg() {
        const imgURI = store.chartWrapper?.getChart()?.getImageURI();
        if (imgURI && store.currentReport) {
            const a = document.createElement("a");
            a.setAttribute("style", "display:none");
            a.id = "AcxVizPng";
            a.style.display = "none";
            a.href = imgURI;
            a.download = `${store.currentReport.name.trim()}.png`;
            a.click();
            a.remove();
        } else {
            msgStore.logWarning("Unable to download chart as .png");
        }

        handleMoreMenuClose();
    }

    const menuItems: AcxMenuItemProps[] = [
        {
            id: "share-report-menu-item",
            hidden: true,
            props: { onClick: handleSharePublicLink, disabled: true },
            label: (
                <StyledMenuLabel className={classes.menuText}>
                    Share Public Link
                </StyledMenuLabel>
            ),
            icon: <ReplyIcon className={classes.shareIcon} />,
        },
        {
            id: "config-chart-report-menu-item",
            hidden: !store.currentReport,
            props: {
                onClick: handleOpenChartConfig,
                // disabled: store.currentReport?.vizType === VizType.Table,
            },
            label: (
                <StyledMenuLabel className={classes.menuText}>
                    Chart Configuration
                </StyledMenuLabel>
            ),
            icon: <TuneIcon color={"secondary"} />,
        },
        {
            id: "publish-report-as-widget",
            props: {
                onClick: handlePublishAsWidget,
                disabled:
                    !Boolean(store.currentReport?.id) || !store.canEditReports,
            },
            label: (
                <StyledMenuLabel className={classes.menuText}>
                    Publish As Widget
                </StyledMenuLabel>
            ),
            icon: <PublishIcon color={"secondary"} />,
        },
        {
            id: "un-publish-report-as-widget",
            hidden: !store.currentReport?.isPublished,
            props: {
                onClick: handleUnPublishAsWidget,
                disabled:
                    !Boolean(store.currentReport?.id) || !store.canEditReports,
            },
            label: (
                <StyledMenuLabel
                    className={clsx(classes.menuText, classes.importantText)}
                >
                    UnPublish Widget
                </StyledMenuLabel>
            ),
            icon: <EjectIcon className={classes.deleteIcon} />,
        },
        {
            id: "delete-report-menu-item",
            props: {
                onClick: handleDeleteReport,
                disabled:
                    !Boolean(store.currentReport?.id) || !store.canEditReports,
            },
            label: (
                <StyledMenuLabel
                    className={clsx(classes.menuText, classes.importantText)}
                >
                    Delete
                </StyledMenuLabel>
            ),
            icon: <DeleteIcon className={classes.deleteIcon} />,
        },
        {
            id: "print-report-menu-item",
            props: {
                onClick: onDownloadChartImg,
                disabled:
                    !store.currentReport ||
                    !store.chartWrapper ||
                    store.currentReport?.vizType === VizType.Table,
            },
            label: (
                <StyledMenuLabel className={classes.menuText}>
                    Download Chart
                </StyledMenuLabel>
            ),
            icon: <PrintIcon />,
        },
    ];

    return (
        <>
            <Grid
                container
                className={classes.root}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item xs={9}>
                    <Grid container item spacing={2}>
                        <Grid item>
                            <StyledMenuLabel
                                className={classes.titleText}
                                variant="subtitle1"
                            >
                                {props.reportName}
                            </StyledMenuLabel>
                        </Grid>
                        <Grid item style={{ alignSelf: "center" }}>
                            <BeatLoader
                                size={6}
                                color={theme.palette.secondary.main}
                                loading={
                                    store.reportDataViewModel.anyTaskLoading ||
                                    store.anyTaskLoading
                                }
                            />
                        </Grid>
                    </Grid>
                    {!store.currentReport?.drillThru && (
                        <StyledMenuLabel
                            className={classes.subTitleText}
                            variant="h3"
                        >
                            Last edited by {props.editedBy} on {props.editedOn}{" "}
                            at {props.editedAt}{" "}
                        </StyledMenuLabel>
                    )}
                </Grid>
                <Grid
                    item
                    xs={3}
                    container
                    justifyContent={"flex-end"}
                    spacing={2}
                    wrap={"nowrap"}
                >
                    <Grid item>
                        <Button
                            onClick={onEditReport}
                            disabled={!store.canEditReports}
                            className={classes.editorButtons}
                            variant={"contained"}
                            color={"secondary"}
                            size={"small"}
                            disableElevation
                        >
                            Edit Criteria
                        </Button>
                    </Grid>
                    <Grid item>
                        <ButtonBase
                            onClick={handleMoreMenuClick}
                            className={clsx(
                                classes.moreVertButton,
                                classes.smallOutlineIconButton,
                            )}
                        >
                            <MoreVertIcon />
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Grid>

            <AcxMenu
                menuItems={menuItems}
                anchorElement={anchorEl}
                onMenuClose={handleMoreMenuClose}
            />
        </>
    );
});

export default ReportHeader;
