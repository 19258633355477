import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import AcxToggleButton from "components/UI/AcxToggleButton";
import { HipaaCompliance } from "stores/ApplicationFilters/Filters/HipaaCompliance";
import { useStore } from "utils/useStore";
import { AuthStore } from "stores/AuthStore";

export const HipaaComplianceFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("HIPAA Compliance Model")) return null;

    return (
        <AcxToggleButton
            id="application-filters-HIPAA-Compliance"
            exclusive
            items={[
                {
                    id: "application-filters-HIPAA-Compliance-Not-Compliant",
                    element: "Not Compliant",
                    value: HipaaCompliance.NotCompliant,
                },
                {
                    id: "application-filters-HIPAA-Compliance-Compliant",
                    element: "Compliant",
                    value: HipaaCompliance.Compliant,
                },
                {
                    id: "application-filters-HIPAA-Compliance-NA",
                    element: "N/A",
                    value: HipaaCompliance.NotApplicable,
                },
            ]}
            onChange={(value) => {
                props.store.setHipaaCompliance(value);
            }}
            defaultValue={props.store.hipaaCompliance}
            justifyContent="center"
            groupFlexGrow={1}
        />
    );
});
