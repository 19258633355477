import {
    ButtonBase,
    Divider,
    Grid,
    IconButton,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import MessageStoreBase from "stores/RealTimeMessages/MessageStoreBase";
import { useStore } from "../../../utils/useStore";
import EmptyNotifications from "./EmptyNotifications";
import NotificationList from "./NotificationList";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "350px",
        height: "100%",
    },
    leftPadding: {
        paddingLeft: theme.spacing(1),
    },
    title: {},
    sectionTitle: {
        fontWeight: "bold",
    },
    mainContent: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    controlsSection: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    controlsSectionSpacing: {
        height: theme.spacing(2.5),
        width: "100%",
    },
    dismissAll: {
        textTransform: "none",
        color: theme.palette.info.dark,
        fontSize: "13px",
    },
    dividerSpacing: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
    },
}));

interface OwnProps {
    onClose: () => void;
}

type Props = OwnProps;

const NotificationDrawerContent: FunctionComponent<Props> = observer(
    (props) => {
        const classes = useStyles();
        const store = useStore(MessageStoreBase);

        const emptyNotifications = store.notifications.length === 0;

        return (
            <div className={classes.root}>
                <Grid container justifyContent={"flex-start"}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        wrap={"nowrap"}
                    >
                        <Grid item className={classes.leftPadding} zeroMinWidth>
                            <Typography variant={"h1"}>
                                Notifications
                            </Typography>
                        </Grid>
                        <Grid item style={{ alignSelf: "flex-start" }}>
                            <IconButton onClick={props.onClose} size="large">
                                <CloseIcon fontSize={"medium"} />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <div className={classes.controlsSectionSpacing} />
                    {!emptyNotifications ? (
                        <>
                            <Grid
                                item
                                container
                                justifyContent={"flex-end"}
                                className={classes.controlsSection}
                            >
                                <ButtonBase
                                    onClick={store.dismissAllNotifications}
                                    className={classes.dismissAll}
                                >
                                    Dismiss All
                                </ButtonBase>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.dividerSpacing}
                            >
                                <Divider variant={"fullWidth"} />
                            </Grid>
                        </>
                    ) : (
                        <EmptyNotifications />
                    )}
                </Grid>
                {!emptyNotifications && (
                    <div style={{ height: "calc(100% - 115px)" }}>
                        <Scrollbars autoHide>
                            <NotificationList
                                notifications={store.notifications}
                                onDismiss={store.dismissNotification}
                            />
                        </Scrollbars>
                    </div>
                )}
            </div>
        );
    },
);

export default NotificationDrawerContent;
