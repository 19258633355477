import React from "react";

interface SmartPredictSvgProps {
    width?: string;
    height?: string;
}

type Props = SmartPredictSvgProps;

const SmartPredictSvg: React.FC<Props> = ({ width, height }) => {
    return (
        <svg
            width={width ?? "24"}
            height={height ?? "25"}
            viewBox={`0 0 ${width ?? 24} ${height ?? 25}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1329_63)">
                <path
                    d="M11.5382 24.1955C17.9106 24.1955 23.0764 18.823 23.0764 12.1957C23.0764 5.56847 17.9106 0.195999 11.5382 0.195999C5.16583 0.195999 0 5.56847 0 12.1957C0 18.823 5.16583 24.1955 11.5382 24.1955Z"
                    fill="#32D1B7"
                />
                <path
                    d="M15.7038 12.4775L12.9365 13.4656C12.8953 13.4803 12.8579 13.5046 12.8272 13.5366C12.7964 13.5686 12.7731 13.6075 12.7589 13.6503L11.809 16.5286C11.7896 16.5872 11.7531 16.638 11.7046 16.674C11.6561 16.71 11.5981 16.7293 11.5385 16.7293C11.479 16.7293 11.4209 16.71 11.3724 16.674C11.3239 16.638 11.2875 16.5872 11.2681 16.5286L10.3181 13.6503C10.304 13.6075 10.2806 13.5686 10.2499 13.5366C10.2191 13.5046 10.1817 13.4803 10.1406 13.4656L7.37324 12.4775C7.31675 12.4575 7.26769 12.4196 7.23296 12.3691C7.19823 12.3186 7.17955 12.2581 7.17955 12.196C7.17955 12.1339 7.19823 12.0733 7.23296 12.0229C7.26769 11.9724 7.31675 11.9344 7.37324 11.9144L10.1406 10.9269C10.1817 10.912 10.2191 10.8876 10.2498 10.8555C10.2806 10.8234 10.3039 10.7845 10.3181 10.7417L11.2681 7.8633C11.2875 7.80473 11.3239 7.75391 11.3724 7.71794C11.4209 7.68196 11.479 7.66263 11.5385 7.66263C11.5981 7.66263 11.6561 7.68196 11.7046 7.71794C11.7531 7.75391 11.7896 7.80473 11.809 7.8633L12.7589 10.7417C12.7731 10.7845 12.7965 10.8234 12.8272 10.8555C12.858 10.8876 12.8953 10.912 12.9365 10.9269L15.7038 11.9144C15.7603 11.9344 15.8094 11.9724 15.8441 12.0229C15.8788 12.0733 15.8975 12.1339 15.8975 12.196C15.8975 12.2581 15.8788 12.3186 15.8441 12.3691C15.8094 12.4196 15.7603 12.4575 15.7038 12.4775V12.4775Z"
                    fill="white"
                />
                <path
                    d="M11.5385 20.4626C15.9285 20.4626 19.4872 16.7615 19.4872 12.196C19.4872 7.63042 15.9285 3.92931 11.5385 3.92931C7.14854 3.92931 3.58978 7.63042 3.58978 12.196C3.58978 16.7615 7.14854 20.4626 11.5385 20.4626Z"
                    stroke="white"
                    strokeWidth="6.03805"
                    strokeMiterlimit="10"
                />
                <path
                    d="M11.7949 2.3293H11.2821V4.99597H11.7949V2.3293Z"
                    fill="#32D1B7"
                    stroke="#32D1B7"
                    strokeWidth="2.92019"
                    strokeMiterlimit="10"
                />
                <path
                    d="M3.41975 6.87487L3.04292 7.55365L5.31012 8.91497L5.68694 8.23619L3.41975 6.87487Z"
                    fill="#32D1B7"
                    stroke="#32D1B7"
                    strokeWidth="2.92019"
                    strokeMiterlimit="10"
                />
                <path
                    d="M3.04244 16.8306L3.41927 17.5094L5.68646 16.1481L5.30964 15.4693L3.04244 16.8306Z"
                    fill="#32D1B7"
                    stroke="#32D1B7"
                    strokeWidth="2.92019"
                    strokeMiterlimit="10"
                />
                <path
                    d="M11.2821 22.0627H11.7949V19.396H11.2821V22.0627Z"
                    fill="#32D1B7"
                    stroke="#32D1B7"
                    strokeWidth="2.92019"
                    strokeMiterlimit="10"
                />
                <path
                    d="M19.6232 17.5108L20 16.832L17.7329 15.4707L17.356 16.1495L19.6232 17.5108Z"
                    fill="#32D1B7"
                    stroke="#32D1B7"
                    strokeWidth="2.92019"
                    strokeMiterlimit="10"
                />
                <path
                    d="M19.9276 7.68581L19.5508 7.00391L17.2732 8.36523L17.6501 9.04713L19.9276 7.68581Z"
                    fill="#32D1B7"
                    stroke="#32D1B7"
                    strokeWidth="2.93025"
                    strokeMiterlimit="10"
                />
            </g>
            <defs>
                <clipPath id="clip0_1329_63">
                    <rect
                        width={width ?? "23.0769"}
                        height={height ?? "24"}
                        fill="white"
                        // transform="translate(0 0.195999)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SmartPredictSvg;
