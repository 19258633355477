import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";
import { SafetyEvent } from "stores/ApplicationFilters/Filters/SafetyEvent";

export const SafetyEventChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    const safetyEventValue = applicationFiltersStore.safetyEvent;
    if (safetyEventValue === undefined || safetyEventValue === null)
        return null;

    return (
        <AppliedFilterChip
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={() => applicationFiltersStore.setSafetyEvent(undefined)}
        >
            SE {SafetyEvent.toString(safetyEventValue)}
        </AppliedFilterChip>
    );
});
