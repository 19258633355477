import {
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { User } from "oidc-client";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { DashboardDomain } from "../../../models/Dashboard/DashboardDefinition";
import {
    ReportDataField,
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "../../../models/Reporting/ReportDataField";
import { FieldUse, ReportField } from "../../../models/Reporting/ReportField";
import {
    Comparator,
    ReportFilter,
} from "../../../models/Reporting/ReportFilter";
import { AuthStore } from "../../../stores/AuthStore";
import { BaseStore } from "../../../stores/BaseStore";
import { OrgSelectorComponentStore } from "../../../stores/ComponentStores/OrgSelectorComponentStore";
import { DashboardStore } from "../../../stores/Layout/DashboardStore";
import ManagerInteractionsStore from "./ManagerInteractionsStore";

@AcxStore
export class ManagerInteractionsDashboardStore extends BaseStore {
    @observable orgSelectStore: OrgSelectorComponentStore;
    readonly dashboardStore: DashboardStore;

    @observable private user?: User | null;
    @observable private orgId?: string;

    constructor(private rootStore: IRootStore) {
        super("Analyst Manager Dashboard Store");
        makeObservable(this);
        this.orgSelectStore = this.rootStore.getStore(
            ManagerInteractionsStore,
        ).orgSelectStore;
        this.dashboardStore = new DashboardStore(
            rootStore,
            DashboardDomain.AnalystManagement,
        );

        rootStore
            .getStore(AuthStore)
            .getUserObject()
            .then((value) => {
                runInAction(() => {
                    this.user = value;
                });
            })
            .catch((reason) =>
                console.error(`Failed to retrieve User Profile ${reason}`),
            );

        reaction(
            (r) => this.orgSelectStore.orgId,
            (organizationId) => {
                if (organizationId) {
                    this.orgId = organizationId;
                }
            },
            { fireImmediately: true, delay: 0 },
        );

        reaction(
            (r) => ({
                orgId: this.orgId,
                user: this.user,
                activeLocation: this.rootStore.activeLocation,
            }),
            (arg) => {
                if (
                    arg.activeLocation &&
                    arg.activeLocation.location !==
                        "/app/interactions/Dashboard"
                ) {
                    return;
                }

                if (arg.orgId && arg.user) {
                    this.dashboardStore.initializeStoreParameters(
                        arg.orgId,
                        arg.user,
                        undefined,
                        this.buildDashboardDynamicFilters(),
                    );
                }
            },
            { fireImmediately: true, delay: 0 },
        );

        reaction(
            (r) => ({
                selectedOrganization:
                    this.rootStore.getStore(AuthStore).orgStore
                        ?.selectedOrganization,
            }),
            (arg) => {
                this.orgSelectStore.selectOrg(arg.selectedOrganization);
            },
            { fireImmediately: true },
        );
    }

    private buildDashboardDynamicFilters() {
        const filterMap = new Map<string, ReportFilter>();

        const teamMembersFilter = new ReportFilter();
        teamMembersFilter.value = "%TeamMembers%";
        teamMembersFilter.comparator = Comparator.EQ;
        teamMembersFilter.reportField = new ReportField(
            undefined,
            undefined,
            undefined,
            undefined,
        );
        teamMembersFilter.reportField.fieldUse = FieldUse.QuickFilter;
        teamMembersFilter.reportField.reportDataField = new ReportDataField();
        teamMembersFilter.reportField.reportDataField.variation =
            ReportDataFieldVariation.Concrete;
        teamMembersFilter.reportField.reportDataField.datatype =
            ReportFieldDataTypes.Categorical;
        teamMembersFilter.reportField.reportDataField.fieldName = "AnalystName";
        teamMembersFilter.reportField.reportDataField.value = "AnalystName";

        filterMap.set("TeamMembers", teamMembersFilter);

        const selfFilter = new ReportFilter();
        selfFilter.value = "%Self%";
        selfFilter.comparator = Comparator.EQ;
        selfFilter.reportField = new ReportField(
            undefined,
            undefined,
            undefined,
            undefined,
        );
        selfFilter.reportField.fieldUse = FieldUse.QuickFilter;
        selfFilter.reportField.reportDataField = new ReportDataField();
        selfFilter.reportField.reportDataField.variation =
            ReportDataFieldVariation.Concrete;
        selfFilter.reportField.reportDataField.datatype =
            ReportFieldDataTypes.Categorical;
        selfFilter.reportField.reportDataField.fieldName = "AnalystName";
        selfFilter.reportField.reportDataField.value = "AnalystName";

        filterMap.set("Self", selfFilter);

        return filterMap;
    }

    @computed
    get isEditMode() {
        return this.dashboardStore.editMode;
    }
}
