import { action, computed, makeObservable, observable, reaction } from "mobx";
import ClassifierService from "services/ClassifierService";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { CombinatorRuleType } from "models/RuleCombinator";

export interface IWordsPhrasesModel {
    value: "Contains" | "Does not contain" | "Starts with";
    searchPhrase: string;
    combinator: CombinatorRuleType;
    filterGroupId: number;
    saidWithin?: number;
}

export const initWordsPhrasesItems: IWordsPhrasesModel[] = [
    {
        value: "Contains",
        searchPhrase: "",
        combinator: 0,
        filterGroupId: 0,
    },
];

export default class WordsPhrasesStore extends BaseStore {
    private readonly classifierService: ClassifierService =
        new ClassifierService();

    @observable
    onSearchQueryChange: (searchPhrase: string) => void;

    @observable
    wordsPhrasesItems: IWordsPhrasesModel[] = initWordsPhrasesItems;

    @observable
    wordsPhrasesString: string = "";

    @observable
    isInputStandard: boolean = true;

    @observable
    isConfirmationModalOpen: boolean = false;

    @observable
    wordsPhrasesFtsSyntaxError: string | undefined;

    @observable
    isValidWordsPhrasesString: boolean | undefined;

    @observable
    errorIndices: number[] = [];

    constructor(public rootStore: IRootStore) {
        super("WordsPhrasesStore");
        makeObservable(this);

        reaction(
            () => ({
                searchString: this.wordsPhrasesString,
            }),
            () => {
                this.onSearchQueryChange(this.wordsPhrasesString);
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                items: this.wordsPhrasesItems?.length,
            }),
            () => {
                const newSearchString = this.getSearchPhraseString();
                this.wordsPhrasesString = newSearchString;
                this.onSearchQueryChange(newSearchString);
            },
            { fireImmediately: true },
        );
    }

    @action
    setWordsPhrasesItem = (
        index: number,
        wordsPhrasesItem: IWordsPhrasesModel,
    ) => {
        this.wordsPhrasesItems[index] = wordsPhrasesItem;
    };

    @action
    toggleConfirmationModal = () => {
        this.isConfirmationModalOpen = !this.isConfirmationModalOpen;
    };

    @action
    toggleStandardInput = () => {
        this.isInputStandard = !this.isInputStandard;
    };

    @action
    setWordsPhrasesString = (input: string) => {
        this.wordsPhrasesString = input;
    };

    @action
    setWordsPhrasesItemSearchPhrase = (index: number, searchPhrase: string) => {
        this.wordsPhrasesItems[index].searchPhrase = searchPhrase;
        // need to rerender here to ensure onChange reaction runs
        this.wordsPhrasesItems = [...this.wordsPhrasesItems];
    };

    @action
    setWordsPhrasesItems = (wordsPhrasesItems: IWordsPhrasesModel[]) => {
        this.wordsPhrasesItems = wordsPhrasesItems;
    };

    @action
    setIsValidWordsPhrasesString = (isValidString: boolean) => {
        this.isValidWordsPhrasesString = isValidString;
    };

    @action
    setWordsPhrasesFtsSyntaxError = (error: string) => {
        this.wordsPhrasesFtsSyntaxError = error;
    };

    @action
    updateRBCItemContains = (
        index: number,
        value: IWordsPhrasesModel["value"],
    ) => {
        this.wordsPhrasesItems[index].value = value;
        // need to rerender here to ensure onChange reaction runs
        this.wordsPhrasesItems = [...this.wordsPhrasesItems];
    };

    @action
    addWordsPhrasesItem = (combinator: CombinatorRuleType) => {
        const newItem: IWordsPhrasesModel = {
            value: "Contains",
            searchPhrase: "",
            combinator: combinator,
            filterGroupId: 0,
        };

        if (this.wordsPhrasesItems.length) {
            const lastItemFilterGroupId =
                this.wordsPhrasesItems[this.wordsPhrasesItems.length - 1]
                    ?.filterGroupId;

            newItem.filterGroupId =
                combinator === CombinatorRuleType.And
                    ? lastItemFilterGroupId
                    : lastItemFilterGroupId + 1;
        }

        this.wordsPhrasesItems.push(newItem);
    };

    @action
    removeWordsPhrasesItem = (inputIndex: number) => {
        if (this.wordsPhrasesItems.length === 1) return;
        this.wordsPhrasesItems = this.wordsPhrasesItems.filter(
            (item, index) => index !== inputIndex,
        );
    };

    @action
    changeCombinator = (index: number, input: CombinatorRuleType) => {
        const thisItem = this.wordsPhrasesItems[index];
        const nextItem = this.wordsPhrasesItems[index + 1];
        const itemCount = this.wordsPhrasesItems.length;
        const newFilterId =
            input === CombinatorRuleType.Or
                ? thisItem.filterGroupId + 1
                : thisItem.filterGroupId;

        // thisItem.combinator = input;
        nextItem.combinator = input;
        nextItem.filterGroupId = newFilterId;

        // For all items after changed item
        for (let i = index + 2; i < itemCount; i++) {
            const currentItem = this.wordsPhrasesItems[i];
            if (input === CombinatorRuleType.And) {
                currentItem.filterGroupId--;
            } else {
                currentItem.filterGroupId++;
            }
        }
        // need to rerender here to ensure onChange reaction runs
        this.wordsPhrasesItems = [...this.wordsPhrasesItems];
    };

    @action
    setRuleSaidWithin = (index: number, input: number) => {
        this.wordsPhrasesItems[index].saidWithin = input;
        // need to rerender here to ensure onChange reaction runs
        this.wordsPhrasesItems = [...this.wordsPhrasesItems];
    };

    @action
    async validateWordsPhrasesAdvString() {
        const result = await this.classifierService.isFTSCompatible(
            this.wordsPhrasesString,
        );
        this.setIsValidWordsPhrasesString(result.success);
        if (!result.success) this.setWordsPhrasesFtsSyntaxError(result.message);
    }

    // parse search string from standard inputs, validate inputs
    getSearchPhraseString(): string {
        this.errorIndices = [];
        return this.wordsPhrasesItems
            ?.map((item, index) => {
                let formattedPhrase = item.searchPhrase?.trim() || "";

                if (formattedPhrase) {
                    if (this.hasStandardSyntaxError(formattedPhrase)) {
                        this.errorIndices.push(index);
                    }
                    if (formattedPhrase.includes(" ")) {
                        formattedPhrase = `"${formattedPhrase}"`;
                    }
                    if (item.value === "Starts with") {
                        formattedPhrase = `${formattedPhrase}*`;
                        if (item.searchPhrase.includes(" ")) {
                            this.errorIndices.push(index);
                        }
                    } else if (item.value === "Does not contain") {
                        formattedPhrase = `!${formattedPhrase}`;
                    }
                    if (item.saidWithin) {
                        if (
                            !item.searchPhrase.includes(" ") ||
                            item.value !== "Contains" ||
                            item.saidWithin < 0 ||
                            item.saidWithin > 15
                        ) {
                            this.errorIndices.push(index);
                        }
                        formattedPhrase = `${formattedPhrase}~${item.saidWithin}`;
                    }
                }

                // I think this may need to change to check groupIds when parens are implemented
                const combinatorSymbol =
                    index === 0
                        ? ""
                        : item.combinator === CombinatorRuleType.And
                        ? " & "
                        : " | ";

                return combinatorSymbol + formattedPhrase;
            })
            .join("");
    }

    hasStandardSyntaxError(standardString: string): boolean {
        const pattern = /^[a-zA-Z0-9\s]*$/;
        return !pattern.test(standardString);
    }

    @computed
    get hasError(): boolean {
        return !!this.errorIndices.length;
    }
}

// WIP: ADV to STDRD works for items without quotes
// @action
// parseAndSetStandardCombinatorItems(phrase: string) {
//     this.wordsPhrasesItems = [];

//     const parts = phrase.split(" ");
//     // const phrases = phrase.split('"').map((item, index) => index % 2); //only odd index values will have phrases

//     // if we have phrases, split on ('" ') instead
//     // user cannot mix phrases with words

//     console.log(parts);

//     let currentFilterGroupId = 0;

//     // if no phrases we can split on space
//     parts.forEach((part) => {
//         const trimmedPart = part.trim();

//         const item: IWordsPhrasesModel = {
//             searchPhrase: "",
//             combinator: 0,
//             filterGroupId: currentFilterGroupId,
//             value: "Contains",
//         };
//         // if (trimmedPart.includes('"')) {
//         //     item.searchPhrase = trimmedPart.replace('"', "");
//         // } else
//         if (trimmedPart === "&") {
//             item.combinator = CombinatorRuleType.And;
//             item.filterGroupId = currentFilterGroupId;
//             return;
//         } else if (trimmedPart === "|") {
//             item.combinator = CombinatorRuleType.Or;
//             currentFilterGroupId++;
//             item.filterGroupId = currentFilterGroupId;
//             return;
//         }

//         if (trimmedPart.includes("*")) {
//             item.value = "Starts with";
//             item.searchPhrase = trimmedPart.replace("*", "");
//         } else if (trimmedPart.includes("!")) {
//             item.value = "Does not contain";
//             item.searchPhrase = trimmedPart.replace("!", "");
//         } else {
//             item.searchPhrase = trimmedPart;
//         }

//         this.wordsPhrasesItems.push(item);
//     });
// }
