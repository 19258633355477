import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { Observer } from "mobx-react";
import React, { useCallback } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import SideControlsPage from "../../../../../Layouts/SideControls/SideControlsPage";
import { ClassifierType } from "../../../../../models/ClassifierModel";
import AcxSnackBar from "../../../../UI/AcxSnackBar";
import ErrorSnackBar from "../../../../UI/ErrorSnackBar";
import { RuleBuildStore } from "../RuleBuildStore";
import MainContent from "./MainContent/MainContent";
import RuleSetSaveDialog from "./MainContent/RuleSetSaveDialog";
import RuleSideContent from "./MainContent/SideContent";

const styles = (theme: Theme) =>
    createStyles({
        mainContent: { padding: theme.spacing(2) },
        drawerContent: {},
    });

export function ClassifierTypeToItemSymbol(classifierTypeName: string) {
    return classifierTypeName === ClassifierType.Lucene
        ? RuleBuilderItemTypes.SpeechClassifierRule
        : RuleBuilderItemTypes.MetaClassifierRule;
}

export const RuleBuilderItemTypes = {
    MetaClassifierRule: Symbol("AcxMetaClassifierRule"),
    SpeechClassifierRule: Symbol("AcxSpeechClassifierRule"),
};

export type RuleTypes = "Filter" | "Stratify" | "Ranking";

export type OnDropParams = {
    type: symbol;
    classifierId: string;
    ruleId: string;
    ruleType: "Filter" | "Stratify" | "Ranking";
};

export default function MainRuleBuilder() {
    const classes = useStyles(styles);
    const store = useStore(RuleBuildStore);

    const onDrop = useCallback(
        (item: OnDropParams, monitor: DropTargetMonitor) => {
            const isOverMe = monitor.isOver({ shallow: true });
            if (isOverMe) {
                store.associateClassifierWithRuleType(item);
            }
        },
        [store],
    );

    const drop = useDrop({
        accept: [
            RuleBuilderItemTypes.MetaClassifierRule,
            RuleBuilderItemTypes.SpeechClassifierRule,
        ],
        drop(item, monitor) {
            const isOverMe = monitor.isOver({ shallow: true });

            if (isOverMe) {
                store.deactivateRule(item as any);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
    });

    return (
        <Observer>
            {() => (
                <>
                    {store.anyTaskErrors && (
                        <ErrorSnackBar
                            store={store}
                            onClose={store.clearTaskErrors}
                            onRetryClick={() => store.nextTaskError?.task?.()}
                        />
                    )}

                    {store.latestWarning && (
                        <AcxSnackBar
                            open={Boolean(store.latestWarning)}
                            severity={"warning"}
                            message={store.latestWarning}
                            onClose={store.clearLatestWarning}
                        />
                    )}

                    <RuleSetSaveDialog store={store} />

                    <SideControlsPage
                        ref={drop[1]}
                        drawerContentClasses={classes.drawerContent}
                        mainContentClasses={classes.mainContent}
                        drawerContent={<RuleSideContent store={store} />}
                        mainContent={<MainContent onDrop={onDrop} />}
                        drawerVerticalOffsetPixels={0}
                        drawerLocation={"left"}
                        containerOffsetPixels={0}
                    />
                </>
            )}
        </Observer>
    );
}
