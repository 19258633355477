import { AcxConfirmationDialogWrapper } from "components/UI/AcxConfirmationDialog";
import AcxServiceHierarchySelector from "components/UI/Select/AcxServiceHierarchySelector";
import { observer } from "mobx-react";
import React, { useRef } from "react";
import { useStore } from "utils/useStore";
import AssignInteractionToHierarchyDialogStore, {
    LOAD_HIERARCHY,
} from "../../Stores/AssignInteractionToHierarchyDialogStore";

interface Props {
    onConfirm: () => void;
    orgId?: string;
}

const AssignInteractionToHierarchyDialog: React.FC<Props> = observer(
    ({ onConfirm, orgId }) => {
        const store = useStore(AssignInteractionToHierarchyDialogStore);
        const containerRef = useRef(null);

        React.useEffect(() => {
            if (orgId) {
                store.setOrgId(orgId);
            }
        }, [orgId, store]);

        return (
            <AcxConfirmationDialogWrapper
                isLoading={store.getTaskLoading(LOAD_HIERARCHY)}
                dialogStore={store.dialogStore}
                title="Assign interactions to new location in organization structure"
                confirmButtonText="Assign"
                content={
                    <AcxServiceHierarchySelector
                        levels={store.levels}
                        members={store.members}
                        hierarchySelections={store.hierarchySelections}
                        onSelectMember={store.setHierarchySelection}
                        containerRef={containerRef.current}
                    />
                }
                onConfirm={onConfirm}
            />
        );
    },
);

export default AssignInteractionToHierarchyDialog;
