import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import OrganizationWorkflowEmailStore from "../Stores/OrganizationWorkflowEmailStore";
import { Setter } from "./OrganizationWorkflowsEmailEditor";

const initialState = {
    mouseX: null,
    mouseY: null,
};

type Props = {
    children: any;
    setter: (
        variable: Setter["variable"],
        isLink?: Setter["isLink"],
        linkType?: Setter["linkType"],
    ) => void;
    context: "BODY" | "SUBJECT";
};

const OrganizationWorkflowEmailContextMenu: React.FC<Props> = observer(
    ({ children, setter, context }) => {
        const [state, setState] = React.useState<{
            mouseX: null | number;
            mouseY: null | number;
        }>(initialState);

        const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            setState({
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
            });
        };

        const handleClose = () => {
            setState(initialState);
        };

        const handleVariable = (e) => {
            const variable = e.target.dataset.type;
            setter(variable);
            handleClose();
        };

        const handleWorkflowLink = (e) => {
            const variable = e.target.dataset.type;
            setter(variable, true, "Workflow");
            handleClose();
        };

        const handleEvaluationLink = (e) => {
            const variable = e.target.dataset.type;
            setter(variable, true, "Evaluation");
            handleClose();
        };
        const emailStore = useStore(OrganizationWorkflowEmailStore);
        return (
            <div
                onContextMenu={handleClick}
                style={{ cursor: "context-menu", height: "100%" }}
            >
                {children}

                <Menu
                    keepMounted
                    open={state.mouseY !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                >
                    <MenuItem disabled>Insert Variables</MenuItem>
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem
                            data-type="EvaluationNumber"
                            onClick={handleVariable}
                        >
                            EvaluationNumber
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem
                            data-type="HierarchyName"
                            onClick={handleVariable}
                        >
                            HierarchyName
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem
                            data-type="AgentName"
                            onClick={handleVariable}
                        >
                            AgentName
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem data-type="Level" onClick={handleVariable}>
                            Level
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem
                            data-type="LevelName"
                            onClick={handleVariable}
                        >
                            LevelName
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem data-type="DueDate" onClick={handleVariable}>
                            DueDate
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem
                            data-type="QuestionText"
                            onClick={handleVariable}
                        >
                            QuestionText
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem data-type="Answer" onClick={handleVariable}>
                            Answer
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem data-type="Analyst" onClick={handleVariable}>
                            Analyst
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && (
                        <MenuItem
                            data-type="ScoreThreshold"
                            onClick={handleVariable}
                        >
                            ScoreThreshold
                        </MenuItem>
                    )}
                    {!emailStore.templateNameMatchCase && context === "BODY" && (
                        <MenuItem
                            data-type="EvalLinkUri"
                            onClick={handleEvaluationLink}
                        >
                            Link to Evaluation
                        </MenuItem>
                    )}

                    <MenuItem data-type="UserName" onClick={handleVariable}>
                        UserName
                    </MenuItem>

                    <MenuItem data-type="Cadence" onClick={handleVariable}>
                        Cadence
                    </MenuItem>

                    <MenuItem data-type="PendingCount" onClick={handleVariable}>
                        PendingCount
                    </MenuItem>
                    <MenuItem
                        data-type="WorkflowDefinition"
                        onClick={handleVariable}
                    >
                        WorkflowDefinition
                    </MenuItem>
                    {context === "BODY" && (
                        <MenuItem
                            data-type="WorkflowsLinkUri"
                            onClick={handleWorkflowLink}
                        >
                            WorkflowsLinkUri
                        </MenuItem>
                    )}
                </Menu>
            </div>
        );
    },
);

export default OrganizationWorkflowEmailContextMenu;
