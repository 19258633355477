import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AcxExpansion from "components/UI/AcxExpansion";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import React, { useCallback } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useLocation, useNavigate } from "react-router";
import useStyles from "Styles/Styles";
import theme, { ThemeColors } from "../../Theme/AppTheme";
import NavigationLink from "./NavigationLink";
import NavigationStore, { IItemOptions, INavigation } from "./NavigationStore";
import { observer } from "mobx-react";

const styles = (theme: Theme) =>
    createStyles({
        itemNavigationBody: {
            paddingRight: theme.spacing(0.5),
            paddingLeft: theme.spacing(1.75),
        },
        itemNavigationRoot: {
            paddingRight: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.5),
        },
        groupHeader: {
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: "bold",
            color: theme.palette.black.main,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        drawerHeader: {
            marginBottom: theme.spacing(2),
            paddingRight: theme.spacing(1.35),
            paddingLeft: theme.spacing(1.35),
        },
        orgSelect: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        searchField: {
            marginTop: theme.spacing(1),
        },
        searchIconColor: {
            color: ThemeColors.black,
            opacity: 0.25,
            marginRight: theme.spacing(1),
        },
        editorButtons: {
            textTransform: "none",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        root: {
            height: "100%",
            paddingTop: theme.spacing(1.75),
        },
    });

export default observer(function Navigation(props: {
    store: NavigationStore;
    itemOptions?: IItemOptions;
    controls?: React.ReactNode;
    isLoading?: boolean;
    itemsLoading?: boolean;
}) {
    const store = props.store;
    const classes = useStyles(styles);
    const navigate = useNavigate();
    const location = useLocation();

    const handleNavItemClick = useCallback(
        (item: INavigation) => {
            const u = item.link;
            store.currentNavItem = item;
            if (location.pathname !== u) {
                navigate(u);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname],
    );

    const navItems = store.itemGroups ? (
        store.itemGroups.map((val) => (
            <AcxExpansion
                expanded={val.expanded ?? false}
                headerClass={classes.groupHeader}
                header={val.header}
                key={`side-nav-${val.header}`}
                body={val.items.map((val, index) => (
                    <NavigationLink
                        key={`side-nav-item-${val.title}-${index}`}
                        val={val}
                        store={store}
                        handleNavItemClick={handleNavItemClick}
                        itemOptions={props.itemOptions}
                    />
                ))}
                bodyClass={classes.itemNavigationBody}
                rootClass={classes.itemNavigationRoot}
            />
        ))
    ) : (
        <div
            style={{
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            }}
        >
            {store.items?.map((val) => (
                <NavigationLink
                    key={`side-nav-item-${val.title}`}
                    val={val}
                    store={store}
                    handleNavItemClick={handleNavItemClick}
                    itemOptions={props.itemOptions}
                />
            ))}
        </div>
    );

    React.useEffect(() => {
        // finding matching link
        // console.log("looking for matching link");
        if (location.pathname !== store.currentNavItem?.link) {
            let item;
            if (store.itemGroups) {
                store.itemGroups?.forEach((value, index) => {
                    value.items.forEach((val, i) => {
                        if (val.link === location.pathname) {
                            item = val;
                        }
                    });
                });
            } else {
                store.items?.forEach((val, i) => {
                    if (val.link === location.pathname) {
                        item = val;
                    }
                });
            }

            if (item) {
                store.currentNavItem = item;
            } else {
                if (store.defaultRoute) {
                    navigate(store.defaultRoute?.link, { replace: true });
                } else {
                    store.currentNavItem = undefined;
                }
            }
        }
    }, [
        location.pathname,
        navigate,
        store,
        store.currentNavItem,
        store.defaultRoute,
        store.itemGroups,
        store.items,
    ]);

    return (
        <div className={classes.root}>
            {props.isLoading ? (
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={125}
                />
            ) : (
                <>
                    <Scrollbars autoHide>
                        {props.controls}

                        {props.itemsLoading ? (
                            <AcxLoadingIndicator
                                color="secondary"
                                alternate="PuffLoader"
                                size={60}
                            />
                        ) : (
                            navItems
                        )}
                    </Scrollbars>
                </>
            )}
        </div>
    );
});
