import { ButtonBase, Grid, Theme, useTheme } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import Color from "color";
import React, { useState } from "react";
import hexToRGB from "utils/hexToRGB";

const useTabsStyles = makeStyles((theme: Theme) => {
    return {
        indicator: {
            display: "none",
        },
        root: {
            width: "100%",
        },
    };
});

const useStyles = makeStyles((theme: Theme) => {
    return {
        actionButton: {
            padding: theme.spacing(1.25),
            marginRight: theme.spacing(1),
            backgroundColor: theme.palette.blue.main,
            color: theme.palette.white.main,
            fontWeight: "bold",
            fontFamily: "Inter",
            fontSize: "12px",
        },
        middle: {
            [theme.breakpoints.only("sm")]: {
                width: "99%",
            },
            [theme.breakpoints.only("md")]: {
                width: "96%",
            },
            [theme.breakpoints.only("lg")]: {
                width: "88%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "80%",
            },
        },
        gutterWidth: {
            [theme.breakpoints.only("sm")]: {
                width: "0.5%",
            },
            [theme.breakpoints.only("md")]: {
                width: "2%",
            },
            [theme.breakpoints.only("lg")]: {
                width: "6%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "10%",
            },
        },
    };
});

const StyledTab = styled(Tab)(({ theme }) => {
    const bgColor = Color(theme.palette.white.main).alpha(0.35).toString();
    const selectedBgColor = theme.palette.white.main;
    const minWidth = {
        md: 120,
    };

    return {
        opacity: 1,
        textTransform: "none",
        overflow: "initial",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderTopLeftRadius: theme.spacing(1),
        borderTopRightRadius: theme.spacing(1),
        color: theme.palette.black.main,
        backgroundColor: bgColor,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        transition: "0.2s",
        minHeight: "44px",
        lineHeight: "1.75",
        "& .MuiTab-root": {
            fontFamily: '"Inter", sans-serif',
            fontWeight: 500,
        },

        [theme.breakpoints.up("md")]: {
            minWidth: minWidth.md,
        },
        "&:before": {
            transition: "0.2s",
        },
        "&:not(:first-of-type)": {
            "&:before": {
                content: '" "',
                position: "absolute",
                left: 0,
                display: "block",
                height: 0,
                width: 1,
                zIndex: 1,
                marginTop: theme.spacing(0.5),
                backgroundColor: theme.palette.grey[500],
            },
        },
        "& + $selected:before": {
            opacity: 0,
        },
        "&:hover": {
            "&:not($selected)": {
                backgroundColor: Color(bgColor).whiten(0.6).hex(),
            },
            "&::before": {
                opacity: 0,
            },
            "& + $root:before": {
                opacity: 0,
            },
        },
        "& .MuiTab-root.Mui-selected": {
            backgroundColor: selectedBgColor,
            color: theme.palette.black.main,
            "& + $root": {
                zIndex: 1,
            },
            "& + $root:before": {
                opacity: 0,
            },
        },
        "&.Mui-selected": {
            color: theme.palette.black.main,
            backgroundColor: selectedBgColor,
        },
    };
});

export type ChromeTab = {
    label:
        | string
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    icon?:
        | string
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | undefined;
    disabled?: boolean;
    route?: string;
};

interface IChromeTabs {
    tabs: ChromeTab[];
    tabStyle?: { bgColor: string; minWidth: {} };
    onChange: (newValue: number) => void;
    activeTabIndex: number;
    justifyContent?:
        | "flex-start"
        | "center"
        | "flex-end"
        | "space-between"
        | "space-around"
        | "space-evenly";
    alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
    noGutter?: boolean;
    actionLabel?: string;
    onActionClick?: () => void;
}

const ChromeTabs = (props: IChromeTabs) => {
    const [value, setValue] = useState(
        props.activeTabIndex > -1 ? props.activeTabIndex : 0,
    );
    const [tabs, setTabs] = useState([] as ChromeTab[]);
    const theme = useTheme();
    const tabsClasses = useTabsStyles(theme);
    const classes = useStyles(theme);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        props.onChange(newValue);
    };

    React.useEffect(() => {
        setValue(props.activeTabIndex);
    }, [props.activeTabIndex]);

    React.useEffect(() => {
        if (props.tabs.length > 0) {
            if (props.tabs.length - 1 < value) {
                setValue(props.tabs.length - 1);
            }
        }
        setTabs(props.tabs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tabs]);
    return (
        <Grid
            container
            flexWrap="nowrap"
            style={{
                display: "flex",
                height: "48px",
                backgroundColor: hexToRGB(theme.palette.primary.main, 0.1),
                width: "100%",
            }}
        >
            <Grid
                item
                className={classes.gutterWidth}
                style={{ width: props.noGutter ? "0%" : "" }}
            />
            <Grid
                container
                item
                flexWrap="nowrap"
                justifyContent="space-between"
                flexGrow={1}
            >
                <Grid
                    item
                    sx={{
                        justifyContent: props.justifyContent ?? "flex-start",
                        alignItems: props.alignItems ?? "stretch",
                        minWidth: "fit-content",
                    }}
                >
                    {tabs?.length > 0 && (
                        <Tabs
                            sx={{
                                display: "inline-grid",
                                alignContent: "flex-end",
                                alignSelf: "flex-end",
                            }}
                            value={value}
                            onChange={handleChange}
                            classes={tabsClasses}
                        >
                            {tabs.map((tab) => (
                                <StyledTab
                                    label={tab.label}
                                    icon={tab.icon}
                                    key={tab.label.toString()}
                                    disabled={tab.disabled}
                                />
                            ))}
                        </Tabs>
                    )}
                </Grid>
                {props.actionLabel && (
                    <Grid
                        container
                        item
                        // justifyContent={"flex-end"}
                        alignItems={"center"}
                        sx={{ width: "fit-content" }}
                    >
                        <Grid item>
                            <ButtonBase
                                className={classes.actionButton}
                                onClick={props.onActionClick}
                                sx={(theme) => ({
                                    padding: theme.spacing(1.25),
                                    marginRight: theme.spacing(1),
                                    backgroundColor: theme.palette.blue.main,
                                    color: theme.palette.white.main,
                                    fontWeight: "bold",
                                    fontFamily: "Inter",
                                    fontSize: "12px",
                                })}
                            >
                                {props.actionLabel}
                            </ButtonBase>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

ChromeTabs.defaultProps = {
    tabs: [],
    tabStyle: {},
    tabProps: {},
    activeTabIndex: 0,
};

export default ChromeTabs;
