import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useStyles from "Styles/Styles";
import clsx from "clsx";
import { Theme } from "@mui/material/styles";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
    tabs: {
        displayName: string;
        typeName: string;
        hasDisputedAnswers: boolean;
    }[];
    activeTab?: string; // active tab is a TypeName NOT a DisplayName
    onClick: (typeName: string) => void;
}

const useTabStyles = makeStyles({
    root: {
        justifyContent: "center",
    },
    scroller: {
        flexGrow: 0,
    },
});

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            width: "100%",
            backgroundColor: theme.palette.background.paper,
            justifyContent: "center",
        },
        tab: {
            minWidth: "min-content",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "0.85rem",
            paddingRight: "20px",
        },
        bullet: {
            "&::after": {
                content: '"•"',
                display: "block",
                position: "absolute",
                right: "0",
                color: "gray",
            },
        },
    });

const AcxScrollingTabs = (props: Props) => {
    const classes = useStyles(styles);
    const tabClasses = useTabStyles();
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (props.activeTab) {
            setValue(
                props.tabs.findIndex((tab) => tab.typeName === props.activeTab),
            );
        }
    }, [props.activeTab, props.tabs]);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Tabs
            classes={{ root: tabClasses.root, scroller: tabClasses.scroller }}
            value={value}
            onChange={handleChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
                style: {
                    display: "none",
                },
            }}
        >
            {props.tabs.map((tab, index) => (
                <Tab
                    key={`${tab.displayName}_${index}`}
                    id={`${tab.displayName}_${index}`}
                    wrapped
                    label={tab.displayName}
                    onClick={(event) => props.onClick(tab.typeName)}
                    className={clsx(
                        classes.tab,
                        props.tabs[index + 1] && classes.bullet,
                    )}
                    style={{ color: tab.hasDisputedAnswers ? "red" : "" }}
                />
            ))}
        </Tabs>
    );
};

export default AcxScrollingTabs;
