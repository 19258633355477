import BaseEntity from "./BaseEntity";

export default abstract class CrudEntity extends BaseEntity {
    static apiUrl: string;

    abstract create(): Promise<Response>;
    abstract update(): Promise<Response>;
    abstract delete(): Promise<Response>;

    static async read<T extends BaseEntity>(): Promise<T> {
        throw new Error("Not Implemented");
    }
    static async list<T extends BaseEntity>(): Promise<T[]> {
        throw new Error("Not Implemented");
    }
}
