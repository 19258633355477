import {
    CssBaseline,
    Grid,
    StyledEngineProvider,
    ThemeProvider,
    Typography,
} from "@mui/material";
import AppTheme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import React, { useEffect } from "react";
import { AuthStatus, AuthStore } from "stores/AuthStore";
import LocalStorage from "stores/LocalStorage";
import RootStore from "stores/RootStore";
import { useStore } from "utils/useStore";
import AuthPage from "./AuthPage";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";

function Consent() {
    const authStore = useStore(AuthStore);

    const [declined, setDeclined] = React.useState(false);
    const [authroizationUri, setAuthorizationURI] = React.useState("");

    const local = RootStore().getStore(LocalStorage);
    const connectLocal = local.getLocalStore("Connect");

    useEffect(() => {
        async function setAndResetUri() {
            const authorization_uri = (await connectLocal.getItem(
                "authorizationURI",
            )) as string;
            setAuthorizationURI(authorization_uri);
            await connectLocal.clear();
        }

        setAndResetUri();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function accept() {
        const redirect_uri = `${window.location.origin}${ApplicationPaths.SaveConnection}`;
        const client_id = process.env.FiveNineOptions__ClientId;
        const state = JSON.stringify({
            redirect_uri: redirect_uri,
        });

        window.location.replace(
            `${authroizationUri}?redirect_uri=${redirect_uri}&client_id=${client_id}&response_type=code&state=${state}&scope=offline_access&grant_type=authorization_code`,
        );
    }

    function defaultDisplay() {
        return (
            <>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignContent="center"
                    direction="column"
                >
                    <Typography>
                        Please close this page and try again.
                    </Typography>
                </Grid>
            </>
        );
    }

    function displayOptions() {
        const status: AuthStatus = authStore.status;
        const authenticated: boolean = authStore._isAuthenticated;

        if (
            !authenticated ||
            status !== AuthStatus.Initialized ||
            !authroizationUri
        ) {
            return defaultDisplay();
        } else if (!authStore.canUserViewAdminApp()) {
            return (
                <>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        direction="column"
                    >
                        <Typography>You do not have permission.</Typography>
                    </Grid>
                </>
            );
        } else if (!declined) {
            return (
                <>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        direction="column"
                    >
                        <Typography>
                            Five9 is requesting access to your Authenticx
                            Account
                        </Typography>

                        <AcxButton
                            fullWidth
                            style={{
                                margin: "0.75rem 0",
                                height: "2.25rem",
                            }}
                            onClick={accept}
                            color="primary"
                            loading={false}
                        >
                            Accept
                        </AcxButton>
                        <AcxButton
                            fullWidth
                            variant="outlined"
                            style={{
                                margin: "0.75rem 0",
                                height: "2.25rem",
                                color: "#000000",
                            }}
                            onClick={() => setDeclined(true)}
                            loading={false}
                            color="inherit"
                        >
                            Decline
                        </AcxButton>
                    </Grid>
                </>
            );
        } else if (declined) {
            return (
                <>
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                    >
                        <Typography>
                            Access has been declined. Please close this page.
                        </Typography>
                    </Grid>
                </>
            );
        } else {
            return defaultDisplay();
        }
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
                <AuthPage isLoading={false} title="Authorize App">
                    <CssBaseline />
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{ height: "100%" }}
                        direction="row"
                    >
                        {displayOptions()}
                    </Grid>
                </AuthPage>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default Consent;
