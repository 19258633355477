import {
    Fade,
    Grid,
    Grow,
    Paper,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import FilterListIcon from "@mui/icons-material/FilterList";
import LayersIcon from "@mui/icons-material/Layers";
import PlaylistAddSharpIcon from "@mui/icons-material/PlaylistAddSharp";
import SortIcon from "@mui/icons-material/Sort";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import clsx from "clsx";
import { Observer } from "mobx-react";
import Classifier from "models/ClassifierModel";
import React from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import useStyles from "Styles/Styles";
import { CombinatorRuleType } from "../../../../../../models/RuleCombinator";
import hexToRGB from "../../../../../../utils/hexToRGB";
import { RuleBuildStore } from "../../RuleBuildStore";
import { OnDropParams, RuleTypes } from "../MainRuleBuilder";
import { IAcxClassifierRuleItem } from "./AcxClassifierRuleItem";

// "rgba(51,120,225,0.27)"
const styles = (theme: Theme) =>
    createStyles({
        droppable: {
            border: "1px dotted",
            minHeight: "100px",
            textAlign: "center",
            verticalAlign: "center",
            backgroundColor: theme.palette.white.main,
        },
        sticky: {
            position: "sticky",
            top: 0,
            zIndex: 2,
        },

        dropHover: {
            backgroundColor: hexToRGB(theme.palette.secondary.main, 0.15),
        },
        dropTarget: {
            width: "100%",
            height: "285px",
            overflow: "auto",
            overscrollBehavior: "contain",
        },
        emptyDropTarget: {
            flexGrow: 1,
            height: "calc(100% - 55px)",
        },
        notesIcon: {
            width: "64px",
            height: "64px",
        },
        emptyTargetText: {
            height: "48px",
            maxWidth: "319px",
            opacity: 0.5,
            fontWeight: "bold",
            color: "rgb(31,31,31)",
            fontFamily: theme.typography.fontFamily,
            fontSize: "16px",
            letterSpacing: 0,
            lineHeight: "24px",
            textAlign: "center",
        },
        headerExpanded: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            fontWeight: "bold",
        },
        header: {
            paddingLeft: "8px",
            height: "56px",
            borderRadius: theme.spacing(0.5),
            boxShadow:
                "0 0 3px 0 rgba(0,0,0,0.05), 0 1px 1px 0 rgba(0,0,0,0.05)",
        },
        dropHoverText: {
            opacity: 0.5,
            color: theme.palette.purple.main,
        },

        root: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            userSelect: "none",

            opacity: 0.35,
            backgroundColor: theme.palette.background.default,
            color: theme.palette.black.contrastText,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        selected: {
            "&$selected": {
                // increase the specificity for the pseudo class
                //"#4DB6AC"
                // color: "#4DB6AC"
                opacity: 1,
                backgroundColor: theme.palette.white.main,
                color: theme.palette.success.main,
            },
        },
    });

interface IAcxRuleDropTarget {
    store: RuleBuildStore;
    title: RuleTypes;
    enableDnd?: true;
    itemModel?: Classifier[];
    onDrop?: (item: OnDropParams, monitor: DropTargetMonitor) => void;
    onRemove?: (item: IAcxClassifierRuleItem) => void;
    accept: symbol[];
}

const AcxRuleDropTarget = (props: IAcxRuleDropTarget) => {
    const classes = useStyles(styles);

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: props.accept,
        drop: (item, monitor) =>
            props.onDrop?.(
                { ...item, ruleType: props.title } as OnDropParams,
                monitor,
            ),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const isActive = isOver && canDrop;

    return (
        <Observer>
            {() => (
                <Paper elevation={1} ref={drop}>
                    <div
                        className={clsx(
                            classes.dropTarget,
                            isActive && classes.dropHover,
                        )}
                    >
                        <div
                            className={clsx(classes.sticky)}
                            style={{ height: "48px", marginBottom: "5px" }}
                        >
                            <Fade
                                in={
                                    props.store.ruleListForType(props.title)
                                        .length > 0
                                }
                                timeout={600}
                            >
                                <Grid
                                    container
                                    wrap="nowrap"
                                    alignItems="center"
                                    className={clsx(
                                        classes.header,
                                        classes.headerExpanded,
                                    )}
                                >
                                    <Grid item>
                                        <HeaderIconForRuleType
                                            ruleType={props.title}
                                        />
                                    </Grid>

                                    <Grid
                                        style={{
                                            marginLeft: "24px",
                                            userSelect: "none",
                                        }}
                                        item
                                    >
                                        {props.title ?? ""} Rules
                                    </Grid>

                                    <Grid item xs></Grid>

                                    <Grid item>
                                        {props.store.ruleListForType(
                                            props.title,
                                        ).length > 1 &&
                                            props.title !== "Stratify" && (
                                                <Grow in={true} timeout={600}>
                                                    <ToggleButtonGroup
                                                        style={{
                                                            marginRight: "8px",
                                                        }}
                                                        value={props.store.getCombinatorForRuleType(
                                                            props.title,
                                                        )}
                                                        exclusive
                                                        onChange={(
                                                            event,
                                                            value,
                                                        ) => {
                                                            if (
                                                                props.title !==
                                                                "Ranking"
                                                            ) {
                                                                props.store.setCombinatorForRuleType(
                                                                    props.title,
                                                                    value,
                                                                );
                                                            }
                                                        }}
                                                        aria-label="rule combinator"
                                                    >
                                                        <ToggleButton
                                                            classes={{
                                                                selected:
                                                                    classes.selected,
                                                                root: classes.root,
                                                            }}
                                                            style={{
                                                                height: "32px",
                                                            }}
                                                            value={
                                                                CombinatorRuleType.And
                                                            }
                                                            aria-label="and combinator"
                                                        >
                                                            AND
                                                        </ToggleButton>
                                                        {props.title !==
                                                            "Ranking" && (
                                                            <ToggleButton
                                                                classes={{
                                                                    selected:
                                                                        classes.selected,
                                                                    root: classes.root,
                                                                }}
                                                                style={{
                                                                    height: "32px",
                                                                }}
                                                                value={
                                                                    CombinatorRuleType.Or
                                                                }
                                                                aria-label="or combinator"
                                                            >
                                                                OR
                                                            </ToggleButton>
                                                        )}
                                                    </ToggleButtonGroup>
                                                </Grow>
                                            )}
                                    </Grid>
                                </Grid>
                            </Fade>
                        </div>

                        {props.store.ruleListForType(props.title).length > 0 ? (
                            props.store
                                .ruleListForType(props.title)
                                .map((val, idx) =>
                                    props.store.ruleContentForType(
                                        val,
                                        props.title,
                                    ),
                                )
                        ) : (
                            <EmptyDropTarget
                                isActive={isActive}
                                title={props.title}
                            />
                        )}
                    </div>
                </Paper>
            )}
        </Observer>
    );
};

const HeaderIconForRuleType = ({ ruleType }: { ruleType: RuleTypes }) => {
    switch (ruleType) {
        case "Stratify":
            return <LayersIcon style={{ color: "white" }} />;

        case "Ranking":
            return <SortIcon style={{ color: "white" }} />;

        case "Filter":
            return <FilterListIcon style={{ color: "white" }} />;
        default:
            throw new Error(`Unknown rule type ${ruleType}`);
    }
};

const EmptyDropTarget = (props: { title: string; isActive: boolean }) => {
    const classes = useStyles(styles);
    const theme = useTheme();
    return (
        <>
            <Grow in={true} timeout={600}>
                <Grid
                    className={classes.emptyDropTarget}
                    container
                    justifyContent="center"
                    alignItems={"center"}
                    direction={"column"}
                >
                    <Grid item xs={2}>
                        <PlaylistAddSharpIcon
                            className={clsx(
                                classes.notesIcon,
                                props.isActive && classes.dropHoverText,
                            )}
                            color={"disabled"}
                            fontSize={"large"}
                        />
                    </Grid>

                    <Grid item>
                        <Typography
                            style={{ userSelect: "none" }}
                            className={clsx(
                                classes.emptyTargetText,
                                props.isActive && classes.dropHoverText,
                            )}
                        >
                            Drag classifiers here to build{" "}
                            <span
                                style={{
                                    color: props.isActive
                                        ? theme.palette.info.main
                                        : theme.palette.info.dark,
                                }}
                            >
                                {props.title}
                            </span>{" "}
                            rules
                        </Typography>
                    </Grid>
                </Grid>
            </Grow>
        </>
    );
};

export default AcxRuleDropTarget;
