import {
    Button,
    Grid,
    IconButton,
    Slide,
    SlideProps,
    Snackbar,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Alert } from "@mui/material";
import { Severity } from "components/ManagerInteractions/Stores/MessageStore";
import React, { FunctionComponent } from "react";

interface OwnProps {
    open?: boolean;
    message: string;
    autoHideDuration?: number;
    onClose: () => void;
    actions?: React.ReactNode;
    severity?: Severity;
    options?: {
        buttonText: { approve: string; deny: string };
        buttonAction: {
            approve: () => void | Promise<void>;
            deny: () => void | Promise<void>;
        };
        timeoutOverride?: number;
    };
}

type Props = OwnProps;

function SlideTransition(props: SlideProps) {
    return <Slide {...props} timeout={500} direction="down" />;
}

const AcxSnackBar: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = (props) => {
    return (
        <Snackbar
            style={{ zIndex: 99999999999 }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={Boolean(props.open)}
            autoHideDuration={props.autoHideDuration ?? 1 * 60 * 1000} // 1 Minute
            onClose={props.onClose}
            TransitionComponent={SlideTransition}
        >
            <Alert
                severity={props.severity}
                action={
                    <React.Fragment>
                        {Boolean(props.actions) && props.actions}
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={props.onClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            >
                <Typography style={{ paddingLeft: "8px" }} variant={"body1"}>
                    {props.message}
                </Typography>

                {props.options && (
                    <Grid
                        container
                        item
                        xs={12}
                        alignContent="center"
                        alignItems="center"
                    >
                        <Button
                            style={{
                                textTransform: "none",
                            }}
                            variant="text"
                            color="secondary"
                            onClick={props.options?.buttonAction.approve}
                        >
                            {props.options?.buttonText.approve}
                        </Button>
                        <Button
                            style={{
                                textTransform: "none",
                            }}
                            variant="text"
                            color="secondary"
                            onClick={props.options?.buttonAction.deny}
                        >
                            {props.options?.buttonText.deny}
                        </Button>
                    </Grid>
                )}
            </Alert>
        </Snackbar>
    );
};

AcxSnackBar.defaultProps = {
    severity: "info",
};

export default AcxSnackBar;
