import { Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import Topic from "models/Topic";

type Props = {
    Topic: Topic,
    OpenTopic: (topic: Topic) => void
};

const styles = () => {
    return createStyles({
        Card: {
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            paddingLeft: "2%",
            paddingRight: "2%",
            marginBottom: "24px",
            cursor: "pointer",
            transition: "transform .2s",
            "&:hover": {
                boxShadow: "2px 2px 6px 4px rgba(53,100,213,.3), -2px -2px 6px 4px rgba(58,210,185,.3)",
                transform: "scale(1.01s5)",
            },
        },
        Title: {
            paddingTop: "16px",
            paddingBottom: "8px",
            fontSize: "22px",
        },
        WordsList: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            paddingBottom: "16px",
        },
        Word: {
            background: "#dbdbdb",
            marginLeft: "2px",
            marginRight: "2px",
            marginBottom: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontSize: "12px",
        },
    });
};


const TopicPreviewCard: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer(
    ({Topic, OpenTopic}) => {

        const classes = useStyles(styles);

        const wordLabels = Topic.words.map((word) => 
            <label key={word} className={classes.Word} >{word}</label>
        );

        return <>
            <div className={classes.Card} onClick={() => OpenTopic(Topic)}>
                 <Typography className={classes.Title} variant="subtitle1">
                    {Topic.userAssignedLabel ?? `Topic ${Topic.topicNumber ?? ''}: ${Topic.words[0]}, ${Topic.words[1]}, ${Topic.words[2]}`}
                    </Typography>
                 <div className={classes.WordsList} >
                    {wordLabels}
                 </div>
            </div>
        </>
    }
)

export default TopicPreviewCard;