import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Grid, Paper, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AcxButton from "components/UI/AcxButton";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxRecursiveTree from "components/UI/AcxRecursiveTree/AcxRecursiveTree";
import {
    BranchDataProps,
    HandleBranchProps,
} from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import RecursiveTreeStore from "components/UI/AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import {
    OrganizationUserStore,
    UPDATE_ALL,
    UPDATE_CHILDREN,
} from "../stores/OrganizationUserStore";
import HierarchyAssignmentTier from "./Components/HierarchyAssignmentTier";
import HierarchyAssignmentToggles from "./Components/HierarchyAssignmentToggles";
import {
    HierarchyPermissions,
    HierarchyTypes,
    IHierarchyAssignmentTier,
} from "./Components/Types";
import {
    PERMISSION_ICONS,
    permissionToggleEnabled,
    TYPE_ICONS,
    typeToggleEnabled,
} from "./Components/Utilities";

const styles = (theme: Theme) => {
    return createStyles({
        formContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
        contentContainer: {
            margin: "0.2rem 0",
        },
        legendContainer: {
            padding: "0.5rem 0",
        },
        legendText: {
            padding: "0 0.25rem",
        },
        heading: {
            padding: "0.25rem 0.5rem",
            textAlign: "center",
        },
        paperLegendContainer: {
            padding: "0.75rem",
            width: "100%",
        },
        legendCaption: {
            fontFamily: "Inter",
            fontStyle: "italic",
        },
        dialogCaption: {
            fontFamily: "Inter",
            fontStyle: "italic",
            paddingTop: "1.5rem",
        },
        mainTitle: {
            marginLeft: "0.5rem",
        },
    });
};

interface IHierarchyAssignmentProps {
    orgId: string;
    userId: string;
}

const HierarchyAssignments: React.FC<IHierarchyAssignmentProps> = observer(
    ({ orgId, userId }) => {
        const classes = useStyles(styles);
        const userStore = useStore(OrganizationUserStore);
        const authStore = useStore(AuthStore);
        const permStore = authStore.permStore;

        const [recursiveTree, setRecursiveTree] = React.useState<any>();
        const [showInactive, setShowInactive] = React.useState<boolean>(false);

        const hasAdminPerms = authStore.canUserEdit(
            "Assign Organization Structure Members To Users",
        );

        // This is the onChange used to handle the toggles in our Confirmation Dialog
        const onChangeHandler = (event: any, checked: boolean) => {
            if (userStore.activeTier) {
                const value: string = event.target.value;

                if (value) {
                    // "master" is the Toggle All switch
                    if (value === "master") {
                        userStore.activeTier.types = [];
                        userStore.activeTier.permissions = [];

                        if (checked) {
                            const typesArr: number[] = [];
                            const permsArr: number[] = [];

                            for (
                                let i = 0;
                                i < userStore.hierarchyTypes.length;
                                i++
                            ) {
                                typesArr.push(i);
                            }

                            for (
                                let i = 0;
                                i < userStore.hierarchyPermissions.length;
                                i++
                            ) {
                                permsArr.push(i);
                            }

                            userStore.setTierPerms(permsArr, typesArr);
                        }
                    } else if (value in HierarchyPermissions) {
                        if (checked) {
                            userStore.setTierPerms(value);
                        } else {
                            userStore.setTierPerms(
                                userStore.activeTier.permissions?.filter(
                                    (perm) =>
                                        perm !== HierarchyPermissions[value],
                                ),
                            );

                            if (value === "View") {
                                // Clear domain access
                                userStore.setTierPerms(undefined, []);

                                // Remove Edit & Manage rights
                                userStore.setTierPerms(
                                    userStore.activeTier.permissions?.filter(
                                        (perm) =>
                                            perm !==
                                                HierarchyPermissions["Edit"] &&
                                            perm !==
                                                HierarchyPermissions["Manage"],
                                    ),
                                );
                            }

                            if (
                                value === "Edit" &&
                                userStore.activeTier.permissions &&
                                userStore.activeTier.permissions.includes(
                                    HierarchyPermissions["Manage"],
                                )
                            ) {
                                // Remove Manage rights
                                userStore.setTierPerms(
                                    userStore.activeTier.permissions?.filter(
                                        (perm) =>
                                            perm !==
                                            HierarchyPermissions["Manage"],
                                    ),
                                );
                            }
                        }
                    } else if (value in HierarchyTypes) {
                        if (checked) {
                            userStore.setTierPerms(undefined, value);
                        } else {
                            userStore.setTierPerms(
                                undefined,
                                userStore.activeTier.types?.filter(
                                    (type) => type !== HierarchyTypes[value],
                                ),
                            );
                        }
                    }
                }
            }
        };

        React.useEffect(() => {
            if (!orgId) {
                return;
            }

            if (userId) {
                userStore.setActiveUserId(userId);
            }

            userStore.hierarchyPermissions = (
                Object.values(HierarchyPermissions) as Array<
                    keyof typeof HierarchyPermissions
                >
            ).filter((hp) => typeof hp === "string");

            userStore.hierarchyTypes = (
                Object.values(HierarchyTypes) as Array<
                    keyof typeof HierarchyTypes
                >
            ).filter((ht) => typeof ht === "string");

            const treeStore: RecursiveTreeStore = new RecursiveTreeStore({
                getBranch: async (orgId, parentId) => {
                    const res = (await userStore.getOsmAssignmentTier(
                        orgId,
                        parentId,
                        showInactive,
                    )) as any;
                    return { children: res, rowCount: 0 };
                },
                updateBranch: userStore.updateOsmAssignmentTier as any,
                createBranch: undefined,
                deleteBranch: undefined,
            });

            userStore.treeStore = treeStore;
            userStore.setOrgId(orgId);

            treeStore.setTopLevelDataKey("tiers");
            treeStore.setOrganizationId(orgId);

            const topLevelHierarchies =
                userStore.getTopLevelServiceHierarchiesForOrganization(
                    userId,
                    orgId,
                    showInactive,
                );

            treeStore.getTopLevelBranches(topLevelHierarchies);

            const branchChildrenContent = (
                currentBranch: BranchDataProps,
                handleBranch: HandleBranchProps,
            ) => {
                return (
                    <HierarchyAssignmentTier
                        branch={currentBranch}
                        handleTier={handleBranch}
                        store={userStore}
                        authStore={authStore}
                    />
                );
            };

            treeStore.setBranchContent(branchChildrenContent);

            setRecursiveTree(<AcxRecursiveTree store={treeStore} />);
        }, [orgId, permStore, authStore, userId, userStore, showInactive]);

        const handleDialogOpen = () => {
            userStore.setActiveTier({} as IHierarchyAssignmentTier);
            userStore.setAllDialogIsOpen(true);
        };

        return (
            <>
                <AcxConfirmationDialog
                    isOpen={userStore.propagateDialogIsOpen}
                    onClose={() => userStore.setPropagateDialogIsOpen(false)}
                    onConfirm={() =>
                        userStore.updateAllTiersOfParent(
                            orgId,
                            userId,
                            showInactive,
                        )
                    }
                    isLoading={userStore.getTaskLoading(UPDATE_CHILDREN)}
                    buttonsDisabled={!hasAdminPerms}
                    spinnerSize={50}
                    alternateSpinnerType="PuffLoader"
                    confirmButtonText="Confirm"
                    title={`Propagate Assignments - ${
                        userStore.activeTier?.name ?? "Overview"
                    }`}
                    subTitle={`Changes made will affect all descendent tiers of ${
                        userStore.activeTier?.name ?? "your selected tier"
                    }.`}
                    content={
                        <>
                            <HierarchyAssignmentToggles
                                onChangeHandler={onChangeHandler}
                                permissionValidator={permissionToggleEnabled}
                                typeValidator={typeToggleEnabled}
                                branch={
                                    userStore.activeTier ??
                                    ({} as IHierarchyAssignmentTier)
                                }
                                store={userStore}
                                hasAdminPerms={hasAdminPerms}
                                fullSize
                            />

                            <Grid item className={classes.dialogCaption}>
                                <Typography variant="caption">
                                    The toggles displayed above do not reflect
                                    the active state of any potential child
                                    tiers. Hierarchy assignments will be
                                    <strong> reloaded on confirm.</strong>
                                </Typography>
                            </Grid>
                        </>
                    }
                />

                <AcxConfirmationDialog
                    isOpen={userStore.updateAllDialogIsOpen}
                    onClose={() => userStore.setAllDialogIsOpen(false)}
                    onConfirm={() =>
                        userStore.updateAllTiers(
                            orgId,
                            userId,
                            userStore.topLevelHierarchies?.tiers.map(
                                (x) => x.id,
                            ) ?? [],
                            userStore.activeTier?.permissions,
                            userStore.activeTier?.types,
                            showInactive,
                        )
                    }
                    isLoading={userStore.getTaskLoading(UPDATE_ALL)}
                    buttonsDisabled={!hasAdminPerms}
                    spinnerSize={50}
                    alternateSpinnerType="PuffLoader"
                    confirmButtonText="Confirm"
                    title={"Update All Assignments"}
                    subTitle={"Changes made will affect all tiers"}
                    content={
                        <>
                            <HierarchyAssignmentToggles
                                onChangeHandler={onChangeHandler}
                                permissionValidator={permissionToggleEnabled}
                                typeValidator={typeToggleEnabled}
                                branch={
                                    userStore.activeTier ??
                                    ({} as IHierarchyAssignmentTier)
                                }
                                store={userStore}
                                hasAdminPerms={hasAdminPerms}
                                fullSize
                            />

                            <Grid item className={classes.dialogCaption}>
                                <Typography variant="caption">
                                    The toggles displayed above do not reflect
                                    the active state of any potential tiers.
                                    Hierarchy assignments will be
                                    <strong> reloaded on confirm.</strong>
                                </Typography>
                            </Grid>
                        </>
                    }
                />

                <Paper elevation={1} className={classes.formContainer}>
                    <Grid container item xs={12} justifyContent="space-between">
                        <Grid container item xs={6}>
                            <Typography
                                className={classes.mainTitle}
                                variant="h5"
                            >
                                Hierarchy Assignments
                            </Typography>
                        </Grid>
                        <Grid container item xs={3}>
                            <AcxButton
                                fullWidth
                                onClick={handleDialogOpen}
                                color="primary"
                            >
                                Update All
                            </AcxButton>
                        </Grid>
                        <Grid container item xs={3}>
                            <AcxButton
                                fullWidth
                                onClick={() => setShowInactive((prev) => !prev)}
                                color="primary"
                            >
                                {showInactive
                                    ? "Hide Inactive"
                                    : "Show Inactive"}
                            </AcxButton>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-end"
                        className={classes.legendContainer}
                    >
                        <Paper
                            elevation={1}
                            className={classes.paperLegendContainer}
                        >
                            <Grid container item xs={12}>
                                <Typography
                                    variant="caption"
                                    color="initial"
                                    className={classes.legendCaption}
                                >
                                    The icon to the left of each label is a
                                    helpful guide which we use to identify the
                                    functionality of each toggle below.
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                item
                                justifyContent="flex-start"
                                className={classes.contentContainer}
                            >
                                <Grid item className={classes.heading}>
                                    <Grid container alignItems="center">
                                        <AllInclusiveIcon
                                            fontSize="small"
                                            color="secondary"
                                        />

                                        <Typography
                                            color="primary"
                                            className={classes.legendText}
                                        >
                                            Toggle Row
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {userStore.hierarchyPermissions?.map(
                                    (permission) => (
                                        <Grid
                                            key={permission}
                                            item
                                            className={classes.heading}
                                        >
                                            <Grid container alignItems="center">
                                                {PERMISSION_ICONS[permission]}

                                                <Typography
                                                    color="primary"
                                                    className={
                                                        classes.legendText
                                                    }
                                                >
                                                    {permission}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    ),
                                )}

                                {userStore.hierarchyTypes?.map((type) => (
                                    <Grid
                                        key={type}
                                        item
                                        className={classes.heading}
                                    >
                                        <Grid container alignItems="center">
                                            {TYPE_ICONS[type]}

                                            <Typography
                                                color="primary"
                                                className={classes.legendText}
                                            >
                                                {type}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>

                    {recursiveTree}
                </Paper>
            </>
        );
    },
);

export default HierarchyAssignments;
