import { Grid, Theme, Typography, useTheme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { Observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "../../../../../../Styles/Styles";
import ConfirmationDialog from "../../../../../UI/AcxConfirmationDialog";
import AcxMainTextField from "../../../../../UI/AcxMainTextField";
import { RuleBuildStore } from "../../RuleBuildStore";

const styles = (theme: Theme) =>
    createStyles({
        mainContent: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },

        drawerItem: {
            width: "100%",
            paddingLeft: "24px",
            paddingRight: "24px",
        },

        drawer: {
            width: "280px",
            flexShrink: 0,
        },
        infoText: {
            color: theme.palette.info.main,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        drawerHeader: {
            color: theme.palette.info.main,
        },
        drawerSection: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        drawerContainer: {
            overflow: "auto",
        },
    });

interface OwnProps {
    store: RuleBuildStore;
}

type Props = OwnProps;

const RuleSetSaveDialog = (props: Props) => {
    const classes = useStyles(styles);
    const navigate = useNavigate();
    const theme = useTheme();

    const contentPreSave = (
        <Observer>
            {() => (
                <Grid
                    container
                    alignItems={"stretch"}
                    justifyContent={"center"}
                    direction={"column"}
                >
                    <Grid item style={{ paddingBottom: "16px" }}>
                        <Typography
                            variant={"body1"}
                            style={{ color: theme.palette.text.secondary }}
                        >
                            {props.store.isActiveRuleSetNew
                                ? "Choose a name for your ruleset unique to your organization"
                                : ""}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <AcxMainTextField
                            id={"ruleset-name-field-id"}
                            autofocus
                            onChange={(evt) =>
                                props.store.setRulesetName(evt.target.value)
                            }
                            labelText={"RuleSet Name"}
                            value={props.store.activeRuleSet.name}
                        />
                    </Grid>
                </Grid>
            )}
        </Observer>
    );

    const contentPostSave = (
        <Observer>
            {() => (
                <Typography>
                    <span className={classes.infoText}>
                        {props.store.activeRuleSet.name}
                    </span>{" "}
                    ruleset saved successfully. You can continue building rules
                    or return to Smart Sampler
                </Typography>
            )}
        </Observer>
    );

    return (
        <Observer>
            {() => (
                <ConfirmationDialog
                    isOpen={props.store.showFinishDialog}
                    onClose={() => {
                        props.store.setShowFinishDialog(false);
                        if (props.store.saveToServerFinished) {
                            props.store.partiallyResetStore();
                        }
                    }}
                    title={
                        props.store.saveToServerFinished
                            ? "Are You Finished Building RuleSets?"
                            : props.store.isActiveRuleSetNew
                            ? "Save New RuleSet"
                            : `Update Existing RuleSet`
                    }
                    content={
                        props.store.saveToServerFinished
                            ? contentPostSave
                            : contentPreSave
                    }
                    isLoading={props.store.anyTaskLoading}
                    buttonsDisabled={props.store.anyTaskLoading}
                    cancelButtonText={
                        props.store.saveToServerFinished
                            ? "Build More Rules"
                            : "Cancel"
                    }
                    confirmButtonText={
                        props.store.saveToServerFinished
                            ? "Return To Sampler"
                            : props.store.isActiveRuleSetNew
                            ? "Save"
                            : "Update"
                    }
                    onConfirm={() => {
                        if (props.store.saveToServerFinished) {
                            props.store.setShowFinishDialog(false);
                            props.store.resetStore();
                            navigate(-1);
                        } else {
                            props.store.saveToServerActually();
                        }
                    }}
                />
            )}
        </Observer>
    );
};

export default RuleSetSaveDialog;
