import {
    Grid,
    InputBase,
    InputBaseComponentProps,
    SxProps,
    Theme,
    Tooltip,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { ClassNameMap } from "@mui/styles";
import WarningIcon from "@mui/icons-material/Warning";
import clsx from "clsx";
import React, {
    CSSProperties,
    MouseEventHandler,
    ReactNode,
    useRef,
} from "react";
import useStyles from "Styles/Styles";
import AcxInputLabel from "./AcxInputLabel";

const styles = (theme: Theme) => {
    // debugger;
    return createStyles({
        inputText: {
            border: "none",
            borderWidth: 0,
            outlineWidth: 0,
            backgroundColor: "transparent",
            // flex: "1 1 auto",
            fontSize: "13px",
            lineHeight: "20px",
            fontFamily: theme.typography.fontFamily,
            paddingLeft: theme.spacing(1),
            userSelect: "text",
        },
        inputTextFocused: {
            caretColor: theme.palette.primary.main,
            borderColor: theme.palette.info.light,
            borderRadius: theme.spacing(0.5),
        },
        textContainer: {
            border: "1px solid",
            backgroundColor: theme.palette.white.main,
            borderColor: theme.palette.black.light,
            borderRadius: theme.spacing(0.5),
            // display: "flex",
            // height: "32px",
            alignItems: "center",
        },
        textContainerFocused: {
            borderColor: theme.palette.primary.main,
        },
        textLabel: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
            userSelect: "none",
        },
        required: {
            "&:after": {
                color: theme.palette.red.main,
                content: '" *" !important',
            },
        },
        textLabelFocused: {
            color: theme.palette.secondary.main,
        },

        helperText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        helperTextError: {
            color: theme.palette.error.main,
        },
        labelContainer: {
            opacity: 1,
        },
        warningIcon: {
            color: theme.palette.red.main,
            marginRight: "8px",
            marginLeft: "4px",
        },
    });
};

export interface IAcxTextFieldProps {
    labelText?: string | undefined;
    error?: boolean;
    placeholderText?: string;
    id: string;
    value: string;
    multiLine?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onEnterPressed?: () => void;
    helperText?: string | undefined;
    inputProps?: InputBaseComponentProps;
    required?: boolean;
    type?:
        | string
        | ("text" | "time" | "url" | "tel" | "number" | "email" | "date");
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    containerStyle?: CSSProperties;
    containerSx?: SxProps<Theme>;
    textItemStyle?: CSSProperties;
    textContainerSx?: SxProps<Theme>;
    labelSx?: SxProps<Theme>;
    sx?: SxProps<Theme>;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    autofocus?: boolean;
    onClick?: MouseEventHandler<any>;
    onDblClick?: MouseEventHandler<any>;
    rows?: number;
    rowsMax?: number;
    disabled?: boolean;
    autoFocus?: boolean;
    blurOnEnterPress?: boolean;
    autoComplete?: string;
}

function InputWarningIcon(props: {
    title: string | undefined;
    classes: ClassNameMap;
}) {
    return (
        <Tooltip title={props.title ?? ""} placement={"top-end"}>
            <WarningIcon className={props.classes.warningIcon} />
        </Tooltip>
    );
}

export default function AcxMainTextField(props: IAcxTextFieldProps) {
    const classes = useStyles(styles);
    const [focused, setFocused] = React.useState(false);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

    const onBlur = () => {
        setFocused(false);
        props.onBlur?.();
    };

    const onFocus = () => {
        setFocused(true);
        props.onFocus?.();
    };

    const onKeyPress = (
        e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (e.key === "Enter") {
            props.onEnterPressed?.();
        }
        if (props.blurOnEnterPress && e.key === "Enter") {
            onBlur();
            inputRef.current?.blur();
        }
    };

    return (
        <Grid
            container
            style={props.containerStyle}
            sx={props.containerSx}
            direction="column"
            justifyContent="center"
            alignItems="stretch"
        >
            {!!props.labelText && (
                <Grid item className={classes.labelContainer}>
                    <AcxInputLabel
                        htmlFor={props.id}
                        id={props.id}
                        labelText={props.labelText ?? ""}
                        focused={focused}
                        className={clsx({
                            [classes.textLabelFocused]: focused,
                            [classes.required]: props.required && !props.value,
                            [classes.textLabel]: true,
                        })}
                        sx={props.labelSx}
                    ></AcxInputLabel>
                </Grid>
            )}

            <Grid
                style={{ ...(props.textItemStyle ?? {}), userSelect: "text" }}
                sx={props.textContainerSx}
                item
                className={clsx({
                    [classes.textContainer]: true,
                    [classes.textContainerFocused]: focused,
                })}
            >
                <InputBase
                    id={props.id}
                    autoComplete={props.autoComplete}
                    onDoubleClick={props.onDblClick}
                    disabled={props.disabled}
                    readOnly={props.disabled}
                    onClick={props.onClick}
                    inputProps={{
                        ...{ type: props.type ?? "text" },
                        ...props.inputProps,
                    }}
                    placeholder={props.placeholderText}
                    startAdornment={
                        props.error ? undefined : props.startAdornment
                    }
                    endAdornment={
                        props.error ? (
                            <InputWarningIcon
                                title={props.helperText}
                                classes={classes}
                            />
                        ) : (
                            props.endAdornment
                        )
                    }
                    className={clsx({
                        [classes.inputText]: true,
                        [classes.inputTextFocused]: focused,
                    })}
                    type={props.type}
                    fullWidth
                    minRows={props.rows}
                    maxRows={props.rowsMax}
                    multiline={props.multiLine}
                    error={props.error}
                    value={props.value ?? ""}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={props.onChange}
                    autoFocus={props.autoFocus ?? false}
                    inputRef={inputRef}
                    onKeyDown={onKeyPress}
                    sx={props.sx}
                />
            </Grid>
        </Grid>
    );
}

AcxMainTextField.defaultProps = {
    error: false,
    labelText: "",
    placeholderText: "",
    value: "",
    required: false,
    type: "text",
    containerClass: "",
};
