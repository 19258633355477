import { ClipTag } from "components/Evaluation/Stores/EvalStore";
import { sentimentToColor } from "components/Evaluation/Utils";
import * as _ from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { AnswerTypeNames } from "models/AnswerType";
import LicensedModule from "models/LicensedModule";
import Question, { QuestionType } from "models/Question";
import { Tag } from "models/Tag";

export class ConvoModuleUIModel {
    @observable questionTags: Map<string, Tag[]> = new Map<string, Tag[]>();
    @observable moduleTypeName: string;
    @observable licensedModule: LicensedModule;
    @observable scoredAnswers: Map<string, number[]> = new Map<
        string,
        number[]
    >();
    conversationModuleId?: string;
    private scoredWeights: Map<string, number> = new Map<string, number>();

    // private questions: Question[];

    constructor(
        questions: Question[],
        module: LicensedModule,
        clipTags: ClipTag[],
        conversationModuleId?: string,
    ) {
        makeObservable(this);
        this.initializeNew(module, questions, clipTags, conversationModuleId);
    }

    private async initializeNew(
        module: LicensedModule,
        questions: Question[],
        clipTags: ClipTag[],
        conversationModuleId?: string,
    ) {
        this.moduleTypeName = module.name!;
        this.licensedModule = module;
        this.conversationModuleId = conversationModuleId;
        // this.questions = questions;

        const sortedQuestions = questions.sort((a, b) => a.order - b.order);

        for (const question of sortedQuestions) {
            const valueTags: Tag[] = [];

            const names = question.tags.filter(
                (value) => value.isActive && question.isActive,
            );

            names
                .sort((a, b) => {
                    return a.order - b.order;
                })

                .forEach(
                    (value) =>
                        (value.parent = {
                            value: question.questionText,
                        } as Tag),
                );

            valueTags.push(...names);

            if (
                question.answerType.answerTypeName ===
                AnswerTypeNames.StarRating
            ) {
                valueTags.sort(
                    (a, b) => (a.scoredValue ?? 0) - (b.scoredValue ?? 0),
                );
            }

            runInAction(() => {
                this.questionTags.set(question.id, valueTags);
                if (
                    question.answerType.answerTypeName !==
                    AnswerTypeNames.StarRating
                ) {
                    clipTags.push(...valueTags);
                }
            });
        }
    }

    @computed
    get moduleScore() {
        return this.computeScore();
    }

    // TODO: do we need module scores for convo workflows? probly no
    // public GenerateModuleScore(): number | null {
    //     for (const question of this.questions.filter(
    //         (value) => value.isActive,
    //     )) {
    //         const selectedTags = this.evaluation?.answers
    //             .filter(
    //                 (value) =>
    //                     value.questionId === question.id && value.isActive,
    //             )
    //             .flatMap((value) => value.answerTags as AnswerTag[])
    //             .filter((value) => value.isActive)
    //             .map((value) => value.tag)
    //             .filter((value) => !!value);

    //         this.updateModuleScore(question, selectedTags as Tag[]);
    //     }

    //     return this.computeScore();
    // }

    private computeScore(): number | null {
        const scores = [...this.scoredAnswers.values()].flatMap(
            (value) => value,
        );

        if (scores.length === 0) {
            return null;
        }

        const weightsSum = _.sum([...this.scoredWeights.values()]);

        return (
            (100 * (_.sum(scores) ?? 0)) /
            (scores.length === 0
                ? 1
                : weightsSum === 0
                ? scores.length
                : weightsSum)
        );
    }

    // //We need to update this evaluation reference after save as or this would be a stale reference
    // @action
    // updateEvaluation(evaluation: Evaluation) {
    //     this.evaluation = evaluation;
    // }

    @action
    updateModuleScore(question: Question, tags: Tag[]) {
        if (question.type === QuestionType.QuestionHeading) {
            return;
        }

        const questionWeight = question.weight ?? 1.0;

        if (
            tags.filter(
                (value) =>
                    value.scoredValue !== null &&
                    value.scoredValue !== undefined &&
                    !question.answerType?.excludeFromModuleScoreCalculation,
            ).length
        ) {
            this.scoredWeights.set(question.id, question.weight ?? 1);
        } else {
            this.scoredWeights.delete(question.id);
        }

        const values: number[] = [];
        tags.forEach((value) => {
            if (
                value.scoredValue !== null &&
                !question.answerType?.excludeFromModuleScoreCalculation
            ) {
                values.push(
                    (value.scoredValue * questionWeight) /
                        tags.filter(
                            (value) =>
                                value.scoredValue !== null &&
                                value.scoredValue !== undefined,
                        ).length,
                );
            }
        });

        this.scoredAnswers.set(question.id, values);
    }

    // TODO -- figure out implementation to determine when a given question/answer is Alert-able
    // (exemplary/needs-warning)
    public isAlertableAnswer = (questionId: string) => {
        return false;
    };

    public getTagsFormattedForScaledResponse(questionId: string) {
        const tags = this.questionTags.get(questionId);
        return (
            tags?.map((value) => ({
                option: {
                    color: sentimentToColor(value.sentiment, value.scoredValue),
                    text: value.value,
                },
                data: value,
            })) ?? []
        );
    }

    public getTagsFormattedForTagResponse(questionId: string) {
        const tags = this.questionTags.get(questionId);
        return tags;
    }

    public getTagsFormattedForStarRating(questionId: string) {
        const tags = this.questionTags.get(questionId);
        return tags;
    }
}
