import WordsPhraseInput from "components/Conversations/Views/Drawer/components/DeepFilter/WordsPhrasesInput";
import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import { WordsPhrasesConfirmationModal } from "components/Conversations/Views/Drawer/components/DeepFilter/WordsPhrasesConfirmationModal";
import { Grid } from "@mui/material";

export const WordsAndPhrasesFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    return (
        <>
            <Grid container item>
                <WordsPhraseInput store={props.store.wordsAndPhrasesStore} />
            </Grid>
            <WordsPhrasesConfirmationModal
                store={props.store.wordsAndPhrasesStore}
            />
        </>
    );
});
