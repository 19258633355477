import {
    Collapse,
    Grid,
    IconButton,
    Theme,
    Tooltip,
    useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import { observer, Observer } from "mobx-react";
import React, { CSSProperties, useEffect, useState } from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        bodyShow: {
            display: "block",
        },
        bodyHide: {
            // display: 'none'
        },
        headerMenu: {
            marginRight: theme.spacing(1),
        },
        headerClosed: {
            backgroundColor: theme.palette.white.main,
            color: theme.palette.white.contrastText,
            fontWeight: "bold",
        },
        headerExpanded: {
            backgroundColor: theme.palette.primary[300],
            color: theme.palette.white.main,
            fontWeight: "bold",
        },

        header: {
            userSelect: "none",
            height: "56px",
            borderRadius: theme.spacing(0.5),
            boxShadow:
                "0 0 3px 0 rgba(0,0,0,0.05), 0 1px 1px 0 rgba(0,0,0,0.05)",
        },
        chevronExpanded: {
            color: theme.palette.white.main,
        },
    });

export type ConditionalStyleOverride = {
    1: /*isExpanded==true*/ CSSProperties;
    0: /*isExpanded==false*/ CSSProperties;
};

interface IAcxMainExpansion {
    expanded?: boolean;
    onExpandChange?: (expanded: boolean, name: string) => void;
    header: React.ReactNode;
    headerClass?: string;
    headerMenu?: (
        menuAnchor: HTMLElement | null,
        setMenuAnchor: (arg: HTMLElement | null) => void,
    ) => React.ReactNode;
    alwaysShowHeaderMenu?: boolean;
    name?: string;
    body?: React.ReactNode;
    bodyClass?: string;
    rootClass?: string;
    expandable?: boolean;
    indentLevel?: number;
    controls?: React.ReactNode;
    headerStyleOverRide?: Partial<ConditionalStyleOverride>;
    bodyStyleOverRide?: Partial<ConditionalStyleOverride>;
    chevronStyleOverRide?: Partial<ConditionalStyleOverride>;
    disableTooltip?: boolean;
}

function AcxMainExpansion(props: IAcxMainExpansion) {
    const classes = useStyles(styles);
    const theme = useTheme();
    const [isExpanded, setIsExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (!props.expandable) {
            setIsExpanded(false);
        }
    }, [props.expandable]);

    const expand = () => {
        if (props.onExpandChange) {
            props.onExpandChange(!isExpanded, props.name ? props.name : "");
        }
        setIsExpanded(!isExpanded);
    };

    function renderSpace(levels: number) {
        const res: React.ReactNode[] = new Array<React.ReactNode>();
        for (let i = 0; i < levels; i++) {
            res.push(
                <div key={"space-" + i.toString()} style={{ width: "48px" }} />,
            );
        }
        return res;
    }

    useEffect(() => {
        if (props.expanded !== undefined) {
            setIsExpanded(props.expanded);
        }
    }, [props.expanded]);

    return (
        <Observer>
            {() => (
                <div className={props.rootClass}>
                    <Grid
                        style={props.headerStyleOverRide?.[+(isExpanded ?? 0)]}
                        container
                        wrap="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                        className={clsx(
                            props.headerClass ?? classes.header,
                            !isExpanded && classes.headerClosed,
                            isExpanded && classes.headerExpanded,
                        )}
                    >
                        {renderSpace(props.indentLevel ?? 0)}
                        <Grid
                            container
                            item
                            alignItems="center"
                            alignContent={"flex-end"}
                        >
                            <Grid item>
                                {props.expandable ? (
                                    isExpanded ? (
                                        <Tooltip
                                            disableFocusListener
                                            title={
                                                props.disableTooltip
                                                    ? ""
                                                    : "Collapse"
                                            }
                                            placement={"top-start"}
                                        >
                                            <IconButton
                                                onClick={expand}
                                                sx={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                                style={
                                                    props
                                                        .chevronStyleOverRide?.[
                                                        +(isExpanded ?? 0)
                                                    ]
                                                }
                                                size="large"
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            disableFocusListener
                                            title={
                                                props.disableTooltip
                                                    ? ""
                                                    : "Expand"
                                            }
                                            placement={"top-start"}
                                        >
                                            <IconButton
                                                onClick={expand}
                                                color="primary"
                                                style={
                                                    props
                                                        .chevronStyleOverRide?.[
                                                        +(!isExpanded ?? 0)
                                                    ]
                                                }
                                                size="large"
                                            >
                                                <ChevronRightIcon />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                ) : (
                                    <div style={{ width: "16px" }} />
                                )}
                            </Grid>

                            <Grid item xs>
                                {props.header}
                            </Grid>

                            <Grid item marginRight={theme.spacing(1)}>
                                {(props.expanded ||
                                    Boolean(props.alwaysShowHeaderMenu)) &&
                                    props.headerMenu?.(anchorEl, setAnchorEl)}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Collapse in={isExpanded}>
                        <div
                            style={
                                props.bodyStyleOverRide?.[+(isExpanded ?? 0)]
                            }
                            className={clsx(
                                {
                                    [classes.bodyShow]: isExpanded,
                                    [classes.bodyHide]: !isExpanded,
                                },
                                props.bodyClass,
                            )}
                        >
                            {props.body}
                        </div>
                    </Collapse>
                </div>
            )}
        </Observer>
    );
}

AcxMainExpansion.defaultProps = {
    expandable: true,
    indentLevel: 0,
};

export default observer(AcxMainExpansion);
