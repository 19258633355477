import { observer } from "mobx-react";
import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";

const AcxIdleTimer = observer((props) => {
    const authStore = useStore(AuthStore);
    const [timeout, setTimeout] = React.useState<number>(
        authStore.orgStore?.selectedOrganization?.timeoutDuration ??
            15 * 60 * 1000,
    );

    const handleOnIdle = (event) => {
        console.info("User is idle: ", event);
        console.info(
            "Last active: ",
            new Date(getLastActiveTime()).toLocaleTimeString(),
        );

        authStore.handleUserLogout(true);
    };

    React.useEffect(() => {
        if (authStore.orgStore?.selectedOrganization) {
            if (authStore.orgStore.selectedOrganization?.timeoutDuration) {
                setTimeout(
                    authStore.orgStore?.selectedOrganization?.timeoutDuration,
                );
            }
        }
    }, [authStore.orgStore?.selectedOrganization]);

    const { getLastActiveTime } = useIdleTimer({
        timeout: timeout,
        onIdle: handleOnIdle,
    });

    return props.children;
});

export default AcxIdleTimer;
