export enum ThreadActionType {
    SelectHierarchy = "SelectHierarchy",
    SelectTheme = "SelectTheme",
    SelectClassifier = "SelectClassifier",
    SelectModule = "SelectModule",
    SelectAgent = "SelectAgent",
    SelectAllModulesInCategory = "SelectAllModulesInCategory",
    ViewSqlQueries = "ViewSqlQueries",
}

/**
 * Represents the map/dict that we receive from BE when actions are present.
 * The actual value for each key may vary hence the unknown.
 */
export type ThreadActionsMap = {
    [ThreadActionType.SelectHierarchy]: unknown;
    [ThreadActionType.SelectTheme]: unknown;
    [ThreadActionType.SelectClassifier]: unknown;
    [ThreadActionType.SelectModule]: unknown;
    [ThreadActionType.SelectAllModulesInCategory]: unknown;
    [ThreadActionType.ViewSqlQueries]: unknown;
};

export class ThreadActions {
    private map: Map<string, unknown>;

    constructor() {
        this.map = new Map();
    }

    public static from(map: ThreadActionsMap): ThreadActions {
        const actions = new ThreadActions();
        for (const [key, value] of Object.entries(map))
            actions.set(key as keyof ThreadActionsMap, value);
        return actions;
    }

    public set<T extends keyof ThreadActionsMap>(
        type: T,
        value: ThreadActionsMap[T],
    ) {
        this.map.set(type, value);
    }

    public get<T extends keyof ThreadActionsMap>(type: T): ThreadActionsMap[T] {
        return this.map.get(type) as ThreadActionsMap[T];
    }

    public has<T extends keyof ThreadActionsMap>(type: T) {
        return this.map.has(type);
    }

    public entries() {
        return Array.from(this.map.entries()) as Array<
            [keyof ThreadActionsMap, ThreadActionsMap[keyof ThreadActionsMap]]
        >;
    }
}
