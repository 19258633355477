import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            background: "#FFFFFF",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
            borderRadius: 8,
            minHeight: 175,
            padding: 12,
            display: "flex",
            justifyContent: "space-between",
            textDecoration: "none",
            color: theme.palette.blackFont.main,
        },
        groupName: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 16,
            display: "flex",
            alignItems: "flex-start",
            textAlign: "left",
            color: theme.palette.blackFont.main,
        },
        users: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 14,
            display: "flex",
            textAlign: "right",
            color: "#A1A1AA",
            alignItems: "flex-end",
        },
    }),
);

type Props = {
    data: any[];
    isLoading: boolean;
    orgId: string;
};

const OrganizationAnalystGroupsListCards: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = ({ data, isLoading, orgId }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {data.map((item) => (
                    <Grid item xs={12} sm={6} lg={4} key={item.id}>
                        <Link
                            to={`/admin/organizations/${orgId}/analyst-groups?id=${item.id}`}
                            className={classes.paper}
                        >
                            <div className={classes.groupName}>
                                {item.groupName}
                            </div>
                            <div className={classes.users}>
                                {item.analystGroupMembers?.length} users
                            </div>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default OrganizationAnalystGroupsListCards;
