import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { computed, makeObservable, observable, reaction } from "mobx";
import React from "react";
import { BaseStore } from "stores/BaseStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import { OrganizationStore } from "stores/OrganizationStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import AnalystSvg from "SvgIcons/ConfigIcons/AnalystSvg";
import BuildingSvg from "SvgIcons/ConfigIcons/BuildingSvg";
import HierarchySvg from "SvgIcons/ConfigIcons/HierarchySvg";
import LicenseSvg from "SvgIcons/ConfigIcons/LicenseSvg";
import ModulesSvg from "SvgIcons/ConfigIcons/ModulesSvg";
import StorageSvg from "SvgIcons/ConfigIcons/StorageSvg";
import UsersSvg from "SvgIcons/ConfigIcons/UsersSvg";
import NavigationStore, {
    INavigation,
    INavigationGroup,
} from "../../../../Layouts/SideNavigation/NavigationStore";
import { AuthStore } from "../../../../stores/AuthStore";
import { ThemeColors } from "../../../../Theme/AppTheme";

@AcxStore
export default class ConfigStore extends BaseStore {
    @observable orgStore: OrganizationStore;
    @observable messageStore: MessageStore;
    orgSelectorStore = new OrgSelectorComponentStore();
    navigationStore: NavigationStore;

    @observable _currentNavItem: INavigation | undefined;
    @computed
    get currentNavItem() {
        return this._currentNavItem;
    }
    set currentNavItem(value) {
        this._currentNavItem = value;
    }

    orgSetupItems: INavigation[] = [
        {
            title: "Org Details",
            link: "/admin/config/orgdetails",
            icon: <BuildingSvg />,
            selectedIcon: <BuildingSvg color={ThemeColors.blue} opacity={1} />,
        },
        {
            title: "License",
            link: "/admin/config/license",
            icon: <LicenseSvg />,
            selectedIcon: <LicenseSvg color={ThemeColors.blue} opacity={1} />,
        },
        {
            title: "Modules",
            link: "/admin/config/modules",
            icon: <ModulesSvg />,
            selectedIcon: <ModulesSvg color={ThemeColors.blue} opacity={1} />,
        },
        {
            title: "Service Hierarchy",
            link: "/admin/config/servicehierarchy",
            icon: <HierarchySvg />,
            selectedIcon: <HierarchySvg color={ThemeColors.blue} opacity={1} />,
        },
    ];
    managementItems: INavigation[] = [
        {
            title: "Users",
            link: "/admin/config/users",
            icon: <UsersSvg />,
            selectedIcon: <UsersSvg color={ThemeColors.blue} opacity={1} />,
        },
        {
            title: "Analysts",
            link: "/admin/config/analysts",
            icon: <AnalystSvg />,
            selectedIcon: <AnalystSvg color={ThemeColors.blue} opacity={1} />,
        },
        {
            title: "Storage",
            link: "/admin/config/storage",
            icon: <StorageSvg />,
            selectedIcon: <StorageSvg color={ThemeColors.blue} opacity={1} />,
        },
    ];
    navGroupItems: INavigationGroup[] = [
        { header: "Organization Setup", items: this.orgSetupItems },
        { header: "Management", items: this.managementItems },
    ];
    public constructor(private rootStore: IRootStore) {
        super("Config Store");
        makeObservable(this);
        this.orgStore = rootStore.getStore(AuthStore).orgStore;
        this.messageStore = rootStore.getStore(MessageStore);
        this.navigationStore = new NavigationStore(
            rootStore,
            "OrgConfig",
            this.navGroupItems,
            this.orgSelectorStore,
        );

        reaction(
            () => ({ orgId: this.orgSelectorStore.orgId }),
            async (args) => {
                this.setupAsyncTask("Reset", () => this.reset());
            },
            { delay: 10, fireImmediately: true },
        );
    }

    reset = async () => {
        console.log("config reset");
    };
}
