import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import RefreshIcon from "@mui/icons-material/Refresh";
import ManagerInteractionsStore, {
    GENERATE_SAMPLE,
    SELECT_SAMPLE,
} from "components/ManagerInteractions/Stores/ManagerInteractionsStore";
import AcxButton from "components/UI/AcxButton";
import ConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { CustomControlItem } from "components/UI/AcxDataGrid/AcxDataGridStore";
import { dateTimeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import { durationColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DurationColType";
import { interactionTypeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/InteractionTypeColType";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import { OrganizationService } from "services/OrganizationService";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { DataProcessingOptions } from "../../../../models/DataProcesses";
import { SamplingType } from "../../../../services/AudioFilesService";
import OnDemandFilters from "./OnDemandFilters";

const styles = () =>
    createStyles({
        componentContainer: {
            width: "100%",
            height: "100%",
            padding: "2rem",
        },
        typeIcon: {
            color: "#000",
        },
    });

const selectCustomStyle = {
    menuPortal: (provided, state) => ({ ...provided, zIndex: 10000 }),
};

const OnDemandSample = observer(() => {
    const classes = useStyles(styles);
    const store = useStore(ManagerInteractionsStore);
    const authStore = useStore(AuthStore);
    const orgStore = useStore(AuthStore).orgStore;
    const drawerStore = store.drawerStore;

    const sampleRowsIsDisabled = store.onDemandDgStore.selectedRows.length < 1;

    const [userHierarchy, setUserHierarchy] = React.useState<
        { id: string; label: string }[]
    >([]);

    const [hierarchyLoading, setHierarchyLoading] =
        React.useState<boolean>(false);

    const handleDialogConfirmation = () => {
        store.selectOnDemandSample();
        store.onDemandSampleDialog.close();
    };

    function handleSampleTypeChange(
        event: React.ChangeEvent<HTMLInputElement>,
    ) {
        if (event.target.checked) {
            store.setSamplingType(SamplingType.MiddleOfTheFunnel);
        } else {
            store.setSamplingType(SamplingType.BottomOfTheFunnel);
        }
    }

    // Keep store in sync with Profile Hierarchy selection
    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    // Initialize our data-grid + controls & setup drawer actions
    React.useEffect(() => {
        const closeDrawer = () => {
            drawerStore.closeAndResetDrawer();
            drawerStore.restoreDefaults();
        };

        const openDrawer = () => {
            drawerStore.setOpen(true);
            drawerStore.contentInjector = () => (
                <VerticalDrawerContentTemplate
                    width="350px"
                    title="Define Filters"
                    content={
                        <div
                            style={{
                                height: "calc(100% - 2rem)",
                            }}
                        >
                            <OnDemandFilters store={store} />
                        </div>
                    }
                    onClose={closeDrawer}
                />
            );
        };

        const initializeGrid = async () => {
            // Load Appropriate Meta Labels
            const getMetaLabels = async () => {
                if (orgStore.selectedOrganization) {
                    const metaLabels = await store.getMetaLabels();

                    if (metaLabels && Object.keys(metaLabels).length > 0) {
                        for (const [key, value] of Object.entries(metaLabels)) {
                            store.metaLabelSelectLabels[key] = value;
                        }
                    }
                }
            };

            await getMetaLabels();

            const columns: IColDef[] = [
                {
                    headerName: "Name",
                    field: "fileName",
                    width: 110,
                },
                {
                    headerName: "Interaction Date",
                    field: "timestamp",
                    width: 160,
                    ...dateTimeColumnType,
                },
                {
                    headerName: "Agent",
                    field: "agentName",
                    width: 110,
                },
                {
                    headerName: "Call Direction",
                    field: "callDirection",
                    width: 110,
                },
                {
                    headerName: "Duration",
                    field: "callDurationMillis",
                    width: 110,
                    ...durationColumnType,
                },
                {
                    headerName: "Directory",
                    field: "origDirectoryPath",
                    width: 110,
                },
                {
                    headerName: "Type",
                    field: "mediaType",
                    width: 110,
                    display: "flex",
                    ...interactionTypeColumnType,
                },
                {
                    headerName:
                        store.metaLabelSelectLabels["Meta1"] ?? "Meta 1",
                    field: "meta1",
                },
                {
                    headerName:
                        store.metaLabelSelectLabels["Meta2"] ?? "Meta 2",
                    field: "meta2",
                },
                {
                    headerName:
                        store.metaLabelSelectLabels["Meta3"] ?? "Meta 3",
                    field: "meta3",
                },
                {
                    headerName:
                        store.metaLabelSelectLabels["Meta4"] ?? "Meta 4",
                    field: "meta4",
                },
                {
                    headerName:
                        store.metaLabelSelectLabels["Meta5"] ?? "Meta 5",
                    field: "meta5",
                },
                {
                    headerName: "Sample response",
                    field: "sampleResponse",
                    width: 200,
                },
                {
                    headerName: "Conversation Message Count",
                    field: "conversationMessageCount",
                    width: 110,
                },
            ];

            const dateRangePicker = (
                <Observer>
                    {() => (
                        <AcxDateRangeInput
                            labelText="Select Interaction Date Range"
                            defaultStartDate={
                                store.assignedDatePickerStore.beginDate
                            }
                            defaultEndDate={
                                store.assignedDatePickerStore.endDate
                            }
                            onSelect={(start: Moment, end: Moment) => {
                                store.assignedDatePickerStore.setBeginDate(
                                    start,
                                );
                                store.assignedDatePickerStore.setEndDate(end);
                            }}
                        />
                    )}
                </Observer>
            );

            const optionalFilterButton = (
                <Observer>
                    {() => (
                        <AcxButton
                            tooltip="Define optional filters to help narrow potential sample data."
                            color="primary"
                            onClick={openDrawer}
                            style={{ height: "32px" }}
                        >
                            Define
                        </AcxButton>
                    )}
                </Observer>
            );

            const refreshData = (
                <Observer>
                    {() => (
                        <AcxButton
                            tooltip="Refresh data with applied filters."
                            onClick={() => store.generateSample(false)}
                            style={{ height: "32px" }}
                            loading={store.getTaskLoading(GENERATE_SAMPLE)}
                            disabled={store.getTaskLoading(GENERATE_SAMPLE)}
                        >
                            <RefreshIcon />
                        </AcxButton>
                    )}
                </Observer>
            );

            const controls: CustomControlItem[] = [
                {
                    controlElement: dateRangePicker,
                },
                {
                    controlElement: refreshData,
                },
                {
                    controlElement: optionalFilterButton,
                },
            ];

            store.onDemandDgStore.setColumns(columns);
            store.onDemandDgStore.controls = controls;
            store.onDemandDgStore.controlsColumnSpan = 12;
            store.onDemandDgStore.density = "compact";
            store.onDemandDgStore.removeHeight = "7rem";
            store.onDemandDgStore.controlsColumnStyle = {
                justifyContent: "flex-end",
                marginTop: "0.5rem",
            };
        };

        initializeGrid();
    }, [
        classes.typeIcon,
        drawerStore,
        store,
        store.onDemandDgStore,
        orgStore.selectedOrganization,
    ]);

    React.useEffect(() => {
        if (store.orgSelectStore.orgId && authStore._user.profile.sub) {
            const orgService = new OrganizationService();
            setUserHierarchy([]);
            setHierarchyLoading(true);

            const getUserHierarchies = async () => {
                const hierarchies =
                    await orgService.getFlattenedServiceHierarchyForUser(
                        store.orgSelectStore.orgId ?? "",
                        authStore._user.profile.sub,
                    );

                setUserHierarchy(hierarchies);
                setHierarchyLoading(false);
            };

            getUserHierarchies();
        }
    }, [authStore._user.profile.sub, store.orgSelectStore.orgId, store]);

    return (
        <>
            <ConfirmationDialog
                isOpen={store.onDemandSampleDialog.isOpen}
                onClose={store.onDemandSampleDialog.close}
                title="Sample Files"
                subTitle={`${store.onDemandDgStore.selectedRows.length} file${
                    store.onDemandDgStore.selectedRows.length > 1
                        ? "s have been"
                        : ""
                } selected for sampling. Are you sure that you would like to continue?`}
                content={
                    <>
                        {orgStore.organizationHasDataProcessingOptionEnabled(
                            DataProcessingOptions.MiddleOfTheFunnel,
                        ) && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                store.onDemandSamplingType ===
                                                SamplingType.MiddleOfTheFunnel
                                            }
                                            onChange={handleSampleTypeChange}
                                            name="SampleToMiddleOfTheFunnel"
                                        />
                                    }
                                    label={
                                        <Typography
                                            color={"textSecondary"}
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "12px",
                                            }}
                                            variant={"subtitle2"}
                                        >
                                            Sample to Middle of the Funnel
                                        </Typography>
                                    }
                                />

                                <AcxSelectSingle
                                    inputLabel="Hierarchy"
                                    infoText="Available hierarchies may vary amongst different users."
                                    isLoading={hierarchyLoading}
                                    options={userHierarchy}
                                    id="hierarchies"
                                    valueField="id"
                                    labelField="label"
                                    customStyle={selectCustomStyle}
                                    defaultValue={store.selectedHierarchy}
                                    onChange={store.setSelectedHierarchy}
                                    isClearable={true}
                                />

                                {!!store.selectedHierarchy?.id && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    store.shouldOverrideOSM
                                                }
                                                onChange={
                                                    store.toggleShouldOverrideOSM
                                                }
                                            />
                                        }
                                        label={
                                            <Typography
                                                color={"textSecondary"}
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "12px",
                                                }}
                                                variant={"subtitle2"}
                                            >
                                                Override interaction hierarchy
                                                if it exists
                                            </Typography>
                                        }
                                    />
                                )}
                            </>
                        )}
                    </>
                }
                confirmButtonText="Confirm Sample"
                cancelButtonText="Cancel"
                isLoading={store.getTaskLoading(SELECT_SAMPLE)}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={12}
                onConfirm={handleDialogConfirmation}
            />

            <Grid container className={classes.componentContainer}>
                <Grid container item justifyContent="flex-end">
                    <AcxButton
                        tooltip="Sample selected rows."
                        color="primary"
                        onClick={store.onDemandSampleDialog.open}
                        style={{ height: "32px" }}
                        disabled={
                            sampleRowsIsDisabled ||
                            store.getTaskLoading(SELECT_SAMPLE) ||
                            store.onDemandDgStore.selectedRows.length > 10
                        }
                        loading={store.getTaskLoading(SELECT_SAMPLE)}
                    >
                        Sample Rows
                    </AcxButton>
                </Grid>

                <AcxDataGrid dataGridStore={store.onDemandDgStore} />
            </Grid>
        </>
    );
});

export default OnDemandSample;
