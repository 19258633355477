import { IconButton, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { PauseCircleFilled, PlayCircleFilledWhite } from "@mui/icons-material";
import clsx from "clsx";
import * as React from "react";

interface Props {
    onClick: () => void;
    color: "default" | "inherit" | "primary" | "secondary";
    status: PlayStatus;
    controlButtonClass?: string;
    pauseButtonClass?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    iconHeight?: number;
    iconWidth?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
    disabledControl: (props: Props) => ({
        color: theme.palette.gray.main,
    }),

    button: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
    },
}));

export enum PlayStatus {
    Play,
    Paused,
    Stopped,
}

const MicroPlayerControl = (props: Props) => {
    const classes = useStyles(props);
    const hoverMessage = props.status === PlayStatus.Play ? "Pause" : "Play";
    const icon =
        props.status === PlayStatus.Play ? (
            <PauseCircleFilled
                style={{ height: props.iconHeight, width: props.iconWidth }}
            />
        ) : (
            <PlayCircleFilledWhite
                style={{ height: props.iconHeight, width: props.iconWidth }}
                className={clsx(
                    props.disabled && classes.disabledControl,
                    !props.disabled && props.controlButtonClass,
                )}
            />
        );
    return (
        <Tooltip title={hoverMessage} arrow>
            <span>
                <IconButton
                    sx={props.style}
                    disabled={props.disabled}
                    onClick={props.onClick}
                    onContextMenu={() => alert("context")}
                    color={props.controlButtonClass ? "inherit" : props.color}
                    className={clsx(classes.button)}
                    size="large"
                >
                    {icon}
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default MicroPlayerControl;
