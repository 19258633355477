import { Grid, Theme, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useMemo } from "react";
import BaseEntity from "../../../../models/BaseEntity";
import { ClassifierResult } from "../../../../models/ClassifierResult";
import { useStore } from "../../../../utils/useStore";
import AcxAnswerSelector, {
    AnswerColor,
    AnswerSelection,
} from "../../../UI/AcxAnswerSelector";
import AcxChipList from "../../../UI/AcxChipList";
import { EvalStore } from "../../Stores/EvalStore";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},

    padding3: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },

    questionText: {
        color: "#1F1F1F",
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    },

    fullSize: {
        height: "100%",
        width: "100%",
    },
    questionHeader: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    infoSVG: {
        marginRight: theme.spacing(0.5),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
}));

class ValidationResultAnswer extends BaseEntity {
    constructor(result: "true" | "false") {
        super();
        this.id = result as string;
        this.result = result;
    }
    result: "true" | "false";
}
// Array<{ data: T; option: AnswerSelection }>;
const validationResultOptions: Array<{
    data: ValidationResultAnswer;
    option: AnswerSelection;
}> = [
    {
        data: new ValidationResultAnswer("true"),
        option: { text: "Yes", color: AnswerColor.DARKBLUE },
    },
    {
        data: new ValidationResultAnswer("false"),
        option: { text: "No", color: AnswerColor.DARKBLUE },
    },
];

interface OwnProps {
    classifierResult: ClassifierResult;
    isDisabled?: boolean;
}

type Props = OwnProps;

const ClassifierFeedbackAnswer: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const store = useStore(EvalStore);
    const classes = useStyles();

    const classifierValidation =
        store.currentEval?.getClassifierValidationForResult(
            props.classifierResult,
        );
    const validationResult = useMemo(
        () =>
            classifierValidation?.result
                ? new ValidationResultAnswer(classifierValidation.result)
                : undefined,
        [classifierValidation],
    );

    function onValidationResult(
        validationResultAnswer?: ValidationResultAnswer,
    ) {
        if (!props.isDisabled) {
            if (validationResultAnswer === undefined) {
                store.currentEval?.setValidationForClassificationResult(
                    props.classifierResult,
                    undefined,
                    classifierValidation,
                );
            } else {
                store.currentEval?.setValidationForClassificationResult(
                    props.classifierResult,
                    validationResultAnswer.result,
                    classifierValidation,
                );
            }
        }
    }

    return (
        <Grid
            item
            xs={12}
            className={classes.root}
            container
            direction={"column"}
            justifyContent={"flex-start"}
            alignItems="stretch"
        >
            <Grid
                item
                xs={12}
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                wrap={"nowrap"}
                className={clsx(classes.questionHeader, classes.padding3)}
                spacing={4}
            >
                <Grid item lg={2} md={6} sm={6} xs={6}>
                    <Tooltip
                        title={props.classifierResult.classifier.name}
                        placement={"top"}
                    >
                        <div>
                            <AcxChipList
                                size={"small"}
                                color={"blue"}
                                values={[
                                    props.classifierResult.classifier.name,
                                ]}
                            />
                        </div>
                    </Tooltip>
                </Grid>
                <Grid
                    item
                    lg={3}
                    md={6}
                    sm={6}
                    xs={6}
                    container
                    justifyContent={"flex-end"}
                >
                    <AcxAnswerSelector
                        options={validationResultOptions}
                        onSelect={onValidationResult}
                        defaultValue={validationResult}
                        isDisabled={props.isDisabled}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
});

export default ClassifierFeedbackAnswer;
