import { Grid } from "@mui/material";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import { observer } from "mobx-react";
import React from "react";
import { EvalStore, UpdateEvaluationTask } from "../../Stores/EvalStore";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";

interface IRequestDisputeDialogProps {
    evalStore: EvalStore;
}

const defaultConfirmButtonText = "Reassign Evaluation Hierarchy";

const ReassignHierarchyDialog: React.FC<IRequestDisputeDialogProps> = observer(
    ({ evalStore }) => {
        // const classes = useStyles();
        const [confirmButton, setConfirmButton] = React.useState<string>(
            defaultConfirmButtonText,
        );

        let content = (
            <Grid container alignItems="center" justifyContent="center">
                <AcxHierarchySelector
                    userId={evalStore.authStore._user.profile.sub}
                    orgId={evalStore.organizationId ?? ""}
                    displayType="baseOnly"
                    setHierarchyIds={(ids) => {
                        evalStore.setSelectedReassignHierarchy(ids[0]);
                    }}
                    onSaveUpdateWithHierarchies={() => null}
                    width="500px"
                    singleSelect
                    hideTopContent
                    hideBottomContent
                />
            </Grid>
        );
        evalStore.reassignHierarchyDialogStore.setTitle(confirmButton);
        evalStore.reassignHierarchyDialogStore.setSubTitle(
            "Select a new hierarchy for this evaluation",
        );

        const onClose = () => {
            evalStore.reassignHierarchyDialogStore.close();
            setConfirmButton(defaultConfirmButtonText);
        };

        const onSubmitRequest = async () => {
            if (!evalStore.currentEval) {
                return;
            }

            evalStore.reassignEvaluationHierarchy();
        };

        React.useEffect(() => {
            setConfirmButton(defaultConfirmButtonText);

            return () => {
                setConfirmButton(defaultConfirmButtonText);
            };
        }, []);

        return (
            <AcxConfirmationDialog
                onClose={onClose}
                onConfirm={onSubmitRequest}
                isLoading={
                    evalStore.reassignHierarchyDialogStore.isLoading ||
                    evalStore.getTaskLoading(UpdateEvaluationTask)
                }
                buttonsDisabled={
                    evalStore.reassignHierarchyDialogStore.isLoading ||
                    !evalStore.selectedReassignHierarchyId
                }
                onCancel={onClose}
                title={evalStore.reassignHierarchyDialogStore.title}
                subTitle={evalStore.reassignHierarchyDialogStore.subTitle}
                cancelButtonText={"Cancel"}
                confirmButtonText={confirmButton}
                content={content}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={20}
                isOpen={evalStore.reassignHierarchyDialogStore.isOpen}
            />
        );
    },
);

export default ReassignHierarchyDialog;
