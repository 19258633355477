import { Tooltip } from "@mui/material";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import MusicOffIcon from "@mui/icons-material/MusicOff";
import React, { FunctionComponent } from "react";
import { MontageSoundClipStore } from "../Stores/MontageSoundClipStore";

interface OwnProps {
    clipId: string;
    id: string;
    type: "silent" | "tone" | undefined;
    store: MontageSoundClipStore;
}

type Props = OwnProps;

const ToneIcon: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const removeTone = () => {
        handleClose();
        props.store.removeToneBufferFromSet(props.clipId, props.id);
    };

    if (!props.type) {
        return null;
    }
    switch (props.type) {
        case "silent":
            return (
                <Tooltip
                    placement={"top"}
                    title={"Silent Break"}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                >
                    <MusicOffIcon
                        style={{ cursor: "pointer" }}
                        color="primary"
                        onClick={removeTone}
                        fontSize="small"
                    />
                </Tooltip>
            );
        case "tone":
            return (
                <Tooltip
                    placement={"top"}
                    title={"Tone Break"}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                >
                    <MusicNoteIcon
                        style={{ cursor: "pointer" }}
                        color="primary"
                        fontSize="small"
                        onClick={removeTone}
                    />
                </Tooltip>
            );
    }
};

export default ToneIcon;
