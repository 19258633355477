import { Collapse, Grid, GridSize, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { WorkflowReviewStore } from "../Stores/WorkflowReviewStore";
import { Answer } from "models/Answer";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import theme from "Theme/AppTheme";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import Question from "models/Question";
import AcxChipList from "components/UI/AcxChipList";

interface WFRQuoteNoteProps {
    answer?: Answer;
    question: Question;
    showNote?: boolean;
    showQuote?: boolean;

    noteXs?: GridSize;
    noteSm?: GridSize;
    noteMd?: GridSize;
    noteLg?: GridSize;
    noteXl?: GridSize;

    quoteXs?: GridSize;
    quoteSm?: GridSize;
    quoteMd?: GridSize;
    quoteLg?: GridSize;
    quoteXl?: GridSize;

    evaluationModuleId?: string;
    columnDirection: "column" | "column-reverse";
    isDisabled?: boolean;
}

const WFRQuoteNote: React.FC<WFRQuoteNoteProps> = observer((props) => {
    const styles = (theme: Theme) =>
        createStyles({
            fullSize: {
                width: "100%",
                height: "100%",
            },
            padding3: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
            },
            botPadding: {
                paddingBottom: "1.25rem",
            },
        });
    const classes = useStyles(styles);
    const store = useStore(WorkflowReviewStore);

    const onNoteChange = useCallback(
        (evt: any) => {
            if (!props.answer) {
                const newAnswer = store.setAnswerForQuestion(
                    props.question,
                    [],
                    props.answer,
                    props.evaluationModuleId,
                );
                newAnswer?.setNote?.(evt.currentTarget.value);
            } else {
                props.answer.setNote?.(evt.currentTarget.value);
            }
        },
        [props.answer, props.question, props.evaluationModuleId, store],
    );

    const soundClipChips =
        props.showQuote &&
        props.answer?.activeSoundClips.map(
            (soundClip) => soundClip.segmentName ?? "",
        );

    return (
        <Grid
            container
            item
            xs={12}
            direction={props.columnDirection}
            justifyContent={"flex-start"}
            alignItems={"stretch"}
            className={clsx(classes.padding3, classes.fullSize)}
        >
            <Grid
                item
                xs={props.noteXs}
                className={clsx(classes.fullSize, classes.botPadding)}
            >
                <Collapse
                    in={props.showNote}
                    timeout={theme.transitions.duration.standard}
                >
                    <AcxMainTextField
                        id={"question-note-field"}
                        rows={3}
                        rowsMax={5}
                        labelText={"Add A Note"}
                        multiLine
                        value={props.answer?.note}
                        onChange={onNoteChange}
                        disabled={props.isDisabled}
                        textItemStyle={
                            props.isDisabled
                                ? {
                                      backgroundColor: theme.palette.gray[50],
                                  }
                                : {}
                        }
                    />
                </Collapse>
            </Grid>
            {soundClipChips && (
                <Grid container padding={theme.spacing(1, 1, 1, 0)}>
                    <Grid item paddingRight={1}>
                        <Typography
                            component="span"
                            fontWeight={600}
                            fontSize="14px"
                        >
                            Clips:
                        </Typography>
                    </Grid>
                    <Grid>
                        <AcxChipList
                            size="small"
                            color="blue"
                            values={soundClipChips}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
});

export default WFRQuoteNote;
