import { Grid, Tooltip } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import AcxExpansion from "components/UI/AcxExpansion";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { Observer, observer } from "mobx-react";
import {
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "models/Reporting/ReportDataField";
import { ReportField } from "models/Reporting/ReportField";
import { Moment } from "moment";
import React from "react";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import AcxDateRangeInput from "../../UI/Calendar/DateRange/AcxDateRangeInput";
import { ReportQuickFilterStore } from "../Stores/ReportQuickFilterStore";
import ReportsStore from "../Stores/ReportsStore";

const referenceOptions = [
    DateReferenceOption.InteractionDate,
    DateReferenceOption.EvaluationCompleteDate,
    DateReferenceOption.EvaluationStartDate,
];

type FakeObject = { value: string };

const ReportCardFilters = observer(() => {
    type dataTypeRenderer = (
        fieldName: string,
        displayName: string | undefined,
        index: number,
    ) => JSX.Element;
    const dataTypeRenderers = new Map<ReportFieldDataTypes, dataTypeRenderer>();
    dataTypeRenderers.set(
        ReportFieldDataTypes.Categorical,
        renderCategoricalQuickFilter,
    );
    dataTypeRenderers.set(
        ReportFieldDataTypes.Hierarchy,
        renderCategoricalQuickFilter,
    );
    dataTypeRenderers.set(
        ReportFieldDataTypes.CategoricalText,
        renderCategoricalQuickFilter,
    );
    dataTypeRenderers.set(ReportFieldDataTypes.Text, renderStringQuickFilter);

    function setNewCategoricalValue(selected: FakeObject[], index: number) {
        const combined = JSON.stringify(selected.map((x) => x.value));
        store.setQuickFilterValue(index, combined);
    }

    function setNewStringValue(
        event: React.ChangeEvent<HTMLInputElement>,
        index,
    ) {
        const val = event.target.value;
        store.setQuickFilterValue(index, val);
    }

    function renderQuickFilter(field: ReportField, index: number) {
        const filterType = field.reportDataField?.datatype;
        if (filterType === undefined) {
            return null;
        }

        const fieldName =
            field.reportDataField?.variation ===
            ReportDataFieldVariation.Virtual
                ? field.reportDataField?.displayName
                : field.reportDataField?.fieldName ?? field.name;

        const displayName =
            field.displayName?.trim() || field.reportDataField?.displayName;

        const renderer = dataTypeRenderers.get(filterType);
        if (renderer) return renderer(fieldName, displayName, index);

        return null;
    }

    function renderCategoricalQuickFilter(
        fieldName: string,
        displayName: string | undefined,
        index: number,
    ) {
        let validValues =
            fieldName !== "" ? store.quickFilterValidValues.get(fieldName) : [];
        if (!validValues) validValues = [];
        const options: FakeObject[] = validValues.map((v) => {
            return { value: v };
        });

        const currentValues: FakeObject[] =
            store.quickFilters.length > index &&
            store.quickFilters[index]?.value
                ? JSON.parse(store.quickFilters[index].value).map((v) => {
                      return { value: v };
                  })
                : [];

        return (
            <Grid
                item
                xs={"auto"}
                style={{ minWidth: "280px", maxWidth: "350px" }}
                key={"quick_filter_" + index}
            >
                <AcxSelectMulti
                    id={"quick_filter_" + index}
                    options={options}
                    valueField="value"
                    containerHeight={"auto"}
                    labelField="value"
                    inputLabel={displayName ?? fieldName ?? "Unnamed Filter"}
                    onChange={(newSelected) =>
                        setNewCategoricalValue(newSelected || [], index)
                    }
                    defaultValue={currentValues}
                />
            </Grid>
        );
    }

    function renderStringQuickFilter(
        fieldName: string,
        displayName: string | undefined,
        index: number,
    ) {
        return (
            <Grid
                item
                xs={"auto"}
                style={{ minWidth: "200px", maxWidth: "280px" }}
                key={"quick_filter_" + index}
            >
                <AcxMainTextField
                    id={"quick_filter_" + index}
                    labelText={displayName ?? fieldName ?? "Unnamed Filter"}
                    onChange={(event) => setNewStringValue(event, index)}
                    containerStyle={{ width: "280px" }}
                />
            </Grid>
        );
    }

    function renderUnsupportedFilters(filters: ReportField[]) {
        const unsupportedFilters = filters.filter(
            (f) =>
                f.reportDataField !== undefined &&
                !dataTypeRenderers.has(f.reportDataField.datatype),
        );

        if (unsupportedFilters.length < 1) return null;

        const tipContent = (
            <>
                <div key="unsupported_header">
                    This report specifies some quick filters that are not
                    supported:
                </div>
                {unsupportedFilters.map((filter, idx) => (
                    <div key={"unsupported_" + idx}>{filter.name}</div>
                ))}
            </>
        );

        return (
            <Tooltip title={tipContent} key="unsupported_icon">
                <WarningIcon
                    fontSize="small"
                    htmlColor={theme.palette.warning.main}
                />
            </Tooltip>
        );
    }

    const store = useStore(ReportQuickFilterStore);
    const reportsStore = useStore(ReportsStore);

    let controls: (JSX.Element | null)[] = [];
    const unSupportedFilters = renderUnsupportedFilters(
        store.quickFilterFields,
    );

    controls.push(
        <Grid
            item
            xs={"auto"}
            style={{ marginBottom: "0.5rem" }}
            key="date-time-selector-quick-filter"
        >
            <Observer>
                {() => (
                    <AcxDateRangeInput
                        defaultStartDate={
                            reportsStore.datePickerStore.beginDate
                        }
                        defaultEndDate={reportsStore.datePickerStore.endDate}
                        maxWidth="272px"
                        onSelect={(start: Moment, end: Moment) => {
                            reportsStore.datePickerStore.setBeginDate(start);
                            reportsStore.datePickerStore.setEndDate(end);
                        }}
                        datePickerStore={reportsStore.datePickerStore}
                        dateReferenceOptions={referenceOptions}
                        displayDateType
                    />
                )}
            </Observer>
        </Grid>,
    );

    controls = controls.concat(
        store.quickFilterFields.slice().map(renderQuickFilter),
    );

    controls.push(unSupportedFilters);

    return (
        <Grid
            item
            xs={12}
            container
            justifyContent="flex-start"
            alignItems={"flex-start"}
        >
            <AcxExpansion
                header="Quick Filters"
                body={
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        spacing={1}
                        alignItems={"flex-start"}
                    >
                        {controls}
                    </Grid>
                }
                rootStyle={{ width: "100%" }}
                expanded
            />
        </Grid>
    );
});

export default ReportCardFilters;
