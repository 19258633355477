import React from "react";
import { WorkflowReviewStore } from "../../Stores/WorkflowReviewStore";
import { useStore } from "utils/useStore";
import ConfirmationDialog from "components/UI/AcxConfirmationDialog";
import { WFRInstanceLevelState } from "./WFRInstanceLevel";
import ReportIcon from "@mui/icons-material/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";

interface WFRConfirmRejectDialogProps {
    open: boolean;
    approveRejectComplete: WFRInstanceLevelResult;
    onApprove: () => void;
    onReject: () => void;
    onComplete: () => void;
    onClose: () => void;
    buttonsDisabled?: boolean;
    state: WFRInstanceLevelState;
    conditionType?: WorkflowConditionType;
}
export type WFRInstanceLevelResult = "approve" | "reject" | "complete" | null;

const WFRApproveRejectCompleteDialog: React.FC<WFRConfirmRejectDialogProps> = ({
    open,
    approveRejectComplete,
    onApprove,
    onReject,
    onComplete,
    onClose,
    buttonsDisabled,
    state,
    conditionType,
}) => {
    const store = useStore(WorkflowReviewStore);
    return (
        <>
            <ConfirmationDialog
                isOpen={open && approveRejectComplete === "approve"}
                confirmButtonText={
                    conditionType === WorkflowConditionType.APT
                        ? "Advance"
                        : "Submit"
                }
                onConfirm={onApprove}
                onClose={onClose}
                title={
                    conditionType === WorkflowConditionType.APT
                        ? `Advance ${store.currentLevelName}`
                        : `Submit ${store.currentLevelName}`
                }
                content={
                    <div>
                        {`Workflow will be ${
                            conditionType === WorkflowConditionType.APT
                                ? "advanced"
                                : "submitted"
                        } by ${store.authStore._user.profile.email}.`}
                    </div>
                }
                buttonsDisabled={buttonsDisabled}
                headerIcon={
                    <CheckCircleIcon fontSize="large" color="success" />
                }
            />
            <ConfirmationDialog
                isOpen={open && approveRejectComplete === "reject"}
                confirmButtonText={"End Workflow"}
                onConfirm={onReject}
                onClose={onClose}
                title={`End ${store.currentLevelName}`}
                content={
                    <div>
                        {`Workflow will be closed by ${store.authStore._user.profile.email}.`}
                    </div>
                }
                buttonsDisabled={buttonsDisabled}
                headerIcon={<ReportIcon fontSize="large" color="error" />}
            />
            <ConfirmationDialog
                isOpen={
                    open &&
                    approveRejectComplete === "complete" &&
                    state === "boss"
                }
                confirmButtonText={"Complete"}
                onConfirm={onComplete}
                onClose={onClose}
                title={`Complete ${store.currentLevelName}`}
                content={
                    <div>
                        {`Workflow will be completed by ${store.authStore._user.profile.email}.`}
                    </div>
                }
                buttonsDisabled={buttonsDisabled}
                headerIcon={
                    <CheckCircleIcon fontSize="large" color="success" />
                }
            />
        </>
    );
};

export default WFRApproveRejectCompleteDialog;
