import { Box, Theme, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Topics } from "components/Conversations/Stores/ConversationsStore";
import { observer } from "mobx-react";
import React from "react";
import Topic from "./Topics/Topic";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        backgroundColor: "#E5E7EB",
        borderRadius: "4px",
        padding: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: theme.spacing(2),
    },
    noTopics: {
        fontSize: "13px",
        fontWeight: 600,
        color: "#D1D5DB",
    },
}));

type TopicsProps = {
    topics: Topics | null;
};

const TopicsContainer: React.FC<TopicsProps> = observer(({ topics }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box className={classes.marginBottom}>
            <Typography
                sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    marginBottom: theme.spacing(1),
                }}
                component="div"
            >
                Key Topics
            </Typography>
            {topics ? (
                <Box className={classes.container}>
                    {!!topics.topic1 && (
                        <Topic
                            name={topics.topic1}
                            description={topics.topic1Reasoning}
                        />
                    )}
                    {!!topics.topic2 && (
                        <Topic
                            name={topics.topic2}
                            description={topics.topic2Reasoning}
                        />
                    )}
                    {!!topics.topic3 && (
                        <Topic
                            name={topics.topic3}
                            description={topics.topic3Reasoning}
                        />
                    )}
                </Box>
            ) : (
                <Typography className={classes.noTopics}>
                    No topics have been generated on this conversation
                </Typography>
            )}
        </Box>
    );
});

export default TopicsContainer;
