import { ITranscriptionSegment } from "services/TranscriptionService";
import { ChatPersona, IChatRecord } from "../Chat/AcxChatViewer";
import { useCallback, useEffect, useState } from "react";
import { addEddyToChatRecords } from "../Chat/utils";
import moment from "moment";
import { isNil, uniqBy } from "lodash";
import { durationDisplay } from "components/SoundClipEditor/Controls/Player";
import EddyBlurb from "components/UI/Eddy/EddyBlurb";
import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import { convertTranscriptToBlurbs } from "../AcxTranscription";
import { SpeakerChannelMap } from "../AcxTranscription/Stores/SpeakerStore/SpeakerStore";
import { P2Results } from "models/P2Results";

interface BaseEddyBlurb {
    text: string;
    timestamp: string;
    eddy?: boolean;
}

interface EddyBlurbWithChannel extends BaseEddyBlurb {
    persona?: never;
    channel: number;
}

interface EddyBlurbWithPersona extends BaseEddyBlurb {
    persona: "Agent" | "Customer";
    channel?: never;
}

type TEddyBlurb = EddyBlurbWithChannel | EddyBlurbWithPersona;

interface ChatBlurbable {
    type: "chat";
    chat: IChatRecord[];
    transcription?: never;
}

interface AudioBlurbable {
    type: "audio";
    transcription: ITranscriptionSegment[];
    chat?: never;
}

type Blurbable = ChatBlurbable | AudioBlurbable;

type EddyBlurbsProps = Blurbable & {
    data: P2Results[];
    blurbClassName?: string;
    variant?: "eddy" | "adverse";
    speakerChannelMap?: SpeakerChannelMap;
};

const EddyBlurbs: React.FC<EddyBlurbsProps> = ({
    type,
    chat,
    data,
    blurbClassName,
    transcription,
    variant = "eddy",
    speakerChannelMap,
}) => {
    const [blurbGroups, setBlurbGroups] = useState<TEddyBlurb[][]>([]);
    const [isUndiarized, setIsUndiarized] = useState(false);

    const setBlurbGroupsFromChat = useCallback(() => {
        if (type !== "chat") return;

        const blurbGroups: TEddyBlurb[][] = [];
        let currentGroup: TEddyBlurb[] = [];

        addEddyToChatRecords({
            records: chat,
            eddyEffectP2Data: data,
        })
            .map<TEddyBlurb>((chat) => {
                return {
                    text: chat.text ?? "",
                    timestamp: moment(chat.timestamp).format("hh:mm"),
                    persona:
                        chat.from === ChatPersona.Agent ? "Agent" : "Customer",
                    eddy: chat.eddy,
                };
            })
            .forEach((blurb, idx, arr) => {
                if (blurb.eddy) {
                    currentGroup.push(blurb);
                } else if (!blurb.eddy && currentGroup.length) {
                    blurbGroups.push(currentGroup);
                    currentGroup = [];
                }
                if (idx === arr.length - 1 && !!currentGroup.length) {
                    blurbGroups.push(currentGroup);
                }
            });
        setBlurbGroups(blurbGroups);
    }, [type, chat, data]);

    const setBlurbGroupsFromTranscript = useCallback(() => {
        if (type !== "audio") return;

        const blurbGroups: TEddyBlurb[][] = [];
        let currentGroup: TEddyBlurb[] = [];

        const blurbs = convertTranscriptToBlurbs(transcription, data, variant);
        const undiarized =
            uniqBy(transcription, (word) => word.channel).length === 1 &&
            blurbs.length === 1 &&
            data.length > 1;

        setIsUndiarized(undiarized);

        blurbs
            .map<TEddyBlurb>((blurb) => ({
                text: blurb.text,
                channel: blurb.channel,
                eddy: blurb.foundEddy,
                timestamp: durationDisplay(blurb.timeInterval[0]),
            }))
            .forEach((blurb, idx, arr) => {
                if (blurb.eddy) {
                    currentGroup.push(blurb);
                } else if (!blurb.eddy && currentGroup.length) {
                    blurbGroups.push(currentGroup);
                    currentGroup = [];
                }
                if (idx === arr.length - 1 && !!currentGroup.length) {
                    blurbGroups.push(currentGroup);
                }
            });
        setBlurbGroups(blurbGroups);
    }, [type, transcription, data, variant]);

    useEffect(() => {
        if (type === "chat") {
            setBlurbGroupsFromChat();
        } else if (type === "audio") {
            setBlurbGroupsFromTranscript();
        }
    }, [type, setBlurbGroupsFromChat, setBlurbGroupsFromTranscript]);

    return (
        <Box>
            {!isUndiarized ? (
                blurbGroups.map((blurbs, idx) => (
                    <span
                        key={`${idx} ${blurbs
                            .map(
                                ({ timestamp, persona }, idx) =>
                                    `${idx}_${persona}_${timestamp}`,
                            )
                            .join("-")}`}
                    >
                        {blurbs.map(
                            ({ persona, timestamp, text, channel }, idx) =>
                                persona ? (
                                    <EddyBlurb
                                        key={`${idx} ${timestamp} ${text}`}
                                        persona={persona}
                                        timestamp={timestamp}
                                        text={text}
                                        className={blurbClassName}
                                        variant={variant}
                                        speakerChannelMap={speakerChannelMap}
                                    />
                                ) : (
                                    !isNil(channel) && (
                                        <EddyBlurb
                                            key={`${idx} ${timestamp} ${text}`}
                                            channel={channel}
                                            timestamp={timestamp}
                                            text={text}
                                            className={blurbClassName}
                                            variant={variant}
                                            speakerChannelMap={
                                                speakerChannelMap
                                            }
                                        />
                                    )
                                ),
                        )}
                        {idx !== blurbGroups?.length - 1 && <Divider />}
                    </span>
                ))
            ) : (
                <Typography
                    style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "#D1D5DB",
                        marginTop: "8px",
                    }}
                >
                    {`There was an issue displaying ${
                        variant === "eddy"
                            ? "Eddy Effect Signals"
                            : "Adverse Event Signals"
                    } for this conversation.${
                        variant === "eddy"
                            ? " You can still view them on the Transcription tab, highlighted in blue."
                            : ""
                    }`}
                </Typography>
            )}
        </Box>
    );
};

export default EddyBlurbs;
