import localForage from "localforage";
import { AcxStore } from "./RootStore";

@AcxStore
export default class LocalStorage {
    private instances: any;
    constructor() {
        this.instances = {};
        this.instances["AcxDefault"] = localForage.createInstance({
            name: "AcxDefault",
            description: "default browser data store",
        });
    }
    getLocalStore = (name?: string) => {
        let localStore: LocalForage;
        if (name) {
            if (this.instances[name]) {
                localStore = this.instances[name];
            } else {
                this.instances[name] = localForage.createInstance({
                    name: name,
                    description: name,
                });
                localStore = this.instances[name];
            }
        } else {
            localStore = this.instances["AcxDefault"];
        }
        return localStore;
    };
}
