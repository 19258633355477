import { Grid, GridSize, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "Styles/Styles";
import FocusPage from "./FocusPage";

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: theme.spacing(3),
            maxWidth: "100%",
            position: "relative",
            margin: "auto",
        },
        column: {
            position: "relative",
            margin: "auto",
        },
    });

type Props = {
    title: string;
    onCancel?: () => void;
    leftSide: React.ReactNode;
    rightSide: React.ReactNode;
    loading?: boolean;
    onSave?: () => Promise<void>;
    showSave?: boolean;
    cancelText?: string;
    saveText?: string;
    leftSize?: GridSize;
    rightSize?: GridSize;
};

function FocusColumns(props: Props) {
    const navigate = useNavigate();
    const classes = useStyles(styles);
    const onCancel = () => {
        // history.push("/app");
        // history.goBack();
        navigate(-1);
    };
    return (
        <FocusPage
            title={props.title}
            onCancel={onCancel}
            loading={props.loading}
            showSave={props.showSave}
            onSave={props.onSave}
            cancelText={props.cancelText}
            saveText={props.saveText}
        >
            <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                <Grid
                    className={classes.content}
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={6}
                >
                    <Grid item container xs={props.leftSize ?? 5}>
                        {props.leftSide}
                    </Grid>
                    <Grid item container xs={props.rightSize ?? 7}>
                        {props.rightSide}
                    </Grid>
                </Grid>
            </div>
        </FocusPage>
    );
}

export default FocusColumns;
