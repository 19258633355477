import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import React from "react";
import useStyles from "Styles/Styles";
import { ReportsNavigation, ReportsRoutes } from "./ReportsNavigation";

const styles = (theme: Theme) =>
    createStyles({
        sideControlMain: {
            padding: 0,
        },
    });
function Reports() {
    const classes = useStyles(styles);

    return (
        <SideControlsPage
            mainContent={<ReportsRoutes />}
            enableMinimize
            containerOffsetPixels={64}
            drawerContent={<ReportsNavigation />}
            drawerLocation="left"
            mainGridAlignItems="stretch"
            mainGridJustify="flex-start"
            mainGridSpacing={0}
            mainContentClasses={classes.sideControlMain}
        />
    );
}

export default Reports;
