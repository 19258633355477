import React from "react";
import icon from "../Eddy-effect-icon.png";

interface IEddyIconSvg {
    opacity?: number;
    width?: number;
    height?: number;
    style?: React.CSSProperties;
    className?: string;
}

const EddyIconSvg: React.FC<IEddyIconSvg> = (props) => {
    return (
        <img
            className={props.className}
            src={icon}
            alt="Eddy Effect Icon"
            style={props.style}
        />
    );
};

export default EddyIconSvg;
