import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import React from "react";

type Props = {
    setFilter: React.Dispatch<React.SetStateAction<string>>;
};

const OrganizationAnalystGroupsListSearch: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    setFilter,
}) => {
    return (
        <div
            style={{
                flexBasis: "100%",
            }}
        >
            <AcxMainTextField
                id="montageList-search"
                value=""
                placeholderText="Search..."
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                    setFilter(evt.currentTarget.value.toLowerCase())
                }
            />
        </div>
    );
};

export default OrganizationAnalystGroupsListSearch;
