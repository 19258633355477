import React from "react";
import { Route, Routes } from "react-router";
import TopicDetail from "../TopicDetail/TopicDetail";
import UnidentfiedTopicsList from "./UnidentifiedTopicsList";

type Props = {
    id: string;
};

const UnidentifiedTopicsWrapper: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ id }) => {
    return (
        <Routes>
            <Route
                path={"/"}
                element={<UnidentfiedTopicsList orgId={id} />}
            ></Route>
            <Route
                path={`:topicId`}
                element={<TopicDetail orgId={id} />}
            ></Route>
        </Routes>
    );
};

export default UnidentifiedTopicsWrapper;
