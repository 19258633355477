import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AcxSelectCreatable from "components/UI/Select/BaseSelectComponents/AcxSelectCreatable";
import { observer } from "mobx-react";
import { FieldUse } from "models/Reporting/ReportField";
import React from "react";
import { VizType } from "../../../../../models/Reporting/ReportModel";
import { useStore } from "../../../../../utils/useStore";
import AcxMainTextField from "../../../../UI/AcxMainTextFieldGrid";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import CreateGroup from "../CreateGroup";
import ReportTypeToggle from "../ReportTypeToggle";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },

    aclButtons: {
        textTransform: "none",
        fontWeight: "bold",
    },
    aclInfoText: {
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        paddingTop: "9px",
    },
    mainSectionTitle: {
        marginTop: theme.spacing(1.5),
    },
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    mainSectionSubtitleText: {
        fontFamily: "Inter",
        opacity: "0.5",
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
        maxWidth: "300px",
    },
}));

interface OwnProps {}

type Props = OwnProps;

const ReportDetailSection: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);
    //const reportStore = useStore(ReportsStore);
    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        store.currentReport?.setName((event.target as HTMLInputElement).value);
    }

    function getCurrentReportGroup() {
        const grp = store.reportGroupOptions?.find(
            (g) => g.id === store.currentReport?.groupId,
        );
        return grp;
    }

    function onVizTypeChange(vizType: VizType) {
        if (
            (store.currentReport?.vizType !== VizType.Table &&
                vizType === VizType.Table) ||
            (store.currentReport?.vizType === VizType.Table &&
                vizType !== VizType.Table)
        ) {
            if (store.currentReport) {
                store.currentReport.reportFields
                    .filter(
                        (value) =>
                            value.fieldUse !== undefined &&
                            [
                                FieldUse.VirtualValue,
                                FieldUse.VirtualScored,
                                FieldUse.X,
                                FieldUse.Y,
                            ].includes(value.fieldUse),
                    )
                    .forEach((value) => {
                        store.currentReport?.removeReportField(value);
                    });
            }
        }

        store.currentReport?.setVizOptions(null);
        store.currentReport?.setVizType(vizType);
        /*if (store.currentReport) {
            if (
                store.currentReport.reportFields.filter(
                    (value) =>
                        value.fieldUse !== undefined &&
                        [FieldUse.X, FieldUse.Y].includes(value.fieldUse),
                ).length === 2
            ) {
                reportStore.reportDataViewModel.loadPreviewReportData(
                    reportStore.orgSelectorStore.orgId,
                    store.prepareReportForPreview,
                    store.currentReport?.vizType === VizType.Table,
                );
            }
        }*/
    }

    return (
        <>
            {store.createGroupOpen && <CreateGroup />}
            <Grid item xs={5}>
                <AcxSelectCreatable
                    isClearable
                    isLoading={false}
                    id="report-group-options"
                    options={store.reportGroupOptions}
                    valueField="id"
                    labelField="groupName"
                    placeholder="Select a report group"
                    inputLabel="Report Group"
                    isMulti={false}
                    defaultValue={getCurrentReportGroup()}
                    onChange={store.currentReport?.setGroupId}
                    onCreate={store.initCreateGroup}
                />
            </Grid>
            <Grid item xs={12} className={classes.vertSpace}>
                <AcxMainTextField
                    id={"report-editor-name"}
                    value={store.currentReport?.name}
                    labelText={"Report Name"}
                    onChange={handleNameChange}
                />
            </Grid>

            <Grid item xs={12} className={classes.vertSpace}>
                <Typography className={classes.sectionTitle}>
                    Report Type
                </Typography>
                <ReportTypeToggle
                    defaultValue={store.currentReport?.vizType}
                    onChange={onVizTypeChange}
                />
            </Grid>
        </>
    );
});

export default ReportDetailSection;
