import { Observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BaseDashboard from "../../../Layouts/Dashboard/BaseDashboard";
import { AuthStore } from "../../../stores/AuthStore";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import { useStore } from "../../../utils/useStore";
import { Routes } from "../../Navigation/Routes";
import { AgentDashboardStore } from "./Stores/AgentDashboardStore";
import AgentNavigator from "./Views/AgentNavigator";

// interface MatchParams {
//     agentId?: string;
//     orgId?: string;
//     agentName?: string;
// }
// interface OwnProps extends RouteComponentProps<Partial<MatchParams>> {}

// type Props = OwnProps;

const AgentDashboard = () => {
    const store = useStore(AgentDashboardStore);
    const location = useLocation();
    const orgStore = useStore(AuthStore).orgStore;
    const navigate = useNavigate();
    const [segs, setSegs] = useState<string[]>();
    const { elementHeight, elementWidth, theRef } =
        useResizeObserver<HTMLDivElement>();

    useEffect(() => {
        const parms = location.pathname;
        const seg = parms.split("/");
        setSegs(seg);
        if (seg.length > 0) {
            const orgId = seg[4];
            const agentId = seg[5];
            const agentName = seg[6];
            orgStore.onActiveOrgIdChange(orgId);
            store.setAgentAndOrg(orgId, agentId, agentName);
        } else {
            navigate(Routes.routePrefix + Routes.AGENT_COACHING, {
                replace: true,
            });
        }
        store.dashboardStore.refreshAll();
    }, [store, orgStore, location.pathname, navigate]);

    const customControls = useCallback(
        () => <AgentNavigator currentAgentName={decodeURI(segs?.[6] || "")} />,
        [segs],
    );

    return (
        <Observer>
            {() => (
                <div
                    style={{
                        height: "100%",
                        position: "relative",
                        marginLeft: store.dashboardStore.isWidgetsDrawerOpen
                            ? "340px"
                            : "0px",
                        width: `calc(100% - ${
                            store.dashboardStore.isWidgetsDrawerOpen
                                ? "340px"
                                : "0px"
                        }`,
                    }}
                    ref={theRef}
                >
                    {elementWidth && elementHeight && (
                        <BaseDashboard
                            width={elementWidth ?? 0}
                            customControls={customControls}
                            height={elementHeight ?? 0}
                            dashboardStore={store.dashboardStore}
                        />
                    )}
                </div>
            )}
        </Observer>
    );
};

export default AgentDashboard;
