import {
    action,
    observable,
    reaction,
    runInAction,
    makeObservable,
} from "mobx";
import { IWidget } from "../models/Dashboard/Widget";
import { WidgetsService } from "../services/WidgetsService";
import { BaseStore } from "./BaseStore";
import { AcxStore } from "./RootStore";

@AcxStore
export class PublishedWidgetStore extends BaseStore {
    private readonly widgetsService: WidgetsService = new WidgetsService();

    @observable availableWidgets: IWidget[] = [];
    @observable orgId?: string;

    constructor() {
        super("Published Widget Store");

        makeObservable(this);

        reaction(
            (r) => this.orgId,
            (arg) => {
                this.setupAsyncTask("Loading Published Widgets", () =>
                    this.loadPublished(arg),
                );
            },
            { fireImmediately: true },
        );
    }

    @action
    setOrgId(orgId?: string) {
        this.orgId = orgId;
    }

    @action
    refreshWidgets = () => {
        this.setupAsyncTask("Loading Published Widgets", () =>
            this.loadPublished(this.orgId),
        );
    };

    @action
    async loadPublished(orgId?: string) {
        const res = await this.widgetsService.getPublishedWidgets(orgId);
        res.forEach((value) => {
            if (value.args) {
                value.args = JSON.parse(
                    (value.args ?? JSON.stringify({})) as unknown as string,
                );
            }
        });

        runInAction(() => {
            this.availableWidgets = res;
        });
    }
}
