import React, { CSSProperties } from "react";
import AcxSelectSingle from "./BaseSelectComponents/AcxSelectSingle";
import Organization from "models/Organization";
import { Observer } from "mobx-react-lite";
import { AuthStore } from "../../../stores/AuthStore";
import { useStore } from "../../../utils/useStore";

interface IOrgSelectTemporg {
    id: string;
    onLoading?: (isLoading: boolean) => void;
    showLoading?: boolean;
    onError?: (errorMessage?: string) => void;
    onSelect?: (org: Organization) => void;
    onDisplay?: (isVisible: boolean) => void;
    label?: string;
    fullWidth?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    disabled?: boolean;
    autoSelectUsersOrg?: boolean;
    defaultValue?: any;
    customStyle?: any;
    orgId?: string;
    customRootStyles?: CSSProperties;
    onChange: (val) => void;
}

function OrgSelectTemporg(props: IOrgSelectTemporg) {
    const authStore = useStore(AuthStore);

    // const handleChange = async (val) => {
    //     console.log("org select temp org mutating temp org");
    // authStore.orgStore.temporarySelectOrganization = val;
    // };

    return (
        <Observer>
            {() => (
                <div
                    style={{
                        ...{
                            display:
                                (authStore.orgStore?.organizations?.length ||
                                    0) > 1 ?? false
                                    ? "block"
                                    : "none",
                        },
                        ...props.customRootStyles,
                    }}
                >
                    <AcxSelectSingle
                        inputLabel={props.label}
                        fullWidth={props.fullWidth}
                        isDisabled={props.disabled}
                        defaultValue={
                            props.defaultValue ||
                            authStore.orgStore?.selectedOrganization
                        }
                        id={`org-selector-${props.id}`}
                        labelField="name"
                        valueField="id"
                        options={authStore.orgStore?.organizations ?? []}
                        onChange={props.onChange}
                        placeholder={
                            props.showLoading &&
                            authStore.orgStore.anyTaskLoading
                                ? "Loading.."
                                : "Select Organization"
                        }
                        menuPortalTarget={props.menuPortalTarget}
                        customStyle={props.customStyle}
                    />
                </div>
            )}
        </Observer>
    );
}

export default OrgSelectTemporg;
