import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const RightArrowSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.293 0.293C8.48053 0.105529 8.73484 0.000213623 9 0.000213623C9.26516 0.000213623 9.51947 0.105529 9.707 0.293L15.707 6.293C15.8945 6.48053 15.9998 6.73484 15.9998 7C15.9998 7.26516 15.8945 7.51947 15.707 7.707L9.707 13.707C9.5184 13.8892 9.2658 13.99 9.0036 13.9877C8.7414 13.9854 8.49059 13.8802 8.30518 13.6948C8.11977 13.5094 8.0146 13.2586 8.01233 12.9964C8.01005 12.7342 8.11084 12.4816 8.293 12.293L12.586 8H1C0.734784 8 0.48043 7.89464 0.292893 7.70711C0.105357 7.51957 0 7.26522 0 7C0 6.73478 0.105357 6.48043 0.292893 6.29289C0.48043 6.10536 0.734784 6 1 6H12.586L8.293 1.707C8.10553 1.51947 8.00021 1.26516 8.00021 1C8.00021 0.734836 8.10553 0.480528 8.293 0.293Z"
                fill="#A1A1AA"
            />
        </svg>
    );
};

export default RightArrowSvg;
