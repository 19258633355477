import { Button, Theme, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    drawerToggle: (props: Props) => ({
        position: "absolute",
        top: "50%",
        left: props.left,
        zIndex: theme.zIndex.drawer,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: props.drawerOpen
            ? theme.spacing(0.75)
            : theme.spacing(1.25),
        paddingBottom: props.drawerOpen
            ? theme.spacing(0.75)
            : theme.spacing(1.25),

        backgroundColor: theme.palette.white.main,
        boxShadow: theme.shadows[1],
        borderRadius: theme.shape.borderRadius,
    }),

    chevonColor: (props: Props) => ({
        color: props.drawerOpen ? "#1F1F1F" : theme.palette.primary.main,
        opacity: props.drawerOpen ? 0.5 : 1,
    }),
}));

interface OwnProps {
    drawerOpen: boolean;
    left?: number;

    onClick?: () => void;
    style?: React.CSSProperties;
}

type Props = OwnProps;

const DrawerToggleButton: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();
    return (
        <>
            {props.left !== undefined && (
                <Button
                    onClick={props.onClick}
                    style={{ minWidth: "unset", ...props.style }}
                    variant={props.drawerOpen ? undefined : "outlined"}
                    size="small"
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: props.left,
                        zIndex: theme.zIndex.drawer,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: props.drawerOpen
                            ? theme.spacing(0.75)
                            : theme.spacing(1.25),
                        paddingBottom: props.drawerOpen
                            ? theme.spacing(0.75)
                            : theme.spacing(1.25),

                        backgroundColor: theme.palette.white.main,
                        boxShadow: theme.shadows[1],
                        borderRadius: theme.shape.borderRadius,
                    }}
                >
                    {props.drawerOpen ? (
                        <ChevronLeftIcon className={classes.chevonColor} />
                    ) : (
                        <ChevronRightIcon className={classes.chevonColor} />
                    )}
                </Button>
            )}
        </>
    );
};

export default DrawerToggleButton;
