import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import React, { useEffect, useState } from "react";
import { useForm } from "shared/hooks/useForm";
import useStyles from "Styles/Styles";
import RightArrowSvg from "SvgIcons/ConfigIcons/RightArrowSvg";
import { useStore } from "utils/useStore";
import useOrganizationHierarchy from "../../hooks/useOrganizationHierarchy";
import { AnalystGroup, AnalystGroupMember } from "../../types/AnalystGroup";
import AnalystGroupService from "./AnalystGroup.service";
import HierarchyPosition from "./HierarchyPosition";

const styles = (theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            position: "relative",
            width: 424,
            height: 500,
            filter: "drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))",
            borderRadius: "8px",
        },
        hierarchyContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            position: "relative",
            width: 600,
            height: 600,
            filter: "drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))",
            borderRadius: "8px",
        },
        dialogContentWithBg: {
            width: "100%",
            backgroundColor: theme.palette.lightgray.background,
        },
        titleContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            position: "static",
            width: "424px",
            height: "136px",
            left: "0px",
            top: "0px",
            background: "#FFFFFF",
            flex: "none",
            order: 0,
            flexGrow: 0,
            margin: "0px 0px",
        },
        actions: {
            display: "flex",
            alignItems: "flex-end",
            padding: "24px",
            width: "100%",
        },
        title: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "32px",
            color: theme.palette.blackFont.main,
        },
        subtitle: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#71717A",
        },
        closeButton: {
            position: "absolute",
            top: 16,
            color: "#A1A1AA",
            right: 8,
        },
        closeIcon: {
            height: 20,
            width: 20,
        },
        error: {
            fontSize: "13px",
            color: theme.palette.error.main,
            textAlign: "center",
            padding: theme.spacing(1),
        },
        actionButton: {
            width: "inherit",
        },
        cancel: {
            color: theme.palette.blackFont.main,
            height: "30px",
            overflow: "hidden",
            fontSize: "13px",
            textAlign: "center",
            fontFamily: '"Inter", sans-serif',
            fontWeight: "bold",
            lineHeight: "20px",
            marginLeft: "0",
            whiteSpace: "nowrap",
            marginRight: "0",
            textOverflow: "ellipsis",
            letterSpacing: "0",
            textTransform: "none",
            background: "#FFFFFF",
            border: "1px solid #E4E4E7",
            "&:hover": {
                backgroundColor: theme.palette.white.main,
            },
        },
        fullWidth: {
            width: "100%",
        },
        required: {
            "&:after": {
                color: theme.palette.red.main,
                content: '" *" !important',
            },
        },
        helperText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        helperTextError: {
            color: `${theme.palette.error.main} !important`,
        },
    });

const validation = {
    groupName: {
        required: {
            message: "Group name is required.",
        },
    },
    members: {
        required: {
            message: "At least one group manager is required.",
        },
    },
};

enum States {
    MainDialog,
    ServiceHierarchySelector,
}

type Props = {
    orgId: string;
    id: string;
    close: () => void;
    refetch: () => void;
    data?: AnalystGroup;
    memberOptions?: AnalystGroupMember[];
    isLoadingUsers: boolean;
};

const OrganizationAnalystGroupsDetail: React.FC<Props> = ({
    orgId,
    id,
    close,
    refetch,
    data = {
        groupName: "",
        description: "",
        organizationStructureMemberIds: [],
        members: [],
    },
    memberOptions = [],
    isLoadingUsers,
}) => {
    const classes = useStyles(styles);
    const {
        value,
        registerSubmit,
        errors,
        onChange,
        isSubmitting,
        patchValue,
    } = useForm<AnalystGroup>(data, validation);
    const [state, setState] = useState(States.MainDialog);
    const { hierarchy, hierarchyHash } = useOrganizationHierarchy({
        id: orgId,
    });
    const [hierarchyValue, setHierarchyValue] = useState<string[]>([]);
    const messageStore = useStore(MessageStore);

    useEffect(() => {
        patchValue(data);
    }, [data, patchValue]);

    useEffect(() => {
        setHierarchyValue(value?.organizationStructureMemberIds || []);
    }, [value]);

    const registerOnChange =
        (prop: keyof AnalystGroup) =>
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(prop, e.target.value);
        };

    const onChangeHierarchy = () => {
        onChange("organizationStructureMemberIds", hierarchyValue);
        setState(States.MainDialog);
    };

    const submit = async () => {
        return AnalystGroupService.saveAnalystGroup({
            ...value,
            organizationId: orgId,
        });
    };

    const onSuccess = () => {
        close();
        messageStore.logMessage(`Successfully saved analyst group.`, "success");
        refetch();
    };

    const onFail = (message: string) => {
        messageStore.logMessage(
            `Failed to save analyst group${message ? `: ${message}` : ""}`,
            "error",
        );
    };

    return (
        <>
            <Dialog
                classes={{ paper: classes.container }}
                scroll={"paper"}
                open={state === States.MainDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    className={classes.titleContainer}
                >
                    <Typography
                        component="span"
                        variant="h1"
                        className={classes.title}
                    >
                        {id === "add" ? "New" : "Update"} Analyst Group
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color={"textSecondary"}
                        className={classes.subtitle}
                    >
                        {id === "add"
                            ? "Create a new group, assign a group owner, and set its position in the service hierarchy."
                            : "Update this group, assign a group owner, and set its position in the service hierarchy."}
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={close}
                        size="large"
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.fullWidth}>
                    <Grid container spacing={1}>
                        <Grid container item spacing={1} wrap="wrap">
                            <Grid item xs={12}>
                                <AcxMainTextField
                                    showAllErrors={true}
                                    onChange={registerOnChange("groupName")}
                                    id="groupName"
                                    labelText="Group Name"
                                    value={value.groupName}
                                    error={!!errors?.fieldErrors?.groupName}
                                    helperText={errors?.fieldErrors?.groupName}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcxSelectMulti
                                    id="members"
                                    options={memberOptions}
                                    valueField="userId"
                                    labelField="name"
                                    inputLabel="Group Manager"
                                    skeleton={isLoadingUsers}
                                    defaultValue={value.members}
                                    onChange={(e) => {
                                        onChange("members", e);
                                    }}
                                    containerHeight="auto"
                                    fullWidth
                                    required={true}
                                    error={!!errors?.fieldErrors?.members}
                                    helperText={errors?.fieldErrors?.members}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcxMainTextField
                                    showAllErrors={true}
                                    onChange={registerOnChange("description")}
                                    id="description"
                                    labelText="Description"
                                    value={value.description}
                                    error={!!errors?.fieldErrors?.description}
                                    helperText={
                                        errors?.fieldErrors?.description
                                    }
                                    multiline
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AcxInputLabel
                                    labelText="Service Hierarchy Position"
                                    className={classes.required}
                                />
                                <Button
                                    onClick={() => {
                                        setState(
                                            States.ServiceHierarchySelector,
                                        );
                                    }}
                                    variant="contained"
                                    disableElevation
                                    className={classes.cancel}
                                >
                                    {value.organizationStructureMemberIds
                                        ?.length ? (
                                        <span style={{ marginRight: 12 }}>
                                            <span
                                                style={{
                                                    color: "#a7a7af",
                                                    fontWeight: "normal",
                                                }}
                                            >
                                                Current position:
                                            </span>{" "}
                                            <span style={{ color: "#3464d5" }}>
                                                {
                                                    value
                                                        .organizationStructureMemberIds
                                                        .length
                                                }{" "}
                                                of{" "}
                                                {
                                                    Object.keys(hierarchyHash)
                                                        .length
                                                }
                                            </span>
                                        </span>
                                    ) : (
                                        <span style={{ marginRight: 12 }}>
                                            Click to set...
                                        </span>
                                    )}
                                    <RightArrowSvg />
                                </Button>
                                <div>
                                    {value.organizationStructureMemberIds?.map(
                                        (memberId) => (
                                            <Chip
                                                size="small"
                                                label={hierarchyHash[memberId]}
                                                key={memberId}
                                                style={{
                                                    marginRight: 6,
                                                    marginTop: 6,
                                                }}
                                            />
                                        ),
                                    )}
                                </div>
                                <div>
                                    {!!errors?.fieldErrors
                                        ?.organizationStructureMemberIds && (
                                        <AcxInputLabel
                                            className={clsx({
                                                [classes.helperText]: true,
                                                [classes.helperTextError]: true,
                                            })}
                                            showAllErrors
                                        >
                                            {
                                                errors?.fieldErrors
                                                    ?.organizationStructureMemberIds
                                            }
                                        </AcxInputLabel>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography className={classes.error} component="div">
                        {errors.form?.join("\n")}
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <div>
                        <Button
                            onClick={close}
                            variant="contained"
                            disableElevation
                            className={classes.cancel}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <AcxButton
                            fullWidth
                            onClick={registerSubmit(submit, {
                                onSuccess,
                                onFail,
                            })}
                            color="primary"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {id === "add" ? "Create" : "Update"} Group
                        </AcxButton>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog
                classes={{ paper: classes.hierarchyContainer }}
                scroll={"paper"}
                open={state === States.ServiceHierarchySelector}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="lg"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    className={classes.titleContainer}
                >
                    <Typography
                        component="span"
                        variant="h1"
                        className={classes.title}
                    >
                        Service Hierarchy
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color={"textSecondary"}
                        className={classes.subtitle}
                    >
                        Select the service hierarchy structure members that this
                        group should belong to.
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={() => {
                            setHierarchyValue(
                                value.organizationStructureMemberIds,
                            );
                            setState(States.MainDialog);
                        }}
                        size="large"
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </DialogTitle>

                <DialogContent className={classes.dialogContentWithBg}>
                    <Grid container spacing={1} style={{ paddingTop: "12px" }}>
                        <Grid container item spacing={1} wrap="wrap">
                            <Grid item xs={12}>
                                <HierarchyPosition
                                    options={hierarchy}
                                    value={hierarchyValue}
                                    onChange={setHierarchyValue}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography className={classes.error} component="div">
                        {/* {props.errorMessage} */}
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <div>
                        <Button
                            onClick={() => {
                                setHierarchyValue(
                                    value.organizationStructureMemberIds,
                                );
                                setState(States.MainDialog);
                            }}
                            variant="contained"
                            disableElevation
                            className={classes.cancel}
                        >
                            Go Back
                        </Button>
                    </div>
                    <div>
                        <AcxButton
                            fullWidth
                            onClick={onChangeHierarchy}
                            color="primary"
                        >
                            Save Position
                        </AcxButton>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrganizationAnalystGroupsDetail;
