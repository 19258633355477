import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import React from "react";
import AcxExpansion from "components/UI/AcxExpansion";
import { Stack } from "@mui/material";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { useStore } from "utils/useStore";
import AcxButton from "components/UI/AcxButton";
import { toJS } from "mobx";
import theme from "Theme/AppTheme";
import AcxMainTextField from "components/UI/AcxMainTextField";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme: Theme) => ({
    expansionHeader: {
        height: "48px",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    expansionBody: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    chevronExpanded: {
        transform: "rotate(180deg)",
    },
    fontSize18: {
        fontSize: "18px",
    },
    fontSize14: {
        fontSize: "14px",
    },
    dividerMargins: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    footerMargins: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    grayBg: {
        backgroundColor: "#F9FAFB!important",
    },
    applyControlsFloat: {
        position: "sticky",
        bottom: 0,
        backgroundColor: "white",
        zIndex: 100,
        paddingBottom: theme.spacing(2),
    },
    searchInputContainer: {
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

export const localStorageFilterInputKey = "additionalFilterInputs";

export const basicFilterCatTitle = "Basic Filters";
export const addtlMetadataCatTitle = "Additional Metadata";

export const mediaTypeTitle = "Media Type";
export const callDirectionTitle = "Call Direction";
export const clientCallIdTitle = "Client Call Id";

export interface ConversationAdditionalFilterCategory {
    title: string;
    subtitle: string;
    filters: ConversationAdditionalFilter[];
}

export interface ConversationAdditionalFilter {
    filterTitle: string;
    isActive: boolean; // is active in the conversation filter drawer
    id: string;
    storeObjectKey: string;
    filterLabel?: string;
    isShownInPanel: boolean;
}

export default function ManageFilterInputDrawer() {
    const classes = useStyles();

    const store = useStore(ConversationsStore);

    const [filterCategories, setFilterCategories] = React.useState<
        ConversationAdditionalFilterCategory[]
    >(toJS(store.additionalFilterCategories));

    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const checkboxHandler = (
        categoryTitle: ConversationAdditionalFilterCategory["title"],
        filterId: string,
    ) => {
        const filterCatCopy = [...filterCategories];

        const clickedItem = filterCatCopy
            .find((cat) => cat.title === categoryTitle)
            ?.filters.find((filt) => filt.id === filterId);
        if (clickedItem) {
            clickedItem.isActive = !clickedItem.isActive;
        }

        setFilterCategories(filterCatCopy);
    };

    const renderFilterItem = (
        categoryItem: ConversationAdditionalFilterCategory,
        filterItem: ConversationAdditionalFilter,
    ) => {
        return (
            <FormControlLabel
                key={filterItem.id}
                label={filterItem.filterLabel ?? filterItem.filterTitle}
                control={
                    <Checkbox
                        onChange={() => {
                            checkboxHandler(categoryItem.title, filterItem.id);
                        }}
                        checked={filterItem.isActive}
                    />
                }
            />
        );
    };

    // State to track the currently selected category
    const [selectedIndex, setSelectedIndex] = React.useState(null);

    // Event handler for when an category is clicked
    const handleItemClick = (index) => {
        setSelectedIndex(index);
    };

    const handleSearchInputChange = (evt) => {
        setSearchTerm(evt.target.value);
    };

    const handleApplyFilters = () => {
        store.setAdditionalFilterCategories(filterCategories);

        store.localStorage.setItem(
            localStorageFilterInputKey +
                store.authStore.orgStore.selectedOrganization?.id,
            JSON.stringify(filterCategories),
        );
        setSearchTerm("");
        store.toggleManageFilterInput();
    };

    const renderCategory = (
        categoryItem: ConversationAdditionalFilterCategory,
        index: number,
    ) => (
        <Grid onClick={() => handleItemClick(index)} key={index}>
            <AcxExpansion
                expanded={true}
                header={
                    <Stack>
                        <Box fontWeight={"bold"} fontSize={14}>
                            {categoryItem.title}
                        </Box>

                        {/* Omit category subtitle for now
                                            <Box
                                                fontWeight={"normal"}
                                                fontSize={12}
                                            >
                                                {categoryItem.subtitle}
                                            </Box> */}
                    </Stack>
                }
                body={
                    <Stack>
                        {categoryItem.filters
                            .filter((item) =>
                                item.filterLabel
                                    ? searchTerm
                                        ? item.filterLabel
                                              .toLowerCase()
                                              .includes(
                                                  searchTerm.toLowerCase(),
                                              )
                                        : true
                                    : searchTerm
                                    ? item.filterTitle
                                          .toLowerCase()
                                          .includes(searchTerm.toLowerCase())
                                    : true,
                            )
                            .map((filterItem) =>
                                renderFilterItem(categoryItem, filterItem),
                            )}
                    </Stack>
                }
                headerClass={
                    classes.expansionHeader +
                    " " +
                    (index === selectedIndex && classes.grayBg)
                }
                bodyClass={
                    classes.expansionBody +
                    " " +
                    (index === selectedIndex && classes.grayBg)
                }
                conversationVariant
                chevronExpandedClass={classes.chevronExpanded}
            />
        </Grid>
    );

    return (
        <VerticalDrawerContentTemplate
            content={
                <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12}>
                        <Grid
                            item
                            xs={12}
                            className={classes.searchInputContainer}
                        >
                            <AcxMainTextField
                                id="search filter input"
                                placeholderText="Search filters"
                                fullWidth
                                startAdornment={<SearchIcon color="disabled" />}
                                value={searchTerm}
                                onChange={handleSearchInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.dividerMargins}>
                            <Divider />
                        </Grid>
                        {filterCategories.map(renderCategory)}
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        style={{ alignSelf: "flex-end" }}
                        className={classes.applyControlsFloat}
                        justifyContent="space-around"
                    >
                        <Grid item xs={12} className={classes.footerMargins}>
                            <Divider />
                        </Grid>
                        <Grid item xs={5} paddingTop="10px">
                            <AcxButton
                                fullWidth
                                id="conversations-manage-filter-input-cancel-changes-button"
                                color="white"
                                onClick={() => {
                                    setFilterCategories(
                                        toJS(store.additionalFilterCategories),
                                    );
                                    setSearchTerm("");
                                    store.toggleManageFilterInput();
                                }}
                            >
                                Cancel
                            </AcxButton>
                        </Grid>
                        <Grid item xs={5} paddingTop="10px">
                            <AcxButton
                                fullWidth
                                id="conversations-manage-filter-input-apply-changes-button"
                                color="primary"
                                onClick={handleApplyFilters}
                            >
                                Apply
                            </AcxButton>
                        </Grid>
                    </Grid>
                </Grid>
            }
            title="Additional Filters"
            subTitle="Add the filters you want to see when filtering conversations."
            customMarginTop="72px"
            width="400px"
            wrapSubtitle
            contentStyles={{
                height: "calc(100% - 107px)",
                paddingLeft: 0,
                paddingRight: 0,
                display: "flex",
            }}
            titleStyles={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(1),
                position: "sticky",
                top: 0,
            }}
            titleClass={classes.fontSize18}
            subTitleStyles={{
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            }}
            subtitleClass={classes.fontSize14}
            arrowCloseIcon
            onClose={store.toggleManageFilterInput}
        />
    );
}
