import { action, makeObservable, observable } from "mobx";
import moment from "moment";
import { AcxStore } from "stores/RootStore";

const ERROR_DISPLAY_MILLIS = 1 * 60 * 1000; // 1 minute

export type Severity = "warning" | "info" | "error" | "success";

interface AcxMessage {
    text: string;
    timestamp: number;
    severity: Severity;
    options?: MessageOptions;
}

type MessageOptions = {
    buttonText: {
        approve: string;
        deny: string;
    };
    buttonAction: {
        approve: () => void | Promise<void>;
        deny: () => void | Promise<void>;
    };
    timeoutOverride?: number;
};

@AcxStore
export default class MessageStore {
    timeout: NodeJS.Timeout;
    timeoutActive = false;

    @observable timeoutDuration: number = ERROR_DISPLAY_MILLIS;
    @observable messageQueue: AcxMessage[] = [];
    @observable messageHistory: AcxMessage[] = [];
    @observable currentMessage?: AcxMessage;
    @observable showMessage = false;

    constructor() {
        makeObservable(this);
    }

    @action setTimeoutDuration(duration: number) {
        this.timeoutDuration = duration;
    }

    @action logError(message: string) {
        this.logMessage(message, "error");
    }

    @action logWarning(message: string) {
        this.logMessage(message, "warning");
    }

    @action logInfo(message: string) {
        this.logMessage(message, "info");
    }

    @action logMessage = (
        message: string,
        severity: Severity,
        options?: MessageOptions,
    ) => {
        const newError = {
            text: message,
            timestamp: moment.now(),
            severity: severity,
            options,
        };

        this.messageQueue = [newError, ...this.messageQueue];

        if (!this.timeoutActive) {
            this.showNextMessage();
        }
    };

    @action showNextMessage = () => {
        clearTimeout(this.timeout);
        const nextMessage = this.messageQueue.pop();
        if (!nextMessage) {
            this.timeoutActive = false;
            this.showMessage = false;
            return;
        }

        this.showMessage = true;
        this.currentMessage = nextMessage;
        this.messageHistory.push(this.currentMessage);

        this.timeout = setTimeout(
            action(() => {
                nextMessage.options?.buttonAction.deny();
                this.showNextMessage();
            }),
            nextMessage.options?.timeoutOverride ?? this.timeoutDuration,
        );

        this.timeoutActive = true;
    };
}
