import * as signalR from "@microsoft/signalr";
import { AnalystDashboardStore } from "components/Dashboard/Analyst/Stores/AnalystDashboardStore";
import { action, makeObservable, reaction } from "mobx";
import { User } from "oidc-client";
import { AcxStoreFactory } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { NotificationAcknowledgeService } from "../../services/NotificationAcknowledgeService";
import { AuthStatus, AuthStore } from "../AuthStore";
import MessageStoreBase, {
    NotificationArgument,
    NotificationDetails,
    NotificationType,
} from "../RealTimeMessages/MessageStoreBase";
import { EvaluationActionNotificationArg } from "./Models/EvalActionNotification";
import moment, { Moment } from "moment";
import AISummariesStore from "components/Signals/Store/AISummariesStore";
import ClassifierBuilderV2ReclassifyDialogStore from "components/Classifiers/ClassifierBuilderV2/Stores/ClassifierBuilderV2ReclassifyDialogStore";

export interface INotificationTransformer<T = any> {
    (arg: T): NotificationArgument;
}
// ToDo: changed in upgrade
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface INotificationTransformFactory<T = any> {
    (...args: any[]): INotificationTransformer;
}

@AcxStoreFactory(false)
export default class NotificationStore {
    private readonly notificationAcknowledgeService =
        new NotificationAcknowledgeService();
    readonly messageStore: MessageStoreBase;
    readonly aiSummariesStore: AISummariesStore;
    private connection: signalR.HubConnection;

    private user: User | null = null;
    private orgId: string | undefined;

    constructor(private rootStore: IRootStore) {
        makeObservable(this);
        this.messageStore = rootStore.getStore(MessageStoreBase);
        this.aiSummariesStore = rootStore.getStore(AISummariesStore);

        reaction(
            () => ({
                user: rootStore.getStore(AuthStore)?._user,
                activeOrgId: rootStore.getStore(AuthStore)?.activeOrgId,
                status: rootStore.getStore(AuthStore)?.status,
            }),
            async (arg) => {
                if (
                    this.shouldRebuildConnection(arg) &&
                    arg.status === AuthStatus.Initialized
                ) {
                    this.user = arg.user;
                    this.orgId = arg.activeOrgId ?? arg.user.profile?.OrgId;

                    await this.initHubConnection(
                        rootStore.getStore(AuthStore).activeOrgId ??
                            arg.user.profile?.OrgId!,
                    );
                }
            },
            { delay: 5000 },
        );
    }

    private notificationTransformers: Map<
        string,
        (customType: any) => NotificationArgument
    > = new Map();

    @action
    registerTransformer(
        id: string,
        transformer: (args: any) => NotificationArgument,
    ) {
        this.notificationTransformers.set(id, transformer);
    }

    async initHubConnection(activeOrgId: string) {
        if (this.connection) {
            await this.connection.stop();
        }
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(
                `/hubs/notifications?ws_organizationId=${encodeURIComponent(
                    activeOrgId,
                )}`,
                {
                    accessTokenFactory: () =>
                        this.rootStore
                            .getStore(AuthStore)
                            .getAccessToken() as Promise<string>,
                },
            )
            .withAutomaticReconnect()
            .build();

        this.connection.on(
            "NewNotification",
            (argument: NotificationArgument) => {
                if (this.notificationTransformers.has(argument.id!)) {
                    this.notificationTransformers.get(argument.id!)?.(argument);
                }
                this.messageStore.addOrUpdateNotification(argument);
            },
        );

        this.connection.on("SignalsAiSummaryResponseReceived", (response) => {
            this.aiSummariesStore.handleSummaryResponse(response);
        });

        this.connection.on(
            "EvaluationActionNotification",
            (actionNotification: EvaluationActionNotificationArg) => {
                const id = "eval-action-notification";
                if (!this.notificationTransformers.has(id)) {
                    this.registerTransformer(
                        id,
                        EvaluationActionNotificationArg.buildNotificationTransformer(),
                    );
                }

                let argument: NotificationArgument | undefined;
                if (this.notificationTransformers.has(id)) {
                    argument = this.notificationTransformers.get(id)?.({
                        ...actionNotification,
                        recipientUserName:
                            actionNotification.recipientUserName ??
                            this.user?.profile.email,
                    });

                    if (argument) {
                        argument.onDismiss = () =>
                            this.notificationAcknowledgeService.acknowledge(
                                "Action",
                                {
                                    headId: actionNotification.rootThreadId,
                                    userId: this.user?.profile.sub,
                                },
                            );

                        this.rootStore
                            .getStore(AnalystDashboardStore)
                            .refresh();
                        this.messageStore.addOrUpdateNotification(argument);
                    }
                }
            },
        );

        this.connection.on(
            "ReclassifyNotification",
            (argument: {
                id: string;
                notificationId: string;
                title: string;
                message: string;
                timestamp: string;
                type: NotificationType;
                completedCount?: number;
                totalCount?: number;
                addtlDetails?: NotificationDetails;
            }) => {
                const reclassifyStore = this.rootStore.getStore(
                    ClassifierBuilderV2ReclassifyDialogStore,
                );

                argument.addtlDetails = {
                    tenantName:
                        reclassifyStore.orgSelectStore.organization?.name,
                    dateReference: this.formatDateRef(
                        reclassifyStore.datePickerStore.referenceOption,
                    ),
                    startDate: this.formatDateRange(
                        reclassifyStore.datePickerStore.beginDate,
                    ),
                    endDate: this.formatDateRange(
                        reclassifyStore.datePickerStore.endDate,
                    ),
                    classifierNames: reclassifyStore.selectedClassifiers.map(
                        (classifier) => classifier.name,
                    ),
                };

                if (this.notificationTransformers.has(argument.id!)) {
                    this.notificationTransformers.get(argument.id!)?.(argument);
                }

                if (argument.completedCount === argument.totalCount) {
                    reclassifyStore.setReclassifySessionInProgress(false);
                }
                this.messageStore.addOrUpdateNotification(argument);
            },
        );

        await this.startConnection();
    }

    formatDateRange(date: Moment): string {
        return moment(date).format("MM/DD/YYYY");
    }

    formatDateRef(dateRef: string): string {
        if (dateRef === "ArrivalDate") {
            return "Arrival Date";
        } else if (dateRef === "InteractionDate") {
            return "Interaction Date";
        } else {
            return dateRef;
        }
    }

    startConnection = async () => {
        try {
            await this.connection.start();
        } catch (err) {
            console.log(err);
            setTimeout(this.startConnection, 5000);
        }
    };

    private shouldRebuildConnection(arg: {
        activeOrgId?: string;
        user?: User;
    }): boolean {
        return !!(
            (!this.user && arg.user) ||
            (!this.orgId && arg.activeOrgId) ||
            arg.user?.profile.sub !== this.user?.profile.sub ||
            this.orgId !== arg.activeOrgId
        );
    }

    sendNotification(arg: NotificationArgument) {
        this.connection
            .send("messageFromClient", arg)
            .then(() => console.log("Notification message sent"));
    }
}
