import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import LicensedModule from "../../../../models/LicensedModule";

const useStyles = makeStyles((theme: Theme) => ({
    padding3: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },

    root: {
        marginTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
}));

interface OwnProps {
    module?: LicensedModule;
}

type Props = OwnProps;

const ModuleCardHeader: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const classes = useStyles();
    let headerContent: React.ReactNode = null;

    return (
        <>
            {Boolean(headerContent) && (
                <Grid
                    item
                    xs={12}
                    direction={"row"}
                    wrap={"nowrap"}
                    container
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    className={clsx(classes.padding3, classes.root)}
                >
                    <Grid item xs={4}>
                        {headerContent}
                    </Grid>
                    <Grid
                        item
                        xs={8}
                        container
                        justifyContent={"flex-start"}
                        alignItems={"baseline"}
                        wrap={"wrap"}
                    ></Grid>
                </Grid>
            )}
        </>
    );
});

export default ModuleCardHeader;
