import { observable, action, makeObservable } from "mobx";

export class DialogComponentStore<T = any> {
    private readonly defaultResetDialog = () => {
        this.subTitle = undefined;
        this.isLoading = false;
        this.errorMessage = "";
    };

    @observable title?: string;
    @observable subTitle?: string;

    @observable isOpen: boolean = false;
    @observable isLoading: boolean;
    @observable errorMessage: string;

    resetDialog: () => void;

    public constructor(resetDialog?: () => void, public hostingStore?: T) {
        makeObservable(this);
        this.resetDialog = resetDialog || this.defaultResetDialog;
    }

    @action open = () => {
        this.resetDialog();
        this.isLoading = false;
        this.errorMessage = "";
        this.isOpen = true;
    };

    @action close = () => {
        this.isOpen = false;
    };

    @action setTitle = (title?: string) => {
        this.title = title;
    };
    @action setSubTitle = (subTitle?: string) => {
        this.subTitle = subTitle;
    };

    @action setErrorMessage = (newMessage: string) => {
        this.errorMessage = newMessage;
    };
    @action setLoading = () => {
        this.isLoading = true;
    };
    @action setNotLoading = () => {
        this.isLoading = false;
    };
}
