import { action, makeObservable, observable } from "mobx";
import moment, { Moment } from "moment";

export enum DateReferenceOption {
    ArrivalDate = "ArrivalDate",
    InteractionDate = "InteractionDate",
    EvaluationCompleteDate = "EvaluationCompleteDate",
    EvaluationStartDate = "EvaluationStartDate",
    CreatedOnDate = "CreatedOnDate",
}

export enum InteractionDateReferenceOption {
    ArrivalDate = "ArrivalDate",
    InteractionDate = "InteractionDate",
    EvaluationCompleteDate = "EvaluationCompleteDate",
    EvaluationModifiedDate = "EvaluationModifiedDate",
    EvaluationStartDate = "EvaluationStartDate",
    CreatedOnDate = "CreatedOnDate",
}

export const basicDateReferenceOptions = [
    DateReferenceOption.InteractionDate,
    DateReferenceOption.ArrivalDate,
];

export class DatePickerComponentStore {
    @observable beginDate: moment.Moment;
    @observable offsetBeginDate: moment.Moment;

    @observable endDate: moment.Moment;
    @observable offsetEndDate: moment.Moment;

    @observable referenceOption: DateReferenceOption;
    @observable dateRangeFilterLabel: string = "Interaction Date";
    @observable dateRangePresetLabel: allowedDateRangeLabels = "";

    public constructor(
        defaultBeginDate: moment.Moment = moment()
            .startOf("date")
            .subtract(10, "days"),
        defaultEndDate: moment.Moment = moment().endOf("date"),
        defaultReferenceOption: DateReferenceOption = DateReferenceOption.ArrivalDate,
    ) {
        makeObservable(this);
        this.beginDate = defaultBeginDate;
        this.offsetBeginDate = defaultBeginDate;

        this.endDate = defaultEndDate;
        this.offsetEndDate = defaultEndDate;

        this.referenceOption = defaultReferenceOption;
    }

    getBeginDate(useStartOfDay = true) {
        return this.beginDate.startOf("day").utc();
    }
    getOffsetBeginDate() {
        return addLocalOffset(this.beginDate.startOf("day"));
    }
    @action setBeginDate(newDate: moment.Moment, useStartOfDay = false) {
        // if (useStartOfDay) {
        //     this.beginDate = newDate.startOf("date");
        // } else {
        //     this.beginDate = newDate;
        // }
        this.beginDate = newDate;
        this.offsetBeginDate = addLocalOffset(this.beginDate);
    }

    getEndDate() {
        return this.endDate.endOf("day").utc();
    }
    getOffsetEndDate() {
        return addLocalOffset(this.endDate.endOf("day"));
    }
    @action setEndDate(newDate: moment.Moment, useEndOfDay = true) {
        // if (useEndOfDay) {
        //     this.endDate = newDate.endOf("date");
        // } else {
        //     this.endDate = newDate;
        // }
        if (
            this.beginDate &&
            moment(this.beginDate).startOf("date") >
                moment(newDate).startOf("date")
        ) {
            this.endDate = moment(this.beginDate).endOf("date");
            this.setBeginDate(newDate.startOf("date"));
        } else {
            this.endDate = newDate.endOf("date");
        }
        this.offsetEndDate = addLocalOffset(this.endDate);
    }
    @action setReferenceOption(newOption: DateReferenceOption) {
        this.referenceOption = newOption;
        this.dateRangeFilterLabel =
            newOption === "ArrivalDate" ? "Arrived On" : "Interaction Date";
    }

    @action setDateRangePreset(input: allowedDateRangeLabels) {
        this.dateRangePresetLabel = input;
    }
}

function addLocalOffset(inputDate: moment.Moment) {
    const minuteOffset = new Date().getTimezoneOffset();

    return moment(inputDate).add(minuteOffset, "minutes");
}

export type allowedDateRangeLabels =
    | "Any Time"
    | "This Quarter"
    | "This Month"
    | "This Week"
    | "Today"
    | "Yesterday"
    | "Last 30 Days"
    | "";

/**
 * Returns moment objects in an array [startDate, endDate]
 * @param {string} value - A string from the allowedDateRangeLabels
 */
export function getDateFromDateRangeLabel(
    value: allowedDateRangeLabels,
): Moment[] {
    let startDate, endDate;
    switch (value) {
        case "Any Time":
            startDate = moment("2020-01-01");
            endDate = moment();
            break;
        case "This Quarter":
            startDate = moment().startOf("quarter");
            endDate = moment();
            break;
        case "This Month":
            startDate = moment().startOf("month");
            endDate = moment();
            break;
        case "This Week":
            startDate = moment().startOf("week");
            endDate = moment().endOf("week");
            break;
        case "Today":
            startDate = moment().startOf("day");
            endDate = moment();
            break;
        case "Yesterday":
            startDate = moment().subtract(1, "day");
            endDate = moment();
            break;
        case "Last 30 Days":
            startDate = moment().subtract(30, "days");
            endDate = moment();
            break;
        default:
            startDate = moment();
            endDate = moment();
    }
    return [startDate, endDate];
}
