import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import CloseIcon from "@mui/icons-material/Close";
import { Observer } from "mobx-react-lite";
import * as React from "react";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";
import AcxButton from "./AcxButton";
import { AcxSpinnerTypes } from "./AcxLoadingIndicator";
import { useTheme } from "@mui/styles";

const styles = (theme: Theme) =>
    createStyles({
        container: {
            minHeight: "max-content",
            minWidth: "500px",
            borderRadius: "8px",
        },
        actions: {
            height: "64px",
            backgroundColor: hexToRGB(theme.palette.black.main, 0.05),
        },
        closeButton: {
            color: theme.palette.black.main,
        },
        content: {
            fontSize: "13px",
            color: theme.palette.black.main,
            height: "100%",
        },
        error: {
            fontSize: "13px",
            color: theme.palette.error.main,
            textAlign: "center",
            padding: theme.spacing(1),
        },
    });

type dialogWidth = "lg" | "md" | "sm" | "xl" | "xs" | false;

type Props = {
    isOpen?: boolean;
    title?: string;
    subTitle?: string;
    content?: React.ReactNode | string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onClose: () => void;
    onConfirm?: any;
    onCancel?: () => void;
    onTransitionExit?: () => void;
    buttonsDisabled?: boolean;
    confirmButtonDisabled?: boolean;
    noControls?: boolean;
    isLoading?: boolean;
    errorMessage?: string;
    maxWidth?: dialogWidth;
    ref?: React.MutableRefObject<null>;
    loadingProgress?: number;
    alternateSpinnerType?: AcxSpinnerTypes;
    spinnerSize?: number;
    noCancel?: boolean;
    dialogContentStyles?: React.CSSProperties;
    dialogStyles?: React.CSSProperties;
    fullWidth?: boolean;
    optionalButtons?: React.ReactNode[] | React.ReactNode | string;
    controlStyles?: React.CSSProperties;
    centerTitle?: boolean;
    headerIcon?: React.ReactNode;
};

function ConfirmationDialog(props: Props): React.ReactElement {
    const classes = useStyles(styles);
    const theme = useTheme();

    const onCancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
        props.onClose();
    };

    const CloseIconButton: React.FC = () => (
        <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.onClose}
            size="large"
        >
            <CloseIcon />
        </IconButton>
    );

    return (
        <Dialog
            classes={{ paper: classes.container }}
            scroll={"paper"}
            open={props.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={props.maxWidth}
            fullWidth={props.fullWidth ?? false}
            style={props.dialogStyles ?? {}}
            TransitionProps={{
                onExited: () => {
                    if (props.onTransitionExit) {
                        props.onTransitionExit();
                    }
                },
            }}
        >
            <DialogTitle id="alert-dialog-title" sx={{ opacity: 1 }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {props.headerIcon && (
                        <>
                            <Grid item xs={10}>
                                {props.headerIcon}
                            </Grid>
                            <Grid
                                container
                                item
                                xs={2}
                                justifyContent="flex-end"
                            >
                                <CloseIconButton />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={props.headerIcon ? 12 : 10}>
                        <Typography
                            sx={{ color: theme.palette.black.main }}
                            component="p"
                            variant="h1"
                            style={
                                props.centerTitle
                                    ? {
                                          textAlign: "center",
                                          paddingLeft: "90px",
                                      }
                                    : { width: "90%" }
                            }
                        >
                            {props.title || "Please Confirm..."}
                        </Typography>
                        {Boolean(props.subTitle) && (
                            <Typography
                                variant="subtitle2"
                                style={{ marginTop: "1rem" }}
                                color={"textSecondary"}
                            >
                                {props.subTitle}
                            </Typography>
                        )}
                    </Grid>
                    {!props.headerIcon && (
                        <Grid container item xs={2} justifyContent="flex-end">
                            <CloseIconButton />
                        </Grid>
                    )}
                </Grid>
            </DialogTitle>

            <DialogContent
                className={classes.content}
                style={props.dialogContentStyles}
                ref={props.ref}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    {Boolean(props.content) && (
                        <Grid item xs={12}>
                            {props.content}
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            <Typography className={classes.error} component="div">
                {props.errorMessage}
            </Typography>

            {!Boolean(props.noControls) && (
                <DialogActions
                    className={classes.actions}
                    style={props.controlStyles}
                >
                    {Boolean(!props.noCancel) && (
                        <AcxButton
                            onClick={onCancel}
                            style={{
                                backgroundColor: "#E0E0E0",
                                color: theme.palette.black.main,
                            }}
                        >
                            {props.cancelButtonText || "Cancel"}
                        </AcxButton>
                    )}

                    {props.optionalButtons}

                    {props.onConfirm && (
                        <AcxButton
                            style={{ marginRight: "0.5rem" }}
                            loading={props.isLoading ?? false}
                            disabled={
                                props.buttonsDisabled ||
                                props.confirmButtonDisabled ||
                                props.isLoading
                            }
                            onClick={props.onConfirm}
                        >
                            {props.confirmButtonText || "Confirm"}
                        </AcxButton>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
}

interface WrapperProps {
    dialogStore: DialogComponentStore;
    title?: string;
    content?: JSX.Element | string;
    confirmButtonDisabled?: boolean;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
    buttonsDisabled?: boolean;
    noControls?: boolean;
    maxWidth?: dialogWidth;
    ref?: React.MutableRefObject<null>;
    isLoading?: boolean;
}

export function AcxConfirmationDialogWrapper(props: WrapperProps) {
    const dialog = props.dialogStore;
    return (
        <Observer>
            {() => (
                <ConfirmationDialog
                    onClose={dialog.close}
                    isOpen={dialog.isOpen}
                    isLoading={dialog.isLoading}
                    errorMessage={dialog.errorMessage}
                    {...props}
                />
            )}
        </Observer>
    );
}

export default ConfirmationDialog;
