import { Observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import ControlsSection from "../../../../../../Layouts/SideControls/ControlsSection";
import InfoSection from "../../../../../../Layouts/SideControls/InfoSection";
import RuleSetSelect from "../../../AudioFileSampler/Views/RuleSetSelector";
import {
    isNewRuleSet,
    LoadRuleSetsOp,
    RuleBuildStore,
} from "../../RuleBuildStore";

interface OwnProps {
    store: RuleBuildStore;
}

type Props = OwnProps;

const SideContent: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <Observer>
            {() => {
                return (
                    <>
                        <InfoSection
                            sectionTitle={"RuleSets"}
                            showDivider
                            sectionContent={
                                <>
                                    Drag <span>Classifiers</span> onto{" "}
                                    <span>Filter</span>, <span>Ranking</span>,{" "}
                                    <span>Stratification</span> targets to build
                                    rules based on the classifier.
                                    <br />
                                    <br />
                                    Filter rules require <span>
                                        operators
                                    </span>{" "}
                                    and <span>thresholds</span>. Ranking rules
                                    are defined as either <span>Ascending</span>{" "}
                                    or <span>Descending</span>. Stratification
                                    rules provide options for <span>Equal</span>{" "}
                                    or <span>Representative</span> sampling.
                                    <br />
                                    <br />
                                    Filtering on <span>
                                        Speech Classifiers
                                    </span>{" "}
                                    results in sample sets where every
                                    interaction contains(
                                    <span>true</span>) or not(<span>false</span>
                                    ), the search text; whereas, Ranking will
                                    order interactions by search-text
                                    relevance--providing more flexible rulesets.
                                </>
                            }
                        />

                        <ControlsSection
                            sectionTitle={"Saved RuleSets"}
                            sectionContent={
                                <RuleSetSelect
                                    noSelect
                                    customStyle={{
                                        menuPortal: (provided, state) => ({
                                            ...provided,
                                            zIndex: 9999,
                                        }),
                                    }}
                                    orgId={props.store.orgId ?? ""}
                                    defaultValue={
                                        isNewRuleSet(props.store.activeRuleSet)
                                            ? undefined
                                            : props.store.activeRuleSet
                                    }
                                    ruleSets={[...props.store.ruleSets]}
                                    loading={props.store.getTaskLoading(
                                        LoadRuleSetsOp,
                                    )}
                                    showSpinner={false}
                                    error={
                                        props.store.getTaskError(LoadRuleSetsOp)
                                            ?.message ?? ""
                                    }
                                    onSelect={props.store.setActiveRuleSet}
                                />
                            }
                        />
                    </>
                );
            }}
        </Observer>
    );
};

export default SideContent;
