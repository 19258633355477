import AcxChip from "components/UI/AcxChip";
import AcxPieGraph from "components/UI/Visualization/AcxPieGraph";
import { observer } from "mobx-react";
import React, { useEffect, useCallback } from "react";
import theme from "Theme/AppTheme";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import WorkflowStore from "components/Workflows/Stores/WorkflowStore";
import { uuidv4 } from "utils/helpers";
import AWOverviewChartStore from "../../Stores/AWOverviewChartStore";
import { Box, Grid, Paper } from "@mui/material";
import { GridApiPro } from "@mui/x-data-grid-pro";
import RootStore from "stores/RootStore";
import LocalStorage from "stores/LocalStorage";

type Props = {
    store: WorkflowStore;
    gridApiRef?: React.MutableRefObject<GridApiPro>;
    chartStore: AWOverviewChartStore;
};

const AWOverviewChart: React.FC<Props> = observer(
    ({ store, gridApiRef, chartStore }) => {
        const overviewChartFilterId = "overview-chart-filter";

        const localStorageKey = "agingWorkflowsDgFilter";

        const local = RootStore().getStore(LocalStorage);

        useEffect(() => {
            store.dgStore.gridApi?.current?.subscribeEvent?.(
                "filterModelChange",
                () => {
                    const tempLocal = local.getLocalStore();
                    if (
                        store.dgStore.gridApi?.current?.state?.filter
                            ?.filterModel
                    ) {
                        tempLocal.setItem(
                            localStorageKey,
                            JSON.stringify(
                                store.dgStore.gridApi?.current?.state?.filter
                                    ?.filterModel,
                            ),
                        );
                    }
                },
            );
        }, [local, store.dgStore.gridApi]);

        useEffect(() => {
            async function getLocal() {
                const tempLocal = local.getLocalStore();
                const localFilters = await tempLocal.getItem<string | null>(
                    localStorageKey,
                );

                if (localFilters) {
                    store.dgStore.gridApi?.current.setFilterModel?.(
                        JSON.parse(localFilters),
                    );
                }
            }
            getLocal();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const clearOnlyChartFilterItems = useCallback(() => {
            if (
                gridApiRef &&
                gridApiRef?.current?.state?.filter?.filterModel?.items
            ) {
                gridApiRef.current.state.filter.filterModel.items =
                    gridApiRef.current.state.filter.filterModel.items.filter(
                        (i) => {
                            return typeof i.id !== "string" ? true : false;
                        }, // default filter items have numeric ids, filter items added from overviewchart have string ids
                    );

                gridApiRef.current.setFilterModel(
                    { ...gridApiRef.current.state.filter.filterModel },
                    "restoreState",
                );
            }
        }, [gridApiRef]);

        const handleFilterFromOverviewChart = (seriesName: string) => {
            if (chartStore.selectedSeriesName === seriesName) {
                handleResetChartFilters();
                return;
            }

            if (chartStore.selectedSeriesName) {
                // need to clear previous filter if it was already filtered
                clearOnlyChartFilterItems();
            }

            chartStore.setSelectedSeriesName(seriesName);

            if (chartStore.selectedChartType === "Priority") {
                gridApiRef?.current.setFilterModel({
                    ...gridApiRef.current.state.filter.filterModel,
                    items: [
                        ...(gridApiRef.current.state.filter.filterModel
                            ?.items ?? []),
                        {
                            field: "highPriority",
                            id: overviewChartFilterId + uuidv4(),
                            operator: "is",
                            value: seriesName === "Normal" ? "false" : "true",
                        },
                    ],
                });
            } else if (chartStore.selectedChartType === "Status") {
                gridApiRef?.current.setFilterModel({
                    ...gridApiRef.current.state.filter.filterModel,
                    items: [
                        ...(gridApiRef.current.state.filter.filterModel
                            ?.items ?? []),
                        {
                            field: "currentStatus",
                            id: overviewChartFilterId + uuidv4(),
                            operator: "contains",
                            value: seriesName,
                        },
                    ],
                });
            }
        };

        const buildChipForPieData = (i: string[], index: number) => {
            const seriesName = i[0];
            const seriesCount = Number(i[1]);

            const seriesBgColor = chartStore.chartColors[seriesName]?.[2];

            const chipColor = chartStore.chartColors[seriesName]?.[1] as any;

            const countEl = (
                <Grid
                    container
                    item
                    style={{ width: "100%", height: "100%" }}
                    alignItems="center"
                    xs={12}
                    wrap="nowrap"
                >
                    <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            color: "white",
                            backgroundColor: seriesBgColor,
                            height: "36px",
                            width: "48px",
                            marginRight: theme.spacing(1),
                            fontSize: "12px",
                        }}
                    >
                        {seriesCount.toLocaleString()}
                    </Grid>
                    <Grid item style={{ lineHeight: "initial" }}>
                        {seriesName}
                    </Grid>
                </Grid>
            );

            return (
                <Grid item container xs={6} key={index}>
                    <AcxChip
                        size="medium"
                        color={chipColor}
                        label={countEl}
                        style={{
                            width: "100%",
                            height: "36px",
                            justifyContent: "flex-start",
                            border:
                                seriesName === chartStore.selectedSeriesName
                                    ? "1px solid"
                                    : "",
                            borderColor:
                                seriesName === chartStore.selectedSeriesName
                                    ? seriesBgColor
                                    : "",
                            opacity:
                                chartStore.selectedSeriesName &&
                                seriesName !== chartStore.selectedSeriesName
                                    ? 0.3
                                    : 1,
                        }}
                        onClick={() => {
                            handleFilterFromOverviewChart(seriesName);
                        }}
                        removeLeftLabelPadding
                    />
                </Grid>
            );
        };

        const noOverviewDataPlaceholder = (
            <Grid
                container
                item
                justifyContent={"center"}
                style={{
                    height: "100%",
                    width: "100%",
                    paddingTop: "12px",
                }}
            >
                <div
                    style={{
                        border: "60px solid",
                        borderColor: theme.palette.neutralGray.main,
                        borderRadius: "50%",
                        height: "270px",
                        width: "270px",
                    }}
                ></div>
            </Grid>
        );

        const handleResetChartFilters = () => {
            clearOnlyChartFilterItems();
            chartStore.setSelectedSeriesName("");
        };

        const handleChartSelect = (series, x, y) => {
            if (x) {
                handleFilterFromOverviewChart(x.toString());
            }
        };

        useEffect(() => {
            // check if filter from chart was cleared from filter panel
            if (
                !gridApiRef?.current.state.filter.filterModel?.items.some((i) =>
                    i.id?.toString().includes(overviewChartFilterId),
                )
            ) {
                chartStore.setSelectedSeriesName("");
            }
        }, [
            chartStore,
            gridApiRef,
            gridApiRef?.current.state.filter.filterModel,
        ]);

        return (
            <Paper>
                <Grid
                    container
                    item
                    style={{ padding: theme.spacing(2) }}
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Box fontWeight={600} fontSize={16}>
                            Workflow Overview
                        </Box>
                    </Grid>
                    <Grid item>
                        <AcxSelectSingle
                            options={[
                                { value: "Status", label: "Status" },
                                {
                                    value: "Priority",
                                    label: "Priority",
                                },
                            ]}
                            id="workflow-overview-type-select"
                            valueField="value"
                            labelField="label"
                            defaultValue={{
                                value: chartStore.selectedChartType,
                                label: chartStore.selectedChartType,
                            }}
                            onChange={(item) => {
                                chartStore.setCurrentChartType(
                                    item.value as "Priority" | "Status",
                                );
                                handleResetChartFilters();
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    style={{
                        height: "300px",
                    }}
                    onClick={() => {
                        if (chartStore.selectedSeriesName) {
                            handleResetChartFilters();
                        }
                    }}
                >
                    {chartStore.chartData.filter((i) => i[1]).length > 1 ? (
                        <AcxPieGraph
                            data={chartStore.chartData}
                            legendPosition="none"
                            sliceText="none"
                            colorScheme={Object.entries(
                                chartStore.chartColors,
                            ).map((i) => i[1][0])}
                            onSelect={handleChartSelect}
                            useAlternateRowLabel
                            containerHeight="100%"
                        />
                    ) : (
                        noOverviewDataPlaceholder
                    )}
                </Grid>
                <Grid
                    container
                    item
                    style={{ padding: theme.spacing(2) }}
                    spacing={1}
                >
                    {chartStore.chartData.slice(1).map(buildChipForPieData)}
                </Grid>
            </Paper>
        );
    },
);

export default AWOverviewChart;
