import { Grid, Theme, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import InfoSvg from "SvgIcons/InfoSvg";
import {
    ReportDataField,
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "../../../../../../models/Reporting/ReportDataField";
import {
    FieldUse,
    ReportField,
} from "../../../../../../models/Reporting/ReportField";
import { isNullableType } from "../../../../../../utils/TypeGuards";
import { useStore } from "../../../../../../utils/useStore";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import ReportFieldTransferList from "./ReportFieldTransferList";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    titleText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
}));

function tableFieldNameSelector(
    reportDataField: ReportDataField | ReportField,
) {
    if (reportDataField instanceof ReportField) {
        return reportDataField.reportDataField!.displayName;
    } else {
        return reportDataField.displayName;
    }

    // const res = (reportDataField as ReportDataField)?.displayName
    //     || (reportDataField as ReportDataField)?.fieldName || (reportDataField as ReportField)?.name;
    //
    // return res;
}

function tableColumnPredicate(field: ReportField) {
    return (
        field.fieldUse === FieldUse.X ||
        field.fieldUse === FieldUse.VirtualValue ||
        field.fieldUse === FieldUse.VirtualScored
    );
}

interface OwnProps {}

type Props = OwnProps;

const TableDataSection: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);

    function isConfigurationRequiredOnReportField(arg: ReportDataField) {
        const reportField = store.currentReport?.activeReportFields.find(
            (value) =>
                (value.fieldUse === FieldUse.VirtualValue ||
                    value.fieldUse === FieldUse.VirtualScored) &&
                value.reportDataField?.displayName === arg?.displayName,
        );

        return (
            arg.datatype === ReportFieldDataTypes.Number &&
            arg.variation === ReportDataFieldVariation.Virtual &&
            isNullableType(reportField?.aggregateFunction)
        );
    }

    return (
        <>
            <Typography className={classes.sectionTitle}>
                Columns to Display
            </Typography>
            <Grid container item xs={12} spacing={2}>
                <ReportFieldTransferList
                    reportFieldPredicate={tableColumnPredicate}
                    selectedFields={
                        store.currentReport?.tableReportDataFields ?? []
                    }
                    labelFieldSelector={tableFieldNameSelector}
                    isActionRequired={isConfigurationRequiredOnReportField}
                    onSelectedFields={
                        store.currentReport?.changeTableReportFields
                    }
                    selectedFieldsTitle={"Report Columns"}
                    availableFieldsTitle={"All Columns"}
                    availableFields={store.availableTableReportDataFields}
                    idField={"displayName"}
                    height={"350px"}
                    selectedFieldEndAdornment={
                        <Tooltip title="Display names must be less than 64 characters.">
                            <InfoSvg width="24px" height="24px" />
                        </Tooltip>
                    }
                />
            </Grid>
        </>
    );
});

export default TableDataSection;
