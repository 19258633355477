import React from "react";

interface HelpSvgProps {
    width?: string;
    height?: string;
}

type Props = HelpSvgProps;

const SmartPredictSvg: React.FC<Props> = ({ width, height }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ?? "21"}
            height={height ?? "20"}
            viewBox={`0 0 ${width ?? 21} ${height ?? 20}`}
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.0908 10C18.0908 12.1217 17.248 14.1566 15.7477 15.6569C14.2474 17.1571 12.2126 18 10.0908 18C7.96909 18 5.93426 17.1571 4.43397 15.6569C2.93368 14.1566 2.09082 12.1217 2.09082 10C2.09082 7.87827 2.93368 5.84344 4.43397 4.34315C5.93426 2.84285 7.96909 2 10.0908 2C12.2126 2 14.2474 2.84285 15.7477 4.34315C17.248 5.84344 18.0908 7.87827 18.0908 10ZM10.0908 7C9.91512 6.99983 9.74247 7.04595 9.59027 7.13373C9.43806 7.22151 9.31167 7.34784 9.22382 7.5C9.16039 7.61788 9.07393 7.72182 8.96958 7.80566C8.86523 7.8895 8.74511 7.95154 8.61634 7.9881C8.48757 8.02466 8.35277 8.03499 8.21993 8.01849C8.08709 8.00198 7.95891 7.95897 7.843 7.89201C7.7271 7.82505 7.62581 7.7355 7.54515 7.62867C7.46449 7.52184 7.40611 7.3999 7.37345 7.27008C7.34079 7.14027 7.33453 7.00522 7.35503 6.87294C7.37554 6.74065 7.42239 6.61384 7.49282 6.5C7.82304 5.92811 8.33273 5.48116 8.94285 5.22846C9.55296 4.97576 10.2294 4.93144 10.8673 5.10236C11.5052 5.27328 12.0688 5.64989 12.4708 6.1738C12.8729 6.6977 13.0908 7.33962 13.0908 8C13.091 8.62062 12.8988 9.22603 12.5406 9.73285C12.1824 10.2397 11.6759 10.623 11.0908 10.83V11C11.0908 11.2652 10.9855 11.5196 10.7979 11.7071C10.6104 11.8946 10.356 12 10.0908 12C9.8256 12 9.57125 11.8946 9.38371 11.7071C9.19618 11.5196 9.09082 11.2652 9.09082 11V10C9.09082 9.73478 9.19618 9.48043 9.38371 9.29289C9.57125 9.10536 9.8256 9 10.0908 9C10.356 9 10.6104 8.89464 10.7979 8.70711C10.9855 8.51957 11.0908 8.26522 11.0908 8C11.0908 7.73478 10.9855 7.48043 10.7979 7.29289C10.6104 7.10536 10.356 7 10.0908 7ZM10.0908 15C10.356 15 10.6104 14.8946 10.7979 14.7071C10.9855 14.5196 11.0908 14.2652 11.0908 14C11.0908 13.7348 10.9855 13.4804 10.7979 13.2929C10.6104 13.1054 10.356 13 10.0908 13C9.8256 13 9.57125 13.1054 9.38371 13.2929C9.19618 13.4804 9.09082 13.7348 9.09082 14C9.09082 14.2652 9.19618 14.5196 9.38371 14.7071C9.57125 14.8946 9.8256 15 10.0908 15Z"
                fill="#3664D6"
            />
        </svg>
    );
};

export default SmartPredictSvg;
