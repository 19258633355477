import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import BarChartIcon from "@mui/icons-material/BarChart";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PieChartIcon from "@mui/icons-material/PieChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import React, { FunctionComponent } from "react";
import { VizType } from "../../models/Reporting/ReportModel";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
    },
    vizIcon: (props: Props) => ({
        color: theme.palette.text.disabled,
        opacity: "0.35",
        fontSize: `${props.parentHeightPx * 0.42}px`,
    }),
    vizText: (props: Props) => ({
        opacity: "0.25",
        color: "#1F1F1F",
        fontSize: props.parentHeightPx > 450 ? "24px" : "18px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
        // textAlign: "center"
    }),
}));

interface OwnProps {
    vizType?: VizType;
    parentHeightPx: number;
}

type Props = OwnProps;

const EmptyReportIndicator: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const classes = useStyles(props);

    function getIcon(vizType?: VizType) {
        if (vizType === VizType.Line) {
            return <TrendingUpIcon className={classes.vizIcon} />;
        } else if (vizType === VizType.Bar) {
            return <EqualizerIcon className={classes.vizIcon} />;
        } else if (vizType === VizType.Table) {
            return <TableChartIcon className={classes.vizIcon} />;
        } else if (vizType === VizType.Pie) {
            return <PieChartIcon className={classes.vizIcon} />;
        } else if (vizType === VizType.Histogram) {
            return <BarChartIcon className={classes.vizIcon} />;
        }
        return "";
    }

    return (
        <>
            {" "}
            {props.vizType !== undefined && (
                <div style={{ position: "relative", top: `20%` }}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            {getIcon(props.vizType)}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.vizText}>
                                No data available for applied filters
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};

export default EmptyReportIndicator;
