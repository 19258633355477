import { AppUser } from "components/Admin/UserManager/UserStore";
import RoleService from "../components/Admin/Organizations/OrganizationDetail/OrganizationRoles/Role.service";
import { ApplicationUser } from "../models/Permission/ApplicationUser";
//"../OrganizationDetail/OrganizationRoles/Role.service";
import BaseService from "./BaseService";

export class UserService extends BaseService {
    protected urlBase: string = "api/User";

    public async getUserLoginHistory(userId: string) {
        const params = new URLSearchParams({ userId });
        const res = await this.get(params, "UserLoginHistory");

        return res;
    }

    public async updateUserProfile(appUser: AppUser) {
        const params = new URLSearchParams({});
        const res = await this.put(
            params,
            "UpdateProfile",
            JSON.stringify(appUser),
        );
        return res;
    }
    public async updatePassword(appUser: AppUser) {
        const params = new URLSearchParams({});
        const res = await this.put(
            params,
            "UpdatePassword",
            JSON.stringify(appUser),
        );
        return res;
    }
    public async adminResetPassword(appUser: AppUser) {
        const params = new URLSearchParams({});
        const res = await this.put(
            params,
            "AdminResetPassword",
            JSON.stringify(appUser),
        );
        return res;
    }

    public async getUsers(orgId: string): Promise<AppUser[]> {
        const params = new URLSearchParams({ orgId: orgId });
        const res = (await this.get(params, "GetUsers")) as AppUser[];
        return res;
    }

    public async searchUsers(
        organizationId: string,
        searchValue: string,
    ): Promise<AppUser[]> {
        const res = await this.post<AppUser[]>(
            JSON.stringify({ organizationId, searchValue }),
            new URLSearchParams({}),
            "SearchUsers",
        );
        return res;
    }

    public async getUserNames(
        organizationId: string,
    ): Promise<ApplicationUser[]> {
        const res = await this.post<ApplicationUser[]>(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "UserNames",
        );
        return res;
    }

    public async getUsersByHierarchy(
        hierarchyId: string,
    ): Promise<ApplicationUser[]> {
        const params = new URLSearchParams({ hierarchyId: hierarchyId });
        const res = await this.get(params, "GetUsersByHierarchy");
        return res;
    }

    public async getRoles(): Promise<any[]> {
        const parms = new URLSearchParams({});
        const res = await this.get(parms, "GetRoles");
        return res;
    }
    public async getRolesByOrganizationId(orgId: string): Promise<any[]> {
        return await RoleService.getRoles(orgId);
    }
    public async createUser(appUser: AppUser) {
        const parms = new URLSearchParams({});
        const res = await this.post(
            JSON.stringify(appUser),
            parms,
            "RegisterUser",
        );
        return res;
    }
}
