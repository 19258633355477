import moment from "moment";
import { FieldUse } from "../../../../models/Reporting/ReportField";
import { ReportModel, VizType } from "../../../../models/Reporting/ReportModel";
import { parseFromISO } from "../../../../utils/DateTimeUtils";
import { IReport } from "../Stores/ReportsStore";

export function reportToIReport(report: ReportModel): IReport {
    const modifiedOn = parseFromISO(
        report.modifiedOn ?? moment().toISOString(),
    );
    const now = moment();
    const moreThanAYearAgo = modifiedOn.isBefore(now, "year");

    const editedOnFormat = moreThanAYearAgo ? "MMM D, YYYY" : "MMM D";
    const editedAtFormat = "h:mm a";

    const editedOn = report.modifiedOn ? modifiedOn.format(editedOnFormat) : "";
    const editedAt = report.modifiedOn ? modifiedOn.format(editedAtFormat) : "";
    let xAxis: string | undefined;
    let yAxis: string | undefined;
    if (report.vizType !== VizType.Table) {
        const xAxisField = report.reportFields?.find(
            (value) => value.fieldUse === FieldUse.X,
        );
        xAxis = xAxisField?.displayName || xAxisField?.name;
        const yAxisField = report.reportFields?.find(
            (value) => value.fieldUse === FieldUse.Y,
        );
        yAxis = yAxisField?.displayName || yAxisField?.name;
    }

    // @ts-expect-error: modifiedOnDate property missing, apparently not causing issues
    return {
        id: report.id,
        organizationId: report.organizationId,
        createdBy: report.createdBy ?? "",
        group: report.group.groupName,
        name: report.name ?? "",
        modifiedBy: report.modifiedBy ?? report.createdBy ?? "",
        modifiedOn: editedOn,
        modifiedAt: editedAt,
        vizType: report.vizType,
        xAxisLabel: xAxis,
        yAxisLabel: yAxis,
        report,
        isPublished: report.isPublished,
        vizOptions: { ...report.vizOptions },
    };
}
