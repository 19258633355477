import React from "react";
import { Route, Routes } from "react-router";
import OrganizationModulesList from "./OrganizationModulesList";

type Props = {
    id: string;
};

const OrganizationModules: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ id }) => {
    return (
        <Routes>
            <Route path="*" element={<OrganizationModulesList orgId={id} />} />
        </Routes>
    );
};

export default OrganizationModules;
