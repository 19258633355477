import { copyBaseFields } from "../../utils/BaseEntityUtils";
import BaseEntity from "../BaseEntity";
import { ApplicationUser } from "../Permission/ApplicationUser";
import { ActionHead } from "./ActionHead";
import { ActionMetadata } from "./ActionMetadata";
import { ActionRecipient } from "./ActionRecipient";
import { ActionWatcher } from "./ActionWatcher";

export class Action<
    TActionMetadata extends {} = any,
    TRecipientMetadata extends {} = any,
    TWatcherMetadata extends {} = any,
    THeadMetadata extends {} = any,
> extends BaseEntity {
    organizationId: string;
    userId: string;
    user: ApplicationUser;

    actionMetadataId: string;
    actionMetadata: ActionMetadata<TActionMetadata>;

    entityName: string;
    entityId: string;

    actionHeadId: string;
    actionHead: ActionHead<THeadMetadata>;

    actionRecipients?: ActionRecipient<
        TRecipientMetadata,
        TActionMetadata,
        TWatcherMetadata
    >[];
    actionWatchers?: ActionWatcher<
        TWatcherMetadata,
        TActionMetadata,
        TRecipientMetadata
    >[];

    parentActionId?: string;
    parentAction?: Action<
        TActionMetadata,
        TRecipientMetadata,
        TWatcherMetadata,
        THeadMetadata
    >;

    actionResponses?: Action<
        TActionMetadata,
        TRecipientMetadata,
        TWatcherMetadata,
        THeadMetadata
    >[];

    isUserRecipient(userId: string) {
        return (
            this.actionRecipients?.find((value) => value.userId === userId) !=
            null
        );
    }

    static fromJson<T extends {}>(actionJson: Action<T>) {
        const clz = new Action<T>();
        copyBaseFields(actionJson, clz);
        clz.organizationId = actionJson.organizationId;
        clz.userId = actionJson.userId;
        clz.user = actionJson.user;
        clz.actionMetadataId = actionJson.actionMetadataId;
        clz.actionMetadata = actionJson.actionMetadata;
        clz.entityName = actionJson.entityName;
        clz.entityId = actionJson.entityId;
        clz.actionRecipients = actionJson.actionRecipients ?? [];
        clz.parentActionId = actionJson.parentActionId;
        clz.parentAction = actionJson.parentAction
            ? this.fromJson<T>(actionJson.parentAction)
            : undefined;
        clz.actionResponses =
            actionJson.actionResponses?.map((value) =>
                this.fromJson<T>(value),
            ) ?? [];
        clz.actionHead = actionJson.actionHead;
        clz.actionWatchers = actionJson.actionWatchers;

        return clz;
    }
}
