import React from "react";
import { ThemeProvider, Theme, StyledEngineProvider, CssBaseline } from "@mui/material";
import AppTheme from "Theme/AppTheme";
import AuthPage from "./AuthPage";
import SessionExpired from "./SessionExpired";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function SessionExpiredUser() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
                <CssBaseline />
                <AuthPage title="Session Expired">
                    <SessionExpired />
                </AuthPage>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default SessionExpiredUser;
