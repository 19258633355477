import { Grid, Theme, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import makeStyles from '@mui/styles/makeStyles';
import Switch from "@mui/material/Switch";
import { observer } from "mobx-react";
import {
    CurrencyOptions,
    DecimalOptions,
    DelimiterOptions,
    VizType,
} from "models/Reporting/ReportModel";
import React, { FunctionComponent, useEffect, useState } from "react";
import theme from "Theme/AppTheme";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import { formatChartValue } from "utils/ReportDataToGoogleFormat";
import {
    ReportDataField,
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "../../../../../../models/Reporting/ReportDataField";
import {
    AggregateFunction,
    DateAggregation,
    FieldUse,
    ReportField,
} from "../../../../../../models/Reporting/ReportField";
import { useStore } from "../../../../../../utils/useStore";
import AcxBooleanFormControlGroup, {
    AcxBooleanFormFieldControllers,
} from "../../../../../UI/AcxBooleanFormControlGroup";
import AcxMainTextField from "../../../../../UI/AcxMainTextFieldGrid";
import AcxSelectSingle from "../../../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";

const useStyles = makeStyles((theme: Theme) => ({
    mainSectionTitle: {
        paddingTop: theme.spacing(1),
    },
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        paddingRight: theme.spacing(1),
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
}));

export const dateGroupings = [
    { id: DateAggregation.DateId, value: "Date" },
    { id: DateAggregation.WeekOfYear, value: "Week" },
    { id: DateAggregation.MonthYear, value: "Month" },
    { id: DateAggregation.Quarter, value: "Quarter" },
];

const aggregateFunctions = [
    { id: AggregateFunction.Avg, value: "Avg" },
    { id: AggregateFunction.Count, value: "Count" },
    { id: AggregateFunction.Sum, value: "Sum" },
    { id: AggregateFunction.Max, value: "Max" },
    { id: AggregateFunction.Min, value: "Min" },
    { id: AggregateFunction.Percentage, value: "Percentage" },
];
const aggregateFunctionsPercentage = [
    { id: AggregateFunction.Avg, value: "Avg" },
    { id: AggregateFunction.Count, value: "Count" },
    { id: AggregateFunction.Sum, value: "Sum" },
    { id: AggregateFunction.Max, value: "Max" },
    { id: AggregateFunction.Min, value: "Min" },
];

const delimiterOptions = EnumToDropdownOptionConversion(DelimiterOptions);

const currencyOptions = EnumToDropdownOptionConversion(CurrencyOptions);

const decimalOptions = EnumToDropdownOptionConversion(DecimalOptions);

export function aggregateFunctionsForReportField(reportField?: ReportField) {
    const fieldDataType = reportField?.reportDataField?.datatype; //?? translateFieldUseToDatatype(reportField);

    switch (fieldDataType) {
        case ReportFieldDataTypes.Date:
            return aggregateFunctions.filter(
                (value) =>
                    value.id === AggregateFunction.Count ||
                    value.id === AggregateFunction.Min ||
                    value.id === AggregateFunction.Max,
            );
        case ReportFieldDataTypes.Text:
            return aggregateFunctions.filter(
                (value) => value.id === AggregateFunction.Count,
            );
        case ReportFieldDataTypes.Hierarchy:
        case ReportFieldDataTypes.Categorical:
        case ReportFieldDataTypes.Guid:
            return aggregateFunctions.filter(
                (value) =>
                    value.id === AggregateFunction.Count ||
                    value.id === AggregateFunction.Percentage,
            );
        case ReportFieldDataTypes.Percentage:
            return aggregateFunctions.filter(
                (value) => value.id === AggregateFunction.Percentage,
            );
        case ReportFieldDataTypes.Number:
        default:
            return aggregateFunctions;
    }
}
export function aggregateFunctionsForReportFieldPercentage(
    reportField?: ReportField,
) {
    const fieldDataType = reportField?.reportDataField?.datatype; //?? translateFieldUseToDatatype(reportField);

    switch (fieldDataType) {
        case ReportFieldDataTypes.Date:
            return aggregateFunctionsPercentage.filter(
                (value) =>
                    value.id === AggregateFunction.Count ||
                    value.id === AggregateFunction.Min ||
                    value.id === AggregateFunction.Max,
            );

        case ReportFieldDataTypes.Hierarchy:
        case ReportFieldDataTypes.Categorical:
        case ReportFieldDataTypes.Guid:
        case ReportFieldDataTypes.Text:
            return aggregateFunctionsPercentage.filter(
                (value) => value.id === AggregateFunction.Count,
            );
        case ReportFieldDataTypes.Number:
        default:
            return aggregateFunctionsPercentage;
    }
}

export function dateAggregationSelector(dateAggregation?: DateAggregation) {
    switch (dateAggregation) {
        case DateAggregation.DateId:
            return { value: "Date", id: dateAggregation };

        case DateAggregation.MonthYear:
            return { value: "Month", id: dateAggregation };

        case DateAggregation.Quarter:
            return { value: "Quarter", id: dateAggregation };

        case DateAggregation.WeekOfYear:
            return { value: "Week", id: dateAggregation };
        default:
            return undefined;
    }
}

export function aggregateFuncSelector(aggregateFunction?: AggregateFunction) {
    switch (aggregateFunction) {
        case AggregateFunction.Avg:
            return { value: "Avg", id: aggregateFunction };
        case AggregateFunction.Count:
            return { value: "Count", id: aggregateFunction };
        case AggregateFunction.Max:
            return { value: "Max", id: aggregateFunction };
        case AggregateFunction.Min:
            return { value: "Min", id: aggregateFunction };
        case AggregateFunction.Sum:
            return { value: "Sum", id: aggregateFunction };
        case AggregateFunction.Percentage:
            return { value: "Percentage", id: aggregateFunction };
        default:
            return undefined;
    }
}
export function aggregateFuncSelectorPercentage(
    aggregateFunction?: AggregateFunction,
) {
    switch (aggregateFunction) {
        case AggregateFunction.Avg:
            return { value: "Avg", id: aggregateFunction };
        case AggregateFunction.Count:
            return { value: "Count", id: aggregateFunction };
        case AggregateFunction.Max:
            return { value: "Max", id: aggregateFunction };
        case AggregateFunction.Min:
            return { value: "Min", id: aggregateFunction };
        case AggregateFunction.Sum:
            return { value: "Sum", id: aggregateFunction };

        default:
            return undefined;
    }
}

function canBeDistinct(aggregateFunction?: AggregateFunction) {
    switch (aggregateFunction) {
        case AggregateFunction.Count:
        case AggregateFunction.Sum:
            return true;
        default:
            return false;
    }
}

interface OwnProps {}

type Props = OwnProps;

const VizDataSection: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles(props);
    const store = useStore(ReportEditorStore);
    //const reportStore = useStore(ReportsStore);
    const xField = store.currentReport?.activeVizXAxisField;
    const yField = store.currentReport?.activeVizYAxisField;
    const vizGroupField = store.currentReport?.activeVizGroupingField;

    const initialXFieldGroupByToggle =
        xField?.dateAggregation !== undefined &&
        xField.dateAggregation !== null;

    const [xFieldGroupByDataToggle, setXFieldGroupByDataToggle] =
        useState<boolean>(initialXFieldGroupByToggle);

    //const [summerizeData, setSummerizeData] = useState(false);
    //const [summerizeDataField, setSummerizeDataField] = useState(false);

    const initialFormattingToggleObject: {
        delimiter: boolean;
        currency: boolean;
        decimal: boolean;
    } = {
        delimiter:
            store.currentReport?.formattingValues?.delimiter !== undefined &&
            store.currentReport?.formattingValues?.delimiter !== null,
        currency:
            store.currentReport?.formattingValues?.currency !== undefined &&
            store.currentReport?.formattingValues?.currency !== null,
        decimal:
            store.currentReport?.formattingValues?.decimal !== undefined &&
            store.currentReport?.formattingValues?.decimal !== null,
    };

    const [formattingToggles, setFormattingToggles] = useState(
        initialFormattingToggleObject,
    );

    function handleFieldAxisTitleChange(
        evt: React.ChangeEvent<HTMLInputElement>,
        fieldUse: FieldUse,
    ) {
        if (fieldUse === FieldUse.X) {
            const field =
                xField ?? store.currentReport?.addVizReportField(FieldUse.X);
            field?.setDisplayName(evt.currentTarget.value);
        } else {
            const field =
                yField ?? store.currentReport?.addVizReportField(FieldUse.Y);
            field?.setDisplayName(evt.currentTarget.value);
        }
    }

    function handleReportDataFieldChange(
        reportDataField: ReportDataField | undefined,
        fieldUse: FieldUse,
    ) {
        if (fieldUse === FieldUse.X) {
            if (reportDataField) {
                const field =
                    xField ??
                    store.currentReport?.addVizReportField(FieldUse.X);
                field?.resetField();
                field?.setName(reportDataField.fieldName);
                field?.setDisplayName(reportDataField.displayName);

                if (
                    reportDataField.variation !==
                    ReportDataFieldVariation.Virtual
                ) {
                    field?.setReportDataField(reportDataField);
                }
            }
        } else if (fieldUse === FieldUse.Y) {
            if (reportDataField) {
                const field =
                    yField ??
                    store.currentReport?.addVizReportField(FieldUse.Y);
                field?.resetField();

                field?.setName(reportDataField.fieldName);
                field?.setDisplayName(reportDataField.displayName);
                field?.setReportDataField(reportDataField);
            }
        } else if (fieldUse === FieldUse.VizGroup) {
            if (reportDataField) {
                const field =
                    vizGroupField ??
                    store.currentReport?.addVizReportField(FieldUse.VizGroup);
                field?.resetField();
                field?.setName(reportDataField.fieldName);
                field?.setDisplayName(reportDataField.displayName);

                if (
                    reportDataField.variation !==
                    ReportDataFieldVariation.Virtual
                ) {
                    field?.setReportDataField(reportDataField);
                }
            } else {
                const vizGroupField = store.currentReport?.reportFields.find(
                    (value) =>
                        value.isActive && value.fieldUse === FieldUse.VizGroup,
                );
                if (vizGroupField) {
                    store.currentReport?.removeReportField(vizGroupField);
                }
            }
        }

        /*if (xField && yField) {
            updatePreviewGraph();
        }*/
    }

    function handleXAxisDateAggregationChange(dateAgg?: {
        id: DateAggregation;
        value: string;
    }) {
        xField?.setDateAggregation(dateAgg?.id);
    }

    function handleVizGroupDateAggregationChange(dateAgg?: {
        id: DateAggregation;
        value: string;
    }) {
        vizGroupField?.setDateAggregation(dateAgg?.id);
        //updatePreviewGraph();
    }

    /*function handleSummerizeDataToggle(toggle: boolean) {
        if (toggle) {
            //handleReportDataFieldChange(undefined, FieldUse.VizGroup);
            handleYAxisAggregationChange();
            setSummerizeDataField(toggle);
        } else {
            setSummerizeDataField(!Boolean(store.currentReportDataView));
        }
        setSummerizeData(!toggle);
        updatePreviewGraph();
    }*/

    function handleXFieldGroupByToggle() {
        setXFieldGroupByDataToggle((prev) => !prev);
        if (xFieldGroupByDataToggle) {
            handleXAxisDateAggregationChange();
        }
        //updatePreviewGraph();
    }

    function handleYAxisAggregationChange(agg?: {
        id: AggregateFunction;
        value: string;
    }) {
        yField?.setAggregateFunction(agg?.id);

        if (!canBeDistinct(agg?.id)) {
            yField?.setDistinct(false);
        }
        //updatePreviewGraph();
        // NOTE This is a hard constraint that ReportDataFields that are distinct per answer; not per evaluation must have
        // their fieldName prefixed by "Answer" e.g. "AnswerSentiment", "AnswerNote", "AnswerAlert"
        // yField?.setDistinct(
        //     agg?.id === AggregateFunction.Count &&
        //         !yField?.reportDataField?.fieldName
        //             .toLowerCase()
        //             .startsWith("answer"),
        // );
    }

    function handleDelimiterChange(arg: DelimiterOptions | null) {
        store.currentReport?.setFormattingValues("delimiter", arg);
    }
    /*function updatePreviewGraph() {
        reportStore.reportDataViewModel.loadPreviewReportData(
            reportStore.orgSelectorStore.orgId,
            store.prepareReportForPreview,
            false,
        );
    }*/
    function handleCurrencyChange(arg: CurrencyOptions | null) {
        store.currentReport?.setFormattingValues("currency", arg);
    }

    function handleDecimalChange(arg: DecimalOptions | null) {
        store.currentReport?.setFormattingValues("decimal", arg);
    }

    // handle clearing out formatting values if switch is toggled off
    useEffect(() => {
        if (!formattingToggles.currency) {
            handleCurrencyChange(null);
        }
        if (!formattingToggles.decimal) {
            handleDecimalChange(null);
        }
        if (!formattingToggles.delimiter) {
            handleDelimiterChange(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formattingToggles]);
    /*useEffect(() => {
        if (xField && yField) {
            updatePreviewGraph();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xField, yField]);*/
    // handle setting initial formatting toggles after loading report into store
    useEffect(() => {
        setFormattingToggles(initialFormattingToggleObject);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentReport?.formattingValues]);

    // handle setting initial xFieldGroupBy toggle after loading report into store
    useEffect(() => {
        setXFieldGroupByDataToggle(initialXFieldGroupByToggle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [xField?.dateAggregation]);

    return (
        <>
            <Grid item xs={12} className={classes.mainSectionTitle}>
                <Typography className={classes.mainSectionTitleText}>
                    Build Your Report
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                style={{ display: "inline-flex" }}
                className={classes.vertSpace}
            >
                <Grid
                    container
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} container>
                        <Typography
                            className={classes.sectionTitle}
                            style={{ paddingTop: "20px" }}
                        >
                            Horizontal Axis
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xl={9}
                    lg={9}
                    md={9}
                    sm={9}
                    xs={9}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <AcxSelectSingle
                                options={
                                    store.availableReportHorizAxisFields ?? []
                                }
                                id={"report-editor-x-datafield"}
                                defaultValue={xField?.reportDataField}
                                valueField={"fieldName"}
                                labelField={"displayName"}
                                fullWidth
                                isDisabled={
                                    !Boolean(store.currentReportDataView)
                                }
                                onChange={(arg0) =>
                                    handleReportDataFieldChange(
                                        arg0,
                                        FieldUse.X,
                                    )
                                }
                                inputLabel={"Data to Display"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AcxMainTextField
                                id={"report-editor-x-axis-label"}
                                value={xField?.displayName}
                                disabled={!Boolean(store.currentReportDataView)}
                                labelText={"Axis Label"}
                                onChange={(evt) =>
                                    handleFieldAxisTitleChange(evt, FieldUse.X)
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {xField?.reportDataField?.datatype?.valueOf() ===
                ReportFieldDataTypes.Date.valueOf() && (
                <>
                    <Grid
                        item
                        xs={12}
                        style={{ display: "inline-flex" }}
                        className={classes.vertSpace}
                    >
                        <Grid
                            container
                            item
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={3}
                            direction="row"
                            wrap={"nowrap"}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        ></Grid>
                        <Grid
                            container
                            item
                            xl={9}
                            lg={9}
                            md={9}
                            sm={9}
                            xs={9}
                            direction="row"
                            wrap={"nowrap"}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid container item xs={12} spacing={2}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{ display: "inline-flex" }}
                                >
                                    <FormControlLabel
                                        style={{ display: "inline-flex" }}
                                        control={
                                            <Switch
                                                checked={
                                                    xFieldGroupByDataToggle
                                                }
                                                onChange={
                                                    handleXFieldGroupByToggle
                                                }
                                            />
                                        }
                                        label="Group values by:"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AcxSelectSingle
                                        options={dateGroupings}
                                        id={"report-editor-x-groupby"}
                                        isDisabled={!xFieldGroupByDataToggle}
                                        defaultValue={dateAggregationSelector(
                                            xField?.dateAggregation,
                                        )}
                                        enableNoSelection
                                        valueField={"id"}
                                        labelField={"value"}
                                        fullWidth
                                        onChange={
                                            handleXAxisDateAggregationChange
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: "inline-flex",
                            background: "#DBEAFE",
                            borderRadius: "4px",
                            padding: "8px",
                        }}
                        className={classes.vertSpace}
                    >
                        <Typography style={{ color: "#2563EB" }}>
                            Grouping allows you to visually distinguish and
                            compare multiple sets of data.
                        </Typography>
                    </Grid>
                </>
            )}
            <Grid
                item
                xs={12}
                style={{ display: "inline-flex" }}
                className={classes.vertSpace}
            >
                <Grid
                    container
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} container>
                        <Typography
                            className={classes.sectionTitle}
                            style={{ paddingTop: "20px" }}
                        >
                            Vertical Axis
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xl={9}
                    lg={9}
                    md={9}
                    sm={9}
                    xs={9}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <AcxSelectSingle
                                options={
                                    store.availableReportVertAxisFields ?? []
                                }
                                isDisabled={
                                    !Boolean(store.currentReportDataView)
                                }
                                id={"report-editor-y-datafield"}
                                defaultValue={yField?.reportDataField}
                                valueField={"fieldName"}
                                labelField={"displayName"}
                                fullWidth
                                onChange={(arg0) =>
                                    handleReportDataFieldChange(
                                        arg0,
                                        FieldUse.Y,
                                    )
                                }
                                inputLabel={"Data to Display"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AcxMainTextField
                                id={"report-editor-y-axis-label"}
                                value={yField?.displayName}
                                disabled={!Boolean(store.currentReportDataView)}
                                onChange={(evt) =>
                                    handleFieldAxisTitleChange(evt, FieldUse.Y)
                                }
                                labelText={"Axis Label"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {Boolean(yField) && (
                <>
                    <Grid
                        item
                        xs={12}
                        style={{ display: "inline-flex" }}
                        className={classes.vertSpace}
                    >
                        <Grid
                            container
                            item
                            xl={3}
                            lg={3}
                            md={3}
                            sm={3}
                            xs={3}
                            direction="row"
                            wrap={"nowrap"}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        ></Grid>
                        <Grid
                            container
                            item
                            xl={9}
                            lg={9}
                            md={9}
                            sm={9}
                            xs={9}
                            direction="row"
                            wrap={"nowrap"}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid container item xs={12} spacing={2}>
                                <Grid
                                    item
                                    xs={6}
                                    style={{ display: "inline-flex" }}
                                ></Grid>
                                <Grid item xs={6}>
                                    {(store.currentReport?.vizType ===
                                        VizType.Pie ||
                                        store.currentReport?.vizType ===
                                            VizType.Table) && (
                                        <AcxSelectSingle
                                            options={aggregateFunctionsForReportFieldPercentage(
                                                yField,
                                            )}
                                            isDisabled={false}
                                            id={
                                                "report-editor-y-datafield-aggregator"
                                            }
                                            enableNoSelection
                                            defaultValue={aggregateFuncSelectorPercentage(
                                                yField?.aggregateFunction,
                                            )}
                                            valueField={"id"}
                                            labelField={"value"}
                                            fullWidth
                                            onChange={
                                                handleYAxisAggregationChange
                                            }
                                        />
                                    )}
                                    {store.currentReport?.vizType !==
                                        VizType.Pie &&
                                        store.currentReport?.vizType !==
                                            VizType.Table && (
                                            <AcxSelectSingle
                                                options={aggregateFunctionsForReportField(
                                                    yField,
                                                )}
                                                isDisabled={false}
                                                id={
                                                    "report-editor-y-datafield-aggregator"
                                                }
                                                enableNoSelection
                                                defaultValue={aggregateFuncSelector(
                                                    yField?.aggregateFunction,
                                                )}
                                                valueField={"id"}
                                                labelField={"value"}
                                                fullWidth
                                                onChange={
                                                    handleYAxisAggregationChange
                                                }
                                            />
                                        )}
                                    {canBeDistinct(
                                        yField?.aggregateFunction,
                                    ) && (
                                        <AcxBooleanFormControlGroup
                                            formControlModel={yField}
                                            control={"checkbox"}
                                            modelFieldControls={[
                                                {
                                                    fieldName: "distinct",
                                                    fieldSetter: "setDistinct",
                                                    displayName: "Distinct",
                                                    infoText:
                                                        "Perform aggregation over distinct values",
                                                } as AcxBooleanFormFieldControllers<ReportField>,
                                            ]}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: "block",
                            background: "#DBEAFE",
                            borderRadius: "4px",
                            padding: "8px",
                        }}
                        className={classes.vertSpace}
                    >
                        <Typography style={{ color: "#2563EB" }}>
                            <b>Summary Functions:</b>
                        </Typography>
                        <Typography
                            style={{
                                color: "#2563EB",
                                paddingLeft: "10px",
                            }}
                        >
                            <b>Sum:</b> Adds up all data points into a total
                        </Typography>
                        <Typography
                            style={{ color: "#2563EB", paddingLeft: "10px" }}
                        >
                            <b>Average:</b> The sum of all data points, divided
                            by the total number of data points
                        </Typography>
                        <Typography
                            style={{ color: "#2563EB", paddingLeft: "10px" }}
                        >
                            <b>Count: </b>Count the number of data points
                        </Typography>
                        <Typography
                            style={{ color: "#2563EB", paddingLeft: "10px" }}
                        >
                            <b>Minimum: </b> The data point with the lowest
                            value
                        </Typography>
                        <Typography
                            style={{ color: "#2563EB", paddingLeft: "10px" }}
                        >
                            <b>Maximum: </b> The data point with the highest
                            value
                        </Typography>
                        <Typography
                            style={{ color: "#2563EB", paddingLeft: "10px" }}
                        >
                            <b>Percentage: </b> Percentage of data points
                        </Typography>
                    </Grid>
                </>
            )}

            <Grid
                item
                xs={12}
                style={{ display: "inline-flex" }}
                className={classes.vertSpace}
            >
                <Grid
                    container
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={12} container>
                        <Typography
                            className={classes.sectionTitle}
                            style={{ paddingTop: "20px" }}
                        >
                            Series
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xl={9}
                    lg={9}
                    md={9}
                    sm={9}
                    xs={9}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <AcxSelectSingle
                                options={
                                    store.availableReportHorizAxisFields ?? []
                                }
                                isDisabled={
                                    !Boolean(store.currentReportDataView)
                                }
                                fullWidth
                                id={"report-data-series-selector"}
                                defaultValue={vizGroupField?.reportDataField}
                                valueField={"fieldName"}
                                labelField={"displayName"}
                                enableNoSelection
                                onChange={(arg0) =>
                                    handleReportDataFieldChange(
                                        arg0,
                                        FieldUse.VizGroup,
                                    )
                                }
                                inputLabel={"Series Grouping"}
                            />
                        </Grid>
                        {vizGroupField?.reportDataField?.datatype?.valueOf() ===
                            ReportFieldDataTypes.Date.valueOf() && (
                            <Grid item xs={6}>
                                <AcxSelectSingle
                                    options={dateGroupings}
                                    id={"report-editor-vizGroupDate-groupby"}
                                    isDisabled={
                                        !Boolean(store.currentReportDataView)
                                    }
                                    defaultValue={dateAggregationSelector(
                                        vizGroupField?.dateAggregation,
                                    )}
                                    enableNoSelection
                                    valueField={"id"}
                                    labelField={"value"}
                                    fullWidth
                                    onChange={
                                        handleVizGroupDateAggregationChange
                                    }
                                    inputLabel={"Grouped By"}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Typography className={classes.sectionTitle}>Formatting</Typography>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={5}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formattingToggles.delimiter}
                                onChange={() => {
                                    setFormattingToggles((prev) => ({
                                        ...prev,
                                        delimiter: !prev.delimiter,
                                    }));
                                }}
                            />
                        }
                        label="Visually delimit large numbers"
                    />
                </Grid>
                <Grid item xs={4}>
                    <AcxSelectSingle
                        options={delimiterOptions}
                        fullWidth
                        id={"report-data-delimiter-selector"}
                        defaultValue={delimiterOptions.find(
                            (item) =>
                                item.value ===
                                store.currentReport?.formattingValues
                                    ?.delimiter,
                        )}
                        valueField={"value"}
                        labelField={"label"}
                        onChange={(arg) => handleDelimiterChange(arg.value)}
                        isDisabled={!formattingToggles.delimiter}
                        inputLabel={""}
                    />
                </Grid>
                <Grid container item xs={3} alignContent="flex-end">
                    <Typography
                        style={{
                            marginBottom: "12px",
                            color: theme.palette.gray.main,
                        }}
                    >
                        {`e.g. ${formatChartValue(
                            1000000.0001,
                            store.currentReport,
                        )}`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={5}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formattingToggles.currency}
                                onChange={() => {
                                    setFormattingToggles((prev) => ({
                                        ...prev,
                                        currency: !prev.currency,
                                    }));
                                }}
                            />
                        }
                        label="Use symbols for currency"
                    />
                </Grid>
                <Grid item xs={4}>
                    <AcxSelectSingle
                        options={currencyOptions}
                        fullWidth
                        id={"report-data-currency-selector"}
                        defaultValue={currencyOptions.find(
                            (item) =>
                                item.value ===
                                store.currentReport?.formattingValues?.currency,
                        )}
                        valueField={"value"}
                        labelField={"label"}
                        onChange={(arg) => handleCurrencyChange(arg.value)}
                        inputLabel={""}
                        isDisabled={!formattingToggles.currency}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={5}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formattingToggles.decimal}
                                onChange={() => {
                                    setFormattingToggles((prev) => ({
                                        ...prev,
                                        decimal: !prev.decimal,
                                    }));
                                }}
                            />
                        }
                        label="Display decimals for numbers"
                    />
                </Grid>

                <Grid item xs={4}>
                    <AcxSelectSingle
                        options={decimalOptions}
                        fullWidth
                        id={"report-data-decimals-selector"}
                        defaultValue={decimalOptions.find(
                            (item) =>
                                item.value ===
                                store.currentReport?.formattingValues?.decimal,
                        )}
                        valueField={"value"}
                        labelField={"label"}
                        onChange={(arg) => handleDecimalChange(arg.value)}
                        inputLabel={""}
                        isDisabled={!formattingToggles.decimal}
                    />
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={
                                store.currentReport?.formattingValues
                                    ?.hideDataLabels ?? false
                            }
                            onChange={() =>
                                store.currentReport?.setFormattingValues(
                                    "hideDataLabels",
                                    !store.currentReport?.formattingValues
                                        ?.hideDataLabels,
                                )
                            }
                        />
                    }
                    label="Hide data labels"
                />
            </Grid>
        </>
    );
});

export default VizDataSection;
