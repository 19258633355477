import { Observer, observer } from "mobx-react";
import React, { useCallback, useEffect, useRef } from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { PlayerOptions } from "components/SoundClipEditor/SoundClipEditor";
import BlurbBox from "./BlurbBox/BlurbBox";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { AcxTranscriptionStore } from "../Stores/AcxTranscriptionStore";
import { useWindowResize } from "../utils";
import { SpeakerStore } from "../Stores/SpeakerStore/SpeakerStore";

type Props = {
    playerBlurb?: (playerOptions: PlayerOptions) => void;
    audioClip?: { status: "default" | "success" | "error" | "loading" };
    hasEvaluation: boolean;
    onClip?: (start: number, end: number) => void;
    speakerStore?: SpeakerStore;
    disableClip?: boolean;
};

export const AcxTranscriptionBody: React.FC<Props> = observer(
    ({
        playerBlurb,
        audioClip,
        hasEvaluation,
        onClip,
        speakerStore,
        disableClip,
    }) => {
        const authstore = useStore(AuthStore);
        const store = useStore(AcxTranscriptionStore);

        if (!authstore.canUserView("Transcription")) {
            return null;
        }

        const onCreateClip = (timeArray: number[]) => {
            if (store.highlightedText.length > 1) {
                onClip?.(
                    store.highlightedText[0].startTime,
                    store.highlightedText[store.highlightedText.length - 1]
                        .endTime,
                );
            } else {
                onClip?.(timeArray[0], timeArray[1]);
            }
            store.resetHighlightedText();
        };

        const onCancelHighlight = () => {
            const updatedBlurbs = [...store.blurbs];

            store.highlightedText.forEach((highlighted) => {
                const cleanedText = highlighted.text.replace(
                    /<mark>(.*?)<\/mark>/g,
                    "$1",
                );
                if (updatedBlurbs[highlighted.index]) {
                    updatedBlurbs[highlighted.index].text = cleanedText;
                }
            });

            store.setHighlightedText([]);
            store.selectIndex = -1;
        };

        useEffect(() => {
            if (audioClip?.status === "success") {
                onCancelHighlight();
            }
            //eslint-disable-next-line react-hooks/exhaustive-deps
        }, [audioClip]);

        useEffect(() => {
            if (store.scrollIndex !== null) {
                // @ts-ignore
                listRef.current?.scrollToItem(store.scrollIndex, "smart");
                store.setScrollIndex(null);
            }
        }, [store, store.scrollIndex]);

        const listRef = useRef(null);
        const sizeMap = useRef({});
        const setSize = useCallback((index, size) => {
            sizeMap.current = { ...sizeMap.current, [index]: size };
            // @ts-ignore
            listRef.current?.resetAfterIndex(index);
        }, []);
        const getSize = (index) => sizeMap.current[index] || 80;
        const [windowWidth] = useWindowResize();

        return (
            <AutoSizer className="pendo-ignore">
                {({ height, width }) => (
                    <Observer>
                        {() => (
                            <List
                                ref={listRef}
                                height={height - 1}
                                itemCount={store.blurbs.length}
                                width={width - 1}
                                itemData={store.blurbs}
                                itemSize={getSize}
                            >
                                {({ data, index, style }) => {
                                    const blurb = data[index];
                                    const rowRef = useRef(null);

                                    useEffect(() => {
                                        setSize(
                                            index,
                                            // @ts-ignore
                                            rowRef.current?.getBoundingClientRect()
                                                .height,
                                        );
                                        // eslint-disable-next-line react-hooks/exhaustive-deps
                                    }, [setSize, index, windowWidth]);

                                    return (
                                        <div
                                            style={{
                                                ...style,
                                                whiteSpace: "normal",
                                            }}
                                        >
                                            <div ref={rowRef}>
                                                <BlurbBox
                                                    blurb={blurb}
                                                    hasEvaluation={
                                                        hasEvaluation
                                                    }
                                                    toolTipOpen={
                                                        store.selectIndex ===
                                                        index
                                                    }
                                                    audioClip={audioClip}
                                                    onCreateClip={onCreateClip}
                                                    cancelHighlight={
                                                        onCancelHighlight
                                                    }
                                                    playerBlurb={playerBlurb}
                                                    index={index}
                                                    speakerStore={speakerStore}
                                                    disableClip={disableClip}
                                                />
                                            </div>
                                        </div>
                                    );
                                }}
                            </List>
                        )}
                    </Observer>
                )}
            </AutoSizer>
        );
    },
);
