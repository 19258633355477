import React, { FunctionComponent } from "react";

interface OwnProps {}

type Props = OwnProps;

const EmptyClipListSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="124px"
            height="124px"
            viewBox="0 0 124 124"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Evaluation"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Interaction---Analyst-Evaluate----Classifiers"
                    transform="translate(-1230.000000, -163.000000)"
                >
                    <g id="Content" transform="translate(0.000000, 56.000000)">
                        <g
                            id="Quotes"
                            transform="translate(1144.000000, 0.000000)"
                        >
                            <g
                                id="Stacked-Group"
                                transform="translate(16.000000, 56.000000)"
                            >
                                <g id="Rectangle-Copy-4">
                                    <g
                                        id="Group-10"
                                        transform="translate(70.000000, 51.000000)"
                                    >
                                        <circle
                                            id="Oval"
                                            fill="#C9C9C9"
                                            fillRule="nonzero"
                                            opacity="0.2"
                                            cx="62"
                                            cy="62"
                                            r="62"
                                        ></circle>
                                        <g
                                            id="chat-46"
                                            transform="translate(32.000000, 36.000000)"
                                            stroke="#CFCFCF"
                                            strokeWidth="2"
                                        >
                                            <path
                                                d="M25.5,42.5 L36.5278532,42.5 C37.1597759,42.5 37.7755618,42.6995448 38.2873826,43.0701736 L52,53 L52,53 L52,43.5 C52,42.9477153 52.4477153,42.5 53,42.5 L57,42.5 C58.6568542,42.5 60,41.1568542 60,39.5 L60,16 C60,14.3431458 58.6568542,13 57,13 L47.6298717,13 L47.6298717,13"
                                                id="Line-2"
                                                strokeLinecap="square"
                                            ></path>
                                            <path
                                                d="M5,1 C3.8954305,1 2.8954305,1.44771525 2.17157288,2.17157288 C1.44771525,2.8954305 1,3.8954305 1,5 L1,28 C1,29.060866 1.42142736,30.0782816 2.17157288,30.8284271 C2.92171839,31.5785726 3.93913404,32 5,32 L11,32 L11,44.7962318 L25.6242649,32 L43,32 C44.1045695,32 45.1045695,31.5522847 45.8284271,30.8284271 C46.5522847,30.1045695 47,29.1045695 47,28 L47,5 C47,3.8954305 46.5522847,2.8954305 45.8284271,2.17157288 C45.1045695,1.44771525 44.1045695,1 43,1 L5,1 Z"
                                                id="Path"
                                                fillRule="nonzero"
                                            ></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default EmptyClipListSvg;
