import BaseEntity from "./BaseEntity";

export default class StorageAccount extends BaseEntity {
    name: string;
    keyVaultSecretName: string;
    sasKeySecretName: string;
    storageAccountUse: string;
}

export enum StorageAccountUseOptions {
    Holding = "Holding",
    Intake = "Intake",
    Main = "Main",
    Montages = "Montages",
    Transcriptions = "Transcriptions",
    Internal = "Internal",
}
