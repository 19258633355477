import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import AcxExpansion from "components/UI/AcxExpansion";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import moment from "moment";
import { AsyncTaskStatus } from "stores/BaseStore";
import { useStore } from "utils/useStore";
import makeStyles from '@mui/styles/makeStyles';
import { addtlMetadataCatTitle } from "./ManageFilterInputDrawer";
import AudioMetadataModel from "models/AudioMetadataModel";
import { startCase } from "lodash";

const useStyles = makeStyles(() => ({
    grayColor: { color: "#71717A" },
    value: {
        whiteSpace: "normal",
        wordBreak: "break-all",
    },
}));

export const FocusedAdditionalMetadata = observer(() => {
    const store = useStore(ConversationsStore);
    const classes = useStyles();

    // Using the ConversationsStore filter categories we get the
    // actual labels for meta values 1-5
    const metaLabels = useMemo(
        () =>
            store.additionalFilterCategories.find(
                (category) => category.title === addtlMetadataCatTitle,
            )?.filters,
        [store],
    );

    const [metadata, setMetadata] = useState<
        | {
              audioMetadata: AudioMetadataModel;
              extendedMetadata: Record<string, unknown>;
          }
        | undefined
    >(undefined);
    const isMetadataLoading = store.getTaskLoading("SingleConvoMetadata");

    return (
        <AcxExpansion
            header={
                <Stack>
                    <Box fontWeight={"bold"} fontSize={14}>
                        Additional Metadata
                    </Box>
                </Stack>
            }
            onExpandChange={(expanded) => {
                if (metadata || isMetadataLoading) return;
                if (expanded) {
                    store
                        .getFocusedConversationAdditionalMetadata()
                        .then((metadata) => {
                            if (metadata !== AsyncTaskStatus.Error)
                                setMetadata(metadata);
                        });
                }
            }}
            conversationVariant
            body={
                <React.Fragment>
                    {metadata && (
                        <Grid container direction="column" gap={1}>
                            <Grid item container direction="column">
                                <Grid
                                    item
                                    fontSize={14}
                                    className={classes.grayColor}
                                >
                                    File Name
                                </Grid>
                                <Grid item className={classes.value}>
                                    {
                                        metadata.audioMetadata
                                            .fileName as React.ReactNode
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container direction="column">
                                <Grid
                                    item
                                    fontSize={14}
                                    className={classes.grayColor}
                                >
                                    File Size (KB)
                                </Grid>
                                <Grid item className={classes.value}>
                                    {
                                        metadata.audioMetadata
                                            .fileSizeKiloBytes as React.ReactNode
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container direction="column">
                                <Grid
                                    item
                                    fontSize={14}
                                    className={classes.grayColor}
                                >
                                    Arrival Date
                                </Grid>
                                <Grid item className={classes.value}>
                                    {moment(
                                        metadata.audioMetadata.arrivedOn,
                                    ).format("MM/DD/YYYY")}
                                </Grid>
                            </Grid>
                            {store.currentEval && (
                                <Grid item container direction="column">
                                    <Grid
                                        item
                                        fontSize={14}
                                        className={classes.grayColor}
                                    >
                                        Sample Date
                                    </Grid>
                                    <Grid
                                        item
                                        style={{
                                            whiteSpace: "normal",
                                            wordBreak: "break-all",
                                        }}
                                    >
                                        {moment(
                                            store.currentEval.createdOn,
                                        ).format("MM/DD/YYYY")}
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item container direction="column">
                                <Grid
                                    item
                                    fontSize={14}
                                    className={classes.grayColor}
                                >
                                    Client Call ID
                                </Grid>
                                <Grid item className={classes.value}>
                                    {
                                        metadata.audioMetadata
                                            .clientCallId as React.ReactNode
                                    }
                                </Grid>
                            </Grid>
                            {metaLabels &&
                                metaLabels
                                    .filter(
                                        (mapper) =>
                                            !!metadata.audioMetadata[
                                                mapper.storeObjectKey
                                            ],
                                    )
                                    .map((mapper) => (
                                        <Grid item container direction="column">
                                            <Grid
                                                item
                                                fontSize={14}
                                                className={classes.grayColor}
                                            >
                                                {mapper.filterLabel ??
                                                    mapper.filterTitle}
                                            </Grid>
                                            <Grid
                                                item
                                                className={classes.value}
                                            >
                                                {
                                                    metadata.audioMetadata[
                                                        mapper.storeObjectKey
                                                    ] as React.ReactNode
                                                }
                                            </Grid>
                                        </Grid>
                                    ))}
                            {Object.entries(metadata.extendedMetadata).map(
                                ([key, value]) => (
                                    <Grid item container direction="column">
                                        <Grid
                                            item
                                            fontSize={14}
                                            className={classes.grayColor}
                                        >
                                            {startCase(key)}
                                        </Grid>
                                        <Grid item className={classes.value}>
                                            {value as React.ReactNode}
                                        </Grid>
                                    </Grid>
                                ),
                            )}
                        </Grid>
                    )}
                    {(!metadata || isMetadataLoading) && (
                        <AcxLoadingIndicator
                            color="secondary"
                            alternate="PuffLoader"
                            size={32}
                        />
                    )}
                </React.Fragment>
            }
        />
    );
});
