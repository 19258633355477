import { Observer, observer } from "mobx-react";

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import CopyIcon from "@mui/icons-material/FileCopyOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import ClassifierLibraryStore from "components/Classifiers/Stores/ClassifierLibraryStore";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { CustomControlItem } from "components/UI/AcxDataGrid/AcxDataGridStore";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import ClassifierBuilderV2ReclassifyDialogStore from "../../Stores/ClassifierBuilderV2ReclassifyDialogStore";
import { ClassifierBuilderV2Store } from "../../Stores/ClassifierBuilderV2Store";
import ClassifierBuilderV2Popper from "../Builder/ClassifierBuilderV2Popper";
import ClassifierBuilderV2CopyDialog from "../Dialogs/ClassifierBuilderV2CopyDialog";
import ClassifierBuilderV2ReclassifyDialog from "../Dialogs/ClassifierBuilderV2ReclassifyDialog";
import ClassifierBuilderV2UnpublishDialog from "../Dialogs/ClassifierBuilderV2UnpublishDialog";
import { columnDefinitions } from "./ClassifierBuilderTableV2Columns";

function InformationIcon() {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36 20C36 24.2435 34.3143 28.3131 31.3137 31.3137C28.3131 34.3143 24.2435 36 20 36C15.7565 36 11.6869 34.3143 8.68629 31.3137C5.68571 28.3131 4 24.2435 4 20C4 15.7565 5.68571 11.6869 8.68629 8.68629C11.6869 5.68571 15.7565 4 20 4C24.2435 4 28.3131 5.68571 31.3137 8.68629C34.3143 11.6869 36 15.7565 36 20ZM22 28C22 28.5304 21.7893 29.0391 21.4142 29.4142C21.0391 29.7893 20.5304 30 20 30C19.4696 30 18.9609 29.7893 18.5858 29.4142C18.2107 29.0391 18 28.5304 18 28C18 27.4696 18.2107 26.9609 18.5858 26.5858C18.9609 26.2107 19.4696 26 20 26C20.5304 26 21.0391 26.2107 21.4142 26.5858C21.7893 26.9609 22 27.4696 22 28ZM20 10C19.4696 10 18.9609 10.2107 18.5858 10.5858C18.2107 10.9609 18 11.4696 18 12V20C18 20.5304 18.2107 21.0391 18.5858 21.4142C18.9609 21.7893 19.4696 22 20 22C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20V12C22 11.4696 21.7893 10.9609 21.4142 10.5858C21.0391 10.2107 20.5304 10 20 10Z"
                fill="#3664D6"
            />
        </svg>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    controlBtn: {
        borderRadius: "4px",
        border: "1px solid #E4E4E7",
        backgroundColor: "#FFF",
        color: `#3F3F46!important`,
        "&:hover": {
            backgroundColor: "#F4F4F5!important",
        },
    },
    hidden: {
        visibility: "hidden",
    },
    popover: {
        pointerEvents: "none",
    },
    cell: {
        width: "100%",
        height: "100%",
    },
    newClassifierButton: {
        textDecoration: "none",
    },
}));

export type ClassifierDetails = {
    title: string;
    description: string | null;
    id: string;
    isPublished: boolean;
    createdBy: string;
} | null;

interface IClassifierBuilderTableV2Props {}

type Props = IClassifierBuilderTableV2Props;

const ClassifierBuilderTableV2: React.FC<Props> = observer((props: Props) => {
    const store = useStore(ClassifierBuilderV2Store);
    const reclassifyStore = useStore(ClassifierBuilderV2ReclassifyDialogStore);
    const authStore = useStore(AuthStore);
    const classes = useStyles();
    const navigate = useNavigate();
    const [unpublishDialogIsOpen, setUnpublishDialogIsOpen] = useState(false);
    const [copyDialogIsOpen, setCopyDialogIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [popperDetails, setPopperDetails] = useState<ClassifierDetails>(null);
    const [mouseInPopper, setMouseInPoper] = useState(false);
    const [mouseInCell, setMouseInCell] = useState(false);
    const [open, setOpen] = useState(false);

    const libraryStore = useStore(ClassifierLibraryStore);
    const [isConfirmAddLibraryDialogOpen, setIsConfirmAddLibraryDialogOpen] =
        useState(false);
    // Determines whether to show the "Add to Library" dialog or the "Remove from Library" dialog
    const [isAddLibraryDialog, setIsAddLibraryDialog] = useState(false);
    const [isAddGroupDialogOpen, setIsAddGroupDialogOpen] = useState(false);
    const [isLibraryActionLoading, setIsLibraryActionLoading] = useState(false);

    const restrictReclassify =
        !authStore.isUserUltra() &&
        authStore.canUserView("Restrict Reclassify");

    const onUnpublish = async () => {
        if (store.singleSelectedDataGridClassifier) {
            await store.unpublishClassifier(
                store.singleSelectedDataGridClassifier.id,
            );
        }
        onClose();
    };

    const onPublish = useCallback(() => {
        if (store.singleSelectedDataGridClassifier) {
            store.publishClassifier(store.singleSelectedDataGridClassifier.id);
        }
    }, [store]);

    const onClose = () => {
        setUnpublishDialogIsOpen(false);
    };

    const onOpen = () => {
        setUnpublishDialogIsOpen(true);
    };

    const onCopy = useCallback(async () => {
        store.copyClassifier({
            onSuccess: () => {
                setCopyDialogIsOpen(true);
            },
        });
    }, [store]);

    useEffect(() => {
        setOpen(mouseInCell || mouseInPopper);
        if (!mouseInCell && !mouseInPopper) {
            setAnchorEl(null);
        }
    }, [mouseInCell, mouseInPopper]);

    useEffect(() => {
        const columns = columnDefinitions(
            ({
                row: { id, name: title, description, isPublished, createdBy },
            }) => (
                <div
                    className={classes.cell}
                    onMouseEnter={(e) => {
                        setPopperDetails({
                            title,
                            description,
                            id,
                            isPublished,
                            createdBy,
                        });
                        setMouseInCell(true);
                        setAnchorEl(e.currentTarget);
                    }}
                    onMouseLeave={() => {
                        setMouseInCell(false);
                    }}
                >
                    {title}
                </div>
            ),
        );

        const renderSearchInput = () => {
            const handleInputChange = (
                e: React.ChangeEvent<HTMLInputElement>,
            ) => {
                store.setClassifierDgSearchStr(e.target.value);
            };

            const clearInput = () => {
                store.setClassifierDgSearchStr("");
            };

            return (
                <Observer>
                    {() => (
                        <Grid item xs={12} key={`search-rbcs`}>
                            <AcxMainTextField
                                id="classifier-search"
                                value={store.classifierDgSearchStr}
                                placeholderText={
                                    store.classifierDgSearchStr
                                        ? ""
                                        : "Search classifiers..."
                                }
                                onChange={handleInputChange}
                                startAdornment={<SearchIcon />}
                                endAdornment={
                                    <IconButton
                                        size="small"
                                        onClick={clearInput}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                }
                            />
                        </Grid>
                    )}
                </Observer>
            );
        };

        const copyIcon = (
            <Observer>
                {() => (
                    <Tooltip
                        title={
                            store.dgStore.selectedRows.length !== 1
                                ? "Only one classifier can be copied at a time."
                                : "Copy selected classifiers to a new classifier."
                        }
                        className={clsx(
                            !store.dgStore.selectedRows.length &&
                                classes.hidden,
                        )}
                    >
                        <span>
                            <IconButton
                                edge={false}
                                size={"medium"}
                                onClick={onCopy}
                                disabled={
                                    !store.singleSelectedDataGridClassifier
                                }
                            >
                                <CopyIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                )}
            </Observer>
        );
        const reclassifyBtn = (
            <Observer>
                {() => {
                    const reclassifyBtnDisabled =
                        store.orgSelectStore.orgId === undefined ||
                        store.orgSelectStore.orgId === null ||
                        restrictReclassify ||
                        !authStore.canUserEdit("Reclassify") ||
                        store.selectedPublishedDataGridClassifiers.length === 0;
                    return (
                        <Grid item container justifyContent="flex-end">
                            <AcxButton
                                fullWidth
                                style={{
                                    borderRadius: "4px",
                                    border: "1px solid #E4E4E7",
                                    backgroundColor: "#FFF",
                                    color: `#3F3F46!important`,
                                }}
                                className={clsx(
                                    classes.controlBtn,
                                    !store.dgStore.selectedRows.length &&
                                        classes.hidden,
                                )}
                                onClick={() => {
                                    reclassifyStore.setSelectedClassifiers(
                                        store.selectedPublishedDataGridClassifiers,
                                    );
                                    reclassifyStore.dialog.open();
                                }}
                                disabled={reclassifyBtnDisabled}
                                tooltip={
                                    restrictReclassify
                                        ? "This role does not have access to reclassify."
                                        : reclassifyBtnDisabled &&
                                          store.selectedDataGridClassifiers
                                              .length !==
                                              store
                                                  .selectedPublishedDataGridClassifiers
                                                  .length
                                        ? "Classifiers must be published in order to reclassify"
                                        : ""
                                }
                                color="primary"
                            >
                                Reclassify
                            </AcxButton>
                        </Grid>
                    );
                }}
            </Observer>
        );
        const unpublishBtn = (
            <Observer>
                {() => (
                    <Grid item container justifyContent="flex-end">
                        <Tooltip
                            title={
                                store.dgStore.selectedRows.length !== 1
                                    ? "Only one classifier can be unpublished at a time."
                                    : !store.singleSelectedDataGridClassifier
                                          ?.isPublished
                                    ? "Classifier already unpublished"
                                    : "Unpublish selected classifier"
                            }
                            className={clsx(
                                !store.dgStore.selectedRows.length &&
                                    classes.hidden,
                            )}
                        >
                            <span>
                                <AcxButton
                                    fullWidth
                                    style={{
                                        borderRadius: "4px",
                                        border: "1px solid #E4E4E7",
                                        backgroundColor: "#FFF",
                                        color: `#3F3F46!important`,
                                    }}
                                    className={clsx(
                                        classes.controlBtn,
                                        !store.dgStore.selectedRows.length &&
                                            classes.hidden,
                                    )}
                                    onClick={onOpen}
                                    disabled={
                                        !store.singleSelectedDataGridClassifier ||
                                        !store.singleSelectedDataGridClassifier
                                            ?.isPublished
                                    }
                                    color="primary"
                                >
                                    Unpublish
                                </AcxButton>
                            </span>
                        </Tooltip>
                    </Grid>
                )}
            </Observer>
        );
        const publishBtn = (
            <Observer>
                {() => (
                    <Grid item container justifyContent="flex-end">
                        <Tooltip
                            title={
                                store.dgStore.selectedRows.length !== 1
                                    ? "Only one classifier can be published at a time."
                                    : store.singleSelectedDataGridClassifier
                                          ?.isPublished
                                    ? "Classifier already published"
                                    : "Publish selected classifier"
                            }
                            className={clsx(
                                !store.dgStore.selectedRows.length &&
                                    classes.hidden,
                            )}
                        >
                            <span>
                                <AcxButton
                                    fullWidth
                                    style={{
                                        borderRadius: "4px",
                                        border: "1px solid #E4E4E7",
                                        backgroundColor: "#FFF",
                                        color: `#3F3F46!important`,
                                    }}
                                    className={clsx(
                                        classes.controlBtn,
                                        !store.dgStore.selectedRows.length &&
                                            classes.hidden,
                                    )}
                                    onClick={onPublish}
                                    disabled={
                                        !store.singleSelectedDataGridClassifier ||
                                        store.singleSelectedDataGridClassifier
                                            ?.isPublished
                                    }
                                    color="primary"
                                >
                                    Publish
                                </AcxButton>
                            </span>
                        </Tooltip>
                    </Grid>
                )}
            </Observer>
        );

        const libraryBtn = (
            <Observer>
                {() => {
                    const classifier = store.singleSelectedDataGridClassifier;
                    const isInLibrary = store.isClassifierInLibrary(classifier);

                    let tooltip = "";
                    if (store.dgStore.selectedRows.length !== 1)
                        tooltip = "Only one classifier can be added at a time.";
                    else if (isInLibrary)
                        tooltip = "Remove classifier from library";
                    else if (!classifier?.isPublished)
                        tooltip =
                            "Classifier must be published before being added to the library";
                    else tooltip = "Add classifier to library";

                    return (
                        <Grid item container justifyContent="flex-end">
                            <Tooltip
                                title={tooltip}
                                className={clsx(
                                    !store.dgStore.selectedRows.length &&
                                        classes.hidden,
                                )}
                            >
                                <span>
                                    <AcxButton
                                        fullWidth
                                        style={{
                                            borderRadius: "4px",
                                            border: "1px solid #E4E4E7",
                                            backgroundColor: "#FFF",
                                            color: `#3F3F46!important`,
                                        }}
                                        className={clsx(
                                            classes.controlBtn,
                                            !store.dgStore.selectedRows
                                                .length && classes.hidden,
                                        )}
                                        disabled={
                                            !store.singleSelectedDataGridClassifier ||
                                            !store
                                                .singleSelectedDataGridClassifier
                                                .isPublished
                                        }
                                        color="primary"
                                        onClick={async () => {
                                            if (!classifier) return;
                                            const isInLibrary =
                                                store.isClassifierInLibrary(
                                                    classifier,
                                                );

                                            if (isInLibrary) {
                                                setIsAddLibraryDialog(false);
                                                setIsConfirmAddLibraryDialogOpen(
                                                    true,
                                                );
                                            } else if (
                                                !classifier.classifierGroupId
                                            ) {
                                                setIsAddGroupDialogOpen(true);
                                            } else {
                                                setIsAddLibraryDialog(true);
                                                setIsConfirmAddLibraryDialogOpen(
                                                    true,
                                                );
                                            }
                                        }}
                                    >
                                        {isInLibrary && "Remove From Library"}
                                        {!isInLibrary && "Add To Library"}
                                    </AcxButton>
                                </span>
                            </Tooltip>
                        </Grid>
                    );
                }}
            </Observer>
        );

        const newClassifierBtn = (
            <Grid item container justifyContent="flex-end">
                <Link to={`create`} className={classes.newClassifierButton}>
                    <AcxButton
                        fullWidth
                        color="primary"
                        onClick={() => {
                            store.standardCBStore.reset();
                        }}
                    >
                        <AddIcon />
                        New Classifier
                    </AcxButton>
                </Link>
            </Grid>
        );

        const canEditBuilder = authStore.canUserEdit("Builder");
        const canReclassify = authStore.canUserEdit("Reclassify");

        const controls: CustomControlItem[] = [
            {
                controlElement: renderSearchInput(),
                style: {
                    position: "absolute",
                    left: "0px",
                    marginRight: "auto",
                },
                visible: true,
            },
            {
                controlElement: copyIcon,
                style: { margin: "0px" },
                visible: canEditBuilder,
            },
            {
                controlElement: reclassifyBtn,
                visible: canReclassify,
            },
            {
                controlElement: unpublishBtn,
                visible: canEditBuilder,
            },
            {
                controlElement: publishBtn,
                visible: canEditBuilder,
            },
            {
                controlElement: libraryBtn,
                visible:
                    authStore.canUserEdit("Classifier Library Edit") &&
                    store.isAuthcxTenant,
            },
            {
                controlElement: newClassifierBtn,
                visible: canEditBuilder,
            },
        ]
            .filter((c) => c.visible)
            .map(
                (control) =>
                    ({
                        xs: 5,
                        sm: 4,
                        md: 3,
                        lg: 2,
                        xl: 1,
                        ...control,
                    } as CustomControlItem),
            );

        store.dgStore.controls = controls;
        store.dgStore.headerColumnSpan = 1;
        store.dgStore.controlsColumnSpan = 12;
        // store.dgStore.controlsColumnSpan = "auto";
        store.dgStore.removeHeight = "100px";
        store.dgStore.setColumns(columns);
    }, [
        authStore,
        classes.cell,
        classes.controlBtn,
        classes.hidden,
        classes.newClassifierButton,
        onCopy,
        onPublish,
        reclassifyStore.dialog.open,
        restrictReclassify,
        store,
        store.dgStore,
        store.isAuthcxTenant,
        reclassifyStore,
    ]);

    return (
        <>
            <ClassifierBuilderV2UnpublishDialog
                isOpen={unpublishDialogIsOpen}
                onClose={onClose}
                onUnpublish={onUnpublish}
                classifierCount={store.dgStore.selectedRows.length}
                loading={store.isClassifierListLoading}
                selectedRows={store.dgStore.selectedRows}
            />
            <ClassifierBuilderV2CopyDialog
                isOpen={copyDialogIsOpen}
                onClose={() => {
                    setCopyDialogIsOpen(false);
                }}
                onConfirm={() => {
                    store.clearCopiedClassifer();
                    store.loadClassifiers();
                    setCopyDialogIsOpen(false);
                }}
                onCancel={() => {
                    if (store.copiedClassifier) {
                        navigate(`edit/${store.copiedClassifier.id}`);
                    }
                }}
                classifierName={store.copiedClassifier?.name}
            />
            <ClassifierBuilderV2ReclassifyDialog
                defaultValue={store.selectedPublishedDataGridClassifiers}
                showUnpublishedText={
                    store.selectedPublishedDataGridClassifiers.length !==
                    store.selectedDataGridClassifiers.length
                }
                classifierOptions={store.publishedClassifiers}
            />
            <AcxDataGrid dataGridStore={store.dgStore} />
            <ClassifierBuilderV2Popper
                open={open}
                anchorEl={anchorEl}
                handleLeave={() => {
                    setMouseInPoper(false);
                    setPopperDetails(null);
                }}
                handleEnter={() => {
                    setMouseInPoper(true);
                }}
                details={popperDetails}
                editable={
                    authStore.canUserEdit("Builder") &&
                    // This is hardcoded for now since we dont have a column
                    // that can describe the mutability of a classifier right now.
                    // The value to check against should be the createdBy value as seen
                    // in the ClassifiersController (AddCoreClassifierToTenant, and AddClassifiersFromCoreGroupToTenant)
                    (popperDetails?.createdBy !==
                        "AuthenticxClassifierLibrary" ||
                        (store.isAuthcxTenant &&
                            authStore.canUserEdit("Classifier Library Edit")))
                }
            />
            {store.isAuthcxTenant && !!store.singleSelectedDataGridClassifier && (
                <AcxDialog
                    title={<InformationIcon />}
                    subTitle="Please assign 'Group' to Add to Library"
                    text={`Assign a group in the dropdown so we can accurately display the classifier in the correct group`}
                    isOpen={isAddGroupDialogOpen}
                    onClose={() => setIsAddGroupDialogOpen(false)}
                    hideCancelButton
                >
                    <AcxButton
                        style={{
                            borderRadius: "4px",
                            border: "1px solid #E4E4E7",
                            backgroundColor: "#FFF",
                            color: `#3F3F46!important`,
                        }}
                        className={classes.controlBtn}
                        onClick={() => setIsAddGroupDialogOpen(false)}
                    >
                        Cancel
                    </AcxButton>
                    <Link
                        style={{
                            textDecoration: "none",
                        }}
                        to={`edit/${store.singleSelectedDataGridClassifier.id}`}
                    >
                        <AcxButton color="primary">
                            Assign Group To Classifier
                        </AcxButton>
                    </Link>
                </AcxDialog>
            )}
            {store.isAuthcxTenant && isAddLibraryDialog && (
                <AcxDialog
                    title={<InformationIcon />}
                    subTitle="Confirm Classifier Library Addition"
                    text={`Adding a Classifier to the Library will make it available for all Authenticx users to copy to their own account. Are you sure you want to proceed?`}
                    isOpen={isConfirmAddLibraryDialogOpen}
                    onClose={() => setIsConfirmAddLibraryDialogOpen(false)}
                    hideCancelButton
                >
                    <AcxButton
                        style={{
                            borderRadius: "4px",
                            border: "1px solid #E4E4E7",
                            backgroundColor: "#FFF",
                            color: `#3F3F46!important`,
                        }}
                        className={classes.controlBtn}
                        onClick={() => setIsConfirmAddLibraryDialogOpen(false)}
                    >
                        Cancel
                    </AcxButton>
                    <AcxButton
                        color="primary"
                        loading={isLibraryActionLoading}
                        onClick={async () => {
                            if (!store.singleSelectedDataGridClassifier) return;
                            setIsLibraryActionLoading(true);

                            await store.addClassifierToLibrary(
                                store.singleSelectedDataGridClassifier.id,
                            );
                            await store.loadClassifiers();
                            libraryStore.loadLibrary();

                            setIsLibraryActionLoading(false);
                            setIsConfirmAddLibraryDialogOpen(false);
                        }}
                    >
                        Add To Library
                    </AcxButton>
                </AcxDialog>
            )}
            {store.isAuthcxTenant && !isAddLibraryDialog && (
                <AcxDialog
                    title={<InformationIcon />}
                    subTitle="Confirm Classifier Library Removal"
                    text={`Removing a Classifier from the Library will make it unavailable to all Authenticx users. Are you sure you want to proceed?`}
                    isOpen={isConfirmAddLibraryDialogOpen}
                    onClose={() => setIsConfirmAddLibraryDialogOpen(false)}
                    hideCancelButton
                >
                    <AcxButton
                        style={{
                            borderRadius: "4px",
                            border: "1px solid #E4E4E7",
                            backgroundColor: "#FFF",
                            color: `#3F3F46!important`,
                        }}
                        className={classes.controlBtn}
                        onClick={() => setIsConfirmAddLibraryDialogOpen(false)}
                    >
                        Cancel
                    </AcxButton>
                    <AcxButton
                        color="primary"
                        loading={isLibraryActionLoading}
                        onClick={async () => {
                            if (!store.singleSelectedDataGridClassifier) return;
                            setIsLibraryActionLoading(true);

                            await store.removeClassifierFromLibrary(
                                store.singleSelectedDataGridClassifier.id,
                            );
                            await store.loadClassifiers();
                            libraryStore.loadLibrary();

                            setIsLibraryActionLoading(false);
                            setIsConfirmAddLibraryDialogOpen(false);
                        }}
                    >
                        Remove From Library
                    </AcxButton>
                </AcxDialog>
            )}
        </>
    );
});

export default ClassifierBuilderTableV2;
