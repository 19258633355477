import { action, computed, makeObservable, observable } from "mobx";
import { copyBaseFields } from "../../utils/BaseEntityUtils";
import BaseEntity from "../BaseEntity";
import { ReportDataField, ReportDataFieldVariation } from "./ReportDataField";
import { CombinatorFilterType, ReportFilter } from "./ReportFilter";

export class ReportField extends BaseEntity {
    constructor(
        id: string | undefined,
        createdBy: string | undefined,
        modifiedBy: string | undefined,
        reportId?: string,
        reportDataFieldId?: string,
    ) {
        super(id, createdBy, modifiedBy);
        makeObservable(this);
        this.reportId = reportId;
        this.reportDataFieldId = reportDataFieldId;
        this.reportFilters = [];
    }

    @observable name: string;
    @observable reportId?: string;
    @observable reportDataFieldId?: string;
    @observable reportDataField?: ReportDataField;
    @observable aggregateFunction?: AggregateFunction;
    @observable dateAggregation?: DateAggregation;
    @observable quickFilter: boolean;
    @observable grouping: boolean;
    @observable fieldUse?: FieldUse;
    @observable distinct: boolean;
    @observable reportFilters: ReportFilter[];
    @observable displayName: string;
    @observable order?: number;
    @observable virtualFieldType?: string;
    // @observable filterGroupId?: number;

    @computed
    get activeReportFilters() {
        return this.reportFilters.filter((value) => value.isActive);
    }

    @computed
    get reportFilter() {
        const filters = this.activeReportFilters;
        const filterCount = filters.length;
        if ((filterCount ?? 0) > 0) {
            return filters[0];
        } else {
            return undefined;
        }
    }

    @action
    setReportDataField = (reportDataField: ReportDataField) => {
        this.reportDataField = reportDataField;
        this.reportDataFieldId = reportDataField.id;
        this?.setName(reportDataField.fieldName);
        this?.setDisplayName(reportDataField.displayName);
        this.virtualFieldType = reportDataField.virtualFieldType;
    };

    @action
    setName = (name: string) => {
        this.name = name;
    };

    @action
    setDisplayName = (name: string) => {
        this.displayName = name;
    };

    @action
    setVirtualFieldType = (name: string) => {
        this.virtualFieldType = name;
    };

    @action
    setOrder = (order: number) => {
        this.order = order;
    };

    @action
    setFieldUse = (fieldUse: FieldUse) => {
        this.fieldUse = fieldUse;
    };

    @action
    setReportFilters = (reportFilters: ReportFilter[]) => {
        this.reportFilters = reportFilters;
    };

    @action
    addReportFilter = (combinatorFilterType?: CombinatorFilterType) => {
        const reportFilter = new ReportFilter(
            undefined,
            this.createdBy,
            this.modifiedBy,
        );
        reportFilter.setReportField(this);

        if (
            this.reportDataField?.variation === ReportDataFieldVariation.Virtual
        ) {
            this.setName(this.reportDataField.displayName);
            this.setDisplayName(this.reportDataField.displayName);
        }
        this.reportFilters.push(reportFilter);
        return reportFilter;
    };

    @action
    removeReportFilter = (reportFilter: ReportFilter) => {
        const existingIndx = this.reportFilters.findIndex(
            (value) =>
                value.reportFieldId === reportFilter.reportFieldId &&
                value.isActive,
        );
        if (existingIndx > -1) {
            const existing = this.reportFilters[existingIndx];
            if (existing.id && !existing.id.startsWith("local-")) {
                existing.isActive = false;
            } else {
                this.reportFilters.splice(existingIndx, 1);
            }
        }
    };

    @action
    setQuickFilter = (quickFiler: boolean) => {
        this.quickFilter = quickFiler;
    };

    @action
    setGrouping = (grouping: boolean) => {
        this.grouping = grouping;
    };

    @action
    setAggregateFunction = (func?: AggregateFunction) => {
        this.aggregateFunction = func;
    };

    @action
    setDistinct = (distinct: boolean) => {
        this.distinct = distinct;
    };

    @action
    resetField() {
        this.aggregateFunction = undefined;
        this.dateAggregation = undefined;
        this.quickFilter = false;
        this.grouping = false;
        this.distinct = false;
    }

    @action
    setDateAggregation = (aggregation?: DateAggregation) => {
        this.dateAggregation = aggregation;
    };

    static fromJson(json: ReportField) {
        const cls = new ReportField(
            json.id,
            json.createdBy,
            json.modifiedBy,
            json.reportId,
        );
        copyBaseFields(json, cls);
        cls.name = json.name;
        cls.reportId = json.reportId;
        cls.reportDataFieldId = json.reportDataFieldId;
        cls.aggregateFunction = json.aggregateFunction;
        cls.dateAggregation = json.dateAggregation;
        cls.quickFilter = json.quickFilter;
        cls.grouping = json.grouping;
        cls.fieldUse = json.fieldUse;
        cls.displayName = json.displayName || json.name;
        cls.order = json.order;
        cls.distinct = json.distinct;
        cls.virtualFieldType = json.virtualFieldType;

        if (json.reportDataField) {
            cls.reportDataField = ReportDataField.fromJson(
                json.reportDataField,
            );
        }

        cls.reportFilters =
            json.reportFilters?.map(ReportFilter.fromJson) ?? [];

        return cls;
    }
}

export enum FieldUse {
    X,
    Y,
    VizGroup,
    Filter,
    QuickFilter,
    VirtualScored,
    VirtualValue,
    DrillDownFilter,
}

export enum AggregateFunction {
    Sum,
    Avg,
    Count,
    Max,
    Min,
    Percentage,
}

export enum DateAggregation {
    DateId,
    MonthYear,
    Quarter,
    WeekOfYear,
}
