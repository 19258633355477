import { Button, ButtonBase, Checkbox, FormControlLabel, Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import { runInAction } from "mobx";
import { Observer, observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import { DataProcessingOptions } from "../../../../../../models/DataProcesses";
import { OrganizationStructureMember } from "../../../../../../models/OrganizationModels/OrganizationStructureMember";
import { SamplingType } from "../../../../../../services/AudioFilesService";
import useStyles from "../../../../../../Styles/Styles";
import AcxMainTextField from "../../../../../UI/AcxMainTextFieldGrid";
import VerticalDrawerContentTemplate from "../../../../../UI/Drawer/VerticalDrawerContentTemplate";
import AcxServiceHierarchySelector from "../../../../../UI/Select/AcxServiceHierarchySelector";
import { UseSampleOp } from "../../../AudioFileSampler/Stores/AudioFileSamplerStore";
import { RecommendationStepStore } from "../../Stores/RecommendationStepStore";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",
            paddingBottom: theme.spacing(1.5),
        },
        inputField: {
            width: "100%",
            height: "100%",
        },
        addAssignmentButton: {
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightBold as any,
            fontSize: "13px",
        },
        addIcon: {
            marginRight: theme.spacing(1),
            fontSize: "14px",
        },
        xIcon: {
            color: theme.palette.text.disabled,
        },
        error: {
            fontSize: "13px",
            color: theme.palette.error.main,
            textAlign: "left",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
    });

interface SpecSaveConfirmDialog {
    store: RecommendationStepStore;
}

type Props = SpecSaveConfirmDialog;

const StartSamplingDrawerContent: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer(
    (props: Props) => {
        const classes = useStyles(styles);
        const [err, setErr] = useState<string | undefined>();
        const orgStore = props.store.orgStore;

        function onStart() {
            try {
                setErr(undefined);
                props.store.useSample(props.store.useBackgroundSampler);
            } catch (e) {
                setErr(`${e}`);
            }
        }

        function removeOption(index: number) {
            const samplingOptionDistributionMap =
                props.store.hierarchyAssignmentDistribution;
            runInAction(() => {
                samplingOptionDistributionMap.delete(index);
            });
        }

        function onAddOption() {
            const samplingOptionDistributionMap =
                props.store.hierarchyAssignmentDistribution;

            const initElement: any = { selections: [], count: "0" };

            const index = Math.max(...samplingOptionDistributionMap.keys()) + 1;

            runInAction(() => {
                samplingOptionDistributionMap.set(index, initElement);
            });
        }

        function onAllotmentChange(evt, index: number) {
            setErr(undefined);
            const existing = props.store.hierarchyAssignmentDistribution.get(
                index,
            ) ?? {
                selections: [],
                count: "0",
            };
            runInAction(() => {
                existing.count = stringToAllotment(evt.currentTarget.value);
                props.store.hierarchyAssignmentDistribution.set(
                    index,
                    existing,
                );
            });
        }

        function onHierarchySelect(
            index: number,
            hierarchyIndex: number,
            newValue: OrganizationStructureMember,
        ) {
            setErr(undefined);
            const existing = props.store.hierarchyAssignmentDistribution.get(
                index,
            ) ?? { selections: [], count: "0" };
            runInAction(() => {
                existing.selections = [
                    ...existing.selections
                        .splice(0, hierarchyIndex)
                        .concat([newValue])
                        .filter((value) => value.name !== "No Selection"),
                ];
                props.store.hierarchyAssignmentDistribution.set(
                    index,
                    existing,
                );
            });
        }

        function handleSampleTypeChange(
            event: React.ChangeEvent<HTMLInputElement>,
        ) {
            if (event.target.checked) {
                props.store.setSamplingType(SamplingType.MiddleOfTheFunnel);
            } else {
                props.store.setSamplingType(SamplingType.BottomOfTheFunnel);
            }
        }

        function handleUseBackgroundSamplerChange(
            event: React.ChangeEvent<HTMLInputElement>,
        ) {
            if (event.target.checked) {
                props.store.setBackgroundSampling(true);
            } else {
                props.store.setBackgroundSampling(false);
            }
        }

        function getContent() {
            const samplingOptionDistributionMap =
                props.store.hierarchyAssignmentDistribution;

            return (
                <Observer>
                    {() => (
                        <div>
                            <Grid container wrap={"nowrap"}>
                                <Grid item>
                                    <Button
                                        variant={"contained"}
                                        style={{
                                            textTransform: "none",
                                            fontWeight: "bold",
                                        }}
                                        disableElevation
                                        disabled={props.store.getTaskLoading(
                                            UseSampleOp,
                                        )}
                                        color={"secondary"}
                                        onClick={onStart}
                                    >
                                        Start
                                    </Button>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    justifyContent={"flex-end"}
                                    alignItems={"center"}
                                >
                                    {props.store.authStore.isUserUltra()
                                        && (
                                            <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                props.store.useBackgroundSampler
                                                            }
                                                            onChange={
                                                                handleUseBackgroundSamplerChange
                                                            }
                                                            name="UseBackgroundSampler"
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            color={"textSecondary"}
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize: "12px",
                                                            }}
                                                            variant={"subtitle2"}
                                                        >
                                                            Use new background sampler
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                        )
                                    }
                                    
                                    
                                    {orgStore.organizationHasDataProcessingOptionEnabled(
                                        DataProcessingOptions.MiddleOfTheFunnel,
                                    ) && (
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            props.store
                                                                .samplingType ===
                                                            SamplingType.MiddleOfTheFunnel
                                                        }
                                                        onChange={
                                                            handleSampleTypeChange
                                                        }
                                                        name="SampleToMiddleOfTheFunnel"
                                                    />
                                                }
                                                label={
                                                    <Typography
                                                        color={"textSecondary"}
                                                        style={{
                                                            fontWeight: "bold",
                                                            fontSize: "12px",
                                                        }}
                                                        variant={"subtitle2"}
                                                    >
                                                        Sample to Middle of the
                                                        Funnel
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Typography
                                className={classes.error}
                                component="div"
                            >
                                {err}
                            </Typography>

                            {[...samplingOptionDistributionMap.keys()].map(
                                (i: number) => (
                                    <Grid
                                        container
                                        direction={"row"}
                                        justifyContent="flex-start"
                                        className={classes.root}
                                        key={`${i}`}
                                        alignItems="center"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.inputField}
                                            container
                                            justifyContent={"flex-start"}
                                            alignItems={"flex-start"}
                                            spacing={1}
                                            wrap={"nowrap"}
                                        >
                                            <Grid item xs>
                                                <AcxMainTextField
                                                    value={`${
                                                        samplingOptionDistributionMap.get(
                                                            i,
                                                        )?.count ?? 0
                                                    }`}
                                                    textItemStyle={{
                                                        minHeight: "34px",
                                                    }}
                                                    labelText={"Allotment"}
                                                    onChange={(evt) =>
                                                        onAllotmentChange(
                                                            evt,
                                                            i,
                                                        )
                                                    }
                                                    id={`callbatch-callcount-${i}`}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <AcxServiceHierarchySelector
                                                    levels={props.store.levels}
                                                    fullWidth
                                                    members={
                                                        props.store.members
                                                    }
                                                    hierarchySelections={
                                                        props.store.hierarchyAssignmentDistribution.get(
                                                            i,
                                                        )?.selections ?? []
                                                    }
                                                    onSelectMember={(
                                                        hierarchyIndex,
                                                        newValue,
                                                    ) =>
                                                        onHierarchySelect(
                                                            i,
                                                            hierarchyIndex,
                                                            newValue,
                                                        )
                                                    }
                                                    containerRef={document.body}
                                                />
                                            </Grid>

                                            <Grid item xs={1}>
                                                {i > 0 && (
                                                    <ButtonBase
                                                        style={{
                                                            marginTop: "24px",
                                                        }}
                                                        onClick={() =>
                                                            removeOption(i)
                                                        }
                                                    >
                                                        <CloseIcon
                                                            className={
                                                                classes.xIcon
                                                            }
                                                            fontSize={"small"}
                                                        />
                                                    </ButtonBase>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ),
                            )}

                            <Grid
                                item
                                xs={12}
                                container
                                justifyContent={"flex-start"}
                                style={{ marginTop: "22px" }}
                            >
                                <ButtonBase
                                    className={classes.addAssignmentButton}
                                    onClick={() => onAddOption()}
                                >
                                    <AddCircleOutlineIcon
                                        className={classes.addIcon}
                                        fontSize={"small"}
                                    />{" "}
                                    Add Assignment
                                </ButtonBase>
                            </Grid>
                        </div>
                    )}
                </Observer>
            );
        }

        return (
            <VerticalDrawerContentTemplate
                title={"Initiate Sampling"}
                content={getContent()}
                width={"500px"}
            />
        );
    },
);

export function stringToAllotment(sampleSize: string) {
    sampleSize = (sampleSize ?? "").trim();
    if (sampleSize === "") {
        return sampleSize;
    }

    try {
        let size = parseInt(sampleSize, 10);
        if (Number.isNaN(size) || size < 0) {
            size = 0;
        }

        return size.toString(10);
    } catch (err) {
        return "0";
    }
}

export default StartSamplingDrawerContent;
