import AcxSlider from "components/UI/AcxSlider";
import React from "react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import { observer } from "mobx-react";

interface CallDurationFilterProps extends IApplicationFilterComponent {
    label?: string;
    description?: string;
}

export const CallDurationFilter = observer(function (
    props: CallDurationFilterProps,
) {
    return (
        <AcxSlider
            label={props.label}
            description={props.description ?? "minutes"}
            value={props.store.callDuration}
            onChange={(duration) => {
                props.store.setCallDuration(duration);
            }}
        />
    );
});
