import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Observer } from "mobx-react";
import React, { FunctionComponent } from "react";
interface IGutters {
    justifyContent?:
        | "flex-start"
        | "center"
        | "flex-end"
        | "space-between"
        | "space-around"
        | "space-evenly";
    alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
    height?: string;
    bigGutter?: boolean;
    tinyGutter?: boolean;
    overflow?: string;
    children?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => {
    return {
        middle: {
            [theme.breakpoints.only("sm")]: {
                width: "99%",
            },
            [theme.breakpoints.only("md")]: {
                width: "96%",
            },
            [theme.breakpoints.only("lg")]: {
                width: "88%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "80%",
            },
        },
        gutterWidth: {
            [theme.breakpoints.only("sm")]: {
                width: "0.5%",
            },
            [theme.breakpoints.only("md")]: {
                width: "2%",
            },
            [theme.breakpoints.only("lg")]: {
                width: "6%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "10%",
            },
        },
        bigGutterWidth: {
            [theme.breakpoints.only("sm")]: {
                width: "0.5%",
            },
            [theme.breakpoints.only("md")]: {
                width: "5%",
            },
            [theme.breakpoints.only("lg")]: {
                width: "10%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "14%",
            },
        },
        bigGutterMiddle: {
            [theme.breakpoints.only("sm")]: {
                width: "99%",
            },
            [theme.breakpoints.only("md")]: {
                width: "90%",
            },
            [theme.breakpoints.only("lg")]: {
                width: "80%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "72%",
            },
        },
        tinyGutterWidth: {
            [theme.breakpoints.only("sm")]: {
                width: "0.25%",
            },
            [theme.breakpoints.only("md")]: {
                width: "2%",
            },
            [theme.breakpoints.only("lg")]: {
                width: "5.5%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "8%",
            },
        },
        tinyGutterMiddle: {
            [theme.breakpoints.only("sm")]: {
                width: "99%",
            },
            [theme.breakpoints.only("md")]: {
                width: "98%",
            },
            [theme.breakpoints.only("lg")]: {
                width: "94.5%",
            },
            [theme.breakpoints.up("xl")]: {
                width: "92%",
            },
        },
    };
});

const Gutters: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<IGutters>>> = (props) => {
    const classes = useStyles(useStyles);

    return (
        <Observer>
            {() => (
                <>
                    <div
                        style={{
                            display: "flex",
                            overflow: props.overflow ?? "auto",
                            height: props.height ?? "100%",
                        }}
                    >
                        <div
                            className={
                                props.bigGutter
                                    ? classes.bigGutterWidth
                                    : props.tinyGutter
                                    ? classes.tinyGutterWidth
                                    : classes.gutterWidth
                            }
                        />
                        <div
                            className={
                                props.bigGutter
                                    ? classes.bigGutterMiddle
                                    : props.tinyGutter
                                    ? classes.tinyGutterMiddle
                                    : classes.middle
                            }
                            style={{
                                height: "100%",
                                justifyContent:
                                    props.justifyContent ?? "flex-start",
                                alignItems: props.alignItems ?? "stretch",
                            }}
                        >
                            {props.children}
                        </div>
                        <div
                            className={
                                props.bigGutter
                                    ? classes.bigGutterWidth
                                    : classes.gutterWidth
                            }
                        />
                    </div>
                </>
            )}
        </Observer>
    );
};

export default Gutters;
