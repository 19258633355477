import { Grid, IconButton, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import React, { FunctionComponent } from "react";
import {
    AgentCalls,
    CallDurationStats,
    InteractionDateStats,
} from "../../../../../../models/RecommendedSample";
import { durationFormatter } from "../../../../../ManagerInteractions/Util";
import AcxControlledTable from "../../../../../UI/AcxTable/AcxControlledTable";
import { IAcxTableColumn } from "../../../../../UI/AcxTable/AcxTable";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "500px",
        paddingLeft: theme.spacing(1),
    },
    title: {},
    sectionTitle: {
        fontWeight: "bold",
    },

    mainContent: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
}));

interface OwnProps {
    agentCalls?: AgentCalls[];
    durationStats?: CallDurationStats;
    dateStats?: InteractionDateStats;
    onClose: () => void;
}

type Props = OwnProps;

const RecommendationStats: FunctionComponent<Props> = (props) => {
    const classes = useStyles(props);

    const columnDefs = [
        ["AgentName", "agentName"],
        ["Calls", "callCount"],
    ];

    const columns = columnDefs.map((c) => {
        return {
            headerLabel: c[0],
            dataKey: c[1],
        } as IAcxTableColumn;
    });

    return (
        <div className={classes.root}>
            <Grid container justifyContent={"flex-start"}>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Grid item>
                        <Typography variant={"h5"}>
                            Recommendation Statistics
                        </Typography>
                    </Grid>
                    <Grid item style={{ alignSelf: "flex-start" }}>
                        <IconButton onClick={props.onClose} size="large">
                            <CloseIcon fontSize={"medium"} />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid item xs={12} container className={classes.mainContent}>
                    <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <Typography
                            className={classes.sectionTitle}
                            variant={"h3"}
                        >
                            Interaction Date Range
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {props.dateStats?.minDate
                                ? moment(props.dateStats?.minDate).format(
                                      "YYYY-MM-DD hh:mm A",
                                  )
                                : ""}{" "}
                            -{" "}
                            {props.dateStats?.maxDate
                                ? moment(props.dateStats?.maxDate).format(
                                      "YYYY-MM-DD hh:mm A",
                                  )
                                : ""}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} container className={classes.mainContent}>
                    <Grid item xs={12} style={{ marginBottom: "8px" }}>
                        <Typography
                            className={classes.sectionTitle}
                            variant={"h3"}
                        >
                            Call Duration Range
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {durationFormatter(
                                props.durationStats?.minDuration,
                            )}{" "}
                            -{" "}
                            {durationFormatter(
                                props.durationStats?.maxDuration,
                            )}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.mainContent}>
                    <div style={{ height: "100%", minHeight: "175px" }}>
                        <AcxControlledTable
                            rowTerm={
                                <Typography
                                    className={classes.sectionTitle}
                                    variant={"h3"}
                                >
                                    Agent Call Distribution
                                </Typography>
                            }
                            cellDense
                            enableSort
                            columns={columns}
                            rows={props.agentCalls ?? []}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default RecommendationStats;
