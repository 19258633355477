import BaseService from "services/BaseService";
import { AnalystGroup } from "../../types/AnalystGroup";

class AnalystGroupService extends BaseService {
    protected urlBase: string = "api/AnalystGroups";

    public async getAnalystGroups(organizationId: string): Promise<AnalystGroup[]> {
        return this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({ }),
            "GetAnalystGroupsForOrganization",
        );
    }

    public async createAnalystGroup(val: AnalystGroup): Promise<AnalystGroup> {
        const params = new URLSearchParams({});
        return this.post(JSON.stringify(val), params, "CreateAnalystGroup", {
            returnJson: true,
        });
    }

    public async updateAnalystGroup(val: AnalystGroup): Promise<AnalystGroup> {
        const params = new URLSearchParams({});
        return this.put(params, "UpdateAnalystGroup", JSON.stringify(val), {
            returnJson: true,
        });
    }

    public async saveAnalystGroup(val: AnalystGroup): Promise<AnalystGroup> {
        return val.id
            ? this.updateAnalystGroup(val)
            : this.createAnalystGroup(val);
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new AnalystGroupService();
