import React, { FunctionComponent } from "react";
import icon from "./signalsIcon.svg";

interface ISignalsIcon {
    style?: React.CSSProperties;
    className?: string;
}

const SignalsSvg: FunctionComponent<ISignalsIcon> = (props) => {
    return (
        <img
            src={icon}
            className={props.className}
            alt="down chevron"
            style={props.style}
        />
    );
};

export default SignalsSvg;
