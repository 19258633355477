import { action, makeObservable, observable } from "mobx";
import { uuidv4 } from "utils/helpers";
import { HasParent } from "./Parent";
import { ThreadMessageGroup } from "./ThreadMessageGroup";

export interface IThreadMessage extends HasParent<ThreadMessageGroup> {
    readonly id: string;
    content: string;
    dynamicContent?: () => string;

    persist(
        additionalInformation?: Record<string, unknown>,
    ): Promise<IThreadMessage>;

    toString(): string;
}

export class ThreadMessage implements IThreadMessage {
    readonly id: string;

    public readonly parent: ThreadMessageGroup;

    @observable content: string;
    @observable cancelled: boolean;

    constructor(content: string);
    constructor(messageGroup: ThreadMessageGroup, content: string);
    constructor(
        contentOrMessageGroup: string | ThreadMessageGroup,
        content?: string,
    ) {
        makeObservable(this);

        this.id = uuidv4();
        if (
            content === undefined &&
            typeof contentOrMessageGroup === "string"
        ) {
            this.content = contentOrMessageGroup;
        } else if (
            typeof content === "string" &&
            contentOrMessageGroup instanceof ThreadMessageGroup
        ) {
            this.parent = contentOrMessageGroup;
            this.content = content;
        } else {
            throw new Error(
                "Invalid ThreadMessage constructor usage. See available overloads.",
            );
        }
        this.cancelled = false;
    }

    @action public setContent(content: string) {
        this.content = content;
    }

    @action public appendContent(content: string) {
        this.content += content;
    }

    @action public setCancelled(cancelled: boolean) {
        this.cancelled = cancelled;
    }

    public async persist(
        additionalInformation?: Record<string, unknown>,
    ): Promise<ThreadMessage> {
        if (!this.parent) {
            throw new Error(
                "Cannot persist standalone message. Message must be part of a message group in a thread.",
            );
        }

        const messageGroup = this.parent;
        const thread = messageGroup.parent;

        await thread.service.persistMessage(thread.id, {
            author: messageGroup.author,
            content: this.content,
            additionalInformation,
        });

        return this;
    }

    public toString() {
        return this.content;
    }
}

export class TemplatedThreadMessage implements IThreadMessage {
    readonly id: string;

    public readonly parent: ThreadMessageGroup;

    @observable arg: unknown;
    @observable content: string;
    @observable buildContent: (arg: unknown) => string;
    @observable dynamicContent = () => this.buildContent(this.arg);

    constructor(
        parent: ThreadMessageGroup,
        arg: unknown,
        buildContent: (arg: unknown) => string,
    ) {
        makeObservable(this);
        this.parent = parent;
        this.id = uuidv4();
        this.arg = arg;
        this.buildContent = buildContent;
    }

    public async persist(
        additionalInformation?: Record<string, unknown>,
    ): Promise<TemplatedThreadMessage> {
        const messageGroup = this.parent;
        const thread = messageGroup.parent;

        await thread.service.persistMessage(thread.id, {
            author: messageGroup.author,
            content: this.dynamicContent(),
            additionalInformation,
        });

        return this;
    }

    public toString(): string {
        return this.dynamicContent();
    }
}
