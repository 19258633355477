import {
    CssBaseline,
    Theme,
    ThemeProvider,
    StyledEngineProvider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React from "react";
import AppTheme from "Theme/AppTheme";
import MessageStore from "../../components/ManagerInteractions/Stores/MessageStore";
import AcxSnackBar from "../../components/UI/AcxSnackBar";
import AcxDrawer from "../../components/UI/Drawer/AcxDrawer";
import { LayoutDrawerStore } from "../../stores/Layout/LayoutDrawerStore";
import { useStore } from "../../utils/useStore";
import FocusTitleBar from "./FocusTitleBar";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme: Theme) => ({
    loadingContainer: {
        height: "100vh",
    },
    titleBarContainer: {
        height: "64px",
        zIndex: theme.zIndex.drawer + 9999,
    },
    root: {
        width: "100%",
        height: "100%",
    },
}));

type FocusPageProps = {
    title: string;
    onCancel?: () => void;
    children?: React.ReactNode;
    loading?: boolean;
    onSave?: () => Promise<void>;
    showSave?: boolean;
    saveText?: string;
    cancelText?: string;
    hideCancelButton?: boolean;
    onSaveAs?: () => void;
    showSaveAs?: boolean;
    saveAsText?: string;
    saveTooltip?: string;
    saveAsTooltip?: string;
};

const FocusPage: React.FC<FocusPageProps> = observer(
    (props: FocusPageProps) => {
        const classes = useStyles();
        const drawerStore = useStore(LayoutDrawerStore);
        const messageStore = useStore(MessageStore);

        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={AppTheme}>
                    <CssBaseline />

                    <div className={classes.root}>
                        <AcxSnackBar
                            open={messageStore.showMessage}
                            onClose={messageStore.showNextMessage}
                            message={messageStore.currentMessage?.text || ""}
                            severity={messageStore.currentMessage?.severity}
                        />

                        <FocusTitleBar
                            title={props.title}
                            onCancelClick={props.onCancel}
                            onSaveClick={props.onSave}
                            showSave={props.showSave}
                            saveText={props.saveText}
                            cancelText={props.cancelText}
                            loading={props.loading}
                            onSaveAsClick={props.onSaveAs}
                            showSaveAs={props.showSaveAs}
                            hideCancelButton={props.hideCancelButton}
                            saveAsText={props.saveAsText}
                        />

                        {props.children}

                        <div>
                            <AcxDrawer
                                customRootStyles={drawerStore.customRootStyles}
                                anchor={drawerStore.anchor}
                                open={drawerStore.isOpen}
                                collapsible={drawerStore.collapseSize}
                                variant={drawerStore.variant}
                                offsetPx={drawerStore.offsetPx}
                                offsetPosition={drawerStore.offsetPosition}
                                onDrawerClose={() => drawerStore.setOpen(false)}
                                drawerStore={drawerStore}
                                onDrawerOpen={() => drawerStore.setOpen(true)}
                                content={drawerStore.contentInjector?.()}
                            />
                        </div>
                    </div>
                </ThemeProvider>
            </StyledEngineProvider>
        );
    },
);

export default FocusPage;
