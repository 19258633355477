import { Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

const AcxWideTooltip = withStyles({
    tooltip: {
        maxWidth: "100%",
    },
})(Tooltip);

export default AcxWideTooltip;
