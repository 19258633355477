import { makeObservable, observable, reaction, runInAction } from "mobx";
import { User } from "oidc-client";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { DashboardDomain } from "../../../../models/Dashboard/DashboardDefinition";
import { AuthStore } from "../../../../stores/AuthStore";
import { BaseStore } from "../../../../stores/BaseStore";
import { OrgSelectorComponentStore } from "../../../../stores/ComponentStores/OrgSelectorComponentStore";
import { DashboardStore } from "../../../../stores/Layout/DashboardStore";

@AcxStore
export class LandingDashboardStore extends BaseStore {
    readonly dashboardStore: DashboardStore;
    readonly orgSelectorComponentStore: OrgSelectorComponentStore =
        new OrgSelectorComponentStore();

    @observable private user?: User | null;

    constructor(private rootStore: IRootStore) {
        super("Landing Dashboard");

        makeObservable(this);

        this.dashboardStore = new DashboardStore(
            rootStore,
            DashboardDomain.Landing,
        );

        rootStore
            .getStore(AuthStore)
            .getUserObject()
            .then((value) => {
                runInAction(() => {
                    this.user = value;
                });
            })
            .catch((reason) =>
                console.error(`Failed to retrieve User Profile ${reason}`),
            );

        reaction(
            (r) => ({
                orgSelectId: this.orgSelectorComponentStore.orgId,
                user: this.user,
                activeLocation: this.rootStore.activeLocation,
            }),
            (arg) => {
                if (
                    arg.activeLocation.location &&
                    arg.activeLocation.location !== "/app"
                )
                    return;

                if (!arg.orgSelectId && this.user) {
                    this.dashboardStore.initializeStoreParameters(
                        this.user?.profile?.OrgId,
                        this.user,
                    );
                } else if (arg.user && arg.orgSelectId) {
                    const orgId = arg.orgSelectId;
                    this.dashboardStore.initializeStoreParameters(
                        orgId!,
                        arg.user,
                    );
                }
            },
            { fireImmediately: true, delay: 4000 },
        );

        reaction(
            (r) => ({
                selectedOrganization:
                    this.rootStore.getStore(AuthStore).orgStore
                        ?.selectedOrganization,
            }),
            (arg) => {
                this.orgSelectorComponentStore.selectOrg(
                    arg.selectedOrganization,
                );
            },
            { fireImmediately: true },
        );
    }
}
