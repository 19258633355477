import { Grid, IconButton, Paper } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import { OrganizationMetadataField } from "models/OrganizationModels/OrganizationMetadataField";
import React, { FunctionComponent } from "react";
import useStyles from "Styles/Styles";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import { OrganizationMetadataStore } from "./Store/OrganizationMetadataStore";

const styles = () =>
    createStyles({
        fieldSpacing: {
            margin: "0.25rem 0",
            padding: "0 0 0 0.125rem",
        },
        metadataContainer: {
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        inputSpacing: { paddingLeft: ".125rem" },
    });
interface MetadataExtendedAssociationFieldRowProps {
    configIndex: number;
    id: string;
    currentIndex: number;
    store: OrganizationMetadataStore;
}

const MetadataExtendedAssociationFieldRow: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<MetadataExtendedAssociationFieldRowProps>>> =
    observer((props) => {
        const { configIndex, id, currentIndex, store } = props;
        const classes = useStyles(styles);

        const onExtendedFieldNameChange = React.useCallback(
            (value: string, index: number) => {
                store.handleMetadataExtendedAssociationFieldNameUpdates(
                    configIndex,
                    value,
                    index,
                );
            },
            [configIndex, store],
        );

        const onExtendedFieldChange = React.useCallback(
            (value: number, index: number) => {
                store.handleMetadataExtendedAssociationFieldUpdates(
                    configIndex,
                    value,
                    index,
                );
            },
            [configIndex, store],
        );

        const metadataExtendedAssociationFieldArray =
            store.organizationMetadataConfiguration[configIndex]
                ?.configuration?.metaDataExtendedAssociationFields ?? [];

        const extendedFields =
            store.organizationMetadataConfiguration[configIndex]
                ?.configuration?.extendedFields ?? [];

        const currentMetadataExtendedAssociationField =
            metadataExtendedAssociationFieldArray[currentIndex];

        const fieldNameChoices = extendedFields.map((item) => ({
            label: item.fieldName,
            value: item.fieldName,
        }));

        const fieldChoices = EnumToDropdownOptionConversion(
            OrganizationMetadataField,
        );

        return !!currentMetadataExtendedAssociationField && (
            <Paper className={classes.metadataContainer}>
                <Grid container item xs={12}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.fieldSpacing}
                    >
                        <Grid item xs={6} className={classes.inputSpacing}>
                            <AcxSelect
                                inputLabel="Extended Field Name"
                                fullWidth
                                defaultValue={fieldNameChoices.find(
                                    (item) =>
                                        item.value ===
                                        currentMetadataExtendedAssociationField.extendedMetadataFieldName,
                                )}
                                id={`fieldName_${currentIndex}`}
                                options={fieldNameChoices}
                                onChange={(event) =>
                                    onExtendedFieldNameChange(
                                        event.value,
                                        currentIndex,
                                    )
                                }
                                placeholder="Extended Field Name"
                            />
                        </Grid>

                        <Grid item xs={5} className={classes.inputSpacing}>
                            <AcxSelect
                                inputLabel="Metadata Field"
                                fullWidth
                                defaultValue={fieldChoices.find(
                                    (item) =>
                                        item.value ===
                                        currentMetadataExtendedAssociationField.metaDataField,
                                )}
                                id={`fieldName_${currentIndex}`}
                                options={fieldChoices}
                                onChange={(event) =>
                                    onExtendedFieldChange(
                                        event.value,
                                        currentIndex,
                                    )
                                }
                                placeholder="Metadata Field"
                            />
                        </Grid>

                        <Grid
                            container
                            item
                            xs={1}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <IconButton
                                onClick={() =>
                                    store.removeFieldConfiguration(
                                        "metaDataExtendedAssociationFields",
                                        id,
                                        configIndex,
                                    )
                                }
                                size="large">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    });

export default MetadataExtendedAssociationFieldRow;
