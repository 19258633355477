import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { BranchDataProps } from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import RecursiveTreeStore from "components/UI/AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import _ from "lodash";
import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { uuidv4 } from "utils/helpers";
import {
    allRecursiveTestBranches,
    branchOne,
    branchOneChildOneChildren,
    branchOneChildren,
    branchTwo,
    branchTwoChildren,
    topTierBranches,
} from "./RecursiveTreeTestData";
import type { IFakeRecursiveTier } from "./RecursiveTreeTestData";

@AcxStore
export class RecursiveTreeTestStore extends BaseStore {
    readonly messageStore: MessageStore;
    readonly deleteDialogStore: DialogComponentStore<RecursiveTreeTestStore>;
    readonly addTierDialog: DialogComponentStore<RecursiveTreeTestStore>;

    @observable.ref
    organizationId: string;

    @observable
    isLoading: boolean;

    @observable
    showDeleteDialog: boolean = false;

    @observable
    showAddDialog: boolean = false;

    @observable
    showAddTopLevelDialog: boolean = false;

    @observable
    activeTier: IFakeRecursiveTier;

    @observable
    endpointFired: { active: boolean; payload?: "Add" | "Remove" | "Update" };

    treeStore: RecursiveTreeStore;

    constructor(private rootStore: IRootStore) {
        super("RecursiveTreeTestStore");

        makeObservable(this);

        this.deleteDialogStore = new DialogComponentStore(undefined, this);
        this.addTierDialog = new DialogComponentStore(undefined, this);
        this.messageStore = rootStore.getStore(MessageStore);
    }

    @action
    setOrganizationId = (orgId: string) => {
        this.organizationId = orgId;
    };

    @action
    setActiveTierAttribute = (pathToKey: string, value) => {
        if (!this.activeTier) return;
        const tier = this.activeTier;
        _.set(tier, pathToKey, value);

        this.activeTier = tier;
    };

    @action
    setActiveTier = (tier: IFakeRecursiveTier) => {
        this.activeTier = tier;
    };

    @action
    setEndpointFired = (
        active: boolean,
        payload?: "Add" | "Remove" | "Update",
    ) => {
        this.endpointFired = { active, payload };
    };

    @action
    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    @action
    setShowDeleteDialog = (isVisible: boolean) => {
        this.showDeleteDialog = isVisible;
    };

    @action
    setShowAddDialog = (isVisible: boolean) => {
        this.showAddDialog = isVisible;
    };

    @action
    setShowAddTopLevelDialog = (isVisible: boolean) => {
        this.showAddTopLevelDialog = isVisible;
    };

    @action
    confirmRemoveTier = () => {
        if (this.activeTier) {
            this.setShowDeleteDialog(false);
            this.setEndpointFired(true, "Remove"); // RecursiveTreeTestBranch.tsx - React.useEffect() will fire off of this.
        } else {
            this.messageStore.logInfo(
                "Something went wrong. Cancel and try again.",
            );
        }
    };

    public async createTopLevelBranch(
        orgId: string,
        newBranch: BranchDataProps,
    ) {
        // In a true use case - within the Try / Catch block - a call to an endpoint would be made, which would create a new top level branch based on the data passed via the `newBranch` argument.
        try {
            const newTopLevels = [
                ...topTierBranches,
                { ...newBranch, id: uuidv4() },
            ];

            // Call to endpoint would go here.
            const data = (await new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(newTopLevels);
                }, 300);
            })) as any[];

            this.treeStore.setTopLevelBranches(data);

            this.messageStore.logMessage(
                `Successfully created ${newBranch.name}`,
                "success",
            );
        } catch (error) {
            console.error("CreateTopLevelTier", error);
            this.messageStore.logError(
                (error as Error).message ?? "Failed to create a new branch.",
            );
        }
    }

    public createBranch(
        orgId: string,
        parent: IFakeRecursiveTier,
        newBranch: IFakeRecursiveTier,
    ) {
        // Remove the fired endpoint state to prevent looping.
        this.setEndpointFired(false);

        // Same Application As Above
        if (orgId && parent) {
            // The data for the new branch
            if (newBranch) {
                const childBranch = {
                    ...newBranch,
                    id: uuidv4(),
                    branchDepth: (parent.branchDepth ?? 0) + 1,
                    isLeaf: true,
                };

                const returnObj = {
                    child: childBranch,
                    parent: {
                        ...parent,
                        isLeaf: false,
                        dummyTiers: [...(parent.dummyTiers ?? []), childBranch],
                    },
                };

                // Opened Branch Ids indicate when to toggle chevrons and display branch children. In the event of new branch creation, open the parent id.
                this.treeStore.openedBranchIds.push(parent.id);

                // Mimic call to endpoint
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve(returnObj);
                    }, 300);
                }) as any;
            }

            return null as unknown as Error;
        }

        return null as unknown as Error;
    }

    public getTopLevelBranches(orgId: string) {
        this.setEndpointFired(false);

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ dummyTiers: topTierBranches });
            }, 300);
        }) as Promise<any>;
    }

    public async getBranch(orgId: string, parentId: string) {
        this.setEndpointFired(false);
        let res; // Only used for test purposes.

        // The organization id is used to identify the Organization whose hierarchy is being operated within.
        if (orgId) {
            // The branch that is interacted with. Used to find the children that fall below it based off `topLevelDataKey`.
            if (parentId) {
                switch (parentId) {
                    case branchOne.id:
                        // Mimic call to endpoint
                        res = await new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve(branchOneChildren);
                            }, 300);
                        });

                        return res;

                    case branchTwo.id:
                        res = await new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve(branchTwoChildren);
                            }, 300);
                        });

                        return res;

                    case "b1c1":
                        res = await new Promise((resolve, reject) => {
                            setTimeout(() => {
                                resolve(branchOneChildOneChildren);
                            }, 300);
                        });

                        return res;

                    default:
                        // Will begin to fail here as mock data becomes unavailable.
                        return null;
                }
            }
        }
    }

    public updateBranch(
        orgId: string,
        parentId: string,
        newBranch: IFakeRecursiveTier,
    ) {
        // Remove the fired endpoint state to prevent looping.
        this.setEndpointFired(false);

        // Same Application As Above
        if (orgId && parentId) {
            // The data for the updated branch
            if (newBranch) {
                const branch =
                    allRecursiveTestBranches.find((b) => b.id === parentId) ??
                    {};

                const childBranch = {
                    ...branch,
                    newBranch,
                };

                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        resolve(childBranch);
                    }, 300);
                });
            }

            return null as any;
        }

        return null as any;
    }

    public deleteBranch(
        orgId: string,
        branchId: string,
        parentBranch?: BranchDataProps,
    ) {
        // Remove the fired endpoint state to prevent looping.
        this.setEndpointFired(false);

        // Delete branch in endpoint here.
        return undefined as any;
    }
}
