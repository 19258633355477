import { Grid } from "@mui/material";
import React from "react";

export const ChartPlaceholderContainer = ({
    children,
    ...props
}: React.PropsWithChildren<React.ComponentProps<typeof Grid>>) => {
    return (
        <Grid
            container
            style={{ height: "300px" }}
            justifyContent="center"
            alignItems="center"
            {...props}
        >
            {children}
        </Grid>
    );
};
