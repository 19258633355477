import { Button, Tooltip, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import Gutters from "Layouts/Responsive/Gutters";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import rbcToTsQueryStore from "./RbcToTsQueryStore";
import ContentPasteIcon from "@mui/icons-material/Input";

const RbcToTsQuery = observer(() => {
    const store = useStore(rbcToTsQueryStore);
    const authStore = useStore(AuthStore);
    const messageStore = useStore(MessageStore);

    const refreshData = async () => {
        try {
            await store.updateFailures();
        } catch {
            messageStore.logError(
                "Unable to fetch pipeline failures. Please retry.",
            );
        }
    };

    useEffect(() => {
        if (
            !store.failures ||
            !store.failures.classifiers.find(
                (failure) =>
                    failure.organizationId ===
                    authStore.orgStore.selectedOrganization?.id,
            )
        ) {
            store.updateFailures();
        }
    }, [authStore.orgStore.selectedOrganization?.id, store]);

    const columns: IColDef[] = [
        {
            headerName: "Classifier Name",
            field: "classifierName",
            type: "string",
        },
        {
            headerName: "Classifier Id",
            field: "id",
            type: "string",
        },
        {
            headerName: "Classifier Definition",
            field: "classifierDef",
            type: "string",
            renderCell: (params) => (
                <>
                    <Tooltip title="Copy">
                        <Button
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    params.value?.toString() ?? "",
                                );
                                messageStore.logInfo(
                                    "Definition Copied to Clipboard!",
                                );
                            }}
                        >
                            <ContentPasteIcon />
                        </Button>
                    </Tooltip>
                    {params.value?.toString().replace(/[\r\n]/g, "\u{23CE}")}
                </>
            ),
        },
        {
            headerName: "Parsing Failure Reason",
            field: "errorMessage",
            type: "string",
        },
    ];

    const controls = [
        <AcxButton
            tooltip="Refresh data."
            color="primary"
            onClick={refreshData}
            style={{ height: "32px" }}
            loading={store.rbcToTsQueryDgStore.isLoading}
            disabled={store.rbcToTsQueryDgStore.isLoading}
        >
            <RefreshIcon />
        </AcxButton>,
    ];

    //Data grid store setup function
    useEffect(() => {
        store.rbcToTsQueryDgStore.setColumns(columns);
        store.rbcToTsQueryDgStore.controls = controls;
    });

    return (
        <Gutters>
            <Typography
                variant="h1"
                style={{ textAlign: "center", paddingTop: "40px" }}
            >
                {store.failures
                    ? store.failures.failurestatistics +
                      " Classifiers Failed For Organization!"
                    : ""}
            </Typography>
            {<AcxDataGrid dataGridStore={store.rbcToTsQueryDgStore} />}
        </Gutters>
    );
});

export default RbcToTsQuery;
