import { Grid, Theme, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import React from "react";
import hexToRGB from "../../../utils/hexToRGB";

const useStyles = makeStyles((theme: Theme) => ({
    textContainer: {
        marginTop: theme.spacing(1.5),
    },
    // text:{
    //     fontWeight: theme.typography.fontWeightBold,
    //     color:theme.palette.text.disabled,
    //
    // },

    text: {
        color: "#1F1F1F",
        opacity: 0.25,
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: 0,
        lineHeight: "20px",
        textAlign: "center",
    },
}));

interface OwnProps {}

type Props = OwnProps;

const EmptyNotifications: React.FC<Props> = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <>
            <Grid item xs={12} container justifyContent={"center"}>
                <NotificationsIcon
                    sx={{
                        fontSize: "160px",
                        color: hexToRGB(theme.palette.gray.main, 0.2),
                    }}
                />
            </Grid>
            <Grid
                item
                xs={12}
                container
                justifyContent={"center"}
                className={classes.textContainer}
            >
                <Typography variant={"h2"} className={classes.text}>
                    No new notifications
                </Typography>
            </Grid>
        </>
    );
};

export default EmptyNotifications;
