import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import React from "react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import _ from "lodash";

export const ExtendedMetadataInputFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    return (
        <AcxMainTextField
            id={props.id ?? props.filterKey}
            value={_.get(props.store, props.filterKey) as string | undefined}
            onChange={(e) =>
                // @ts-expect-error
                props.store.setByKey(props.filterKey, e.target.value)
            }
            endAdornment={
                <Tooltip title="This field supports comma separated values e.g.: value1,value2,value3">
                    <InfoIcon color="disabled" />
                </Tooltip>
            }
            fullWidth
        />
    );
});
