import { Grid, Paper, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import logo from "../../../../authenticx-green.png";
import { useStore } from "../../../../utils/useStore";
import { EvalStore } from "../../Stores/EvalStore";
import ClassifierFeedbackAnswer from "./ClassifierFeedbackAnswer";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingBottom: "16px",
        width: "100%",
        height: "auto",
        borderRadius: "8px",
    },

    sectionTitle: {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        marginBottom: "16px",
    },

    logo: {
        height: 28,
        width: 28,
        marginRight: theme.spacing(1),
    },
    logoText: {
        fontSize: "13px",
        fontWeight: "bold",
    },
}));
interface OwnProps {}

type Props = OwnProps;

const ClassifierResultFeedbackCard: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer(
    (props) => {
        const store = useStore(EvalStore);
        const classes = useStyles();

        return (
            <Paper className={classes.root} elevation={4}>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        className={classes.sectionTitle}
                        container
                        wrap={"nowrap"}
                    >
                        <Grid item>
                            <img className={classes.logo} alt="" src={logo} />
                        </Grid>
                        <Grid item>
                            <Typography variant={"h1"}>
                                Did you hear this?
                            </Typography>
                        </Grid>
                    </Grid>
                    <>
                        {store.allClassifiers?.map((value) => {
                            return (
                                <React.Fragment key={value.id}>
                                    <ClassifierFeedbackAnswer
                                        classifierResult={value}
                                        isDisabled={
                                            !store.currentEval?.isEditable
                                        }
                                    />
                                </React.Fragment>
                            );
                        })}
                    </>
                </Grid>
            </Paper>
        );
    },
);

export default ClassifierResultFeedbackCard;
