import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const AlertSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="25px"
            height="25px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Evaluation"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.25}
            >
                <g
                    id="Interaction---Analyst-Evaluate-"
                    transform="translate(-980.000000, -601.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Content" transform="translate(0.000000, 56.000000)">
                        <g
                            id="Middle"
                            transform="translate(344.000000, 24.000000)"
                        >
                            <g
                                id="Quality-Copy"
                                transform="translate(0.000000, 252.000000)"
                            >
                                <g
                                    id="Stacked-Group"
                                    transform="translate(24.000000, 76.000000)"
                                >
                                    <g
                                        id="Field-Copy-27"
                                        transform="translate(0.000000, 164.000000)"
                                    >
                                        <g id="Stacked-Group">
                                            <g
                                                id="Group-3"
                                                transform="translate(0.000000, 28.000000)"
                                            >
                                                <g
                                                    id="Group-14"
                                                    transform="translate(612.000000, 1.000000)"
                                                >
                                                    <g id="Group-12">
                                                        <g id="Icon/Quote-Copy-2">
                                                            <path
                                                                d="M16.0133333,14.6075 L10.735,4.71166667 C10.4216667,4.12333333 9.57833333,4.12333333 9.26416667,4.71166667 L3.98666667,14.6075 C3.69083333,15.1625 4.09333333,15.8333333 4.7225,15.8333333 L15.2783333,15.8333333 C15.9066667,15.8333333 16.3091667,15.1625 16.0133333,14.6075 Z M10,14.1666667 C9.54,14.1666667 9.16666667,13.7933333 9.16666667,13.3333333 C9.16666667,12.8733333 9.54,12.5 10,12.5 C10.46,12.5 10.8333333,12.8733333 10.8333333,13.3333333 C10.8333333,13.7933333 10.46,14.1666667 10,14.1666667 Z M10.8333333,11.6666667 L9.16666667,11.6666667 L9.16666667,8.33333333 L10.8333333,8.33333333 L10.8333333,11.6666667 Z"
                                                                id="Fill"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default AlertSvg;
