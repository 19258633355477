import { SamplingTargetTimePeriod } from "components/Admin/Organizations/types/SamplingTargetTimePeriod";
import { IAcxTableColumn } from "components/UI/AcxTable/AcxTable";
import { parseFromISO, standardDateFormat } from "utils/DateTimeUtils";

export const tableColumns: IAcxTableColumn[] = [
    {
        headerLabel: "Date",
        dataKey: "createdOn",
        formatter: (arg) => {
            const dateString = standardDateFormat(parseFromISO(arg, true));
            return dateString !== "Invalid Date" ? dateString : "";
        },
    },
    {
        headerLabel: "Value",
        dataKey: "value",
    },
    {
        headerLabel: "Unit",
        dataKey: "unitOfMeasure",
        formatter: (arg) => (!arg ? "" : arg === 2 ? "%" : "#"),
    },
    {
        headerLabel: "Period",
        dataKey: "timePeriod",
        formatter: (arg) => SamplingTargetTimePeriod[arg],
    },
    {
        headerLabel: "Monitor",
        dataKey: "monitoredByUserFullName",
    },
];

export const unitOptions: { id: string; value: string }[] = [
    {
        id: "1",
        value: "Number",
    },
    {
        id: "2",
        value: "Percentage",
    },
];

export const timePeriodOptions: { id: string; value: string }[] = [
    {
        id: "1",
        value: "Weekly",
    },
    {
        id: "2",
        value: "BiWeekly",
    },
    {
        id: "3",
        value: "Monthly",
    },
    {
        id: "4",
        value: "Quarterly",
    },
];
