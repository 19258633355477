import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import ConversationsStore from "../../Stores/ConversationsStore";
import UnfocusedDrawer from "./UnfocusedDrawer";
import FocusedDrawer from "./FocusedDrawer";

const useStyles = makeStyles((theme: Theme) => ({
    container: { height: "100%" },
}));

const ConversationsDrawer: React.FunctionComponent = observer(() => {
    const store = useStore(ConversationsStore);
    const classes = useStyles();

    return (
        <Grid container justifyContent="center" className={classes.container}>
            {store.isFocused ? <FocusedDrawer /> : <UnfocusedDrawer />}
        </Grid>
    );
});

export default ConversationsDrawer;
