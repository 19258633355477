import { InputLabel, MenuItem, Select } from "@mui/material";
import {
    // GridColDef,
    // GridFilterInputValueProps,
    // GridFilterItem,
    // GridFilterOperator,
    GridRenderCellParams,
} from "@mui/x-data-grid-pro";
// import { JSXElementConstructor } from "react";
// import _ from "lodash";
import { EvaluationStatus } from "models/Evaluation";
import React from "react";
import theme from "Theme/AppTheme";

const getStatus = (
    status: number,
    amdStatus?: number,
): { color: string; text: string; number: number } => {
    let color, text;

    if (status === EvaluationStatus.Completed) {
        color = theme.palette.success.main;
        text = "Completed";
    } else if (amdStatus === 4) {
        color = theme.palette.yellow.main;
        text = "On-Demand Processing";
    } else if (status === EvaluationStatus.InProgress) {
        color = theme.palette.warning.main;
        text = "In Progress";
    } else if (status === EvaluationStatus.NotStarted) {
        color = theme.palette.black.main;
        text = "Not Started";
    } else if (status === EvaluationStatus.NoValue) {
        color = theme.palette.error.main;
        text = "No Value";
    } else if (status === EvaluationStatus.PendingReview) {
        color = theme.palette.info.main;
        text = "Pending Review";
    } else if (status === EvaluationStatus.ReviewComplete) {
        color = theme.palette.info.main;
        text = "Review Complete";
    } else if (status === EvaluationStatus.Disputed) {
        color = theme.palette.warning.main;
        text = "Disputed";
    } else if (status === EvaluationStatus.Escalated) {
        color = theme.palette.error.main;
        text = "Escalated";
    } else if (status === EvaluationStatus.Enriching) {
        color = theme.palette.gray[500];
        text = "Enriching";
    }

    return { color, text, number: status };
};

export const statusFormatter: (
    params: GridRenderCellParams,
) => React.ReactNode = (params) => {
    const val = params.value?.toString();
    const amdStatus = params.row.interaction?.audioMetadata?.status;

    let status;
    if (typeof val === "string") {
        status = parseInt(val, 10);
    }

    const { color, text } = getStatus(status, amdStatus);

    return (
        <span
            style={{
                color: color,
                fontWeight: "bold",
                fontFamily: theme.typography.fontFamily,
            }}
        >
            {text}
        </span>
    );
};

// const statusComp = (p) => {
//     return (
//         <div ref={p.innerRef} {...p.innerProps}>
//             <span
//                 style={{
//                     color: p?.data?.color,
//                     fontWeight: "bold",
//                     cursor: "pointer",
//                 }}
//             >
//                 {p.label}
//             </span>
//         </div>
//     );
// };

// export const getStatusFormatterFilterOperators: (
//     filterField: string,
// ) => GridFilterOperator[] = (filterField: string) => [
//     {
//         label: "equals",
//         value: "equals",
//         getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//             if (
//                 !filterItem.columnField ||
//                 !filterItem.value ||
//                 !filterItem.operatorValue
//             ) {
//                 return null;
//             }

//             return (params): boolean => {
//                 const rowValue = _.get(params.row, filterField);
//                 return rowValue?.toString() === filterItem.value?.toString();
//             };
//         },
//         InputComponent: StatusFormatterFilter,
//     },
// ];

export const StatusFormatterFilter: React.JSXElementConstructor<any> = (
    props,
) => {
    const { item, applyValue } = props;

    // eslint-disable-next-line array-callback-return
    const statuses: {
        color: string;
        text: string;
        number: string;
    }[] = [];

    for (const [key] of Object.entries(EvaluationStatus)) {
        const k = parseInt(key);
        if (!isNaN(k)) {
            const val = getStatus(k);
            statuses.push({ ...val, number: val.number.toString() });
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [curVal, setCurVal] = React.useState<string | undefined>(item.value);

    const handleFilterChange = (arg) => {
        const val = arg.target["value"];
        setCurVal(arg.target["value"]);
        applyValue({ ...item, value: val });
    };

    return (
        <>
            <InputLabel {...props} id="status-filter-selector-label">
                Search Value
            </InputLabel>
            <Select
                {...props}
                labelId="status-filter-selector-label"
                id="status-filter-selector"
                defaultValue={curVal}
                label={"Status"}
                onChange={handleFilterChange}
                variant="outlined"
                size="small"
            >
                {statuses.map((status) => (
                    <MenuItem
                        value={status.number}
                        sx={{ color: status.color }}
                    >
                        {status.text}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};
