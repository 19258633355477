export enum OrganizationMetadataField {
    "Agent Name",
    "CallDirection",
    "InteractionDate",
    "TimeOnHold",
    "TransferCount",
    "AgentId",
    "PhoneNumber",
    "ClientCallId",
    "GroupName",
    "HIPAAValidated",
    EmployeeGroupName,
    MemberId,
    Tags,
    HierarchyId,
}
