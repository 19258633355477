import { Grid, Theme, Toolbar, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import useStyles from "../../Styles/Styles";
import ControlSectionDivider from "./ControlSectionDivider";

const styles = (theme: Theme) =>
    createStyles({
        drawerItem: {
            width: "100%",
            paddingLeft: "24px",
            paddingRight: "24px",
        },
        drawerHeader: {
            color: theme.palette.info.main,
        },
        drawerSection: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
    });

interface OwnProps {
    sectionTitle: string;
    sectionContent: React.ReactNode;
    titleClassName?: string;
    contentClassName?: string;

    // if true; hides entire section, both title and content will not be rendered
    hideSection?: boolean;
    // if true; show divider below content; should be true for all but last section in side-content
    showDivider?: boolean;
}

type Props = OwnProps;

const ControlsSection: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const classes = useStyles(styles);
    return (
        <>
            {props.sectionContent && !props.hideSection && (
                <>
                    <Toolbar>
                        <Typography
                            className={clsx(
                                classes.drawerHeader,
                                props.titleClassName && props.titleClassName,
                            )}
                            variant={"h5"}
                        >
                            {props.sectionTitle}
                        </Typography>
                    </Toolbar>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        className={classes.drawerSection}
                    >
                        <Grid item xs className={classes.drawerItem}>
                            {props.sectionContent}
                        </Grid>
                    </Grid>
                    {props.showDivider && <ControlSectionDivider />}
                </>
            )}
        </>
    );
};

export default ControlsSection;
