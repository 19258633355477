import IntegrationConfiguration from "models/OrganizationModels/OrganizationIntegrationConfiguration";
import BaseService from "./BaseService";

export class IntegrationService extends BaseService {
    protected urlBase: string = "api/Integrations";

    public async getIntegrationConfiguration(organizationId: string) {
        const body = JSON.stringify({ organizationId });
        const res = this.post(body, new URLSearchParams({}), "List")
        return await res;
    }

    public async updateIntegrationConfiguration(
        value: IntegrationConfiguration[],
        organizationId: string,
    ) {
        const formattedItems = value.map((item: IntegrationConfiguration) => {
            return {
                ...item,
                name: item.type,
                baseIntegrationUri: item.url,
                // filter out empty string queueIds
                configuration: item.configuration &&
                    item.configuration?.customConfiguration && {
                        ...item.configuration,
                        customConfiguration: {
                            ...item.configuration?.customConfiguration,
                            queueIds:
                                item.configuration?.customConfiguration.queueIds?.filter(
                                    (qId) => !!qId,
                                ),
                        },
                    },
            };
        });

        const stringifiedItem = JSON.stringify(formattedItems);
        const res = this.post(
            stringifiedItem,
            new URLSearchParams({ organizationId }),
            "Update",
        );
        return await res;
    }
}
