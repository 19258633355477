import moment from "moment";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import BaseService from "./BaseService";
import { toIsoByDateReference } from "utils/DateTimeUtils";

interface CheckPayload {
    numRecords: number;
}

interface ReclassifyResult {
    succeeded: number;
    failed: number;
}

export type ReclassifyJobStatus =
    | "Queued"
    | "Running"
    | "Completed"
    | "Failed"
    | "Timeout";

export interface ReclassifyJobResult {
    id: string;
    startDate: string;
    endDate: string;
    dateReference: string;
    userName: string;
    hierarchies: string[];
    classifiers: string[];
    status: ReclassifyJobStatus;
    failureReason?: string;
    executionStartDateTimeUtc: string;
    executionEndDateTimeUtc: string;
    totalRecords: number;
    processedRecords: number;
}

export class ClassificationService extends BaseService {
    protected urlBase = "api/Classification";

    public async CheckReclassification(
        orgId: string,
        startDate: moment.Moment,
        endDate: moment.Moment,
        dateReference: DateReferenceOption,
        hierarchyIds?: string[],
        classifierIds?: string[],
    ) {
        const { beginDate: start, endDate: end } = toIsoByDateReference(
            startDate,
            endDate,
            dateReference,
        );

        // const params = new URLSearchParams({
        //     orgId: orgId || "",
        //     startDate: startDate?.format() || "",
        //     endDate: endDate?.format() || "",
        //     dateReference: dateReference.valueOf(),
        //     hierarchyIds: hierarchyIds?.join(",") || "",
        //     classifierIds: classifierIds?.join(",") || "",
        // });
        const params = new URLSearchParams();
        let body = {
            orgId: orgId || "",
            startDate: start!,
            endDate: end!,
            dateReference: dateReference.valueOf(),
            classifierIds: classifierIds || [],
            hierarchyIds: hierarchyIds || [],
        };
        const rv = await this.post<CheckPayload>(
            JSON.stringify(body),
            params,
            "CheckReclassification",
        );
        return rv;
    }

    public async Reclassify(
        orgId: string,
        sessionId: string,
        startDate: moment.Moment,
        endDate: moment.Moment,
        dateReference: DateReferenceOption,
        hierarchyIds?: string[],
        classifierIds?: string[],
    ) {
        const { beginDate: start, endDate: end } = toIsoByDateReference(
            startDate,
            endDate,
            dateReference,
        );

        const params = new URLSearchParams();
        let body = {
            orgId: orgId || "",
            sessionId: sessionId || "",
            startDate: start!,
            endDate: end!,
            dateReference: dateReference.valueOf(),
            classifierIds: classifierIds || [],
            hierarchyIds: hierarchyIds || [],
        };

        // if (classifierIds?.length) {
        //     body.classifierIds = classifierIds;
        //     for (const id of classifierIds) {
        //         params.append("classifierIds", id);
        //     }
        // }

        // if (hierarchyIds?.length) {
        //     for (const id of hierarchyIds) {
        //         params.append("hierarchyIds", id);
        //     }
        // }

        await this.post<ReclassifyResult>(
            JSON.stringify(body),
            params,
            "Reclassify",
        );
        // await this.get<ReclassifyResult>(params, "Reclassify");
        return;
    }

    public async reclassifyResults(): Promise<ReclassifyJobResult[]> {
        return this.get(new URLSearchParams(), "ReclassifyResults");
    }
}
