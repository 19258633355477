import {
    CssBaseline,
    Grid,
    Link,
    ThemeProvider,
    StyledEngineProvider,
} from "@mui/material";
import { default as AppTheme, default as theme } from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import AcxTextField from "components/UI/AcxTextField";
import React, { useEffect } from "react";
import {
    Navigate,
    Link as RouterLink,
    useSearchParams,
} from "react-router-dom";
import LocalStorage from "stores/LocalStorage";
import RootStore from "stores/RootStore";
import AuthPage from "./AuthPage";
import {
    ApplicationPaths,
    QueryParameterNames,
} from "./api-authorization/ApiAuthorizationConstants";

function EnterSubdomain() {
    const [subdomain, setSubdomain] = React.useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _] = useSearchParams();
    const authorization_uri = searchParams.get(
        `${QueryParameterNames.AuthorizationUri}`,
    );

    const local = RootStore().getStore(LocalStorage);
    const connectLocal = local.getLocalStore("Connect");

    useEffect(() => {
        async function setAuthorizationURI() {
            await connectLocal.setItem("authorizationURI", authorization_uri);
        }

        setAuthorizationURI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loginRoute = `${ApplicationPaths.Login}`;

    function login() {
        window.location.replace(
            `https://${encodeURI(subdomain)}.${window.location.host}${
                ApplicationPaths.Login
            }?${QueryParameterNames.AuthorizationUri}=${authorization_uri}`,
        );
    }

    if (!authorization_uri) {
        return <Navigate to={loginRoute} />;
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
                <CssBaseline />
                <AuthPage isLoading={false} title="Enter Company Subdomain">
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                        style={{ height: "100%" }}
                        direction="row"
                    >
                        <>
                            <Grid
                                item
                                xs={12}
                                container
                                alignItems="center"
                                justifyContent="center"
                                direction="column"
                            >
                                <div style={{ width: "100%" }}>
                                    <AcxTextField
                                        autoFocus
                                        id="InputSubdomain"
                                        type="text"
                                        label="Company Subdomain"
                                        placeholder="Company Subdomain"
                                        required={true}
                                        onChange={(event) =>
                                            setSubdomain(event.target.value)
                                        }
                                        fullWidth={true}
                                        other={{}}
                                    />
                                </div>

                                <AcxButton
                                    fullWidth
                                    style={{
                                        margin: "0.75rem 0",
                                        height: "2.25rem",
                                    }}
                                    onClick={login}
                                    color="primary"
                                    disabled={subdomain.length < 1}
                                    loading={false}
                                >
                                    Submit
                                </AcxButton>
                                <Link
                                    variant="body1"
                                    key={`enter-subdomain-skip`}
                                    style={{
                                        color: theme.palette.neutral[600],
                                        textUnderlineOffset: theme.spacing(0.5),
                                        fontWeight: "inherit",
                                    }}
                                    color="textPrimary"
                                    component={RouterLink}
                                    to={`${window.location.origin}${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${window.location.origin}${ApplicationPaths.Login}?${QueryParameterNames.AuthorizationUri}=${authorization_uri}`}
                                >
                                    Skip
                                </Link>
                            </Grid>
                        </>
                    </Grid>
                </AuthPage>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default EnterSubdomain;
