import theme from "Theme/AppTheme";

export const defaultColorScheme = [
    theme.palette.green.main,
    theme.palette.blue.main,
    theme.palette.orange.main,
    theme.palette.red.main,
];

export const signalsChartColorScheme = [
    "#1D2A60",
    "#32D1B7",
    "#F97750",
    "#FFD240",
    "#1E40AF",
    "#17A4EB",
    "#F6B100",
    "#B91C1C",
    "#6F95F4",
    "#7E22CE",
];

export const pieGraphDefaultColorScheme: string[] = [
    theme.palette.green.main,
    theme.palette.blue.main,
    theme.palette.orange.main,
    theme.palette.red.main,
    theme.palette.green.dark!, // need to ensure green dark gets set
    theme.palette.yellow.main,
];
