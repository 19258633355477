import { Checkbox, FormControlLabel, Grid, IconButton, Paper } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import { JsonDataTypes } from "models/OrganizationModels/OrganizationMetadataConfiguration";
import React, { FunctionComponent } from "react";
import useStyles from "Styles/Styles";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import {
    LOAD_CONFIG,
    OrganizationMetadataStore,
} from "./Store/OrganizationMetadataStore";

const styles = () =>
    createStyles({
        fieldSpacing: {
            margin: "0.25rem 0",
            padding: "0 0 0 0.125rem",
        },
        metadataContainer: {
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        inputSpacing: { paddingLeft: ".125rem" },
    });

interface MetadataExtendedFieldRowProps {
    configIndex: number;
    id: string;
    currentIndex: number;
    store: OrganizationMetadataStore;
}

const MetadataExtendedFieldRow: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<MetadataExtendedFieldRowProps>>> =
    observer((props) => {
        const { configIndex, id, currentIndex, store } = props;
        const classes = useStyles(styles);

        const onExtendedFieldNameChange = React.useCallback(
            (event: string, index: number) => {
                store.handleExtendedFieldNameUpdates(configIndex, event, index);
            },
            [store, configIndex],
        );

        const onExtendedFieldPathChange = React.useCallback(
            (event: string, index: number) => {
                store.handleExtendedFieldPathUpdates(configIndex, event, index);
            },
            [store, configIndex],
        );

        const onRequiredUpdate = React.useCallback(
            (index: number) => {
                store.handleExtendedFieldIsRequiredUpdates(configIndex, index);
            },
            [store, configIndex],
        );

        const onIndexedUpdate = React.useCallback(
            (index: number) => {
                store.handledIsIndexedUpdates(configIndex, index);
            },
            [store, configIndex],
        );

        const onJsonDataTypeChange = React.useCallback(
            (event: string, index: number) => {
                store.handleJsonDataTypeUpdate(
                    configIndex,
                    Number(event),
                    index,
                );
            },
            [store, configIndex],
        );

        const currentExtendedField =
            store.organizationMetadataConfiguration[configIndex]
                .configuration?.extendedFields?.[currentIndex];

        const jsonDataTypeOptions =
            EnumToDropdownOptionConversion(JsonDataTypes);

        return (
            <Paper className={classes.metadataContainer}>
                <Grid container item xs={12}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.fieldSpacing}
                    >
                        <Grid item xs={3} className={classes.inputSpacing}>
                            <AcxMainTextField
                                fullWidth
                                skeleton={store.getTaskLoading(LOAD_CONFIG)}
                                labelText="Field Name"
                                id="extendedFieldName"
                                value={currentExtendedField?.fieldName}
                                onChange={(event) =>
                                    onExtendedFieldNameChange(
                                        event.target.value,
                                        currentIndex,
                                    )
                                }
                                showAllErrors={true}
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.inputSpacing}>
                            <AcxMainTextField
                                fullWidth
                                skeleton={store.getTaskLoading(LOAD_CONFIG)}
                                labelText="Field Path"
                                id="fieldPath"
                                value={currentExtendedField?.fieldPath}
                                onChange={(event) =>
                                    onExtendedFieldPathChange(
                                        event.target.value,
                                        currentIndex,
                                    )
                                }
                                showAllErrors={true}
                            />
                        </Grid>

                        <Grid item xs={2} className={classes.inputSpacing}>
                            <AcxSelect
                                inputLabel="JSON Data Type"
                                fullWidth
                                defaultValue={jsonDataTypeOptions.find(
                                    (item) =>
                                        item.value ===
                                        currentExtendedField?.jsonDataType,
                                )}
                                id={`jsonDataType_${currentIndex}`}
                                options={jsonDataTypeOptions}
                                onChange={(event) =>
                                    onJsonDataTypeChange(
                                        event.value,
                                        currentIndex,
                                    )
                                }
                                placeholder="JSON Data Type"
                            />
                        </Grid>

                        <Grid
                            container
                            item
                            xs={1}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <IconButton
                                onClick={() =>
                                    store.removeFieldConfiguration(
                                        "extendedFields",
                                        id,
                                        configIndex,
                                    )
                                }
                                size="large">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.fieldSpacing}
                    >
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={() =>
                                            onRequiredUpdate(currentIndex)
                                        }
                                        id={`isExtendFieldRequired_${currentIndex}`}
                                        name="isExtendedFieldRequired"
                                        checked={
                                            currentExtendedField?.isRequired ??
                                            false
                                        }
                                    />
                                }
                                label="Is Required"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={() =>
                                            onIndexedUpdate(currentIndex)
                                        }
                                        id={`isIndexed_${currentIndex}`}
                                        name="isIndexed"
                                        checked={
                                            currentExtendedField?.isIndexed ??
                                            false
                                        }
                                    />
                                }
                                label="Is Indexed"
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item xs={5} className={classes.inputSpacing}>
                            <AcxMainTextField
                                fullWidth
                                skeleton={store.getTaskLoading(LOAD_CONFIG)}
                                labelText="Extraction Pattern"
                                id="extractionPattern"
                                value={currentExtendedField?.extractionPattern}
                                onChange={(event) =>
                                    store.handleExtractionPatternUpdate(
                                        configIndex,
                                        event.target.value,
                                        currentIndex,
                                        "MetadataExtendedField"
                                    )
                                }
                                showAllErrors={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    });

export default MetadataExtendedFieldRow;
