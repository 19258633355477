import { DateReferenceOption } from "../../stores/ComponentStores/DatePickerComponentStore";
import { IDashboardItem } from "../../stores/Layout/DashboardStore";
import {
    DynamicTimeSpan,
    RefreshInterval,
} from "../../utils/reportingTimeHelpers";

export enum DashboardDomain {
    AnalystManagement = "AnalystManagement",
    Analyst = "Analyst",
    Leader = "Leader",
    Agent = "Agent",
    Landing = "Landing",
}

export interface IDashboardDefinition {
    userId: string | null;
    organizationId: string;
    domain: DashboardDomain;
    items: IDashboardItem[];
    timeSpan: DynamicTimeSpan;
    refresh: RefreshInterval;
    dateReference: DateReferenceOption;
    hierarchyIds: string[];
}
