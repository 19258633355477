import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AcxExpansion from "components/UI/AcxExpansion";
import { INavigationGroup } from "Layouts/SideNavigation/NavigationStore";
import React from "react";

import { ThemeColors } from "../../Theme/AppTheme";
import SideNavigationLink from "./SideNavigationLink";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    itemNavigationBody: {
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(1.75),
    },
    itemNavigationRoot: {
        paddingRight: theme.spacing(0.75),
        paddingLeft: theme.spacing(0.75),
    },
    groupHeader: (props: Props) => ({
        fontSize: props.signalsVariant ? "14px" : "16px",
        fontFamily: "Inter",
        fontWeight: "bold",
        color: theme.palette.black.main,
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    }),
    drawerHeader: {
        marginBottom: theme.spacing(2),
        paddingRight: theme.spacing(1.35),
        paddingLeft: theme.spacing(1.35),
    },
    orgSelect: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    searchField: {
        marginTop: theme.spacing(1),
    },
    searchIconColor: {
        color: ThemeColors.black,
        opacity: 0.25,
        marginRight: theme.spacing(1),
    },
    editorButtons: {
        textTransform: "none",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightBold as any,
    },
    root: {
        height: "100%",
        paddingTop: theme.spacing(1.75),
    },
    chevronClass: {
        marginLeft: "0px",
        marginRight: theme.spacing(1.5),
    },
}));

type Props = {
    items: INavigationGroup[];
    currentNavItem: string;
    organizationId: string;
    isLoading?: boolean;
    signalsVariant?: boolean;
};

const SideNavigation: React.FC<Props> = observer((props) => {
    const { items, currentNavItem, organizationId, isLoading } = props;
    const classes = useStyles(props);

    return (
        <>
            {isLoading ? (
                <AcxLoadingIndicator size={45} alternate="PuffLoader" />
            ) : (
                items.map((item, i) => (
                    <AcxExpansion
                        expanded={item.expanded ?? true}
                        headerClass={classes.groupHeader}
                        header={
                            item.linkPath === "" || item.linkPath ? (
                                <Link
                                    to={item.linkPath ?? ""}
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    {item.header}
                                </Link>
                            ) : (
                                item.header
                            )
                        }
                        key={`side-nav-${i}`}
                        body={item.items.map((subItem, index) => (
                            <SideNavigationLink
                                key={`side-nav-item-${subItem.title?.toString()}-${index}`}
                                val={subItem}
                                currentNavItem={currentNavItem}
                                replaceLinks={{ organizationId }}
                            />
                        ))}
                        bodyClass={classes.itemNavigationBody}
                        rootClass={classes.itemNavigationRoot}
                        chevronCloseClass={
                            props.signalsVariant
                                ? classes.chevronClass
                                : undefined
                        }
                        chevronExpandedClass={
                            props.signalsVariant
                                ? classes.chevronClass
                                : undefined
                        }
                    />
                ))
            )}
        </>
    );
});

export default SideNavigation;
