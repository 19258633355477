import { Box, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React from "react";
import {
    ConversationClassifier,
    IRBCHighlight,
} from "services/ConversationService";
import AcxChipList from "components/UI/AcxChipList";
import { FTSHighlights } from "./FTSHighlights";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        marginBottom: theme.spacing(1),
    },
}));

interface RBCSearchHighlightProps {
    RBCHighlight: IRBCHighlight;
    conversationClassifiers: ConversationClassifier[];
    isChat: boolean;
    disableChips?: boolean;
}

const RBCSearchHighlight: React.FC<RBCSearchHighlightProps> = observer(
    ({
        RBCHighlight: {
            speaker0Highlights,
            speaker1Highlights,
            speaker2Highlights,
            speaker3Highlights,
            classifierName,
        },
        isChat,
        disableChips,
    }) => {
        const classes = useStyles();

        return (
            <Box className={classes.container}>
                <FTSHighlights
                    highlights={{
                        fullTextSearchHighlightsSpeaker0: speaker0Highlights,
                        fullTextSearchHighlightsSpeaker1: speaker1Highlights,
                        fullTextSearchHighlightsSpeaker2: speaker2Highlights,
                        fullTextSearchHighlightsSpeaker3: speaker3Highlights,
                    }}
                    disableAllChips
                    isChat={isChat}
                />
                {!disableChips && (
                    <AcxChipList
                        size="small"
                        color="gray"
                        values={[classifierName]}
                    />
                )}
            </Box>
        );
    },
);

export default RBCSearchHighlight;
