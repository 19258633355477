export const arrayMove = (
    arr: any[],
    previousIndex: number,
    newIndex: number,
) => {
    if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(previousIndex, 1)[0]);
    return arr;
};
