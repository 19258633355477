import DirectoryModel from "../models/DirectoryModel";
import BaseService from "./BaseService";

export class DirectoryService extends BaseService {
    protected urlBase: string = "api/Directory";

    public async addServiceHierarchy(DirId: string, OrgMemberId?: string) {
        const params = new URLSearchParams({ DirId });
        if (OrgMemberId) {
            params.set("OrgMemberId", OrgMemberId);
        }

        const res = await this.put<DirectoryModel>(params, "AddOrgMember");

        return res;
    }

    public async addServiceHierarchies(DirIds: string[], OrgMemberId?: string) {
        let params = new URLSearchParams({});
        for (let dirId of DirIds) {
            params.append("DirIds", dirId);
        }

        if (OrgMemberId) {
            params.set("OrgMemberId", OrgMemberId);
        }

        const res = await this.put<DirectoryModel[]>(params, "AddOrgMembers");

        return res;
    }
}
