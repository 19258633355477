import { makeObservable, observable } from "mobx";
import type { MetadataSpec } from "models/MetadataSpec";
import moment from "moment";

export type MetaSource = "filename" | "file" | "";
export type ExtHandle = "removedot" | "removestring" | "";
export type MetaFieldLabels =
    | "Filename"
    | "Agent Name"
    | "Interaction Date"
    | "Call Direction"
    | "Meta 1"
    | "Meta 2"
    | "Meta 3"
    | "Meta 4"
    | "Meta 5";

export class TemplateModel {
    @observable sampleString: string = "";
    @observable delimiter: string = "."; //. - _ ,
    @observable metaSource: MetaSource = "";

    @observable filename: string = "";
    @observable extHandle: ExtHandle = "";
    @observable removeChars: string = "";
    @observable processedFilename: string = "";

    @observable agentName: string = "";
    @observable date: moment.Moment = moment();
    @observable callDirection: string = "";
    @observable meta1: string = "";
    @observable meta2: string = "";
    @observable meta3: string = "";
    @observable meta4: string = "";
    @observable meta5: string = "";

    @observable metaSourceType: "CSV" | "FILENAME" = "CSV";

    @observable parseType?: MetadataSpec.ParseType;
    @observable spec?: MetadataSpec.ISpecType;

    @observable fieldOptions: { label: string; value: string }[] = [];

    constructor() {
        makeObservable(this);
    }
}
