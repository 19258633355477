import { Divider, Grid, IconButton, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CachedIcon from "@mui/icons-material/Cached";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteRuleSetDialog from "components/Admin/AdminUIComponents/AudioFileSampler/Views/DeleteRuleSetDialog";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import {
    ARBITRARY_STRATIFICATION_OPERATOR,
    RuleType,
} from "../../../../../../models/Rule";
import theme from "../../../../../../Theme/AppTheme";
import { useStore } from "../../../../../../utils/useStore";
import AcxButton from "../../../../../UI/AcxButton";
import ConfirmationDialog from "../../../../../UI/AcxConfirmationDialog";
import AcxFilePicker from "../../../../../UI/AcxFileSelector/AcxFilePicker";
import AcxMainTextField from "../../../../../UI/AcxMainTextFieldGrid";
import { CustomControlItem } from "../../../../../UI/AcxTable/AcxControlledTable";
import DeleteSpecDialog from "../../../AudioFileSampler/Views/DeleteSpecDialog";
import RuleSetSelect from "../../../AudioFileSampler/Views/RuleSetSelector";
import SpecConfirmationDialog from "../../../AudioFileSampler/Views/SpecSaveConfirmDialog";
import SpecSelect from "../../../AudioFileSampler/Views/SpecSelector";
import {
    ConfigurationStepStore,
    LoadFilesFromSelectedDirectoriesTask,
    LoadRuleSets,
} from "../../Stores/ConfigurationStepStore";
import SourceFilesTable from "../SourceFilesTable";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";

const useStyles = makeStyles((theme) => ({
    rotateIcon45: {
        transform: "rotate(45deg)",
    },
    secondaryIconButton: {
        backgroundColor: theme.palette.white.main,
        borderRadius: theme.spacing(0.5),
        border: "1px solid #E4E4E7",
        color: "#9CA3AF",
    },
}));

interface OwnProps {}

type Props = OwnProps;

const ConfigurationStep: FunctionComponent<Props> = observer((props) => {
    const store = useStore(ConfigurationStepStore);
    const classes = useStyles();

    const navigate = useNavigate();

    function onRuleBuilderNavigate() {
        if (store.orgSelectorStore.orgId) {
            store.prepareRuleBuilderForNavigation();
            navigate("/focus/rulebuilder");
        }
    }

    function onSpecBuilderNavigate() {
        store.prepareSpecBuilderForNavigation();
        const url = "/focus/metadatabuilder";
        navigate(url);
    }

    const formatters: Map<string, (arg: any, row: any) => React.ReactNode> =
        new Map();
    formatters["agentName"] = (agentName: string) => {
        return (
            <Typography
                color={
                    Boolean(
                        store.formattedAgentsMap[
                            agentName?.replace(/\s+/g, "")?.toLowerCase() ??
                                "no-agent"
                        ],
                    )
                        ? "textPrimary"
                        : "error"
                }
            >
                {agentName}
            </Typography>
        );
    };

    const ruleSetControls: CustomControlItem[] = [
        {
            controlElement: (
                <IconButton
                    onClick={store.executeLoadFilesFromSelectedDirs}
                    size="small"
                    className={classes.secondaryIconButton}
                >
                    <CachedIcon className={classes.rotateIcon45} />
                </IconButton>
            ),
            xl: "auto",
            lg: "auto",
            md: "auto",
            sm: "auto",
            xs: "auto",
            style: {},
        },
        {
            controlElement: (
                <RuleSetSelect
                    defaultValue={store.activeRuleSet}
                    label={"RuleSets"}
                    orgId={store.orgSelectorStore.orgId}
                    ruleSets={store.ruleSets}
                    showSpinner={false}
                    onSelect={store.setActiveRuleSet}
                    loading={store.getTaskLoading(LoadRuleSets)}
                />
            ),
            xl: "auto",
            lg: "auto",
            md: "auto",
            sm: "auto",
            xs: "auto",
            style: { minWidth: "200px" },
        },
        {
            controlElement: (
                <IconButton
                    onClick={() => {
                        store.deleteRuleSetOpen = true;
                    }}
                    disabled={store.activeRuleSet === undefined}
                    style={{ padding: theme.spacing(0.5) }}
                    size="large"
                >
                    <DeleteIcon />
                </IconButton>
            ),
            xl: "auto",
            lg: "auto",
            md: "auto",
            sm: "auto",
            xs: "auto",
            style: {},
        },
        {
            controlElement: (
                <AcxButton
                    fullWidth
                    onClick={onRuleBuilderNavigate}
                    color="white"
                >
                    {store.activeRuleSet ? "Edit" : "Build"} RuleSet
                </AcxButton>
            ),
            xl: "auto",
            lg: "auto",
            md: "auto",
            sm: "auto",
            xs: "auto",
            style: {},
        },
    ];

    const configControls: CustomControlItem[] = [
        ...(store.ruleSetHidden ? [] : ruleSetControls),
        {
            controlElement: (
                <AcxHierarchySelector
                    displayType="input"
                    userId={
                        store.recommendationStepStore.authStore._user.profile
                            .sub
                    }
                    orgId={store.orgSelectorStore.orgId ?? ""}
                    setHierarchyIds={(ids) => {
                        store.hierarchyIdsToSampleFrom = ids;
                    }}
                    onSaveUpdateWithHierarchies={() => {}}
                    popperPlacement="bottom"
                    customZIndex={1}
                    removeMinHeight
                />
            ),
            xl: "auto",
            lg: "auto",
            md: "auto",
            sm: "auto",
            xs: "auto",
            style: {},
        } as CustomControlItem,
        {
            controlElement: (
                <AcxButton
                    fullWidth
                    disabled={
                        !Boolean(store.activeRuleSet) && !store.ruleSetHidden
                    }
                    color="primary"
                    onClick={store.showBuildRecommendationDialog}
                    style={{
                        height: "32px",
                        marginRight: "auto",
                        marginLeft: "auto",
                    }}
                >
                    Build Recommendation
                </AcxButton>
            ),
            xl: "auto",
            lg: "auto",
            md: "auto",
            sm: "auto",
            xs: "auto",
            style: {},
        } as CustomControlItem,
    ];

    return (
        <>
            {store.newSpecFromBuilder && (
                <SpecConfirmationDialog store={store} />
            )}
            {store.deleteSpecOpen && <DeleteSpecDialog store={store} />}

            {store.buildRecommendationDialogVisibility && (
                <ConfirmationDialog
                    isOpen={store.buildRecommendationDialogVisibility}
                    onClose={() => store.closeBuildRecommendDialog()}
                    onConfirm={() => {
                        store.closeBuildRecommendDialog();
                        store.startBuildingSampleSuggestion();
                    }}
                    content={renderDialogContent(store, theme)}
                    title={"Generate Sampling Recommendation"}
                />
            )}

            <Grid
                container
                item
                xs={12}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                style={{ height: "100%" }}
            >
                <Grid
                    item
                    xs={12}
                    container
                    alignItems={"flex-end"}
                    justifyContent="flex-end"
                    spacing={1}
                >
                    <Grid
                        item
                        xs={6}
                        sm={5}
                        md={3}
                        lg={3}
                        xl={2}
                        style={{ minWidth: "130px", display: "block" }}
                    >
                        <SpecSelect
                            label={"Spec Applied"}
                            key={store.activeSpecModel?.specName}
                            orgId={store.orgSelectorStore.orgId}
                            store={store as any}
                            specModels={
                                store.orgSelectorStore.orgId
                                    ? store.metadataSpecModelList.get(
                                          store.orgSelectorStore.orgId,
                                      )
                                    : undefined
                            }
                            defaultValue={store.activeSpecModel}
                            showSpinner={false}
                            noSelect
                            onSelect={store.setActiveSpec}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={store.setDeleteSpecOpen}
                            disabled={!store.activeSpecModel}
                            style={{ padding: theme.spacing(0.5) }}
                            className=""
                            size="large"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={3} sm={3} md={2} lg={1} xl={1}>
                        <AcxButton
                            fullWidth
                            disabled={!store.files || store.files.length === 0}
                            color="white"
                            style={{
                                height: "32px",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                            tooltip="Open metadata specification builder"
                            onClick={onSpecBuilderNavigate}
                        >
                            {store.activeSpecModel ? "Edit" : "Build"} Spec
                        </AcxButton>
                    </Grid>
                    <Grid item xs={6} sm={5} md={3} lg={3} xl={2}>
                        <AcxFilePicker
                            name={"Metadata"}
                            defaultValue={store.MetaFiles}
                            onChange={store.setMetaFiles}
                            accept="text/csv"
                        />
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={2} xl={2}>
                        <AcxButton
                            fullWidth
                            disabled={
                                !store.files ||
                                store.files.length === 0 ||
                                !store.activeSpecModel
                            }
                            color="white"
                            style={{
                                height: "32px",
                                marginRight: "auto",
                                marginLeft: "auto",
                            }}
                            tooltip="Update metadata - this may take some time"
                            loading={store.getTaskLoading(
                                "Parse Update Metadata",
                            )}
                            onClick={() => {
                                store.parseUpdateMetadata();
                            }}
                        >
                            Update Metadata
                        </AcxButton>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{ marginTop: "12px" }}>
                    <Divider style={{ height: "2px" }} variant={"fullWidth"} />
                </Grid>
                <Grid item xs={12} style={{ height: "100%" }}>
                    <SourceFilesTable
                        loading={store.getTaskLoading(
                            LoadFilesFromSelectedDirectoriesTask,
                        )}
                        tableStoreFragment={store.tableStore}
                        controls={configControls}
                        formatters={formatters}
                        metaLabels={store.sourceFilesStepStore.metaLabels}
                    />
                </Grid>
            </Grid>
            <DeleteRuleSetDialog store={store} />
        </>
    );
});

export default ConfigurationStep;

const renderDialogContent = (store: ConfigurationStepStore, theme: Theme) => {
    let labelText = "Sample Size";

    const activeStratRule = store.activeRuleSet?.rules.find(
        (r) => r.ruleType === RuleType.Stratify,
    );

    if (activeStratRule?.operator === ARBITRARY_STRATIFICATION_OPERATOR) {
        labelText =
            "Number of calls for each " + activeStratRule?.classifier.name;
    }

    return (
        <Grid
            container
            spacing={4}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <Grid item>
                <Typography
                    variant={"h5"}
                    component={"div"}
                    sx={{
                        color: theme.palette.text.secondary,
                        ul: {
                            overflowY: "auto",
                            maxHeight: "50vh",
                        },
                        span: {
                            color: theme.palette.info.light,
                        },
                    }}
                >
                    Are you sure that you would like to generate sampling
                    recommendations between{" "}
                    <span>{store.formattedArrivedOnDateRange}</span> from:{" "}
                    <br />
                    <ul>
                        {store.samplingDirectories?.map((value) => (
                            <li key={value}>{value}</li>
                        ))}
                    </ul>
                    with Ruleset: <span>{store.activeRuleSet?.name}</span>{" "}
                    {store.activeSpecModel?.specName && (
                        <>
                            {" "}
                            and Metadata spec:{" "}
                            <span>{store.activeSpecModel?.specName}</span>{" "}
                        </>
                    )}
                </Typography>
            </Grid>

            <Grid item>
                <AcxMainTextField
                    id={"recommendation-dialog-sample-size"}
                    value={store.sampleSizeAsString}
                    labelText={labelText}
                    onChange={(evt) => store.setSampleSize(evt.target.value)}
                />
            </Grid>
        </Grid>
    );
};
