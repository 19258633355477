export class Routes {
    static EVALUATION = "/evaluation/:orgId/:evalId";
    static HOME = "/app";
    static ReportsRv2 = "/reportsRv2";
    static Signals = "/signals";
    static INTERACTIONS = "interactions";
    static CONVERSATIONS = "conversations";
    static CONVERSATIONS_WORKFLOW = "/app/conversations/:amdId/workflow";
    static AGENT_COACHING = "/agentcoaching";
    static AGENT_DASHBOARD = `${Routes.AGENT_COACHING}/dashboard/:orgId/:agentId/:agentName`;
    static WORKFLOWS = "/workflows";
    static WORKFLOW_REVIEW = "/workflow_review/:orgId/:workflowId";
    static routePrefix = "/app";

    //This gets referenced anonymously by the backend so don't remove it, even it appears to have no calls in the frontend.
    static makeAgentDashboardRoute(
        orgId: string,
        agentId: string,
        agentName: string,
    ) {
        return Routes.AGENT_DASHBOARD.replace(":orgId", orgId)
            .replace(":agentId", agentId)
            .replace(":agentName", agentName);
    }

    static makeEvaluationRoute(orgId: string, evalId: string) {
        return Routes.EVALUATION.replace(":orgId", orgId).replace(
            ":evalId",
            evalId,
        );
    }

    static makeConversationsRoute(orgId: undefined, conversationId: string) {
        return `${this.routePrefix}/${Routes.CONVERSATIONS}/${conversationId}`;
    }
}
