import AppsIcon from "@mui/icons-material/Apps";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Badge, IconButton, Theme, Tooltip, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { AdminRoutes } from "components/Admin/AdminNavigation/AdminRoutes";
import { observer } from "mobx-react";
import React, { CSSProperties, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import AcxMenu, { AcxMenuItemProps, StyledMenuLabel } from "../UI/Menu/AcxMenu";
import HelpDialog from "./HelpDialog";

const styles = (theme: Theme) =>
    createStyles({
        profileButton: {
            marginRight: "0.5rem!important",
            marginLeft: ".5rem",
        },
        menuItemLabel: {
            color: "inherit",
            fontSize: "inherit",
            display: "inline-block",
            "&:hover, &.Mui-selected:hover": {
                color: theme.palette.neutral[600],
            },
            "&:focus, &:active, &.Mui-selected": {
                color: theme.palette.primary[500],
            },
        },
    });

interface IProfileMenuIcon {
    currLocation: "mainApp" | "adminApp";
    organizationName?: string;
    customRootStyles?: CSSProperties;
}

const ProfileMenuIcon = observer((props: IProfileMenuIcon) => {
    const authStore = useStore(AuthStore);
    const navigate = useNavigate();
    const theme = useTheme();
    const classes = useStyles(styles);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [helpDialogOpen, setHelpDialogOpen] = useState<boolean>(false);
    const location = useLocation();

    function closeHelpDialog() {
        setHelpDialogOpen(false);
    }

    function openHelpDialog() {
        setHelpDialogOpen(true);
    }

    const handleUserClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    function handleProfileOpen() {
        navigate("/focus/userprofile", { state: { previousPath: location.pathname }});
        handleUserMenuClose();
    }

    function handleAdmin() {
        const settingsTab = AdminRoutes.tabsConfig.find(
            (i) => i.tabLabel === "Settings",
        );
        if (settingsTab) {
            if (authStore.orgStore.selectedOrganization?.id) {
                settingsTab.route = "/organizations/" + authStore.orgStore.selectedOrganization?.id;
            } else {
                settingsTab.route =
                    "/organizations/" + authStore._user.profile.OrgId;
            }
        }
        navigate("/admin");
    }
    // function handleOrgList() {
    //     navigate("/admin/organizationList");
    // }

    function handleMainApp() {
        navigate("/app");
    }

    const handleUserMenuClose = () => {
        setAnchorEl(null);
    };

    const menuItems: AcxMenuItemProps[] = [
        {
            id: "profile-menu-item",
            label: <StyledMenuLabel>Profile</StyledMenuLabel>,
            icon: (
                <Badge
                    overlap="rectangular"
                    badgeContent={
                        props.organizationName &&
                        props.organizationName.length > 0
                            ? props.organizationName[0].toUpperCase()
                            : ""
                    }
                    color="primary"
                >
                    <PersonOutlineIcon fontSize={"small"} />
                </Badge>
            ),
            props: {
                onClick: handleProfileOpen,
                style: { minWidth: "195px", overflow: "visible" },
            },
        },
        {
            id: "settings-menu-item",
            label: <StyledMenuLabel>Settings</StyledMenuLabel>,
            icon: <SupervisorAccountIcon fontSize={"small"} />,
            hidden: !(
                props.currLocation === "mainApp" &&
                authStore.canUserViewAdminApp()
            ),
            props: { onClick: handleAdmin, style: { minWidth: "195px" } },
        },
        // {
        //     id: "organizations-menu-item",
        //     label: <StyledMenuLabel>Organizations</StyledMenuLabel>,
        //     icon: <ListIcon fontSize={"small"} />,
        //     hidden: !(
        //         props.currLocation === "mainApp" &&
        //         authStore.canUserViewAdminApp() &&
        //         authStore.permStore.userTenantAuthorizations.length
        //     ),
        //     props: { onClick: handleOrgList, style: { minWidth: "195px" } },
        // },
        {
            id: "home-menu-item",
            label: <StyledMenuLabel>Home</StyledMenuLabel>,
            icon: <AppsIcon fontSize={"small"} />,
            hidden: props.currLocation === "mainApp",
            props: { onClick: handleMainApp, style: { minWidth: "195px" } },
        },
        {
            id: "help-menu-item",
            label: <StyledMenuLabel>Help</StyledMenuLabel>,
            icon: <ContactSupportIcon fontSize={"small"} />,
            props: { onClick: openHelpDialog, style: { minWidth: "195px" } },
        },
        {
            id: "logout-menu-item",
            label: <StyledMenuLabel>Logout</StyledMenuLabel>,
            icon: <ExitToAppIcon fontSize={"small"} />,
            props: {
                onClick: () => authStore.handleUserLogout(),
                style: { minWidth: "195px" },
            },
        },
    ];

    return (
        <>
            <HelpDialog onClose={closeHelpDialog} open={helpDialogOpen} />
            <Tooltip title={`User Tenant: ${props.organizationName}` ?? ""}>
                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={"profile-menu"}
                    aria-haspopup="true"
                    onClick={handleUserClick}
                    size="small"
                    style={{
                        backgroundColor: hexToRGB(
                            theme.palette.primary.main,
                            0.1,
                        ),
                        ...props.customRootStyles,
                    }}
                    sx={{
                        marginRight: "0.5rem!important",
                        marginLeft: ".5rem",
                    }}
                    className={
                        !props.customRootStyles ? classes.profileButton : ""
                    }
                >
                    <Badge
                        overlap="rectangular"
                        badgeContent={
                            props.organizationName &&
                            props.organizationName.length > 0
                                ? props.organizationName[0].toUpperCase()
                                : ""
                        }
                        color="primary"
                    >
                        <PersonIcon color="primary" />
                    </Badge>
                </IconButton>
            </Tooltip>

            <AcxMenu
                menuItems={menuItems}
                anchorElement={anchorEl}
                onMenuClose={handleUserMenuClose}
            />
        </>
    );
});

export default ProfileMenuIcon;
