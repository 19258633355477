import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Popover,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveIcon from "@mui/icons-material/Remove";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import { ChatFileSchemaHandlers } from "models/OrganizationModels/OrganizationMetadataConfiguration";
import React, { useCallback } from "react";
import useStyles from "Styles/Styles";
import theme from "Theme/AppTheme";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import MetadataCampaignRow from "./MetadataCampaignRow";
import MetadataExtendedAssociationFieldRow from "./MetadataExtendedAssociationFieldRow";
import MetadataExtendedFieldRow from "./MetadataExtendedFieldRow";
import MetadataFieldRow from "./MetadataFieldRow";
import MetadataLanguageRow from "./MetadataLanguageRow";
import MetadataMediaSegments from "./MetadataMediaSegments";
import {
    LOAD_CONFIG,
    OrganizationMetadataStore,
    SET_CONFIG,
} from "./Store/OrganizationMetadataStore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { DateFormatHelperText } from "components/Admin/AdminUIComponents/MetadataTemplate/MetaInput/FormatOptions";

const WideTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
    },
});

const styles = () =>
    createStyles({
        fieldSpacing: {
            margin: "0.25rem 0",
            padding: "0 0 0 0.125rem",
        },
        metadataHeader: {
            margin: "5px",
        },
        metadataContainer: {
            padding: "16px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        inputSpacing: { paddingLeft: ".125rem" },
        heading: {
            fontSize: theme.typography.pxToRem(14),
            flexBasis: "60%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
        },
        block: { display: "block" },
    });

interface MetadataConfigurationProps {
    organizationId?: string;
    metadataStore: OrganizationMetadataStore;
    configIndex: number;
}

type Props = MetadataConfigurationProps;

const MetadataConfiguration: React.FunctionComponent<Props> = observer(
    ({ metadataStore, configIndex }) => {
        const [isOpen, setIsOpen] = React.useState<boolean>(false);
        const [nameChanged, setIsNameChanged] = React.useState<boolean>(false);
        const [dateFormatHelperIcon, setDateFormatHelperIcon] =
            React.useState<HTMLButtonElement | null>(null);
        const classes = useStyles(styles);

        const toggleAccordion = () => {
            setIsOpen((prev) => !prev);
        };

        const handleFieldAddition = useCallback(() => {
            metadataStore.addMetadataField(configIndex);
        }, [configIndex, metadataStore]);

        const handleCampaignAddition = useCallback(() => {
            metadataStore.addCampaignIndicatorDefinition(configIndex);
        }, [configIndex, metadataStore]);

        const handleLanguageAddition = useCallback(() => {
            metadataStore.addLanguageIndicatorDefinition(configIndex);
        }, [configIndex, metadataStore]);

        const handleExtendedFieldAddition = useCallback(() => {
            metadataStore.addExtendedField(configIndex);
        }, [configIndex, metadataStore]);

        const handleExtendedAssociationFieldAddition = useCallback(() => {
            metadataStore.addExtendedAssociationField(configIndex);
        }, [configIndex, metadataStore]);

        const handleMediaSegmentsAdd = useCallback(() => {
            metadataStore.addMediaSegments(configIndex);
        }, [configIndex, metadataStore]);

        const handleMetadataFileTypeUpdate = useCallback(
            (event: any) =>
                metadataStore.handleMetadataFileTypeUpdates(
                    configIndex,
                    event.value,
                ),
            [configIndex, metadataStore],
        );

        const handleMetadataFileTypeExtensionUpdate = useCallback(
            (event: any) =>
                metadataStore.handleMetadataFileTypeExtensionUpdates(
                    configIndex,
                    event.value,
                ),
            [configIndex, metadataStore],
        );

        const handleNameSpaceUpdate = useCallback(
            (event: any) => {
                metadataStore.handleNameSpaceUpdates(
                    configIndex,
                    event.target.value,
                );
            },
            [configIndex, metadataStore],
        );

        const handleChatFileSchemaHandlerUpdate = useCallback(
            (event: any) =>
                metadataStore.handleChatFileSchemaHandlerUpdates(
                    configIndex,
                    event.value,
                ),
            [configIndex, metadataStore],
        );

        const handlePrefixUpdate = useCallback(
            (event: any) =>
                metadataStore.handlePrefixUpdates(
                    configIndex,
                    event.target.value,
                ),
            [configIndex, metadataStore],
        );

        const handleNameUpdate = useCallback(
            (event: any) => {
                setIsNameChanged(true);

                metadataStore.handleNameUpdates(
                    configIndex,
                    event.target.value,
                );
            },
            [configIndex, metadataStore],
        );

        const handleIsDebugUpdate = useCallback(
            (event: any) =>
                metadataStore.handleIsDebugUpdates(
                    configIndex,
                    event.target.checked,
                ),
            [configIndex, metadataStore],
        );

        const handleAgentRequiredUpdates = useCallback(
            (event: any) =>
                metadataStore.handleAgentRequiredUpdates(
                    configIndex,
                    event.target.checked,
                ),
            [configIndex, metadataStore],
        );

        const currentFile =
            metadataStore.organizationMetadataConfiguration[configIndex];

        const currentFieldConfig = currentFile.configuration;

        const chatFileOptions = EnumToDropdownOptionConversion(
            ChatFileSchemaHandlers,
        );

        const notSavedName = `File ${configIndex + 1}: Not Saved`;

        const savedString = `File ${configIndex + 1}: ${
            currentFile.createdBy
        } - ${new Date(
            currentFile.createdOn
                ? new Date(currentFile.createdOn).toLocaleString()
                : "N/A",
        ).toLocaleString()}`;

        if (
            nameChanged === false &&
            !currentFieldConfig.name &&
            currentFile.createdBy
        )
            currentFieldConfig.name = savedString;

        const header =
            nameChanged || currentFile.createdBy
                ? currentFieldConfig.name
                : notSavedName;

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setDateFormatHelperIcon(event.currentTarget);
        };
        const handleClose = () => {
            setDateFormatHelperIcon(null);
        };

        const dateFormatHelperOpen = Boolean(dateFormatHelperIcon);

        const agentCodeTooltipTable = (
            <table cellPadding={5} cellSpacing={1} border={1}>
                <tr>
                    <td></td>
                    <td>LooseMetadataCoupling Enabled</td>
                    <td>LooseMetadataCoupling Not Enabled</td>
                </tr>
                <tr>
                    <td>"Agent Code Required" is Checked</td>
                    <td>
                        For an Agent record to be associated to an interaction,
                        the Agent Code present in the metadata must already be
                        present within the tenant database. If no agent code is
                        present and the agent does not already exist, a new
                        agent record will not be created.
                    </td>
                    <td>
                        For a new Agent record to be created, an Agent Code must
                        be present within the metadata. If no agent code is
                        present and the agent does not already exist, the file
                        will fail to process.
                    </td>
                </tr>
                <tr>
                    <td>"Agent Code Required" is Unchecked</td>
                    <td>
                        A best match will be performed on existing Agents. If no
                        Agent is found, a new Agent record will be created with
                        what data is available.
                    </td>
                    <td>
                        A best match will be performed on existing Agents. If no
                        Agent is found, a new Agent record will be created with
                        what data is available.
                    </td>
                </tr>
            </table>
        );

        return (
            <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={isOpen}
                onChange={toggleAccordion}
                aria-controls="panel1d-config-content"
                id="panel1d-config-header"
            >
                <AccordionSummary>
                    <Grid container justifyContent="space-between">
                        <Typography className={classes.heading}>
                            {isOpen ? (
                                <KeyboardArrowDownIcon />
                            ) : (
                                <KeyboardArrowRightIcon />
                            )}
                            {header}
                        </Typography>
                        <Grid item>
                            {!!metadataStore.configErrors[configIndex] && (
                                <ErrorIcon color="error" />
                            )}
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.block}>
                    <Grid item xs={12}>
                        <Grid container item xs={12}>
                            <Grid item xs={12} className={classes.fieldSpacing}>
                                <AcxMainTextField
                                    fullWidth
                                    skeleton={metadataStore.getTaskLoading(
                                        LOAD_CONFIG,
                                    )}
                                    labelText="Configuration Name"
                                    id="name"
                                    value={header}
                                    onChange={handleNameUpdate}
                                />
                            </Grid>

                            <Grid item xs={2} className={classes.fieldSpacing}>
                                <AcxSelect
                                    id="metadataFileType"
                                    inputLabel="File Type"
                                    fullWidth
                                    defaultValue={metadataStore.organizationMetadataFileTypes.find(
                                        (option) =>
                                            option.value ===
                                            currentFieldConfig.metaDataFileType,
                                    )}
                                    options={
                                        metadataStore.organizationMetadataFileTypes ??
                                        []
                                    }
                                    onChange={handleMetadataFileTypeUpdate}
                                    placeholder="File Type"
                                />
                            </Grid>

                            <Grid item xs={2} className={classes.fieldSpacing}>
                                <AcxSelect
                                    id="metadataFileTypeExtension"
                                    inputLabel="File Type Extension"
                                    fullWidth
                                    defaultValue={metadataStore.organizationMetadataFileTypeExtensions.find(
                                        (option) =>
                                            option.value ===
                                            currentFieldConfig.metaDataFileTypeExtension,
                                    )}
                                    options={
                                        metadataStore.organizationMetadataFileTypeExtensions ??
                                        []
                                    }
                                    onChange={
                                        handleMetadataFileTypeExtensionUpdate
                                    }
                                    placeholder="File Type Extension"
                                />
                            </Grid>

                            <Grid item xs={3} className={classes.fieldSpacing}>
                                <AcxSelect
                                    id="ChatFileSchemaHandler"
                                    inputLabel="Chat File Schema Handler"
                                    fullWidth
                                    defaultValue={chatFileOptions.find(
                                        (item) =>
                                            item.value ===
                                            currentFieldConfig?.chatFileSchemaHandler,
                                    )}
                                    options={EnumToDropdownOptionConversion(
                                        ChatFileSchemaHandlers,
                                    )}
                                    onChange={handleChatFileSchemaHandlerUpdate}
                                    placeholder="Chat File Schema Handler"
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.fieldSpacing}>
                                <AcxMainTextField
                                    fullWidth
                                    skeleton={metadataStore.getTaskLoading(
                                        LOAD_CONFIG,
                                    )}
                                    labelText="Namespace"
                                    id="fieldNameSpace"
                                    value={currentFieldConfig?.nameSpace}
                                    onChange={handleNameSpaceUpdate}
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.fieldSpacing}>
                                <AcxMainTextField
                                    fullWidth
                                    skeleton={metadataStore.getTaskLoading(
                                        LOAD_CONFIG,
                                    )}
                                    labelText="Prefix"
                                    id="prefix"
                                    value={currentFieldConfig?.prefix}
                                    onChange={handlePrefixUpdate}
                                />
                            </Grid>

                            <Grid item xs={2} className={classes.fieldSpacing}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleIsDebugUpdate}
                                            id={`isDebug_${configIndex}`}
                                            name="isDebug"
                                            checked={
                                                currentFieldConfig.isDebug ??
                                                false
                                            }
                                        />
                                    }
                                    label="Is Debug"
                                    labelPlacement="end"
                                />
                            </Grid>

                            <Grid item xs={2} className={classes.fieldSpacing}>
                                <WideTooltip title={agentCodeTooltipTable}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={
                                                    handleAgentRequiredUpdates
                                                }
                                                id={`agentRequired${configIndex}`}
                                                name="agentRequired"
                                                checked={
                                                    currentFieldConfig.agentCodeRequired ??
                                                    false
                                                }
                                            />
                                        }
                                        label="Agent Code Required"
                                        labelPlacement="end"
                                    />
                                </WideTooltip>
                            </Grid>
                        </Grid>

                        <Card className={classes.metadataContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="textSecondary">
                                    Fields
                                </Typography>
                            </Grid>
                            {currentFieldConfig?.fields?.map((field, index) => (
                                <MetadataFieldRow
                                    key={field.id}
                                    id={field.id}
                                    configIndex={configIndex}
                                    store={metadataStore}
                                    currentIndex={index}
                                    fieldOptions={
                                        metadataStore.organizationMetadataFields
                                    }
                                />
                            ))}
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent={"flex-end"}
                                alignItems="center"
                            >
                                <AcxButton
                                    onClick={handleFieldAddition}
                                    style={{
                                        marginRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                    }}
                                    color="primary"
                                    tooltip={`Cannot have more than ${metadataStore.organizationMetadataFields?.length} fields`}
                                    disabled={
                                        metadataStore.getTaskLoading(
                                            LOAD_CONFIG,
                                        ) ||
                                        metadataStore.getTaskLoading(
                                            SET_CONFIG,
                                        ) ||
                                        currentFieldConfig.fields?.length ===
                                            metadataStore
                                                .organizationMetadataFields
                                                ?.length
                                    }
                                >
                                    Add
                                </AcxButton>
                            </Grid>
                        </Card>

                        <Card className={classes.metadataContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="textSecondary">
                                    Campaign Indicator Definitions
                                </Typography>
                            </Grid>
                            {currentFieldConfig?.campaignIndicatorDefinitions?.map(
                                (campaign, index) => (
                                    <MetadataCampaignRow
                                        key={campaign.id}
                                        id={campaign.id}
                                        configIndex={configIndex}
                                        store={metadataStore}
                                        currentIndex={index}
                                    />
                                ),
                            )}
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent={"flex-end"}
                                alignItems="center"
                            >
                                <AcxButton
                                    onClick={handleCampaignAddition}
                                    style={{
                                        marginRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                    }}
                                    color="primary"
                                >
                                    Add
                                </AcxButton>
                            </Grid>
                        </Card>

                        <Card className={classes.metadataContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="textSecondary">
                                    Lanugage Indicator Definitions
                                </Typography>
                            </Grid>
                            {currentFieldConfig?.languageIndicatorDefinitions?.map(
                                (language, index) => (
                                    <MetadataLanguageRow
                                        key={language.id}
                                        id={language.id}
                                        configIndex={configIndex}
                                        store={metadataStore}
                                        currentIndex={index}
                                    />
                                ),
                            )}
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent={"flex-end"}
                                alignItems="center"
                                style={{
                                    marginRight: theme.spacing(1),
                                    marginLeft: theme.spacing(1),
                                }}
                            >
                                <AcxButton
                                    onClick={handleLanguageAddition}
                                    color="primary"
                                >
                                    Add
                                </AcxButton>
                            </Grid>
                        </Card>

                        <Card className={classes.metadataContainer}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="textSecondary">
                                    Extended Fields
                                </Typography>
                            </Grid>
                            {currentFieldConfig?.extendedFields?.map(
                                (extendedField, index) => (
                                    <MetadataExtendedFieldRow
                                        key={extendedField.id}
                                        id={extendedField.id}
                                        configIndex={configIndex}
                                        store={metadataStore}
                                        currentIndex={index}
                                    />
                                ),
                            )}
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent={"flex-end"}
                                alignItems="center"
                            >
                                <AcxButton
                                    onClick={handleExtendedFieldAddition}
                                    color="primary"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                    }}
                                >
                                    Add
                                </AcxButton>
                            </Grid>
                        </Card>

                        <Card
                            className={classes.metadataContainer}
                            color="textSecondary"
                        >
                            <Grid item xs={12}>
                                <Typography variant="h5" color="textSecondary">
                                    Extended Association Fields
                                </Typography>
                            </Grid>
                            {currentFieldConfig.metaDataExtendedAssociationFields?.map(
                                (associationField, index) => (
                                    <MetadataExtendedAssociationFieldRow
                                        key={associationField.id}
                                        id={associationField.id}
                                        configIndex={configIndex}
                                        store={metadataStore}
                                        currentIndex={index}
                                    />
                                ),
                            )}
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent={"flex-end"}
                                alignItems="center"
                            >
                                <AcxButton
                                    onClick={
                                        handleExtendedAssociationFieldAddition
                                    }
                                    color="primary"
                                    style={{
                                        marginRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                    }}
                                >
                                    Add
                                </AcxButton>
                            </Grid>
                        </Card>
                        <Card
                            className={classes.metadataContainer}
                            color="textSecondary"
                        >
                            <Grid item xs={12}>
                                <Typography variant="h5" color="textSecondary">
                                    Media Segments
                                </Typography>
                            </Grid>
                            {currentFieldConfig.mediaSegments ? (
                                <MetadataMediaSegments
                                    store={metadataStore}
                                    mediaSegments={
                                        currentFieldConfig.mediaSegments
                                    }
                                    configIndex={configIndex}
                                />
                            ) : (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent={"flex-end"}
                                    alignItems="center"
                                >
                                    <AcxButton
                                        onClick={handleMediaSegmentsAdd}
                                        color="primary"
                                        style={{
                                            marginRight: theme.spacing(1),
                                            marginLeft: theme.spacing(1),
                                        }}
                                    >
                                        Add
                                    </AcxButton>
                                </Grid>
                            )}
                        </Card>
                        <Card
                            className={classes.metadataContainer}
                            color="textSecondary"
                        >
                            <Grid item container xs={12} alignItems="center">
                                <Typography variant="h5" color="textSecondary">
                                    Date Format Patterns
                                </Typography>
                                <IconButton onClick={handleClick} size="small">
                                    <HelpOutlineIcon fontSize="small" />
                                </IconButton>
                                <Popover
                                    open={dateFormatHelperOpen}
                                    anchorEl={dateFormatHelperIcon}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    <DateFormatHelperText />
                                </Popover>
                            </Grid>
                            <Grid container item xs={12}>
                                {!!currentFieldConfig.customDateFormatPatterns
                                    ?.length &&
                                    currentFieldConfig.customDateFormatPatterns.map(
                                        (
                                            dateFormatPattern,
                                            dateFormatIndex,
                                        ) => (
                                            <Grid
                                                item
                                                xs={3}
                                                className={classes.inputSpacing}
                                                key={dateFormatIndex}
                                            >
                                                <AcxMainTextField
                                                    fullWidth
                                                    skeleton={metadataStore.getTaskLoading(
                                                        LOAD_CONFIG,
                                                    )}
                                                    labelText="Date Format Pattern"
                                                    id={
                                                        "dateFormatPattern" +
                                                        dateFormatIndex
                                                    }
                                                    value={dateFormatPattern}
                                                    onChange={(event) =>
                                                        metadataStore.handleDateFormatPatternUpdates(
                                                            configIndex,
                                                            event.target.value,
                                                            dateFormatIndex,
                                                        )
                                                    }
                                                    showAllErrors={true}
                                                />
                                            </Grid>
                                        ),
                                    )}
                            </Grid>

                            <Grid
                                item
                                container
                                xs={12}
                                justifyContent="flex-end"
                            >
                                <IconButton
                                    onClick={() => {
                                        metadataStore.addDateFormatPattern(
                                            configIndex,
                                        );
                                    }}
                                    size="large"
                                >
                                    <AddIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        currentFieldConfig.customDateFormatPatterns!.pop();
                                    }}
                                    disabled={
                                        !currentFieldConfig
                                            .customDateFormatPatterns?.length
                                    }
                                    size="large"
                                >
                                    <RemoveIcon />
                                </IconButton>
                            </Grid>
                        </Card>
                        <IconButton
                            onClick={() =>
                                metadataStore.handleFileRemove(
                                    metadataStore
                                        .organizationMetadataConfiguration[
                                        configIndex
                                    ].id,
                                )
                            }
                            size="large"
                        >
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    },
);

export default MetadataConfiguration;
