import IAmdTimeline from "models/IAmdTimeline";
import PipelineFault from "models/PipelineFault";
import ProcessFailure from "models/ProcessFailure";
import { Moment } from "moment";
import BaseService from "services/BaseService";
import { serializeToUtc } from "utils/DateTimeUtils";

export class PipelineService extends BaseService {
    protected urlBase: string = "api/pipeline";

    public async getTimelineByAMD(
        orgId: string,
        amdId: string,
    ): Promise<IAmdTimeline> {
        const params = new URLSearchParams({
            orgId: orgId,
            amdId: amdId,
        });
        const res = await this.get(params, "Timeline/GetTimelineByAMDId");
        return res;
    }

    public async getFailuresByOrg(
        orgId: string,
        beginDate: Moment,
        endDate: Moment,
    ) {
        const params = new URLSearchParams({
            orgId: orgId,
            beginDate: serializeToUtc(beginDate)!,
            endDate: serializeToUtc(endDate)!,
        });
        const res = await this.get(params, "Faults/GetProcessFailures");
        return res;
    }

    public async requeueFailures(orgId: string, failures: ProcessFailure[]) {
        const params = new URLSearchParams({ orgId: orgId });
        const res = await this.delete(
            params,
            "Faults/RequeueFailures",
            JSON.stringify(failures),
        );
        return res;
    }
    
    public async reprocessFailures(orgId: string, failures: ProcessFailure[], location: string) {
        const params = new URLSearchParams({ orgId: orgId, requestedRequeueContractName: location });
        const res = await this.delete(
            params,
            "Faults/ReprocessFailures",
            JSON.stringify(failures),
        );
        return res;
    }

    public async getFaults() {
        const params = new URLSearchParams({});
        const res = await this.get(params, "Faults/GetAllFaults");
        return res;
    }

    public async getRbcsToTsQueryFailures() {
        const params = new URLSearchParams({});
        const res = await this.get(params, "RBCTsQueryStatus/GetInvalidRBCs");
        return res;
    }

    public async getFaultsByOrg(
        orgId: string,
        beginDate: Moment,
        endDate: Moment,
    ) {
        const params = new URLSearchParams({
            orgId: orgId,
            beginDate: beginDate.format(),
            endDate: endDate.format(),
        });
        const res = await this.get(params, "Faults/GetFaultsByOrganization");
        return res;
    }

    public async getFaultsByOrgandFailure(
        orgId: string,
        failure: ProcessFailure,
    ) {
        const params = new URLSearchParams({
            orgId: orgId,
            amdId: failure.id,
        });
        const res = await this.get(params, "Faults/GetPipelineFaultByAmdId");
        return res;
    }

    public async requeueFaults(orgId: string, faults: PipelineFault[]) {
        const params = new URLSearchParams({ orgId: orgId });
        const res = await this.delete(
            params,
            "Faults/RequeueFaults",
            JSON.stringify(faults),
        );
        return res;
    }

    public async getInteractionsByOrg(
        orgId: string,
        beginDate: Moment,
        endDate: Moment,
    ) {
        const params = new URLSearchParams({
            orgId: orgId,
            beginDate: serializeToUtc(beginDate)!,
            endDate: serializeToUtc(endDate)!,
        });
        const res = await this.get(
            params,
            "SyntheticInteraction/GetInteractionsByOrganization",
        );
        return res;
    }

    public async pollOpsDatabase(orgId: string) {
        const params = new URLSearchParams({
            orgId: orgId,
        });
        await this.get(params, "SyntheticInteraction/PollOpsDatabase");
    }

    public async uploadInteractions(orgId: string, fileTypes: string) {
        const params = new URLSearchParams({
            orgId: orgId,
            fileTypes: fileTypes,
        });
        await this.get(params, "SyntheticInteraction/UploadInteractions");
    }
}
