import React from "react";
import { ThemeProvider, Theme, StyledEngineProvider, CssBaseline, Typography } from "@mui/material";
import AppTheme from "Theme/AppTheme";
import SplashPage from "./SplashPage";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function LoginCallback() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
                <CssBaseline />
                <SplashPage title="Loading">
                    <Typography></Typography>
                </SplashPage>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default LoginCallback;
