import BaseService from "./BaseService";

export class NotificationAcknowledgeService extends BaseService {
    protected urlBase: string = "api/NotificationAcknowledge";

    public acknowledge = async (
        notificationType: string,
        parameters: object,
    ) => {
        try {
            const params = new URLSearchParams({ ...parameters });
            await this.put(new URLSearchParams(params), notificationType);
        } catch (e) {
            console.error(`failed to acknowledge notification ${e}`);
        }
    };
}
