import { Container } from "@mui/material";
import {
    Extension,
    FileCopy,
    FindInPage,
    FormatClear,
    GraphicEq,
    Layers,
    LeakRemove,
    MusicVideo,
    Receipt,
    RecordVoiceOver,
    School,
} from "@mui/icons-material";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import IAmdTimeline from "models/IAmdTimeline";
import IAmdTimelineEvent from "models/IAmdTimelineEvent";
import React from "react";
import { PipelineService } from "services/PipelineService";
import { AuthStore } from "stores/AuthStore";
import { extractGuid } from "utils/StringUtils";
import { useStore } from "utils/useStore";
import AcxLoadingIndicator from "../AcxLoadingIndicator";
import Timeline, { TimelineItem } from "./Timeline";

const microserviceIconMap = {
    "File Pre Processor": <FileCopy />,
    "Text Metadata Processor": <FindInPage />,
    "Audio Video Metadata Processor": <MusicVideo />,
    "Extended Metadata Processor": <Extension />,
    "Audio Media Processor": <GraphicEq />,
    "Transcribe Media Processor": <RecordVoiceOver />,
    "RBC Classifier": <School />,
    "Redact Transcription": <FormatClear />,
    "Redact Audio": <LeakRemove />,
    "Index Transcription": <Receipt />,
    "Transcription Batch Producer": <Layers />,
};

interface AmdTimelineProps {
    amdId: string;

    /**
     *
     */
    timeline?: IAmdTimeline;

    /**
     * If true will display the amd's file name at the top of the timeline
     */
    displayFileName?: boolean;
}

function amdTimelineEventsToRenderableItems(
    events: IAmdTimelineEvent[],
): TimelineItem[] {
    return events.map((item) => {
        let color = item.isStartOfMicroservice
            ? "grey"
            : item.success
            ? "green"
            : "red";

        const variant = item.event === null ? "default" : "outlined";

        const time = new Date(item.time).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            day: "numeric",
            month: "short",
            year: "numeric",
            hour12: true,
        });

        let content: string;
        if (item.event) content = item.event;
        else
            content = `${item.microservice} ${
                item.success
                    ? item.isStartOfMicroservice
                        ? "Started"
                        : "Succeeded"
                    : "Failed"
            }`;

        return {
            color,
            variant,
            time,
            icon: microserviceIconMap[item.microservice] ?? <FileCopy />,
            content,
            contentHeader: item.event ? item.microservice : undefined,
        };
    });
}

export default function AmdTimeline({
    amdId,
    timeline,
    displayFileName = true,
}: AmdTimelineProps) {
    const messageStore = useStore(MessageStore);
    const authStore = useStore(AuthStore);
    const pipelineService = React.useMemo(() => new PipelineService(), []);

    const [timelineItems, setTimelineItems] = React.useState<TimelineItem[]>(
        timeline?.timelineEvents
            ? amdTimelineEventsToRenderableItems(timeline.timelineEvents)
            : [],
    );
    const [fileName, setFileName] = React.useState<string | undefined>(
        timeline?.fileName,
    );
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        // If user passed in their own events, dont attempt to fetch
        // the timeline
        if (timeline?.timelineEvents && timeline.timelineEvents.length > 0)
            return;
        // Dont want to perform fetches on empty amdId or non guid amdId
        if (!amdId || amdId === "" || extractGuid(amdId) === null) return;
        (async function () {
            const orgId = authStore.orgStore.selectedOrganization?.id;
            if (!orgId) return;

            setIsLoading(true);
            try {
                const timeline = await pipelineService.getTimelineByAMD(
                    orgId,
                    amdId,
                );
                setFileName(timeline.fileName);
                setTimelineItems(
                    amdTimelineEventsToRenderableItems(timeline.timelineEvents),
                );
            } catch (error) {
                messageStore.logError(
                    "Unable to fetch timeline for this AMD. Please retry.",
                );
            }
            setIsLoading(false);
        })();
    }, [timeline, amdId, authStore, pipelineService, messageStore]);

    return (
        <>
            {isLoading && (
                <AcxLoadingIndicator size={64} alternate="PuffLoader" />
            )}
            {!isLoading && (
                <Container>
                    <Timeline
                        items={timelineItems}
                        header={displayFileName && fileName}
                    />
                </Container>
            )}
        </>
    );
}
