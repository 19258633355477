import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React, { useCallback } from "react";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { Grid } from "@mui/material";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import Classifier from "models/ClassifierModel";

export const ClassifierChips = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const applicationFilterValuesStore = useStore(ApplicationFilterValuesStore);
    const messageStore = useStore(MessageStore);

    const classifierIds = applicationFiltersStore.rbcFilterItems.flatMap(
        (item) => item.classifierIds,
    );

    if (classifierIds.length === 0) return null;

    const classifiers = classifierIds.map((id) =>
        applicationFilterValuesStore.userClassifiers?.find(
            (classifier) => classifier.id === id,
        ),
    );

    const isClassifiersLoading = applicationFilterValuesStore.getTaskLoading(
        ApplicationFilterValuesStore.Tasks.LOAD_USER_CLASSIFIERS,
    );

    const removeClassifier = useCallback(
        (classifier?: Classifier) => {
            let success;
            if (!classifier) success = false;
            else
                success = applicationFiltersStore.removeClassifierById(
                    classifier.id,
                );

            if (!success)
                messageStore.logError(
                    "Error removing classifier. Classifier not found.",
                );
        },
        [messageStore, applicationFiltersStore],
    );

    if (isClassifiersLoading)
        return classifierIds.map(() => (
            <AppliedFilterChip
                disabled={props.disabled}
                isApplicable={props.isApplicable}
            >
                <Grid
                    container
                    direction="row"
                    flexWrap="nowrap"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>Classifier:</Grid>
                    <Grid item>
                        <AcxLoadingIndicator size={16} alternate="PuffLoader" />
                    </Grid>
                </Grid>
            </AppliedFilterChip>
        ));

    return classifiers.map((classifier) => (
        <AppliedFilterChip
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={
                !classifier ? undefined : () => removeClassifier(classifier)
            }
        >
            Classifier: {classifier?.name}
        </AppliedFilterChip>
    ));
});
