import RBCFilterInput from "components/Conversations/Views/Drawer/components/DeepFilter/RBCFilterInput";
import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";

export const ClassifiersFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    return <RBCFilterInput store={props.store.rbcFilterStore} />;
});
