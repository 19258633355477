import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";

export const CallDurationChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    return (
        <AppliedFilterChip
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={() => applicationFiltersStore.setCallDuration([])}
        >
            Call Duration: {applicationFiltersStore.minCallDuration ?? 0}-
            {applicationFiltersStore.maxCallDuration ?? 0} min
        </AppliedFilterChip>
    );
});
