import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Paper,
    Slider,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { observer } from "mobx-react";
import { PipelineProcessTypes } from "models/DataProcesses";
import React, { useEffect } from "react";
import { useQuill } from "react-quilljs";
import { FormFieldErrors } from "shared/types/FormErrors.type";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import useDataProcessingOptions from "../../hooks/useDataProcessingOptions";
import {
    DbConnectionStatusType,
    DB_CONNECTION_STATUS,
} from "../../hooks/useOrganization";
import { Organization } from "../../types/Organization.type";
import useOrganizationUserNames from "../../hooks/useOrganizationUserNames";

const styles = (theme: Theme) => {
    return createStyles({
        formContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
        error: {
            fontFamily: "Inter", // make Inter due to italics
            color: theme.palette.error.main,
            fontSize: "12px",
            lineHeight: "16px",
        },
        heading: {
            fontSize: theme.typography.pxToRem(16),
            flexBasis: "60%",
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
        },
        valueLabel: {
            "& *": {
                background: "transparent",
            },
        },
        voiceRedactionFactorLabel: {
            marginTop: "20px",
        },
        paddingRight: {
            paddingRight: "0.8rem!important",
        },
    });
};

type Props = {
    updateOrg: (
        val: string | string[] | boolean | number,
        prop: keyof Organization,
    ) => void;
    isLoading: boolean;
    org?: Organization | null;
    fieldErrors?: FormFieldErrors;
    formErrors?: string[];
    updateContainerName: (val?: string | number) => void;
    checkDbConnection: () => void;
    dbConnectionStatus: DbConnectionStatusType;
};

const timeoutOptions = [
    {
        label: "2 Minutes",
        value: 2 * 60 * 1000,
    },
    {
        label: "5 Minutes",
        value: 5 * 60 * 1000,
    },
    {
        label: "10 Minutes",
        value: 10 * 60 * 1000,
    },
    {
        label: "15 Minutes",
        value: 15 * 60 * 1000,
    },
    {
        label: "30 Minutes",
        value: 30 * 60 * 1000,
    },
    {
        label: "1 Hour",
        value: 60 * 60 * 1000,
    },
    {
        label: "4 Hours",
        value: 60 * 60 * 1000 * 4,
    },
];

const processOptions = [
    {
        label: "Automatic",
        value: PipelineProcessTypes.Automatic,
    },
    {
        label: "On Demand",
        value: PipelineProcessTypes.OnDemand,
    },
];

const processOptionsTooltips = {
    Automatic:
        "Automatic: Interaction files are processed, transcribed, and receive classifier results upon receipt of the interaction",
    "On Demand":
        "On Demand: Interaction files are processed, transcribed, and receive classifier results upon sampling the interaction",
};

const dataProcessingTooltips = {
    Transcription: "Transcription: Enables transcription",
    Classification: "Classification: Enables MLs and RBCs to collect results",
    HumanAnalysis: "HumanAnalysis: Enables BOTF evaluations for a tenant",
    AddressRedaction: "AddressRedaction: Redacts Addresses from interactions",
    // CC not defined, default to label
    NameRedaction: "NameRedaction: Redacts Names from interactions",
    // Date not defined, default to label
    // Phone not defined, default to label
    Redaction:
        "Redaction: Enables redaction for a tenant. Must be selected in order for any specific redaction options to work.",
    MiddleOfTheFunnel:
        "MiddleOfTheFunnel: Enables MOTF evaluations for a tenant",
    VoiceRedaction:
        'VoiceRedaction: Obfuscates voices in audio interactions by allowing the tenant to specify level of obfuscation with the "Voice Redaction Factor" scale',
    SSNRedaction:
        "SSNRedaction: Redacts Social Security Numbers from interactions",
    LooseMetadataCoupling:
        "LooseMetadataCoupling: Enables a client to have metadata processing specs at the organization level while also processing call files with no metadata. Files will continue processing to success even without metadata being added.",
    ClassifierValidation:
        "ClassifierValidation: Not Used - Enables the 'Listen For' module on Evaluations",
    RaiseAudioByOctave:
        "RaiseAudioByOctave: Obfuscates voices in audio interactions by decreasing the pitch of all voices by one octave",
    LowerAudioByOctave:
        "LowerAudioByOctave: Obfuscates voices in audio interactions by increasing the pitch of all voices by one octave",
    BypassAgentOsmAssignment:
        "BypassAgentOSMAssignment : Enabling this processing option tells the pipeline to NOT use Agent Hierarchy Relationships to assign a Hierarchy to an AMD",
    EnableScreenRecordings:
        "EnableScreenRecordings : Enabling this is what allows Screen Recordings to exist for an Organization",
    BypassScreenRecordingRequiredForEvaluation:
        "BypassScreenRecordingRequiredForEval : Enabling this allows for evaluations to exist without a corresponding ScreenRecording being found - cannot be used without EnableScreenRecordings",
};

// tooltip values to match labels for org details
const orgDetailTooltips = {
    name: "Name of the organization",
    dbConnectionString:
        "String that connects the clients database to the Authenticx Platform",
    dbVersionString: "Clients version database to the Authenticx Platform",
    blobContainer: `Name of the blob folder within the Storage Account "interactionfilesinstage" that call files will land in when initially uploaded to the Authenticx Platform (either via SFTP or API)`,
    pgpKeyName: "The key Authenticx uses to decrypt any PGP files received",
    maxIdleDuration:
        "How long a browser window can sit idle before being automatically logged out",
    motfAnalystName: `The name in the Analyst field for MOTF evaluations. By default, this will be "AcxMachine"`,
    searchService:
        "Name of the index in the Azure service that enables the Index search",
    searchServiceIndex:
        "The index file that indexes transcriptions in the indexable directory",
    eddyHourlyRate: "The hourly rate used to calculate Eddy Cost",
    agentAck:
        "Number of business days before agent can no longer acknowledge the evaluation. Weekends and standard US Holidays will not be counted as business days",
    voiceRedaction: `The level of redaction that is applied to Audio Interactions if "Redaction" and "Voice Redaction" are enabled under Data Processing Options`,
    enhancedModuleView:
        "Enables an additional column in the Interactions Domain that shows which modules within an evaluation are still incomplete.",
    enhancedPermissions:
        "Enables enhanced role permissions to provide additional flexibility in role configuration. This should always be enabled.",
    emailFooterPlain:
        "A custom footer added to all emails sent from the Authenticx Platform to the clients users. Plain text should be utilized when the client has plain text emails enabled for their employees.",
    emailFooterHtml:
        "A custom footer added to all emails sent from the Authenticx Platform to the clients users. HTML is the default format for most emails.",
};

const OrganizationDetails: React.FC<Props> = observer(
    ({
        updateOrg,
        isLoading,
        org,
        fieldErrors,
        formErrors,
        updateContainerName,
        checkDbConnection,
        dbConnectionStatus,
    }) => {
        const authStore = useStore(AuthStore);
        const classes = useStyles(styles);
        const { data: dpOptions, isLoading: dpIsLoading } =
            useDataProcessingOptions();

        const { users, isLoading: usersLoading } = useOrganizationUserNames(
            org!.id! as string,
        );

        const handleTimeoutChange = (arg: { label: string; value: number }) => {
            if (org) {
                updateOrg(arg?.value as any, "timeoutDuration");

                if (authStore.orgStore?.selectedOrganization) {
                    authStore.orgStore.selectedOrganization.timeoutDuration =
                        arg?.value;
                }
            }
        };

        const modules = {
            toolbar: [
                ["bold", "italic", "underline", "strike", "code"],
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ indent: "-1" }, { indent: "+1" }],
                ["link", "image"],
                [{ color: [] }, { background: [] }],
                [{ size: ["small", false, "large", "huge"] }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ],
        };

        const { quill, quillRef } = useQuill({ modules });

        const handleProcessOptionChange = (arg: {
            label: string;
            value: number;
        }) => {
            if (org) {
                updateOrg(arg?.value as any, "pipelineProcessingOption");
            }
        };

        const [voiceRedactionFactorValue, setVoiceRedactionFactorValue] =
            React.useState<number | undefined>(org?.voiceRedactionFactor);

        const handlevoiceRedactionFactorValueChange = (
            event: any,
            newValue: number | number[],
        ) => {
            setVoiceRedactionFactorValue(newValue as number);
            updateOrg(newValue as number, "voiceRedactionFactor");
        };

        useEffect(() => {
            if (
                (org?.voiceRedactionFactor ||
                    org?.voiceRedactionFactor === 0) &&
                (voiceRedactionFactorValue === undefined || null) &&
                org?.id
            )
                setVoiceRedactionFactorValue(org?.voiceRedactionFactor);
            else if (org?.id && !org?.voiceRedactionFactor)
                setVoiceRedactionFactorValue(0);
        }, [org?.id, org?.voiceRedactionFactor, voiceRedactionFactorValue]);

        useEffect(() => {
            if (quill && org?.customEmailFooterHtml) {
                quill.clipboard.dangerouslyPasteHTML(
                    org?.customEmailFooterHtml,
                );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [org?.id, quill]);

        useEffect(() => {
            if (quill) {
                quill.on("text-change", () => {
                    updateOrg(quill.root.innerHTML, "customEmailFooterHtml");
                });
            }
        }, [quill, updateOrg]);

        useEffect(() => {
            if (dbConnectionStatus === DB_CONNECTION_STATUS.ERROR) {
                updateOrg(true, "usesCoreDatabase");
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dbConnectionStatus]);

        return (
            <Paper elevation={1} className={classes.formContainer}>
                <Typography variant="h5">Organization Details</Typography>
                <Typography variant="caption">
                    {org?.id ? "Edit organization details" : "Add organization"}
                </Typography>
                <Grid container spacing={1} style={{ paddingTop: "12px" }}>
                    <Grid container item spacing={1} wrap="wrap">
                        <Tooltip title={orgDetailTooltips.name} arrow>
                            <Grid item xs={12}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    labelText="Name"
                                    id="name"
                                    value={org?.name}
                                    isDisabled={
                                        org?.id ? org?.name === "Authcx" : false
                                    }
                                    onChange={(e) => {
                                        if (
                                            org?.id &&
                                            e.target.value === "Authcx"
                                        ) {
                                            e.target.value = "";
                                        }
                                        updateOrg(e.target.value, "name");
                                    }}
                                    error={!!fieldErrors?.name}
                                    helperText={fieldErrors?.name}
                                    showAllErrors={true}
                                    onBlur={updateContainerName}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip>
                        <Tooltip
                            title={orgDetailTooltips.dbConnectionString}
                            arrow
                        >
                            <Grid item xs={9}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    labelText="Database Connection"
                                    id="databaseConnectionString"
                                    value={org?.databaseConnectionString}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "databaseConnectionString",
                                        )
                                    }
                                    error={
                                        !!fieldErrors?.databaseConnectionString
                                    }
                                    showAllErrors={true}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip>
                        <Tooltip
                            title={orgDetailTooltips.dbVersionString}
                            arrow
                        >
                            <Grid item xs={3}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    labelText="Database Version"
                                    id="databaseVersionString"
                                    value={org?.databaseVersion}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "databaseVersion",
                                        )
                                    }
                                    error={!!fieldErrors?.databaseVersion}
                                    showAllErrors={true}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip>
                        <Grid item xs={6}>
                            <Tooltip
                                title={orgDetailTooltips.motfAnalystName}
                                arrow
                            >
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "4px" }}
                                >
                                    <AcxMainTextField
                                        skeleton={isLoading}
                                        onChange={(e) =>
                                            updateOrg(
                                                e.target.value,
                                                "middleOfTheFunnelAnalystName",
                                            )
                                        }
                                        id="middleOfTheFunnelAnalystName"
                                        labelText="Middle of the Funnel Analyst Name"
                                        value={
                                            org?.middleOfTheFunnelAnalystName
                                        }
                                        error={
                                            !!fieldErrors?.middleOfTheFunnelAnalystName
                                        }
                                        helperText={
                                            fieldErrors?.middleOfTheFunnelAnalystName
                                        }
                                        showAllErrors={true}
                                        infoText="The name of the analyst shown for MOTF evaluations. Sets Defaults to Acx Machine if not specified."
                                        disableTooltip
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                        <Tooltip title={orgDetailTooltips.blobContainer} arrow>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "blobContainer",
                                        )
                                    }
                                    id="blobContainer"
                                    labelText="Blob Container"
                                    value={org?.blobContainer}
                                    error={!!fieldErrors?.blobContainer}
                                    helperText={fieldErrors?.blobContainer}
                                    showAllErrors={true}
                                    // readonly={!!org?.id}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip>
                        <Tooltip title={orgDetailTooltips.pgpKeyName} arrow>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(e.target.value, "pgpKeyName")
                                    }
                                    id="pgpKeyName"
                                    labelText="Pgp Key Name"
                                    value={org?.pgpKeyName}
                                    error={!!fieldErrors?.pgpKeyName}
                                    helperText={fieldErrors?.pgpKeyName}
                                    showAllErrors={true}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip>
                        <Grid item xs={6} className={classes.paddingRight}>
                            <AcxSelect
                                inputLabel="Pipeline Processing Option"
                                defaultValue={processOptions.find((option) => {
                                    return (
                                        option.value ===
                                        org?.pipelineProcessingOption
                                    );
                                })}
                                id="pipeline-processing-option-selector"
                                labelField="label"
                                valueField="value"
                                tooltips={processOptionsTooltips} // hardcoded
                                options={processOptions} // hardcoded
                                onChange={handleProcessOptionChange}
                                placeholder="Select Pipeline Processing Option"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.paddingRight}>
                            <AcxInputLabel labelText="Range of Interactions Expected Weekly" />
                            <Grid
                                item
                                xs={2}
                                display={"inline-block"}
                                minWidth={"70px"}
                            >
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "weeklyInteractionsMin",
                                        )
                                    }
                                    type="number"
                                    id="weeklyInteractionsMin"
                                    value={org?.weeklyInteractionsMin}
                                    error={!!fieldErrors?.weeklyInteractionsMin}
                                    helperText={
                                        fieldErrors?.weeklyInteractionsMin
                                    }
                                    showAllErrors={true}
                                    disableTooltip
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                display={"inline-block"}
                                marginRight={"0.5rem"}
                            >
                                To
                            </Grid>
                            <Grid
                                item
                                xs={2}
                                display={"inline-block"}
                                minWidth={"70px"}
                            >
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "weeklyInteractionsMax",
                                        )
                                    }
                                    type="number"
                                    id="weeklyInteractionsMax"
                                    value={org?.weeklyInteractionsMax}
                                    error={!!fieldErrors?.weeklyInteractionsMax}
                                    helperText={
                                        fieldErrors?.weeklyInteractionsMax
                                    }
                                    showAllErrors={true}
                                    disableTooltip
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} className={classes.paddingRight}>
                            <AcxSelectMulti
                                id="weeklyInteractionAlertUserIds"
                                options={users ?? []}
                                skeleton={isLoading || usersLoading}
                                defaultValue={org?.weeklyInteractionAlertUserIds?.map(
                                    (id) =>
                                        users?.find((user) => id === user.id),
                                )}
                                onChange={(e) =>
                                    updateOrg(
                                        e
                                            ? e.map((user) => user?.id || "")
                                            : [],
                                        "weeklyInteractionAlertUserIds",
                                    )
                                }
                                valueField={"id"}
                                labelField={"userName"}
                                containerHeight="auto"
                                fullWidth
                                isClearable
                                inputLabel="User(s) to alert if interaction count out of range"
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.paddingRight}>
                            <AcxSelectMulti
                                id="dataProcessingOptions"
                                options={dpOptions ? dpOptions : []}
                                valueField="id"
                                labelField="processingOption"
                                tooltips={dataProcessingTooltips} // hardcoded
                                inputLabel="Data Processing Options"
                                skeleton={isLoading || dpIsLoading}
                                defaultValue={org?.dataProcessingOptions?.map(
                                    (opt) =>
                                        dpOptions?.find(
                                            (opt2) =>
                                                opt === opt2.processingOption,
                                        ),
                                )}
                                onChange={(e) =>
                                    updateOrg(
                                        e
                                            ? e.map(
                                                  (opt) =>
                                                      opt?.processingOption ||
                                                      "",
                                              )
                                            : [],
                                        "dataProcessingOptions",
                                    )
                                }
                                containerHeight="auto"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.paddingRight}>
                            <Tooltip
                                title={orgDetailTooltips.maxIdleDuration}
                                arrow
                            >
                                <Grid item xs={12}>
                                    <AcxSelect
                                        inputLabel="Max Idle Duration"
                                        fullWidth={true}
                                        defaultValue={timeoutOptions.find(
                                            (option) => {
                                                return (
                                                    option.value ===
                                                    org?.timeoutDuration
                                                );
                                            },
                                        )}
                                        id="idle-duration-timeout-selector"
                                        labelField="label"
                                        valueField="value"
                                        options={timeoutOptions}
                                        onChange={handleTimeoutChange}
                                        placeholder="Select Maximum Idle Time"
                                    />
                                </Grid>
                            </Tooltip>
                        </Grid>
                        {/* <Tooltip title={orgDetailTooltips.searchService} arrow>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "searchService",
                                        )
                                    }
                                    id="searchService"
                                    labelText="Search Service"
                                    value={org?.searchService}
                                    error={!!fieldErrors?.searchService}
                                    helperText={fieldErrors?.searchService}
                                    showAllErrors={true}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip> */}
                        {/* <Tooltip
                            title={orgDetailTooltips.searchServiceIndex}
                            arrow
                        >
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "searchServiceIndex",
                                        )
                                    }
                                    id="searchServiceIndex"
                                    labelText="Search Service Index"
                                    value={org?.searchServiceIndex}
                                    error={!!fieldErrors?.searchServiceIndex}
                                    helperText={fieldErrors?.searchServiceIndex}
                                    showAllErrors={true}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip> */}
                        <Tooltip title={orgDetailTooltips.eddyHourlyRate} arrow>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "eddyHourlyRate",
                                        )
                                    }
                                    type="number"
                                    id="eddyHourlyRate"
                                    labelText="Eddy Hourly Rate"
                                    value={org?.eddyHourlyRate}
                                    error={!!fieldErrors?.eddyHourlyRate}
                                    helperText={fieldErrors?.eddyHourlyRate}
                                    showAllErrors={true}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip>
                        <Tooltip title={orgDetailTooltips.agentAck} arrow>
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "evalAcknowledgeDays",
                                        )
                                    }
                                    type="number"
                                    id="evaluation-acknowledge-days-input"
                                    labelText="Agent Evaluation Acknowledgement"
                                    placeholderText="Enter number of business days…"
                                    value={org?.evalAcknowledgeDays}
                                    error={!!fieldErrors?.evalAcknowledgeDays}
                                    helperText={
                                        fieldErrors?.evalAcknowledgeDays
                                    }
                                    showAllErrors={true}
                                    disableTooltip
                                    insertBlankIfNumber
                                />
                            </Grid>
                        </Tooltip>
                        {voiceRedactionFactorValue !== undefined &&
                            voiceRedactionFactorValue !== null && (
                                <Grid item xs={6}>
                                    <Tooltip
                                        title={orgDetailTooltips.voiceRedaction}
                                        arrow
                                    >
                                        <Typography
                                            color={"textSecondary"}
                                            className={
                                                classes.voiceRedactionFactorLabel
                                            }
                                            gutterBottom
                                        >
                                            Voice Redaction Factor
                                        </Typography>
                                    </Tooltip>
                                    <Slider
                                        value={voiceRedactionFactorValue}
                                        onChange={
                                            handlevoiceRedactionFactorValueChange
                                        }
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        step={0.01}
                                        min={-0.1}
                                        max={0.1}
                                        color="secondary"
                                        classes={{
                                            valueLabel: classes.valueLabel,
                                        }}
                                    />
                                </Grid>
                            )}

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            updateOrg(
                                                e.target.checked,
                                                "isActive",
                                            )
                                        }
                                        id="isActive"
                                        name={"isActive"}
                                        checked={org?.isActive || false}
                                        disabled={isLoading}
                                    />
                                }
                                label="Is Active"
                            />
                            <FormControlLabel
                                control={
                                    dbConnectionStatus ===
                                    DB_CONNECTION_STATUS.LOADING ? (
                                        <Checkbox
                                            onChange={(e) => {
                                                updateOrg(
                                                    e.target.checked,
                                                    "usesCoreDatabase",
                                                );
                                                checkDbConnection();
                                            }}
                                            id="useCoreDatabase"
                                            name={"useCoreDatabase"}
                                            checked={
                                                org?.usesCoreDatabase || false
                                            }
                                            disabled={
                                                isLoading ||
                                                dbConnectionStatus ===
                                                    DB_CONNECTION_STATUS.LOADING
                                            }
                                            icon={
                                                <CircularProgress size={14} />
                                            }
                                        />
                                    ) : (
                                        <Checkbox
                                            onChange={(e) => {
                                                updateOrg(
                                                    e.target.checked,
                                                    "usesCoreDatabase",
                                                );
                                                checkDbConnection();
                                            }}
                                            id="useCoreDatabase"
                                            name={"useCoreDatabase"}
                                            checked={
                                                org?.usesCoreDatabase || false
                                            }
                                            disabled={isLoading}
                                        />
                                    )
                                }
                                label={
                                    dbConnectionStatus ===
                                    DB_CONNECTION_STATUS.LOADING
                                        ? "Checking DB Connection"
                                        : dbConnectionStatus ===
                                          DB_CONNECTION_STATUS.ERROR
                                        ? "⚠️  Could Not Connect to DB, try again"
                                        : "Use Core Database"
                                }
                            />
                            {authStore.isUserUltra() && (
                                <Tooltip
                                    title={
                                        orgDetailTooltips.enhancedPermissions
                                    }
                                    arrow
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={(e) =>
                                                    updateOrg(
                                                        e.target.checked,
                                                        "enhancedPermissionStatus",
                                                    )
                                                }
                                                id="enhancedPermissionStatus"
                                                name={
                                                    "enhancedPermissionStatus"
                                                }
                                                checked={
                                                    org?.enhancedPermissionStatus ||
                                                    false
                                                }
                                                disabled={isLoading}
                                            />
                                        }
                                        label="Enhanced Permissions"
                                    />
                                </Tooltip>
                            )}
                        </Grid>
                        <Tooltip
                            title={orgDetailTooltips.emailFooterPlain}
                            arrow
                        >
                            <Grid item xs={6}>
                                <AcxMainTextField
                                    skeleton={isLoading}
                                    onChange={(e) =>
                                        updateOrg(
                                            e.target.value,
                                            "customEmailFooterPlainText",
                                        )
                                    }
                                    id="customEmailFooterPlainText"
                                    labelText="Email Footer (plain text)"
                                    value={org?.customEmailFooterPlainText}
                                    showAllErrors={true}
                                    multiline={true}
                                    rows={15}
                                    disableTooltip
                                />
                            </Grid>
                        </Tooltip>
                        <Tooltip
                            title={orgDetailTooltips.emailFooterHtml}
                            arrow
                        >
                            <Grid item xs={6} style={{ height: "271px" }}>
                                <AcxInputLabel labelText="Email Footer (HTML)" />
                                <div ref={quillRef} />
                            </Grid>
                        </Tooltip>

                        <Grid item xs={12}>
                            {formErrors?.map((err, i) => (
                                <AcxInputLabel
                                    key={i}
                                    className={clsx(classes.error)}
                                >
                                    {err}
                                </AcxInputLabel>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    },
);

export default OrganizationDetails;
