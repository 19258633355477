export const getAmplitudeScaleFromVolume = (volume: number) => {
    if (volume < 0.01) {
        return 0.0;
    } else if (volume < 0.1) {
        return 0.1;
    } else if (volume < 0.2) {
        return 0.25;
    } else if (volume < 0.3) {
        return 0.5;
    } else if (volume < 0.4) {
        return 0.75;
    } else if (volume < 0.5) {
        return 1.0;
    } else if (volume < 0.6) {
        return 1.5;
    } else if (volume < 0.7) {
        return 2.0;
    } else if (volume < 0.8) {
        return 3.0;
    } else if (volume < 0.9) {
        return 4.0;
    } else {
        return 5.0;
    }
};
