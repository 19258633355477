import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxToggleButton from "components/UI/AcxToggleButton";
import AcxDrawer from "components/UI/Drawer/AcxDrawer";
import { observer } from "mobx-react";
import React from "react";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import { useStore } from "utils/useStore";
import OrganizationWorkflowsCreateConditionForm from "./Components/OrganizationWorkflowsCreateConditionForm";
import OrganizationWorkflowsEmailEditor from "./Components/OrganizationWorkflowsEmailEditor";
import OrganizationWorkflowsActionBar from "./OrganizationWorkflowsActionBar";
import OrganizationWorkflowsListConditions from "./OrganizationWorkflowsListConditions";
import OrganizationWorkflowsListEmails from "./OrganizationWorkflowsListEmails";
import OrganizationWorkflowEmailStore from "./Stores/OrganizationWorkflowEmailStore";
import OrganizationWorkflowsStore, {
    LOAD_CONDITIONS,
} from "./Stores/OrganizationWorkflowsStore";

export enum OrganizationWorkflowViews {
    "Workflow Conditions" = 0,
    "Email Templates" = 1,
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: "2.25rem 0",
        },
        listWrapper: {
            marginTop: "1rem",
        },
    }),
);

type Props = {
    orgId: string;
};

const OrganizationWorkflowsWrapper: React.FC<Props> = observer(({ orgId }) => {
    const classes = useStyles();
    const conditionStore = useStore(OrganizationWorkflowsStore);
    const emailStore = useStore(OrganizationWorkflowEmailStore);
    const drawerStore = conditionStore.drawerStore;

    React.useEffect(() => {
        if (orgId) {
            conditionStore.setOrganizationId(orgId);
            emailStore.setOrganizationId(orgId);
        }
    }, [orgId, conditionStore, emailStore]);

    return (
        <>
            <AcxDrawer
                disableBackdrop={false}
                anchor={drawerStore.anchor}
                open={drawerStore.isOpen}
                collapsible={drawerStore.collapseSize}
                drawerStore={drawerStore}
                customRootStyles={drawerStore.customRootStyles}
                onDrawerClose={conditionStore.handleDrawerClose}
                onDrawerOpen={conditionStore.handleDrawerOpen}
                offsetPosition={drawerStore.offsetPosition}
                offsetPx={drawerStore.offsetPx}
                content={
                    <OrganizationWorkflowsCreateConditionForm
                        store={conditionStore}
                    />
                }
            />

            {conditionStore.getTaskLoading(LOAD_CONDITIONS) ? (
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={175}
                />
            ) : (
                <Grid container className={classes.wrapper}>
                    {emailStore.emailEditorIsOpen ? (
                        <OrganizationWorkflowsEmailEditor
                            emailStore={emailStore}
                        />
                    ) : (
                        <>
                            <Grid
                                item
                                xs={12}
                                style={{ marginBottom: "0.5rem" }}
                            >
                                <AcxToggleButton
                                    id="organization-workflows-view-selector"
                                    exclusive
                                    onChange={conditionStore.handleViewChange}
                                    spacing={3}
                                    items={EnumToDropdownOptionConversion(
                                        OrganizationWorkflowViews,
                                    ).map((view) => {
                                        return {
                                            value: view.value,
                                            element: view.label,
                                        };
                                    })}
                                    defaultValue={conditionStore.selectedView}
                                    inputLabel="Select Your View..."
                                    darkBg
                                    style={{ display: "inline-block" }}
                                />
                            </Grid>

                            <Grid container item xs={12}>
                                <OrganizationWorkflowsActionBar
                                    conditionStore={conditionStore}
                                    emailStore={emailStore}
                                />
                            </Grid>

                            <Grid item xs={12} className={classes.listWrapper}>
                                {conditionStore.selectedView ===
                                OrganizationWorkflowViews[
                                    "Workflow Conditions"
                                ] ? (
                                    <OrganizationWorkflowsListConditions
                                        store={conditionStore}
                                    />
                                ) : (
                                    <OrganizationWorkflowsListEmails
                                        store={emailStore}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
        </>
    );
});

export default OrganizationWorkflowsWrapper;
