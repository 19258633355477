import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const segmentPlayerColors = [
    "#2EC674",
    "#5451FF",
    // Add more colors if needed
];

const globalStyles = (theme: Theme) =>
    createStyles({
        AppRoot: {
            backgroundColor: theme.palette.background.default,
            height: "100%",
            padding: theme.spacing(1),
        },
        appContent: {
            width: "85%",
            marginLeft: "auto",
            marginRight: "auto",
        },
        button: {
            margin: theme.spacing(1),
        },
        buttonPaper: {
            height: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
        },
        paperBg0: { background: segmentPlayerColors[0] },
        paperBg1: { background: segmentPlayerColors[1] },
        clickable: {
            cursor: "pointer",
        },
        segmentContainer: {
            marginBottom: theme.spacing(1),
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
        },
        segmentContainer0: { border: "1px solid " + segmentPlayerColors[0] },
        segmentContainer1: { border: "1px solid " + segmentPlayerColors[1] },
        tagContainer: {
            display: "flex",
        },
        // fontColorPrimary: {
        //     color: theme.palette.primary.main,
        // },
        // fontColorSecondary: {
        //     color: theme.palette.secondary.main,
        // },
        // fontColorSuccess: {
        //     color: theme.palette.green.main,
        // },
        // fontColorInfo: {
        //     color: theme.palette.blue.main,
        // },
        // fontColorWarning: {
        //     color: theme.palette.yellow.main,
        // },
        // fontColorError: {
        //     color: theme.palette.red.main,
        // },
        fontSizeSmall: {
            fontSize: 12,
        },
        fontSizeMedium: {
            fontSize: 14,
        },
        fontSizeLarge: {
            fontSize: 16,
        },
        fontWeightMedium: {
            fontWeight: theme.typography.fontWeightMedium as any,
        },
        fontWeightBold: {
            fontWeight: theme.typography.fontWeightBold as any,
        },
        lightText: {
            opacity: 0.5,
        },
        italic: {
            fontStyle: "italic",
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            // maxWidth: 300
        },
        // hasChanges: {
        //     backgroundColor: theme.palette.hasChanges.main,
        // },
        nextStepFocus: {
            // borderRadius: theme.shape.borderRadius,
            // boxShadow: `0 0 3pt 2pt ${theme.palette.primary.main}`
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(2),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        spaceall: {
            margin: theme.spacing(1),
        },
        spaceHorizontally: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        spaceVertically: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        centerVertically: {
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            marginLeft: theme.spacing(1),
        },
    });

export default globalStyles;
