import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import {
    action,
    makeObservable,
    observable,
    reaction,
    computed,
    runInAction,
} from "mobx";
import ClassifierCategory from "models/ClassifierCategoryId";
import ClassifierGroup from "models/ClassifierGroup";
import type Classifier from "models/ClassifierModel";
import { ClassifierUtils } from "models/ClassifierModel";
import ClassifierService from "services/ClassifierService";
import {
    ConversationData,
    ConversationService,
    extractOtherDetails,
} from "services/ConversationService";
import { AuthStore } from "stores/AuthStore";
import { AsyncTaskStatus, BaseStore } from "stores/BaseStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import StandardClassifierBuilderStore from "../Views/Builder/StandardView/StandardClassifierBuilderStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { GridValidRowModel } from "@mui/x-data-grid-pro";

export enum ClassifierBuilderView {
    Standard = "Standard",
    Advanced = "Advanced",
}

@AcxStore
export class ClassifierBuilderV2Store extends BaseStore {
    private readonly classifierService = new ClassifierService();
    private readonly conversationService = new ConversationService();
    readonly orgSelectStore = new OrgSelectorComponentStore();
    readonly standardCBStore: StandardClassifierBuilderStore =
        new StandardClassifierBuilderStore();
    readonly dgStore: AcxDataGridStore;
    readonly messageStore: MessageStore;
    readonly authStore: AuthStore;
    private readonly applicationFiltersStore: ApplicationFiltersStore;

    @observable classifierCategories: ClassifierCategory[] = [];

    @observable classifierGroups: ClassifierGroup[] = [];

    @observable classifiers: Classifier[] = [];

    @observable rbcConversationResults: ConversationData[] = [];

    @observable selectedClassifier?: Classifier;

    @observable copiedClassifier: Classifier | null = null;

    @observable classifierDgSearchStr: string = "";

    @observable rbcSearchTermStr: string = "";

    @observable testClassifierError: string = "";

    @observable estimatedTotalResCount: number = 0;

    @observable pageNumber: number = 1;

    @observable isClassifierListLoading: boolean = false;

    @observable resultsLoading: boolean = false;

    @observable testIsLoading: boolean = false;

    @observable showTestRuleSetWarning: boolean = false;

    @observable validationLoading: boolean = false;

    @observable isRbcFtsStringValid: boolean = false;

    @observable isSwitchViewDialogOpen: boolean = false;

    @observable view: ClassifierBuilderView = ClassifierBuilderView.Standard;

    @observable isAuthcxTenant: boolean = false;

    public static Tasks = {
        UpdateClassifier: "Update Classifier",
    } as const;

    constructor(rootStore: IRootStore) {
        super("ClassifierBuilderV2Store");
        makeObservable(this);
        this.messageStore = rootStore.getStore(MessageStore);
        this.authStore = rootStore.getStore(AuthStore);
        this.applicationFiltersStore = rootStore.getStore(
            ApplicationFiltersStore,
        );
        this.dgStore = new AcxDataGridStore("ClassifiersV2", "Analyticx");
        this.dgStore.removeHeight = "120px";
        this.dgStore.controlsColumnSpan = "auto";
        this.dgStore.hideFilter = true;
        this.standardCBStore.onQueryStringChange = (queryString) => {
            this.setRbcSearchTermStr(queryString);
        };
        reaction(
            () => ({
                orgId: this.orgSelectStore.orgId,
            }),
            async () => {
                this.isAuthcxTenant =
                    this.orgSelectStore.organization?.name === "Authcx";

                this.loadClassifiers();
                this.classifierCategories =
                    await this.classifierService.getClassifierCategories();
                this.classifierGroups =
                    await this.classifierService.getClassifierGroups();
            },
        );
        reaction(
            () => ({
                rbcConversationResults: this.rbcConversationResults,
            }),
            () => {
                if (
                    this.rbcConversationResults &&
                    this.rbcConversationResults.length !== 0
                ) {
                    this.setResultsLoading(false);
                }
            },
        );
        reaction(
            () => ({
                testClassifierError: this.testClassifierError,
            }),
            () => {
                if (this.testClassifierError === "" || null) {
                    this.setShowTestRuleSetWarning(false);
                } else {
                    this.setShowTestRuleSetWarning(true);
                }
            },
        );
        reaction(
            () => ({ selectedClassifier: this.selectedClassifier }),
            ({ selectedClassifier }) => {
                if (
                    selectedClassifier?.classifierImplementations?.[0]
                        .queryString &&
                    this.view !== ClassifierBuilderView.Advanced
                ) {
                    this.view = ClassifierBuilderView.Advanced;
                } else {
                    this.resetView();
                }
            },
        );
    }

    @action
    setPageNumberAndGetClassifierResults = async (value: number) => {
        this.pageNumber = value;
        await this.testClassifier();
        await this.getHighlights();
    };

    @action
    setPageNumberOnly = (value: number) => {
        this.pageNumber = value;
    };

    @action loadClassifiers = async () => {
        this.dgStore.isLoading = true;
        this.dgStore.reset();
        this.isClassifierListLoading = true;
        this.clearSelectedClassifier();

        try {
            this.classifiers =
                await this.classifierService.loadClassifiersForCurrentUser(
                    this.orgSelectStore.orgId,
                );
            await this.updateDataGridClassifiers();
        } catch (e) {
            if (e instanceof Error) this.messageStore.logError(e.message);
        } finally {
            this.isClassifierListLoading = false;
            this.dgStore.isLoading = false;
        }
    };

    @action updateDataGridClassifiers = () => {
        this.dgStore.reset();
        this.dgStore.isLoading = true;
        if (this.classifierDgSearchStr) {
            this.dgStore.rows = this.classifiers
                .filter((classifier) =>
                    classifier.name
                        .toLowerCase()
                        .includes(this.classifierDgSearchStr.toLowerCase()),
                )
                .map((classifier) => ({
                    searchTerms: this.getSearchTerms(classifier),
                    ...classifier,
                }));
        } else {
            this.dgStore.rows = this.classifiers.map((classifier) => ({
                searchTerms: this.getSearchTerms(classifier),
                ...classifier,
            }));
        }
        this.dgStore.isLoading = false;
    };

    @action deleteSelectedClassifiers = async () => {
        this.isClassifierListLoading = true;
        await this.dgStore.selectedRows.forEach(
            async (row) =>
                await this.classifierService
                    .deleteClassifier(row.id)
                    .catch((err) => {
                        this.messageStore.logError(err);
                    }),
        );
        this.loadClassifiers();
    };

    @action
    setClassifierDgSearchStr = (searchStr: string) => {
        this.classifierDgSearchStr = searchStr;
        this.updateDataGridClassifiers();
    };

    @action
    setSelectedClassifier = async (id: string) => {
        await this.loadClassifiers();
        this.selectedClassifier = this.classifiers.find(
            (classifier) => classifier.id === id,
        );
        if (
            this.selectedClassifier?.classifierImplementations.some(
                (impl) => !!impl.queryString,
            )
        ) {
            this.view = ClassifierBuilderView.Advanced;
        }
    };

    @action
    setShowTestRuleSetWarning = (show: boolean) => {
        this.showTestRuleSetWarning = show;
    };

    @action
    clearSelectedClassifier = () => {
        this.selectedClassifier = undefined;
    };

    @action
    createClassifier = async (
        name: string,
        queryString: string, // from the builder search term text input
        description?: string,
        category?: ClassifierCategory | null,
        group?: ClassifierGroup,
    ) => {
        return this.setupAsyncTask(
            ClassifierBuilderV2Store.Tasks.UpdateClassifier,
            async () => {
                try {
                    const res = await this.classifierService.createClassifier(
                        name,
                        queryString,
                        this.orgSelectStore.orgId,
                        description,
                        category?.id ?? "",
                        group?.id ?? "",
                    );
                    this.messageStore.logInfo(
                        `Classifier ${res.classifier.name} successfully created.`,
                    );
                    this.loadClassifiers();
                    return res;
                } catch (error: any) {
                    this.messageStore.logError(error.errorMessage);
                    return AsyncTaskStatus.Error;
                }
            },
        );
    };

    @action
    updateClassifier = async (
        name: string,
        queryString: string,
        id: string,
        description?: string,
        category?: ClassifierCategory | null,
        group?: ClassifierGroup,
    ) => {
        return this.setupAsyncTask(
            ClassifierBuilderV2Store.Tasks.UpdateClassifier,
            async () => {
                try {
                    const user = await this.authStore.userManager.getUser();
                    if (user?.profile.email && name && queryString && id) {
                        const res =
                            await this.classifierService.updateClassifier(
                                name,
                                user.profile.email,
                                queryString,
                                id,
                                description ?? "",
                                category?.id ?? "",
                                group?.id,
                            );

                        this.setSelectedClassifier(res.id);
                        this.messageStore.logInfo("Classifier Saved");
                        return res;
                    }
                } catch (error: any) {
                    this.messageStore.logError(error.errorMessage);
                    return AsyncTaskStatus.Error;
                }
            },
        );
    };

    @action
    publishClassifier = async (id: string, shouldReloadSelected?: boolean) => {
        return this.setupAsyncTask(
            ClassifierBuilderV2Store.Tasks.UpdateClassifier,
            async () => {
                try {
                    await this.classifierService.publishClassifier(id);
                    this.messageStore.logInfo("Classifier Published");
                    if (shouldReloadSelected) {
                        await this.reloadSelectedClassifier();
                    } else {
                        await this.loadClassifiers();
                    }
                } catch (error: any) {
                    this.messageStore.logError(error.errorMessage);
                    return AsyncTaskStatus.Error;
                }
            },
        );
    };

    @action
    unpublishClassifier = async (
        id: string,
        shouldReloadSelected?: boolean,
    ) => {
        return this.setupAsyncTask(
            ClassifierBuilderV2Store.Tasks.UpdateClassifier,
            async () => {
                try {
                    await this.classifierService.unpublishClassifier(id);
                    this.messageStore.logInfo("Classifier Unpublished");
                    if (shouldReloadSelected) {
                        await this.reloadSelectedClassifier();
                    } else {
                        await this.loadClassifiers();
                    }
                } catch (error: any) {
                    this.messageStore.logError(error.errorMessage);
                    return AsyncTaskStatus.Error;
                }
            },
        );
    };

    @action
    copyClassifier = async ({
        id,
        onSuccess,
    }: {
        id?: string;
        onSuccess?: (classifier: Classifier) => void;
    }) => {
        const filterId = id ? id : this.singleSelectedDataGridClassifier?.id;
        const classifier = this.getClassifierById(filterId);
        if (classifier) {
            try {
                const {
                    name,
                    description,
                    classifierCategory: category,
                    classifierImplementations,
                } = classifier;
                const res = await this.classifierService.createClassifier(
                    `Copy of ${name}`,
                    classifierImplementations[0]?.queryString ?? "",
                    this.orgSelectStore.orgId,
                    description ?? "",
                    category?.id ?? "",
                );

                this.copiedClassifier = res.classifier;
                if (onSuccess) {
                    onSuccess(res.classifier);
                }
            } catch (e) {
                if (e instanceof Error) this.messageStore.logError(e.message);
            }
        }
    };

    async addClassifierToLibrary(coreClassifierId: string) {
        if (!this.isAuthcxTenant) return;

        await this.classifierService.addClassifierToLibrary(coreClassifierId);
    }

    async removeClassifierFromLibrary(coreClassifierId: string) {
        if (!this.isAuthcxTenant) return;

        await this.classifierService.removeClassifierFromLibrary(
            coreClassifierId,
        );
    }

    isClassifierInLibrary(classifier?: Classifier | GridValidRowModel | null) {
        return classifier?.createdBy === "AuthenticxClassifierLibrary";
    }

    @action
    async createClassifierGroup(name: string) {
        try {
            const group = await this.classifierService.createClassifierGroup(
                name,
            );
            // force update
            this.classifierGroups = [...this.classifierGroups, group];
            return group;
        } catch (error: any) {
            this.messageStore.logError(error.message);
        }
    }

    @action
    async updateClssifierGroup(id: string, name: string) {
        try {
            const group = await this.classifierService.updateClassifierGroup(
                id,
                name,
            );
            const index = this.classifierGroups.findIndex(
                (g) => g.id === group.id,
            );
            this.classifierGroups[index] = group;
            // force update
            this.classifierGroups = [...this.classifierGroups];
            return group;
        } catch (error: any) {
            this.messageStore.logError(error.message);
        }
    }

    @action
    async deleteClassifierGroup(id: string) {
        try {
            await this.classifierService.deleteClassifierGroup(id);

            const index = this.classifierGroups.findIndex((g) => g.id === id);
            this.classifierGroups.splice(index, 1);
            this.classifierGroups = [...this.classifierGroups];
        } catch (error: any) {
            this.messageStore.logError(error.message);
        }
    }

    @action
    reloadSelectedClassifier = async () => {
        const id = this.selectedClassifier?.id;
        this.selectedClassifier = undefined;
        if (id) {
            this.setSelectedClassifier(id);
        }
    };

    @action
    clearCopiedClassifer = () => {
        this.copiedClassifier = null;
    };

    @action
    setRbcSearchTermStr = (searchTermInput: string) => {
        this.rbcSearchTermStr = searchTermInput;
    };

    @action
    setRbcConversationResults = (results: ConversationData[]) => {
        this.rbcConversationResults = results;
    };

    @action
    setIsRbcFtsStringValid = (isValid: boolean) => {
        this.isRbcFtsStringValid = isValid;
    };

    @action
    setTestClassifierError = (err: string) => {
        if (err.includes("Timeout during reading attempt")) {
            this.testClassifierError =
                "Search was too large and timed out. Try running the search again in a shorter date range, or with simpler criteria.";
        } else {
            this.testClassifierError = err;
        }
    };

    @action
    testClassifier = async () => {
        try {
            const filters = this.applicationFiltersStore.toRequestObject();

            const res = await this.conversationService.getConversations({
                startDate: filters.startDate,
                endDate: filters.endDate,
                // @ts-expect-error We have both dateReference and dateReferenceOption here
                // this is due to a naming mixup between fe and be, will update be later.
                dateReference: filters.dateReferenceOption,
                dateReferenceOption: filters.dateReferenceOption,
                pageNumber: this.pageNumber ?? 1,
                wordsAndPhrasesSearchString: this.rbcSearchTermStr,
                // all required properties from the ApplicationFilters interface, could just
                // ts-ignore but this is probably better.
                evaluationTypes: [],
                hierarchyIds: [],
                mediaTypes: [],
                callDirections: [],
                topics: [],
                containsTopics: false,
                wordsAndPhrasesItems: [],
                rbcFilterItems: [],
                contactTypes: [],
            });
            this.setRbcConversationResults(res.conversationResults);
            this.setEstimatedTotalResCount(res.estimatedCount);
        } catch (error) {
            const errorResponse = (error as Error).message;
            const errorMessage =
                extractOtherDetails(errorResponse) ?? errorResponse;
            this.setTestClassifierError(errorMessage);
        }
    };

    @action
    getHighlights = async () => {
        if (this.rbcSearchTermStr && !!this.rbcConversationResults?.length) {
            try {
                const highlightRes =
                    await this.conversationService.getHighlights({
                        searchPhrase: this.rbcSearchTermStr,
                        rbcFilterItems: [],
                        conversationIds: this.rbcConversationResults.map(
                            (i) => i.conversationId,
                        ),
                    });
                runInAction(() => {
                    this.rbcConversationResults.forEach((c) => {
                        const foundHighlight = highlightRes.find(
                            (r) => r?.audioMetadataId === c.conversationId,
                        );
                        if (foundHighlight) {
                            c.highlights = foundHighlight;
                        }
                    });
                });
            } catch (e) {
                // Do not display error to user
            }
        }
    };

    @action
    async validateRbcFtsSearchString() {
        const result = await this.classifierService.isFTSCompatible(
            this.rbcSearchTermStr,
        );
        this.setIsRbcFtsStringValid(result.success);
        if (!result.success) this.setTestClassifierError(result.message);

        return result.success;
    }

    @action
    setEstimatedTotalResCount = (count: number) => {
        this.estimatedTotalResCount = count;
    };

    @action setResultsLoading = (isLoading: boolean) => {
        this.resultsLoading = isLoading;
    };

    @action setValidationLoading = (isLoading: boolean) => {
        this.validationLoading = isLoading;
    };

    @action
    toggleView() {
        this.view =
            this.view === ClassifierBuilderView.Standard
                ? ClassifierBuilderView.Advanced
                : ClassifierBuilderView.Standard;
    }

    @action
    toggleSwitchViewDialog() {
        this.isSwitchViewDialogOpen = !this.isSwitchViewDialogOpen;
    }

    @action
    resetView() {
        this.view = ClassifierBuilderView.Standard;
    }

    getNextView(): ClassifierBuilderView {
        return this.view === ClassifierBuilderView.Standard
            ? ClassifierBuilderView.Advanced
            : ClassifierBuilderView.Standard;
    }

    getSearchTerms(classifier: Classifier) {
        const impl = classifier.classifierImplementations?.[0];
        return ClassifierUtils.searchStringToTerms(impl?.queryString).length;
    }

    getClassifierById(id?: string) {
        return this.classifiers.find((classifier) => id === classifier.id);
    }

    @computed
    get singleSelectedDataGridClassifier() {
        if (this.dgStore.selectedRows.length === 1) {
            return this.dgStore.selectedRows[0];
        } else {
            return null;
        }
    }

    @computed
    get selectedDataGridClassifiers() {
        return this.classifiers.filter((classifier) =>
            this.dgStore.selectedRowIds.includes(classifier.id),
        );
    }

    @computed
    get selectedPublishedDataGridClassifiers() {
        return this.classifiers.filter(
            (classifier) =>
                this.dgStore.selectedRowIds.includes(classifier.id) &&
                classifier.isPublished,
        );
    }

    @computed
    get publishedClassifiers() {
        return this.classifiers.filter((classifier) => classifier.isPublished);
    }
}
