import BaseEntity from "./BaseEntity";

// export default class CustomerType extends BaseEntity {
//     name: string;
//     sourceName?: string;
//     organizationId: string;
// }

export interface UnMappedCustomerType extends BaseEntity {
    name: string;
    sourceName?: string;
    organizationId: string;
}

export interface MappedCustomerType extends UnMappedCustomerType {
    sourceName: string;
}

type CustomerType = UnMappedCustomerType | MappedCustomerType;

export function isMappedCustomerType(
    customerType: CustomerType,
): customerType is MappedCustomerType {
    const sourceName = customerType.sourceName;
    return sourceName !== undefined && sourceName !== null;
}

export function isUnMappedCustomerType(
    customerType: CustomerType,
): customerType is UnMappedCustomerType {
    const sourceName = customerType.sourceName;
    return sourceName === undefined || sourceName === null;
}

export default CustomerType;
