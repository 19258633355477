import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { observable } from "mobx";

class ApplicationInsightsProvider {
    @observable.ref appInsightsInstance: ApplicationInsights;

    initializeApplicationInsights(connectionString: string) {
        if (!this.appInsightsInstance) {
            const browserHistory = createBrowserHistory(); //support for basename option removed in v5: https://github.com/remix-run/history/issues/810
            const reactPlugin = new ReactPlugin();

            this.appInsightsInstance = new ApplicationInsights({
                config: {
                    connectionString, // This connection string is coming back on the Organization Record from API now since its diff for Prod and Stage
                    autoTrackPageVisitTime: true,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: { history: browserHistory },
                    },
                    loggingLevelConsole: 1,
                    disableFetchTracking: false,
                    isBrowserLinkTrackingEnabled: true,
                    enableAutoRouteTracking: true,
                    namePrefix: "AcxApplicationInsights",
                    enableResponseHeaderTracking: false,
                    enableRequestHeaderTracking: false,
                    enableUnhandledPromiseRejectionTracking: true,
                    enableCorsCorrelation: false,
                    // correlationHeaderExcludedDomains: ['myapp.azurewebsites.net', '*.queue.core.windows.net'] - these are examples
                },
            });

            this.appInsightsInstance.loadAppInsights();
            this.appInsightsInstance.trackPageView();
        }

        console.info(
            "Loading finished. Tracking enabled for Application Insights.",
        );
    }
}

export default ApplicationInsightsProvider;
