import { Checkbox, FormControlLabel, Grid, IconButton, Paper } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import { OrganizationMetadataField } from "models/OrganizationModels/OrganizationMetadataField";
import React, { FunctionComponent } from "react";
import useStyles from "Styles/Styles";
import {
    LOAD_CONFIG,
    OrganizationMetadataStore,
} from "./Store/OrganizationMetadataStore";

const styles = () =>
    createStyles({
        fieldSpacing: {
            margin: "0.25rem 0",
            padding: "0 0 0 0.125rem",
        },
        metadataContainer: {
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        inputSpacing: { paddingLeft: ".125rem" },
    });

export interface DropdownOption<Type> {
    label: Type;
    value: number;
}

interface MetadataFieldRowProps {
    configIndex: number;
    id: string;
    fieldOptions: DropdownOption<OrganizationMetadataField>[];
    currentIndex: number;
    store: OrganizationMetadataStore;
}

const MetadataFieldRow: FunctionComponent<MetadataFieldRowProps> = observer(
    (props) => {
        const { configIndex, fieldOptions, currentIndex, store, id } = props;
        const classes = useStyles(styles);
        const onFieldChange = React.useCallback(
            (
                event: DropdownOption<OrganizationMetadataField>,
                index: number,
            ) => {
                store.handleFieldUpdates(configIndex, event.value, index);
            },
            [configIndex, store],
        );

        const onXPathChange = React.useCallback(
            (event: string, index: number) => {
                store.handleXPathUpdate(configIndex, event, index);
            },
            [configIndex, store],
        );

        const onRequiredUpdate = (event: boolean, index: number) => {
            store.handleFieldIsRequiredUpdates(configIndex, event, index);
        };
        const currentField =
            store.organizationMetadataConfiguration[configIndex].configuration
                .fields[currentIndex];

        return (
            <Paper className={classes.metadataContainer}>
                <Grid container item xs={12}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.fieldSpacing}
                    >
                        <Grid item xs={3} className={classes.inputSpacing}>
                            <AcxSelect
                                inputLabel="Field Type"
                                fullWidth
                                defaultValue={fieldOptions.find(
                                    (option) =>
                                        option.value === currentField?.field,
                                )}
                                id={`field_${currentIndex}`}
                                options={fieldOptions ?? []}
                                onChange={(event) =>
                                    onFieldChange(event, currentIndex)
                                }
                                placeholder="Field Type"
                            />
                        </Grid>

                        <Grid item xs={8} className={classes.inputSpacing}>
                            <AcxMainTextField
                                fullWidth
                                skeleton={store.getTaskLoading(LOAD_CONFIG)}
                                labelText="Field Path"
                                id="fieldPath"
                                value={currentField?.xPathString}
                                onChange={(event) =>
                                    onXPathChange(
                                        event.target.value,
                                        currentIndex,
                                    )
                                }
                                showAllErrors={true}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={1}
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <IconButton
                                onClick={() =>
                                    store.removeFieldConfiguration(
                                        "fields",
                                        id,
                                        configIndex,
                                    )
                                }
                                size="large">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} alignItems="center" className={classes.fieldSpacing}>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) =>
                                            onRequiredUpdate(
                                                event.target.checked,
                                                currentIndex,
                                            )
                                        }
                                        id={`isRequired_${currentIndex}`}
                                        name="isRequired"
                                        checked={currentField?.isRequired ?? false}
                                    />
                                }
                                label="Is Required"
                                labelPlacement="end"
                            />
                        </Grid>
                        
                        <Grid item xs={8} className={classes.inputSpacing}>
                            <AcxMainTextField
                                fullWidth
                                skeleton={store.getTaskLoading(LOAD_CONFIG)}
                                labelText="Extraction Pattern"
                                id="extractionPattern"
                                value={currentField?.extractionPattern}
                                onChange={(event) =>
                                    store.handleExtractionPatternUpdate(
                                        configIndex,
                                        event.target.value,
                                        currentIndex,
                                        "MetadataField"
                                    )
                                }
                                showAllErrors={true}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    },
);

export default MetadataFieldRow;