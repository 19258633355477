import { Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import WFRInstanceLevel from "./WFRInstanceLevel/WFRInstanceLevel";
import { WorkflowReviewStore } from "../Stores/WorkflowReviewStore";
import theme from "Theme/AppTheme";
import { MergedWorkflowLevelInfos } from "../types";

const WorkflowInstanceList: React.FC = observer((props) => {
    const store = useStore(WorkflowReviewStore);

    const instance = store.workflowStore.pruneEarlyCompleteDisputeInstances(
        store.workflowInfoWithAssignedWorkflowInstances
            ? [store.workflowInfoWithAssignedWorkflowInstances]
            : [],
    )?.[0];

    if (!instance) return null;

    const name = instance?.workflowInfo.conditionName;
    const subTitle = store.workflowStore.instanceSubTitle(
        instance,
        instance.workflowInfo.implementationType!,
    );
    const title = store.workflowStore.instanceTitle(
        instance,
        instance.workflowInfo.implementationType!,
    );

    const level: MergedWorkflowLevelInfos | undefined =
        instance?.workflowInfo.workflowLevelInfos?.[store.stepperLevel ?? -1];

    const workflowEvalModule = level?.evaluationModuleId
        ? store.workflowEvalModules.find((value) => {
              return value.evaluationModuleId === level.evaluationModuleId;
          }) ?? null
        : null;

    return (
        <section key={name + "|" + subTitle + "|" + title}>
            <Typography
                component="span"
                sx={{
                    fontSize: "16px",
                    color: theme.palette.gray.main,
                    whiteSpace: "nowrap",
                }}
            >
                {title}
            </Typography>
            <Typography
                component="span"
                sx={{
                    fontSize: "16px",
                    color: theme.palette.gray.main,
                    whiteSpace: "nowrap",
                }}
            >
                {!!subTitle ? ` - ${subTitle}` : ""}
            </Typography>
            <section>
                {level && (
                    <WFRInstanceLevel
                        key={level.level + level.workflowInstanceId}
                        levelType={instance.workflowType}
                        state={level.state}
                        workflowEvalModule={workflowEvalModule}
                        notes={level.note}
                        result={level.result as "approve" | "reject" | null}
                        completedBy={level.completedBy}
                        level={level.level}
                        metadataId={level.workflowMessagingMetadataId}
                        instanceId={level.workflowInstanceId}
                        name={level.levelName}
                    />
                )}
            </section>
        </section>
    );
});

export default WorkflowInstanceList;
