import { Card, Grid, Theme, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import cx from "clsx";
import React from "react";
import hexToRGB from "utils/hexToRGB";
import AcxLoadingIndicator from "./AcxLoadingIndicator";

const useStyles = makeStyles<Theme, Props>((theme) => ({
    fullWidthRoot: (props) => ({
        width: "100%",
        height: props.wrapText ? "fit-content" : "40px",
        backgroundColor:
            props.fullWidthMessage && props.severity === "error"
                ? "#FEE2E2"
                : "",
        padding:
            props.fullWidthMessage && props.severity === "error"
                ? "0px 8px"
                : "",
        boxShadow: props.severity === "infoV2" ? "none!important" : "",
    }),
    root: (props) => ({
        width: "327px",
        height: props.wrapText ? "" : "40px",
    }),
    title: {
        fontSize: "12px",
        color: theme.palette.black.main,
        fontWeight: "bold",
        maxWidth: "250px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        whiteSpace: "nowrap",
    },
    grid: {
        margin: "-2px",
        flexWrap: "nowrap",
    },
    gridInfoV2: {
        backgroundColor: "#DBEAFE",
        borderRadius: "4px",
        margin: "0px",
    },
    messageContainer: (props) => ({
        width: props.fullWidthMessage ? "95%" : "",
    }),
    message: (props) => ({
        fontSize: "12px",
        color: hexToRGB(theme.palette.black.main, 0.5),
        maxWidth: "250px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        whiteSpace: props.wrapText ? "normal" : "nowrap",
    }),
    fullWidthMessage: (props: Props) => ({
        marginTop: "4px",
        maxWidth: "95%",
        color: determineFontColor(props),
    }),
    icon: {
        width: "32px",
        height: "32px",
    },
    innerIcon: {
        margin: "6px",
        fontSize: "20px",
    },
    innerIconV2: {
        margin: "6px",
        fontSize: "20px",
        position: "relative",
        top: "2px",
    },
    closeIcon: (props) => ({
        position: "absolute",
        right: 0,
        top: "10%",
        width: "2rem",
        color: determineFontColor(props),
    }),
    closeIconV2: (props) => ({
        position: "absolute",
        right: "35px",
        top: "3px",
        color: determineFontColor(props),
    }),
    fontColorSuccess: {
        color: theme.palette.green.main,
    },
    fontColorInfo: {
        color: theme.palette.blue.main,
    },
    fontColorWarning: {
        color: theme.palette.yellow.main,
    },
    fontColorError: {
        color: theme.palette.red.main,
    },
}));

type Severity = "success" | "info" | "error" | "loading" | "infoV2";

type Props = {
    title: string;
    message: string;
    severity?: Severity;
    fullWidth?: boolean;
    variant?: "outlined";
    onClose?: () => void;
    fullWidthMessage?: boolean;
    wrapText?: boolean;
};

function determineFontColor(props: Props) {
    if (props.severity === "infoV2") {
        return "#1E3A8A";
    } else if (props.severity === "error") {
        return "#B91C1C";
    } else {
        return "#000000";
    }
}

function AcxToast(props: Props) {
    const classes = useStyles(props);

    function _renderIcon() {
        if (props.severity === undefined) {
            return null;
        }
        let content: React.ReactElement;
        if (props.severity === "loading") {
            content = (
                <AcxLoadingIndicator
                    size={20}
                    alternate="PuffLoader"
                    color="secondary"
                />
            );
        } else if (props.severity === "infoV2") {
            content = (
                <InfoOutlinedIcon
                    className={cx(classes.innerIconV2, classes.fontColorInfo)}
                />
            );
        } else if (props.severity === "info") {
            content = (
                <InfoIcon
                    className={cx(classes.innerIcon, classes.fontColorInfo)}
                />
            );
        } else if (props.severity === "success") {
            content = (
                <CheckIcon
                    className={cx(classes.innerIcon, classes.fontColorSuccess)}
                />
            );
        } else {
            content = (
                <WarningIcon
                    className={cx(classes.innerIcon, classes.fontColorError)}
                />
            );
        }
        return <div className={classes.icon}>{content}</div>;
    }

    function _renderCloseIcon() {
        return <CloseIcon onClick={props.onClose} />;
    }

    return (
        <Card
            variant={props.variant || "elevation"}
            className={props.fullWidth ? classes.fullWidthRoot : classes.root}
        >
            <Grid
                container
                spacing={1}
                alignItems="center"
                className={cx(
                    classes.grid,
                    props.severity === "infoV2" && classes.gridInfoV2,
                )}
            >
                <Grid item>{_renderIcon()}</Grid>
                <Grid item className={classes.messageContainer}>
                    <Tooltip title={props.title}>
                        <div className={classes.title}>{props.title}</div>
                    </Tooltip>
                    <Tooltip title={props.message}>
                        <div
                            className={cx(
                                classes.message,
                                props.fullWidthMessage &&
                                    classes.fullWidthMessage,
                            )}
                        >
                            {props.message}
                        </div>
                    </Tooltip>
                </Grid>
                {props.onClose && (
                    <Grid
                        item
                        className={cx(
                            props.severity !== "infoV2"
                                ? classes.closeIcon
                                : classes.closeIconV2,
                        )}
                    >
                        {_renderCloseIcon()}
                    </Grid>
                )}
            </Grid>
        </Card>
    );
}

export default AcxToast;
