import {
    Checkbox,
    FormControlLabel,
    Grid,
    Typography,
    useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import React from "react";
import OrganizationWorkflowsStore, {
    CREATE_CONDITION,
    LOAD_CONDITIONS,
    LOAD_LICENSED_MODULES,
    UPDATE_CONDITION,
} from "../Stores/OrganizationWorkflowsStore";
import { WorkflowConditionType } from "../Types/WorkflowModels";
import OrganizationWorkflowsLevel from "./OrganizationWorkflowsLevel";

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            width: "45vw",
            padding: "2.25rem",
            position: "relative",
        },
        closeButton: {
            position: "absolute",
            top: "0.75rem",
            right: "0.75rem",
            cursor: "pointer",
        },
        container: {
            margin: "0.5rem 0",
        },
        levelActionContainer: {
            marginBottom: "5rem",
        },
    }),
);

type Props = {
    store: OrganizationWorkflowsStore;
};

const OrganizationWorkflowsCreateConditionForm: React.FC<Props> = observer(
    ({ store }) => {
        const classes = useStyles();
        const theme = useTheme();

        const conditionType = store.inProgressCondition?.implementationType;

        const baseConditionSetupIsValid =
            store.inProgressCondition &&
            (conditionType === WorkflowConditionType.APT
                ? store.inProgressCondition.questionId &&
                  store.inProgressCondition.tagId
                : conditionType === WorkflowConditionType.ScoreEscalation
                ? store.inProgressCondition.licensedModuleId &&
                  store.inProgressCondition.scoreThreshold
                : conditionType === WorkflowConditionType.EvaluationDispute ||
                  conditionType === WorkflowConditionType.ClassifierResult
                ? true
                : false);

        const fullConditionSetupIsValid =
            baseConditionSetupIsValid &&
            store.inProgressCondition &&
            store.inProgressCondition.workflowMessagingMetadata.length !== 0;

        const namesAreValid =
            store.inProgressCondition?.workflowMessagingMetadata?.every(
                (wf) => (wf?.name?.length ?? 0) <= 42,
            );

        const submissionButtonTooltipText = () => {
            if (!fullConditionSetupIsValid) {
                switch (conditionType) {
                    case WorkflowConditionType.APT:
                        return "A question, associated tag, and recipients are required to save a condition.";

                    case WorkflowConditionType.EvaluationDispute:
                        return "Recipients are required to save an evaluation dispute condition.";

                    case WorkflowConditionType.ScoreEscalation:
                        return "A licensed module, score threshold, and recipients are required to save a score escelation condition.";

                    default:
                        return "";
                }
            } else if (!namesAreValid) {
                return "A Workflow Level Name is too long.";
            } else {
                return store.inProgressCondition && store.inProgressCondition.id
                    ? "Update Workflow Condition"
                    : "Create Workflow Condition";
            }
        };

        const showRemoveLevelButton =
            store.inProgressCondition?.workflowDefinition?.name ===
            "SafetyEvent"
                ? store.inProgressCondition?.workflowMessagingMetadata
                      .length !== 1
                : true;

        return (
            <Grid item xs={12} className={classes.wrapper}>
                <Grid item xs={12}>
                    <CloseIcon
                        onClick={store.handleDrawerClose}
                        className={classes.closeButton}
                    />

                    <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.container}>
                            <Typography variant="h2" color="secondary">
                                {store.inProgressCondition?.id
                                    ? "Update"
                                    : "Create"}{" "}
                                Workflow Condition
                            </Typography>
                        </Grid>

                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.container}
                        >
                            {store.inProgressCondition?.id && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) =>
                                                store.handleIsActiveCheckbox(
                                                    e.target.checked,
                                                )
                                            }
                                            id="useCoreDatabase"
                                            name={"useCoreDatabase"}
                                            checked={
                                                store.inProgressCondition
                                                    ?.isActive ?? false
                                            }
                                            disabled={store.getTaskLoading(
                                                UPDATE_CONDITION,
                                            )}
                                        />
                                    }
                                    label="Is Active"
                                />
                            )}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            store.handleIsHighPriorityCheckbox(
                                                e.target.checked,
                                            )
                                        }
                                        id="highPriority"
                                        name={"highPriority"}
                                        checked={
                                            store.inProgressCondition
                                                ?.highPriority
                                        }
                                        disabled={store.getTaskLoading(
                                            UPDATE_CONDITION,
                                        )}
                                    />
                                }
                                label="Mark as priority"
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.container}>
                            <AcxSelectSingle
                                required
                                infoText="A Workflow Definition indicates which Workflow will be triggered from this Condition."
                                inputLabel="Select a Workflow Definition"
                                menuPortalTarget={document.body}
                                fullWidth={true}
                                defaultValue={
                                    store.inProgressCondition
                                        ?.workflowDefinition
                                }
                                id="workflowDefinitionId"
                                labelField="name"
                                valueField="id"
                                options={
                                    store.availableWorkflowDefinitions ?? []
                                }
                                onChange={
                                    store.handleWorkflowDefinitionSelection
                                }
                                isDisabled={
                                    store.inProgressCondition?.id != null ||
                                    store.getTaskLoading(LOAD_CONDITIONS) ||
                                    store.getTaskLoading(CREATE_CONDITION) ||
                                    store.getTaskLoading(LOAD_LICENSED_MODULES)
                                }
                                placeholder={
                                    store.getTaskLoading(LOAD_CONDITIONS) ||
                                    store.getTaskLoading(LOAD_LICENSED_MODULES)
                                        ? "Loading"
                                        : "Select a Workflow Definition..."
                                }
                            />
                        </Grid>

                        {conditionType ===
                            WorkflowConditionType.ClassifierResult && (
                            <Grid item xs={12} className={classes.container}>
                                <AcxSelectSingle
                                    infoText="This Workflow will be triggered based on settings for the Safety Event Model in Model Control."
                                    inputLabel="Select a Workflow Condition"
                                    menuPortalTarget={document.body}
                                    fullWidth={true}
                                    id="workflowConditionId"
                                    labelField="name"
                                    valueField="id"
                                    options={[]}
                                    isDisabled={true}
                                    placeholder={"Safety Event Identified"}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12} className={classes.container}>
                            <AcxMainTextField
                                textareaRootStyles={{ margin: "0" }}
                                infoText="This will auto-populate once a question is chosen."
                                placeholderText="QuestionReference-Condition"
                                skeleton={
                                    store.getTaskLoading(LOAD_CONDITIONS) ||
                                    store.getTaskLoading(LOAD_LICENSED_MODULES)
                                }
                                labelText="Workflow Condition Name (optional)"
                                id="workflowName"
                                value={store.inProgressCondition?.name}
                                onChange={(e) =>
                                    store.handleWorkflowNameUpdate(
                                        e.target.value,
                                    )
                                }
                            />
                        </Grid>

                        {conditionType !==
                            WorkflowConditionType.EvaluationDispute &&
                            conditionType !==
                                WorkflowConditionType.ClassifierResult && (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.container}
                                >
                                    <AcxSelectSingle
                                        required={
                                            store.inProgressCondition
                                                ?.implementationType ===
                                                WorkflowConditionType.APT ||
                                            store.inProgressCondition
                                                ?.implementationType ===
                                                WorkflowConditionType.ScoreEscalation
                                        }
                                        infoText={
                                            store.inProgressCondition
                                                ?.implementationType ===
                                            WorkflowConditionType.APT
                                                ? "The selected Licensed Module will populate a list of its available questions."
                                                : "The selected Licensed Module is required."
                                        }
                                        inputLabel="Select a Licensed Module"
                                        menuPortalTarget={document.body}
                                        fullWidth={true}
                                        defaultValue={
                                            store.selectedLicensedModule
                                        }
                                        id="worfklowAvailableLicensedModule"
                                        labelField="displayName"
                                        valueField="id"
                                        options={
                                            store.hierarchyAndAlwaysModules
                                        }
                                        onChange={
                                            store.handleAvailableLicensedModuleSelection
                                        }
                                        isDisabled={
                                            store.getTaskLoading(
                                                CREATE_CONDITION,
                                            ) ||
                                            store.getTaskLoading(
                                                LOAD_LICENSED_MODULES,
                                            )
                                        }
                                        placeholder={
                                            store.getTaskLoading(
                                                LOAD_LICENSED_MODULES,
                                            )
                                                ? "Loading"
                                                : "Select a Licensed Module to begin..."
                                        }
                                    />
                                </Grid>
                            )}

                        {conditionType ===
                            WorkflowConditionType.ScoreEscalation && (
                            <Grid item xs={12} className={classes.container}>
                                <AcxMainTextField
                                    type="number"
                                    textareaRootStyles={{ margin: "0" }}
                                    infoText="This number indicates a whole percent value. Eg. 60.5 will be read as 60.5%"
                                    placeholderText="QuestionReference-ScoreThreshold"
                                    skeleton={
                                        store.getTaskLoading(LOAD_CONDITIONS) ||
                                        store.getTaskLoading(
                                            LOAD_LICENSED_MODULES,
                                        )
                                    }
                                    labelText="Workflow Condition Score Threshold"
                                    required
                                    id="workflowScoreThreshold"
                                    value={
                                        store.inProgressCondition
                                            ?.scoreThreshold
                                    }
                                    onChange={(e) =>
                                        store.handleWorkflowScoreThreshold(
                                            e.target.value,
                                        )
                                    }
                                />
                            </Grid>
                        )}

                        {store.selectedLicensedModule &&
                            store.inProgressCondition?.implementationType ===
                                WorkflowConditionType.APT && (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.container}
                                >
                                    <AcxSelectSingle
                                        required={
                                            store.inProgressCondition
                                                ?.implementationType ===
                                            WorkflowConditionType.APT
                                        }
                                        infoText="Selecting a question will populate a list of its associated Tags, which can then be chosen to trigger this workflow."
                                        inputLabel="Select a Question"
                                        helperText={
                                            store.selectedQuestion
                                                ?.licensedModule?.description ??
                                            ""
                                        }
                                        menuPortalTarget={document.body}
                                        fullWidth={true}
                                        defaultValue={store.selectedQuestion}
                                        id="workflowConditionQuestionId"
                                        labelField="questionText"
                                        valueField="id"
                                        options={store.availableQuestions}
                                        onChange={store.handleQuestionSelection}
                                        isDisabled={
                                            store.getTaskLoading(
                                                LOAD_CONDITIONS,
                                            ) ||
                                            store.getTaskLoading(
                                                CREATE_CONDITION,
                                            ) ||
                                            store.getTaskLoading(
                                                LOAD_LICENSED_MODULES,
                                            )
                                        }
                                        placeholder={
                                            store.getTaskLoading(
                                                LOAD_CONDITIONS,
                                            ) ||
                                            store.getTaskLoading(
                                                LOAD_LICENSED_MODULES,
                                            )
                                                ? "Loading"
                                                : "Select Question..."
                                        }
                                    />
                                </Grid>
                            )}

                        {store?.inProgressCondition?.questionId &&
                            store.inProgressCondition?.implementationType ===
                                WorkflowConditionType.APT && (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.container}
                                >
                                    <AcxSelectSingle
                                        required={
                                            store.inProgressCondition
                                                ?.implementationType ===
                                            WorkflowConditionType.APT
                                        }
                                        infoText="The selected Tag indicates what will trigger this workflow condition."
                                        inputLabel="Select a Tag"
                                        menuPortalTarget={document.body}
                                        fullWidth={true}
                                        defaultValue={store?.selectedTag}
                                        id="workflowConditionTagId"
                                        labelField="value"
                                        valueField="id"
                                        options={
                                            store.selectedQuestion?.tags ?? []
                                        }
                                        onChange={store.handleTagSelection}
                                        isDisabled={
                                            store.getTaskLoading(
                                                LOAD_CONDITIONS,
                                            ) ||
                                            store.getTaskLoading(
                                                CREATE_CONDITION,
                                            ) ||
                                            store.getTaskLoading(
                                                LOAD_LICENSED_MODULES,
                                            )
                                        }
                                        placeholder={
                                            store.getTaskLoading(
                                                LOAD_CONDITIONS,
                                            ) ||
                                            store.getTaskLoading(
                                                LOAD_LICENSED_MODULES,
                                            )
                                                ? "Loading"
                                                : "Select Tag..."
                                        }
                                    />
                                </Grid>
                            )}
                    </Grid>

                    {baseConditionSetupIsValid && (
                        <Grid container item xs={12}>
                            <Grid container item xs={12}>
                                {store.inProgressCondition?.workflowMessagingMetadata?.map(
                                    (metadata, index) => (
                                        <OrganizationWorkflowsLevel
                                            key={index}
                                            store={store}
                                            level={index}
                                        />
                                    ),
                                )}
                            </Grid>

                            {!store.inProgressCondition?.id && (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="flex-start"
                                    alignContent="center"
                                    className={classes.levelActionContainer}
                                >
                                    {showRemoveLevelButton && (
                                        <AcxButton
                                            disabled={
                                                store.getTaskLoading(
                                                    LOAD_CONDITIONS,
                                                ) ||
                                                store.getTaskLoading(
                                                    CREATE_CONDITION,
                                                ) ||
                                                store.getTaskLoading(
                                                    LOAD_LICENSED_MODULES,
                                                ) ||
                                                (store.inProgressCondition
                                                    ?.workflowMessagingMetadata
                                                    ?.length ?? 0) === 0
                                            }
                                            tooltip={
                                                (store.inProgressCondition
                                                    ?.workflowMessagingMetadata
                                                    ?.length ?? 0) === 0
                                                    ? "A level must be added first."
                                                    : "Remove the most recently added level."
                                            }
                                            size="large"
                                            onClick={store.handleLevelRemoval}
                                            fullWidth={false}
                                        >
                                            Remove Last Level
                                        </AcxButton>
                                    )}

                                    <AcxButton
                                        disabled={
                                            store.getTaskLoading(
                                                LOAD_CONDITIONS,
                                            ) ||
                                            store.getTaskLoading(
                                                CREATE_CONDITION,
                                            ) ||
                                            store.getTaskLoading(
                                                LOAD_LICENSED_MODULES,
                                            ) ||
                                            (store.inProgressCondition
                                                ?.workflowMessagingMetadata
                                                ?.length ?? 0) >= 6
                                        }
                                        tooltip={
                                            (store.inProgressCondition
                                                ?.workflowMessagingMetadata
                                                ?.length ?? 0) >= 6
                                                ? "No more than six levels of recipients can be specified."
                                                : (store.inProgressCondition
                                                      ?.workflowMessagingMetadata
                                                      ?.length ?? 0) > 0
                                                ? "Add an additional level."
                                                : "Add the first level."
                                        }
                                        color="primary"
                                        size="large"
                                        onClick={store.handleLevelAddition}
                                        style={{
                                            marginRight: theme.spacing(1),
                                            marginLeft: theme.spacing(1),
                                        }}
                                    >
                                        Add Level
                                    </AcxButton>
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>

                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-end"
                    alignContent="center"
                    style={{}}
                >
                    <AcxButton
                        tooltip="Close Drawer"
                        size="large"
                        onClick={store.handleDrawerClose}
                        style={{
                            backgroundColor: theme.palette.grey[200],
                            color: theme.palette.black.main,
                            borderRadius: "4px",
                            paddingBlock: theme.spacing(0.5),
                            paddingInline: theme.spacing(2),
                            marginRight: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        Cancel
                    </AcxButton>

                    {!store.inProgressCondition?.id ? (
                        <AcxButton
                            disabled={
                                store.getTaskLoading(LOAD_CONDITIONS) ||
                                store.getTaskLoading(CREATE_CONDITION) ||
                                store.getTaskLoading(LOAD_LICENSED_MODULES) ||
                                !fullConditionSetupIsValid ||
                                !namesAreValid
                            }
                            tooltip={submissionButtonTooltipText()}
                            color="primary"
                            size="large"
                            onClick={store.createWorkflowCondition}
                            loading={store.getTaskLoading(CREATE_CONDITION)}
                        >
                            Create Condition
                        </AcxButton>
                    ) : (
                        <AcxButton
                            disabled={
                                store.getTaskLoading(LOAD_CONDITIONS) ||
                                store.getTaskLoading(UPDATE_CONDITION) ||
                                store.getTaskLoading(LOAD_LICENSED_MODULES) ||
                                !fullConditionSetupIsValid ||
                                !namesAreValid
                            }
                            tooltip={submissionButtonTooltipText()}
                            color="primary"
                            size="large"
                            onClick={store.updateWorkflowCondition}
                            loading={store.getTaskLoading(UPDATE_CONDITION)}
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.white.main,
                                borderRadius: "4px",
                                paddingBlock: theme.spacing(0.5),
                                paddingInline: theme.spacing(2),
                            }}
                        >
                            Update Condition
                        </AcxButton>
                    )}
                </Grid>
            </Grid>
        );
    },
);

export default OrganizationWorkflowsCreateConditionForm;
