import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";
import { AppDomains } from "models/Permission/AppDomains";
import React from "react";

const StyledTab = styled(Tab)(({ theme }) => ({
    fontSize: "13px",
    textTransform: "initial",
    lineHeight: "20px",
    opacity: 0.5,
    color: theme.palette.black.main,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    minWidth: "120px",
    padding: "22px 12px",
    "&.Mui-selected": {
        opacity: 1,
        fontFamily: "Inter Bold",

        fontWeight: "bold",
    },
}));

type Props = {
    selected: number;
    onChange: (event: React.ChangeEvent<{}>, newTab: number) => void;
    tabs: Array<ITab>;
};

export interface ITab {
    tabLabel:
        | string
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    // roles is deprecated
    // roles: Array<string>;
    route: string;
    routePrefix?: string;
    domain: AppDomains;
    domainAction?: string;
    icon?:
        | string
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | undefined;
    newDomainName?: string;
}

function NavMenu(props: Props) {
    function makeTab(tab: ITab) {
        const id = "tab-" + tab.tabLabel.toString();
        return (
            <StyledTab
                style={{
                    padding: "22px 12px",
                }}
                label={tab.tabLabel}
                id={id}
                key={id}
            />
        );
    }

    return (
        <Tabs
            value={props.selected}
            variant={"scrollable"}
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            onChange={props.onChange}
            sx={(theme) => ({
                ".MuiTabScrollButton-root": {
                    color:  theme.palette.black.light + "!important",
                },
            })}
        >
            {props.tabs ? props.tabs.map((tab) => makeTab(tab)) : ""}
        </Tabs>
    );
}

export default NavMenu;
