import { action, computed, makeObservable, observable } from "mobx";
import BaseEntity from "models/BaseEntity";
import { v4 as uuid } from "uuid";
import { OrderedReportDataField } from "../../components/Reports/Editor/Views/EditorSections/ReportFieldTransferList";
import { OrderedItem } from "../../components/Reports/Stores/ReportEditorStore";
import { copyBaseFields } from "../../utils/BaseEntityUtils";
import { symmetricDifferenceBy } from "../../utils/SetAlgebraUtils";
import Organization from "../Organization";
import { ApplicationUser } from "../Permission/ApplicationUser";
import {
    ReportAccessType,
    ReportHierarchyAccessControl,
    ReportRolesAccessControl,
    ReportUserAccessControl,
} from "./ReportAccessControls";
import {
    ReportDataField,
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "./ReportDataField";
import { ReportDataView } from "./ReportDataView";
import { DateAggregation, FieldUse, ReportField } from "./ReportField";
import { CombinatorFilterType } from "./ReportFilter";
import { ReportGroup } from "./ReportGroup";

export enum VizType {
    Bar = 0,
    Line = 1,
    Treemap = 2,
    Pie = 3,
    Table = 4,
    Histogram = 5,
    HorizontalBar = 6,
    Scatter = 7,
}

export class ReportModel extends BaseEntity {
    constructor(
        id: string | undefined,
        createdBy?: string,
        modifiedBy?: string,
        organizationId?: string,
    ) {
        super(id, createdBy, modifiedBy);

        makeObservable(this);

        this.organizationId = organizationId;
        this.reportFields = [];
        this.userAccessControls = [];
        this.groupAccessControls = [];
        this.rolesAccessControls = [];
        // default VizType
        this.vizType = VizType.Bar;
        this.filterSectionCount = 0;
    }

    @observable group: ReportGroup;
    @observable groupId?: string;
    @observable name: string = "";
    @observable persona?: string;
    @observable vizType?: VizType;
    @observable reportSourceDataId?: string;
    @observable dataView?: ReportDataView;
    @observable reportFields: ReportField[];
    @observable organizationId?: string;
    organization?: Organization;
    isPublished?: boolean;
    @observable vizOptions: any;
    @observable creatorId: string;
    @observable userAccessControls: ReportUserAccessControl[];
    @observable groupAccessControls: ReportHierarchyAccessControl[];
    @observable rolesAccessControls: ReportRolesAccessControl[];
    @observable filterSectionCount: number | 0;
    @observable formattingValues: {
        delimiter: DelimiterOptions | null;
        currency: CurrencyOptions | null;
        decimal: DecimalOptions | null;
        hideDataLabels: boolean;
    } = {
        delimiter: null,
        currency: null,
        decimal: null,
        hideDataLabels: false,
    };

    @action
    setFormattingValues = (
        field: string,
        value:
            | DelimiterOptions
            | CurrencyOptions
            | DecimalOptions
            | null
            | boolean,
    ) => {
        if (!this.formattingValues) {
            this.formattingValues = {
                delimiter: null,
                currency: null,
                decimal: null,
                hideDataLabels: false,
            };
        }
        this.formattingValues[field] = value;
    };

    @action
    setGroupId = (group?: ReportGroup) => {
        this.groupId = group?.id;
        if (this.group && group) {
            this.group.id = group.id;
        }
    };
    @action
    setVizType = (vizType?: VizType) => {
        this.vizType = vizType ?? VizType.Bar;
    };

    @action
    setName = (name?: string) => {
        this.name = name ?? "";
    };

    @action
    setPersona = (persona?: string) => {
        this.persona = persona;
    };

    @action
    addVizReportField(fieldUse: FieldUse) {
        const field = new ReportField(
            `local-${uuid()}`,
            this.createdBy,
            this.modifiedBy,
            this.id,
        );
        field.fieldUse = fieldUse;

        this.reportFields.push(field);
        return field;
    }

    @computed
    get quickFilterReportDataFields() {
        const activeReportFields = this.activeReportFields;

        const concreteReportDataFields = activeReportFields
            .filter(
                (value) =>
                    value.fieldUse === FieldUse.QuickFilter &&
                    value.reportDataField,
            )
            .map((value) => ({
                ...value.reportDataField!,
                order: value.order ?? 0,
            }));

        return concreteReportDataFields;
    }
    @action
    changeRolesACL = (
        roles: { name: string; id: string }[],
        type: ReportAccessType,
    ) => {
        const existingItems: ReportRolesAccessControl[] =
            this.rolesAccessControls.filter(
                (value) => value.accessType === type,
            );

        const [newlyAdded, intersection, removed] = symmetricDifferenceBy(
            existingItems,
            roles,
            (arg) => arg.roleId!,
            (arg) => arg.id!,
        );

        for (let intersectionElement of intersection) {
            for (let existingItem of existingItems.filter(
                (value) => value.roleId === intersectionElement,
            )) {
                existingItem.isActive = true;
            }
        }

        for (let removedElement of removed) {
            for (let existingItem of existingItems.filter(
                (value) => value.roleId === removedElement,
            )) {
                existingItem.isActive = false;
            }
        }

        for (let newlyAddedElement of newlyAdded) {
            const rolesAccessControls = new ReportRolesAccessControl(
                undefined,
                this.createdBy,
                this.modifiedBy,
            );
            rolesAccessControls.reportId = this.id;
            rolesAccessControls.roleId = newlyAddedElement;
            rolesAccessControls.accessType = type;

            this.rolesAccessControls.push(rolesAccessControls);
        }
    };
    @action
    changeUserACL = (users: ApplicationUser[], type: ReportAccessType) => {
        const existingItems: ReportUserAccessControl[] =
            this.userAccessControls.filter(
                (value) => value.accessType === type,
            );

        const [newlyAdded, intersection, removed] = symmetricDifferenceBy(
            existingItems,
            users,
            (arg) => arg.userId!,
            (arg) => arg.id!,
        );

        for (let intersectionElement of intersection) {
            for (let existingItem of existingItems.filter(
                (value) => value.userId === intersectionElement,
            )) {
                existingItem.isActive = true;
            }
        }

        for (let removedElement of removed) {
            for (let existingItem of existingItems.filter(
                (value) => value.userId === removedElement,
            )) {
                existingItem.isActive = false;
            }
        }

        for (let newlyAddedElement of newlyAdded) {
            const userAccessControl = new ReportUserAccessControl(
                undefined,
                this.createdBy,
                this.modifiedBy,
            );
            userAccessControl.reportId = this.id;
            userAccessControl.userId = newlyAddedElement;
            userAccessControl.accessType = type;

            this.userAccessControls.push(userAccessControl);
        }
    };

    @computed
    get userACL() {
        return this.userAccessControls.filter((value) => value.isActive);
    }
    @computed
    get rolesACL() {
        return this.rolesAccessControls.filter((value) => value.isActive);
    }

    @action
    changeGroupACL = (
        hierarchies: { label: string; id: string }[],
        type: ReportAccessType,
    ) => {
        const existingItems: ReportHierarchyAccessControl[] =
            this.groupAccessControls.filter(
                (value) => value.accessType === type,
            );
        const [newlyAdded, intersection, removed] = symmetricDifferenceBy(
            existingItems,
            hierarchies,
            (arg) => arg.organizationStructureMemberId!,
            (arg) => arg.id!,
        );

        for (let intersectionElement of intersection) {
            for (let existingItem of existingItems.filter(
                (value) =>
                    value.organizationStructureMemberId === intersectionElement,
            )) {
                existingItem.isActive = true;
            }
        }

        for (let removedElement of removed) {
            for (let existingItem of existingItems.filter(
                (value) =>
                    value.organizationStructureMemberId === removedElement,
            )) {
                existingItem.isActive = false;
            }
        }

        for (let newlyAddedElement of newlyAdded) {
            const groupAccessControl = new ReportHierarchyAccessControl(
                undefined,
                this.createdBy,
                this.modifiedBy,
            );
            groupAccessControl.reportId = this.id;
            groupAccessControl.organizationStructureMemberId =
                newlyAddedElement;
            groupAccessControl.accessType = type;

            this.groupAccessControls.push(groupAccessControl);
        }
    };

    @computed
    get groupACL() {
        return this.groupAccessControls.filter((value) => value.isActive);
    }

    @computed
    get anyViewACLs() {
        return (
            [...this.userACL, ...this.groupACL, ...this.rolesACL].filter(
                (value) => value.accessType === ReportAccessType.View,
            ).length > 0
        );
    }

    @computed
    get anyEditACLs() {
        return (
            [...this.userACL, ...this.groupACL, ...this.rolesACL].filter(
                (value) => value.accessType === ReportAccessType.Edit,
            ).length > 0
        );
    }

    canEdit(userId: string | undefined, hierarchyIds: string[]) {
        if (!userId) {
            return false;
        }
        if (this.creatorId === userId) {
            console.log("canEDIT - creator");
            return true;
        }

        const allUsersEdit =
            this.userAccessControls.filter(
                (value) => value.accessType === ReportAccessType.Edit,
            ).length === 0;

        const allGroupsEdit =
            this.groupAccessControls.filter(
                (value) => value.accessType === ReportAccessType.Edit,
            ).length === 0;

        if (allGroupsEdit && allUsersEdit) {
            console.log("canEDIT - organization");

            return true;
        }

        const canUserEdit =
            this.userAccessControls.filter(
                (value) =>
                    value.accessType === ReportAccessType.Edit &&
                    value.userId === userId,
            ).length > 0;

        const canGroupEdit =
            this.groupAccessControls.filter(
                (value) =>
                    value.accessType === ReportAccessType.Edit &&
                    hierarchyIds.some(
                        (value1) =>
                            value1 === value.organizationStructureMemberId,
                    ),
            ).length > 0;

        const res = canUserEdit || canGroupEdit;
        if (res) {
            console.log("canEDIT - custom");
        }

        return res;
    }
    @action
    updateQuickFilters = (reportDataField: OrderedReportDataField) => {
        const reportField = new ReportField(
            undefined,
            this.createdBy,
            this.modifiedBy,
            this.id,
            undefined,
        );
        //reportField.setOrder(0);
        reportField.setName(reportDataField.displayName);
        reportField.setDisplayName(
            reportDataField.displayName?.trim() ||
                `${reportDataField.fieldName} Filter`,
        );

        if (reportDataField.variation === ReportDataFieldVariation.Virtual) {
            reportField.reportDataField = reportDataField;
        } else {
            reportField.setReportDataField(reportDataField);
            if (reportDataField.datatype === ReportFieldDataTypes.Date) {
                reportField.dateAggregation = DateAggregation.DateId;
            }
        }

        reportField.setFieldUse(FieldUse.QuickFilter);
        let updateStatus = true;
        // eslint-disable-next-line array-callback-return
        this.reportFields.map((reportFields, index) => {
            if (reportFields.fieldUse === FieldUse.QuickFilter) {
                if (reportFields.displayName === reportDataField.displayName) {
                    updateStatus = false;
                    this.reportFields[index].isActive =
                        !this.reportFields[index].isActive;
                }
            }
        });
        if (updateStatus) {
            this.reportFields.push(reportField);
        }
    };
    @action
    changeQuickFilters = (reportDataFields: OrderedReportDataField[]) => {
        // eslint-disable-next-line array-callback-return
        reportDataFields.map((value) => {
            this.reportFields.map(
                // eslint-disable-next-line array-callback-return
                (fieldValue) => {
                    if (
                        fieldValue.name === value.displayName &&
                        fieldValue.fieldUse === FieldUse.QuickFilter
                    ) {
                        fieldValue.setOrder(value.order);
                    }
                },
            );
        });
        const [newlyAdded, intersection, removed] = symmetricDifferenceBy(
            this.reportFields.filter(
                (value) => value.fieldUse === FieldUse.QuickFilter,
            ),
            reportDataFields,
            (arg) => arg.reportDataField!.displayName,
            (arg) => arg.displayName,
        );

        for (const intersectionId of intersection) {
            for (let reportField of this.reportFields.filter(
                (value) => value.fieldUse === FieldUse.QuickFilter,
            )) {
                if (
                    reportField.reportDataField!.displayName === intersectionId
                ) {
                    reportField.isActive = true;
                }
            }
        }
        for (const removedId of removed) {
            for (const reportField of this.reportFields.filter(
                (value) => value.fieldUse === FieldUse.QuickFilter,
            )) {
                if (reportField.reportDataField!.displayName === removedId) {
                    if (
                        !reportField.id ||
                        reportField.id.startsWith("local-")
                    ) {
                        const indx = this.reportFields.findIndex(
                            (value) =>
                                value.reportDataField!.displayName ===
                                    removedId &&
                                value.fieldUse === FieldUse.QuickFilter,
                        );

                        if (indx > -1) {
                            this.reportFields.splice(indx, 1);
                        }
                    } else {
                        reportField.isActive = false;
                    }
                }
            }
        }

        for (const reportDataField of reportDataFields.filter((value) =>
            newlyAdded.has(value.displayName),
        )) {
            const reportField = new ReportField(
                undefined,
                this.createdBy,
                this.modifiedBy,
                this.id,
                undefined,
            );
            reportField.setName(reportDataField.displayName);
            reportField.setDisplayName(
                reportDataField.displayName?.trim() ||
                    `${reportDataField.fieldName} Filter`,
            );

            if (
                reportDataField.variation === ReportDataFieldVariation.Virtual
            ) {
                reportField.setReportDataField(reportDataField);
            } else {
                reportField.setReportDataField(reportDataField);
                if (reportDataField.datatype === ReportFieldDataTypes.Date) {
                    reportField.setDateAggregation(DateAggregation.DateId);
                }
            }

            reportField.setFieldUse(FieldUse.QuickFilter);
            reportField.setOrder(
                this.reportFields.filter(
                    (value) => value.fieldUse === FieldUse.QuickFilter,
                ).length,
            );
            this.reportFields.push(reportField);
        }
    };

    @computed
    get tableReportDataFields(): (ReportDataField & OrderedItem)[] {
        const activeReportFields = this.activeReportFields;

        const concreteReportDataFields = activeReportFields
            .filter(
                (value) =>
                    value.fieldUse === FieldUse.X ||
                    value.fieldUse === FieldUse.VirtualScored ||
                    value.fieldUse === FieldUse.VirtualValue,
            )
            .map((value) => ({
                ...value.reportDataField!,
                order: value.order ?? 0,
            }));

        return concreteReportDataFields.sort((a, b) => a.order - b.order);
    }

    @action
    changeTableReportFields = (reportDataFields: OrderedReportDataField[]) => {
        const [newlyAdded, intersection, removed] = symmetricDifferenceBy(
            this.reportFields.filter(
                (value) =>
                    value.fieldUse === FieldUse.X ||
                    value.fieldUse === FieldUse.VirtualValue ||
                    value.fieldUse === FieldUse.VirtualScored,
            ),
            reportDataFields,
            (arg) => arg.reportDataField!.displayName,
            (arg) => arg.displayName,
        );

        for (const intersectionId of intersection) {
            for (let reportField of this.reportFields.filter(
                (value) =>
                    value.fieldUse === FieldUse.X ||
                    value.fieldUse === FieldUse.VirtualValue ||
                    value.fieldUse === FieldUse.VirtualScored,
            )) {
                if (
                    reportField.reportDataField!.displayName === intersectionId
                ) {
                    reportField.isActive = true;
                }
            }
        }
        for (const removedId of removed) {
            for (const reportField of this.reportFields.filter(
                (value) =>
                    value.fieldUse === FieldUse.X ||
                    value.fieldUse === FieldUse.VirtualValue ||
                    value.fieldUse === FieldUse.VirtualScored,
            )) {
                if (reportField.reportDataField!.displayName === removedId) {
                    if (
                        !reportField.id ||
                        reportField.id.startsWith("local-")
                    ) {
                        const indx = this.reportFields.findIndex(
                            (value) =>
                                value.reportDataField!.displayName ===
                                    removedId &&
                                (value.fieldUse === FieldUse.X ||
                                    value.fieldUse === FieldUse.VirtualValue ||
                                    value.fieldUse === FieldUse.VirtualScored),
                        );

                        if (indx > -1) {
                            this.reportFields.splice(indx, 1);
                        }
                    } else {
                        reportField.isActive = false;
                    }

                    reportField.order = 99;
                }
            }
        }

        for (const reportDataField of reportDataFields.filter((value) =>
            newlyAdded.has(value.displayName),
        )) {
            const reportField = new ReportField(
                undefined,
                this.createdBy,
                this.modifiedBy,
                this.id,
                undefined,
            );

            reportField.setName(reportDataField.displayName);
            reportField.setDisplayName(reportDataField.displayName);

            if (
                reportDataField.variation === ReportDataFieldVariation.Virtual
            ) {
                reportField.setVirtualFieldType(
                    reportDataField.virtualFieldType,
                );
                if (reportDataField.datatype === ReportFieldDataTypes.Number) {
                    reportField.setFieldUse(FieldUse.VirtualScored);
                    reportField.reportDataField = reportDataField;
                } else {
                    reportField.setFieldUse(FieldUse.VirtualValue);
                    reportField.reportDataField = reportDataField;
                }
            } else {
                reportField.setFieldUse(FieldUse.X);
                reportField.setReportDataField(reportDataField);
                if (reportDataField.datatype === ReportFieldDataTypes.Date) {
                    reportField.dateAggregation = DateAggregation.DateId;
                }
            }

            this.reportFields.push(reportField);
        }

        this.reportFields
            .filter((value) => value.isActive)
            .filter(
                (value) =>
                    value.fieldUse === FieldUse.X ||
                    value.fieldUse === FieldUse.VirtualValue ||
                    value.fieldUse === FieldUse.VirtualScored,
            )
            .forEach((rf) => {
                const reportFieldForDataField = reportDataFields.find((rdf) =>
                    rf.fieldUse === FieldUse.X
                        ? rdf.id === rf.reportDataFieldId
                        : rdf.displayName === rf.reportDataField!.displayName,
                );

                if (reportFieldForDataField) {
                    rf.setOrder(reportFieldForDataField.order ?? 99);
                }
            });
    };

    @action
    addFilterSection = () => {
        this.filterSectionCount++;
    };

    @action
    addFilterReportField = () => {
        const field = new ReportField(
            `local-${uuid()}`,
            this.createdBy,
            this.modifiedBy,
            this.id,
        );
        field.fieldUse = FieldUse.Filter;
        this.reportFields.push(field);
        return field;
    };

    @action
    addFilterReportFieldV2 = (displayId?: number) => {
        const field = new ReportField(
            `local-${uuid()}`,
            this.createdBy,
            this.modifiedBy,
            this.id,
        );
        field.fieldUse = FieldUse.Filter;
        let reportFilter = field.reportFilter;
        if (!reportFilter) {
            reportFilter = field.addReportFilter();
        }
        reportFilter.setfilterGroupId(displayId);
        //field.filterGroupId = displayId;
        this.reportFields.push(field);
        return field;
    };
    @action
    removeReportFieldSection = (displayId: any) => {
        // eslint-disable-next-line array-callback-return

        if (this.filterSectionCount > 0) {
            // eslint-disable-next-line array-callback-return
            this.reportFields.map((report, index: number) => {
                let displayList: any;
                displayList = report?.reportFilters[0]?.filterGroupId;
                let updateDisplayList: any = 0;
                // eslint-disable-next-line array-callback-return
                if (displayList && displayList < displayId) {
                    updateDisplayList = displayList;
                    this.reportFields[index].reportFilters[0].filterGroupId =
                        updateDisplayList;
                }
                if (displayList && displayList > displayId) {
                    updateDisplayList = displayList - 1;
                    this.reportFields[index].reportFilters[0].filterGroupId =
                        updateDisplayList;
                }

                if (displayList && displayList === displayId) {
                    this.reportFields[index].reportFilters[0].filterGroupId =
                        -1;
                }
            });
            for (let index = this.reportFields.length; index > 0; index--) {
                let filterGroupId =
                    this.reportFields[index - 1]?.reportFilters[0]
                        ?.filterGroupId;
                if (filterGroupId === -1) {
                    this.removeReportField(this.reportFields[index - 1]);
                    //this.reportFields.splice(index - 1, 1);
                }
            }
            this.filterSectionCount--;
        }
    };
    @action
    removeReportField = (reportField: ReportField) => {
        const indx = this.reportFields.findIndex(
            (value) => value.id === reportField.id && value.isActive,
        );
        if (indx > -1) {
            const field = this.reportFields[indx];
            if (field.id && !field.id.startsWith("local-")) {
                field.isActive = false;
                field.reportFilters.forEach(
                    (value) => (value.isActive = false),
                );
            } else {
                this.reportFields.splice(indx, 1);
            }
        }
    };

    setVizOptions = (opts: string | null) => {
        this.vizOptions = opts;
    };

    @computed
    get hasQueryFilters() {
        return this.activeReportQueryFilterFields.length > 0;
    }

    @computed
    get activeReportFields() {
        return this.reportFields.filter((value) => value.isActive);
    }

    @computed
    get activeVizXAxisField() {
        return this.activeReportFields.find(
            (value) => value.fieldUse === FieldUse.X,
        );
    }

    @computed
    get activeVizYAxisField() {
        return this.activeReportFields.find(
            (value) => value.fieldUse === FieldUse.Y,
        );
    }

    @computed
    get activeVizGroupingField() {
        return this.activeReportFields.find(
            (value) => value.fieldUse === FieldUse.VizGroup,
        );
    }

    @computed
    get activeReportQueryFilterFields() {
        return this.activeReportFields.filter(
            (value) => value.fieldUse === FieldUse.Filter,
        );
    }

    static fromJson(json: ReportModel) {
        const cls = new ReportModel(
            json.id,
            json.createdBy,
            json.modifiedBy,
            json.organizationId,
        );
        copyBaseFields(json, cls);
        cls.groupId = json.groupId;
        cls.name = json.name;
        cls.persona = json.persona;
        cls.vizType = json.vizType;
        cls.reportSourceDataId = json.reportSourceDataId;
        cls.organization = json.organization;
        cls.dataView = json.dataView;
        cls.group = json.group;
        cls.isPublished = json.isPublished;
        cls.vizOptions = json.vizOptions;
        cls.creatorId = json.creatorId;
        cls.userAccessControls = (json.userAccessControls ?? []).map(
            ReportUserAccessControl.fromJson,
        );
        cls.groupAccessControls = (json.groupAccessControls ?? []).map(
            ReportHierarchyAccessControl.fromJson,
        );
        cls.rolesAccessControls = (json.rolesAccessControls ?? []).map(
            ReportRolesAccessControl.fromJson,
        );

        cls.reportFields = [];
        if (json.reportFields !== undefined) {
            cls.reportFields = json.reportFields.map(ReportField.fromJson);
        }

        // handle setting filterGroupId for filter criteria created from V1
        cls.reportFields = [
            ...cls.reportFields.filter(
                (value) => value.fieldUse !== FieldUse.Filter,
            ),
            ...cls.reportFields
                .filter((value) => value.fieldUse === FieldUse.Filter)
                .map((field, index) => {
                    if (
                        field.reportFilters[0].filterGroupId === null ||
                        field.reportFilters[0].filterGroupId === undefined
                    ) {
                        field.reportFilters[0].filterGroupId =
                            field.reportFilters[0].combinator ===
                            CombinatorFilterType.AND
                                ? 0
                                : index;
                    }
                    return field;
                }),
        ];

        cls.filterSectionCount = 0;
        for (
            let filterIndex = 0;
            filterIndex < cls.reportFields.length;
            filterIndex++
        ) {
            let filterId =
                cls.reportFields[filterIndex]?.reportFilters[0]?.filterGroupId;
            if (filterId && cls.filterSectionCount < filterId) {
                cls.filterSectionCount = filterId;
            }
        }
        cls.formattingValues = json.formattingValues;

        return cls;
    }
}

export enum DelimiterOptions {
    Commas,
    Spaces,
    Dots,
}

export enum CurrencyOptions {
    "$ - American Dollar",
}

export enum DecimalOptions {
    "One Decimal Place" = 1,
    "Two Decimal Places" = 2,
}
