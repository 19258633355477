import { Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import useStyles from "../../../../Styles/Styles";
import EmptyClipListSvg from "../../../../SvgIcons/EmptyClipListSvg";

const styles = (theme: Theme) =>
    createStyles({
        circle: {
            position: "relative",
            borderRadius: "50%",
            height: "124px",
            width: "124px",
            // opacity: 0.2,
            // backgroundColor: "#C9C9C9"
        },
        center: {
            position: "absolute",
            top: "68%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
        text: {
            color: "#1F1F1F",
            opacity: 0.25,
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "bold",
            letterSpacing: 0,
            lineHeight: "20px",
            textAlign: "center",
        },
    });

interface OwnProps {}

type Props = OwnProps;

const EmptyClipList: React.FC<Props> = (props) => {
    const classes = useStyles(styles);

    return (
        <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            style={{ position: "relative", top: "51px" }}
        >
            <Grid item className={classes.circle}>
                <div className={classes.center}>
                    <EmptyClipListSvg />
                </div>
            </Grid>
            <Grid item style={{ marginTop: "32px" }}>
                <Typography className={classes.text} variant={"body2"}>
                    You haven't created any quotes
                </Typography>
            </Grid>
        </Grid>
    );
};

export default EmptyClipList;
