import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
    width?: string;
    height?: string;
}

type Props = OwnProps;

const EditSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width={props.width ?? "25px"}
            height={props.height ?? "25px"}
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Evaluation"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.25}
            >
                <g
                    id="Interaction---Analyst-Evaluate-"
                    transform="translate(-1016.000000, -437.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Content" transform="translate(0.000000, 56.000000)">
                        <g
                            id="Middle"
                            transform="translate(344.000000, 24.000000)"
                        >
                            <g
                                id="Quality-Copy"
                                transform="translate(0.000000, 252.000000)"
                            >
                                <g
                                    id="Stacked-Group"
                                    transform="translate(24.000000, 76.000000)"
                                >
                                    <g id="Field-Copy-22">
                                        <g id="Stacked-Group">
                                            <g
                                                id="Group-3"
                                                transform="translate(0.000000, 28.000000)"
                                            >
                                                <g
                                                    id="Group-14"
                                                    transform="translate(612.000000, 1.000000)"
                                                >
                                                    <g id="Group-12">
                                                        <g
                                                            id="Icon/Quote-Copy"
                                                            transform="translate(36.000000, 0.000000)"
                                                        >
                                                            <path
                                                                d="M10.0833333,6.25 L13.75,9.91666667 L7.25,16.4166667 C7.08333333,16.5833333 6.91666667,16.6666667 6.66666667,16.6666667 L6.66666667,16.6666667 L4.16666667,16.6666667 C3.66666667,16.6666667 3.33333333,16.3333333 3.33333333,15.8333333 L3.33333333,15.8333333 L3.33333333,13.3333333 C3.33333333,13.0833333 3.41666667,12.9166667 3.58333333,12.75 L3.58333333,12.75 L10.0833333,6.25 Z M12.75,3.58333333 C13.0833333,3.25 13.5833333,3.25 13.9166667,3.58333333 L13.9166667,3.58333333 L16.4166667,6.08333333 C16.75,6.41666667 16.75,6.91666667 16.4166667,7.25 L16.4166667,7.25 L14.9166667,8.75 L11.25,5.08333333 Z"
                                                                id="Fill"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default EditSvg;
