import BaseService from "./BaseService";

export class ConnectionService extends BaseService {
    protected urlBase: string = "api/FiveNine";

    public async saveConnectionCode(code: string) {
        const params = new URLSearchParams({ code });
        const res = await this.post(JSON.stringify({}), params, "SaveAuthorizationCode");

        return res;
    }
}
