import { InteractionType } from "models/InteractionType";
import { useCallback } from "react";
import { OrganizationService } from "services/OrganizationService";
import useOne from "shared/hooks/useOne";
import UserService from "../User.service";

const useUserHierarchy = (
    userId: string,
    orgId?: string,
    flattened = false,
    mediaType?: InteractionType,
) => {
    const getData = useCallback(async () => {
        if (!orgId) return;

        if (mediaType) {
            return new OrganizationService().getFlattenedServiceHierarchyForUserByMedia(
                mediaType,
            );
        }
        if (flattened) {
            return new OrganizationService().getFlattenedServiceHierarchyForUser(
                orgId,
                userId,
            );
        }

        return UserService.getUserHierarchy(orgId, userId);
    }, [orgId, flattened, userId, mediaType]);

    const {
        isLoading,
        data: userHierarchy,
        refetch,
    } = useOne<any | undefined>({
        get: getData,
    });

    const save = async (hierarchy: any, orgId: string) => {
        if (!orgId) return;
        return UserService.saveUserHierarchy(orgId, userId, hierarchy);
    };

    return {
        userHierarchy,
        isLoading,
        refetch,
        save,
    };
};

export default useUserHierarchy;
