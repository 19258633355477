import {
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import { Question } from "components/Admin/Organizations/types/Module.type";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import {
    LicensedModuleQuestionDependencyConditionalGroup,
    QuestionConditionalGroupType,
    QuestionDependencyQuestionAnswerCondition,
    QuestionDependencyQuestionAnswerOperator,
    QuestionDependencyType,
} from "models/Question";
import React from "react";
import useStyles from "Styles/Styles";
import { emptyGUID } from "./OrganizationModuleQuestionDependencies";
import OrganizationModuleQuestionSubDependency from "./OrganizationModuleQuestionSubDependency";

const styles = (theme: Theme) => {
    return createStyles({
        trashIcon: {
            cursor: "pointer",
            color: theme.palette.blue.main,
            "&:hover": {
                transform: "scale(1.03)",
            },
        },
        addIcon: {
            cursor: "pointer",
            color: theme.palette.gray.main,
            "&:hover": {
                transform: "scale(1.03)",
            },
        },
        dependencyContainer: {
            justifyContent: "space-between",
            alignItems: "flex-end",
            paddingTop: "8px",
        },
        radioContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        iconContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        subDependencyContainer: { marginTop: "8px" },
    });
};

type Props = {
    conditionIndex: number;
    condition: any;
    index: number;
    value: Question;
    onChange: (key: keyof Question, val: any) => void;
    dependencyQuestions: Question[];
    isDefault?: boolean;
};

const questionDepencyOptions = [
    {
        id: 1,
        label: "Condition Group",
    },
    {
        id: 2,
        label: "Question/Answer",
    },
];

export const questionOperatorOptions = [
    {
        id: 1,
        label: "Contains",
    },
    {
        id: 2,
        label: "IsAnswered",
    },
];

const OrganizationModuleQuestionDependency: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = ({
    conditionIndex,
    condition,
    index,
    value,
    onChange,
    dependencyQuestions,
    isDefault,
}) => {
    const classes = useStyles(styles);

    const changeDependencyType = (id: number, conditionIndex: number) => {
        const previousSetup = JSON.parse(JSON.stringify(value.dependencies));
        const previousSetupConditions = previousSetup.conditions;

        if (id === QuestionDependencyType.QuestionAnswer) {
            previousSetupConditions[conditionIndex] = {
                dependsOnQuestionId: emptyGUID,
                op: QuestionDependencyQuestionAnswerOperator.Contains,
                value: "",
                type: QuestionDependencyType.QuestionAnswer,
            } as QuestionDependencyQuestionAnswerCondition;
        } else if (id === QuestionDependencyType.ConditionalGroup) {
            previousSetupConditions[conditionIndex] = {
                conditions: [
                    {
                        dependsOnQuestionId: emptyGUID,
                        op: QuestionDependencyQuestionAnswerOperator.IsAnswered,
                        value: "",
                        type: QuestionDependencyType.QuestionAnswer,
                    } as QuestionDependencyQuestionAnswerCondition,
                ],
                conditionalGroupType: QuestionConditionalGroupType.Or,
                type: QuestionDependencyType.ConditionalGroup,
            } as LicensedModuleQuestionDependencyConditionalGroup;
        }
        return previousSetup;
    };

    const changeQuestion = (id: string, conditionIndex: number) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        previousDependencies.conditions[conditionIndex].dependsOnQuestionId =
            id;

        previousDependencies.conditions[conditionIndex].value = "";

        return previousDependencies;
    };

    const changeTag = (tagValue: string, conditionIndex: number) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        const clickedCondition = previousDependencies.conditions[
            conditionIndex
        ] as QuestionDependencyQuestionAnswerCondition;

        clickedCondition.value = tagValue;

        return previousDependencies;
    };

    const changeOperator = (operatorId: number, conditionIndex: number) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        const clickedCondition = previousDependencies.conditions[
            conditionIndex
        ] as QuestionDependencyQuestionAnswerCondition;

        clickedCondition.op = operatorId;

        return previousDependencies;
    };

    const dependencyTypeDropdown = (conditionIndex: number) => {
        const defaultValueId =
            value.dependencies?.conditions[conditionIndex].type;

        return (
            <AcxSelectSingle
                inputLabel="Type"
                id={`questionDecisionType-${index}`}
                valueField="id"
                labelField="label"
                options={questionDepencyOptions}
                defaultValue={{
                    id: defaultValueId!,
                    label: questionDepencyOptions[defaultValueId! - 1]?.label,
                }}
                onChange={(val) => {
                    onChange(
                        "dependencies",
                        changeDependencyType(val.id, conditionIndex),
                    );
                }}
                isDisabled={isDefault}
            />
        );
    };

    const addBinaryDependency = (conditionIndex: number) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );
        previousDependencies?.conditions[conditionIndex].conditions.push({
            dependsOnQuestionId: emptyGUID,
            op: QuestionDependencyQuestionAnswerOperator.Contains,
            value: "",
            type: QuestionDependencyType.QuestionAnswer,
        } as QuestionDependencyQuestionAnswerCondition);
        return previousDependencies;
    };

    const removeDependency = (conditionIndex: number) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        previousDependencies.conditions.splice(conditionIndex, 1);

        return previousDependencies;
    };

    const questionDropdownDefault = dependencyQuestions?.find((q) => {
        const depCondition = value?.dependencies?.conditions[
            conditionIndex
        ] as QuestionDependencyQuestionAnswerCondition;
        return q.id === depCondition.dependsOnQuestionId;
    });

    const containsDropdownDefault = questionDropdownDefault?.tags?.find((t) => {
        const depCondition = value?.dependencies?.conditions[
            conditionIndex
        ] as QuestionDependencyQuestionAnswerCondition;
        return t.value === depCondition.value;
    });

    const changeRadio = (groupType: string, conditionIndex: number) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        previousDependencies.conditions[conditionIndex].conditionalGroupType =
            QuestionConditionalGroupType[groupType];

        return previousDependencies;
    };

    const conditionalGroupType =
        QuestionConditionalGroupType[
            (
                value.dependencies?.conditions[
                    conditionIndex
                ] as LicensedModuleQuestionDependencyConditionalGroup
            ).conditionalGroupType
        ];

    const operator = (
        value.dependencies?.conditions[
            conditionIndex
        ] as QuestionDependencyQuestionAnswerCondition
    ).op;

    return (
        <Grid
            container
            key={conditionIndex}
            className={classes.dependencyContainer}
        >
            {condition.type === QuestionDependencyType.ConditionalGroup ? (
                <>
                    <Grid item xs={3}>
                        {dependencyTypeDropdown(conditionIndex)}
                    </Grid>
                    <Grid item xs={6} className={classes.radioContainer}>
                        <FormControl component="fieldset">
                            <RadioGroup
                                row
                                aria-label="conditional-type"
                                value={conditionalGroupType}
                                name="radio-buttons-group"
                                onChange={(event) => {
                                    onChange(
                                        "dependencies",
                                        changeRadio(
                                            event.currentTarget.value,
                                            conditionIndex,
                                        ),
                                    );
                                }}
                            >
                                <FormControlLabel
                                    value="And"
                                    control={<Radio disabled={isDefault} />}
                                    label="All are true"
                                />
                                <FormControlLabel
                                    value="Or"
                                    control={<Radio disabled={isDefault} />}
                                    label="Any are true"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2} className={classes.iconContainer}>
                        {!isDefault && (
                            <>
                                <AddSharpIcon
                                    onClick={() => {
                                        onChange(
                                            "dependencies",
                                            addBinaryDependency(conditionIndex),
                                        );
                                    }}
                                    className={classes.addIcon}
                                />
                                <DeleteIcon
                                    onClick={() =>
                                        onChange(
                                            "dependencies",
                                            removeDependency(conditionIndex),
                                        )
                                    }
                                    className={classes.trashIcon}
                                />{" "}
                            </>
                        )}
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.subDependencyContainer}
                    >
                        {condition.conditions.map(
                            (_binaryCond, groupIndex: number) => (
                                <OrganizationModuleQuestionSubDependency
                                    key={
                                        (value.id ?? "") +
                                        conditionIndex +
                                        groupIndex
                                    }
                                    conditionIndex={conditionIndex}
                                    value={value}
                                    index={index}
                                    onChange={onChange}
                                    groupIndex={groupIndex}
                                    dependencyQuestions={dependencyQuestions}
                                    isDefault={isDefault}
                                />
                            ),
                        )}
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={2}>
                        {dependencyTypeDropdown(conditionIndex)}
                    </Grid>
                    <Grid item xs={2}>
                        <AcxSelectSingle
                            inputLabel="Question"
                            id={`questionDecisionType-${index}`}
                            valueField="id"
                            labelField="questionText"
                            options={dependencyQuestions.filter(
                                (q) => q.id !== value.id,
                            )}
                            defaultValue={questionDropdownDefault}
                            onChange={(val) => {
                                onChange(
                                    "dependencies",
                                    changeQuestion(
                                        val.id ?? "",
                                        conditionIndex,
                                    ),
                                );
                            }}
                            required
                            isDisabled={isDefault}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {questionDropdownDefault && (
                            <AcxSelectSingle
                                inputLabel="Operator"
                                id={`operatorList-${index}`}
                                valueField="id"
                                labelField="label"
                                options={questionOperatorOptions}
                                defaultValue={{
                                    id: operator,
                                    label: questionOperatorOptions[operator - 1]
                                        ?.label,
                                }}
                                onChange={(val) => {
                                    onChange(
                                        "dependencies",
                                        changeOperator(
                                            val.id ?? 0,
                                            conditionIndex,
                                        ),
                                    );
                                }}
                                required
                                isDisabled={isDefault}
                            />
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        {questionDropdownDefault &&
                            operator ===
                                QuestionDependencyQuestionAnswerOperator.Contains && (
                                <AcxSelectSingle
                                    inputLabel="Answer"
                                    id={`containList-${index}`}
                                    valueField="id"
                                    labelField="value"
                                    options={
                                        questionDropdownDefault?.tags ?? []
                                    }
                                    defaultValue={containsDropdownDefault}
                                    onChange={(val) => {
                                        onChange(
                                            "dependencies",
                                            changeTag(
                                                val.value ?? "",
                                                conditionIndex,
                                            ),
                                        );
                                    }}
                                    required
                                    isDisabled={isDefault}
                                />
                            )}
                    </Grid>

                    {!isDefault && (
                        <DeleteIcon
                            onClick={() =>
                                onChange(
                                    "dependencies",
                                    removeDependency(conditionIndex),
                                )
                            }
                            className={classes.trashIcon}
                        />
                    )}
                </>
            )}
        </Grid>
    );
};

export default OrganizationModuleQuestionDependency;
