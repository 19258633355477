import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import React from "react";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { useStore } from "utils/useStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";

export const ContactTypeFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const applicationFilterValuesStore = useStore(ApplicationFilterValuesStore);

    return (
        <AcxSelectMulti
            id="application-filters-contact-type"
            fullWidth
            options={applicationFilterValuesStore.contactTypeOptions}
            defaultValue={props.store.contactTypes.map((i) => ({
                label: applicationFilterValuesStore.getContactTypeLabel(i),
                value: i,
            }))}
            onChange={(options) => {
                props.store.setContactTypes(!!options ? options : []);
            }}
            valueField="value"
            labelField="label"
            containerHeight="auto"
            maxContainerHeight="96px"
            alignControls="flex-start"
            isClearable={true}
        />
    );
});
