import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import { OrganizationStructureLevel } from "models/OrganizationModels/OrganizationStructureLevel";
import { OrganizationStructureMember } from "models/OrganizationModels/OrganizationStructureMember";
import React from "react";
import AcxSelectSingle from "./BaseSelectComponents/AcxSelectSingle";

interface Props {
    levels: OrganizationStructureLevel[];
    members: OrganizationStructureMember[];
    hierarchySelections: OrganizationStructureMember[];
    onSelectMember: (
        index: number,
        selection: OrganizationStructureMember,
    ) => void;
    containerRef?: HTMLElement | null | undefined;
    fullWidth?: boolean;
}

const AcxServiceHierarchySelector = observer((props: Props) => {
    function renderLevel(index: number) {
        const level = props.levels[index];

        let parentSelection: OrganizationStructureMember | null;
        if (index === null || props.hierarchySelections.length < index) {
            parentSelection = null;
        } else {
            parentSelection = props.hierarchySelections[index - 1];
        }

        const options =
            parentSelection === null || parentSelection === undefined
                ? props.members.filter(
                      (m) =>
                          m.parentMemberId === null ||
                          m.parentMemberId === undefined,
                  )
                : props.members.filter(
                      (m) => m.parentMemberId === parentSelection!.id,
                  );

        let defaultValue: OrganizationStructureMember | undefined;
        if (props.hierarchySelections.length > index) {
            defaultValue = props.hierarchySelections[index];
        }

        return (
            <Grid item xs key={`render-level-${index}`}>
                <AcxSelectSingle
                    id={`service-hierarchy-selector-${index}`}
                    options={options}
                    valueField={"name"}
                    labelField={"name"}
                    customStyle={{
                        menuPortal: (provided, state) => ({
                            ...provided,
                            zIndex: 9999,
                        }),
                    }}
                    onChange={(newValue) =>
                        props.onSelectMember(index, newValue)
                    }
                    inputLabel={level.name}
                    enableNoSelection
                    isDisabled={props.hierarchySelections.length < index}
                    menuPortalTarget={document.body}
                    fullWidth={props.fullWidth}
                    menuPlacement={
                        (index ?? 0) === (props.levels?.length ?? 0) - 1 &&
                        (props.levels?.length ?? 1) !== 1
                            ? "top"
                            : "auto"
                    }
                    defaultValue={defaultValue}
                />
            </Grid>
        );
    }

    const items = [...Array(props.levels.length).keys()].map(renderLevel);

    return (
        <Grid container direction="column" spacing={3}>
            {items}
        </Grid>
    );
});

export default AcxServiceHierarchySelector;
