export class Blurb {
    text: string;
    channel: number;
    timeInterval: number[];
    foundEddy: boolean;

    constructor(
        text: string,
        channel: number,
        timeInterval: number[],
        foundEddy: boolean,
    ) {
        this.text = text;
        this.channel = channel;
        this.timeInterval = timeInterval;
        this.foundEddy = foundEddy;
    }
}
