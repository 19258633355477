import { Link } from "@mui/material";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import {
    dateComparatorMoment,
    dateIgnoreTimeColumnType,
} from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { action, makeObservable, observable, reaction } from "mobx";
import Organization from "models/Organization";
import React from "react";
import { FileProcessStatsService } from "services/FileProcessStatsService";
import type {
    IProcessAmd,
    IProcessDirectoryStat,
} from "services/FileProcessStatsService";
import { BaseStore } from "stores/BaseStore";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import theme from "Theme/AppTheme";

export enum FileProcessView {
    Dashboard,
    DirectoryAmds,
    AmdTimeline,
}

function getAllDirectoriesColumns(store: FileProcessStore) {
    return [
        {
            headerName: "Id",
            field: "id",
            hide: true,
            type: "number",
            flex: 1,
        },
        {
            headerName: "DirId",
            field: "dirId",
            hide: true,
            type: "string",
            flex: 1,
        },
        {
            headerName: "Arrived On",
            field: "arrivedDate",
            flex: 1,
            ...dateIgnoreTimeColumnType,
            sortComparator: dateComparatorMoment,
        },
        {
            headerName: "Status",
            field: "status",
            type: "string",
            flex: 1,
            renderCell(params) {
                return (
                    <Link
                        variant="body1"
                        style={{
                            textAlign: "left",
                            color: theme.palette.blue.main,
                            fontWeight: "bolder",
                            cursor: "pointer",
                        }}
                        color="textPrimary"
                        onClick={() => {
                            store.setSelectDirectoryStat(
                                params.row as IProcessDirectoryStat,
                            );
                        }}
                    >
                        {params.value?.toString()}
                    </Link>
                );
            },
        },
        {
            headerName: "Directory",
            field: "originalDirectory",
            type: "string",
            flex: 3,
        },
        {
            headerName: "Media Type",
            field: "mediaType",
            type: "string",
            flex: 1,
        },
        {
            headerName: "Count",
            field: "count",
            type: "number",
            flex: 1,
        },
    ] as IColDef[];
}

function getSelectedDirectoryColumns(store: FileProcessStore): IColDef[] {
    return [
        {
            headerName: "Id",
            field: "id",
            flex: 1,
            type: "string",
        },
        {
            headerName: "Arrived On",
            field: "arrivedOn",
            flex: 1,
            ...dateIgnoreTimeColumnType,
        },
        {
            headerName: "Status",
            field: "status",
            type: "string",
            flex: 1,
        },
        {
            headerName: "Directory",
            field: "origDirectoryPath",
            type: "string",
            flex: 4,
        },
        {
            headerName: "File Name",
            field: "fileName",
            type: "string",
            flex: 3,
            renderCell(params) {
                return (
                    <Link
                        variant="body1"
                        style={{
                            textAlign: "left",
                            color: theme.palette.blue.main,
                            fontWeight: "bolder",
                            cursor: "pointer",
                        }}
                        color="textPrimary"
                        onClick={() => {
                            store.setSelectedAmd(params.row as IProcessAmd);
                        }}
                    >
                        {params.value?.toString()}
                    </Link>
                );
            },
        },
        {
            headerName: "Media Type",
            field: "mediaType",
            type: "string",
            flex: 1,
        },
    ];
}

@AcxStore
export class FileProcessStore extends BaseStore {
    public readonly datePickerStore = new DatePickerComponentStore();

    dataGridStore: AcxDataGridStore;

    private readonly fileProcessStatsService: FileProcessStatsService;

    @observable selectedOrganization: Organization;

    @observable selectedDirectoryStat: IProcessDirectoryStat | undefined;

    @observable selectedAmd: IProcessAmd | undefined;

    @observable view: FileProcessView;

    constructor(private rootStore: IRootStore) {
        super("FileProcessStore");
        makeObservable(this);

        this.dataGridStore = new AcxDataGridStore(
            "FileProcessGrid",
            "FileProcess",
        );
        this.dataGridStore.checkboxSelection = false;
        this.dataGridStore.removeHeight = "50px";
        this.fileProcessStatsService = new FileProcessStatsService();

        this.view = FileProcessView.Dashboard;

        reaction(
            (r) => ({
                organization: this.selectedOrganization,
                beginDate: this.datePickerStore.beginDate,
                endDate: this.datePickerStore.endDate,
            }),
            this.debounceEffect(({ organization }) => {
                if (organization) {
                    this.setView(FileProcessView.Dashboard);
                }
            }, 500),
            { delay: 0, fireImmediately: true },
        );

        reaction(
            (r) => this.dataGridStore.rows.length > 0,
            (hasRows) => {
                this.dataGridStore.vertIconMenuItemsBuilder = () => {
                    return [
                        this.dataGridStore.buildVertMenuOptionForCsvExport(
                            hasRows,
                        ),
                    ];
                };
            },
        );
    }

    @action
    async loadDirectoriesStats() {
        this.dataGridStore.setLoading(true);
        const stats: IProcessDirectoryStat[] =
            await this.fileProcessStatsService.getOrgDirectoriesStats(
                this.selectedOrganization.id,
                this.datePickerStore.beginDate,
                this.datePickerStore.endDate,
            );

        await this.dataGridStore.setColumns(getAllDirectoriesColumns(this));
        this.dataGridStore.setLoading(false);
        this.dataGridStore.rows = stats;
    }

    @action
    async loadDirectoryAmds() {
        if (!this.selectedDirectoryStat) return;
        this.dataGridStore.setLoading(true);

        const { dirId, status, mediaType, arrivedDate } =
            this.selectedDirectoryStat;
        const amds: IProcessAmd[] =
            await this.fileProcessStatsService.getAudioMetadataByDirectory(
                dirId,
                status,
                mediaType,
                arrivedDate,
            );

        await this.dataGridStore.setColumns(getSelectedDirectoryColumns(this));
        this.dataGridStore.setLoading(false);
        this.dataGridStore.rows = amds;
    }

    @action
    setSelectDirectoryStat(stat: IProcessDirectoryStat) {
        this.selectedDirectoryStat = stat;
        this.setView(FileProcessView.DirectoryAmds);
    }

    @action
    setSelectedAmd(amd: IProcessAmd) {
        this.selectedAmd = amd;
        this.setView(FileProcessView.AmdTimeline);
    }

    @action
    public setOrganization(organization: Organization) {
        this.selectedOrganization = organization;
    }

    @action
    public goBackView() {
        if (this.view > 0) {
            this.setView(this.view - 1);
        }
    }

    @action
    public async setView(view: FileProcessView) {
        switch (view) {
            case FileProcessView.Dashboard:
                await this.loadDirectoriesStats();
                break;
            case FileProcessView.DirectoryAmds:
                await this.loadDirectoryAmds();
                break;
            case FileProcessView.AmdTimeline:
                // Dont need to load anything in here
                // Data is handled by AmdTimeline component directly in
                // FileProcessDashboard
                break;
            default:
                await this.loadDirectoriesStats();
                break;
        }
        this.view = view;
    }
}
