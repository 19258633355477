import { GridColTypeDef, GridFilterItem } from "@mui/x-data-grid-pro";
import {
    statusFormatter,
    StatusFormatterFilter,
} from "../Formatters/StatusFormatter";

export const statusColumnType: GridColTypeDef = {
    renderCell: statusFormatter,
    filterOperators: [
        {
            label: "equals",
            value: "equals",
            getApplyFilterFn: (filterItem: GridFilterItem) => {
                if (
                    !filterItem.field ||
                    !filterItem.value ||
                    !filterItem.operator
                ) {
                    return null;
                }

                return (params): boolean => {
                    const rowValue = params;
                    return (
                        rowValue?.toString() === filterItem.value?.toString()
                    );
                };
            },
            InputComponent: StatusFormatterFilter,
        },
    ],
};
