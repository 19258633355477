import { AppUser } from "components/Admin/UserManager/UserStore";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import AcxSelectSingleAsync from "components/UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import { observer } from "mobx-react";
import { Moment } from "moment";
import React, { ReactChild, useState } from "react";
import { useStore } from "utils/useStore";
import ServiceHierarchySelect from "../../OrganizationModules/fields/ServiceHierarchySelect";
import { OrganizationAgentStore } from "../stores/OrganizationAgentStore";

interface IAcxCreateNewAgentProps {
    onSubmitHandler?: (
        firstName: string,
        lastName: string,
        agentEmail: string,
        agentCode: string,
        osms: string[],
        startDate?: Moment,
        managerId?: string
    ) => void;
    isOpen?: boolean;
    onClose: () => void;
    isLoading?: boolean;
    orgId?: string;
}

const CreateNewAgentDialog = observer((props: IAcxCreateNewAgentProps) => {
    const agentStore = useStore(OrganizationAgentStore);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [agentEmail, setAgentEmail] = useState("");
    const [agentCode, setAgentCode] = useState("");
    const [startDate, setStartDate] = useState<Moment | undefined>();
    const [managerId, setManagerId] = useState("");

    function onSubmit() {
        setFirstName("");
        setLastName("");
        setAgentEmail("");
        setAgentCode("");
        setStartDate(undefined);
        props.onSubmitHandler?.(
            firstName,
            lastName,
            agentEmail,
            agentCode,
            agentStore.selectedAgentHierarchies ?? [],
            startDate,
            managerId
        );
    }

    function onClose() {
        setFirstName("");
        setLastName("");
        setAgentEmail("");
        setAgentCode("");
        setManagerId("");
        props.onClose();
    }

    const selectManager = (user: AppUser) => {
        setManagerId(user?.id || "");
    };

    React.useEffect(() => {
        agentStore.orgId = props.orgId;
    }, [agentStore, props.orgId]);

    const createAgentFormComponents: ReactChild[] = [
        <AcxMainTextField
            key="firstName"
            containerStyle={{ marginBottom: "0.5rem" }}
            id="firstName"
            labelText="First Name"
            placeholderText='e.g. "Jane"'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autofocus={true}
            required={true}
        />,
        <AcxMainTextField
            key="lastName"
            containerStyle={{ marginBottom: "0.5rem" }}
            id="lastName"
            labelText="Last Name"
            placeholderText='e.g. "Doe"'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required={true}
        />,
        <AcxMainTextField
            key="agentEmail"
            id="agentEmail"
            containerStyle={{ marginBottom: "0.5rem" }}
            labelText="Email"
            placeholderText='e.g. "jane.doe@domain.com"'
            value={agentEmail}
            onChange={(e) => setAgentEmail(e.target.value)}
            required={true}
        />,
        <AcxMainTextField
            key="agentCode"
            id="agentCode"
            containerStyle={{ marginBottom: "0.5rem" }}
            labelText="Agent Code"
            placeholderText="Id or value used by your org"
            value={agentCode}
            onChange={(e) => setAgentCode(e.target.value)}
            required={false}
        />,
        <ServiceHierarchySelect
            key="hieararchySelect"
            orgId={agentStore.orgId ?? ""}
            value={agentStore.selectedAgentHierarchies ?? []}
            onChange={(val) => agentStore.setSelectedAgentHierarchies(val)}
            fullWidth
        />,
        <div style={{ marginBottom: "8px", marginTop: "8px" }}>
        <AcxCalendarPopup
            id={"new-agent-start-date-cal"}
            inputLabel="Start Date"
            currentDate={startDate}
            onSelect={(selectedDate) => setStartDate(selectedDate)}
        />
    </div>,
        <AcxSelectSingleAsync
            key="userId"
            inputLabel="Agent Manager"
            fullWidth
            id="agent-user-option-selector"
            labelField={(option: any) => {
                // using any type "any" here because the returned option was not the Option type expected
                return `${option.firstName} ${option.lastName}`;
            }}
            valueField="id"
            loadOptions={agentStore.searchUsers}
            cacheOptions
            isClearable
            isLoading={false}
            onChange={selectManager}
            noOptionMessage="Please enter First Name, Last Name, Email, or Username"
        />,
    ];

    return (
        <>
            <AcxConfirmationDialog
                isOpen={props.isOpen}
                onClose={onClose}
                title="New Agent"
                subTitle="Create a new agent and assign them to an agent group."
                content={createAgentFormComponents}
                confirmButtonText="Create Agent"
                cancelButtonText="Cancel"
                isLoading={props.isLoading}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={12}
                onConfirm={onSubmit}
            />
        </>
    );
});

export default CreateNewAgentDialog;
