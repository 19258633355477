import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React from "react";
import AlertSvg from "../../../../SvgIcons/AlertSvg";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    messageText: () => ({
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    }),
    infoSVG: {
        marginRight: theme.spacing(2.7),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        color: "red",
    },
}));

const InProgressWorkflowsMessage = observer(() => {
    const classes = useStyles();
    let message =
        "In Progress Workflows must be resolved before this Evaluation can be edited.";

    return (
        <>
            <Grid
                item
                xs={12}
                style={{
                    marginTop: "5px",
                    paddingLeft: "24px",
                    paddingRight: "24px",
                }}
                container
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems="stretch"
            >
                <Grid
                    item
                    xs={12}
                    style={{
                        border: "1px solid red",
                        borderRadius: "5px",
                        backgroundColor: "#FEEEEE",
                        paddingLeft: "5px",
                    }}
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    wrap={"nowrap"}
                >
                    <Grid item className={classes.infoSVG}>
                        <AlertSvg color="red" opacity={0.75} />
                    </Grid>
                    <Grid item>
                        <Typography className={classes.messageText}>
                            {message}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});

export default InProgressWorkflowsMessage;
