import { ButtonBase, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import theme, { ThemeColors } from "../../../../Theme/AppTheme";
import hexToRGB from "../../../../utils/hexToRGB";
import { ChatPersona, IAugmentedChatRecord } from "../AcxChatViewer";
import { ChatViewModel } from "../ViewModel/ChatViewModel";
import moment from "moment";
import ScissorsSvg from "SvgIcons/ScissorsSvg";
import EddyChip from "./EddyChip";
import { getSpeakerColorByChannelOrPersona } from "Theme/utils";

const useStyles = makeStyles((theme: Theme) => ({
    fullSize: {
        width: "100%",
        height: "100%",
    },
    avatar_agent: {
        color: theme.palette.white.main,
        backgroundColor: theme.palette.secondary.main,
        width: theme.spacing(4.25),
        height: theme.spacing(4.25),
    },
    avatar_customer: {
        color: theme.palette.white.main,
        backgroundColor: ThemeColors.green,
        width: theme.spacing(4.25),
        height: theme.spacing(4.25),
    },
    chatAgent: (props) => ({
        color: getSpeakerColorByChannelOrPersona(ChatPersona.Agent),
        fontSize: "12px",
        fontWeight: 700,
    }),
    chatCustomer: (props) => ({
        color: getSpeakerColorByChannelOrPersona(ChatPersona.Customer),
        fontSize: "12px",
        fontWeight: 700,
    }),
    agent: (props: Props) => ({
        borderWidth: props.record.clipId ? "2px" : "unset",
        borderStyle: props.record.clipId ? "solid" : "none",
        borderColor: props.record.clipId ? props.record.color : "unset",
        borderRadius: "8px 8px 8px 0",
        backgroundColor: "#F9FAFB",
    }),
    customer: (props: Props) => ({
        borderWidth: props.record.clipId ? "2px" : "unset",
        borderStyle: props.record.clipId ? "solid" : "none",
        borderColor: props.record.clipId ? props.record.color : "unset",
        borderRadius: "8px 8px 0 8px",
        backgroundColor: "#F9FAFB",
    }),
    messageBox: {
        width: "100",
    },
    messageText: {
        padding: "8px 24px 8px 16px",
        color: "#52525B",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontFamily: theme.typography.fontFamily,
        fontSize: "13px",
        lineHeight: "20px",
        letterSpacing: 0,
        width: "100%",
        height: "100%",
        textAlign: "left",
    },
    timestamp: {
        color: theme.palette.text.disabled,
        fontFamily: theme.typography.fontFamily,
        paddingLeft: theme.spacing(1.7),
        paddingRight: theme.spacing(1.7),
    },
    actionIcon: {
        height: "26px",
        width: "26px",
        borderRadius: "50%",
        backgroundColor: hexToRGB(theme.palette.gray.main, 0.15),
    },
    actionIconInClipSet: {
        backgroundColor: hexToRGB(theme.palette.secondary.main, 1.0),
    },
}));

interface OwnProps {
    record: IAugmentedChatRecord;
    index: number;
    viewModel?: ChatViewModel;
    disableClip?: boolean;
}

type Props = OwnProps;

const ChatMessage: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles(props);

    const inClipSet = props.viewModel?.clipSet.has(props.index);

    return (
        <>
            <Grid item id={props.record.timestamp}>
                {props.record.from === ChatPersona.Agent ? (
                    <Typography className={classes.chatAgent}>Agent</Typography>
                ) : (
                    <Typography className={classes.chatCustomer}>
                        Customer
                    </Typography>
                )}
                {props.record.timestamp && (
                    <Typography>
                        {moment(props.record.timestamp).format("hh:mm")}
                    </Typography>
                )}
            </Grid>
            <Grid item zeroMinWidth>
                <div
                    className={clsx(
                        classes.messageBox,
                        classes[props.record.from],
                    )}
                    onMouseEnter={(event) =>
                        props.viewModel?.onFocusClip?.(props.record.clipId)
                    }
                    onMouseLeave={(event) =>
                        props.viewModel?.onFocusClip?.(undefined)
                    }
                >
                    <Typography
                        className={clsx(classes.messageText, "pendo-ignore")}
                    >
                        {props.record.words.map(({ word, eddy }, i) => (
                            <span
                                key={`${i} ${word}`}
                                style={
                                    eddy
                                        ? {
                                              color: theme.palette.blue.main,
                                              fontWeight: "bold",
                                          }
                                        : {}
                                }
                            >
                                {word +
                                    (props.record.words.length === i
                                        ? ""
                                        : " ")}
                            </span>
                        ))}
                    </Typography>
                    {props.record.eddy && (
                        <EddyChip
                            containerStyle={{
                                padding: "0px 24px 8px 16px",
                            }}
                        />
                    )}
                </div>
            </Grid>
            <Grid item>
                {!props.record.clipId && !props.disableClip && (
                    <ButtonBase
                        className={clsx({
                            [classes.actionIcon]: true,
                            [classes.actionIconInClipSet]: inClipSet,
                        })}
                        onClick={(event) =>
                            props.viewModel?.toggleRecordToClipSet(props.index)
                        }
                    >
                        <ScissorsSvg
                            width={"16px"}
                            height={"16px"}
                            color={
                                inClipSet
                                    ? ThemeColors.white
                                    : ThemeColors.black
                            }
                        />
                    </ButtonBase>
                )}
            </Grid>
        </>
    );
});

export default ChatMessage;
