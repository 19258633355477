import { observable, makeObservable } from "mobx";
import BaseEntity from "./BaseEntity";
import { RuleType } from "./Rule";
import RuleSet from "./RuleSet";
import { v4 as uuidv4 } from "uuid";

export enum CombinatorRuleType {
    And,
    Or,
}
export default class RuleCombinator extends BaseEntity {
    constructor(
        id: string | null,
        ruleSetId: string,
        ruleType: RuleType,
        combinator?: CombinatorRuleType,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        super(id ?? uuidv4(), createdBy, modifiedBy, createdOn, modifiedOn);

        makeObservable(this);

        this.ruleSetId = ruleSetId;
        this.ruleType = ruleType;
        this.combinator =
            combinator ??
            (ruleType === RuleType.Filter || ruleType === RuleType.Stratify
                ? CombinatorRuleType.Or
                : CombinatorRuleType.And);
    }

    static fromServer(json: RuleCombinator, ruleSet: RuleSet) {
        const combinator = new RuleCombinator(
            json.id,
            json.ruleSetId,
            json.ruleType,
            json.combinator,
            json.createdBy,
            json.modifiedBy,
            json.createdOn,
            json.modifiedOn,
        );
        combinator.id = json.id;
        combinator.isActive = json.isActive;
        combinator.ruleSet = ruleSet;
        return combinator;
    }
    @observable ruleSetId: string;
    ruleSet: RuleSet | null;
    @observable ruleType: RuleType;
    @observable combinator?: CombinatorRuleType;
}
