import { ApplicationUser } from "../../models/Permission/ApplicationUser";
import BaseService from "../BaseService";

export class ActionRecipientsService extends BaseService {
    protected urlBase: string = "api/ActionRecipients";

    public async loadEvaluationDomainRecipients(
        organizationId: string,
        organizationStructureMemberId: string,
        userId: string,
    ) {
        const params = new URLSearchParams({
            organizationStructureMemberId,
        });

        const res = await this.post<ApplicationUser[]>(
            JSON.stringify({ organizationId, userId }),
            params,
            "EvaluationActionRecipients",
        );
        return res;
    }
}
