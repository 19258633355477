import { Paper, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { observer } from "mobx-react";
import React from "react";
import { FormFieldErrors } from "shared/types/FormErrors.type";
import useStyles from "Styles/Styles";
import { Organization } from "../../types/Organization.type";
import ServiceSetupRows from "./ServiceSetupRows";

const styles = (theme: Theme) => {
    return createStyles({
        formContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
    });
};

type ServiceSetupProps = {
    updateOrg: (
        val: string | string[] | boolean,
        prop: keyof Organization,
    ) => void;
    setup?: string[];
    fieldErrors?: FormFieldErrors;
    disabled?: boolean;
};

const ServiceSetup: React.FC<React.PropsWithChildren<React.PropsWithChildren<ServiceSetupProps>>> = observer(
    ({ updateOrg, setup = [], fieldErrors, disabled }) => {
        const classes = useStyles(styles);

        const updateRow = (index: number, val: string) => {
            const newSetup = [...setup];
            newSetup[index] = val;
            updateOrg(newSetup, "serviceSetup");
        };

        const addRow = () => {
            updateOrg([...setup, ""], "serviceSetup");
        };

        const removeRow = (removeIndex: number) => {
            const newSetup = [...setup];
            newSetup.splice(removeIndex, 1);
            updateOrg(newSetup, "serviceSetup");
        };

        return (
            <Paper elevation={1} className={classes.formContainer}>
                <Typography variant="h5">Service Setup</Typography>

                <Typography variant="caption">
                    {disabled
                        ? "Permissions are required to edit this structure."
                        : "Setup your service hierarchy levels here. After setup, you can start to populate your organization in the Service Hierarchy tab."}
                </Typography>

                {setup?.length > 5 && (
                    <div style={{ marginTop: 12 }}>
                        <Typography
                            variant="caption"
                            color="error"
                            style={{
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <WarningIcon
                                color="error"
                                style={{ marginRight: 12 }}
                            />{" "}
                            ACX recommends 5 tiers or less.
                        </Typography>
                    </div>
                )}

                {!disabled && (
                    <div
                        style={{
                            paddingRight: 12,
                            paddingTop: 12,
                            width: "100%",
                            textAlign: "right",
                        }}
                    >
                        <AddCircleIcon
                            color="secondary"
                            onClick={addRow}
                            cursor="pointer"
                        />
                    </div>
                )}

                <ServiceSetupRows
                    items={setup}
                    updateRow={updateRow}
                    removeRow={removeRow}
                    fieldErrors={fieldErrors}
                    disabled={disabled}
                />
            </Paper>
        );
    },
);

export default ServiceSetup;
