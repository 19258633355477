import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { ApplicationUser } from "../../../../models/Permission/ApplicationUser";
import { AsyncTaskStatus } from "../../../../stores/BaseStore";
import { DialogComponentStore } from "../../../../stores/ComponentStores/DialogComponentStore";
import hexToRGB from "../../../../utils/hexToRGB";
import ConfirmationDialog from "../../../UI/AcxConfirmationDialog";
import AcxMainTextField from "../../../UI/AcxMainTextFieldGrid";
import AcxSelectMulti from "../../../UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectSingle from "../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import {
    EvalStore,
    ModuleName,
    UpdateEvaluationTask,
} from "../../Stores/EvalStore";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    labelBox: {
        marginBottom: theme.spacing(2.25),
        padding: theme.spacing(0.5),
        borderRadius: "4px",
        backgroundColor: hexToRGB("#3564D5", 0.1),
    },
    interactionNameText: {
        color: "#3564D5",
    },
    labelText: {
        fontSize: "13px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    },
    selectorContainer: {
        marginBottom: theme.spacing(2.25),
    },
    noteContainer: {
        marginBottom: theme.spacing(1),
    },
}));

interface IRequestReviewActionDialog {
    dialogStore: DialogComponentStore<EvalStore>;
    navigateBack: () => void;
}

const defaultConfirmButtonText = "Send Request";

const RequestReviewActionDialog: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<IRequestReviewActionDialog>>> =
    observer((props) => {
        const classes = useStyles();
        const [note, setNote] = React.useState<string>("");

        const [reason, setReason] = React.useState<ModuleName | undefined>(
            undefined,
        );

        const [confirmButton, setConfirmButton] = React.useState<string>(
            defaultConfirmButtonText,
        );

        const [sendTo, setSendTo] = React.useState<
            ApplicationUser[] | undefined
        >(undefined);

        const moduleNames =
            props.dialogStore.hostingStore?.licensedModulesForEval?.map(
                (m) =>
                    ({
                        displayName: m.name,
                        typeName: m.name,
                    } as ModuleName),
            ) ?? [];

        const interactionName =
            props.dialogStore.hostingStore?.currentEval?.interaction
                ?.formattedName;

        const recipients =
            props.dialogStore.hostingStore?.actionRecipients ?? [];

        const onClose = () => {
            props.dialogStore.close();
            props.dialogStore.hostingStore?.clearLastTaskError();
            setConfirmButton(defaultConfirmButtonText);
        };

        const onNotesChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
            const val = evt.currentTarget.value;
            setNote(val);
        };

        const onSubmitRequest = async () => {
            if (
                !props.dialogStore.hostingStore ||
                !props.dialogStore.hostingStore.currentEval
            ) {
                return;
            }

            const currentEval = props.dialogStore.hostingStore.currentEval;
            const hostingStore = props.dialogStore.hostingStore;

            hostingStore.clearTaskErrors();
            props.dialogStore.setErrorMessage("");

            if (!reason) {
                props.dialogStore.setErrorMessage(
                    "You must choose a reason for this request",
                );

                return;
            }

            if (!sendTo || !sendTo.length) {
                props.dialogStore.setErrorMessage(
                    "You must choose recipients for this request",
                );

                return;
            }

            props.dialogStore.setLoading();
            props.dialogStore.setSubTitle("Updating Evaluation ...");

            const updateEvalTask: boolean = await hostingStore.updateEval(
                currentEval.evaluationStatus,
                { pendingAction: true },
            );

            if (!updateEvalTask) {
                props.dialogStore.setNotLoading();
                setConfirmButton("Retry");
            } else {
                props.dialogStore.setLoading();
                props.dialogStore.setSubTitle("Generating Request ...");

                const requestReviewTask =
                    await props.dialogStore.hostingStore.generateRequestReviewAction(
                        note,
                        reason.displayName,
                        sendTo.map((value) => value.id!),
                    );

                if (requestReviewTask === AsyncTaskStatus.Error) {
                    props.dialogStore.setNotLoading();
                    setConfirmButton("Retry");
                } else {
                    props.dialogStore.close();
                    props.navigateBack();
                }
            }
        };

        const content = (
            <div className={classes.root}>
                <Grid
                    container
                    className={classes.labelBox}
                    alignItems={"center"}
                    spacing={1}
                >
                    <Grid item>
                        <Typography className={classes.labelText}>
                            Interaction:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            className={clsx(
                                classes.labelText,
                                classes.interactionNameText,
                            )}
                        >
                            {interactionName}
                        </Typography>
                    </Grid>
                </Grid>

                <div className={classes.selectorContainer}>
                    <AcxSelectSingle
                        inputLabel={"Reason for request:"}
                        defaultValue={reason}
                        options={moduleNames}
                        onChange={setReason}
                        fullWidth
                        containerHeight={"auto"}
                        id={"request-review-eval-action-reasons"}
                        valueField={"typeName"}
                        labelField={"displayName"}
                    />
                </div>

                <div className={classes.selectorContainer}>
                    <AcxSelectMulti
                        inputLabel={"Send this request to:"}
                        defaultValue={sendTo}
                        options={recipients}
                        onChange={setSendTo}
                        fullWidth
                        containerHeight={"auto"}
                        id={"request-review-eval-send-request-to"}
                        valueField={"id"}
                        labelField={"userName"}
                    />
                </div>

                <div>
                    <AcxMainTextField
                        onChange={onNotesChange}
                        labelText={"Additional Notes (optional):"}
                        multiLine
                        rows={5}
                        rowsMax={6}
                        id="request-review-eval-additional-notes-id"
                        value={note}
                    />
                </div>
            </div>
        );

        React.useEffect(() => {
            setConfirmButton(defaultConfirmButtonText);

            return () => {
                setConfirmButton(defaultConfirmButtonText);
                setNote("");
                setReason(undefined);
                setSendTo(undefined);
            };
        }, []);

        return (
            <ConfirmationDialog
                onClose={onClose}
                onConfirm={onSubmitRequest}
                isLoading={
                    props.dialogStore.isLoading ||
                    props.dialogStore.hostingStore?.getTaskLoading(
                        UpdateEvaluationTask,
                    ) ||
                    props.dialogStore.hostingStore?.getTaskLoading(
                        "Generating review request",
                    )
                }
                buttonsDisabled={props.dialogStore.isLoading}
                onCancel={onClose}
                errorMessage={
                    props.dialogStore.errorMessage ||
                    props.dialogStore.hostingStore?.getTaskError(
                        UpdateEvaluationTask,
                    )?.message ||
                    props.dialogStore.hostingStore?.getTaskError(
                        "Generating review request",
                    )?.message
                }
                title={props.dialogStore.title}
                subTitle={props.dialogStore.subTitle}
                cancelButtonText={"Cancel"}
                confirmButtonText={confirmButton}
                content={content}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={20}
                isOpen={props.dialogStore.isOpen}
            />
        );
    });

export default RequestReviewActionDialog;
