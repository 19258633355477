import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AssessmentIcon from "@mui/icons-material/Assessment";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import AcxKeyInformationCard from "components/UI/AcxKeyInformationCard";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "../../../utils/useStore";
import { AgentCoachingStore } from "./Stores/AgentCoachingStore";
import AgentsCoachingTable from "./Views/AgentsCoachingTable";

const useStyles = makeStyles((theme) => ({
    fullSize: {
        height: "calc(100% - 100px)",
        width: "100%",
    },
    fullHeight: {
        height: "100%",
    },
    main: {
        height: "calc(100% - 0px)",
        width: "100%",
        padding: "2rem",
    },
    table: {
        paddingRight: "12px",
    },
}));
interface OwnProps {}

type Props = OwnProps;

const AgentCoaching: FunctionComponent<Props> = observer((props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const store = useStore(AgentCoachingStore);
    const classes = useStyles();
    const orgStore = useStore(AuthStore).orgStore;

    const [cards, setCards] = useState<React.ReactChild[]>([]);

    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectorStore.selectOrg(orgStore.selectedOrganization);
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    useEffect(() => {
        if (store.coachingDetails) {
            if (store.coachingDetails.length <= 1) {
                setCards([]);
                return;
            }

            let displayCards = store.coachingDetails.map((detail, index) => {
                return (
                    <Grid
                        item
                        xs
                        key={`${detail.label}_${detail.value}_gridItem`}
                    >
                        <AcxKeyInformationCard
                            key={`${detail.label}_${detail.value}`}
                            id={`${detail.label}_${detail.value}`}
                            title={detail.label}
                            icon={
                                index === 0 ? (
                                    <RecordVoiceOverIcon />
                                ) : (
                                    <AssessmentIcon />
                                )
                            }
                            value={detail.value}
                            valueLabel="%"
                        />
                    </Grid>
                );
            });

            setCards(displayCards);
        }
    }, [store.coachingDetails]);

    return (
        <div className={classes.fullSize}>
            <div className={classes.main}>
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {cards}
                </Grid>

                <AgentsCoachingTable />
            </div>
        </div>
    );
});

export default AgentCoaching;
