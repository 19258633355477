import { Chip, FormControlLabel, Grid, Link, Switch } from "@mui/material";
import { AddSharp } from "@mui/icons-material";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxControlledTable from "components/UI/AcxTable/AcxControlledTable";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "Styles/Styles";
import { AuthStore } from "../../../stores/AuthStore";
import { useStore } from "../../../utils/useStore";
import useOrganizations from "./hooks/useOrganizations";
import OrganizationArchiveConfirmationDialog from "./OrganizationArchiveConfirmationDialog";
import { Organization } from "./types/Organization.type";

const OrganizationsList = () => {
    const classes = useStyles(() => {
        return {};
    });
    const orgStore = useStore(AuthStore).orgStore;
    // const [showMoreAnchorEl, setShowMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    // const [showMoreFor, setShowMoreFor] = useState<string | null>();
    const [orgFilter, setOrgFilter] = useState<string>("");
    const [showInactiveTenants, setShowInactiveTenants] =
        useState<boolean>(false);
    const navigate = useNavigate();
    const { organizations, toggleOrganization, isLoading } = useOrganizations();
    const [filteredOrganizations, setFilteredOrganizations] = useState<
        Organization[]
    >([]);
    const [orgToArchive, setOrgToArchive] = useState<Organization | null>();

    useEffect(() => {
        let activeOrgs = organizations?.filter((org) => org.isActive === true);
        let orgList = !showInactiveTenants ? activeOrgs : organizations;

        const filtered = orgList?.filter((org) =>
            org.$search?.includes(orgFilter),
        );
        setFilteredOrganizations(filtered ? [...filtered] : []);
    }, [organizations, orgFilter, showInactiveTenants]);

    /*
    const showMore = (id: string, event: any) => {
        setShowMoreAnchorEl(event.currentTarget);
        setShowMoreFor(id);
    };

    const showMoreClose = () => {
        setShowMoreFor(null);
        setShowMoreAnchorEl(null);
    };
    */

    /* 
    const toggleOrganizationClick = (org: Organization) => {
        setShowMoreFor(null);
        setOrgToArchive(org);
    };
     */

    /*
    const moreMoreItems = (org: Organization): AcxMenuItemProps[] => [
        {
            id: "archive",
            label: org.isActive ? (
                <span style={{ color: theme.palette.error.main }}>Archive</span>
            ) : (
                <span style={{ color: theme.palette.secondary.main }}>
                    Restore
                </span>
            ),
            icon: org.isActive ? (
                <InboxSharp
                    fontSize={"small"}
                    htmlColor={theme.palette.error.main}
                />
            ) : (
                <RestoreFromTrashIcon
                    fontSize={"small"}
                    htmlColor={theme.palette.secondary.main}
                />
            ),
            props: {
                onClick: () => toggleOrganizationClick(org),
            },
        },
    ];
    */

    const columns = [
        {
            headerLabel: "Organization Name",
            dataKey: "name",
            formatter: (key, row) => (
                <Grid item>
                    <Link
                        onClick={() => {
                            orgStore?.onActiveOrgIdChange(row.id);
                            orgStore?.setGlobalOrganization(
                                orgStore.organizations?.find(
                                    (org) => org.id === row.id,
                                )! as any,
                            );
                            navigate(`/admin/organizations/${row.id}`);
                        }}
                        color="primary"
                        style={{ fontWeight: "bold" }}
                    >
                        {key}
                    </Link>
                </Grid>
            ),
        },
        {
            headerLabel: "",
            dataKey: "id",
            formatter: (key, row) => (
                <Grid
                    container
                    justifyContent="flex-end"
                    style={{ marginTop: -10, marginBottom: -10 }}
                >
                    <Grid item>
                        {!row.isActive && (
                            <Chip
                                size="small"
                                label="Inactive"
                                style={{ marginRight: 6 }}
                            />
                        )}
                        {/*
                        <IconButton onClick={showMore.bind(null, row.id)}>
                            <MoreVertIcon />
                        </IconButton>
                        */}
                        {/*<AcxMenu
                            menuItems={moreMoreItems(row)}
                            anchorElement={
                                showMoreFor === row.id ? showMoreAnchorEl : null
                            }
                            getContentAnchorEl={null}
                            open={showMoreFor === row.id}
                            onMenuClose={showMoreClose}
                        /> */}
                    </Grid>
                </Grid>
            ),
        },
    ];

    const showInactiveToggle = (
        <FormControlLabel
            control={
                <Switch
                    checked={showInactiveTenants}
                    onClick={() => setShowInactiveTenants(!showInactiveTenants)}
                />
            }
            label="Display Inactive"
        />
    );

    const searchBox = (
        <AcxMainTextField
            id="orgsList-search"
            value=""
            placeholderText="Search..."
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                setOrgFilter(evt.currentTarget.value.toLowerCase())
            }
        />
    );

    return (
        <>
            <div className={classes.appContent}>
                <AcxControlledTable
                    isLoading={isLoading}
                    skeletonRows={5}
                    rows={filteredOrganizations}
                    columns={columns}
                    rowTerm="Organizations"
                    enableStripes={false}
                    controls={[
                        showInactiveToggle,
                        searchBox,
                        {
                            controlElement: (
                                <AcxButton
                                    fullWidth
                                    style={{ height: "33px" }}
                                    color="primary"
                                    startIcon={<AddSharp />}
                                    onClick={() => {
                                        navigate("/admin/organizations/add");
                                    }}
                                >
                                    Add
                                </AcxButton>
                            ),
                            xs: 2,
                            sm: 2,
                            md: 2,
                            lg: 2,
                            xl: 2,
                        },
                    ]}
                />
            </div>

            {orgToArchive && (
                <OrganizationArchiveConfirmationDialog
                    isOpen={!!orgToArchive}
                    org={orgToArchive}
                    onClose={() => setOrgToArchive(null)}
                    toggleOrganization={toggleOrganization}
                />
            )}
        </>
    );
};

export default OrganizationsList;
