import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { MetadataSpec } from "../../../../../models/MetadataSpec";
import { AudioFileSamplerStore } from "../Stores/AudioFileSamplerStore";

interface OwnProps {
    onSelect?: (specModel: MetadataSpec.IMetadataSpecModel) => void;
    label: string;
    showSpinner?: boolean;
    orgId?: string;
    noSelect?: boolean;
    defaultValue?: MetadataSpec.IMetadataSpecModel;
    error?: string;
    specModels?: MetadataSpec.IMetadataSpecModel[];
    specsLoading?: boolean;
    store: AudioFileSamplerStore;
}

type Props = OwnProps;

const SpecSelect: FunctionComponent<Props> = observer((props) => {
    function onSelectWithNoSelectionEnabled(
        specModel: MetadataSpec.IMetadataSpecModel,
    ) {
        if (!specModel) {
            props.onSelect?.({
                specName: "No Selection",
                [Symbol.for("UnSelect")]: true,
            } as any);
        } else {
            props.onSelect?.(specModel);
        }
    }

    return (
        <div>
            <AcxSelect
                inputLabel={props.label}
                defaultValue={props.defaultValue}
                id="metaspec-selector"
                enableNoSelection
                labelField="specName"
                valueField="id"
                options={props.specModels ?? []}
                onChange={onSelectWithNoSelectionEnabled}
                placeholder={
                    props.specsLoading
                        ? "Loading"
                        : props.error
                        ? ""
                        : "Select..."
                }
                isClearable
            />
        </div>
    );
});

export default SpecSelect;
