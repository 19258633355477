import { Grid, Typography } from "@mui/material";
import React from "react";
import theme from "Theme/AppTheme";

const ClassifierCheatsheet = () => {
    return (
        <Grid
            container
            sx={{
                margin: "0px 0.8rem",
                width: "25%",
                minHeight: "100%",
                maxHeight: "250px",
                overflow: "scroll",
            }}
        >
            <section id="word_cheat_container">
                <Typography fontWeight="bold">Match Word</Typography>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        Specify a word by itself
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the calls that contain the word deduction
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Example:
                        </span>
                        <ul>
                            <li>Deduction</li>
                        </ul>
                    </Typography>
                </div>
            </section>
            <section id="phrase_cheat_container">
                <Typography fontWeight="bold">Match Phrase</Typography>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        Specify a phrase surrounded by quotes
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the calls that contain the phrase "I can help"
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Example:
                        </span>
                        <ul>
                            <li>"I can help"</li>
                        </ul>
                    </Typography>
                </div>
            </section>
            <section id="withand_cheat_container">
                <Typography fontWeight="bold">Match with AND</Typography>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        Add an <strong>AND</strong> or <strong>&</strong>{" "}
                        between search criteria
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the interactions that contain both the word
                        provider and account
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Examples:
                        </span>
                        <ul>
                            <li>
                                provider <strong>AND</strong> account
                            </li>
                            <li>
                                provider <strong>&</strong> account
                            </li>
                        </ul>
                    </Typography>
                </div>
            </section>
            <section id="withor_cheat_container">
                <Typography fontWeight="bold">Match with OR</Typography>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        Add an <strong>OR</strong> or <strong>|</strong> between
                        search criteria. A new line is also considered an OR
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the interactions that contain either prior
                        authorization or physician
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Examples:
                        </span>
                        <ul>
                            <li>
                                “prior authorization” <strong>OR</strong>{" "}
                                physician
                            </li>
                            <li>
                                “prior authorization” <strong>|</strong>{" "}
                                physician
                            </li>
                        </ul>
                    </Typography>
                </div>
            </section>
            <section id="proximity_cheat_container">
                <Typography fontWeight="bold">Match with Proximity</Typography>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        After a phrase, add a tilde followed by a number
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the interactions that contain the words
                        insurance and policy within 5 words of each other
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Example:
                        </span>
                        <ul>
                            <li>“insurance policy”~5</li>
                        </ul>
                    </Typography>
                </div>
            </section>
            <section id="withoutword_cheat_container">
                <Typography fontWeight="bold">Match without Word</Typography>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        Add an <strong>AND NOT</strong> or <strong>&!</strong>{" "}
                        between search criteria
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the interactions that contain the word payment
                        without the word approved
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Examples:
                        </span>
                        <ul>
                            <li>
                                payment <strong>AND NOT</strong> approved
                            </li>
                            <li>
                                payment <strong>&!</strong> approved
                            </li>
                        </ul>
                    </Typography>
                </div>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        Add an <strong>OR NOT</strong> or <strong>|!</strong>{" "}
                        between search criteria
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the interactions that contain the phrase copay
                        card or do not contain the word cash
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Examples:
                        </span>
                        <ul>
                            <li>
                                “copay card” <strong>OR NOT</strong> cash
                            </li>
                            <li>
                                “copay card” <strong>|!</strong> cash
                            </li>
                        </ul>
                    </Typography>
                </div>
            </section>
            <section id="byspeaker_cheat_container">
                <Typography fontWeight="bold">Match By Speaker</Typography>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        Add a colon followed by a capital letter after an
                        individual word <br />
                        D = First speaker
                        <br />
                        <br />
                        C = Second speaker
                        <br />
                        <br />
                        B = Third speaker (uncommon)
                        <br />
                        <br />A = Fourth Speaker (extremely uncommon)
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the times the first speaker spoke the word help
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Example:
                        </span>
                        <ul>
                            <li>help:D</li>
                        </ul>
                    </Typography>
                </div>
            </section>
            <section id="byspeakerwproximity_cheat_container">
                <Typography fontWeight="bold">
                    Match By Speaker with Proximity
                </Typography>
                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        Inside of quotes, specify two words with a colon and a
                        capital letter after them
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find the word help said by the first speaker within 10
                        words of the second speaker saying yes
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Example:
                        </span>
                        <ul>
                            <li>“help:D yes:C”~10</li>
                        </ul>
                    </Typography>
                </div>
            </section>
            <section id="wildcard_cheat_container">
                <Typography fontWeight="bold">Match with Wildcard</Typography>

                <div>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                        component="div"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            To Use:
                        </span>
                        After the first part of a word, add an asterisk
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                        component="div"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Use Case:
                        </span>
                        Find all the interactions that have a word beginning
                        with run in them. This will match run, runs, running,
                        runaway, etc.
                    </Typography>
                    <Typography
                        fontFamily={
                            "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace"
                        }
                        padding="0.25rem"
                        component="div"
                    >
                        <span
                            style={{
                                textDecoration: "underline",
                                paddingRight: theme.spacing(1),
                            }}
                        >
                            {" "}
                            Example:
                        </span>
                        <ul>
                            <li>run*</li>
                        </ul>
                    </Typography>
                </div>
            </section>
        </Grid>
    );
};

export default ClassifierCheatsheet;
