import { Button, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useStore } from "../../../../../../utils/useStore";
import AcxButton from "../../../../../UI/AcxButton";
import ConfirmationDialog from "../../../../../UI/AcxConfirmationDialog";
import { UseSampleOp } from "../../../AudioFileSampler/Stores/AudioFileSamplerStore";
import { RecommendationStepStore } from "../../Stores/RecommendationStepStore";
import SourceFilesTable from "../SourceFilesTable";

interface OwnProps {}

type Props = OwnProps;

const RecommendationStep: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const store = useStore(RecommendationStepStore);
    const [alternateTitle, setAlternateTitle] = useState<
        React.ReactNode | undefined
    >();

    const samplingProgress = store.samplingProgressParams;
    const samplingStatusCounts = store.samplingStatusCounts;
    const samplingErrorsOccurred = store.samplingErrorsOccurred;
    useEffect(() => {
        if (samplingProgress) {
            setAlternateTitle(
                samplingProgress && (
                    <Grid container direction={"column"}>
                        <Grid item>
                            <Typography variant="subtitle1">
                                {samplingProgress?.[1]} Sampled /{" "}
                                {samplingProgress?.[0]} Selected
                            </Typography>
                        </Grid>
                        {Boolean(samplingProgress?.[2]) && (
                            <Grid item>
                                <Typography
                                    color={"textSecondary"}
                                    variant="subtitle2"
                                >
                                    {samplingProgress?.[2]} Out of sample
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                ),
            );
        } else if (samplingErrorsOccurred) {
            setAlternateTitle(
                samplingErrorsOccurred && (
                    <Grid container direction={"column"}>
                        <Grid item>
                            <Typography variant="subtitle1">
                                {store.errorItemsCount} Sampling Errors
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                color={"textSecondary"}
                                variant="subtitle2"
                            >
                                {samplingStatusCounts?.[0]} Selected{" "}
                                {Boolean(samplingStatusCounts?.[2]) && (
                                    <>
                                        / {samplingStatusCounts?.[2]} Out of
                                        sample
                                    </>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                ),
            );
        } else {
            setAlternateTitle(undefined);
        }
    }, [
        samplingErrorsOccurred,
        samplingProgress,
        store.errorItemsCount,
        samplingStatusCounts,
    ]);

    return (
        <>
            <ConfirmationDialog
                isOpen={store.emptyRecommendationDialogVisible}
                onClose={store.closeEmptyRecommendationDialog}
                cancelButtonText={"OK"}
                content={`Your sampling configuration returned an empty set. `}
                title={"Empty Sample Suggestion Set"}
            />

            <Grid
                container
                item
                xs={12}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                style={{ height: "100%" }}
            >
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent={"flex-end"}
                    alignContent="flex-end"
                    alignItems={"center"}
                >
                    <AcxButton
                        disabled={
                            (store.tableStore.items?.length ?? 0) === 0 ||
                            store.getTaskLoading(UseSampleOp)
                        }
                        color={"primary"}
                        onClick={store.showStartSamplingDrawer}
                        style={{ maxWidth: "150px" }}
                    >
                        Use Sample
                    </AcxButton>
                </Grid>

                <Grid item xs={12} style={{ height: "100%" }}>
                    <SourceFilesTable
                        loading={store.isRecommendedSetLoading}
                        title={"Recommended Files"}
                        alternateTitle={alternateTitle}
                        tableStoreFragment={store.tableStore}
                        metaLabels={store.sourceFilesStepStore.metaLabels}
                        extendedMetaLabels={store.extendedMetaLabels}
                        enableCheck
                        controls={[
                            {
                                controlElement: (
                                    <Grid
                                        key={
                                            "SamplerRecommendationStepStatsButton"
                                        }
                                        item
                                        container
                                        justifyContent={"flex-end"}
                                    >
                                        {" "}
                                        <Button
                                            variant={"contained"}
                                            style={{
                                                textTransform: "none",
                                            }}
                                            disableElevation
                                            disabled={!store.canOpenStatsDrawer}
                                            color={"secondary"}
                                            onClick={store.openStatsDrawer}
                                        >
                                            View Statistics
                                        </Button>
                                    </Grid>
                                ),
                            },
                        ]}
                    />
                </Grid>
            </Grid>
        </>
    );
});

export default RecommendationStep;
