import _ from "lodash";
import { action, makeObservable, observable, reaction } from "mobx";
import { BaseStore } from "stores/BaseStore";
import { BranchContentProps, BranchDataProps } from "../AcxRecursiveTreeBranch";

export const LOAD_TOP_LEVEL = "Load AcxRecursiveTree Top Level Branches";

type updateBranchType = (
    orgId: string,
    branchId: string,
    branch: BranchDataProps,
) => Promise<BranchDataProps>;

type createBranchType = (
    orgId: string,
    parent: BranchDataProps,
    newBranch: BranchDataProps,
) => Promise<{ parent: BranchDataProps; child: BranchDataProps }>;

type deleteBranchType = (
    orgId: string,
    branchId: string,
    parentBranch?: BranchDataProps,
) => Promise<BranchDataProps | undefined>;

type getBranchType = (
    orgId: string,
    parentId: string,
) => Promise<{ children: BranchDataProps[]; rowCount: number }>;

type getAllBranchesType = () => Promise<BranchDataProps[]>;

interface IRecursiveTreeStoreCtor {
    getAllBranches?: getAllBranchesType;
    getBranch: getBranchType;
    updateBranch: updateBranchType;
    createBranch?: createBranchType;
    deleteBranch?: deleteBranchType;
}

export default class RecursiveTreeStore extends BaseStore {
    @observable.ref
    topLevelBranches?: BranchDataProps[];

    @observable.ref
    organizationId: string;

    @observable
    openedBranchIds: string[] = [];

    @observable
    selectedBranchIds: string[] = [];

    @observable
    selectAllCheckedIds: string[] = [];

    @observable
    selectedBranchLabels: string[] = [];

    @observable
    selectAllToggle: boolean = false;

    @observable
    headerWarningHasRendered: boolean = false;

    @observable
    isTreeLoading: boolean = false;

    @observable
    treeSearchResponse: BranchDataProps[] = [];

    @observable
    searchString: string = "";

    @observable
    displayChildSelection: boolean = false;

    @observable
    selectedCategories: string[] = [];

    @observable
    selectedSubcategories: string[] = [];

    onSelectedBranchIdsChange: ((branchIds: string[]) => void) | undefined;

    onSelectAllChildren?: (parentBranchId: string, isChecked: boolean) => void; // parentBranchId as osmId

    getRecursiveChildCount?: (parentBranchId: string) => Promise<number>;

    getAllBranches?: getAllBranchesType;
    getBranch: getBranchType;
    updateBranch: updateBranchType;
    createBranch?: createBranchType;
    deleteBranch?: deleteBranchType;

    branchContent?: BranchContentProps;

    enableCheckboxSelection: boolean = false;
    enableIndeterminateMode: boolean = false;
    enableAutomaticChildSelection: boolean = false;
    singleSelect: boolean = false;
    topLevelDataKey?: string;

    constructor(args: IRecursiveTreeStoreCtor) {
        super("RecursiveTreeStore");
        makeObservable(this);

        this.getBranch = args.getBranch;
        this.deleteBranch = args.deleteBranch;
        this.createBranch = args.createBranch;
        this.updateBranch = args.updateBranch;
        this.getAllBranches = args.getAllBranches;

        reaction(
            () => this.selectedBranchIds.length,
            () => this.onSelectedBranchIdsChange?.(this.selectedBranchIds),
        );

        reaction(
            () => this.treeSearchResponse,
            (treeSearchResponse) => {
                // open child branches that match search
                this.openedBranchIds = treeSearchResponse.map(
                    (item) => item.id,
                );

                // reset children for search response branches
                treeSearchResponse.forEach((searchBranch) => {
                    searchBranch.children = [];
                });

                // set children based on parentMemberIds
                treeSearchResponse.forEach((searchBranch) => {
                    const currentParentId = searchBranch.parentMemberId;
                    const currentParentBranch = treeSearchResponse.find(
                        (item) => item.id === currentParentId,
                    );
                    if (currentParentBranch) {
                        currentParentBranch.children?.push(searchBranch);
                    }
                });

                // get top level
                const topLevelSearch = treeSearchResponse.filter(
                    (item) => item.branchDepth === 1,
                );
                this.topLevelBranches = topLevelSearch;
            },
        );
    }

    @action
    setIsTreeLoading = (isLoading: boolean) => {
        this.isTreeLoading = isLoading;
    };

    @action
    setHeaderWarningHasRendered = (shown: boolean) => {
        this.headerWarningHasRendered = shown;
    };

    @action
    setOrganizationId = (organizationId: string) => {
        this.organizationId = organizationId;
    };

    @action
    setBranchContent = (branchContent: BranchContentProps) => {
        this.branchContent = branchContent;
    };

    @action
    setSearchString = (searchStr: string) => {
        this.searchString = searchStr;
    };

    @action
    setTreeSearchResponse = (tiers: BranchDataProps[]) => {
        this.treeSearchResponse = tiers;
    };

    @action
    setTopLevelBranches = (topLevelBranches: any[]) => {
        // need to set Branch depth if backend doesnt provide it
        // Note: new backend branch depth starts at 1, old frontend branch depth starts at 0
        if (topLevelBranches.some((item) => !item.branchDepth)) {
            topLevelBranches.forEach((branch) => (branch.branchDepth = 0));
        }

        this.topLevelBranches = topLevelBranches;
    };

    @action
    setTopLevelDataKey = (topLevelDataKey: string) => {
        this.topLevelDataKey = topLevelDataKey;
    };

    @action
    setSelectAllToggle = (isToggled: boolean) => {
        this.selectAllToggle = isToggled;
    };

    @action
    addSelectedBranchId = (id: string, label: string) => {
        if (!this.selectedBranchIds.includes(id)) {
            this.selectedBranchIds.push(id);
            this.selectedBranchLabels.push(label);
        }
    };

    @action
    removeSelectedBranchId = (id: string, label: string) => {
        this.selectedBranchIds = this.selectedBranchIds.filter(
            (branchId) => branchId !== id,
        );
        this.selectedBranchLabels = this.selectedBranchLabels.filter(
            (branchName) => branchName !== label,
        );
    };

    @action
    addSelectAllBranchId = (id: string) => {
        if (!this.selectAllCheckedIds.includes(id)) {
            this.selectAllCheckedIds.push(id);
        }
    };

    @action
    removeSelectAllBranchId = (id: string) => {
        this.selectAllCheckedIds = this.selectAllCheckedIds.filter(
            (branchId) => branchId !== id,
        );
    };

    @action
    setOpenedBranchIds = (ids: string[]) => {
        this.openedBranchIds = ids;
    };

    @action
    addMultipleSelectedBranches = (idArr: string[], labelArr: string[]) => {
        // filter ids and labels not already in array
        let selectedIdsNotInArr = idArr.filter(
            (id) => !this.selectedBranchIds.includes(id),
        );
        let selectedLabelsNotInArr = labelArr.filter(
            (label) => !this.selectedBranchLabels.includes(label),
        );

        this.selectedBranchIds.push(...selectedIdsNotInArr);
        this.selectedBranchLabels.push(...selectedLabelsNotInArr);
    };

    @action
    removeMultipleSelectedBranches = (idArr: string[], labelArr: string[]) => {
        this.selectedBranchIds = this.selectedBranchIds.filter(
            (id) => !idArr.includes(id),
        );

        this.selectedBranchLabels = this.selectedBranchLabels.filter(
            (label) => !labelArr.includes(label),
        );
    };

    @action
    clearSelectedBranchIds = () => {
        this.selectedBranchIds = [];
        this.selectedBranchLabels = [];
        this.selectAllCheckedIds = [];
        this.selectedCategories = [];
        this.selectedSubcategories = [];
    };

    // currently not used in new hierarchy selector
    public getTopLevelBranches(getter: Promise<any>) {
        this.setupAsyncTask(LOAD_TOP_LEVEL, async () => {
            let topLevelBranches = await getter;

            if (this.topLevelDataKey) {
                topLevelBranches = _.get(
                    topLevelBranches,
                    this.topLevelDataKey,
                );
            }

            if (topLevelBranches) {
                this.setTopLevelBranches(topLevelBranches);
            }
        });
    }

    public async getBranchChildren(orgId: string, parentId: string) {
        this.openedBranchIds.push(parentId);

        let response = await this.getBranch?.(orgId, parentId);

        return response;
    }
}
