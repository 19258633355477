import * as _ from "lodash";
import { ValueKeyIteratee } from "lodash";
import {
    CurrencyOptions,
    DelimiterOptions,
} from "models/Reporting/ReportModel";

export const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            // eslint-disable-next-line no-mixed-operators
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        },
    );
};

const appendZero = (num) => (num < 10 ? `0${num}` : num);

export const formatSeconds = (seconds: number) => {
    var minutes = Math.floor(seconds / 60);
    var secs = appendZero(seconds - minutes * 60);
    var res = minutes + ":" + secs;
    return res;
};

export const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

JSON.safeStringify = (obj: any, indent = 2) => {
    let cache = [];
    const retVal = JSON.stringify(
        obj,
        (key: string, value: any) =>
            typeof value === "object" && value !== null
                ? // @ts-ignore
                  cache.includes(value)
                    ? undefined // Duplicate reference found, discard key
                    : // @ts-ignore
                      cache.push(value) && value // Store value in our collection
                : value,
        indent,
    );
    // @ts-ignore
    cache = null;
    return retVal;
};
// eslint-disable-next-line no-extend-native
Array.prototype.insertAt = function <T>(
    this: Array<T>,
    index: number,
    ...elementsArray: T[]
) {
    this.splice(index, 0, ...elementsArray);

    return this;
};

// eslint-disable-next-line no-extend-native
Array.prototype.findAllIndexes = function <T>(
    this: Array<T>,
    predicate: ValueKeyIteratee<T>,
) {
    return _.map(_.keys(_.pickBy(this, predicate)), Number) as number[];
};

export function capitalizeFirstChar(str: unknown) {
    if (typeof str === "string") {
        return str.replace(/^\w/, (c) => c.toUpperCase());
    } else {
        return "";
    }
}

/**
 * generate array of values in [start,stop-1]
 * @param {number} start
 * @param {number} stop non-inclusive
 * @param {number} step
 */
export function range(start: number, stop: number, step = 1) {
    return Array(Math.ceil((stop - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);
}

export function getDelimiterCharacter(inputIndex: number | null) {
    if (inputIndex === null) return "";
    if (DelimiterOptions[inputIndex] === "Dots") {
        return ".";
    } else if (DelimiterOptions[inputIndex] === "Commas") {
        return ",";
    } else if (DelimiterOptions[inputIndex] === "Spaces") {
        return " ";
    }
}

export function getCurrencyCharacter(inputIndex: number | null) {
    if (inputIndex === null) return "";
    if (CurrencyOptions[inputIndex] === "$ - American Dollar") {
        return "$";
    }
}

export const getHAxisHeight = (fontSize: string): string => {
    return `${90 - Number(fontSize) / 5}%`;
};

export const getVAxisWidth = (fontSize: string): string => {
    return `${Number(fontSize) / 5 + 2}%`;
};

export const isNotNull = <T extends unknown>(
    item: T | null | undefined,
): item is T => item !== null && item !== undefined;
