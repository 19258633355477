import { CssBaseline, StyledEngineProvider, Theme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "Theme/AppTheme";
import { Observer } from "mobx-react";
import React from "react";
import WorkflowReview from "./WorkflowReview";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const WorkflowReviewContainer = () => {
    return (
        <Observer>
            {() => (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <WorkflowReview />
                    </ThemeProvider>
                </StyledEngineProvider>
            )}
        </Observer>
    );
};

export default WorkflowReviewContainer;
