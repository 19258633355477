import { Box, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Stack } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    infoIcon: {
        marginRight: theme.spacing(1),
        height: theme.spacing(2.5),
        width: theme.spacing(2.5),
        color: "#9CA3AF",
    },
    tipTitle: {
        fontSize: theme.typography.h3.fontSize,
        fontWeight: 600,
    },
    tip: {
        padding: theme.spacing(1, 2),
    },
}));

const ClassifierBuilderTips = () => {
    const classes = useStyles();
    const tips = [
        "Name your classifier, select the category, and add a description.",
        "Add a group and rule to a classification.",
        "Don't forget to test and publish your classifier.",
    ];
    return (
        <Grid container direction="row">
            {tips.map((tip, idx) => (
                <Grid key={idx} item xs={12} className={classes.tip}>
                    <Stack direction="row">
                        <InfoOutlinedIcon className={classes.infoIcon} />
                        <Box>
                            <Typography className={classes.tipTitle}>{`Tip ${
                                idx + 1
                            }`}</Typography>
                            <Typography>{tip}</Typography>
                        </Box>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
};

export default ClassifierBuilderTips;
