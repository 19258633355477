import * as _ from "lodash";
import {
    action,
    IReactionDisposer,
    makeObservable,
    observable,
    reaction,
    toJS,
} from "mobx";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { WidgetContentType } from "../../../models/Dashboard/Widget";
import { DateReferenceOption } from "../../../stores/ComponentStores/DatePickerComponentStore";
import {
    DashboardStore,
    IDashboardItem,
} from "../../../stores/Layout/DashboardStore";
import {
    CustomDynamicTimeSpan,
    DynamicTimeSpan,
    RefreshInterval,
    timeSpanToMoments,
} from "../../../utils/reportingTimeHelpers";
import { AcxMenuItemProps } from "../../UI/Menu/AcxMenu";

export class BaseWidgetDefinition {
    readonly dashboardStoreAccessor: () => DashboardStore;

    dashboardItem_?: IDashboardItem;
    @observable title?: string;
    @observable loading = false;
    @observable.ref menuItemsBuilder?: (
        navigate: NavigateFunction,
        dashboardStore?: DashboardStore,
        closeMenu?: () => void,
    ) => AcxMenuItemProps[];

    @observable styles?: React.CSSProperties;
    @observable useCardTitleAndMenu = true;
    @observable viewModelReady: boolean = true;
    @observable protected error?: string;

    private readonly widgetKey: string;
    protected disposers: IReactionDisposer[] = [];
    private refreshIntervalTimer;

    constructor(
        dashboardItem: IDashboardItem,
        public refreshInterval: RefreshInterval,
        public timeSpan: DynamicTimeSpan | CustomDynamicTimeSpan,
        public dateReferenceOption: DateReferenceOption,
        dashboardStore: () => DashboardStore,
        public hierarchyIds?: string[],
    ) {
        makeObservable(this);

        this.widgetKey = dashboardItem.key;
        this.dashboardStoreAccessor = dashboardStore;
        this.dashboardItem_ = dashboardItem;
        this.title = this.dashboardItem_?.name;

        this.styles = dashboardItem.args.isTable
            ? ({
                  backgroundColor: "unset",
                  boxShadow: "none",
                  padding: "unset",
              } as React.CSSProperties)
            : undefined;

        this.setupPeriodicRefresh(
            dashboardItem.contentType,
            dashboardItem.refresh ?? refreshInterval,
        );

        this.disposers.push(
            reaction(
                (r) =>
                    _.omit(
                        toJS(
                            this.dashboardStoreAccessor().dashboardItems?.find(
                                (v) => v.key === this.widgetKey,
                            ),
                        ),
                        "widgetDefinition",
                    ),
                (arg) => {
                    if (!arg) {
                        return;
                    }

                    this.dashboardItem_ = arg as IDashboardItem;
                },
                { fireImmediately: true, delay: 0 },
            ),
        );
    }

    get dashboardItem() {
        return (
            this.dashboardStoreAccessor().dashboardItems?.find(
                (v) => v.key === this.widgetKey,
            ) ?? this.dashboardItem_
        );
    }

    private setupPeriodicRefresh(
        contentType: WidgetContentType,
        refreshInterval: RefreshInterval,
    ) {
        clearInterval(this.refreshIntervalTimer);

        if (
            contentType === WidgetContentType.DYNAMIC &&
            refreshInterval !== RefreshInterval.OFF
        ) {
            // const intervalMillis = refreshIntervalToMillis(refreshInterval);
            // TODO temporarily disable this since it breaks for users who have the ability to switch tenants
            // this.refreshIntervalTimer = setInterval(
            //     () => this.refresh(this.dashboardItem_?.dateReference ?? this.dateReferenceOption,
            //                        ...timeSpanToMoments(this.dashboardItem_?.timeSpan ?? this.timeSpan)),
            //     intervalMillis);
        }
    }

    @action
    setGlobalTimespan = (timeSpan: DynamicTimeSpan | CustomDynamicTimeSpan) => {
        this.timeSpan = timeSpan;
        if (this.dashboardItem?.timeSpan) {
            this.refresh(
                this.dashboardItem.dateReference ?? this.dateReferenceOption,
                ...timeSpanToMoments(this.timeSpan),
                this.hierarchyIds,
            );
        } else if (this.dashboardItem_) {
            this.refresh(
                this.dateReferenceOption,
                ...timeSpanToMoments(this.timeSpan),
                this.hierarchyIds,
            );
            this.setupPeriodicRefresh(
                this.dashboardItem_?.contentType,
                this.refreshInterval,
            );
        }
    };

    @action
    setGlobalDateReference(dateReferenceOption: DateReferenceOption) {
        this.dateReferenceOption = dateReferenceOption;
        this.setGlobalTimespan(this.timeSpan);
    }

    @action
    setGlobalRefreshInterval(refreshInterval: RefreshInterval) {
        this.refreshInterval = refreshInterval;
        if (this.dashboardItem?.refresh) {
            return;
        } else if (this.dashboardItem_) {
            this.setupPeriodicRefresh(
                this.dashboardItem_.contentType,
                this.refreshInterval,
            );
        }
    }

    @action
    setGlobalHierarchyIds(hierarhcyIds: string[]) {
        this.hierarchyIds = hierarhcyIds;
        console.log(this.hierarchyIds);
        if (this.dashboardItem_) {
            this.refresh(
                this.dateReferenceOption,
                ...timeSpanToMoments(this.timeSpan),
                this.hierarchyIds,
            );
        }
    }

    @action
    setItemTimespan(timeSpan?: DynamicTimeSpan | CustomDynamicTimeSpan | null) {
        const item = this.dashboardItem ?? this.dashboardItem_;
        if (!item?.key) {
            console.log(
                `BaseWidgetDef:setItemTimespan: CANT FIND KEY - ${this.title}`,
            );
            return;
        }

        this.dashboardStoreAccessor().updateItemTimeSpan(timeSpan, item?.key);
        this.refresh(
            item.dateReference ?? this.dateReferenceOption,
            ...timeSpanToMoments(timeSpan ?? this.timeSpan),
            this.hierarchyIds,
        );
        this.setupPeriodicRefresh(
            item.contentType,
            item.refresh ?? this.refreshInterval,
        );
    }

    @action
    setItemDateReference(dateReferenceOption?: DateReferenceOption | null) {
        const item = this.dashboardItem ?? this.dashboardItem_;
        if (!item?.key) {
            return;
        }

        this.dashboardStoreAccessor().updateItemDateReference(
            dateReferenceOption,
            item?.key,
        );
        this.setItemTimespan(item.timeSpan ?? this.timeSpan);
    }

    @action
    setItemRefreshInterval(refreshInterval?: RefreshInterval | null) {
        const item = this.dashboardItem ?? this.dashboardItem_;
        if (!item?.key) {
            return;
        }

        this.dashboardStoreAccessor().updateItemRefreshInterval(
            refreshInterval,
            item?.key,
        );
        this.setupPeriodicRefresh(
            item.contentType,
            refreshInterval ?? this.refreshInterval,
        );
    }

    @action
    setItemHierarchyIds(hierarchyIds?: string[]) {
        const item = this.dashboardItem ?? this.dashboardItem_;
        if (!item?.key) {
            return;
        }

        this.dashboardStoreAccessor().updateItemHierarchyIds(
            hierarchyIds,
            item?.key,
        );

        this.refresh(
            this.dateReferenceOption,
            ...timeSpanToMoments(this.timeSpan),
            hierarchyIds,
        );
    }

    @action
    setItemCustomArgs(key: string, value: any) {
        const item = this.dashboardItem ?? this.dashboardItem_;
        if (!item?.key) {
            return;
        }

        this.dashboardStoreAccessor().updateItemCustomArgs(
            key,
            value,
            item?.key,
        );
        this.refresh(
            this.dashboardItem_?.dateReference ?? this.dateReferenceOption,
            ...timeSpanToMoments(
                this.dashboardItem_?.timeSpan ?? this.timeSpan,
            ),
            this.hierarchyIds,
        );
    }

    dispose = () => {
        this.error = undefined;
        this.loading = false;
        clearInterval(this.refreshIntervalTimer);
        this.disposers.forEach((value) => {
            try {
                value();
            } finally {
                //no-op
            }
        });

        this.disposers = [];
        this.dashboardItem_ = undefined;
    };

    @action.bind(this)
    updateViewModelParams(args: any) {}

    @action.bind(this)
    refresh(...args: any[]) {
        this.error = undefined;
    }

    render = (...args: any[]) => {
        return <></>;
    };
}
