import { action, computed, makeObservable, observable, reaction } from "mobx";
import moment from "moment";
import Evaluation from "../../../models/Evaluation";
import { LMType } from "../../../models/LicensedModule";
import {
    AssignedWorkflowInstance,
    WorkflowStatus,
} from "../../../models/Workflows/WorkflowInstance";
import { WorkflowInstanceLevel } from "../../../models/Workflows/WorkflowInstanceLevel";
import { WorkflowMessagingMetadata } from "../../../models/Workflows/WorkflowMessagingMetadata";
import { WorkflowMetadata } from "../../../services/EvaluationService";
import { BaseStore } from "../../../stores/BaseStore";
import { WorkflowConditionType } from "../../Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import { WorkflowLevelProps } from "../Views/Modules/Capa/WorkflowInstanceLevel";
import { EvalLicensedModule, EvalStore } from "./EvalStore";

export class EvalWorkflowStore extends BaseStore {
    static RejectResult: "reject" = "reject";
    static ApproveResult: "approve" = "approve";
    static CompletedResult: "complete" = "complete";

    readonly evalStore: EvalStore;

    @observable private modulesNameById: Map<string, string> = new Map<
        string,
        string
    >();

    @observable.ref private workflowMetadata: Record<
        string /*keyed by instanceId*/,
        WorkflowMetadata[]
    > = {};

    constructor(evalStore: EvalStore) {
        super("Eval Workflow");
        makeObservable(this);
        this.evalStore = evalStore;

        reaction(
            (r) => this.evalStore.licensedModulesForEval,
            (arg) => {
                arg?.filter(
                    (value) => value.lmType !== LMType.Workflow,
                ).forEach((value) => {
                    this.modulesNameById.set(value.id, value.name!);
                });
            },
        );
    }

    @action
    reset() {
        this.modulesNameById.clear();
    }

    @action
    initialize(workflowMetadata: Record<string, WorkflowMetadata[]>) {
        this.workflowMetadata = workflowMetadata;
    }

    @action
    onCompleteLevel = (
        instanceId: string,
        workflowModuleId: string,
        level: number,
        notes: string,
        result: "approve" | "reject" | null,
    ) => {
        const instance = this.evalStore.currentEval?.workflowInstances!.find(
            (value) => value.instanceId === instanceId,
        );

        if (instance) {
            const activeLevel = instance.workflowInstanceLevels.find(
                (value) => value.workflowMessagingMetadata.level === level,
            );

            const workflowMessagingMetadataId: WorkflowMetadata =
                this.workflowMetadata[instanceId].find(
                    (value) => value.level === level,
                )!;

            if (!activeLevel) {
                instance.workflowInstanceLevels.push({
                    note: notes,
                    result: result,
                    createdOn: moment().local().toISOString(),
                    createdBy: this.evalStore.user?.profile?.email,
                    userId: this.evalStore.user?.profile?.sub,
                    workflowInstanceId: instance.id,
                    workflowMessagingMetadata:
                        workflowMessagingMetadataId as any,
                    workflowMessagingMetadataId: workflowMessagingMetadataId.id,
                    evaluationModuleId: workflowModuleId,
                } as WorkflowInstanceLevel);
            } else {
                activeLevel.note = notes;
                activeLevel.result = result;
                activeLevel.createdOn = moment().local().toISOString();
                activeLevel.createdBy = this.evalStore.user?.profile?.email;
                activeLevel.userId = this.evalStore.user?.profile?.sub;
                activeLevel.workflowInstanceId = instance.id;
                activeLevel.workflowMessagingMetadata =
                    workflowMessagingMetadataId as any;
                activeLevel.workflowMessagingMetadataId =
                    workflowMessagingMetadataId!.id;
                activeLevel.evaluationModuleId = workflowModuleId;
            }
        }
    };

    /**
     * called on eval save-as-complete to remove a non boss workflow module that is incomplete
     * this is because the module and answers should not be saved if the user did not complete or reject the level
     *
     * @param evaluation
     */
    @action
    removeUncompletedNonBossModules(evaluation: Evaluation) {
        for (let instance of this.workflowInstances) {
            for (let level of instance.levels) {
                //this level has a module but it is still pending
                if (level.workflowEvalModule && level.state === "pending") {
                    evaluation.answers = evaluation.answers.filter(
                        (value) =>
                            value.evaluationModuleId !==
                            level.workflowEvalModule?.evaluationModuleId,
                    );

                    evaluation.evaluationModules =
                        evaluation.evaluationModules.filter(
                            (value) =>
                                value.id !==
                                level.workflowEvalModule?.evaluationModuleId,
                        );
                }
            }
        }
    }

    /**
     * called on eval save-as-complete to append onto the instanceLevel array
     * levels that represent completed boss levels..since boss levels are completed as
     * modules, the levels need to be appended manually so the aging-view recognizes the workflow as complete.
     *
     * @param evaluation
     */
    @action
    completeBossLevels(evaluation: Evaluation) {
        for (let instance of this.assignedWorkflowInstancesForEval(
            evaluation,
        )) {
            const { activeLevel, metadataForLevel } =
                this.activeLevelForInstance(instance);

            // TODO handle dispute boss here or other place?
            if (
                !instance.workflowInstanceLevels.some(
                    (value) =>
                        value.workflowMessagingMetadata.level < activeLevel &&
                        (value.result === EvalWorkflowStore.RejectResult ||
                            value.result === EvalWorkflowStore.CompletedResult),
                ) &&
                instance.bossEvalModuleId &&
                !instance.workflowInstanceLevels.some(
                    (value) =>
                        value.workflowMessagingMetadataId ===
                        metadataForLevel.id,
                )
            ) {
                instance.workflowInstanceLevels.push({
                    workflowMessagingMetadataId: metadataForLevel.id,
                    result: EvalWorkflowStore.CompletedResult,
                    createdOn: moment().local().toISOString(),
                    createdBy: this.evalStore.user?.profile?.email,
                    userId: this.evalStore.user?.profile?.sub,
                    workflowInstanceId: instance.id,
                    workflowMessagingMetadata:
                        metadataForLevel as WorkflowMessagingMetadata,
                    evaluationModuleId: instance.bossEvalModuleId,
                } as WorkflowInstanceLevel);
            }
        }
    }

    validate() {
        let isValid = true;

        const instances = this.assignedWorkflowInstancesForEval();

        instances
            .filter(
                (value) =>
                    value.implementationType === WorkflowConditionType.APT,
            )
            .forEach((instance) => {
                const level = instance.workflowInstanceLevels.find(
                    (level) =>
                        level.result === null &&
                        level.workflowMessagingMetadata?.licensedModuleId ===
                            null,
                );

                if (level !== undefined) isValid = false;
            });

        return isValid;
    }

    isBossModuleForActiveInstance(workflowModule: EvalLicensedModule) {
        const instances = this.assignedWorkflowInstancesForEval();

        // TODO needs to handle dispute boss levels
        const res = !!instances.find((instance) => {
            const { activeLevel, metadataForLevel } =
                this.activeLevelForInstance(instance);

            return (
                metadataForLevel.licensedModuleId &&
                metadataForLevel.licensedModuleId === workflowModule.id &&
                instance.bossEvalModuleId ===
                    workflowModule.evaluationModuleId &&
                !instance.workflowInstanceLevels.some(
                    (value) =>
                        value.workflowMessagingMetadata.level < activeLevel &&
                        (value.result === EvalWorkflowStore.RejectResult ||
                            value.result === EvalWorkflowStore.CompletedResult),
                )
            );
        });

        return res;
    }

    get disputeBossLevelExists() {
        if (!this.evalStore.currentEval) {
            return false;
        }

        const disputeInstances = this.listDisputeInstances(
            this.evalStore.currentEval,
        );

        if (!disputeInstances.length) {
            return false;
        }
        return this.disputeConditionAtBossLevel(disputeInstances);
    }

    private disputeConditionAtBossLevel(AssignedWorkflowInstance);
    private disputeConditionAtBossLevel(
        disputeInstances: AssignedWorkflowInstance[],
    ): any {
        if (!Array.isArray(disputeInstances)) {
            disputeInstances = [disputeInstances];
        }

        const anyBossLevels = disputeInstances.map((disputeInstance) => {
            const meta = this.workflowMetadata[disputeInstance.instanceId];
            const completedLevels =
                disputeInstance.workflowInstanceLevels.length;
            const totalLevels = meta.length;

            const possiblyAtBossLevel = completedLevels >= totalLevels - 1;
            if (!possiblyAtBossLevel) {
                return false;
            }

            const finishedEarly = disputeInstance.workflowInstanceLevels.some(
                (wil) => {
                    const isComplete =
                        wil.result === EvalWorkflowStore.CompletedResult;

                    return isComplete && !(completedLevels === totalLevels);
                },
            );

            return !finishedEarly && possiblyAtBossLevel;
        });

        const allTrue = anyBossLevels.every((value) => value);
        const allFalse = anyBossLevels.every((value) => !value);

        if (allTrue) {
            return true;
        } else return !allFalse;
    }

    getModuleWorkflowCondition(moduleId) {
        for (let workflowInstance of this.assignedWorkflowInstancesForEval()) {
            if (
                workflowInstance.workflowCondition.licensedModuleId === moduleId
            )
                return workflowInstance.workflowCondition;
        }
    }


    private instanceTitle(
        instance: AssignedWorkflowInstance,
        type: WorkflowConditionType,
    ) {
        switch (type) {
            case WorkflowConditionType.APT:
                return `${this.modulesNameById.get(
                    instance.questionModuleId,
                )} - `;
            case WorkflowConditionType.EvaluationDispute:
                return instance.conditionName;
            case WorkflowConditionType.ScoreEscalation:
                return `${instance.conditionName} - `;
            case WorkflowConditionType.ClassifierResult:
                return instance.conditionName;
            case WorkflowConditionType.WorkflowConditionBase:
                return instance.conditionName;
        }
    }

    private instanceSubTitle(
        instance: AssignedWorkflowInstance,
        type: WorkflowConditionType,
    ) {
        switch (type) {
            case WorkflowConditionType.APT:
                return instance.question;
            case WorkflowConditionType.EvaluationDispute:
                break;
            case WorkflowConditionType.ScoreEscalation:
                return `${this.modulesNameById.get(
                    instance.workflowCondition.licensedModuleId!,
                )}`;
        }
    }

    @computed
    get workflowInstances(): {
        subTitle?: string;
        name: string;
        title: string;
        workflowType: WorkflowConditionType;
        levels: WorkflowLevelProps[];
    }[] {
        const workflowEvalModules: EvalLicensedModule[] =
            this.evalStore.licensedModulesForEval?.filter(
                (value) => value.lmType === LMType.Workflow,
            ) ?? [];

        const workflowMetadata = this.workflowMetadata;
        const instances = this.pruneEarlyCompleteDisputeInstances(
            this.assignedWorkflowInstancesForEval(),
        );

        const projectedInstances = instances
            .filter(
                (value) =>
                    (value.implementationType !== WorkflowConditionType.APT &&
                        this.disputeConditionAtBossLevel(value)) ||
                    value.implementationType === WorkflowConditionType.APT,
            )
            .map((assignedInstance) => {
                const workflowInstances = {
                    name: assignedInstance.conditionName,
                    title: this.instanceTitle(
                        assignedInstance,
                        assignedInstance.implementationType!,
                    ),
                    subTitle: this.instanceSubTitle(
                        assignedInstance,
                        assignedInstance.implementationType!,
                    ),
                    workflowType: assignedInstance.implementationType!,
                    levels: assignedInstance.workflowInstanceLevels.map(
                        (instanceLevel) => {
                            return {
                                levelType: assignedInstance.implementationType,
                                state:
                                    instanceLevel.evaluationModuleId ===
                                        assignedInstance.bossEvalModuleId &&
                                    assignedInstance.bossEvalModuleId !== null
                                        ? "boss"
                                        : instanceLevel.result !== null
                                        ? "complete"
                                        : "pending",
                                instanceId: assignedInstance.instanceId,
                                workflowEvalModule:
                                    instanceLevel.evaluationModuleId
                                        ? workflowEvalModules.find((value) => {
                                              return (
                                                  value.evaluationModuleId ===
                                                  instanceLevel.evaluationModuleId
                                              );
                                          })
                                        : null,

                                notes: instanceLevel.note,
                                result: instanceLevel.result,
                                createdBy: instanceLevel.createdBy,
                                createdOn: instanceLevel.createdOn,
                                level: instanceLevel.workflowMessagingMetadata
                                    .level,
                                metadataId:
                                    instanceLevel.workflowMessagingMetadataId,
                                name: instanceLevel.workflowMessagingMetadata
                                    .name,
                            } as WorkflowLevelProps;
                        },
                    ),
                };

                const activeLevelMetadatas =
                    workflowMetadata[assignedInstance.instanceId];

                const activeLevelMetadata = activeLevelMetadatas.find(
                    (value) => value.level === assignedInstance.activeLevel,
                );

                if (
                    !workflowInstances.levels.some(
                        (value) => value.level === assignedInstance.activeLevel,
                    ) &&
                    assignedInstance.status !== WorkflowStatus.Closed
                ) {
                    workflowInstances.levels.push({
                        levelType: assignedInstance.implementationType,
                        state:
                            activeLevelMetadata ===
                            activeLevelMetadatas[
                                activeLevelMetadatas.length - 1
                            ]
                                ? "boss"
                                : "pending",
                        instanceId: assignedInstance.instanceId,
                        workflowEvalModule: null,
                        result: null,
                        notes: "",
                        level: assignedInstance.activeLevel,
                        metadataId: activeLevelMetadata!.id,
                        createdBy: this.evalStore.user?.profile?.email,
                        createdOn: moment().local().toISOString(),
                        name: activeLevelMetadata?.name,
                    } as WorkflowLevelProps);
                }

                return workflowInstances;
            });

        //just for last pending level, need to assign correct eval module if needed.  Make sure level does not share the same eval module
        for (let instance of projectedInstances) {
            let lastAddedLevel = instance.levels[instance.levels.length - 1];

            const activeLevelMetadatas =
                workflowMetadata[lastAddedLevel.instanceId];

            const activeLevelMetadata = activeLevelMetadatas.find(
                (value) => value.level === lastAddedLevel.level,
            );

            if (
                activeLevelMetadata?.licensedModuleId &&
                lastAddedLevel.workflowEvalModule === null
            ) {
                var projectedLevels = projectedInstances
                    .map((instance) => instance.levels)
                    .reduce((a, b) => a.concat(b));

                lastAddedLevel.workflowEvalModule =
                    this.findUnusedEvaluationWorkflowModule(
                        activeLevelMetadata,
                        workflowEvalModules,
                        projectedLevels,
                    );
            }
        }

        projectedInstances.forEach((value: any) => {
            value.levels = value.levels
                .slice()
                .sort((a, b) => (a.level > b.level ? -1 : 1));
        });

        return projectedInstances;
    }

    private assignedWorkflowInstancesForEval(
        evaluation?: Evaluation,
    ): AssignedWorkflowInstance[] {
        const assignedInstances = ((evaluation ?? this.evalStore.currentEval)
            ?.workflowInstances ?? []) as AssignedWorkflowInstance[];

        return assignedInstances;
    }

    // level 0,1,2
    // metaLevels.length = 3
    private pruneEarlyCompleteDisputeInstances(
        instances: AssignedWorkflowInstance[],
    ) {
        return instances.filter((instance) => {
            const metaLevels = this.workflowMetadata[instance.instanceId];
            if (instance.implementationType !== WorkflowConditionType.APT) {
                const isCompleteBeforeBoss =
                    instance.workflowInstanceLevels.some(
                        (level) =>
                            level.result ===
                                EvalWorkflowStore.CompletedResult &&
                            metaLevels.length - 1 >
                                level.workflowMessagingMetadata.level,
                    );

                return !isCompleteBeforeBoss;
            }
            return true;
        });
    }

    private activeLevelForInstance(instance: AssignedWorkflowInstance): {
        activeLevel: number;
        metadataForLevel: WorkflowMetadata;
    } {
        const activeLevel = instance.activeLevel!;

        const metadataForLevel = this.workflowMetadata[
            instance.instanceId
        ].find((value) => value.level === activeLevel)!;
        return { activeLevel, metadataForLevel };
    }

    private listDisputeInstances(
        evaluation: Evaluation,
    ): AssignedWorkflowInstance[] {
        const instances = this.assignedWorkflowInstancesForEval(evaluation);

        return instances.filter((value) => {
            return [
                WorkflowConditionType.EvaluationDispute,
                WorkflowConditionType.ScoreEscalation,
            ].includes(value.implementationType!);
        });
    }

    private findUnusedEvaluationWorkflowModule(
        metaData: WorkflowMetadata,
        evalLicenceModules: EvalLicensedModule[],
        workflowLevelProps: WorkflowLevelProps[],
    ): EvalLicensedModule | null {
        //get list of modules with the same licence module id as the one we are looking for
        var modules = evalLicenceModules.filter(
            (module) => module.id === metaData.licensedModuleId,
        );

        //if a level has a modules eval module Id, it is one already being used
        for (let module of modules) {
            var found = false;

            for (let levelProp of workflowLevelProps) {
                if (
                    levelProp.workflowEvalModule?.evaluationModuleId ===
                    module.evaluationModuleId
                ) {
                    found = true;
                    continue;
                }
            }

            if (found) continue;
            return module;
        }

        //unused module was not found, return null for default workflow module
        return null;
    }

    @action
    public addIntermediateLevelToDisputeInstance = (
        currentEval: Evaluation,
    ) => {
        const disputeInstances = this.listDisputeInstances(currentEval);

        for (let disputeInstance of disputeInstances) {
            const metaLevels =
                this.workflowMetadata[disputeInstance.instanceId];

            const { activeLevel, metadataForLevel } =
                this.activeLevelForInstance(disputeInstance);

            if (metaLevels.length - 1 === activeLevel) {
                // delete to this.CompleteBossLevels for  adding boss level
                continue;
            }

            if (!disputeInstance.workflowInstanceLevels?.length) {
                // create array and add level

                disputeInstance.workflowInstanceLevels = [
                    {
                        workflowMessagingMetadataId: metadataForLevel.id,
                        result: null,
                        createdOn: moment().local().toISOString(),
                        createdBy: this.evalStore.user?.profile?.email,
                        userId: this.evalStore.user?.profile?.sub,
                        workflowInstanceId: disputeInstance.id,
                        workflowMessagingMetadata:
                            metadataForLevel as WorkflowMessagingMetadata,
                        evaluationModuleId: metadataForLevel.licensedModuleId
                            ? disputeInstance.bossEvalModuleId
                            : null,
                    } as WorkflowInstanceLevel,
                ];
            } else {
                if (
                    !disputeInstance.workflowInstanceLevels.some(
                        (value) =>
                            value.workflowMessagingMetadata.level ===
                            disputeInstance.activeLevel,
                    ) &&
                    disputeInstance.status !== WorkflowStatus.Closed
                ) {
                    disputeInstance.workflowInstanceLevels.push({
                        workflowMessagingMetadataId: metadataForLevel.id,
                        result: null,
                        createdOn: moment().local().toISOString(),
                        createdBy: this.evalStore.user?.profile?.email,
                        userId: this.evalStore.user?.profile?.sub,
                        workflowInstanceId: disputeInstance.id,
                        workflowMessagingMetadata:
                            metadataForLevel as WorkflowMessagingMetadata,
                        evaluationModuleId: metadataForLevel.licensedModuleId
                            ? disputeInstance.bossEvalModuleId
                            : null,
                    } as WorkflowInstanceLevel);
                }
            }
        }
    };
}
