import { IReport } from "components/ReportsRv2/Stores/ReportsStore";
import { ReportDatum } from "models/Reporting/ReportDatum";
import { ReportField } from "models/Reporting/ReportField";
import { ReportFilter } from "models/Reporting/ReportFilter";
import { ReportGroup } from "models/Reporting/ReportGroup";
import { ReportModel } from "models/Reporting/ReportModel";
import TableReportData from "models/Reporting/TableReportData";
import { Moment } from "moment";
import { ReportDataView } from "../models/Reporting/ReportDataView";
import { DateReferenceOption } from "../stores/ComponentStores/DatePickerComponentStore";
import { toIsoByDateReference } from "../utils/DateTimeUtils";
import BaseService from "./BaseService";

type reportDataPayload = { data: ReportDatum[] };
type reportsPayload = { reports: ReportModel[] };
type reportsPayloadV2 = {
    reports: IReport[];
    refreshStats: { daily: string; hourly: string };
};
type quickFilterPayload = {
    filterFields: ReportField[];
    validValues: { [fieldName: string]: string[] };
};

export class ReportsService extends BaseService {
    protected urlBase: string = "api/Reports";

    constructor(apiVersion = "1.0") {
        super();

        this.apiVersion = apiVersion;
    }

    async getReportGroups(orgId?: string) {
        const path = "ReportGroups";
        const params = new URLSearchParams();
        if (orgId) {
            params.set("orgId", orgId);
        }

        const res = await this.get<ReportGroup[]>(params, path);
        return res;
    }

    async getTableReportDataByReportId(
        orgId: string | undefined,
        reportId: string,
        startDate: Moment,
        endDate: Moment,
        referenceOption: DateReferenceOption,
        quickFilters: ReportFilter[],
        debounceKey?: string,
        hierarchyIds?: string[],
        agentId?: string,
        abortSignal?: AbortSignal
    ) {
        const path = "TableReportDataByReportId";
        const dates = toIsoByDateReference(startDate, endDate, referenceOption);
        const bodyObject = {
            organizationId: orgId,
            reportId,
            dateOptions: {
                startDate: dates.beginDate,
                endDate: dates.endDate,
                referenceOption,
            },
            quickFilters,
            hierarchyIds,
            dashboardFilters: { agentId }
        };

        const params = new URLSearchParams({});

        const body = JSON.stringify(bodyObject);

        const res = await this.post<TableReportData>(
            body,
            params,
            path,
            undefined,
            debounceKey,
            abortSignal,
        );
        return res;
    }

    async getReportDataByReportId(
        orgId: string | undefined,
        reportId,
        startDate: Moment,
        endDate: Moment,
        referenceOption: DateReferenceOption,
        quickFilters: ReportFilter[],
        debounceKey?: string,
        hierarchyIds?: string[],
        agentId?: string,
        abortSignal?: AbortSignal
    ) {
        const path = "ReportDataByReportId";
        const dates = toIsoByDateReference(startDate, endDate, referenceOption);
        const bodyObject = {
            organizationId: orgId,
            reportId,
            dateOptions: {
                startDate: dates.beginDate,
                endDate: dates.endDate,
                referenceOption,
            },
            quickFilters,
            hierarchyIds,
            dashboardFilters: { agentId }
        };

        const params = new URLSearchParams({});

        const body = JSON.stringify(bodyObject);

        const res = await this.post<reportDataPayload>(
            body,
            params,
            path,
            undefined,
            debounceKey,
            abortSignal,
        );
        return res.data;
    }

    async getQuickFilters(
        reportId: string,
        debounceKey?: string,
    ) {
        const path = "QuickFilters";
        const params = new URLSearchParams({
            reportId,
        });

        const payload = await this.get<quickFilterPayload>(
            params,
            path,
            undefined,
            debounceKey,
        );
        return payload;
    }

    async getReports(orgId?: string) {
        const path = "Reports";
        const params = new URLSearchParams();
        if (orgId) {
            params.set("organizationId", orgId);
        }

        const res = await this.get<reportsPayload>(params, path);

        return res.reports;
    }

    async getReportsV2(orgId?: string) {
        const path = "Reports";
        const params = new URLSearchParams();
        if (orgId) {
            params.set("organizationId", orgId);
        }

        const res = await this.get<reportsPayloadV2>(params, path);

        return res;
    }

    async getReport(reportId: string, debounceKey?: string) {
        const path = "Report";
        const params = new URLSearchParams({ reportId });

        const res = await this.get<ReportModel>(
            params,
            path,
            undefined,
            debounceKey,
        );

        if (typeof res.vizOptions === "string") {
            res.vizOptions = JSON.parse(res.vizOptions ?? "{}");
        }

        return res;
    }

    async getReportDataViews(orgId?: string) {
        const path = "ReportDataViews";
        const params = new URLSearchParams();
        if (orgId) {
            params.set("orgId", orgId);
        }

        const res = await this.get<ReportDataView[]>(params, path);

        return res;
    }

    async createReport(report: ReportModel, orgId?: string) {
        const path = "Report";
        const params = new URLSearchParams();
        if (orgId) {
            params.set("orgId", orgId);
        }

        if (typeof report.vizOptions === "object") {
            report.vizOptions = JSON.stringify(report.vizOptions ?? {});
        }

        const body: string = JSON.stringify(report);
        const res = await this.post(body, params, path);

        return res;
    }

    async updateReport(report: ReportModel, orgId?: string) {
        const path = "Report";
        const params = new URLSearchParams();
        if (orgId) {
            params.set("orgId", orgId);
        }

        if (typeof report.vizOptions === "object") {
            report.vizOptions = JSON.stringify(report.vizOptions ?? {});
        }

        const body: string = JSON.stringify(report);
        const res = await this.put(params, path, body);
        if (typeof res.vizOptions === "string") {
            res.vizOptions = JSON.parse(res.vizOptions ?? "{}");
        }
        return res;
    }

    async createGroup(group: ReportGroup) {
        const path = "CreateGroup";
        const params = new URLSearchParams();

        const res = await this.post(JSON.stringify(group), params, path);

        return res as ReportGroup[];
    }

    async deleteReport(reportId: string) {
        const path = `Report/${reportId}`;

        const params = new URLSearchParams();

        await this.delete(params, path);
    }

    async drillIntoReport(
        reportId: string,
        orgId: string | undefined,
        xAxisLabel: string,
        seriesLabel: string,
        startDate: Moment,
        endDate: Moment,
        referenceOption: DateReferenceOption,
        quickFilters: ReportFilter[],
        debounceKey?: string,
    ) {
        const path = "DrillIntoReport";
        const params = new URLSearchParams({ reportId });
        if (xAxisLabel) {
            params.set("xAxisLabel", xAxisLabel);
        }

        if (seriesLabel) {
            params.set("seriesLabel", seriesLabel);
        }
        if (orgId) {
            params.set("orgId", orgId);
        }
        const dates = toIsoByDateReference(startDate, endDate, referenceOption);
        params.set("startDate", dates.beginDate!);
        params.set("endDate", dates.endDate!);
        params.set("referenceOption", referenceOption);

        const res = await this.post<TableReportData>(
            JSON.stringify(quickFilters),
            params,
            path,
            undefined,
            debounceKey,
        );

        return res;
    }

    /* async getDataSourcePreview(
        organizationId: string,
        dataSourceId: string,
        startDate?: string,
    ) {
        const path = "DataSourcePreview";
        let params;
        if (startDate) {
            params = new URLSearchParams({
                organizationId,
                dataSourceId,
                startDate,
            });
        } else {
            params = new URLSearchParams({
                organizationId,
                dataSourceId,
            });
        }

        const res = await this.post("", params, path);

        return res;
    }*/

    async getPreviewReportData(
        orgId: string | undefined,
        report: ReportModel | undefined,
    ) {
        const path = "Preview";
        const params = new URLSearchParams();
        if (orgId) {
            params.set("orgId", orgId);
        }

        if (typeof report?.vizOptions === "object") {
            report.vizOptions = JSON.stringify(report.vizOptions ?? {});
        }

        const body: string = JSON.stringify(report);

        const res = await this.post<reportDataPayload>(body, params, path);
        return res;
    }
}
