import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import React from "react";
import { OrganizationService } from "services/OrganizationService";
import { BaseStore } from "stores/BaseStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import VerticalDrawerContentTemplate from "../Drawer/VerticalDrawerContentTemplate";
import { IHierarchyDetailsFabStore } from "./AcxHierarchyDetailsFab";

export const LOAD_HIERARCHIES = "Load Flattened Hierarchies For User";

@AcxStore
export default class AcxHierarchyDetailsFabStore
    extends BaseStore
    implements IHierarchyDetailsFabStore
{
    private readonly orgService = new OrganizationService();

    readonly drawerStore: LayoutDrawerStore;

    @observable
    orgId?: string;

    @observable
    userId?: string;

    @observable
    hierarchies?: { label: string; id: string }[];

    constructor(public rootStore: IRootStore) {
        super("HierarchyDetailsFabStore");
        makeObservable(this);

        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);

        reaction(
            () => ({ orgId: this.orgId, userId: this.userId }),
            (args) => {
                if (args.orgId && args.userId) {
                    this.populateUserHierarchies();
                }
            },
            { fireImmediately: true },
        );
    }

    @action
    setParams = (orgId: string, userId: string) => {
        runInAction(() => {
            this.orgId = orgId;
            this.userId = userId;
        });
    };

    @action
    populateUserHierarchies = () => {
        if (!this.orgId || !this.userId) {
            return;
        }

        this.setupAsyncTask(LOAD_HIERARCHIES, async () => {
            const hierarchies =
                await this.orgService.getFlattenedServiceHierarchyForUser(
                    this.orgId!,
                    this.userId!,
                );

            this.hierarchies = hierarchies;
        });
    };

    @action
    displayView = (content: React.ReactNode, mainContentClass?: string) => {
        this.drawerStore.closeAndResetDrawer();
        this.drawerStore.restoreDefaults();

        this.drawerStore.setContentFactory(() => (
            <VerticalDrawerContentTemplate
                customMarginTop="0px"
                width="450px"
                title="User Hierarchies"
                content={content}
                mainContentClass={mainContentClass}
                titleStyles={{ padding: "0.75rem" }}
                contentStyles={{ padding: "0.75rem", height: "85%" }}
            />
        ));

        this.drawerStore.openDrawer();
    };
}
