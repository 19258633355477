import { Checkbox, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import AcxWideTooltip from "components/UI/AcxWideTooltip";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import sanitizeHtml from "sanitize-html";
import Question from "../../../../models/Question";
import { Tag } from "../../../../models/Tag";
import InfoSvg from "../../../../SvgIcons/InfoSvg";
import theme from "../../../../Theme/AppTheme";
import { useStore } from "../../../../utils/useStore";
import AcxStarRating from "../../../UI/AcxStarRating";
import { WorkflowReviewStore } from "components/WorkflowReview/Stores/WorkflowReviewStore";
import { ModuleUIModel } from "components/Evaluation/Models/ModuleUIModel";
import WFRSmartPredictToggle from "../WFRSmartPredictToggle";
import WFRAIExplanationCards from "../WFRAIExplanationCard/WFRAIExplanationCards";

interface StyleProps {
    question: Question;
    standalone?: boolean;
    leftBorderColor?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    root: (props) => ({
        paddingLeft:
            props.question.parentId || props.leftBorderColor ? "16px" : "0px",
        borderLeft: props.leftBorderColor
            ? `2px solid ${props.leftBorderColor}`
            : "none",
    }),

    padding3: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },

    questionText: (props) => ({
        color: "#1F1F1F",
        fontSize: props.standalone ? "12px" : "14px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    }),

    fullSize: {
        height: "100%",
        width: "100%",
    },
    questionHeader: (props) => ({
        marginBottom: props.standalone ? theme.spacing(0.25) : theme.spacing(1),
        marginTop: props.standalone ? theme.spacing(0.5) : theme.spacing(2),
        paddingRight: theme.spacing(2),
    }),
    infoSVG: {
        marginRight: theme.spacing(2.7),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
    },
    sPContainer: {
        display: "flex",
        flexDirection: "row",
        wrap: "nowrap",
        alignItems: "center",
    },
}));

interface WFRStarRatingAnswerProps {
    question: Question;
    moduleUIModel?: ModuleUIModel;
    standalone?: boolean;
    leftBorderColor?: string;
    isDisabled?: boolean;
    showDisputeCheckbox?: boolean;
}

function generateRatingLabels(tags: Tag[]) {
    const ratingLabels: Record<number, string> = {};
    tags!.forEach((value) => {
        ratingLabels[value.scoredValue ?? 0] = value.value;
    });
    return ratingLabels;
}

const WFRStarRatingAnswer: React.FC<WFRStarRatingAnswerProps> = observer(
    (props) => {
        const classes = useStyles(props);
        const store = useStore(WorkflowReviewStore);

        const answer = store.getAnswerForQuestion(
            props.question,
            props.moduleUIModel?.licensedModule.evaluationModuleId,
        );

        const activeTag =
            answer?.activeTags[0] ??
            store.tags.find(
                (tag) => tag.id === answer?.activeAnswerTags[0]?.tagId,
            );

        const isSmartPredicted = answer?.hasAccuratePredictions;

        const starRatingTags =
            props.moduleUIModel?.getTagsFormattedForStarRating(
                props.question.id,
            );

        const maxStars = starRatingTags?.length;
        const ratingLabels = useMemo(
            () => generateRatingLabels(starRatingTags ?? []),
            [starRatingTags],
        );

        const onStarRatingChange = (rating: number | null) => {
            const arg =
                rating !== null
                    ? starRatingTags?.find(
                          (value) => value.scoredValue === rating,
                      )
                    : undefined;
            if (arg === undefined) {
                store.setAnswerForQuestion(
                    props.question,
                    [],
                    answer,
                    props.moduleUIModel?.licensedModule.evaluationModuleId,
                );
            } else {
                store.setAnswerForQuestion(
                    props.question,
                    [arg],
                    answer,
                    props.moduleUIModel?.licensedModule.evaluationModuleId,
                );
            }
        };

        let showAIExplanationCard = store.showAIExplanation[props.question.id];

        return (
            <Grid
                id={`question-${props.question.id}`}
                item
                xs={12}
                className={classes.root}
                container
                direction={"column"}
                justifyContent={"flex-start"}
                alignItems="stretch"
            >
                <Grid
                    item
                    xs={12}
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    wrap={"nowrap"}
                    className={clsx(classes.questionHeader, {
                        [classes.padding3]: !props.standalone,
                    })}
                >
                    {Boolean(answer && props.showDisputeCheckbox) && (
                        <Checkbox
                            id={"DisputeAnswer-" + props.question.id}
                            title="Check to Dispute this Answer."
                            checked={answer?.isDisputed ? true : false}
                            onClick={answer?.toggleIsDisputed}
                        />
                    )}
                    <AcxWideTooltip
                        title={
                            sanitizeHtml(props.question.helperText) ? (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            props.question.helperText,
                                            {
                                                allowedAttributes: {
                                                    table: ["style"],
                                                    th: ["style"],
                                                    td: ["style"],
                                                },
                                                allowedStyles: {
                                                    "*": {
                                                        // Match any number with px, em, or %
                                                        width: [
                                                            /^\d+(?:px|em|%)$/,
                                                        ],
                                                        border: [
                                                            /^1px solid rgba\(0, 0, 0, 1\)$/,
                                                        ],
                                                    },
                                                },
                                            },
                                        ),
                                    }}
                                />
                            ) : (
                                ""
                            )
                        }
                        placement={"top"}
                    >
                        <Grid item className={classes.infoSVG}>
                            <InfoSvg width="24px" height="24px" />
                        </Grid>
                    </AcxWideTooltip>
                    <Grid item xs={10}>
                        <Grid
                            container
                            direction={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"baseline"}
                            spacing={1}
                        >
                            <Grid item>
                                <Typography
                                    className={classes.questionText}
                                    style={{
                                        color: answer?.isDisputed
                                            ? "theme.palette.error.main"
                                            : "theme.palette.text",
                                    }}
                                >
                                    {answer?.isDisputed && (
                                        <span
                                            style={{
                                                color: theme.palette.error.main,
                                            }}
                                            title="This answer has been escalated or disputed."
                                        >
                                            {props.question?.questionText}{" "}
                                        </span>
                                    )}
                                    {!answer?.isDisputed &&
                                        props.question?.questionText}
                                    {props.question.required &&
                                        !answer?.isAnswered && (
                                            <span
                                                style={{
                                                    color: theme.palette.error
                                                        .main,
                                                }}
                                            >
                                                *
                                            </span>
                                        )}
                                </Typography>
                            </Grid>
                            <WFRSmartPredictToggle
                                question={props.question}
                                moduleUIModel={props.moduleUIModel}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    direction={"row"}
                    wrap={"nowrap"}
                    container
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    className={clsx(
                        { [classes.padding3]: !props.standalone },
                        classes.fullSize,
                    )}
                >
                    <Grid item xs={12} style={{ flexDirection: "row" }}>
                        <AcxStarRating
                            id={"StarRatingAnswer-" + props.question.id}
                            max={maxStars ?? 3}
                            iconHoverColor={theme.palette.secondary.main}
                            iconFilledColor={theme.palette.secondary.main}
                            ratingLabels={ratingLabels}
                            onChange={onStarRatingChange}
                            value={activeTag?.scoredValue ?? 0}
                            size={"large"}
                            disabled={props.isDisabled}
                        />
                    </Grid>
                </Grid>
                {isSmartPredicted && showAIExplanationCard && activeTag && (
                    <WFRAIExplanationCards
                        activeTags={[activeTag]}
                        predictedTags={answer.predictedTags}
                        questionId={props.question.id}
                        answer={answer}
                    />
                )}
            </Grid>
        );
    },
);

export default WFRStarRatingAnswer;
