import {observer} from "mobx-react";
import React, {FunctionComponent} from "react";
import BaseDashboard from "../../../../Layouts/Dashboard/BaseDashboard";
import {AuthStore} from "../../../../stores/AuthStore";
import {useStore} from "../../../../utils/useStore";
import {ManagerInteractionsDashboardStore} from "../../Stores/ManagerInteractionsDashboardStore";

interface OwnProps {
    width: number;
    height: number;
}

type Props = OwnProps;

const AnalystManagementDashboard: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer(
    (props) => {
        const store = useStore(ManagerInteractionsDashboardStore);
        const orgStore = useStore(AuthStore).orgStore;

        React.useEffect(() => {
            if (orgStore && orgStore.selectedOrganization) {
                store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
            }
        }, [orgStore, orgStore?.selectedOrganization, store]);

        return (
            <BaseDashboard
                width={props.width}
                height={props.height}
                dashboardStore={store.dashboardStore}
            />
        );
    },
);

export default AnalystManagementDashboard;
