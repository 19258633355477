import { Divider, Theme, Typography, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from "react";
import { stringReplacePluralizer } from "../../../utils/StringPluralizer";
import AcxMainExpansion from "../AcxMainExpansion";
import { IAcxTableRow } from "./AcxTable";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },

    expanionHeader: {
        userSelect: "none",
        height: "56px",
    },
    expansionRoot: {
        // boxShadow:theme.shadows[1]
        //  "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        boxShadow:
            "0px 2px 2px -1px rgba(0,0,0,0.09), 0 1px 1px 0 rgba(0,0,0,0.07), 0px 1px 2px 0px rgba(0,0,0,0.05)",
    },
}));

interface OwnProps {
    itemName?: string;
    itemNamePluralized?: string;
    selectedRows: IAcxTableRow[];
    renderSelectedRow: (row: IAcxTableRow) => React.ReactNode;
    action?: React.ReactNode;
    keyField?: string;
}

type Props = OwnProps;

const AcxTableSelectionContext: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const classes = useStyles(props);
    const theme = useTheme();

    const body = (
        <>
            {props.selectedRows.map((value, index) => {
                return (
                    <React.Fragment key={`table-context-divider-${index}`}>
                        <Divider variant={"fullWidth"} />
                        {props.renderSelectedRow(value)}
                    </React.Fragment>
                );
            })}
        </>
    );

    const count = props.selectedRows?.length ?? 0;
    return (
        <AcxMainExpansion
            expandable={(props.selectedRows?.length ?? 0) > 0}
            rootClass={classes.expansionRoot}
            headerClass={classes.expanionHeader}
            header={
                <div>
                    <Typography className={classes.title}>
                        {count} Selected{" "}
                        {stringReplacePluralizer(
                            props.itemName ?? "Item",
                            count,
                            props.itemNamePluralized ?? "Items",
                        )}
                    </Typography>
                </div>
            }
            body={body}
            headerStyleOverRide={{
                0: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },
                1: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },
            }}
            bodyStyleOverRide={{
                0: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },
                1: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },
            }}
            chevronStyleOverRide={{
                0: {
                    color: theme.palette.secondary.main,
                },
                1: {
                    color: theme.palette.secondary.main,
                },
            }}
            headerMenu={(menuAnchor) => props.action}
            alwaysShowHeaderMenu={Boolean(props.action)}
        />
    );
};

export default AcxTableSelectionContext;
