import React from "react";
import { ThemeProvider, Theme, StyledEngineProvider, CssBaseline } from "@mui/material";
import AppTheme from "Theme/AppTheme";
import AuthPage from "./AuthPage";
import ChangePassword from "./ChangePassword";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function ChangePasswordUser() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
                <CssBaseline />
                <AuthPage title="Log In">
                    <ChangePassword />
                </AuthPage>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default ChangePasswordUser;
