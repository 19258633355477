import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";

const styles = (theme: Theme) => {
    return createStyles({
        root: {
            color: theme.palette.secondary.main,
            fontSize: "16px",
        },
        button: {
            // borderRadius:'0 4px 4px 0',
            margin: 0,
            backgroundColor: "transparent",
            // color: theme.palette.secondary.main,
            flexBasis: "auto",
            border: "none",
            width: "32px",
            height: "32px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: hexToRGB(theme.palette.primary.main, 0.1),
            },
        },
    });
};
interface AcxFileButtonProps {
    className?: string;
    onClick: () => void;
}
export default function AcxRemoveFileButton(props: AcxFileButtonProps) {
    const classes = useStyles(styles);
    const handleVectorEvents = (e: React.MouseEvent) => {
        if (props.onClick) {
            props?.onClick();
        }
        e.stopPropagation();
    };
    function FileListIcon(props) {
        return (
            <ClearIcon
                onClick={handleVectorEvents}
                {...props}
                className={classes.root}
            />
        );
    }
    return (
        // <IconButton className={classes.button} color="secondary" onClick={props?.onClick}><FileListIcon fontSize={'small'} /></IconButton>
        <button className={classes.button} onClick={props?.onClick}>
            <FileListIcon fontSize={"small"} />
        </button>
    );
}
