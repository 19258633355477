import { useCallback } from "react";
import { addSearch } from "shared/functions/addSearch";
import useList from "shared/hooks/useList";
import OrganizationService from "../Organization.service";
import { Organization } from "../types/Organization.type";

const useOrganizations = () => {
    const getData = useCallback(async () => {
        const res = await OrganizationService.getAllOrganizations();
        return addSearch(res, ["name", "blobContainer"]);
    }, []);

    const {
        isLoading,
        data: organizations,
        setData,
        refetch,
    } = useList<Organization>({
        get: getData,
    });

    const toggleOrganization = async (org: Organization): Promise<boolean> => {
        if (!org.id) {
            return false;
        }
        try {
            if (org.isActive) {
                await OrganizationService.deleteOrganization(org.id);
            } else {
                await OrganizationService.updateOrganization({
                    ...org,
                    isActive: true,
                });
            }
            const newOrgs = organizations?.map((newOrg) => ({
                ...newOrg,
                isActive:
                    newOrg.id === org.id ? !org.isActive : newOrg.isActive,
            }));
            setData(newOrgs);
            return true;
        } catch (err) {
            console.error(err);
        }
        return false;
    };

    return {
        organizations,
        isLoading,
        refetch,
        toggleOrganization,
    };
};

export default useOrganizations;
