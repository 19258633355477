import { Grid, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import { Observer } from "mobx-react";
import Classifier, { ClassifierType } from "models/ClassifierModel";
import React from "react";
import { useDrag } from "react-dnd";
import useStyles from "Styles/Styles";
import { isStringType } from "../../../../../../utils/TypeGuards";
import { RuleBuildStore } from "../../RuleBuildStore";
import { ClassifierTypeToItemSymbol } from "../MainRuleBuilder";

const styles = (theme: Theme) =>
    createStyles({
        mainContent: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        itemClicked: {
            backgroundColor: theme.palette.primary.main,
        },
        itemNotClicked: {
            backgroundColor: "none",
        },
        headerChecked: {
            backgroundColor: "#EAF3FF",
            color: theme.palette.white.contrastText,
        },
        headerUnChecked: {
            backgroundColor: theme.palette.white.main,
        },
        header: {
            minHeight: "48px",
            borderRadius: theme.spacing(0.5),
            boxShadow: "inset 0 -1px 0 0 rgba(0,0,0,0.05)",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        unChecked: {
            color: theme.palette.secondary.main,
        },
        checked: {
            color: theme.palette.secondary.main,
        },
        innerIcon: {
            margin: "6px",
            fontSize: "20px",
        },
    });

export interface IAcxClassifierRuleItem {
    store: RuleBuildStore;
    classifierItem: Classifier;
    content: React.ReactNode;
    ruleId: string;
    id: string;
    name: string;
    status?: "Loading" | "Complete" | "";
    type: ClassifierType;
    validateRule?: boolean;
}

const AcxClassifierRuleItem = (props: IAcxClassifierRuleItem) => {
    const classes = useStyles(styles);
    const theme = useTheme();

    const [{ isDragging }, drag] = useDrag({
        item: {
            type: ClassifierTypeToItemSymbol(props.type),
            classifierId: props.classifierItem.id,
            ruleId: props.ruleId,
        },

        canDrag: (monitor) => {
            return !props.store.thresholdFocused;
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const opacity = isDragging ? 0.34 : 1;

    const customMetalabel =
        props.store.metaLabels[
            "Meta" + props.classifierItem.name?.split("-")[1]
        ];

    return (
        <Observer>
            {() => (
                <Grid
                    ref={drag}
                    style={{
                        opacity,
                        cursor: isDragging ? "grabbing" : "grab",
                    }}
                    container
                    alignItems="center"
                    justifyContent={"flex-start"}
                    wrap="nowrap"
                    className={clsx(
                        classes.header,
                        classes.headerUnChecked,
                        classes.mainContent,
                    )}
                >
                    <Grid
                        item
                        container
                        zeroMinWidth
                        xs={props.content ? 4 : 11}
                        style={{ cursor: "inherit", opacity }}
                    >
                        <Typography
                            variant={"h5"}
                            noWrap
                            style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                fontWeight: "bold",
                                userSelect: "none",
                                color: theme.palette.text.secondary,
                            }}
                        >
                            {props.classifierItem.name.includes("MetaField") &&
                            props.store.orgHasMetaLabels &&
                            customMetalabel
                                ? customMetalabel
                                : props.classifierItem.name}{" "}
                            {props.validateRule &&
                                props.store.errorForRule(props.ruleId) && (
                                    <span
                                        style={{
                                            color: theme.palette.error.main,
                                        }}
                                    >
                                        *
                                    </span>
                                )}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        container
                        xs={props.content ? 8 : 1}
                        style={{ cursor: "inherit", opacity }}
                    >
                        {isStringType(props.content) ? (
                            <Typography
                                noWrap
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                }}
                            >
                                {props.content}
                            </Typography>
                        ) : (
                            props.content
                        )}
                    </Grid>
                </Grid>
            )}
        </Observer>
    );
};

export default AcxClassifierRuleItem;
