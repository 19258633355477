export function stringPluralizer(str: string, count: number, plural: string) {
    if (count > 1 || count === 0) {
        return `${str}${plural}`;
    }
    return str;
}

export function stringReplacePluralizer(
    str: string,
    count: number,
    plural: string,
) {
    if (count > 1 || count === 0) {
        return `${plural}`;
    }
    return str;
}
