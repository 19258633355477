import { Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxRecursiveTree from "components/UI/AcxRecursiveTree/AcxRecursiveTree";
import {
    BranchDataProps,
    HandleBranchProps,
} from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import RecursiveTreeStore, {
    LOAD_TOP_LEVEL,
} from "components/UI/AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { EMockOrgSetup, IFakeRecursiveTier } from "./RecursiveTreeTestData";
import { RecursiveTreeTestStore } from "./RecursiveTreeTestStore";
import RecursiveTreeTestBranch from "./TreeTestComponents/RecursiveTreeTestBranch";

const RecursiveTreeTestComponent = observer(() => {
    const authStore = useStore(AuthStore);
    const mockStore = useStore(RecursiveTreeTestStore);

    const [recursiveTree, setRecursiveTree] = React.useState<any>();
    const [newTierName, setNewTierName] = React.useState<string>();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleAddTierConfirmation = (name?: string) => {
        if (!name) {
            mockStore.messageStore.logError("Name cannot be empty.");

            return;
        }

        if (
            authStore.orgStore.selectedOrganization?.id &&
            mockStore.activeTier
        ) {
            mockStore.setActiveTierAttribute("name", name);
            mockStore.setEndpointFired(true, "Add"); // React.useEffect() nested within the branch content component will fire from this.
            mockStore.setShowAddDialog(false);

            setNewTierName("");
        }
    };

    // The endpoint which generates top-level branches should interact with the TreeStore observable array property `topLevelBranches`.
    const handleTopLevelBranchCreation = async () => {
        if (!newTierName) {
            mockStore.messageStore.logError("Name cannot be empty.");

            return;
        }

        if (mockStore.organizationId) {
            setLoading(true);

            await mockStore.createTopLevelBranch(mockStore.organizationId, {
                name: newTierName,
            } as unknown as IFakeRecursiveTier);

            setNewTierName("");
            mockStore.setShowAddTopLevelDialog(false);

            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (!authStore.orgStore.selectedOrganization?.id) {
            return;
        }

        // Tree store is instantiated will full CRUD - interfaces can be found in AcxRecursiveTreeStore.tsx.
        const treeStore: RecursiveTreeStore = new RecursiveTreeStore({
            createBranch: mockStore.createBranch.bind(mockStore),
            getBranch: mockStore.getBranch.bind(mockStore),
            updateBranch: mockStore.updateBranch.bind(mockStore),
            deleteBranch: mockStore.deleteBranch.bind(mockStore),
        });

        mockStore.treeStore = treeStore;
        mockStore.setOrganizationId(authStore.orgStore.selectedOrganization.id);

        // This indicates the path to the property whose value houses the array of branches.
        treeStore.setTopLevelDataKey("dummyTiers");
        treeStore.setOrganizationId(authStore.orgStore.selectedOrganization.id);

        // Here, a call is made which will populate the topmost branches for the initial render.
        treeStore.getTopLevelBranches(
            mockStore.getTopLevelBranches(
                authStore.orgStore.selectedOrganization.id,
            ),
        );

        // This function will be called at each level of the tree. Every branch passes its own props, which can be accessed within your custom component.
        const branchChildrenContent = (
            currentBranch: BranchDataProps,
            handleBranch: HandleBranchProps,
        ) => {
            return (
                <RecursiveTreeTestBranch
                    branch={currentBranch}
                    handleBranch={handleBranch}
                    store={mockStore}
                />
            );
        };

        treeStore.setBranchContent(branchChildrenContent);

        setRecursiveTree(<AcxRecursiveTree store={treeStore} />);
    }, [authStore.orgStore.selectedOrganization?.id, mockStore]); // Used to re-render whenever a new organization is selected.

    return (
        <>
            {/* In order to interact with components living outside of the recursive tree, the use of a third-party store is recommended. In this instance, we have our `mockStore` with an observable property called `activeTier` which will resemble the current branch we are working with. Once an update is made, it is recommended to setup a `React.useEffect()` hook to capture that interaction - as seen in RecursiveTreeTestBranch.tsx */}

            {/* Dialog for generating new top-level branches */}
            <AcxDialog
                isOpen={mockStore.showAddTopLevelDialog}
                onClose={() => {
                    setNewTierName("");
                    mockStore.setShowAddTopLevelDialog(false);
                }}
                subTitle="Confirm Top Level Branch Addition"
                title="Top Level Branch"
                text="The newly named branch will appear at the highest level."
                children={
                    <AcxButton
                        loading={loading}
                        disabled={loading}
                        color="primary"
                        onClick={handleTopLevelBranchCreation}
                    >
                        Confirm
                    </AcxButton>
                }
                dialogContentChildren={
                    <AcxMainTextField
                        focus
                        id="name"
                        value={newTierName}
                        onChange={(e) => setNewTierName(e.target.value)}
                        labelText={EMockOrgSetup[0] ?? ""}
                    />
                }
            />

            {/* Dialog for generating new child branches */}
            <AcxDialog
                isOpen={mockStore.showAddDialog}
                onClose={() => {
                    setNewTierName("");
                    mockStore.setShowAddDialog(false);
                }}
                subTitle="Confirm Branch Addition"
                title={mockStore.activeTier ? mockStore.activeTier.name : ""}
                text="The newly named tier will appear below its parent."
                children={
                    <AcxButton
                        color="primary"
                        onClick={() => handleAddTierConfirmation(newTierName)}
                    >
                        Confirm
                    </AcxButton>
                }
                dialogContentChildren={
                    <AcxMainTextField
                        focus
                        id="name"
                        value={newTierName}
                        onChange={(e) => setNewTierName(e.target.value)}
                        labelText={
                            mockStore.activeTier
                                ? EMockOrgSetup[
                                      (mockStore.activeTier.branchDepth ?? 0) +
                                          1
                                  ]
                                : ""
                        }
                    />
                }
            />

            {/* Dialog used for deleting individual branches */}
            <AcxDialog
                isOpen={mockStore.showDeleteDialog}
                onClose={() => {
                    mockStore.setShowDeleteDialog(false);
                }}
                subTitle="Confirm Removal"
                title={mockStore.activeTier ? mockStore.activeTier.name : ""}
                text="Once deleted, you will not be able to retrieve this tier."
                children={
                    <AcxButton
                        color="primary"
                        onClick={mockStore.confirmRemoveTier}
                    >
                        Confirm
                    </AcxButton>
                }
            />

            {/* Button used to populate the Add Top Level Dialog  */}
            {!mockStore.treeStore?.getTaskLoading(LOAD_TOP_LEVEL) && (
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-start"
                    style={{ padding: "0 0.5rem" }}
                >
                    <AcxButton
                        color="primary"
                        disabled={loading}
                        loading={loading}
                        onClick={() => mockStore.setShowAddTopLevelDialog(true)}
                    >
                        Add Top Level Branch
                    </AcxButton>
                </Grid>
            )}

            {/* Implementation of RecursiveTree component */}
            {recursiveTree}
        </>
    );
});

export default RecursiveTreeTestComponent;
