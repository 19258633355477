import { useEffect, useState } from "react";

type Props<T> = {
    get: () => Promise<T>;
};

function useOne<T>({ get }: Props<T>) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<T>();

    useEffect(() => {
        let destroyed = false;
        const runFn = async () => {
            try {
                setIsLoading(true);
                const res = await get();
                if (!destroyed) {
                    setData(res);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };
        runFn();
        return () => {
            destroyed = true;
            setData(undefined);
        };
    }, [get]);

    const refetch = async () => {
        try {
            setIsLoading(true);
            const res = await get();
            setData(res);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        data,
        setData,
        refetch,
    };
}

export default useOne;
