import React from "react";
import { ThemeProvider, Theme, StyledEngineProvider, CssBaseline } from "@mui/material";
import AppTheme from "Theme/AppTheme";
import Login from "./Login";
import AuthPage from "./AuthPage";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function LoginUser() {
    const [loading, setLoading] = React.useState(false);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
                <CssBaseline />
                <AuthPage isLoading={loading} title="Log In">
                    <Login setLoading={setLoading} />
                </AuthPage>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default LoginUser;
