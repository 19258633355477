// import { toJS } from "mobx";
import { observer, Observer } from "mobx-react";
import { Answer } from "models/Answer";
import { AnswerTypeNames } from "models/AnswerType";
import {
    QuestionConditionalGroupType,
    QuestionDependencyCondition,
    QuestionDependencyQuestionAnswerOperator,
    QuestionDependencyType,
} from "models/Question";
import React from "react";
import { ThemeColors } from "Theme/AppTheme";
import hexToRGB from "utils/hexToRGB";
import ConvoWorkflowStore, { ConvoQuestion } from "../store/ConvoWorkflowStore";
import { useStore } from "utils/useStore";
import { Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import ConvoNote from "./ConvoNote";
import ConvoScaledResponseAnswer from "./ConvoScaledResponseAnswer";
import ConvoStarRatingAnswer from "./ConvoStarRatingAnswer";
import ConvoTOFIResponseAnswer from "./ConvoTOFIResponseAnswer";
import { endDialogTitle } from "../ConvoWorkflowContainer";

interface OwnProps {
    convoModuleId?: string;
    licensedModuleId?: string;
    isEditable?: boolean;
    workflowLevel?: number;
}

type Props = OwnProps;

function isConditionMet(
    condition: QuestionDependencyCondition,
    answers: Answer[],
    conversationModuleId: string,
): boolean {
    switch (condition.type) {
        case QuestionDependencyType.QuestionAnswer:
            const value = condition.value;
            const answer = answers.find(
                (a) =>
                    a.questionId === condition.dependsOnQuestionId &&
                    a.conversationModuleId === conversationModuleId,
            );

            if (!answer) {
                return false;
            }

            switch (condition.op) {
                case QuestionDependencyQuestionAnswerOperator.Contains:
                    return answer.activeAnswerTags.some(
                        (t) => t.tag?.value === value,
                    );
                case QuestionDependencyQuestionAnswerOperator.IsAnswered:
                    return !!answer.activeAnswerTags.length;
            }

            break;
        case QuestionDependencyType.ConditionalGroup:
            switch (condition.conditionalGroupType) {
                case QuestionConditionalGroupType.And:
                    return condition.conditions.every((c) =>
                        isConditionMet(c, answers, conversationModuleId),
                    );
                case QuestionConditionalGroupType.Or:
                    return condition.conditions.some((c) =>
                        isConditionMet(c, answers, conversationModuleId),
                    );
            }
    }
}

function shouldRender(
    answers: Answer[] | undefined,
    question: ConvoQuestion,
) {
    if (!question.dependencies) {
        return true;
    }
    if (!question.dependencies.conditions) {
        return true;
    }
    if (!question.dependencies.conditions.length) {
        return true;
    }
    if (!answers) {
        return false;
    }
    // if (!answers.length) {
    //     return false;
    // }

    return question.dependencies.conditions.every((d) =>
        isConditionMet(d, answers, question.conversationModuleId),
    );
}

const ConvoQuestionAnswerList = observer((props: Props) => {
    const store = useStore(ConvoWorkflowStore);

    const module = store.licensedModules.find(
        (m) => m.id === props.licensedModuleId,
    );
    const uiModel = store.uiModels?.find(
        (m) => m.conversationModuleId === props.convoModuleId,
    );

    return (
        <Observer>
            {() => {
                const questionsWithDependencies = module?.questions.filter(
                    (r) => r.dependencies?.conditions.length,
                );

                const sortedQuestions = (module?.sortedQuestion ?? []).filter(
                    (q) => {
                        var convoQuestion = q as ConvoQuestion;
                        convoQuestion.conversationModuleId =
                            props.convoModuleId as string;

                        return shouldRender(store.answers, convoQuestion);
                    },
                );

                const isMissingRequiredAnswer =
                    store.isMissingRequiredAnswer(sortedQuestions);

                return (
                    <>
                        {!!props.convoModuleId ? (
                            <>
                                {sortedQuestions?.map((questionValue) => {
                                    if (questionValue.isActive) {
                                        const leftBorder =
                                            questionValue.parentId &&
                                            questionsWithDependencies?.includes(
                                                questionValue,
                                            )
                                                ? hexToRGB(
                                                      ThemeColors.purple,
                                                      1,
                                                  )
                                                : questionsWithDependencies?.includes(
                                                      questionValue,
                                                  )
                                                ? hexToRGB(
                                                      ThemeColors.red,
                                                      0.75,
                                                  )
                                                : questionValue.parentId
                                                ? hexToRGB(
                                                      ThemeColors.blue,
                                                      0.5,
                                                  )
                                                : undefined;

                                        if (
                                            questionValue.answerType
                                                .answerTypeName ===
                                                AnswerTypeNames.ScaledResponse ||
                                            questionValue.answerType
                                                .answerTypeName ===
                                                AnswerTypeNames.ScaledBooleanResponse ||
                                            questionValue.answerType
                                                .answerTypeName ===
                                                AnswerTypeNames.QuestionGrouping
                                        ) {
                                            return (
                                                <ConvoScaledResponseAnswer
                                                    moduleUIModel={uiModel}
                                                    question={questionValue}
                                                    leftBorderColor={leftBorder}
                                                    key={questionValue.id}
                                                    isDisabled={
                                                        !props.isEditable
                                                    }
                                                />
                                            );
                                        } else if (
                                            questionValue.answerType
                                                .answerTypeName ===
                                                AnswerTypeNames.TagResponse ||
                                            questionValue.answerType
                                                .answerTypeName ===
                                                AnswerTypeNames.ScoredTagResponse ||
                                            questionValue.answerType
                                                .answerTypeName ===
                                                AnswerTypeNames.TextResponse ||
                                            questionValue.answerType
                                                .answerTypeName ===
                                                AnswerTypeNames.DateResponse
                                        ) {
                                            return (
                                                <ConvoTOFIResponseAnswer
                                                    key={questionValue.id}
                                                    leftBorderColor={leftBorder}
                                                    moduleUIModel={uiModel}
                                                    question={questionValue}
                                                    isDisabled={
                                                        !props.isEditable
                                                    }
                                                />
                                            );
                                        } else if (
                                            questionValue.answerType
                                                .answerTypeName ===
                                            AnswerTypeNames.StarRating
                                        ) {
                                            return (
                                                <ConvoStarRatingAnswer
                                                    key={questionValue.id}
                                                    moduleUIModel={uiModel}
                                                    question={questionValue}
                                                    leftBorderColor={leftBorder}
                                                    isDisabled={
                                                        !props.isEditable
                                                    }
                                                />
                                            );
                                        } else {
                                            return "";
                                        }
                                    } else {
                                        return "";
                                    }
                                })}
                            </>
                        ) : (
                            <ConvoNote
                                columnDirection={"column"}
                                noteXs={12}
                                showNote={true}
                                workflowLevel={props.workflowLevel}
                                isDisabled={!props.isEditable}
                            />
                        )}
                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent={"flex-end"}
                            spacing={1}
                        >
                            {store.currentWorkflowStatus !==
                                WorkflowStatus.Closed &&
                                props.isEditable && (
                                    <Grid item>
                                        <AcxButton
                                            onClick={() => {
                                                store.dialogStore.setTitle(
                                                    endDialogTitle,
                                                );
                                                store.dialogStore.setSubTitle(
                                                    "Are you sure you want to end this workflow?",
                                                );
                                                store.dialogStore.isOpen = true;
                                            }}
                                            loading={store.anyTaskLoading}
                                            color={
                                                store.isLastLevel
                                                    ? "primary"
                                                    : "error"
                                            }
                                            disabled={isMissingRequiredAnswer}
                                        >
                                            {store.isLastLevel
                                                ? "Complete"
                                                : "End Workflow"}
                                        </AcxButton>
                                    </Grid>
                                )}
                            {store.workflowLevelNames.length > 1 &&
                                !store.isLastLevel &&
                                props.isEditable && (
                                    <Grid item>
                                        <AcxButton
                                            onClick={() => {
                                                store.dialogStore.setTitle(
                                                    "Confirm Advance Workflow",
                                                );
                                                store.dialogStore.setSubTitle(
                                                    "Are you sure you want to advance this workflow to the next level?",
                                                );
                                                store.dialogStore.isOpen = true;
                                            }}
                                            disabled={isMissingRequiredAnswer}
                                        >
                                            Advance
                                        </AcxButton>
                                    </Grid>
                                )}
                        </Grid>
                    </>
                );
            }}
        </Observer>
    );
});

export default ConvoQuestionAnswerList;
