import { observer } from "mobx-react";
import React from "react";
import {
    DatePickerComponentStore,
    DateReferenceOption,
    InteractionDateReferenceOption,
} from "../../../../stores/ComponentStores/DatePickerComponentStore";
import AcxSelectSingle from "../../Select/BaseSelectComponents/AcxSelectSingle";

const dropDownOptions = {
    [DateReferenceOption.ArrivalDate]: {
        label: "Arrival Date",
        value: DateReferenceOption.ArrivalDate,
    },
    [DateReferenceOption.InteractionDate]: {
        label: "Interaction Date",
        value: DateReferenceOption.InteractionDate,
    },
    [DateReferenceOption.EvaluationCompleteDate]: {
        label: "Evaluation Complete Date",
        value: DateReferenceOption.EvaluationCompleteDate,
    },
    [InteractionDateReferenceOption.EvaluationModifiedDate]: {
        label: "Evaluation Modified Date",
        value: InteractionDateReferenceOption.EvaluationModifiedDate,
    },
    [DateReferenceOption.EvaluationStartDate]: {
        label: "Evaluation Start Date",
        value: DateReferenceOption.EvaluationStartDate,
    },
    [DateReferenceOption.CreatedOnDate]: {
        label: "Created On Date",
        value: DateReferenceOption.CreatedOnDate,
    },
};
interface IDateTypeSelectorProps {
    datePickerStore: DatePickerComponentStore;
    dateReferenceOptions?:
        | DateReferenceOption[]
        | InteractionDateReferenceOption[];
    disabled?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    onChange?(dateType: string): void;
}

const AcxDateTypeSelector: React.FC<IDateTypeSelectorProps> = observer(
    ({
        datePickerStore,
        dateReferenceOptions = Object.values(DateReferenceOption),
        disabled,
        onChange,
        menuPortalTarget,
    }) => {
        const enabledReferenceOptions = React.useMemo(
            () => dateReferenceOptions.map((o) => dropDownOptions[o]),
            [dateReferenceOptions],
        );

        return (
            <>
                {dateReferenceOptions.length > 0 && (
                    <AcxSelectSingle
                        inputLabel="Date Type"
                        isDisabled={disabled}
                        defaultValue={
                            dropDownOptions[datePickerStore.referenceOption]
                        }
                        id="referenceSelector"
                        options={enabledReferenceOptions}
                        labelField="label"
                        valueField="value"
                        onChange={(opt) => {
                            onChange?.(opt);
                            datePickerStore.setReferenceOption(opt.value);
                        }}
                        menuPortalTarget={menuPortalTarget}
                    />
                )}
            </>
        );
    },
);

export default AcxDateTypeSelector;
