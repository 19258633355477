import { Checkbox, FormControlLabel, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStore } from "utils/useStore";
import theme from "Theme/AppTheme";
import AcxMainTextField from "components/UI/AcxMainTextField";
import SignalsReportSelectorStore from "../Store/SignalsReportSelectorStore";
import hexToRGB from "utils/hexToRGB";
import SearchIcon from "@mui/icons-material/Search";
import { ISignalsVizDefinition } from "../Models/SignalsModels";

const useStyles = makeStyles((theme: Theme) => ({
    rootContainer: { height: "50vh" },
    leftContainer: {
        border: "1px solid",
        borderRight: 0,
        borderColor: theme.palette.lightgrayBorder.main,
        padding: "8px",
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        height: "40vh",
        overflow: "auto",
    },
    leftItem: {
        paddingLeft: "8px",
        paddingTop: "4px",
        paddingBottom: "4px",
        borderRadius: theme.shape.borderRadius,
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: 600,
    },
    groupHeader: {
        fontSize: "10px",
        fontWeight: 700,
        color: theme.palette.gray.main,
    },
    rightContainer: {
        border: "1px solid",
        borderColor: theme.palette.lightgrayBorder.main,
        padding: "8px",
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        height: "40vh",
        overflow: "auto",
    },
}));

const AddReportsDialogContent: React.FC = observer(() => {
    const store = useStore(SignalsReportSelectorStore);
    const classes = useStyles();
    const handleReportSelection = (e, i: ISignalsVizDefinition) => {
        if (e.target.checked) {
            store.selectedVizDefs.push(i);
        } else {
            store.selectedVizDefs = store.selectedVizDefs.filter(
                (j) => j.id !== i.id,
            );
        }
    };
    const [searchTerm, setSearchTerm] = useState("");

    return (
        <Grid
            container
            className={classes.rootContainer}
            style={{ width: "80vh" }}
        >
            <Grid item xs={12} style={{ marginBottom: "8px" }}>
                <AcxMainTextField
                    id="signals-report-selector-search-input"
                    placeholderText="Search reports..."
                    fullWidth
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    startAdornment={<SearchIcon color="disabled" />}
                />
            </Grid>
            <Grid container item xs={12} style={{ height: "40vh" }}>
                <Grid
                    container
                    item
                    direction={"column"}
                    xs={3}
                    wrap="nowrap"
                    className={classes.leftContainer}
                >
                    <Grid className={classes.groupHeader}>SIGNALS REPORTS</Grid>
                    {store.signalsStore.navStore.itemGroups?.map((i, index) => (
                        <Grid
                            key={index}
                            margin="4px"
                            item
                            onClick={(e) => {
                                if (i.linkPath === "") {
                                    store.currentItems =
                                        store.signalsReportStore.allChartData.filter(
                                            (c) => c.isEddyReport,
                                        );
                                } else if (i.linkPath === "trends") {
                                    store.currentItems =
                                        store.signalsReportStore.allChartData.filter(
                                            (c) => c.isTrendReport,
                                        );
                                } else if (i.linkPath === "topics") {
                                    store.currentItems =
                                        store.signalsReportStore.allChartData.filter(
                                            (c) => c.isTopicReport,
                                        );
                                } else if (i.linkPath === "contacts") {
                                    store.currentItems =
                                        store.signalsReportStore.allChartData.filter(
                                            (c) => c.isContactReport,
                                        );
                                }
                                store.selectedItemGroup =
                                    i.header?.toString() ?? "";
                            }}
                            style={{
                                backgroundColor:
                                    store.selectedItemGroup === i.header
                                        ? hexToRGB(theme.palette.blue.main, 0.1)
                                        : theme.palette.white.main,
                            }}
                            className={classes.leftItem}
                        >
                            {i.header}
                        </Grid>
                    ))}
                    <Grid
                        className={classes.groupHeader}
                        style={{ marginTop: "8px" }}
                    >
                        MY REPORTS
                    </Grid>
                    {store.signalsStore.userDashboardNavStore.itemGroups?.map(
                        (i, index) => (
                            <Grid
                                key={index}
                                item
                                margin="4px"
                                onClick={() => {
                                    if (i.linkPath?.includes("dashboard")) {
                                        const dashId = i.linkPath.split("/")[1];

                                        store.currentItems =
                                            store.signalsReportStore.allDashboardsUserCanView.find(
                                                (i) => i.id === dashId,
                                            )?.signalsVizDefinitions ?? [];
                                        store.selectedItemGroup =
                                            i.header?.toString() ?? "";
                                    }
                                }}
                                style={{
                                    backgroundColor:
                                        store.selectedItemGroup === i.header
                                            ? hexToRGB(
                                                  theme.palette.blue.main,
                                                  0.1,
                                              )
                                            : theme.palette.white.main,
                                }}
                                className={classes.leftItem}
                            >
                                {i.header}
                            </Grid>
                        ),
                    )}
                </Grid>

                <Grid
                    container
                    item
                    direction={"column"}
                    wrap={"nowrap"}
                    xs={9}
                    className={classes.rightContainer}
                >
                    {store.currentItems
                        ?.filter((i) =>
                            i.chartTitle
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()),
                        )
                        .map((i) => {
                            return (
                                <Grid container key={i.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={store.selectedVizDefs
                                                    .map((i) => i.id)
                                                    .includes(i.id)}
                                                onChange={(e) =>
                                                    handleReportSelection(e, i)
                                                }
                                            />
                                        }
                                        label={
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                direction="column"
                                            >
                                                <Grid item>{i.chartTitle}</Grid>
                                                <Grid item fontSize={"10px"}>
                                                    {i.chartSubtitle}
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            );
                        })}
                </Grid>
            </Grid>
        </Grid>
    );
});

export default AddReportsDialogContent;
