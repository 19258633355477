import moment, { Moment } from "moment";
import React from "react";
import MonthBody from "./MonthBody";
import CalendarSideBar from "./CalendarSideBar";
import CalendarHeadDouble from "./TwoMonthCalendar/CalendarHeadDouble";
import CalendarBodyDouble from "./TwoMonthCalendar/CalendarBodyDouble";
import { Grid, Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    calendarContainer: {
        width: "246px",
        paddingRight: "4px",
        paddingLeft: "4px",
    },
}));

interface CalendarProps {
    onSelection: (selectedDate: moment.Moment) => void;
    selectedStartDate?: moment.Moment;
    selectedEndDate?: moment.Moment;
    isRangeSelect?: boolean;
    doubleCalendar?: boolean;
    sideBar?: boolean;
    handleSelectedDateOptionChange?: (inputVal: any) => void;
    choosingBegin?: boolean;
}

export default function Calendar(props: CalendarProps) {
    const classes = useStyles();
    const [mo, setMo] = React.useState(moment(props.selectedStartDate));
    const [mo2, setMo2] = React.useState(
        props.selectedEndDate?.month() === props.selectedStartDate?.month()
            ? moment(props.selectedStartDate).add(1, "month")
            : moment(props.selectedEndDate),
    );
    const [showMonth, setShowMonth] = React.useState(false);
    const [showMonth2, setShowMonth2] = React.useState(false);
    const [selectedMonth, setSelectedMonth] = React.useState("");
    const [selectedMonth2, setSelectedMonth2] = React.useState("");
    const [hoverDay, setHoverDay] = React.useState<Moment>();

    React.useEffect(() => {
        if (selectedMonth) {
            const mnth = parseInt(moment().month(selectedMonth!).format("M"));
            const yr = mo.get("year");
            const dy = mo.get("date");

            var m = moment({ y: yr, d: dy, month: mnth - 1 });
            setMo(m);
        }
        if (selectedMonth2) {
            const mnth2 = parseInt(moment().month(selectedMonth2!).format("M"));
            const yr2 = mo.get("year");
            const dy2 = mo.get("date");
            var m2 = moment({ y: yr2, d: dy2, month: mnth2 - 1 });
            setMo2(m2);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMonth, selectedMonth2]);

    const onNextMonth = () => {
        let m = mo.add(1, "month");
        let nmo = moment().year(m.year()).month(m.month());
        setMo(nmo);
    };
    const onPrevMonth = () => {
        let m = mo.add(-1, "month");
        let nmo = moment().year(m.year()).month(m.month());
        setMo(nmo);
    };
    const onMonthClick = () => {
        setShowMonth(!showMonth);
    };
    const onMonthSelect = (m) => {
        setSelectedMonth(m);
        onMonthClick();
    };

    const onNextMonth2 = () => {
        let m = mo2.add(1, "month");
        let nmo = moment().year(m.year()).month(m.month());
        setMo2(nmo);
    };
    const onPrevMonth2 = () => {
        let m = mo2.add(-1, "month");
        let nmo = moment().year(m.year()).month(m.month());
        setMo2(nmo);
    };
    const onMonthClick2 = () => {
        setShowMonth2(!showMonth2);
    };
    const onMonthSelect2 = (m) => {
        setSelectedMonth2(m);
        onMonthClick2();
    };

    return (
        <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            justifyContent="space-between"
            alignItems="stretch"
        >
            {props.sideBar && (
                <CalendarSideBar
                    handleSelectedDateOptionChange={
                        props.handleSelectedDateOptionChange
                    }
                />
            )}
            <div className={classes.calendarContainer}>
                {showMonth ? (
                    <MonthBody onMonthSelect={onMonthSelect} />
                ) : (
                    <>
                        <CalendarHeadDouble
                            onMonthClick={onMonthClick}
                            month={mo.month()}
                            year={mo.year()}
                            nextMonth={onNextMonth}
                            prevMonth={onPrevMonth}
                        />
                        <CalendarBodyDouble
                            month={mo.month()}
                            year={mo.year()}
                            dayClick={props.onSelection}
                            selectedStartDate={props.selectedStartDate}
                            selectedEndDate={props.selectedEndDate}
                            choosingBegin={props.choosingBegin}
                            hoverDay={hoverDay}
                            setHoverDay={setHoverDay}
                            isRangeSelect={props.isRangeSelect}
                        />
                    </>
                )}
            </div>
            {props.doubleCalendar && (
                <div className={classes.calendarContainer}>
                    {showMonth2 ? (
                        <MonthBody onMonthSelect={onMonthSelect2} />
                    ) : (
                        <>
                            <CalendarHeadDouble
                                onMonthClick={onMonthClick2}
                                month={mo2.month()}
                                year={mo2.year()}
                                nextMonth={onNextMonth2}
                                prevMonth={onPrevMonth2}
                            />
                            <CalendarBodyDouble
                                month={mo2.month()}
                                year={mo2.year()}
                                dayClick={props.onSelection}
                                selectedStartDate={props.selectedStartDate}
                                selectedEndDate={props.selectedEndDate}
                                choosingBegin={props.choosingBegin}
                                hoverDay={hoverDay}
                                setHoverDay={setHoverDay}
                                isRangeSelect={props.isRangeSelect}
                            />
                        </>
                    )}
                </div>
            )}
        </Grid>
    );
}
