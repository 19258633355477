import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { useStore } from "../../../../../utils/useStore";
import { WorkflowConditionType } from "../../../../Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import NonBossLevelApproveRejectModuleCard from "../../../../UI/Cards/NonBossLevelApproveRejectModuleCard";
import { EvalLicensedModule, EvalStore } from "../../../Stores/EvalStore";
import { EvalWorkflowStore } from "../../../Stores/EvalWorkflowStore";
import ModuleCard from "../ModuleCard";

/**
 *   state: "complete",
 bossModule: null,
 notes: instanceLevel.note,
 result: instanceLevel.result,
 createdBy: instanceLevel.createdBy,
 createdOn: instanceLevel.createdOn,
 level: instanceLevel.workflowMessagingMetadata
 .level,
 metadataId:
 instanceLevel.workflowMessagingMetadataId,
 */

export interface WorkflowLevelProps {
    levelType: WorkflowConditionType;
    state: "complete" | "pending" | "boss";
    workflowEvalModule: EvalLicensedModule | null;
    notes: string;
    result: string | null;
    createdBy: string;
    createdOn: string;
    level: number;
    metadataId: string;
    instanceId: string;
    name?: string;
}

type Props = WorkflowLevelProps;

/**
 * Renders an InstanceLevel as either an intermediate level (complete, pending) or a boss module within a <ModuleCard/> component.
 */
const WorkflowInstanceLevel: FunctionComponent<Props> = observer((props) => {
    const workflowStore = useStore(EvalStore).workflowStore;

    const APTLevelTitle = `APT Review - ${
        props.name ?? "Level " + (props.level + 1)
    } Reviewer`;

    return (
        <div>
            {props.workflowEvalModule && props.state === "boss" && (
                <ModuleCard module={props.workflowEvalModule} />
            )}

            {props.state === "pending" &&
                props.levelType === WorkflowConditionType.APT && (
                    <NonBossLevelApproveRejectModuleCard
                        key={props.level}
                        title={APTLevelTitle}
                        isOpen
                        reviewed={props.result as any}
                        reviewerNotes={!props.result ? props.notes : undefined}
                        subContent={props.result ? props.notes : undefined}
                        onNoteChange={(arg) =>
                            workflowStore.onCompleteLevel(
                                props.instanceId,
                                props.workflowEvalModule
                                    ? props.workflowEvalModule
                                          .evaluationModuleId
                                    : "",
                                props.level,
                                arg as string,
                                props.result as any,
                            )
                        }
                        onConfirm={() =>
                            workflowStore.onCompleteLevel(
                                props.instanceId,
                                props.workflowEvalModule
                                    ? props.workflowEvalModule
                                          .evaluationModuleId
                                    : "",
                                props.level,
                                props.notes,
                                EvalWorkflowStore.ApproveResult,
                            )
                        }
                        onReject={() =>
                            workflowStore.onCompleteLevel(
                                props.instanceId,
                                props.workflowEvalModule
                                    ? props.workflowEvalModule
                                          .evaluationModuleId
                                    : "",
                                props.level,
                                props.notes,
                                EvalWorkflowStore.RejectResult,
                            )
                        }
                        reviewerName={props.createdBy}
                        verifiedDate={props.createdOn}
                        lineClamp={1}
                        customRootStyles={{
                            marginTop: "1.5rem",
                            width: "100%",
                        }}
                        confirmationText="Continue Workflow"
                        rejectionText="Close Workflow"
                        module={
                            props.workflowEvalModule
                                ? props.workflowEvalModule
                                : undefined
                        }
                    />
                )}

            {props.state === "complete" &&
                props.levelType === WorkflowConditionType.APT && (
                    <NonBossLevelApproveRejectModuleCard
                        key={props.level}
                        title={APTLevelTitle}
                        reviewed={props.result as any}
                        isOpen
                        reviewerName={props.createdBy}
                        verifiedDate={props.createdOn}
                        subContent={props.notes}
                        lineClamp={1}
                        customRootStyles={{
                            marginTop: "1.5rem",
                            width: "100%",
                        }}
                        module={
                            props.workflowEvalModule
                                ? props.workflowEvalModule
                                : undefined
                        }
                    />
                )}
        </div>
    );
});

export default WorkflowInstanceLevel;
