import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { Action } from "../../../../../models/Actions/Action";
import { EvalReviewActionMetadata } from "../../../../../models/Actions/Evaluation/EvalReviewActionMetadata";
import {
    parseFromISO,
    standardDateTimeFormat,
} from "../../../../../utils/DateTimeUtils";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(1.5),
    },

    vertSpacing: {
        marginBottom: theme.spacing(1.5),
    },
    sideLeftPadding: {
        paddingLeft: theme.spacing(3.5),
    },
    sideRightPadding: {
        paddingRight: theme.spacing(3.5),
    },
    responseIdent: {
        marginLeft: theme.spacing(3),
    },

    notesContainer: {
        marginTop: theme.spacing(2),
    },

    fullSize: {
        height: "100%",
        width: "100%",
    },

    subtitleText: {
        fontSize: "12px",
    },
    labelText: {
        // fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    },
}));

interface OwnProps {
    request: Action<EvalReviewActionMetadata>;
}

type Props = OwnProps;

const RequestDetails: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const classes = useStyles();
    let actionRecipients;

    if (props.request.actionRecipients) {
        actionRecipients = props.request.actionRecipients.map(
            (recipient, index) =>
                `${
                    recipient.user?.userName
                        ? recipient.user.userName
                        : "Unknown"
                }${props.request?.actionRecipients?.[index + 1] ? ", " : ""}`,
        );
    }

    return (
        <>
            <Grid
                container
                justifyContent={"flex-start"}
                className={clsx(classes.sideLeftPadding, classes.root)}
            >
                <Grid item xs={12}>
                    <Typography
                        className={classes.labelText}
                        variant={"subtitle1"}
                    >
                        {props.request.actionMetadata.parsedMetadata.reason}{" "}
                        Review
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography
                        className={classes.subtitleText}
                        variant={"subtitle2"}
                        color={"textSecondary"}
                    >
                        Started by {props.request.user.userName},{" "}
                        {standardDateTimeFormat(
                            parseFromISO(props.request.createdOn!),
                        )}
                    </Typography>
                </Grid>

                {props.request.actionRecipients &&
                    props.request.actionRecipients.length > 0 && (
                        <Grid item xs={12}>
                            <Typography
                                className={classes.subtitleText}
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                Action Recipients:
                            </Typography>

                            <Typography
                                className={classes.subtitleText}
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                {actionRecipients}
                            </Typography>
                        </Grid>
                    )}

                <Grid container className={classes.notesContainer}>
                    <Grid item lg={6} md={8} xs={11}>
                        <Typography>
                            {props.request.actionMetadata.parsedMetadata.notes}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});

export default RequestDetails;
