import makeStyles from "@mui/styles/makeStyles";
import {
    GridCellParams,
    GridColDef,
    GridFilterInputValueProps,
} from "@mui/x-data-grid-pro";
import _ from "lodash";
import React from "react";

const useStyles = makeStyles({
    root: {
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        height: 48,
        paddingLeft: 20,
    },
    emptyHiddenFilterInput: {
        width: "10rem",
        height: "100%",
    },
});

const EmptyHiddenFilterInput = (props: GridFilterInputValueProps) => {
    const classes = useStyles();
    const { item, applyValue } = props;

    const handleFilterChange = (event) => {
        applyValue({ ...item, value: event.target.value });
    };

    return (
        <div className={classes.root}>
            <div className={classes.emptyHiddenFilterInput}>
                <input
                    id="EmptyHiddenFilterInput"
                    type="hidden"
                    value=""
                    placeholder="Filter value"
                    onChange={handleFilterChange}
                />
            </div>
        </div>
    );
};

export const CUSTOM_FILTERS = [
    {
        label: "isNotEmpty",
        value: "isNotEmpty",
        getApplyFilterFn: (filterItem: any, column: GridColDef): any => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }

            return (params: GridCellParams): boolean => {
                const rowValue = _.get(params.row, filterItem.columnField);

                if (Array.isArray(rowValue)) {
                    return !!rowValue.length;
                }

                return !!rowValue;
            };
        },
        InputComponent: EmptyHiddenFilterInput,
        InputComponentProps: { type: "string" },
        CustomOperatorFilter: true,
    },
    {
        label: "isEmpty",
        value: "isEmpty",
        getApplyFilterFn: (filterItem: any, column: GridColDef): any => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }

            return (params: GridCellParams): boolean => {
                const rowValue = _.get(params.row, filterItem.columnField);

                if (Array.isArray(rowValue)) {
                    return !rowValue.length;
                }

                return !rowValue;
            };
        },
        InputComponent: EmptyHiddenFilterInput,
        InputComponentProps: { type: "string" },
        CustomOperatorFilter: true,
    },
];
