import moment from "moment";
import { serializeToUtc } from "utils/DateTimeUtils";
import AudioMetadataModel from "../models/AudioMetadataModel";
import { RecommendedSample } from "../models/RecommendedSample";
import { BaseService } from "./BaseService";
import { IExtendedMetadataFilterItem } from "components/ManagerInteractions/Stores/ManagerInteractionsStore";

export class AudioSamplingService extends BaseService {
    protected urlBase: string = "api/AudioSampling";

    async generateSamplingSuggestion(
        orgId: string,
        ruleSetId: string,
        specName: string,
        orgStructMemberId: string | undefined | null,
        samplingDirectory: string,
        sampleSize: number,
        startDate: string,
        endDate: string,
        csvContents: string,
        allowNullOverwrites = false,
    ): Promise<AudioMetadataModel[]> {
        const urlSearchParams = new URLSearchParams({
            orgId,
            specName,
            samplingDirectory,
            ruleSetId,
            startDate,
            endDate,
            allowNullOverwrites: allowNullOverwrites.toString(),
        });

        urlSearchParams.append("sampleSize", sampleSize.toString(10));
        if (orgStructMemberId) {
            urlSearchParams.append("orgStructMemberId", orgStructMemberId);
        }

        return await this.post(
            csvContents ?? "",
            urlSearchParams,
            "GenerateSample",
        );
    }

    async generateSamplingSuggestionV2(
        orgId: string,
        ruleSetId: string,
        specId: string | undefined,
        sampleSize: string,
        startDate: string,
        endDate: string,
        csvContents: string,
        allowNullOverwrites = false,
        dirIds?: string[],
        hierarchyIds?: string[],
    ): Promise<RecommendedSample> {
        const body = {
            orgId,
            ruleSetId,
            startDate,
            endDate,
            allowNullOverwrites: allowNullOverwrites.toString(),
            specId,
            sampleSize,
            dirIds,
            hierarchyIds,
        };
        const params = new URLSearchParams({});
        // urlSearchParams.append("sampleSize", sampleSize);

        // if (specId) {
        //     urlSearchParams.append("specId", specId);
        // }

        // if (dirIds) {
        //     for (const dirId of dirIds) {
        //         urlSearchParams.append("dirIds", dirId);
        //     }
        // }

        // if (hierarchyIds) {
        //     for (const hierId of hierarchyIds) {
        //         urlSearchParams.append("hierarchyIds", hierId);
        //     }
        // }

        // return await this.post<RecommendedSample>(
        //     csvContents ?? "",
        //     urlSearchParams,
        //     "GenerateSampleV2",
        // );
        return await this.post<RecommendedSample>(
            JSON.stringify(body),
            params,
            "GenerateSampleV2",
        );
    }

    async generateOnDemandSample(
        startDate: moment.Moment,
        endDate: moment.Moment,
        organizationId: string,
        callDirection?: string,
        agentId?: string,
        mediaType?: string, // Enum of OnDemandMediaTypes
        callDurationMin?: number,
        callDurationMax?: number,
        meta1?: string,
        meta2?: string,
        meta3?: string,
        meta4?: string,
        meta5?: string,
        phone?: string,
        campaignId?: string,
        extendedMetadataFields?: IExtendedMetadataFilterItem[],
        conversationMessageCountMax?: number,
        conversationMessageCountMin?: number,
    ) {
        const params = new URLSearchParams({});

        const extendedMetadataFieldsObject = {};

        if (extendedMetadataFields) {
            extendedMetadataFields
                .filter((extMetaItem) => extMetaItem.value)
                .forEach((extMetaItem) => {
                    extendedMetadataFieldsObject[extMetaItem.item1] =
                        extMetaItem.value;
                });
        }

        const body = {
            startDate: serializeToUtc(startDate),
            endDate: serializeToUtc(endDate),
            organizationId,
            callDirection,
            agentId,
            mediaType,
            callDurationMin,
            callDurationMax,
            meta1,
            meta2,
            meta3,
            meta4,
            meta5,
            phone,
            campaignId,
            extendedMetadataFields: extendedMetadataFieldsObject,
            conversationMessageCountMax,
            conversationMessageCountMin,
        };

        const response = await this.post(
            JSON.stringify(body),
            params,
            "GenerateOnDemandSample",
        );

        return response;
    }
}
