import { Grid, IconButton, Theme, Tooltip, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Search } from "@mui/icons-material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import clsx from "clsx";
import AcxMenu, {
    AcxMenuItemProps,
    StyledMenuLabel,
} from "components/UI/Menu/AcxMenu";
import { observer } from "mobx-react";
import React, { useState } from "react";
import SearchOff from "../../../SvgIcons/SearchOffSvg";
import { toggleZoomViewTooltip } from "../SoundClipVisualizer";
import SoundClipEditorStore from "../Stores/SoundClipEditorStore";

const useStyles = makeStyles((theme: Theme) => ({
    iconButton: {
        padding: theme.spacing(0),
        color: "#3564D5",
    },
    zoomButtonContainer: {
        borderRadius: "0.25rem",
        display: "flex",
        alignContent: "center",
    },
    zoomContainer: {
        borderRadius: "0.25rem",
        display: "flex",
        alignContent: "center",
        padding: theme.spacing(0),
    },
    zoomControl: {
        padding: theme.spacing(0),
        color: "#3564D5",
    },
    highlight: {
        borderRadius: "49px",
        backgroundColor: "#DBEAFE",
    },
    container: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(0.5),
        width: "auto",
    },
}));

interface ZoomProps {
    viewModel: SoundClipEditorStore;
    minimal?: boolean;
}

const Zoom: React.FC<ZoomProps> = observer(({ viewModel, minimal }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const onMinimizedZoomOut = () => {
        if (viewModel.zoomIndex === viewModel.zoomLevelsArray.length - 1) {
            viewModel.setZoomView(false);
        } else {
            viewModel.zoomOut();
        }
    };

    const onMinimizedZoomIn = () => {
        if (!viewModel.showZoomView) {
            viewModel.setZoomView(true);
        } else {
            viewModel.zoomIn();
        }
    };

    const zoomMenuItems: AcxMenuItemProps[] = [
        {
            id: "zoom-in",
            label: <StyledMenuLabel>Zoom In</StyledMenuLabel>,
            icon: <ZoomInIcon fontSize="small" />,
            props: {
                onClick: onMinimizedZoomIn,
                disabled: viewModel.zoomIndex === 0,
            },
            toolTipText: "Zoom In",
        },
        {
            id: "zoom-out",
            label: <StyledMenuLabel>Zoom Out</StyledMenuLabel>,
            icon: <ZoomOutIcon fontSize="small" />,
            props: {
                onClick: onMinimizedZoomOut,
                disabled: !viewModel.showZoomView,
            },
            toolTipText: "Zoom Out",
        },
    ];

    const handleZoomMenuClose = () => {
        setAnchorEl(null);
    };

    const handlePlaybackMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Grid
            item
            sx={{
                display: "flex",
                flexDirection: "row",
                padding: theme.spacing(0.5),
                width: "auto",
                alignItems: "center",
            }}
            className={clsx(
                classes.container,
                viewModel.showZoomView && classes.highlight,
            )}
        >
            <Grid
                item
                sx={{
                    borderRadius: "0.25rem",
                    display: "flex",
                    alignContent: "center",
                    padding: theme.spacing(0),
                }}
                style={{ paddingRight: minimal ? "0px" : "6px" }}
            >
                <Tooltip title={toggleZoomViewTooltip}>
                    <span>
                        <IconButton
                            onClick={
                                minimal
                                    ? handlePlaybackMenuClick
                                    : viewModel.toggleZoomView
                            }
                            size="small"
                            sx={{ padding: theme.spacing(0), color: "#3564D5" }}
                        >
                            {viewModel.showZoomView && !minimal ? (
                                <SearchOff />
                            ) : (
                                <Search fontSize="small" />
                            )}
                        </IconButton>
                    </span>
                </Tooltip>
            </Grid>
            {!minimal ? (
                <>
                    <Grid
                        item
                        sx={{
                            borderRadius: "0.25rem",
                            display: "flex",
                            alignContent: "center",
                        }}
                        // style={{ paddingRight: minimal ? "0px" : "6px" }}
                    >
                        <Tooltip title="Zoom Out">
                            <span>
                                <IconButton
                                    onClick={() => {
                                        viewModel.zoomOut();
                                    }}
                                    className={classes.iconButton}
                                    disabled={
                                        !viewModel.showZoomView ||
                                        viewModel.zoomIndex ===
                                            viewModel.zoomLevelsArray.length - 1
                                    }
                                    size="large"
                                >
                                    <ZoomOutIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            borderRadius: "0.25rem",
                            display: "flex",
                            alignContent: "center",
                        }}
                    >
                        <Tooltip title="Zoom In">
                            <span>
                                <IconButton
                                    onClick={() => {
                                        viewModel.zoomIn();
                                    }}
                                    className={classes.iconButton}
                                    disabled={
                                        !viewModel.showZoomView ||
                                        viewModel.zoomIndex === 0
                                    }
                                    size="large"
                                >
                                    <ZoomInIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </>
            ) : (
                <AcxMenu
                    menuItems={zoomMenuItems}
                    anchorElement={anchorEl}
                    onMenuClose={handleZoomMenuClose}
                />
            )}
        </Grid>
    );
});

export default Zoom;
