import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { useStore } from "../../../../../utils/useStore";
import { StepperStore } from "../Stores/StepperStore";
import ConfigurationStep from "./ConfigurationStep/ConfigurationStep";
import RecommendationStep from "./RecommendationStep/RecommendationStep";
import SourceFilesStep from "./SourceFilesStep/SourceFilesStep";
import StepSelector from "./StepSelector";

interface OwnProps {}

type Props = OwnProps;

const StepViewer: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const store = useStore(StepperStore);

    return (
        <Grid item xs={12} style={{ height: "calc(100% - 72px)" }}>
            <StepSelector index={0} activeStepIndex={store.stepIndex}>
                <SourceFilesStep />
            </StepSelector>
            <StepSelector index={1} activeStepIndex={store.stepIndex}>
                <ConfigurationStep />
            </StepSelector>
            <StepSelector index={2} activeStepIndex={store.stepIndex}>
                <RecommendationStep />
            </StepSelector>
        </Grid>
    );
});

export default StepViewer;
