import moment, { Moment } from "moment";
import { SignalsTemporalLabels } from "../Models/SignalsModels";

export const THE_MICHAEL_GRAIN = 12;

export function temporalGrain(
    startDate: Moment,
    endDate: Moment,
): SignalsTemporalLabels {
    const daysInRange = endDate.diff(startDate, "days");

    if (daysInRange <= THE_MICHAEL_GRAIN) {
        return "Daily";
    }
    const weeksInRange = endDate.diff(startDate, "weeks");

    if (weeksInRange <= THE_MICHAEL_GRAIN) {
        return "Weekly";
    }

    // use start of month because that matches backend calculation in GroupByAuthenticxTemporalGrain function in SignalsReportQuery.cs
    const monthsInRange = moment(endDate)
        .startOf("M")
        .diff(moment(startDate).startOf("M"), "months");

    if (monthsInRange <= THE_MICHAEL_GRAIN) {
        return "Monthly";
    }

    return "Quarterly";
}
