import BarChartIcon from "@mui/icons-material/BarChart";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PieChartIcon from "@mui/icons-material/PieChart";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import TableChartIcon from "@mui/icons-material/TableChart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { AcxMenuItemProps, StyledMenuLabel } from "components/UI/Menu/AcxMenu";
import NavigationStore, {
    INavigation,
    INavigationGroup,
} from "Layouts/SideNavigation/NavigationStore";
import type { IObservableArray } from "mobx";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import moment from "moment";
import { User } from "oidc-client";
import Papa from "papaparse";
import React from "react";
import type { GoogleChartWrapper } from "react-google-charts/dist/types";
import { ReportsService } from "services/ReportsService";
import {
    DatePickerComponentStore,
    DateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import { OrganizationStore } from "stores/OrganizationStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import theme from "Theme/AppTheme";
import { parseFromISO } from "utils/DateTimeUtils";
import hexToRGB from "utils/hexToRGB";
import { DynamicTimeSpan, timeSpanToMoments } from "utils/reportingTimeHelpers";
import { ReportModel, VizType } from "../../../../models/Reporting/ReportModel";
import { WidgetsService } from "../../../../services/WidgetsService";
import { AuthStore } from "../../../../stores/AuthStore";
import { BaseStore } from "../../../../stores/BaseStore";
import { LayoutDrawerStore } from "../../../../stores/Layout/LayoutDrawerStore";
import { PublishedWidgetStore } from "../../../../stores/PublishedWidgetStore";
import { ReportDataViewModel } from "../Common/ReportDataViewModel";
import VizOptions from "../VizOptions";
import { ReportEditorStore } from "./ReportEditorStore";
import { ReportQuickFilterStore } from "./ReportQuickFilterStore";

export interface IReport {
    id: string;
    organizationId?: string;
    group: string;
    name: string;
    roles?: string[];
    persona?: string[];
    createdBy: string;
    modifiedBy: string;
    modifiedOn: string; // modification day
    modifiedOnDate: string; // modification dateTime
    modifiedAt: string; // modification time
    vizType?: VizType;
    drillThru?: boolean;
    xAxisLabel?: string;
    yAxisLabel?: string;
    report?: ReportModel;
    isPublished?: boolean;
    vizOptions: any;
}

interface UnparseObject {
    data: [];
    fields: [];
}

@AcxStore
class ReportsStore extends BaseStore {
    private readonly widgetService: WidgetsService = new WidgetsService();
    private readonly reportServiceV2: ReportsService = new ReportsService(
        "2.0",
    );

    readonly authStore: AuthStore;
    readonly orgStore: OrganizationStore;
    readonly messageStore: MessageStore;
    readonly quickFilterStore: ReportQuickFilterStore;
    readonly navStore: NavigationStore;

    reportDataViewModel: ReportDataViewModel = new ReportDataViewModel();

    @observable currentReport: IReport | null;
    @observable currentGroup;
    @observable isSettingUpReports: boolean = false;
    @observable.ref menuItemsBuilder?: (
        closeMenu?: () => void,
    ) => AcxMenuItemProps[];

    datePickerStore = new DatePickerComponentStore(
        undefined,
        undefined,
        DateReferenceOption.InteractionDate,
    );
    @observable orgSelectorStore = new OrgSelectorComponentStore();

    @observable reports: IObservableArray<IReport> = observable([]);
    @observable filteredReports: IReport[] = [];

    @observable itemGroups: INavigationGroup[];

    @observable reportsSearchTerm: string;
    @observable private user: User | null;

    @observable expandAll: boolean;

    @observable.ref chartWrapper?: GoogleChartWrapper;

    @observable lastRefreshTime: string;

    currentReportsNavGroups;

    public constructor(private rootStore: IRootStore) {
        super("Reports Store");
        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.orgStore = rootStore.getStore(AuthStore).orgStore;
        this.messageStore = rootStore.getStore(MessageStore);
        this.quickFilterStore = rootStore.getStore(ReportQuickFilterStore);
        this.navStore = new NavigationStore(
            rootStore,
            "Signals-Reports",
            this.itemGroups,
            this.orgSelectorStore,
        );

        rootStore
            .getStore(AuthStore)
            .getUserObject()
            .then((value) => {
                runInAction(() => {
                    this.user = value;
                });
            })
            .catch((reason) =>
                console.error(`Failed to retrieve User Profile ${reason}`),
            );

        reaction(
            (r) => ({
                storeError: this.nextTaskError,
                viewModelError: this.reportDataViewModel.nextTaskError,
            }),
            (arg) => {
                if (
                    arg &&
                    (arg.storeError?.message || arg.viewModelError?.message)
                ) {
                    const msg =
                        arg.storeError?.message ?? arg.viewModelError?.message;
                    this.rootStore.getStore(MessageStore).logError(msg!);
                    this.clearLastTaskError();
                }
            },
            { delay: 0 },
        );

        reaction(
            () => ({
                activeLocation: this.rootStore.activeLocation,
            }),
            async (args, prevValue) => {
                if (
                    args.activeLocation &&
                    !args.activeLocation.location.includes("reports")
                ) {
                    return;
                }

                const searchParams = new URLSearchParams(
                    window.location.search,
                );
                const timespan = searchParams.get(
                    "timespan",
                ) as DynamicTimeSpan | null;
                const startDate = searchParams.get("start");
                const endDate = searchParams.get("end");

                // Exit if not navigating directly from a report om the dashboard
                if (!timespan && !startDate && !endDate) {
                    return;
                }
                // Update date picker store for reports with date values from the dashboard filters
                if (timespan !== null) {
                    const convertedTimespan = timeSpanToMoments(timespan);
                    this.datePickerStore.setBeginDate(convertedTimespan[0]);
                    this.datePickerStore.setEndDate(convertedTimespan[1]);
                } else if (startDate && endDate) {
                    this.datePickerStore.setBeginDate(moment(startDate));
                    this.datePickerStore.setEndDate(moment(endDate));
                }
            },
            { delay: 2, fireImmediately: true },
        );

        reaction(
            () => ({
                orgId: this.orgSelectorStore.orgId,
                activeLocation: this.rootStore.activeLocation,
            }),
            async (args, prevValue) => {
                if (
                    args.activeLocation &&
                    !args.activeLocation.location.includes("reports")
                ) {
                    return;
                }

                if (!args.orgId) {
                    return;
                }

                if (prevValue?.orgId !== args.orgId || !this.reports.length) {
                    this.reportDataViewModel.setOrgId(args.orgId);
                    this.setupAsyncTask("Load Reports", () =>
                        this.loadReports(args.orgId),
                    );
                }
            },
            { delay: 10, fireImmediately: true },
        );

        reaction(
            () => this.reportsSearchTerm,
            this.debounceEffect((reportsSearchTerm) => {
                if (reportsSearchTerm) {
                    const foundSearchTermCallback = (i: INavigation) =>
                        i.title
                            ?.toString()
                            .toLowerCase()
                            .includes(reportsSearchTerm.toLowerCase());
                    const navGroupCopy = [...this.currentReportsNavGroups];

                    const currentNavItems = navGroupCopy
                        .filter((g) => {
                            if (g.items.some(foundSearchTermCallback)) {
                                g.expanded = true;
                                return true;
                            } else {
                                return false;
                            }
                        })
                        .map((g) => {
                            return {
                                ...g,
                                items: g.items.filter(foundSearchTermCallback),
                            };
                        });

                    this.navStore.setNavItems(currentNavItems);
                } else {
                    this.currentReportsNavGroups.forEach(
                        (g) => (g.expanded = false),
                    );
                    this.navStore.setNavItems(this.currentReportsNavGroups);
                }
            }, 500),
        );

        reaction(
            () => ({
                filters: toJS(this.quickFilterStore.quickFilters),
            }),
            this.debounceEffect(async (args) => {
                if (
                    this.quickFilterStore.lockReportRefresh ||
                    !this.currentReport
                ) {
                    return;
                } // don't do anything if the report refresh is locked or currentReport is empty

                this.reportDataViewModel.setQuickFilters(args.filters);
            }, 555),
            { delay: 10 },
        );

        reaction(
            () => ({ report: this.currentReport }),
            this.debounceEffect(async (arg) => {
                if (arg.report && arg.report.id) {
                    this.setupAsyncTask(`Loading Quick Filters`, () =>
                        this.quickFilterStore.loadQuickFilters(
                            this.orgSelectorStore.orgId,
                            arg.report!.id,
                        ),
                    );
                }
            }, 100),
            { delay: 10 },
        );

        reaction(
            () => ({
                report: this.currentReport,
                startDate: this.datePickerStore.beginDate,
                endDate: this.datePickerStore.endDate,
                dateRef: this.datePickerStore.referenceOption,
                orgId: this.orgSelectorStore.orgId,
            }),
            this.debounceEffect(async (arg) => {
                if (!arg.report) {
                    return;
                }

                this.reportDataViewModel.setReport(
                    arg.report,
                    arg.startDate,
                    arg.endDate,
                    arg.dateRef,
                    undefined,
                    undefined,
                );

                this.isSettingUpReports = false;

                if (
                    this.reportDataViewModel.currentReport?.vizType !==
                    VizType.Table
                ) {
                    this.buildWidgetMenuItems();
                } else {
                    this.menuItemsBuilder = undefined;
                }
            }, 450),
            { delay: 10, fireImmediately: true },
        );
    }

    signalRoutePrefix = "/app/signals/reports";

    getIcon = (r: IReport) => {
        if (r.vizType === VizType.Line) {
            return {
                icon: (
                    <TrendingUpIcon
                        fontSize="small"
                        htmlColor={hexToRGB(theme.palette.black.main, 0.5)}
                    />
                ),
                selectedIcon: (
                    <TrendingUpIcon fontSize="small" color="secondary" />
                ),
            };
        } else if (
            r.vizType === VizType.Bar ||
            r.vizType === VizType.HorizontalBar
        ) {
            return {
                icon: (
                    <EqualizerIcon
                        fontSize="small"
                        htmlColor={hexToRGB(theme.palette.black.main, 0.5)}
                    />
                ),
                selectedIcon: (
                    <EqualizerIcon fontSize="small" color="secondary" />
                ),
            };
        } else if (r.vizType === VizType.Table) {
            return {
                icon: (
                    <TableChartIcon
                        fontSize="small"
                        htmlColor={hexToRGB(theme.palette.black.main, 0.5)}
                    />
                ),
                selectedIcon: (
                    <TableChartIcon fontSize="small" color="secondary" />
                ),
            };
        } else if (r.vizType === VizType.Pie) {
            return {
                icon: (
                    <PieChartIcon
                        fontSize="small"
                        htmlColor={hexToRGB(theme.palette.black.main, 0.5)}
                    />
                ),
                selectedIcon: (
                    <PieChartIcon fontSize="small" color="secondary" />
                ),
            };
        } else if (r.vizType === VizType.Histogram) {
            return {
                icon: (
                    <BarChartIcon
                        fontSize="small"
                        htmlColor={hexToRGB(theme.palette.black.main, 0.5)}
                    />
                ),
                selectedIcon: (
                    <BarChartIcon fontSize="small" color="secondary" />
                ),
            };
        } else if (r.vizType === VizType.Scatter) {
            return {
                icon: (
                    <ScatterPlotIcon
                        fontSize="small"
                        htmlColor={hexToRGB(theme.palette.black.main, 0.5)}
                    />
                ),
                selectedIcon: (
                    <ScatterPlotIcon fontSize="small" color="secondary" />
                ),
            };
        }
        return undefined;
    };

    @action
    prepareReportSwitch() {
        this.quickFilterStore.reset();
        this.reportDataViewModel.setQuickFilters([]);
        this.isSettingUpReports = true;
    }

    populateChartConfigDrawer = () => {
        if (this.currentReport) {
            const report = this.currentReport;
            const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
            drawerStore.closeAndResetDrawer();
            drawerStore.restoreDefaults();

            drawerStore.setContentFactory(() => (
                <VizOptions report={report} store={this} />
            ));

            drawerStore.setOpen(true);
        }
    };

    @computed
    get canEditReports() {
        const authStore = this.rootStore.getStore(AuthStore);

        const canUserEdit =
            authStore.canUserEdit("Reporting V2") ||
            authStore.canUserEdit("Reporting");

        return canUserEdit;
    }

    @action
    refreshReports(newReportId?: string) {
        this.setupAsyncTask("Load Reports", () =>
            this.loadReports(this.orgSelectorStore.orgId, newReportId),
        );
    }

    @action
    private loadReports = async (orgId?: string, newReportId?: string) => {
        if (!orgId) return;

        const reports = await this.reportServiceV2.getReportsV2(orgId);
        runInAction(() => {
            this.reports.clear();
            this.reports.replace(reports.reports.map(this.formatReportTimes));
            this.lastRefreshTime = moment
                .utc(reports.refreshStats.daily)
                .local()
                .format("MMM Do YYYY, h:mm a");
            if (newReportId) {
                this.currentReport =
                    this.reports.find((value) => value.id === newReportId) ??
                    null;
            }

            const grps = [
                ...new Set(this.reports.map((r) => r.group)),
            ] as string[];
            const navgrp: INavigationGroup[] = [];
            const expanded = Boolean(this.reportsSearchTerm);
            for (let i = 0; i < grps.length; i++) {
                const element = grps[i];
                const navItems: INavigation[] = [];
                const items = this.reports.filter((x) => x.group === element);
                if (items) {
                    items.forEach((el) => {
                        navItems.push({
                            title: el.name,
                            badgeContent: el.isPublished ? "P" : undefined,
                            badgeOptions: {
                                variant: "dot",
                                color: "primary",
                            },
                            icon: this.getIcon(el)?.icon,
                            selectedIcon: this.getIcon(el)?.selectedIcon,
                            link: `${this.signalRoutePrefix}/${el?.organizationId}/${el?.id}`,
                        });
                    });
                }
                const grp: INavigationGroup = {
                    header: element,
                    items: navItems,
                    expanded: expanded,
                };
                navgrp.push(grp);
            }
            this.currentReportsNavGroups = navgrp;
            this.navStore.setNavItems(navgrp);
        });
    };

    formatReportTimes(report: IReport): IReport {
        const modifiedOn = parseFromISO(
            report.modifiedOnDate ?? moment().toISOString(),
        );
        const now = moment();
        const moreThanAYearAgo = modifiedOn.isBefore(now, "year");

        const editedOnFormat = moreThanAYearAgo ? "MMM D, YYYY" : "MMM D";
        const editedAtFormat = "h:mm a";

        report.modifiedOn = report.modifiedOn
            ? modifiedOn.format(editedOnFormat)
            : "";
        report.modifiedAt = report.modifiedOn
            ? modifiedOn.format(editedAtFormat)
            : "";
        return report;
    }

    @action
    initializeReportEditorForNew() {
        if (!this.user) {
            console.error("User profile required to Edit/Create reports");
            return;
        }
        this.rootStore
            .getStore(ReportEditorStore)
            .initializeFromReportStore(
                this.user,
                this.orgSelectorStore.orgId,
                undefined,
            );
    }

    @action
    initializeReportEditorForExisting() {
        if (!this.user) {
            console.error("User profile required to Edit/Create reports");
            return;
        }
        this.rootStore
            .getStore(ReportEditorStore)
            .initializeFromReportStore(
                this.user,
                this.orgSelectorStore.orgId,
                this.currentReport?.id,
            );
    }

    @action
    async deleteReport() {
        if (this.currentReport) {
            this.setupAsyncTask(`Delete Report`, async () => {
                if (this.currentReport?.id) {
                    const reportId = this.currentReport.id;
                    await this.reportServiceV2.deleteReport(reportId);
                    this.rootStore
                        .getStore(PublishedWidgetStore)
                        .refreshWidgets();
                    runInAction(() => {
                        const deletedItem = this.reports.find(
                            (value) => value.id === reportId,
                        );
                        if (deletedItem) {
                            this.reports.remove(deletedItem);
                        }
                        this.currentReport = null;
                    });
                }
            });
        }
    }

    @action
    clearDrillDownState = () => {
        this.reportDataViewModel.clearDrillDownState();
    };

    @action
    updateReportVizOptions(options: any) {
        this.setupAsyncTask("Update Visualization Options", async () => {
            if (!this.currentReport?.report) {
                throw new Error("No report available");
            }
            const report = toJS(this.currentReport.report);
            report.vizOptions = toJS(options);

            await this.reportServiceV2.updateReport(
                report,
                this.orgSelectorStore.orgId,
            );

            const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
            drawerStore.closeAndResetDrawer();
            drawerStore.restoreDefaults();

            this.refreshReports();
            this.messageStore.logMessage(
                `Report '${report.name}' Options Updated`,
                "success",
            );
        });
    }

    @action
    captureChartRef = (chartWrapper: GoogleChartWrapper) => {
        this.chartWrapper = chartWrapper;
    };

    @action
    publishReportAsWidget = () => {
        if (!this.currentReport?.id) {
            console.warn(`Attempting to publish widget with empty report`);
            return;
        }
        const reportId = this.currentReport.id;
        const reportName = this.currentReport.name;
        this.setupAsyncTask("Publish Report Widget", () =>
            this.widgetService
                .publishWidget(reportId)
                .then((value) => {
                    this.messageStore.logMessage(
                        `Report '${reportName}' Published as Widget`,
                        "success",
                    );
                })
                .then((value) => {
                    this.refreshReports();
                    this.rootStore
                        .getStore(PublishedWidgetStore)
                        .refreshWidgets();
                }),
        );
    };

    @action
    unPublishReportWidget = () => {
        if (!this.currentReport?.id || !this.currentReport?.organizationId) {
            console.warn(`Attempting to un-publish widget with empty report`);
            return;
        }
        const orgId = this.currentReport.organizationId;
        const reportId = this.currentReport.id;
        const reportName = this.currentReport.name;
        this.setupAsyncTask("UnPublish Report Widget", () =>
            this.widgetService
                .unPublishWidget(orgId, reportId)
                .then((value) => {
                    this.messageStore.logMessage(
                        `Report Widget '${reportName}' UnPublished`,
                        "success",
                    );
                })
                .then((value) => {
                    this.refreshReports();
                    this.rootStore
                        .getStore(PublishedWidgetStore)
                        .refreshWidgets();
                }),
        );
    };

    @action
    private buildWidgetMenuItems() {
        const menuItems: (close?: () => void) => AcxMenuItemProps[] = (
            close,
        ) => {
            return this.authStore.canUserView("Reporting V2") ||
                this.authStore.canUserView("Signals")
                ? [
                      {
                          id: `export-to-csv-button`,
                          label: (
                              <StyledMenuLabel>Export to CSV</StyledMenuLabel>
                          ),
                          icon: <SubdirectoryArrowRightIcon />,
                          props: {
                              onClick: () => {
                                  let parsedData = {} as any;
                                  parsedData["data"] = [];

                                  parsedData["fields"] = [
                                      this.reportDataViewModel.currentReport
                                          ?.xAxisLabel,
                                      this.reportDataViewModel.currentReport
                                          ?.yAxisLabel,
                                  ];

                                  this.reportDataViewModel.googleFormattedData?.forEach(
                                      (row, index) => {
                                          if (index === 0) {
                                              parsedData["data"].push(
                                                  row.filter(
                                                      (x) =>
                                                          typeof x === "string",
                                                  ),
                                              );

                                              if (
                                                  parsedData["data"][0].length >
                                                  2
                                              ) {
                                                  var extraFields =
                                                      parsedData["data"][0]
                                                          .length - 2;

                                                  for (
                                                      let i = 0;
                                                      i < extraFields;
                                                      i++
                                                  ) {
                                                      parsedData["fields"].push(
                                                          "",
                                                      );
                                                  }
                                              }
                                          } else {
                                              parsedData["data"].push(
                                                  row.filter(
                                                      (x, index) =>
                                                          index === 0 ||
                                                          typeof x ===
                                                              "number" ||
                                                          x === null,
                                                  ),
                                              );
                                          }
                                      },
                                  );

                                  parsedData = Papa.unparse(
                                      parsedData as UnparseObject,
                                  );

                                  let downloadLink =
                                      document.createElement("a");
                                  const blob = new Blob(["\ufeff", parsedData]);
                                  const url = URL.createObjectURL(blob);

                                  downloadLink.href = url;
                                  downloadLink.download = "My_Report.csv";

                                  document.body.appendChild(downloadLink);
                                  downloadLink.click();
                                  document.body.removeChild(downloadLink);
                                  close?.();
                              },
                          },
                      },
                  ]
                : [];
        };
        this.menuItemsBuilder = menuItems;
    }
}

export default ReportsStore;
