import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { MetaLabelService } from "services/MetaLabelService";
import type { MetaLabels } from "services/MetaLabelService";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import AudioMetadataModel from "../../../../../models/AudioMetadataModel";
import DirectoryInfoModel from "../../../../../models/DirectoryInfoModel";
import { InteractionHierarchyLevels } from "../../../../../models/InteractionHierarchyLevels";
import Organization, {
    IOrganization,
} from "../../../../../models/Organization";
import { OrganizationStructureLevel } from "../../../../../models/OrganizationModels/OrganizationStructureLevel";
import { OrganizationStructureMember } from "../../../../../models/OrganizationModels/OrganizationStructureMember";
import StorageAccount, {
    StorageAccountUseOptions,
} from "../../../../../models/StorageAccount";
import { DirectoryService } from "../../../../../services/DirectoryService";
import { OrganizationService } from "../../../../../services/OrganizationService";
import { SourceFilesService } from "../../../../../services/SourceFilesService";
import { AsyncTaskStatus, BaseStore } from "../../../../../stores/BaseStore";
import { DatePickerComponentStore } from "../../../../../stores/ComponentStores/DatePickerComponentStore";
import { OrgSelectorComponentStore } from "../../../../../stores/ComponentStores/OrgSelectorComponentStore";
import { TableComponentStore } from "../../../../../stores/ComponentStores/TableComponentStore";
import { LoadOragnizationHierarchiesTask } from "../../AudioFileSampler/Stores/AudioFileSamplerStore";
import { LoadMetalabels } from "../../RuleBuilder/RuleBuildStore";
import { StepperStore } from "./StepperStore";
import { AuthStore } from "stores/AuthStore";
export const LoadSourceDirectoriesTask = "Load Source Files";
export const LoadSampleFilesTask = "Load Sample Files";

const rootDirectoryId = "root-directory-id";

@AcxStore
export class SourceFilesStepStore extends BaseStore {
    private readonly directoryService: DirectoryService =
        new DirectoryService();
    private readonly orgService: OrganizationService =
        new OrganizationService();
    private readonly sourceFilesService: SourceFilesService =
        new SourceFilesService();
    private metaLabelService = new MetaLabelService();

    readonly datePickerStore: DatePickerComponentStore =
        new DatePickerComponentStore();
    readonly orgSelectStore: OrgSelectorComponentStore =
        new OrgSelectorComponentStore();
    readonly tableStore: TableComponentStore<DirectoryInfoModel> =
        new TableComponentStore<DirectoryInfoModel>();
    readonly filesDialogtableStore: TableComponentStore<AudioMetadataModel> =
        new TableComponentStore<AudioMetadataModel>(undefined, true);

    messageStore: MessageStore;

    @observable.shallow levels: OrganizationStructureLevel[] = [];
    @observable.shallow members: OrganizationStructureMember[] = [];

    @observable hierarchyMap: Map<string, InteractionHierarchyLevels[]> =
        new Map<string, InteractionHierarchyLevels[]>();
    @observable sourceDirectories: DirectoryInfoModel[] = [];
    @observable currentDirectoryPath: DirectoryInfoModel[] = [];

    @observable showDirectoryHierarchyDialog = false;
    @observable showSampleFilesDialog = false;

    @observable metaLabels?: MetaLabels = undefined;

    @observable.ref storageAccount?: StorageAccount;

    refresh: () => Promise<void | AsyncTaskStatus>;

    @observable private readonly rootDirectory: DirectoryInfoModel =
        new DirectoryInfoModel();

    @observable
    isFromOutsideSampler: boolean = false;

    auth: AuthStore;

    constructor(rootStore: IRootStore) {
        super("SourceFilesStep Store");
        makeObservable(this);
        this.rootDirectory.currDirectoryId = this.rootDirectory.id =
            rootDirectoryId;

        rootStore.getStore(StepperStore).addResetCallback(this.reset, 0);
        this.messageStore = rootStore.getStore(MessageStore);
        this.auth = rootStore.getStore(AuthStore);

        // let's hide the org selector and hard code the org to current tenant
        this.orgSelectStore.setShowOrgSelect(false);
        // this.orgSelectStore.organization =
        //     this.auth.orgStore.selectedOrganization;

        reaction(
            (r) => ({ org: this.orgSelectStore.organization }),
            (arg) => {
                this.tableStore.reset();
                this.hierarchyMap.clear();
                this.sourceDirectories = [];
                this.currentDirectoryPath = [];

                if (arg.org && !this.isFromOutsideSampler) {
                    const org = arg.org;

                    this.messageStore.logMessage(
                        "Loading directories, please wait.",
                        "info",
                    );
                    this.setupAsyncTask(LoadSourceDirectoriesTask, () =>
                        this.listSourceDirectories(org, null),
                    );
                    this.setupAsyncTask(LoadOragnizationHierarchiesTask, () =>
                        this.retrieveHierarchy(),
                    );
                    this.setupAsyncTask(LoadMetalabels, () =>
                        this.loadMetalabels(),
                    );
                }
            },
            { fireImmediately: true, delay: 0 },
        );

        reaction(
            (r) => [...this.currentDirectoryPath],
            (arg) => {
                if (arg.length > 0) {
                    const newItems = arg[arg.length - 1].subDirectories ?? [];

                    this.tableStore.setItems(newItems);
                } else {
                    this.tableStore.setItems([]);
                }
            },
            { fireImmediately: true, delay: 0 },
        );

        reaction(
            (r) => [...this.tableStore.selectedItems],
            (arg) => {
                for (let directoryInfoModel of arg) {
                    if (directoryInfoModel.organizationStructureMemberId) {
                        const orgStructureMemberId =
                            directoryInfoModel.organizationStructureMemberId;
                        if (
                            !this.hierarchyMap[
                                directoryInfoModel.organizationStructureMemberId
                            ]
                        ) {
                            this.setupAsyncTask(`Service Hierarchy`, () =>
                                this.orgService
                                    .getInteractionHierarchyLevels(
                                        orgStructureMemberId,
                                    )
                                    .then((value) => {
                                        runInAction(() => {
                                            this.hierarchyMap.set(
                                                orgStructureMemberId,
                                                value,
                                            );
                                        });
                                    }),
                            );
                        }
                    }
                }
            },
            { fireImmediately: true, delay: 0 },
        );

        reaction(
            (r) => ({
                startDate: this.datePickerStore.beginDate,
                endDate: this.datePickerStore.endDate,
            }),
            (arg) => {
                this.reloadSourceDirectories();
            },
            { delay: 0, fireImmediately: true },
        );
    }

    get selectedDirectories() {
        return this.tableStore.selectedItems;
    }

    @action
    async addHierarchyToDirectories(
        hierarchy: OrganizationStructureMember[],
        directories: DirectoryInfoModel[],
    ) {
        if (!directories) {
            throw new Error(
                "Cannot assign hierarchy to directory without valid Directory Id",
            );
        }

        if (directories.filter((directory) => !directory.id).length > 0) {
            throw new Error(
                "Cannot assign hierarchy to directory without valid Directory Id",
            );
        }

        const directoryIds = directories.map((directory) => directory.id);

        let orgMemberId: string | undefined = undefined;
        if (hierarchy.length > 0) {
            orgMemberId = hierarchy[hierarchy.length - 1].id;
        }

        await this.directoryService.addServiceHierarchies(
            directoryIds,
            orgMemberId,
        );

        if (orgMemberId) {
            const memberId = orgMemberId;
            const hierarchyLevels =
                await this.orgService.getInteractionHierarchyLevels(
                    orgMemberId,
                );

            runInAction(() => {
                this.hierarchyMap.set(memberId, hierarchyLevels);
                directories.forEach(
                    (directory) =>
                        (directory.organizationStructureMemberId = memberId),
                );
            });
        } else {
            runInAction(() => {
                directories.forEach(
                    (directory) =>
                        (directory.organizationStructureMemberId = orgMemberId),
                );
            });
        }
    }

    @action
    private async retrieveHierarchy() {
        const payload = await this.orgService.getInternalStructureHierarchy();

        runInAction(() => {
            this.levels = payload.levels;
            this.members = payload.members;
        });
    }

    @action
    public updateDirectoryPath(directory: DirectoryInfoModel) {
        if (directory.id === rootDirectoryId) {
            this.currentDirectoryPath.splice(
                1,
                this.currentDirectoryPath.length,
            );
        } else {
            const indx = this.currentDirectoryPath.findIndex(
                (value) => value.id === directory.id,
            );
            if (
                indx > -1 &&
                indx < this.currentDirectoryPath.length - 1 &&
                indx + 1 < this.currentDirectoryPath.length
            ) {
                this.currentDirectoryPath.splice(
                    indx + 1,
                    this.currentDirectoryPath.length,
                );
            }
        }
    }

    @action
    public appendDirectoryPath(directory: DirectoryInfoModel) {
        this.currentDirectoryPath.push(directory);
    }

    private holdingStorageAccount(
        org: Organization,
    ): StorageAccount | undefined {
        return org.organizationStorageAccounts?.find(
            (value) =>
                value.storageAccount.storageAccountUse ===
                StorageAccountUseOptions.Holding,
        )?.storageAccount;
    }

    @action
    unselectDirectory = (directory: DirectoryInfoModel) => {
        this.tableStore.setSelectedItems(
            this.tableStore.selectedItems
                .filter((value) => value.id !== directory.id)
                .map((value) => value.id),
        );
    };

    @action
    openDirectoryHierarchyDialog = () => {
        this.showDirectoryHierarchyDialog = true;
    };

    @action
    closeDirectoryHierarchyDialog = () => {
        this.showDirectoryHierarchyDialog = false;
    };

    @action
    openSampleFileDialog = () => {
        this.showSampleFilesDialog = true;
    };
    @action
    closeSampleFileDialog = () => {
        this.showSampleFilesDialog = false;
        setTimeout(() => {
            runInAction(() => {
                this.filesDialogtableStore.reset();
            });
        }, 500);
    };

    @action
    loadFiles(directoryId: string) {
        if (this.orgSelectStore.orgId) {
            this.openSampleFileDialog();
            this.refresh = () =>
                this.setupAsyncTask(LoadSampleFilesTask, async () => {
                    const res =
                        await this.sourceFilesService.getSampleFilesFromDirectory(
                            directoryId,
                            this.datePickerStore.offsetBeginDate.toISOString(),
                            this.datePickerStore.offsetEndDate.toISOString(),
                            200,
                        );
                    runInAction(() => {
                        this.filesDialogtableStore.setItems(res);
                        this.openSampleFileDialog();
                    });
                });
            this.refresh();
        }
    }

    @action
    private async listSourceDirectories(
        org: Organization,
        folderPath: string | null,
    ) {
        const account = this.holdingStorageAccount(org)?.name;
        const res = await this.sourceFilesService.getSourceDirectories(
            folderPath,
            account,
            this.datePickerStore.offsetBeginDate.toISOString(),
            this.datePickerStore.offsetEndDate.toISOString(),
        );
        runInAction(() => {
            this.rootDirectory.currDirectory =
                this.rootDirectory.currDirectorySegment =
                    org.blobContainer + "/";
            this.rootDirectory.subDirectories = res;
            this.sourceDirectories = res;

            this.currentDirectoryPath.splice(0, 1, this.rootDirectory);
        });
    }

    @action
    reset = () => {
        this.tableStore.reset();
        this.hierarchyMap.clear();
        this.filesDialogtableStore.reset();
        this.sourceDirectories = [];
        this.tableStore.setItems([]);
        this.currentDirectoryPath = [];
        this.reloadSourceDirectories();
    };

    // obsolete
    @action
    setOrganization = (org: IOrganization) => {
        this.reset();
        this.orgSelectStore.selectOrg(org as Organization);
    };

    @action
    reloadSourceDirectories = () => {
        if (this.orgSelectStore.organization) {
            const org = this.orgSelectStore.organization;
            const folderPath = null;
            this.setupAsyncTask(LoadSourceDirectoriesTask, () =>
                this.listSourceDirectories(org, folderPath),
            );
        }
    };

    @action
    loadMetalabels = async () => {
        this.metaLabels = await this.metaLabelService.getMetaLabels();
    };
}
