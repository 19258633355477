import { Grid } from "@mui/material";
import { Observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { DropTargetMonitor } from "react-dnd";
import { useStore } from "utils/useStore";
import { RuleBuildStore } from "../../RuleBuildStore";
import { OnDropParams, RuleBuilderItemTypes } from "../MainRuleBuilder";
import AcxClassifierList from "./AcxClassifierList";
import AcxRuleDropTarget from "./AcxRuleDropTarget";

interface OwnProps {
    onDrop: (params: OnDropParams, monitor: DropTargetMonitor) => void;
}

type Props = OwnProps;

const MainContent: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const store = useStore(RuleBuildStore);

    return (
        <Observer>
            {() => (
                <>
                    <Grid item xs={6}>
                        {store.classifiers.length > 0 && (
                            <AcxClassifierList store={store} />
                        )}
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        spacing={2}
                        style={{ zIndex: 0 }}
                        justifyContent="flex-start"
                        alignItems="stretch"
                        item
                        xs={6}
                    >
                        <Grid item>
                            <AcxRuleDropTarget
                                store={store}
                                title={"Filter"}
                                onDrop={props.onDrop}
                                accept={[
                                    RuleBuilderItemTypes.SpeechClassifierRule,
                                    RuleBuilderItemTypes.MetaClassifierRule,
                                ]}
                            />
                        </Grid>
                        <Grid item>
                            <AcxRuleDropTarget
                                store={store}
                                title={"Ranking"}
                                onDrop={props.onDrop}
                                accept={[
                                    RuleBuilderItemTypes.SpeechClassifierRule,
                                    RuleBuilderItemTypes.MetaClassifierRule,
                                ]}
                            />
                        </Grid>
                        <Grid item>
                            <AcxRuleDropTarget
                                store={store}
                                title={"Stratify"}
                                onDrop={props.onDrop}
                                accept={[
                                    RuleBuilderItemTypes.MetaClassifierRule,
                                ]}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Observer>
    );
};

export default MainContent;
