import { Box, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AcxChip from "components/UI/AcxChip";
import { observer } from "mobx-react";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    marginBottom: { marginBottom: theme.spacing(1) },
    description: {
        fontWeight: 500,
        fontSize: "13px",
    },
}));

type TopicProps = {
    name: string;
    description?: string | null;
};

const Topic: React.FC<TopicProps> = observer(({ name, description }) => {
    const classes = useStyles();

    return (
        <Box className={classes.marginBottom}>
            <AcxChip size="small" label={name} color="white" />
            {description && (
                <Typography className={classes.description}>
                    {description}
                </Typography>
            )}
        </Box>
    );
});

export default Topic;
