import { Divider, Theme, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GridRowModel } from "@mui/x-data-grid-pro";
import { observer } from "mobx-react";
import React from "react";
import { stringReplacePluralizer } from "../../../utils/StringPluralizer";
import AcxMainExpansion from "../AcxMainExpansion";
import AcxDataGridStore from "./AcxDataGridStore";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },
    expanionHeader: {
        userSelect: "none",
        height: "56px",
    },
    expansionRoot: {
        // boxShadow:theme.shadows[1]
        //  "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)"
        boxShadow:
            "0px 2px 2px -1px rgba(0,0,0,0.09), 0 1px 1px 0 rgba(0,0,0,0.07), 0px 1px 2px 0px rgba(0,0,0,0.05)",
    },
}));

const ContextRow = (
    index: number,
    renderer: ((row: GridRowModel) => React.ReactNode) | undefined,
    value,
): JSX.Element => {
    return (
        <React.Fragment key={`table-context-divider-${index}`}>
            <Divider variant={"fullWidth"} />
            <div style={{ padding: "8px" }}>{renderer?.(value)}</div>
        </React.Fragment>
    );
};

interface IGridSelection {
    store: AcxDataGridStore;
}

const AcxGridSelection = observer((props: IGridSelection) => {
    const classes = useStyles(props);
    const theme = useTheme();

    const body = (
        <>
            {props.store.selectedRows?.map((value, index) => {
                return ContextRow(
                    index,
                    props.store.selectionContextOptions?.renderSelectedRow,
                    value,
                );
            })}
        </>
    );

    const count = props.store.selectedRows.length;

    return (
        <AcxMainExpansion
            expandable={count > 0}
            rootClass={classes.expansionRoot}
            headerClass={classes.expanionHeader}
            header={
                <div>
                    <Typography className={classes.title}>
                        {count} Selected{" "}
                        {stringReplacePluralizer(
                            props.store.selectionContextOptions?.itemName ??
                                "Item",
                            count,
                            props.store.selectionContextOptions
                                ?.itemNamePluralized ?? "Items",
                        )}
                    </Typography>
                </div>
            }
            body={body}
            headerStyleOverRide={{
                0: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },
                1: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },
            }}
            bodyStyleOverRide={{
                0: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },
                1: {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.common.white,
                },
            }}
            chevronStyleOverRide={{
                0: {
                    color: theme.palette.secondary.main,
                },
                1: {
                    color: theme.palette.secondary.main,
                },
            }}
            headerMenu={(menuAnchor) =>
                props.store.selectionContextOptions?.action
            }
            alwaysShowHeaderMenu={Boolean(
                props.store.selectionContextOptions?.action,
            )}
        />
    );
});

export default AcxGridSelection;
