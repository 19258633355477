import { IChatRecord } from "../components/UI/Chat/AcxChatViewer";
import BaseService from "./BaseService";

export class ChatMediaService extends BaseService {
    protected urlBase: string = "api/ChatMedia";

    public async getChatMediaFile(
        amdId: string | undefined,
    ): Promise<IChatRecord[]> {
        const res = await this.get<IChatRecord[]>(
            new URLSearchParams({
                amdId: amdId as string,
            }),
            "ChatTranscriptionTextByAudioMetadata",
        );

        return res;
    }
}
