import { Divider } from "@mui/material";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { NotificationArgument } from "../../../stores/RealTimeMessages/MessageStoreBase";
import NotificationItem from "./NotificationItem";

interface OwnProps {
    notifications: NotificationArgument[];
    onDismiss?: (arg: NotificationArgument) => void;
}

type Props = OwnProps;

const NotificationList: FunctionComponent<Props> = observer((props) => {
    return (
        <>
            {props.notifications.map((value) => {
                return (
                    <React.Fragment
                        key={
                            (value.id ?? `${value.title + value.message}`) +
                            value.timestamp
                        }
                    >
                        <NotificationItem
                            notification={value}
                            onDismiss={props.onDismiss}
                        />
                        <Divider variant={"fullWidth"} />
                    </React.Fragment>
                );
            })}
        </>
    );
});

export default NotificationList;
