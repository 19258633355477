import React, { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { durationDisplay } from "../../../../SoundClipEditor/Controls/Player";
import { useStyles, useOverrideStyles } from "./styles";
import Stack from "@mui/material/Stack";
import { Observer, observer } from "mobx-react";
import EditIcon from "@mui/icons-material/Edit";
import clsx from "clsx";
import { SpeakerPopover } from "./SpeakerPopover";
import { Blurb } from "./Blurb";
import { Speaker } from "../../Stores/SpeakerStore/constants";
import { SpeakerStore } from "../../Stores/SpeakerStore/SpeakerStore";
import { getSpeakerColorByChannelOrPersona } from "Theme/utils";

interface BlurbBoxNameDropdownProps {
    blurb: Blurb;
    speakerStore?: SpeakerStore;
}

export const BlurbBoxSpeakerTitle: React.FC<BlurbBoxNameDropdownProps> =
    observer(({ blurb, speakerStore }) => {
        const disabled = !speakerStore?.amdId;
        const classes = useStyles({
            blurb,
            speakerNameDisabled: disabled,
        });
        const theme = useTheme();
        // overrides classes in useStyles
        const highlightClasses = useOverrideStyles({
            speakerNameDisabled: disabled,
        });

        const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
        const [isEditing, setisEditing] = useState(false);

        return (
            <Stack direction={"column"} className={classes.title}>
                <span
                    onClick={(e) => {
                        if (!disabled) {
                            setisEditing(true);
                            setAnchorEl(e.currentTarget);
                        }
                    }}
                >
                    <Typography
                        className={clsx(
                            classes.channel,
                            isEditing && highlightClasses.selected,
                        )}
                        sx={{
                            alignItems: "center",
                            fontWeight: "bold",
                            color: getSpeakerColorByChannelOrPersona(
                                blurb.channel,
                            ),
                            padding: theme.spacing(0.25),
                            paddingRight: "4px",
                            paddingLeft: "4px",
                            // "&:hover": blurb.channel
                            //     ? {}
                            //     : {
                            //           border: `2px ${theme.palette.gray[200]} solid`,
                            //           paddingLeft: "2px",
                            //           cursor: "default",
                            //           borderRadius: theme.shape.borderRadius,
                            //           backgroundColor: theme.palette.white.main,
                            //       },
                            // "& .hidden-edit-icon": {
                            //     visibility: "hidden",
                            // },
                            // "&:hover .hidden-edit-icon": {
                            //     visibility: props.speakerNameDisabled
                            //         ? "hidden"
                            //         : "visible",
                            // },
                        }}
                        component="span"
                    >
                        <Observer>
                            {() => (
                                <>
                                    {speakerStore?.speakerChannelMap.get(
                                        blurb.channel,
                                    )?.name ??
                                        speakerStore?.predictedSpeakerChannelMap.get(
                                            blurb.channel,
                                        ) ??
                                        `Speaker ${blurb.channel + 1}`}
                                </>
                            )}
                        </Observer>
                        <EditIcon
                            className="hidden-edit-icon"
                            fontSize="inherit"
                        />
                    </Typography>
                </span>
                <SpeakerPopover
                    open={isEditing && !disabled}
                    anchorEl={anchorEl}
                    blurb={blurb}
                    onClose={() => {
                        setisEditing(false);
                        setAnchorEl(null);
                    }}
                    onSetSpeaker={(speaker: Speaker) => {
                        speakerStore?.setSpeakerNameByChannel(
                            blurb.channel,
                            speaker,
                        );
                        setisEditing(false);
                        setAnchorEl(null);
                    }}
                />

                <Typography
                    sx={{
                        color: theme.palette.gray[400],
                        padding: theme.spacing(0.25),
                        paddingLeft: "4px",
                    }}
                >
                    {durationDisplay(blurb.timeInterval[0])}
                </Typography>
            </Stack>
        );
    });
