import { Box, Grid, Input, Popover } from "@mui/material";
import { every } from "lodash";
import { HexColorPicker } from "react-colorful";
import React, { useEffect, useState } from "react";
import theme from "Theme/AppTheme";
import { Colord } from "colord";
import { InputLabel } from "@mui/material";
interface AcxColorPickerProps {
    open: boolean;
    defaultColor?: string | Colord;
    anchorEl: HTMLElement | null;
    onColorChange?: (color: Colord) => void;
    onClose?: () => void;
}

export const AcxColorPicker: React.FC<AcxColorPickerProps> = ({
    open,
    onColorChange,
    anchorEl,
    defaultColor,
    onClose,
}) => {
    const [color, setColor] = useState<Colord>(
        defaultColor instanceof Colord
            ? defaultColor
            : new Colord(defaultColor ?? theme.palette.primary.main),
    );
    const [hexInput, setHexInput] = useState(color.toHex().slice(1));

    const onHexInputChange = (e) => {
        const allowedChars = "1234567890abcdef";
        if (
            every(e.target.value.split(""), (char) =>
                allowedChars.includes(char.toLowerCase()),
            ) &&
            e.target.value.length <= 6
        ) {
            setHexInput(e.target.value);
            if (e.target.value.length === 6) {
                setColor(new Colord("#" + e.target.value));
            }
        }
    };

    useEffect(() => {
        setHexInput(color.toHex().slice(1));
    }, [color]);
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: -8,
                horizontal: "center",
            }}
            onClose={() => {
                onClose?.();
            }}
        >
            <Grid
                container
                style={{
                    width: "340px",
                    height: "241px",
                    padding: theme.spacing(1),
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Grid item xs={12} container justifyContent="center">
                    <HexColorPicker
                        style={{ width: "304px", height: "112px" }}
                        onChange={(hex) => {
                            onColorChange?.(new Colord(hex));
                            setColor(new Colord(hex));
                        }}
                        color={color.toHex()}
                    />
                </Grid>
                <Grid item container xs={12} justifyContent="space-between">
                    <Grid item xs={2}>
                        <Box
                            borderRadius="4px"
                            border="black 1px solid"
                            style={{ backgroundColor: color.toHex() }}
                            width="48px"
                            height="48px"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel htmlFor="hex-input">Hex</InputLabel>
                        <Input
                            id="hex-input"
                            value={hexInput.toLocaleUpperCase()}
                            startAdornment="#"
                            onChange={onHexInputChange}
                        />
                    </Grid>
                    <Grid item container justifyContent="space-between" xs={5}>
                        <Grid item xs={4}>
                            <InputLabel htmlFor="hex-input">R</InputLabel>
                            <Input
                                type="number"
                                value={color.toRgb().r}
                                onChange={(e) => {
                                    const num = Number(e.target.value);
                                    if (num <= 255 && num >= 0) {
                                        setColor(
                                            new Colord(
                                                `rgb(${num},${
                                                    color.toRgb().g
                                                },${color.toRgb().b})`,
                                            ),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel htmlFor="hex-input">G</InputLabel>
                            <Input
                                type="number"
                                value={color.toRgb().g}
                                onChange={(e) => {
                                    const num = Number(e.target.value);
                                    if (num <= 255 && num >= 0) {
                                        setColor(
                                            new Colord(
                                                `rgb(${
                                                    color.toRgb().r
                                                },${num},${color.toRgb().b})`,
                                            ),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel htmlFor="hex-input">B</InputLabel>
                            <Input
                                type="number"
                                value={color.toRgb().b}
                                onChange={(e) => {
                                    const num = Number(e.target.value);
                                    if (num <= 255 && num >= 0) {
                                        setColor(
                                            new Colord(
                                                `rgb(${color.toRgb().r},${
                                                    color.toRgb().g
                                                },${num})`,
                                            ),
                                        );
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Popover>
    );
};
