import React, { CSSProperties } from "react";
import AcxSelect from "./AcxSelect";

export interface IAcxSelectMulti<S> {
    options: S[];
    id: string;
    onChange?: (arg: S[] | undefined) => void;
    placeholder?: string;
    defaultValue?: S[];
    menuPortalTarget?: HTMLElement | null | undefined;
    valueField: string;
    labelField: string;
    colorField?: string;
    inputLabel?: string;
    enableNoSelection?: boolean;
    editIcon?: boolean;
    onEditClick?: () => void;
    isClearable?: boolean;
    isLoading?: boolean;
    shrink?: boolean;
    infoText?: string;
    fullWidth?: boolean;
    width?: string;
    containerHeight?: string | "auto";
    maxContainerHeight?: string;
    alignControls?: CSSProperties["alignItems"];
    isDisabled?: boolean;
    menuPlacement?: "top" | "bottom" | "auto";
    customStyle?: Record<string, (provided, state) => CSSProperties>;
    customFilter?: (option: any, rawInput: string) => boolean;
    groupCollapse?: boolean;
    skeleton?: boolean;
    error?: boolean;
    helperText?: string;
    showAllErrors?: boolean;
    required?: boolean;
    tooltips?: { [key: string]: string };
    alternateItemColor?: string;
}

export default function AcxSelectMulti<S>(props: IAcxSelectMulti<S>) {
    const onChange = (value: S[] | undefined) => {
        if (value) {
            if (props.onChange) {
                props.onChange(value as S[]);
            }
        } else {
            if (props.onChange) {
                props.onChange(undefined);
            }
        }
    };

    return (
        <AcxSelect
            id={props.id}
            containerHeight={props.containerHeight}
            maxContainerHeight={props.maxContainerHeight}
            width={props.width}
            placeholder={props.placeholder}
            onMultiChange={onChange}
            options={props.options}
            colorField={props.colorField}
            fullWidth={props.fullWidth}
            isMulti={true}
            defaultValue={props.defaultValue}
            valueField={props.valueField}
            labelField={props.labelField}
            inputLabel={props.inputLabel ? props.inputLabel : undefined}
            enableNoSelection={props.enableNoSelection}
            onEditClick={props.onEditClick}
            editIcon={props.editIcon}
            isClearable={props.isClearable}
            isLoading={props.isLoading}
            menuPortalTarget={props.menuPortalTarget}
            shrink={props.shrink}
            infoText={props.infoText}
            isDisabled={props.isDisabled}
            menuPlacement={props.menuPlacement}
            customStyle={props.customStyle}
            customFilter={props.customFilter}
            groupCollapse={props.groupCollapse}
            skeleton={props.skeleton}
            error={props.error}
            helperText={props.helperText}
            showAllErrors={props.showAllErrors}
            required={props.required}
            tooltips={props.tooltips}
            alternateItemColor={props.alternateItemColor}
        />
    );
}
