import moment from "moment";
import {
    AnalystEvalHistory,
    EvaluationLeaderStats,
} from "../models/EvalMetricModels";
import { TimePeriod } from "../models/TimePeriod";
import { serializeToUtc } from "../utils/DateTimeUtils";
import { isUndefinedType } from "../utils/TypeGuards";
import BaseService from "./BaseService";

export class EvaluationMetricsService extends BaseService {
    protected urlBase: string = "api/EvaluationMetrics";

    public async getAnalystHistory(
        analystId: string,
        timePeriod: TimePeriod | undefined,
        beginDate?: moment.Moment | undefined,
        endDate?: moment.Moment | undefined,
        debounceKey?: string,
    ) {
        const urlSearchParams = new URLSearchParams({ analystId });
        if (!isUndefinedType(timePeriod)) {
            urlSearchParams.set("timePeriod", `${timePeriod}`);
        }
        if (!isUndefinedType(beginDate)) {
            urlSearchParams.set("start", serializeToUtc(beginDate)!);
        }
        if (!isUndefinedType(endDate)) {
            urlSearchParams.set("end", serializeToUtc(endDate)!);
        }

        return await this.get<AnalystEvalHistory>(
            urlSearchParams,
            "AnalystHistory",
            undefined,
            debounceKey,
        );
    }

    public async getAnalystLeaderStats(
        timePeriod?: TimePeriod,
        debounceKey?: string,
    ) {
        const urlSearchParams = new URLSearchParams({});
        if (!isUndefinedType(timePeriod)) {
            urlSearchParams.set("timePeriod", `${timePeriod}`);
        }
        return await this.get<EvaluationLeaderStats[]>(
            urlSearchParams,
            "AnalystLeaders",
            undefined,
            debounceKey,
        );
    }
}
