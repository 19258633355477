import { DeleteForeverRounded } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@mui/icons-material/GetApp";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import SoundClipEditor from "components/SoundClipEditor/SoundClipEditor";
import AcxButton from "components/UI/AcxButton";
import { AcxConfirmationDialogWrapper } from "components/UI/AcxConfirmationDialog";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { dateTimeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { observer, Observer } from "mobx-react";
import { Moment } from "moment";
import React, { useEffect } from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { ClipUIModel } from "../../../models/SoundClip";
import AcxDateRangeInput from "../../UI/Calendar/DateRange/AcxDateRangeInput";
import { MontageListStore } from "../Stores/MontageListStore";

const useStyles = makeStyles((theme: Theme) => ({
    viewFilesButton: {
        textTransform: "none",
        fontFamily: theme.typography.fontFamily,
    },
    gridContainer: {
        height: "100%",
        paddingBottom: "5rem",
        overflow: "hidden",
    },
}));

type Props = {
    orgId: string;
};

const MontageList: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ orgId }) => {
    const authStore = useStore(AuthStore);
    const listStore = useStore(MontageListStore);
    const classes = useStyles();

    useEffect(() => {
        const columns: IColDef[] = [
            {
                headerName: "Id",
                hide: true,
                field: "id",
            },
            {
                headerName: "Name",
                field: "name",
                flex: 1,
            },
            {
                headerName: "Owner",
                field: "createdBy",
                flex: 1,
            },
            {
                headerName: "Created Date",
                field: "createdOn",
                flex: 1,
                ...dateTimeColumnType,
            },
            {
                headerName: "File Path",
                field: "fileName",
                flex: 1,
            },
        ];

        listStore.dgStore.setColumns(columns);
        listStore.dgStore.controlsColumnSpan = 12;
        listStore.dgStore.controlsColumnStyle = {
            justifyContent: "flex-end",
            marginTop: "0.5rem",
            marginLeft: "0px",
        };

        listStore.dgStore.controls = [
            <Observer>
                {() => (
                    <div>
                        <AcxDateRangeInput
                            labelText={"Select Created On Date Range"}
                            defaultStartDate={
                                listStore.datePickerStore.beginDate
                            }
                            defaultEndDate={listStore.datePickerStore.endDate}
                            onSelect={(start: Moment, end: Moment) => {
                                listStore.datePickerStore.setBeginDate(start);
                                listStore.datePickerStore.setEndDate(end);
                            }}
                        />
                    </div>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            fullWidth
                            disabled={
                                (!authStore.isUserUltra() &&
                                    authStore.canUserView("Restrict Media")) ||
                                !listStore.singleClickEnabled
                            }
                            color="primary"
                            style={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<PlayCircleOutlineIcon />}
                            onClick={listStore.handlePlayerClick}
                        >
                            Listen
                        </AcxButton>
                    </div>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            fullWidth
                            disabled={
                                !authStore.canUserEdit("Montage Library") ||
                                !listStore.singleClickEnabled
                            }
                            color="primary"
                            style={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<EditIcon />}
                            onClick={listStore.editMontage}
                        >
                            Edit
                        </AcxButton>
                    </div>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            fullWidth
                            disabled={
                                !listStore.multiClickEnabled ||
                                (!authStore.isUserUltra() &&
                                    authStore.canUserView("Restrict Downloads"))
                            }
                            color="primary"
                            style={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<GetAppIcon />}
                            onClick={listStore.batchDownload}
                        >
                            Download
                        </AcxButton>
                    </div>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            fullWidth
                            disabled={
                                !authStore.canUserEdit("Montage Library") ||
                                !listStore.multiClickEnabled
                            }
                            color="primary"
                            style={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<DeleteForeverRounded />}
                            onClick={listStore.deleteMontage}
                        >
                            Delete
                        </AcxButton>
                    </div>
                )}
            </Observer>,
        ];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        listStore.datePickerStore.beginDate,
        listStore.datePickerStore.endDate,
        listStore.searchString,
        orgId,
    ]);

    return listStore.orgId ? (
        <>
            <div className={classes.gridContainer}>
                <AcxDataGrid dataGridStore={listStore.dgStore} />
            </div>

            <AcxConfirmationDialogWrapper
                buttonsDisabled={
                    !authStore.isUserUltra() &&
                    authStore.canUserView("Restrict Downloads")
                }
                dialogStore={listStore.dialogStore}
                title="Montage"
                maxWidth="xl"
                confirmButtonText="Download File"
                onConfirm={listStore.download}
                content={
                    listStore.currentSoundClip && (
                        <SoundClipEditor
                            id="montage-listen-download"
                            noMenu={true}
                            alternateClipDownloader={() =>
                                listStore.fetchCurrentMontageAudio(
                                    listStore.currentSoundClip.id,
                                )
                            }
                            clip={listStore.currentSoundClip as ClipUIModel}
                        />
                    )
                }
            />
        </>
    ) : null;
});

export default MontageList;
