import type { MappedCustomerType } from "models/CustomerType";
import BaseService from "services/BaseService";

class CustomerTypesService extends BaseService {
    protected urlBase: string = "api/CustomerTypes";

    public async getPermissions(): Promise<any[]> {
        return this.get(new URLSearchParams({}), "GetAllPermissions");
    }

    public async getCustomerTypes(organizationId: string): Promise<any[]> {
        return this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "GetCustomerTypesForOrganization",
        );
    }

    public async getMappedCustomerTypes(): Promise<MappedCustomerType[]> {
        return this.get(
            new URLSearchParams(),
            "GetMappedCustomerTypesForOrganization",
        );
    }

    public async createCustomerType(val: any): Promise<any> {
        const params = new URLSearchParams();
        return this.post(JSON.stringify(val), params, "CreateCustomerType", {
            returnJson: true,
        });
    }

    public async updateCustomerType(val: any): Promise<any> {
        const params = new URLSearchParams({ id: val.id });
        return this.put(params, "UpdateCustomerType", JSON.stringify(val), {
            returnJson: true,
        });
    }

    async saveRole(val: any): Promise<any> {
        return val.id
            ? this.updateCustomerType(val)
            : this.createCustomerType(val);
    }

    public async deleteCustomerTypes(customerTypeIds) {
        const req = await this.delete(
            new URLSearchParams(),
            "DeleteCustomerTypes",
            JSON.stringify(customerTypeIds),
        );
        return req;
    }
}

export default CustomerTypesService;
