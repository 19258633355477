export enum EvaluationType {
    NotEvaluated,
    AIEvaluated,
    HumanEvaluated,
}

export namespace EvaluationType {
    export function toString(evaluationType: EvaluationType): string {
        switch (evaluationType) {
            case EvaluationType.NotEvaluated:
                return "No Evaluation";
            case EvaluationType.AIEvaluated:
                return "AI Evaluated";
            case EvaluationType.HumanEvaluated:
                return "Human Evaluated";
        }
    }
}
