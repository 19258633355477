import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Grid, Theme, Tooltip, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import {
    BranchDataProps,
    HandleBranchProps,
} from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { OrganizationUserStore } from "../../stores/OrganizationUserStore";
import HierarchyAssignmentToggles from "./HierarchyAssignmentToggles";
import {
    HierarchyPermissions,
    HierarchyTypes,
    IHierarchyAssignmentTier,
} from "./Types";
import { permissionToggleEnabled, typeToggleEnabled } from "./Utilities";

const styles = (theme: Theme) =>
    createStyles({
        tierTitle: {
            fontWeight: "bold",
        },
        icon: {
            fontSize: "1.1rem",
            cursor: "pointer",
            margin: "0 0.5rem",
            "&:hover": {
                color: theme.palette.blue.light,
            },
        },
    });

interface IHierarchyAssignmentTierProps {
    branch: IHierarchyAssignmentTier;
    handleTier: HandleBranchProps;
    store: OrganizationUserStore;
    authStore: AuthStore;
}

const HierarchyAssignmentTier: React.FC<IHierarchyAssignmentTierProps> =
    observer(({ branch, handleTier, store, authStore }) => {
        const classes = useStyles(styles);
        const hasAdminPerms = authStore.canUserEdit(
            "Assign Organization Structure Members To Users",
        );

        const onChangeHandler = (event, checked) => {
            store.setActiveTier(branch);

            if (store.activeTier) {
                const value: string = event.target.value;

                if (value) {
                    // "master" is the Toggle All switch
                    if (value === "master") {
                        store.activeTier.types = [];
                        store.activeTier.permissions = [];

                        if (checked) {
                            const typesArr: number[] = [];
                            const permsArr: number[] = [];

                            for (
                                let i = 0;
                                i < store.hierarchyTypes.length;
                                i++
                            ) {
                                typesArr.push(i);
                            }

                            for (
                                let i = 0;
                                i < store.hierarchyPermissions.length;
                                i++
                            ) {
                                permsArr.push(i);
                            }

                            store.setTierPerms(permsArr, typesArr);
                        }
                    } else if (value in HierarchyPermissions) {
                        if (checked) {
                            store.setTierPerms(value);
                        } else {
                            store.setTierPerms(
                                store.activeTier.permissions?.filter(
                                    (perm) =>
                                        perm !== HierarchyPermissions[value],
                                ),
                            );

                            if (value === "View") {
                                // Clear domain access
                                store.setTierPerms(undefined, []);

                                // Remove Edit & Manage rights
                                store.setTierPerms(
                                    store.activeTier.permissions?.filter(
                                        (perm) =>
                                            perm !==
                                                HierarchyPermissions["Edit"] &&
                                            perm !==
                                                HierarchyPermissions["Manage"],
                                    ),
                                );
                            }

                            if (
                                value === "Edit" &&
                                store.activeTier.permissions &&
                                store.activeTier.permissions.includes(
                                    HierarchyPermissions["Manage"],
                                )
                            ) {
                                // Remove Manage rights
                                store.setTierPerms(
                                    store.activeTier.permissions?.filter(
                                        (perm) =>
                                            perm !==
                                            HierarchyPermissions["Manage"],
                                    ),
                                );
                            }
                        }
                    } else if (value in HierarchyTypes) {
                        if (checked) {
                            store.setTierPerms(undefined, value);
                        } else {
                            store.setTierPerms(
                                undefined,
                                store.activeTier.types?.filter(
                                    (type) => type !== HierarchyTypes[value],
                                ),
                            );
                        }
                    }

                    // Update Branch
                    handleTier(store.activeTier as BranchDataProps, "Update");

                    store.messageStore.logMessage(
                        "Successfully updated Hierarchy Assignments.",
                        "success",
                    );
                }
            }
        };

        const handleDialogOpen = () => {
            store.setActiveTier(branch);
            store.setPropagateDialogIsOpen(true);
        };

        return (
            <Grid
                container
                item
                xs={12}
                style={{
                    backgroundColor: branch.isActive ? "" : "#CCCCCC",
                    borderRadius: "6px",
                    padding: "0.25rem",
                }}
            >
                <Grid container item xs={3} alignContent="center">
                    <Grid container item>
                        <Typography color="secondary" variant="caption">
                            {store.orgServiceSetup?.[branch.branchDepth ?? 0] ??
                                ""}
                        </Typography>
                    </Grid>

                    <Grid container item>
                        <Typography
                            color="initial"
                            component="div"
                            className={classes.tierTitle}
                        >
                            {branch.name}
                        </Typography>

                        <Tooltip title="Propagate Assignments">
                            <ArrowDownwardIcon
                                color="secondary"
                                onClick={handleDialogOpen}
                                className={classes.icon}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>

                <HierarchyAssignmentToggles
                    permissionValidator={permissionToggleEnabled}
                    typeValidator={typeToggleEnabled}
                    onChangeHandler={onChangeHandler}
                    branch={branch}
                    store={store}
                    hasAdminPerms={hasAdminPerms}
                />
            </Grid>
        );
    });

export default HierarchyAssignmentTier;
