import React from "react";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import { Typography } from "@mui/material";
import { observer } from "mobx-react";

interface IAssignAnalystDialog {
    onSubmitHandler?: () => Promise<void>;
    isOpen?: boolean;
    onClose: () => void;
    isLoading?: boolean;
}

const UnAssignAnalystDialog = observer((props: IAssignAnalystDialog) => {
    const onSubmit = () => {
        props.onSubmitHandler?.()?.then(() => props.onClose());
    };

    const content = (
        <Typography key="content" variant="h3">
            Are you sure that you would like to unassign this analyst?
        </Typography>
    );

    return (
        <>
            <AcxConfirmationDialog
                isOpen={props.isOpen}
                onClose={props.onClose}
                title="Confirm Unassign"
                content={content}
                confirmButtonText="Confirm"
                cancelButtonText="Cancel"
                isLoading={props.isLoading}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={12}
                onConfirm={onSubmit}
            />
        </>
    );
});

export default UnAssignAnalystDialog;
