import { Button, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { observer } from "mobx-react";
import React from "react";
import { ReportAccessType } from "../../../../../models/Reporting/ReportAccessControls";
import { useStore } from "../../../../../utils/useStore";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },

    aclButtons: {
        textTransform: "none",
        fontWeight: "bold",
    },
    aclInfoText: {
        fontWeight: "bold",
        color: theme.palette.text.secondary,
        paddingTop: "9px",
    },
    mainSectionTitle: {
        marginTop: theme.spacing(1.5),
    },
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    mainSectionSubtitleText: {
        fontFamily: "Inter",
        opacity: "0.5",
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
        maxWidth: "300px",
    },
    showMoreText: {
        color: "black",
    },
}));

interface OwnProps {}

type Props = OwnProps;

const ReportPermissionSection: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);

    function getReportAccess(accessType: ReportAccessType) {
        return store.displayUsers(accessType, classes);
    }
    return (
        <>
            <Grid item xs={12} style={{ paddingBottom: "17px" }}>
                <Typography className={classes.mainSectionTitleText}>
                    Permissions
                </Typography>
                <Typography variant="caption">
                    By default, anyone in your org will be able to view and edit
                    reports unless you specify otherwise.
                </Typography>
            </Grid>

            <Grid item xs={12} className={classes.vertSpace}>
                <Typography className={classes.sectionTitle}>
                    Access Control Lists
                </Typography>

                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            disabled={!store.currentReport}
                            className={classes.aclButtons}
                            onClick={() =>
                                store.onOpenAccessControls(
                                    ReportAccessType.View,
                                )
                            }
                            size="medium"
                            startIcon={<VisibilityIcon />}
                            variant={"outlined"}
                            color="secondary"
                        >
                            Choose Viewers...
                        </Button>
                    </Grid>
                    <Grid item>
                        {store.currentReport &&
                            ((store.currentReport?.anyViewACLs ? (
                                getReportAccess(ReportAccessType.View)
                            ) : (
                                <Typography className={classes.aclInfoText}>
                                    {" "}
                                    -
                                    {
                                        "The entire organization will be able to view this report"
                                    }
                                </Typography>
                            )) as React.ReactNode)}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            disabled={!store.currentReport}
                            className={classes.aclButtons}
                            startIcon={<EditIcon />}
                            onClick={() =>
                                store.onOpenAccessControls(
                                    ReportAccessType.Edit,
                                )
                            }
                            size="medium"
                            variant={"outlined"}
                            color="secondary"
                        >
                            Choose Editors....
                        </Button>
                    </Grid>
                    <Grid item>
                        {store.currentReport &&
                            ((store.currentReport?.anyEditACLs ? (
                                getReportAccess(ReportAccessType.Edit)
                            ) : (
                                <Typography className={classes.aclInfoText}>
                                    {" "}
                                    -
                                    {
                                        "The entire organization will be able to edit this report"
                                    }
                                </Typography>
                            )) as React.ReactNode)}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});

export default ReportPermissionSection;
