import { makeObservable, observable } from "mobx";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { BaseStore } from "../../../stores/BaseStore";
import SignalsReportStore from "./SignalsReportStore";
import { AuthStore } from "stores/AuthStore";
import * as SignalsModels from "../Models/SignalsModels";
import SignalsStore from "./SignalsStore";

@AcxStore
class SignalsReportSelectorStore extends BaseStore {
    readonly signalsReportStore: SignalsReportStore;
    readonly authStore: AuthStore;
    readonly signalsStore: SignalsStore;

    @observable
    showReportSelector: boolean = false;

    @observable
    currentItems: SignalsModels.ISignalsVizDefinition[] = [];

    @observable
    selectedVizDefs: SignalsModels.ISignalsVizDefinition[] = [];

    @observable
    selectedItemGroup: string = "Eddy Reports";

    @observable
    savePDFName: string = "";

    public constructor(rootStore: IRootStore) {
        super("Signals Store");
        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);

        this.signalsReportStore = rootStore.getStore(SignalsReportStore);
        this.signalsStore = rootStore.getStore(SignalsStore);

        this.currentItems = this.signalsReportStore.allChartData.filter(
            (c) => c.isEddyReport,
        );
    }
}

export default SignalsReportSelectorStore;
