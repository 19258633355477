import {
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    Theme,
    Tooltip,
    Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import React from "react";
import { Stack } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import ClassifierCheatsheet from "components/Classifiers/ClassifierCheatsheet";
import {
    ClassifierBuilderV2Store,
    ClassifierBuilderView,
} from "../../Stores/ClassifierBuilderV2Store";
import { useStore } from "utils/useStore";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { useNavigate } from "react-router";
import StandardClassifierBuilder from "./StandardView/StandardClassifierBuilder";
import AcxToast from "components/UI/AcxToast";
import InfoIcon from "@mui/icons-material/Info";
import theme from "Theme/AppTheme";
import { ClassifierBuilderV2SwitchViewDialog } from "../Dialogs/ClassifierBuilderV2SwitchViewDialog";
import { basicDateReferenceOptions } from "../../../../../stores/ComponentStores/DatePickerComponentStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        padding: theme.spacing(1.8, 2.3),
    },
    paperContainer: {
        [theme.breakpoints.up(2560)]: {
            paddingBottom: theme.spacing(4),
        },
    },
    header: {
        fontSize: theme.typography.h2.fontSize,
        fontWeight: 600,
    },
    headerContainer: {
        paddingTop: theme.spacing(2.3),
        paddingBottom: theme.spacing(1.8),
        paddingLeft: theme.spacing(1.8),
        paddingRight: theme.spacing(1.8),
    },
    testControls: {
        marginTop: theme.spacing(4),
        "& > *": {
            marginRight: theme.spacing(4), // Adjust right margin for all children
        },
    },
    testButton: {
        marginLeft: theme.spacing(1),
        marginTop: "5px",
    },
    addButton: {
        color: theme.palette.secondary.main,
        "&:hover": {
            color: "white",
        },
    },
    textField: {
        width: "70%",
        height: theme.spacing(38),
    },
    hasErrorTextField: {
        width: "70%",
        height: theme.spacing(25),
    },
    icon: {
        height: "16px",
        width: "16px",
        marginLeft: theme.spacing(0.5),
    },
    tooltipIcon: {
        height: "16px",
        width: "16px",
        marginRight: theme.spacing(0.5),
    },
}));

const ClassifierBuilderRuleset = observer(() => {
    const classes = useStyles();
    const store = useStore(ClassifierBuilderV2Store);
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    const navigate = useNavigate();

    const validateAndTestRuleSet = async () => {
        try {
            store.setTestClassifierError("");
            store.setResultsLoading(true);
            store.setValidationLoading(true);

            if (store.view === ClassifierBuilderView.Advanced) {
                await store.validateRbcFtsSearchString();
            } else {
                // Set true for standard view since we don't need to validate the search str
                store.setIsRbcFtsStringValid(true);
            }

            runInAction(async () => {
                if (store.isRbcFtsStringValid && !store.testClassifierError)
                    await store.testClassifier();

                if (!store.testClassifierError) {
                    navigate("../results");
                }
                store.setValidationLoading(false);
                store.getHighlights();
            });
        } catch (error) {
            throw error;
        } finally {
            store.setResultsLoading(false);
        }
    };

    React.useEffect(() => {
        store.setTestClassifierError("");
        store.setPageNumberOnly(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const standardBuilderTooltip = (
        <>
            <Stack direction="row" alignItems="center">
                <InfoIcon className={classes.tooltipIcon} />
                <Box fontWeight="bold">Building a Classifier</Box>
            </Stack>
            <ol style={{ paddingLeft: theme.spacing(1) }}>
                <li>
                    Select <b>'+ New Group'</b> to begin building your
                    classifier. All rules within a group will be combined (think
                    of it as combining rules within parentheses).
                </li>
                <li>
                    Select <b>'+ Rule'</b> to add a rule to determine what
                    results should be returned.
                </li>
                <li>
                    A rule selector will appear with a list of a available{" "}
                    <b>operators</b>.
                </li>
                <li>
                    Once an operator is selected, define the <b>value</b>.
                </li>
                <li>
                    Continue to add rules using logical operators (AND/OR) to
                    determine how rules within a group will be combined.
                </li>
                <li>
                    Select <b>'Test Rule Set'</b> to generate results that match
                    your classifier.
                </li>
            </ol>
        </>
    );

    const onSwitchView = () => {
        store.rbcSearchTermStr
            ? store.toggleSwitchViewDialog()
            : store.toggleView();
    };

    return (
        <>
            <Grid item>
                <Paper
                    sx={{
                        [theme.breakpoints.up("lg")]: {
                            paddingBottom: theme.spacing(5),
                        },
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                            paddingTop: theme.spacing(2.3),
                            paddingBottom: theme.spacing(1.8),
                            paddingLeft: theme.spacing(1.8),
                            paddingRight: theme.spacing(1.8),
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: theme.typography.h2.fontSize,
                                fontWeight: 600,
                            }}
                        >
                            {store.view === ClassifierBuilderView.Standard ? (
                                <>
                                    Classifier Builder
                                    <Tooltip
                                        placement="right-start"
                                        title={standardBuilderTooltip}
                                    >
                                        <InfoIcon
                                            className={classes.icon}
                                            color="primary"
                                        />
                                    </Tooltip>
                                </>
                            ) : (
                                "Advanced"
                            )}
                        </Typography>
                        <AcxButton
                            size="small"
                            variant="outlined"
                            onClick={onSwitchView}
                        >
                            {store.getNextView()}
                        </AcxButton>
                    </Stack>
                    <Divider />
                    <Grid container className={classes.input}>
                        {store.showTestRuleSetWarning && (
                            <Grid
                                item
                                container
                                style={{
                                    position: "relative",
                                    marginBottom: "1rem",
                                }}
                                id={"toast--test-rule-set-error"}
                            >
                                <span style={{ width: "100%" }}>
                                    <AcxToast
                                        fullWidth
                                        severity="error"
                                        message={store.testClassifierError}
                                        title={""}
                                        onClose={() =>
                                            store.setTestClassifierError("")
                                        }
                                        fullWidthMessage={true}
                                    />
                                </span>
                            </Grid>
                        )}

                        {store.view === ClassifierBuilderView.Standard ? (
                            <StandardClassifierBuilder
                                store={store.standardCBStore}
                            />
                        ) : (
                            <Grid container item>
                                <TextField
                                    className={
                                        store.testClassifierError
                                            ? classes.hasErrorTextField
                                            : classes.textField
                                    }
                                    id="search-term-entry"
                                    label="Enter Search Terms"
                                    multiline
                                    maxRows={
                                        store.testClassifierError ? 14 : 17
                                    }
                                    minRows={
                                        store.testClassifierError ? 14 : 17
                                    }
                                    value={store.rbcSearchTermStr || ""}
                                    variant="outlined"
                                    inputProps={{
                                        style: {
                                            fontFamily:
                                                "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontFamily:
                                                "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
                                        },
                                    }}
                                    onChange={(event) =>
                                        store.setRbcSearchTermStr(
                                            event.target.value,
                                        )
                                    }
                                    error={!!store.testClassifierError}
                                    // onError={() => {
                                    //     // store.messageStore.logError(
                                    //     //     "Error for RBC FTS Search Syntax",
                                    //     // );
                                    // }}
                                    required
                                />
                                <ClassifierCheatsheet />
                            </Grid>
                        )}
                    </Grid>
                </Paper>
            </Grid>

            <Grid
                item
                container
                className={classes.testControls}
                alignItems="flex-end"
            >
                <AcxDateRangeInput
                    onSelect={(start, end) =>
                        applicationFiltersStore.setDatePair(start, end)
                    }
                    defaultStartDate={applicationFiltersStore.startDate}
                    defaultEndDate={applicationFiltersStore.endDate}
                    datePickerStore={applicationFiltersStore.datePickerStore}
                    displayDateType
                    dateReferenceOptions={basicDateReferenceOptions}
                    maxWidth="100%"
                    inputStyle={{ width: "100%" }}
                    wrapperStyle={{ maxWidth: "100%" }}
                />
                <AcxButton
                    color="primary"
                    onClick={validateAndTestRuleSet}
                    className={classes.testButton}
                    loading={store.validationLoading}
                    disabled={store.standardCBStore.hasError}
                >
                    Test Rule Set
                </AcxButton>
            </Grid>
            <ClassifierBuilderV2SwitchViewDialog store={store} />
        </>
    );
});

export default ClassifierBuilderRuleset;
