import { observer } from "mobx-react";
import React, { useState } from "react";
import { Suggestion } from "../Suggestions/Suggestion";
import AcxDialog from "../../UI/Dialog/AcxDialog";
import copy from "copy-to-clipboard";
import { useStore } from "../../../utils/useStore";
import MessageStore from "../../ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";

export const ViewSqlQueriesAction = observer(({ args: sqlStatements }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const messageStore = useStore(MessageStore);

    return (
        <>
            <Suggestion onClick={() => setModalOpen(true)}>
                View SQL Queries
            </Suggestion>
            <AcxDialog
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                title="SQL Queries"
                maxWidth="xl"
                dialogContentChildren={sqlStatements
                    .filter((s) => !!s)
                    .map((s) => (
                        <>
                            <pre>{s}</pre>
                            <AcxButton
                                onClick={() => {
                                    copy(s!);
                                    messageStore.logInfo("Copied to clipboard");
                                }}
                            >
                                Copy to clipboard
                            </AcxButton>
                        </>
                    ))}
            ></AcxDialog>
        </>
    );
});
