import { Grid } from "@mui/material";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React, { FunctionComponent, useEffect } from "react";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";
import { useStore } from "../../../../utils/useStore";
import AcxDataGrid from "../../../UI/AcxDataGrid/AcxDataGrid";
import { CustomControlItem } from "../../../UI/AcxTable/AcxControlledTable";
import { AgentCoachingStore } from "../Stores/AgentCoachingStore";

interface OwnProps {}

type Props = OwnProps;

const referenceOptions = [
    DateReferenceOption.EvaluationCompleteDate,
    DateReferenceOption.InteractionDate,
    //DateReferenceOption.ArrivalDate,
];

const AgentsCoachingTable: FunctionComponent<Props> = observer((props) => {
    const store = useStore(AgentCoachingStore);

    useEffect(() => {
        const dateRangePicker = (
            <Observer>
                {() => (
                    <Grid
                        key="agent-coaching-table-dateRangeSelector"
                        item
                        xs
                        container
                        justifyContent={"flex-end"}
                    >
                        <AcxDateRangeInput
                            datePickerStore={store.datePickerStore}
                            displayDateType
                            dateReferenceOptions={referenceOptions}
                            defaultStartDate={store.datePickerStore.beginDate}
                            defaultEndDate={store.datePickerStore.endDate}
                            onSelect={(start: Moment, end: Moment) => {
                                store.datePickerStore.setBeginDate(start);
                                store.datePickerStore.setEndDate(end);
                            }}
                        />
                    </Grid>
                )}
            </Observer>
        );
        const controls: CustomControlItem[] = [
            {
                controlElement: dateRangePicker,
                xs: 5,
                sm: 4,
                md: 3,
                lg: 2,
                xl: 1,
            },
        ];

        store.dgStore.controls = controls;
    }, [store.datePickerStore, store.dgStore, store.orgSelectorStore]);

    useEffect(() => {
        if (store.coachingDetails?.length > 1) {
            store.dgStore.removeHeight = "150px";
        } else {
            store.dgStore.removeHeight = "2rem";
        }
    }, [store.coachingDetails?.length, store.dgStore]);

    return (
        <Grid container item xs={12} style={{ height: "100%" }}>
            <AcxDataGrid dataGridStore={store.dgStore} />
        </Grid>
    );
});

export default AgentsCoachingTable;
