import CallBatch from "../models/CallBatch";
import BaseService from "./BaseService";

export interface ICallbatchService {
    listCallbatches(orgId: string): Promise<CallBatch[]>;
}

export class CallbatchService extends BaseService implements ICallbatchService {
    protected urlBase: string = "api/CallBatches";

    public async listCallbatches(orgId: string): Promise<CallBatch[]> {
        let urlSearchParams = new URLSearchParams({ oid: orgId });

        const rv = await this.get(urlSearchParams, `SuperList`);
        return rv;
    }
}
