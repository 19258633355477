import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { ReportDataField } from "../../../../../models/Reporting/ReportDataField";
import {
    FieldUse,
    ReportField,
} from "../../../../../models/Reporting/ReportField";
import hexToRGB from "../../../../../utils/hexToRGB";
import { useStore } from "../../../../../utils/useStore";
import AcxToggleButton from "../../../../UI/AcxToggleButton";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import { QuickFilterOptions, ToggleFilterOptions } from "../Editor";
import ReportFieldTransferList from "./ReportFieldTransferList";

const useStyles = makeStyles((theme: Theme) => ({
    addButton: {
        textTransform: "none",
        color: theme.palette.blue.main,
        marginTop: theme.spacing(3),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
    addButtonText: {
        color: theme.palette.blue.main,
        fontSize: "13px",
        fontWeight: theme.typography.fontWeightBold as any,
        letterSpacing: 0,
        lineHeight: "20px",
        marginLeft: theme.spacing(1),
    },
    addButtonIcon: {
        width: "16px",
        height: "16px",
        backgroundColor: hexToRGB(theme.palette.blue.main, 0.2),
        borderRadius: "50%",
    },
    removeButton: {
        fontWeight: theme.typography.fontWeightBold as any,
        textTransform: "none",
        backgroundColor: theme.palette.red.main,
        width: "17px",
        height: "17px",
        color: theme.palette.white.main,
        borderRadius: "50%",
        marginTop: theme.spacing(1.2),
        paddingBottom: "2px",
    },
    selectedQuickFiltersText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    bulletedList: {
        // listStyle: "circle",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },

    buttonIcon: {
        width: "10px",
        height: "10px",
        marginTop: "2px",
        textAlign: "center",
    },

    globalCombinatorText: {
        fontSize: "16px",
        letterSpacing: 0,
        lineHeight: "24px",
    },

    root: {
        marginTop: theme.spacing(3),
    },
    leftSubsectionPadding: {
        paddingLeft: theme.spacing(0.5),
    },
}));

function quickFilterFieldNameSelector(
    reportDataField: ReportDataField | ReportField,
) {
    if (reportDataField instanceof ReportField) {
        return reportDataField.reportDataField!.displayName;
    } else {
        return reportDataField.displayName;
    }
}

function quickFilterPredicate(field: ReportField) {
    return field.fieldUse === FieldUse.QuickFilter;
}

interface OwnProps {}

type Props = OwnProps;

const QuickFilterSection: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);
    return (
        <>
            <div className={clsx(classes.vertSpace, classes.sectionSpace)}>
                <AcxToggleButton
                    id={"QuickFilterOptions"}
                    items={ToggleFilterOptions}
                    disabled={!Boolean(store.currentReportDataView)}
                    defaultValue={store.quickFilterOption}
                    onChange={store.setQuickFilterOption}
                    exclusive
                />
            </div>

            <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                    {store.quickFilterOption === QuickFilterOptions.Custom && (
                        <Typography
                            className={clsx(classes.selectedQuickFiltersText)}
                        >
                            Selected Filters
                        </Typography>
                    )}
                </Grid>
                {store.quickFilterOption === QuickFilterOptions.Custom ? (
                    <>
                        <ReportFieldTransferList
                            onSelectedFields={
                                store.currentReport?.changeQuickFilters
                            }
                            hideAggregateSelectors
                            reportFieldPredicate={quickFilterPredicate}
                            labelFieldSelector={quickFilterFieldNameSelector}
                            availableFields={
                                store.availableReportQuickFilterFields
                            }
                            selectedFields={
                                store.currentReport
                                    ?.quickFilterReportDataFields ?? []
                            }
                            selectedFieldsTitle={"Selected Filters"}
                            availableFieldsTitle={"Available Filters"}
                            idField={"displayName"}
                            height={"230px"}
                        />
                    </>
                ) : (
                    <Grid item xs={12}>
                        {Boolean(store.currentReportDataView) && (
                            <>
                                <Typography
                                    className={classes.selectedQuickFiltersText}
                                >
                                    Standard Quick Filters
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography
                                            className={classes.bulletedList}
                                        >
                                            Interaction Date
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            className={classes.bulletedList}
                                        >
                                            Evaluation Date
                                        </Typography>
                                    </li>
                                </ul>
                            </>
                        )}
                    </Grid>
                )}
            </Grid>
        </>
    );
});

export default QuickFilterSection;
