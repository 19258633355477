import ChatIcon from "@mui/icons-material/Chat";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EmailIcon from "@mui/icons-material/Email";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PhoneIcon from "@mui/icons-material/Phone";
import {
    GridColDef,
    GridColTypeDef,
    GridFilterInputValueProps,
    GridFilterItem,
} from "@mui/x-data-grid-pro";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import _ from "lodash";
import { InteractionType } from "models/InteractionType";
import React from "react";

const InteractionTypeFilterInput = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    // eslint-disable-next-line array-callback-return
    const interactionTypes: {
        text: string;
        number: string;
    }[] = [];

    for (const [key] of Object.entries(InteractionType)) {
        const k = parseInt(key);
        if (!isNaN(k)) {
            const val = getInteractionType(k);
            if (
                val.text !== "Video" &&
                val.text !== "Metadata" &&
                val.text !== "ExtendedMetadata"
            ) {
                interactionTypes.push({
                    ...val,
                    number: val.number.toString(),
                });
            }
        }
    }

    const [curVal, setCurVal] = React.useState<
        | {
              text: string;
              number: string;
          }
        | undefined
    >(interactionTypes.find((status) => status.number === item.value));

    const handleFilterChange = (arg) => {
        const val = arg["number"];
        setCurVal(arg);
        applyValue({ ...item, value: val });
    };

    return (
        <AcxSelect
            optionComponent={interactionTypeComp}
            options={interactionTypes}
            defaultValue={curVal}
            id={"type-filter-selector"}
            isMulti={false}
            labelField={"text"}
            valueField={"number"}
            inputLabel="Select Status"
            onChange={handleFilterChange}
        />
    );
};

const getInteractionType = (
    type: number,
): {
    text: string;
    number: number;
} => {
    let text;

    if (type === InteractionType.Audio) {
        text = "Audio";
    } else if (type === InteractionType.Email) {
        text = "Email";
    } else if (type === InteractionType.Chat) {
        text = "Chat";
    } else if (type === InteractionType.Video) {
        text = "Video";
    } else if (type === InteractionType.Live) {
        text = "Live";
    } else if (type === InteractionType.Metadata) {
        text = "Metadata";
    } else if (type === InteractionType.ProcessedChat) {
        text = "ProcessedChat";
    } else if (type === InteractionType.ExtendedMetadata) {
        text = "ExtendedMetadata";
    }

    return { text, number: text };
};

export const interactionTypeColumnType: GridColTypeDef = {
    // sortComparator: (v1, v2, param1, param2) => {
    //     const p1 = param1.api.getRow(param1.id)?.[param1.field];
    //     const p2 = param2.api.getRow(param2.id)?.[param2.field];

    //     if (!p1) return 1;
    //     if (!p2) return -1;
    //     return p2 - p1 <= 0 ? -1 : 1;
    // },
    renderCell: (arg) => {
        const typeName = _.get(arg.row, arg.field);
        return getIconForInteractionType(typeName);
    },
    filterOperators: [
        {
            label: "equals",
            value: "equals",
            getApplyFilterFn: (
                filterItem: GridFilterItem,
                column: GridColDef,
            ) => {
                if (
                    !filterItem.field ||
                    !filterItem.value ||
                    !filterItem.operator
                ) {
                    return null;
                }

                return (params): boolean => {
                    const rowValue = _.get(params.row, column.field);
                    return (
                        rowValue ===
                        InteractionType[filterItem.value?.toString()]
                    );
                };
            },
            InputComponent: InteractionTypeFilterInput,
        },
    ],
    sortable: false,
};

const getIconForInteractionType = (typeName) => {
    let nameIcon;

    if (typeName === InteractionType.Audio) {
        nameIcon = <PhoneIcon />;
    } else if (typeName === InteractionType.Email) {
        nameIcon = <EmailIcon />;
    } else if (typeName === InteractionType.Chat) {
        nameIcon = <ChatBubbleIcon />;
    } else if (typeName === InteractionType.Live) {
        nameIcon = <EmojiPeopleIcon />;
    } else if (typeName === InteractionType.ProcessedChat) {
        nameIcon = <ChatIcon />;
    }

    return nameIcon;
};

const interactionTypeComp = (p) => {
    return (
        <div
            ref={p.innerRef}
            {...p.innerProps}
            style={{
                fontWeight: "bold",
                cursor: "pointer",
                display: "flex",
            }}
        >
            <div>{getIconForInteractionType(InteractionType[p.label])}</div>
            <div>{p.label}</div>
        </div>
    );
};
