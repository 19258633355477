import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "Styles/Styles";
import { useResizeObserver } from "utils/useResizeObserver";
import FocusPage from "./FocusPage";

const styles = (theme: Theme) =>
    createStyles({
        content: {
            padding: theme.spacing(0),
        },
    });

type Props = {
    title: string;
    controlHeader: React.ReactNode;
    controlHeaderClass?: string;
    mainContent: React.ReactNode;
    mainContentClass?: string;
    loading?: boolean;
    onCancel?: () => void;
    onSave?: () => Promise<void>;
    showSave?: boolean;
    onResize?: (wdith, height) => void;

    saveText?: string;
    saveTooltip?: string;
    cancelText?: string;
    hideCancelButton?: boolean;

    onSaveAs?: () => void;
    showSaveAs?: boolean;
    saveAsText?: string;
    saveAsTooltip?: string;
};

function FocusControls(props: Props) {
    const classes = useStyles(styles);
    const navigate = useNavigate();
    const onCancel = () => {
        try {
            props.onCancel?.();
        } finally {
            navigate(-1);
        }
    };
    const onResize = (width, height) => {
        props.onResize?.(width, height);
    };
    const { elementHeight, theRef } = useResizeObserver<HTMLDivElement>({
        onResize: onResize,
        name: "FocusControls",
    });
    return (
        <FocusPage
            title={props.title}
            cancelText={props.cancelText}
            saveText={props.saveText}
            onCancel={onCancel}
            loading={props.loading}
            onSave={props.onSave}
            showSave={props.showSave}
            onSaveAs={props.onSaveAs}
            showSaveAs={props.showSaveAs}
            hideCancelButton={props.hideCancelButton}
            saveAsText={props.saveAsText}
            saveTooltip={props.saveTooltip}
            saveAsTooltip={props.saveAsTooltip}
        >
            <Grid
                style={{ height: "calc(100% - 64px)" }}
                className={classes.content}
                container
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={0}
            >
                {props.controlHeader && (
                    <Grid
                        item
                        xs={12}
                        className={props.controlHeaderClass}
                        ref={theRef}
                    >
                        {props.controlHeader}
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    style={{
                        height: `calc(100% - ${elementHeight}px)`,
                        overflowX: "hidden",
                    }}
                    className={props.mainContentClass}
                >
                    {props.mainContent}
                </Grid>
            </Grid>
        </FocusPage>
    );
}

export default FocusControls;
