import { observable, makeObservable } from "mobx";
import BaseEntity from "./BaseEntity";
import Classifier, { ResultType } from "./ClassifierModel";
import RuleSet from "./RuleSet";
import { v4 as uuidv4 } from "uuid";

export enum RuleType {
    Filter,
    Stratify,
    Rank,
}

export enum Operator {
    Gt,
    GtEq,
    Lt,
    LtEq,
    NEq,
    Eq,
    In,
    Between,
}

export const EQUAL_STRATIFICATION_OPERATOR = Operator.Eq;
export const REPRESENTATIVE_STRATIFICATION_OPERATOR = Operator.NEq;
export const ARBITRARY_STRATIFICATION_OPERATOR = Operator.In;

export default class Rule extends BaseEntity {
    constructor(
        id: string | null,
        ruleSetId: string,
        classifierId: string,
        order?: number,
        ruleType?: RuleType,
        operator?: Operator,
        resultType?: ResultType,
        threshold?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        super(id ?? uuidv4(), createdBy, modifiedBy, createdOn, modifiedOn);

        makeObservable(this);

        this.ruleSetId = ruleSetId;
        this.ruleType = ruleType;
        this.classifierId = classifierId;
        this.operator = operator;
        this.order = order;
        this.resultType = resultType ?? ResultType.StringType;
        this.threshold = threshold;
    }

    static fromServer(json: Rule, ruleSet: RuleSet) {
        if (json.ruleType === RuleType.Filter) {
            if (
                json.operator === Operator.In ||
                json.operator === Operator.Between
            ) {
                let thresholdArray = JSON.parse(
                    json.threshold ?? JSON.stringify([]),
                ) as string[];

                if (json.classifier.name === "Agent Name") {
                    json.threshold = thresholdArray.join(",\n");
                } else {
                    if (json.classifier.name === "Call Duration") {
                        thresholdArray = thresholdArray.map(
                            (value) =>
                                `${(parseInt(value ?? "0", 10) / 60000).toFixed(
                                    2,
                                )}`,
                        );
                    }

                    json.threshold = thresholdArray.join(",");
                }
            } else if (json.classifier.name === "Call Duration") {
                json.threshold = `${(
                    parseInt(json.threshold ?? "0", 10) / 60000
                ).toFixed(2)}`;
            }
        }

        const rule = new Rule(
            json.id!,
            json.ruleSetId,
            json.classifierId,
            json.order,
            json.ruleType,
            json.operator,
            json.resultType,
            json.threshold,
            json.createdBy,
            json.modifiedBy,
            json.createdOn,
            json.modifiedOn,
        );
        rule.classifier = json.classifier;
        rule.id = json.id;
        rule.order = json.order;
        rule.isActive = json.isActive;
        rule.ruleSet = ruleSet;
        return rule;
    }

    // @observable isActive:boolean;  ToDo: commented in upgrad
    @observable ruleSetId: string;
    ruleSet: RuleSet | null;
    @observable ruleType?: RuleType;
    @observable classifierId: string;
    @observable order?: number;
    classifier: Classifier;
    @observable operator?: Operator;
    @observable resultType?: ResultType;
    @observable threshold?: string;
}
