import { Link } from "@mui/material";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { action, makeObservable, observable, reaction } from "mobx";
import Topic from "models/Topic";
import TopicRelevantInteraction from "models/TopicRelevantInteraction";
import moment from "moment";
import React from "react";
import { TopicService } from "services/TopicService";
import { TranscriptionService } from "services/TranscriptionService";
import type { ITranscriptionPayload } from "services/TranscriptionService";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

@AcxStore
export class TopicStore extends BaseStore {
    public readonly topicService = new TopicService();
    public readonly transcriptionService = new TranscriptionService();
    readonly dgStore: AcxDataGridStore;

    @observable.ref
    orgId: string;

    @observable
    identifiedTopics: Topic[] | null;

    @observable
    unidentifiedTopics: Topic[] | null;

    @observable
    selectedTopic: Topic;

    @observable
    transcription?: ITranscriptionPayload;

    @observable
    showTranscription: boolean = false;

    @observable
    selectedInteraction: TopicRelevantInteraction;

    @observable.ref
    relevantInteractions: TopicRelevantInteraction[];

    constructor(private rootStore: IRootStore) {
        super("TopicStore");
        makeObservable(this);
        this.dgStore = new AcxDataGridStore(
            "TopiceDetailGridDataStore",
            "TopicDetail",
        );
        this.dgStore.setColumns([
            {
                field: "fileName",
                flex: 1,
                headerName: "Interaction",
                renderCell: (params) => (
                    <Link
                        component="button"
                        color="secondary"
                        onClick={() =>
                            this.handleShowTranscription(
                                params.row as TopicRelevantInteraction,
                            )
                        }
                    >
                        {params.value as React.ReactNode}
                    </Link>
                ),
            },
            {
                field: "interactionDate",
                flex: 1,
                headerName: "Interaction Date",
                type: "dateTime",
                valueFormatter: (_, row) =>
                    row.interactionDate
                        ? moment(row.interactionDate).format("MMM DD, YYYY")
                        : "",
            },
        ]);

        reaction(
            (r) => this.relevantInteractions,
            () => {
                this.dgStore.rows = this.relevantInteractions;
            },
        );
    }

    @action
    async handleShowTranscription(interaction: TopicRelevantInteraction) {
        this.setSelectedInteraction(interaction);
        this.setShowTranscription(true);

        this.setupAsyncTask("Load Transcription", async () => {
            try {
                const transcriptObj =
                    await this.transcriptionService.getTranscriptionTextForAudioMetadata(
                        interaction.id,
                    );
                this.setTranscription(transcriptObj);
            } catch (err) {
                this.rootStore
                    .getStore(MessageStore)
                    .logError(
                        "Load transcription failed: " +
                            (err as any).errorMessage,
                    );
                this.handleHideTranscription();
            }
        });
    }

    @action
    handleHideTranscription() {
        this.showTranscription = false;
        this.transcription = undefined;
    }

    @action
    setTranscription(input) {
        this.transcription = input;
    }

    @action
    setSelectedInteraction(input) {
        this.selectedInteraction = input;
    }

    @action
    setShowTranscription(input) {
        this.showTranscription = input;
    }

    public invalidateStore() {
        this.identifiedTopics = null;
        this.unidentifiedTopics = null;
    }

    public getIdentifiedTopics() {
        this.setupAsyncTask("getIdentifiedTopicsByOrganization", async () =>
            this.getIdenifiedTopicsByOrganization(),
        );
    }

    public getUnidentifiedTopics() {
        this.setupAsyncTask("getUnidentifiedTopicsByOrganization", async () =>
            this.getUnidenifiedTopicsByOrganization(),
        );
    }

    public updateTopicName(topic: Topic, name: string) {
        this.setupAsyncTask("updateTopicName", async () =>
            this.putTopicName(topic.id, name),
        );
    }

    public setSelectedTopic(topic: Topic) {
        this.setupAsyncTask("getRelevantInteractions", async () =>
            this.getRelevantInteractionsByTopic(topic.id),
        );
        this.selectedTopic = topic;
    }

    private getIdenifiedTopicsByOrganization = async () => {
        this.identifiedTopics =
            await this.topicService.getIdentifiedByOrganization();
    };

    private getUnidenifiedTopicsByOrganization = async () => {
        this.unidentifiedTopics =
            await this.topicService.getUnidentifiedByOrganization();
    };

    private getRelevantInteractionsByTopic = async (topicId: string) => {
        this.relevantInteractions =
            await this.topicService.getRelevantInteractions(topicId);
    };

    private putTopicName = async (topicId: string, name: string) => {
        await this.topicService.updateTopicName(topicId, name);
    };
}
