import React from "react";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material";

function ClipPlus() {
    const theme = useTheme();
    return (
        <Divider
            style={{
                width: "100%",
                backgroundColor: theme.palette.secondary.main,
            }}
        />
    );
}

export default ClipPlus;
