import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { ApplicationUser } from "../models/Permission/ApplicationUser";
import { ActionRecipientsService } from "../services/Actions/ActionRecipientsService";
import { BaseStore } from "./BaseStore";
import { AcxStore } from "./RootStore";

@AcxStore
export class ActionRecipientStore extends BaseStore {
    private readonly actionRecipientsService: ActionRecipientsService =
        new ActionRecipientsService();

    private readonly EvaluationDomain = "Evaluation";
    private readonly ReportingDomain = "Reporting";
    private readonly MontageDomain = "Montage";

    @observable domainRecipients: Map<string, ApplicationUser[]> = new Map<
        string,
        ApplicationUser[]
    >();

    constructor() {
        super("Action Recipients");
        makeObservable(this);
    }

    @action
    loadEvaluationRecipients(
        orgId: string,
        organizationStructureMemberId: string,
        userId: string,
    ) {
        this.setupAsyncTask("Load Evaluation Action Recipients", async () => {
            const res =
                await this.actionRecipientsService.loadEvaluationDomainRecipients(
                    orgId,
                    organizationStructureMemberId,
                    userId,
                );

            runInAction(() => {
                this.domainRecipients.set(this.EvaluationDomain, res);
            });
        });
    }

    @computed
    get evaluationRecipients() {
        return this.domainRecipients.get(this.EvaluationDomain) ?? [];
    }

    @action
    reset() {
        this.domainRecipients.clear();
    }
}
