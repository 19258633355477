// import {ConfigurationLoader} from './configuration/configurationLoader';
import { ErrorConsoleReporter } from "./plumbing/errors/errorConsoleReporter";
import { ErrorCodes } from "./plumbing/errors/errorCodes";
import { ErrorHandler } from "./plumbing/errors/errorHandler";
import { TokenRenewalResponseHandler } from "./plumbing/oauth/tokenRenewalResponseHandler";
import { ApplicationPaths } from "components/Auth/api-authorization/ApiAuthorizationConstants";

/*
 * A mini application for the hidden iframe that does silent token renewal
 */
export class IFrameApp {
    public async execute(): Promise<void> {
        try {
            // Download configuration
            // const configuration = await ConfigurationLoader.download('spa.config.json');
            const configuration = await (
                await fetch(
                    ApplicationPaths.ApiAuthorizationClientConfigurationUrl,
                )
            ).json();
            // {"authority":"https://localhost:5001",
            // "client_id":"acxplatform",
            // "redirect_uri":"https://localhost:5001/authentication/login-callback",
            // "post_logout_redirect_uri":"https://localhost:5001/authentication/logout-callback",
            // "response_type":"code","scope":"openid profile AcxPlatformAPI",
            // "userClaims":"role"}
            // Handle token renewal responses on an iframe
            const handler = new TokenRenewalResponseHandler({
                clientId: configuration.client_id as any,
                authority: configuration.authority as any,
            } as any);
            await handler.handleSilentTokenRenewalResponse();
        } catch (e) {
            // In the event of errors, avoid impacting end users and output the error to the console
            const uiError = ErrorHandler.getFromTokenError(
                e,
                ErrorCodes.tokenRenewalError,
            );
            ErrorConsoleReporter.output(uiError);
        }
    }
}
