import React from "react";
import { HeatMapGrid } from "react-grid-heatmap";

interface Props {
    data: number[][];
    xLabels?: string[];
    yLabels?: string[];
    cellHeight?: string;
    cellTooltip?: (x: number, y: number, value: number) => JSX.Element;
    xLabelStyle?: (index?: number) => {};
    yLabelStyle?: (index?: number) => {};
    cellStyle?: (x: number, y: number, ratio: number) => {};
    xLabelPos?: "top" | "bottom";
    yLabelPos?: "left" | "right";
}

export default function AcxHeatMapGrid(props: Props) {
    return (
        <HeatMapGrid
            data={props.data}
            xLabels={props.xLabels}
            yLabels={props.yLabels}
            cellRender={props.cellTooltip}
            xLabelsStyle={props.xLabelStyle}
            yLabelsStyle={props.yLabelStyle}
            cellStyle={props.cellStyle}
            cellHeight={props.cellHeight}
            xLabelsPos={props.xLabelPos}
            yLabelsPos={props.yLabelPos}
        />
    );
}
