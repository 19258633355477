import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import React from "react";
import useStyles from "Styles/Styles";
import SignalsDrawerContent from "./SignalsDrawerContent";
import SignalsRoutes from "./SignalsRoutes";
import { observer } from "mobx-react";
import { useStore } from "utils/useStore";
import SignalsReportStore from "./Store/SignalsReportStore";

const styles = (theme: Theme) =>
    createStyles({
        sideControlMain: {
            padding: 0,
        },
    });
function SignalsLayout() {
    const classes = useStyles(styles);
    const store = useStore(SignalsReportStore);

    React.useEffect(() => {
        return () => {
            store.isPresentationMode = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SideControlsPage
            mainContent={<SignalsRoutes hideTitle={true} />}
            containerOffsetPixels={64}
            drawerContent={<SignalsDrawerContent />}
            drawerLocation="left"
            mainGridAlignItems="stretch"
            mainGridJustify="flex-start"
            mainGridSpacing={0}
            mainContentClasses={classes.sideControlMain}
            drawerClosed={store.isPresentationMode}
            enableMinimize
            hideMinimizeToggle
        />
    );
}

export default observer(SignalsLayout);
