import { SelectionOption } from "components/ManagerInteractions/Stores/ManagerInteractionsStore";
import AudioMetadataModel from "models/AudioMetadataModel";
import { EvaluationStatus } from "models/Evaluation";
import { ReactText } from "react";
import {DateReferenceOption, InteractionDateReferenceOption} from "stores/ComponentStores/DatePickerComponentStore";
import BaseService from "./BaseService";

type evalIdPayload = { id: string };

export class InteractionRecord extends AudioMetadataModel {
    organizationId: string;
    evaluationId: string;
    evaluationStatus: EvaluationStatus;
    evaluationType: string;
    evalNumber: number;
    assignedAnalysts: string;
}

export class InteractionService extends BaseService {
    protected urlBase = "api/Interaction";

    public async getUnassignedInteractions(
        startDate: string,
        endDate: string,
        dateReference?: DateReferenceOption,
        debounceKey?: string,
    ) {
        if (
            dateReference !== DateReferenceOption.ArrivalDate &&
            dateReference !== DateReferenceOption.InteractionDate
        ) {
            dateReference = DateReferenceOption.ArrivalDate;
        }

        const apiPath = "UnassignedInteractions";
        const params = new URLSearchParams({
            startDate: startDate || "",
            endDate: endDate || "",
            dateReference: dateReference.valueOf(),
        });
        return this.get<AudioMetadataModel[]>(
            params,
            apiPath,
            undefined,
            debounceKey,
        );
    }

    public async getAssignedInteractions(
        selectionOption: SelectionOption,
        analystGroupId: string | undefined,
        startDate: string | undefined,
        endDate: string | undefined,
        dateRef?: DateReferenceOption | InteractionDateReferenceOption,
        debounceKey?: string,
        getOutstandingModules?: boolean,
        showMachineEvals?: boolean,
    ) {
        const apiPath = "AssignedInteractions";
        const params = new URLSearchParams({
            selectionOption: selectionOption.toString(),
            analystGroupId: analystGroupId || "",
        });
        if (startDate && endDate) {
            params.set("startDate", startDate);
            params.set("endDate", endDate);
        }
        if (dateRef) {
            params.set("dateReference", dateRef.toString());
        }
        if (getOutstandingModules) {
            params.set("enhancedEvalStatus", String(getOutstandingModules));
        }
        if (showMachineEvals === false) {
            params.set("evalType", String("BottomOfTheFunnel"));
        }
        // console.log(params);
        const res = await this.get<{
            data: InteractionRecord[];
            summary: {
                total: number;
                statuses: Record<EvaluationStatus, number>;
            };
        }>(params, apiPath, undefined, debounceKey);

        return res;
    }

    public async getEvaluationId(orgId: string, audioMetadataId: string) {
        const apiPath = "EvaluationId";
        const params = new URLSearchParams({
            orgId: orgId || "",
            amdId: audioMetadataId || "",
        });
        var payload = await this.get<evalIdPayload>(params, apiPath);
        return payload.id;
    }

    public async assignInteractions(
        interactionIds: string[],
        structureMemberId: string | null,
    ) {
        const apiPath = "AssignInteractions";
        const body = {
            interactionIds,
            structureMemberId,
        };
        const urlParams = new URLSearchParams();
        await this.post<void>(JSON.stringify(body), urlParams, apiPath);
    }

    public async unAssignInteractions(
        interactionIds: string[] | ReactText[],
        structureMemberId: string | null,
    ) {
        const apiPath = "UnAssignInteractions";
        const body = {
            interactionIds,
        };
        const urlParams = new URLSearchParams();
        await this.post<void>(JSON.stringify(body), urlParams, apiPath);
    }

    public async assignToAnalyst(
        evalIds: string[] | ReactText[],
        analystId: string | null,
    ) {
        const apiPath = "AssignToAnalyst";
        const body = {
            evalIds,
            analystId,
        };
        const urlParams = new URLSearchParams();
        await this.post<void>(JSON.stringify(body), urlParams, apiPath);
    }

    public async unAssignAnalyst(evalIds: string[]) {
        const apiPath = "UnAssignAnalyst";
        const body = {
            evalIds,
        };
        const urlParams = new URLSearchParams();
        await this.post<void>(JSON.stringify(body), urlParams, apiPath);
    }
}
