import { Grid, Theme } from "@mui/material";
import { GridDirection, GridProps } from "@mui/material/Grid/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import {
    ChatPersona,
    IAugmentedChatRecord,
    IChatRecord,
} from "../AcxChatViewer";
import { ChatViewModel } from "../ViewModel/ChatViewModel";
import ChatMessage from "./ChatMessage";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "100%",
        backgroundColor: theme.palette.white.main,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    fullHeight: {
        height: "100%",
    },
    container: {
        marginBottom: theme.spacing(3),
    },
}));

interface OwnProps {
    records: IAugmentedChatRecord[];
    viewModel?: ChatViewModel;
    disableClip?: boolean;
}

type Props = OwnProps;

const ChatMessageList: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.records.map((rec, index) => {
                const layoutProps = chatPersonaToLayoutProps(rec);
                const key =
                    rec.clipId && rec.startIndex === index
                        ? `${rec.clipId} ${index}`
                        : `${rec.text}|${rec.timestamp} ${index}`;
                return (
                    <Grid
                        key={key}
                        container
                        className={classes.container}
                        direction={layoutProps.direction}
                        ref={(instance) => {
                            if (rec.startIndex === index) {
                                props.viewModel?.setClipMessageRef(
                                    key,
                                    instance,
                                );
                            }
                        }}
                    >
                        <Grid
                            item
                            container
                            xs={12}
                            alignItems={"flex-end"}
                            wrap={"nowrap"}
                            spacing={1}
                            justifyContent={layoutProps.justify}
                            direction={layoutProps.direction}
                        >
                            <ChatMessage
                                record={rec}
                                index={index}
                                viewModel={props.viewModel}
                                disableClip={props.disableClip}
                            />
                            <Grid item xs={6} />
                        </Grid>
                    </Grid>
                );
            })}
        </div>
    );
});

function chatPersonaToLayoutProps(chatRecord: IChatRecord) {
    if (chatRecord.from === ChatPersona.Agent) {
        return {
            justify: "flex-start" as GridProps["justifyContent"],
            direction: "row" as GridDirection,
        };
    } else {
        return {
            justify: "flex-start" as GridProps["justifyContent"],
            direction: "row-reverse" as GridDirection,
        };
    }
}

export default ChatMessageList;
