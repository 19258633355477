import { ButtonBase, Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import clsx from "clsx";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WidgetContentType } from "../../../models/Dashboard/Widget";
import { DashboardStore } from "../../../stores/Layout/DashboardStore";
import AcxMenu, { AcxMenuItemProps } from "../../UI/Menu/AcxMenu";
import { BaseWidgetDefinition } from "../Definitions/BaseWidgetDefinition";

const useStyles = makeStyles((theme: Theme) => ({
    smallButton: {
        width: "32px",
        height: "32px",
    },

    moreVertButton: (props: Props) => ({
        width: "48px",
        height: "32px",
        top: props.top !== undefined ? props.top : -9,
        right: props.right !== undefined ? props.right : -9,
    }),

    exitIcon: {
        color: theme.palette.red.main,
    },
    smallOutlineIconButton: {
        color: theme.palette.secondary.main,
    },
}));

interface OwnProps {
    widgetDefinition?: BaseWidgetDefinition;
    disableActions?: boolean;
    dashboardStore?: DashboardStore;
    top?: number;
    right?: number;
}

type Props = OwnProps;

const WidgetMenu: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles(props);
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState<
        AcxMenuItemProps[] | undefined
    >();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    function handleMoreMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        event.preventDefault();
    }

    function handleMoreMenuClose() {
        setAnchorEl(null);
    }

    function handleRemoveClick() {
        props.dashboardStore?.removeWidget(
            props.widgetDefinition?.dashboardItem,
        );
    }

    useEffect(() => {
        const disposer = autorun((r) => {
            const acxMenuItems = props.widgetDefinition?.menuItemsBuilder?.(
                navigate,
                props.dashboardStore,
                () => {
                    setAnchorEl(null);
                },
            );
            setMenuItems(acxMenuItems);
        });
        return () => disposer();
    }, [navigate, props.dashboardStore, props.widgetDefinition]);

    return (
        <>
            {menuItems &&
                !!menuItems.length &&
                !props.dashboardStore?.editMode && (
                    <Grid item style={{ position: "relative" }}>
                        <ButtonBase
                            onClick={handleMoreMenuClick}
                            disabled={
                                props.disableActions ||
                                props.widgetDefinition?.loading
                            }
                            className={clsx(
                                classes.moreVertButton,
                                classes.smallOutlineIconButton,
                            )}
                        >
                            <MoreVertIcon />
                        </ButtonBase>
                    </Grid>
                )}

            {props.dashboardStore?.editMode &&
                props.dashboardStore.canEditDashboard &&
                props.widgetDefinition?.dashboardItem?.contentType !==
                    WidgetContentType.IMMUTABLE && (
                    <ButtonBase
                        onClick={handleRemoveClick}
                        className={clsx(
                            classes.moreVertButton,
                            classes.exitIcon,
                        )}
                    >
                        <CloseIcon />
                    </ButtonBase>
                )}

            <>
                {menuItems && (
                    <AcxMenu
                        menuItems={menuItems}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        anchorElement={anchorEl}
                        onMenuClose={handleMoreMenuClose}
                    />
                )}
            </>
        </>
    );
});

export default WidgetMenu;
