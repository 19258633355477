import React from "react";
import { Grid, Typography, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Stack from "@mui/material/Stack";
import theme from "Theme/AppTheme";
import { getSpeakerColorByChannelOrPersona } from "Theme/utils";
import clsx from "clsx";
import AcxChip from "../AcxChip";
import { SpeakerChannelMap } from "../AcxTranscription/Stores/SpeakerStore/SpeakerStore";

interface BaseProps {
    timestamp: string;
    text: string;
    className?: string;
    speakerChannelMap?: SpeakerChannelMap;
}

interface EddyBlurbWithChannel extends BaseProps {
    persona?: never;
    channel: number;
}

interface EddyBlurbWithPersona extends BaseProps {
    persona: "Agent" | "Customer";
    channel?: never;
}

// Eddy Blurbs can have a persona or a channel, but not both
type EddyBlurbProps = { variant: "eddy" | "adverse" } & (
    | EddyBlurbWithChannel
    | EddyBlurbWithPersona
);

type StyleProps = {
    persona?: "Agent" | "Customer";
    channel?: number;
    variant: "eddy" | "adverse";
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    container: {
        position: "relative",
        padding: theme.spacing(2.5, 0),
    },
    title: {
        width: "90px",
        minWidth: "90px",
        justifyContent: "center",
    },
    persona: (props) => ({
        fontWeight: "bold",
        color: !!props.persona
            ? getSpeakerColorByChannelOrPersona(props.persona)
            : getSpeakerColorByChannelOrPersona(props.channel),
        paddingRight: "4px",
    }),
    timestamp: {
        color: "gray",
    },
    content: ({ variant }) => ({
        paddingRight: "90px",
        paddingLeft: theme.spacing(1),
        borderLeft: `2px solid ${
            variant === "eddy"
                ? theme.palette.blue.main
                : theme.palette.error[900]
        }`,
    }),
}));

export default function EddyBlurb({
    persona,
    timestamp,
    text,
    channel,
    className,
    variant,
    speakerChannelMap,
}: EddyBlurbProps) {
    const classes = useStyles({ persona, channel, variant });

    const speaker = speakerChannelMap?.get(channel ?? -1);

    return (
        <Grid container className={clsx(classes.container, className)}>
            <Stack direction={"row"}>
                <Stack direction={"column"} className={classes.title}>
                    <Typography className={classes.persona}>
                        {!!persona
                            ? persona
                            : speaker
                            ? speaker.name
                            : channel
                            ? `Speaker ${channel + 1}`
                            : "Speaker"}
                    </Typography>
                    <Typography className={classes.timestamp}>
                        {timestamp}
                    </Typography>
                </Stack>
                <Typography className={classes.content} component="div">
                    <div
                        id={timestamp}
                        dangerouslySetInnerHTML={{
                            __html: text,
                        }}
                    />
                    <Grid style={{ paddingTop: theme.spacing(1) }}>
                        {variant === "eddy" ? (
                            <AcxChip
                                color="eddy"
                                icon="eddy"
                                size="small"
                                label="Eddy Effect Signal"
                            />
                        ) : (
                            <AcxChip
                                color="adverse"
                                size="small"
                                label="Adverse Event"
                            />
                        )}
                    </Grid>
                </Typography>
            </Stack>
        </Grid>
    );
}
