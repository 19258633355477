import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { ButtonBase, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";
import clsx from "clsx";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { ThemeColors } from "../../Theme/AppTheme";
import { DashboardStore } from "../../stores/Layout/DashboardStore";
import hexToRGB from "../../utils/hexToRGB";

// googles edit mode color: '#8a2da5'
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: "48px",
        position: "relative",
        width: "100%",
        paddingLeft: theme.spacing(2.5),
        display: "flex",
    },
    editorIcon: (props: Props & { editMode?: boolean }) => ({
        color: props.editMode
            ? hexToRGB(theme.palette.primary.main, 0.96)
            : theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: "1.9em",
        marginRight: theme.spacing(0.5),
    }),
    saveDefaultIcon: {
        color: ThemeColors.purple,
        fontWeight: "bold",
        fontSize: "1.9em",
        marginRight: theme.spacing(0.5),
    },
    editModeText: {
        color: theme.palette.text.primary,
        fontWeight: "bold",
    },
    modeIndicatorText: {
        color: theme.palette.white.main,
        fontWeight: "bold",
        textTransform: "none",
        position: "absolute",
        right: "16px",
    },
    cancelColor: {
        color: hexToRGB(theme.palette.red.main, 0.75),
        fontWeight: "bold",
        fontSize: "1.9em",
        marginRight: theme.spacing(0.5),
    },
    saveColor: {
        color: hexToRGB(theme.palette.info.dark, 0.96),
        fontWeight: "bold",
        fontSize: "1.9em",
        marginRight: theme.spacing(0.5),
    },
    selectWidth: {
        width: "150px",
        marginRight: theme.spacing(2),
        paddingTop: theme.spacing(1.25),
    },
}));

const PaddedButtonBase = styled(ButtonBase)(({ theme }) => ({
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(0.5),
    textTransform: "none",
    marginRight: theme.spacing(2.0),
}));

interface OwnProps {
    dashboardStore?: DashboardStore;
    showRefresh?: boolean;
}

type Props = OwnProps;

const DashboardEditor: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles({ editMode: props.dashboardStore?.editMode });
    const labelOptions = [
        { label: "25% initial width", value: "1" },
        { label: "50% initial width", value: "2" },
        { label: "75% initial width", value: "3" },
        { label: "100% initial width", value: "4" },
    ];

    return (
        <div className={classes.root}>
            {props.dashboardStore?.editMode && (
                <div className={classes.selectWidth}>
                    <AcxSelectSingle
                        labelField="label"
                        id="new-widget-width"
                        valueField="value"
                        options={labelOptions}
                        onChange={(item) =>
                            (props.dashboardStore!.initialWidgetWidth =
                                item.value)
                        }
                        defaultValue={labelOptions.find(
                            (opt) =>
                                opt.value ===
                                props.dashboardStore?.initialWidgetWidth,
                        )}
                    />
                </div>
            )}
            {props.showRefresh && (
                <PaddedButtonBase onClick={props.dashboardStore?.refreshAll}>
                    <RefreshIcon className={classes.editorIcon} />
                    <Typography className={classes.editModeText}>
                        Refresh
                    </Typography>
                </PaddedButtonBase>
            )}

            {!props.dashboardStore?.isAgentAsUser && (
                <>
                    {!props.dashboardStore?.editMode ? (
                        <PaddedButtonBase
                            onClick={props.dashboardStore?.editDashboard}
                        >
                            <EditOutlinedIcon className={classes.editorIcon} />
                            <Typography className={classes.editModeText}>
                                Edit
                            </Typography>
                        </PaddedButtonBase>
                    ) : (
                        props.dashboardStore.pendingDashboardChanges && (
                            <PaddedButtonBase
                                onClick={props.dashboardStore?.saveChanges}
                            >
                                <SaveAltIcon
                                    className={clsx(classes.saveColor)}
                                />
                                <Typography
                                    className={clsx(classes.editModeText)}
                                >
                                    Save
                                </Typography>
                            </PaddedButtonBase>
                        )
                    )}

                    {props.dashboardStore?.editMode && (
                        <PaddedButtonBase
                            onClick={props.dashboardStore?.cancelEditing}
                        >
                            <CancelPresentationIcon
                                className={clsx(classes.cancelColor)}
                            />
                            <Typography className={clsx(classes.editModeText)}>
                                Cancel
                            </Typography>
                        </PaddedButtonBase>
                    )}

                    {props.dashboardStore?.editMode &&
                        props.dashboardStore.canBuildDefaultDashboard &&
                        props.dashboardStore.pendingDashboardChanges && (
                            <PaddedButtonBase
                                onClick={
                                    props.dashboardStore?.saveChangesAsDefault
                                }
                            >
                                <SaveIcon className={classes.saveDefaultIcon} />
                                <Typography className={classes.editModeText}>
                                    Save Default
                                </Typography>
                            </PaddedButtonBase>
                        )}

                    {props.dashboardStore?.editMode &&
                        props.dashboardStore.isCustomUserDashboard && (
                            <PaddedButtonBase
                                onClick={
                                    props.dashboardStore
                                        ?.restoreDefaultDashboard
                                }
                            >
                                <SettingsBackupRestoreIcon
                                    className={classes.editorIcon}
                                />
                                <Typography className={classes.editModeText}>
                                    Restore Default
                                </Typography>
                            </PaddedButtonBase>
                        )}
                </>
            )}
        </div>
    );
});

export default DashboardEditor;
