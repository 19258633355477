import { Grid, IconButton, Paper } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import {
    LocaleIds,
    MetadataComparisonOperators,
} from "models/OrganizationModels/OrganizationMetadataConfiguration";
import React, { FunctionComponent } from "react";
import useStyles from "Styles/Styles";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import {
    LOAD_CONFIG,
    OrganizationMetadataStore,
} from "./Store/OrganizationMetadataStore";

const styles = () =>
    createStyles({
        fieldSpacing: {
            margin: "0.25rem 0",
            padding: "0 0 0 0.125rem",
        },
        metadataContainer: {
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        inputSpacing: { paddingLeft: ".125rem" },
    });

interface MetadataLanguageRowProps {
    configIndex: number;
    id: string;
    currentIndex: number;
    store: OrganizationMetadataStore;
}

const MetadataLanguageRow: FunctionComponent<MetadataLanguageRowProps> =
    observer((props) => {
        const { configIndex, id, currentIndex, store } = props;
        const classes = useStyles(styles);

        const onFieldPathChange = React.useCallback(
            (event: string, index: number) => {
                store.handleLanguageFieldPathUpdates(configIndex, event, index);
            },
            [configIndex, store],
        );

        const onLocaleIdChange = React.useCallback(
            (event: any, index: number) => {
                store.handleLanguageLocaleIdUpdates(
                    configIndex,
                    event.value,
                    index,
                );
            },
            [configIndex, store],
        );

        const onComparisonOperatorChange = (event: string, index: number) => {
            store.handleLanguageComparisonUpdates(
                configIndex,
                Number(event),
                index,
            );
        };

        const onIndicatorStringChange = React.useCallback(
            (event: string, index: number, stringIndex: number) => {
                store.handleLanguageIndicatorStringUpdates(
                    configIndex,
                    event,
                    index,
                    stringIndex,
                );
            },
            [configIndex, store],
        );

        const languageIndicatorArray =
            store.organizationMetadataConfiguration[configIndex]?.configuration
                ?.languageIndicatorDefinitions ?? [];

        const currentLanguageRow = languageIndicatorArray[currentIndex];

        const localeIdChoices = EnumToDropdownOptionConversion(LocaleIds);

        const comparisonChoices = EnumToDropdownOptionConversion(
            MetadataComparisonOperators,
        );

        return !!currentLanguageRow && (
            <Paper className={classes.metadataContainer}>
                <Grid container item xs={12}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.fieldSpacing}
                    >
                        <Grid item xs={3} className={classes.inputSpacing}>
                            <AcxSelect
                                inputLabel="Locale ID"
                                fullWidth
                                defaultValue={localeIdChoices.find(
                                    (item) =>
                                        item.value ===
                                        currentLanguageRow.localeId,
                                )}
                                id={`field_${currentIndex}`}
                                options={localeIdChoices}
                                onChange={(event) =>
                                    onLocaleIdChange(event, currentIndex)
                                }
                                placeholder="Locale ID"
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.inputSpacing}>
                            <AcxMainTextField
                                fullWidth
                                skeleton={store.getTaskLoading(LOAD_CONFIG)}
                                labelText="Field Path"
                                id="fieldPath"
                                value={currentLanguageRow?.fieldPath}
                                onChange={(event) =>
                                    onFieldPathChange(
                                        event.target.value,
                                        currentIndex,
                                    )
                                }
                                showAllErrors={true}
                            />
                        </Grid>

                        <Grid item xs={2} className={classes.inputSpacing}>
                            <AcxSelect
                                inputLabel="Comparison Operator"
                                fullWidth
                                defaultValue={comparisonChoices.find(
                                    (item) =>
                                        item.value ===
                                        currentLanguageRow.comparisonOperator,
                                )}
                                id={`langComparisonOperator${currentIndex}`}
                                options={comparisonChoices}
                                onChange={(event) =>
                                    onComparisonOperatorChange(
                                        event.value,
                                        currentIndex,
                                    )
                                }
                                placeholder="Comparison Operator"
                            />
                        </Grid>

                        <Grid
                            container
                            item
                            xs={1}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <IconButton
                                onClick={() =>
                                    store.removeFieldConfiguration(
                                        "languageIndicatorDefinitions",
                                        id,
                                        configIndex,
                                    )
                                }
                                size="large">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.fieldSpacing}
                    >
                        <Grid
                            item
                            container
                            xs={10}
                            className={classes.inputSpacing}
                        >
                            {!!currentLanguageRow.languageIndicatorStrings
                                .length &&
                                currentLanguageRow.languageIndicatorStrings.map(
                                    (
                                        languageIndicatorString,
                                        stringIndex,
                                    ) => (
                                        <Grid
                                            item
                                            xs={3}
                                            className={classes.inputSpacing}
                                            key={stringIndex}
                                        >
                                            <AcxMainTextField
                                                fullWidth
                                                skeleton={store.getTaskLoading(
                                                    LOAD_CONFIG,
                                                )}
                                                labelText="Language Indicator String"
                                                id={
                                                    "languageIndicatorString" +
                                                    stringIndex
                                                }
                                                value={
                                                    languageIndicatorString
                                                }
                                                onChange={(event) =>
                                                    onIndicatorStringChange(
                                                        event.target.value,
                                                        currentIndex,
                                                        stringIndex,
                                                    )
                                                }
                                                showAllErrors={true}
                                            />
                                        </Grid>
                                    ),
                                )}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={2}
                            justifyContent="flex-end"
                        >
                            <IconButton
                                onClick={() => {
                                    store.addLanguageIndicatorString(
                                        configIndex,
                                        currentIndex,
                                    );
                                }}
                                size="large">
                                <AddIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    currentLanguageRow.languageIndicatorStrings.pop();
                                }}
                                size="large">
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    });

export default MetadataLanguageRow;
