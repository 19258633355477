import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import * as RootStore from "stores/RootStore";
import { PipelineService } from "../../../../../services/PipelineService";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { makeObservable, observable } from "mobx";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import SynthInteraction from "models/SynthInteraction";
import moment from "moment";


@RootStore.AcxStore
export default class OpsStore extends BaseStore {
    readonly authStore: AuthStore;
    readonly pipelineService: PipelineService;
    
    public readonly datePickerStore = new DatePickerComponentStore(moment().startOf("date"));
    
    
    @observable
    public interactions: SynthInteraction[];
    readonly opsDgStore: AcxDataGridStore;
    
    constructor(public rootStore: RootStore.IRootStore) {
        super("OpsDataGridStore");
        makeObservable(this);
        
        this.opsDgStore = new AcxDataGridStore("Ops", "Administrative");
        this.pipelineService = new PipelineService();
        this.authStore = rootStore.getStore(AuthStore);
    }
    
    public async updateInteractions() {
        this.opsDgStore.rows = [];
        this.opsDgStore.isLoading = true;
        try {
            await this.pipelineService.pollOpsDatabase(this.authStore.orgStore.selectedOrganization?.id!);
            this.interactions = await this.pipelineService.getInteractionsByOrg(
                this.authStore.orgStore.selectedOrganization?.id!,
                this.datePickerStore.beginDate,
                this.datePickerStore.endDate,
            );
            this.opsDgStore.rows = this.interactions
        } catch (error) {
            throw error;
        } finally {
            this.opsDgStore.isLoading = false;
        }
    }
    
    public async uploadInteractions() {
        this.opsDgStore.isLoading = true;
        try {
            // if this is changed to have a checkbox, the string needs to be formatted in the way it is below
            // camelCase with commas separating, no spaces
            await this.pipelineService.uploadInteractions(
                this.authStore.orgStore.selectedOrganization?.id!,
                "audioWithMetadata,audioWithoutMetadata,chat"
                );
            await this.updateInteractions();
        } catch (error) {
            throw error;
        } finally {
            this.opsDgStore.isLoading = false;  
        }
    }
    
}