import { action, observable, makeObservable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import BaseEntity from "./BaseEntity";
import Classifier from "./ClassifierModel";
import Rule, { RuleType } from "./Rule";
import RuleCombinator, { CombinatorRuleType } from "./RuleCombinator";

export default class RuleSet extends BaseEntity {
    constructor(
        name: string,
        id?: string,
        organizationId?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        super(id ?? uuidv4(), createdBy, modifiedBy, createdOn, modifiedOn);
        makeObservable(this);
        this.name = name;
        this.organizationId = organizationId;
        this.addCombinator(
            RuleType.Filter,
            CombinatorRuleType.Or,
            createdBy,
            modifiedBy,
            createdOn,
            modifiedOn,
        );
        this.addCombinator(
            RuleType.Rank,
            CombinatorRuleType.And,
            createdBy,
            modifiedBy,
            createdOn,
            modifiedOn,
        );
    }

    static fromServer(
        jsonRuleSet: RuleSet,
        currentRuleSet?: RuleSet,
        classifiers?: Classifier[],
    ) {
        const ruleSet = new RuleSet(
            jsonRuleSet.name,
            jsonRuleSet.id,
            jsonRuleSet.organizationId,
            jsonRuleSet.createdBy,
            jsonRuleSet.modifiedBy,
            jsonRuleSet.createdOn,
            jsonRuleSet.modifiedOn,
        );
        // ruleSet.id = jsonRuleSet.id;
        // ruleSet.isActive = jsonRuleSet.isActive;
        // ruleSet.name = jsonRuleSet.name;

        const newRules = jsonRuleSet.rules.map((value) =>
            Rule.fromServer(value, ruleSet),
        );

        const newCombos = jsonRuleSet.ruleCombinators.map((value) =>
            RuleCombinator.fromServer(value, ruleSet),
        );

        newCombos.push(
            ...ruleSet.ruleCombinators.filter(
                (defaultCombo) =>
                    newCombos.findIndex(
                        (newCombo) =>
                            newCombo.ruleType.valueOf() ===
                            defaultCombo.ruleType.valueOf(),
                    ) < 0,
            ),
        );

        ruleSet.rules.splice(0, ruleSet.rules.length, ...newRules);
        ruleSet.ruleCombinators.splice(
            0,
            ruleSet.ruleCombinators.length,
            ...newCombos,
        );

        return ruleSet;
    }

    @observable name: string;
    organizationId?: string;
    @observable rules: Rule[] = observable.array();
    @observable ruleCombinators: RuleCombinator[] = observable.array();

    setOrganizationId(organizationId: string) {
        this.organizationId = organizationId;
    }

    @action
    addRule(ruleId: string, classifierId: string, userName?: string) {
        const rule = new Rule(ruleId, this.id!, classifierId);
        rule.createdBy = userName;
        rule.modifiedBy = userName;

        this.rules.push(rule);
        return rule;
    }

    @action
    addCombinator(
        ruleType: RuleType,
        combinator?: CombinatorRuleType,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        if (ruleType === RuleType.Rank) {
            const ruleCombinator = new RuleCombinator(
                null,
                this.id!,
                ruleType,
                combinator ?? CombinatorRuleType.And,
                createdBy,
                modifiedBy,
                createdOn,
                modifiedOn,
            );
            this.ruleCombinators.push(ruleCombinator);
            return ruleCombinator;
        }
        if (ruleType === RuleType.Filter) {
            const ruleCombinator = new RuleCombinator(
                null,
                this.id!,
                ruleType,
                combinator ?? CombinatorRuleType.Or,
                createdBy,
                modifiedBy,
                createdOn,
                modifiedOn,
            );
            this.ruleCombinators.push(ruleCombinator);
            return ruleCombinator;
        }
    }
}
