import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React, { FunctionComponent } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        width: "100%",
        height: "100%",
    },
    errIcon: (props: Props) => ({
        color: theme.palette.error.main,
        opacity: "0.35",
        fontSize: `${props.parentHeightPx * 0.42}px`,
    }),
    errText: (props: Props) => ({
        opacity: "0.25",
        color: "#1F1F1F",
        fontSize: props.parentHeightPx > 450 ? "24px" : "18px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
        // textAlign: "center"
    }),
}));

interface OwnProps {
    parentHeightPx: number;
    errorText?: string;
}

type Props = OwnProps;

const WidgetError: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const classes = useStyles(props);
    return (
        <div style={{ position: "relative", top: `20%` }}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <ErrorOutlineIcon className={classes.errIcon} />
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.errText}>
                        {props.errorText}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
};

export default WidgetError;
