import React from "react";
import { Link } from "react-router-dom";
import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { standardDateFormat, parseFromISO } from "utils/DateTimeUtils";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        paper: {
            background: "#FFFFFF",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.07)",
            borderRadius: 8,
            minHeight: 175,
            padding: 12,
            display: "flex",
            justifyContent: "space-between",
            textDecoration: "none",
            color: theme.palette.blackFont.main,
            position: "relative",
        },
        name: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 16,
            position: "absolute",
            margin: "0.5rem",
            top: 0,
            left: 0,
            textAlign: "left",
            color: theme.palette.blackFont.main,
        },
        users: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 12,
            position: "absolute",
            margin: "0.5rem",
            bottom: 0,
            right: 0,
            textAlign: "right",
            color: "#A1A1AA",
        },
        modifiedBy: {
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: 12,
            color: "#A1A1AA",
            position: "absolute",
            margin: "0.5rem",
            top: "1.5rem",
            left: 0,
        },
    }),
);

type Props = {
    data: any[];
    isLoading: boolean;
    orgId: string;
};

const OrganizationRolesListCards: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = ({ data, isLoading, orgId }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                {data.map((item) => (
                    <Grid item xs={12} sm={6} lg={4} key={item.id}>
                        <Link
                            to={`/admin/organizations/${orgId}/roles?id=${item.id}`}
                            className={classes.paper}
                        >
                            <div className={classes.name}>{item.name}</div>

                            {item.modifiedBy && (
                                <div className={classes.modifiedBy}>
                                    Edited by{" "}
                                    {item.modifiedBy.includes("@")
                                        ? item.modifiedBy.slice(
                                              0,
                                              item.modifiedBy.indexOf("@"),
                                          )
                                        : item.modifiedBy}{" "}
                                    on{" "}
                                    {standardDateFormat(
                                        parseFromISO(item.modifiedOn, true),
                                    )}
                                    .
                                </div>
                            )}

                            <div className={classes.users}>
                                {item.userCount} user
                                {item.userCount > 1 || item.userCount === 0
                                    ? "s"
                                    : ""}
                            </div>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default OrganizationRolesListCards;
