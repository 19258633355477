import { Box, Container, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { GridRowModel } from "@mui/x-data-grid-pro";
import AcxChipList from "components/UI/AcxChipList";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    chipContainer: {
        maxHeight: "150px",
        overflowY: "auto",
    },
}));

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onUnpublish: () => void;
    classifierCount: number;
    loading: boolean;
    selectedRows: GridRowModel[];
}

const ClassifierBuilderV2UnpublishDialog = ({
    isOpen,
    onClose,
    onUnpublish,
    loading,
    selectedRows,
}: Props) => {
    const classes = useStyles();
    return (
        <AcxConfirmationDialog
            isOpen={isOpen}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={onUnpublish}
            title={`Are you sure you want to unpublish classifier?`}
            fullWidth
            isLoading={loading}
            confirmButtonText="Unpublish"
            content={
                <>
                    <Box>
                        <Typography>
                            Unpublishing a classifier will delete all results
                            associated with that classifier.
                        </Typography>
                    </Box>
                    <br />
                    <Box>
                        <Typography color="error">
                            If you are not the owner, you may lose visibility of
                            this classifier.
                        </Typography>
                    </Box>
                    <br />
                    <Box>
                        <Typography>
                            The following classifier's results will be deleted:
                        </Typography>
                    </Box>
                    <Container className={classes.chipContainer}>
                        <AcxChipList
                            values={selectedRows.map(({ name }) => name)}
                            color="red"
                            size="small"
                        />
                    </Container>
                </>
            }
        />
    );
};

export default ClassifierBuilderV2UnpublishDialog;
