import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import React from "react";
import { useParams } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import OrganizationDetail from "./OrganizationDetail";
import OrganizationDetailNavigation from "./OrganizationDetailNavigation";
import OrganizationDetailRoutes from "./OrganizationDetailRoutes";

const styles = (theme: Theme) =>
    createStyles({
        sideControlMain: {
            padding: 0,
        },
    });

const OrganizationDetailContainer = () => {
    const classes = useStyles(styles);
    let { organizationId } = useParams();
    const authStore = useStore(AuthStore);

    if (organizationId === "add") {
        return <OrganizationDetail />;
    }

    // Get Selected Org from Auth Store OR as a last resort, get Org from User
    organizationId =
        authStore.orgStore.selectedOrganization?.id ??
        authStore._user.profile.OrgId;

    return (
        <SideControlsPage
            mainContent={
                <OrganizationDetailRoutes organizationId={organizationId!} />
            }
            enableMinimize
            containerOffsetPixels={64}
            drawerContent={
                <OrganizationDetailNavigation
                    organizationId={organizationId!}
                />
            }
            drawerLocation="left"
            mainGridAlignItems="stretch"
            mainGridSpacing={0}
            mainContentClasses={classes.sideControlMain}
        />
    );
};

export default OrganizationDetailContainer;
