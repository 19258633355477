import React from "react";
import { Colord } from "colord";
import { Button, ButtonProps, styled } from "@mui/material";
import theme from "Theme/AppTheme";
import useDebounce from "utils/useDebounce";

type AcxColorPickerButtonProps = {
    pickerColor: Colord | string;
} & ButtonProps;

const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "pickerColor",
})<{ pickerColor: Colord }>(({ pickerColor }) => ({
    width: "24px",
    height: "24px",
    borderRadius: "4px",
    minWidth: "24px",
    margin: theme.spacing(0.75),
    backgroundColor: pickerColor.toHex(),
    border: `${pickerColor.darken(0.08).toHex()} 1px solid`,
    "&:hover": {
        backgroundColor: pickerColor.darken(0.05).toHex(),
    },
}));

export const AcxColorPickerButton: React.FC<AcxColorPickerButtonProps> = ({
    pickerColor,
    ...props
}) => {
    const debouncedPickerColor = useDebounce<Colord>(
        typeof pickerColor === "string" ? new Colord(pickerColor) : pickerColor,
        200,
    );

    return <StyledButton pickerColor={debouncedPickerColor} {...props} />;
};
