import { uniqBy } from "lodash";
import moment from "moment/moment";
import React from "react";
import EvalActionNotification from "../../../components/Evaluation/Views/ActionNotification/EvalActionNotification";
import { Routes } from "../../../components/Navigation/Routes";
import { EvaluationStatus } from "../../../models/Evaluation";
import {
    parseFromISO,
    standardDateTimeFormat,
} from "../../../utils/DateTimeUtils";
import { INotificationTransformer } from "../NotificationStore";
import {
    NotificationArgument,
    NotificationType,
} from "../../RealTimeMessages/MessageStoreBase";

export class EvalActionNotificationDetails {
    actionReason: string;
    actionNotes: string;
    fromUserName: string;
    actionType: "CompleteReview" | "RequestReview" | "ReviewComment" | string;
    createdOn: string;

    notesLength: number;

    static fromJson(json: EvalActionNotificationDetails) {
        const clz = new EvalActionNotificationDetails();
        clz.actionReason = json.actionReason ?? "Not Available";
        clz.actionNotes = json.actionNotes ?? "Not Available";
        clz.actionType = json.actionType;
        clz.notesLength = json.actionNotes?.length ?? 0;
        clz.fromUserName = json.fromUserName;
        clz.createdOn = standardDateTimeFormat(
            parseFromISO(json.createdOn ?? moment().toISOString(), false),
        );
        return clz;
    }
}

export class EvaluationActionNotificationArg {
    rootThreadId: string;
    organizationId: string;
    evaluationId: string;
    evaluationNumber: string;
    interactionName: string;
    initiatorUserName: string;
    recipientUserName: string;
    status: EvaluationStatus;

    details: EvalActionNotificationDetails[];

    completedReviewActionsCnt: number;
    requestReviewActionsCnt: number;
    reviewCommentActionsCnt: number;
    highestPrecedenceActionType:
        | "CompleteReview"
        | "RequestReview"
        | "ReviewComment"
        | string;

    getNotesForRequestReview() {
        const requestReviewDetails = this.details.filter(
            (value) => value.actionType === "RequestReview",
        );
        return {
            notes: requestReviewDetails.map((value) => value.actionNotes)[0],
            notesLength: requestReviewDetails.map(
                (value) => value.notesLength,
            )[0],
        };
    }

    getFromUserForCompleteReview() {
        return uniqBy(
            this.details
                .filter((value) => value.actionType === "CompleteReview")
                .map((value) => value.fromUserName),
            (s) => s,
        );
    }

    getFromUserForRequestReview() {
        return this.details
            .filter((value) => value.actionType === "RequestReview")
            .map((value) => value.fromUserName)[0];
    }

    getReasonForRequestReview() {
        return this.details
            .filter((value) => value.actionType === "RequestReview")
            .map((value) => value.actionReason)[0];
    }

    getCreatedOnForRequestReview() {
        return this.details
            .filter((value) => value.actionType === "RequestReview")
            .map((value) => value.createdOn)[0];
    }

    static fromJson(json: EvaluationActionNotificationArg) {
        const clz = new EvaluationActionNotificationArg();
        clz.rootThreadId = json.rootThreadId;
        clz.organizationId = json.organizationId;
        clz.evaluationId = json.evaluationId;
        clz.evaluationNumber = json.evaluationNumber;
        clz.interactionName = json.interactionName;
        clz.initiatorUserName = json.initiatorUserName;
        clz.recipientUserName = json.recipientUserName;
        clz.status = json.status;
        clz.details = (json.details ?? [])
            .map((value) => EvalActionNotificationDetails.fromJson(value))
            .sort((a, b) =>
                (a.createdOn ?? "") < (b.createdOn ?? "") ? 1 : -1,
            );

        clz.completedReviewActionsCnt = clz.details.filter(
            (value) => value.actionType === "CompleteReview",
        ).length;
        clz.requestReviewActionsCnt = clz.details.filter(
            (value) => value.actionType === "RequestReview",
        ).length;
        clz.reviewCommentActionsCnt = clz.details.filter(
            (value) => value.actionType === "ReviewComment",
        ).length;

        if (clz.requestReviewActionsCnt > clz.completedReviewActionsCnt) {
            clz.highestPrecedenceActionType = "RequestReview";
        } else {
            clz.highestPrecedenceActionType = "CompleteReview";
        }

        return clz;
    }

    static buildNotificationTransformer: () => INotificationTransformer<EvaluationActionNotificationArg> =
        () => {
            return (notificationJson: EvaluationActionNotificationArg) => {
                const actionNotification =
                    EvaluationActionNotificationArg.fromJson(notificationJson);

                let title = "";
                let subTitle = `Evaluation ${actionNotification.evaluationNumber}`;
                let message: React.ReactNode = "";
                let appPath = "";
                if (
                    actionNotification.organizationId &&
                    actionNotification.evaluationId
                ) {
                    appPath = Routes.makeEvaluationRoute(
                        actionNotification.organizationId,
                        actionNotification.evaluationId,
                    );
                }

                const isRequestReviewAction = actionNotification.details.every(
                    (value) => value.actionType === "RequestReview",
                );

                if (isRequestReviewAction) {
                    title = `Evaluation Review Requested`;
                } else {
                    const hasReviewCompleteActions =
                        actionNotification.details.some(
                            (value) => value.actionType === "CompleteReview",
                        );

                    // const hasReviewCommentsActions = actionNotification.details.some(
                    //     value => value.actionType === "ReviewComment");

                    if (hasReviewCompleteActions) {
                        title = `Evaluation Review Completed`;
                    }
                }

                message = (
                    <EvalActionNotification
                        status={actionNotification.status}
                        actionNotification={actionNotification}
                        appPath={appPath}
                    />
                );

                return {
                    id: actionNotification.rootThreadId,
                    appPath: appPath,
                    subTitle: subTitle,
                    message: message,
                    timestamp: moment().local().toISOString(),
                    title: title,
                    type: NotificationType.Info,
                } as NotificationArgument;
            };
        };
}
