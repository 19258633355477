export enum CallDirection {
    Inbound,
    Outbound,
    Transfer,
}

export namespace CallDirection {
    export function toString(callDirection: CallDirection): string {
        return CallDirection[callDirection].toString();
    }
}
