import { Grid } from "@mui/material";
import { SummaryStats } from "components/ManagerInteractions/Stores/ManagerInteractionsStore";
import { statusFormatter } from "components/ManagerInteractions/Util";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import theme from "../../../../Theme/AppTheme";

interface Props {
    stats: SummaryStats;
}

const SummaryDisplay = observer((props: Props) => {
    const statuses = useMemo(
        () =>
            Array.from(props.stats.statuses.entries()).sort(
                (a, b) => b[0] - a[0],
            ),
        [props.stats.statuses],
    );

    return (
        <Grid container spacing={3}>
            {statuses.map(([name, count], i) => {
                const Name = statusFormatter(name);
                return (
                    <Grid item key={"status-item-" + i}>
                        {Name}
                        {": " + count}
                    </Grid>
                );
            })}
            <Grid item>
                <span
                    style={{
                        color: theme.palette.text.secondary,
                        fontWeight: "bold",
                    }}
                >
                    Total
                </span>
                : {props.stats.total}
            </Grid>
        </Grid>
    );
});

export default SummaryDisplay;
