import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import React, { useEffect, useMemo, useRef } from "react";
import Chart from "react-google-charts";
import { GoogleChartWrapper, GoogleViz } from "react-google-charts/dist/types";
import { getHAxisHeight, getVAxisWidth } from "utils/helpers";
import theme from "../../../Theme/AppTheme";
import { defaultColorScheme } from "./GraphColorSchemes";

const chartName = "AcxHistogram";

type AxesScaleTypes = "log" | "mirrorLog" | null;
type GoogleDataFormat = any[][];

interface OwnProps {
    data?: GoogleDataFormat;

    colorScheme?: string[]; // not actually used since the utility reportDataToGoogleFormat styles each bar based on
    // theme colors

    legendPosition: "none" | "bottom" | "in" | "right" | "top";
    showGrid?: boolean;
    yTitle?: string;
    xTitle?: string;

    onSelect?: (
        series: string | null,
        x: string | number | null,
        y: string | number | null | any,
    ) => void;

    chartAreaLeftOffset?: number | string;
    chartAreaTopOffset?: number | string;
    chartAreaHeight?: number | string;
    chartAreaWidth?: number | string;

    showDemoData?: boolean;
    asWidget?: boolean;

    lastBucketPercentile?: number;
    enableZoom?: boolean;
    bucketSize?: number | "auto";
    hideBucketItems?: boolean;
    minValue?: number | "auto";
    maxValue?: number | "auto";
    numBucketsRule?: "sqrt" | "sturges" | "rice";
    verticalScaleType?: AxesScaleTypes;
    horizontalScaleType?: AxesScaleTypes;
    hAxisFontSize?: string;
    vAxisFontSize?: string;

    onChartReady?: (
        chartWrapper: GoogleChartWrapper,
        google: GoogleViz,
    ) => void;
}

type Props = OwnProps;

const AcxHistogram: React.FC<Props> = (props) => {
    const wrapper = useRef<null | any>();
    useEffect(() => {
        return () => {
            wrapper.current?.getChart()?.clearChart();
        };
    }, []);

    const data = Boolean(props.showDemoData) ? fake_data : props.data;

    const showLegend =
        (data?.[0].filter((d) => !Boolean(d.role)).length ?? 0) > 2;

    const options = useMemo(() => {
        const res = {
            fontName: "Inter",
            dataOpacity: 0.65,
            animation: {
                startup: true,
                duration: 500,
                easing: "out",
            },
            interpolateNulls: false,
            colors:
                props.colorScheme && props.colorScheme.length
                    ? props.colorScheme
                    : defaultColorScheme,
            tooltip: {
                textStyle: { fontName: "Inter" },
            },
            legend: {
                position: showLegend ? "top" : "none",
                alignment: "start",
                textStyle: {
                    fontName: "Inter",
                },
                maxLines: 5,
            },
            chart: {},
            chartArea: {
                left: props.vAxisFontSize
                    ? getVAxisWidth(props.vAxisFontSize)
                    : props.chartAreaLeftOffset ??
                      (props.asWidget ? "8%" : "4%"),
                top: props.chartAreaTopOffset ?? "5%",
                width: props.chartAreaWidth ?? (props.asWidget ? "88%" : "90%"),
                height: props.hAxisFontSize
                    ? getHAxisHeight(props.hAxisFontSize)
                    : props.chartAreaHeight ?? (props.asWidget ? "77%" : "90%"),

                // left: props.chartAreaLeftOffset ?? '8%',
                // top: props.chartAreaTopOffset ?? '13%',
                // width: props.chartAreaWidth ?? '88%',
                // height: props.chartAreaHeight ?? (props.asWidget ? '70%' : '80%')
            },
            hAxis: {
                scaleType: null as AxesScaleTypes,
                title: props.xTitle,
                titleTextStyle: {
                    color: theme.palette.gray[400],

                    fontSize: props.asWidget ? 13 : 16,
                    fontName: "Inter Bold",
                },
                textStyle: {
                    color: theme.palette.gray[400],

                    fontSize: props.asWidget ? 10 : props.hAxisFontSize ?? 9,
                    fontName: "Inter Bold",
                },
                minorGridLines: {
                    count: 0,
                },
                gridLines: {},
                // viewWindowMode:"pretty",
            },
            vAxis: {
                scaleType: null as AxesScaleTypes,
                title: props.yTitle,
                titleTextStyle: {
                    color: theme.palette.gray[400],

                    fontSize: props.asWidget ? 13 : 16,
                    fontName: "Inter Bold",
                },
                textStyle: {
                    color: theme.palette.gray[400],
                    fontSize: props.asWidget ? 10 : props.vAxisFontSize ?? 13,
                    fontName: "Inter Bold",
                },
                minorGridLines: {
                    count: 0,
                },
                gridlines: {
                    color: props.showGrid ? "E7E7E7" : "none",
                },
                minValue: 0,
            },
            enableInteractivity: true,
            histogram: {} as any,
        };

        res.vAxis.scaleType = props.verticalScaleType ?? null;
        res.hAxis.scaleType = props.horizontalScaleType ?? null;

        res.histogram.lastBucketPercentile = props.lastBucketPercentile ?? 0;

        res.histogram.numBucketsRule = props.numBucketsRule ?? "sqrt";

        res.histogram.bucketSize = props.bucketSize ?? "auto";

        return res;
    }, [
        props.asWidget,
        props.bucketSize,
        props.chartAreaHeight,
        props.chartAreaLeftOffset,
        props.chartAreaTopOffset,
        props.chartAreaWidth,
        props.colorScheme,
        props.hAxisFontSize,
        props.horizontalScaleType,
        props.lastBucketPercentile,
        props.numBucketsRule,
        props.showGrid,
        props.vAxisFontSize,
        props.verticalScaleType,
        props.xTitle,
        props.yTitle,
        showLegend,
    ]);
    return (
        <>
            {Boolean(data) && (
                <Chart
                    width={`100%`}
                    height={props.asWidget ? `97%` : "100%"}
                    chartType="Histogram"
                    loader={
                        <AcxLoadingIndicator
                            color="secondary"
                            alternate="PuffLoader"
                            size={96}
                        />
                    }
                    data={data}
                    options={options}
                    chartEvents={[
                        {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();
                                if (selection.length === 1) {
                                    const [selectedItem] = selection;
                                    const dataTable =
                                        chartWrapper.getDataTable();
                                    const { row, column } = selectedItem;

                                    if (row !== null && column !== null) {
                                        const rowLabel = data?.[row + 1]?.[0];
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    series: dataTable?.getColumnLabel(
                                                        column,
                                                    ),
                                                    X: rowLabel,
                                                    Y: dataTable?.getValue(
                                                        row,
                                                        column,
                                                    ),
                                                    rowProps:
                                                        dataTable?.getRowProperties(
                                                            row,
                                                        ),
                                                    columnProps:
                                                        dataTable?.getColumnProperties(
                                                            column,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            rowLabel,
                                            dataTable?.getValue(row, column) ??
                                                null,
                                        );
                                    } else if (row !== null) {
                                        const rowLabel = data?.[row + 1]?.[0];
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    X: rowLabel,
                                                    rowProps:
                                                        dataTable?.getRowProperties(
                                                            row,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(null, rowLabel, null);
                                    } else if (column !== null) {
                                        console.log(
                                            `${chartName} selection : ${JSON.stringify(
                                                {
                                                    row,
                                                    column,
                                                    series: dataTable?.getColumnLabel(
                                                        column,
                                                    ),
                                                    columnProps:
                                                        dataTable?.getColumnProperties(
                                                            column,
                                                        ),
                                                },
                                            )}`,
                                        );

                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            null,
                                            null,
                                        );
                                    }
                                }
                            },
                        },
                    ]}
                    getChartWrapper={(w, g) => {
                        wrapper.current = w;
                        props.onChartReady?.(w, g);
                    }}
                />
            )}
        </>
    );
};

const fake_data = [
    ["Tags", "Count"],
    ["Help With Bill", 8.94],
    ["Brand Detractor", 10.49],
    ["Program Value", 19.3],
    ["Brand Value", 21.45],
];

export default AcxHistogram;
