import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import BarChartIcon from "@mui/icons-material/BarChart";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PieChartIcon from "@mui/icons-material/PieChart";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import TableChartIcon from "@mui/icons-material/TableChart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import React, { FunctionComponent } from "react";
import { VizType } from "../../../../../models/Reporting/ReportModel";
import AcxToggleButton from "../../../../UI/AcxToggleButton";

const useStyles = makeStyles((theme: Theme) => ({
    typeText: {
        fontWeight: "inherit",
        color: "inherit",
        fontSize: "inherit",
    },
    horizRotate: {
        transform: "rotate(90deg)",
    },
}));

interface OwnProps {
    defaultValue?: VizType;
    onChange?: (arg: VizType) => void;
}

type Props = OwnProps;

const ReportTypeToggle: FunctionComponent<Props> = (props) => {
    const classes = useStyles();

    return (
        <AcxToggleButton
            id="viz-type"
            justifyContent={"space-between"}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            size={"large"}
            items={[
                {
                    value: VizType.Bar,
                    element: (
                        <div>
                            <EqualizerIcon fontSize={"large"} />
                            <Typography className={classes.typeText}>
                                Bar
                            </Typography>
                        </div>
                    ),
                },
                {
                    value: VizType.Histogram,
                    sizeOverride: "default",
                    element: (
                        <div>
                            <BarChartIcon fontSize={"large"} />
                            <Typography className={classes.typeText}>
                                Histogram
                            </Typography>
                        </div>
                    ),
                },
                {
                    value: VizType.Table,
                    element: (
                        <div>
                            <TableChartIcon fontSize={"large"} />
                            <Typography className={classes.typeText}>
                                Table
                            </Typography>
                        </div>
                    ),
                },
                {
                    value: VizType.Pie,
                    element: (
                        <div>
                            <PieChartIcon fontSize={"large"} />
                            <Typography className={classes.typeText}>
                                Pie
                            </Typography>
                        </div>
                    ),
                },
                {
                    value: VizType.Line,
                    element: (
                        <div>
                            <TrendingUpIcon fontSize={"large"} />
                            <Typography className={classes.typeText}>
                                Line
                            </Typography>
                        </div>
                    ),
                },
                {
                    value: VizType.HorizontalBar,
                    sizeOverride: "default",
                    element: (
                        <div>
                            <EqualizerIcon
                                className={classes.horizRotate}
                                fontSize={"large"}
                            />
                            <Typography className={classes.typeText}>
                                Horizontal Bar
                            </Typography>
                        </div>
                    ),
                },
                {
                    value: VizType.Scatter,
                    element: (
                        <div>
                            <ScatterPlotIcon fontSize={"large"} />
                            <Typography className={classes.typeText}>
                                Scatter
                            </Typography>
                        </div>
                    ),
                },
            ]}
            spacing={3}
            exclusive
        />
    );
};

export default ReportTypeToggle;
