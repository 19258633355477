import {
    Grid,
    GridDirection,
    GridProps,
    GridSpacing,
    Theme,
    useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { Observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useResizeObserver } from "../../utils/useResizeObserver";
import DrawerToggleButton from "./DrawerToggleButton";

const useStyles = makeStyles((theme: Theme) => ({
    drawerContainer: (props: ISideControlsPage & { drawerOpen: boolean }) => ({
        overflow: "auto",
        backgroundColor: "white",
        minWidth: !props.drawerOpen ? 0 : `${props.drawerMinWidth ?? 260}px`,
        maxWidth: !props.drawerOpen ? 0 : `${props.drawerMaxWidth ?? 340}px`,
        zIndex: theme.zIndex.appBar - 1,
        position: "relative",

        height: `calc(100% - ${props.drawerVerticalOffsetPixels ?? 0}px)`,
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down("sm")]: props.addMargin
            ? {
                  marginTop: props.addMargin,
              }
            : {},
        [theme.breakpoints.down("lg")]: {},
        [theme.breakpoints.up("lg")]: {},
    }),

    mainContent: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

interface ISideControlsPage {
    onDrawerChange?: (drawerClosed: boolean) => void;

    enableMinimize?: boolean;
    hideMinimizeToggle?: boolean;

    drawerClosed?: boolean;

    drawerMinWidth?: string | number;
    drawerMaxWidth?: string | number;

    // content in the side panel; see RuleBuilder/SideContent for example
    drawerContent: React.ReactNode;

    // main content; see RuleBuilder/MainContent for example
    mainContent: React.ReactNode;

    // style class for Grid containing main content
    mainContentClasses?: string;

    containerOffsetPixels?: number;

    // style class for Grid containing side content
    drawerContentClasses?: string;

    // the side content height is 100vh and that interacts strange with FocusTitleBar (of height 64px)
    // so MainRuleBuilder sets this to 64 to prevent vertical drawer growing off the page. So default this
    // to the height of any ToolBars above or undefined if not needed
    drawerVerticalOffsetPixels?: number;

    // set position of side-content drawer on either left or right side; rule builder uses left side
    drawerLocation: "left" | "right";

    // options for Grid that contains main content; defaults chosen for RuleBuilder;
    mainGridDirection?: GridDirection; // defaults to "row"
    mainGridJustify?: GridProps["justifyContent"]; // defaults to "center"
    mainGridAlignItems?: GridProps["alignItems"]; // defaults to "flex-start"
    mainGridSpacing?: GridSpacing; // defaults to 2

    thinDrawer?: boolean;
    wideDrawerOverride?: boolean;

    addMargin?: string;

    toggleButtonStyles?: React.CSSProperties;
}

const SideControlsPage = React.forwardRef<HTMLDivElement, ISideControlsPage>(
    (props, ref) => {
        const [togglePosition, setTogglePosition] = useState<
            number | undefined
        >();
        const [drawerOpen, setDrawerOpen] = useState(true);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { elementHeight, elementWidth, theRef } =
            useResizeObserver<HTMLDivElement>({
                debounceWait: 0,
            });
        const classes = useStyles({ ...props, drawerOpen: drawerOpen });
        const theme = useTheme();

        useEffect(() => {
            if (elementWidth !== undefined && elementWidth !== null) {
                const left = elementWidth + (drawerOpen ? -12 : 2);
                setTogglePosition(left);
            }
            props.onDrawerChange?.(!drawerOpen);
        }, [elementWidth, drawerOpen, props]);

        useEffect(() => {
            if (props.enableMinimize) {
                setDrawerOpen(!props.drawerClosed);
            } else {
                setDrawerOpen(true);
            }
        }, [props.enableMinimize, props.drawerClosed]);

        return (
            <Observer>
                {() => (
                    <>
                        <Grid
                            ref={ref}
                            container
                            style={{
                                height: `calc(100% - ${
                                    props.containerOffsetPixels ?? 16
                                }px`,
                                position: "relative",
                                width: "100%",
                            }}
                            wrap={"nowrap"}
                            direction={
                                props.drawerLocation === "left"
                                    ? "row"
                                    : "row-reverse"
                            }
                            justifyContent="flex-start"
                            alignItems="stretch"
                        >
                            {props.enableMinimize &&
                                !props.hideMinimizeToggle && (
                                    <DrawerToggleButton
                                        onClick={() =>
                                            setDrawerOpen(
                                                (prevState) => !prevState,
                                            )
                                        }
                                        drawerOpen={drawerOpen}
                                        left={togglePosition}
                                        style={props.toggleButtonStyles}
                                    />
                                )}
                            <Grid
                                item
                                ref={theRef}
                                className={clsx(
                                    classes.drawerContainer,
                                    props.drawerContentClasses &&
                                        props.drawerContentClasses,
                                )}
                                sx={{
                                    overflow: "auto",
                                    backgroundColor: "white",
                                    minWidth: !drawerOpen
                                        ? 0
                                        : `${props.drawerMinWidth ?? 260}px`,
                                    maxWidth: !drawerOpen
                                        ? 0
                                        : `${props.drawerMaxWidth ?? 340}px`,
                                    zIndex: theme.zIndex.appBar - 1,
                                    position: "relative",

                                    height: `calc(100% - ${
                                        props.drawerVerticalOffsetPixels ?? 0
                                    }px)`,
                                    boxShadow: theme.shadows[1],
                                    [theme.breakpoints.down("sm")]:
                                        props.addMargin
                                            ? {
                                                  marginTop: props.addMargin,
                                              }
                                            : {},
                                    [theme.breakpoints.down("lg")]: {},
                                    [theme.breakpoints.up("lg")]: {},
                                }}
                            >
                                {props.drawerContent}
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                style={{
                                    height: "100%",
                                    overflowY: "auto",
                                    width: "100%",
                                }}
                                direction={props.mainGridDirection ?? "row"}
                                justifyContent={
                                    props.mainGridJustify ?? "center"
                                }
                                spacing={props.mainGridSpacing ?? 2}
                                alignItems={
                                    props.mainGridAlignItems ?? "flex-start"
                                }
                                className={
                                    props.mainContentClasses ??
                                    classes.mainContent
                                }
                            >
                                {props.mainContent}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Observer>
        );
    },
);

export default SideControlsPage;
