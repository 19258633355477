import { action, computed, makeObservable, observable, toJS } from "mobx";
import React from "react";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { parseFromISO } from "utils/DateTimeUtils";

export enum NotificationType {
    Error,
    Success,
    Info,
    InProgress,
}

export type NotificationDetails = {
    // details for relcassify notification
    tenantName?: string;
    dateReference?: string;
    startDate?: string;
    endDate?: string;
    classifierNames?: string[];
};

export class NotificationArgument {
    @observable id?: string;
    @observable notificationId?: string;
    @observable title: string;
    @observable subTitle?: string;
    @observable.ref message: string | React.ReactNode;
    @observable timestamp: string;
    @observable type: NotificationType;
    @observable appPath?: string;
    @observable addtlDetails?: NotificationDetails;

    onDismiss?: () => Promise<void>;

    constructor() {
        makeObservable(this);
    }

    static fromJson(json: NotificationArgument) {
        return Object.assign(new NotificationArgument(), json);
    }
}

@AcxStore
export default class MessageStoreBase {
    @observable notifications_: NotificationArgument[] = [];

    @computed
    get notifications() {
        return this.notifications_;
    }
    @computed
    get anyRunningOperations() {
        return (
            this.notifications.filter(
                (value) => value.type === NotificationType.InProgress,
            ).length > 0
        );
    }
    constructor(private rootStore: IRootStore) {
        makeObservable(this);
    }

    @action
    addOrUpdateNotification(argument: NotificationArgument) {
        if (argument.id) {
            const existingMsgIndex = this.notifications_.findIndex(
                (value) =>
                    value.id === argument.id &&
                    value.notificationId === argument.notificationId,
            );
            if (existingMsgIndex > -1) {
                this.notifications_.splice(existingMsgIndex, 1, argument);
                return;
            }
        }

        const notifications = toJS(this.notifications_)
            .concat(argument)
            .sort((a, b) => {
                const typeSortRes =
                    Number(b.type === NotificationType.InProgress) -
                    Number(a.type === NotificationType.InProgress);

                if (typeSortRes === 0) {
                    return parseFromISO(a.timestamp) > parseFromISO(b.timestamp)
                        ? -1
                        : 1;
                }

                return typeSortRes;
            });

        this.notifications_.splice(
            0,
            this.notifications_.length,
            ...notifications.map((value) =>
                NotificationArgument.fromJson(value),
            ),
        );
    }

    @action
    dismissAllNotifications = () => {
        const notifications = this.notifications.splice(0);
        notifications.forEach((value) => value.onDismiss?.());

        this.rootStore.getStore(LayoutDrawerStore).closeAndResetDrawer();
    };

    @action
    dismissNotification = (arg: NotificationArgument) => {
        function notificationKey(notification: NotificationArgument) {
            if (arg.id && arg.notificationId) {
                return notification.id + "-" + notification.notificationId;
            } else {
                return `${notification.title}-${notification.message}-${notification.timestamp}`;
            }
        }

        const index = this.notifications.findIndex(
            (value) => notificationKey(value) === notificationKey(arg),
        );
        if (index > -1) {
            const notifications = this.notifications.splice(index, 1);
            notifications.forEach((value) => value.onDismiss?.());
        }
    };
}
