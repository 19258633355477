import { useCallback } from "react";
import { addSearch } from "shared/functions/addSearch";
import useList from "shared/hooks/useList";
import UserService from "../OrganizationDetail/OrganizationUsers/User.service";
import { User } from "../types/User.type";

const useOrganizationUsers = (orgId: string) => {
    const getData = useCallback(async () => {
        const res = await UserService.getUsers(orgId);
        return addSearch(res, ["firstName", "lastName", "userName"]);
    }, [orgId]);

    const {
        isLoading,
        data: users,
        refetch,
    } = useList<User>({
        get: getData,
    });

    return {
        users,
        isLoading,
        refetch,
    };
};

export default useOrganizationUsers;
