import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Question } from "components/Admin/Organizations/types/Module.type";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import {
    LicensedModuleQuestionDependencyConditionalGroup,
    QuestionDependencyQuestionAnswerCondition,
    QuestionDependencyQuestionAnswerOperator,
} from "models/Question";
import React from "react";
import useStyles from "Styles/Styles";
import { questionOperatorOptions } from "./OrganizationModuleQuestionDependency";

const styles = (theme: Theme) => {
    return createStyles({
        trashIcon: {
            cursor: "pointer",
            color: theme.palette.blue.main,
            "&:hover": {
                transform: "scale(1.03)",
            },
        },
        addButton: {
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "15px",
        },
        subDependencyContainer: {
            justifyContent: "space-between",
            alignItems: "flex-end",
            paddingTop: "8px",
            marginLeft: "16px",
            borderLeft: "4px solid " + theme.palette.blue.main,
            paddingLeft: "8px",
        },
        iconContainer: { display: "flex", justifyContent: "flex-end" },
    });
};

type Props = {
    conditionIndex: number;
    groupIndex?: number;
    index: number;
    value: Question;
    onChange: (key: keyof Question, val: any) => void;
    dependencyQuestions: Question[];
    isDefault?: boolean;
};

const OrganizationModuleQuestionSubDependency: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = ({
    conditionIndex,
    index,
    value,
    onChange,
    groupIndex,
    dependencyQuestions,
    isDefault,
}) => {
    const classes = useStyles(styles);

    const changeQuestion = (
        id: string,
        conditionIndex: number,
        groupIndex: number,
    ) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        previousDependencies.conditions[conditionIndex].conditions[
            groupIndex
        ].dependsOnQuestionId = id;
        previousDependencies.conditions[conditionIndex].conditions[
            groupIndex
        ].value = "";

        return previousDependencies;
    };

    const changeTag = (
        tagValue: string,
        conditionIndex: number,
        groupIndex: number,
    ) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        previousDependencies.conditions[conditionIndex].conditions[
            groupIndex
        ].value = tagValue;

        return previousDependencies;
    };

    const changeOperator = (
        operatorId: number,
        conditionIndex: number,
        groupIndex: number,
    ) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        previousDependencies.conditions[conditionIndex].conditions[
            groupIndex
        ].op = operatorId;

        return previousDependencies;
    };

    const questionDropdownDefault = dependencyQuestions?.find((q) => {
        const condGroup = value?.dependencies?.conditions[
            conditionIndex
        ] as LicensedModuleQuestionDependencyConditionalGroup;
        const cond = condGroup.conditions[
            groupIndex as number
        ] as QuestionDependencyQuestionAnswerCondition;
        return q.id === cond.dependsOnQuestionId;
    });

    const containsDropdownDefault = questionDropdownDefault?.tags?.find((q) => {
        const condGroup = value?.dependencies?.conditions[
            conditionIndex
        ] as LicensedModuleQuestionDependencyConditionalGroup;
        const cond = condGroup.conditions[
            groupIndex as number
        ] as QuestionDependencyQuestionAnswerCondition;
        return q.value === cond.value;
    });

    const removeSubDependency = (
        conditionIndex: number,
        groupIndex: number,
    ) => {
        const previousDependencies = JSON.parse(
            JSON.stringify(value.dependencies),
        );

        previousDependencies.conditions[conditionIndex].conditions.splice(
            groupIndex,
            1,
        );

        return previousDependencies;
    };

    const operator = (
        (
            value.dependencies?.conditions[
                conditionIndex
            ] as LicensedModuleQuestionDependencyConditionalGroup
        ).conditions[
            groupIndex as number
        ] as QuestionDependencyQuestionAnswerCondition
    ).op;

    return (
        <Grid
            container
            key={conditionIndex}
            className={classes.subDependencyContainer}
        >
            <Grid item xs={3}>
                <AcxSelectSingle
                    inputLabel="Question"
                    id={`questionDecisionType-${index}`}
                    valueField="id"
                    labelField="questionText"
                    options={dependencyQuestions.filter(
                        (_q) => _q.id !== value.id,
                    )}
                    defaultValue={questionDropdownDefault}
                    onChange={(val) => {
                        onChange(
                            "dependencies",
                            changeQuestion(
                                val.id ?? "",
                                conditionIndex,
                                groupIndex ?? -1,
                            ),
                        );
                    }}
                    required
                    isDisabled={isDefault}
                />
            </Grid>
            <Grid item xs={3}>
                <AcxSelectSingle
                    inputLabel="Operator"
                    id={`operatorList-${index}`}
                    valueField="id"
                    labelField="label"
                    options={questionOperatorOptions}
                    defaultValue={{
                        id: operator,
                        label: questionOperatorOptions[operator - 1]?.label,
                    }}
                    onChange={(val) => {
                        onChange(
                            "dependencies",
                            changeOperator(
                                val.id ?? 0,
                                conditionIndex,
                                groupIndex as number,
                            ),
                        );
                    }}
                    required
                    isDisabled={isDefault}
                />
            </Grid>
            <Grid item xs={3}>
                {operator ===
                    QuestionDependencyQuestionAnswerOperator.Contains && (
                    <AcxSelectSingle
                        inputLabel="Answer"
                        id={`containList-sub-${index}`}
                        valueField="id"
                        labelField="value"
                        options={questionDropdownDefault?.tags ?? []}
                        defaultValue={containsDropdownDefault}
                        onChange={(val) => {
                            onChange(
                                "dependencies",
                                changeTag(
                                    val.value ?? "",
                                    conditionIndex,
                                    groupIndex as number,
                                ),
                            );
                        }}
                        required
                        isDisabled={isDefault}
                    />
                )}
            </Grid>
            <Grid item xs={1} className={classes.iconContainer}>
                {!isDefault && (
                    <DeleteIcon
                        onClick={() =>
                            onChange(
                                "dependencies",
                                removeSubDependency(
                                    conditionIndex,
                                    groupIndex as number,
                                ),
                            )
                        }
                        className={classes.trashIcon}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default OrganizationModuleQuestionSubDependency;
