import AudioMetadataUiModel from "models/AudioMetadataUiModel";
import BlobItemModel from "models/BlobItemModel";
import DirectoryInfoModel from "models/DirectoryInfoModel";
import moment from "moment";
import AuthUtils from "utils/AccessTokens";
import { fetch_retry } from "utils/FetchRetry";
import BaseService from "./BaseService";

export class BlobDirectoryService extends BaseService {
    protected urlBase: string = "api/Storage/";

    // https://localhost:5001/api/AudioFiles/ExpandDirectory?OrgId=9bcbba4f-4d04-4ed2-829f-21dfb5785c47&FolderPath=Customer Service - 137/
    getDirsAndStats = async (
        orgId: string,
        prefix: string,
        account: string,
        containerName: string,
        startDate: moment.Moment,
        endDate: moment.Moment,
    ) => {
        let params = {
            OrgId: orgId,
            FolderPath: prefix,
            Account: account,
            StartDate: startDate.format(),
            EndDate: endDate.format(),
            ContainerName: containerName,
        };
        const urlParams = new URLSearchParams(Object.entries(params));
        const d = await this.get(urlParams, `api/AudioFiles/ExpandDirectory`);
        return d as DirectoryInfoModel[];
    };

    getSampleFiles = async (
        orgId: string,
        directoryId: string,
        startDate: moment.Moment,
        endDate: moment.Moment,
        sampleSize: string,
    ) => {
        let params = {
            OrgId: orgId,
            directoryId,
            StartDate: startDate.format(),
            EndDate: endDate.format(),
            SampleSize: sampleSize,
        };
        const urlParams = new URLSearchParams(Object.entries(params));
        const d = await this.get(urlParams, `api/AudioFiles/GetSampleFiles`);
        return d as AudioMetadataUiModel[];
    };
    getFilesAndDirs = async (orgId: string, prefix: string) => {
        let params = { orgid: orgId, prefix: prefix };
        const urlParams = new URLSearchParams(Object.entries(params));
        const d = await this.get(
            urlParams,
            `${this.urlBase}GetFilesAndDirectories`,
        );
        return d as BlobItemModel[];
    };

    getDirs = async (orgId: string) => {
        let params = { orgid: orgId };
        const urlParams = new URLSearchParams(Object.entries(params));
        const d = await this.get(urlParams, `${this.urlBase}GetDirectories`);
        return d;
    };

    transferFiles = async (
        orgId: string,
        batch: string,
        timestamp: string = "",
        agentShortName: string,
        callDirection: string,
        fileNames: { SourceFolder: string; FileName: string },
    ) => {
        const headers = await AuthUtils.getHeaderAsync();
        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(fileNames),
        };

        let params = {
            orgid: orgId,
            InteractionAssignmentId: batch,
            timestamp: timestamp,
            agentShortName: agentShortName,
            callDirection: callDirection,
        };
        const urlParams = new URLSearchParams(Object.entries(params));
        const response = await fetch_retry(
            `api/AudioFiles/UploadFromStageWithMeta?${urlParams}`,
            options,
            2,
        );

        const data = await response;
        console.log(data);
        return data;
    };
}
