import { Divider, Grid, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "../../../../utils/useStore";
import { WorkflowReviewStore } from "components/WorkflowReview/Stores/WorkflowReviewStore";
import { EvalLicensedModule } from "components/Evaluation/Stores/EvalStore";

interface WFRModuleCardFooterProps {
    module?: EvalLicensedModule;
    hideScore?: boolean;
}

const WFRModuleCardFooter: React.FC<WFRModuleCardFooterProps> = observer(
    (props) => {
        const store = useStore(WorkflowReviewStore);
        const authStore = useStore(AuthStore);
        const theme = useTheme();

        let headerContent: React.ReactNode = null;

        const moduleScore = store.moduleScores
            ?.find((module) => module?.id === props.module?.id)
            ?.score.toFixed(2);

        if (
            !props.hideScore &&
            moduleScore !== null &&
            moduleScore !== undefined &&
            authStore.canUserView("Module Scores")
        ) {
            headerContent = (
                <Typography variant={"h5"}>
                    Overall {props.module?.name} Score:{" "}
                    <span style={{ fontWeight: "normal" }}>{moduleScore}%</span>
                </Typography>
            );
        }

        return (
            <>
                {Boolean(headerContent) && (
                    <>
                        <Divider variant={"middle"} />
                        <Grid
                            item
                            xs={12}
                            direction={"row"}
                            wrap={"nowrap"}
                            container
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            sx={{
                                paddingLeft: theme.spacing(3),
                                paddingRight: theme.spacing(3),
                                marginTop: theme.spacing(3),
                                marginBottom: theme.spacing(3),
                            }}
                        >
                            <Grid item xs={4}>
                                {headerContent}
                            </Grid>
                            <Grid
                                item
                                xs={8}
                                container
                                justifyContent={"flex-start"}
                                alignItems={"baseline"}
                                wrap={"wrap"}
                            ></Grid>
                        </Grid>
                    </>
                )}
            </>
        );
    },
);

export default WFRModuleCardFooter;
