import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import React from "react";

export function AIExplanationNoHighlights() {
    return (
        <Grid
            container
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            direction="column"
            style={{ padding: "20px" }}
        >
            <Typography
                style={{
                    color: "#4B5563",
                    fontSize: "16px",
                    fontWeight: 600,
                    textAlign: "center",
                }}
            >
                No highlights available
            </Typography>
            <Typography
                style={{
                    color: "#4B5563",
                    fontSize: "12px",
                    textAlign: "center",
                }}
            >
                Try selecting a different question to view highlights or browse
                the transcript below.
            </Typography>
        </Grid>
    );
}
