import { action, makeObservable, observable } from "mobx";
import { copyBaseFields } from "../utils/BaseEntityUtils";
import { Answer } from "./Answer";
import BaseEntity from "./BaseEntity";
import { Tag } from "./Tag";

export class AnswerTag extends BaseEntity {
    @observable answerId?: string;
    @observable answer?: Answer;

    @observable tagId: string;
    @observable tag?: Tag;

    constructor(
        answerId: string,
        tagId: string,
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
    ) {
        super(id, createdBy, modifiedBy);
        makeObservable(this);
        this.tagId = tagId;
        this.answerId = answerId ?? undefined;
    }

    @action
    setAnswer(answer?: Answer) {
        this.answer = answer;
    }

    @action
    setTag(tag?: Tag) {
        this.tag = tag;
    }

    static fromJson(answerTagJson: AnswerTag) {
        const res = new AnswerTag(
            answerTagJson.answerId as string,
            answerTagJson.tagId,
        );
        copyBaseFields(answerTagJson, res);

        if (answerTagJson.tag) {
            res.tag = Tag.fromJson(answerTagJson.tag);
        }
        return res;
    }
}
