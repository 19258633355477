import React, { FunctionComponent } from "react";
import theme from "../../Theme/AppTheme";
import hexToRGB from "../../utils/hexToRGB";
import NotificationMenuIcon from "./NotificationMenuIcon";

interface OwnProps {
    drawerAnchor?: "left" | "right" | "top" | "bottom";
    drawerVariant?: "permanent" | "persistent" | "temporary";
    drawerOffsetPx?: number;
    drawerOffsetPosition?: "Left" | "Top" | "Right" | "Bottom";
}

type Props = OwnProps;

const FocusPageNotificationMenuIcon: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <NotificationMenuIcon
            inactiveButtonBackground={theme.palette.white.main}
            loaderColor={hexToRGB("#2979ff", 0.65)}
            inactiveIconColor={hexToRGB(theme.palette.gray.main, 0.25)}
            activeButtonBackground={theme.palette.white.main}
            activeIconColor={hexToRGB("#2979ff", 0.65)}
            {...props}
        />
    );
};

export default FocusPageNotificationMenuIcon;
