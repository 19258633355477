import { observable, makeObservable } from "mobx";
import BaseEntity from "models/BaseEntity";

export class ReportGroup extends BaseEntity {
    @observable organizationId?: string;
    @observable groupName: string;
    @observable accessLevel: AccessLevel;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }
}
export enum AccessLevel {
    Organization,
    Role,
    User,
}
