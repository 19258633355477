import { styled } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import AgentStore from "./Stores/AgentStore";

const AgentToggleButton = styled(AcxButton)(() => ({
    background:
        "linear-gradient(131deg, #32D1B7 3.43%, #349AC7 70.89%, #3664D6 100.5%)",
    padding: 0,
}));

const AgentChatMenuIcon = observer(() => {
    const agentStore = useStore(AgentStore);
    const authStore = useStore(AuthStore);

    if (!authStore.canUserView("View Ava")) return null;

    return (
        <div
            style={{
                position: "relative",
            }}
        >
            <AgentToggleButton
                fullWidth={false}
                onClick={() => agentStore.toggleDrawer()}
                style={{
                    width: "40px !important",
                    minWidth: "40px",
                    paddingInline: "6px",
                    paddingBlock: "8px",
                }}
                id="ava"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="52"
                    height="14"
                    viewBox="0 0 52 14"
                    fill="none"
                >
                    <g opacity="0.95">
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10.7734 1.67223L10.4942 1.19057C10.0667 0.544437 9.4375 0.000286762 8.37637 0.000286762C7.50783 -0.013088 6.69991 0.442228 6.2643 1.19057L5.985 1.67223H10.7734ZM4.19792 4.75398L5.16805 3.08103H6.44316C6.90514 3.08103 7.27964 3.45553 7.27964 3.9175C7.27964 4.37947 6.90514 4.75398 6.44316 4.75398H4.19792ZM2.41085 7.83573L3.38097 6.16278H8.82051C9.28249 6.16278 9.65699 6.53728 9.65699 6.99925C9.65699 7.46122 9.28249 7.83573 8.82051 7.83573H2.41085ZM0.688965 10.9175C0.741565 10.7481 0.814207 10.5847 0.905882 10.431L1.5939 9.24453H11.6381C12.1001 9.24453 12.4746 9.61903 12.4746 10.081C12.4746 10.543 12.1001 10.9175 11.6381 10.9175H0.688965ZM2.91169 13.9992C1.77619 13.9723 1.00639 13.1963 0.716335 12.3263H6.70731C7.16929 12.3263 7.54379 12.7008 7.54379 13.1628C7.54379 13.6247 7.16929 13.9992 6.70731 13.9992H2.91169ZM16.0391 12.3263C15.7293 13.2019 14.9029 13.9724 13.8446 13.9992H9.78906C9.32709 13.9992 8.95259 13.6247 8.95259 13.1628C8.95259 12.7008 9.32709 12.3263 9.78906 12.3263H16.0391ZM15.164 9.24453L15.852 10.431C15.9481 10.5837 16.0235 10.7473 16.0769 10.9175H14.7199C14.2579 10.9175 13.8834 10.543 13.8834 10.081C13.8834 9.61903 14.2579 9.24453 14.7199 9.24453H15.164ZM13.3772 6.16278L14.3472 7.83573H11.9023C11.4403 7.83573 11.0658 7.46122 11.0658 6.99925C11.0658 6.53728 11.4403 6.16278 11.9023 6.16278H13.3772ZM11.5903 3.08103L12.5603 4.75398H9.52491C9.06294 4.75398 8.68844 4.37947 8.68844 3.9175C8.68844 3.45553 9.06294 3.08103 9.52491 3.08103H11.5903Z"
                            fill="white"
                        />
                        <path
                            d="M46.5106 13.9994C45.8003 13.9994 45.2104 13.879 44.7409 13.6382C44.2835 13.3975 43.9464 13.0724 43.7297 12.6631C43.513 12.2538 43.4047 11.8024 43.4047 11.3088C43.4047 10.755 43.5431 10.2675 43.82 9.84611C44.1089 9.42477 44.5423 9.09973 45.1201 8.871C45.698 8.63023 46.4263 8.50984 47.3051 8.50984H49.5623C49.5623 8.06442 49.4961 7.69724 49.3637 7.40832C49.2433 7.1194 49.0507 6.9027 48.7859 6.75824C48.5331 6.61378 48.208 6.54155 47.8107 6.54155C47.3412 6.54155 46.938 6.6499 46.6009 6.86659C46.2758 7.07124 46.0712 7.39628 45.9869 7.84171H43.6214C43.6936 7.17959 43.9103 6.60776 44.2714 6.12622C44.6446 5.64468 45.1322 5.27149 45.7341 5.00664C46.3481 4.7418 47.0403 4.60938 47.8107 4.60938C48.6775 4.60938 49.4239 4.75384 50.0499 5.04276C50.6759 5.31964 51.1574 5.73497 51.4945 6.28874C51.8316 6.83047 52.0001 7.49259 52.0001 8.27509V13.7827H49.9777L49.7249 12.3923H49.6526C49.46 12.6571 49.2493 12.8918 49.0206 13.0965C48.8039 13.3012 48.5632 13.4697 48.2983 13.6021C48.0335 13.7345 47.7506 13.8308 47.4496 13.891C47.1486 13.9633 46.8356 13.9994 46.5106 13.9994ZM47.3051 12.1214C47.6422 12.1214 47.9311 12.0672 48.1719 11.9589C48.4247 11.8385 48.6414 11.682 48.822 11.4894C49.0026 11.2968 49.141 11.074 49.2373 10.8212C49.3457 10.5564 49.4179 10.2855 49.454 10.0086H47.576C47.2028 10.0086 46.8958 10.0568 46.6551 10.1531C46.4263 10.2374 46.2578 10.3638 46.1494 10.5323C46.0411 10.6888 45.9869 10.8754 45.9869 11.0921C45.9869 11.3088 46.0411 11.4954 46.1494 11.6519C46.2578 11.8084 46.4143 11.9288 46.6189 12.013C46.8236 12.0853 47.0523 12.1214 47.3051 12.1214Z"
                            fill="white"
                        />
                        <path
                            d="M36.2136 13.7848L33.3244 4.82812H35.8886L38.0014 12.1776H38.0916L40.2225 4.82812H42.7686L39.8794 13.7848H36.2136Z"
                            fill="white"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23.5533 8.23027L21.7917 13.7849H24.3378L25.4483 10.1553H30.1126L31.1998 13.7849H33.782L29.7913 1.14453H25.8005L24.0002 6.82109H26.4685L27.7688 2.57109H27.841L29.5447 8.25927H26.0284L26.0373 8.23027H23.5533Z"
                            fill="white"
                        />
                    </g>
                </svg>
            </AgentToggleButton>
        </div>
    );
});

export default AgentChatMenuIcon;
