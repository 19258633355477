import { Badge, Link, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import {
    IItemOptions,
    INavigation,
} from "Layouts/SideNavigation/NavigationStore";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// import useStyles from "Styles/Styles";
import { ThemeColors } from "Theme/AppTheme";
import hexToRGB from "utils/hexToRGB";

const useStyles = makeStyles((theme: Theme) => ({
    itemNavigationBody: {
        // marginLeft: "20px"
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(1.75),
    },
    itemNavigationRoot: {
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
    },
    itemLinkContainer: (props) => ({
        paddingLeft: "6px",
        marginLeft: "8px",
        paddingRight: "6px",
        fontSize: props.val.signalsVariant ? "12px" : "16px",
        minHeight: props.val.removeMinHeight ? "0" : "24px",
        display: props.val.signalsVariant ? "flex" : "block",
        alignItems: props.val.signalsVariant ? "center" : undefined,
    }),
    curItemContainer: {
        borderRadius: "5px",
        backgroundColor: hexToRGB(ThemeColors.blue, 0.1),
    },
    itemContainer: (props: Props) => ({
        width: "100%",
        paddingLeft: "6px",
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        minHeight: "32px",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }),
    denseItemContainer: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    spreadItemContainer: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    curItemLink: (props: Props) => ({
        color: props.val.signalsVariant ? ThemeColors.gray : ThemeColors.blue,
        fontWeight: "bold",
        textAlign: "left",
    }),
    itemLink: {
        color: hexToRGB(ThemeColors.black, 0.5),
    },
    iconContainer: {
        opacity: 0.5,
    },
}));

type Props = {
    val: INavigation;
    itemOptions?: IItemOptions;
    currentNavItem?: string;
    replaceLinks: {
        [key: string]: string;
    };
};

const SideNavigationLink: React.FC<Props> = observer((props: Props) => {
    const { val, itemOptions, currentNavItem, replaceLinks } = props;
    const classes = useStyles(props);
    const [link, setLink] = useState<string>("");

    useEffect(() => {
        setLink(
            Object.keys(replaceLinks).reduce((str, param) => {
                str = str.replace(`:${param}`, replaceLinks[param]);
                return str;
            }, val.link),
        );
    }, [replaceLinks, val]);

    return (
        <div
            key={`config-links-${val.title}`}
            className={clsx(
                currentNavItem === link && classes.curItemContainer,
                classes.itemContainer,
                itemOptions?.itemSpacing === "spread" &&
                    classes.spreadItemContainer,
                itemOptions?.itemSpacing === "dense" &&
                    classes.denseItemContainer,
            )}
        >
            {val.badgeContent || val.icon || val.selectedIcon ? (
                <div
                    style={{
                        float: "left",
                        height: "24px",
                        width: "24px",
                        display: "flex",
                        alignItems: "center",
                    }}
                    className={clsx(
                        currentNavItem !== link && classes.iconContainer,
                    )}
                >
                    {val.badgeContent ? (
                        <Badge
                            max={9999}
                            color="primary"
                            variant="dot"
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            badgeContent={val.badgeContent}
                            {...val.badgeOptions}
                        >
                            <>
                                {currentNavItem === link && val.selectedIcon
                                    ? val.selectedIcon
                                    : val.icon}
                            </>
                        </Badge>
                    ) : currentNavItem === link && val.selectedIcon ? (
                        val.selectedIcon
                    ) : (
                        val.icon
                    )}
                </div>
            ) : (
                // spacer for items without icon alignment
                <div
                    style={{
                        float: "left",
                        height: "24px",
                        width: "4px",
                    }}
                ></div>
            )}
            <div
                key={`link-container-${val.title}`}
                className={clsx(classes.itemLinkContainer)}
            >
                {val.enabled === false ? (
                    <Link
                        component="button"
                        disabled
                        color="textPrimary"
                        underline="none"
                        style={{
                            textAlign: "left",
                            textDecoration: "none",
                            cursor: "auto",
                            opacity: "50%",
                            // Need this extra control due to signals nav being a combination of old and new nav due to legacy reports code
                            fontSize: props.val.signalsVariant
                                ? "12px"
                                : itemOptions?.linkFontSize ?? "16px",
                        }}
                        variant="body1"
                        key={`navigation-item-${val.title}`}
                        className={clsx(
                            currentNavItem !== link && classes.itemLink,

                            currentNavItem === link && classes.curItemLink,
                        )}
                    >
                        {val.title}
                    </Link>
                ) : (
                    <Link
                        component={RouterLink}
                        to={link}
                        color="textPrimary"
                        underline="none"
                        style={{
                            textAlign: "left",
                            // Need this extra control due to signals nav being a combination of old and new nav due to legacy reports code
                            fontSize: props.val.signalsVariant
                                ? "12px"
                                : itemOptions?.linkFontSize ?? "16px",
                        }}
                        variant="body1"
                        key={`navigation-item-${val.title}`}
                        className={clsx(
                            currentNavItem !== link && classes.itemLink,

                            currentNavItem === link && classes.curItemLink,
                        )}
                    >
                        {val.title}
                    </Link>
                )}
            </div>
        </div>
    );
});

export default SideNavigationLink;
