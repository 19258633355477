import { Tooltip, Typography } from "@mui/material";
import AcxMenu, {
    AcxMenuItemProps,
    StyledMenuLabel,
} from "components/UI/Menu/AcxMenu";
import { observer } from "mobx-react";
import React from "react";
import SoundClipEditorStore from "../Stores/SoundClipEditorStore";

interface IPlaybackRate {
    viewModel?: SoundClipEditorStore;
    handleVideoPlaybackRate?: (rate: number) => void;
    videoPlaybackRate?: number;
}

const PlaybackRate: React.FC<IPlaybackRate> = observer(
    ({ viewModel, handleVideoPlaybackRate, videoPlaybackRate }) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
            null,
        );

        const playbackMenuItems: AcxMenuItemProps[] = [
            {
                id: "one-point-zero",
                label: <StyledMenuLabel>1.0x</StyledMenuLabel>,
                props: {
                    onClick: (e) => handlePlaybackSelection(e),
                    style: { minWidth: "50px" },
                },
                toolTipText: "Normal playback speed.",
            },
            {
                id: "one-point-two",
                label: <StyledMenuLabel>1.2x</StyledMenuLabel>,
                props: {
                    onClick: (e) => handlePlaybackSelection(e),
                    style: { minWidth: "50px" },
                },
                toolTipText: "Increase playback speed by 20%.",
            },
            {
                id: "one-point-five",
                label: <StyledMenuLabel>1.5x</StyledMenuLabel>,
                props: {
                    onClick: (e) => handlePlaybackSelection(e),
                    style: { minWidth: "50px" },
                },
                toolTipText: "Increase playback speed by 50%.",
            },
            {
                id: "two-point-zero",
                label: <StyledMenuLabel>2.0x</StyledMenuLabel>,
                props: {
                    onClick: (e) => handlePlaybackSelection(e),
                    style: { minWidth: "50px" },
                },
                toolTipText: "Double playback speed.",
            },
        ];

        const handlePlaybackMenuClick = (
            event: React.MouseEvent<HTMLButtonElement>,
        ) => {
            setAnchorEl(event.currentTarget);
        };

        const handlePlaybackMenuClose = () => {
            setAnchorEl(null);
        };

        const handlePlaybackSelection = (event) => {
            const rate = parseFloat(event.target.innerText.slice(0, -1));

            if (rate) {
                if (viewModel) {
                    viewModel.handlePlaybackRate(rate);
                }
                if (handleVideoPlaybackRate) {
                    handleVideoPlaybackRate(rate);
                }
            }

            handlePlaybackMenuClose();
        };

        return (
            <>
                <AcxMenu
                    menuItems={playbackMenuItems}
                    anchorElement={anchorEl}
                    onMenuClose={handlePlaybackMenuClose}
                />
                <Tooltip title="Set Playback Rate">
                    <Typography
                        sx={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#3564D5",
                            fontWeight: 700,
                            fontSize: "14px",
                        }}
                        onClick={handlePlaybackMenuClick}
                    >
                        {viewModel?.playbackRate ?? videoPlaybackRate}x
                    </Typography>
                </Tooltip>
            </>
        );
    },
);

export default PlaybackRate;
