import { Alert, Stack } from "@mui/material";
import { observer } from "mobx-react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import theme from "Theme/AppTheme";
import React from "react";
import { parseFromISO, standardDateFormat } from "utils/DateTimeUtils";

interface WFRResultBannerProps {
    result: string | null;
    completedBy: string;
    verifiedDate?: string;
}

function convertToLocalTimeZone(date: string) {
    return new Date(
        date.indexOf("Z") !== -1
            ? date.replace("Z", "+00:00")
            : date + "+00:00",
    )
        .toLocaleString(Intl.DateTimeFormat().resolvedOptions().locale, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        .toString();
}

const WFRResultBanner: React.FC<WFRResultBannerProps> = observer(
    ({ result, completedBy, verifiedDate }) => {
        return (
            <Alert
                severity="success"
                sx={{
                    borderRadius: theme.spacing(0.25),
                    color: theme.palette.success[700],
                    backgroundColor: theme.palette.success[100],
                    display: "inline-block",
                    padding: theme.spacing(0, 1, 0, 1),
                }}
                icon={false}
            >
                <Stack direction="row" alignItems="center" gap={1}>
                    <CheckCircleIcon />
                    {`Workflow ${
                        result === "complete"
                            ? "completed"
                            : result === "approve"
                            ? "advanced"
                            : "ended"
                    } by ${completedBy}${
                        verifiedDate
                            ? ` on ${standardDateFormat(
                                  parseFromISO(
                                      convertToLocalTimeZone(verifiedDate),
                                      true,
                                  ),
                              )}`
                            : "."
                    }`}
                </Stack>
            </Alert>
        );
    },
);

export default WFRResultBanner;
