import { Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { CustomControlItem } from "components/UI/AcxDataGrid/AcxDataGridStore";
import { hierarchyColumnType } from "components/UI/AcxDataGrid/ColumnTypes/AcxGridColTypes";
import {
    dateTimeColumnType,
    interactionDateColumnType,
} from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import { durationColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DurationColType";
import { interactionTypeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/InteractionTypeColType";
import { statusColumnType } from "components/UI/AcxDataGrid/ColumnTypes/StatusColType";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React, { FunctionComponent } from "react";
import { AuthStore } from "stores/AuthStore";
import { evaluationNameFromBlobFileKey } from "utils/Utils";
import { useStore } from "../../../../utils/useStore";
import AcxDateRangeInput from "../../../UI/Calendar/DateRange/AcxDateRangeInput";
import {
    AnalystDashboardStore,
    REFRESH,
} from "../Stores/AnalystDashboardStore";
import { get } from "lodash";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { Routes } from "components/Navigation/Routes";

interface OwnProps {
    goToEvalPage: (evalId: string) => void;
}

type Props = OwnProps;

const AnalystEvaluationTable: FunctionComponent<Props> = observer((props) => {
    const store = useStore(AnalystDashboardStore);
    const orgStore = useStore(AuthStore).orgStore;

    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectorStore.selectOrg(orgStore.selectedOrganization);
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    React.useEffect(() => {
        const cols: IColDef[] = [
            {
                headerName: "Evaluation Number",
                field: "evaluationQbId",
                type: "string",
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const orgId = orgStore.selectedOrganization?.id;
                    const evalId = params.row.id;

                    return (
                        <Link
                            to={Routes.makeEvaluationRoute(
                                orgId ?? "",
                                evalId ?? "",
                            )}
                        >
                            {params.row.evaluationQbId}
                        </Link>
                    );
                },
            },
            {
                headerName: "Interaction",
                field: "interaction.audioMetadata.blobFileKey",
                cellClassName: "pendo-ignore",
                valueFormatter(_, row, column) {
                    const value = get(row, column.field);
                    let evalName = evaluationNameFromBlobFileKey(
                        value as string | undefined,
                    );
                    if (evalName === "") evalName = value?.toString() ?? "";
                    return evalName;
                },
                sortable: false,
            },
            {
                headerName: "Agent",
                field: "agentName",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Interaction Date",
                field: "interaction.interactionDate",
                flex: 1,
                type: "date",
                ...interactionDateColumnType,
            },
            {
                headerName: "Duration",
                field: "interaction.audioMetadata.callDurationMillis",
                flex: 1,
                ...durationColumnType,
            },
            {
                headerName: "Service Hierarchy",
                field: "hierarchy",
                width: 200,
                ...hierarchyColumnType,
            },
            {
                headerName: "Type",
                field: "interaction.interactionType",
                flex: 1,
                ...interactionTypeColumnType,
            },
            {
                headerName: "Status",
                field: "evaluationStatus",
                flex: 1,
                ...statusColumnType,
            },
            {
                headerName: "Modified On",
                field: "modifiedOn",
                flex: 1,
                ...dateTimeColumnType,
            },
            {
                headerName: "Submitted On",
                field: "submittedDate",
                flex: 1,
                ...dateTimeColumnType,
            },
        ];

        store.dgStore.setColumns(cols);

        const dateRangePicker = (
            <Observer>
                {() => (
                    <Grid item>
                        <AcxDateRangeInput
                            labelText={"Select Submitted On Date Range"}
                            defaultStartDate={store.datePickerStore.beginDate}
                            defaultEndDate={store.datePickerStore.endDate}
                            onSelect={(start: Moment, end: Moment) => {
                                store.datePickerStore.setBeginDate(start);
                                store.datePickerStore.setEndDate(end);
                            }}
                        />
                    </Grid>
                )}
            </Observer>
        );

        const refreshData = (
            <Observer>
                {() => (
                    <AcxButton
                        tooltip="Refresh data with applied filters."
                        color="primary"
                        onClick={store.refreshTable}
                        style={{ height: "32px" }}
                        loading={store.getTaskLoading(REFRESH)}
                        disabled={store.getTaskLoading(REFRESH)}
                    >
                        <RefreshIcon />
                    </AcxButton>
                )}
            </Observer>
        );

        const controls: CustomControlItem[] = [
            {
                controlElement: dateRangePicker,
            },
            {
                controlElement: refreshData,
            },
        ];

        store.dgStore.controls = controls;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.dgStore]);

    return <AcxDataGrid dataGridStore={store.dgStore} />;
});

export default AnalystEvaluationTable;
