import {useCallback} from "react";
import ModuleService from "../Module.service";
import useList from "../../../../../../shared/hooks/useList";
import {QuestionCategory} from "../../../types/Module.type";

export const useQuestionCategories = () => {
    const getData = useCallback(async () => {
        return ModuleService.getQuestionCategories();
    }, []);

    const {
        isLoading,
        data: questionCategories,
        refetch,
    } = useList<QuestionCategory>({
        get: getData,
    });

    return {
        questionCategories,
        isLoading,
        refetch,
    };
};