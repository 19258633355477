import { TableCell, TableRow, Typography } from "@mui/material";
import { Observer } from "mobx-react";
import React from "react";
import { IAcxTableColumn } from "./AcxTable";

function AcxTableEmptyRow(props: {
    columns: IAcxTableColumn[];
    enableCheck: boolean;
    rowClass: string;
}) {
    const checkCount = () => {
        if (props.enableCheck) {
            return 1;
        }
        return 0;
    };

    return (
        <Observer>
            {() => (
                <TableRow key={"table_row_empty"} className={props.rowClass}>
                    <TableCell colSpan={props.columns.length + checkCount()}>
                        <Typography align="center" variant="h5">
                            Records are not available for your selection
                        </Typography>
                    </TableCell>
                </TableRow>
            )}
        </Observer>
    );
}

AcxTableEmptyRow.defaultProps = {
    enableCheck: false,
};
export default AcxTableEmptyRow;
