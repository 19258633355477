import {
    Box,
    Collapse,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import EddyBlurbs from "components/UI/Eddy/EddyBlurbs";
import React, { useState } from "react";
import { useStyles } from "./styles";
import { observer } from "mobx-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface EddySectionProps {
    store: ConversationsStore;
}

const EddySection: React.FC<EddySectionProps> = observer(({ store }) => {
    const canCollapse =
        store.selectedConversation?.hasEddyEffect.containsExamples &&
        store.selectedConversation?.hasAdverseEvent.containsExamples;
    const classes = useStyles({ canCollapse });
    const [open, setOpen] = useState(false);

    return (
        <Box className={classes.eddyContainer}>
            <Box className={classes.width85}>
                {canCollapse && (
                    <Tooltip
                        title={`${
                            open ? "Collapse" : "Expand"
                        } Eddy Effect Highlights`}
                    >
                        <IconButton
                            onClick={() => {
                                setOpen(!open);
                            }}
                            size="small"
                            edge="start"
                            className={classes.chevronButton}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    </Tooltip>
                )}
                <Typography
                    className={classes.summaryTitle}
                    component="span"
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    {!store.selectedConversation?.hasEddyEffect.containsExamples
                        ? "Eddy Signals: "
                        : "Eddy Signals"}
                </Typography>
                {!store.selectedConversation?.hasEddyEffect
                    .containsExamples && (
                    <Typography component="span">
                        No specific examples identified in transcript
                    </Typography>
                )}
                <Typography className={classes.eddy}>
                    {store.selectedConversation?.hasEddyEffect
                        .containsExamples && (
                        <>
                            <Typography
                                className={classes.eddieHighlight}
                                component="span"
                            >
                                {store.selectedConversation?.eddyData.length > 1
                                    ? `${store.selectedConversation?.eddyData.length} Eddy Signals `
                                    : "1 Eddy Signal "}
                            </Typography>
                            found in this conversation
                        </>
                    )}
                </Typography>
            </Box>
            <Collapse in={canCollapse ? open : true}>
                {store.selectedConversation?.isChat ? (
                    <EddyBlurbs
                        data={store.selectedConversation?.eddyData ?? []}
                        type="chat"
                        chat={store.selectedConversation.chatTranscription}
                        speakerChannelMap={store.speakerStore.speakerChannelMap}
                    />
                ) : (
                    <EddyBlurbs
                        data={store.selectedConversation?.eddyData ?? []}
                        type="audio"
                        transcription={
                            store.selectedConversation?.audioTranscription ?? []
                        }
                        speakerChannelMap={store.speakerStore.speakerChannelMap}
                    />
                )}
            </Collapse>
        </Box>
    );
});

export default EddySection;
