import React, { FunctionComponent } from "react";
import AcxScrollingTabs from "components/UI/SubTabs/AcxScrollingTabs";
import { observer } from "mobx-react";
import { useStore } from "../../../utils/useStore";
import { EvalStore, ModuleName } from "../Stores/EvalStore";

interface OwnProps {
    moduleDisplayNames?: ModuleName[];
}

type Props = OwnProps;

const ModuleNavList: FunctionComponent<Props> = observer((props) => {
    const evalStore = useStore(EvalStore);

    return (
        <>
            {props.moduleDisplayNames && (
                <AcxScrollingTabs
                    activeTab={evalStore.currentModule?.name}
                    tabs={props.moduleDisplayNames}
                    onClick={evalStore.setCurrentModule}
                />
            )}
        </>
    );
});

export default ModuleNavList;
