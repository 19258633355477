import React from "react";
// import { Route, Switch, useRouteMatch } from "react-router";
import OrganizationRolesList from "./OrganizationRolesList";

type Props = {
    id: string;
};

const OrganizationRoles: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ id }) => {
    // const { path } = useRouteMatch();

    return (
        // <Switch>
        // <Route path={path} exact>
        // </Route>
        // </Switch>
        (<OrganizationRolesList orgId={id} />)
    );
};

export default OrganizationRoles;
