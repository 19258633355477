export enum SafetyEvent {
    Identified,
    NotIdentified,
    Acknowledged,
    NotAcknowledged,
}

export namespace SafetyEvent {
    export function toString(safetyEvent: SafetyEvent): string {
        switch (safetyEvent) {
            case SafetyEvent.Identified:
                return "Identified";
            case SafetyEvent.NotIdentified:
                return "Not Identified";
            case SafetyEvent.Acknowledged:
                return "Acknowledged";
            case SafetyEvent.NotAcknowledged:
                return "Not Acknowledged";
        }
    }
}
