import theme from "Theme/AppTheme";
import { ChatPersona } from "components/UI/Chat/AcxChatViewer";

export type Speaker = number | ChatPersona | "Agent" | "Customer";

export const getSpeakerColorByChannelOrPersona = (channel: Speaker = 0) => {
    const channelColor = [
        theme.palette.primary.main,
        theme.palette.secondary[800],
        theme.palette.primary[800],
        theme.palette.secondary.main,
        theme.palette.primary[300],
    ];
    switch (channel) {
        case ChatPersona.Agent:
            return theme.palette.secondary[800];
        case "Agent":
            return theme.palette.secondary[800];
        case ChatPersona.Customer:
            return theme.palette.primary.main;
        case "Customer":
            return theme.palette.primary.main;
        default:
            return (
                channelColor[channel % channelColor.length] ??
                theme.palette.primary.main
            );
    }
};
