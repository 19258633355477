import { styled } from "@mui/material";
import { MessageBody } from "./MessageBody";

export const AgentMessage = styled(MessageBody)(({ theme }) => ({
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.gray[600],
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "0px",
}));
