import { computed, makeObservable, observable } from "mobx";
import { ThreadMessageGroup } from "./ThreadMessageGroup";
import { MessageAuthor } from "./MessageAuthor";
import { AcxAgentService } from "services/AcxAgentService";

export class Thread {
    /**
     * id of the thread as returned by the start-stream endpoint.
     * this coincides with the id in the database.
     */
    id: string;
    @observable messageGroups: ThreadMessageGroup[];

    readonly service: AcxAgentService;

    constructor() {
        makeObservable(this);

        this.service = new AcxAgentService();
        this.messageGroups = [];
    }

    private addMessageGroup(author: MessageAuthor) {
        const messageGroup = new ThreadMessageGroup(this, author);
        this.messageGroups.push(messageGroup);
        return messageGroup;
    }

    private getRecentMessageGroup(author: MessageAuthor) {
        for (let i = this.messageGroups.length - 1; i >= 0; i--)
            if (this.messageGroups[i].author === author)
                return this.messageGroups[i];
    }

    @computed get hasUserMessaged() {
        return !!this.id && this.messageGroups.length > 1;
    }

    @computed get recentMessageGroup() {
        return this.messageGroups.at(-1);
    }

    @computed get recentAgentMessageGroup() {
        return this.getRecentMessageGroup(MessageAuthor.Agent);
    }

    @computed get recentUserMessageGroup() {
        return this.getRecentMessageGroup(MessageAuthor.User);
    }

    public addAgentMessageGroup() {
        return this.addMessageGroup(MessageAuthor.Agent);
    }

    public addUserMessageGroup() {
        return this.addMessageGroup(MessageAuthor.User);
    }
}
