import { Grid } from "@mui/material";
import SmartPredictSvg from "SvgIcons/SmartPredictSvg";
import CloseIcon from "@mui/icons-material/Close";
import { observer } from "mobx-react";
import { Tag } from "models/Tag";
import React from "react";
import { Divider, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AIExplanationCard from "./AIExplanationCard";
import { useStore } from "utils/useStore";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";
import { PredictedTag } from "models/PredictedTag";
import { Answer } from "models/Answer";

const useStyles = makeStyles((theme: Theme) => ({
    outerContainer: {
        marginTop: "0.25rem",
        paddingInline: theme.spacing(3),
        maxWidth: "100%",
    },
    container: {
        backgroundColor: theme.palette.neutralGray.main,
        borderRadius: theme.shape.borderRadius,
        border: "1px solid #E5E7EB",
    },
    item: {
        padding: theme.spacing(1.25),
    },
    xIcon: {
        color: "#A1A1AA",
        cursor: "pointer",
    },
}));

interface AIExplanationCardsProps {
    activeTags: Tag[];
    predictedTags: PredictedTag[];
    answer: Answer;
    questionId: string;
}

const AIExplanationCards = observer((props: AIExplanationCardsProps) => {
    const classes = useStyles();
    const store = useStore(EvalStore);

    const tagIdToPredictedTags = React.useMemo(() => {
        const result: Record<string, PredictedTag[]> = {};
        for (const predictedTag of props.predictedTags) {
            if (!result[predictedTag.tagId])
                result[predictedTag.tagId] = [predictedTag];
            else result[predictedTag.tagId].push(predictedTag);
        }
        return result;
    }, [props.predictedTags]);

    return (
        <Grid className={classes.outerContainer}>
            <Grid container direction="column" className={classes.container}>
                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    className={classes.item}
                >
                    <SmartPredictSvg />
                    <CloseIcon
                        className={classes.xIcon}
                        fontSize={"small"}
                        onClick={() =>
                            store.setShowAIExplanation(props.questionId, false)
                        }
                    />
                </Grid>
                {props.activeTags.map((tag, index) => (
                    <React.Fragment key={tag.id}>
                        {index > 0 && <Divider />}
                        <Grid
                            item
                            style={{
                                maxWidth: "100%",
                            }}
                        >
                            <AIExplanationCard
                                activeTag={tag}
                                predictedTags={tagIdToPredictedTags[tag.id]}
                                answer={props.answer}
                            />
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
});

export default AIExplanationCards;
