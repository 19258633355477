import { observer } from "mobx-react";
import React from "react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import { startCase } from "lodash";
import { basicDateReferenceOptions } from "stores/ComponentStores/DatePickerComponentStore";
import theme from "Theme/AppTheme";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";

export const DateReferenceFilter = observer(function (
    props: IApplicationFilterComponent &
        Partial<React.ComponentPropsWithoutRef<typeof AcxSelectSingle>>,
) {
    return (
        <AcxSelectSingle
            id="application-filters-date-type"
            fullWidth
            defaultValue={{
                label: startCase(props.store.dateReferenceOption),
                value: props.store.dateReferenceOption,
            }}
            options={basicDateReferenceOptions.map((item) => ({
                label: startCase(item),
                value: item,
            }))}
            onChange={(item) => {
                props.onChange?.(item);
                props.store.setDateReferenceOption(item.value);
            }}
            placeholder="Date Type"
            labelField="label"
            valueField="value"
            alternateItemColor={theme.palette.gray.main}
        />
    );
});
