import WorkflowCondition from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import { toJS } from "mobx";
import BaseService from "./BaseService";
import EvaluationModule from "models/EvaluationModule";
import { Answer } from "models/Answer";
import {
    ClassifierWorkflowInfo,
    WorkflowReviewerInfo,
} from "components/WorkflowReview/types";
import ConversationModule from "models/ConversationModule";

interface WorkflowUpdatePayload {
    evaluationId?: string;
    workflowInstanceId: string;
    workflowInstanceLevel: WorkflowUpdateInstanceLevelPayload;
    answers: Answer[];
}

export interface WorkflowUpdateInstanceLevelPayload {
    workflowInstanceId: string;
    result: string | null;
    note: string;
    workflowMessagingMetadataId: string;
    userId?: string;
    evaluationModuleId?: string;
    evaluationModule: EvaluationModule | null;
    conversationModuleId?: string;
    conversationModule: ConversationModule | null;
    completedBy?: string;
    createdBy?: string;
}

type DisputeEvaluationModuleScores = {
    disputeEvaluationModuleId: string;
    disputeEvaluationModuleScore: number;
};

export type AptWorkflowUpdatePayload = WorkflowUpdatePayload & {
    aptModuleId: string;
    aptModuleScore?: number;
};

export type ScoreEscalationWorkflowUpdatePayload = WorkflowUpdatePayload & {
    scoreEscalationModuleId: string;
    scoreEscalationModuleScore: number;
};

export type EvalutationDisputeWorkflowUpdatePayload = WorkflowUpdatePayload & {
    disputeEvaluationModuleScores: DisputeEvaluationModuleScores[];
};

type ClassifierWorkflowUpdatePayload = WorkflowUpdatePayload & {
    audioMetadataId: string;
};

export class WorkflowService extends BaseService {
    protected urlBase: string = "api/Workflow";

    public async getAgingWorkflowData(
        onlyMe: boolean,
        checkOpen: boolean,
        startDate?: string,
        endDate?: string,
        dateReference?: string,
        hierarchies?: string[],
    ) {
        const params = new URLSearchParams({
            onlyMe: JSON.stringify(onlyMe),
            checkOpen: JSON.stringify(checkOpen),
        });

        if (startDate && endDate) {
            params.set("startDate", startDate);
            params.set("endDate", endDate);
        }

        if (dateReference) {
            params.set("dateReference", dateReference);
        }

        if (hierarchies?.length) {
            for (const hierId of hierarchies) {
                params.append("hierarchies", hierId);
            }
        }

        const response = await this.get(
            params,
            "GetAllWorkflowInformationForOrg",
        );

        return response;
    }

    public async getQuestionAndTags(orgId: string) {
        const params = new URLSearchParams({ orgId });
        const response = await this.get(params, "GetQuestionsAndTagsForOrg");
        return response;
    }

    public async getContactTypes() {
        const params = new URLSearchParams({});
        const response = await this.get(params, "GetHierarchyContactTypes");
        return response;
    }

    public async getWorkflowRecipients(organizationId: string) {
        const response = await this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "GetWorkflowRecipients",
        );
        return response;
    }

    public async getWorkflowLicensedModules(orgId: string) {
        const params = new URLSearchParams({ orgId });
        const response = await this.get(
            params,
            "GetWorkflowLicenceModulesForOrg",
        );
        return response;
    }

    public async getWorkflowDefinitions(organizationId: string) {
        const response = await this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "GetWorkflowDefinitions",
        );
        return response;
    }

    public async getWorkflowConditions(organizationId: string) {
        const response = await this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "GetWorkflowConditionsForOrg",
        );
        return response;
    }

    public async getWorkflowEmails(organizationId: string) {
        const response = await this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "ListWorkflowEmailTemplate",
        );
        return response;
    }

    public async createEmailTemplate(
        body: string,
        bodyText: string,
        subject: string,
        templateName: string,
        organizationId: string,
    ) {
        const params = new URLSearchParams({});
        const postBody = JSON.stringify({
            body,
            bodyText,
            subject,
            templateName,
            organizationId,
        });

        const response = await this.post(
            postBody,
            params,
            "CreateWorkflowEmailTemplate",
            {
                returnJson: true,
            },
        );

        return response;
    }

    public async createWorkflowCondition(condition: WorkflowCondition) {
        const params = new URLSearchParams({});
        const body = JSON.stringify(toJS(condition));
        try {
            const response = await this.post(body, params, "CreateWorkflow", {
                returnJson: true,
            });
            return response;
        } catch (error) {
            return error;
        }
    }

    public async updateWorkflowCondition(condition: WorkflowCondition) {
        const params = new URLSearchParams({});
        const body = JSON.stringify(toJS(condition));
        try {
            const response = await this.put(params, "EditWorkflow", body, {
                returnJson: true,
            });
            return response;
        } catch (error) {
            return error;
        }
    }

    public async updateEmailTemplate(
        templateId: string,
        body: string,
        bodyText: string,
        subject: string,
        templateName: string,
        organizationId: string,
    ) {
        const params = new URLSearchParams({});

        const putBody = JSON.stringify({
            templateId,
            organizationId,
            templateName,
            subject,
            body,
            bodyText,
        });

        const response = await this.put(
            params,
            "UpdateWorkflowEmailTemplate",
            putBody,
            {
                returnJson: true,
            },
        );

        return response;
    }

    public async deleteEmailTemplate(templateId: string) {
        const params = new URLSearchParams({ templateId });
        const response = await this.delete(
            params,
            "DeleteWorkflowEmailTemplate",
            undefined,
            {
                returnJson: true,
            },
        );
        return response;
    }

    public async cancelWorkflows(orgId: string, workflowInstances: any[]) {
        const params: URLSearchParams = new URLSearchParams({ orgId });

        const body = JSON.stringify(workflowInstances);
        const response = await this.delete(params, "CancelWorkflows", body);

        return response;
    }

    public async reopenWorkflow(
        workflowInstanceId: string,
        reopenToLevel: number,
    ) {
        const params: URLSearchParams = new URLSearchParams({
            workflowInstanceId,
            reopenToLevel: reopenToLevel.toString(),
        });

        const response = await this.delete(params, "ReopenWorkflow");

        return response;
    }

    public async getValidWorkflowReopenLevelOptions(
        workflowInstanceId: string,
    ): Promise<number[]> {
        const params: URLSearchParams = new URLSearchParams({
            workflowInstanceId,
        });

        const response = await this.get(
            params,
            "GetValidWorkflowReopenLevelOptions",
        );

        return response;
    }

    public async getWorkflowForReviewer(
        workflowInstanceIds: string | string[],
    ) {
        const params: URLSearchParams = new URLSearchParams();

        if (Array.isArray(workflowInstanceIds)) {
            workflowInstanceIds.forEach((id) => {
                params.append("workflowInstanceIds", id);
            });
        } else {
            params.append("workflowInstanceIds", workflowInstanceIds);
        }

        const response = await this.get<WorkflowReviewerInfo[]>(
            params,
            "GetWorkflowInfoForReviewer",
        );

        return response;
    }

    public async getClassifierWorkflowInfo(workflowInstanceIds: string[]) {
        const params = new URLSearchParams({});
        workflowInstanceIds.forEach((id) =>
            params.append("workflowInstanceIds", id),
        );
        const res = await this.get<ClassifierWorkflowInfo[]>(
            params,
            "GetClassifierWorkflowInfo",
        );

        return res;
    }

    public async updateAptWorkflow(updateParams: AptWorkflowUpdatePayload) {
        const body = JSON.stringify(updateParams);

        const params: URLSearchParams = new URLSearchParams();

        const response = await this.put(params, "UpdateAptWorkflow", body);

        return response;
    }

    public async updateScoreEscalationWorkflow(
        updateParams: ScoreEscalationWorkflowUpdatePayload,
    ) {
        const body = JSON.stringify(updateParams);

        const params: URLSearchParams = new URLSearchParams();

        const response = await this.put(
            params,
            "UpdateScoreEscalationWorkflow",
            body,
        );

        return response;
    }

    public async updateEvaluationDisputeWorkflow(
        updateParams: EvalutationDisputeWorkflowUpdatePayload,
    ) {
        const body = JSON.stringify(updateParams);

        const params: URLSearchParams = new URLSearchParams();

        const response = await this.put(
            params,
            "UpdateEvaluationDisputeWorkflow",
            body,
        );

        return response;
    }

    public async updateClassifierWorkflow(
        updateParams: ClassifierWorkflowUpdatePayload,
    ) {
        console.log(updateParams);
        const body = JSON.stringify(updateParams);

        const params: URLSearchParams = new URLSearchParams();

        const response = await this.put(
            params,
            "UpdateClassifierWorkflow",
            body,
        );

        return response;
    }
}
