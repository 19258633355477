import { Grid } from "@mui/material";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import React from "react";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { useStore } from "utils/useStore";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";

export const AgentChips = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const applicationFilterValuesStore = useStore(ApplicationFilterValuesStore);

    const isAgentLoading = applicationFilterValuesStore.getTaskLoading(
        ApplicationFilterValuesStore.Tasks.LOAD_AGENTS,
    );

    if (isAgentLoading)
        return (
            <>
                {applicationFiltersStore.detailedAgents.map((agent) => (
                    <AppliedFilterChip
                        disabled={props.disabled}
                        isApplicable={props.isApplicable}
                    >
                        <Grid
                            container
                            direction="row"
                            flexWrap="nowrap"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item>Agent:</Grid>
                            <Grid item>
                                <AcxLoadingIndicator
                                    size={16}
                                    alternate="PuffLoader"
                                />
                            </Grid>
                        </Grid>
                    </AppliedFilterChip>
                ))}
            </>
        );

    return (
        <>
            {applicationFiltersStore.detailedAgents.map((agent) => {

                return (
                    <AppliedFilterChip
                        disabled={props.disabled}
                        isApplicable={props.isApplicable}
                        onRemove={() => {
                            const agentsLeft =
                            applicationFiltersStore.detailedAgents.filter(
                                    (selectedAgentId) =>
                                        selectedAgentId.id !== agent.id,
                                );
                            applicationFiltersStore.setAgentIds(agentsLeft.map((agent) => agent.id));
                            applicationFiltersStore.setDetailedAgents(agentsLeft);
                        }}
                    >
                        {agent?.fullName}
                    </AppliedFilterChip>
                );
            })}
        </>
    );
});
