import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const BuildingSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Admin"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.5}
            >
                <g
                    id="Admin---Organization---Users"
                    transform="translate(-32.000000, -204.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Left-Nav" transform="translate(0.000000, 64.000000)">
                        <g
                            id="Group-10"
                            transform="translate(24.000000, 104.000000)"
                        >
                            <g
                                id="Group-3"
                                transform="translate(0.000000, 32.000000)"
                            >
                                <g
                                    id="Icon/Building"
                                    transform="translate(8.000000, 4.000000)"
                                >
                                    <path
                                        d="M13,4 L13,6 L16,6 C16.6,6 17,6.4 17,7 L17,7 L17,13 L19,13 C19.6,13 20,13.4 20,14 L20,14 L20,19 C20,19.6 19.6,20 19,20 L19,20 L5,20 C4.4,20 4,19.6 4,19 L4,19 L4,14 C4,13.4 4.4,13 5,13 L5,13 L7,13 L7,7 C7,6.4 7.4,6 8,6 L8,6 L11,6 L11,4 L13,4 Z M15,8 L9,8 L9,18 L11,18 L11,15 L13,15 L13,18 L15,18 L15,8 Z M13,10 L13,12 L11,12 L11,10 L13,10 Z"
                                        id="Fill"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default BuildingSvg;
