import { Grid, Typography } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { OptionType } from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { Observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import { TemplateStore } from "../TemplateStore";

export default function FileExtView() {
    const tStore = useStore(TemplateStore);

    function getExtensionOptions() {
        const arr = new Array<OptionType>();
        arr.push({
            value: "removedot",
            label: "Remove all after and including rightmost period",
        });
        arr.push({
            value: "removestring",
            label: "Remove all after and including...",
        });
        return arr;
    }

    function findDefaultExtension(val: string) {
        const ar = getExtensionOptions();
        const defaultRec = ar.find((a) => a.value === val);
        return defaultRec;
    }
    function onRemoveCharChange(evt: React.ChangeEvent<HTMLInputElement>) {
        const chars = evt.target.value;
        tStore.updateModelProp("removeChars", chars);
    }
    function onExtChange(val: OptionType) {
        tStore.updateModelProp("extHandle", val.value);
    }
    function onSampleFilenameChange(evt: React.ChangeEvent<HTMLInputElement>) {
        tStore.updateModelProp("filename", evt.target.value);
    }
    return (
        <Observer>
            {() => (
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={5}>
                        <AcxMainTextField
                            value={tStore.model.filename}
                            id={"filename-sample"}
                            labelText="Enter a sample filename"
                            onChange={onSampleFilenameChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AcxSelectSingle
                            defaultValue={findDefaultExtension(
                                tStore.model.extHandle,
                            )}
                            inputLabel="File extension handling"
                            onChange={onExtChange}
                            options={getExtensionOptions()}
                            id="extension-options"
                            labelField="label"
                            valueField="value"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {tStore.model.extHandle === "removestring" && (
                            <AcxMainTextField
                                id={"remove-string"}
                                value={tStore.model.removeChars}
                                onChange={onRemoveCharChange}
                                labelText="Enter characters to remove"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {tStore.model.processedFilename || ""}
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Observer>
    );
}
