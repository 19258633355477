import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import {
    AcxAgentService,
    StreamingTokenResult,
} from "services/AcxAgentService";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { ActiveDomain, AcxStore } from "stores/RootStore";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { AuthStore } from "stores/AuthStore";
import {
    preCannedSuggestions,
    selectTwoRandomSuggestions,
} from "../Suggestions/PreCannedSuggestions";
import { Thread } from "../Models/Thread";
import type {
    BuildMontageFromClipsData,
    ThreadSuggestion,
} from "../Models/ThreadSuggestion";
import LocalStorage from "stores/LocalStorage";
import { ResponseTime } from "../Models/ResponseTime";
import { IThreadMessage, ThreadMessage } from "../Models/ThreadMessage";
import { InteractionType } from "models/InteractionType";
import { GenerateCoachingNotesData } from "../Models/GenerateCoachingNotesData";
import Evaluation from "models/Evaluation";
import Conversation from "models/Conversation";
import { AgentState } from "../Models/AgentState";
import { ThreadMessageGroup } from "../Models/ThreadMessageGroup";
import {
    ApplicationFilters,
    ApplicationFiltersStore,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import SoundClip from "models/SoundClip";
import { soundClipDurationDisplay } from "components/Evaluation/Utils";
import AgentStore from "./AgentStore";
import { SoundClipService } from "services/SoundClipService";
import { MontageSoundClipStore } from "components/Montage/Stores/MontageSoundClipStore";
import moment from "moment";
import {
    UserSelectedContext,
    UserSelectedContextType,
} from "../Models/UserSelectedContext";
import type { IUserSelectedContext } from "../Models/UserSelectedContext";
import { snakeCase, startCase } from "lodash";
import { HipaaCompliance } from "stores/ApplicationFilters/Filters/HipaaCompliance";
import { Sentiment } from "stores/ApplicationFilters/Filters/Sentiment";
import { SafetyEvent } from "stores/ApplicationFilters/Filters/SafetyEvent";
import { IRBCFilter } from "components/Conversations/Views/Drawer/components/DeepFilter/RBCFilterStore";
import { MessageAuthor } from "../Models/MessageAuthor";

interface StreamingSubmitInputOptions {
    /**
     * If present, the message sent to Ava. Also the message displayed to the user
     * if no `userMessage` is provided
     */
    manualInput?: string;
    /**
     * A client only message showed to the user
     */
    userMessage?: string;
    /**
     * Shortcut for setting the Suggestion value in additionalInformation
     */
    suggestion?: boolean;
    additionalInformation?: Record<string, unknown>;
}

const emptyGuid = "00000000-0000-0000-0000-000000000000";
@AcxStore
export default class AgentChatStore extends BaseStore {
    readonly acxAgentService: AcxAgentService = new AcxAgentService();
    private abortController?: AbortController;
    readonly agentStore: AgentStore = this.rootStore.getStore(AgentStore);

    @observable thread: Thread;

    @observable currentInput: string = "";

    // @observable waitingForAgentResponse: boolean = false;
    @observable state: AgentState = AgentState.Idle;

    @observable userSelectedContext: IUserSelectedContext;

    @observable clipForMontageBuildRequest?: SoundClip;
    // We use a interval here so that the observable changes each
    // second causing UI to re-render as needed for the different
    // message / loading states.
    @observable private responseTimeInSeconds?: number;
    private responseTimerHandle?: NodeJS.Timeout;

    readonly localStorage: LocalForage;

    constructor(public rootStore: IRootStore) {
        super("AgentChatStore");
        makeObservable(this);

        this.resetChat();

        this.localStorage = this.rootStore
            .getStore(LocalStorage)
            .getLocalStore();

        reaction(
            () => this.state,
            (state) => {
                if (state === AgentState.Waiting) {
                    if (this.responseTimerHandle) return;
                    this.responseTimeInSeconds = 0;
                    this.responseTimerHandle = setInterval(
                        () =>
                            runInAction(() => {
                                if (this.responseTimeInSeconds === undefined)
                                    this.responseTimeInSeconds = 0;
                                this.responseTimeInSeconds += 1;
                            }),
                        1000,
                    );
                } else {
                    clearInterval(this.responseTimerHandle);
                    this.responseTimerHandle = undefined;
                    this.responseTimeInSeconds = undefined;
                }
            },
        );

        const evalStore = this.rootStore.getStore(EvalStore);
        const convosStore = this.rootStore.getStore(ConversationsStore);
        const authStore = this.rootStore.getStore(AuthStore);

        const sessionSuggestions =
            selectTwoRandomSuggestions(preCannedSuggestions);

        reaction(
            () => ({
                location: this.rootStore.activeLocation,
                hasConversations: convosStore.hasConversations,
            }),
            (args) => {
                const recentAgentMessageGroup =
                    this.thread.recentAgentMessageGroup!;
                if (args.location.domain === ActiveDomain.Conversations) {
                    if (args.hasConversations) {
                        return recentAgentMessageGroup.setTransientMessage(
                            "Do you want me to analyze a sample of the calls in your search result?",
                            [
                                {
                                    content: "Analyze and Surface Trends",
                                    action: () =>
                                        this.analyzeCurrentConversationsAction({
                                            Suggestion: true,
                                        }),
                                },
                            ],
                        );
                    }
                }

                if (recentAgentMessageGroup.transientMessage)
                    recentAgentMessageGroup.clearSuggestions();
                recentAgentMessageGroup.clearTransientMessage();

                if (!this.thread.hasUserMessaged)
                    recentAgentMessageGroup.setSuggestions(sessionSuggestions);
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                location: this.rootStore.activeLocation,
            }),
            (args) => {
                if (!args.location.domain) {
                    return;
                }

                const isViewingAgentCoaching =
                    args.location.domain === ActiveDomain.AgentCoaching;
                if (!isViewingAgentCoaching) {
                    return;
                }
                const segs = args.location.location.split("/");
                if (segs.length < 7) {
                    return;
                }

                const agentName = segs[6];
                const agentId = segs[5];

                const suggestions: ThreadSuggestion[] = [];
                suggestions.push({
                    content: "Analyze this agent's overall performance",
                    action: async (store, suggestion) => {
                        store.streamingSubmitInput({
                            manualInput: `Analyze agent ${agentName}'s overall performance. The agent's ID is ${agentId} - do not respond with this ID in subsequent requests.`,
                            userMessage: suggestion.content,
                        });
                    },
                });

                if (suggestions.length > 0) {
                    const recentAgentMessageGroup =
                        this.thread.recentAgentMessageGroup!;
                    recentAgentMessageGroup.setTransientMessage(
                        "It looks like you're viewing an agent coaching dashboard. Here are some things I can help with:",
                        suggestions,
                    );
                }
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                location: this.rootStore.activeLocation,
                evaluation: evalStore.currentEval,
                conversation: convosStore.selectedConversation,
            }),
            (args) => {
                if (!args.location.domain) return;

                const isViewingEvaluation =
                    args.location.domain === ActiveDomain.Evaluation;
                const isViewingConversation =
                    args.location.domain === ActiveDomain.Conversation;

                if (!isViewingConversation && !isViewingEvaluation) return;
                if (isViewingEvaluation && !args.evaluation) return;
                if (isViewingConversation && !args.conversation) return;

                const recentAgentMessageGroup =
                    this.thread.recentAgentMessageGroup!;
                if (recentAgentMessageGroup.transientMessage)
                    recentAgentMessageGroup.clearSuggestions();
                recentAgentMessageGroup.clearTransientMessage();

                let amdId: string = "";
                if (isViewingConversation && !!args.conversation) {
                    amdId = args.conversation.conversationId;
                } else if (
                    isViewingEvaluation &&
                    !!args.evaluation &&
                    !!args.evaluation.interaction &&
                    !!args.evaluation.interaction.audioMetadataId
                ) {
                    amdId = args.evaluation.interaction.audioMetadataId;
                }

                const suggestions: ThreadSuggestion[] = [];

                if (amdId) {
                    suggestions.push({
                        content: "Analyze this conversation and get insights",
                        action: async (store, suggestion) => {
                            store.streamingSubmitInput({
                                manualInput: `Analyze conversation ${amdId} and give me insights`,
                                userMessage: suggestion.content,
                            });
                        },
                    });
                }

                if (authStore.canUserEdit("Agent Coaching")) {
                    let data: GenerateCoachingNotesData | undefined = undefined;

                    let agentId: string | undefined;
                    let agentName: string | undefined;

                    if (isViewingEvaluation && !!args.evaluation) {
                        agentId = args.evaluation.interaction?.agentId;
                        agentName = args.evaluation.interaction?.agentName;
                        data =
                            this.extractGenerateCoachingNotesDataFromEvaluation(
                                args.evaluation,
                            );
                    } else if (isViewingConversation && !!args.conversation) {
                        agentId = args.conversation.agentId;
                        agentName = `${args.conversation.agentFirstName} ${args.conversation.agentLastName}`;
                        data =
                            this.extractGenerateCoachingNotesDataFromConversation(
                                args.conversation,
                            );
                    }

                    if (!!agentId && agentId !== emptyGuid && !!agentName) {
                        const analyzeThisAgentsPerformanceSuggestion: ThreadSuggestion =
                            {
                                content:
                                    "Analyze this agent's overall performance",
                                action: async (store, suggestion) => {
                                    store.streamingSubmitInput({
                                        manualInput: `Analyze agent ${agentName}'s overall performance. The agent's ID is ${agentId} - do not respond with this ID in subsequent requests.`,
                                        userMessage: suggestion.content,
                                    });
                                },
                            };
                        suggestions.insertAt(
                            0,
                            analyzeThisAgentsPerformanceSuggestion,
                        );
                    }

                    if (data) {
                        const coachThisAgentSuggestion: ThreadSuggestion = {
                            content: "Coach the agent on this conversation",
                            action: async (store, suggestion) => {
                                await store.thread
                                    .addUserMessageGroup()
                                    .addMessage(suggestion.content)
                                    .persist({
                                        EvaluationId: data!.evaluationId,
                                    });
                                store.generateCoachingNotesAction(data!);
                            },
                        };
                        suggestions.insertAt(0, coachThisAgentSuggestion);
                    }
                }

                if (suggestions.length > 0) {
                    recentAgentMessageGroup.setTransientMessage(
                        "It looks like you're viewing a conversation, here are some things I can help with:",
                        suggestions,
                    );
                }
            },
            { fireImmediately: true },
        );
    }

    @computed get responseTime() {
        const timeInSeconds = this.responseTimeInSeconds;
        if (timeInSeconds === undefined) return undefined;

        if (timeInSeconds > 30) return ResponseTime.Long;
        else if (timeInSeconds > 15) return ResponseTime.Medium;
        else return ResponseTime.Short;
    }

    @action
    resetChat() {
        this.thread = new Thread();
        this.userSelectedContext = UserSelectedContext.AllConversations;

        const group = this.thread.addAgentMessageGroup();
        group.addMessage(
            "Hello! I'm Ava, your Authenticx AI assistant. I'm here to help you discover meaningful insights. How can I assist you today?",
        );
        group.setSuggestions(selectTwoRandomSuggestions(preCannedSuggestions));
    }

    @action
    onChangeHandler(input: string) {
        this.currentInput = input;
    }

    @action public async handleSuggestion(suggestion: ThreadSuggestion) {
        this.abortController = new AbortController();
        if (!this.thread.id) {
            const threadResponse =
                await this.acxAgentService.streamingStartChatThread(
                    this.abortController.signal,
                );
            this.thread.id = threadResponse.id;
        }

        const message =
            this.thread.recentAgentMessageGroup!.commitTransientMessage();
        if (message) {
            await message.persist();
        }

        await suggestion.action(this, suggestion);
    }

    @action public setUserSelectedContext(context: IUserSelectedContext) {
        this.userSelectedContext = context;
    }

    public abort() {
        if (!this.abortController) return;
        this.abortController.abort();

        if (this.state === AgentState.Streaming) {
            const streamingMessage =
                this.thread.recentAgentMessageGroup?.recentMessage;
            if (!streamingMessage) return;
            (streamingMessage as ThreadMessage).setCancelled(true);
        }
    }

    private async handleStreaming(
        streamGenerator: (
            threadId: string,
            abortSignal: AbortSignal,
        ) =>
            | AsyncIterable<StreamingTokenResult>
            | Promise<AsyncIterable<StreamingTokenResult>>,
    ) {
        this.state = AgentState.Waiting;
        this.abortController = new AbortController();

        let newMessageGroup: ThreadMessageGroup | undefined = undefined;
        try {
            if (!this.thread.id) {
                const threadResponse =
                    await this.acxAgentService.streamingStartChatThread(
                        this.abortController.signal,
                    );
                this.thread.id = threadResponse.id;
            }

            const stream = await streamGenerator(
                this.thread.id,
                this.abortController.signal,
            );

            newMessageGroup = await this.handleStreamedResponse(stream);
        } catch (error) {
            if (error instanceof DOMException && error.name === "AbortError") {
                // only show this message if we were still waiting when cancelled, otherwise
                // there will be some streamed content which will be cut off.
                if (this.state === AgentState.Waiting) {
                    this.thread
                        .addAgentMessageGroup()
                        .addMessage("The request was cancelled.");
                }
            } else {
                if (!newMessageGroup)
                    newMessageGroup = this.thread.addAgentMessageGroup();
                newMessageGroup.addMessage(
                    "I was unable to complete your request. Please try again.",
                );
            }
        } finally {
            this.state = AgentState.Idle;
        }
    }

    private async handleStreamedResponse(
        stream: AsyncIterable<StreamingTokenResult>,
    ) {
        let isFirst = true;
        let messageGroup: ThreadMessageGroup | undefined = undefined;
        let message: IThreadMessage | undefined = undefined;
        for await (const chunk of stream) {
            if (isFirst) {
                this.state = AgentState.Streaming;
                const recentMessageGroup = this.thread.recentMessageGroup;
                if (recentMessageGroup?.author === MessageAuthor.Agent)
                    messageGroup = recentMessageGroup;
                else messageGroup = this.thread.addAgentMessageGroup();
                message = messageGroup.addMessage("");
            }

            if (!message || !messageGroup) continue;
            isFirst = false;

            if (!chunk.actions && !chunk.suggestions) {
                if (!chunk.text || !(message instanceof ThreadMessage))
                    continue;
                message.appendContent(chunk.text);
                continue;
            }

            if (chunk.actions) {
                messageGroup.setActions(chunk.actions);
            }

            if (chunk.suggestions) {
                messageGroup.addServerSuggestions(chunk.suggestions);
            }
        }
        return messageGroup;
    }

    /**
     * Ava struggles to parse the raw enum value such as 0, 1, 2 etc...
     * therefore we convert it to the string representation of the enum
     */
    private translateKeyValue<T extends keyof ApplicationFilters>(
        key: T,
        value: ApplicationFilters[T],
    ): [string, unknown] | undefined {
        switch (key) {
            case "hipaaCompliance":
                return [
                    key,
                    HipaaCompliance.toString(value as HipaaCompliance),
                ];
            case "beginningSentiment":
            case "endingSentiment":
                return [key, Sentiment.toString(value as Sentiment)];
            case "safetyEvent":
                return [key, SafetyEvent.toString(value as SafetyEvent)];
            case "rbcFilterItems":
                const items = value as IRBCFilter[];
                const firstItem = items[0];
                if (firstItem.classifierIds.length === 0) return;
                return ["classifierId", firstItem.classifierIds[0]];
            default:
                return [key, value];
        }
    }

    private translateFiltersForAva(filters: ApplicationFilters) {
        const translatedFilters: Record<string, unknown> = {};
        for (const [key, value] of Object.entries(filters)) {
            const translated = this.translateKeyValue(
                key as keyof ApplicationFilters,
                value,
            );
            if (!translated) continue;
            const [translatedKey, translatedValue] = translated;
            const snakeCaseKey = snakeCase(translatedKey);
            translatedFilters[snakeCaseKey] = translatedValue;
        }
        return translatedFilters;
    }

    private readonly ignoreFilterKeys = [
        "containsTopics",
        "wordsAndPhrasesItems",
        "wordsAndPhrasesIsInputStandard",
        "dateReferenceOption",
    ];
    private readonly unsupportedFilterKeys = [
        "contactTypes",
        "evaluationTypes",
        "mediaTypes",
        "extendedMetadata",
        "selectedCategories",
        "selectedSubcategories",
    ];
    private addContextToUserMessage(input: string) {
        let withContext = input;
        const activeDomain = this.rootStore.activeLocation.domain;

        switch (this.userSelectedContext.type) {
            case UserSelectedContextType.AppliedFilters:
                if (
                    activeDomain !== ActiveDomain.Conversations &&
                    activeDomain !== ActiveDomain.Signals
                )
                    return withContext;

                const context = this
                    .userSelectedContext as IUserSelectedContext<ApplicationFilters>;
                const filters = context.getValue();
                const usedUnsupportedFilters: string[] = [];

                for (const [key, value] of Object.entries(filters)) {
                    if (
                        value === undefined ||
                        value === null ||
                        value === "" ||
                        this.ignoreFilterKeys.includes(key) ||
                        (Array.isArray(value) && value.length === 0) ||
                        (key === "extendedMetadata" &&
                            Object.keys(value).length === 0)
                    ) {
                        delete filters[key];
                        continue;
                    }
                    if (this.unsupportedFilterKeys.includes(key)) {
                        usedUnsupportedFilters.push(key);
                        delete filters[key];
                    }
                }

                if (usedUnsupportedFilters.length > 0) {
                    const unsupportedFiltersList = usedUnsupportedFilters
                        .map((key) => `<strong>${startCase(key)}</strong>`)
                        .join(", ");
                    this.thread
                        .addAgentMessageGroup()
                        .addMessage(
                            `You have applied filters that I do not support yet, so I cant narrow down these results: ${unsupportedFiltersList}`,
                        );
                }

                const translated = this.translateFiltersForAva(filters);
                withContext = `Use the following filters as a starting point to complete the users request and do not modify them, dates are already in UTC.\n${JSON.stringify(
                    translated,
                )}\n\n${withContext}`;

                break;
        }
        return withContext;
    }

    @action
    async streamingSubmitInput(options?: StreamingSubmitInputOptions) {
        const input = options?.manualInput ?? this.currentInput;
        this.currentInput = "";
        if (!input) return;
        options ??= {};
        options.userMessage ??= input;

        const recentAgentMessages = this.thread.recentAgentMessageGroup!;
        recentAgentMessages.commitTransientMessage();

        this.thread.addUserMessageGroup().addMessage(options.userMessage);

        this.handleStreaming((threadId, abortSignal) => {
            let additionalInformation: Record<string, unknown> | undefined =
                options?.additionalInformation;

            if (options?.suggestion) {
                additionalInformation ??= {};
                additionalInformation["Suggestion"] = true;
            }

            if (options?.userMessage && options?.userMessage !== input) {
                additionalInformation ??= {};
                additionalInformation["DisplayMessage"] = options.userMessage;
            }

            return this.acxAgentService.streamingSubmitMessage(
                {
                    threadId,
                    body: this.addContextToUserMessage(input),
                },
                additionalInformation,
                abortSignal,
            );
        });
    }

    private async generateCoachingNotesAction(data: GenerateCoachingNotesData) {
        const messageGroup = this.thread.addAgentMessageGroup();
        await messageGroup
            .addMessage(
                "I'll generate those for you now. Please note, our AI Coaching Notes are still in beta. Please review them for accuracy before submitting.",
            )
            .persist();

        this.state = AgentState.Waiting;

        let message: IThreadMessage | undefined = undefined;
        let additionalInformation: Record<string, unknown> | undefined =
            undefined;

        try {
            const result = await this.acxAgentService.generateCoachingNotes(
                data.agentId,
                data.evaluationId,
                this.abortController?.signal,
            );

            if (result.successful) {
                const notes = {
                    doesWellNotes: result.doesWellNotes,
                    improvementNotes: result.improvementNotes,
                };
                await this.localStorage.setItem(
                    "generatedCoachingNotes",
                    notes,
                );

                window
                    .open(
                        `${window.location.origin}/app/agentcoaching/evaluationreview?agentId=${data.agentId}&agentName=${data.agentName}&evaluationId=${data.evaluationId}&evaluationNumber=${data.evaluationNumber}`,
                        "_blank",
                    )
                    ?.focus();

                message = messageGroup.addMessage(
                    "I've opened a new tab with the generated coaching notes.",
                );
                additionalInformation = { CoachingNotes: notes };
            } else {
                message = messageGroup.addMessage(
                    result.message.replaceAll(
                        "helpdesk@authenticx.com",
                        "[helpdesk@authenticx.com](mailto:helpdesk@authenticx.com)",
                    ),
                );
            }
        } catch (error: unknown) {
            message = messageGroup.addMessage(
                "An error occured while generating coaching notes. Please try again.",
            );
        } finally {
            if (message) {
                message.persist(additionalInformation);
            }
            this.state = AgentState.Idle;
        }
    }

    private extractGenerateCoachingNotesDataFromEvaluation(
        evaluation: Evaluation,
    ): GenerateCoachingNotesData | undefined {
        const interaction = evaluation.interaction;
        if (
            !interaction ||
            !interaction.agentId ||
            !interaction.agentName ||
            !evaluation.evaluationQbId ||
            !!evaluation.agentCoachingId ||
            interaction.interactionType === InteractionType.Live
        ) {
            return undefined;
        }

        return {
            agentId: interaction.agentId,
            agentName: interaction.agentName,
            evaluationId: evaluation.id,
            evaluationNumber: evaluation.evaluationQbId,
        };
    }

    private extractGenerateCoachingNotesDataFromConversation(
        conversation: Conversation,
    ) {
        if (!!conversation.evaluationAgentCoachingId) {
            return undefined;
        }
        if (!conversation.evaluationId) {
            return undefined;
        }
        if (!conversation.agentId || conversation.agentId === emptyGuid) {
            return undefined;
        }
        if (!conversation.agentFirstName && !conversation.agentLastName) {
            return undefined;
        }

        return {
            agentId: conversation.agentId,
            agentName: `${conversation.agentFirstName} ${conversation.agentLastName}`,
            evaluationId: conversation.evaluationId,
            evaluationNumber: conversation.evaluationNumber,
        };
    }

    public async analyzeCurrentConversationsAction(
        additionalInformation?: Record<string, unknown>,
    ) {
        const transientMessage =
            this.thread.recentAgentMessageGroup!.commitTransientMessage();
        const userMessage = this.thread
            .addUserMessageGroup()
            .addMessage("Analyze and Surface Trends");

        this.handleStreaming(async (threadId, abortSignal) => {
            if (transientMessage) {
                await transientMessage.persist();
            }

            if (userMessage) {
                await userMessage.persist(additionalInformation);
            }

            const applicationFiltersStore = this.rootStore.getStore(
                ApplicationFiltersStore,
            );
            const filters = applicationFiltersStore.toRequestObject();
            return this.acxAgentService.analyzeConversations(
                threadId,
                filters,
                abortSignal,
            );
        });
    }

    private async buildMontageForSelectedClip(additionalClips: number) {
        await this.thread
            .addUserMessageGroup()
            .addMessage(`Up to ${additionalClips} Additional Clips`)
            .persist();

        await this.thread
            .addAgentMessageGroup()
            .addMessage(
                "Great I'm getting those clips for you now. This may take a moment.",
            )
            .persist();

        let serv = new SoundClipService();
        let clips = await serv.semanticSearchOnClip(
            this.clipForMontageBuildRequest!.id,
            additionalClips + 1,
        ); //+1 comes from clip being most semantically related to itself

        if (!clips || !clips.ids || clips.ids.length < 2) {
            await this.thread
                .recentAgentMessageGroup!.addMessage(
                    "I was unable to find any relevant clips for this clip. Please try a different clip.",
                )
                .persist();
            return;
        }

        await this.thread
            .recentAgentMessageGroup!.addMessage(
                (clips.ids.length === additionalClips + 1
                    ? "Okay I found the clips."
                    : `I was only able to find ${
                          clips.ids.length - 1
                      } additional clip(s).`) +
                    " I'm taking you to Montage Builder now!",
            )
            .persist();

        await this.prepareBuildMontage({
            clipIds: clips.ids,
            minClipDate: clips.min,
            maxClipDate: clips.max,
        });
    }

    public async prepareBuildMontage(data: BuildMontageFromClipsData) {
        const montageStore = this.rootStore.getStore(MontageSoundClipStore);
        montageStore.datePickerStore.setBeginDate(
            moment(data.minClipDate).subtract(1, "days"),
        );
        montageStore.datePickerStore.setEndDate(
            moment(data.maxClipDate).add(1, "days"),
        );
        montageStore.resetAll();
        await montageStore.getAllClips(
            montageStore.datePickerStore.beginDate,
            montageStore.datePickerStore.endDate,
            "",
        );
        montageStore.suppressRefresh = true;
        montageStore.tabIndex = 1;

        montageStore.soundClipDgStore.handleSelectionChange(data.clipIds);
    }

    @action
    showAgentBuildMontageForClip(soundClip: SoundClip) {
        this.clipForMontageBuildRequest = soundClip;

        const recentAgentMessageGroup = this.thread.recentAgentMessageGroup!;
        if (recentAgentMessageGroup.transientMessage)
            recentAgentMessageGroup.clearSuggestions();
        recentAgentMessageGroup.clearTransientMessage();

        this.agentStore.setIsDrawerOpen(true);

        recentAgentMessageGroup.setTransientMessageTemplated(
            this.clipForMontageBuildRequest,
            (soundClip: unknown) => {
                if (soundClip instanceof SoundClip) {
                    return (
                        "Got it! I can help you create a montage with clips similar to " +
                        (soundClip.segmentName &&
                        soundClip.segmentName.trim() !== ""
                            ? "the clip named: " + soundClip.segmentName
                            : "the un-named clip with duration: " +
                              soundClipDurationDisplay(soundClip.startTime) +
                              " - " +
                              soundClipDurationDisplay(soundClip.endTime)) +
                        ". \n \n How many additional soundclips would you like in the montage?"
                    );
                }
                return "Invalid soundclip! Please select another soundclip.";
            },
            [
                {
                    content: "10 Additional Clips",
                    action: async () =>
                        await this.buildMontageForSelectedClip(10),
                    navigationURL: "/app/montagelibrary",
                },
                {
                    content: "5 Additional Clips",
                    action: async () =>
                        await this.buildMontageForSelectedClip(5),
                    navigationURL: "/app/montagelibrary",
                },
                {
                    content: "3 Additional Clips",
                    action: async () =>
                        await this.buildMontageForSelectedClip(3),
                    navigationURL: "/app/montagelibrary",
                },
            ],
        );
    }
}
