import BaseEntity from "./BaseEntity";

export default class AnswerType extends BaseEntity {
    answerTypeName: string;
    variation: string;
    isScored?: boolean;
    isFillInAnswer: boolean;
    excludeFromModuleScoreCalculation?: boolean;
}

export enum AnswerTypeNames {
    ScaledResponse = "Scaled Response",
    ScaledBooleanResponse = "Scaled Boolean Response",
    TagResponse = "Tag Response",
    ScoredTagResponse = "Scored Tag Response",
    StarRating = "Star Rating",
    QuestionGrouping = "Question Group",

    DateResponse = "Date Response",
    TextResponse = "Text Response",
}

export enum AnswerTypeVariations {
    Single = "Single",
}
