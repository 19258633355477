import authService from "components/Auth/api-authorization/AuthorizeService";
import { ITab } from "components/Navigation/NavMenuNew";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { User, UserManager } from "oidc-client";
import { Auth } from "utils/AccessTokens";
import { delay } from "utils/helpers";
import type { OrganizationStore } from "./OrganizationStore";
import type PermissionStore from "./PermissionStore";
import { IRootStore } from "./RootStore";

export enum AuthStatus {
    NotInitialized,
    Initializing,
    Initialized,
}

export class AuthStore {
    @observable authExpiring;
    @observable userManager: UserManager;
    @observable _user: User;
    @observable _isAuthenticated: boolean = false;

    @observable.ref permStore: PermissionStore;
    @observable.ref orgStore: OrganizationStore;

    @observable private _expired: boolean = false;
    @observable status: AuthStatus = AuthStatus.NotInitialized;
    @observable currentState:
        | "default"
        | "constructing"
        | "ready"
        | "loading auth"
        | "auth loaded"
        | "initializing auth dependencies"
        | "dependencies loaded"
        | "default" = "default";

    @observable activeOrgId?: string = undefined;

    @observable _roles: Map<string, string> = new Map();

    // ALlows us to use the normal auth flow for connecting 3rd partys apps
    @observable connectionAuthorizationUri: string;

    // private userService: UserService;
    _popUpDisabled = true;
    ApplicationName: string = "acxplatform";
    prefix = "/Authenticate";
    ApplicationPaths = {
        DefaultLoginRedirectPath: "/",
        ApiAuthorizationClientConfigurationUrl: `/_configuration/acxplatform`,
        ApiAuthorizationPrefix: this.prefix,
        Login: `${this.prefix}/Login`,
        // LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
        // LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
        // Register: `${prefix}/${LoginActions.Register}`,
        // Profile: `${prefix}/${LoginActions.Profile}`,
        // LogOut: `${prefix}/${LogoutActions.Logout}`,
        // LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
        LoggedOut: "/",
        // LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
        // IdentityRegisterPath: '/Identity/Account/Register',
        // IdentityManagePath: '/Identity/Account/Manage'
    };
    QueryParameterNames = {
        ReturnUrl: "returnUrl",
        Message: "message",
    };
    private signinRetries = 2;

    constructor(public rootStore: IRootStore) {
        makeObservable(this);
        reaction(
            () => ({
                appInsights:
                    this.orgStore?.selectedOrganization?.appConfiguration
                        ?.appInsights,
            }),
            async ({ appInsights }) => {
                if (appInsights) {
                    this.rootStore.appInsightsProvider.initializeApplicationInsights(
                        appInsights,
                    );
                }

                const permissionServiceResponse =
                    await this.permStore.permissionService.getAppPermission(
                        this._user?.profile?.uId,
                    );

                const user = permissionServiceResponse.user;
                //Org name:
                this.rootStore.appInsightsProvider.appInsightsInstance?.addTelemetryInitializer(
                    (envelope) => {
                        if (envelope.tags) {
                            envelope.tags["ai.user.accountId"] =
                                user.organizationId;
                            envelope.tags["ai.user.tenantName"] =
                                this.orgStore.selectedOrganization?.name;
                            envelope.tags["ai.user.roleName"] =
                                this.permStore.user.role.name;
                            envelope.tags["ai.user.userName"] =
                                this.permStore.user.userName;
                        }

                        envelope.baseData = envelope.baseData || {};
                        envelope.baseData.properties =
                            envelope.baseData.properties === undefined
                                ? {}
                                : envelope.baseData.properties;
                        envelope.baseData.properties["ai.user.accountId"] =
                            user.organizationId;
                        envelope.baseData.properties["ai.user.roleName"] =
                            this.permStore.user.role.name;
                        envelope.baseData.properties["ai.user.userName"] =
                            this.permStore.user.userName;
                        envelope.baseData.properties["ai.user.tenantName"] =
                            this.orgStore.selectedOrganization?.name;
                        envelope.baseData.properties["ai.user.Nav"] =
                            window.location.pathname;
                        envelope.baseData.properties["ai.user.tenantName"] =
                            this.orgStore.selectedOrganization?.name;
                    },
                );
                this.rootStore.appInsightsProvider?.appInsightsInstance?.trackPageView(
                    {
                        properties: {
                            user_tenantName:
                                this.orgStore.selectedOrganization?.name,
                            user_roleName: this.permStore.user.role.name,
                            user_userName: this.permStore.user.userName,
                        },
                    },
                );
            },
        );

        runInAction(() => (this.currentState = "constructing"));

        this.populateAuth();
    }

    @action
    setCurrentPage(currentPage: string) {
        this.rootStore.setActiveLocation(currentPage);
    }

    async populateAuth(): Promise<Auth | undefined> {
        runInAction(() => {
            this.currentState = "loading auth";
        });

        await this.ensureUserManagerInitialized();
        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUserObject(),
        ]);

        runInAction(async () => {
            this._user = user!;
            this._isAuthenticated = isAuthenticated;
            if (!this.areDependenciesInitialized() && this._isAuthenticated) {
                await this.initializeDependencies();
            }
            this.status =
                this.status === AuthStatus.NotInitialized
                    ? AuthStatus.Initializing
                    : this.status;
            this.currentState = "auth loaded";
        });
        if (user) {
            return { isAuthenticated, user };
        }
    }

    private async initializeDependencies() {
        runInAction(() => {
            this.currentState = "initializing auth dependencies";
        });

        try {
            const [orgStore, permStore] = await Promise.all([
                import("./OrganizationStore"),
                import("./PermissionStore"),
            ]);

            const permission_store = new permStore.default();
            const org_store = new orgStore.OrganizationStore(
                this.rootStore,
                this._user?.profile?.OrgId,
                this.setActiveOrgId,
            );

            await org_store.loadOrganizations();

            await permission_store.loadPerms(
                this._user?.profile?.uId,
                this._user?.profile.OrgId,
            );

            await permission_store.loadTenantAuthorizations(
                this._user?.profile?.uId,
            );

            runInAction(() => {
                this.permStore = permission_store;
                this.orgStore = org_store;
                this.status = AuthStatus.Initialized;
                this.currentState = "ready";
            });

            runInAction(() => {
                this.currentState = "dependencies loaded";
            });

            this.setupDocumentVisibilityChange();
        } catch (e) {
            console.error(e);
            this.handleAuthorizationFailure();
        }
    }

    setActiveOrgId = (orgId?: string) => {
        if (orgId !== this.activeOrgId) {
            this.activeOrgId = orgId;
        }
    };

    getRequestHeader(useTemp: boolean = false) {
        let token: string | null = this._user?.access_token;
        let orgId =
            (useTemp ? this.orgStore?.temporarySelectOrganization?.id : null) ??
            this.activeOrgId ??
            this.orgStore?.selectedOrganization?.id ??
            this._user?.profile?.OrgId;

        let head: any;
        if (token) {
            head = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Acx-OrganizationId": orgId!,
                "Acx-Timezone":
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
        } else {
            head = { "Content-Type": "application/json" };
        }
        return head;
    }

    @computed
    get isExpired() {
        return this._expired;
    }

    createArguments(state?: any) {
        return { useReplaceToNavigate: true, data: state };
    }

    success(state) {
        return { status: "success", state };
    }

    error(message) {
        return { status: "fail", message };
    }

    redirect() {
        return { status: "redirect" };
    }

    async getUserObject() {
        if (this._user) {
            return this._user;
        }
        await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        return user;
    }

    async getAccessToken() {
        if (this._user) {
            return this._user.access_token;
        }
        await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        return user && user.access_token;
    }

    async clearUser() {
        await this.ensureUserManagerInitialized();
        this.userManager.removeUser();
        this.userManager.clearStaleState();
    }

    private expiredCallback = async () => {
        runInAction(() => {
            this._expired = true;
        });
    };
    private userSignedOutCallback = async () => {
        await this.userManager.removeUser();
        window.location.assign("/sessionexpired");
    };

    private userLoadedCallback = async () => {
        const req = await authService.getUserObject();
        if (req) {
            this._user = req;
        }
    };

    private silentRenewErrCallback = async (error) => {
        await this.silentRenewAccess();
    };

    async ensureUserManagerInitialized() {
        if (!authService.userManager) {
            await authService.ensureUserManagerInitialized();
        }
        if (!this.userManager) {
            if (authService.userManager) {
                this.userManager = authService.userManager;
            }
        }

        if (this.userManager) {
            this.userManager.events.removeAccessTokenExpired(
                this.expiredCallback,
            );
            this.userManager.events.removeUserSignedOut(
                this.userSignedOutCallback,
            );
            this.userManager.events.removeUserLoaded(this.userLoadedCallback);
            this.userManager.events.removeSilentRenewError(
                this.silentRenewErrCallback,
            );

            this.userManager.events.addUserSignedOut(
                this.userSignedOutCallback,
            );

            this.userManager.events.addAccessTokenExpired(this.expiredCallback);

            this.userManager.events.addSilentRenewError(
                this.silentRenewErrCallback,
            );

            this.userManager.events.addUserLoaded(this.userLoadedCallback);
        }
    }

    getLoginUrl() {
        const redirectUrl = `${this.ApplicationPaths.Login}?${
            this.QueryParameterNames.ReturnUrl
        }=${encodeURIComponent(window.location.href)}`;
        return redirectUrl;
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(this.QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error(
                "Invalid return url. The return url needs to have the same origin as the current page.",
            );
        }
        return (
            (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${this.ApplicationPaths.LoggedOut}`
        );
    }

    @action
    handleAuthorizationFailure = async () => {
        try {
            await this.userManager.clearStaleState().finally(async () => {
                await this.silentRenewAccess();
            });
        } catch (e) {
            console.error(`AuthStore::handleAuthorizationFailure - ${e}`);

            this.redirectToSignIn();
        }
    };

    private areDependenciesInitialized(): boolean {
        return Boolean(this.permStore) && Boolean(this.orgStore);
    }

    private redirectToSignIn() {
        const origin = window.location.href;
        window.location.href = `/authentication/login?returnUrl=${encodeURIComponent(
            origin,
        )}`;
    }

    @action
    setupDocumentVisibilityChange = () => {
        document.addEventListener("visibilitychange", async () => {
            // we only care when the user switches to the page.
            if (document.hidden === false) {
                // Getting the user will re-initialize all access-token events and the
                // associated timers to implement expiry checking/silent renewal
                const user = await this.userManager.getUser();

                if (user && user.expired) {
                    // If the token has already expired, there won't be any new events
                    // coming from oidc-client, so we need to handle this manually.
                    // dispatch(userExpired());
                    let retryStep = 0;
                    while (retryStep < this.signinRetries) {
                        retryStep += 1;
                        try {
                            // return await this.userManager.signinSilent();
                            return await this.silentRenewAccess();
                        } catch (err) {
                            // This may fail while the OS reconnects to a network
                            // after waking up and we were a bit too quick trying
                            // to perform the signin. We will retry a few times.
                        }
                    }
                }
            }
        });
    };

    @action
    silentRenewAccess = async () => {
        this.userManager.signinSilent().then(
            (user) => {
                this._user = user;
            },
            (err) => {},
        );
    };

    @action
    handleUserLogout = async (redirect?: boolean) => {
        const headers = this.getRequestHeader();

        let logoutURL = `api/Authenticate/Logout`;

        try {
            var response = await fetch(logoutURL, {
                method: "Post",
                headers: headers,
                body: JSON.stringify({ ReturnUrl: "/" }),
            });

            if (response.status === 200) {
                await authService.userManager?.removeUser();
                sessionStorage.removeItem("backupConversationFilters");
                localStorage.clear();
                await delay(200);

                if (redirect) {
                    window.location.assign("/sessionexpired");
                } else {
                    window.location.replace(
                        `/authentication/login?returnUrl=${encodeURI(
                            origin,
                        )}/app`,
                    );
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    public isUserUltra(): boolean {
        if (
            this.permStore.user?.organization?.name === "Authcx" &&
            this.permStore.user.role?.name === "Ultra"
        ) {
            return true;
        }
        return false;
    }

    //Universal check for whether a user can edit V2 roles.
    public canUserEditRoles(): boolean {
        if (
            this.permStore.user?.organization?.name === "Authcx" &&
            this.permStore.user.role?.name === "Ultra"
        ) {
            return true;
        }
        return this.permStore.user.role.isOrgAdministrator;
    }

    //TODO: define resource for function help
    //V2 Check for view permissions, documentation applies to all actions
    public canUserView(resource: string): boolean {
        //If the organization doesn't have enhanced permissions enabled, then for backwards compatibility purposes the user has permission
        //Note: this is valid because other permission check for non-V2 roles and permissions still exist in the code
        if (!this.orgStore.selectedOrganization?.enhancedPermissionStatus) {
            return true;
        }
        //If the user is ultra, they can do whatever they want
        if (
            this.permStore.user?.organization?.name === "Authcx" &&
            this.permStore.user.role?.name === "Ultra"
        ) {
            return true;
        }
        //Ask the perm store about this action and resource, only the perm store knows what permissions a user's role has.
        return this.permStore.userHasPermissionTo("View", resource);
    }

    //V2 Check for edit permissions
    public canUserEdit(resource: string): boolean {
        if (!this.orgStore.selectedOrganization?.enhancedPermissionStatus) {
            return true;
        }
        if (
            this.permStore.user?.organization?.name === "Authcx" &&
            this.permStore.user.role?.name === "Ultra"
        ) {
            return true;
        }
        return this.permStore.userHasPermissionTo("Edit", resource);
    }

    //V2 Check for manage permissions
    public canUserManage(resource: string): boolean {
        if (!this.orgStore.selectedOrganization?.enhancedPermissionStatus) {
            return true;
        }
        if (
            this.permStore.user?.organization?.name === "Authcx" &&
            this.permStore.user.role?.name === "Ultra"
        ) {
            return true;
        }
        return this.permStore.userHasPermissionTo("Manage", resource);
    }

    //V2 Check for multiple permissions on an OR logic basis for edit permissions
    public canUserEditAny(resources: string[]) {
        for (const resource of resources) {
            if (this.canUserEdit(resource)) {
                return true;
            }
        }
        return false;
    }

    public isLoggedInUserAgent(): boolean {
        return !!this.permStore.agent;
    }

    public filterTabs(tabs: ITab[]): ITab[] {
        return this.permStore.filterTabs(tabs, this.shouldUseNewPermissions());
    }

    public getV1UserDomains(): string[] {
        return this.permStore.appPermissions
            ? Object.keys(this.permStore.appPermissions)
            : [];
    }

    public canUserViewAdminApp(): boolean {
        return this.permStore.isAdminUser(this.shouldUseNewPermissions());
    }

    public shouldUseNewPermissions(): boolean {
        return (
            this.orgStore.selectedOrganization?.enhancedPermissionStatus ??
            false
        );
    }
}
