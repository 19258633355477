import React from "react";
import AcxAnswerSelector, {
    AnswerColor,
} from "components/UI/AcxAnswerSelector";
import BaseEntity from "models/BaseEntity";
import { Grid } from "@mui/material";

class FakeEntity extends BaseEntity {
    value: any;

    constructor(value: any) {
        super();
        this.value = value;
    }
}

const options2way = [
    {
        data: new FakeEntity(1),
        option: { text: "Doesn't Meet", color: AnswerColor.RED },
    },
    {
        data: new FakeEntity(3),
        option: { text: "Meets", color: AnswerColor.GREEN },
    },
];

const options3way = [
    {
        data: new FakeEntity(1),
        option: { text: "Doesn't Meet", color: AnswerColor.RED },
    },
    {
        data: new FakeEntity(2),
        option: { text: "Partially Meets", color: AnswerColor.YELLOW },
    },
    {
        data: new FakeEntity(3),
        option: { text: "Meets", color: AnswerColor.GREEN },
    },
];

const options5way = [
    {
        data: new FakeEntity(1),
        option: { text: "Doesn't Meet", color: AnswerColor.RED },
    },
    {
        data: new FakeEntity(2),
        option: { text: "Sort of Meets", color: AnswerColor.YELLOW },
    },
    {
        data: new FakeEntity(3),
        option: { text: "Partially Meets", color: AnswerColor.YELLOW },
    },
    {
        data: new FakeEntity(4),
        option: { text: "Mostly Meets", color: AnswerColor.YELLOW },
    },
    {
        data: new FakeEntity(5),
        option: { text: "Meets", color: AnswerColor.GREEN },
    },
];

function SampleKButtons() {
    return (
        <>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <AcxAnswerSelector
                        options={options2way}
                        onSelect={(a) => console.log(a)}
                    />
                </Grid>
                <Grid item>
                    <AcxAnswerSelector
                        options={options3way}
                        onSelect={(a) => console.log(a)}
                    />
                </Grid>
                <Grid item>
                    <AcxAnswerSelector
                        options={options5way}
                        onSelect={(a) => console.log(a)}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default SampleKButtons;
