import { action, observable, makeObservable } from "mobx";
import { copyBaseFields } from "../utils/BaseEntityUtils";
import { Answer } from "./Answer";
import BaseEntity from "./BaseEntity";
import SoundClip from "./SoundClip";

export class SoundClipAnswer extends BaseEntity {
    @observable soundClipId: string;
    @observable soundClip?: SoundClip;

    @observable answerId: string;
    @observable answer?: Answer;

    constructor(
        soundClipId: string,
        answerId: string,
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        super(id, createdBy, modifiedBy, createdOn, modifiedOn);
        makeObservable(this);
        this.soundClipId = soundClipId;
        this.answerId = answerId;
    }

    @action
    setSoundClip(soundClip?: SoundClip) {
        this.soundClip = soundClip;
    }

    @action
    setAnswer(answer: Answer) {
        this.answer = answer;
    }

    static fromJson(soundClipAnswerJson: SoundClipAnswer) {
        const res = new SoundClipAnswer(
            soundClipAnswerJson.soundClipId,
            soundClipAnswerJson.answerId,
        );
        copyBaseFields(soundClipAnswerJson, res);

        if (soundClipAnswerJson.soundClip) {
            res.soundClip = SoundClip.fromJson(soundClipAnswerJson.soundClip);
        }
        if (soundClipAnswerJson.answer) {
            res.answer = Answer.fromJson(soundClipAnswerJson.answer);
        }

        return res;
    }
}
