/* eslint-disable react/jsx-no-comment-textnodes */
import { ButtonBase, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { VizType } from "../../../../../models/Reporting/ReportModel";
import hexToRGB from "../../../../../utils/hexToRGB";
import { useStore } from "../../../../../utils/useStore";
import AcxSelectSingle from "../../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import { QueryFilterOptions } from "../Editor";
import QueryFilterSection from "../EditorSections/QueryFilterSection";
import FilterRules from "../FilterRules";
import TableDataSection from "./TableDataSection";
import VizDataSection from "./VizDataSection";

const useStyles = makeStyles((theme: Theme) => ({
    buttonIcon: {
        width: "10px",
        height: "10px",
        marginTop: "2px",
        textAlign: "center",
    },

    addButtonText: {
        color: theme.palette.blue.main,
        fontSize: "13px",
        fontWeight: "bold",
        letterSpacing: 0,
        lineHeight: "20px",
        marginLeft: theme.spacing(1),
    },
    addButtonIcon: {
        width: "16px",
        height: "16px",
        backgroundColor: hexToRGB(theme.palette.blue.main, 0.2),
        borderRadius: "50%",
    },
    addButton: {
        textTransform: "none",
        color: theme.palette.blue.main,
        marginTop: theme.spacing(3),
    },
    filterContainer: {
        borderStyle: "solid",
        padding: "7px",
        marginTop: "15px",
        backgroundColor: "#FFFBEB",
    },
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
    selectedQuickFiltersText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    mainSectionTitle: {
        marginTop: theme.spacing(1.5),
    },
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    mainSectionSubtitleText: {
        fontFamily: "Inter",
        opacity: "0.5",
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
        maxWidth: "300px",
    },
    bulletedList: {
        // listStyle: "circle",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    elementSpacing: {
        paddingBottom: "17px",
    },
    filterConditionSpacing: {
        textAlign: "center",
        paddingTop: "15px",
    },
    toggleButtonContainer: {
        padding: "7px",
        alignItems: "center",
        textAlign: "center",
        display: "table-cell",
        background: "lightgrey",
        borderRadius: "4px",
        width: "max-content",
    },
    toggleButtonLable: {
        background: "white",
        color: "black",
        paddingRight: "5px",
        paddingLeft: "5px",
    },
}));

interface OwnProps {}

type Props = OwnProps;

const ReportDataSection: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);

    const returnFilterSection = () => {
        let maxCount = 0;
        let components: any = [];
        store.currentReport?.filterSectionCount !== undefined
            ? (maxCount = store.currentReport?.filterSectionCount)
            : (maxCount = 0);
        for (let index = 0; index <= maxCount; index++) {
            if (index > 0) {
                components.push(
                    <React.Fragment key={`report-or-filter-${index}`}>
                        <Grid
                            item
                            xs={12}
                            className={classes.filterConditionSpacing}
                        >
                            <Typography
                                className={classes.mainSectionTitleText}
                            >
                                OR
                            </Typography>
                        </Grid>
                    </React.Fragment>,
                );
            }
            components.push(
                <React.Fragment key={`report-filters-${index}`}>
                    <Grid
                        item
                        xs={12}
                        container
                        className={clsx(classes.filterContainer)}
                    >
                        <FilterRules displayId={index} />
                    </Grid>
                </React.Fragment>,
            );
        }
        return components;
    };

    return (
        <>
            <Grid item xs={12} className={classes.elementSpacing}>
                <Typography className={classes.mainSectionTitleText}>
                    Report Data
                </Typography>
            </Grid>
            <Grid
                container
                item
                xs={12}
                className={classes.elementSpacing}
                justifyContent="space-between"
            >
                <Grid item xs={5}>
                    <AcxSelectSingle
                        options={store.reportDataViews ?? []}
                        id={"report-data-selector"}
                        onChange={store.setCurrentReportDataView}
                        defaultValue={store.currentReport?.dataView}
                        valueField={"id"}
                        labelField={"displayName"}
                        fullWidth
                        inputLabel={"What data would you like to report?"}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid
                    item
                    xs={5}
                    className={clsx(classes.toggleButtonContainer)}
                >
                    <QueryFilterSection />
                </Grid>
            </Grid>
            {store.queryFilterOption === QueryFilterOptions.Custom &&
                returnFilterSection()}
            {store.queryFilterOption === QueryFilterOptions.Custom && (
                <ButtonBase
                    className={classes.addButton}
                    onClick={store.currentReport?.addFilterSection}
                >
                    <div className={classes.addButtonIcon}>
                        <AddIcon className={classes.buttonIcon} />
                    </div>
                    <Typography className={classes.addButtonText}>
                        Add OR Condition
                    </Typography>
                </ButtonBase>
            )}
            <Grid item xs={12} className={classes.vertSpace} container>
                {isGraphicalReportType(
                    store.currentReport?.vizType ?? VizType.Bar,
                ) ? (
                    <VizDataSection />
                ) : (
                    <TableDataSection />
                )}
            </Grid>
        </>
    );
});

export default ReportDataSection;

function isGraphicalReportType(vizType: VizType) {
    return (
        vizType === VizType.Bar ||
        vizType === VizType.Line ||
        vizType === VizType.Pie ||
        vizType === VizType.Histogram ||
        vizType === VizType.Scatter ||
        vizType === VizType.HorizontalBar
    );
}
