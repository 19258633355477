import { IconButton, Popper, Theme, Tooltip } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";
import React, { FunctionComponent, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EvaluationService } from "services/EvaluationService";
import { AuthStore } from "stores/AuthStore";
import { default as hexToRGB } from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import { ServiceError } from "../../services/BaseService";
import theme from "../../Theme/AppTheme";
import MessageStore from "../ManagerInteractions/Stores/MessageStore";
import { Routes } from "../Navigation/Routes";
import AcxLoadingIndicator from "../UI/AcxLoadingIndicator";
import AcxMainTextField from "../UI/AcxMainTextField";

const useStyles = makeStyles((theme: Theme) => ({
    searchButton: () => ({
        backgroundColor: hexToRGB(theme.palette.black.main, 0.05),
    }),
    icon: () => ({
        color: hexToRGB(theme.palette.black.main, 0.25),
    }),
    root: {
        "&:hover": {
            backgroundColor: theme.palette.white.main,
            color: hexToRGB(theme.palette.secondary.main, 0.25),
        },
    },
    searchIcon: {
        padding: "7px",
        backgroundColor: "white",
        border: "1px solid rgba(0, 0, 0, 0.5)",
        marginRight: "2px",
        borderRadius: "2px",
        paddingLeft: "10px",
    },
}));

const evaluationService: EvaluationService = new EvaluationService();

const EvaluationSearch: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const isBreakpointLarge = useMediaQuery(theme.breakpoints.up("lg"));
    const [shouldShowSearch, setShouldShowSearch] =
        React.useState<boolean>(false);
    const classes = useStyles();
    const buttonRef: any = null; // = useRef<HTMLButtonElement>();
    const inputRef = useRef<HTMLInputElement>();

    if (isBreakpointLarge) {
        return <EvaluationSearchBox />;
    }

    return (
        <>
            <Tooltip title="Search by Eval Number">
                <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={"profile-menu"}
                    aria-haspopup="true"
                    onClick={() => {
                        setShouldShowSearch(!shouldShowSearch);
                        if (shouldShowSearch) {
                            inputRef.current?.focus?.();
                        }
                    }}
                    classes={{ root: classes.root }}
                    size="small"
                    className={clsx(classes.searchButton)}
                    ref={buttonRef}
                    style={{
                        marginLeft: "0.5rem",
                        marginRight: "0.25rem",
                    }}
                >
                    <SearchIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Popper
                open={shouldShowSearch}
                anchorEl={buttonRef?.current}
                style={{ zIndex: 10000 }}
                placement="left"
            >
                <div className={classes.searchIcon}>
                    <EvaluationSearchBox focus={shouldShowSearch} />
                </div>
            </Popper>
        </>
    );
};

const EvaluationSearchBox: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<{ focus?: boolean }>>> = (props) => {
    const authStore = useStore(AuthStore);
    const navigate = useNavigate();
    const messageStore = useStore(MessageStore);

    const [evalNumber, setEvalNumber] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    async function searchForEval() {
        if (!evalNumber) {
            return;
        }

        const evalNumberCast = +evalNumber;
        if (isNaN(evalNumberCast)) {
            messageStore.logError(
                "An evaluation number can only contain digits.",
            );
            return;
        }

        setIsLoading(true);

        try {
            const orgId = authStore.orgStore.selectedOrganization!.id;
            const id = await evaluationService.getEvalByEvaluationNumber(
                evalNumberCast,
                orgId,
            );

            navigate(Routes.makeEvaluationRoute(orgId, id));
        } catch (e) {
            let message;
            const serviceError = e as ServiceError;

            if (serviceError.response.status === 403) {
                message =
                    "You do not have appropriate permissions to view this evaluation.";
            } else if (serviceError.response.status === 404) {
                message =
                    "An evaluation with the provided number was not found.";
            } else {
                message =
                    "A general error occurred. Please try again later or contact your system administrator.";
            }

            messageStore.logError(message);
        }

        setIsLoading(false);
    }

    const endAdornment = isLoading ? (
        <AcxLoadingIndicator
            size={20}
            alternate="PuffLoader"
            color="primary"
            style={{
                width: "auto",
            }}
        />
    ) : (
        <IconButton onClick={searchForEval} size="small">
            <SearchIcon fontSize="small" />
        </IconButton>
    );

    return (
        <>
            {authStore.canUserView("Evaluation Search") && (
                <AcxMainTextField
                    id="evalSearch"
                    placeholderText="Find Eval by #"
                    type="text"
                    onChange={(e) => setEvalNumber(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            searchForEval();
                        }
                    }}
                    endAdornment={endAdornment}
                    focus={props.focus}
                />
            )}
        </>
    );
};

export default EvaluationSearch;
