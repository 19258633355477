import React, { FunctionComponent } from "react";
import icon from "./DownChevron.svg";

interface IDownChevron {
    style?: React.CSSProperties;
    className?: string;
}

const DownChevronSvg: FunctionComponent<IDownChevron> = (props) => {
    return (
        <img
            src={icon}
            className={props.className}
            alt="down chevron"
            style={props.style}
        />
    );
};

export default DownChevronSvg;
