import { Segment } from "@beacx/peacx";
import { IconButtonProps, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import Edit from "@mui/icons-material/Edit";
import PauseCircleFilled from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { MontageSoundClipStore } from "components/Montage/Stores/MontageSoundClipStore";
import { IAcxButtonProps } from "components/UI/AcxButton";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { AuthStore } from "stores/AuthStore";
import ScissorsSvg from "SvgIcons/ScissorsSvg";
import theme from "Theme/AppTheme";
import { useStore } from "utils/useStore";
import { ClipUIModel } from "../../models/SoundClip";
import useStyles from "../../Styles/Styles";
import AcxMenu, { StyledMenuLabel } from "../UI/Menu/AcxMenu";
import RefineClipDialog from "./RefineClipDialog";
import SoundClipVisualizer from "./SoundClipVisualizer";
import SoundClipEditorStore, {
    CreateClipTask,
    DownloadAudioClipTask,
} from "./Stores/SoundClipEditorStore";
import TimeRangePopover from "./TimeRangePopover";

const styles = (_theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            position: "relative",
            backgroundColor: "white",
        },
    });

type PlayerOptions = {
    id: string;
    isPlaying: boolean;
    startTime: number;
    endTime: number;
};

type PartialSegment = Partial<Segment> & { transcriptionText?: string };

type Props = {
    clip?: ClipUIModel;
    id: string;
    clipBlob?: Blob;
    clipAudioBuffer?: AudioBuffer;
    alternateClipDownloader?: () => Promise<ArrayBuffer>;
    onClipLoading?: (id: string, isLoading: boolean) => void;
    onAudioBufferReady?: ({
        id,
        index,
        audioBuffer,
        blob,
    }: {
        id: string;
        index: number;
        audioBuffer: AudioBuffer;
        blob: Blob;
    }) => void;
    onClipGenerated?: (start: number, end: number) => void;
    onClipUpdated?: (soundClip) => void;
    onSegmentMouseOver?: (
        id?: string,
        startTime?: number,
        endTime?: number,
    ) => void;
    onSegmentClick?: (id?: string | null) => void;
    segmentList?: Array<PartialSegment>;
    onClipEditorReady?: (isSuccess: boolean) => void;
    index?: number;
    editorHeight?: number;
    fullControls?: boolean;
    allowUndo?: boolean;
    noSegmentRemoval?: boolean;
    noMenu?: boolean;
    disabled?: boolean;
    minimize?: boolean;
    generateMp3?: boolean;
    maxClipDuration?: number;
    throwWarning?: boolean;
    enableZoom?: boolean;
    isMontage?: boolean;
    playerOptions?: PlayerOptions;
    disablePlaybackRate?: boolean;
    mediaUrl?: string;
    textButton?: string | null;
    textButtonProps?: Partial<IAcxButtonProps>;
    iconButton?: React.ReactNode;
    iconButtonProps?: Partial<IconButtonProps>;
    disableClip?: boolean;
    soundClipEditorStore: SoundClipEditorStore;
};

const SoundClipEditorV2: React.FC<React.PropsWithChildren<Props>> = observer(
    (props) => {
        const classes = useStyles(styles);
        const montageStore = useStore(MontageSoundClipStore);
        const authStore = useStore(AuthStore);

        const store = props.soundClipEditorStore;

        const [refineSegmentDialogOpen, setRefineSegmentDialogOpen] =
            useState(false);

        useEffect(() => {
            if (!store) return;
            if (props.segmentList) {
                store.setSegmentList(props.segmentList);
            }
            store.refreshSegmentList();
        }, [props.segmentList, store]);

        useEffect(() => {
            props.playerOptions &&
                !!store &&
                store.onBlurbPlaySegment(props?.playerOptions);
        }, [props?.playerOptions, store]);

        //right now index is really only used in the montage store
        if (props.index !== undefined && !!store)
            montageStore.addViewModel(store, props.id);

        useEffect(() => {
            if (!store) return;
            return () => {
                store.dispose();
            };
        }, [store]);

        useEffect(() => {
            if (!store) return;
            store.disableClip = props.disableClip;
        }, [props.disableClip, store]);

        useEffect(() => {
            if (!store) return;
            store.replaceAudioBuffer(props.clipAudioBuffer);
        }, [props.clipAudioBuffer, store]);

        // useEffect(() => {
        //     store.setClip(props.clip);
        // }, [props.clip, store]);

        useEffect(() => {
            if (!store) return;
            store.changeVisualizerHeight(props.editorHeight);
        }, [props.editorHeight, store]);

        useEffect(() => {
            if (props.maxClipDuration && !!store) {
                store.maxClipDurationSeconds = props.maxClipDuration;
            }
        }, [props.maxClipDuration, store]);

        const isAgent = authStore.isLoggedInUserAgent();

        return (
            <div className={classes.root}>
                <RefineClipDialog
                    viewModel={store}
                    montageStore={montageStore}
                    refineSegmentDialogOpen={refineSegmentDialogOpen}
                    setRefineSegmentDialogOpen={setRefineSegmentDialogOpen}
                />
                {!props.disableClip && <TimeRangePopover viewModel={store} />}
                {store.getTaskLoading(DownloadAudioClipTask) && (
                    <AcxLoadingIndicator
                        color="secondary"
                        alternate="PuffLoader"
                        size={50}
                        style={{
                            position: "absolute",
                            left: `calc(${
                                (store._playerRef?.clientWidth ?? 0) / 2
                            }px)`,
                            top: `${
                                props.noMenu ||
                                props.isMontage ||
                                !props.fullControls
                                    ? 0
                                    : -37.5
                            }px`,
                        }}
                    />
                )}

                {!props.disabled && (
                    <SoundClipVisualizer
                        viewModel={store}
                        editorHeight={props.editorHeight}
                        noMenu={props.noMenu}
                        minimize={props.minimize}
                        allowUndo={props.allowUndo}
                        fullControls={props.fullControls}
                        enablePlaybackRate={!props.disablePlaybackRate}
                        enableZoom={props.enableZoom}
                        isMontage={props.isMontage}
                        textButton={props.textButton}
                        textButtonProps={props.textButtonProps}
                        iconButton={props.iconButton}
                        iconButtonProps={props.iconButtonProps}
                    />
                )}

                {!props.noMenu && (
                    <AcxMenu
                        menuItems={[
                            {
                                id: "menu-item-play/pause-segment",
                                label: (
                                    <StyledMenuLabel>
                                        {store.isPlaying ? "Pause" : "Play"}
                                    </StyledMenuLabel>
                                ),
                                icon: store.isPlaying ? (
                                    <PauseCircleFilled
                                        fontSize={"small"}
                                        color="primary"
                                    />
                                ) : (
                                    <PlayCircleFilled
                                        fontSize={"small"}
                                        color="primary"
                                    />
                                ),
                                props: {
                                    onClick: store.togglePlaySegment,
                                },
                            },
                            {
                                id: "menu-item-refine-segment",
                                label: <StyledMenuLabel>Edit</StyledMenuLabel>,
                                icon: <Edit fontSize={"small"} />,
                                hidden: isAgent || props.noSegmentRemoval,
                                props: {
                                    disabled:
                                        store.isPlaying ||
                                        !store.isSegmentRemovable ||
                                        isAgent ||
                                        store.getTaskLoading(CreateClipTask),
                                    onClick: () => {
                                        store.setLatestSegmentStartTime(
                                            Number(
                                                store.latestSegment?.startTime?.toFixed(
                                                    0,
                                                ),
                                            ),
                                        );
                                        store.setLatestSegmentEndTime(
                                            Number(
                                                store.latestSegment?.endTime?.toFixed(
                                                    0,
                                                ),
                                            ),
                                        );
                                        setRefineSegmentDialogOpen(true);
                                    },
                                },
                            },
                            {
                                id: "menu-item-remove-segment",
                                label: (
                                    <StyledMenuLabel>Remove</StyledMenuLabel>
                                ),
                                icon: <RemoveCircleIcon fontSize={"small"} />,
                                hidden: isAgent || props.noSegmentRemoval,
                                props: {
                                    disabled:
                                        store.isPlaying ||
                                        !store.isSegmentRemovable ||
                                        isAgent ||
                                        store.getTaskLoading(CreateClipTask),
                                    onClick: () => {
                                        store.removeSegment(
                                            montageStore.pushUndoStack,
                                        );
                                    },
                                },
                            },
                            {
                                id: "menu-item-clip-create-segment",
                                label: (
                                    <StyledMenuLabel>{"Edit"}</StyledMenuLabel>
                                ),
                                icon: (
                                    <Edit fontSize={"small"} color="primary" />
                                ),
                                hidden: isAgent || !store.onClipGenerated,
                                action: store.getTaskLoading(CreateClipTask) ? (
                                    <AcxLoadingIndicator
                                        color="secondary"
                                        alternate="PuffLoader"
                                        size={75}
                                    />
                                ) : null,
                                props: {
                                    disabled:
                                        isAgent ||
                                        !!store.getTaskLoading(
                                            CreateClipTask,
                                        ) ||
                                        store.disableClip,
                                    onClick: () => {
                                        store.setPopoverOpen(true);
                                    },
                                },
                            },
                            {
                                id: "menu-item-clip-create",
                                label: (
                                    <StyledMenuLabel>
                                        {store.isExistingClip
                                            ? "Update"
                                            : "Clip"}
                                    </StyledMenuLabel>
                                ),
                                icon: (
                                    <ScissorsSvg
                                        color={theme.palette.blue.main}
                                    />
                                ),
                                hidden: isAgent || !store.onClipGenerated,
                                action: store.getTaskLoading(CreateClipTask) ? (
                                    <AcxLoadingIndicator
                                        color="secondary"
                                        alternate="PuffLoader"
                                        size={75}
                                    />
                                ) : null,
                                props: {
                                    disabled:
                                        isAgent ||
                                        store.isMaxClipTimeExceeded ||
                                        store.getTaskLoading(CreateClipTask) ||
                                        store.disableClip,

                                    onClick: () => {
                                        store.isExistingClip
                                            ? store.canUpdateClips &&
                                              store.updateLatestClipFromSegment()
                                            : store.createClipFromSegment();
                                    },
                                },
                            },
                        ]}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        open={
                            !!store.segmentMenuPosition.mouseX &&
                            !store.popoverOpen
                        }
                        onMenuClose={store.closeSegmentMenu}
                        anchorReference="anchorPosition"
                        anchorPosition={store.segmentMenuAnchorPosition}
                        rootStyle={{ alignItems: "center" }}
                    />
                )}
            </div>
        );
    },
);

export default SoundClipEditorV2;

SoundClipEditorV2.defaultProps = {
    generateMp3: true,
};
