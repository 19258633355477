import LicensedModule from "models/LicensedModule";
import BaseService from "./BaseService";

export class ModuleService extends BaseService {
    protected urlBase: string = "api/Modules";

    public async getLicensedModule(LicensedModuleId: string) {
        const res = await this.get<LicensedModule>(
            new URLSearchParams({ LicensedModuleId: LicensedModuleId }),
            "GetLicensedModule",
        );
        return res;
    }

    public async getAllLicensedModuleTypes(
        organizationId: string,
        evaluationId?: string,
    ) {
        const body = JSON.stringify({
            organizationId,
            evaluationId: evaluationId ?? "",
        });

        const res = await this.post<LicensedModule[]>(
            body,
            new URLSearchParams({}),
            "GetAllLicensedModuleTypes",
        );

        return res;
    }

    public async getLicensedModuleByTypeName(
        organizationId: string,
        typeName: string,
    ) {
        const res = await this.get<LicensedModule>(
            new URLSearchParams({
                OrganizationId: organizationId,
                ModuleTypeName: typeName,
            }),
            "GetLicensedModuleType",
        );
        return res;
    }

    public async getLicensedModules(): Promise<
        { id: string; title: string }[]
    > {
        return this.get(new URLSearchParams(), "GetLicensedModules");
    }
}
