import {
    Grid,
    IconButton,
    IconButtonProps,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import HistoryIcon from "@mui/icons-material/History";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { IAcxButtonProps } from "components/UI/AcxButton";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import useStyles from "../../Styles/Styles";
import { MinimalPlayer } from "./Controls/MinimalPlayer";
import Player from "./Controls/Player";
import SoundClipEditorStore, {
    DownloadAudioClipTask,
} from "./Stores/SoundClipEditorStore";

export const toggleZoomViewTooltip =
    "Toggle Zoom View - hold 'Shift' while clicking to create clips from the Zoom View";

const styles = (theme: Theme) =>
    createStyles({
        waveform: {
            width: "100%",
        },
        overview: {
            width: "100%",
            backgroundColor: theme.palette.lightBlue.background,
        },
        playIcon: {
            color: theme.palette.blue.main,
        },
        errorRefreshIcon: {
            color: theme.palette.red.main,
        },
        retryButton: {
            position: "absolute",
            right: "0%",
            top: "5%",
        },
        undoButton: {
            position: "absolute",
            right: "3px",
            top: "0px",
        },
        playbackRateContainer: {
            backgroundColor: theme.palette.blue.main,
            borderRadius: "0.25rem",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
        },
        zoomButtonContainer: {
            borderRadius: "0.25rem",
            display: "flex",
            alignContent: "center",
        },
    });

interface SoundClipVisualizerProps {
    viewModel: SoundClipEditorStore;
    editorHeight?: number;
    fullControls?: boolean;
    enablePlaybackRate?: boolean;
    allowUndo?: boolean;
    minimize?: boolean;
    noMenu?: boolean;
    enableZoom?: boolean;
    isMontage?: boolean;
    textButton?: string | null;
    textButtonProps?: Partial<IAcxButtonProps>;
    iconButton?: React.ReactNode;
    iconButtonProps?: Partial<IconButtonProps>;
}

const SoundClipVisualizer: React.FC<SoundClipVisualizerProps> = observer(
    (props) => {
        const classes = useStyles(styles);
        const theme = useTheme();
        const smallDownMatch = useMediaQuery(theme.breakpoints.down('md'));
        const largeUpMatch = useMediaQuery("(min-width:1450px)");
        const [shiftPressed, setShiftPressed] = React.useState<boolean>(false);

        useEffect(() => {
            if (props.viewModel.showZoomView) {
                props.viewModel.createSoloZoomview();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.viewModel.showZoomView]);

        useEffect(() => {
            if (props.viewModel.showZoomView) {
                props.viewModel.toggleZoomView();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.fullControls]);

        useEffect(() => {
            function handleKeyDown(event) {
                if (event.shiftKey) {
                    setShiftPressed(true);
                }
            }

            function handleKeyUp(event) {
                if (event.key === "Shift") {
                    setShiftPressed(false);
                }
            }

            window.addEventListener("keydown", handleKeyDown);
            window.addEventListener("keyup", handleKeyUp);

            return () => {
                window.removeEventListener("keydown", handleKeyDown);
                window.removeEventListener("keyup", handleKeyUp);
            };
        }, []);

        const containerHeight = props.fullControls ? "119px" : "84px";

        return <>
            <Grid
                container
                direction={props.fullControls ? "column" : "row"}
                wrap="nowrap"
                alignItems="center"
                justifyContent="flex-start"
                style={
                    props.isMontage &&
                    !!props.viewModel.getTaskError(DownloadAudioClipTask)
                        ? { marginTop: "1rem" }
                        : {}
                }
            >
                {!props.fullControls && (
                    <MinimalPlayer
                        viewModel={props.viewModel}
                        enablePlaybackRate={props.enablePlaybackRate}
                        minimize={props.minimize}
                        enableZoom={props.enableZoom}
                        iconButton={props.iconButton}
                        iconButtonProps={props.iconButtonProps}
                    />
                )}
                <Grid
                    container
                    item
                    xs={
                        props.fullControls
                            ? 12
                            : props.isMontage
                            ? largeUpMatch
                                ? 11
                                : 10
                            : 12
                    }
                >
                    <div
                        id="waveform"
                        className={classes.waveform}
                        ref={(el) => {
                            if (!el) {
                                props.viewModel.setCanvasRect(null);
                                return;
                            }

                            props.viewModel.setCanvasRect(
                                el.getBoundingClientRect(),
                            );
                        }}
                    >
                        {props.viewModel.showZoomView ? (
                            <div
                                className={classes.overview}
                                style={{
                                    height: containerHeight,
                                    cursor: props.noMenu
                                        ? "crosshair"
                                        : shiftPressed
                                        ? "ew-resize"
                                        : "grab",
                                }}
                                id={"zoomview-container"}
                                ref={props.viewModel.setZoomviewElement}
                                onMouseMove={
                                    props.viewModel.trackMouseCoordinates
                                }
                            />
                        ) : (
                            <div
                                className={classes.overview}
                                style={{
                                    height: containerHeight,
                                    cursor: props.noMenu
                                        ? "crosshair"
                                        : "ew-resize",
                                }}
                                id={"overview-container"}
                                ref={props.viewModel.setOverviewElement}
                                onMouseMove={
                                    props.viewModel.trackMouseCoordinates
                                }
                            />
                        )}

                        {Boolean(
                            props.viewModel.getTaskError(
                                DownloadAudioClipTask,
                            ),
                        ) && (
                            <div className={classes.retryButton}>
                                {props.viewModel.getTaskLoading(
                                    DownloadAudioClipTask,
                                ) ? (
                                    <Typography>Retrying ...</Typography>
                                ) : (
                                    <Tooltip title={"Retry clip download"}>
                                        <IconButton
                                            onClick={() =>
                                                props.viewModel
                                                    .getTaskError(
                                                        DownloadAudioClipTask,
                                                    )
                                                    ?.task?.()
                                            }
                                            size="large">
                                            <SyncProblemIcon
                                                fontSize={
                                                    smallDownMatch ||
                                                    props.minimize
                                                        ? "small"
                                                        : "large"
                                                }
                                                className={
                                                    classes.errorRefreshIcon
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </div>
                    {Boolean(props.allowUndo) && (
                        <div className={classes.undoButton}>
                            <IconButton size="large">
                                <HistoryIcon />
                            </IconButton>
                        </div>
                    )}

                    <audio
                        ref={props.viewModel.setAudioElement}
                        onPlay={props.viewModel.onPlayingEvent}
                        onPause={props.viewModel.onPausingEvent}
                    ></audio>
                </Grid>

                {props.fullControls && (
                    <Player
                        enablePlaybackRate
                        viewModel={props.viewModel}
                        enableZoom={props.enableZoom}
                        iconButton={props.iconButton}
                        iconButtonProps={props.iconButtonProps}
                        textButton={props.textButton}
                        textButtonProps={props.textButtonProps}
                    />
                )}
            </Grid>
        </>;
    },
);

export default SoundClipVisualizer;
