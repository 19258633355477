import { observer } from "mobx-react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";
import React from "react";

export const HierarchiesFilter = observer(function ({
    id,
    store,
    filterKey,
    ...props
}: IApplicationFilterComponent &
    Partial<React.ComponentProps<typeof AcxHierarchySelector>>) {
    const authStore = useStore(AuthStore);

    return (
        <AcxHierarchySelector
            orgId={authStore.orgStore.selectedOrganization?.id ?? ""}
            userId={authStore._user.profile.sub}
            onSaveUpdateWithHierarchies={() => null}
            displayType="input"
            removeMinHeight
            removeMinWidth
            customZIndex={9999}
            // popperPlacement="top"
            width="360px"
            {...props}
            setHierarchyIds={(ids) => {
                props.setHierarchyIds?.(ids);
                store.setHierarchyIds(ids);
            }}
            initSelectedHierarchyIds={store.hierarchyIds}

            // This allows us to navigate away to and from conversations/signals
            // and always maintain the selected (sub)categories
            storeFilterCategories={(categories) =>
                (store.selectedCategories = categories)
            }
            storeFilterSubategories={(subcategories) =>
                (store.selectedSubcategories = subcategories)
            }
            initCategories={{
                categories: store.selectedCategories,
                subcategories: store.selectedSubcategories,
            }}
        />
    );
});
