/* eslint-disable react/jsx-no-comment-textnodes */
import AddIcon from "@mui/icons-material/Add";
import { ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { VizType } from "../../../../../../models/Reporting/ReportModel";
import hexToRGB from "../../../../../../utils/hexToRGB";
import { useStore } from "../../../../../../utils/useStore";
import AcxSelectSingle from "../../../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import { QueryFilterOptions } from "../Editor";
import FilterRules from "../FilterRules";
import QueryFilterSection from "./QueryFilterSection";
import TableDataSection from "./TableDataSection";
import VizDataSection from "./VizDataSection";

interface OwnProps {}

type Props = OwnProps;

const ReportDataSection: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const theme = useTheme();
    const store = useStore(ReportEditorStore);

    const returnFilterSection = () => {
        let maxCount = 0;
        let components: any = [];
        store.currentReport?.filterSectionCount !== undefined
            ? (maxCount = store.currentReport?.filterSectionCount)
            : (maxCount = 0);
        for (let index = 0; index <= maxCount; index++) {
            if (index > 0) {
                components.push(
                    <React.Fragment key={`report-or-filter-${index}`}>
                        <Grid
                            item
                            xs={12}
                            sx={{ textAlign: "center", paddingTop: "15px" }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: "Inter",
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    letterSpacing: "0",
                                    lineHeight: "28px",
                                }}
                            >
                                OR
                            </Typography>
                        </Grid>
                    </React.Fragment>,
                );
            }
            components.push(
                <React.Fragment key={`report-filters-${index}`}>
                    <Grid
                        item
                        xs={12}
                        container
                        sx={{
                            borderStyle: "solid",
                            padding: "7px",
                            marginTop: "15px",
                            backgroundColor: "#FFFBEB",
                        }}
                    >
                        <FilterRules displayId={index} />
                    </Grid>
                </React.Fragment>,
            );
        }
        return components;
    };

    return (
        <>
            <Grid item xs={12} sx={{ paddingBottom: "17px" }}>
                <Typography
                    sx={{
                        fontFamily: "Inter",
                        fontSize: "20px",
                        fontWeight: "bold",
                        letterSpacing: "0",
                        lineHeight: "28px",
                    }}
                >
                    Report Data
                </Typography>
            </Grid>
            <Grid
                container
                item
                xs={12}
                sx={{ paddingBottom: "17px" }}
                justifyContent="space-between"
            >
                <Grid item xs={5}>
                    <AcxSelectSingle
                        options={store.reportDataViews ?? []}
                        id={"report-data-selector"}
                        onChange={store.setCurrentReportDataView}
                        defaultValue={store.currentReport?.dataView}
                        valueField={"id"}
                        labelField={"displayName"}
                        fullWidth
                        inputLabel={"What data would you like to report?"}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid
                    item
                    xs={5}
                    sx={{
                        padding: "7px",
                        alignItems: "center",
                        textAlign: "center",
                        display: "table-cell",
                        background: "lightgrey",
                        borderRadius: "4px",
                        width: "max-content",
                    }}
                >
                    <QueryFilterSection />
                </Grid>
            </Grid>
            {store.queryFilterOption === QueryFilterOptions.Custom &&
                returnFilterSection()}
            {store.queryFilterOption === QueryFilterOptions.Custom && (
                <ButtonBase
                    sx={{
                        textTransform: "none",
                        color: theme.palette.blue.main,
                        marginTop: theme.spacing(3),
                    }}
                    onClick={store.currentReport?.addFilterSection}
                >
                    <div
                        style={{
                            width: "16px",
                            height: "16px",
                            backgroundColor: hexToRGB(
                                theme.palette.blue.main,
                                0.2,
                            ),
                            borderRadius: "50%",
                        }}
                    >
                        <AddIcon
                            sx={{
                                width: "10px",
                                height: "10px",
                                marginTop: "2px",
                                textAlign: "center",
                            }}
                        />
                    </div>
                    <Typography
                        sx={{
                            color: theme.palette.blue.main,
                            fontSize: "13px",
                            fontWeight: "bold",
                            letterSpacing: 0,
                            lineHeight: "20px",
                            marginLeft: theme.spacing(1),
                        }}
                    >
                        Add OR Condition
                    </Typography>
                </ButtonBase>
            )}
            <Grid item xs={12} sx={{ marginTop: theme.spacing(3) }} container>
                {isGraphicalReportType(
                    store.currentReport?.vizType ?? VizType.Bar,
                ) ? (
                    <VizDataSection />
                ) : (
                    <TableDataSection />
                )}
            </Grid>
        </>
    );
});

export default ReportDataSection;

function isGraphicalReportType(vizType: VizType) {
    return (
        vizType === VizType.Bar ||
        vizType === VizType.Line ||
        vizType === VizType.Pie ||
        vizType === VizType.Histogram ||
        vizType === VizType.Scatter ||
        vizType === VizType.HorizontalBar
    );
}
