import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { Observer, observer } from "mobx-react";
import React from "react";
import OrganizationWorkflowEmailStore, {
    DELETE_EMAIL,
} from "./Stores/OrganizationWorkflowEmailStore";

type Props = {
    store: OrganizationWorkflowEmailStore;
};

const OrganizationWorkflowListEmails: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ store }) => {
    React.useEffect(() => {
        store.dataGridStore.controls = [
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            fullWidth
                            disabled={store.isEditDisabled}
                            color="primary"
                            style={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<EditIcon />}
                            onClick={store.editWorkflowEmailTemplate}
                        >
                            Edit
                        </AcxButton>
                    </div>
                )}
            </Observer>,
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            fullWidth
                            disabled={store.isEditDisabled}
                            loading={store.getTaskLoading(DELETE_EMAIL)}
                            color="primary"
                            style={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<CloseIcon />}
                            onClick={store.deleteWorkflowEmailTemplate}
                        >
                            Delete
                        </AcxButton>
                    </div>
                )}
            </Observer>,
        ];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.organizationId]);

    return (
        <Grid item xs={12} style={{ height: "65vh" }}>
            <AcxDataGrid dataGridStore={store.dataGridStore} />
        </Grid>
    );
});

export default OrganizationWorkflowListEmails;
