import { Box, Grid, Paper } from "@mui/material";
import clsx from "clsx";
import { PlayerOptions } from "components/SoundClipEditor/SoundClipEditor";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import {
    IMultiLanguageTranscriptionPayload,
    ITranscriptionSegment,
} from "services/TranscriptionService";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { AcxTranscriptionStore } from "./Stores/AcxTranscriptionStore";
import { styles } from "./styles";
import { AcxTranscriptionSidebar } from "./Components/Sidebar";
import { EddyEffectP2Results } from "models/EddyEffectP2Results";
import { AcxTranscriptionBody } from "./Components/AcxTranscriptionBody";
import { SpeakerStore } from "./Stores/SpeakerStore/SpeakerStore";

interface AcxTranscriptionBaseProps {
    transcriptWords: ITranscriptionSegment[];
    eddyEffectP2Data: EddyEffectP2Results[];
    onClip?: (start: number, end: number) => void;
    hasEvaluation: boolean;
    onSelectedClip?: (options: PlayerOptions) => void;
    showTranslationUI?: boolean;
    amdId?: string;
    speakerStore?: SpeakerStore;
    disableClip?: boolean;
}

interface AcxTranscriptionFull extends AcxTranscriptionBaseProps {
    selectedLanguageCode?: IMultiLanguageTranscriptionPayload["languageCode"];
    isTranslated?: boolean;
    onChangeLanguage?: (
        languageCode: IMultiLanguageTranscriptionPayload["languageCode"],
    ) => void;
    audioClip?: never;
    minimal?: false;
}

interface AcxTranscriptionMinimal extends AcxTranscriptionBaseProps {
    selectedLanguageCode?: never;
    isTranslated?: never;
    onChangeLanguage?: never;
    audioClip?: { status: "default" | "success" | "error" | "loading" };
    minimal?: true;
}

type AcxTranscriptionProps = AcxTranscriptionFull | AcxTranscriptionMinimal;

export const AcxTranscription: React.FC<AcxTranscriptionProps> = observer(
    ({
        transcriptWords,
        eddyEffectP2Data,
        onClip,
        onSelectedClip,
        showTranslationUI,
        isTranslated,
        onChangeLanguage,
        selectedLanguageCode,
        hasEvaluation,
        audioClip,
        minimal,
        amdId,
        speakerStore,
        disableClip,
    }) => {
        const classes = useStyles(styles);
        const store = useStore(AcxTranscriptionStore);
        const authstore = useStore(AuthStore);

        if (!authstore.canUserView("Transcription")) {
            return null;
        }

        useEffect(() => {
            store.setWords(transcriptWords);
            store.setEddyEffect(eddyEffectP2Data);
            store.setHasEval(hasEvaluation);
        }, [eddyEffectP2Data, hasEvaluation, store, transcriptWords]);

        const handleChecked = (checked: boolean) => {
            store.isCheckedEddyEffectP2 = checked;
            store.searchString = "";
        };

        useEffect(() => {
            speakerStore?.setAmdId(amdId);
        }, [amdId, speakerStore]);

        useEffect(() => {
            return () => {
                store.resetHighlightedText();
            };
        }, [store]);

        if (minimal) {
            return (
                <AcxTranscriptionBody
                    hasEvaluation={hasEvaluation}
                    playerBlurb={onSelectedClip}
                    audioClip={audioClip}
                    onClip={onClip}
                    speakerStore={speakerStore}
                    disableClip={disableClip}
                />
            );
        }

        return (
            <Paper square className={clsx(classes.content)}>
                <Grid
                    item
                    xs={12}
                    style={{ display: "flex", height: "100%" }}
                    className={clsx("pendo-ignore")}
                >
                    <Grid
                        item
                        xs={2}
                        style={{
                            marginLeft: "1%",
                            overflowY: "auto",
                        }}
                    >
                        <AcxTranscriptionSidebar
                            showTranslationUI={showTranslationUI}
                            handleChecked={handleChecked}
                            selectedLanguageCode={selectedLanguageCode}
                            onChangeLanguage={onChangeLanguage}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={10}
                        style={{
                            marginLeft: "2%",
                        }}
                    >
                        {showTranslationUI && isTranslated && (
                            <Grid
                                container
                                item
                                xs={12}
                                style={{ position: "sticky" }}
                            >
                                <Box
                                    fontFamily={"Inter"}
                                    fontStyle={"italic"}
                                    fontWeight={"bold"}
                                    style={{
                                        color: "gray",
                                    }}
                                >
                                    Translated transcript
                                </Box>
                            </Grid>
                        )}
                        <Box style={{ overflow: "auto", height: "100%" }}>
                            <AcxTranscriptionBody
                                hasEvaluation={hasEvaluation}
                                playerBlurb={onSelectedClip}
                                onClip={onClip}
                                speakerStore={speakerStore}
                                disableClip={disableClip}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        );
    },
);
