import { Grid, GridSize, Theme, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { Observer, observer } from "mobx-react";
import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import AcxChip from "../../components/UI/AcxChip";
import AcxMenu, {
    AcxMenuItemProps,
    StyledMenuLabel,
} from "../../components/UI/Menu/AcxMenu";
import { BaseWidgetDefinition } from "../../components/Widgets/Definitions/BaseWidgetDefinition";
import { WidgetType } from "../../models/Dashboard/Widget";
import { DateReferenceOption } from "../../stores/ComponentStores/DatePickerComponentStore";
import { DashboardStore } from "../../stores/Layout/DashboardStore";
import {
    CustomDynamicTimeSpan,
    DynamicTimeSpan,
    isCustomDynamicTypeSpan,
    quarterLabelHelper,
    RefreshInterval,
} from "../../utils/reportingTimeHelpers";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";
import { getDateReferenceOptionLabel } from "utils/DateTimeUtils";
import { styled } from "@mui/system";

const useStyles = makeStyles((theme: Theme) => ({
    root: (props: Props) => ({
        height: "100%",
        width: "100%",
        position: "relative",
    }),
    info: {
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(0.25),
        color: theme.palette.text.primary,
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        width: "100%",
    },
    controlsSection: (props: Props) => ({
        height: props.orientation === "vertical" ? "100%" : "32px",
        width: "100%",
        marginBottom: theme.spacing(1.25),
        // overflow: props.orientation === "vertical" ? "hidden" : "auto",
        position: props.orientation !== "vertical" ? "sticky" : "inherit",
        paddingLeft: props.orientation !== "vertical" ? "16px" : "0px",
        paddingRight: props.orientation !== "vertical" ? "16px" : "0px",
    }),

    customControlsSection: (props: Props) => ({
        height: props.orientation === "vertical" ? "100%" : "32px",
        width: "100%",
        marginBottom: theme.spacing(1.25),
        // overflow: props.orientation === "vertical" ? "hidden" : "auto",
        position: props.orientation !== "vertical" ? "sticky" : "inherit",
        paddingLeft: props.orientation !== "vertical" ? "32px" : "0px",
        paddingRight: props.orientation !== "vertical" ? "16px" : "0px",
    }),
    embeddedInfoText: {
        // fontWeight: "bold",
        fontStyle: "italic",
        color: theme.palette.text.disabled,
    },
    chipValue: {
        color: theme.palette.black.main,
        fontWeight: "bold",
    },
    menuItemStyles: {
        color: "inherit",
        "&:active": {
            color: theme.palette.primary[500] + "!important",
            backgroundColor: theme.palette.secondary[50],
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.primary[500],
            },
        },
    },
}));
// const firstChipStyle = {
//     // marginLeft: "8px",
//     // marginRight: "16px",
//     borderRadius: "16px",
//     paddingLeft: "4px",
//     paddingRight: "4px",
// };

const InfoTypography = styled(Typography)(({ theme }) => ({
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
    color: theme.palette.text.primary,
    wordWrap: "break-word",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    // fontFamily: theme.typography.fontFamily,
    // fontSize: theme.typography.fontSize,
    width: "100%",
}));

const restChipStyles = {
    // marginRight: "16px",
    borderRadius: "16px",
    paddingLeft: "4px",
    paddingRight: "4px",
};

interface OwnProps {
    widgetDefinition?: BaseWidgetDefinition;
    dashboardStore: DashboardStore;
    orientation?: "horizontal" | "vertical";
    forceEditMode?: boolean;

    customControls?: () => React.ReactNode;
}

type Props = OwnProps;

type enumControlItems = {
    refresh: Array<[string, string]>;
    timespan: Array<[string, string]>;
    dateref: Array<[string, string]>;
};
const initState = {
    refresh: Object.entries(RefreshInterval),
    timespan: Object.entries(DynamicTimeSpan),
    dateref: Object.entries(DateReferenceOption),
} as enumControlItems;

const DISABLE_CUSTOM: string = "DISABLE";
const DashboardControls: React.FC<Props> = observer((props) => {
    const classes = useStyles(props);

    const [menuItems, setMenuItems] = useState<
        AcxMenuItemProps[] | undefined
    >();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [enumItems, setEnumItems] = useState<enumControlItems>(initState);
    const handleClose = useCallback(function handleClose() {
        setMenuItems(undefined);
        setAnchorEl(null);
    }, []);

    useEffect(() => {
        const newEnumItems = { ...initState };

        if (props.widgetDefinition) {
            newEnumItems.dateref = [
                [DISABLE_CUSTOM, "Use Global"],
                ...newEnumItems.dateref,
            ];
            newEnumItems.timespan = [
                [DISABLE_CUSTOM, "Use Global"],
                ...newEnumItems.timespan,
            ];
            newEnumItems.refresh = [
                [DISABLE_CUSTOM, "Use Global"],
                ...newEnumItems.refresh,
            ];
        }
        setEnumItems(newEnumItems);
    }, [props.dashboardStore.members, props.widgetDefinition]);

    const timeRangeLabel = () => {
        let res =
            props.widgetDefinition?.dashboardItem?.timeSpan ??
            props.dashboardStore.globalTimeSpan;

        if (isCustomDynamicTypeSpan(res)) {
            return `${moment(res.startDate).format("MM/DD/YY")} - ${moment(
                res.endDate,
            ).format("MM/DD/YY")}`;
        }

        return quarterLabelHelper(res);
    };

    const dashboardFilterMenuItems: AcxMenuItemProps[] = [
        ...(props.dashboardStore.availableReportFilters?.entries() ?? []),
    ]
        .insertAt(0, ["None", "disable_custom_filters"])
        .map((value) => ({
            id: value[0],
            label: <StyledMenuLabel>{value[0]}</StyledMenuLabel>,
            props: {
                onClick: () => {
                    props.widgetDefinition?.setItemCustomArgs(
                        "dashboardFilterKey",
                        value[0],
                    );
                    handleClose();
                },
            },
        }));

    // const refreshMenuItems: AcxMenuItemProps[] = useMemo(
    //     () =>
    //         enumItems.refresh.map((value) => ({
    //             id: value[0],
    //             label: value[1],
    //             props: {
    //                 onClick: (event) => {
    //                     if (props.widgetDefinition) {
    //                         if (value[0] === DISABLE_CUSTOM) {
    //                             props.widgetDefinition.setItemRefreshInterval(
    //                                 null,
    //                             );
    //                         } else {
    //                             props.widgetDefinition.setItemRefreshInterval(
    //                                 value[1] as RefreshInterval,
    //                             );
    //                         }
    //                     } else {
    //                         props.dashboardStore?.updateGlobalRefreshInterval(
    //                             value[1] as RefreshInterval,
    //                         );
    //                     }
    //                     handleClose();
    //                 },
    //             },
    //         })),
    //     [
    //         enumItems.refresh,
    //         handleClose,
    //         props.dashboardStore,
    //         props.widgetDefinition,
    //     ],
    // );

    const timeSpanMenuItems: AcxMenuItemProps[] = useMemo(
        () =>
            enumItems.timespan.map((value) => {
                const timeSpanValue = value[1] as DynamicTimeSpan;
                const isQuarterValue =
                    timeSpanValue === DynamicTimeSpan.QUARTER ||
                    timeSpanValue === DynamicTimeSpan.PREVQUARTER1 ||
                    timeSpanValue === DynamicTimeSpan.PREVQUARTER2 ||
                    timeSpanValue === DynamicTimeSpan.PREVQUARTER3 ||
                    timeSpanValue === DynamicTimeSpan.PREVQUARTER4;

                return {
                    id: value[0],
                    label:
                        timeSpanValue === DynamicTimeSpan.CUSTOM ? (
                            <Observer>
                                {() => {
                                    const timespan:
                                        | DynamicTimeSpan
                                        | CustomDynamicTimeSpan =
                                        props.widgetDefinition?.dashboardItem
                                            ?.timeSpan ??
                                        props.dashboardStore.globalTimeSpan;
                                    return (
                                        <AcxDateRangeInput
                                            labelText={
                                                "Select Custom Date Range"
                                            }
                                            defaultStartDate={moment(
                                                isCustomDynamicTypeSpan(
                                                    timespan,
                                                )
                                                    ? moment(timespan.startDate)
                                                    : moment()
                                                          .subtract(1, "week")
                                                          .startOf("date"),
                                            )}
                                            defaultEndDate={moment(
                                                isCustomDynamicTypeSpan(
                                                    timespan,
                                                )
                                                    ? moment(timespan.endDate)
                                                    : moment(),
                                            )}
                                            onSelect={(
                                                start: Moment,
                                                end: Moment,
                                            ) => {
                                                const tempStart = moment(start);
                                                const tempEnd = moment(end);

                                                if (tempStart > tempEnd) {
                                                    start = tempEnd;
                                                    end = tempStart;
                                                }

                                                if (props.widgetDefinition) {
                                                    props.widgetDefinition.setItemTimespan(
                                                        {
                                                            startDate:
                                                                start.toISOString(),
                                                            endDate:
                                                                end.toISOString(),
                                                        } as CustomDynamicTimeSpan,
                                                    );
                                                } else {
                                                    props.dashboardStore?.updateGlobalTimeSpan(
                                                        {
                                                            startDate:
                                                                start.toISOString(),
                                                            endDate:
                                                                end.toISOString(),
                                                        } as CustomDynamicTimeSpan,
                                                    );
                                                }
                                            }}
                                            maxWidth={"200px"}
                                        />
                                    );
                                }}
                            </Observer>
                        ) : isQuarterValue ? (
                            <StyledMenuLabel>
                                {quarterLabelHelper(timeSpanValue)}
                            </StyledMenuLabel>
                        ) : (
                            <StyledMenuLabel>{timeSpanValue}</StyledMenuLabel>
                        ),
                    props: {
                        onClick: (event: any) => {
                            if (timeSpanValue !== DynamicTimeSpan.CUSTOM) {
                                if (props.widgetDefinition) {
                                    if (value[0] === DISABLE_CUSTOM) {
                                        props.widgetDefinition.setItemTimespan(
                                            null,
                                        );
                                    } else {
                                        props.widgetDefinition.setItemTimespan(
                                            timeSpanValue,
                                        );
                                    }
                                } else {
                                    props.dashboardStore?.updateGlobalTimeSpan(
                                        timeSpanValue,
                                    );
                                }
                                handleClose();
                            }
                        },
                    },
                };
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            enumItems.timespan,
            handleClose,
            props.dashboardStore,
            props.widgetDefinition,
        ],
    );

    const dateRefMenuItems: AcxMenuItemProps[] = useMemo(
        () =>
            enumItems.dateref
                .map((value) => ({
                    id: value[0],
                    label: (
                        <StyledMenuLabel>
                            {getDateReferenceOptionLabel(value[1])}
                        </StyledMenuLabel>
                    ),
                    props: {
                        onClick: () => {
                            if (props.widgetDefinition) {
                                if (value[0] === DISABLE_CUSTOM) {
                                    props.widgetDefinition.setItemDateReference(
                                        null,
                                    );
                                } else {
                                    props.widgetDefinition.setItemDateReference(
                                        value[1] as DateReferenceOption,
                                    );
                                }
                            } else {
                                props.dashboardStore?.updateGlobalDateReference(
                                    value[1] as DateReferenceOption,
                                );
                            }

                            handleClose();
                        },
                    },
                }))
                .filter((item) => item.id !== "CreatedOnDate"),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            enumItems.dateref,
            handleClose,
            props.dashboardStore,
            props.widgetDefinition,
        ],
    );

    function handleClick(
        event: React.MouseEvent<HTMLButtonElement>,
        whichMenu:
            | "TimeSpan"
            // | "Refresh"
            | "DateReference"
            | "CustomFilters"
            | "Hierarchy",
    ) {
        // if (whichMenu === "Refresh") {
        //     setMenuItems(refreshMenuItems);
        // } else
        if (whichMenu === "TimeSpan") {
            setMenuItems(timeSpanMenuItems);
        } else if (whichMenu === "DateReference") {
            setMenuItems(dateRefMenuItems);
        } else if (whichMenu === "CustomFilters") {
            setMenuItems(dashboardFilterMenuItems);
        } else if (whichMenu === "Hierarchy") {
            // TODO: look into refactoring hierarchy menuItems into more readable object, vs empty array
            setMenuItems([]);
        }
        setAnchorEl(event.currentTarget);
    }

    let gridSizing: GridSize = "auto";
    if (props.orientation === "vertical") {
        gridSizing = 12;
    }

    const buttonDisabled =
        props.dashboardStore.anyTaskLoading ||
        (!props.dashboardStore.editMode && !props.forceEditMode);

    let hierarchyChipLabel;

    if (Boolean(props.widgetDefinition?.hierarchyIds)) {
        if (props.widgetDefinition?.dashboardItem?.hierarchyIds?.length) {
            hierarchyChipLabel =
                props.widgetDefinition?.dashboardItem?.hierarchyIds?.length +
                " selected";
        } else {
            hierarchyChipLabel = "All selected";
        }
    } else {
        if (props.dashboardStore?.hierarchySelections?.length) {
            hierarchyChipLabel =
                props.dashboardStore?.hierarchySelections.length + " selected";
        } else {
            hierarchyChipLabel = "All selected";
        }
    }

    return (
        <>
            <div className={classes.controlsSection}>
                <Grid
                    container
                    direction={
                        props.orientation === "vertical" ? "column" : "row"
                    }
                    justifyContent={"flex-start"}
                    style={{ width: "100%" }}
                    spacing={props.orientation === "vertical" ? 3 : 1}
                    alignItems={
                        props.orientation === "vertical"
                            ? "flex-start"
                            : "center"
                    }
                >
                    {/*<Grid item zeroMinWidth xs={gridSizing}>
                        <>
                            {" "}
                            {props.orientation === "vertical" && (
                                <Typography className={classes.info} noWrap>
                                    Refresh interval to automatically refresh{" "}
                                    <span className={classes.embeddedInfoText}>
                                        {props.widgetDefinition?.title}
                                    </span>{" "}
                                    widget
                                </Typography>
                            )}
                        </>
                         <AcxChip
                            style={firstChipStyle}
                            label={
                                <Typography
                                    component={"div"}
                                    noWrap
                                    className={classes.chipLabel}
                                >
                                    Auto Refresh :{" "}
                                    <span className={classes.chipValue}>
                                        {props.widgetDefinition?.dashboardItem
                                            ?.refresh ??
                                            props.dashboardStore
                                                .globalRefreshInterval}
                                    </span>
                                </Typography>
                            }
                            color="blue"
                            size="small"
                            clickable={true}
                            disabled={buttonDisabled}
                            onClick={(evt) => handleClick(evt, "Refresh")}
                        /> 
                    </Grid>*/}
                    <Grid item zeroMinWidth xs={gridSizing}>
                        <>
                            {" "}
                            {props.orientation === "vertical" && (
                                <InfoTypography noWrap>
                                    Timespan that controls the range of data
                                    consumed by{" "}
                                    <span className={classes.embeddedInfoText}>
                                        {props.widgetDefinition?.title}
                                    </span>{" "}
                                    widget
                                </InfoTypography>
                            )}
                        </>
                        <AcxChip
                            style={restChipStyles}
                            label={
                                <Typography
                                    component={"div"}
                                    noWrap
                                    sx={{
                                        color: "inherit",
                                        fontWeight: "inherit",
                                        width: "100%",
                                        fontSize: "inherit",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    Time Range :{" "}
                                    <span className={classes.chipValue}>
                                        {timeRangeLabel()}
                                    </span>
                                </Typography>
                            }
                            color="blue"
                            size="small"
                            clickable={true}
                            disabled={buttonDisabled}
                            onClick={(evt) => handleClick(evt, "TimeSpan")}
                        />
                    </Grid>
                    <Grid item zeroMinWidth xs={gridSizing}>
                        <>
                            {" "}
                            {props.orientation === "vertical" && (
                                <InfoTypography noWrap>
                                    Date Reference option that determines which
                                    type of datetime{" "}
                                    <span className={classes.embeddedInfoText}>
                                        {props.widgetDefinition?.title}
                                    </span>{" "}
                                    widget uses to filter data
                                </InfoTypography>
                            )}
                        </>
                        <AcxChip
                            style={restChipStyles}
                            label={
                                <Typography
                                    component={"div"}
                                    noWrap
                                    sx={{
                                        color: "inherit",
                                        fontWeight: "inherit",
                                        width: "100%",
                                        fontSize: "inherit",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    Date Reference :{" "}
                                    <span className={classes.chipValue}>
                                        {props.widgetDefinition?.dashboardItem
                                            ?.dateReference
                                            ? getDateReferenceOptionLabel(
                                                  props.widgetDefinition
                                                      ?.dashboardItem
                                                      ?.dateReference,
                                              )
                                            : getDateReferenceOptionLabel(
                                                  props.dashboardStore
                                                      .globalDateReference,
                                              )}
                                    </span>
                                </Typography>
                            }
                            color="blue"
                            size="small"
                            clickable={true}
                            disabled={buttonDisabled}
                            onClick={(evt) => handleClick(evt, "DateReference")}
                        />
                    </Grid>

                    <Grid item zeroMinWidth xs={gridSizing}>
                        <>
                            {" "}
                            {props.orientation === "vertical" && (
                                <InfoTypography noWrap>
                                    Service hierarcy option that determines
                                    which service hierarchy{" "}
                                    <span className={classes.embeddedInfoText}>
                                        {props.widgetDefinition?.title}
                                    </span>{" "}
                                    widget uses to filter data
                                </InfoTypography>
                            )}
                        </>
                        <AcxChip
                            style={{
                                ...restChipStyles,
                                width: "160px",
                            }}
                            label={
                                <Typography
                                    component={"div"}
                                    noWrap
                                    sx={{
                                        color: "inherit",
                                        fontWeight: "inherit",
                                        width: "100%",
                                        fontSize: "inherit",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    Hierarchy :{" "}
                                    <span className={classes.chipValue}>
                                        {hierarchyChipLabel}
                                    </span>
                                </Typography>
                            }
                            color="blue"
                            size="small"
                            clickable={true}
                            disabled={
                                props.dashboardStore.anyTaskLoading ||
                                (!props.dashboardStore.editMode &&
                                    !props.forceEditMode)
                            }
                            onClick={(evt) => {
                                if (!buttonDisabled) {
                                    handleClick(evt, "Hierarchy");
                                }
                            }}
                        />
                    </Grid>

                    {Boolean(
                        props.widgetDefinition &&
                            props.widgetDefinition.dashboardItem_
                                ?.widgetType === WidgetType.Report &&
                            dashboardFilterMenuItems.length > 0,
                    ) && (
                        <Grid item zeroMinWidth xs={gridSizing}>
                            <>
                                {" "}
                                {props.orientation === "vertical" && (
                                    <InfoTypography noWrap>
                                        Custom filters for Report widgets
                                        provided by the dashboard
                                    </InfoTypography>
                                )}
                            </>
                            <AcxChip
                                style={restChipStyles}
                                label={
                                    <Typography
                                        component={"div"}
                                        noWrap
                                        sx={{
                                            color: "inherit",
                                            fontWeight: "inherit",
                                            width: "100%",
                                            fontSize: "inherit",
                                            textOverflow: "ellipsis",
                                        }}
                                    >
                                        Custom Filter :{" "}
                                        <span className={classes.chipValue}>
                                            {props.widgetDefinition
                                                ?.dashboardItem?.args
                                                ?.dashboardFilterKey ?? "None"}
                                        </span>
                                    </Typography>
                                }
                                color="blue"
                                size="small"
                                clickable={true}
                                disabled={buttonDisabled}
                                onClick={(evt) =>
                                    handleClick(evt, "CustomFilters")
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
            {Boolean(props.customControls) && (
                <div className={classes.customControlsSection}>
                    {props.customControls?.()}
                </div>
            )}

            <>
                {menuItems &&
                    (menuItems.length ? (
                        <AcxMenu
                            menuItems={menuItems as any}
                            anchorElement={anchorEl}
                            onMenuClose={handleClose}
                        />
                    ) : (
                        <AcxHierarchySelector
                            displayType="popperOnly"
                            userId={
                                props.dashboardStore.authStore._user.profile.sub
                            }
                            orgId={props.dashboardStore.orgId ?? ""}
                            onSaveUpdateWithHierarchies={(ids) => {
                                if (props.widgetDefinition) {
                                    props.widgetDefinition.setItemHierarchyIds(
                                        ids,
                                    );
                                } else {
                                    props.dashboardStore.updateGlobalHierarchyIds(
                                        ids ?? [],
                                    );
                                }
                                handleClose();
                            }}
                            // Don't update hierarchies on click due to reactions on hierarchyId change in stores
                            setHierarchyIds={() => {}}
                            popperOpen={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            handlePopperClose={handleClose}
                            initSelectedHierarchyIds={
                                props.widgetDefinition
                                    ? props.widgetDefinition.hierarchyIds
                                    : props.dashboardStore.hierarchySelections
                            }
                            popToLeft={!!props.widgetDefinition}
                        />
                    ))}
            </>
        </>
    );
});

export default DashboardControls;
