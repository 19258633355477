import { useTheme } from "@mui/material";
import AcxMainExpansion from "components/UI/AcxMainExpansion";
import { Observer } from "mobx-react";
import Classifier from "models/ClassifierModel";
import React from "react";
import { RuleBuildStore } from "../../RuleBuildStore";
import AcxClassifierRuleItem from "./AcxClassifierRuleItem";

interface IAcxClassifierList {
    store: RuleBuildStore;
}

export default function AcxClassifierList(props: IAcxClassifierList) {
    const theme = useTheme();

    return (
        <Observer>
            {() => (
                <>
                    <Section
                        items={props.store.speechClassifiers}
                        kind={"Speech Classifiers"}
                        store={props.store}
                    />
                    <div
                        key={"spacer-1"}
                        style={{ height: theme.spacing(2) }}
                    />
                    <Section
                        items={
                            props.store.orgCampaigns.length
                                ? props.store.metaClassifiers
                                : props.store.metaClassifiers.filter(
                                      (classifier) =>
                                          classifier.name !== "Campaign",
                                  )
                        }
                        kind={"MetaData Classifiers"}
                        store={props.store}
                    />
                    <div
                        key={"spacer-2"}
                        style={{ height: theme.spacing(2) }}
                    />
                    <Section
                        items={props.store.machineLearningClassifiers}
                        kind={"ML Classifiers"}
                        store={props.store}
                    />
                    {!!props.store.extendedMetaClassifiers.length && (
                        <>
                            <div
                                key={"spacer-3"}
                                style={{ height: theme.spacing(2) }}
                            />
                            <Section
                                items={props.store.extendedMetaClassifiers}
                                kind={"Extended MetaData Classifiers"}
                                store={props.store}
                            />
                        </>
                    )}
                </>
            )}
        </Observer>
    );
}

function Section(props: {
    items: Classifier[];
    kind: string;
    store: RuleBuildStore;
}) {
    return (
        <Observer>
            {() => (
                <AcxMainExpansion
                    header={<div>{props.kind}</div>}
                    key={"section-header-" + props.kind}
                    name={props.kind}
                    body={
                        <>
                            {props.items.map(
                                (item: Classifier, index: number) => (
                                    <AcxClassifierRuleItem
                                        store={props.store}
                                        key={item.id}
                                        classifierItem={item}
                                        ruleId={
                                            props.store.createRule(item.id!)!
                                        }
                                        content={null}
                                        id={
                                            "classifier-item-" +
                                            index.toString()
                                        }
                                        name={item.name}
                                        type={item.classifierTypeName}
                                    />
                                ),
                            )}
                        </>
                    }
                />
            )}
        </Observer>
    );
}
