import { GridComparatorFn, GridRenderCellParams } from "@mui/x-data-grid-pro";
import AcxChipList from "components/UI/AcxChipList";
import _, { isObject } from "lodash";
import React from "react";

export const TagFormatter = (obj?: { delimiter?: string }) => {
    const fn = (params: GridRenderCellParams) => {
        const parms = obj;
        let tags: string;

        if (isObject(params)) {
            tags = params.value?.toString() ?? "";
        } else {
            tags = params;
        }

        const delim = parms?.delimiter ? parms?.delimiter : ",,";

        const strTags = _.uniqBy(tags?.split(delim), (arg) => arg);
        const parsed = strTags
            ?.filter((value) => Boolean(value))
            .map((value) => {
                const t = value.split("|");
                if (t.length > 1) {
                    return `${t[0]} : ${t[1]}`;
                }
                return `${t[0]}`;
            });

        const res = (
            <AcxChipList
                size={"small"}
                color={"blue"}
                values={parsed}
                height="100%"
                toolTip={parsed.join(", ")}
            />
        );

        return res;
    };

    return fn;
};

export const ChipComparator: GridComparatorFn = (
    v1,
    v2,
    param1,
    param2,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    const p1 = _.get(row1, param1.field)?.toString();
    const p2 = _.get(row2, param2.field)?.toString();

    if (!p1) return 1;
    if (!p2) return -1;
    return p1.localeCompare(p2);
};
