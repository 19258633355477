import { useCallback } from "react";
import { addSearch } from "shared/functions/addSearch";
import useList from "shared/hooks/useList";
import AnalystGroupService from "../OrganizationDetail/OrganizationAnalystGroups/AnalystGroup.service";
import { AnalystGroup } from "../types/AnalystGroup";

const useOrganizationAnalystGroups = (orgId: string) => {
    const getData = useCallback(async () => {
        const res = await AnalystGroupService.getAnalystGroups(orgId);
        const transformedRes = res.map((item) => ({
            ...item,
            members: (item.analystGroupMembers || [])
                .filter((item: any) => item.type === "Manager")
                .map((member) => ({
                    type: member.type,
                    userId: member.userId,
                })),
        }));
        return addSearch(transformedRes, ["groupName"]);
    }, [orgId]);

    const {
        isLoading,
        data: analystGroups,
        refetch,
    } = useList<AnalystGroup>({
        get: getData,
    });

    const save = (val: AnalystGroup) => {
        return AnalystGroupService.saveAnalystGroup(val);
    };

    return {
        analystGroups,
        isLoading,
        refetch,
        save,
    };
};

export default useOrganizationAnalystGroups;
