import React from "react";
import AcxDialog from "./Dialog/AcxDialog";
import AcxButton from "./AcxButton";
import copy from "copy-to-clipboard";
import { useStore } from "../../utils/useStore";
import MessageStore from "../ManagerInteractions/Stores/MessageStore";
import { observer } from "mobx-react";

interface Props {
    title?: string;
    sqlStatements: (string | undefined)[];
}

export const AcxViewSqlDialog: React.FC<Props> = observer(
    ({ title, sqlStatements }) => {
        const [modalOpen, setModalOpen] = React.useState(false);
        const messageStore = useStore(MessageStore);

        return (
            <>
                <AcxDialog
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    title={title || "SQL Queries"}
                    maxWidth="xl"
                    dialogContentChildren={sqlStatements
                        .filter((s) => !!s)
                        .map((s, i) => (
                            <React.Fragment key={i}>
                                <pre>{s}</pre>
                                <AcxButton
                                    onClick={() => {
                                        copy(s!);
                                        messageStore.logInfo(
                                            "Copied to clipboard",
                                        );
                                    }}
                                >
                                    Copy to clipboard
                                </AcxButton>
                            </React.Fragment>
                        ))}
                />
                <AcxButton onClick={() => setModalOpen(true)} color="white">
                    {sqlStatements?.length > 1
                        ? "View SQL Queries"
                        : "View SQL Query"}
                </AcxButton>
            </>
        );
    },
);
