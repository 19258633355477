import { Answer } from "models/Answer";
import BaseService from "./BaseService";

export class EvaluationDisputeService extends BaseService {
    protected urlBase: string = "api/EvaluationDispute";

    public async createEvaluationDispute(
        organizationId: string,
        evaluationId: string,
        answers: Answer[]
    ) {
        const params = new URLSearchParams({
            organizationId,
            evaluationId
        });

        const res = this.post(
            JSON.stringify(answers),
            params,
            "DisputeEval",
            { returnJson: true },
        );

        return res;
    }
}
