import { Grid, Link, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        forgotLink: {
            margin: theme.spacing(2),
        },
        bodyTextContainer: {
            textAlign: "center",
        },
    });

const origin = window.location.origin;

export default function PasswordSent() {
    const classes = useStyles(styles);

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
            direction="row"
        >
            <Grid item xs={12} className={classes.bodyTextContainer}>
                <Typography>
                    Follow the instruction in the email we just sent you to
                    reset your password.
                </Typography>
            </Grid>

            <Grid item container justifyContent="center" xs={12}>
                <Typography className={classes.forgotLink}>
                    <Link
                        href={`/authentication/login?returnUrl=${encodeURI(
                            origin,
                        )}/app`}
                        color="secondary"
                    >
                        Back to Log In
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
}
