import { cloneDeep, uniq } from "lodash";
import { Highlight } from "services/ConversationService";

const shouldRenderHighlightedText = (highlight?: string | null): boolean => {
    if (!highlight) return false;
    return highlight.includes("<b>") && highlight.includes("</b>");
};

export const filterHighlightData = (highlights: Highlight): Highlight => {
    if (!highlights) {
        return null;
    }

    // Deep copy to avoid modifying mobx store
    const highlightsCopy = cloneDeep(highlights);

    for (let key in highlightsCopy) {
        if (
            key.toLowerCase().includes("speaker") &&
            !shouldRenderHighlightedText(highlightsCopy[key])
        ) {
            highlightsCopy[key] = null;
        }
    }

    highlightsCopy.rbcHighlightResults = highlightsCopy.rbcHighlightResults
        ?.map((rbc) => {
            for (let key in rbc) {
                if (
                    key.toLowerCase().includes("speaker") &&
                    !shouldRenderHighlightedText(rbc[key])
                ) {
                    rbc[key] = null;
                }
            }
            return rbc;
        })
        .filter(
            (rbc) =>
                rbc.speaker0Highlights !== null ||
                rbc.speaker1Highlights !== null ||
                rbc.speaker2Highlights !== null ||
                rbc.speaker3Highlights !== null,
        );

    if (
        highlightsCopy.fullTextSearchHighlightsSpeaker0 === null &&
        highlightsCopy.fullTextSearchHighlightsSpeaker1 === null &&
        highlightsCopy.fullTextSearchHighlightsSpeaker2 === null &&
        highlightsCopy.fullTextSearchHighlightsSpeaker3 === null &&
        highlightsCopy.rbcHighlightResults.length === 0
    ) {
        return null;
    }

    return highlightsCopy;
};

export const defaultChatSpeakerNames = [
    "Agent",
    "Customer",
    "Customer 2",
    "Customer 3",
];

export const defaultCallSpeakerNames = [
    "Speaker 1",
    "Speaker 2",
    "Speaker 3",
    "Speaker 4",
];

function extractWord(wordWithTag: string): string {
    return wordWithTag.replace("<b>", "").replace("</b>", "");
}

/**
 * Selects the fragments that add the most new words/phrases based
 * on the list of already selected fragments. This ensures that we
 * get fragments with varying words and phrases and little duplicates.
 * @param highlight
 * @param maxFragments
 * @returns
 */
export function extractWordsAndFragmentsFromHighlight(
    highlight: string,
    maxFragments = 3,
): { fragments: string[]; words: string[] } {
    const rawFragments = highlight.split("...");

    const wordRegex = /<b>(.*?)<\/b>/g;

    if (rawFragments.length <= maxFragments) {
        return {
            words: uniq(highlight.match(wordRegex) ?? []).map(extractWord),
            fragments: rawFragments,
        };
    }

    const wordsAndFragments: { words: string[]; fragment: string }[] = [];

    for (const fragment of rawFragments) {
        const words = fragment.match(wordRegex) ?? [];
        wordsAndFragments.push({ words, fragment: fragment.trim() });
    }

    // Set of highlighted words within phrases already selected.
    // Allows us to select new phrases with highlighted words not in this set
    const usedWords = new Set<string>();
    const fragments: string[] = [];

    while (fragments.length < maxFragments) {
        let maxNewWords = -1;
        let maxIndex = -1;

        for (let i = 0; i < wordsAndFragments.length; i++) {
            const { words } = wordsAndFragments[i];

            let newWords = 0;
            for (const word of words) {
                if (!usedWords.has(word)) newWords++;
            }

            if (newWords > maxNewWords) {
                maxNewWords = newWords;
                maxIndex = i;
            }
        }

        if (maxIndex >= 0) {
            const [newFragment] = wordsAndFragments.splice(maxIndex, 1);
            fragments.push(newFragment.fragment);
            newFragment.words.forEach((word) => usedWords.add(word));
        }
    }

    return {
        fragments,
        words: Array.from(usedWords).map(extractWord),
    };
}
