import BaseService from "./BaseService";

interface IHistoryListItem {
    failedCount: number;
    warningCount: number;
    successCount: number;
    skipCount: number;
    numberOfExecutions: number;
    processed: number;
    totalItems: number;
    isMiddleOfTheFunnel: boolean;
    isOnDemandProcessing: boolean;
    reason: string;
    status:
        | "NotStarted"
        | "InProgress"
        | "Cancelled"
        | "Completed"
        | "CompletedWithErrors"
        | "CompletedWithWarnings"
        | "NothingToProcess";
    createdBy: string;
    ruleId: string | null;
    rulleName: string;
    id?: string;
}
export class MotfAutomationHistoryService extends BaseService {
    protected urlBase: string = "api/MotfAutomation/History";

    public async getMotfAutomationHistory(
        organizationId: string,
    ): Promise<IHistoryListItem[]> {
        return this.post(JSON.stringify({ organizationId }), new URLSearchParams({}), "");
    }
}
