import { Grid, Theme, Tooltip } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxMainTextField from "components/UI/AcxMainTextField";
import {
    BranchDataProps,
    HandleBranchProps,
} from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import { EMockOrgSetup, IFakeRecursiveTier } from "../RecursiveTreeTestData";
import { RecursiveTreeTestStore } from "../RecursiveTreeTestStore";
import RecursiveTreeTestAddRowIcon from "./RecursiveTreeTestAddRowIcon";

const styles = (theme: Theme) => {
    return createStyles({
        icon: {
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            cursor: "pointer",
        },
    });
};

interface RecursiveTreeTestBranchProps {
    branch: BranchDataProps;
    handleBranch: HandleBranchProps;
    store: RecursiveTreeTestStore;
}

const RecursiveTreeTestBranch: React.FC<RecursiveTreeTestBranchProps> =
    observer(({ branch, handleBranch, store }) => {
        const classes = useStyles(styles);
        const [name, setName] = React.useState<string>(branch.name ?? "");
        const [isLoading, setIsLoading] = React.useState<boolean>(false);

        // The following will prevent the UpdateBranch() method from being invoked on keydown. Instead, wait 1.5 seconds after the last keydown to fire.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        const debouncedNameUpdate = React.useCallback(
            _.debounce((name: string, branch: BranchDataProps) => {
                handleBranch({ ...branch, name: name }, "Update");
                setIsLoading(false);
            }, 1500),
            [],
        );

        // This handles the renaming of the branch
        const onNameChange = (name: string) => {
            setName(name);
            setIsLoading(true);
            debouncedNameUpdate(name, branch);
        };

        // This handles the deletion of the branch
        const handleDelete = () => {
            store.setActiveTier(branch as IFakeRecursiveTier);
            store.setShowDeleteDialog(true);
        };

        // The useEffect() hook fires every time the branch is interacted with. Interactions are tracked through the use of an observable property in the third-party store (Eg. the RecursiveTreeTestStore property `activeTier`). Once Fired, the handleBranch() method - propagated from RecursiveTreeBranch - is called to trigger the appropriate UI rerenders.
        React.useEffect(() => {
            if (
                store.endpointFired &&
                store.endpointFired.active &&
                store.activeTier
            ) {
                if (branch.id === store.activeTier.id) {
                    switch (store.endpointFired.payload) {
                        case "Add":
                            handleBranch(
                                store.activeTier as BranchDataProps,
                                "Add",
                            );

                            return;

                        case "Update":
                            handleBranch(
                                store.activeTier as BranchDataProps,
                                "Update",
                            );

                            return;

                        case "Remove":
                            handleBranch(
                                store.activeTier as BranchDataProps,
                                "Remove",
                            );

                            return;

                        default:
                            return;
                    }
                }
            }
        }, [
            store.endpointFired,
            store.endpointFired?.active,
            store.activeTier,
            branch.id,
            handleBranch,
        ]);

        return (
            <>
                <Grid container item xs={8} alignItems="center">
                    {/* Text field which houses the name of the branch */}
                    <AcxMainTextField
                        containerStyle={{
                            width: "100%",
                            padding: "0.25rem 1rem",
                        }}
                        fullWidth
                        id="name"
                        value={name}
                        onChange={(e) => {
                            onNameChange(e.target.value);
                        }}
                        labelText={EMockOrgSetup[branch.branchDepth ?? 0] ?? ""}
                    />
                </Grid>

                <Grid
                    container
                    item
                    xs={4}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {isLoading && (
                        <Grid item>
                            <AcxLoadingIndicator
                                size={25}
                                alternate="PuffLoader"
                            />
                        </Grid>
                    )}

                    {/* Enables branch addition */}
                    <RecursiveTreeTestAddRowIcon
                        branch={branch as IFakeRecursiveTier}
                        store={store}
                    />

                    {/* Enables branch deletion */}
                    <Tooltip title={"Delete Branch"}>
                        <DeleteIcon
                            htmlColor="#bebebe"
                            onClick={handleDelete}
                            cursor="pointer"
                            className={classes.icon}
                        />
                    </Tooltip>
                </Grid>
            </>
        );
    });

export default RecursiveTreeTestBranch;
