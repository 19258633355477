import { Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { BeatLoader } from "react-spinners";
import { DashboardStore } from "../../../stores/Layout/DashboardStore";
import theme from "../../../Theme/AppTheme";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import { BaseWidgetDefinition } from "../Definitions/BaseWidgetDefinition";
import WidgetMenu from "./WidgetMenu";

interface WidgetCardProps {
    dashboardStore?: DashboardStore;
    content: (cardHeight?: number, controlsHeight?: number) => React.ReactNode;
    widgetDefinition?: BaseWidgetDefinition;
    disableActions?: boolean;
    styles?: React.CSSProperties;
}

const WidgetCard = observer((props: WidgetCardProps) => {
    const { elementHeight, elementWidth, theRef } =
        useResizeObserver<HTMLDivElement>({
            // name: "widgetCardMain",
            debounceWait: 10,
        });

    const { elementHeight: controlsHeight, theRef: controlsRef } =
        useResizeObserver<HTMLDivElement>({
            // name: "widgetCardControls",
            debounceWait: 10,
        });

    return (
        <>
            <Grid
                container
                ref={theRef}
                item
                xs
                sx={{ height: "100%", width: "100%" }}
            >
                <Grid
                    container
                    item
                    xs
                    sx={(theme) => ({
                        height: "100%",
                        padding: props.styles?.padding ?? theme.spacing(1.5),
                        backgroundColor:
                            props.styles?.backgroundColor ??
                            theme.palette.white.main,
                        boxShadow:
                            props.styles?.boxShadow ??
                            "0 0 2px 1px rgba(0, 0, 0, 0.03), 0 6px 10px 2px rgba(0, 0, 0, 0.08)",
                        borderRadius: 5,
                    })}
                >
                    {(!!props.widgetDefinition?.useCardTitleAndMenu ||
                        !props.widgetDefinition?.viewModelReady) && (
                        <Grid
                            ref={controlsRef}
                            item
                            xs={12}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems={"flex-start"}
                            wrap={"nowrap"}
                        >
                            <Grid
                                item
                                xs
                                spacing={2}
                                container
                                alignItems={"baseline"}
                            >
                                <Grid item zeroMinWidth>
                                    <Typography
                                        noWrap
                                        variant="h5"
                                        sx={{
                                            userSelect: "none",
                                        }}
                                    >
                                        {props.widgetDefinition?.title}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <BeatLoader
                                        size={6}
                                        color={theme.palette.primary.main}
                                        loading={
                                            props.widgetDefinition?.loading
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <WidgetMenu
                                dashboardStore={props.dashboardStore}
                                disableActions={props.disableActions}
                                widgetDefinition={props.widgetDefinition}
                            />
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        xs={12}
                        style={{
                            height: "calc(100% - 8px)",
                            paddingTop: "8px",
                        }}
                    >
                        <div
                            style={{
                                height: `${
                                    (elementHeight ?? 0) -
                                    ((controlsHeight ?? 0) + 24)
                                }px`,
                                width: `${(elementWidth ?? 0) - 16}px`,
                            }}
                        >
                            {props.content(elementHeight, controlsHeight)}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});

export default WidgetCard;
