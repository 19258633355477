import {
    Button,
    ButtonBase,
    Divider,
    Grid,
    Grow,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SendIcon from "@mui/icons-material/Send";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import { BeatLoader } from "react-spinners";
import { Action } from "../../../../../models/Actions/Action";
import { EvalReviewActionMetadata } from "../../../../../models/Actions/Evaluation/EvalReviewActionMetadata";
import theme from "../../../../../Theme/AppTheme";
import {
    parseFromISO,
    standardDateTimeFormat,
} from "../../../../../utils/DateTimeUtils";
import { useStore } from "../../../../../utils/useStore";
import AcxMainTextField from "../../../../UI/AcxMainTextFieldGrid";
import { EvalStore } from "../../../Stores/EvalStore";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(1.5),
    },

    sideLeftPadding: (props: Props) => ({
        paddingLeft: props.level * parseInt(theme.spacing(5.5)),
    }),

    responseDetailsSection: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        // paddingLeft: theme.spacing(1.5)
    },
    notesContainer: {
        marginTop: theme.spacing(2),
    },

    subtitleText: {
        fontSize: "12px",
    },
    smallButtons: {
        maxHeight: "36px",
    },
    disablesSend: {
        color: theme.palette.text.disabled,
    },
    enablesSend: {
        color: theme.palette.primary.main,
    },
    sendButton: {
        fontWeight: "bold",
        textTransform: "none",
    },
    sendIcon: {
        marginRight: theme.spacing(2),
    },
    noWrapText: {
        whiteSpace: "nowrap",
    },

    replyButton: {
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "12px",
    },
}));

interface OwnProps {
    response: Action<EvalReviewActionMetadata>;
    level: number;
    responseIndex: number;
    enableComments: boolean;
    totalResponses: number;
}

type Props = OwnProps;

const ResponseDetails: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const store = useStore(EvalStore);
    const classes = useStyles(props);
    const [loading, setLoading] = useState<boolean>(false);
    const [reply, setReply] = useState<string>("");
    const [addingResponse, setAddingResponse] = useState(false);

    function onReplyChange(evt: React.ChangeEvent<HTMLInputElement>) {
        setReply(evt.currentTarget.value);
    }

    function canReplyTo() {
        return (
            props.enableComments &&
            props.response.userId !== store.user?.profile.sub &&
            props.response.actionMetadata.actionType !== "ReviewComment" &&
            !addingResponse
        );
    }

    async function onSubmitReply() {
        setLoading(true);
        store.replyToCompleteReviewAction(props.response, reply).finally(() => {
            setReply("");
            setAddingResponse(false);
            setLoading(false);
        });
    }

    return (
        <>
            <Grid
                container
                justifyContent={"flex-start"}
                className={clsx(classes.sideLeftPadding, classes.root)}
            >
                <Grid item container xs={12} spacing={1} alignItems={"center"}>
                    <Grid item>
                        <Typography
                            className={classes.subtitleText}
                            variant={"subtitle2"}
                            color={"textSecondary"}
                        >
                            {props.response.actionMetadata.actionType ===
                            "ReviewComment" ? (
                                <>Reply</>
                            ) : (
                                <>Response</>
                            )}{" "}
                            by {props.response.user.userName},{" "}
                            {standardDateTimeFormat(
                                parseFromISO(props.response.createdOn!),
                            )}
                        </Typography>
                    </Grid>
                    {canReplyTo() && (
                        <Grid item>
                            <Button
                                className={classes.replyButton}
                                variant={"text"}
                                color={"secondary"}
                                onClick={(event) => setAddingResponse(true)}
                            >
                                Reply
                            </Button>
                        </Grid>
                    )}

                    {Boolean(addingResponse && loading) && (
                        <Grid item>
                            <BeatLoader
                                size={9}
                                color={theme.palette.secondary.main}
                                loading={loading}
                            />
                        </Grid>
                    )}
                </Grid>

                <Grid container className={classes.notesContainer}>
                    <Grid item lg={6} md={8} xs={11}>
                        <Typography>
                            {props.response.actionMetadata.parsedMetadata.notes}
                        </Typography>
                    </Grid>

                    {addingResponse && (
                        <>
                            <Grid
                                item
                                xs={12}
                                className={classes.responseDetailsSection}
                                justifyContent={"flex-start"}
                                container
                                wrap={"nowrap"}
                            >
                                <Grid item lg={6} md={8} xs={11}>
                                    <AcxMainTextField
                                        onChange={onReplyChange}
                                        labelText={`Reply to ${props.response.user.userName}`}
                                        multiLine
                                        rows={2}
                                        rowsMax={3}
                                        placeholderText={""}
                                        id="response-reply-notes-action-id"
                                        value={reply ?? ""}
                                    />

                                    <Grid
                                        container
                                        justifyContent={"flex-end"}
                                        style={{ marginTop: "8px" }}
                                    >
                                        <Grid item>
                                            <ButtonBase
                                                className={clsx(
                                                    classes.noWrapText,
                                                    classes.smallButtons,
                                                    classes.sendButton,
                                                    Boolean(!reply) &&
                                                        classes.enablesSend,
                                                    !Boolean(!reply) &&
                                                        classes.disablesSend,
                                                )}
                                                disabled={!reply}
                                                onClick={onSubmitReply}
                                            >
                                                <Grow
                                                    in={!!reply}
                                                    timeout={400}
                                                >
                                                    <SendIcon
                                                        className={
                                                            classes.sendIcon
                                                        }
                                                    />
                                                </Grow>{" "}
                                                Reply
                                            </ButtonBase>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
            {props.responseIndex < props.totalResponses - 1 && (
                <Divider variant={"fullWidth"} />
            )}
        </>
    );
});

export default ResponseDetails;
