import { Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Theme } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

interface ControlledStyles {
    maxTruncatedLines: number;
}

const useStyles = (styles: ControlledStyles) =>
    makeStyles((theme: Theme) => ({
        text: {
            lineHeight: "normal",
        },
        truncatedText: {
            display: "-webkit-box",
            "-webkit-line-clamp": styles.maxTruncatedLines,
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
        },
        moreTextBtn: {
            cursor: "pointer",
            color: "#3564D5",
            userSelect: "none",
        },
    }))();

interface AcxExpandableTextProps {
    maxTruncatedLines?: number;
    pendoIgnore?: boolean;
    defaultExpanded?: boolean;
    containerClassName?: string;
}

export function AcxExpandableText({
    maxTruncatedLines = 3,
    defaultExpanded = false,
    pendoIgnore,
    containerClassName,
    children,
    ...props
}: React.ComponentPropsWithoutRef<typeof Typography> &
    React.PropsWithChildren<AcxExpandableTextProps>) {
    const classes = useStyles({ maxTruncatedLines });

    const textRef = useRef<React.ComponentRef<typeof Typography>>(null);
    const [isClamped, setIsClamped] = useState(true);

    useEffect(() => {
        function checkIsClamped() {
            if (!textRef.current) return;

            setIsClamped(
                textRef.current.clientHeight !== textRef.current.scrollHeight,
            );
        }

        checkIsClamped();

        window.addEventListener("resize", checkIsClamped);
        return () => window.removeEventListener("resize", checkIsClamped);
    }, [textRef]);

    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    return (
        <Grid
            container
            direction="column"
            gap={1}
            className={containerClassName}
        >
            <Grid item>
                <Typography
                    {...props}
                    ref={textRef}
                    className={clsx(
                        classes.text,
                        !isExpanded && classes.truncatedText,
                        pendoIgnore && "pendo-ignore",
                        props.className,
                    )}
                >
                    {children}
                </Typography>
            </Grid>
            {isClamped && (
                <Grid item>
                    <Typography
                        className={classes.moreTextBtn}
                        onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setIsExpanded((expanded) => !expanded);
                        }}
                    >
                        {isExpanded ? "Less..." : "More..."}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}
