import { Container, Grid, Paper, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AcxLogoLoader from "components/UI/Loaders/AcxLogoLoader";
import React from "react";
import useStyles from "Styles/Styles";
import bigLogo from "../../authenticx-logo-white-greenAsset.png";
import svgBackground from "../../Bckgnd.svg";

type Props = {
    title?: string | undefined;
    children?: React.ReactNode;
    isLoading?: boolean;
};
const styles = (theme: Theme) =>
    createStyles({
        gridContainer: {
            height: "calc(100% - 8px)",
        },
        topRow: {
            height: "20%",
        },
        bottomRow: {
            height: "80%",
            // backgroundImage: 'url(' + bgimage + ')',
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: 'cover',
            // // backgroundSize: '100% 100%',
            // backgroundColor: '#E3F7FF'
        },
        pageContainer: {
            // backgroundImage: 'url(' + bgimage + ')',
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // backgroundSize: '100% 100%',
            // backgroundColor: theme.palette.blue.main,
            backgroundColor: "#1D2960",
            padding: "0px",
            height: "100%",
        },
        childBox: {
            backgroundColor: theme.palette.white.main,
            width: "100%",
            padding: theme.spacing(5),
            borderRadius: "4px",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            zIndex: 200,
        },
        titleText: {
            color: theme.palette.black.main,
            fontSize: 20,
            fontWeight: "bold",
            margin: theme.spacing(4),
            marginTop: "0.5rem",
            marginBottom: "1rem",
        },
    });
export default function AuthPage(props: Props) {
    const classes = useStyles(styles);
    return (
        <Container maxWidth={false} className={classes.pageContainer}>
            <img
                src={svgBackground}
                alt=""
                style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    zIndex: 100,
                }}
            />

            <Grid
                container
                className={classes.gridContainer}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    item
                    xs={12}
                    className={classes.topRow}
                >
                    <img src={bigLogo} alt="" style={{ maxWidth: "220px" }} />
                </Grid>

                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    item
                    xs={12}
                    className={classes.bottomRow}
                >
                    <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent="center"
                        xs={4}
                    >
                        <Paper className={classes.childBox}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                    item
                                    xs={12}
                                >
                                    {/* <img src={smallLogo} style={{maxWidth: "64px"}} alt=""></img> */}
                                    <AcxLogoLoader
                                        height={64}
                                        isSpinning={
                                            props.isLoading ? true : false
                                        }
                                    />
                                </Grid>
                                {props.title && (
                                    <Grid
                                        container
                                        alignItems="center"
                                        justifyContent="center"
                                        item
                                        xs={12}
                                    >
                                        <Typography
                                            className={classes.titleText}
                                        >
                                            {props.title}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    {props.children}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
