import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";
import type {
    IReport,
    UnparseObject,
} from "components/Reports/Stores/ReportsStore";
import type { IReport as IReportV2 } from "components/ReportsRv2/Stores/ReportsStore";
import { action, makeObservable, observable, reaction } from "mobx";
import { Observer } from "mobx-react";
import { Moment } from "moment";
import Papa from "papaparse";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { ReportFilter } from "../../../models/Reporting/ReportFilter";
import { DateReferenceOption } from "../../../stores/ComponentStores/DatePickerComponentStore";
import {
    DashboardStore,
    IDashboardItem,
} from "../../../stores/Layout/DashboardStore";
import { ThemeColors } from "../../../Theme/AppTheme";
import {
    CustomDynamicTimeSpan,
    DynamicTimeSpan,
    RefreshInterval,
    timeSpanToMoments,
} from "../../../utils/reportingTimeHelpers";
import { ReportDataViewModel } from "../../Reports/Common/ReportDataViewModel";
import { buildReportVizType } from "../../Reports/Common/ReportVisualizationBuilder";
import { ReportDataViewModel as RDVModelV2 } from "../../ReportsRv2/Common/ReportDataViewModel";
import { buildReportVizType as buildReportV2 } from "../../ReportsRv2/Common/ReportVisualizationBuilder";
import { AcxMenuItemProps, StyledMenuLabel } from "../../UI/Menu/AcxMenu";
import WidgetError from "../Views/WidgetError";
import { BaseWidgetDefinition } from "./BaseWidgetDefinition";
import { TypographyProps } from "@mui/material";

export class ReportWidgetDefinition extends BaseWidgetDefinition {
    viewModel: ReportDataViewModel | RDVModelV2;
    @observable report?: IReport | IReportV2;

    @observable reportId: string;
    @observable orgId: string;

    private readonly isTable: boolean = false;
    private readonly isAbleToRouteToReporting: boolean =
        this.dashboardStoreAccessor().authStore.canUserView("Reporting V2") ||
        this.dashboardStoreAccessor().authStore.canUserView("Signals");

    constructor(
        dashboardItem: IDashboardItem,
        globalDateRef: DateReferenceOption,
        globalRefresh: RefreshInterval,
        globalTimeSpan: DynamicTimeSpan | CustomDynamicTimeSpan,
        dashboardStore: () => DashboardStore,
        globalHierarchyIds?: string[],
    ) {
        super(
            dashboardItem,
            globalRefresh,
            globalTimeSpan,
            globalDateRef,
            dashboardStore,
            globalHierarchyIds,
        );

        makeObservable(this);

        this.viewModelReady = !dashboardItem.args.isTable;
        this.reportId = dashboardItem.args.reportId;
        this.orgId = dashboardItem.args.orgId;
        this.isTable = !!dashboardItem.args.isTable;
        this.useCardTitleAndMenu = !this.isTable;

        this.initialize(
            this.dateReferenceOption,
            this.getRegisteredDashboardFilters(),
            ...timeSpanToMoments(this.timeSpan),
            this.hierarchyIds,
        );
    }

    private getRegisteredDashboardFilters() {
        const res = this.dashboardStoreAccessor().availableReportFilters?.get(
            this.dashboardItem?.args?.dashboardFilterKey,
        );
        if (res) {
            return [res];
        }
        return [];
    }

    private initialize(
        dateReferenceOption: DateReferenceOption,
        filter: ReportFilter[],
        startDate: Moment,
        endDate: Moment,
        hierarchyIds?: string[],
    ) {
        const currentRoute =
            this.dashboardStoreAccessor().authStore.rootStore.activeLocation
                .location;
        let agentId;
        if (currentRoute.includes("agentcoaching")) {
            const seg = currentRoute.split("/");
            agentId = seg[5];
        }

        this.viewModel = new RDVModelV2(
            this.reportId,
            this.orgId,
            startDate,
            endDate,
            dateReferenceOption,
            filter,
            this.onViewModelReady,
            true,
            hierarchyIds,
            agentId,
        );

        this.disposers.push(
            reaction(
                (r) => this.viewModel.nextTaskError,
                (arg) => {
                    if (arg?.message) {
                        this.error = arg?.message;
                    }
                    this.viewModel.clearTaskErrors();
                },
                { delay: 0, fireImmediately: true },
            ),
            reaction(
                (r) => this.viewModel.anyTaskLoading,
                (arg) => {
                    this.loading = arg;
                },
                { delay: 0, fireImmediately: true },
            ),
            reaction(
                (r) => this.loading,
                (arg, prev, r) => {
                    if (this.isTable) {
                        this.viewModel.tableReportData.setLoading(arg);
                    } else {
                        r.dispose();
                    }
                },
            ),
            reaction(
                (r) => ({
                    isEditing: this.dashboardStoreAccessor().editMode,
                    hasRows: this.viewModel.tableReportData.rows.length > 0,
                }),
                (arg) => {
                    if (
                        this.isTable &&
                        this.report &&
                        this.report.report?.organizationId &&
                        this.reportId
                    ) {
                        const reportId = this.reportId;
                        const items = (close, navigate) => {
                            const removeTableWidgetMenuItem: {
                                icon: JSX.Element;
                                id: string;
                                label: React.ReactElement<TypographyProps>;
                                props: { onClick: () => void };
                            } = {
                                id: `${reportId}-remove-widget`,
                                label: (
                                    <StyledMenuLabel>Remove</StyledMenuLabel>
                                ),
                                icon: (
                                    <ClearIcon
                                        style={{ color: ThemeColors.red }}
                                    />
                                ),
                                props: {
                                    onClick: () => {
                                        this.dashboardStoreAccessor().removeWidget(
                                            this.dashboardItem,
                                        );
                                        close?.();
                                    },
                                },
                            };

                            const editWidgetLink = {
                                id: `${reportId}-open-widget-config-drawer`,
                                label: (
                                    <StyledMenuLabel>
                                        Edit Widget
                                    </StyledMenuLabel>
                                ),
                                icon: <EditIcon />,
                                props: {
                                    onClick: () => {
                                        this.dashboardStoreAccessor().editWidget(
                                            this,
                                        );
                                        close?.();
                                    },
                                },
                            };

                            const tableWidgetMenuItems: {
                                icon: JSX.Element;
                                id: string;
                                label: React.ReactElement<TypographyProps>;
                                props: { onClick: () => void };
                            }[] = this.isAbleToRouteToReporting
                                ? [
                                      {
                                          id: `${reportId}-go-to-link`,
                                          label: (
                                              <StyledMenuLabel>
                                                  Go To Report
                                              </StyledMenuLabel>
                                          ),
                                          icon: <LinkIcon />,
                                          props: {
                                              onClick: () => {
                                                  setTimeout(() => {
                                                      this.navigateToReport(
                                                          navigate,
                                                          this.report,
                                                      );
                                                  }, 75);
                                                  close?.();
                                              },
                                          },
                                      },
                                      editWidgetLink,
                                  ]
                                : [editWidgetLink];

                            if (arg.isEditing) {
                                return [
                                    removeTableWidgetMenuItem,
                                    ...tableWidgetMenuItems,
                                    this.viewModel.tableReportData.buildVertMenuOptionForCsvExport(
                                        arg.hasRows,
                                    ),
                                ];
                            } else {
                                return [
                                    ...tableWidgetMenuItems,
                                    this.viewModel.tableReportData.buildVertMenuOptionForCsvExport(
                                        arg.hasRows,
                                    ),
                                ];
                            }
                        };

                        this.viewModel.tableReportData.vertIconMenuItemsBuilder =
                            items;
                    }
                },
            ),
        );
    }

    // @action.bind(this)
    refresh(
        dateReferenceOption: DateReferenceOption,
        startDate: Moment,
        endDate: Moment,
        hierarchyIds?: string[],
    ) {
        super.refresh();
        if (!this.report) {
            return;
        }
        const currentRoute =
            this.dashboardStoreAccessor().authStore.rootStore.activeLocation
                .location;
        let agentId;
        if (currentRoute.includes("agentcoaching")) {
            const seg = currentRoute.split("/");
            agentId = seg[5];
        }

        this.viewModel.quickFilters = this.getRegisteredDashboardFilters();

        (this.viewModel as RDVModelV2).setReport(
            this.report as IReportV2,
            startDate,
            endDate,
            dateReferenceOption,
            hierarchyIds,
            agentId,
        );
    }

    dispose = () => {
        this.viewModel.abortReportDataLoad();
    };

    @action
    private buildWidgetMenuItems() {
        if (this.report) {
            const report = this.report;
            if (
                this.dashboardStoreAccessor().authStore.isLoggedInUserAgent() &&
                !this.dashboardStoreAccessor().authStore.isUserUltra()
            ) {
                this.menuItemsBuilder = () => [];
            } else {
                const menuItems: (
                    navigate: NavigateFunction,
                    dashboardStore?: DashboardStore,
                    close?: () => void,
                ) => AcxMenuItemProps[] = (navigate, dashboardStore, close) => {
                    const editWidgetLink = {
                        id: `${report.id}-open-widget-config-drawer`,
                        label: <StyledMenuLabel>Edit Widget</StyledMenuLabel>,
                        icon: <EditIcon />,
                        props: {
                            onClick: () => {
                                dashboardStore?.editWidget(this);
                                close?.();
                            },
                        },
                    };
                    return this.isAbleToRouteToReporting
                        ? [
                              {
                                  id: `${report.id}-go-to-link`,
                                  label: (
                                      <StyledMenuLabel>
                                          Go To Report
                                      </StyledMenuLabel>
                                  ),
                                  icon: <LinkIcon />,
                                  props: {
                                      onClick: () => {
                                          setTimeout(() => {
                                              this.navigateToReport(
                                                  navigate,
                                                  report,
                                              );
                                          }, 75);
                                          close?.();
                                      },
                                  },
                              },
                              editWidgetLink,
                              {
                                  id: `export-to-csv-button`,
                                  label: (
                                      <StyledMenuLabel>
                                          Export to CSV
                                      </StyledMenuLabel>
                                  ),
                                  icon: <SubdirectoryArrowRightIcon />,
                                  props: {
                                      onClick: () => {
                                          let parsedData = {} as any;
                                          parsedData["data"] = [];

                                          parsedData["fields"] = [
                                              this.viewModel.currentReport
                                                  ?.xAxisLabel,
                                              this.viewModel.currentReport
                                                  ?.yAxisLabel,
                                          ];

                                          this.viewModel.googleFormattedData?.forEach(
                                              (row, index) => {
                                                  if (index === 0) {
                                                      parsedData["data"].push(
                                                          row.filter(
                                                              (x) =>
                                                                  typeof x ===
                                                                  "string",
                                                          ),
                                                      );

                                                      if (
                                                          parsedData["data"][0]
                                                              .length > 2
                                                      ) {
                                                          var extraFields =
                                                              parsedData[
                                                                  "data"
                                                              ][0].length - 2;

                                                          for (
                                                              let i = 0;
                                                              i < extraFields;
                                                              i++
                                                          ) {
                                                              parsedData[
                                                                  "fields"
                                                              ].push("");
                                                          }
                                                      }
                                                  } else {
                                                      parsedData["data"].push(
                                                          row.filter(
                                                              (x, index) =>
                                                                  index === 0 ||
                                                                  typeof x ===
                                                                      "number" ||
                                                                  x === null,
                                                          ),
                                                      );
                                                  }
                                              },
                                          );

                                          parsedData = Papa.unparse(
                                              parsedData as UnparseObject,
                                          );

                                          let downloadLink =
                                              document.createElement("a");
                                          const blob = new Blob([
                                              "\ufeff",
                                              parsedData,
                                          ]);
                                          const url = URL.createObjectURL(blob);

                                          downloadLink.href = url;
                                          downloadLink.download =
                                              "My_Report.csv";

                                          document.body.appendChild(
                                              downloadLink,
                                          );
                                          downloadLink.click();
                                          document.body.removeChild(
                                              downloadLink,
                                          );
                                          close?.();
                                      },
                                  },
                              },
                          ]
                        : [editWidgetLink];
                };

                this.menuItemsBuilder = menuItems;
            }
        }
    }

    @action
    private onViewModelReady = (report: IReport | IReportV2) => {
        this.viewModelReady = true;
        this.report = report;

        if (this.isTable) {
            const report = this.report;
            const items = (close, navigate) => {
                const editWidgetLink = {
                    id: `${report.id}-open-widget-config-drawer`,
                    label: <StyledMenuLabel>Edit Widget</StyledMenuLabel>,
                    icon: <EditIcon />,
                    props: {
                        onClick: () => {
                            this.dashboardStoreAccessor().editWidget(this);
                            close?.();
                        },
                    },
                };
                return this.isAbleToRouteToReporting
                    ? [
                          {
                              id: `${report.id}-go-to-link`,
                              label: (
                                  <StyledMenuLabel>
                                      Go To Report
                                  </StyledMenuLabel>
                              ),
                              icon: <LinkIcon />,
                              props: {
                                  onClick: () => {
                                      setTimeout(() => {
                                          this.navigateToReport(
                                              navigate,
                                              report,
                                          );
                                      }, 75);
                                      close?.();
                                  },
                              },
                          },
                          editWidgetLink,
                          this.viewModel.tableReportData.buildVertMenuOptionForCsvExport(
                              this.viewModel.tableReportData.rows.length > 0,
                          ),
                      ]
                    : [editWidgetLink];
            };
            this.title = report.name;

            this.viewModel.tableReportData.vertIconMenuItemsBuilder = items;
            this.viewModel.tableReportData.title = report.name;
            this.viewModel.tableReportData.gridId = report.id + "tablereport";
            this.viewModel.tableReportData.removeHeight = "60px";
        } else {
            this.title = report.name;
            this.buildWidgetMenuItems();
        }
    };

    private navigateToReport = (navigate, report) => {
        let route: string;
        // Signals Reporting will be the preference in the case of both Signals and Reporting V2 being enabled
        if (this.dashboardStoreAccessor().authStore.canUserView("Signals")) {
            if (typeof this.timeSpan !== "object") {
                // for dynamic timespans
                route = `/app/signals/reports/${report.report?.organizationId}/${report.id}?timespan=${this.timeSpan}`;
            } else {
                // for custom timespan date ranges
                route = `/app/signals/reports/${report.report?.organizationId}/${report.id}?start=${this.timeSpan.startDate}&end=${this.timeSpan.endDate}`;
            }
        } else if (
            this.dashboardStoreAccessor().authStore.canUserView("Reporting V2")
        ) {
            route = `/app/reportsRv2/${report.report?.organizationId}/${report.id}`;
        } else {
            // if a user does not have access to either permissions, they should not be getting routed to reporting
            return;
        }

        // Navigate with window.location if navigate hasn't been provided
        if (navigate) {
            navigate(route);
        } else {
            window.location.assign(route);
        }
    };

    render = (cardHeight?: number, controlsHeight?: number) => {
        return (
            <Observer>
                {() => {
                    if (this.error) {
                        return (
                            <>
                                <WidgetError
                                    parentHeightPx={
                                        (cardHeight ?? 0) -
                                        (controlsHeight ?? 0)
                                    }
                                    errorText={this.error}
                                />
                            </>
                        );
                    }

                    return (
                        <>
                            {this.dashboardStoreAccessor().authStore.canUserView(
                                "Reporting V2",
                            ) ||
                            this.dashboardStoreAccessor().authStore.canUserView(
                                "Signals",
                            )
                                ? buildReportV2(
                                      this.viewModel.currentReport?.vizType,
                                      this.viewModel.emptyDataSet,
                                      this.viewModel.googleFormattedData,
                                      this.viewModel.tableReportData,
                                      this.viewModel.currentReport?.xAxisLabel,
                                      this.viewModel.currentReport?.yAxisLabel,
                                      this.viewModel.isDrillDown,
                                      undefined,
                                      cardHeight,
                                      controlsHeight,
                                      true,
                                      this.viewModel.currentReport?.vizOptions,
                                  )
                                : buildReportVizType(
                                      this.viewModel.currentReport?.vizType,
                                      this.viewModel.emptyDataSet,
                                      this.viewModel.googleFormattedData,
                                      this.viewModel.tableReportData,
                                      this.viewModel.currentReport?.xAxisLabel,
                                      this.viewModel.currentReport?.yAxisLabel,
                                      this.viewModel.isDrillDown,
                                      undefined,
                                      cardHeight,
                                      controlsHeight,
                                      true,
                                      this.viewModel.currentReport?.vizOptions,
                                  )}
                        </>
                    );
                }}
            </Observer>
        );
    };
}
