import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
    Divider,
    Grid,
    Icon,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useStore } from "utils/useStore";
import ClassifierLibraryStore from "../Stores/ClassifierLibraryStore";
import { useNavigate, useParams } from "react-router";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxButton from "components/UI/AcxButton";
import AcxCard from "components/UI/Cards/AcxCard";
import AcxChip from "components/UI/AcxChip";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import { AsyncTaskStatus } from "stores/BaseStore";
import theme from "Theme/AppTheme";
import makeStyles from "@mui/styles/makeStyles";
import { CheckCircleOutline } from "@mui/icons-material";
import { basicDateReferenceOptions } from "../../../stores/ComponentStores/DatePickerComponentStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";

const CheckIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 36C24.2435 36 28.3131 34.3143 31.3137 31.3137C34.3143 28.3131 36 24.2435 36 20C36 15.7565 34.3143 11.6869 31.3137 8.68629C28.3131 5.68571 24.2435 4 20 4C15.7565 4 11.6869 5.68571 8.68629 8.68629C5.68571 11.6869 4 15.7565 4 20C4 24.2435 5.68571 28.3131 8.68629 31.3137C11.6869 34.3143 15.7565 36 20 36ZM27.414 17.414C27.7783 17.0368 27.9799 16.5316 27.9753 16.0072C27.9708 15.4828 27.7605 14.9812 27.3896 14.6104C27.0188 14.2395 26.5172 14.0292 25.9928 14.0247C25.4684 14.0201 24.9632 14.2217 24.586 14.586L18 21.172L15.414 18.586C15.0368 18.2217 14.5316 18.0201 14.0072 18.0247C13.4828 18.0292 12.9812 18.2395 12.6104 18.6104C12.2395 18.9812 12.0292 19.4828 12.0247 20.0072C12.0201 20.5316 12.2217 21.0368 12.586 21.414L16.586 25.414C16.9611 25.7889 17.4697 25.9996 18 25.9996C18.5303 25.9996 19.0389 25.7889 19.414 25.414L27.414 17.414Z"
                fill="#239C98"
            />
        </svg>
    );
};

const useStyles = makeStyles(() => {
    return {
        button: {
            borderRadius: "4px",
            border: "1px solid #E4E4E7",
            backgroundColor: "#FFF",
            color: `#3F3F46!important`,
            "&:hover": {
                backgroundColor: "#F4F4F5!important",
            },
        },
    };
});

const AddLibraryClassifier = observer(() => {
    const classifierLibraryStore = useStore(ClassifierLibraryStore);
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    const { classifierId } = useParams();
    const navigate = useNavigate();

    const classes = useStyles();

    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [wasClassifierAdded, setWasClassifierAdded] = useState(false);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);

    const isClassifierLoading = classifierLibraryStore.getTaskLoading(
        ClassifierLibraryStore.Tasks.LoadLibraryClassifier,
    );
    const isAddClassifierLoading = classifierLibraryStore.getTaskLoading(
        ClassifierLibraryStore.Tasks.AddClassifierToTenant,
    );

    useEffect(() => {
        if (classifierId)
            classifierLibraryStore.setSelectedClassifier(classifierId);
    }, [classifierLibraryStore, classifierId]);

    if (isClassifierLoading || !classifierLibraryStore.selectedClassifier)
        return (
            <AcxLoadingIndicator
                color="secondary"
                alternate="PuffLoader"
                size={64}
            />
        );

    const classifier = classifierLibraryStore.selectedClassifier;
    const existsInTenant = classifierLibraryStore.library?.[
        classifier.classifierGroup.name
    ].find((c) => c.id === classifier.id)?.existsInTenant;

    return (
        <Grid container direction="column" wrap="nowrap" padding={6} gap={4}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{
                    height: "fit-content",
                }}
            >
                <Stack direction="row" alignItems="center" gap={2}>
                    <Typography variant="h1">{classifier.name}</Typography>
                    {existsInTenant && (
                        <Tooltip title="This classifier exists in your classifiers">
                            <Icon>
                                <CheckCircleOutline />
                            </Icon>
                        </Tooltip>
                    )}
                </Stack>
                <AcxButton
                    color="primary"
                    disabled={wasClassifierAdded || existsInTenant}
                    loading={isAddClassifierLoading}
                    onClick={async () => {
                        const result =
                            await classifierLibraryStore.addClassifierToCurrentTenant();

                        if (result === AsyncTaskStatus.Error) return;

                        setIsSuccessDialogOpen(true);
                        setWasClassifierAdded(true);
                    }}
                >
                    Add Classifier
                </AcxButton>
            </Grid>
            <AcxDialog
                title={<CheckIcon />}
                subTitle="Classifier added successfully"
                text={`'${classifier.name}' classifier has been added to your classifier list. You can manage and edit your classifiers on the following page.`}
                isOpen={isSuccessDialogOpen}
                onClose={() => setIsSuccessDialogOpen(false)}
                hideCancelButton
            >
                <AcxButton onClick={() => navigate("../library")}>
                    Back to library
                </AcxButton>
                <AcxButton color="primary" onClick={() => navigate("../")}>
                    View your classifiers
                </AcxButton>
            </AcxDialog>
            <AcxCard
                rootStyle={{
                    maxWidth: "none",
                    height: "fit-content",
                    backgroundColor: theme.palette.lightBlue.background,
                    paddingInline: "32px",
                    paddingBlock: "20px",
                    boxShadow: "none",
                }}
                title={
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Typography variant="h5">Description</Typography>
                        {classifier.classifierCategory && (
                            <AcxChip
                                size="small"
                                color="green"
                                label={classifier.classifierCategory.name}
                            />
                        )}
                    </Grid>
                }
                mainContent={
                    <Grid
                        container
                        direction="row"
                        sx={{ width: "100%", justifyContent: "start" }}
                    >
                        <Typography variant="body1" textAlign="left">
                            {classifier.description}
                        </Typography>
                    </Grid>
                }
            />
            <AcxCard
                rootStyle={{
                    maxWidth: "none",
                    padding: "16px",
                }}
                title={
                    <Grid container direction="column">
                        <Typography variant="h5">Search Terms</Typography>
                        <Divider
                            sx={{ marginBlock: "8px" }}
                            orientation="horizontal"
                        />
                    </Grid>
                }
                mainContent={
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        minRows={14}
                        value={classifier.classifierImplementations
                            .map((ci) => ci.queryString)
                            .join("\n")}
                        variant="outlined"
                        inputProps={{
                            style: {
                                fontFamily:
                                    "SFMono-Regular, Menlo,Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace",
                            },
                        }}
                    />
                }
            />
            <Grid container gap={4} alignItems="flex-end">
                <AcxDateRangeInput
                    onSelect={applicationFiltersStore.setDatePair}
                    defaultStartDate={applicationFiltersStore.startDate}
                    defaultEndDate={applicationFiltersStore.endDate}
                    datePickerStore={applicationFiltersStore.datePickerStore}
                    displayDateType
                    dateReferenceOptions={basicDateReferenceOptions}
                    maxWidth="100%"
                    inputStyle={{ width: "100%" }}
                    wrapperStyle={{ maxWidth: "100%" }}
                />
                <AcxButton
                    fullWidth
                    className={classes.button}
                    loading={isPreviewLoading}
                    onClick={async () => {
                        setIsPreviewLoading(true);

                        const searchTerm = classifier.classifierImplementations
                            .map((ci) => ci.queryString)
                            .join("\n");

                        classifierLibraryStore.rbcStore.setRbcSearchTermStr(
                            searchTerm,
                        );
                        await classifierLibraryStore.rbcStore.testClassifier();

                        setIsPreviewLoading(false);
                        navigate("../results");
                        await classifierLibraryStore.rbcStore.getHighlights();
                    }}
                >
                    Preview Results
                </AcxButton>
            </Grid>
        </Grid>
    );
});

export default AddLibraryClassifier;
