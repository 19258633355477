import {
    Box,
    Collapse,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import EddyBlurbs from "components/UI/Eddy/EddyBlurbs";
import React, { useState } from "react";
import { useStyles } from "./styles";
import { observer } from "mobx-react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useStore } from "../../../../../utils/useStore";
import { AuthStore } from "../../../../../stores/AuthStore";

interface AdverseEventsSectionProps {
    store: ConversationsStore;
}

const AdverseEventsSection: React.FC<AdverseEventsSectionProps> = observer(
    ({ store }) => {
        const authStore = useStore(AuthStore);
        if (!authStore.canUserView("Safety Events Model")) return null;
        const canCollapse =
            store.selectedConversation?.hasEddyEffect.containsExamples &&
            store.selectedConversation?.hasAdverseEvent.containsExamples;
        const classes = useStyles({ canCollapse });
        const [open, setOpen] = useState(false);

        return (
            <Box className={classes.aeContainer}>
                <Box className={classes.width85}>
                    {canCollapse && (
                        <Tooltip
                            title={`${
                                open ? "Collapse" : "Expand"
                            } Adverse Event Highlights`}
                        >
                            <IconButton
                                onClick={() => {
                                    setOpen(!open);
                                }}
                                size="small"
                                edge="start"
                                className={classes.chevronButton}
                            >
                                {open ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                    <Typography
                        className={classes.summaryTitle}
                        component="span"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {!store.selectedConversation?.hasAdverseEvent
                            .containsExamples
                            ? "Adverse Event Signals: "
                            : "Adverse Event Signals"}
                    </Typography>
                    {!store.selectedConversation?.hasAdverseEvent
                        .containsExamples && (
                        <Typography component="span">
                            No specific examples identified in transcript
                        </Typography>
                    )}
                    <Typography className={classes.eddy}>
                        {store.selectedConversation?.hasAdverseEvent
                            .containsExamples && (
                            <>
                                <Typography
                                    className={classes.adverseEventHighlight}
                                    component="span"
                                >
                                    {store.selectedConversation
                                        ?.adverseEventData.length > 1
                                        ? `${store.selectedConversation?.adverseEventData.length} Adverse Event Signals `
                                        : "1 Adverse Event Signal "}
                                </Typography>
                                found in this conversation
                            </>
                        )}
                    </Typography>
                </Box>

                <Collapse in={canCollapse ? open : true}>
                    {store.selectedConversation?.isChat ? (
                        <EddyBlurbs
                            data={
                                store.selectedConversation?.adverseEventData ??
                                []
                            }
                            type="chat"
                            chat={store.selectedConversation.chatTranscription}
                            variant="adverse"
                            speakerChannelMap={
                                store.speakerStore.speakerChannelMap
                            }
                        />
                    ) : (
                        <EddyBlurbs
                            data={
                                store.selectedConversation?.adverseEventData ??
                                []
                            }
                            type="audio"
                            transcription={
                                store.selectedConversation
                                    ?.audioTranscription ?? []
                            }
                            variant="adverse"
                            speakerChannelMap={
                                store.speakerStore.speakerChannelMap
                            }
                        />
                    )}
                </Collapse>
            </Box>
        );
    },
);

export default AdverseEventsSection;
