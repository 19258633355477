import { AppUser } from "components/Admin/UserManager/UserStore";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxSelectSingleAsync from "components/UI/Select/BaseSelectComponents/AcxSelectSingleAsync";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import React from "react";
import { useStore } from "utils/useStore";
import ServiceHierarchySelect from "../../OrganizationModules/fields/ServiceHierarchySelect";
import { OrganizationAgentStore } from "../stores/OrganizationAgentStore";

const UpdateAgentsDialog = observer(() => {
    const agentStore = useStore(OrganizationAgentStore);

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [agentEmail, setAgentEmail] = React.useState("");
    const [agentCode, setAgentCode] = React.useState("");
    const [startDate, setStartDate] = React.useState<Moment | undefined>();
    const [isActive, setIsActive] = React.useState();
    const [userId, setUserId] = React.useState("");
    const [user, setUser] = React.useState<AppUser | undefined>();
    const [managerId, setManagerId] = React.useState<string>("");

    // We only care about the first selected row, it's on the parent component
    // to restrict updating to 1 selected agent at a time.
    const selectedRow = agentStore.dgStore.selectedRows[0];
    if (!selectedRow) {
        throw Error(
            "Shouldn't be rendering UpdateAgentDialog without a Selected Row",
        );
    }

    React.useEffect(() => {
        setFirstName(selectedRow.firstName);
        setLastName(selectedRow.lastName);
        setAgentEmail(selectedRow.agentEmail);
        setAgentCode(selectedRow.agentCode);
        setStartDate(
            !!selectedRow.startDate ? moment(selectedRow.startDate) : undefined,
        );
        setIsActive(selectedRow.isActive);
        setUserId(selectedRow.userId);
        setUser(selectedRow.user);
        setManagerId(selectedRow.managerId ?? "");
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agentStore.dgStore.selectedRows]);

    function onSubmit() {
        agentStore.onAgentUpdateConfirm(
            firstName,
            lastName,
            agentEmail,
            agentCode,
            isActive,
            agentStore.selectedAgentHierarchies,
            userId,
            startDate,
            managerId,
        );
    }

    function onClose() {
        agentStore.updateAgentsDialogStore.close();
    }
    const processOptions = [
        {
            label: "Active",
            value: true,
        },
        {
            label: "Inactive",
            value: false,
        },
    ];

    const selectUser = (user: AppUser) => {
        setUserId(user?.id || "");
    };

    const selectManager = (user: AppUser) => {
        setManagerId(user?.id || "");
    };

    let updateAgentsFormComponents: React.ReactChild[] = [];
    updateAgentsFormComponents = [
        <AcxMainTextField
            key="firstName"
            containerStyle={{ marginBottom: "0.5rem" }}
            id="firstName"
            labelText="First Name"
            placeholderText='e.g. "Jane"'
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            autofocus
            required
        />,
        <AcxMainTextField
            key="lastName"
            containerStyle={{ marginBottom: "0.5rem" }}
            id="lastName"
            labelText="Last Name"
            placeholderText='e.g. "Doe"'
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
        />,
        <AcxMainTextField
            key="agentEmail"
            containerStyle={{ marginBottom: "0.5rem" }}
            id="agentEmail"
            labelText="Email"
            placeholderText='e.g. "jane.doe@domain.com"'
            value={agentEmail}
            onChange={(e) => setAgentEmail(e.target.value)}
            required
        />,
        <AcxMainTextField
            key="agentCode"
            containerStyle={{ marginBottom: "0.5rem" }}
            id="agentCode"
            labelText="Agent Code"
            placeholderText='e.g. "janedoe"'
            value={agentCode}
            onChange={(e) => setAgentCode(e.target.value)}
        />,
        <AcxSelectSingleAsync
            key="userId"
            inputLabel="Connected User"
            fullWidth={true}
            defaultValue={user}
            id="agent-user-option-selector"
            labelField="email"
            valueField="id"
            loadOptions={agentStore.searchUsers}
            cacheOptions
            isClearable
            isLoading={false}
            onChange={selectUser}
        />,

        <AcxSelect
            key="agentStatus"
            inputLabel="Agent Status"
            fullWidth={true}
            defaultValue={processOptions.find((option) => {
                return option.value === isActive;
            })}
            id="pipeline-processing-option-selector"
            labelField="label"
            valueField="value"
            options={processOptions}
            onChange={(e) => setIsActive(e.value)}
        />,
        <ServiceHierarchySelect
            key="hieararchySelect"
            orgId={agentStore.orgId ?? ""}
            value={agentStore.selectedAgentHierarchies ?? []}
            onChange={(val) => agentStore.setSelectedAgentHierarchies(val)}
            fullWidth
        />,
        <div style={{ marginBottom: "8px", marginTop: "8px" }}>
            <AcxCalendarPopup
                id={"update-agent-start-date-cal"}
                inputLabel="Start Date"
                currentDate={startDate}
                onSelect={(selectedDate) => setStartDate(selectedDate)}
            />
        </div>,
        <AcxSelectSingleAsync
            key="userId"
            defaultValue={selectedRow.manager}
            inputLabel="Agent Manager"
            fullWidth
            id="agent-user-option-selector"
            labelField={(option: any) => {
                // using any type "any" here because the returned option was not the Option type expected
                return `${option.firstName} ${option.lastName}`;
            }}
            valueField="id"
            loadOptions={agentStore.searchUsers}
            cacheOptions
            isClearable
            isLoading={false}
            onChange={selectManager}
            noOptionMessage="Please enter First Name, Last Name, Email, or Username"
        />,
    ];

    return (
        <>
            <AcxConfirmationDialog
                isOpen={agentStore.updateAgentsDialogStore.isOpen}
                onClose={onClose}
                title="Update Agent"
                subTitle="Edit the fields you would like to change."
                content={updateAgentsFormComponents}
                confirmButtonText="Update Agent"
                cancelButtonText="Cancel"
                isLoading={agentStore.getTaskLoading("Update Agent")}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={12}
                onConfirm={onSubmit}
            />
        </>
    );
});

export default UpdateAgentsDialog;
