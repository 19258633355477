import React, { FunctionComponent } from "react";
import icon from "./ChevronDoubleLeft.svg";

interface IDoubleChevron {
    style?: React.CSSProperties;
    className?: string;
}

const DoubleChevronSvg: FunctionComponent<IDoubleChevron> = (props) => {
    return (
        <img
            src={icon}
            className={props.className}
            alt="down chevron"
            style={props.style}
        />
    );
};

export default DoubleChevronSvg;
