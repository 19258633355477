import {
    Question,
    Tag,
} from "components/Admin/Organizations/types/Module.type";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import Classifier from "models/ClassifierModel";
import { ClassifierResultAnswerTag } from "models/ClassifierResultAnswerTag";
import React from "react";
import ClassifierService from "services/ClassifierService";
import { useStore } from "utils/useStore";
import { LabelSmartPredictConfig } from "./SmartPredict/LabelSmartPredictConfig";

interface OrganizationModuleAutoScoringFormProps {
    tags?: Tag[];
    organizationId?: string;
    question?: Question;
}

const OrganizationModuleAutoScoringForm = (
    props: OrganizationModuleAutoScoringFormProps,
) => {
    const messageStore = useStore(MessageStore);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [classifiers, setClassifiers] = React.useState<Classifier[]>([]);

    React.useEffect(() => {
        const getClassifiers = async () => {
            if (props.organizationId) {
                const classifierList =
                    await new ClassifierService().getPublishedClassifiers(
                        props.organizationId,
                    );

                setClassifiers(
                    classifierList.filter(
                        (classifier) =>
                            classifier.classifierTypeName !== "Metadata",
                    ),
                );

                setLoading(false);
            }
        };

        getClassifiers();
    }, [props.organizationId]);

    const handleOnSubmit = async (mappings: ClassifierResultAnswerTag[]) => {
        await setLoading(true);

        try {
            mappings.forEach((value) => {
                // @ts-ignore
                delete value.classifier;
                delete (value as any).classifierOutput;
            });

            await new ClassifierService().configureAutoScoring(
                props?.organizationId!,
                props.question?.id!,
                mappings,
            );

            messageStore.logMessage(
                "SmartPredict settings have been updated.",
                "success",
            );
            setLoading(false);
        } catch (error) {
            //@ts-ignore
            console.error(error.message);
            messageStore.logError(`Failed to save SmartPredict configuration`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <LabelSmartPredictConfig
            loading={loading}
            question={props.question}
            orgId={props.organizationId!}
            tags={props.tags ?? []}
            classifiers={classifiers}
            handleOnSubmit={handleOnSubmit}
        />
    );
};

export default OrganizationModuleAutoScoringForm;
