import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import clsx from "clsx";
import { Observer } from "mobx-react";
import React, { FunctionComponent, useRef, useState } from "react";
import useStyles from "../../../../../Styles/Styles";
import ConfirmationDialog from "../../../../UI/AcxConfirmationDialog";
import AcxMainTextField from "../../../../UI/AcxMainTextFieldGrid";
import AcxServiceHierarchySelector from "../../../../UI/Select/AcxServiceHierarchySelector";
import { ConfigurationStepStore } from "../../AudioFileSamplerV2/Stores/ConfigurationStepStore";
import { AudioFileSamplerStore } from "../Stores/AudioFileSamplerStore";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",
        },
        inputField: {
            // marginBottom: theme.spacing(2)
            width: "100%",
            height: "100%",
        },
        topField: {
            marginBottom: theme.spacing(2),
        },
    });

interface SpecSaveConfirmDialog {
    store: AudioFileSamplerStore | ConfigurationStepStore;
}

type Props = SpecSaveConfirmDialog;

const SpecConfirmationDialog: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props: Props) => {
    const classes = useStyles(styles);

    const [specName, setSpecName] = React.useState("");
    const [errorMsg, setErrorMsg] = useState<string | undefined>();

    const containerRef = useRef(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        switch (event.target.id) {
            case "specName":
                setSpecName(event.target.value);
                break;
        }
    };
    function onPublish() {
        if (specName) {
            setErrorMsg(undefined);
            props.store.fromSpecConfirmDialog(
                specName,
                props.store.hierarchySelections,
            );
        } else {
            if (!specName) {
                setErrorMsg("Spec name must be populated");
            }
        }
    }

    const content = (
        <Observer>
            {() => (
                <Grid
                    container
                    direction={"row"}
                    justifyContent="center"
                    className={classes.root}
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        className={clsx(classes.inputField, classes.topField)}
                    >
                        <AcxMainTextField
                            value={specName}
                            onChange={handleChange}
                            id="specName"
                            labelText="Spec Name"
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.inputField}>
                        <AcxServiceHierarchySelector
                            levels={props.store.levels}
                            fullWidth
                            members={props.store.members}
                            hierarchySelections={
                                props.store.hierarchySelections
                            }
                            onSelectMember={props.store.setHierarchySelection}
                            containerRef={containerRef.current}
                        />
                    </Grid>
                </Grid>
            )}
        </Observer>
    );

    return (
        <Observer>
            {() => (
                <ConfirmationDialog
                    onClose={props.store.cancelSpecSaveDialog}
                    errorMessage={errorMsg}
                    isOpen={props.store.newSpecFromBuilder}
                    confirmButtonText="Publish"
                    content={content}
                    title={"Publish Metadata Spec"}
                    onConfirm={onPublish}
                />
            )}
        </Observer>
    );
};

export default SpecConfirmationDialog;
