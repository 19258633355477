import { Divider } from "@mui/material";
import React, { FunctionComponent } from "react";

interface OwnProps {}

type Props = OwnProps;

const ControlSectionDivider: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <>
            <div style={{ height: "24px" }} />
            <Divider />
            <div style={{ height: "8px" }} />
        </>
    );
};

export default ControlSectionDivider;
