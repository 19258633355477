export enum HipaaCompliance {
    Compliant,
    NotCompliant,
    NotApplicable,
}

export namespace HipaaCompliance {
    export function toString(hipaaCompliance: HipaaCompliance): string {
        switch (hipaaCompliance) {
            case HipaaCompliance.Compliant:
                return "Compliant";
            case HipaaCompliance.NotCompliant:
                return "Not Compliant";
            case HipaaCompliance.NotApplicable:
                return "N/A";
        }
    }
}
