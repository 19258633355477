import { Grid, styled } from "@mui/material";

export const MessageBody = styled(Grid)(({ theme }) => ({
    display: "inline-block",
    width: "fit-content",
    height: "auto",
    textWrap: "wrap",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    paddingBlock: theme.spacing(1),
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    fontFamily: "Inter",
    margin: 0,
}));
