import * as signalR from "@microsoft/signalr";
import { action, makeObservable, reaction } from "mobx";
import { User } from "oidc-client";
import { AuthStatus, AuthStore } from "stores/AuthStore";
import { AcxStoreFactory } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import MessageStoreBase, {
    NotificationArgument,
    NotificationType,
} from "./MessageStoreBase";
import { metaTransformer } from "./MetadataUpdateHandler";

export interface TaskUpdate {
    taskId: string;
    timestamp: Date;
    notificationType: NotificationType;
    message: string;
}

@AcxStoreFactory(false)
export default class TaskStore {
    readonly messageStore: MessageStoreBase;
    private connection: signalR.HubConnection;
    private stopConnection: boolean = false;

    private user: User | null = null;
    private orgId: string | undefined;

    constructor(private rootStore: IRootStore) {
        makeObservable(this);
        this.messageStore = this.rootStore.getStore(MessageStoreBase);

        reaction(
            () => ({
                user: rootStore.getStore(AuthStore)?._user,
                activeOrgId: rootStore.getStore(AuthStore)?.activeOrgId,
                status: rootStore.getStore(AuthStore)?.status,
            }),
            async (arg) => {
                if (
                    this.shouldRebuildConnection(arg) &&
                    arg.status === AuthStatus.Initialized
                ) {
                    this.user = arg.user;
                    this.orgId = arg.activeOrgId ?? arg.user.profile?.OrgId;

                    await this.initHubConnection(
                        rootStore.getStore(AuthStore).activeOrgId ??
                            arg.user.profile?.OrgId!,
                    );
                }
            },
            { delay: 5000 },
        );
    }

    private notificationTransformers: Map<
        string,
        (notifications_: any) => NotificationArgument
    > = new Map();

    @action
    registerTransformer(id: string, transformer: (args: any) => any) {
        this.notificationTransformers.set(id, transformer);
    }

    async initHubConnection(activeOrgId: string) {
        if (this.connection) {
            await this.connection.stop();
        }
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(
                `/hubs/tasks?ws_organizationId=${encodeURIComponent(
                    activeOrgId,
                )}`,
                {
                    accessTokenFactory: () =>
                        this.rootStore
                            .getStore(AuthStore)
                            .getAccessToken() as Promise<string>,
                },
            )
            .withAutomaticReconnect()
            .build();

        this.initTransformers();
        this.initListeners();
        await this.startConnection();
    }

    initTransformers = () => {
        this.registerTransformer("MetadataProgress", metaTransformer);
    };

    initListeners = () => {
        this.connection.on("MetadataProgress", (arg: TaskUpdate) => {
            try {
                const a =
                    this.notificationTransformers.get("MetadataProgress")?.(
                        arg,
                    );
                if (a) {
                    this.messageStore.addOrUpdateNotification(a);
                }
            } catch (error) {
                console.log(error);
            }
        });
    };

    startConnection = async () => {
        try {
            if (!this.stopConnection) {
                await this.connection.start();
            }
        } catch (err) {
            console.log(err);
            setTimeout(this.startConnection, 5000);
        }
    };

    private shouldRebuildConnection(arg: {
        activeOrgId?: string;
        user?: User;
    }): boolean {
        return !!(
            (!this.user && arg.user) ||
            (!this.orgId && arg.activeOrgId) ||
            arg.user?.profile.sub !== this.user?.profile.sub ||
            this.orgId !== arg.activeOrgId
        );
    }
}
