import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { Moment } from "moment";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxDateRangeInput from "components/UI/Calendar/DateRange/AcxDateRangeInput";
import { dateTimeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { useStore } from "utils/useStore";
import RequeueStore from "../Admin/AdminUIComponents/PipelineTools/Requeue/RequeueStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { AuthStore } from "stores/AuthStore";
import { Container, Grid, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: "relative",
        width: "100%",
        height: "calc(100% - 100px)",
        overflow: "auto",
    },
    container: {
        height: "100%",
    },
    chartsGrid: {
        height: "100%",
        padding: "2rem",
        marginTop: "28px",
    },
    gridItemStyle: {
        marginTop: "8px",
        paddingLeft: "8px",
    },
}));

const FileProcessFailures = observer(() => {
    const store = useStore(RequeueStore);
    const authStore = useStore(AuthStore);
    const messageStore = useStore(MessageStore);
    const classes = useStyles();

    useEffect(() => {
        if (
            !store.failures ||
            !store.failures.some(
                (failure) =>
                    failure.organizationId ===
                    authStore.orgStore.selectedOrganization?.id,
            )
        ) {
            store.updateFailures();
        }
    }, [authStore.orgStore.selectedOrganization?.id, store]);

    const columns: IColDef[] = useMemo(
        () => [
            {
                headerName: "File Name",
                field: "fileName",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Failed On",
                field: "processFailedOn",
                flex: 1,
                ...dateTimeColumnType,
            },
            {
                headerName: "Failure Message",
                field: "message",
                type: "string",
                flex: 1,
            },
        ],
        [],
    );
    console.log(columns);

    useEffect(() => {
        const refreshData = async () => {
            try {
                await store.updateFailures();
            } catch {
                messageStore.logError(
                    "Unable to fetch pipeline failures. Please retry.",
                );
            }
        };

        store.requeueDgStore.setColumns(columns);
        store.requeueDgStore.controls = [
            <AcxDateRangeInput
                key="dateRangeInput"
                labelText="Select Date"
                defaultStartDate={store.datePickerStore.beginDate}
                defaultEndDate={store.datePickerStore.endDate}
                onSelect={(start: Moment, end: Moment) => {
                    store.datePickerStore.setBeginDate(start);
                    store.datePickerStore.setEndDate(end);
                    // noinspection JSIgnoredPromiseFromCall
                    refreshData();
                }}
            />,
        ];
    }, [store, columns, messageStore]);

    return (
        <Container maxWidth="xl" className={classes.container}>
            <Grid
                container
                spacing={3}
                direction="column"
                alignItems="center"
                className={classes.chartsGrid}
                wrap="nowrap"
            >
                <AcxDataGrid dataGridStore={store.requeueDgStore} />
            </Grid>
        </Container>
    );
});

export default FileProcessFailures;
