import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "& > *": {
                margin: theme.spacing(1),
            },
        },
    });
type toggleOptions = "filename" | "file" | "";
interface IFileFieldToggle {
    whichSource: toggleOptions;
    onToggle: (toggle: toggleOptions) => void;
}
export default function FileFieldToggle(props: IFileFieldToggle) {
    const classes = useStyles(styles);
    function getVariant(toggle: toggleOptions) {
        if (toggle === props.whichSource) {
            return "contained";
        } else {
            return "outlined";
        }
    }
    function doClick(val: toggleOptions) {
        props.onToggle(val);
    }
    return (
        <Grid container item>
            <Grid item xs={12}>
                <Typography variant="h3">
                    Is your metadata detail information inside of the call
                    filename itself or in a separate metadata file?
                </Typography>
            </Grid>
            <Grid
                item
                container
                xs={12}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <div className={classes.root}>
                    <ButtonGroup size="small" color="secondary">
                        <Button
                            id="filename-toggle"
                            onClick={() => {
                                doClick("filename");
                            }}
                            variant={getVariant("filename")}
                            disableElevation={true}
                        >
                            In the filename
                        </Button>
                        <Button
                            id="separatefile-toggle"
                            onClick={() => {
                                doClick("file");
                            }}
                            variant={getVariant("file")}
                            disableElevation={true}
                        >
                            A separate file
                        </Button>
                    </ButtonGroup>
                </div>
            </Grid>
        </Grid>
    );
}

FileFieldToggle.defaultProps = {
    whichSource: "filename",
};
