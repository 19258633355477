import { EvalStore } from "components/Evaluation/Stores/EvalStore";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import EddyBlurbs from "components/UI/Eddy/EddyBlurbs";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import { AIExplanationNoHighlights } from "./AIExplanationNoHighlights";
import { Grid } from "@mui/material";
import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: "10px",
    },
    blurb: {
        padding: theme.spacing(1, 1.5),
    },
}));

interface AIExplanationEddyContextProps {
    tagId: string;
}

const AIExplanationEddyContext = observer(
    (props: AIExplanationEddyContextProps) => {
        const classes = useStyles();
        const store = useStore(EvalStore);

        const isLoading = store.explanationLoadingStates[props.tagId];

        if (isLoading)
            return (
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={32}
                    key={"spinner" + props.tagId}
                />
            );

        const data = store.eddyEffectP2Data;

        if (!data || data.length <= 0) return <AIExplanationNoHighlights />;

        return (
            <Grid item className={classes.container}>
                {store.evalAudioTranscription && (
                    <EddyBlurbs
                        type="audio"
                        transcription={store.evalAudioTranscription}
                        data={data}
                        blurbClassName={classes.blurb}
                    />
                )}
                {store.evalChat && (
                    <EddyBlurbs
                        type="chat"
                        chat={store.evalChat}
                        data={data}
                        blurbClassName={classes.blurb}
                    />
                )}
            </Grid>
        );
    },
);

export default AIExplanationEddyContext;
