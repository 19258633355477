import { emptyGUID } from "components/Admin/Organizations/OrganizationDetail/OrganizationModules/OrganizationModuleDetails/OrganizationModuleQuestionDependencies";
import { GroupedTags } from "components/Evaluation/Stores/EvalStore";
import {
    generateClipForConversation,
    matchTranscriptionToSoundCLip,
    soundClipDurationDisplay,
    textClipTimestampDisplay,
} from "components/Evaluation/Utils";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import type {
    PartialSegment,
    PlayerOptions,
} from "components/SoundClipEditor/SoundClipEditor";
import { startCase, uniqBy } from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { InteractionHierarchyLevels } from "models/InteractionHierarchyLevels";
import SoundClip from "models/SoundClip";
import { StorageAccountUseOptions } from "models/StorageAccount";
import { Moment } from "moment";
import AgentService from "services/AgentService";
import type {
    ConversationData,
    ConversationSoundClip,
} from "services/ConversationService";
import {
    ConversationClassifier,
    ConversationService,
    ConversationsSafetyEvent,
    VisibleEvalModule,
} from "services/ConversationService";
import { OrganizationService } from "services/OrganizationService";
import { SoundClipService } from "services/SoundClipService";
import {
    IMultiLanguageTranscriptionPayload,
    ITranscriptionSegment,
    TranscriptionService,
} from "services/TranscriptionService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import {
    allowedDateRangeLabels,
    DateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { isNullableType } from "utils/TypeGuards";
import {
    computeAverageUtteranceForTranscript,
    getTranscriptByLangCode,
} from "utils/Utils";
import LocalStorage from "../../../stores/LocalStorage";
import RootStore from "../../../stores/RootStore";

import { uuidv4 } from "utils/helpers";
import {
    addtlMetadataCatTitle,
    basicFilterCatTitle,
    callDirectionTitle,
    clientCallIdTitle,
    ConversationAdditionalFilter,
    ConversationAdditionalFilterCategory,
    localStorageFilterInputKey,
    mediaTypeTitle,
} from "../Views/Drawer/components/ManageFilterInputDrawer";

import { IExtendedMetadataFilterItem } from "components/ManagerInteractions/Stores/ManagerInteractionsStore";
import SoundClipEditorStore from "components/SoundClipEditor/Stores/SoundClipEditorStore";
import { SpeakerStore } from "components/UI/AcxTranscription/Stores/SpeakerStore/SpeakerStore";
import AudioMetadataModel from "models/AudioMetadataModel";
import Conversation from "models/Conversation";
import Evaluation from "models/Evaluation";
import { OrganizationMetadataField } from "models/OrganizationModels/OrganizationMetadataField";
import { ServiceError } from "services/BaseService";
import { EvaluationService } from "services/EvaluationService";
import { MetadataService } from "services/MetadataService";
import { MetaLabelService } from "services/MetaLabelService";
import QuestionMetadataService from "services/QuestionMetadataService";
import { SignalsService } from "services/SignalsService";
import { UserObjectTrackingService } from "services/UserObjectTrackingService";
import {
    ApplicationFiltersStore,
    SavedFilter,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import RBCFilterStore, {
    IRBCFilter,
} from "../Views/Drawer/components/DeepFilter/RBCFilterStore";
import WordsPhraseStore, {
    IWordsPhrasesModel,
} from "../Views/Drawer/components/DeepFilter/WordsPhrasesStore";
import ConvoWorkflowStore from "../Views/Main/FocusedConversation/components/convoWorkflows/store/ConvoWorkflowStore";

const createAudioClipTask = "Creating Audio Clip";
export const loadConversationSoundClips = "Loading Conversation Sound Clips";

interface TopicsFilter {
    topics: { id: string; topicLabel: string }[];
    /**
     * true for contains, false for does not contains
     */
    contains: boolean;
}

export interface ConversationFilterObject {
    agentId: string;
    agentIds: string[];
    callDuration?: number[];
    qualityEvalScore?: number[];
    searchPhrase: string;
    hasEddyEffect?: boolean;
    hasEval?: boolean;
    evalType: null | EvalType;
    dateRangeLabel: allowedDateRangeLabels;
    RBCFilterItems: IRBCFilter[];
    topicsFilter: TopicsFilter;
    hierarchyIds: string[];
    hipaa: "Compliant" | "Not Compliant" | "N/A" | null;
    begSentiment: "Negative" | "Neutral" | "Positive" | null;
    endSentiment: "Negative" | "Neutral" | "Positive" | null;
    selectedSortOption?: SortOption;
    mediaType: ConversationMediaTypes[];
    callDirection: ConversationCallDirections[];
    clientCallId?: string;
    safetyEvent: ConversationsSafetyEvent | null;
    meta1?: string;
    meta2?: string;
    meta3?: string;
    meta4?: string;
    meta5?: string;
    extendedMetadataFields?: IExtendedMetadataFilterItem[];
    contactTypes: string[];
}

type ConversationMediaTypes = "Audio" | "ProcessedChat";
type ConversationCallDirections = "Inbound" | "Outbound" | "Transfer";

export type BackupFilters = {
    orgId: string;
    filterObject: ConversationFilterObject;
    beginDate: Moment;
    endDate: Moment;
    referenceOption: DateReferenceOption;
    wordsPhrases: {
        items: IWordsPhrasesModel[];
        isInputStandard: boolean;
    };
};

export interface SortOption {
    label: JSX.Element;
    value: string;
    sortField: "ArrivedOn" | "Timestamp" | null; // TODO: add call duration
    sortDirection: "asc" | "desc" | null;
}

export type Topics = {
    topic1: string | null;
    topic2: string | null;
    topic3: string | null;
    topic1Reasoning: string | null;
    topic2Reasoning: string | null;
    topic3Reasoning: string | null;
};

export enum EvalType {
    BottomOfTheFunnel = "BottomOfTheFunnel",
    MiddleOfTheFunnel = "MiddleOfTheFunnel",
}

export const loadConversations = "Load Conversations";
export const loadConversation = "Load Conversation";
export const loadCreateClip = "Create Conversation Clip";
export const loadDeleteClip = "Deleting Conversation Clip";
export const loadCurrentEval = "Loading Evaluation for Conversation";

@AcxStore
export default class ConversationsStore extends BaseStore {
    readonly soundClipService: SoundClipService = new SoundClipService();
    private readonly conversationService = new ConversationService();
    private readonly transcriptionService = new TranscriptionService();
    readonly agentService = new AgentService();
    private readonly organizationService = new OrganizationService();
    private readonly evaluationService = new EvaluationService();
    private readonly questionMetadataService = new QuestionMetadataService();
    private readonly metaLabelService: MetaLabelService =
        new MetaLabelService();
    private readonly metadataService: MetadataService = new MetadataService();
    private readonly signalsService: SignalsService = new SignalsService();
    private readonly userObjectTrackingService =
        new UserObjectTrackingService();

    readonly authStore: AuthStore;
    manageSavedFilterDialogStore = new DialogComponentStore();
    manageSavedFilterCDStore = new DialogComponentStore();

    readonly RBCFilterStore: RBCFilterStore;
    readonly wordsPhrasesStore: WordsPhraseStore;

    private readonly applicationFiltersStore: ApplicationFiltersStore;

    messageStore: MessageStore;
    convoWorkflowStore: ConvoWorkflowStore;

    localStorage: LocalForage;
    defaultLocalStorage: LocalForage;

    readonly speakerStore: SpeakerStore;

    @observable
    fromAgent: boolean = false;

    @observable
    soundClipEditorStore: SoundClipEditorStore;

    @observable
    filterInputManagerIsOpen: boolean = false;

    @observable
    conversationsList?: ConversationData[] = [];

    @computed get hasConversations() {
        return this.conversationsList && this.conversationsList.length > 0;
    }

    @observable sqlQueries: string[] | undefined;
    @computed get hasSqlQueries() {
        return this.sqlQueries && this.sqlQueries.length > 0;
    }

    @observable
    isInitialLoad?: boolean = false;

    @observable
    isWorkflow?: boolean = false;

    @observable
    selectedConversationId?: string;

    /**
     * Ava will set this value if the user followed the "Analyze a sample ..."
     * then "Show me these calls in convos ..." flow.
     */
    @observable
    conversationIds?: string[];

    @observable
    estimatedConversationCount?: number;

    @observable
    selectedConversation: Conversation | null;

    private averageUtteranceDuration: number = 0.29;

    @observable
    pageNumber: number = 1;

    @observable
    groupClipTags: GroupedTags[] = [];

    @observable
    conversationSoundClips: ConversationSoundClip[] = [];

    @observable
    playerOptions: PlayerOptions = {
        isPlaying: false,
        startTime: 0,
        endTime: 0,
        id: "",
    };

    @observable
    audioClip: { status: "default" | "success" | "error" | "loading" } = {
        status: "default",
    };

    @observable
    selectedSortOption?: SortOption;

    @observable
    unsavedFilters: SavedFilter[] = [];

    @observable.shallow
    hierarchyLevels: InteractionHierarchyLevels[] | undefined;

    @observable
    stageDeleteClip: SoundClip | null = null;

    @observable
    evaluateConversationDialogOpen: boolean = false;

    @observable
    evaluateSuccessDialogOpen: boolean = false;

    @observable
    favoriteTrackedGroupId: string = "";

    @observable
    favoritedConversations: { [conversationId: string]: boolean };

    initExtendedMetadataFilters: ConversationAdditionalFilter[] = [
        {
            filterTitle: "Meta1",
            isActive: false,
            id: uuidv4(),
            storeObjectKey: "meta1",
            isShownInPanel: true,
        },
        {
            filterTitle: "Meta2",
            isActive: false,
            id: uuidv4(),
            storeObjectKey: "meta2",
            isShownInPanel: true,
        },
        {
            filterTitle: "Meta3",
            isActive: false,
            id: uuidv4(),
            storeObjectKey: "meta3",
            isShownInPanel: true,
        },
        {
            filterTitle: "Meta4",
            isActive: false,
            id: uuidv4(),
            storeObjectKey: "meta4",
            isShownInPanel: true,
        },
        {
            filterTitle: "Meta5",
            isActive: false,
            id: uuidv4(),
            storeObjectKey: "meta5",
            isShownInPanel: true,
        },
    ];

    initAdditionalFilterCategories: ConversationAdditionalFilterCategory[] = [
        {
            title: basicFilterCatTitle,
            subtitle: "Commonly used filters",
            filters: [
                {
                    filterTitle: mediaTypeTitle,
                    isActive: false,
                    id: uuidv4(),
                    storeObjectKey: "mediaType",
                    isShownInPanel: true,
                },
                {
                    filterTitle: "Date Type",
                    isActive: false,
                    id: uuidv4(),
                    storeObjectKey: "dateReferenceOption",
                    isShownInPanel: true,
                },
                {
                    filterTitle: callDirectionTitle,
                    isActive: false,
                    id: uuidv4(),
                    storeObjectKey: "callDirection",
                    //default to false, depends if CallDirection is returned as a available metadata in loadExtendedMetadata
                    isShownInPanel: false,
                },
                {
                    filterTitle: clientCallIdTitle,
                    isActive: false,
                    id: uuidv4(),
                    storeObjectKey: "clientCallId",
                    //default to false, depends if Client Call Id is returned as a available metadata in loadExtendedMetadata
                    isShownInPanel: false,
                },
                {
                    filterTitle: "Agents",
                    isActive: false,
                    id: uuidv4(),
                    storeObjectKey: "agents",
                    isShownInPanel: true,
                },
                {
                    filterTitle: "Favorite Conversations",
                    isActive: false,
                    id: uuidv4(),
                    storeObjectKey: "favoriteConversations",
                    isShownInPanel: true,
                },
            ],
        },
        {
            title: addtlMetadataCatTitle,
            subtitle: "Filter by extended metadata",
            filters: this.initExtendedMetadataFilters,
        },
    ];

    @observable
    additionalFilterCategories: ConversationAdditionalFilterCategory[] =
        this.initAdditionalFilterCategories;
    // Create a new AbortController for this store
    @observable
    abortController: AbortController = new AbortController();

    // for sorting classifier chips, we only want to update on apply
    @observable
    selectedContainsClassifierIds: string[] = [];

    @observable
    activeClip: SoundClip | undefined;

    @observable
    currentEval: Evaluation | null = null;

    @observable
    showNotRedactedWarning: boolean = false;

    @observable
    evaluationScores: VisibleEvalModule[] = [];

    @observable
    evalScoresLoading = false;

    @observable
    handleUnauthorizedError?: (e: ServiceError) => void;

    constructor(public rootStore: IRootStore) {
        super("ConversationsStore");
        makeObservable(this);
        this.authStore = rootStore.getStore(AuthStore);

        const local = RootStore().getStore(LocalStorage);
        this.localStorage = local.getLocalStore("ConversationsStore");
        this.defaultLocalStorage = local.getLocalStore();

        this.messageStore = rootStore.getStore(MessageStore);
        this.convoWorkflowStore = rootStore.getStore(ConvoWorkflowStore);

        this.speakerStore = new SpeakerStore();

        this.applicationFiltersStore = rootStore.getStore(
            ApplicationFiltersStore,
        );

        reaction(
            () => this.authStore.orgStore.selectedOrganization,
            () => {
                this.resetConversations();
                this.setFavoriteTrackedGroupId();
            },
            { fireImmediately: true },
        );

        reaction(
            () => this.selectedConversationId,
            (id) => {
                this.getSingleConversation(id);
            },
            { fireImmediately: true },
        );

        // extended metadata fields and getting metadata labels 1-5
        reaction(
            () => ({
                orgId: this.authStore.orgStore.selectedOrganization?.id,
                activeLocation: this.rootStore.activeLocation,
            }),
            this.debounceEffect(async (params, prevValue) => {
                if (
                    params.activeLocation &&
                    !params.activeLocation.location.includes("conversations") &&
                    params.orgId === prevValue?.orgId
                ) {
                    return;
                }

                // reset metalabels for org swap
                this.additionalFilterCategories =
                    this.initAdditionalFilterCategories;

                const localItem: string | undefined | null =
                    await this.localStorage.getItem(
                        localStorageFilterInputKey +
                            this.authStore.orgStore.selectedOrganization?.id,
                    );

                let categoriesToUse;
                if (!localItem) {
                    categoriesToUse = this.initAdditionalFilterCategories;
                } else {
                    const parsedItem = JSON.parse(localItem);
                    if (
                        parsedItem &&
                        parsedItem.length ===
                            this.initAdditionalFilterCategories.length
                    ) {
                        var equal = true;
                        //need to check the basic filters length
                        var initBasicFilter =
                            this.initAdditionalFilterCategories.find(
                                (filter) =>
                                    filter.title === basicFilterCatTitle,
                            );
                        var parsedBasicFilter = parsedItem.find(
                            (filter) => filter.title === basicFilterCatTitle,
                        );
                        if (
                            initBasicFilter?.filters.length !==
                            parsedBasicFilter?.filters.length
                        ) {
                            equal = false;
                        }
                        // The number of categories in local storage matches the initial categories
                        if (equal) categoriesToUse = parsedItem;
                        else
                            categoriesToUse =
                                this.initAdditionalFilterCategories;
                    } else {
                        // No item in local storage or difference in category length, use the initial categories
                        categoriesToUse = this.initAdditionalFilterCategories;
                    }
                }

                this.setAdditionalFilterCategories(categoriesToUse);

                this.loadMetadata(params);

                this.loadExtendedMetadata(params);
            }, 500),
            { delay: 0, fireImmediately: true },
        );

        // need to reset inactive filters to defaults when they are removed
        reaction(
            () => ({
                inactiveLength: this.inactiveAdditionalFilterItems.length,
            }),
            () => {
                this.inactiveAdditionalFilterItems.forEach((item) => {
                    switch (item.storeObjectKey) {
                        case "mediaType":
                            this.applicationFiltersStore.setMediaTypes([]);
                            break;
                        case "callDirection":
                            this.applicationFiltersStore.setCallDirections([]);
                            break;
                        case "clientCallId":
                            this.applicationFiltersStore.setClientCallId();
                            break;
                        case "meta1":
                            this.applicationFiltersStore.setMeta1();
                            break;
                        case "meta2":
                            this.applicationFiltersStore.setMeta2();
                            break;
                        case "meta3":
                            this.applicationFiltersStore.setMeta3();
                            break;
                        case "meta4":
                            this.applicationFiltersStore.setMeta4();
                            break;
                        case "meta5":
                            this.applicationFiltersStore.setMeta5();
                            break;
                        default:
                            this.applicationFiltersStore.setExtendedMetadata(
                                {},
                            );
                            break;
                    }
                });
            },
            { delay: 500 },
        );

        reaction(
            () => ({
                evalId: this.selectedConversation?.evaluationId,
            }),
            async ({ evalId }) => {
                if (evalId && evalId !== emptyGUID) {
                    try {
                        this.evalScoresLoading = true;
                        const res =
                            await this.conversationService.getVisibleEvalModulesForCompletedEvaluation(
                                evalId,
                            );
                        this.evaluationScores = res;
                    } catch (e) {
                        console.error(e);
                    } finally {
                        this.evalScoresLoading = false;
                    }
                } else {
                    this.evaluationScores = [];
                }
            },
        );
    }

    @action
    setFavoriteTrackedGroupId() {
        this.setupAsyncTask("Load Favorite Tracked Group ID", async () => {
            this.favoriteTrackedGroupId = (
                await this.userObjectTrackingService.getUserTrackedGroupByName(
                    "Favorite",
                )
            ).id;
        });
    }

    @action
    loadMetadata(params: {
        orgId?: string;
        activeLocation: { location: string };
    }) {
        this.setupAsyncTask("Load Metadata Labels", async () => {
            if (params.orgId) {
                const metaLabels = await this.getMetaLabels();
                runInAction(() => {
                    if (metaLabels && Object.keys(metaLabels).length > 0) {
                        for (const [key, value] of Object.entries(metaLabels)) {
                            // keys are Meta1 to Meta5
                            this.additionalFilterCategories
                                .find(
                                    (cat) =>
                                        cat.title === addtlMetadataCatTitle,
                                )
                                ?.filters.forEach((filter) => {
                                    if (filter.filterTitle === key) {
                                        filter.filterTitle = value;
                                    }
                                });
                        }
                    }

                    this.setfilterLabels();
                });
            }
        });
    }

    @action
    loadExtendedMetadata(params: {
        orgId?: string;
        activeLocation: { location: string };
    }) {
        this.setupAsyncTask("Load Extended Metadata", async () => {
            if (!params.orgId) {
                return;
            }
            const extendedMetadataRes =
                await this.questionMetadataService.getMetadataFields();
            const existingMetaValues =
                this.applicationFiltersStore.extendedMetadata ?? {};

            if (
                extendedMetadataRes.find(
                    (meta) =>
                        meta.item1 ===
                        OrganizationMetadataField.CallDirection.toString(),
                ) !== null
            ) {
                this.additionalFilterCategories
                    .find((cat) => cat.title === basicFilterCatTitle)
                    ?.filters.forEach((filter) => {
                        if (filter.filterTitle === callDirectionTitle) {
                            filter.isShownInPanel = true;
                        }
                    });
            } else if (
                extendedMetadataRes.find(
                    (meta) =>
                        meta.item1 ===
                        OrganizationMetadataField.ClientCallId.toString(),
                ) !== null
            ) {
                this.additionalFilterCategories
                    .find((cat) => cat.title === basicFilterCatTitle)
                    ?.filters.forEach((filter) => {
                        if (filter.filterTitle === clientCallIdTitle) {
                            filter.isShownInPanel = true;
                        }
                    });
            }

            runInAction(() => {
                const newExtendedMetadataFields = extendedMetadataRes
                    .filter((extMetaItem) => extMetaItem.item2 !== 0)
                    .map((meta) => ({
                        ...meta,
                        value: existingMetaValues[meta.item1],
                    }));
                const extendedMetadata = newExtendedMetadataFields.reduce(
                    (previous, current) => {
                        if (!current.value) return previous;
                        previous[current.item1] = current.value;
                        return previous;
                    },
                    {},
                );
                this.applicationFiltersStore.setExtendedMetadata(
                    extendedMetadata,
                );

                this.setInitialExtendedMetadataFilterItems(
                    newExtendedMetadataFields,
                );

                this.setfilterLabels();
            });
        });
    }

    @action
    setInitialExtendedMetadataFilterItems(
        extendedMetadataFields: IExtendedMetadataFilterItem[],
    ) {
        const extendedCategory = this.additionalFilterCategories.find(
            (category) => category.title === addtlMetadataCatTitle,
        );

        let extendedFields: ConversationAdditionalFilter[] =
            extendedMetadataFields
                .filter((item) => item.item1)
                .map((item) => ({
                    filterTitle: item.item1,
                    isActive: false,
                    id: uuidv4(),
                    storeObjectKey: item.item1,
                    isShownInPanel: true,
                }));

        if (extendedCategory) {
            const currentFilterTitles: string[] = extendedCategory.filters.map(
                (item) => item.filterTitle,
            );

            extendedFields.forEach((item) => {
                if (!currentFilterTitles.includes(item.filterTitle)) {
                    extendedCategory.filters.push(item);
                }
            });
        }
    }

    @action
    setfilterLabels = () => {
        this.additionalFilterCategories.forEach(
            (category: ConversationAdditionalFilterCategory) =>
                category.filters.forEach(
                    (filter: ConversationAdditionalFilter) => {
                        filter.filterLabel = startCase(filter.filterTitle);
                    },
                ),
        );
    };

    @action
    resetConversations() {
        this.conversationsList = [];

        this.pageNumber = 1;
        this.selectedConversationId = undefined;
        this.estimatedConversationCount = undefined;
    }

    @action
    setSelectedSortOption = (sortOption?: SortOption) => {
        this.selectedSortOption = sortOption;
    };

    @action
    setSelectedConversationId = (id: string | undefined) => {
        this.selectedConversationId = id;
    };

    @action
    setIsWorkflow = (input: boolean) => {
        this.isWorkflow = input;
    };

    @action
    getSingleConversation = (id?: string) => {
        // Abort any ongoing asynchronous task if it exists
        this.abortController.abort();
        if (id) {
            // Create a new AbortController for this task
            this.abortController = new AbortController();
            // get conversation data
            this.setupAsyncTask(loadConversation, async () => {
                try {
                    const conversationRes =
                        await this.conversationService.getConversation(
                            id,
                            this.abortController.signal,
                        );

                    const speakerIDResult =
                        conversationRes.conversationClassifiers.find(
                            (c) => c.name === "ML-SpeakerIdentification",
                        )?.result;

                    if (!!speakerIDResult) {
                        const parsedResult = JSON.parse(speakerIDResult);
                        this.speakerStore.speakerIdentificationModelResults =
                            parsedResult;
                    } else {
                        this.speakerStore.speakerIdentificationModelResults =
                            undefined;
                    }
                    if (
                        conversationRes.workflowInstanceIds?.length &&
                        this.isWorkflow
                    ) {
                        this.convoWorkflowStore.licensedModules = [];
                        this.convoWorkflowStore.uiModels = [];
                        this.convoWorkflowStore.getClassifierWorkflowInfo(
                            conversationRes.workflowInstanceIds,
                            this.selectedConversationId,
                        );
                    }

                    const hierLevels =
                        await this.organizationService.getInteractionHierarchyLevels(
                            conversationRes.hierarchyId,
                        );

                    this.getSoundClipsByAudioMetadataId(
                        conversationRes.conversationId,
                    );

                    let multiLangTranscriptions: IMultiLanguageTranscriptionPayload[] =
                        [];

                    if (!conversationRes.chatTranscription?.length) {
                        try {
                            multiLangTranscriptions =
                                await this.transcriptionService.getMulitLanguageTranscriptionByAmdId(
                                    id,
                                );
                        } catch (err: any) {
                            if (
                                err.message &&
                                !err.message.includes(
                                    "Could not find Transcription for AudioMetadata Id",
                                )
                            ) {
                                this.messageStore.logError(
                                    "Failed to get Transcription: " +
                                        JSON.stringify(err),
                                );
                            }
                        }
                    }

                    runInAction(() => {
                        this.selectedConversation = new Conversation(
                            conversationRes,
                        );
                        this.hierarchyLevels = hierLevels;
                        this.selectedConversation.multiLangTranscriptions =
                            multiLangTranscriptions;
                        this.selectedConversation.languageCode = "en";
                        this.setCurrentEval();

                        if (this.authStore.orgStore.hasRedaction() === false) {
                            this.setShowNotRedactedWarning(false);
                        } else if (
                            this.selectedConversation?.isRedacted === false
                        ) {
                            this.setShowNotRedactedWarning(true);
                        } else {
                            this.setShowNotRedactedWarning(false);
                        }

                        if (
                            (this.selectedConversation.audioTranscription
                                ?.length ?? 0) > 0
                        ) {
                            this.averageUtteranceDuration =
                                computeAverageUtteranceForTranscript(
                                    this.selectedConversation
                                        .audioTranscription,
                                );
                        }
                    });
                    return conversationRes;
                } catch (e: any) {
                    if (
                        e instanceof ServiceError &&
                        e.response.status === 403 &&
                        this.handleUnauthorizedError
                    ) {
                        this.handleUnauthorizedError(e);
                    } else {
                        if (e.name === "AbortError") {
                            // Handle the request cancellation
                            console.log("Request was canceled");
                        } else {
                            // Handle other errors
                            this.messageStore.logError(
                                "Failed to get conversation: " + e.toString(),
                            );
                        }
                    }
                }
            });
        } else {
            // reset conversation data
            this.selectedConversation = null;
            this.setCurrentEval();
        }
    };

    @action
    setCurrentEval = async () => {
        this.setupAsyncTask(loadCurrentEval, async () => {
            if (
                this.selectedConversationId &&
                this.selectedConversation?.evaluationId &&
                this.selectedConversation?.evaluationId !== emptyGUID &&
                this.authStore.orgStore.selectedOrganization?.id
            ) {
                try {
                    const { evaluation } =
                        await this.evaluationService.getEvalGraph(
                            this.authStore.orgStore.selectedOrganization.id,
                            this.selectedConversation?.evaluationId,
                        );
                    this.currentEval = Evaluation.fromJson(evaluation);
                } catch (e) {
                    if (e instanceof Error) {
                        this.rootStore
                            .getStore(MessageStore)
                            .logError(e.message);
                    }
                }
            } else {
                this.currentEval = null;
            }
        });
    };

    @action
    setSoundClipEditorStore = () => {
        this.soundClipEditorStore = new SoundClipEditorStore(
            "conversation-sound-clip-editor",
            this.currentClip as any,
            undefined,
            {
                onClipGenerated: this.onAudioClipCreated,
                onClipUpdated: this.onAudioClipUpdated,
                segmentList: this.clipsToSegmentList,
                mediaUrl: this.selectedConversation?.mediaUrl,
                disableClip: false,
            },
        );
    };

    @action setConversationIds(ids?: string[]) {
        this.conversationIds = ids;
    }

    @action
    getConversations = async () => {
        await this.setupAsyncTask(loadConversations, async () => {
            try {
                const filters = this.applicationFiltersStore.toRequestObject();

                let conversationIds: string[] | undefined = undefined;
                if (this.conversationIds && this.conversationIds.length > 0) {
                    conversationIds = this.conversationIds;
                    filters.startDate = "0001-01-01";
                    filters.endDate = "9999-12-31";
                    this.setConversationIds(undefined);
                }

                const res = await this.conversationService.getConversations({
                    ...filters,
                    conversationIds,
                    pageNumber: this.pageNumber ?? 1,
                    sortField: this.selectedSortOption?.sortField,
                    sortDirection: this.selectedSortOption?.sortDirection,
                });
                runInAction(() => {
                    this.conversationsList = res.conversationResults;
                    this.estimatedConversationCount = res.estimatedCount;
                    this.sqlQueries = res.sqlQueries;
                });
            } catch (err: any) {
                if (
                    err.message &&
                    err.message.includes("Timeout during reading attempt")
                ) {
                    this.messageStore.logError(
                        "Failed to load conversations: " +
                            "Search was too large and timed out. Try running the search again in a shorter date range, or with simpler criteria.",
                    );
                } else {
                    this.messageStore.logError(
                        "Failed to load conversations: " + JSON.stringify(err),
                    );
                }
            }
        });
        this.getHighlights();
        this.getFavorites();
    };

    @action
    getFavorites = async () => {
        if (!this.favoriteTrackedGroupId) return;

        await this.setupAsyncTask(
            "Load Favorite Status For Conversations",
            async () => {
                try {
                    const conversationIds =
                        this.conversationsList?.map(
                            (conversation) => conversation.conversationId,
                        ) ?? [];

                    if (conversationIds.length === 0) return {};

                    if (
                        !!this.selectedConversationId &&
                        !conversationIds.includes(this.selectedConversationId!)
                    ) {
                        conversationIds.push(this.selectedConversationId!);
                    }

                    const res =
                        await this.userObjectTrackingService.getObjectTrackedStatus(
                            {
                                objectIds: conversationIds,
                                userTrackedGroupId: this.favoriteTrackedGroupId,
                            },
                        );

                    this.favoritedConversations = res;
                } catch (err: any) {
                    if (err) {
                        this.messageStore.logError(
                            "Failed to load conversation favorites",
                        );
                    }
                }
            },
        );
    };

    @action
    addFavorite = async (conversationId: string) => {
        await this.setupAsyncTask("Add Favorite Conversation", async () => {
            try {
                this.userObjectTrackingService.trackObjectForUser({
                    objectId: conversationId,
                    objectType: "conversation",
                    userTrackedGroupId: this.favoriteTrackedGroupId,
                });

                this.favoritedConversations = {
                    ...this.favoritedConversations,
                    [conversationId]: true,
                };
            } catch (err: any) {
                if (err) {
                    this.messageStore.logError(
                        "Failed to favorite conversation",
                    );

                    this.favoritedConversations = {
                        ...this.favoritedConversations,
                        [conversationId]: false,
                    };
                }
            }
        });
    };

    @action
    removeFavorite = async (conversationId: string) => {
        await this.setupAsyncTask("Add Favorite Conversation", async () => {
            try {
                this.userObjectTrackingService.unTrackObjectForUser({
                    objectId: conversationId,
                    objectType: "conversation",
                    userTrackedGroupId: this.favoriteTrackedGroupId,
                });

                this.favoritedConversations = {
                    ...this.favoritedConversations,
                    [conversationId]: false,
                };
            } catch (err: any) {
                if (err) {
                    this.messageStore.logError(
                        "Failed to unfavorite conversation",
                    );

                    this.favoritedConversations = {
                        ...this.favoritedConversations,
                        [conversationId]: true,
                    };
                }
            }
        });
    };

    @action
    getHighlights = async () => {
        const applicationsFilterObject =
            this.applicationFiltersStore.toRequestObject();
        try {
            if (
                (applicationsFilterObject.wordsAndPhrasesSearchString ||
                    !!applicationsFilterObject.rbcFilterItems.length) &&
                !!this.conversationsList?.length
            ) {
                const highlightRes =
                    await this.conversationService.getHighlights({
                        searchPhrase:
                            applicationsFilterObject.wordsAndPhrasesSearchString ??
                            "",
                        rbcFilterItems: applicationsFilterObject.rbcFilterItems,
                        conversationIds: this.conversationsList?.map(
                            (i) => i.conversationId,
                        ),
                    });
                runInAction(() => {
                    this.conversationsList?.forEach((c) => {
                        const foundHighlight = highlightRes.find(
                            (r) => r?.audioMetadataId === c.conversationId,
                        );
                        if (foundHighlight) {
                            c.highlights = foundHighlight;
                        }
                    });
                });
            }
        } catch (e) {
            // Do not display highlight errors to user
        }
    };

    @action
    getSearchConversations = () => {
        this.pageNumber = 1;
        this.isInitialLoad = false;
        this.selectedContainsClassifierIds =
            this.applicationFiltersStore.rbcFilterItems
                .filter(
                    // filter on value because we only care about classifier ids in contains blocks
                    (item) => item.value,
                )
                .flatMap((item) => item.classifierIds);
        this.getConversations();
    };

    @action
    setPageNumberAndGetConversations = (value: number) => {
        this.pageNumber = value;
        this.getConversations();
    };

    @action
    setIsInitialLoad = (input: boolean, fromAgent?: boolean) => {
        this.isInitialLoad = input;
        if (fromAgent !== undefined) this.fromAgent = fromAgent;
    };

    @action
    setShowNotRedactedWarning = (show: boolean) => {
        this.showNotRedactedWarning = show;
    };

    async downloadClipFromBlob(filePath?: string) {
        if (filePath) {
            return await this.soundClipService.downloadAudioClipAsBlob(
                filePath,
                StorageAccountUseOptions.Main,
            );
        }
        return new Blob([], { type: "audio/wav" });
    }

    @action
    onAudioClipCreated = async (startTime: number, endTime: number) => {
        if (
            this.currentClip &&
            !isNullableType(startTime) &&
            !isNullableType(endTime)
        ) {
            const uniqueClipName = `Clip ${soundClipDurationDisplay(
                startTime,
            )} - ${soundClipDurationDisplay(endTime)}`;

            this.audioClip = { status: "loading" };

            const soundClip = generateClipForConversation(
                this.selectedConversation?.conversationId!,
                this.selectedConversation?.evaluationId,
                uniqueClipName,
                startTime,
                endTime,
                this.authStore._user?.profile.email,
            );

            this.transcriptionForClip(soundClip);

            this.createClip(soundClip);
        }
    };

    @action
    onAudioClipUpdated = async (soundClip: PartialSegment) => {
        if (
            this.currentClip &&
            !isNullableType(soundClip.startTime) &&
            !isNullableType(soundClip.endTime)
        ) {
            const uniqueClipName = `Clip ${soundClipDurationDisplay(
                soundClip.startTime,
            )} - ${soundClipDurationDisplay(soundClip.endTime)}`;

            this.audioClip = { status: "loading" };

            const existingClip = this.conversationSoundClips.find(
                (clip) => clip.soundClip.id === soundClip.id,
            );

            if (!existingClip) {
                return;
            }

            existingClip.soundClip.startTime = soundClip.startTime;
            existingClip.soundClip.endTime = soundClip.endTime;
            existingClip.soundClip.segmentName = uniqueClipName;

            if (!existingClip) {
                return;
            }

            this.transcriptionForClip(existingClip.soundClip);

            this.updateClip(existingClip);
        }
    };

    @action
    async createTextClip(clip: SoundClip) {
        if (!this.authStore.orgStore?.selectedOrganization?.id) {
            console.warn("Cannot create clip with empty organization Id");
            return;
        }

        this.setupAsyncTask(loadCreateClip, async () => {
            try {
                await this.soundClipService.createTextClip(
                    this.authStore.orgStore?.selectedOrganization?.id!,
                    clip,
                );

                this.getSoundClipsByAudioMetadataId(
                    this.selectedConversation?.conversationId!,
                );
                this.messageStore.logMessage(
                    "Created clip successfully",
                    "success",
                );
                this.audioClip = { status: "success" };
                setTimeout(() => {
                    this.audioClip = { status: "default" };
                }, 2000);
            } catch (err: any) {
                this.audioClip = { status: "error" };
                this.messageStore.logError(
                    "Failed to create clip: " + JSON.stringify(err),
                );
                setTimeout(() => {
                    this.audioClip = { status: "default" };
                }, 2000);
            }
        });
    }

    @action
    async createClip(clip: SoundClip) {
        if (!this.authStore.orgStore?.selectedOrganization?.id) {
            console.warn("Cannot create clip with empty organization Id");
            return;
        }

        this.setupAsyncTask(loadCreateClip, async () => {
            try {
                await this.soundClipService.createSoundClip(
                    this.authStore.orgStore?.selectedOrganization?.id!,
                    clip,
                );

                this.getSoundClipsByAudioMetadataId(
                    this.selectedConversation?.conversationId!,
                );
                this.messageStore.logMessage(
                    "Created clip successfully",
                    "success",
                );
                this.audioClip = { status: "success" };
                setTimeout(() => {
                    this.audioClip = { status: "default" };
                }, 2000);
            } catch (err: any) {
                this.audioClip = { status: "error" };
                this.messageStore.logError(
                    "Failed to create clip: " + JSON.stringify(err),
                );
                setTimeout(() => {
                    this.audioClip = { status: "default" };
                }, 2000);
            }
        });
    }

    @action
    async updateClip(clip: ConversationSoundClip) {
        if (!this.authStore.orgStore?.selectedOrganization?.id) {
            console.warn("Cannot create clip with empty organization Id");
            return;
        }

        this.setupAsyncTask(loadCreateClip, async () => {
            try {
                await this.soundClipService.updateSoundClip(
                    this.authStore.orgStore?.selectedOrganization?.id!,
                    clip.soundClip,
                );

                this.getSoundClipsByAudioMetadataId(
                    this.selectedConversation?.conversationId!,
                );
                this.messageStore.logMessage(
                    "Updated clip successfully",
                    "success",
                );
                this.audioClip = { status: "success" };
                setTimeout(() => {
                    this.audioClip = { status: "default" };
                }, 2000);
            } catch (err: any) {
                this.audioClip = { status: "error" };
                this.messageStore.logError(
                    "Failed to create clip: " + JSON.stringify(err),
                );
                setTimeout(() => {
                    this.audioClip = { status: "default" };
                }, 2000);
            }
        });
    }

    @action
    async deleteClip(soundClip?: SoundClip) {
        if (soundClip) {
            this.setupAsyncTask(loadDeleteClip, async () => {
                try {
                    await this.soundClipService.deleteClipById(
                        soundClip.id,
                        !!this.selectedConversation?.callDurationMillis,
                    );
                    this.getSoundClipsByAudioMetadataId(
                        this.selectedConversation?.conversationId!,
                    );
                    this.messageStore.logMessage(
                        "Deleted clip successfully",
                        "success",
                    );
                    this.audioClip = { status: "success" };
                    setTimeout(() => {
                        this.audioClip = { status: "default" };
                    }, 2000);
                } catch (err: any) {
                    this.audioClip = { status: "error" };
                    this.messageStore.logError(
                        "Failed to delete clip: " + JSON.stringify(err),
                    );
                    setTimeout(() => {
                        this.audioClip = { status: "default" };
                    }, 2000);
                }
            });
        }
    }

    @action
    private transcriptionForClip(soundClip: SoundClip) {
        if (this.selectedConversation?.audioTranscription === undefined) {
            return;
        }

        if (this.selectedConversation.languageCode !== "en") {
            // Use english transcript from multiLang instead of selectedAudioTranscript
            const engTranscript = getTranscriptByLangCode(
                "en",
                this.selectedConversation.multiLangTranscriptions,
            );
            const avgUtterance =
                computeAverageUtteranceForTranscript(engTranscript);
            const txSegmentModels: ITranscriptionSegment[] =
                engTranscript ?? [];
            matchTranscriptionToSoundCLip(
                soundClip,
                txSegmentModels,
                avgUtterance,
            );
        } else {
            const avgUtterance = this.averageUtteranceDuration;
            const txSegmentModels: ITranscriptionSegment[] =
                this.selectedConversation?.audioTranscription ?? [];
            matchTranscriptionToSoundCLip(
                soundClip,
                txSegmentModels,
                avgUtterance,
            );
        }
    }

    @action
    getSoundClipsByAudioMetadataId(audioMetadataId: string) {
        if (audioMetadataId !== emptyGUID) {
            this.setupAsyncTask(loadConversationSoundClips, async () => {
                try {
                    const res =
                        await this.conversationService.getConversationSoundClipByAudioMetadataId(
                            audioMetadataId,
                        );

                    runInAction(() => {
                        res.sort((a, b) =>
                            a.soundClip.startTime > b.soundClip.startTime
                                ? 1
                                : -1,
                        );
                        this.conversationSoundClips = res.map(
                            ({ soundClip, ...rest }) => ({
                                soundClip: SoundClip.fromJson(soundClip),
                                ...rest,
                            }),
                        );
                    });
                } catch (err: any) {
                    this.messageStore.logError(
                        "Failed to get sound clips: " + JSON.stringify(err),
                    );
                }
            });
        }
    }

    @computed
    get clipsToSegmentList(): PartialSegment[] {
        const activeUniqueSoundClips = uniqBy(
            this.conversationSoundClips.map((clip) => clip.soundClip),
            (arg) => arg.segmentName,
        );
        return activeUniqueSoundClips?.map(
            ({ startTime, endTime, id, segmentName }) => ({
                startTime,
                endTime,
                id,
                labelText: segmentName,
            }),
        );
    }

    @action
    setUnsavedFilters = (input: SavedFilter[]) => {
        // Need to create new copy of input so that UI refreshes when canceling changes
        this.unsavedFilters = [...input];
    };

    @action
    setAdditionalFilterCategories = (
        input: ConversationAdditionalFilterCategory[],
    ) => {
        this.additionalFilterCategories = input;
    };

    @action
    playBlurb = (playerOptions: PlayerOptions) => {
        this.playerOptions = playerOptions;
    };

    @action
    toggleManageFilterInput = () => {
        this.filterInputManagerIsOpen = !this.filterInputManagerIsOpen;
    };

    @action
    changeTranscriptionLanguage(
        languageCode: IMultiLanguageTranscriptionPayload["languageCode"],
    ) {
        if (this.selectedConversation) {
            this.selectedConversation.languageCode = languageCode;
        }
    }

    @action
    onTextMediaClipCreated = async (
        text: string,
        startIndex: number,
        endIndex: number,
    ) => {
        if (text.length > 6000) {
            this.rootStore
                .getStore(MessageStore)
                .logError("Text clips may not exceed 6k characters");
            return;
        }

        const uniqueClipName = `Clip ${textClipTimestampDisplay(
            this.selectedConversation?.chatTranscription?.[startIndex]
                .timestamp ?? "",
        )} - ${textClipTimestampDisplay(
            this.selectedConversation?.chatTranscription?.[endIndex]
                .timestamp ?? "",
        )}`;
        const existingInactive = this.currentEval?.soundClips?.find(
            (s) => s.segmentName === uniqueClipName && !s.isActive,
        );
        if (existingInactive) {
            existingInactive.isActive = true;
            return;
        }
        const user = await this.authStore.getUserObject();

        const soundClip = generateClipForConversation(
            this.selectedConversationId!,
            this.currentEval?.id,
            uniqueClipName,
            startIndex,
            endIndex,
            user?.profile.email,
            true,
        );
        soundClip.transcriptionText = text;

        this.currentEval?.soundClips?.push(soundClip);

        this.setupAsyncTask(createAudioClipTask, () =>
            this.setupAsyncTask(
                `${createAudioClipTask}-${soundClip.segmentName}`,
                () => this.createTextClip(soundClip),
            ).catch((reason) => {}),
        );
    };

    @action
    setActiveClip = (id?: string, start?: number, end?: number) => {
        this.activeClip = this.currentEval?.soundClips?.find(
            (value) => value.id === id,
        );
    };

    @action
    toggleEvaluateConversationDialogOpen = () => {
        this.evaluateConversationDialogOpen =
            !this.evaluateConversationDialogOpen;
    };

    @action
    toggleEvaluationSuccessDialogOpen = () => {
        this.evaluateSuccessDialogOpen = !this.evaluateSuccessDialogOpen;
    };

    async saveUnsavedFilters() {
        const ownedUnsavedFilters = this.unsavedFilters;

        const toUpdate: SavedFilter[] = [];
        const toDelete: string[] = [];

        for (const savedFilter of this.applicationFiltersStore
            .ownedSavedFilters) {
            const associatedUnsavedFilter = ownedUnsavedFilters.find(
                (filter) => filter.id === savedFilter.id,
            );

            if (!associatedUnsavedFilter) toDelete.push(savedFilter.id);
            else if (associatedUnsavedFilter.name !== savedFilter.name)
                toUpdate.push(associatedUnsavedFilter);
        }

        try {
            if (toDelete.length > 0)
                await this.applicationFiltersStore.applicationFiltersService.deleteApplicationFilters(
                    toDelete,
                );

            if (toUpdate.length > 0)
                await this.applicationFiltersStore.applicationFiltersService.updateApplicationFilters(
                    toUpdate,
                );

            this.applicationFiltersStore.setSavedFilters([
                ...this.applicationFiltersStore.notOwnedSavedFilters,
                ...this.unsavedFilters,
            ]);
        } catch (e: unknown) {
            if (e instanceof ServiceError && e.errorMessage) {
                this.messageStore.logError(e.errorMessage);
            }
        }
    }

    @computed
    get isFocused(): boolean {
        return !!this.selectedConversationId;
    }

    @computed
    get currentClip() {
        return {
            ...this.selectedConversation,
            filePath: this.selectedConversation?.mediaUrl,
        };
    }

    @computed
    get canSaveFilters() {
        const newFilterLabels = this.unsavedFilters.map((item) => item.name);
        const newFilterLabelSet = new Set(newFilterLabels);
        return newFilterLabels.length !== newFilterLabelSet.size;
    }

    @computed
    get activeAdditionalFilterItems() {
        const filters = this.additionalFilterCategories
            .flatMap((item) => item.filters)
            .filter((item) => item.isActive);

        return filters;
    }

    @computed
    get inactiveAdditionalFilterItems() {
        const filters = this.additionalFilterCategories
            .flatMap((item) => item.filters)
            .filter((item) => !item.isActive);

        return filters;
    }

    @computed
    get spanishTranscript(): ITranscriptionSegment[] | undefined {
        return getTranscriptByLangCode(
            "es",
            this.selectedConversation?.multiLangTranscriptions,
        );
    }

    @computed
    get activeSoundClips(): SoundClip[] | undefined {
        return this.conversationSoundClips
            ?.map((clip) => clip.soundClip)
            .filter((s) => s.isActive)
            .sort((a, b) => a.startTime - b.startTime)
            .slice();
    }

    @computed
    get topTopics(): Topics | null {
        const topicsClassifier: ConversationClassifier | undefined =
            this.selectedConversation?.conversationClassifiers.find(
                (classifier) =>
                    classifier.resultJson &&
                    JSON.parse(classifier.resultJson)?.ModelName ===
                        "ML-Topic-LLM",
            );

        if (topicsClassifier) {
            const result = JSON.parse(topicsClassifier.result);
            const topics: Topics = {
                topic1:
                    result["Topic 1"] && result["Topic 1"] !== "NA"
                        ? result["Topic 1"]
                        : null,
                topic2:
                    result["Topic 2"] && result["Topic 2"] !== "NA"
                        ? result["Topic 2"]
                        : null,
                topic3:
                    result["Topic 3"] && result["Topic 3"] !== "NA"
                        ? result["Topic 3"]
                        : null,
                topic1Reasoning:
                    (result["Topic 1"] &&
                        result["Topic 1 Reasoning"] &&
                        result["Topic 1"]) !== "NA"
                        ? result["Topic 1 Reasoning"]
                        : null,
                topic2Reasoning:
                    (result["Topic 2"] &&
                        result["Topic 2 Reasoning"] &&
                        result["Topic 2"]) !== "NA"
                        ? result["Topic 2 Reasoning"]
                        : null,
                topic3Reasoning:
                    (result["Topic 3"] &&
                        result["Topic 3 Reasoning"] &&
                        result["Topic 3"]) !== "NA"
                        ? result["Topic 3 Reasoning"]
                        : null,
            };
            return topics;
        } else {
            return null;
        }
    }

    // labels for meta 1-5
    public async getMetaLabels() {
        const metaLabels = await this.metaLabelService.getMetaLabels();
        return metaLabels;
    }

    public async getFocusedConversationAdditionalMetadata() {
        return this.setupAsyncTask("SingleConvoMetadata", async () => {
            const amdId = this.selectedConversationId;
            if (!amdId) return undefined;

            let audioMetadata: AudioMetadataModel =
                (await this.metadataService.getMetadataById(amdId)) ?? {};

            const extendedMetadata: Record<string, unknown> =
                await this.metadataService.getExtendedMetadataValuesById(amdId);

            return { audioMetadata, extendedMetadata };
        });
    }
}
