import { observable, reaction, action, makeObservable } from "mobx";
import validator from "validator";
import { isFunctionType } from "utils/TypeGuards";
import { BaseStore } from "./BaseStore";

export class ValidateProp {
    @observable value: string | number | undefined;
    @observable errorMessage: string | undefined;
    outputMessage: string;
    action:
        | "isEmail"
        | "isMobilePhone"
        | "isCreditCard"
        | string
        | ((arg: string) => boolean)
        | RegExp;

    constructor(val, err, output, action) {
        makeObservable(this);
        this.value = val;
        this.errorMessage = err;
        this.outputMessage = output;
        this.action = action;
    }
}

class ValidationStore extends BaseStore {
    @observable registeredProp: ValidateProp[] = [];

    @action
    register(prop: ValidateProp) {
        this.registeredProp.push(prop);
    }

    constructor() {
        super("Validation");
        makeObservable(this);
        reaction(
            () => JSON.stringify(this.registeredProp),
            this.debounceEffect(() => {
                this.validate();
            }, 500),
        );
    }

    validate() {
        this.registeredProp.forEach((element) => {
            element.errorMessage = undefined;
            if (element.value) {
                if (isFunctionType(element.action)) {
                    if (!element.action(element.value.toString())) {
                        element.errorMessage = element.outputMessage;
                    }
                } else if (element.action instanceof RegExp) {
                    if (!element.action.test(element.value.toString())) {
                        element.errorMessage = element.outputMessage;
                    }
                } else {
                    if (!validator[element.action](element.value.toString())) {
                        element.errorMessage = element.outputMessage;
                    }
                }
            }
        });
    }
}

export default ValidationStore;
