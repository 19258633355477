import { Badge, Link, Theme, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import React from "react";
import useStyles from "Styles/Styles";
import { ThemeColors } from "Theme/AppTheme";
import hexToRGB from "utils/hexToRGB";
import NavigationStore, { IItemOptions, INavigation } from "./NavigationStore";
import { observer } from "mobx-react";

const styles = (theme: Theme) =>
    createStyles({
        itemNavigationBody: {
            // marginLeft: "20px"
            paddingRight: theme.spacing(0.5),
            paddingLeft: theme.spacing(1.75),
        },
        itemNavigationRoot: {
            paddingRight: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.5),
        },
        itemLinkContainer: {
            paddingLeft: "6px",
            marginLeft: "8px",
            paddingRight: "6px",
            fontSize: "16px",
            minHeight: "24px",
        },
        curItemContainer: {
            borderRadius: "5px",
            backgroundColor: hexToRGB(ThemeColors.blue, 0.1),
        },
        itemContainer: {
            width: "100%",
            paddingLeft: "6px",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            minHeight: "32px",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        denseItemContainer: {
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        spreadItemContainer: {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
        },
        curItemLink: {
            color: ThemeColors.blue,
            fontWeight: "bold",
            textAlign: "left",
        },
        itemLink: {
            color: hexToRGB(ThemeColors.black, 0.5),
        },
        iconContainer: {
            opacity: 0.5,
        },
        groupHeader: {
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: "bold",
            color: theme.palette.black.main,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
    });

export default observer(function NavigationLink(props: {
    val: INavigation;
    store: NavigationStore;
    handleNavItemClick: (props: INavigation) => void;
    itemOptions?: IItemOptions;
}) {
    const classes = useStyles(styles);
    const theme = useTheme();

    const currentNavLink = props.store.currentNavItem?.link === props.val.link;

    return (
        <div
            key={`config-links-${props.val.title}`}
            className={clsx(
                props.store.currentNavItem?.link === props.val.link &&
                    classes.curItemContainer,
                classes.itemContainer,
                props.itemOptions?.itemSpacing === "spread" &&
                    classes.spreadItemContainer,
                props.itemOptions?.itemSpacing === "dense" &&
                    classes.denseItemContainer,
            )}
        >
            <div
                style={{
                    float: "left",
                    height: "24px",
                    width: "24px",
                    display: "flex",
                    alignItems: "center",
                }}
                className={clsx(
                    props.store.currentNavItem?.link !== props.val.link &&
                        classes.iconContainer,
                )}
            >
                {props.val.badgeContent ? (
                    <Badge
                        max={9999}
                        color="error"
                        overlap="rectangular"
                        badgeContent={props.val.badgeContent}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        {...props.val.badgeOptions}
                    >
                        <>
                            {props.store.currentNavItem?.link ===
                                props.val.link && props.val.selectedIcon
                                ? props.val.selectedIcon
                                : props.val.icon}
                        </>
                    </Badge>
                ) : props.store.currentNavItem?.link === props.val.link &&
                  props.val.selectedIcon ? (
                    props.val.selectedIcon
                ) : (
                    props.val.icon
                )}
            </div>
            <div
                key={`link-container-${props.val.title}`}
                className={clsx(classes.itemLinkContainer)}
            >
                <Link
                    variant="body1"
                    key={`navigation-item-${props.val.title}`}
                    style={{
                        textAlign: "left",
                        fontSize: props.itemOptions?.linkFontSize ?? "16px",
                        color: currentNavLink
                            ? theme.palette.primary.main
                            : theme.palette.gray.main,
                        fontWeight: currentNavLink ? "bold" : "normal",
                    }}
                    underline="none"
                    color="textPrimary"
                    component="button"
                    className={clsx(
                        props.store.currentNavItem?.link !== props.val.link &&
                            classes.itemLink,
                        props.store.currentNavItem?.link === props.val.link &&
                            classes.curItemLink,
                    )}
                    onClick={() => props.handleNavItemClick(props.val)}
                >
                    {props.val.title}
                </Link>
            </div>
        </div>
    );
});
