import FocusPage from "Layouts/Focus/FocusPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import MetadataSpecMainView from "./MetadataSpecMainView";

function MetadataBuildContainer() {
    const navigate = useNavigate();
    const onCancel = () => {
        navigate(-1);
    };

    return (
        <FocusPage title="Define Metadata Spec" onCancel={onCancel}>
            <MetadataSpecMainView />
        </FocusPage>
    );
}

export default MetadataBuildContainer;
