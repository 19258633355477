import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { FunctionComponent } from "react";

interface OwnProps {
    score: string;
    title: React.ReactNode;
}

type Props = OwnProps;

const EvalReviewCard: FunctionComponent<Props> = (props) => {
    return (
        <Grid
            container
            item
            direction="column"
            padding={3}
            flexWrap="nowrap"
            sx={(theme) => ({
                borderRadius: theme.spacing(1),
                background: theme.palette.white.main,
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
            })}
        >
            <Grid item>
                <Typography
                    sx={(theme) => ({
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        color: theme.palette.gray[400],
                    })}
                >
                    {props.title}
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    sx={(theme) => ({
                        fontSize: "30px",
                        fontWeight: 600,
                        lineHeight: "40px",
                        color: theme.palette.gray[600],
                    })}
                >
                    {props.score}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default EvalReviewCard;
