import {
    ClickAwayListener,
    Dialog,
    Grid,
    IconButton,
    Popper,
    SxProps,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import AcxMainTextField from "components/UI/AcxMainTextField";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { HierarchyService } from "services/HierarchyService";
import { BranchDataProps } from "../AcxRecursiveTree/AcxRecursiveTreeBranch";
import RecursiveTreeStore from "../AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import AcxSelectMulti from "../Select/BaseSelectComponents/AcxSelectMulti";
import HierarchySelectorBase from "./HierarchySelectorBase";
import { observer } from "mobx-react";
import DownChevronSvg from "../Select/BaseSelectComponents/DownChevronSvg";
import { useStore } from "utils/useStore";
import { useLocation } from "react-router";
import ClassifierBuilderV2ReclassifyDialogStore from "components/Classifiers/ClassifierBuilderV2/Stores/ClassifierBuilderV2ReclassifyDialogStore";

const useStyles = makeStyles<Theme, IAcxHierarchySelectorProps>((theme) => ({
    toggleBtnContainer: {
        paddingRight: theme.spacing(2),
        alignSelf: "flex-end",
    },
    hierToggleButton: {
        padding: "1rem",
        minWidth: "max-content",
    },
    toggleInputContainer: (props: IAcxHierarchySelectorProps) => ({
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        minHeight: props.removeMinHeight ? "auto" : "52px",
    }),
    selectorContainerDialog: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "flex-end",
        minHeight: "52px",
    },
    popper: (props: IAcxHierarchySelectorProps) =>
        props.customZIndex
            ? {
                  borderRadius: "8px",
                  zIndex: props.customZIndex,
                  "@media (max-height: 454px)": {
                      top: "60px !important",
                  },
              }
            : { borderRadius: "8px" },
    popperFloat: (props: IAcxHierarchySelectorProps) =>
        props.customZIndex
            ? {
                  borderRadius: "8px",
                  top: "-25px!important",
                  zIndex: props.customZIndex,
              }
            : { borderRadius: "8px", top: "-25px!important" },
    inputField: (props: IAcxHierarchySelectorProps) => ({
        width: "100%",
        minWidth: props.removeMinWidth ? "auto" : "350px",
    }),
    toggleInputStyles: {
        margin: "0px",
    },
    hierConfirmDialog: {
        padding: "0px",
        zIndex: 2000,
    },
    dialogInputField: {
        minWidth: "100%",
        maxWidth: "450px",
        padding: "0px",
    },
    hierLabel: {
        color: "inherit",
        fontWeight: "inherit",
        width: "100%",
        fontSize: "inherit",
        textOverflow: "ellipsis",
    },
    closeIconContainer: {
        width: "100%",
    },
    closeDialogIcon: {
        color: theme.palette.black.main,
        position: "relative",
        top: "11px",
        right: "11px",
        marginBottom: "5px",
        padding: "0px",
    },
    downChevron: { marginRight: "6px", cursor: "pointer" },
}));

const selectCustomStyle = {
    container: (provided, state) => ({
        ...provided,
        width: "100%",
        backgroundColor: "#fff",
        border: "1px solid rgba(31, 31, 31, 0.5)",
        borderRadius: "4px",
        maxHeight: "96px",
        overflowY: "auto",
    }),
    menu: (provided, state) => ({
        display: "none",
    }),
};

export interface IAcxHierarchyComponentProps
    extends IAcxHierarchySelectorProps {
    treeStore: RecursiveTreeStore;
    onApplyClick?: () => void;
}

interface IAcxHierarchySelectorProps {
    userId: string;
    orgId: string;
    displayType: UiDisplayType;
    setHierarchyIds: (selectedBranchIds: string[]) => void;
    onSaveUpdateWithHierarchies: (
        selectedBranchIds?: string[],
        treeStore?: RecursiveTreeStore,
    ) => void;
    initSelectedHierarchyIds?: string[];
    placeholder?: string;
    hideTopContent?: boolean;
    hideBottomContent?: boolean;
    removeMinHeight?: boolean;
    removeMinWidth?: boolean;
    fullHeight?: boolean;
    width?: string;
    applyBtnText?: string;
    singleSelect?: boolean;
    fixedPopper?: boolean;
    popperOpen?: boolean;
    popperPlacement?: "bottom" | "top";
    anchorEl?: any;
    handlePopperClose?: () => void;
    popToLeft?: boolean;
    labelText?: string;
    customZIndex?: number;
    treeStoreGetter?: (treeStore: RecursiveTreeStore) => void;
    storeFilterCategories?: (categories) => void;
    storeFilterSubategories?: (subcategories) => void;
    initCategories?: { categories: string[]; subcategories: string[] };
    /**
     * Only supported by displayType = "input" as of now (our only use case).
     */
    viewOnly?: boolean;
}

type UiDisplayType =
    | "baseOnly" // analyticx > eddy > filter & motfDefintionForm
    | "input" // conversations > search filters > input
    | "inputDialog" // analyticx > report > builder reclassify > input with confirm dialog
    | "popperOnly"; // dashboard & widget editor

const AcxHierarchySelector: React.FC<IAcxHierarchySelectorProps> = observer(
    ({ treeStoreGetter, ...props }: IAcxHierarchySelectorProps) => {
        const classes = useStyles(props);
        const [anchorEl, setAnchorEl] = useState<
            HTMLElement | (() => HTMLElement) | null | undefined
        >(null);
        const location = useLocation();

        const [popperOpen, setPopperOpen] = useState<boolean>(false);
        const [dialogOpen, setDialogOpen] = useState<boolean>(false);
        const popperId = popperOpen ? "hierarchies-popper" : undefined;
        const inputRef = useRef(null);

        const hierService = useMemo(() => new HierarchyService(), []);

        const rbcReclassifyStore = useStore(
            ClassifierBuilderV2ReclassifyDialogStore,
        );

        const treeStore = useMemo(() => {
            const newTreeStore = new RecursiveTreeStore({
                getBranch: async (orgId, osmId) => {
                    const res =
                        await await hierService.getAllChildrenForHierarchyAsync(
                            osmId,
                        );
                    return {
                        children: res.childrenHierarchies,
                        rowCount: res.rowCount,
                    };
                },
                updateBranch: (() => {}) as any,
            });
            newTreeStore.displayChildSelection = !props.singleSelect;
            newTreeStore.enableCheckboxSelection = true;

            newTreeStore.singleSelect = !!props.singleSelect;
            newTreeStore.setBranchContent((currentBranch: BranchDataProps) => {
                return (
                    <Grid
                        style={{
                            alignSelf: "center",
                            paddingTop: "1px",
                        }}
                        item
                        container
                    >
                        <Tooltip title={currentBranch.name}>
                            <Typography noWrap>{currentBranch.name}</Typography>
                        </Tooltip>
                    </Grid>
                );
            });

            // parentBranchId/selected branch as osmId
            newTreeStore.onSelectAllChildren = (
                parentBranchId: string,
                isChecked: boolean,
            ) => {
                hierService
                    .getAllChildrenForHierarchyAsync(parentBranchId)
                    .then((res) => {
                        let children = res.childrenHierarchies.filter(
                            (item) => item.hasPerms,
                        );
                        let idArr: string[] = [];
                        let labelArr: string[] = [];
                        children.forEach((branch) => {
                            idArr.push(branch.id);
                            labelArr.push(branch.name);
                        });
                        isChecked
                            ? treeStore.removeMultipleSelectedBranches(
                                  idArr,
                                  labelArr,
                              )
                            : treeStore.addMultipleSelectedBranches(
                                  idArr,
                                  labelArr,
                              );
                    });
            };

            newTreeStore.getRecursiveChildCount = async (
                parentBranchId: string,
            ) => {
                const res = await hierService.getAllChildrenForHierarchyAsync(
                    parentBranchId,
                );
                return res.rowCount;
            };

            treeStoreGetter?.(newTreeStore);
            return newTreeStore;
        }, [props.singleSelect, treeStoreGetter, hierService]);

        const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
            if (!props.fixedPopper) {
                setAnchorEl(inputRef.current);
            }
            setPopperOpen(!popperOpen);
        };

        const handlePopperClose = (event: MouseEvent | TouchEvent) => {
            setPopperOpen(false);
            treeStore.setOpenedBranchIds([]);
        };

        const handleDialogClose = () => {
            setDialogOpen(false);
            treeStore.setOpenedBranchIds([]);
        };

        const handleDialogOpen = () => {
            setDialogOpen(true);
        };

        const hierCountLabel =
            treeStore.selectedBranchIds.length === 0
                ? props.placeholder ?? "Service Hierarchies"
                : treeStore.selectedBranchIds.length === 1
                ? treeStore.selectedBranchIds.length + " Hierarchy Selected"
                : treeStore.selectedBranchIds.length + " Hierarchies Selected";

        // load top level hierarchies and clear old data
        useEffect(() => {
            if (props.orgId && props.userId) {
                treeStore.setOrganizationId(props.orgId);
                treeStore.setIsTreeLoading(true);

                async function fetchData() {
                    await loadUserServiceHierarchiesHelper(
                        hierService,
                        treeStore,
                    );
                    treeStore.setIsTreeLoading(false);
                }
                fetchData();
                // when orgId or userId changes we need to reset hierarchies
                treeStore.selectedBranchLabels = [];
            }
        }, [props.orgId, props.userId, hierService, treeStore]);

        // Ensures we set the local treeStore's categories if init values from Application Filter change
        useEffect(() => {
            treeStore.selectedCategories =
                props.initCategories?.categories ?? [];
            treeStore.selectedSubcategories =
                props.initCategories?.subcategories ?? [];
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            props.initCategories?.categories,
            props.initCategories?.subcategories,
        ]);

        // Ensures we update Application Filter Stores with Categories
        useEffect(() => {
            props.storeFilterCategories?.(treeStore.selectedCategories);
            props.storeFilterSubategories?.(treeStore.selectedSubcategories);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [treeStore.selectedCategories, treeStore.selectedSubcategories]);

        // set initial hierarchy data, used for preserving hierarchies from a store when component unmounts
        useEffect(() => {
            if (props.initSelectedHierarchyIds) {
                treeStore.selectedBranchIds = props.initSelectedHierarchyIds;
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [treeStore, props.initSelectedHierarchyIds, props.displayType]);

        // set onChange handler
        useEffect(() => {
            treeStore.onSelectedBranchIdsChange = (
                selectedBranchIds: string[],
            ) => {
                props.setHierarchyIds(selectedBranchIds);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.setHierarchyIds, treeStore.selectedBranchIds]);

        // domain: analyticx > eddy dash > filter
        const hierBaseOnly = (
            <HierarchySelectorBase
                fullHeight
                {...props}
                treeStore={treeStore}
                onApplyClick={() => {
                    // apply the hierarchies, I don't believe this changes. What I need to change is what gets selected
                    // when cat / sub-cat are selected/unselected
                    props.onSaveUpdateWithHierarchies(
                        treeStore.selectedBranchIds,
                        treeStore,
                    );
                    props.displayType === "inputDialog" && handleDialogClose();

                    props.displayType === "input" && setPopperOpen(false);
                    // for reclassify dialog to run check only on apply
                    if (location.pathname === "/app/classifiers") {
                        rbcReclassifyStore.setHierarchiesApplied(true);
                    }
                }}
            />
        );

        // externally controlled popper for dashboard & widgets
        const popperOnly: React.ReactNode = (
            <ClickAwayListener
                onClickAway={
                    props.handlePopperClose ? props.handlePopperClose : () => {}
                }
            >
                <Popper
                    id={"hierarchies-popper"}
                    open={!!props.popperOpen}
                    anchorEl={props.anchorEl}
                    sx={() => {
                        return props.customZIndex
                            ? {
                                  borderRadius: "8px",
                                  zIndex: props.customZIndex,
                                  "@media (max-height: 454px)": {
                                      top: "60px !important",
                                  },
                              }
                            : { borderRadius: "8px" };
                    }}
                    placement={props.popToLeft ? "left" : "bottom"}
                    style={props.popToLeft ? { marginRight: "20px" } : {}}
                >
                    {hierBaseOnly}
                </Popper>
            </ClickAwayListener>
        );

        // domain: conversations > search filters && workflows
        const hierInput = (
            <>
                <Grid
                    id="filters-hierarchy-input"
                    container
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    flexWrap="nowrap"
                    minHeight={props.removeMinHeight ? "auto" : "52px"}
                >
                    <Grid
                        item
                        onClick={handleInputClick}
                        ref={inputRef}
                        className={classes.inputField}
                        style={props.width ? { width: props.width } : {}}
                    >
                        <AcxMainTextField
                            id={"hierarchy-selector-input"}
                            fullWidth
                            containerStyle={{
                                width: "100%",
                            }}
                            disableTooltip
                            aria-describedby={popperId}
                            placeholderText={
                                props.placeholder ?? "Service Hierarchies"
                            }
                            isDisabled={true}
                            value={hierCountLabel}
                            endAdornment={
                                props.viewOnly &&
                                props.initSelectedHierarchyIds?.length ===
                                    0 ? null : props.viewOnly ? (
                                    <Typography
                                        sx={(theme) => ({
                                            marginRight: "6px",
                                            cursor: "pointer",
                                            fontWeight: 600,
                                            color: theme.palette.primary.main,
                                        })}
                                    >
                                        View
                                    </Typography>
                                ) : (
                                    <DownChevronSvg
                                        className={classes.downChevron}
                                    />
                                )
                            }
                            labelText={props.labelText ?? ""}
                        />
                    </Grid>
                </Grid>
                {popperOpen && (
                    <ClickAwayListener onClickAway={handlePopperClose}>
                        <Popper
                            id={popperId}
                            open={popperOpen}
                            anchorEl={anchorEl}
                            sx={() => {
                                let popperStyles: SxProps<Theme> = {
                                    borderRadius: "8px",
                                    top: "60px !important",
                                };
                                if (!props.popperPlacement) {
                                    if (props.customZIndex) {
                                        popperStyles.zIndex =
                                            props.customZIndex;
                                    }

                                    return popperStyles;
                                }

                                return props.customZIndex
                                    ? {
                                          borderRadius: "8px",
                                          zIndex: props.customZIndex,
                                          "@media (max-height: 454px)": {
                                              top: "60px !important",
                                          },
                                      }
                                    : {
                                          borderRadius: "8px",
                                          width: props.anchorEl?.clientWidth,
                                      };
                            }}
                            placement={props.popperPlacement ?? "right"}
                        >
                            {hierBaseOnly}
                        </Popper>
                    </ClickAwayListener>
                )}
            </>
        );

        // domain: analytics > builder reclassify
        const hierInputDialog = (
            <>
                <Grid
                    container
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "flex-end",
                        minHeight: "52px",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        onClick={handleDialogOpen}
                        sx={{
                            minWidth: "100%",
                            maxWidth: "450px",
                            padding: "0px",
                        }}
                    >
                        <AcxSelectMulti
                            id={"hierarchy-input-dialog"}
                            inputLabel={
                                props.labelText ?? "Select Hierarchy(ies)"
                            }
                            fullWidth
                            customStyle={selectCustomStyle}
                            placeholder={
                                props.placeholder ?? "Select hierarchy(ies)"
                            }
                            defaultValue={
                                treeStore.selectedBranchLabels?.map(
                                    (branchLabel) => ({
                                        label: (
                                            <Typography
                                                className={classes.hierLabel}
                                            >
                                                {branchLabel}
                                            </Typography>
                                        ),
                                        value: treeStore.selectedBranchLabels[
                                            branchLabel
                                        ],
                                    }),
                                ) ?? undefined
                            }
                            options={[]}
                            valueField="value"
                            labelField="label"
                            containerHeight="auto"
                            maxContainerHeight="50px"
                            alignControls="flex-start"
                        />
                    </Grid>
                </Grid>
                <Dialog
                    open={dialogOpen}
                    aria-labelledby="hier-dialog-title"
                    aria-describedby="hier-dialog-description"
                    maxWidth={"md"}
                    className={classes.hierConfirmDialog}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-end"
                        className={classes.closeIconContainer}
                    >
                        <IconButton
                            aria-label="close"
                            className={classes.closeDialogIcon}
                            onClick={handleDialogClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    {hierBaseOnly}
                </Dialog>
            </>
        );

        const renderHierarchySelectorUI = () => {
            switch (props.displayType) {
                case "baseOnly":
                    return hierBaseOnly;
                case "input":
                    return hierInput;
                case "inputDialog":
                    return hierInputDialog;
                case "popperOnly":
                    return popperOnly;
                default:
                    return null;
            }
        };

        return renderHierarchySelectorUI();
    },
);

export const loadUserServiceHierarchiesHelper = async (
    hierService: HierarchyService,
    treeStore: RecursiveTreeStore,
) => {
    treeStore.setIsTreeLoading(true);

    const userTopLevelHierarchiesResponse =
        await hierService.getTopLevelHierarchiesForUser();

    const userTopLevelTiers =
        userTopLevelHierarchiesResponse.childrenHierarchies;

    // reset openedBranchIds to ensure state is properly maintained when resetting topLevel
    treeStore.openedBranchIds = [];
    treeStore.setTopLevelBranches(userTopLevelTiers);
    treeStore.setIsTreeLoading(false);
};

export default AcxHierarchySelector;
