import {
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme,
    useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import Checkbox from "@mui/material/Checkbox";
import clsx from "clsx";
import { Observer } from "mobx-react";
import React, { useCallback } from "react";
import useStyles from "Styles/Styles";
import { IAcxTableRow } from "./AcxTable";
import AcxTableViewModel from "./AcxTableViewModel";

const styles = (theme: Theme) =>
    createStyles({
        background: {
            backgroundColor: theme.palette.primary.main + " !important",
            position: "sticky",
            top: 0,
            zIndex: 1,
        },
        font: {
            color: theme.palette.white.main,
        },
        active: {
            color: theme.palette.primary.main,
        },
        visuallyHidden: {
            border: 0,
            clip: "rect(0 0 0 0)",
            height: 1,
            margin: -1,
            overflow: "hidden",
            padding: 0,
            position: "absolute",
            top: 20,
            width: 1,
        },
        cellDense: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            fontSize: "13px",
        },
        cellMedium: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            fontSize: "13px",
        },
    });

export type OrderDirectionType = "asc" | "desc";

// export interface HeadCell {
//     disablePadding?: boolean;
//     id: string;
//     label: string;
//     numeric?: boolean;
// }

interface Props {
    viewModel: AcxTableViewModel;
    onSelectAllClick?: (selected: boolean) => void;
    onRequestSort?: (
        event: React.MouseEvent<unknown>,
        property: keyof IAcxTableRow,
    ) => void;
}

function AcxTableHeader(props: Props) {
    const classes = useStyles(styles);
    const theme = useTheme();
    const cellClass = useCallback(() => {
        return clsx({
            [classes.cellDense]: props.viewModel.enableDense,
            [classes.cellMedium]: !props.viewModel.enableDense,
        });
    }, [classes.cellDense, classes.cellMedium, props.viewModel.enableDense]);

    const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        // props.viewModel.selectAllRows = event.currentTarget.checked;
        props.viewModel.onSelectAllClick(event.currentTarget.checked);
    };
    const createSortHandler =
        (property: keyof IAcxTableRow) =>
        (event: React.MouseEvent<unknown>) => {
            props.viewModel.tableSort(property);
        };

    return (
        <Observer>
            {() =>
                props.viewModel.colCells?.length > 0 ? (
                    <TableHead className={classes.background}>
                        <TableRow>
                            {props.viewModel.enableCheck && (
                                <TableCell
                                    className={classes.background}
                                    padding="checkbox"
                                >
                                    <Checkbox
                                        checked={props.viewModel.selectAllRows}
                                        onChange={onSelectAllClick}
                                        color="primary"
                                        style={{
                                            color: theme.palette.white.main,
                                        }}
                                    />
                                </TableCell>
                            )}
                            {props.viewModel.colCells?.map((cell, l) => (
                                <TableCell
                                    key={"table_header_" + l}
                                    sx={{ color: theme.palette.white.main }}
                                    className={clsx(
                                        classes.background,
                                        classes.font,
                                        cellClass(),
                                    )}
                                    align={cell.numeric ? "right" : "left"}
                                    padding={
                                        cell.disablePadding ? "none" : "normal"
                                    }
                                >
                                    <TableSortLabel
                                        active={
                                            props.viewModel.orderByField ===
                                            cell.id
                                        }
                                        direction={
                                            props.viewModel.orderByField ===
                                            cell.id
                                                ? props.viewModel.orderDir
                                                : "asc"
                                        }
                                        onClick={createSortHandler(cell.id)}
                                        hideSortIcon={
                                            !props.viewModel.enableSort
                                        }
                                        // style={getStyleObj(props.orderBy===cell.id)}
                                    >
                                        {cell.label}
                                        {props.viewModel.orderByField ===
                                        cell.id ? (
                                            <span
                                                className={
                                                    classes.visuallyHidden
                                                }
                                            >
                                                {props.viewModel.orderDir ===
                                                "desc"
                                                    ? "sorted descending"
                                                    : "sorted ascending"}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                ) : (
                    <></>
                )
            }
        </Observer>
    );
}

export default AcxTableHeader;
