import moment, { Moment } from "moment";
import { isType } from "./TypeGuards";

export enum RefreshInterval {
    OFF = "Off",
    FIFTEEN = "Every 15 Minutes",
    THIRTY = "Every 30 Minutes",
    HOURLY = "Every Hour",
    DAILY = "Every Day",
}

export enum DynamicTimeSpan {
    CUSTOM = "Custom",
    HOURLY = "Past Hour",
    DAILY = "Past Day",
    WEEK = "Past Week",
    TWOWEEKS = "Past 2 Weeks",
    MONTH = "Past Month",
    SIXWEEKS = "Past 6 Weeks",
    QUARTER = "This Quarter",
    PREVQUARTER1 = "Last Quarter",
    PREVQUARTER2 = "Two Quarters Ago",
    PREVQUARTER3 = "Three Quarters Ago",
    PREVQUARTER4 = "Four Quarters Ago",
    YEAR = "Past Year",
    TENYEAR = "Past 10 Years",
}

export interface CustomDynamicTimeSpan {
    startDate: string;
    endDate: string;
}

export function isCustomDynamicTypeSpan(
    arg: unknown,
): arg is CustomDynamicTimeSpan {
    return isType<CustomDynamicTimeSpan>(arg, "startDate");
}

export function timeSpanToMoments(
    timeSpan: DynamicTimeSpan | CustomDynamicTimeSpan,
) {
    let endDate: Moment = moment().endOf("date");

    if (isCustomDynamicTypeSpan(timeSpan)) {
        return [
            moment(timeSpan.startDate),
            moment(timeSpan.endDate).add(1, "d").subtract(1, "second"),
        ] as readonly [Moment, Moment];
    }

    let startDate: Moment;

    switch (timeSpan) {
        case DynamicTimeSpan.HOURLY:
            startDate = moment().subtract(1, "hour").startOf("date");
            break;

        case DynamicTimeSpan.DAILY:
            startDate = moment().subtract(1, "day").startOf("date");
            break;

        case DynamicTimeSpan.WEEK:
            startDate = moment().subtract(7, "days").startOf("date");
            break;

        case DynamicTimeSpan.TWOWEEKS:
            startDate = moment().subtract(2, "weeks").startOf("date");
            break;

        case DynamicTimeSpan.MONTH:
            startDate = moment().subtract(1, "month").startOf("date");
            break;

        case DynamicTimeSpan.SIXWEEKS:
            startDate = moment().subtract(6, "weeks").startOf("date");
            break;

        case DynamicTimeSpan.QUARTER:
            startDate = moment().startOf("quarter");
            endDate = moment().endOf("quarter");
            break;

        case DynamicTimeSpan.PREVQUARTER1:
            startDate = moment().subtract(1, "Q").startOf("quarter");
            endDate = moment().subtract(1, "Q").endOf("quarter");
            break;

        case DynamicTimeSpan.PREVQUARTER2:
            startDate = moment().subtract(2, "Q").startOf("quarter");
            endDate = moment().subtract(2, "Q").endOf("quarter");
            break;

        case DynamicTimeSpan.PREVQUARTER3:
            startDate = moment().subtract(3, "Q").startOf("quarter");
            endDate = moment().subtract(3, "Q").endOf("quarter");
            break;

        case DynamicTimeSpan.PREVQUARTER4:
            startDate = moment().subtract(4, "Q").startOf("quarter");
            endDate = moment().subtract(4, "Q").endOf("quarter");
            break;

        case DynamicTimeSpan.TENYEAR:
            startDate = moment().subtract(10, "years").startOf("date");
            break;

        case DynamicTimeSpan.CUSTOM:
        case DynamicTimeSpan.YEAR:
        default:
            startDate = moment().subtract(1, "year").startOf("date");
            break;
    }

    return [startDate, endDate] as const;
}

export function refreshIntervalToMillis(refreshInterval: RefreshInterval) {
    switch (refreshInterval) {
        case RefreshInterval.DAILY:
            return 86400000;
        case RefreshInterval.FIFTEEN:
            return 900000;
        // case RefreshInterval.FIVE:
        //     return 300000;
        case RefreshInterval.HOURLY:
            return 3600000;

        case RefreshInterval.THIRTY:
            return 1800000;

        case RefreshInterval.OFF:
        default:
            return undefined;
    }
}

export function quarterLabelHelper(timespan: DynamicTimeSpan) {
    const labelString = (quarterNumber: number) =>
        `Q${moment()
            .startOf("quarter")
            .subtract(quarterNumber, "Q")
            .quarter()} ${moment()
            .startOf("quarter")
            .subtract(quarterNumber, "Q")
            .year()}`;

    switch (timespan) {
        case DynamicTimeSpan.QUARTER:
            return `Q${moment().quarter()} ${moment().year()}`;

        case DynamicTimeSpan.PREVQUARTER1:
            return labelString(1);

        case DynamicTimeSpan.PREVQUARTER2:
            return labelString(2);

        case DynamicTimeSpan.PREVQUARTER3:
            return labelString(3);

        case DynamicTimeSpan.PREVQUARTER4:
            return labelString(4);

        default:
            return timespan;
    }
}
