import { isArrayType } from "./TypeGuards";

export function truncate(str, n, useWordBoundary) {
    if (str.length <= n) {
        return str;
    }
    const subString = str.substr(0, n - 1);
    return (
        (useWordBoundary
            ? subString.substr(0, subString.lastIndexOf(" "))
            : subString) + "..."
    );
}

export function ArrayToCommaSepString(array: any[]) {
    if (isArrayType(array)) {
        const joinedArray = array.join(", ");
        return joinedArray;
    }
    return "";
}

export function tryParse<T extends object = any>(s?: string): T | false {
    try {
        return !s ? false : JSON.parse(s);
    } catch (e) {
        return false;
    }
}

export function isNumeric(str: any) {
    if (typeof str != "string") {
        return false;
    } // we only process strings!
    return (
        !isNaN(str as unknown as number) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
}

//Extracts a GUID from a string, or null if one is not found.
export function extractGuid(str: string | null | undefined): string | null {
    if (!str) {
        return null;
    }

    const match =
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g.exec(
            str,
        );
    return match ? match[0].toLowerCase() : null;
}
