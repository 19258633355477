import {
    ButtonBase,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import VerticalDrawerContentTemplate from "../../components/UI/Drawer/VerticalDrawerContentTemplate";
import { IWidget, WidgetType } from "../../models/Dashboard/Widget";
import { DashboardStore } from "../../stores/Layout/DashboardStore";

const useStyles = makeStyles((theme: Theme) => ({
    titleIcon: {
        paddingLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.25),
    },
}));

interface OwnProps {
    dashboardStore?: DashboardStore;
    onClose?: () => void;
    width: string;
}

type Props = OwnProps;

const PublishedWidgetsList: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles();

    const onClose = () => {
        props.dashboardStore?.cancelEditing();
        props.onClose?.();
    };

    return (
        <VerticalDrawerContentTemplate
            width={props.width}
            titleIcon={
                <ButtonBase
                    className={classes.titleIcon}
                    onClick={props.dashboardStore?.refreshPublishedWidgets}
                >
                    <RefreshIcon color={"primary"} />
                </ButtonBase>
            }
            onClose={onClose}
            loading={props.dashboardStore?.widgetListLoading}
            title={"Published Widgets"}
            content={
                <>
                    <List>
                        {props.dashboardStore?.availableWidgets.map((value) => {
                            return (
                                <React.Fragment key={value.key}>
                                    <ListItem
                                        button
                                        disabled={
                                            props.dashboardStore?.widgetKeys?.includes(
                                                value.key,
                                            ) ||
                                            !props.dashboardStore
                                                ?.canEditDashboard
                                        }
                                        onClick={() =>
                                            props.dashboardStore?.addWidget(
                                                value,
                                                false,
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            {iconForWidgetType(value)}
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                noWrap: false,
                                            }}
                                            style={{ whiteSpace: "normal" }}
                                            primary={value.name}
                                        />{" "}
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                    </List>
                </>
            }
        />
    );
});

function iconForWidgetType(widget: IWidget) {
    switch (widget.widgetType) {
        case WidgetType.Report:
            return <ShowChartIcon />;
        default:
            return <WidgetsIcon />;
    }
}

export default PublishedWidgetsList;
