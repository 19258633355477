import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import React from "react";
import { OrganizationService } from "services/OrganizationService";

type Option = {
    id: string;
    label: string;
};

type Props = {
    orgId: string;
    value: string[];
    onChange: (val: string[]) => void;
    error?: boolean;
    helperText?: string;
    fullWidth?: boolean;
    label?: string;
    placeholder?: string;
};

const ServiceHierarchySelect: React.FC<Props> = ({
    orgId,
    value,
    onChange,
    error,
    helperText,
    fullWidth,
    label,
    placeholder,
}) => {
    const [options, setOptions] = React.useState<Option[]>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handleHierarchyChange = (val?: Option[]) => {
        const values = val?.map((item) => item.id);
        onChange(values || []);
    };

    React.useEffect(() => {
        async function getFlattenedHierarchyOptions(orgId) {
            setIsLoading(true);
            const response =
                await new OrganizationService().getFlattenedServiceHierarchy(
                    orgId,
                );

            setOptions(response);
            setIsLoading(false);
        }

        if (orgId) {
            getFlattenedHierarchyOptions(orgId);
        }
    }, [orgId]);

    return (
        <div style={{ width: "99%" }}>
            <AcxSelectMulti
                id="hierarchyOptions"
                options={options || []}
                valueField="id"
                labelField="label"
                inputLabel={label ?? "Hierarchy Structure Members"}
                skeleton={isLoading}
                defaultValue={options?.filter((opt) => value.includes(opt.id))}
                onChange={handleHierarchyChange}
                containerHeight="auto"
                error={error}
                helperText={helperText}
                showAllErrors={true}
                fullWidth={fullWidth}
                placeholder={placeholder}
            />
        </div>
    );
};

export default ServiceHierarchySelect;
