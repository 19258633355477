export enum WidgetContentType {
    STATIC,
    DYNAMIC,
    IMMUTABLE,
}

export enum WidgetType {
    Report,
    EvaluationCoachingTable,
}

export interface IWidget {
    widgetType: WidgetType;
    contentType: WidgetContentType;
    name: string;
    key: string;
    organizationId: string;
    args: { [key: string]: any };
}
