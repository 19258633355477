import { observer } from "mobx-react";
import React from "react";
import StandardClassifierBuilderStore, {
    IStandardClassifierRule,
} from "./StandardClassifierBuilderStore";
import {
    Box,
    Divider,
    Grid,
    IconButton,
    Theme,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import theme from "Theme/AppTheme";
import { Stack } from "@mui/material";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import AcxMainTextField from "components/UI/AcxMainTextField";
import CloseIcon from "@mui/icons-material/Close";
import AcxButton from "components/UI/AcxButton";
import InfoIcon from "@mui/icons-material/Info";
import { SAID_WITHIN_MAX } from "constants/FullTextSearch";

const useBuilderRuleStyles = makeStyles((theme: Theme) => ({
    itemContainer: {
        padding: "8px",
    },
    andOrContainer: {
        marginTop: "12px",
        marginBottom: "12px",
        height: "28px",
        position: "relative",
    },
    andOrInner: {
        color: "#1E40AF",
        backgroundColor: theme.palette.lightBlue.background,
        borderRadius: "4px",
        padding: "5px 10px 5px 10px",
        position: "absolute",
        cursor: "pointer",
    },
    orDivider: {
        width: "100%",
        backgroundColor: theme.palette.lightBlue.background,
        height: "2px",
    },
    closeContainer: {
        "&:hover $closeButton": {
            visibility: "visible",
        },
        backgroundColor: theme.palette.lightgray.main,
        borderRadius: "4px",
    },
    closeButton: {
        visibility: "hidden",
    },
}));
interface Props {
    rule: IStandardClassifierRule;
    store: StandardClassifierBuilderStore;
    index: number;
    groupIndex: number;
}

const StandardClassifierBuilderRule: React.FunctionComponent<Props> = observer(
    ({ rule, store, index, groupIndex }) => {
        const classes = useBuilderRuleStyles();

        const [saidWithinInputVisible, setSaidWithinInputVisible] =
            React.useState(false);
        const [saidWithinEnabled, setSaidWithinEnabled] = React.useState(false);

        const isLargeScreen = useMediaQuery("(min-width:1650px)");

        const currentGroupList =
            store.classifierGroups[groupIndex].classifierRules;

        const listLength = currentGroupList.length;
        const lastItemIndex = listLength - 1;

        const nextItem =
            index !== lastItemIndex ? currentGroupList[index + 1] : undefined;

        const showAndOrChip = listLength !== 1 && index !== lastItemIndex;

        const isAnd = rule.filterGroupId === nextItem?.filterGroupId;

        const selectOptions = [
            { label: "Contains", value: "Contains" },
            {
                label: "Does not contain",
                value: "Does not contain",
            },
            {
                label: "Starts with",
                value: "Starts with",
            },
        ];

        React.useEffect(() => {
            if (rule.searchString.includes(" ") && rule.value === "Contains") {
                setSaidWithinEnabled(true);
            } else {
                setSaidWithinEnabled(false);
            }
        }, [groupIndex, index, rule.searchString, rule.value]);

        React.useEffect(() => {
            if (rule.saidWithin) {
                setSaidWithinEnabled(true);
                setSaidWithinInputVisible(true);
            }
        }, [rule.saidWithin]);

        const hasError =
            (!rule.searchString.includes(" ") && saidWithinInputVisible) ||
            (rule.value !== "Contains" && saidWithinInputVisible);

        const saidWithinError =
            !!rule.saidWithin &&
            (rule.saidWithin < 0 || rule.saidWithin > SAID_WITHIN_MAX);

        return (
            <>
                <Grid container item xs={12} className={classes.closeContainer}>
                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.itemContainer}
                    >
                        <Grid
                            container
                            item
                            xs={3}
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <AcxSelect
                                id="RBC-contains-select"
                                options={selectOptions}
                                defaultValue={selectOptions.find(
                                    (i) => i.value === rule.value,
                                )}
                                onChange={(item) => {
                                    store.updateRBCItemContains(
                                        index,
                                        groupIndex,
                                        item.value,
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={isLargeScreen ? 7 : 5}>
                            <AcxMainTextField
                                id={"words-text-field-" + groupIndex + index}
                                value={rule.searchString}
                                textareaRootStyles={{ paddingRight: "0.3rem" }}
                                onChange={(e) => {
                                    store.setRuleSearchString(
                                        index,
                                        groupIndex,
                                        e.target.value,
                                    );
                                }}
                                showAllErrors
                                error={hasError}
                                errorHelper="'Said within' only applies to phrases or word groups of 2 or more for 'Contains'"
                            />
                        </Grid>

                        <Grid
                            container
                            item
                            xs={isLargeScreen ? 2 : 4}
                            justifyContent="center"
                            alignItems="center"
                            wrap={"nowrap"}
                        >
                            <Grid item>
                                <Tooltip
                                    title={`Conditions are allowed for 'Contains' inputs with multiple words. 
                                    'Said Within' must be between 0 and ${SAID_WITHIN_MAX} and only applies to phrases or word groups of 2 or more. 
                                    If you'd like to search for exact phrases do not use a condition.`}
                                >
                                    <Grid container alignItems="center">
                                        <InfoIcon color="primary" />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <AcxButton
                                    fullWidth
                                    onClick={() => {
                                        store.setRuleSaidWithin(
                                            index,
                                            groupIndex,
                                            0,
                                        );
                                        setSaidWithinInputVisible(
                                            (prev) => !prev,
                                        );
                                    }}
                                    style={{
                                        backgroundColor:
                                            theme.palette.lightgray.main,
                                        color: theme.palette.blue.main,
                                    }}
                                    disabled={
                                        !saidWithinInputVisible &&
                                        !saidWithinEnabled
                                    }
                                >
                                    {saidWithinInputVisible ? "-" : "+"}{" "}
                                    Condition
                                </AcxButton>
                            </Grid>

                            <Grid item>
                                <IconButton
                                    onClick={() =>
                                        store.removeItem(index, groupIndex)
                                    }
                                    size="small"
                                    className={classes.closeButton}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    {saidWithinInputVisible && (
                        <Grid item container xs={12}>
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.itemContainer}
                            >
                                {/* spacing container */}
                                <Grid container item xs={3}></Grid>
                                <Grid item xs={7}>
                                    <AcxMainTextField
                                        labelText="Said Within"
                                        id={
                                            "said-within-text-field-" +
                                            groupIndex +
                                            index
                                        }
                                        value={rule.saidWithin}
                                        fullWidth
                                        type="number"
                                        placeholderText="# of words"
                                        onChange={(e) => {
                                            store.setRuleSaidWithin(
                                                index,
                                                groupIndex,
                                                Number(e.target.value),
                                            );
                                        }}
                                        isDisabled={!saidWithinEnabled}
                                        error={saidWithinError}
                                        errorHelper={`Must be between 0 and ${SAID_WITHIN_MAX}`}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {showAndOrChip && (
                    <Grid
                        container
                        className={classes.andOrContainer}
                        justifyContent={isAnd ? "flex-start" : "center"}
                        alignItems="center"
                    >
                        {!isAnd && <Divider className={classes.orDivider} />}
                        <Stack
                            direction="row"
                            className={classes.andOrInner}
                            alignItems={"center"}
                            onClick={() => {
                                store.changeRuleCombinator(
                                    index,
                                    groupIndex,
                                    !isAnd ? 0 : 1,
                                );
                            }}
                        >
                            <Box fontWeight={"bold"}>
                                {isAnd ? "AND" : "OR"}
                            </Box>
                        </Stack>
                    </Grid>
                )}
            </>
        );
    },
);

export default StandardClassifierBuilderRule;
