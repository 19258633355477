import { Grid, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AcxMainExpansion from "components/UI/AcxMainExpansion";
import AcxTable, { IAcxTableColumn } from "components/UI/AcxTable/AcxTable";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import { truncate } from "lodash";
import { AnswerAlert } from "models/Answer";
import LicensedModule from "models/LicensedModule";
import React, { FunctionComponent } from "react";
import { EvaluationAnswerHistory } from "services/EvaluationService";
import { parseFromISO } from "utils/DateTimeUtils";

const useStyles = makeStyles(() => ({
    root: {
        margin: "0.25rem",
    },
}));

interface OwnProps {
    licensedModules: LicensedModule[];
    evalAnswerHistory?: EvaluationAnswerHistory[];
    isLoading?: boolean;
}

type Props = OwnProps;

const AnswerHistoryView: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const classes = useStyles();

    const answerAlertFormatter = (item: number | null) =>
        item === null ? "" : AnswerAlert[item];

    const columns: IAcxTableColumn[] = [
        {
            headerLabel: "Changed By",
            dataKey: "changedBy",
        },
        {
            headerLabel: "Changed On",
            dataKey: "changedOn",
            formatter: (item) =>
                parseFromISO(new Date(item).toLocaleString()).format(
                    "MM/DD/YY hh:mm:ss A",
                ),
        },
        {
            headerLabel: "Previous Value(s)",
            dataKey: "previousValues",
        },
        {
            headerLabel: "Current Value(s)",
            dataKey: "currentValues",
        },
        {
            headerLabel: "Current Note",
            dataKey: "currentNote",
        },
        {
            headerLabel: "Previous Note",
            dataKey: "previousNote",
        },
        {
            headerLabel: "Current Alert",
            dataKey: "currentAnswerAlert",
            formatter: answerAlertFormatter,
        },
        {
            headerLabel: "Previous Alert",
            dataKey: "previousAnswerAlert",
            formatter: answerAlertFormatter,
        },
    ];

    return (
        <Grid container item xs={12} style={{ height: "100%" }}>
            <VerticalDrawerContentTemplate
                title={"Answer History by Module"}
                content={
                    <div style={{ height: "calc(100% - 2rem)" }}>
                        {props.evalAnswerHistory &&
                        props.evalAnswerHistory.length > 0 ? (
                            props.evalAnswerHistory.map((item) => {
                                const moduleName = props.licensedModules.find(
                                    (module) => module.id === item.moduleId,
                                )?.name;
                                return (
                                    <AcxMainExpansion
                                        header={moduleName}
                                        name={moduleName}
                                        body={item.answerHistoryObjectsList.map(
                                            (item) => (
                                                <AcxMainExpansion
                                                    key={item.questionId}
                                                    name={item.questionText}
                                                    indentLevel={1}
                                                    header={
                                                        <Tooltip
                                                            title={
                                                                item.questionText
                                                            }
                                                            placement={
                                                                "top-start"
                                                            }
                                                            disableFocusListener
                                                        >
                                                            <span>
                                                                {truncate(
                                                                    item.questionText,
                                                                    {
                                                                        length: 85,
                                                                    },
                                                                )}
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                    body={
                                                        <AcxTable
                                                            columns={columns}
                                                            rows={
                                                                item.answerHistoriesList
                                                            }
                                                            isLoading={
                                                                props.isLoading
                                                            }
                                                        />
                                                    }
                                                    rootClass={classes.root}
                                                />
                                            ),
                                        )}
                                    />
                                );
                            })
                        ) : (
                            <Typography color="secondary">
                                <br />
                                No Answer History Available
                            </Typography>
                        )}
                    </div>
                }
                width="750px"
            />
        </Grid>
    );
};

export default AnswerHistoryView;
