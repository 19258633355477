import React from "react";
import Lottie from "lottie-react";
import animationData from "./AgentLoading.json"; // Adjust the path as necessary

const AgentLoading = () => {
    return (
        <Lottie
            animationData={animationData}
            style={{ width: "100%", height: "100%" }}
        />
    );
};

export default AgentLoading;
