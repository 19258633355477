import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, makeObservable, observable, runInAction } from "mobx";
import { SoundClipService } from "services/SoundClipService";
import { TranscriptionService } from "services/TranscriptionService";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { TableComponentStore } from "stores/ComponentStores/TableComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

const soundClipService = new SoundClipService();
const transcriptionService = new TranscriptionService();

export interface ClipTranscriptionRow {
    id: string;
    blobContainer: string;
    running: boolean;
    complete: boolean;
    clipIds: string[];
    completedTranscriptions: number;
}

@AcxStore
export default class SoundClipTranscriptionStore {
    datePickerStore = new DatePickerComponentStore();
    tableStore = new TableComponentStore<ClipTranscriptionRow>(() => true);

    messageStore: MessageStore;

    @observable isLoading = false;
    @observable transcriptionStarted = false;

    constructor(rootStore: IRootStore) {
        makeObservable(this);
        this.messageStore = rootStore.getStore(MessageStore);
        this.getUntranscribedSoundClipsByOrg();
    }

    @action setLoading = () => {
        this.isLoading = true;
    };
    @action setNotLoading = () => {
        this.isLoading = false;
    };

    @action getUntranscribedSoundClipsByOrg = async () => {
        this.setLoading();
        const payload = await soundClipService.getUntranscribedSoundClips();
        runInAction(() => {
            const items = Object.entries(payload).map((entry, idx) => {
                return {
                    id: idx.toString(),
                    blobContainer: entry[0],
                    clipIds: entry[1],
                    completedTranscriptions: 0,
                    running: false,
                    complete: false,
                };
            });
            this.tableStore.setItems(items);
            this.setNotLoading();
        });
    };

    @action startTranscriptions = async () => {
        this.transcriptionStarted = true;

        const selectedRows = this.tableStore.selectedItems.filter(
            (r) => r.clipIds.length > 0,
        );

        for (const selectedRow of selectedRows) {
            const row = this.tableStore.items.find(
                (i) => i.blobContainer === selectedRow.blobContainer,
            );
            if (!row) continue;
            row.running = true;
            for (const clipId of row.clipIds) {
                try {
                    await transcriptionService.startSoundClipTranscription(
                        clipId,
                    );
                } catch (e) {
                    this.messageStore.logError(
                        "Soundclip transcription failed for " + clipId,
                    );
                }
                runInAction(() => {
                    row.completedTranscriptions += 1;
                });
                await new Promise((r) => setTimeout(r, 300));
            }
        }
    };
}
