import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import { OrganizationCustomerTypeStore } from "../stores/OrganizationCustomerTypeStore";
import { Checkbox, Grid, Paper } from "@mui/material";
import CustomerType from "models/CustomerType";

interface OrganizationAdditionalCustomerTypeProps {
    customerType: CustomerType;
}

const OrganizationAdditionalCustomerType = observer(
    (props: OrganizationAdditionalCustomerTypeProps) => {
        const store = useStore(OrganizationCustomerTypeStore);

        const isDeleteCustomerTypesLoading = store.getTaskLoading(
            OrganizationCustomerTypeStore.Tasks.DELETE_CUSTOMER_TYPES,
        );

        const isDisabled =
            isDeleteCustomerTypesLoading &&
            store.selectedCustomerTypeIds.has(props.customerType.id);

        const [hasError, errorMessage] = store.getCustomerTypeError(
            props.customerType,
        );

        return (
            <Grid item key={props.customerType.id}>
                <Paper
                    elevation={0}
                    sx={{
                        padding: "0.5rem",
                        borderRadius: "0.5rem",
                    }}
                >
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        columnSpacing={1}
                        flexWrap="nowrap"
                    >
                        <Grid item>
                            <Checkbox
                                checked={store.selectedCustomerTypeIds.has(
                                    props.customerType.id,
                                )}
                                onClick={() =>
                                    store.toggleSelectedCustomerType(
                                        props.customerType,
                                    )
                                }
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item width="100%">
                            <AcxMainTextField
                                id={props.customerType.id}
                                value={props.customerType.name}
                                placeholderText="Enter additional contact type..."
                                isDisabled={isDisabled}
                                onChange={(event) => {
                                    store.updateCustomerTypeName(
                                        props.customerType,
                                        event.target.value,
                                    );
                                }}
                                fullWidth
                                error={hasError}
                                errorHelper={errorMessage}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        );
    },
);

export default OrganizationAdditionalCustomerType;
