import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import AcxMicroPlayer from "components/UI/Audio/AcxMicroPlayer";

export default class AcxFormatters {
    playerFormatter: (params: GridRenderCellParams) => React.ReactNode = ({
        value,
    }) => {
        if (value === undefined) {
            return <></>;
        }

        return (
            <AcxMicroPlayer
                style={{ marginLeft: "auto", marginRight: "auto" }}
                mediaUrl={value}
            />
        );
    };
}
