import React from "react";
import Chart from "react-google-charts";
import AcxLoadingIndicator from "../AcxLoadingIndicator";

interface TextStyle {
    fontName?: string;
    fontSize?: number;
    color?: string;
    bold?: boolean;
    italic?: boolean;
}

interface Props {
    data: [string, string, number][]; // [From nodeName, To nodeName, weight][]
    nodeColors?: string[]; // reference: https://jsfiddle.net/PN03/4th0e7e6/8/
    nodePadding?: number;
    nodeWidth?: number;
    labelStyle?: TextStyle;
    labelPadding?: number;
    weightLabel?: string;
    height?: number;
    width?: number;
    linkColorMode?: "source" | "target" | "gradient" | "none";
    tooltipTextStyle?: TextStyle;
}

export default function AcxSankeyDiagram(props: Props): React.ReactElement {
    return (
        <Chart
            chartType="Sankey"
            loader={
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={1}
                />
            }
            data={[
                ["From", "To", props.weightLabel ?? "Weight"],
                ...props.data,
            ]}
            options={{
                height: props.height,
                width: props.width,
                sankey: {
                    link: { colorMode: props.linkColorMode ?? "source" },
                    node: {
                        colors: props.nodeColors,
                        label: props.labelStyle,
                        labelPadding: props.labelPadding,
                        nodePadding: props.nodePadding,
                        width: props.nodeWidth,
                    },
                },
                tooltip: { textStyle: props.tooltipTextStyle },
            }}
        />
    );
}
