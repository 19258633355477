import {
    Divider,
    Grid,
    IconButton,
    Paper,
    Toolbar,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import appTheme from "Theme/AppTheme";
import { observer } from "mobx-react";
import React from "react";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Pagination, Theme } from "@mui/material";
import RbcV2ResultsSearchTermsPanel from "./ClassifierBuilderV2SearchTermsPanel";
import { useNavigate } from "react-router";
import AcxButton from "components/UI/AcxButton";
import { useStore } from "utils/useStore";
import { ClassifierBuilderV2Store } from "../../Stores/ClassifierBuilderV2Store";
import RbcV2ResultsListWrapper from "./ClassifierBuilderV2ResultsListWrapper";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        minHeight: "77vh",
        maxHeight: "77vh",
        overflowY: "auto",
    },
    resultsPanelTop: {
        position: "sticky",
        top: 0,
        zIndex: 2,
        width: "100%",
        backgroundColor: "#fff",
    },
    resultsPanel: {
        padding: theme.spacing(3),
        paddingBottom: "0px",
        minHeight: "calc(77vh - 19vh)",
    },
    toolbar: {
        borderBottom: "0.5px solid var(--neutral-100, #E5E7EB)",
        backgroundColor: "white",
        paddingLeft: "12%",
        paddingRight: "13.5%",
        [theme.breakpoints.down(1550)]: {
            paddingLeft: "7%",
            paddingRight: "8%",
        },
        [theme.breakpoints.down(1350)]: {
            paddingLeft: "5%",
            paddingRight: "6%",
        },
        [theme.breakpoints.down(1280)]: {
            paddingLeft: "4%",
            paddingRight: "3%",
        },
    },
    toolbarTitle: {
        fontSize: "18px",
        fontWeight: theme.typography.fontWeightBold as any,
        color: "black",
        whiteSpace: "nowrap",
        display: "flex",
        padding: theme.spacing(2),
    },
    returnToListBtn: {
        backgroundColor: "white",
        color: appTheme.palette.blackFont.main,
        border: "1px solid #E4E4E7",
        maxWidth: "186px",
        marginRight: "8px",
        "&:hover": {
            color: "white",
        },
    },
    convoExplorerBtn: {
        maxWidth: "183px",
        marginLeft: "0px",
    },
    contentContainer: {
        paddingLeft: "calc(100% - 91%)",
        paddingRight: "calc(100% - 91%)",
        paddingTop: theme.spacing(3),
        height: "calc(100% - 84px)",
        justifyContent: "center",
        [theme.breakpoints.down(1550)]: {
            paddingLeft: "calc(100% - 98%)",
            paddingRight: "calc(100% - 98%)",
        },
    },
    resultsPanelTitle: {
        fontSize: "18px",
        fontWeight: theme.typography.fontWeightBold as any,
        color: "black",
        whiteSpace: "nowrap",
        display: "flex",
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    resultsPanelTitleCount: {
        fontSize: "12px",
        fontWeight: theme.typography.fontWeightBold as any,
        color: "black",
        whiteSpace: "nowrap",
        display: "flex",
        paddingTop: theme.spacing(3.1),
        paddingBottom: theme.spacing(2),
    },
    pagination: {
        bottom: 0,
        position: "sticky",
        display: "flex",
        alignItems: "center",
        maxHeight: "120px",
        backgroundColor: "#fff",
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(2.5),
        borderTop: "1px solid var(--neutral-100, #E5E7EB)",
    },
}));

const ClassifierBuilderV2Results = observer(() => {
    const classes = useStyles();
    const theme = useTheme();
    const store = useStore(ClassifierBuilderV2Store);
    const navigate = useNavigate();
    const [resTitleCount, setResTitleCount] = React.useState<number>(0);

    const onBack = () => {
        navigate(-1);
        store.setEstimatedTotalResCount(0);
    };

    const onConversationExplorer = () => {
        navigate(`/app/conversations/`);
    };

    const totalPages = Math.ceil((store.estimatedTotalResCount ?? 0) / 10);
    const haveResults =
        !!store?.rbcConversationResults?.length || store.pageNumber !== 1;
    const rbcConversationResultsPagination = (
        <Grid container item xs={12} className={classes.pagination}>
            <Grid
                container
                item
                xs={12}
                justifyContent="flex-end"
                alignItems="center"
            >
                <Grid item>
                    <Pagination
                        variant="outlined"
                        color="secondary"
                        count={totalPages > 10 ? 10 : totalPages}
                        page={store.pageNumber}
                        onChange={(evt, val) => {
                            store.setPageNumberAndGetClassifierResults(val);
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );

    React.useEffect(() => {
        setResTitleCount(
            store.estimatedTotalResCount > 100
                ? 100
                : store.estimatedTotalResCount,
        );
    }, [store.estimatedTotalResCount]);

    return (
        <Grid container direction="column">
            <Grid item style={{ width: "100%" }}>
                <Toolbar className={classes.toolbar}>
                    <Grid
                        container
                        item
                        xs={12}
                        direction="row"
                        wrap={"nowrap"}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item container alignItems="center">
                            <Grid item>
                                <IconButton onClick={onBack} size="large">
                                    <ArrowBack
                                        fontSize={"inherit"}
                                        color={"inherit"}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.toolbarTitle}>
                                    Classifier Results
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="row"
                            wrap={"nowrap"}
                            alignItems="center"
                            justifyContent="flex-end"
                            style={{ maxWidth: "400px" }}
                        >
                            <AcxButton
                                fullWidth
                                color="primary"
                                className={classes.returnToListBtn}
                                onClick={() => navigate(`../`)}
                                style={{
                                    borderRadius: "4px",
                                    border: "1px solid #E4E4E7",
                                    backgroundColor: "#FFF",
                                    color: theme.palette.black.main,
                                    marginRight: 0,
                                    height: "36px",
                                }}
                            >
                                Return to Classifier List
                            </AcxButton>
                            <AcxButton
                                fullWidth
                                color="primary"
                                className={classes.convoExplorerBtn}
                                style={{ height: "36px" }}
                                onClick={onConversationExplorer}
                            >
                                Conversation Explorer
                            </AcxButton>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Grid>
            <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                className={classes.contentContainer}
            >
                <RbcV2ResultsSearchTermsPanel onBack={onBack} />
                <Grid item xs={8}>
                    <Paper className={classes.paper}>
                        <Grid item xs={12} className={classes.resultsPanelTop}>
                            <Grid
                                item
                                container
                                justifyContent="flex-start"
                                direction="row"
                                alignItems="center"
                                wrap="nowrap"
                            >
                                <Typography
                                    className={classes.resultsPanelTitle}
                                >
                                    {`Results`}
                                </Typography>{" "}
                                {!store.resultsLoading &&
                                store.estimatedTotalResCount ? (
                                    <Typography
                                        className={
                                            classes.resultsPanelTitleCount
                                        }
                                    >
                                        {`(  Showing ${resTitleCount} of ${store.estimatedTotalResCount} total results )`}
                                    </Typography>
                                ) : (
                                    <></>
                                )}
                            </Grid>

                            <Divider />
                        </Grid>

                        <Grid item container className={classes.resultsPanel}>
                            <RbcV2ResultsListWrapper />
                        </Grid>

                        {haveResults && rbcConversationResultsPagination}
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default ClassifierBuilderV2Results;
