import {observer} from "mobx-react";
import {useStore} from "../../../utils/useStore";
import AgentChatStore from "../Stores/AgentChatStore";
import React from "react";
import {Grid} from "@mui/material";
import {Suggestion} from "../Suggestions/Suggestion";

export const SelectAllModulesInCategoryAction = observer(({args}) => {
    const store = useStore(AgentChatStore);
    const message = `Give me all modules in category ${args}`;
    
    return (
        <Grid item>
            <Suggestion onClick={() => {
                    store.streamingSubmitInput({
                        userMessage: message,
                        manualInput: "The chosen category is: Category_" + args
                    });
                }}>
                {message}
            </Suggestion>
        </Grid>
    );
});