export const fetch_retry = (
    url: string,
    options: any,
    n: number,
): Promise<Response> =>
    fetch(url, options).catch(function (error) {
        if (n === 1) throw error;
        if (error.name === "AbortError") throw error;
        return fetch_retry(url, options, n - 1);
    });
