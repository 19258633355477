import React from "react";
// import { createStyles, Theme, makeStyles } from '@mui/material/styles';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from "@mui/material/DialogTitle";
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import Switch from '@mui/material/Switch';
// import ColumnMap from 'components/Admin/AdminUIComponents/MetadataTemplate/MetaInput/ColumnMap';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     form: {
//       display: 'flex',
//       flexDirection: 'column',
//       margin: 'auto',
//       width: 'fit-content',
//     },
//     formControl: {
//       marginTop: theme.spacing(2),
//       minWidth: 120,
//     },
//     formControlLabel: {
//       marginTop: theme.spacing(1),
//     },
//   }),
// );

interface IFileMetaDialog {
    open: boolean;
    onCloseClick: () => void;
    title: string;
}

export default function FileMetaDialog(props: IFileMetaDialog) {
    //   const classes = useStyles();
    //   const [open, setOpen] = React.useState(props.open);

    const handleClose = () => {
        props.onCloseClick();
    };

    // function onColumnChange(item) {

    // }
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={false}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    {props.title}
                </DialogTitle>
                <DialogContent style={{ minHeight: "400px" }}>
                    {/* <ColumnMap rowLabel="Filename" onChange={onColumnChange} />  */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
