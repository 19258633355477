import {
    Grid,
    GridSize,
    IconButton,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import ClearIcon from "@mui/icons-material/Clear";
import AcxButton from "components/UI/AcxButton";
import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import { Moment } from "moment";
import React, { CSSProperties, useEffect } from "react";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import useStyles from "Styles/Styles";
import { isStringType, isType } from "../../../utils/TypeGuards";
import AcxTable, { IAcxTableRow, Props as TableProps } from "./AcxTable";
import AcxTableSelectionContext from "./AcxTableSelectionContext";

const styles = (theme: Theme) =>
    createStyles({
        controlSection: {
            // height: theme.spacing(4),
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
    });

export interface CustomControlItem {
    controlElement: React.ReactElement;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
    xl?: GridSize;
    style?: React.CSSProperties;
}

interface SelectionContextOptions {
    itemName?: string;
    itemNamePluralized?: string;
    selectedRows: IAcxTableRow[];
    renderSelectedRow: (row: IAcxTableRow) => React.ReactNode;
    action?: React.ReactNode;
}

interface Props extends TableProps {
    rowTerm?: React.ReactNode;
    controls?: (React.ReactElement | CustomControlItem)[];
    selectionContextOptions?: SelectionContextOptions;
    preHeader?: React.ReactElement;
    skeletonRows?: number;
    headerColumnSpan?: GridSize;
    controlsColumnSpan?: GridSize;
    headerColumnsStyle?: CSSProperties;
    controlsColumnStyle?: CSSProperties;
}

function AcxControlledTable(props: Props) {
    const { rowTerm, controls, selectionContextOptions, ...tableProps } = props;
    tableProps["removeHeightInPx"] = "64px";
    const [selItems, setSelItems] = React.useState(props.selectedItems || []);

    useEffect(() => {
        if (props.selectedItems) {
            setSelItems(props.selectedItems);
        }
    }, [props.selectedItems]);

    const classes = useStyles(styles);

    const onClear = () => {
        setSelItems([]);
    };

    const onSelected = (items: any[]) => {
        if (props.onSelecteditems) {
            props.onSelecteditems(items);
        }
    };

    const getHeader = () => {
        if (isStringType(props.rowTerm)) {
            const header = `${props.rows.length} ${props.rowTerm ?? "Items"} `;
            return (
                <Typography variant="subtitle1">
                    {header}
                    {props.selectedItems &&
                        props.selectedItems.length > 0 &&
                        !selectionContextOptions && (
                            // {/* {(selItems && selItems.length > 0) && */}
                            <>
                                ({props.selectedItems.length} selected
                                <Tooltip title="Clear all selections">
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={onClear}
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                )
                            </>
                        )}
                </Typography>
            );
        } else {
            return props.rowTerm;
        }
    };

    const headerColumnSpan = props.headerColumnSpan
        ? props.headerColumnSpan
        : 3;
    const controlsColumnSpan = props.controlsColumnSpan
        ? props.controlsColumnSpan
        : 9;

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems={"flex-end"}
                className={[
                    props.rowTerm || props.controls || props.preHeader
                        ? classes.controlSection
                        : "",
                ].join(" ")}
            >
                {Boolean(selectionContextOptions) && (
                    <Grid item xs={12} style={{ marginBottom: "16px" }}>
                        <AcxTableSelectionContext
                            {...selectionContextOptions!}
                            keyField={props.keyField}
                        />
                    </Grid>
                )}

                <Grid
                    item
                    xs={headerColumnSpan}
                    style={props.headerColumnsStyle}
                >
                    {getHeader()}
                </Grid>
                <Grid
                    item
                    xs={controlsColumnSpan}
                    container
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    alignContent="flex-end"
                    style={props.controlsColumnStyle}
                >
                    {props.controls?.map((arg, index) => {
                        let control = arg;

                        if (isTypeElementAndSize(arg)) {
                            control = arg.controlElement;
                        }

                        const controlMargin = {
                            marginRight: "0.25rem",
                            marginBottom: "0.25rem",
                        };

                        return (
                            <span
                                key={
                                    (control as React.ReactElement).key ??
                                    "control-" + index
                                }
                                style={{
                                    ...controlMargin,
                                    ...(arg as CustomControlItem).style,
                                }}
                            >
                                {control as React.ReactNode}
                            </span>
                        );
                    })}
                </Grid>
                {props.preHeader && <Grid item>{props.preHeader}</Grid>}
            </Grid>

            <AcxTable
                {...tableProps}
                onSelecteditems={onSelected}
                selectedItems={selItems}
            />
        </>
    );
}

AcxControlledTable.defaultProps = {
    enableSort: false,
    enableCheck: false,
    keyField: "id",
    isLoading: false,
};

AcxControlledTable.makeControls = (
    uniqueKeyPrefix: string,
    sharedControlId?: string,
    orgSelectStore?: OrgSelectorComponentStore,
    datePickerStore?: DatePickerComponentStore,
    actionButtonAction?: () => void,
    actionButtonText?: string,
) => {
    sharedControlId = sharedControlId ?? uniqueKeyPrefix;
    const controls: JSX.Element[] = [];

    if (datePickerStore) {
        controls.push(
            <AcxCalendarPopup
                key={`${sharedControlId}-begin-date`}
                id={`${sharedControlId}-begin-date`}
                currentDate={datePickerStore.beginDate}
                onSelect={(dt: Moment) => datePickerStore.setBeginDate(dt)}
            />,
        );
        controls.push(
            <AcxCalendarPopup
                key={`${sharedControlId}-end-date`}
                id={`${sharedControlId}-end-date`}
                currentDate={datePickerStore.endDate}
                onSelect={(dt: Moment) => datePickerStore.setEndDate(dt)}
            />,
        );
    }

    if (actionButtonAction !== null && actionButtonAction !== undefined) {
        controls.push(
            <AcxButton fullWidth onClick={actionButtonAction}>
                {actionButtonText || "Go"}
            </AcxButton>,
        );
    }

    return controls;
};

function isTypeElementAndSize(
    arg: React.ReactElement | CustomControlItem,
): arg is CustomControlItem {
    return isType<CustomControlItem>(arg, "controlElement");
}

export default AcxControlledTable;
