import React from "react";
import AcxControlledTable from "components/UI/AcxTable/AcxControlledTable";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Link } from "@mui/material";
import { standardDateFormat, parseFromISO } from "utils/DateTimeUtils";

const columns = (orgId: string) => [
    {
        headerLabel: "Name",
        dataKey: "name",
        formatter: (key, row) => (
            <Grid item>
                <Link
                    component={RouterLink}
                    to={`/admin/organizations/${orgId}/roles?id=${row.id}`}
                    color="secondary"
                    style={{ fontWeight: "bold" }}
                >
                    {key}
                </Link>
            </Grid>
        ),
    },
    {
        dataKey: "modifiedBy",
        headerLabel: "Modified By",
    },
    {
        dataKey: "modifiedOn",
        headerLabel: "Modified On",
        formatter: (key, row) => standardDateFormat(parseFromISO(key, true)),
    },
    {
        dataKey: "userCount",
        headerLabel: "Users",
    },
];

type Props = {
    data: any[];
    isLoading: boolean;
    orgId: string;
};

const OrganizationRolesListTable: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    data,
    isLoading,
    orgId,
}) => {
    return (
        <AcxControlledTable
            isLoading={isLoading}
            skeletonRows={5}
            rows={data}
            columns={columns(orgId)}
            enableStripes={false}
        />
    );
};

export default OrganizationRolesListTable;
