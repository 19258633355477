import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { debounce } from "lodash";
import { action, makeObservable, observable, runInAction, toJS } from "mobx";
import { ReportFieldDataTypes } from "models/Reporting/ReportDataField";
import { ReportField } from "models/Reporting/ReportField";
import { Comparator, ReportFilter } from "models/Reporting/ReportFilter";
import { ReportsService } from "services/ReportsService";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { delay } from "../../../utils/helpers";

const reportServiceV2 = new ReportsService("2.0");

@AcxStore
export class ReportQuickFilterStore {
    messageStore: MessageStore;
    //reportsStore: ReportsStore;

    @observable quickFilters: ReportFilter[] = observable.array();
    @observable quickFilterFields: ReportField[] = observable.array();
    @observable quickFilterValidValues: Map<string, string[]> =
        observable.map();

    // I cannot think of another way to react to changes to the filters without
    // triggering an infinite loop when a new report is loaded...
    @observable lockReportRefresh = false;

    public constructor(rootStore: IRootStore) {
        makeObservable(this);
        this.messageStore = rootStore.getStore(MessageStore);
    }

    @action
    reset() {
        this.quickFilters = observable.array();
        this.quickFilterFields = observable.array();
        this.quickFilterValidValues = observable.map();
    }

    setQuickFilterValue = debounce(
        action((index: number, newValue: string) => {
            this.quickFilters[index].setValue(newValue);
            console.log(toJS(this.quickFilters));
        }),
        100,
    );

    @action
    loadQuickFilters = async (orgId: string | undefined, reportId: string) => {
        if (!reportId) {
            return;
        }

        this.quickFilters = [];
        this.lockReportRefresh = true;
        this.quickFilterValidValues.clear();
        try {
            const payload = await reportServiceV2.getQuickFilters(
                reportId,
                `ReportQuickFilterStore/QuickFilters`,
            );
            const newFilters: ReportFilter[] = [];
            runInAction(() => {
                for (const validField of payload.filterFields) {
                    let defaultComparator = Comparator.EQ;
                    if (
                        validField.reportDataField?.datatype ===
                            ReportFieldDataTypes.Categorical ||
                        validField.reportDataField?.datatype ===
                            ReportFieldDataTypes.CategoricalText
                    ) {
                        defaultComparator = Comparator.IN;
                    }
                    const newFilter: ReportFilter = new ReportFilter();
                    newFilter.setComparator(defaultComparator);
                    newFilter.setReportField(validField);
                    newFilters.push(newFilter);
                }
                this.quickFilters = newFilters.sort(
                    (a, b) =>
                        (a.reportField?.order ?? 0) -
                        (b.reportField?.order ?? 0),
                );
                this.quickFilterFields = payload.filterFields.sort(
                    (a, b) => (a.order ?? 0) - (b.order ?? 0),
                );

                if (payload.validValues) {
                    for (const [key, value] of Object.entries(
                        payload.validValues,
                    )) {
                        this.quickFilterValidValues.set(key, value);
                    }
                }
            });
        } finally {
            await delay(1333);
            runInAction(() => {
                this.lockReportRefresh = false;
            });
        }
    };
}
