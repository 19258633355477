import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router";
import { useStore } from "utils/useStore";
import { ClassifierBuilderV2Store } from "../../Stores/ClassifierBuilderV2Store";
import { ClassifierUtils } from "models/ClassifierModel";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        height: "calc(100% - 30px)",
        width: "265px",
        marginRight: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down(950)]: {
            width: "205px",
        },
    },
    title: {
        fontSize: "18px",
        fontWeight: theme.typography.fontWeightBold as any,
        color: "black",
        padding: theme.spacing(3),
        paddingBottom: theme.spacing(2),
        width: "100%",
    },
    container: {
        height: "100%",
        width: "100%",
    },
    termsList: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        height: "calc(100vh - 404px)",
        overflowY: "auto",
    },
    editSearchTermsBtn: {
        maxWidth: "135px",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin: "auto",
        marginTop: theme.spacing(1.7),
    },
}));

interface Props {
    onBack: () => void;
}

const ClassifierBuilderV2ResultsSearchTermsPanel = observer((props: Props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const store = useStore(ClassifierBuilderV2Store);

    const renderTerms = () => {
        var split = store.rbcSearchTermStr.match(/(?:[^\s"]+|"[^"]*")+/g);
        if (!split) return [];
        return split?.map((item, index) => {
            return (
                <Grid item xs={12} key={"rbc-search-terms-" + index}>
                    {item} <br />
                </Grid>
            );
        });
    };
    return (
        <Paper className={classes.paper}>
            <Grid
                container
                direction="column"
                className={classes.container}
                wrap="nowrap"
            >
                <Grid item style={{ width: "100%" }}>
                    <Typography className={classes.title}>
                        {`Search Terms ( ${
                            ClassifierUtils.searchStringToTerms(
                                store.rbcSearchTermStr,
                            )?.length
                        } )`}
                    </Typography>
                </Grid>
                <Divider />
                <Grid item>
                    {/* <Grid item style={{ maxHeight: "85%", overflowY: "scroll" }}> */}
                    <Box className={classes.termsList}>{renderTerms()}</Box>
                </Grid>
                <Grid container item justifyContent="center">
                    <AcxButton
                        fullWidth
                        color="primary"
                        style={{ height: "36px" }}
                        onClick={() => navigate(-1)}
                        className={classes.editSearchTermsBtn}
                    >
                        Edit Search Terms
                    </AcxButton>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default ClassifierBuilderV2ResultsSearchTermsPanel;
