import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Grid, Theme } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export const highPriorityRenderTrue = (
    <Grid container justifyContent="center" alignItems="center">
        <ErrorOutlineIcon color="error" />
    </Grid>
);

function getStatusCellBackgroundStyles(
    value: string,
    theme: Theme,
): React.CSSProperties {
    let backgroundColor: string = theme.palette.white.main;
    let color: string = theme.palette.black.main;

    switch (value) {
        case "In Progress":
            backgroundColor = theme.palette.primary[100];
            color = theme.palette.primary.main;
            break;
        case "Overdue":
            backgroundColor = theme.palette.error[100];
            color = theme.palette.error[700];
            break;
        case "Closed":
            backgroundColor = "#DAF2EE";
            color = "#239C98";
    }

    return {
        backgroundColor,
        color,
        paddingInline: theme.spacing(1),
        overflow: "hidden",
        textOverflow: "ellipsis",
    };
}

export function StatusCell(params: GridRenderCellParams) {
    return (
        params.value && (
            <Grid
                container
                item
                sx={(theme) =>
                    getStatusCellBackgroundStyles(
                        params.value!.toString(),
                        theme,
                    )
                }
            >
                {params.value?.toString()}
            </Grid>
        )
    );
}
