import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import React from "react";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    classifierName?: string;
}

const ClassifierBuilderV2CopyDialog = ({
    isOpen,
    onClose,
    onConfirm,
    onCancel,
    classifierName,
}: Props) => {
    return (
        <AcxConfirmationDialog
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
            confirmButtonText="OK"
            cancelButtonText="Edit Copy"
            onCancel={onCancel}
            title={`"${classifierName}" created`}
            subTitle="Don’t forget to rename your classifier. Click on the classifier row to edit details and terms."
        />
    );
};

export default ClassifierBuilderV2CopyDialog;
