import {
    ClickAwayListener,
    Grid,
    Popper,
    Theme,
    useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { useMemo } from "react";
import useStyles from "Styles/Styles";
import AcxInputLabel from "../AcxInputLabel";
import AcxCalendarButton from "./AcxCalendarButton";
import Calendar from "./Calendar";

const styles = (theme: Theme) => {
    return createStyles({
        textLabel: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        popper: {
            zIndex: 9001,
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: "-0.09em",
                //   minWidth
                width: "3em",
                height: "1em",
                "&::before": {
                    borderWidth: "0 1em 1em 1em",
                    borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
                },
            },
            '&[x-placement*="top"] $arrow': {
                bottom: 0,
                left: 0,
                marginBottom: "-0.9em",
                width: "3em",
                height: "1em",
                "&::before": {
                    borderWidth: "1em 1em 0 1em",
                    borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
                },
            },
            '&[x-placement*="right"] $arrow': {
                left: 0,
                marginLeft: "-0.9em",
                height: "3em",
                width: "1em",
                "&::before": {
                    borderWidth: "1em 1em 1em 0",
                    borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
                },
            },
            '&[x-placement*="left"] $arrow': {
                right: 0,
                marginRight: "-0.9em",
                height: "3em",
                width: "1em",
                "&::before": {
                    borderWidth: "1em 0 1em 1em",
                    borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
                },
            },
        },
        arrow: {
            position: "absolute",
            fontSize: 9,
            width: "3em",
            height: "3em",
            "&::before": {
                content: '""',
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                borderStyle: "solid",
            },
        },
        inputText: {
            border: "1px solid",
            borderColor: theme.palette.black.light,
            backgroundColor: theme.palette.white.main,
            width: "calc(50% - 16px - 8px)",
            fontSize: "14px",
            lineHeight: "28px",
            fontFamily: theme.typography.fontFamily,
            paddingLeft: "6px",
        },
        startText: {
            borderRadius: `${theme.shape.borderRadius}px`,
        },
        endText: {
            borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
        },
        toMarker: {
            width: "16px",
            textAlign: "center",
        },
        textContainer: {
            border: "none",
            height: "32px",
            alignItems: "center",
        },
        boxShadowStyle_1: {
            boxShadow: theme.shadows[1],
        },
    });
};

interface Props {
    onSelect?: (
        selectedStartDate: moment.Moment,
        selectedEndDate: moment.Moment,
    ) => void;
    defaultStartDate?: moment.Moment;
    defaultEndDate?: moment.Moment;
    inputLabel?: string;
    labelClassName?: string;
    id: string;
    shrink?: boolean;
    disabled?: boolean;
}

export default function AcxDateRangePopup(props: Props) {
    const theme = useTheme();
    const classes = useStyles(styles);
    const [openPopover, setOpenPopover] = React.useState(false);
    const popRef = React.useRef<HTMLDivElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [selectedStartDate, setSelectedStartDate] = React.useState<
        Moment | undefined
    >(props.defaultStartDate);
    const [selectedEndDate, setSelectedEndDate] = React.useState<
        Moment | undefined
    >(props.defaultEndDate);
    const [startDateText, setStartDateText] = React.useState("");
    const [endDateText, setEndDateText] = React.useState("");
    const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(
        null,
    );

    React.useEffect(() => {
        if (props.defaultStartDate) {
            setSelectedStartDate(props.defaultStartDate);
            setStartDateText(props.defaultStartDate.format("M/D/YYYY"));
        }

        if (props.defaultEndDate) {
            setSelectedEndDate(props.defaultEndDate);
            setEndDateText(props.defaultEndDate.format("M/D/YYYY"));
        }
    }, [props.defaultEndDate, props.defaultStartDate]);

    const onDateClick = (dt: moment.Moment) => {
        if (selectedStartDate) {
            if (selectedEndDate || dt < selectedStartDate) {
                setSelectedEndDate(undefined);
                setEndDateText("");

                setSelectedStartDate(dt);
                setStartDateText(dt.format("M/D/YYYY"));
            } else {
                setSelectedEndDate(dt);
                setEndDateText(dt.format("M/D/YYYY"));

                props.onSelect && props.onSelect(selectedStartDate, dt);

                setOpenPopover(false);
            }
        } else {
            setSelectedStartDate(dt);
            setStartDateText(dt.format("M/D/YYYY"));
        }
    };

    function handleClickAway() {
        setOpenPopover(false);
    }

    const updateStartText = useMemo(
        () =>
            _.debounce((startDate: moment.Moment, endDate?: moment.Moment) => {
                if (endDate) props.onSelect?.(startDate, endDate);
                setSelectedStartDate(startDate);
            }, 700),
        [props],
    );

    const updateEndText = useMemo(
        () =>
            _.debounce((endDate: moment.Moment, startDate?: moment.Moment) => {
                if (startDate) props.onSelect?.(startDate, endDate);
                setSelectedEndDate(endDate);
            }, 700),
        [props],
    );

    const onStartTextChange = (el: React.ChangeEvent<HTMLInputElement>) => {
        const val = el.target.value;

        if (val !== startDateText) {
            try {
                const newStartDate = moment(val, "M/D/YYYY");
                setStartDateText(val);
                const reg =
                    /^([1-9]|[1][0-2])\/([1-9]|[1-2][0-9]|[3][0-1])\/(19|20)\d{2}$/;
                const fmtTest = reg.test(val);
                if (newStartDate.isValid() && fmtTest)
                    updateStartText(newStartDate, selectedEndDate);
            } catch (ex) {}
        }
    };

    const onEndTextChange = (el: React.ChangeEvent<HTMLInputElement>) => {
        const val = el.target.value;

        if (val !== endDateText) {
            try {
                const newEndDate = moment(val, "M/D/YYYY");
                setEndDateText(val);
                const reg =
                    /^([1-9]|[1][0-2])\/([1-9]|[1-2][0-9]|[3][0-1])\/(19|20)\d{2}$/;
                const fmtTest = reg.test(val);
                newEndDate.isValid() &&
                    fmtTest &&
                    updateEndText(newEndDate, selectedStartDate);
            } catch (ex) {}
        }
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                {props.inputLabel && (
                    <AcxInputLabel
                        shrink={props.shrink}
                        labelText={props.inputLabel}
                        className={clsx(
                            props.labelClassName,
                            classes.textLabel,
                        )}
                        id={props.id + "date-picker-label"}
                    />
                )}
                <Grid
                    container
                    ref={containerRef}
                    className={classes.textContainer}
                    alignItems="baseline"
                >
                    <input
                        className={classes.inputText + " " + classes.startText}
                        id="date-range-picker-text"
                        type="text"
                        value={startDateText}
                        onChange={onStartTextChange}
                    />
                    <div className={classes.toMarker}>-</div>
                    <input
                        className={classes.inputText + " " + classes.endText}
                        id="date-range-picker-text"
                        type="text"
                        value={endDateText}
                        onChange={onEndTextChange}
                    />
                    <AcxCalendarButton
                        onClick={() => setOpenPopover(true)}
                        disabled={props.disabled}
                    />
                </Grid>
                <div style={{ position: "relative" }} ref={popRef}></div>

                <Popper
                    anchorEl={containerRef.current}
                    placement="bottom-start"
                    open={openPopover}
                    disablePortal={false}
                    className={classes.popper}
                    modifiers={[
                        {
                            name: "flip",
                            enabled: false,
                        },
                        {
                            name: "preventOverflow",
                            enabled: true,
                            options: {
                                boundariesElement: "window", // Note: 'boundariesElement' has been replaced with 'boundary' in Popper.js v2
                            },
                        },
                        {
                            name: "arrow",
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },
                    ]}
                >
                    <span className={classes.arrow} ref={setArrowRef} />
                    <div
                        className={classes.boxShadowStyle_1}
                        style={{
                            backgroundColor: theme.palette.white.main,
                            padding: theme.spacing(1),
                        }}
                    >
                        <Calendar
                            onSelection={onDateClick}
                            selectedStartDate={
                                selectedStartDate && !selectedEndDate
                                    ? selectedStartDate
                                    : undefined
                            }
                            isRangeSelect
                        />
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

AcxDateRangePopup.defaultProps = {
    currentDate: moment(),
    shrink: false,
};
