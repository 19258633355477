import React from "react";
import { Route, Routes } from "react-router";
import OrganizationUserDetails from "./OrganizationUserDetails/OrganizationUserDetails";
import OrganizationUsersList from "./OrganizationUsersList";

type Props = {
    id: string;
};

const OrganizationUsers: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ id }) => {
    return (
        <Routes>
            <Route
                path={`:userId`}
                element={<OrganizationUserDetails orgId={id} />}
            />
            <Route path={"/*"} element={<OrganizationUsersList orgId={id} />} />
        </Routes>
    );
};

export default OrganizationUsers;
