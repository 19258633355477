import { Grid, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import { durationDisplay } from "../../../SoundClipEditor/Controls/Player";
import AcxMainTextField from "../../AcxMainTextFieldGrid";
import { useInputSearchTranscriptionStyles } from "./InputSearchTranscription.style";
import { useStore } from "utils/useStore";
import { AcxTranscriptionStore } from "../Stores/AcxTranscriptionStore";
import { observer } from "mobx-react";
import parser from "html-react-parser";

export default observer(function InputSearchTranscription() {
    const classes = useInputSearchTranscriptionStyles();
    const store = useStore(AcxTranscriptionStore);

    const searchResults = store.getSearchResults();

    return (
        <div className={classes.container}>
            <AcxMainTextField
                id="Transcription-text-search-separate"
                value={store.searchString}
                placeholderText={"Search within conversation..."}
                onChange={(e) => {
                    store.selectIndex = -1;
                    store.searchString = e.target.value;
                }}
                startAdornment={
                    <IconButton size="small">
                        <SearchIcon fontSize="small" />
                    </IconButton>
                }
                endAdornment={
                    store.searchString !== "" && (
                        <IconButton
                            size="small"
                            onClick={() => (store.searchString = "")}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    )
                }
            />
            {!!searchResults?.length ? (
                <div className={classes.resultsContainer}>
                    <Typography variant="h2" gutterBottom={true}>
                        {searchResults?.length} results for '
                        {store.searchString}'
                    </Typography>
                    {searchResults?.map((searchResult, i) => (
                        <Grid
                            key={i}
                            container
                            item
                            xs={12}
                            onClick={() => {
                                if (!searchResult.phraseStartTime) return;
                                const index = store.blurbs.findIndex(
                                    ({ timeInterval }) =>
                                        searchResult.phraseStartTime <=
                                        timeInterval[0],
                                );
                                store.setScrollIndex(
                                    index !== -1 ? index : null,
                                );
                            }}
                        >
                            <li className={classes.resultsItem}>
                                <span
                                    style={{
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {parser(
                                        store.formatTranscriptForHighlight(
                                            searchResult.stmt,
                                        ),
                                    )}
                                </span>
                                <span>
                                    {durationDisplay(
                                        searchResult.phraseStartTime,
                                    )}
                                </span>
                            </li>
                        </Grid>
                    ))}
                </div>
            ) : null}
        </div>
    );
});
