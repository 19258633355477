import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AssessmentIcon from "@mui/icons-material/Assessment";
import Navigation from "Layouts/SideNavigation/Navigation";
import {
    IItemOptions,
    INavigation,
} from "Layouts/SideNavigation/NavigationStore";
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import WorkflowsNavigationStore, {
    WorkflowsPage,
} from "../Stores/WorkflowsNavigationStore";

const styles = (theme: Theme) =>
    createStyles({
        unselectedIcon: {
            color: hexToRGB(theme.palette.black.main, 0.8),
        },
    });

type Props = {};

const itemOptions: IItemOptions = {
    itemSpacing: "spread",
    autoSelectOrg: true,
};

const WorkflowsNavigation: React.FC<Props> = observer(() => {
    const classes = useStyles(styles);
    const store = useStore(WorkflowsNavigationStore);

    React.useEffect(() => {
        const items: INavigation[] = [
            {
                title: "Workflow Aging",
                link:
                    store.WORKFLOWS_ROUTE_PREFIX +
                    WorkflowsPage.WORKFLOWS_AGING.toString(),
                icon: <AssessmentIcon className={classes.unselectedIcon} />,
                selectedIcon: <AssessmentIcon color="secondary" />,
                defaultRoute: true,
            },
        ];

        store.navStore.setNavItems(items);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.WORKFLOWS_ROUTE_PREFIX, store.navStore]);

    return <Navigation store={store.navStore} itemOptions={itemOptions} />;
});

export default WorkflowsNavigation;
