import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import { type ClassifierLibrary } from "models/ClassifierModel";
import type Classifier from "models/ClassifierModel";
import ClassifierService from "services/ClassifierService";
import { AuthStore } from "stores/AuthStore";
import { AsyncTaskStatus, BaseStore } from "stores/BaseStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import { AcxStore, type IRootStore } from "stores/RootStore";
import { ClassifierBuilderV2Store } from "../ClassifierBuilderV2/Stores/ClassifierBuilderV2Store";

@AcxStore
export default class ClassifierLibraryStore extends BaseStore {
    @observable
    library?: ClassifierLibrary;

    @computed
    get flatLibrary() {
        if (!this.library) return undefined;
        return Object.values(this.library).flat();
    }

    @observable
    selectedClassifierId?: string;
    @observable
    selectedClassifier?: Classifier;

    private classifierService: ClassifierService;
    readonly orgSelectStore = new OrgSelectorComponentStore();
    rbcStore: ClassifierBuilderV2Store;
    messageStore: MessageStore;
    authStore: AuthStore;
    

    public static Tasks = {
        LoadClassifierLibrary: "Load Classifier Library",
        LoadLibraryClassifier: "Load Library Classifier",
        AddGroupToTenant: (groupId: string) => `Add Group to Tenant ${groupId}`,
        AddClassifierToTenant: "Add Classifier to Tenant"
    } as const;

    constructor(private rootStore: IRootStore) {
        super("ClassifierLibrary");
        makeObservable(this);

        this.classifierService = new ClassifierService();
        this.messageStore = this.rootStore.getStore(MessageStore);
        this.rbcStore = this.rootStore.getStore(ClassifierBuilderV2Store);
        const orgStore = this.rootStore.getStore(AuthStore).orgStore;

        this.messageStore = rootStore.getStore(MessageStore);
        this.rbcStore = rootStore.getStore(ClassifierBuilderV2Store);

        reaction(() => orgStore.selectedOrganization, () => this.loadLibrary() )

        reaction(() => this.selectedClassifierId, () => {
            this.loadLibraryClassifier();
        })
    }

    /**
     * Setting this will invoke a reaction to load the selected classifier
     * @param coreClassifierId 
     */
    @action
    setSelectedClassifier(coreClassifierId: string) {
        this.selectedClassifierId = coreClassifierId;
    }

    @action
    loadLibrary() {
        return this.setupAsyncTask(ClassifierLibraryStore.Tasks.LoadClassifierLibrary, async () => {
            this.library = await this.classifierService.getClassifierLibrary();
        })
    }

    @action 
    loadLibraryClassifier(coreClassifierId: string | undefined = this.selectedClassifierId) {
        return this.setupAsyncTask(ClassifierLibraryStore.Tasks.LoadLibraryClassifier, async () => {
            if (!coreClassifierId)
                throw new Error("No classifier id provided");

            this.selectedClassifier = await this.classifierService.getCoreClassifier(coreClassifierId);
        });
    }

    addGroupToCurrentTenant(coreClassifierGroupId: string) {
        return this.setupAsyncTask(ClassifierLibraryStore.Tasks.AddGroupToTenant(coreClassifierGroupId), async () => {
            const status = await this.classifierService.addClassifiersFromCoreGroupToTenant(
                coreClassifierGroupId
            );

            if (status.successfulClassifierNames.length > 0) {
                if (this.flatLibrary) {
                    for (var classifier of this.flatLibrary) {
                        if (classifier.groupId !== coreClassifierGroupId)
                            continue;

                        classifier.existsInTenant = true;
                    }
                }
                this.rbcStore.loadClassifiers();
            }

            return status;
        });
    }

    /**
     * 
     * @param coreClassifierId Id of the core classifier, defaults to selected classifier id
     * @returns 
     */
    addClassifierToCurrentTenant(coreClassifierId: string | undefined = this.selectedClassifierId) {
        return this.setupAsyncTask(ClassifierLibraryStore.Tasks.AddClassifierToTenant, async () => {
            try {
                if (!coreClassifierId)
                    throw new Error("No classifier id provided");

                await this.classifierService.addCoreClassifierToTenant(coreClassifierId)

                if (this.flatLibrary) {
                    for (var classifier of this.flatLibrary) {
                        if (classifier.id !== coreClassifierId)
                            continue;

                        classifier.existsInTenant = true;
                    }
                }

                this.rbcStore.loadClassifiers();
            } catch (error: any) {
                this.messageStore.logError(error?.errorMessage ?? error.message);
                return AsyncTaskStatus.Error;
            }
        })
    }

}
