import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import { default as React } from "react";
import { ReportDataField } from "../../../../../models/Reporting/ReportDataField";
import {
    FieldUse,
    ReportField,
} from "../../../../../models/Reporting/ReportField";
import hexToRGB from "../../../../../utils/hexToRGB";
import { useStore } from "../../../../../utils/useStore";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import { QuickFilterOptions } from "../Editor";
import ReportFieldTransferList from "./ReportFieldTransferList";

const useStyles = makeStyles((theme: Theme) => ({
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    savAsBtn: {
        marginTop: theme.spacing(2.5),
    },
    addButton: {
        textTransform: "none",
        color: theme.palette.blue.main,
        marginTop: theme.spacing(3),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
    addButtonText: {
        color: theme.palette.blue.main,
        fontSize: "13px",
        fontWeight: theme.typography.fontWeightBold as any,
        letterSpacing: 0,
        lineHeight: "20px",
        marginLeft: theme.spacing(1),
    },
    addButtonIcon: {
        width: "16px",
        height: "16px",
        backgroundColor: hexToRGB(theme.palette.blue.main, 0.2),
        borderRadius: "50%",
    },
    removeButton: {
        fontWeight: theme.typography.fontWeightBold as any,
        textTransform: "none",
        backgroundColor: theme.palette.red.main,
        width: "17px",
        height: "17px",
        color: theme.palette.white.main,
        borderRadius: "50%",
        marginTop: theme.spacing(1.2),
        paddingBottom: "2px",
    },
    selectedQuickFiltersText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    bulletedList: {
        // listStyle: "circle",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },

    buttonIcon: {
        width: "10px",
        height: "10px",
        marginTop: "2px",
        textAlign: "center",
    },

    globalCombinatorText: {
        fontSize: "16px",
        letterSpacing: 0,
        lineHeight: "24px",
    },

    root: {
        marginTop: theme.spacing(3),
    },
    leftSubsectionPadding: {
        paddingLeft: theme.spacing(0.5),
    },
}));

function quickFilterFieldNameSelector(
    reportDataField: ReportDataField | ReportField,
) {
    if (reportDataField instanceof ReportField) {
        return reportDataField.reportDataField!.displayName;
    } else {
        return reportDataField.displayName;
    }
}

function quickFilterPredicate(field: ReportField) {
    return field.fieldUse === FieldUse.QuickFilter;
}

interface OwnProps {}

type Props = OwnProps;

const QuickFilterSection: React.FC<Props> = observer((props: Props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);

    return (
        <>
            <Grid
                container
                item
                xl={7}
                lg={7}
                md={7}
                sm={7}
                xs={7}
                direction="row"
                wrap={"nowrap"}
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{ paddingTop: "17px" }}
            >
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                        {store.quickFilterOption ===
                            QuickFilterOptions.Custom && (
                            <>
                                {" "}
                                <Typography
                                    className={clsx(
                                        classes.mainSectionTitleText,
                                    )}
                                >
                                    Quick Filters
                                </Typography>
                                <Typography variant="caption">
                                    Quick filters appear on the report to allow
                                    additional filtering on-the-fly.
                                </Typography>
                            </>
                        )}
                    </Grid>

                    <ReportFieldTransferList
                        onSelectedFields={
                            store.currentReport?.changeQuickFilters
                        }
                        hideAggregateSelectors
                        reportFieldPredicate={quickFilterPredicate}
                        labelFieldSelector={quickFilterFieldNameSelector}
                        availableFields={store.availableReportQuickFilterFields}
                        selectedFields={
                            store.currentReport?.quickFilterReportDataFields ??
                            []
                        }
                        selectedFieldsTitle={"Selected Filters"}
                        availableFieldsTitle={"Available Filters"}
                        idField={"displayName"}
                        height={"350px"}
                    />
                </Grid>
            </Grid>
        </>
    );
});

export default QuickFilterSection;
