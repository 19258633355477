import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const RolesSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            opacity={props.opacity ?? 0.5}
            fill={props.color ?? "#1F1F1F"}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6 1.59999C19.5999 1.39552 19.5476 1.19447 19.4479 1.01592C19.3483 0.837366 19.2047 0.687231 19.0308 0.579761C18.8568 0.472291 18.6583 0.41105 18.454 0.40185C18.2498 0.39265 18.0465 0.435796 17.8636 0.527194L8.51562 5.19999H4.00002C3.04524 5.19999 2.12957 5.57928 1.45444 6.25441C0.779309 6.92954 0.400024 7.84521 0.400024 8.79999C0.400024 9.75477 0.779309 10.6704 1.45444 11.3456C2.12957 12.0207 3.04524 12.4 4.00002 12.4H4.33602L6.46122 18.7792C6.54084 19.0182 6.69368 19.2262 6.89808 19.3735C7.10248 19.5208 7.34807 19.6001 7.60002 19.6H8.80002C9.11828 19.6 9.42351 19.4736 9.64855 19.2485C9.8736 19.0235 10 18.7183 10 18.4V13.1416L17.8636 17.0728C18.0465 17.1642 18.2498 17.2073 18.454 17.1981C18.6583 17.1889 18.8568 17.1277 19.0308 17.0202C19.2047 16.9128 19.3483 16.7626 19.4479 16.5841C19.5476 16.4055 19.5999 16.2045 19.6 16V1.59999Z"
            />
        </svg>
    );
};

export default RolesSvg;
