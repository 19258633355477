import { Grid, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import _ from "lodash";
import moment, { Moment } from "moment";
import React, { useCallback } from "react";
import useStyles from "../../../Styles/Styles";
import AcxCalendarButton from "./AcxCalendarButton";

const styles = (theme: Theme) => {
    return createStyles({
        inputText: {
            border: "1px solid",
            borderColor: theme.palette.black.light,
            backgroundColor: theme.palette.white.main,
            width: "calc(100% - 32px)",
            fontSize: "14px",
            lineHeight: "28px",
            fontFamily: theme.typography.fontFamily,
            paddingLeft: "6px",
        },
        textContainer: {
            border: "none",
            height: "32px",
            alignItems: "center",
        },
    });
};

interface AcxDatePickerProps {
    id?: string;
    onCalendarClick: () => void;
    onDateChange: (dt: Moment) => void;
    selectedDate?: moment.Moment;
    onBlur?: () => void;
    onFocus?: () => void;
    disabled?: boolean;
    inputStyle?: React.CSSProperties;
}

export default function AcxDatePicker(props: AcxDatePickerProps) {
    const classes = useStyles(styles);
    const [dtValue, setDtValue] = React.useState<string>(
        props.selectedDate ? props.selectedDate.format("l") : "",
    );
    const onClick = () => {
        props.onCalendarClick();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    let debouncedThing = useCallback(
        _.debounce(props.onDateChange, 700),

        [props.onDateChange],
    );

    const onTextChange = (el: React.ChangeEvent<HTMLInputElement>) => {
        const val = el.target.value;

        if (val !== dtValue) {
            try {
                const dt = moment(val, "M/D/YYYY");
                setDtValue(val);
                // debugger;
                const reg =
                    /^([1-9]|[1][0-2])\/([1-9]|[1-2][0-9]|[3][0-1])\/(19|20)\d{2}$/;
                const fmtTest = reg.test(val);
                if (dt.isValid() && fmtTest) {
                    debouncedThing.cancel();
                    debouncedThing(dt);
                    // props.onDateChange?.(dt);
                }
            } finally {
            }
        }
    };

    React.useEffect(() => {
        function doDate() {
            if (!props.selectedDate) {
                setDtValue("");
            } else if (
                props.selectedDate?.format("l") !== dtValue &&
                props.selectedDate !== undefined
            ) {
                setDtValue(props.selectedDate?.format("l"));
            }
        }

        doDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedDate]);
    const onBlur = () => {
        props.onBlur?.();
    };
    const onFocus = () => {
        props.onFocus?.();
    };
    return (
        <Grid
            container
            direction={"row"}
            className={classes.textContainer}
            alignItems="baseline"
            spacing={0}
        >
            {/* <Grid item xs={10}> */}
            <input
                id={props.id}
                type="text"
                disabled={props.disabled}
                onChange={onTextChange}
                onBlur={onBlur}
                onFocus={onFocus}
                value={dtValue}
                className={classes.inputText}
                style={props.inputStyle}
            />

            {/* </Grid>
            <Grid item xs={2}> */}
            <AcxCalendarButton onClick={onClick} disabled={props.disabled} />

            {/* </Grid> */}
        </Grid>
    );
}
