import { Saml2BindingType } from "components/Admin/Organizations/types/Organization.type";
import authService from "components/Auth/api-authorization/AuthorizeService";
import { makeObservable, observable } from "mobx";
import BaseEntity from "./BaseEntity";
import CrudEntity from "./CrudEntity";
import { LicensedDataProcessing } from "./DataProcesses";
import OrganizationStorageAccount from "./OrganizationStorageAccount";

export interface IOrganization extends BaseEntity {
    name?: string;
    qbAppId?: string;
    blobContainer?: string;
    fileInStageAccount?: string;
    fileInAccount?: string;
    fileMainAccount?: string;
    montageFileAccount?: string;
    pgpKeyName?: string;
    defaultTimeZoneId?: string;
    superOrganization?: boolean;
    saml2AuthenticxDomain?: string;
    customEmailFooterHtml?: string;
    customEmailFooterPlainText?: string;

    organizationStorageAccounts: OrganizationStorageAccount[];
    licensedData: LicensedDataProcessing[];
    appConfiguration: { appInsights: string };
    organizationConfigurations?: { evalAcknowledgeDays?: string };
}

export default class Organization extends CrudEntity implements IOrganization {
    static apiUrl: string = "api/Organizations";

    name?: string;
    qbAppId?: string;
    blobContainer?: string;
    fileInStageAccount?: string;
    fileInAccount?: string;
    fileMainAccount?: string;
    montageFileAccount?: string;
    pgpKeyName?: string;
    defaultTimeZoneId?: string;
    superOrganization?: boolean;
    serviceSetup?: string[];
    saml2AuthenticxDomain?: string;
    EnableSamlSso?: boolean;
    saml2BindingType?: Saml2BindingType;
    saml2CertificateInPemFormat?: string;
    saml2EntityId?: string;
    saml2LoginButtonText?: string;
    saml2ResponseEmailPropertyName?: string;
    saml2ResponseFirstNamePropertyName?: string;
    saml2ResponseLastNamePropertyName?: string;
    saml2SingleSignOnServiceUrl?: string;
    saml2SingleSignOutServiceUrl?: string;
    organizationConfigurations?: { evalAcknowledgeDays?: string };

    enhancedEvalStatus?: boolean;
    enhancedPermissionStatus?: boolean;

    eddyHourlyRate?: number;
    // Timeout Duration
    @observable timeoutDuration: number;

    @observable appConfiguration: { appInsights: string };
    @observable organizationStorageAccounts: OrganizationStorageAccount[];
    @observable licensedData: LicensedDataProcessing[];

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    static fromServer(json: IOrganization) {
        return Object.assign(new Organization(), json);
    }

    async create(): Promise<Response> {
        const params = new URLSearchParams({
            name: this.name || "",
            qbAppId: this.qbAppId || "",
            blobContainer: this.blobContainer || "",
            fileInStageAccount: this.fileInStageAccount || "",
            pgpKeyName: this.pgpKeyName || "",
            defaultTimeZoneId: this.defaultTimeZoneId || "",
        }).toString();
        const token = await authService.getAccessToken();
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };

        return await fetch(Organization.apiUrl + "?" + params, {
            method: "POST",
            headers: headers,
        });
    }

    async update(): Promise<Response> {
        const params = new URLSearchParams({
            id: this.id || "",
            name: this.name || "",
            qbAppId: this.qbAppId || "",
            blobContainer: this.blobContainer || "",
            fileInStageAccount: this.fileInStageAccount || "",
            pgpKeyName: this.pgpKeyName || "",
            defaultTimeZoneId: this.defaultTimeZoneId || "",
        }).toString();
        const token = await authService.getAccessToken();
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };

        return await fetch(Organization.apiUrl + "?" + params, {
            method: "PUT",
            headers: headers,
        });
    }

    async delete(): Promise<Response> {
        const token = await authService.getAccessToken();
        const headers = {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
        };

        return await fetch(Organization.apiUrl + "/" + this.id, {
            method: "DELETE",
            headers: headers,
        });
    }
}
