import AudioMetadataModel from "models/AudioMetadataModel";
import { SamplingType } from "services/AudioFilesService";

import { AudioFileSamplerStore } from "components/Admin/AdminUIComponents/AudioFileSampler/Stores/AudioFileSamplerStore";
import { RecommendationStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/RecommendationStepStore";
import { SourceFilesStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/SourceFilesStepStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { BaseStore } from "../../../../../stores/BaseStore";

import { action, makeObservable } from "mobx";
import { AuthStore } from "stores/AuthStore";
import { OrganizationStore } from "stores/OrganizationStore";
import { InteractionHierarchyLevels } from "models/InteractionHierarchyLevels";

@AcxStore
export class SamplerStore extends BaseStore {
    private readonly recommendationStepStore: RecommendationStepStore;
    private readonly sourceFilesStepStore: SourceFilesStepStore;
    private readonly audioFileSamplerStore: AudioFileSamplerStore;
    readonly orgStore: OrganizationStore;

    constructor(private rootStore: IRootStore) {
        super("Sampler Store");

        makeObservable(this);

        this.recommendationStepStore = rootStore.getStore(
            RecommendationStepStore,
        );
        this.sourceFilesStepStore = rootStore.getStore(SourceFilesStepStore);
        this.orgStore = rootStore.getStore(AuthStore).orgStore;
        this.audioFileSamplerStore = rootStore.getStore(AudioFileSamplerStore);
    }

    @action
    handleSample = (
        filesToSample: AudioMetadataModel[],
        hierarchyLevels?: InteractionHierarchyLevels[],
    ) => {
        // Setup for outside of sampler sampling
        this.audioFileSamplerStore.isFromOutsideSampler = true;
        this.sourceFilesStepStore.isFromOutsideSampler = true;
        this.sourceFilesStepStore.orgSelectStore.selectOrg(
            this.orgStore.selectedOrganization,
        );

        // Setup Recommendation Step Store so we can use its useSample function
        this.audioFileSamplerStore.orgId =
            this.orgStore.selectedOrganization?.id;

        const ids = filesToSample.map((file) => file.id);

        this.recommendationStepStore.tableStore.setItems(filesToSample);
        this.recommendationStepStore.tableStore.setSelectedItems(ids);

        const selections = hierarchyLevels ?? [];

        this.recommendationStepStore.hierarchyAssignmentDistribution.set(0, {
            selections,
            count: this.recommendationStepStore.tableStore.selectedItems.length.toString(),
        });

        this.recommendationStepStore.setSamplingType(
            SamplingType.MiddleOfTheFunnel,
        );

        this.recommendationStepStore.useSample();
    };
}
