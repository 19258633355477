import { Divider, Grid } from "@mui/material";
import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import SideNavigation from "Layouts/SideNavigationNew/SideNavigation";
import SideNavigationLink from "Layouts/SideNavigationNew/SideNavigationLink";
import { observer } from "mobx-react";
import React from "react";
import {
    Assignment as AssignmentIcon,
    AddCircleOutline as AddCircleOutlineIcon,
} from "@mui/icons-material";
import { useStore } from "utils/useStore";
import ClassifierLibraryStore from "./Stores/ClassifierLibraryStore";
import { Route, Routes, useLocation } from "react-router";
import Page from "components/Navigation/Page";
import ClassifierLibrary from "./Views/ClassifierLibrary";
import AddLibraryClassifier from "./Views/AddLibraryClassifier";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AuthStore } from "stores/AuthStore";
import theme from "Theme/AppTheme";
import ReclassifyHistory from "./Views/ReclassifyHistory";
import ClassifierBuilderV2 from "./ClassifierBuilderV2/ClassifierBuilderV2";
import ClassifierBuilderTableV2Create from "./ClassifierBuilderV2/Views/Builder/ClassifierBuilderV2Create";
import ClassifierBuilderV2Results from "./ClassifierBuilderV2/Views/Results/ClassifierBuilderV2Results";
import ClassifierBuilderTableV2Edit from "./ClassifierBuilderV2/Views/Builder/ClassifierBuilderV2Edit";

// taken from figma
function CollectionsIcon() {
    const strokeColor = theme.palette.gray[500];
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="collection">
                <path
                    id="Vector"
                    d="M19 11H5M19 11C19.5304 11 20.0391 11.2107 20.4142 11.5858C20.7893 11.9609 21 12.4696 21 13V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V13C3 12.4696 3.21071 11.9609 3.58579 11.5858C3.96086 11.2107 4.46957 11 5 11M19 11V9C19 8.46957 18.7893 7.96086 18.4142 7.58579C18.0391 7.21071 17.5304 7 17 7M5 11V9C5 8.46957 5.21071 7.96086 5.58579 7.58579C5.96086 7.21071 6.46957 7 7 7M17 7V5C17 4.46957 16.7893 3.96086 16.4142 3.58579C16.0391 3.21071 15.5304 3 15 3H9C8.46957 3 7.96086 3.21071 7.58579 3.58579C7.21071 3.96086 7 4.46957 7 5V7M17 7H7"
                    stroke={strokeColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
}

function navGroupMapper(originalGroup: string): string {
    if (originalGroup === "preview") return "library";

    return originalGroup;
}

function getNavGroup(pathname: string): string {
    let currentNavGroup = pathname.substring(
        pathname.indexOf("classifiers") + "classifiers".length,
    );
    if (currentNavGroup.startsWith("/"))
        currentNavGroup = currentNavGroup.substring(1);
    if (currentNavGroup.indexOf("/") >= 0)
        currentNavGroup = currentNavGroup.substring(
            0,
            currentNavGroup.indexOf("/"),
        );

    currentNavGroup = navGroupMapper(currentNavGroup);
    return currentNavGroup;
}

const useStyles = makeStyles(() => {
    return {
        mainContent: {
            paddingBlock: "8px",
            paddingInline: "0px",
        },
    };
});

const ClassifiesrLayout = observer(() => {
    const classifierLibraryStore = useStore(ClassifierLibraryStore);
    const authStore = useStore(AuthStore);

    const classes = useStyles();

    const { pathname } = useLocation();
    let currentNavGroup = getNavGroup(pathname);

    let currentNavItem = "";
    if (currentNavGroup === "library")
        currentNavItem = pathname.substring(pathname.indexOf(currentNavGroup));

    return (
        <SideControlsPage
            drawerLocation="left"
            drawerMaxWidth={"100%"}
            mainGridSpacing={0}
            mainGridJustify={"flex-start"}
            mainGridAlignItems={"stretch"}
            containerOffsetPixels={64}
            drawerVerticalOffsetPixels={0}
            mainContentClasses={classes.mainContent}
            enableMinimize
            hideMinimizeToggle
            drawerClosed={currentNavGroup === "reclassify-history"}
            drawerContent={
                <Grid padding={2}>
                    <Grid>
                        {authStore.canUserView("Classifier Library View") && (
                            <SideNavigationLink
                                val={{
                                    title: (
                                        <Box fontSize="14px">
                                            Classifier Library
                                        </Box>
                                    ),
                                    icon: <CollectionsIcon />,
                                    link: "library",
                                    signalsVariant: true,
                                }}
                                currentNavItem={currentNavGroup}
                                replaceLinks={{}}
                            />
                        )}
                        {authStore.canUserView("Builder") && (
                            <>
                                <SideNavigationLink
                                    val={{
                                        title: (
                                            <Box fontSize="14px">
                                                Your Classifiers
                                            </Box>
                                        ),
                                        icon: <AssignmentIcon />,
                                        link: "",
                                        signalsVariant: true,
                                    }}
                                    currentNavItem={currentNavGroup}
                                    replaceLinks={{}}
                                />
                                {authStore.canUserEdit("Builder") && (
                                    <SideNavigationLink
                                        val={{
                                            title: (
                                                <Box fontSize="14px">
                                                    Classifier Builder
                                                </Box>
                                            ),
                                            icon: <AddCircleOutlineIcon />,
                                            link: "create",
                                            signalsVariant: true,
                                        }}
                                        currentNavItem={currentNavGroup}
                                        replaceLinks={{}}
                                    />
                                )}
                            </>
                        )}
                    </Grid>
                    <Divider />
                    {currentNavGroup === "library" && (
                        <SideNavigation
                            items={Object.entries(
                                classifierLibraryStore.library ?? {},
                            ).map(([group, classifiers], idx) => ({
                                header: <Box fontSize="14px">{group}</Box>,
                                items: classifiers.map((classifier) => ({
                                    title: classifier.name,
                                    link: `library/${classifier.id}`,
                                    signalsVariant: true,
                                })),
                                expanded:
                                    (idx === 0 &&
                                        !classifierLibraryStore.selectedClassifierId) ||
                                    classifiers.find(
                                        (c) =>
                                            c.id ===
                                            classifierLibraryStore.selectedClassifierId,
                                    ) !== undefined,
                            }))}
                            currentNavItem={currentNavItem}
                            organizationId=""
                            isLoading={classifierLibraryStore.getTaskLoading(
                                ClassifierLibraryStore.Tasks
                                    .LoadClassifierLibrary,
                            )}
                            signalsVariant
                        />
                    )}
                </Grid>
            }
            mainContent={
                <Routes>
                    {authStore.canUserView("Classifier Library View") && (
                        <>
                            <Route
                                path="library"
                                element={
                                    <Page title="Classifier Library | Authenticx">
                                        <ClassifierLibrary />
                                    </Page>
                                }
                            ></Route>
                            <Route
                                path="library/:classifierId"
                                element={
                                    <Page title="Add Library Classifier | Authenticx">
                                        <AddLibraryClassifier />
                                    </Page>
                                }
                            ></Route>
                        </>
                    )}
                    {authStore.canUserView("Builder") && (
                        <>
                            <Route
                                path=""
                                element={
                                    <Page title="Your Classifiers | Authenticx">
                                        <ClassifierBuilderV2 />
                                    </Page>
                                }
                            ></Route>
                            <Route
                                path="reclassify-history"
                                element={
                                    <Page title="Reclassify History | Authenticx">
                                        <ReclassifyHistory />
                                    </Page>
                                }
                            ></Route>
                            {authStore.canUserEdit("Builder") && (
                                <>
                                    <Route
                                        path="create"
                                        element={
                                            <Page title="New Classifier | Authenticx">
                                                <ClassifierBuilderTableV2Create />
                                            </Page>
                                        }
                                    ></Route>
                                    <Route
                                        path="results"
                                        element={
                                            <Page title="Preview Classifier Results | Authenticx">
                                                <ClassifierBuilderV2Results />
                                            </Page>
                                        }
                                    ></Route>
                                    <Route
                                        path="edit/:id"
                                        element={
                                            <Page title="Edit Classifier | Authenticx">
                                                <ClassifierBuilderTableV2Edit />
                                            </Page>
                                        }
                                    ></Route>
                                </>
                            )}
                        </>
                    )}
                </Routes>
            }
        />
    );
});

export default ClassifiesrLayout;
