import { action, observable, reaction, makeObservable } from "mobx";
import TenantAuthorizationService from "services/TenantAuthorizationService";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";

@AcxStore
export class UserTenantAuthorizationStore extends BaseStore {
    private readonly tenantAuthorizationService: TenantAuthorizationService =
        new TenantAuthorizationService();

    @observable userId?: any;
    @observable usersTenantAuthorizations?: any[];

    constructor() {
        super("UserTenantAuthorizationStore");
        makeObservable(this);
        reaction(
            (r) => this.userId,
            (userId) => {
                if (!userId) return;
                this.loadTenantAuthorizations(userId);
            },
            { fireImmediately: true },
        );
    }

    private loadTenantAuthorizations = async (userId: string) => {
        this.setupAsyncTask("Get Tenant Authorizations", async () => {
            this.usersTenantAuthorizations =
                await this.tenantAuthorizationService.getTenantAuthorizations(
                    userId,
                );
        });
    };

    @action
    onTenantAuthorizationCreate = (orgId, userId) => {
        this.setupAsyncTask("Create Tenant Authorization", async () => {
            await this.tenantAuthorizationService.createTenantAuthorization(
                orgId,
                userId,
            );
            await this.loadTenantAuthorizations(this.userId);
        });
    };

    @action
    onTenantAuthorizationDelete = (id) => {
        this.setupAsyncTask("Delete Tenant Authorization", async () => {
            await this.tenantAuthorizationService.deleteTenantAuthorization(id);
            await this.loadTenantAuthorizations(this.userId);
        });
    };
}
