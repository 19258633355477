import ManagerInteractionsStore from "components/ManagerInteractions/Stores/ManagerInteractionsStore";
import ConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import React from "react";
import { OrganizationService } from "services/OrganizationService";
import useDialog from "shared/hooks/useDialog";
import { useStore } from "utils/useStore";

const orgService = new OrganizationService();

interface IAssignAnalystDialog {
    open: boolean;
    orgId: string | undefined;
    analystId: string | undefined;
    onSave: (analystId) => void;
    onClose?: () => void;
    isLoading?: boolean;
}

const AssignAnalystDialog = observer((props: IAssignAnalystDialog) => {
    const store = useStore(ManagerInteractionsStore);
    const { isLoading, setIsLoading, isOpen, setIsOpen } = useDialog();

    const [analyst, setAnalyst] = React.useState<ApplicationUser[]>([]);
    const [newAnalyst, setNewAnalyst] = React.useState<ApplicationUser>();
    const [title, setTitle] = React.useState<string>();

    const update = (val: ApplicationUser) => {
        setNewAnalyst(val);
    };

    const onClose = () => {
        setIsOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    };

    const save = () => {
        props.onSave(newAnalyst?.id);
        onClose();
    };

    React.useEffect(() => {
        if (!props.orgId || !props.analystId) {
            setIsOpen(false);
        }
    }, [props.analystId, props.orgId, setIsOpen]);

    React.useEffect(() => {
        setIsOpen(props.open);
    }, [props.open, setIsOpen]);

    React.useEffect(() => {
        const fn = async () => {
            const g = await orgService.getTeamAnalysts(
                props.orgId ?? "",
                props.analystId ?? "",
            );

            setAnalyst(
                g.sort((a, b) => {
                    const x = `${a.firstName}${a.lastName}`;
                    const y = `${b.firstName}${b.lastName}`;
                    return x > y ? 1 : 0;
                }),
            );
        };

        if (isOpen) {
            setIsLoading(true);
            fn();
            setIsLoading(false);
        }
    }, [isOpen, props, props.analystId, props.orgId, setIsLoading]);

    React.useEffect(() => {
        if (store.interactionDgStore.selectedRows.length > 1) {
            setTitle(
                `Reassign ${store.interactionDgStore.selectedRows.length} Evaluations to: `,
            );
        } else if (store.interactionDgStore.selectedRows.length === 1) {
            if (
                store.interactionDgStore.selectedRows[0]
                    ?.assignedAnalysts !== null
            ) {
                setTitle(
                    `Reassign analyst from ${store.interactionDgStore.selectedRows[0]?.assignedAnalysts} to: `,
                );
            } else {
                setTitle("Select an analyst to assign: ");
            }
        } else {
            setTitle("Select an analyst to assign: ");
        }
    }, [
        store.interactionDgStore.selectedRows,
        store.interactionDgStore.selectedRows.length,
    ]);

    return (
        <ConfirmationDialog
            onClose={onClose}
            isOpen={isOpen}
            content={
                <AcxSelect
                    isMulti={false}
                    id={"reassign-analyst-select"}
                    options={analyst}
                    onChange={update}
                    labelField={"userName"}
                    valueField={"id"}
                    isLoading={isLoading}
                />
            }
            onConfirm={save}
            onCancel={onClose}
            title={title}
            confirmButtonDisabled={Boolean(!newAnalyst)}
            isLoading={props.isLoading}
            alternateSpinnerType={"BeatLoader"}
            spinnerSize={12}
        />
    );
});

export default AssignAnalystDialog;
