import { action, observable, makeObservable } from "mobx";
import Organization from "models/Organization";

type OrgChangeCallback = (newOrg?: Organization) => void;

export class OrgSelectorComponentStore {
    @observable orgId?: string;
    @observable isLoading: boolean;
    @observable showOrgSelect: boolean = true;
    @observable orgError?: string;
    @observable.ref organization?: Organization;

    // get callback before changing the observable org to a different org so you can
    // do any org-change cleanup before org-based reactions trigger
    constructor(private orgChangeCallback?: OrgChangeCallback) {
        makeObservable(this);
    }

    @action
    selectOrg = (newOrg?: Organization) => {
        if (this.orgId !== newOrg?.id) {
            this.orgChangeCallback?.(newOrg);
            this.orgId = newOrg?.id;
            this.organization = newOrg;
        }
    };

    @action
    setOrgError = (error?: string) => {
        this.orgError = error;
    };

    @action
    setShowOrgSelect = (newValue: boolean) => {
        this.showOrgSelect = newValue;
    };

    @action
    setIsLoading = (newValue: boolean) => {
        this.isLoading = newValue;
    };
}
