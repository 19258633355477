import React from "react";
import moment from "moment";
import { Grid, Typography, IconButton } from "@mui/material";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import useStyles from "Styles/Styles";
// import hexToRGB from "utils/hexToRGB";

const styles = (theme: Theme) => {
    return createStyles({
        headContainer: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        iconBtn: {
            color: "black",
        },
    });
};
interface CalHeadProps {
    month: number;
    year: number;
    prevMonth: () => void;
    nextMonth: () => void;
    onMonthClick: () => void;
}
export default function CalendarHeadDouble(props: CalHeadProps) {
    const classes = useStyles(styles);
    const mo = moment().year(props.year).month(props.month);
    return (
        <div style={{ width: "100%" }}>
            <Grid container className={classes.headContainer}>
                <Grid item xs={1}>
                    <IconButton
                        size="small"
                        onClick={props.prevMonth}
                        className={classes.iconBtn}
                    >
                        <ChevronLeftSharpIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
                <Grid
                    container
                    item
                    xs={10}
                    justifyContent="center"
                    style={{ width: "100%" }}
                >
                    <Typography
                        style={{ cursor: "pointer" }}
                        onClick={props.onMonthClick}
                        variant="h2"
                        display="inline"
                    >
                        {mo.format("MMMM")} {mo.format("YYYY")}
                    </Typography>
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        size="small"
                        className={classes.iconBtn}
                        onClick={props.nextMonth}
                    >
                        <ChevronRightSharpIcon fontSize="inherit" />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    );
}
