import _ from "lodash";
import {
    action,
    computed,
    IObservableArray,
    makeObservable,
    observable,
    reaction,
} from "mobx";
import { uuidv4 } from "utils/helpers";
import { BaseStore } from "../../../stores/BaseStore";
import { isNullableType } from "../../../utils/TypeGuards";
import { IAcxTableColumn, IAcxTableRow } from "./AcxTable";
import type { OrderDirectionType } from "./AcxTableHeader";
import moment from "moment";

class AcxTableViewModel extends BaseStore {
    //row array used to render table
    @observable.shallow tableRows: IAcxTableRow[] = [];

    //stores all rows returned from server
    @observable.shallow _rows: IAcxTableRow[] = [];
    get rows() {
        return this._rows;
    }
    set rows(value) {
        if (!_.isEqual(value, this.rows)) {
            this._rows = [...value];
        }
    }

    @computed
    get renderRows() {
        return this.tableRows;
        // return this.tableRows.slice(this._virtualBeginRow, this.virtualRowCount);
    }

    id: string;

    @observable tableCols: IAcxTableColumn[] = [];

    @observable orderDir: OrderDirectionType = "desc";
    @observable orderByField: keyof IAcxTableRow = "";
    @observable selectAllRows: boolean = false;

    //paging vars
    @observable count: number = 0;
    @observable private _page: number = 0;
    @computed
    get page() {
        return this._page;
    }
    set page(value) {
        this._page = value;
    }
    @observable rowsPerPage: number = 10;
    @observable showPaging: boolean = false;

    @observable virtualize: boolean = false;

    private _virtualChunk: number = 50;
    @observable _virtualBeginRow: number = 0;
    @observable _virtualEndRow: number = this._virtualChunk;

    selectedItems: IObservableArray<string> = observable([]);
    @observable disabledItems: string[] = [];

    @observable beginBatchSelect: string | undefined;
    @observable endBatchSelect: string | undefined;
    @observable currentBatchAction: "select" | "deselect" | undefined;

    @observable enableSort: boolean = true;
    @observable enableCheck: boolean = false;
    @observable enableHover: boolean = false;
    @observable enableDense: boolean = false;
    @observable enableStripes: boolean = false;
    @observable removeHeightInPx: string = "0px";
    @observable keyField: string;

    @observable cellClass: string;
    @observable rowClass: string;

    @observable isLoading: boolean = false;
    @observable skeletonRows: number = 0;
    public onSelectedItem?: (items: any) => void;
    public onPageChange?: (arg: number) => void;
    public onRowsPerPageChange?: (arg: number) => void;

    constructor() {
        super("AcxTableViewModel");
        makeObservable(this);
        this.id = uuidv4();

        reaction(
            () => ({ pg: this.page }),
            () => {
                this.onPageChange?.(this.page);
            },
        );
        reaction(
            () => ({ pg: this.rowsPerPage }),
            () => {
                this.onRowsPerPageChange?.(this.rowsPerPage);
            },
        );

        reaction(
            () => [...this.selectedItems],
            this.debounceEffect(() => {
                this.onSelectItemsChange();
                this.onSelectedItem?.(this.selectedItems);
            }, 120),
            { delay: 0 },
        );
        reaction(
            () => ({ r: this._rows }),
            () => {
                this.onRowsChange();
                this.onSelectItemsChange();
            },
        );
        reaction(
            (r) => this.tableRows,
            (arg) => {
                if (arg && arg.length < 200) {
                    this._virtualBeginRow = 0;
                    this._virtualEndRow = this.tableRows.length;
                } else {
                    this._virtualBeginRow = 0;
                    this._virtualEndRow = this._virtualChunk;
                }
            },
        );
    }

    shouldRenderWaypoint = (indx: number) => {
        if (this.renderRows && this.renderRows.length < 200) {
            return false;
        }
        if (indx === this._virtualEndRow - 5) {
            return true;
        }
        if (indx === this._virtualBeginRow + 5) {
            return true;
        }
        return false;
    };
    @action
    onWaypointEnter = (props) => {
        if (
            props.previousPosition === "below" &&
            props.currentPosition === "inside"
        ) {
            if (this._virtualEndRow > this._virtualChunk) {
                this._virtualBeginRow += this._virtualChunk;
            }
            this._virtualEndRow += 100;
        } else if (
            props.previousPosition === "above" &&
            props.currentPosition === "inside"
        ) {
            console.log("waypoint from above");
            if (this._virtualBeginRow !== 0) {
                this._virtualBeginRow -= this._virtualChunk;
                this._virtualEndRow -= this._virtualChunk;
            }
        }
    };

    @action
    onRowsChange = () => {
        if (this.orderByField !== "") {
            this.tableSort(this.orderByField, false);
        } else {
            this.tableRows = this.rows.slice();
        }
    };

    @action
    onChangePage = (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => {
        this.page = newPage;
    };

    @action
    onSelectAllClick(isSelected: boolean) {
        if (isSelected) {
            const selRows = this.tableRows.map((row) => {
                return row[this.keyField];
            });

            const newSelRows = selRows.filter(
                (value) =>
                    !this.selectedItems.includes(value) &&
                    !this.disabledItems.includes(value),
            );

            const existingSelect = [...this.selectedItems];

            existingSelect.push(...newSelRows);

            this.selectedItems.replace(existingSelect);
        } else {
            const selRows = this.tableRows.map((row) => {
                return row[this.keyField];
            });
            const newSelectedItems = this.selectedItems.filter(
                (value) => !selRows.includes(value),
            );
            this.selectedItems.replace(newSelectedItems);
        }
    }

    @action
    onRowShiftClick = (rowKey) => {
        if (!this.beginBatchSelect) {
            const exists = this.selectedItems.findIndex((x) => x === rowKey);
            this.beginBatchSelect = rowKey;
            if (exists < 0) {
                this.currentBatchAction = "select";
                this.selectedItems.push(rowKey);
            } else {
                this.currentBatchAction = "deselect";
                this.selectedItems.remove(rowKey);
            }
        } else {
            this.endBatchSelect = rowKey;
            const begIndex = this.tableRows.findIndex(
                (x) => x[this.keyField] === this.beginBatchSelect,
            );
            const endIndex = this.tableRows.findIndex(
                (x) => x[this.keyField] === this.endBatchSelect,
            );
            let idx = endIndex > begIndex ? begIndex : endIndex;
            let chk = endIndex > begIndex ? endIndex : begIndex;
            for (let i = idx; i <= chk; i++) {
                const element = this.tableRows[i];
                this.selectedItems.remove(element[this.keyField]);
                if (this.currentBatchAction === "select") {
                    this.selectedItems.push(element[this.keyField]);
                }
            }

            this.currentBatchAction = undefined;
            this.endBatchSelect = undefined;
            this.beginBatchSelect = undefined;
        }
    };

    @action
    onSelectItemsChange = () => {
        const selectedItemsSet = new Set(this.selectedItems);
        const tableRowsSet = new Set(
            this.tableRows.map((value) => value[this.keyField]),
        );
        const intersection = new Set(
            [...selectedItemsSet].filter((x) => tableRowsSet.has(x)),
        );

        const activeTableRows = this.tableRows.filter(
            (row) => !this.disabledItems.includes(row[this.keyField]),
        );

        if (
            intersection.size === activeTableRows.length &&
            activeTableRows.length !== 0
        ) {
            this.selectAllRows = true;
        } else {
            this.selectAllRows = false;
        }
    };
    @action
    selectionChange = (selected: boolean, key: string) => {
        // debugger;
        const i = this.selectedItems.findIndex((f) => f === key);
        if (selected === true) {
            if (i === -1) {
                this.selectedItems.push(key);
                // const ar = this.selectedItems.slice();
                // ar.splice(i, 1, key);
                // this.selectedItems = ar;
            } else {
                // const ar = this.selectedItems.slice();
                // ar.push(key);
                // this.selectedItems = ar;
            }
        } else {
            this.selectedItems.remove(key);
            // const ar = this.selectedItems.slice();
            // ar.splice(i, 1);
            // this.selectedItems = ar;
        }
    };

    @computed
    get colCells() {
        const cells = this.tableCols?.map((c) => {
            return {
                label: c.headerLabel,
                id: c.dataKey,
                disablePadding: false,
                numeric: false,
            };
        });

        return cells;
    }

    @action
    tableSort = (
        orderByField: keyof IAcxTableRow,
        changeOrderDir: boolean = true,
    ) => {
        const isAsc =
            this.orderByField === orderByField && this.orderDir === "asc";
        if (changeOrderDir) {
            this.orderDir = isAsc ? "desc" : "asc";
        }
        this.orderByField = orderByField;

        const valueGetter =
            this.tableCols.find((col) => col.dataKey === orderByField)
                ?.valueGetter ?? ((value) => value);

        this.tableRows = this.rows.slice().sort((a, b) => {
            let aValue = valueGetter(_.get(a, this.orderByField));
            let bValue = valueGetter(_.get(b, this.orderByField));
            if (orderByField.toString().toLowerCase().includes("date")) {
                aValue = moment(aValue);
                bValue = moment(bValue);
            }

            return this.comparator(aValue, bValue);
        });
    };
    private comparator(a, b) {
        let result = 0;

        // Will always sort undefineds/nulls to the bottom
        if (isNullableType(a) && !isNullableType(b)) return 1;
        else if (!isNullableType(a) && isNullableType(b)) return -1;

        if (b < a || isNullableType(a)) result = 1;
        if (b > a || isNullableType(b)) result = -1;

        if (this.orderDir === "desc") result *= -1;

        return result;
    }
}

export default AcxTableViewModel;
