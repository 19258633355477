import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import { VizType } from "models/Reporting/ReportModel";
import React, { FunctionComponent, useEffect, useState } from "react";
import {
    ReportDataField,
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "../../../../../../models/Reporting/ReportDataField";
import {
    AggregateFunction,
    DateAggregation,
    FieldUse,
    ReportField,
} from "../../../../../../models/Reporting/ReportField";
import { useStore } from "../../../../../../utils/useStore";
import AcxBooleanFormControlGroup, {
    AcxBooleanFormFieldControllers,
} from "../../../../../UI/AcxBooleanFormControlGroup";
import AcxMainTextField from "../../../../../UI/AcxMainTextFieldGrid";
import AcxTransferList from "../../../../../UI/AcxTransferList-RV2";
import AcxSelectSingle from "../../../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import {
    aggregateFuncSelector,
    aggregateFuncSelectorPercentage,
    aggregateFunctionsForReportField,
    aggregateFunctionsForReportFieldPercentage,
    dateAggregationSelector,
    dateGroupings,
} from "./VizDataSection";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    titleText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
}));

interface OrderedReportDataField extends ReportDataField {
    order: number;
}

interface OwnProps {
    selectedFields: OrderedReportDataField[];
    selectedFieldsTitle: string;
    availableFields?: OrderedReportDataField[];
    availableFieldsTitle: string;

    isActionRequired?: (field: OrderedReportDataField) => boolean;

    idField: string;
    labelField?: string;
    labelFieldSelector?: (field: OrderedReportDataField) => string;

    onSelectedFields?: (fields: OrderedReportDataField[]) => void;

    height: string;

    reportFieldPredicate: (field: ReportField) => boolean;

    hideAggregateSelectors?: boolean;
    selectedFieldEndAdornment?: React.ReactNode;
}

type Props = OwnProps;

const ReportFieldTransferList: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);
    //const reportStore = useStore(ReportsStore);
    const [selectedColumn, setSelectedColumn] = useState<
        ReportDataField | undefined
    >();
    const [configColumn, setConfigColumn] = useState<ReportField | undefined>();

    const activeReportFields = store.currentReport?.activeReportFields;

    useEffect(() => {
        if (selectedColumn) {
            // eslint-disable-next-line array-callback-return
            const newConfigColumn = activeReportFields?.find((value) => {
                if (value.reportDataField !== undefined) {
                    return (
                        value.reportDataField!.displayName ===
                            selectedColumn?.displayName &&
                        props.reportFieldPredicate(value)
                    );
                }
                return false;
            });

            setConfigColumn(newConfigColumn);
        } else {
            setConfigColumn(undefined);
        }
        /*reportStore.reportDataViewModel.loadPreviewReportData(
            reportStore.orgSelectorStore.orgId,
            store.prepareReportForPreview,
            true,
        );
        store.dgStorePreviewReport.reset();
        store.dgStorePreviewReport.setColumns(
            reportStore.reportDataViewModel.tableReportData.columns,
        );
        store.dgStorePreviewReport.rows =
            reportStore.reportDataViewModel.tableReportData.rows;
        store.dgStorePreviewReport.isLoading = false;*/

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, selectedColumn, activeReportFields]);

    useEffect(() => {
        if (configColumn && configColumn.aggregateFunction == null) {
            configColumn.setDistinct(false);
        }
    }, [configColumn, configColumn?.aggregateFunction]);

    function handleDisplayNameChange(evt: React.ChangeEvent<HTMLInputElement>) {
        configColumn?.setDisplayName(evt.currentTarget.value);
    }

    function handleTableColumnDateAggregationChange(dateAgg?: {
        id: DateAggregation;
        value: string;
    }) {
        configColumn?.setDateAggregation(dateAgg?.id);
    }

    function handleTableColumnAggregationChange(agg?: {
        id: AggregateFunction;
        value: string;
    }) {
        configColumn?.setAggregateFunction(agg?.id);
    }

    return (
        <>
            <Grid item xs={8}>
                {props.availableFields && (
                    <AcxTransferList
                        availableItems={props.availableFields}
                        onSelectedItemClick={setSelectedColumn}
                        isItemActionRequired={props.isActionRequired}
                        selectedItems={props.selectedFields}
                        idField={props.idField}
                        onSelect={props.onSelectedFields}
                        height={props.height}
                        labelFieldSelector={props.labelFieldSelector}
                        availableListTitle={props.availableFieldsTitle}
                        selectedListTitle={props.selectedFieldsTitle}
                        //reorderSelected
                    />
                )}
            </Grid>
            <>
                <Grid
                    item
                    xs={4}
                    container
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                >
                    <Grid item xs={12}>
                        {selectedColumn && (
                            <AcxMainTextField
                                id={
                                    "report-field-transfer-list-editor-column-config-displayname"
                                }
                                value={configColumn?.displayName}
                                rows={1}
                                multiLine
                                rowsMax={4}
                                disabled={!Boolean(store.currentReportDataView)}
                                labelText={"Display Name"}
                                error={
                                    (configColumn?.displayName.length ?? 0) > 63
                                }
                                onChange={(evt) => {
                                    handleDisplayNameChange(evt);
                                }}
                                inputProps={{ maxLength: 63 }}
                                endAdornment={props.selectedFieldEndAdornment}
                            />
                        )}

                        {!props.hideAggregateSelectors && (
                            <div className={classes.vertSpace}>
                                {selectedColumn?.datatype ===
                                ReportFieldDataTypes.Date ? (
                                    <AcxSelectSingle
                                        options={dateGroupings}
                                        id={
                                            "report-field-transfer-list-editor-date-groupby"
                                        }
                                        isDisabled={
                                            !Boolean(
                                                store.currentReportDataView,
                                            )
                                        }
                                        defaultValue={dateAggregationSelector(
                                            configColumn?.dateAggregation,
                                        )}
                                        valueField={"id"}
                                        labelField={"value"}
                                        fullWidth
                                        onChange={
                                            handleTableColumnDateAggregationChange
                                        }
                                        inputLabel={"Grouped By"}
                                    />
                                ) : (
                                    <>
                                        {selectedColumn &&
                                            configColumn?.fieldUse !==
                                                FieldUse.VirtualValue && (
                                                <div>
                                                    {(store.currentReport
                                                        ?.vizType ===
                                                        VizType.Pie ||
                                                        store.currentReport
                                                            ?.vizType ===
                                                            VizType.Table) && (
                                                        <AcxSelectSingle
                                                            options={aggregateFunctionsForReportFieldPercentage(
                                                                configColumn,
                                                            )}
                                                            isDisabled={
                                                                !Boolean(
                                                                    store.currentReportDataView,
                                                                )
                                                            }
                                                            id={
                                                                "report-field-transfer-list-editor-column-aggregator"
                                                            }
                                                            enableNoSelection
                                                            defaultValue={aggregateFuncSelectorPercentage(
                                                                configColumn?.aggregateFunction,
                                                            )}
                                                            valueField={"id"}
                                                            labelField={"value"}
                                                            fullWidth
                                                            onChange={
                                                                handleTableColumnAggregationChange
                                                            }
                                                            inputLabel={
                                                                "Aggregated By"
                                                            }
                                                        />
                                                    )}
                                                    {store.currentReport
                                                        ?.vizType !==
                                                        VizType.Pie &&
                                                        store.currentReport
                                                            ?.vizType !==
                                                            VizType.Table && (
                                                            <AcxSelectSingle
                                                                options={aggregateFunctionsForReportField(
                                                                    configColumn,
                                                                )}
                                                                isDisabled={
                                                                    !Boolean(
                                                                        store.currentReportDataView,
                                                                    )
                                                                }
                                                                id={
                                                                    "report-field-transfer-list-editor-column-aggregator"
                                                                }
                                                                enableNoSelection
                                                                defaultValue={aggregateFuncSelector(
                                                                    configColumn?.aggregateFunction,
                                                                )}
                                                                valueField={
                                                                    "id"
                                                                }
                                                                labelField={
                                                                    "value"
                                                                }
                                                                fullWidth
                                                                onChange={
                                                                    handleTableColumnAggregationChange
                                                                }
                                                                inputLabel={
                                                                    "Aggregated By"
                                                                }
                                                            />
                                                        )}

                                                    {configColumn?.aggregateFunction &&
                                                        selectedColumn.variation !==
                                                            ReportDataFieldVariation.Virtual && (
                                                            <AcxBooleanFormControlGroup
                                                                formControlModel={
                                                                    configColumn!
                                                                }
                                                                control={
                                                                    "checkbox"
                                                                }
                                                                modelFieldControls={[
                                                                    {
                                                                        fieldName:
                                                                            "distinct",
                                                                        fieldSetter:
                                                                            "setDistinct",
                                                                        displayName:
                                                                            "Distinct",
                                                                        infoText:
                                                                            "Perform aggregation over distinct values",
                                                                    } as AcxBooleanFormFieldControllers<ReportField>,
                                                                ]}
                                                            />
                                                        )}
                                                </div>
                                            )}
                                    </>
                                )}
                            </div>
                        )}
                    </Grid>
                </Grid>
            </>
        </>
    );
});

export default ReportFieldTransferList;
