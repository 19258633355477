import React from "react";
import { Route, Routes } from "react-router";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import OrganizationMotfCreate from "./OrganizationMotfCreate";
import OrganizationMotfDefinitions from "./OrganizationMotfDefinitions";
import OrganizationMotfEdit from "./OrganizationMotfEdit";

export default function OrganizationMotfWrapper({ id }: { id: string }) {
    const authStore = useStore(AuthStore);
    const hasEditPerms = authStore.canUserEdit("Sample Automation");
    const hasViewPerms = authStore.canUserView("Sample Automation");

    return (
        <Routes>
            {hasViewPerms && (
                <Route
                    path="definitions"
                    element={<OrganizationMotfDefinitions id={id} />}
                />
            )}
            {hasEditPerms && (
                <>
                    <Route
                        path="create"
                        element={<OrganizationMotfCreate id={id} />}
                    />
                    <Route
                        path="edit"
                        element={<OrganizationMotfEdit id={id} />}
                    />
                </>
            )}
        </Routes>
    );
}
