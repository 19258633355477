import { action, computed, observable, makeObservable } from "mobx";
import Agentv2 from "../Agentv2";
import BaseEntity from "../BaseEntity";
// import Evaluation from "../Evaluation";
import { ApplicationUser } from "../Permission/ApplicationUser";

export enum AgentCoachingStatus {
    InProgress,
    Completed,
}

export class AgentCoaching extends BaseEntity {
    agentId: string;
    agentCoachingId: string;
    agent?: Agentv2;
    evaluationId?: string[];
    coachId: string;
    coach?: ApplicationUser;
    @observable improvementNotes?: string;
    @observable agentDoWellNotes?: string;
    @observable focusAreas?: string;
    @observable responseNotes?: string;
    @observable acknowledged?: boolean;
    @observable evalAcknowledged?: boolean;
    @observable coachingStatus: AgentCoachingStatus;
    /**
     * A json value containing an array of strings
     */
    @observable targetedEvaluationIds?: string;

    @action
    setImprovementNotes = (notes: string) => {
        this.improvementNotes = notes;
    };

    @action
    setDoWellNotes = (notes: string) => {
        this.agentDoWellNotes = notes;
    };

    @action
    setResponseNotes = (notes: string) => {
        this.responseNotes = notes;
    };

    @action
    setAcknowledged = (arg: boolean) => {
        this.acknowledged = arg;
    };

    @action
    setFocusAreas = (focusAreaQuestions?: { value: string }[]) => {
        if (focusAreaQuestions) {
            this.focusAreas = JSON.stringify(
                focusAreaQuestions.map((value) => value.value),
            );
        } else {
            this.focusAreas = undefined;
        }
    };

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    @computed
    get focusAreaQuestions() {
        const focusAreas = this.focusAreas;

        return focusAreas
            ? (JSON.parse(focusAreas) as string[]).map((value) => ({
                  value: value,
              }))
            : [];
    }
}
