import DeleteIcon from "@mui/icons-material/Delete";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import RemoveFromQueueIcon from "@mui/icons-material/RemoveFromQueue";
import UndoIcon from "@mui/icons-material/Undo";
import { Grid, Theme, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { Observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import ControlSectionDivider from "../../../Layouts/SideControls/ControlSectionDivider";
import SideControlsPage from "../../../Layouts/SideControls/SideControlsPage";
import useStyles from "../../../Styles/Styles";
import hexToRGB from "../../../utils/hexToRGB";
import ConfirmationDialog from "../../UI/AcxConfirmationDialog";
import AcxMainTextField from "../../UI/AcxMainTextField";
import MontageClipRedactionDrawer from "../MontageClipRedactionDrawer";
import {
    MontageSoundClipStore,
    SaveDownloadMontageOp,
} from "../Stores/MontageSoundClipStore";

const styles = (_theme: Theme) =>
    createStyles({
        dialogTextInput: {
            marginBottom: _theme.spacing(3),
        },
        iconLookFeel: {
            color: hexToRGB(_theme.palette.primary.main, 0.5),
        },
    });
interface OwnProps {
    store: MontageSoundClipStore;
    children?: React.ReactNode;
}

type Props = OwnProps;

const MontageClipEditorPage: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = (props) => {
    const classes = useStyles(styles);
    const theme = useTheme();

    return (
        <Observer>
            {() => (
                <>
                    {props.store.saveDialogOpen && (
                        <ConfirmationDialog
                            isOpen={props.store.saveDialogOpen}
                            onClose={props.store.closeSaveDialog}
                            buttonsDisabled={props.store.getTaskLoading(
                                SaveDownloadMontageOp,
                            )}
                            title={"Save Montage"}
                            isLoading={props.store.anyTaskLoading}
                            errorMessage={
                                props.store.getTaskError(SaveDownloadMontageOp)
                                    ?.message
                            }
                            content={
                                <>
                                    <AcxMainTextField
                                        containerClass={classes.dialogTextInput}
                                        id={"montage-save-dialog-name-field"}
                                        labelText={"Montage Name"}
                                        value={props.store.montage.name}
                                        onChange={props.store.setMontageName}
                                        autofocus
                                    />

                                    <AcxMainTextField
                                        id={
                                            "montage-save-dialog-directory-field"
                                        }
                                        labelText={"Montage Directory"}
                                        value={props.store.montageDirectory}
                                        onChange={
                                            props.store.setMontageDirectoryName
                                        }
                                    />
                                </>
                            }
                            onConfirm={props.store.saveMontage}
                            confirmButtonText={"Save"}
                        />
                    )}

                    <SideControlsPage
                        mainGridSpacing={0}
                        drawerContent={
                            <>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    marginTop={theme.spacing(1)}
                                    marginBottom={theme.spacing(2)}
                                >
                                    <Grid
                                        item
                                        xs
                                        width="100%"
                                        paddingLeft="24px"
                                        paddingRight="24px"
                                    >
                                        <Typography
                                            sx={{
                                                lineHeight: "24px",
                                                letterSpacing: 0,
                                                fontSize: "16px",
                                                fontWeight: "bold",
                                                marginBottom:
                                                    theme.spacing(1.5),
                                            }}
                                        >
                                            Build Your Montage
                                        </Typography>

                                        <Typography
                                            variant={"body2"}
                                            marginBottom={theme.spacing(1.5)}
                                        >
                                            Build your montage by editing and
                                            combining individual audio clips
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        width="100%"
                                        paddingLeft="24px"
                                        paddingRight="24px"
                                        marginBottom={theme.spacing(2.25)}
                                        container
                                        wrap={"nowrap"}
                                    >
                                        <Grid
                                            item
                                            marginRight={theme.spacing(1.5)}
                                        >
                                            <RemoveFromQueueIcon
                                                className={classes.iconLookFeel}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Typography variant={"body2"}>
                                                Edit your clips by dragging your
                                                cursor on the playhead area to
                                                remove a segment
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        width="100%"
                                        paddingLeft="24px"
                                        paddingRight="24px"
                                        marginBottom={theme.spacing(2.25)}
                                        container
                                        wrap={"nowrap"}
                                    >
                                        <Grid
                                            item
                                            marginRight={theme.spacing(1.5)}
                                        >
                                            <DragHandleIcon
                                                className={classes.iconLookFeel}
                                            />
                                        </Grid>

                                        <Grid item zeroMinWidth>
                                            <Typography variant={"body2"}>
                                                Reorder clips by grabbing the
                                                drag handle and moving the clip
                                                up or down the sound clip list
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        width="100%"
                                        paddingLeft="24px"
                                        paddingRight="24px"
                                        marginBottom={theme.spacing(2.25)}
                                        container
                                        wrap={"nowrap"}
                                    >
                                        <Grid
                                            item
                                            marginRight={theme.spacing(1.5)}
                                        >
                                            <UndoIcon
                                                className={classes.iconLookFeel}
                                            />
                                        </Grid>

                                        <Grid item zeroMinWidth>
                                            <Typography variant={"body2"}>
                                                Undo recent changes on
                                                individual clips by clicking the
                                                undo icon
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        width="100%"
                                        paddingLeft="24px"
                                        paddingRight="24px"
                                        marginBottom={theme.spacing(2.25)}
                                        container
                                        wrap={"nowrap"}
                                    >
                                        <Grid
                                            item
                                            marginRight={theme.spacing(1.5)}
                                        >
                                            <DeleteIcon
                                                className={classes.iconLookFeel}
                                            />
                                        </Grid>

                                        <Grid item zeroMinWidth>
                                            <Typography variant={"body2"}>
                                                Remove a clip from the montage
                                                by clicking the trash can icon
                                                next to the clip
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        width="100%"
                                        paddingLeft="24px"
                                        paddingRight="24px"
                                        container
                                        wrap={"nowrap"}
                                    >
                                        <Grid
                                            item
                                            marginRight={theme.spacing(1.5)}
                                        >
                                            <MusicNoteIcon
                                                className={classes.iconLookFeel}
                                            />
                                        </Grid>

                                        <Grid item zeroMinWidth>
                                            <Typography variant={"body2"}>
                                                Add tone or silent breaks in
                                                between each clip to identify
                                                gaps in the montage
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <ControlSectionDivider />

                                <Typography
                                    width="100%"
                                    paddingLeft="24px"
                                    paddingRight="24px"
                                    marginTop={theme.spacing(1.5)}
                                    color={"textSecondary"}
                                    variant={"body2"}
                                >
                                    When you are satisfied with your clips,
                                    click on the Merge Clips to combine them
                                    into one single audio file. You can then
                                    reset to make more tweaks or save your final
                                    masterpiece.
                                </Typography>
                            </>
                        }
                        drawerVerticalOffsetPixels={0}
                        containerOffsetPixels={0}
                        mainContent={props.children}
                        drawerLocation={"left"}
                    />

                    <MontageClipRedactionDrawer montageStore={props.store} />
                </>
            )}
        </Observer>
    );
};

export default MontageClipEditorPage;
