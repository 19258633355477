import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ModuleUIModel } from "components/Evaluation/Models/ModuleUIModel";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";
import AcxStatusSticker from "components/UI/AcxStatusSticker";
import { observer } from "mobx-react";
import Question from "models/Question";
import React from "react";
import HelpSvg from "SvgIcons/HelpSvg";
import { useStore } from "utils/useStore";

const useStyles = makeStyles((theme: Theme) => ({
    sPContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    aiExpToggle: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
}));

interface OwnProps {
    question: Question;
    moduleUIModel?: ModuleUIModel;
}

type Props = OwnProps;

const SmartPredictToggle = observer((props: Props) => {
    const classes = useStyles(props);
    const store = useStore(EvalStore);

    const answer = store.currentEval?.getAnswerForQuestion(
        props.question,
        props.moduleUIModel?.licensedModule.evaluationModuleId,
    );

    const isSmartPredicted = answer?.hasAccuratePredictions;

    const hasMlModel: boolean = !!answer?.predictedTags.find((item) => {
        // There's some risk to showing the other models and getting it wrong.
        // We should restrict all the other ML models but leave Eddy.
        return (
            item.predictedBy.startsWith("ML") &&
            !["ML-EddyEffect", "ML-EddyEffectP2"].includes(item.predictedBy)
        );
    });

    return (
        <>
            {isSmartPredicted && (
                <Grid item className={classes.sPContainer}>
                    <AcxStatusSticker
                        title={"Smart Predict"}
                        customRootStyles={{
                            width: "max-content",
                        }}
                    />
                    {store.authStore.canUserView("AI Scoring Explanations") &&
                        !hasMlModel && (
                            <Grid
                                item
                                className={classes.aiExpToggle}
                                onClick={() => {
                                    store.setShowAIExplanation(
                                        props.question.id,
                                        !store.showAIExplanation[
                                            props.question.id
                                        ],
                                    );
                                }}
                                id="ai-explanation-icon"
                            >
                                <HelpSvg />
                            </Grid>
                        )}
                </Grid>
            )}
        </>
    );
});

export default SmartPredictToggle;
