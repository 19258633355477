import { Checkbox, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import React from "react";
import theme from "Theme/AppTheme";
import { Tier } from "../../types/Tier.type";

const useStyles = makeStyles({
    root: {
        height: 110,
        flexGrow: 1,
    },
    content: {
        backgroundColor: "#ffffff",
        padding: "0 0 0 8px",
        border: "1px solid #e4e4e7",
        borderRadius: 4,
        marginBottom: 4,
    },
    group: {
        marginLeft: 30,
    },
    label: {
        backgroundColor: "#ffffff !important",
        paddingLeft: 0,
    },
    labelRoot: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 0),
    },
    iconContainer: {
        marginRight: 0,
    },
    iconContainerEmpty: {
        marginRight: 0,
        width: 0,
    },
    checkbox: {
        padding: 4,
        "&&:hover": {
            backgroundColor: "transparent",
        },
    },
});

type Props = {
    options?: Tier[] | null;
    value: string[];
    onChange: (val: string[]) => void;
};

const HierarchyPosition: React.FC<Props> = ({ options, value, onChange }) => {
    const classes = useStyles();

    const renderHierarchy = (hierarchyOptions?: Tier[] | null) => (
        <>
            {hierarchyOptions?.map((opt) => (
                <TreeItem
                    key={opt.id}
                    nodeId={opt.id || ""}
                    classes={{
                        content: classes.content,
                        label: classes.label,
                        iconContainer:
                            classes[
                                opt.tiers?.length
                                    ? "iconContainer"
                                    : "iconContainerEmpty"
                            ],
                        group: classes.group,
                    }}
                    label={
                        <div className={classes.labelRoot}>
                            <Checkbox
                                inputProps={{
                                    "aria-label": "uncontrolled-checkbox",
                                }}
                                disableRipple
                                disableFocusRipple
                                className={classes.checkbox}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const checked =
                                        !!opt.id && value.includes(opt.id);
                                    const newValues = checked
                                        ? value.filter(
                                              (valOpt) => valOpt !== opt?.id,
                                          )
                                        : opt?.id
                                        ? [...value, opt.id]
                                        : value;
                                    onChange(newValues);
                                }}
                                checked={!!opt.id && value.includes(opt.id)}
                            />
                            <Typography>{opt.name}</Typography>
                        </div>
                    }
                >
                    {opt.tiers?.length ? renderHierarchy(opt.tiers) : null}
                </TreeItem>
            ))}
        </>
    );

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={["root"]}
            defaultExpandIcon={<ChevronRightIcon />}
        >
            {renderHierarchy(options)}
        </TreeView>
    );
};

export default HierarchyPosition;
