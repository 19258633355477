import { makeObservable, observable } from "mobx";
import moment from "moment";

export default abstract class BaseEntity {
    constructor(
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        makeObservable(this);
        // @ts-ignore
        this.id = id;
        this.isActive = true;
        this.createdOn = createdOn ?? moment().utc().toISOString();
        this.modifiedOn = modifiedOn ?? moment().utc().toISOString();

        this.modifiedBy = modifiedBy;
        this.createdBy = createdBy;
    }

    id: string;
    @observable isActive?: boolean;
    createdBy?: string;
    createdOn?: string;
    modifiedBy?: string;
    modifiedOn?: string;
    deletedOn?: string;
}
