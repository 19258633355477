import { Button, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import TuneIcon from "@mui/icons-material/Tune";
import { observer } from "mobx-react";
import React, { FunctionComponent, useState } from "react";
import { capitalizeFirstChar } from "../../../utils/helpers";
import hexToRGB from "../../../utils/hexToRGB";
import VerticalDrawerContentTemplate from "../../UI/Drawer/VerticalDrawerContentTemplate";
import { SignalsChartType } from "../Models/SignalsModels";
import SignalsReportStore from "../Store/SignalsReportStore";
import { useStore } from "utils/useStore";
import AcxMainTextField from "components/UI/AcxMainTextField";

const useStyles = makeStyles((theme: Theme) => ({
    bottomMargin: {
        marginBottom: theme.spacing(2.45),
    },
    noOptionsText: {
        color: "#1F1F1F",
        opacity: 0.25,
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: 0,
        lineHeight: "20px",
        textAlign: "center",
    },
    noOptionsIcon: {
        fontSize: "160px",
        color: hexToRGB(theme.palette.gray.main, 0.2),
    },
    noOptionsTextContainer: {
        marginTop: theme.spacing(2.5),
    },
    mainContent: {
        marginTop: theme.spacing(0.25),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
    },
    infoText: {
        // paddingRight: theme.spacing(.5),
        // paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(0.55),
        // color: theme.palette.text.primary,
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        width: "100%",
    },
    infoTitle: {
        fontWeight: "bold",
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
    },
    contentPadding: {
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
    },
    colorAddDelButton: { maxHeight: "64px" },
}));

interface OwnProps {
    chartType: SignalsChartType;
    vizOptions: any;
    chartTitle: string;
    chartId: string;
    dashboardId: string;
    subtitle: string;
    order: number;
}

type Props = OwnProps;

const SignalsVizOptions: FunctionComponent<React.PropsWithChildren<Props>> =
    observer((props) => {
        const classes = useStyles();
        const store = useStore(SignalsReportStore);

        const [title, setTitle] = useState(props.chartTitle);
        const [subtitle, setSubtitle] = useState(props.subtitle);
        const [order, setOrder] = useState(props.order);

        const defaultContent: JSX.Element = (
            <React.Fragment key={"empty-viz-controls"}>
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    alignItems={"center"}
                    className={classes.noOptionsTextContainer}
                >
                    <Grid item xs={12} container justifyContent={"center"}>
                        <TuneIcon className={classes.noOptionsIcon} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent={"center"}
                        style={{ marginTop: "9px" }}
                    >
                        <Typography
                            variant={"h2"}
                            className={classes.noOptionsText}
                        >
                            No options available
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

        let controls = [] as any[];

        const titleControl = (
            <Grid item xs={12} key="signals-chart-title-control">
                <AcxMainTextField
                    id="signals-chart-title-control"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                    }}
                    labelText="Title"
                />
            </Grid>
        );

        const subTitleControl = (
            <Grid item xs={12} key="signals-chart-sub-title-control">
                <AcxMainTextField
                    id="signals-chart-sub-title-control"
                    value={subtitle}
                    onChange={(e) => {
                        setSubtitle(e.target.value);
                    }}
                    labelText="Sub-Title"
                    multiline
                    rowsMax={3}
                />
            </Grid>
        );

        const orderControl = (
            <Grid item xs={12} key="signals-chart-order-control">
                <AcxMainTextField
                    id="signals-chart-order-control"
                    value={order}
                    onChange={(e) => {
                        setOrder(Number(e.target.value));
                    }}
                    labelText="Order"
                    type="number"
                />
            </Grid>
        );

        controls = [titleControl, subTitleControl, orderControl];

        let vizControlsContent;
        if (controls.length) {
            vizControlsContent = (
                <div key={"saveable-viz-controls"}>
                    <div>
                        <Grid
                            style={{ marginBottom: "8px" }}
                            container
                            item
                            xs={12}
                            justifyContent={"flex-end"}
                            alignItems={"flex-start"}
                        >
                            <Button
                                variant={"outlined"}
                                style={{
                                    textTransform: "none",
                                    fontWeight: "bold",
                                }}
                                disableElevation
                                size={"small"}
                                color={"primary"}
                                onClick={() =>
                                    store.updateVizDefinition(
                                        props.chartId,
                                        props.dashboardId,
                                        props.vizOptions,
                                        title,
                                        subtitle,
                                        order,
                                    )
                                }
                            >
                                Save
                            </Button>
                        </Grid>
                    </div>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="baseline"
                        spacing={2}
                    >
                        {controls}
                    </Grid>
                </div>
            );
        }

        vizControlsContent = vizControlsContent ?? defaultContent;

        return (
            <VerticalDrawerContentTemplate
                content={vizControlsContent}
                mainContentClass={classes.mainContent}
                title={`${capitalizeFirstChar(props.chartTitle)} Options`}
            />
        );
    });

export default SignalsVizOptions;
