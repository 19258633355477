export enum InteractionType {
    Audio = 0,
    Email = 1,
    Chat = 2,
    Video = 3,
    Live = 4,
    Metadata = 5,
    ProcessedChat = 6,
    ExtendedMetadata = 7,
}
