import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import {action, makeObservable, observable} from "mobx";
import PipelineFault from "models/PipelineFault";
import type ProcessFailure from "models/ProcessFailure";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import * as RootStore from "stores/RootStore";
import { PipelineService } from "../../../../../services/PipelineService";
import {ReprocessFailureLocations} from "./Models/RequeueModels";

@RootStore.AcxStore
export default class RequeueStore extends BaseStore {
    readonly authStore: AuthStore;
    readonly pipelineService: PipelineService;

    public readonly datePickerStore = new DatePickerComponentStore();

    @observable
    public selectedFailure?: ProcessFailure = undefined;
    public failures: ProcessFailure[];
    readonly requeueDgStore: AcxDataGridStore;

    @observable
    public faults?: PipelineFault[] = undefined;
    readonly faultDgStore: AcxDataGridStore;
    readonly drawerStore: LayoutDrawerStore;
    
    @observable
    selectedReprocessLocation: ReprocessFailureLocations;

    constructor(public rootStore: RootStore.IRootStore) {
        super("RequeueDataGridStore");
        makeObservable(this);

        this.requeueDgStore = new AcxDataGridStore("Requeue", "Administrative");
        this.faultDgStore = new AcxDataGridStore(
            "RequeueFaults",
            "Administrative",
        );
        this.pipelineService = new PipelineService();
        this.authStore = rootStore.getStore(AuthStore);
        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);
    }
    
    @action
    setSelectedFailureLocation(location: ReprocessFailureLocations){
        this.selectedReprocessLocation = location
    }

    public async updateFailures() {
        this.requeueDgStore.rows = [];
        this.requeueDgStore.clearSelected();
        this.requeueDgStore.isLoading = true;
        try {
            this.failures = await this.pipelineService.getFailuresByOrg(
                this.authStore.orgStore.selectedOrganization?.id!,
                this.datePickerStore.beginDate,
                this.datePickerStore.endDate,
            );
            this.requeueDgStore.rows = this.failures;
        } catch (error) {
            throw error;
        } finally {
            this.requeueDgStore.isLoading = false;
        }
    }

    public async requeueFailures() {
        this.setTaskLoading("Requeue", true);

        const faultsToRequeue = this.requeueDgStore.selectedRows.map(
            (item) => item as ProcessFailure,
        );

        try {
            await this.pipelineService.requeueFailures(
                this.authStore.orgStore.selectedOrganization?.id!,
                faultsToRequeue,
            );
            this.failures = this.failures.filter(
                (fault) =>
                    !faultsToRequeue
                        .flatMap((requeuedFailure) => requeuedFailure.id)
                        .includes(fault.id),
            );
            this.requeueDgStore.rows = this.failures;
        } catch (error) {
            throw error;
        } finally {
            this.requeueDgStore.clearSelected();
            this.setTaskLoading("Requeue", false);
        }
    }
    
    public async reprocessFaults() {
        this.setTaskLoading("Requeue", true);

        const faultsToRequeue = this.requeueDgStore.selectedRows.map(
            (item) => item as ProcessFailure,
        );
        const location = this.selectedReprocessLocation;
        try {
            await this.pipelineService.reprocessFailures(this.authStore.orgStore.selectedOrganization?.id!, faultsToRequeue, location);
            this.failures = this.failures.filter(
                (fault) =>
                    !faultsToRequeue
                        .flatMap((requeuedFailure) => requeuedFailure.id)
                        .includes(fault.id),
            );
            this.requeueDgStore.rows = this.failures;
        } catch (error) {
            throw error;
        } finally {
            this.requeueDgStore.clearSelected();
            this.setTaskLoading("Requeue", false);
        }
    }

    public async updateFaults(failure: ProcessFailure) {
        if (
            !this.faults ||
            !this.faults.length ||
            this.faults.find((fault) => fault.correlationId !== failure.id)
        ) {
            try {
                this.faults =
                    await this.pipelineService.getFaultsByOrgandFailure(
                        this.authStore.orgStore.selectedOrganization?.id!,
                        failure,
                    );
                this.faultDgStore.rows = this.faults!;
            } catch (error) {
                throw error;
            }
        }
    }
}
