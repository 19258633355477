import { action, computed, makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { CombinatorRuleType } from "models/RuleCombinator";
import { AuthStore } from "stores/AuthStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";

export const initRBCFilterItems: IRBCFilter[] = [
    {
        value: true,
        classifierIds: [],
        combinator: 0,
        filterGroupId: 0,
    },
];
export interface IRBCFilter {
    value: boolean; // true is "contains", false is "does not contain"
    classifierIds: string[];
    combinator: CombinatorRuleType;
    filterGroupId: number;
}
export default class RBCFilterStore extends BaseStore {
    readonly authStore: AuthStore;
    messageStore: MessageStore;

    @observable
    RBCFilterItems: IRBCFilter[] = initRBCFilterItems;

    @computed get classifiersList() {
        return (
            this.rootStore.getStore(ApplicationFilterValuesStore)
                .userClassifiers ?? []
        );
    }

    constructor(public rootStore: IRootStore) {
        super("RBCFilterStore");
        makeObservable(this);
        this.authStore = rootStore.getStore(AuthStore);
        this.messageStore = rootStore.getStore(MessageStore);
    }

    @action
    setRBCFilterItem = (index: number, RBCFilter: IRBCFilter) => {
        this.RBCFilterItems[index] = RBCFilter;
    };

    @action
    setRBCFilterItems = (RBCFilters: IRBCFilter[]) => {
        this.RBCFilterItems = RBCFilters;
    };

    @action
    updateRBCItemContains = (index: number, value: boolean) => {
        this.RBCFilterItems[index].value = value;
    };

    @action
    addRBCFilterItem = (combinator: CombinatorRuleType) => {
        const newItem: IRBCFilter = {
            value: true,
            classifierIds: [],
            combinator: combinator,
            filterGroupId: 0,
        };

        if (this.RBCFilterItems.length) {
            const lastItemFilterGroupId =
                this.RBCFilterItems[this.RBCFilterItems.length - 1]
                    ?.filterGroupId;

            newItem.filterGroupId =
                combinator === CombinatorRuleType.And
                    ? lastItemFilterGroupId
                    : lastItemFilterGroupId + 1;
        }

        this.RBCFilterItems.push(newItem);
    };

    @action
    removeRBCFilterItem = (inputIndex: number) => {
        if (this.RBCFilterItems.length === 1) return;
        this.RBCFilterItems = this.RBCFilterItems.filter(
            (item, index) => index !== inputIndex,
        );
    };

    @action
    changeRBCCombinator = (index: number, input: CombinatorRuleType) => {
        const thisItem = this.RBCFilterItems[index];
        const nextItem = this.RBCFilterItems[index + 1];
        const itemCount = this.RBCFilterItems.length;
        const newFilterId =
            input === CombinatorRuleType.Or
                ? thisItem.filterGroupId + 1
                : thisItem.filterGroupId;

        thisItem.combinator = input;
        nextItem.combinator = input;
        nextItem.filterGroupId = newFilterId;

        // For all items after changed item
        for (let i = index + 2; i < itemCount; i++) {
            const currentItem = this.RBCFilterItems[i];
            if (input === CombinatorRuleType.And) {
                currentItem.filterGroupId--;
            } else {
                currentItem.filterGroupId++;
            }
        }
    };

    /**
     * Removes the first found classifier from the list of classifiers by
     * its id.
     * @param id
     * @returns true if the classifier was found and removed, false otherwise.
     */
    @action
    removeClassifierById = (id: string) => {
        for (let i = 0; i < this.RBCFilterItems.length; i++) {
            const item = this.RBCFilterItems[i];
            if (item.classifierIds.includes(id)) {
                const newClassifierIds = item.classifierIds.filter(
                    (classifierId) => classifierId !== id,
                );

                if (newClassifierIds.length === 0) {
                    // no new classifiers and i = 0 means we've deleted the only classifier
                    // within the only filter item.
                    if (i === 0) this.setRBCFilterItems(initRBCFilterItems);
                    else this.removeRBCFilterItem(i);
                } else
                    this.setRBCFilterItem(i, {
                        ...item,
                        classifierIds: newClassifierIds,
                    });
                return true;
            }
        }
        return false;
    };
}
