export class ReportFormatOptions {
    public enablePercentFormat?: boolean;

    public static fromJson(json: any): ReportFormatOptions {
        if (json) {
            return Object.assign(new ReportFormatOptions(), json);
        }
        return {};
    }
}
