import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import ConversationRouting from "./Views/Main/ConversationRouting";
import ConversationsStore from "./Stores/ConversationsStore";
import ConversationDrawer from "./Views/Drawer/ConversationDrawer";
import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useConversationStyles = makeStyles((theme: Theme) => ({
    container: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    workflowContainer: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(5),
    },
}));

const Conversations: React.FunctionComponent = observer(() => {
    const store = useStore(ConversationsStore);
    const classes = useConversationStyles();
    return (
        <SideControlsPage
            drawerMinWidth={!store.isFocused ? 260 : 248}
            drawerContent={<ConversationDrawer />}
            mainContent={<ConversationRouting />}
            drawerLocation="left"
            mainGridSpacing={0}
            mainGridJustify={"flex-start"}
            mainGridAlignItems={"stretch"}
            containerOffsetPixels={64}
            drawerVerticalOffsetPixels={0}
            thinDrawer={store.isFocused}
            wideDrawerOverride
            drawerMaxWidth={500}
            mainContentClasses={
                store.isWorkflow ? classes.workflowContainer : classes.container
            }
            addMargin={"0.5rem"}
        />
    );
});

export default Conversations;
