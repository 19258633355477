import { action, makeObservable, observable } from "mobx";
import { copyBaseFields } from "../utils/BaseEntityUtils";
import BaseEntity from "./BaseEntity";
import type Classifier from "./ClassifierModel";
import Evaluation from "./Evaluation";

export class ClassifierResultValidation extends BaseEntity {
    constructor(
        id: string | undefined,
        createdBy: string | undefined,
        modifiedBy: string | undefined,
        createdOn: string | undefined,
        modifiedOn: string | undefined,
        evaluationId: string,
    ) {
        super(id, createdBy, modifiedBy, createdOn, modifiedOn);
        makeObservable(this);
        this.evaluationId = evaluationId;
    }

    @observable result?: "true" | "false";

    classifierId: string;
    classifier: Classifier;

    evaluationId: string;
    evaluation: Evaluation;

    @action
    setClassifier(classifier: Classifier) {
        this.classifier = classifier;
        this.classifierId = classifier.id;
    }

    @action
    setClassifierId(classifierResultId: string) {
        this.classifierId = classifierResultId;
    }

    @action
    setResult = (result?: "true" | "false") => {
        this.result = result;
    };

    static fromJson(json: ClassifierResultValidation) {
        const res = new ClassifierResultValidation(
            json.id,
            json.createdBy,
            json.modifiedBy,
            json.createdOn,
            json.modifiedOn,
            json.evaluationId,
        );
        copyBaseFields(json, res);

        res.result = json.result;
        res.evaluationId = json.evaluationId;
        res.classifierId = json.classifierId;
        res.classifier = json.classifier;
        return res;
    }
}
