import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChipBase from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";

export const AdverseEventChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    return (
        <AppliedFilterChipBase
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={() => applicationFiltersStore.setAdverseEvent(undefined)}
        >
            {props.item.title}:{" "}
            {applicationFiltersStore.adverseEvent ? "Present" : "Not Present"}
        </AppliedFilterChipBase>
    );
});
