import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxLabeledInputToggle from "components/UI/AcxLabeledInputToggle";
import AcxLabeledSelectToggle from "components/UI/Select/AcxLabeledSelectToggle";
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import { OrganizationAgentStore } from "../stores/OrganizationAgentStore";
import AcxLabeledDateToggle from "components/UI/AcxLabeledDateToggle";
import moment from "moment";
import AcxLabeledSelectAsyncToggle from "components/UI/Select/AcxLabeledSelectAsyncToggle";
import { AppUser } from "components/Admin/UserManager/UserStore";

const MergeAgentsDialog = observer(() => {
    const agentStore = useStore(OrganizationAgentStore);
    const [mergeAgentsFormComponents, setMergeAgentsFormComponents] =
        React.useState<React.ReactNode[]>([]);

    React.useEffect(() => {
        let dispose = autorun(() => {
            if (
                agentStore.dgStore.selectedRows.length === 2 &&
                agentStore.dgStore.selectedRows[0] &&
                agentStore.dgStore.selectedRows[1]
            ) {
                setMergeAgentsFormComponents([
                    <AcxLabeledInputToggle
                        id={`${agentStore.dgStore.selectedRows[0].id}_${agentStore.dgStore.selectedRows[1].id}_agentEmail`}
                        key="agentEmailControl"
                        fieldIdentifierLabel="Email"
                        fieldName="agentEmail"
                        firstValue={
                            agentStore.dgStore.selectedRows[1].agentEmail
                        }
                        secondValue={
                            agentStore.dgStore.selectedRows[0].agentEmail
                        }
                        onChange={(e) =>
                            agentStore.onMergeAgentUpdate(
                                "agentEmail",
                                e.target.value,
                            )
                        }
                    />,
                    <AcxLabeledInputToggle
                        id={`${agentStore.dgStore.selectedRows[0].id}_${agentStore.dgStore.selectedRows[1].id}_firstName`}
                        key="firstNameControl"
                        fieldIdentifierLabel="First Name"
                        fieldName="firstName"
                        firstValue={
                            agentStore.dgStore.selectedRows[1].firstName
                        }
                        secondValue={
                            agentStore.dgStore.selectedRows[0].firstName
                        }
                        onChange={(e) =>
                            agentStore.onMergeAgentUpdate(
                                "firstName",
                                e.target.value,
                            )
                        }
                    />,
                    <AcxLabeledInputToggle
                        id={`${agentStore.dgStore.selectedRows[0].id}_${agentStore.dgStore.selectedRows[1].id}_lastName`}
                        key="lastNameControl"
                        fieldIdentifierLabel="Last Name"
                        fieldName="lastName"
                        firstValue={agentStore.dgStore.selectedRows[1].lastName}
                        secondValue={
                            agentStore.dgStore.selectedRows[0].lastName
                        }
                        onChange={(e) =>
                            agentStore.onMergeAgentUpdate(
                                "lastName",
                                e.target.value,
                            )
                        }
                    />,
                    <AcxLabeledSelectToggle
                        id={`${agentStore.dgStore.selectedRows[0].id}_${agentStore.dgStore.selectedRows[1].id}_serviceHierarchies`}
                        key="serviceAccessControl"
                        fieldName="serviceHierarchies"
                        fieldIdentifierLabel="Service Access"
                        firstDropdownOptions={agentStore.dgStore.selectedRows[1].hierarchies.map(
                            (h) => ({
                                id: h.id,
                                label: h.levels
                                    .filter((level) => !!level)
                                    .join(" > "),
                            }),
                        )}
                        secondDropdownOptions={agentStore.dgStore.selectedRows[0].hierarchies.map(
                            (h) => ({
                                id: h.id,
                                label: h.levels
                                    .filter((level) => !!level)
                                    .join(" > "),
                            }),
                        )}
                        valueField="id"
                        labelField="label"
                        onChange={
                            agentStore.onMergeAgentUpdateServiceHierarchies
                        }
                    />,
                    <AcxLabeledInputToggle
                        id={`${agentStore.dgStore.selectedRows[0].id}_${agentStore.dgStore.selectedRows[1].id}_agentCode`}
                        key="agentCodeControl"
                        fieldIdentifierLabel="Agent Code"
                        fieldName="agentCode"
                        firstValue={
                            agentStore.dgStore.selectedRows[1].agentCode
                        }
                        secondValue={
                            agentStore.dgStore.selectedRows[0].agentCode
                        }
                        onChange={(e) =>
                            agentStore.onMergeAgentUpdate(
                                "agentCode",
                                e.target.value,
                            )
                        }
                    />,
                    <AcxLabeledDateToggle
                        id={`${agentStore.dgStore.selectedRows[0].id}_${agentStore.dgStore.selectedRows[1].id}_startDate`}
                        key="startDateControl"
                        fieldIdentifierLabel="Start Date"
                        fieldName="startDate"
                        firstValue={
                            agentStore.dgStore.selectedRows[1].startDate
                        }
                        secondValue={
                            agentStore.dgStore.selectedRows[0].startDate
                        }
                        onChange={(e) => {
                            const value = e.target.value
                                ? moment(e.target.value)
                                : undefined;
                            agentStore.onMergeAgentUpdate("startDate", value);
                        }}
                    />,
                    <AcxLabeledSelectAsyncToggle
                        id={`${agentStore.dgStore.selectedRows[0].id}_${agentStore.dgStore.selectedRows[1].id}_managerId`}
                        key="managerId"
                        fieldName="managerId"
                        fieldIdentifierLabel="Manager"
                        firstDropdownValue={
                            agentStore.dgStore.selectedRows[1]
                                .manager as AppUser
                        }
                        secondDropdownValue={
                            agentStore.dgStore.selectedRows[0]
                                .manager as AppUser
                        }
                        loadOptions={agentStore.searchUsers}
                        valueField="id"
                        labelField={(option: any) => {
                            // using any type "any" here because the returned option was not the Option type expected
                            return `${option.firstName} ${option.lastName}`;
                        }}
                        onChange={(user?: AppUser) => {
                            agentStore.onMergeAgentUpdate(
                                "managerId",
                                user?.id,
                            );
                            agentStore.onMergeAgentUpdate("manager", user);
                        }}
                        noOptionMessage="Please enter First Name, Last Name, Email, or Username"
                    />,
                ]);
            }
        });

        return dispose;
    }, [agentStore, agentStore.dgStore.selectedRows]);

    return (
        <>
            <AcxConfirmationDialog
                isOpen={agentStore.mergeAgentsDialogStore.isOpen}
                onClose={agentStore.mergeAgentsDialogStore.close}
                title="Merge Agents"
                subTitle="Choose which fields you want to keep from each agent record, and we'll merge them into one record."
                content={mergeAgentsFormComponents}
                confirmButtonText="Merge Agents"
                cancelButtonText="Cancel"
                isLoading={agentStore.getTaskLoading("Merge Agents")}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={12}
                onConfirm={agentStore.onAgentsMergeConfirm}
                maxWidth={"lg"}
            />
        </>
    );
});

export default MergeAgentsDialog;
