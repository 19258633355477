import DeleteIcon from "@mui/icons-material/Delete";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import PeopleIcon from "@mui/icons-material/People";
import { Grid, Theme, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxMainTextField from "components/UI/AcxMainTextField";
import {
    BranchDataProps,
    HandleBranchProps,
} from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import _ from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { Tier } from "../../types/Tier.type";
import AddRowIcon from "./Components/AddRowIcon";
import EditTierIcon from "./Components/EditTierIcon";
import SamplingTargetLabeledMetrics from "./Components/SamplingTargetLabeledMetrics";
import { OrganizationServiceHierarchyStore } from "./stores/OrganizationServiceHierarchyStore";

const styles = (theme: Theme) => {
    return createStyles({
        label: {
            display: "flex",
            flexGrow: 1,
            marginRight: theme.spacing(1),
        },
        iconContainer: {
            display: "flex",
            alignItems: "center",
        },
        icon: {
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            cursor: "pointer",
        },
    });
};

interface OrganizationServiceHierarchyTierProps {
    branch: BranchDataProps;
    handleTier: HandleBranchProps;
    store: OrganizationServiceHierarchyStore;
    authStore: AuthStore;
}

const OrganizationServiceHierarchyTier: React.FC<OrganizationServiceHierarchyTierProps> =
    observer(({ branch, handleTier, store, authStore }) => {
        const classes = useStyles(styles);
        const [name, setName] = React.useState<string>(branch.name ?? "");
        const [isLoading, setIsLoading] = React.useState<boolean>(false);

        const hasEditPerms = authStore.canUserEdit("Service Hierarchy");

        // eslint-disable-next-line react-hooks/exhaustive-deps
        const debouncedNameUpdate = React.useCallback(
            _.debounce((name: string, branch: BranchDataProps) => {
                handleTier({ ...branch, name: name }, "Update");
                setIsLoading(false);
            }, 1500),
            [],
        );

        const onNameChange = (name: string) => {
            setName(name);
            setIsLoading(true);
            debouncedNameUpdate(name, branch);
        };

        const handleDelete = () => {
            store.setActiveTier(branch as Tier);
            store.setShowDeleteDialog(true);
        };

        const handleTierReassignment = () => {
            store.setActiveTier(branch as Tier);
            store.setShowReassignTierDialog(true);
        };

        React.useEffect(() => {
            if (store.endpointFired.active && store.activeTier) {
                if (branch.id === store.activeTier.id) {
                    switch (store.endpointFired.payload) {
                        case "Add":
                            handleTier(
                                store.activeTier as BranchDataProps,
                                "Add",
                            );

                            return;

                        case "Update":
                            handleTier(
                                store.activeTier as BranchDataProps,
                                "Update",
                            );

                            return;

                        case "Remove":
                            handleTier(
                                store.activeTier as BranchDataProps,
                                "Remove",
                            );

                            return;

                        default:
                            return;
                    }
                }
            }
        }, [
            store.endpointFired,
            store.endpointFired.active,
            store.activeTier,
            branch.id,
            handleTier,
        ]);

        return (
            <>
                <Grid container item xs={4} alignItems="center">
                    <AcxMainTextField
                        isDisabled={!hasEditPerms}
                        containerStyle={{
                            width: "100%",
                            padding: "0.25rem 1rem",
                        }}
                        fullWidth
                        id="name"
                        value={name}
                        onChange={(e) => {
                            onNameChange(e.target.value);
                        }}
                        labelText={
                            store.orgServiceSetup?.[branch.branchDepth ?? 0] ??
                            ""
                        }
                    />
                </Grid>

                <Grid
                    container
                    item
                    xs={4}
                    alignItems="center"
                    justifyContent="center"
                >
                    <SamplingTargetLabeledMetrics
                        tier={branch as Tier}
                        osmStore={store}
                    />
                </Grid>

                <Grid
                    container
                    item
                    xs={4}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {isLoading && (
                        <Grid item>
                            <AcxLoadingIndicator
                                size={25}
                                alternate="PuffLoader"
                            />
                        </Grid>
                    )}

                    {(branch.isLeaf ?? false) && (
                        <Tooltip title="View Assigned Users">
                            <PeopleIcon
                                className={classes.icon}
                                htmlColor="#bebebe"
                                onClick={() => {
                                    store.setActiveTier(branch as Tier);
                                    store.openServiceHierarchyUsersDrawer();
                                }}
                            />
                        </Tooltip>
                    )}

                    {hasEditPerms && (
                        <AddRowIcon
                            tier={branch as Tier}
                            osmStore={store}
                            isLeaf={branch?.isLeaf ?? false}
                        />
                    )}

                    {(branch.branchDepth ?? 0) > 0 && hasEditPerms && (
                        <Tooltip title="Reassign Tier">
                            <ImportExportIcon
                                color="primary"
                                onClick={handleTierReassignment}
                                cursor="pointer"
                                className={classes.icon}
                            />
                        </Tooltip>
                    )}

                    <EditTierIcon
                        tier={branch}
                        osmStore={store}
                        isLeaf={branch?.isLeaf ?? false}
                    />

                    {hasEditPerms && (
                        <Tooltip title="Delete Tier">
                            <DeleteIcon
                                htmlColor="#bebebe"
                                onClick={handleDelete}
                                cursor="pointer"
                                className={classes.icon}
                            />
                        </Tooltip>
                    )}
                </Grid>
            </>
        );
    });

export default OrganizationServiceHierarchyTier;
