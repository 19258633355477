import { Card, Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import clsx from "clsx";
import React from "react";
import useStyles from "Styles/Styles";
import { isFunctionType, isStringType } from "../../utils/TypeGuards";

const styles = (theme: Theme) =>
    createStyles({
        card: {
            position: "relative",
            minHeight: 185,
            boxShadow:
                "0 0 4px 2px rgba(0,0,0,0.02), 0 2px 24px 0 rgba(0,0,0,0.02)",
        },
        content: {
            padding: theme.spacing(3),

            height: "100%",
        },
        bottomElement: {
            position: "absolute",
            bottom: 0,
        },
    });

type Props = {
    title: React.ReactElement | string;
    subTitle?: string;
    content:
        | React.ReactElement
        | string
        | ((...args: any) => React.ReactElement);
    controls?: React.ReactElement;
    bottomElement?: React.ReactElement;
    mainClassName?: string;
    contentClassName?: string;
};

function ACXCard(props: Props) {
    const classes = useStyles(styles);

    function renderContent(...args: any) {
        const { content } = props;
        if (isStringType(props)) {
            return (
                <Typography className={classes.content}>
                    {content as React.ReactNode}
                </Typography>
            );
        }

        return <>{isFunctionType(content) ? content(...args) : content} </>;
    }

    function renderHeader() {
        let title: any;
        if (isStringType(props.title)) {
            title = <Typography variant="h2">{props.title}</Typography>;
        } else {
            title = props.title;
        }

        return (
            <Grid container justifyContent="space-between">
                <Grid item>{title}</Grid>
                {props.controls && <Grid item>{props.controls}</Grid>}
            </Grid>
        );
    }

    function renderBottomElement() {
        if (props.bottomElement) {
            return (
                <Grid container>
                    <Grid item container sm={12}>
                        {props.bottomElement}
                    </Grid>
                </Grid>
            );
        }

        return null;
    }

    return (
        <Card elevation={0} className={clsx(classes.card, props.mainClassName)}>
            <Grid
                className={props.contentClassName ?? classes.content}
                container
                direction="column"
                spacing={3}
                justifyContent={"flex-end"}
            >
                <Grid item>{renderHeader()}</Grid>
                <Grid item>{renderContent()}</Grid>
            </Grid>
            {renderBottomElement()}
        </Card>
    );
}

export default ACXCard;
