import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "utils/useStore";
import useOrganizationModule from "../hooks/useOrganizationModule";
import OrganizationModuleDetailsForm from "./OrganizationModuleDetailsForm";
import { OrganizationModuleDetailsStore } from "./OrganizationModuleDetailsStore";

const OrganizationModuleDetails: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
    const { organizationId, moduleId } = useParams();
    // const { organizationId, moduleId } = params as {
    //     organizationId: string;
    //     moduleId: string;
    // };
    const { module, updateModule, refetch, isLoading } = useOrganizationModule(
        organizationId!,
        moduleId!,
    );

    const store = useStore(OrganizationModuleDetailsStore);

    useEffect(() => {
        if (module) store.initializeFetchedData(module);
    }, [module, store]);

    return (
        <>
            {module ? (
                <OrganizationModuleDetailsForm
                    module={module}
                    updateModule={updateModule}
                    refetch={refetch}
                    isLoading={isLoading}
                />
            ) : (
                <AcxLoadingIndicator
                    color="secondary"
                    alternate="PuffLoader"
                    size={32}
                />
            )}
        </>
    );
};

export default OrganizationModuleDetails;
