import { Grid, Slider, Typography, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import React from "react";
import useStyles from "Styles/Styles";

interface OwnProps {
    label?: string;
    value?: number | number[]; // an array value changes this to a range slider
    description?: string;
    onChange?: any;
    customRootStyle?: any;
}

const styles = () => {
    return createStyles({
        sliderLabel: {
            fontWeight: "bold",
        },
    });
};

const AcxSlider = (props: OwnProps) => {
    const classes = useStyles(styles);
    const theme = useTheme();

    const handleChange = (event, newValue) => {
        props.onChange(newValue);
    };

    return (
        <Grid className={props.customRootStyle}>
            <Typography id="slider-label" className={classes.sliderLabel}>
                {props.label}
            </Typography>
            <Slider
                value={props.value}
                onChange={handleChange}
                aria-labelledby="slider-label"
                sx={{
                    height: 8,
                    ".MuiSlider-thumb": {
                        height: 10,
                        width: 10,
                        backgroundColor: theme.palette.white.main,
                        border: "2px solid currentColor",
                        // marginTop: -1,
                        // marginLeft: 1,
                        "&:focus, &:hover, &$active": {
                            boxShadow: "inherit",
                        },
                    },
                    active: {},
                    ".MuiSlider-track": {
                        height: 8,
                        borderRadius: 4,
                        // marginLeft: 1,
                    },
                    ".MuiSlider-rail": {
                        height: 8,
                        borderRadius: 4,
                        color: theme.palette.gray.main,
                    },
                }}
            />
            <Typography>
                {Array.isArray(props.value)
                    ? props.value[1] !== 0
                        ? props.value[0]?.toString() +
                          " - " +
                          props.value[1]?.toString()
                        : props.value[0]?.toString()
                    : props.value}{" "}
                {props.description}
            </Typography>
        </Grid>
    );
};

export default AcxSlider;
