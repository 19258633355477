import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

type StyleProps = {
    canCollapse?: boolean;
};

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
    summaryTitle: ({ canCollapse }) => ({
        fontWeight: 600,
        fontSize: "14px",
        marginBottom: theme.spacing(1),
        "&:hover": canCollapse
            ? {
                  color: theme.palette.secondary.main,
                  cursor: "default",
              }
            : {},
    }),
    width85: {
        width: "85%",
    },
    eddy: {
        fontWeight: 400,
        fontSize: "13px",
    },
    eddieHighlight: {
        fontWeight: 700,
        fontSize: "13px",
        color: "#32AEED",
    },
    adverseEventHighlight: {
        fontWeight: 700,
        fontSize: "13px",
        color: theme.palette.error[900],
    },
    aeContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    eddyContainer: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    chevronButton: {
        padding: 0,
        marginRight: theme.spacing(0.25),
    },
}));
