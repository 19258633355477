import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
    Button,
    Grid,
    LinearProgress,
    LinearProgressProps,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import useUserHierarchy from "components/Admin/Organizations/OrganizationDetail/OrganizationUsers/OrganizationUserDetails/useUserHierarchy";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { Observer, observer } from "mobx-react";
import { InteractionType } from "models/InteractionType";
import moment from "moment";
import React, { FunctionComponent } from "react";
import { AuthStore } from "stores/AuthStore";
// import LocalStorage from "stores/LocalStorage";
import { styled } from "@mui/system";
import { AsyncTaskStatus } from "../../../../stores/BaseStore";
import useStyles from "../../../../Styles/Styles";
import hexToRGB from "../../../../utils/hexToRGB";
import { stringPluralizer } from "../../../../utils/StringPluralizer";
import { useStore } from "../../../../utils/useStore";
import {
    AnalystDashboardStore,
    loadAnalystEvaluationsTask,
    LoadHiersExceptLive,
} from "../Stores/AnalystDashboardStore";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            height: "100%",
        },
        controlSection: {
            height: "100%",
            backgroundColor: "#2E8EFF",
            borderRadius: "130px 0 0 6px",
        },
        bigSize: {
            fontSize: "36px",
        },
        bigIcon: {
            fontSize: "99px",
            color: theme.palette.success.main,
        },
        startEvalText: {
            color: "#FFFFFF",
            fontSize: "18px",
            fontWeight: "bold",
            letterSpacing: "0",
            lineHeight: "28px",
            textAlign: "center",
            width: "215px",
            userSelect: "none",
        },
        startEvalButton: {
            marginTop: "16px",
            width: "231px",
            color: "#2E8EFF",
            backgroundColor: "#FFFFFF",
            fontSize: "16px",
            fontWeight: "bold",
            letterSpacing: 0,
            lineHeight: "24px",
            textTransform: "none",
            textAlign: "center",
            "&:hover": {
                backgroundColor: hexToRGB(theme.palette.secondary.main, 0.5),
                color: "#FFFFFF",
            },
        },
        monthlyProgressText: {
            fontSize: "20px",
            fontWeight: "bold",
            letterSpacing: "0",
            lineHeight: "28px",
            userSelect: "none",
        },
        infoSection: {
            height: "100%",
            marginTop: "24px",
            paddingLeft: "5%",
            userSelect: "none",
        },
        iconSubtitle: {
            fontSize: "14px",
            opacity: 0.5,
            letterSpacing: 0,
            lineHeight: "20px",
        },
    });

const StartEvalText = styled(Typography)({
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "28px",
    textAlign: "center",
    width: "215px",
    userSelect: "none",
});

const IconSubtitleText = styled(Typography)({
    fontSize: "14px",
    opacity: 0.5,
    letterSpacing: 0,
    lineHeight: "20px",
});

const MonthlyProgressText = styled(Typography)({
    fontSize: "20px",
    fontWeight: "bold",
    letterSpacing: "0",
    lineHeight: "28px",
    userSelect: "none",
});

function LeadershipComparisonBar(
    props: LinearProgressProps & { value: number; label: string },
) {
    return (
        <Grid
            container
            style={{ paddingRight: "84px" }}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <Grid item container justifyContent={"flex-end"}>
                <IconSubtitleText variant="body2" color="textSecondary">
                    {props.label}
                </IconSubtitleText>
            </Grid>

            <Grid item>
                <LinearProgress
                    sx={{
                        height: "16px",
                        borderRadius: 3,
                        backgroundColor: "#E5E7EB",
                        ".MuiLinearProgress-bar": {
                            backgroundColor: "#22C55E",
                        },
                    }}
                    {...props}
                />
            </Grid>
        </Grid>
    );
}
interface OwnProps {
    goToEvalPage: (evalId: string) => void;
}

type Props = OwnProps;

const Header: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles(styles);
    const store = useStore(AnalystDashboardStore);
    const authStore = useStore(AuthStore);

    // const [allMediaHiers, setAllMediaHiers] = React.useState<boolean>(false);

    const [userHierarchies, setUserHierarchies] =
        React.useState<{ label: string; id: string }[]>();

    // const [allUserHierarchies, setAllUserHierarchies] =
    //     React.useState<{ label: string; id: string }[]>();

    // React.useState<UserHierarchyMember[]>();

    const { userHierarchy } = useUserHierarchy(
        store.authStore._user.profile.uId,
        store.orgSelectorStore.orgId,
        true,
        InteractionType.Live,
    );

    // const { getLocalStore } = useStore(LocalStorage);
    // const evalRecords = getLocalStore("EvalUndo");

    // React.useEffect(() => {
    //     const getHiers = async () => {
    //         const hiers = await store.getUserHierarchiesExceptLive(
    //             store.orgSelectorStore.orgId,
    //             store.authStore._user.profile.uId,
    //         );
    //         setAllUserHierarchies(hiers);
    //         console.log("getting hiers");
    //     };

    //     getHiers();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    React.useEffect(() => {
        if (userHierarchy) {
            setUserHierarchies(userHierarchy);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userHierarchy?.length > 0]);

    const startEvalOptions = React.useMemo(
        () => [
            {
                label: !Boolean(store.pendingEval)
                    ? " Start an Evaluation"
                    : `Continue Evaluation`,
                value: "startEval",
            },
            {
                label: "Choose Hierarchy and Start Evaluation",
                value: "hierarchyStartEval",
            },
        ],
        [store.pendingEval],
    );

    React.useEffect(() => {
        if (userHierarchies && userHierarchies.length > 0) {
            startEvalOptions.push({
                label: "Generate a Live Evaluation",
                value: "generateEval",
            });
        }
    }, [startEvalOptions, userHierarchies]);

    // React.useEffect(() => {
    //     const getEvalRecordsData = async () => {
    //         const entries = await evalRecords.keys();
    //         const record = await evalRecords.getItem(
    //             entries
    //                 .filter((entry) =>
    //                     entry.includes(
    //                         store.authStore.orgStore.selectedOrganization?.id ??
    //                             "",
    //                     ),
    //                 )
    //                 .slice(-1)[0],
    //         );

    //         if (record) {
    //             store.rootStore
    //                 .getStore(EvalStore)
    //                 .setLastEvalRecord(
    //                     JSON.parse(record as string) as Evaluation,
    //                 );

    //             startEvalOptions.push({
    //                 label: "Load Last Evaluation",
    //                 value: "loadLastEval",
    //             });
    //         }
    //     };

    //     getEvalRecordsData();
    // }, [
    //     evalRecords,
    //     startEvalOptions,
    //     store.authStore.orgStore.selectedOrganization?.id,
    //     store.rootStore,
    // ]);

    function startNextEval() {
        store
            .startNextEval("")
            .then((value) => {
                if (!value || value === AsyncTaskStatus.Error) {
                    return;
                }
                props.goToEvalPage(value as string);
                setTimeout(() => {
                    store.refresh();
                }, 2000);
            })
            .catch((reason) => console.error(reason));
    }

    const formattedStartMonth = moment()
        .local()
        .startOf("month")
        .format("MMM Do");

    const formattedEndMonth = moment().local().endOf("month").format("MMM Do");

    const progressVale =
        (100 * (store.analystMonthlyProgress?.completedEvaluations ?? 0)) /
        (store.evaluationLeaderStats?.completedEvaluations ?? 1);

    const diffToLeader =
        (store.evaluationLeaderStats?.completedEvaluations ?? 0) -
        (store.analystMonthlyProgress?.completedEvaluations ?? 0);

    const progressText =
        (store.evaluationLeaderStats?.completedEvaluations ?? 0) === 0 &&
        (store.analystMonthlyProgress?.completedEvaluations ?? 0) === 0
            ? "No evaluations completed yet this month"
            : progressVale < 100
            ? `You're ${diffToLeader} ${stringPluralizer(
                  "evaluation",
                  diffToLeader,
                  "s",
              )} behind this month's leader - ${
                  store.evaluationLeaderStats?.userName
              }`
            : `You're leading this month's evaluations${
                  diffToLeader === 0 ? "" : ` by ${-diffToLeader}`
              }`;

    const onChangeStartEvalSelection = (
        e: { label: string; value: string } | undefined,
    ) => {
        if (e?.value === "startEval") {
            startNextEval();
        } else if (e?.value === "hierarchyStartEval") {
            // setAllMediaHiers(true);
            store.onHierarchyStartEval();
        } else if (e?.value === "generateEval") {
            // setAllMediaHiers(false);
            store.onLiveEvalClose();
        } else if (e?.value === "loadLastEval") {
            if (store.rootStore.getStore(EvalStore).lastEvalRecord) {
                store.rootStore.getStore(EvalStore).loadLastEval = true;
                props.goToEvalPage(
                    store.rootStore.getStore(EvalStore).lastEvalRecord
                        ?.id as string,
                );
            }
        }
    };

    const onClickLoadLast = () => {
        if (store.rootStore.getStore(EvalStore).lastEvalRecord) {
            store.rootStore.getStore(EvalStore).loadLastEval = true;
            props.goToEvalPage(
                store.rootStore.getStore(EvalStore).lastEvalRecord
                    ?.id as string,
            );
        }
    };

    const onChangeHierarchyStartEval = (e: { id: string; label?: string }) => {
        store
            .startHierEval(e.id)
            .then((value) => {
                if (!value || value === AsyncTaskStatus.Error) {
                    return;
                }
                store.onHierarchyStartEval();
                props.goToEvalPage(value as string);
                setTimeout(() => {
                    store.refresh();
                }, 2000);
            })
            .catch((reason) => console.error(reason));
    };

    const onChangeHierarchy = (e: { id: string; label?: string }) => {
        store
            .startLiveEval(e.id)
            .then((value) => {
                if (!value || value === AsyncTaskStatus.Error) {
                    return;
                }
                props.goToEvalPage(value as string);
                setTimeout(() => {
                    store.refresh();
                }, 2000);
            })
            .catch((reason) => console.error(reason));
    };

    return (
        <>
            <AcxDialog
                isOpen={store.liveEvalModalOpen}
                onClose={store.onLiveEvalClose}
                title="Select Hierarchy"
                text="Please indicate which hierarchy you would like to generate a Live Evaluation from."
                dialogContentChildren={
                    <AcxSelectSingle
                        options={userHierarchies ?? []}
                        isDisabled={
                            store.getTaskLoading(loadAnalystEvaluationsTask) ||
                            !store.orgSelectorStore.orgId
                        }
                        onChange={onChangeHierarchy}
                        defaultValue={undefined}
                        isLoading={store.getTaskLoading("Generate Live Eval")}
                        id={"start-evaluation-selection-dropdown"}
                        valueField={"id"}
                        labelField={"label"}
                        placeholder="Please Select..."
                    />
                }
            />
            <Observer>
                {() => (
                    <AcxDialog
                        isOpen={store.hierarchyStartEvalOpen}
                        onClose={store.onHierarchyStartEval}
                        title="Select Hierarchy"
                        text="Please indicate which hierarchy you would like to choose an eval."
                        dialogContentChildren={
                            <AcxSelectSingle
                                options={store.allUserHierarchies ?? []}
                                isDisabled={
                                    store.getTaskLoading(LoadHiersExceptLive) ||
                                    !store.orgSelectorStore.orgId
                                }
                                onChange={onChangeHierarchyStartEval}
                                defaultValue={undefined}
                                isLoading={store.getTaskLoading(
                                    "Hierarchy Start Eval",
                                )}
                                id={"start-hier-evaluation-selection-dropdown"}
                                valueField={"id"}
                                labelField={"label"}
                                placeholder="Please Select..."
                            />
                        }
                    />
                )}
            </Observer>
            <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                width={"100%"}
                height={"100%"}
            >
                <Grid
                    item
                    xs={8}
                    height="100%"
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    marginTop="24px"
                    paddingLeft="5%"
                    sx={{
                        userSelect: "none",
                    }}
                >
                    <Grid
                        item
                        xs={3}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >
                        <CheckCircleOutlineIcon
                            sx={(theme) => ({
                                fontSize: "99px",
                                color: theme.palette.success.main,
                            })}
                        />
                    </Grid>

                    <Grid
                        container
                        item
                        xs={9}
                        direction={"column"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        style={{ paddingLeft: "4px" }}
                    >
                        <Grid item style={{ marginBottom: "8px" }}>
                            <MonthlyProgressText
                                className={classes.monthlyProgressText}
                            >
                                Progress for {formattedStartMonth} -{" "}
                                {formattedEndMonth}
                            </MonthlyProgressText>
                        </Grid>

                        <Grid
                            item
                            container
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Grid item>
                                <Typography variant={"h2"} fontSize={"36px"}>
                                    {store.analystMonthlyProgress
                                        ?.completedEvaluations ?? 0}
                                </Typography>
                            </Grid>
                            <Grid item style={{ paddingTop: "24px" }}>
                                <IconSubtitleText variant={"subtitle2"}>
                                    {stringPluralizer(
                                        "evaluation",
                                        store.analystMonthlyProgress
                                            ?.completedEvaluations ?? 0,
                                        "s",
                                    )}{" "}
                                    completed
                                </IconSubtitleText>
                            </Grid>
                        </Grid>

                        {Boolean(store.evaluationLeaderStats) &&
                            Boolean(store.analystMonthlyProgress) && (
                                <Grid
                                    item
                                    xs={12}
                                    style={{ width: "100%", marginTop: "8px" }}
                                >
                                    <LeadershipComparisonBar
                                        variant="determinate"
                                        value={Math.min(
                                            store.evalProgress,
                                            100,
                                        )}
                                        label={progressText}
                                    />
                                </Grid>
                            )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={4}
                    container
                    direction="column"
                    justifyContent={"center"}
                    alignItems="center"
                    className={classes.controlSection}
                >
                    <Grid item>
                        {authStore.canUserEdit("My Evaluations") ? (
                            <StartEvalText>
                                {!Boolean(store.pendingEval)
                                    ? "Ready to move on to the next evaluation?"
                                    : `Evaluation is currently in progress`}
                            </StartEvalText>
                        ) : (
                            <StartEvalText className={classes.startEvalText}>
                                Ready to jump back in?
                            </StartEvalText>
                        )}
                    </Grid>

                    <Grid
                        item
                        style={{
                            marginTop: "0.5rem",
                            width:
                                startEvalOptions.length > 1
                                    ? "170px"
                                    : "max-width",
                        }}
                    >
                        {authStore.canUserEdit("My Evaluations") &&
                        startEvalOptions.length > 1 ? (
                            <AcxSelectSingle
                                options={startEvalOptions ?? []}
                                isDisabled={store.getTaskLoading(
                                    loadAnalystEvaluationsTask,
                                )}
                                onChange={onChangeStartEvalSelection}
                                defaultValue={undefined}
                                id={"start-evaluation-selection-dropdown"}
                                valueField={"value"}
                                labelField={"label"}
                                placeholder="Please Select..."
                            />
                        ) : authStore.canUserEdit("My Evaluations") ? (
                            <Button
                                disabled={store.getTaskLoading(
                                    loadAnalystEvaluationsTask,
                                )}
                                className={classes.startEvalButton}
                                onClick={startNextEval}
                            >
                                {!Boolean(store.pendingEval)
                                    ? " Start an Evaluation"
                                    : `Continue Evaluation`}
                            </Button>
                        ) : (
                            <Button
                                disabled={store.getTaskLoading(
                                    loadAnalystEvaluationsTask,
                                )}
                                className={classes.startEvalButton}
                                onClick={onClickLoadLast}
                            >
                                Load Last Evaluation
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
});

export default Header;
