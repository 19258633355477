import React, { useState, useRef, useEffect } from "react";
import {
    Grid,
    Tooltip,
    IconButton,
    useMediaQuery,
    Typography,
    Theme,
} from "@mui/material";
import FocusControls from "Layouts/Focus/FocusControls";
import PlayControl from "components/AudioPlayer/PlayControl";
import PlaybackRate from "components/SoundClipEditor/Controls/PlaybackRate";
import { durationTitleDisplay } from "components/SoundClipEditor/Controls/Player";
import Organization from "models/Organization";
import { ConversationService } from "services/ConversationService";
import { AuthStore } from "stores/AuthStore";
import LocalStorage from "stores/LocalStorage";
import { useStore } from "utils/useStore";
import { PlayStatus } from "../UI/Audio/MicroPlayerControl";
import { useLocation } from "react-router-dom";
import theme from "Theme/AppTheme";
import Replay30Icon from "@mui/icons-material/Replay30";
import Forward30Icon from "@mui/icons-material/Forward30";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import ReactPlayer from "react-player";
import { createStyles } from "@mui/styles";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        button: {
            padding: theme.spacing(1),
        },
        duration: {
            color: theme.palette.blue.main,
            fontSize: "14px",
            fontWeight: 400,
            paddingTop: "2px",
        },
        playbackRateContainer: {
            margin: "0 0.5rem",
            padding: "0 0.25rem",
            borderRadius: "0.25rem",
        },
        controls: {
            padding: "12px 22px",
            height: "80px",
            position: "absolute",
            bottom: 10,
        },
        iconColor: {
            color: theme.palette.blue.main,
        },
    });

const VideoPage = () => {
    const [state, setState] = useState({
        url: null,
        pip: false,
        playing: false,
        controls: false,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        playedSeconds: 0,
        loaded: 0,
        loadedSeconds: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        seeking: false,
    });

    const [vidSources, setVidSources] = useState<any[]>([]);
    const [audioSource, setAudioSource] = useState<any>("");
    const [loading, setLoading] = useState(false);

    const [focusedPlayer, setFocusedPlayer] = useState<number>();
    const [hoveredPlayer, setHoveredPlayer] = useState<number>();

    const location = useLocation();

    const classes = useStyles(styles);

    const authStore = useStore(AuthStore);
    const local = authStore.rootStore.getStore(LocalStorage);

    const currentAmdId = location.pathname.split("/").pop();

    const ref1: any = useRef(null);
    const ref2: any = useRef(null);
    const ref3: any = useRef(null);
    const ref4: any = useRef(null);

    const conversationService = new ConversationService();

    const handleSeekMouseDown = (e) => {
        setState((prev) => ({ ...prev, seeking: true }));
    };

    const handleSeekChange = (e) => {
        setState((prev) => ({ ...prev, played: parseFloat(e.target.value) }));
    };

    const handleSeekMouseUp = (e) => {
        setState((prev) => ({ ...prev, seeking: false }));

        syncedSeekTo(e.target.value);
    };

    const syncedSeekTo = (playedRatio) => {
        ref1.current?.seekTo(parseFloat(playedRatio));
        ref2.current?.seekTo(parseFloat(playedRatio));
        ref3.current?.seekTo(parseFloat(playedRatio));
        ref4.current?.seekTo(parseFloat(playedRatio));
    };

    const handleProgress = (inputState) => {
        // We only want to update time slider if we are not currently seeking

        if (!state.seeking) {
            setState((prev) => ({ ...prev, ...inputState }));
        }
    };

    const handleDuration = (duration) => {
        if (duration > state.duration) {
            setState((prev) => ({ ...prev, duration }));
        }
    };

    const handleBack30Seconds = () => {
        const newPlayedSeconds = state.playedSeconds - 30;
        let newPlayed;
        if (newPlayedSeconds > 0) {
            newPlayed = newPlayedSeconds / state.duration;
        } else {
            newPlayed = 0;
        }

        syncedSeekTo(newPlayed);
    };

    const handleForward30Seconds = () => {
        const newPlayedSeconds = state.playedSeconds + 30;
        let newPlayed;
        if (newPlayedSeconds < state.duration) {
            newPlayed = newPlayedSeconds / state.duration;
        } else {
            newPlayed = state.duration;
        }

        syncedSeekTo(newPlayed);
    };

    const handleSetPlaybackRate = (speed) => {
        setState((prev) => ({ ...prev, playbackRate: parseFloat(speed) }));
    };

    const handleFocusScreen = (playerNum: number) => {
        if (playerNum === focusedPlayer) {
            setFocusedPlayer(undefined);
        } else {
            setFocusedPlayer(playerNum);
        }
    };

    const getFocusedHeight = (curNum: number, focusNum?: number) => {
        if (!focusNum) {
            if (vidSources.length > 2) {
                return "35vh";
            } else {
                return "50vh";
            }
        }
        if (curNum === focusNum) {
            return "70vh";
        } else {
            return "130px";
        }
    };

    const getFocusedWidth = (curNum: number, focusNum?: number) => {
        if (!focusNum) return "45vw";
        if (curNum === focusNum) {
            return "100vw";
        } else {
            return "320px";
        }
    };

    const player1 = vidSources.length > 0 && (
        <ReactPlayer
            id="react-player-1"
            ref={ref1}
            playing={state.playing}
            volume={0}
            url={vidSources[0]}
            playbackRate={state.playbackRate}
            loop={false}
            onProgress={handleProgress}
            onDuration={handleDuration}
            height={getFocusedHeight(1, focusedPlayer)}
            width={getFocusedWidth(1, focusedPlayer)}
        />
    );

    const player2 = vidSources.length > 1 && (
        <ReactPlayer
            id="react-player-2"
            ref={ref2}
            playing={state.playing}
            volume={0}
            url={vidSources[1]}
            playbackRate={state.playbackRate}
            loop={false}
            onDuration={handleDuration}
            height={getFocusedHeight(2, focusedPlayer)}
            width={getFocusedWidth(2, focusedPlayer)}
        />
    );

    const player3 = vidSources.length > 2 && (
        <ReactPlayer
            id="react-player-3"
            ref={ref3}
            playing={state.playing}
            url={vidSources[2]}
            playbackRate={state.playbackRate}
            volume={0}
            loop={false}
            onDuration={handleDuration}
            height={getFocusedHeight(3, focusedPlayer)}
            width={getFocusedWidth(3, focusedPlayer)}
        />
    );

    const controls = (
        <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            id="main-grid"
            className={classes.controls}
        >
            <Grid item xs={12}>
                <input
                    type="range"
                    min={0}
                    max={0.999999}
                    step="any"
                    value={state.played}
                    onMouseDown={handleSeekMouseDown}
                    onChange={handleSeekChange}
                    onMouseUp={handleSeekMouseUp}
                    style={{ width: "100%", cursor: "pointer" }}
                />
            </Grid>
            <Grid
                container
                item
                xs={4}
                justifyContent="flex-start"
                alignItems="center"
            >
                {/* <VolumeSlider onVolumeChange={handleVolumeChange} /> */}
            </Grid>
            <Grid container item xs={4} justifyContent="center">
                <Grid item>
                    <Tooltip title="Go back 30 seconds">
                        <IconButton
                            className={classes.button}
                            onClick={handleBack30Seconds}
                            size={"medium"}
                        >
                            <Replay30Icon
                                className={classes.iconColor}
                                fontSize={
                                    useMediaQuery(theme.breakpoints.down("md"))
                                        ? "small"
                                        : "large"
                                }
                                color={"inherit"}
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <PlayControl
                        style={{ color: "#3564D5" }}
                        play={() => {
                            setState((prev) => ({ ...prev, playing: true }));
                        }}
                        pause={() => {
                            setState((prev) => ({ ...prev, playing: false }));
                        }}
                        status={
                            state.playing ? PlayStatus.Play : PlayStatus.Paused
                        }
                        color={"inherit"}
                    />
                </Grid>
                <Grid item>
                    <Tooltip title="Go forward 30 seconds">
                        <IconButton
                            className={classes.button}
                            onClick={handleForward30Seconds}
                            size={"medium"}
                        >
                            <Forward30Icon
                                color={"inherit"}
                                className={classes.iconColor}
                                fontSize={
                                    useMediaQuery(theme.breakpoints.down("md"))
                                        ? "small"
                                        : "large"
                                }
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            <Grid
                container
                item
                xs={4}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Grid item className={classes.playbackRateContainer}>
                    <PlaybackRate
                        handleVideoPlaybackRate={(rate) =>
                            handleSetPlaybackRate(rate)
                        }
                        videoPlaybackRate={state.playbackRate}
                    />
                </Grid>
                <Grid item>
                    <Typography className={classes.duration}>
                        {durationTitleDisplay(
                            state.playedSeconds,
                            state.duration,
                        )}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );

    const hoverIcon = (playerNum?: number) => {
        return (
            hoveredPlayer === playerNum && (
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent="center"
                    style={{
                        position: "relative",
                        height: 0,
                    }}
                >
                    {focusedPlayer !== playerNum ? (
                        <FullscreenIcon />
                    ) : (
                        <FullscreenExitIcon />
                    )}
                </Grid>
            )
        );
    };

    const defaultScreenView = (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            onMouseLeave={() => {
                setHoveredPlayer(undefined);
            }}
        >
            <Grid
                item
                onClick={() => {
                    handleFocusScreen(1);
                }}
                style={{
                    cursor: "pointer",
                    order: focusedPlayer === 1 ? -1 : 1,
                }}
                onMouseEnter={() => {
                    setHoveredPlayer(1);
                }}
            >
                {player1}
                {hoverIcon(1)}
            </Grid>
            {vidSources.length > 1 && (
                <Grid
                    item
                    onClick={() => {
                        handleFocusScreen(2);
                    }}
                    style={{
                        cursor: "pointer",
                        order: focusedPlayer === 2 ? -1 : 2,
                    }}
                    onMouseEnter={() => {
                        setHoveredPlayer(2);
                    }}
                >
                    {player2}
                    {hoverIcon(2)}
                </Grid>
            )}
            {vidSources.length > 2 && (
                <Grid
                    item
                    justifyContent="center"
                    onClick={() => {
                        handleFocusScreen(3);
                    }}
                    style={{
                        cursor: "pointer",
                        order: focusedPlayer === 3 ? -1 : 3,
                    }}
                    onMouseEnter={() => {
                        setHoveredPlayer(3);
                    }}
                >
                    {player3}
                    {hoverIcon(3)}
                </Grid>
            )}
        </Grid>
    );

    const mainContent = (
        <Grid container style={{ height: "calc(100% - 150px)" }}>
            {defaultScreenView}

            {controls}
        </Grid>
    );

    useEffect(() => {
        if (!state.url && currentAmdId && !audioSource && !vidSources.length) {
            setLoading(true);
            // timeout used to ensure we have loaded correct org before loading recordings
            setTimeout(() => {
                try {
                    conversationService
                        .getScreenRecordings(currentAmdId)
                        .then((res: any) => {
                            for (const key in res) {
                                if (key.includes("audio")) {
                                    setAudioSource(res[key]);
                                } else {
                                    setVidSources((prev) => [
                                        ...prev,
                                        res[key],
                                    ]);
                                }
                            }
                            setLoading(false);
                        });
                } catch (e) {
                    setLoading(false);
                }
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const tempLocal = local.getLocalStore();
        tempLocal.getItem<string | null>("selectedOrgID").then((val) => {
            if (val) {
                authStore.orgStore?.setGlobalOrganization(
                    authStore.orgStore.organizations?.find(
                        (org) => org.id === val,
                    )! as Organization,
                );
                authStore.orgStore.onActiveOrgIdChange(val);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FocusControls
            title={"Video"}
            controlHeader={null}
            mainContent={mainContent}
            hideCancelButton
            loading={loading}
        />
    );
};

export default VideoPage;
