import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { makeObservable } from "mobx";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import * as RootStore from "stores/RootStore";
import { PipelineService } from "../../../../../services/PipelineService";
import RbcToTsQueryFailureResults from "models/RbcToTsQueryFailureResults";

@RootStore.AcxStore
export default class RbcToTsQueryStore extends BaseStore {
    readonly authStore: AuthStore;
    readonly pipelineService: PipelineService;

    public readonly datePickerStore = new DatePickerComponentStore();

    public failures: RbcToTsQueryFailureResults;
    readonly rbcToTsQueryDgStore: AcxDataGridStore;

    readonly drawerStore: LayoutDrawerStore;

    constructor(public rootStore: RootStore.IRootStore) {
        super("RequeueDataGridStore");
        makeObservable(this);

        this.rbcToTsQueryDgStore = new AcxDataGridStore(
            "RbcToTsQuery",
            "Administrative",
        );
        this.pipelineService = new PipelineService();
        this.authStore = rootStore.getStore(AuthStore);
        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);
    }

    public async updateFailures() {
        this.rbcToTsQueryDgStore.rows = [];
        this.rbcToTsQueryDgStore.clearSelected();
        this.rbcToTsQueryDgStore.isLoading = true;
        try {
            this.failures =
                await this.pipelineService.getRbcsToTsQueryFailures();
            this.rbcToTsQueryDgStore.rows = this.failures.classifiers;
        } catch (error) {
            throw error;
        } finally {
            this.rbcToTsQueryDgStore.isLoading = false;
        }
    }
}
