import * as React from "react";
import { ThreadActionType } from "../Models/ThreadAction";
import { SelectHierarchiesAction } from "./SelectHierarchies";
import { SelectTopicsAction } from "./SelectTopics";
import { SelectClassifierAction } from "./SelectClassifier";
import { SelectModulesAction } from "./SelectModules";
import { SelectAgentAction } from "./SelectAgent";
import { SelectAllModulesInCategoryAction } from "./SelectAllModulesInCategoryAction";
import { ViewSqlQueriesAction } from "./ViewSqlQueriesAction";

export interface ActionProps {
    args: any;
}

export const ActionTypeToComponent: Record<
    ThreadActionType,
    React.FunctionComponent<ActionProps>
> = {
    [ThreadActionType.SelectHierarchy]: SelectHierarchiesAction,
    [ThreadActionType.SelectTheme]: SelectTopicsAction,
    [ThreadActionType.SelectClassifier]: SelectClassifierAction,
    [ThreadActionType.SelectModule]: SelectModulesAction,
    [ThreadActionType.SelectAgent]: SelectAgentAction,
    [ThreadActionType.SelectAllModulesInCategory]:
        SelectAllModulesInCategoryAction,
    [ThreadActionType.ViewSqlQueries]: ViewSqlQueriesAction,
};
