import { Grid, Link } from "@mui/material";
import AcxControlledTable from "components/UI/AcxTable/AcxControlledTable";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const columns = (orgId: string) => [
    {
        headerLabel: "Name",
        dataKey: "groupName",
        formatter: (key, row) => (
            <Grid item>
                <Link
                    component={RouterLink}
                    to={`/admin/organizations/${orgId}/analyst-groups?id=${row.id}`}
                    color="secondary"
                    style={{ fontWeight: "bold" }}
                >
                    {key}
                </Link>
            </Grid>
        ),
    },
    {
        dataKey: "isActive",
        headerLabel: "Status",
        formatter: (id, row) => (row.isActive ? "Active" : "Disabled"),
    },
];

type Props = {
    data: any[];
    isLoading: boolean;
    orgId: string;
};

const OrganizationAnalystGroupsListTable: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    data,
    isLoading,
    orgId,
}) => {
    return (
        <AcxControlledTable
            isLoading={isLoading}
            skeletonRows={5}
            rows={data}
            columns={columns(orgId)}
            enableStripes={false}
        />
    );
};

export default OrganizationAnalystGroupsListTable;
