import { Grid, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect } from "react";
import theme from "Theme/AppTheme";
import { debounce } from "lodash";
import { IChatRecord } from "components/UI/Chat/AcxChatViewer";
import moment from "moment";
import { useInputSearchTranscriptionStyles } from "components/UI/AcxTranscription/Components/InputSearchTranscription.style";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";

type InputSearchTranscriptionProps = {
    transcriptionWords: IChatRecord[];
};

interface ISearchItemInterface {
    text: string;
    domId: string;
}

function truncateText(
    text: string,
    exampleText: string,
    maxLength: number,
    ellipsis: string = "...",
): string {
    const index = text.indexOf(exampleText);

    if (index === -1) {
        return text.length > maxLength
            ? text.slice(0, maxLength - ellipsis.length) + ellipsis
            : text;
    }

    const truncatedText = text.slice(index + exampleText.length);
    return truncatedText.length > maxLength
        ? truncatedText.slice(0, maxLength - ellipsis.length) + ellipsis
        : truncatedText;
}

const formatTranscriptForHighlight = (
    statement: string,
    filterText: string,
    maxLength: number,
    ellipsis: string = "...",
): JSX.Element => {
    // Escape special characters
    const escapedFilterText = filterText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    const terms = escapedFilterText.split(/\s+/);
    const regex = new RegExp(`(${terms.join("|")})`, "gi"); // Match any term

    const matches = [...statement.matchAll(regex)];

    if (matches.length === 0) {
        return (
            <span>
                {truncateText(statement, filterText, maxLength, ellipsis)}
            </span>
        );
    }

    const firstMatchIndex = matches[0].index || 0;

    const startIndex = Math.max(0, firstMatchIndex - maxLength / 2);
    const endIndex = Math.min(
        statement.length,
        firstMatchIndex + filterText.length + maxLength / 2,
    );

    const truncatedHighlightedStatement =
        startIndex > 0
            ? ellipsis + statement.slice(startIndex, endIndex)
            : statement.slice(startIndex, endIndex);

    const highlightedStatement = truncatedHighlightedStatement.replace(
        regex,
        (match) =>
            `<span style="color: ${theme.palette.secondary.main}; font-weight: bold">${match}</span>`,
    );

    return <span dangerouslySetInnerHTML={{ __html: highlightedStatement }} />;
};

export const InputSearchChat = ({
    transcriptionWords,
}: InputSearchTranscriptionProps) => {
    const classes = useInputSearchTranscriptionStyles();
    const [searchString, setSearchString] = React.useState("");
    const [searchResults, setSearchResults] = React.useState<
        ISearchItemInterface[]
    >([]);
    const [resultsOpen, setResultsOpen] = React.useState(false);

    const handleCloseResults = () => {
        setResultsOpen(false); // Close the results container
    };

    const searchAndFilterArrayByText = (
        array: ISearchItemInterface[] | undefined,
        searchText: string,
    ) => {
        if (!array) {
            return [];
        }

        const lowerCaseSearchTerms = searchText.toLowerCase().split(/\s+/);

        const filteredArray = array.filter((item) => {
            const lowerCaseText = item.text?.toLowerCase();
            return lowerCaseSearchTerms.every((term) =>
                lowerCaseText?.includes(term),
            );
        });

        return filteredArray;
    };

    const debouncedSearch = debounce((searchText) => {
        let searchItems: ISearchItemInterface[] = [];
        if (!transcriptionWords) return;

        searchItems = (transcriptionWords as IChatRecord[])?.map((chat) => ({
            text: chat.text ?? "",
            domId: chat.timestamp,
        }));

        const filteredArray = searchAndFilterArrayByText(
            searchItems,
            searchText,
        );
        setResultsOpen(true);
        setSearchResults(filteredArray);
        if (searchText === "") {
            setSearchResults([]);
            setResultsOpen(false);
        }
    }, 350);

    useEffect(() => {
        debouncedSearch(searchString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    return (
        <div className={classes.container}>
            <AcxMainTextField
                id="Transcription-text-search"
                value={searchString}
                placeholderText={"Search within conversation..."}
                onClick={() => setResultsOpen(true)}
                onChange={(e) => {
                    setSearchString(e.target.value);
                }}
                startAdornment={
                    <IconButton size="small">
                        <SearchIcon fontSize="small" />
                    </IconButton>
                }
                endAdornment={
                    searchString !== "" && (
                        <IconButton
                            size="small"
                            onClick={() => setSearchString("")}
                        >
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    )
                }
            />
            {resultsOpen && searchResults.length > 0 ? (
                <div className={classes.resultsContainer}>
                    <Typography variant="h2" gutterBottom={true}>
                        {searchResults?.length} results for '{searchString}'
                    </Typography>
                    {searchResults?.map((searchResult, i) => (
                        <Grid
                            key={i}
                            container
                            item
                            xs={12}
                            onClick={() => {
                                handleCloseResults();
                                if (!searchResult.domId) return;
                                document
                                    .getElementById(searchResult.domId)
                                    ?.scrollIntoView({
                                        behavior: "smooth",
                                    });
                            }}
                        >
                            <li className={classes.resultsItem}>
                                <span
                                    style={{
                                        whiteSpace: "normal",
                                    }}
                                >
                                    {formatTranscriptForHighlight(
                                        searchResult.text,
                                        searchString,
                                        50,
                                    )}
                                </span>
                                <span>
                                    {moment(searchResult.domId).format("HH:mm")}
                                </span>
                            </li>
                        </Grid>
                    ))}
                </div>
            ) : null}
        </div>
    );
};
