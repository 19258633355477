import FocusPage from "Layouts/Focus/FocusPage";
import React from "react";
import { useNavigate } from "react-router-dom";
import UserProfile from "./UserProfile";

export default function UserProfileFocus() {
    const navigate = useNavigate();
    const onCancel = () => {
        navigate(-1);
    };
    return (
        <FocusPage title="Update Profile" onCancel={onCancel}>
            <UserProfile />
        </FocusPage>
    );
}
