import BaseEntity from "models/BaseEntity";
import { WorkflowConditionType } from "../../components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import EvaluationModule from "../EvaluationModule";
import { WorkflowCondition } from "./WorkflowCondition";
import { WorkflowInstanceLevel } from "./WorkflowInstanceLevel";

export enum WorkflowStatus {
    "Open" = 0,
    "Closed" = 1,
    "Overdue" = 2,
}

export class WorkflowInstance extends BaseEntity {
    instanceId: string;
    evaluationId: string;
    workflowInstanceLevels: WorkflowInstanceLevel[];
    currentLevelDueDate?: string;
}

export class AssignedWorkflowInstance extends WorkflowInstance {
    assignedToUser: boolean;
    implementationType: WorkflowConditionType;
    status: WorkflowStatus;
    activeLevel: number;
    conditionName: string;
    question: string;
    questionModuleId: string;
    workflowCondition: WorkflowCondition;
    bossEvalModuleId?: string;
    bossEvalModule?: EvaluationModule;
}
