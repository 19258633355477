import { Box, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { AISpeakerHighlight } from "components/Evaluation/Stores/EvalStore";
import { AIExplanationNoHighlights } from "components/Evaluation/Views/Modules/AIExplanations/AIExplanationNoHighlights";
import AcxToast from "components/UI/AcxToast";
import { observer } from "mobx-react";
import { Answer } from "models/Answer";
import { PredictedTag } from "models/PredictedTag";
import moment from "moment";
import React from "react";
import { useStore } from "utils/useStore";
import { WorkflowReviewStore } from "../Stores/WorkflowReviewStore";

const useStyles = makeStyles((theme: Theme) => ({
    contextContainer: {
        width: "100%",
        padding: "10px",
        gap: "10px",
        backgroundColor: theme.palette.neutralGray.main,
        // borderTop: "1px solid #E5E7EB",
    },
    speakerCard: {
        width: "100%",
        padding: "0px!important",
        boxShadow: "none",
    },
    cardContent: {
        padding: "0px!important",
        backgroundColor: theme.palette.neutralGray.main,
        width: "100%",
    },
    speakerColumn: {
        gap: "10px",
        flexShrink: 0,
        display: "flex",
        paddingRight: "2px",
        justifyContent: "center",
        alignItems: "flex-start",
        maxWidth: "85px",
    },
    highlightRow: {
        minHeight: "82px",
    },
    highlightColumn: {
        padding: "10px 39px 14px 12px",
        gap: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        width: "100%",
    },
    speakerTextContainer: {
        padding: "12px 10px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        maxWidth: "90px",
    },
    speakerName: {
        fontWeight: 700,
        textWrap: "nowrap",
    },
    chip: {
        padding: "4px 2px 4px 2px",
        gap: "4px",
        borderRadius: "4px",
    },
    chipText: {
        fontSize: "12px",
        fontWeight: 600,
        color: "#374151",
    },
    noHighlightTxt: {
        color: theme.palette.neutral[600],
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "center",
    },
    viewHighlightsTxt: {
        color: theme.palette.neutral[600],
        fontSize: "12px",
        textAlign: "center",
    },
}));
interface WFRAIExplanationContextProps {
    tagId: string;
    predictedTags: PredictedTag[];
    answer: Answer;
}

const WFRAIExplanationContext: React.FC<WFRAIExplanationContextProps> =
    observer((props) => {
        const classes = useStyles(props);
        const store = useStore(WorkflowReviewStore);

        const renderHighlights = (
            highlight: AISpeakerHighlight,
            formattedHighlightString: string,
            index,
        ) => {
            return (
                <Grid
                    key={"speaker-highlight" + index + props.tagId}
                    item
                    container
                    xs={12}
                    wrap="nowrap"
                    className={clsx("pendo-ignore", classes.highlightRow)}
                >
                    <Grid
                        container
                        item
                        xs={3}
                        direction="column"
                        alignContent="flex-start"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        className={classes.speakerColumn}
                    >
                        <Grid className={classes.speakerTextContainer}>
                            <Typography
                                className={classes.speakerName}
                                style={{
                                    color: highlight.color,
                                }}
                            >
                                {highlight.speaker}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box
                        style={{
                            width: "2px",
                            height: "82%",
                            backgroundColor: highlight.color,
                            margin: "auto 0",
                        }}
                    />
                    <Grid item xs={9} className={classes.highlightColumn}>
                        <Typography>
                            {" "}
                            <span
                                className={"pendo-ignore"}
                                dangerouslySetInnerHTML={{
                                    __html: formattedHighlightString,
                                }}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            );
        };

        const modifiedPredictorsString = props.predictedTags
            .filter(
                (tag) =>
                    store.structuredHighlights.has(tag.tagId) &&
                    moment(
                        store.structuredHighlights.get(tag.tagId)
                            ?.predictorModifiedOn,
                    ).isAfter(props.answer.modifiedOn),
            )
            .map(
                (tag) =>
                    `"${
                        store.structuredHighlights.get(tag.tagId)!.predictorName
                    }"`,
            )
            .join(" and ");

        const isEmpty =
            !store.structuredHighlights.has(props.tagId) ||
            !store.structuredHighlights.get(props.tagId)?.aiSpeakerHighlights
                ?.length;

        const structuredHighlight = store.structuredHighlights.get(props.tagId);

        return (
            store.authStore.canUserView("Transcription") && (
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    className={classes.contextContainer}
                    wrap="nowrap"
                    key={"context-container" + props.tagId}
                >
                    <Grid
                        className={classes.cardContent}
                        key={"card-content" + props.tagId}
                    >
                        {!!modifiedPredictorsString && (
                            <AcxToast
                                message={`A user changed the definition of ${modifiedPredictorsString} after scoring was complete. The information in the transcription may no longer reflect this score.`}
                                title={""}
                                fullWidth
                                fullWidthMessage
                                severity="infoV2"
                                wrapText
                            />
                        )}
                        {isEmpty && <AIExplanationNoHighlights />}
                        {!isEmpty &&
                            structuredHighlight &&
                            structuredHighlight.aiSpeakerHighlights.map(
                                (highlight, index) => {
                                    let formattedHighlightString: string =
                                        highlight.highlightText
                                            .replaceAll(
                                                "<b>",
                                                `<span style="color:#3564D5;font-weight:700;">`,
                                            )
                                            .replaceAll("</b>", "</span>");

                                    return (
                                        <Grid
                                            container
                                            xs={12}
                                            item
                                            direction="row"
                                            alignContent="flex-start"
                                            className={"pendo-ignore"}
                                            key={
                                                "spkr-highlight-" +
                                                props.tagId +
                                                index
                                            }
                                        >
                                            {renderHighlights(
                                                highlight,
                                                formattedHighlightString,
                                                index,
                                            )}
                                        </Grid>
                                    );
                                },
                            )}
                    </Grid>
                </Grid>
            )
        );
    });
export default WFRAIExplanationContext;
