import {
    Grid,
    IconButton,
    Toolbar,
    Typography,
    Theme,
    Box,
    Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { useState } from "react";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";
import { FileCopyOutlined } from "@mui/icons-material";
import AcxButton from "components/UI/AcxButton";
import {
    ClassifierBuilderV2Store,
    ClassifierBuilderView,
} from "../../Stores/ClassifierBuilderV2Store";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import { useLocation, useNavigate } from "react-router";
import ClassifierBuilderV2CopyDialog from "../Dialogs/ClassifierBuilderV2CopyDialog";
import theme from "Theme/AppTheme";

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        fontSize: "18px",
        fontWeight: theme.typography.fontWeightBold as any,
        color: "black",
        whiteSpace: "nowrap",
    },
    toolbar: {
        borderBottom: "0.5px solid var(--neutral-100, #E5E7EB)",
        backgroundColor: "white",
    },
    chipContainer: {
        maxHeight: "150px",
        overflowY: "auto",
    },
}));

interface Props {
    store: ClassifierBuilderV2Store;
    onBack: () => void;
    onSave: () => void;
    disableSave?: boolean;
    disableCopy?: boolean;
}

const ClassifierBuilderV2Toolbar = observer(
    ({ store, onBack, onSave, disableSave, disableCopy }: Props) => {
        const classes = useStyles();
        const [dialogIsOpen, setDialogIsOpen] = useState(false);
        const [copyDialogIsOpen, setCopyDialogIsOpen] = useState(false);
        const navigate = useNavigate();
        const { pathname } = useLocation();

        const disablePublishOrUnpublish = pathname.includes("create");

        const onPublish = async () => {
            try {
                store.setTestClassifierError("");
                if (store.view === ClassifierBuilderView.Advanced) {
                    await store.validateRbcFtsSearchString();
                }
                if (
                    !store.testClassifierError &&
                    store.isRbcFtsStringValid &&
                    store.selectedClassifier
                ) {
                    store.publishClassifier(store.selectedClassifier.id, true);
                }
            } catch (error) {
                throw error;
            }
        };
        const onUnpublishConfirm = async () => {
            if (store.selectedClassifier) {
                await store.unpublishClassifier(
                    store.selectedClassifier.id,
                    true,
                );
            }
            onUnpublishClose();
        };

        const onUnpublishOpen = () => {
            setDialogIsOpen(true);
        };

        const onUnpublishClose = () => {
            setDialogIsOpen(false);
        };

        const onCopy = async () => {
            if (store.selectedClassifier) {
                await store.copyClassifier({
                    id: store.selectedClassifier.id,
                    onSuccess: () => {
                        setCopyDialogIsOpen(true);
                    },
                });
            } else {
                store.messageStore.logError("Copy failed");
            }
        };

        const onCopyClose = () => {
            setCopyDialogIsOpen(false);
        };

        const onCopyConfirm = () => {
            store.clearCopiedClassifer();
            setCopyDialogIsOpen(false);
        };

        const onCopyCancel = () => {
            if (store.copiedClassifier) {
                navigate(`../edit/${store.copiedClassifier.id}`);
            }
        };

        return (
            <>
                <Toolbar className={classes.toolbar}>
                    <Grid
                        container
                        direction="row"
                        wrap={"nowrap"}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid
                            container
                            item
                            direction="row"
                            wrap={"nowrap"}
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                <IconButton onClick={onBack} size="large">
                                    <ChevronLeftSharpIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.title}>
                                    Classifier Builder
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            direction="row"
                            wrap={"nowrap"}
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item>
                                <Tooltip title="Copy classifier">
                                    <IconButton
                                        disabled={disableCopy}
                                        onClick={onCopy}
                                        size="large"
                                    >
                                        <FileCopyOutlined />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <AcxButton
                                    style={{
                                        marginRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                    }}
                                    variant="outlined"
                                    disabled={
                                        !store.selectedClassifier ||
                                        !store.selectedClassifier
                                            ?.isPublished ||
                                        disablePublishOrUnpublish
                                    }
                                    onClick={onUnpublishOpen}
                                >
                                    Unpublish
                                </AcxButton>
                            </Grid>
                            <Grid item>
                                <AcxButton
                                    style={{
                                        marginRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                    }}
                                    variant="outlined"
                                    disabled={
                                        !store.selectedClassifier ||
                                        store.selectedClassifier?.isPublished ||
                                        disablePublishOrUnpublish
                                    }
                                    onClick={onPublish}
                                >
                                    Publish
                                </AcxButton>
                            </Grid>
                            <Grid item>
                                <AcxButton
                                    style={{
                                        marginRight: theme.spacing(1),
                                        marginLeft: theme.spacing(1),
                                    }}
                                    color="primary"
                                    onClick={onSave}
                                    loading={store.getTaskLoading(
                                        ClassifierBuilderV2Store.Tasks
                                            .UpdateClassifier,
                                    )}
                                    disabled={
                                        disableSave ||
                                        store.standardCBStore.hasError
                                    }
                                >
                                    Save Classifier
                                </AcxButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
                <AcxConfirmationDialog
                    isOpen={dialogIsOpen}
                    onCancel={onUnpublishClose}
                    onClose={onUnpublishClose}
                    onConfirm={onUnpublishConfirm}
                    title={`Are you sure you want to unpublish "${store.selectedClassifier?.name}"?`}
                    fullWidth
                    isLoading={store.isClassifierListLoading}
                    confirmButtonText="Unpublish"
                    content={
                        <Box>
                            <Typography>
                                Unpublishing a classifier will delete all
                                results associated with that classifier.
                            </Typography>
                            <br />
                            <Typography color="error">
                                If you are not the owner, you may lose
                                visibility of this classifier.
                            </Typography>
                        </Box>
                    }
                />
                <ClassifierBuilderV2CopyDialog
                    isOpen={copyDialogIsOpen}
                    onClose={onCopyClose}
                    onConfirm={onCopyConfirm}
                    onCancel={onCopyCancel}
                    classifierName={store.copiedClassifier?.name}
                />
            </>
        );
    },
);

export default ClassifierBuilderV2Toolbar;
