import Topic from "models/Topic";
import TopicRelevantInteraction from "models/TopicRelevantInteraction";
import BaseService from "./BaseService";

export class TopicService extends BaseService {
    protected urlBase: string = "api/Topics";

    public async getUnidentifiedByOrganization() {
        const params = new URLSearchParams({});
        const res = (await this.get(
            params,
            "GetUnidentifiedByOrganization",
        )) as Topic[];
        return res;
    }

    public async getIdentifiedByOrganization() {
        const params = new URLSearchParams({});
        const res = (await this.get(
            params,
            "GetIdentifiedByOrganization",
        )) as Topic[];
        return res;
    }

    public async getRelevantInteractions(topicId: string) {
        const params = new URLSearchParams({ topicId: topicId });
        const res = (await this.get(
            params,
            "GetRelevantInteractions",
        )) as TopicRelevantInteraction[];
        return res;
    }

    public async updateTopicName(topicId: string, name: string) {
        const params = new URLSearchParams({ topicId: topicId, name: name });
        const res = await this.put(params, "UpdateTopicName");
        return res;
    }

    public async getById(topicId: string) {
        const params = new URLSearchParams({ topicId: topicId });
        const res = (await this.get(params, "GetById")) as Topic;
        return res;
    }
}
