import {
    Grid,
    IconButton,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import ClearIcon from "@mui/icons-material/Clear";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import {
    dateTimeColumnType,
    interactionDateTimeColumnType,
} from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { IAcxTableRow } from "components/UI/AcxTable/AcxTable";
import { Observer, observer } from "mobx-react";
import SoundClip from "models/SoundClip";
import { Moment } from "moment";
import React, { ChangeEvent } from "react";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import AcxDateRangeInput from "../../UI/Calendar/DateRange/AcxDateRangeInput";
import { MontageSoundClipStore } from "../Stores/MontageSoundClipStore";
import AcxMicroPlayer from "components/UI/Audio/AcxMicroPlayer";

import { breadCrumbColumnType } from "components/UI/AcxDataGrid/ColumnTypes/AcxGridColTypes";
import {
    ChipComparator,
    TagFormatter,
} from "components/UI/AcxDataGrid/Formatters/TagFormatter";

const styles = (theme: Theme) => {
    return createStyles({
        selectedItemText: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            userSelect: "none",
            fontFamily: theme.typography.fontFamily,
        },
        selectedItemIcon: {
            color: theme.palette.action.disabled,
        },
        selectedItemLabel: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            userSelect: "none",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold as any,
        },
    });
};

const SoundClipList = observer(() => {
    const classes = useStyles(styles);
    const store = useStore(MontageSoundClipStore);
    const theme = useTheme();

    React.useEffect(() => {
        function renderSelectedRows(row: IAcxTableRow) {
            const clip = row as SoundClip;
            return (
                <div key={row.id}>
                    <Grid
                        container
                        direction="row"
                        sx={{
                            paddingRight: theme.spacing(2),
                            paddingLeft: theme.spacing(2),
                            paddingTop: theme.spacing(1.3),
                            paddingBottom: theme.spacing(1.3),
                            minHeight: "45px",
                        }}
                        justifyContent={"space-between"}
                        wrap={"nowrap"}
                        alignItems={"center"}
                    >
                        <Grid
                            container
                            item
                            xs={11}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            spacing={1}
                        >
                            <Grid item>
                                <Typography
                                    className={classes.selectedItemText}
                                >
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            userSelect: "none",
                                            fontFamily:
                                                theme.typography.fontFamily,
                                            fontWeight: theme.typography
                                                .fontWeightBold as any,
                                        }}
                                    >
                                        Id:
                                    </span>{" "}
                                    {clip.evaluationQbId}
                                </Typography>
                            </Grid>

                            <Grid item xs>
                                {clip.tags}
                            </Grid>

                            {clip.note && (
                                <Grid item xs={4}>
                                    <Typography
                                        className={classes.selectedItemText}
                                    >
                                        <span
                                            className={
                                                classes.selectedItemLabel
                                            }
                                        >
                                            Note:
                                        </span>{" "}
                                        {clip.note}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>

                        <Grid item xs={1} container justifyContent={"flex-end"}>
                            <IconButton
                                size="small"
                                className={classes.selectedItemIcon}
                                onClick={() =>
                                    store.soundClipDgStore.removeSelectedByIdWithVisibilityOverride(clip.id)
                                }
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </div>
            );
        }

        const montageReset = (
            <Tooltip title="Clear Montage">
                <IconButton
                    onClick={store.resetAll}
                    style={{
                        marginBottom: "-0.5rem",
                    }}
                    size="large"
                >
                    <ClearAllIcon />
                </IconButton>
            </Tooltip>
        );

        const dateTimeSelector = (
            <Observer>
                {() => (
                    <AcxDateRangeInput
                        labelText={"Select Created On Date Range"}
                        defaultStartDate={store.datePickerStore.beginDate}
                        defaultEndDate={store.datePickerStore.endDate}
                        onSelect={(start: Moment, end: Moment) => {
                            store.datePickerStore.setBeginDate(start);
                            store.datePickerStore.setEndDate(end);
                        }}
                    />
                )}
            </Observer>
        );

        const qbEvalIdSearch = (
            <AcxMainTextField
                key="montage-search-qbevalid"
                labelText="Eval Number"
                id="montage-search-qbevalid"
                value=""
                placeholderText="Search Eval Number.."
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                    (store.qbEvalIdSearch = evt.currentTarget.value)
                }
            />
        );

        const createButton = (
            <Observer>
                {() => (
                    <AcxButton
                        fullWidth
                        key="start-montage"
                        style={{ marginRight: "auto" }}
                        onClick={store.onStartBuildMontage}
                        color="primary"
                        disabled={
                            store.soundClipDgStore.selectedRowIds.length <= 0
                        }
                    >
                        Create Montage
                    </AcxButton>
                )}
            </Observer>
        );

        const columns: IColDef[] = [
            {
                headerName: "Eval Number",
                field: "evaluationQbId",
                width: 50,
            },
            {
                headerName: "Clip Name",
                field: "segmentName",
                flex: 1,
            },
            {
                headerName: "Owner",
                field: "createdBy",
                flex: 1,
            },
            {
                headerName: "Created Date",
                field: "createdOn",
                flex: 1,
                ...dateTimeColumnType,
            },
            {
                headerName: "Interaction Date",
                field: "interactionDate",
                flex: 1,
                ...interactionDateTimeColumnType,
            },
            {
                headerName: "Agent Name",
                field: "agentName",
                flex: 1,
            },
            {
                headerName: "Customer Type",
                field: "customerType",
                flex: 1,
            },
            {
                headerName: "Call Direction",
                field: "callDirection",
                flex: 1,
            },
            {
                headerName: "Service Hierarchy",
                field: "serviceHierarchy",
                width: 300,
                ...breadCrumbColumnType,
                valueGetter: (_, row, column) => {
                    return row.serviceHierarchy?.split(">");
                },
            },
            {
                headerName: "Tags",
                field: "tags",
                width: 150,
                renderCell: TagFormatter(),
                sortComparator: ChipComparator,
            },
            {
                headerName: "Notes",
                field: "note",

                flex: 1,
            },
            {
                headerName: "Transcription Text",
                field: "transcriptionText",

                flex: 1,
            },
            {
                field: "filePath",
                headerName: " ",
                disableColumnMenu: true,
                hideSortIcons: true,
                filterable: false,
                width: 50,
                align: "right",
                renderCell: ({ value }) => {
                    if (value === undefined) {
                        return <></>;
                    }

                    return (
                        <AcxMicroPlayer
                            style={{ marginLeft: "auto", marginRight: "auto" }}
                            getFunction={async () => {
                                return await store.soundClipService.downloadAudioClipAsBlob(
                                    value,
                                );
                            }}
                        />
                    );
                },
            },
        ];

        store.soundClipDgStore.setColumns(columns);

        store.soundClipDgStore.controls = [
            qbEvalIdSearch,
            dateTimeSelector,
            montageReset,
        ];

        store.soundClipDgStore.selectionContextOptions = {
            itemName: "Sound Clip",
            itemNamePluralized: "Sound Clips",
            action: createButton,
            renderSelectedRow: renderSelectedRows,
        };

        store.soundClipDgStore.controlsColumnSpan = 12;
        store.soundClipDgStore.controlsColumnStyle = { marginLeft: "0px" };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        store.datePickerStore.beginDate,
        store.datePickerStore.endDate,
        store.qbEvalIdSearch,
        store.soundClipDgStore,
    ]);

    return (
        <Observer>
            {() => {
                return store.orgId ? (
                    <AcxDataGrid dataGridStore={store.soundClipDgStore} />
                ) : null;
            }}
        </Observer>
    );
});

export default SoundClipList;
