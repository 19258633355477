import BaseService from "./BaseService";

export interface MetaLabels {
    Meta1?: string;
    Meta2?: string;
    Meta3?: string;
    Meta4?: string;
    Meta5?: string;
}

export class MetaLabelService extends BaseService {
    protected urlBase: string = "api/MetaLabels";

    public async getMetaLabels(): Promise<MetaLabels> {
        const params = new URLSearchParams({});
        const response = await this.get(params, "GetMetaLabels");
        return response;
    }
}
