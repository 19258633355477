import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";

@AcxStore
export default class AgentStore extends BaseStore {
    @observable isDrawerOpen: boolean = false;

    constructor(public rootStore: IRootStore) {
        super("AgentStore");
        makeObservable(this);
    }

    @action
    setIsDrawerOpen(open: boolean) {
        this.isDrawerOpen = open;
    }

    @action closeDrawer() {
        this.setIsDrawerOpen(false);
    }

    @action openDrawer() {
        this.setIsDrawerOpen(true);
    }

    @action toggleDrawer() {
        this.isDrawerOpen = !this.isDrawerOpen;
    }
}
