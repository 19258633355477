import React from "react";
import Markdown from "markdown-to-jsx";
import { UserMessage } from "./UserMessage";
import { AgentMessage } from "./AgentMessage";
import { MessageAuthor } from "../Models/MessageAuthor";
import { IThreadMessage } from "../Models/ThreadMessage";
import { Link } from "react-router-dom";
import { styled } from "@mui/material";
import { observer } from "mobx-react";

type Props = {
    author: MessageAuthor;
} & IThreadMessage;

const UlOverride = styled("ul")(({ theme }) => ({
    paddingLeft: theme.spacing(2),
}));

const OlOverride = styled("ol")(({ theme }) => ({
    paddingLeft: theme.spacing(2),
}));

function LinkOverride(props: React.PropsWithChildren<{ href: string }>) {
    return <Link to={props.href}>{props.children}</Link>;
}

const Message: React.FC<Props> = observer((props) => {
    const isUserMessage = props.author === MessageAuthor.User;

    const MessageContent = isUserMessage ? UserMessage : AgentMessage;

    return (
        <MessageContent item className={"pendo-ignore"}>
            {!isUserMessage && (
                <Markdown
                    options={{
                        overrides: {
                            a: LinkOverride,
                            ul: UlOverride,
                            ol: OlOverride,
                        },
                    }}
                >
                    {props.dynamicContent
                        ? props.dynamicContent()
                        : props.content}
                </Markdown>
            )}
            {isUserMessage && (
                <React.Fragment>
                    {props.dynamicContent
                        ? props.dynamicContent()
                        : props.content}
                </React.Fragment>
            )}
        </MessageContent>
    );
});

export default Message;
