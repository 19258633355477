import { Grid } from "@mui/material";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxRecursiveTree from "components/UI/AcxRecursiveTree/AcxRecursiveTree";
import { Observer, observer } from "mobx-react";
import React, { useEffect } from "react";
import { IAcxHierarchyComponentProps } from "./AcxHierarchySelector";
import { HierarchyService } from "services/HierarchyService";

const HierarchySelectorTree: React.FC<IAcxHierarchyComponentProps> = observer(
    (props: IAcxHierarchyComponentProps) => {
        useEffect(() => {
            const service = new HierarchyService();
            const originalTopLevelBranches = props.treeStore.topLevelBranches;
            if (props.viewOnly) {
                props.treeStore.setIsTreeLoading(true);
                service
                    .getTopLevelHierarchiesForUser(
                        false,
                        props.initSelectedHierarchyIds,
                    )
                    .then((hierarchies) => {
                        props.treeStore.setTopLevelBranches(
                            hierarchies.childrenHierarchies,
                        );
                    })
                    .finally(() => props.treeStore.setIsTreeLoading(false));
            }

            return () => {
                if (originalTopLevelBranches)
                    props.treeStore.setTopLevelBranches(
                        originalTopLevelBranches,
                    );
            };
        }, [props.treeStore, props.viewOnly, props.initSelectedHierarchyIds]);

        return (
            <Observer>
                {() => (
                    <Grid
                        item
                        sx={{
                            height: props.fullHeight ? "50vh" : "40vh",
                            overflowY: "scroll",
                            "@media (max-height: 600px)": {
                                height: "100px",
                            },
                        }}
                    >
                        {props.treeStore.isTreeLoading ? (
                            <Grid
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                                sx={(theme) => ({
                                    padding: theme.spacing(2),
                                    height: "100%",
                                })}
                            >
                                <AcxLoadingIndicator
                                    size={66}
                                    alternate="PuffLoader"
                                />
                            </Grid>
                        ) : (
                            <AcxRecursiveTree
                                viewOnly={props.viewOnly}
                                store={props.treeStore}
                            />
                        )}
                    </Grid>
                )}
            </Observer>
        );
    },
);

export default HierarchySelectorTree;
