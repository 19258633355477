import { Typography } from "@mui/material";
import React, { FunctionComponent } from "react";
import ConfirmationDialog from "../UI/AcxConfirmationDialog";

interface OwnProps {
    onClose: () => void;
    open?: boolean;
}

type Props = OwnProps;

const HelpDialog: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const content = (
        <div>
            <Typography variant={"h5"}>Phone Support</Typography>
            <Typography style={{ margin: "0.25rem 0" }}>
                <a href="tel:+13172966034">+1 (317) 296-6034</a>
            </Typography>
            <br />
            <br />
            <Typography variant={"h5"}>Email Support</Typography>
            <Typography style={{ margin: "0.25rem 0" }}>
                <a href="mailto:helpdesk@authenticx.com">
                    HelpDesk@authenticx.com
                </a>
            </Typography>
            <br />
            <br />
            <Typography variant={"h5"}>Portal</Typography>
            <Typography style={{ margin: "0.25rem 0" }}>
                <a
                    href="https://authenticx.atlassian.net/servicedesk/customer/portal"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Service Desk
                </a>
            </Typography>
        </div>
    );

    return (
        <ConfirmationDialog
            onClose={props.onClose}
            title={"Support Options"}
            isOpen={props.open}
            content={content}
            noControls
        />
    );
};

export default HelpDialog;
