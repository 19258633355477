import * as _ from "lodash";
import moment from "moment";
import Evaluation from "../../models/Evaluation";
import SoundClip from "../../models/SoundClip";
import { Tag, TagSentiment } from "../../models/Tag";
import { AssignedWorkflowInstance } from "../../models/Workflows/WorkflowInstance";
import { ITranscriptionSegment } from "../../services/TranscriptionService";
import { serializeToUtc } from "../../utils/DateTimeUtils";
import { AnswerColor } from "../UI/AcxAnswerSelector";
import { GroupedTags } from "./Stores/EvalStore";
import { InteractionType } from "models/InteractionType";

export function matchTranscriptionToSoundCLip(
    soundClip: SoundClip,
    txSegmentModels: ITranscriptionSegment[],
    avgUtterance: number,
) {
    let clipStart = Math.floor(soundClip.startTime ?? 0);
    let clipEnd = Math.ceil(soundClip.endTime ?? 0);

    let firstSegment: number = 0;
    let lastSegment: number = 1;

    function findSegmentStart(value) {
        return Math.floor(value.startTime) >= clipStart;
    }

    function findSegmentEnd(v) {
        return Math.ceil(v.startTime) >= clipEnd;
    }

    if (txSegmentModels.length === 1) {
        firstSegment = 0;
        lastSegment = 1;
    } else {
        const MaxMatchingAttempts = 15;
        for (let i = 0; i < MaxMatchingAttempts; i++) {
            firstSegment = _.findIndex(txSegmentModels, findSegmentStart, 0);

            lastSegment = _.findIndex(txSegmentModels, findSegmentEnd);

            if (
                firstSegment !== -1 &&
                lastSegment !== -1 &&
                lastSegment > firstSegment
            ) {
                break;
            }

            if (
                firstSegment !== -1 &&
                lastSegment !== -1 &&
                firstSegment === lastSegment
            ) {
                clipStart -= 1;
                clipEnd += 1;
            } else if (firstSegment === -1) {
                clipStart -= 1;
            } else if (lastSegment === -1) {
                clipEnd += 1;
            }
        }
    }

    if (firstSegment > -1 && lastSegment <= firstSegment) {
        lastSegment =
            firstSegment +
            (soundClip.endTime - soundClip.startTime) / avgUtterance;
    }

    if (firstSegment === -1 && lastSegment > 1) {
        firstSegment =
            lastSegment -
            (soundClip.endTime - soundClip.startTime) / avgUtterance;
    }

    firstSegment = firstSegment < 0 ? 0 : firstSegment;
    lastSegment =
        lastSegment >= txSegmentModels.length
            ? txSegmentModels.length - 1
            : lastSegment;

    if (firstSegment !== -1 && lastSegment > 0) {
        const startSliceIndex =
            firstSegment > 0 ? firstSegment - 1 : firstSegment;
        const lastSliceIndex = lastSegment + 1;
        const res = txSegmentModels
            .slice(startSliceIndex, lastSliceIndex)
            .map((value) => value.text)
            .join(" ");
        soundClip.setTranscriptionText(res);
    }
}

export function prepareEvalForSerialization(
    currentEval: Evaluation,
    userName?: string,
) {
    // @ts-ignore
    delete currentEval.isEditable;
    // @ts-ignore
    delete currentEval.actionThread;

    // @ts-ignore
    delete currentEval.interaction?.audioMetadata;
    // @ts-ignore
    delete currentEval.interaction?.agent;

    // @ts-ignore
    delete currentEval.interaction?.organizationStructureMember;
    // @ts-ignore
    delete currentEval.interaction?.organization;
    // @ts-ignore
    delete currentEval.interaction?.customerType;
    // @ts-ignore
    delete currentEval.organization;
    // @ts-ignore
    delete currentEval.analyst;

    currentEval.workflowInstances?.forEach((value) => {
        const assignedInstance = value as AssignedWorkflowInstance;
        // @ts-ignore
        delete assignedInstance.assignedToUser;
        // @ts-ignore
        delete assignedInstance.questionModuleId;
        // @ts-ignore
        delete assignedInstance.activeLevel;
        // @ts-ignore
        delete assignedInstance.question;
        // @ts-ignore
        delete assignedInstance.workflowCondition;

        // @ts-ignore
        delete assignedInstance.bossEvalModule;

        value.workflowInstanceLevels.forEach((value1) => {
            // @ts-ignore
            delete value1.workflowMessagingMetadata;
            // @ts-ignore
            delete value1.workflowInstance;
            // @ts-ignore
            delete value1.user;
        });
    });

    currentEval.classifierResultValidations.forEach((value) => {
        // @ts-ignore
        delete value.classifier;
        // @ts-ignore
        delete value.evaluation;
    });

    currentEval.workflowInstances?.forEach((value) => {
        value.workflowInstanceLevels.forEach((value1) => {
            // @ts-ignore
            delete value1.workflowMessagingMetadata;
        });
    });

    currentEval.soundClips?.forEach((value) => {
        delete value._tags;
        value.soundClipAnswers?.forEach((sca) => {
            delete sca.soundClip;
        });
    });

    currentEval.soundClips = currentEval.soundClips?.filter(
        (value) => !value.id.includes("temporary-clip"),
    );

    currentEval.answers.forEach((value) => {
        // @ts-ignore
        delete value.question;
        // @ts-ignore
        delete value.evaluationModule;

        delete value.answerDisputes;

        value.answerTags.forEach((value1) => {
            delete value1.tag;
            delete (value1 as any).classifierResult;
        });

        value.predictedTags.forEach((value1) => {
            delete value1.tag;
            delete (value1 as any).classifierResult;
        });

        value.soundClipAnswers.forEach((value1) => {
            delete value1.soundClip;
        });
    });

    currentEval.soundClips = currentEval.soundClips?.filter((value) =>
        Boolean(value.id),
    );

    currentEval.modifiedOn = serializeToUtc(moment().local());
    currentEval.modifiedBy = userName;
}

export function soundClipDurationDisplay(val: number | string) {
    const dur = moment.duration(val, "s");

    return moment.utc(dur.asMilliseconds()).format("m[m]:ss[s]");
}

export function textClipTimestampDisplay(timestamp: string) {
    return moment(timestamp).format("hh:mm:ss");
}

export function generateClipForEvaluation(
    currentEval: Evaluation,
    clipName: string,
    start: number,
    end: number,
    userName: string | undefined,
) {
    const clip = new SoundClip(undefined, userName, userName);

    const url = `https://${
        currentEval!.interaction!.audioMetadata!.directory!.account
    }.blob.core.windows.net/${
        currentEval!.interaction!.audioMetadata!.blobFileKey
    }`;

    clip.segmentName = clipName;
    clip.mediaType = currentEval!.interaction!.interactionType;
    clip.note = "";
    clip.url = url;
    clip.evaluationId = currentEval!.id;
    clip.tags = "";
    clip.startTime = start;
    clip.endTime = end;
    clip.isActive = true;
    clip.audioMetadataId = currentEval!.interaction!.audioMetadata!.id;

    return clip;
}

export function generateClipForConversation(
    audioMetadataId: string,
    evalId: string | undefined,
    clipName: string,
    start: number,
    end: number,
    userName: string | undefined,
    isChat?: boolean
) {
    const clip = new SoundClip(undefined, userName, userName);

    clip.audioMetadataId = audioMetadataId;
    clip.segmentName = clipName;
    clip.mediaType = isChat ? InteractionType.ProcessedChat : InteractionType.Audio;
    clip.note = "";
    clip.evaluationId = evalId;
    clip.tags = "";
    clip.startTime = start;
    clip.endTime = end;
    clip.isActive = true;

    return clip;
}

export const groupedTagsFromTags = (tags: Tag[], current?: GroupedTags[]) => {
    let groups: GroupedTags[] = [];
    if (current) {
        groups = current;
    }
    tags.forEach((element) => {
        const idx = groups.findIndex((g) => g.label === element.parent?.value);
        if (idx > -1) {
            groups[idx].options.push(element);
        } else {
            groups.push({
                label: element.parent?.value ?? "Other",
                options: [element],
            });
        }
    });
    return groups;
};

export function sentimentToColor(
    tagSentiment: TagSentiment,
    score: number | null,
) {
    if (score == null) {
        return AnswerColor.BLUE;
    }
    switch (tagSentiment) {
        case TagSentiment.Negative:
            return AnswerColor.RED;
        case TagSentiment.Neutral:
            return AnswerColor.YELLOW;
        case TagSentiment.Positive:
            return AnswerColor.GREEN;
    }
}
