import { Grid, IconButton, Paper, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import { MediaSegments } from "models/OrganizationModels/OrganizationMetadataConfiguration";
import React, { FunctionComponent } from "react";
import useStyles from "Styles/Styles";
import {
    LOAD_CONFIG,
    OrganizationMetadataStore,
} from "./Store/OrganizationMetadataStore";

const styles = () =>
    createStyles({
        fieldSpacing: {
            margin: "0.25rem 0",
            padding: "0 0 0 0.125rem",
        },
        metadataContainer: {
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        inputSpacing: { paddingLeft: ".125rem" },
    });

interface MetadataMediaSegmentProps {
    configIndex: number;
    mediaSegments: MediaSegments;
    store: OrganizationMetadataStore;
}

const MetadataMediaSegments: FunctionComponent<MetadataMediaSegmentProps> =
    observer(({ mediaSegments, store, configIndex }) => {
        const classes = useStyles(styles);

        const onMediaSegmentsValueChange = React.useCallback(
            (input: string, key: "start" | "agentName" | "agentId") => {
                store.handleMediaSegmentsUpdates(configIndex, input, key);
            },
            [store, configIndex],
        );

        const onMediaSegmentsEndValueChange = React.useCallback(
            (input: string, key: "primary" | "alternate") => {
                store.handleMediaSegmentsEndUpdates(configIndex, input, key);
            },
            [store, configIndex],
        );

        const onMediaSegmentsRootValueChange = React.useCallback(
            (input: string) => {
                store.handleMediaSegmentsRootPathUpdates(configIndex, input);
            },
            [store, configIndex],
        );

        return <>
            <Grid container item xs={12} className={classes.fieldSpacing}>
                <Grid item xs={12}>
                    <AcxMainTextField
                        fullWidth
                        skeleton={store.getTaskLoading(LOAD_CONFIG)}
                        labelText="Root Path"
                        id="rootPath"
                        value={mediaSegments?.rootPath ?? ""}
                        onChange={(event) => {
                            onMediaSegmentsRootValueChange(
                                event.target.value,
                            );
                        }}
                        showAllErrors={true}
                        error={store.configErrors[configIndex]?.includes(
                            "rootPath",
                        )}
                        helperText={
                            store.configErrors[configIndex]?.includes(
                                "rootPath",
                            ) && "This field is required"
                        }
                        required
                    />
                </Grid>
            </Grid>
            <Paper className={classes.metadataContainer}>
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.fieldSpacing}
                >
                    <Grid item container xs={12}>
                        <Typography variant="h5" color="textSecondary">
                            Start
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.inputSpacing}>
                        <AcxMainTextField
                            fullWidth
                            skeleton={store.getTaskLoading(LOAD_CONFIG)}
                            labelText="Value Path"
                            id="startValuePath"
                            value={mediaSegments?.start?.valuePath ?? ""}
                            onChange={(event) => {
                                onMediaSegmentsValueChange(
                                    event.target.value,
                                    "start",
                                );
                            }}
                            showAllErrors={true}
                            error={store.configErrors[
                                configIndex
                            ]?.includes("start.valuePath")}
                            helperText={
                                store.configErrors[configIndex]?.includes(
                                    "start.valuePath",
                                ) && "This field is required"
                            }
                            required
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.metadataContainer}>
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.fieldSpacing}
                >
                    <Grid item container xs={12}>
                        <Typography variant="h5" color="textSecondary">
                            End
                        </Typography>
                    </Grid>

                    <Grid item xs={6} className={classes.inputSpacing}>
                        <AcxMainTextField
                            fullWidth
                            skeleton={store.getTaskLoading(LOAD_CONFIG)}
                            labelText="Primary Value Path"
                            id="endPrimValuePath"
                            value={
                                mediaSegments?.end?.primary.valuePath ?? ""
                            }
                            onChange={(event) => {
                                onMediaSegmentsEndValueChange(
                                    event.target.value,
                                    "primary",
                                );
                            }}
                            showAllErrors={true}
                            error={store.configErrors[
                                configIndex
                            ]?.includes("end.primary.valuePath")}
                            helperText={
                                store.configErrors[configIndex]?.includes(
                                    "end.primary.valuePath",
                                ) && "This field is required"
                            }
                            required
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.inputSpacing}>
                        <AcxMainTextField
                            fullWidth
                            skeleton={store.getTaskLoading(LOAD_CONFIG)}
                            labelText="Alternate Value Path"
                            id="endAltValuePath"
                            value={
                                mediaSegments?.end?.alternate.valuePath ?? ""
                            }
                            onChange={(event) => {
                                onMediaSegmentsEndValueChange(
                                    event.target.value,
                                    "alternate",
                                );
                            }}
                            showAllErrors={true}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper className={classes.metadataContainer}>
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.fieldSpacing}
                >
                    <Grid item container xs={12}>
                        <Typography variant="h5" color="textSecondary">
                            Agent
                        </Typography>
                    </Grid>

                    <Grid item xs={6} className={classes.inputSpacing}>
                        <AcxMainTextField
                            fullWidth
                            skeleton={store.getTaskLoading(LOAD_CONFIG)}
                            labelText="Name Value Path"
                            id="agentNameValuePath"
                            value={mediaSegments?.agentName?.valuePath ?? ""}
                            onChange={(event) => {
                                onMediaSegmentsValueChange(
                                    event.target.value,
                                    "agentName",
                                );
                            }}
                            showAllErrors={true}
                            error={store.configErrors[
                                configIndex
                            ]?.includes("agentName.valuePath")}
                            helperText={
                                store.configErrors[configIndex]?.includes(
                                    "agentName.valuePath",
                                ) && "This field is required"
                            }
                            required
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.inputSpacing}>
                        <AcxMainTextField
                            fullWidth
                            skeleton={store.getTaskLoading(LOAD_CONFIG)}
                            labelText="Id Value Path"
                            id="agentIdValuePath"
                            value={mediaSegments?.agentId?.valuePath ?? ""}
                            onChange={(event) => {
                                onMediaSegmentsValueChange(
                                    event.target.value,
                                    "agentId",
                                );
                            }}
                            showAllErrors={true}
                            error={store.configErrors[
                                configIndex
                            ]?.includes("agentId.valuePath")}
                            helperText={
                                store.configErrors[configIndex]?.includes(
                                    "agentId.valuePath",
                                ) && "This field is required"
                            }
                            required
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Grid item container xs={12} justifyContent="flex-end">
                <IconButton
                    onClick={() =>
                        store.handleRemoveMediaSegments(configIndex)
                    }
                    size="large">
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </>;
    });

export default MetadataMediaSegments;
