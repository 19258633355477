import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React from "react";
import AlertSvg from "../../../../SvgIcons/AlertSvg";
import { EvalLicensedModule } from "components/Evaluation/Stores/EvalStore";

const useStyles = makeStyles<Theme>((theme) => ({
    infoSVG: {
        marginRight: theme.spacing(2.7),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        color: "red",
    },
}));

interface DiputedMessageProps {
    module?: EvalLicensedModule;
}

const WFRDisputedMessage: React.FC<DiputedMessageProps> = observer((props) => {
    const classes = useStyles(props);
    let message = "";

    return (
        <>
            {props.module?.hasDisputedAnswers && (
                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                    }}
                    container
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        style={{
                            border: "1px solid red",
                            borderRadius: "5px",
                            backgroundColor: "#FEEEEE",
                            paddingLeft: "5px",
                            marginBottom: "24px",
                        }}
                        container
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        wrap={"nowrap"}
                    >
                        <Grid item className={classes.infoSVG}>
                            <AlertSvg color="red" opacity={0.75} />
                        </Grid>
                        <Grid item>
                            <Typography
                                sx={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    letterSpacing: "0",
                                    lineHeight: "20px",
                                }}
                            >
                                {message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
});

export default WFRDisputedMessage;
