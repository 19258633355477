import { action, computed, observable, makeObservable } from "mobx";
import { TabTableData } from "../AcxTabTable";

export class TabTableViewModel {
    @observable tabTableData: TabTableData<any>[];

    @observable currentTabIndex: number = 0;
    constructor(tabTableData: TabTableData<any>[]) {
        makeObservable(this);
        this.tabTableData = tabTableData;
        this.onChangeTab(0);
    }

    @action
    setTabData(tabTableData: TabTableData<any>[]) {
        this.tabTableData = tabTableData;
    }

    @computed
    get tableProps() {
        return {
            enableCheck: this.enableCheck,
            selectedItems: this.selectedItemIds,
            onSelecteditems: this.tableComponentStore.setSelectedItems,
            disabledItems: this.disabledItemIds,
            rows: this.tableRows ?? [],
            columns: this.tableColumns,
        };
    }

    @computed
    get enableCheck() {
        return this.tabTableData[this.currentTabIndex].enableCheck;
    }

    @computed
    get tableComponentStore() {
        return this.tabTableData[this.currentTabIndex].tableComponentStore;
    }

    @computed
    get selectedItemIds() {
        return this.tabTableData[this.currentTabIndex].tableComponentStore
            .selectedItemIds;
    }

    @computed
    get disabledItemIds() {
        return this.tabTableData[this.currentTabIndex].tableComponentStore
            .disabledItemIds;
    }

    @computed
    get tableRows() {
        return this.tabTableData[this.currentTabIndex].tableComponentStore
            .filteredItems;
    }

    @computed
    get tableColumns() {
        return this.tabTableData[this.currentTabIndex].columns;
    }

    @action
    onChangeTab = (index: number) => {
        this.currentTabIndex = index;
    };
}
