import { AddSharp } from "@mui/icons-material";
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AcxButton from "components/UI/AcxButton";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { UserTenantAuthorizationStore } from "./UserTenantAuthorizationStore";
import theme from "Theme/AppTheme";

const styles = (theme: Theme) => {
    return createStyles({
        formContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
        header: {
            fontWeight: "bold",
            paddingLeft: "0.25rem",
        },
    });
};

const UserTenantAuthorizations = observer(({ userId }) => {
    const classes = useStyles(styles);

    const tenantAuthorizationStore = useStore(UserTenantAuthorizationStore);
    const authStore = useStore(AuthStore);

    const { organizations } = useStore(AuthStore).orgStore;
    const [selectedOrgId, setSelectedOrgId] = useState("");
    const [dropdownOptions, setDropDownOptions] = useState<Array<any>>([]);

    useEffect(() => {
        tenantAuthorizationStore.userId = userId;
    }, [tenantAuthorizationStore, tenantAuthorizationStore.userId, userId]);

    useEffect(() => {
        setDropDownOptions(
            organizations?.filter((org) => {
                const taOrgIds =
                    tenantAuthorizationStore?.usersTenantAuthorizations?.map(
                        (ta) => ta.orgId,
                    ) || [];
                return !taOrgIds.includes(org.id);
            }) || [],
        );
    }, [
        organizations,
        tenantAuthorizationStore,
        tenantAuthorizationStore.usersTenantAuthorizations,
    ]);

    return (
        <Paper elevation={1} className={classes.formContainer}>
            <Grid container spacing={2}>
                <Grid container item xs={6}>
                    <Typography variant="h5">User Access to Tenants</Typography>
                </Grid>

                <Grid container item xs={3}>
                    <AcxSelectSingle
                        id="tenantAuthorizations"
                        options={dropdownOptions}
                        valueField="id"
                        labelField="name"
                        onChange={(e) => {
                            setSelectedOrgId(e.id || "");
                        }}
                    />
                </Grid>

                <Grid container item xs={3}>
                    <AcxButton
                        fullWidth
                        disabled={
                            !authStore.canUserEdit("Tenant Authorizations")
                        }
                        color="primary"
                        style={{
                            marginRight: theme.spacing(1.5),
                            marginLeft: theme.spacing(1.5),
                            padding: "1rem",
                            minWidth: "max-content",
                        }}
                        startIcon={<AddSharp />}
                        onClick={() =>
                            tenantAuthorizationStore.onTenantAuthorizationCreate(
                                selectedOrgId,
                                userId,
                            )
                        }
                    >
                        Add
                    </AcxButton>
                </Grid>

                <Table
                    size="small"
                    aria-label="a dense table"
                    style={{ marginTop: "1rem" }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.header}>
                                Organization Name
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tenantAuthorizationStore.usersTenantAuthorizations
                            ?.length ? (
                            tenantAuthorizationStore.usersTenantAuthorizations?.map(
                                (ta) => (
                                    <TableRow key={ta.id}>
                                        <TableCell
                                            style={{ paddingLeft: "0.25rem" }}
                                        >
                                            {ta.orgName}
                                        </TableCell>
                                        <TableCell align="right" width="20">
                                            <AcxButton
                                                fullWidth
                                                color="primary"
                                                onClick={() => {
                                                    tenantAuthorizationStore.onTenantAuthorizationDelete(
                                                        ta.id,
                                                    );
                                                }}
                                            >
                                                Delete
                                            </AcxButton>
                                        </TableCell>
                                    </TableRow>
                                ),
                            )
                        ) : (
                            <TableRow>
                                <TableCell style={{ paddingLeft: "0.25rem" }}>
                                    No Tenant Authorizations for this User
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Grid>
        </Paper>
    );
});

export default UserTenantAuthorizations;
