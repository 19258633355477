import { NotificationArgument } from "./MessageStoreBase";
import { TaskUpdate } from "./TaskStore";

export function metaTransformer(arg: TaskUpdate) {
    const a: NotificationArgument = {
        id: arg.taskId,
        title: "Metadata Info",
        message: arg.message,
        timestamp: arg.timestamp?.toString(),
        type: arg.notificationType,
    };
    return a;
}
