import React from "react";
import { IconButton } from "@mui/material";
import { Stack } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import theme from "Theme/AppTheme";

const DefaultArrowHeightWidth = theme.spacing(1.5);

interface RefineClipIncrementDecrementArrowsProps {
    onIncrement: () => void;
    onDecrement: () => void;
    style?: React.CSSProperties;
    buttonHeight?: string;
    buttonWidth?: string;
}

const IncrementDecrement: React.FC<RefineClipIncrementDecrementArrowsProps> = ({
    onIncrement,
    onDecrement,
    style,
    buttonHeight = DefaultArrowHeightWidth,
    buttonWidth = DefaultArrowHeightWidth,
}) => (
    <Stack style={style}>
        <IconButton
            size="small"
            style={{
                height: buttonHeight,
                width: buttonWidth,
                cursor: "default",
            }}
            onClick={onIncrement}
            color="secondary"
        >
            <KeyboardArrowUpIcon fontSize="small" />
        </IconButton>
        <IconButton
            size="small"
            style={{
                height: buttonHeight,
                width: buttonWidth,
                cursor: "default",
            }}
            onClick={onDecrement}
            color="secondary"
        >
            <KeyboardArrowDownIcon fontSize="small" />
        </IconButton>
    </Stack>
);

export default IncrementDecrement;
