export enum AppDomains {
    MyDashboard = "MyDashboard",
    MyEvaluations = "MyEvaluations",
    MontageLibrary = "MontageLibrary",
    Interactions = "Interactions",
    Agents = "Agents",
    Reports = "Reports",
    ReportsRv2 = "V2",
    Upload = "Upload",
    Nlp = "Nlp",
    Classifiers = "Classifiers",
    Administrative = "Administrative",
    TranscribeSoundClips = "TranscribeSoundClips",
    AgentCoaching = "AgentCoaching",
    Ultra = "Ultra",
    Signals = "Signals",
    ActionPermissions = "ActionPermissions",
    Workflows = "Workflows",
    WorkflowModules = "WorkflowModules",
}
