import { copyBaseFields } from "../../utils/BaseEntityUtils";
import BaseEntity from "../BaseEntity";

export class ReportDataField extends BaseEntity {
    displayName: string;
    fieldName: string;
    datatype: ReportFieldDataTypes;
    reportDataViewId: string;
    organizationId?: string;
    variation: ReportDataFieldVariation;
    value: string;
    virtualFieldType: string;

    static fromJson(json: ReportDataField) {
        const clz = new ReportDataField(
            json.id,
            json.createdBy,
            json.modifiedBy,
            json.createdOn,
            json.modifiedOn,
        );
        copyBaseFields(json, clz);
        clz.displayName = json.displayName;
        clz.fieldName = json.fieldName;
        clz.datatype = json.datatype;
        clz.reportDataViewId = json.reportDataViewId;
        clz.organizationId = json.organizationId;
        clz.variation = json.variation;
        clz.value = json.value;
        clz.virtualFieldType = json.virtualFieldType;

        return clz;
    }
}

export enum ReportDataFieldVariation {
    Concrete,
    Virtual,
}

export enum ReportFieldDataTypes {
    Text = 0,
    Number = 1,
    Categorical = 2,
    Date = 3,
    Guid = 4,
    Hierarchy = 5,
    CategoricalText = 6,
    Percentage = 7,
}
