import { debounce } from "lodash";
import { action, computed, observable, makeObservable } from "mobx";
import BaseEntity from "models/BaseEntity";

export class TableComponentStore<T extends BaseEntity> {
    @observable.ref itemFilter: (arg0: T, arg1: string) => boolean;
    @observable items: T[] = [];
    @observable selectedItems: T[] = [];
    @observable disabledItems: T[] = [];
    @observable searchString: string;

    @observable pageIndex = 0;
    @observable rowsPerPage = 25;

    constructor(
        itemFilter?: (arg0: T, arg1?: string) => boolean,
        private usePaging?: boolean,
    ) {
        makeObservable(this);
        this.itemFilter = itemFilter ?? ((arg0) => true);
    }

    @action
    reset() {
        this.items = [];
        this.selectedItems = [];
        this.disabledItems = [];
        this.searchString = "";
        this.pageIndex = 0;
        this.rowsPerPage = 25;
        return this;
    }

    @action
    setItems = (newItems: T[]) => {
        this.items = newItems ?? [];
    };

    @action
    setSelectedItems = (items: any[]) => {
        if (items.length === 0) {
            this.selectedItems.splice(0, this.selectedItems.length);
        } else {
            const removeThese = this.selectedItems.filter(
                (v) => !items.includes(v.id),
            );
            for (let rm of removeThese) {
                const indx = this.selectedItems.findIndex(
                    (value) => value.id === rm.id,
                );
                if (indx > -1) {
                    this.selectedItems.splice(indx, 1);
                }
            }

            for (let i = 0; i < items.length; i++) {
                const foundItem = this.items.find((f) => f.id === items[i]);
                if (!foundItem) {
                    continue;
                }
                const copy: T = { ...foundItem } as T;
                if (
                    this.selectedItems.findIndex((sc) => sc.id === items[i]) ===
                    -1
                ) {
                    this.selectedItems.push(copy);
                }
            }
        }
    };

    @action
    setDisabledItems = (items: T[]) => {
        this.disabledItems = items;
    };

    @action
    clearSelectedItems() {
        this.selectedItems = [];
    }

    @action
    setSearchString = debounce((newSearchString: string) => {
        this.searchString = newSearchString;
    }, 500);

    @computed get filteredItems() {
        const filtered: T[] = [];
        for (const item of this.items ?? []) {
            if (this.itemFilter(item, this.searchString)) {
                filtered.push(item);
            }
        }

        return this.usePaging
            ? filtered.slice(
                  this.pageIndex * this.rowsPerPage,
                  this.pageIndex * this.rowsPerPage + this.rowsPerPage,
              )
            : filtered;
    }

    @computed get selectedItemIds() {
        return this.selectedItems?.map((x) => x.id) ?? [];
    }

    @computed get disabledItemIds() {
        return this.disabledItems.map((x) => x.id) ?? [];
    }

    @computed
    get totalRows() {
        return this.items.length;
    }

    @computed
    get totalSelectedRows() {
        return this.selectedItems.length;
    }

    @action
    onPageChange = (pageIndex: number) => {
        this.pageIndex = pageIndex;
    };

    @action
    onRowsPerPageChange = (rowCount: number) => {
        this.rowsPerPage = rowCount;
    };
}
