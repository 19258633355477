import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { dateColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { WorkflowService } from "services/WorkflowService";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import { injectHtml } from "../Components/InjectHtml";
import {
    WorkflowTemplate,
    WorkflowTemplateDeliveryCadence,
} from "../Types/WorkflowModels";

export const EMAIL_TEMPLATE_DELIVERY_CADENCES: {
    label: string;
    value: number;
}[] = EnumToDropdownOptionConversion(WorkflowTemplateDeliveryCadence);

export const LOAD_EMAILS = "Load Organization Workflow Emails";
export const CREATE_EMAIL = "Create Organization Workflow Email";
export const UPDATE_EMAIL = "Update Organization Workflow Email";
export const DELETE_EMAIL = "Delete Organization Workflow Email";
const templateNameMatchings: string[] = ["daily", "monthly", "weekly"];

@AcxStore
export default class OrganizationWorkflowEmailStore extends BaseStore {
    readonly workflowService: WorkflowService;
    readonly dataGridStore: AcxDataGridStore;
    readonly messageStore: MessageStore;
    @observable.ref
    workflowEmails: WorkflowTemplate[];

    @observable.ref
    theme: string = "snow";

    @observable.ref
    placeholder: string = "Type here...";

    @observable.ref
    formats: string[] = [
        "bold",
        "italic",
        "underline",
        "strike",
        "code",
        "align",
        "list",
        "indent",
        "size",
        "header",
        "link",
        "image",
        "video",
        "color",
        "background",
        "clean",
    ];

    @observable.ref
    modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike", "code"],
            [{ align: [] }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["link", "image", "video"],
            [{ color: [] }, { background: [] }],
            ["clean"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };

    @observable
    organizationId?: string;

    @observable
    emailTemplateId?: string;

    @observable
    emailEditorIsOpen: boolean = false;

    @observable
    body: string = "";

    @observable
    bodyText: string = "";

    @observable
    subject: string = "";

    @observable
    templateName: string = "";

    @observable
    templateNameMatchCase: boolean = false;

    constructor(private rootStore: IRootStore) {
        super("OrganizationWorkflowEmailStore");
        makeObservable(this);

        this.dataGridStore = new AcxDataGridStore(
            "workflowConditionsTable",
            "Workflows",
        );

        this.messageStore = this.rootStore.getStore(MessageStore);
        this.workflowService = new WorkflowService();

        reaction(
            (r) => ({
                storeError: this.nextTaskError,
            }),
            (arg) => {
                if (arg && arg.storeError?.message) {
                    const msg = arg.storeError?.message;
                    this.messageStore.logError(msg);
                    this.clearLastTaskError();
                }
            },
            { delay: 0 },
        );

        reaction(
            (r) =>
                this.getTaskLoading(LOAD_EMAILS) ||
                this.getTaskLoading(DELETE_EMAIL),
            (isLoading) => {
                if (isLoading) {
                    this.dataGridStore.setLoading(true);
                }
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.organizationId,
            (organizationId) => {
                if (organizationId) {
                    this.getWorkflowEmails(organizationId);
                }
            },
            { fireImmediately: true },
        );
    }

    @action
    setOrganizationId = (organizationId: string) => {
        this.organizationId = organizationId;
    };

    @action
    setEmailEditorIsOpen = (isOpen: boolean) => {
        this.emailEditorIsOpen = isOpen;

        if (!isOpen) {
            this.resetEmailStore();
        }
    };

    @action
    handleTemplateNameChange = (event) => {
        this.templateName = event.target.value;
        this.templateNameMatchCase = false;
        if (
            templateNameMatchings.some((matchingName) =>
                this.templateName.toLowerCase().includes(matchingName),
            )
        ) {
            this.templateNameMatchCase = true;
        }
    };

    @action
    handleSubjectChange = (event) => {
        this.subject = event.target.value;
    };

    @action
    editWorkflowEmailTemplate = () => {
        this.setEmailEditorIsOpen(true);

        const selectedRow = this.dataGridStore.selectedRows[0];
        this.templateName = selectedRow.templateName;
        this.subject = selectedRow.subject;
        this.body = selectedRow.body;
        this.bodyText = selectedRow.bodyText;
        this.emailTemplateId = selectedRow.id;
    };

    @action
    initializeDataGrid = () => {
        this.dataGridStore.reset();

        const columns: IColDef[] = [
            {
                headerName: "Template Name",
                field: "templateName",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Subject",
                field: "subject",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Body",
                field: "bodyText",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Created On",
                field: "createdOn",
                flex: 1,
                ...dateColumnType,
            },
            {
                headerName: "Created By",
                field: "createdBy",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Modified On",
                field: "modifiedOn",
                flex: 1,
                ...dateColumnType,
            },
        ];

        this.dataGridStore.setColumns(columns);
    };

    @action
    resetEmailStore = () => {
        this.bodyText = "";
        this.body = "";
        this.subject = "";
        this.templateName = "";
        this.emailTemplateId = undefined;
    };

    @computed
    get isEditDisabled() {
        return this.dataGridStore.selectedRows.length !== 1;
    }

    public getWorkflowEmails = (orgId: string) => {
        this.setupAsyncTask(LOAD_EMAILS, async () => {
            this.workflowEmails = await this.workflowService.getWorkflowEmails(
                orgId,
            );

            this.initializeDataGrid();
            this.dataGridStore.rows = this.workflowEmails;
            this.dataGridStore.setLoading(false);
        });
    };

    public getBodyFromQuill = (quill) => {
        runInAction(() => {
            this.bodyText = this.body = quill.editor.isBlank()
                ? ""
                : quill.root.innerHTML;

            this.body = quill.editor.isBlank()
                ? ""
                : injectHtml(quill.root.innerHTML);
        });
    };

    private removeLeftoverStyles = (bodyText: string) => {
        const dom = document.createElement("p");
        dom.innerHTML = bodyText;

        const allParagraphElements = dom.getElementsByTagName("p");

        for (let i = 0; i < allParagraphElements.length; i++) {
            const hasSpanChild = allParagraphElements[i].querySelector("span");

            if (hasSpanChild && hasSpanChild?.getAttribute("style")) {
                const containsEvalString =
                    hasSpanChild.innerHTML.trim() === "Link to Evaluation";

                if (containsEvalString) {
                    allParagraphElements[i].innerHTML = hasSpanChild.innerHTML;
                }
            }
        }

        for (let k = 0; k < allParagraphElements.length; k++) {
            const hasLinkChild = allParagraphElements[k].querySelector("a");
            if (hasLinkChild && hasLinkChild?.getAttribute("style")) {
                const containsEmptyString =
                    hasLinkChild.innerHTML.trim() === "";

                if (containsEmptyString) {
                    allParagraphElements[k].innerHTML = hasLinkChild.innerHTML;
                }
            }
        }

        return dom.innerHTML;
    };

    // This functions as a Create and an Update based upon {{ emailTemplateId }}
    public saveEmailTemplate = (quill: any) => {
        if (quill && this.organizationId) {
            this.getBodyFromQuill(quill);

            if (!this.emailTemplateId) {
                // Create
                this.setupAsyncTask(CREATE_EMAIL, async () => {
                    await this.workflowService.createEmailTemplate(
                        this.body,
                        this.removeLeftoverStyles(this.bodyText),
                        this.subject,
                        this.templateName,
                        this.organizationId as string,
                    );

                    this.messageStore.logMessage(
                        `${this.templateName} has been successfully created.`,
                        "success",
                    );

                    this.getWorkflowEmails(this.organizationId as string);

                    this.resetEmailStore();

                    runInAction(() => {
                        this.emailEditorIsOpen = false;
                    });
                });
            } else {
                // Update
                this.setupAsyncTask(UPDATE_EMAIL, async () => {
                    await this.workflowService.updateEmailTemplate(
                        this.emailTemplateId as string,
                        this.body,
                        this.removeLeftoverStyles(this.bodyText),
                        this.subject,
                        this.templateName,
                        this.organizationId as string,
                    );

                    this.messageStore.logMessage(
                        `${this.templateName} has been successfully updated.`,
                        "success",
                    );

                    this.getWorkflowEmails(this.organizationId as string);

                    this.resetEmailStore();

                    runInAction(() => {
                        this.emailEditorIsOpen = false;
                    });
                });
            }
        }
    };

    public deleteWorkflowEmailTemplate = () => {
        const templateId = this.dataGridStore.selectedRows[0].id;

        if (templateId) {
            this.setupAsyncTask(DELETE_EMAIL, async () => {
                await this.workflowService.deleteEmailTemplate(templateId);

                this.getWorkflowEmails(this.organizationId as string);
            }).then(() => {
                this.dataGridStore.setLoading(false);
            });
        }
    };
}
