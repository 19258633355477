import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { breadCrumbColumnType } from "components/UI/AcxDataGrid/ColumnTypes/AcxGridColTypes";
import { dateTimeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

@AcxStore
export default class AcxTestFilterDgStore extends BaseStore {
    @observable
    testDgStore: AcxDataGridStore;

    constructor(public rootStore: IRootStore) {
        super("HierarchyDetailsFabStore");
        makeObservable(this);

        this.testDgStore = new AcxDataGridStore();
        this.testDgStore.filterMode = "server";
        console.log(this.testDgStore.filterMode);

        const columns: IColDef[] = [
            {
                headerName: "Eval Number",
                field: "evaluationQbId",
                // renderType: "longString",
                width: 50,
            },
            {
                headerName: "Owner",
                field: "createdBy",
                // renderType: "longString",
                flex: 1,
            },
            {
                headerName: "Created Date",
                field: "createdOn",
                flex: 1,
                ...dateTimeColumnType,
            },
            {
                headerName: "Agent Name",
                field: "agentName",
                // renderType: "longString",
                flex: 1,
            },
            {
                headerName: "Customer Type",
                field: "customerType",
                // renderType: "longString",
                flex: 1,
            },
            {
                headerName: "Call Direction",
                field: "callDirection",
                // renderType: "longString",
                flex: 1,
            },
            {
                headerName: "Service Hierarchy",
                field: "serviceHierarchy",
                width: 300,
                ...breadCrumbColumnType,
            },
            {
                headerName: "Tags",
                field: "tags",
                // renderType: {
                // type: "chip",
                // fields: { delimiter: ",,", sortOn: "tags" },
                // },
                width: 150,
                // renderCell: new AcxFormatters().tagFormatter(),
                // sortComparator: new AcxFormatters().chipComparator,
            },
            {
                headerName: "Notes",
                field: "note",
                // renderType: "longString",
                flex: 1,
            },
            {
                headerName: "Transcription Text",
                field: "transcriptionText",
                // renderType: "longString",
                flex: 1,
            },
            {
                field: "filePath",
                headerName: " ",
                // renderType: "playerFormatter",
                disableColumnMenu: true,
                hideSortIcons: true,
                filterable: false,
                width: 50,
                align: "right",
                // disableClickEventBubbling: true,
            },
        ];

        this.testDgStore.setColumns(columns);
        this.testDgStore.rows = [
            {
                createdOn: "2023-03-01T18:41:23.105104",
                createdBy: "julie.gallina@authenticx.com",
                evaluationQbId: 51029230,
                note: null,
                segmentName: "Clip 0m:17s - 0m:27s",
                transcriptionText:
                    "number. Is that considered the member id? Hello? To",
                endTime: 27.4222119140625,
                startTime: 17.6967509765625,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                blobContainer: "authcx",
                id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
                agentName: "Liz Leija",
                customerType: null,
                callDirection: "Inbound",
                serviceHierarchy: ["Smart Predict", null, null, null, null],
            },
            {
                createdOn: "2023-03-01T19:27:42.720127",
                createdBy: "hemalatha.malluvalasa@authcx.com",
                evaluationQbId: 51029252,
                note: null,
                segmentName: "Clip 0m:31s - 0m:40s",
                transcriptionText:
                    "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
                endTime: 40.299999899999996,
                startTime: 31.686563,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                blobContainer: "authcx",
                id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
                agentName: "Sydnee Cobbs",
                customerType: "Physician",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T20:50:20.6596",
                createdBy: "michelle.hey@authenticx.com",
                evaluationQbId: 51029228,
                note: null,
                segmentName: "Clip 0m:05s - 0m:06s",
                transcriptionText:
                    "have the member Id number that you 're calling about? Hi.",
                endTime: 6,
                startTime: 5,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                blobContainer: "authcx",
                id: "ee3153f1-4e4d-4afa-a923-ab3ce596de20",
                agentName: "Liz Leija",
                customerType: "Patient",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "Agent Dispute",
                    "Quality Assurance",
                    "Testing",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2022-12-23T18:46:59.424308",
                createdBy: "bryce.frase@authenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 4m:10s - 5m:06s",
                transcriptionText: null,
                endTime: 306.75918375651,
                startTime: 250.294650227865,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                blobContainer: "authcx",
                id: "ed2cf3c5-9961-46e2-8432-133d723f5093",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2021-05-05T20:39:47.718000",
                createdBy: "emir.sabljakovic@beauthenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 0m:02s - 9m:15s",
                transcriptionText: null,
                endTime: 2.80377835591634,
                startTime: 555.83,
                tags: "",
                url: "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                filePath:
                    "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                blobContainer: "securityllc",
                id: "8f7eb25b-33b2-4800-8382-8a80e12a2f84",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T18:41:23.105104",
                createdBy: "julie.gallina@authenticx.com",
                evaluationQbId: 51029230,
                note: null,
                segmentName: "Clip 0m:17s - 0m:27s",
                transcriptionText:
                    "number. Is that considered the member id? Hello? To",
                endTime: 27.4222119140625,
                startTime: 17.6967509765625,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                blobContainer: "authcx",
                id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
                agentName: "Liz Leija",
                customerType: null,
                callDirection: "Inbound",
                serviceHierarchy: ["Smart Predict", null, null, null, null],
            },
            {
                createdOn: "2023-03-01T19:27:42.720127",
                createdBy: "hemalatha.malluvalasa@authcx.com",
                evaluationQbId: 51029252,
                note: null,
                segmentName: "Clip 0m:31s - 0m:40s",
                transcriptionText:
                    "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
                endTime: 40.299999899999996,
                startTime: 31.686563,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                blobContainer: "authcx",
                id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
                agentName: "Sydnee Cobbs",
                customerType: "Physician",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T20:50:20.6596",
                createdBy: "michelle.hey@authenticx.com",
                evaluationQbId: 51029228,
                note: null,
                segmentName: "Clip 0m:05s - 0m:06s",
                transcriptionText:
                    "have the member Id number that you 're calling about? Hi.",
                endTime: 6,
                startTime: 5,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                blobContainer: "authcx",
                id: "ee3153f1-4e4d-4afa-a923-ab3ce596de20",
                agentName: "Liz Leija",
                customerType: "Patient",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "Agent Dispute",
                    "Quality Assurance",
                    "Testing",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2022-12-23T18:46:59.424308",
                createdBy: "bryce.frase@authenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 4m:10s - 5m:06s",
                transcriptionText: null,
                endTime: 306.75918375651,
                startTime: 250.294650227865,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                blobContainer: "authcx",
                id: "ed2cf3c5-9961-46e2-8432-133d723f5093",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2021-05-05T20:39:47.718000",
                createdBy: "emir.sabljakovic@beauthenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 0m:02s - 9m:15s",
                transcriptionText: null,
                endTime: 2.80377835591634,
                startTime: 555.83,
                tags: "",
                url: "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                filePath:
                    "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                blobContainer: "securityllc",
                id: "8f7eb25b-33b2-4800-8382-8a80e12a2f84",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T18:41:23.105104",
                createdBy: "julie.gallina@authenticx.com",
                evaluationQbId: 51029230,
                note: null,
                segmentName: "Clip 0m:17s - 0m:27s",
                transcriptionText:
                    "number. Is that considered the member id? Hello? To",
                endTime: 27.4222119140625,
                startTime: 17.6967509765625,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                blobContainer: "authcx",
                id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
                agentName: "Liz Leija",
                customerType: null,
                callDirection: "Inbound",
                serviceHierarchy: ["Smart Predict", null, null, null, null],
            },
            {
                createdOn: "2023-03-01T19:27:42.720127",
                createdBy: "hemalatha.malluvalasa@authcx.com",
                evaluationQbId: 51029252,
                note: null,
                segmentName: "Clip 0m:31s - 0m:40s",
                transcriptionText:
                    "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
                endTime: 40.299999899999996,
                startTime: 31.686563,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                blobContainer: "authcx",
                id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
                agentName: "Sydnee Cobbs",
                customerType: "Physician",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T20:50:20.6596",
                createdBy: "michelle.hey@authenticx.com",
                evaluationQbId: 51029228,
                note: null,
                segmentName: "Clip 0m:05s - 0m:06s",
                transcriptionText:
                    "have the member Id number that you 're calling about? Hi.",
                endTime: 6,
                startTime: 5,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                blobContainer: "authcx",
                id: "ee3153f1-4e4d-4afa-a923-ab3ce596de20",
                agentName: "Liz Leija",
                customerType: "Patient",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "Agent Dispute",
                    "Quality Assurance",
                    "Testing",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2022-12-23T18:46:59.424308",
                createdBy: "bryce.frase@authenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 4m:10s - 5m:06s",
                transcriptionText: null,
                endTime: 306.75918375651,
                startTime: 250.294650227865,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                blobContainer: "authcx",
                id: "ed2cf3c5-9961-46e2-8432-133d723f5093",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2021-05-05T20:39:47.718000",
                createdBy: "emir.sabljakovic@beauthenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 0m:02s - 9m:15s",
                transcriptionText: null,
                endTime: 2.80377835591634,
                startTime: 555.83,
                tags: "",
                url: "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                filePath:
                    "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                blobContainer: "securityllc",
                id: "8f7eb25b-33b2-4800-8382-8a80e12a2f84",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T18:41:23.105104",
                createdBy: "julie.gallina@authenticx.com",
                evaluationQbId: 51029230,
                note: null,
                segmentName: "Clip 0m:17s - 0m:27s",
                transcriptionText:
                    "number. Is that considered the member id? Hello? To",
                endTime: 27.4222119140625,
                startTime: 17.6967509765625,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                blobContainer: "authcx",
                id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
                agentName: "Liz Leija",
                customerType: null,
                callDirection: "Inbound",
                serviceHierarchy: ["Smart Predict", null, null, null, null],
            },
            {
                createdOn: "2023-03-01T19:27:42.720127",
                createdBy: "hemalatha.malluvalasa@authcx.com",
                evaluationQbId: 51029252,
                note: null,
                segmentName: "Clip 0m:31s - 0m:40s",
                transcriptionText:
                    "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
                endTime: 40.299999899999996,
                startTime: 31.686563,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                blobContainer: "authcx",
                id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
                agentName: "Sydnee Cobbs",
                customerType: "Physician",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T20:50:20.6596",
                createdBy: "michelle.hey@authenticx.com",
                evaluationQbId: 51029228,
                note: null,
                segmentName: "Clip 0m:05s - 0m:06s",
                transcriptionText:
                    "have the member Id number that you 're calling about? Hi.",
                endTime: 6,
                startTime: 5,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                blobContainer: "authcx",
                id: "ee3153f1-4e4d-4afa-a923-ab3ce596de20",
                agentName: "Liz Leija",
                customerType: "Patient",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "Agent Dispute",
                    "Quality Assurance",
                    "Testing",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2022-12-23T18:46:59.424308",
                createdBy: "bryce.frase@authenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 4m:10s - 5m:06s",
                transcriptionText: null,
                endTime: 306.75918375651,
                startTime: 250.294650227865,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                blobContainer: "authcx",
                id: "ed2cf3c5-9961-46e2-8432-133d723f5093",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2021-05-05T20:39:47.718000",
                createdBy: "emir.sabljakovic@beauthenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 0m:02s - 9m:15s",
                transcriptionText: null,
                endTime: 2.80377835591634,
                startTime: 555.83,
                tags: "",
                url: "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                filePath:
                    "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                blobContainer: "securityllc",
                id: "8f7eb25b-33b2-4800-8382-8a80e12a2f84",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T18:41:23.105104",
                createdBy: "julie.gallina@authenticx.com",
                evaluationQbId: 51029230,
                note: null,
                segmentName: "Clip 0m:17s - 0m:27s",
                transcriptionText:
                    "number. Is that considered the member id? Hello? To",
                endTime: 27.4222119140625,
                startTime: 17.6967509765625,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                blobContainer: "authcx",
                id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
                agentName: "Liz Leija",
                customerType: null,
                callDirection: "Inbound",
                serviceHierarchy: ["Smart Predict", null, null, null, null],
            },
            {
                createdOn: "2023-03-01T19:27:42.720127",
                createdBy: "hemalatha.malluvalasa@authcx.com",
                evaluationQbId: 51029252,
                note: null,
                segmentName: "Clip 0m:31s - 0m:40s",
                transcriptionText:
                    "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
                endTime: 40.299999899999996,
                startTime: 31.686563,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                blobContainer: "authcx",
                id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
                agentName: "Sydnee Cobbs",
                customerType: "Physician",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T20:50:20.6596",
                createdBy: "michelle.hey@authenticx.com",
                evaluationQbId: 51029228,
                note: null,
                segmentName: "Clip 0m:05s - 0m:06s",
                transcriptionText:
                    "have the member Id number that you 're calling about? Hi.",
                endTime: 6,
                startTime: 5,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                blobContainer: "authcx",
                id: "ee3153f1-4e4d-4afa-a923-ab3ce596de20",
                agentName: "Liz Leija",
                customerType: "Patient",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "Agent Dispute",
                    "Quality Assurance",
                    "Testing",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2022-12-23T18:46:59.424308",
                createdBy: "bryce.frase@authenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 4m:10s - 5m:06s",
                transcriptionText: null,
                endTime: 306.75918375651,
                startTime: 250.294650227865,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                blobContainer: "authcx",
                id: "ed2cf3c5-9961-46e2-8432-133d723f5093",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2021-05-05T20:39:47.718000",
                createdBy: "emir.sabljakovic@beauthenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 0m:02s - 9m:15s",
                transcriptionText: null,
                endTime: 2.80377835591634,
                startTime: 555.83,
                tags: "",
                url: "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                filePath:
                    "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                blobContainer: "securityllc",
                id: "8f7eb25b-33b2-4800-8382-8a80e12a2f84",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T18:41:23.105104",
                createdBy: "julie.gallina@authenticx.com",
                evaluationQbId: 51029230,
                note: null,
                segmentName: "Clip 0m:17s - 0m:27s",
                transcriptionText:
                    "number. Is that considered the member id? Hello? To",
                endTime: 27.4222119140625,
                startTime: 17.6967509765625,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                blobContainer: "authcx",
                id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
                agentName: "Liz Leija",
                customerType: null,
                callDirection: "Inbound",
                serviceHierarchy: ["Smart Predict", null, null, null, null],
            },
            {
                createdOn: "2023-03-01T19:27:42.720127",
                createdBy: "hemalatha.malluvalasa@authcx.com",
                evaluationQbId: 51029252,
                note: null,
                segmentName: "Clip 0m:31s - 0m:40s",
                transcriptionText:
                    "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
                endTime: 40.299999899999996,
                startTime: 31.686563,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                blobContainer: "authcx",
                id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
                agentName: "Sydnee Cobbs",
                customerType: "Physician",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T20:50:20.6596",
                createdBy: "michelle.hey@authenticx.com",
                evaluationQbId: 51029228,
                note: null,
                segmentName: "Clip 0m:05s - 0m:06s",
                transcriptionText:
                    "have the member Id number that you 're calling about? Hi.",
                endTime: 6,
                startTime: 5,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                blobContainer: "authcx",
                id: "ee3153f1-4e4d-4afa-a923-ab3ce596de20",
                agentName: "Liz Leija",
                customerType: "Patient",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "Agent Dispute",
                    "Quality Assurance",
                    "Testing",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2022-12-23T18:46:59.424308",
                createdBy: "bryce.frase@authenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 4m:10s - 5m:06s",
                transcriptionText: null,
                endTime: 306.75918375651,
                startTime: 250.294650227865,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                blobContainer: "authcx",
                id: "ed2cf3c5-9961-46e2-8432-133d723f5093",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2021-05-05T20:39:47.718000",
                createdBy: "emir.sabljakovic@beauthenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 0m:02s - 9m:15s",
                transcriptionText: null,
                endTime: 2.80377835591634,
                startTime: 555.83,
                tags: "",
                url: "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                filePath:
                    "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                blobContainer: "securityllc",
                id: "8f7eb25b-33b2-4800-8382-8a80e12a2f84",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T18:41:23.105104",
                createdBy: "julie.gallina@authenticx.com",
                evaluationQbId: 51029230,
                note: null,
                segmentName: "Clip 0m:17s - 0m:27s",
                transcriptionText:
                    "number. Is that considered the member id? Hello? To",
                endTime: 27.4222119140625,
                startTime: 17.6967509765625,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                blobContainer: "authcx",
                id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
                agentName: "Liz Leija",
                customerType: null,
                callDirection: "Inbound",
                serviceHierarchy: ["Smart Predict", null, null, null, null],
            },
            {
                createdOn: "2023-03-01T19:27:42.720127",
                createdBy: "hemalatha.malluvalasa@authcx.com",
                evaluationQbId: 51029252,
                note: null,
                segmentName: "Clip 0m:31s - 0m:40s",
                transcriptionText:
                    "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
                endTime: 40.299999899999996,
                startTime: 31.686563,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                blobContainer: "authcx",
                id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
                agentName: "Sydnee Cobbs",
                customerType: "Physician",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T20:50:20.6596",
                createdBy: "michelle.hey@authenticx.com",
                evaluationQbId: 51029228,
                note: null,
                segmentName: "Clip 0m:05s - 0m:06s",
                transcriptionText:
                    "have the member Id number that you 're calling about? Hi.",
                endTime: 6,
                startTime: 5,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                blobContainer: "authcx",
                id: "ee3153f1-4e4d-4afa-a923-ab3ce596de20",
                agentName: "Liz Leija",
                customerType: "Patient",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "Agent Dispute",
                    "Quality Assurance",
                    "Testing",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2022-12-23T18:46:59.424308",
                createdBy: "bryce.frase@authenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 4m:10s - 5m:06s",
                transcriptionText: null,
                endTime: 306.75918375651,
                startTime: 250.294650227865,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                blobContainer: "authcx",
                id: "ed2cf3c5-9961-46e2-8432-133d723f5093",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2021-05-05T20:39:47.718000",
                createdBy: "emir.sabljakovic@beauthenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 0m:02s - 9m:15s",
                transcriptionText: null,
                endTime: 2.80377835591634,
                startTime: 555.83,
                tags: "",
                url: "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                filePath:
                    "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                blobContainer: "securityllc",
                id: "8f7eb25b-33b2-4800-8382-8a80e12a2f84",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T18:41:23.105104",
                createdBy: "julie.gallina@authenticx.com",
                evaluationQbId: 51029230,
                note: null,
                segmentName: "Clip 0m:17s - 0m:27s",
                transcriptionText:
                    "number. Is that considered the member id? Hello? To",
                endTime: 27.4222119140625,
                startTime: 17.6967509765625,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/5f7c6d99-4716-4e76-8981-659b3369d4dd.mp3",
                blobContainer: "authcx",
                id: "5f7c6d99-4716-4e76-8981-659b3369d4dd",
                agentName: "Liz Leija",
                customerType: null,
                callDirection: "Inbound",
                serviceHierarchy: ["Smart Predict", null, null, null, null],
            },
            {
                createdOn: "2023-03-01T19:27:42.720127",
                createdBy: "hemalatha.malluvalasa@authcx.com",
                evaluationQbId: 51029252,
                note: null,
                segmentName: "Clip 0m:31s - 0m:40s",
                transcriptionText:
                    "My name Jeff. And I am I did have Care global. Sydney I do have a provider on the back line inquiring about a benefits for this member.",
                endTime: 40.299999899999996,
                startTime: 31.686563,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/e1c13258-4be5-4cdd-8dd6-6feec1dccbac.mp3",
                blobContainer: "authcx",
                id: "e1c13258-4be5-4cdd-8dd6-6feec1dccbac",
                agentName: "Sydnee Cobbs",
                customerType: "Physician",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2023-03-01T20:50:20.6596",
                createdBy: "michelle.hey@authenticx.com",
                evaluationQbId: 51029228,
                note: null,
                segmentName: "Clip 0m:05s - 0m:06s",
                transcriptionText:
                    "have the member Id number that you 're calling about? Hi.",
                endTime: 6,
                startTime: 5,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ee3153f1-4e4d-4afa-a923-ab3ce596de20.mp3",
                blobContainer: "authcx",
                id: "ee3153f1-4e4d-4afa-a923-ab3ce596de20",
                agentName: "Liz Leija",
                customerType: "Patient",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "Agent Dispute",
                    "Quality Assurance",
                    "Testing",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2022-12-23T18:46:59.424308",
                createdBy: "bryce.frase@authenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 4m:10s - 5m:06s",
                transcriptionText: null,
                endTime: 306.75918375651,
                startTime: 250.294650227865,
                tags: "",
                url: "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                filePath:
                    "https://intfilemainstg.blob.core.windows.net/authcx/sampled/Clips/ed2cf3c5-9961-46e2-8432-133d723f5093.mp3",
                blobContainer: "authcx",
                id: "ed2cf3c5-9961-46e2-8432-133d723f5093",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
            {
                createdOn: "2021-05-05T20:39:47.718000",
                createdBy: "emir.sabljakovic@beauthenticx.com",
                evaluationQbId: 51029246,
                note: null,
                segmentName: "Clip 0m:02s - 9m:15s",
                transcriptionText: null,
                endTime: 2.80377835591634,
                startTime: 555.83,
                tags: "",
                url: "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                filePath:
                    "https://appdevtest.blob.core.windows.net/securityllc/sampled/Clips/8f7eb25b-33b2-4800-8382-8a80e12a2f84.mp3",
                blobContainer: "securityllc",
                id: "8f7eb25b-33b2-4800-8382-8a80e12a2f84",
                agentName: "John Cavalieri",
                customerType: "Insurance Benefit Provider",
                callDirection: "Inbound",
                serviceHierarchy: [
                    "SharkWeek",
                    "Shiver",
                    "MeTimbers",
                    null,
                    null,
                ],
            },
        ];
    }
}
