import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Divider, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import AcxChip from "components/UI/AcxChip";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import EddyChip from "components/UI/Chat/Views/EddyChip";
import { WorkflowReviewStore } from "components/WorkflowReview/Stores/WorkflowReviewStore";
import WFRAIExplanationContext from "components/WorkflowReview/WorkflowReviewAIExplanationContext/WorkflowReviewAIExplanationContext";
import WFRAIExplanationEddyContext from "components/WorkflowReview/WorkflowReviewAIExplanationContext/WorkflowReviewAIExplanationEddyContext";
import { observer } from "mobx-react";
import { Answer } from "models/Answer";
import { PredictedTag } from "models/PredictedTag";
import { Tag, TagSentiment } from "models/Tag";
import React, { useState } from "react";
import { useStore } from "utils/useStore";

const useStyles = makeStyles((theme: Theme) => ({
    item: {
        padding: theme.spacing(1.25),
    },
    card: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
    fontBold: {
        fontWeight: "bold",
    },
    chip: {
        padding: "4px 2px 4px 2px",
        gap: "4px",
        borderRadius: "4px",
    },
    chipText: {
        fontSize: "12px",
        fontWeight: 600,
        color: "#374151",
    },
    viewShownChip: {
        borderRadius: "4px",
    },
    eddyShownChip: {
        padding: "4px 6px 4px 6px",
    },
    viewContextChip: {
        padding: "4px 2px 4px 2px",
    },
    shownExpContainer: {
        maxHeight: "250px",
        overflow: "auto",
    },
    eddyContext: {
        padding: "10px",
    },
    modifiedWarning: {
        borderRadius: "4px",
        backgroundColor: "#DBEAFE",
        padding: "6px 16px",
        margin: "10px 0px",
        color: "#1E3A8A",
    },
    infoIcon: {
        margin: "auto",
        marginRight: "12px",
    },
}));

interface AIExplanationCardProps {
    activeTag: Tag;
    predictedTags: PredictedTag[];
    answer: Answer;
}

const AIExplanationCard: React.FC<AIExplanationCardProps> = observer(
    (props) => {
        const classes = useStyles();
        const store = useStore(WorkflowReviewStore);
        const [showExplanationContext, setShowExplanationContext] =
            useState(false);

        const isEddySignal: boolean = !!props.predictedTags.find(
            (item) =>
                item.predictedBy === "ML-EddyEffect-P2" ||
                item.predictedBy === "ML-EddyEffect",
        );

        const didExperienceEddy: boolean =
            isEddySignal &&
            props.activeTag.value.toLowerCase().includes("did experience");

        const classifierResultIsTrue =
            store.structuredHighlights
                .get(props.activeTag.id)
                ?.result.toLowerCase() === "true";

        const isNotOnlyClassifier = (): boolean => {
            const predictorDefintion =
                store.structuredHighlights
                    .get(props.activeTag.id)
                    ?.predictorDefinition?.toString() ?? null;

            if (predictorDefintion === null) {
                return false;
            }

            // Not a "Not Only Classifier" if no "!" present
            if (!predictorDefintion.includes("!")) {
                return false;
            }

            // This regex checks to see that the first alpha character in the string has a ! in front of it
            const isNotString = /^[^a-zA-Z]*!"?[a-zA-Z]/;
            const lines = predictorDefintion.split("\n");

            // This loop will run through each line and ensure each line isNotString
            for (const line of lines) {
                if (!isNotString.test(line)) {
                    return false;
                }
            }

            // This regex check will look to see if any &'s or |'s are missing a ! after them
            const ampersandOrPipeNotFollowedByNotFollowedByNot = /[&|][^!]/;
            if (
                ampersandOrPipeNotFollowedByNotFollowedByNot.test(
                    predictorDefintion,
                )
            ) {
                return false;
            }

            // This regex check will look to see if any AND's or OR's are missing a NOT after them
            const andOrNotFollowedByNot = /(AND|OR)(?!\sNOT)/;
            if (andOrNotFollowedByNot.test(predictorDefintion)) {
                return false;
            }

            // At this point we've met the conditions of a "Not Only Classifier"
            return true;
        };

        const renderExplanation = () => {
            let words: string = "";

            if (isEddySignal) {
                words += didExperienceEddy
                    ? " because we identified the following:"
                    : " because we did not identify any Eddy Effect Signals.";
            } else {
                if (classifierResultIsTrue) {
                    words += " because we identified";
                } else {
                    words += " because we did not identify";
                }

                words += ` the following classifier within the transcript.`;

                if (isNotOnlyClassifier()) {
                    words +=
                        " This classifier is defined by certain words or phrases not being found in the transcript.";
                }
            }

            return (
                <React.Fragment>
                    {"We scored this module question as "}
                    <span
                        className={classes.fontBold}
                        style={{
                            color: determineHighlightColor(props.activeTag),
                        }}
                    >
                        {props.activeTag.value}
                    </span>
                    {words}
                </React.Fragment>
            );
        };
        const determineHighlightColor = (activeTag: Tag): string => {
            switch (activeTag.value) {
                case "Yes":
                    return "#30CAAF";
                case "No":
                    return "#F15858";
                case "NA":
                    return "#2196f3";
            }
            switch (activeTag.sentiment) {
                case TagSentiment.Positive:
                    return "#30CAAF";
                case TagSentiment.Neutral:
                    return "#D7A000";
                case TagSentiment.Negative:
                    return "#F15858";
                default:
                    return "#30CAAF";
            }
        };
        const chevron = showExplanationContext ? (
            <KeyboardArrowUpIcon />
        ) : (
            <KeyboardArrowDownIcon />
        );

        const onViewContext = () => {
            const willShowContext = !showExplanationContext;

            setShowExplanationContext(willShowContext);
        };

        const isLoading = store.explanationLoadingStates[props.activeTag.id];

        // useEffect(() => {
        //     store.setExplanationLoadingStates(props.activeTag.id, true);
        //     if (isEddySignal && !store.eddyEffectP2Data) {
        //         store.setExplanationLoadingStates(props.activeTag.id, true);
        //         store
        //             .loadTEddyEffectP2Results(
        //                 store.currentEval?.interaction?.audioMetadataId,
        //             )
        //             .then(() =>
        //                 store.setExplanationLoadingStates(
        //                     props.activeTag.id,
        //                     false,
        //                 ),
        //             );
        //     } else if (
        //         !store.structuredHighlights.has(props.activeTag.id) ||
        //         !store.structuredHighlights.get(props.activeTag.id)!
        //             .aiSpeakerHighlights
        //     )
        //         store
        //             .getHighlightsForTag(
        //                 props.activeTag.id,
        //                 store.currentEval?.interaction?.audioMetadataId ?? "",
        //             )
        //             .then(() =>
        //                 store.setExplanationLoadingStates(
        //                     props.activeTag.id,
        //                     false,
        //                 ),
        //             );
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, []);

        function renderRbcChipsForTag() {
            return props.predictedTags.map((predictedRbc, index) => {
                return (
                    <AcxChip
                        key={predictedRbc.predictedBy + index}
                        style={{
                            maxWidth: "100%",
                        }}
                        label={
                            <Grid
                                container
                                alignItems="center"
                                spacing={1}
                                className={classes.chip}
                            >
                                <Grid
                                    item
                                    className={classes.chipText}
                                    style={
                                        // Agreed on max character amount for classifier chips
                                        predictedRbc.predictedBy.length > 40
                                            ? {
                                                  textOverflow: "ellipsis",
                                                  overflow: "hidden",
                                                  maxWidth: "100%",
                                              }
                                            : {}
                                    }
                                >
                                    {predictedRbc.predictedBy}
                                    {/* // TODO: Include the count for the # of phrases found that support the classifier */}
                                </Grid>
                            </Grid>
                        }
                        size="small"
                        color={"gray"}
                    />
                );
            });
        }

        function renderTranscriptChip() {
            return (
                <AcxChip
                    key={"view-context-aiexplanations"}
                    id="ai-explanation-view-context"
                    label={
                        <Grid
                            container
                            alignItems="center"
                            spacing={1}
                            className={clsx(
                                classes.viewShownChip,
                                classes.viewContextChip,
                            )}
                        >
                            <Grid
                                container
                                item
                                alignItems="center"
                                wrap="nowrap"
                            >
                                View Context {chevron}
                            </Grid>
                        </Grid>
                    }
                    size="small"
                    color={"transparent"}
                    onClick={onViewContext}
                    style={{
                        backgroundColor: "transparent",
                    }}
                />
            );
        }

        if (isLoading) {
            return (
                <Grid
                    container
                    item
                    xs={12}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    wrap={"nowrap"}
                >
                    <Grid container item direction="column" xs={12}>
                        <Grid
                            container
                            item
                            direction="column"
                            xs={12}
                            rowSpacing={1}
                            className={classes.item}
                        >
                            <AcxLoadingIndicator
                                color="secondary"
                                alternate="PuffLoader"
                                size={32}
                                key={"spinner" + props.activeTag.id}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid
                container
                item
                xs={12}
                direction={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                wrap={"nowrap"}
            >
                <Grid container item direction="column" xs={12}>
                    <Grid
                        container
                        item
                        direction="column"
                        xs={12}
                        rowSpacing={1}
                        className={classes.item}
                    >
                        <Grid item>
                            <Typography>{renderExplanation()}</Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"flex-end"}
                            wrap="nowrap"
                            xs={12}
                        >
                            {isEddySignal && didExperienceEddy && (
                                <EddyChip
                                    altText={
                                        "Eddy Effect Signal"
                                        // TODO: + eddySignalCount
                                        // Include the total # of eddies shown in transcript on the chip with the label
                                    }
                                />
                            )}
                            {!isEddySignal && (
                                <Grid
                                    container
                                    item
                                    xs={9}
                                    direction="row"
                                    alignItems="flex-start"
                                >
                                    {renderRbcChipsForTag()}
                                </Grid>
                            )}
                            {store.authStore.canUserView("Transcription") &&
                                (isEddySignal ? didExperienceEddy : true) &&
                                !isNotOnlyClassifier() &&
                                classifierResultIsTrue &&
                                renderTranscriptChip()}
                        </Grid>
                    </Grid>
                    {!isEddySignal && showExplanationContext && (
                        <React.Fragment>
                            <Divider />
                            <Grid
                                container
                                item
                                xs={12}
                                className={clsx(
                                    classes.shownExpContainer,
                                    classes.item,
                                )}
                                key={"explanation" + props.activeTag.id}
                            >
                                <WFRAIExplanationContext
                                    tagId={props.activeTag.id}
                                    predictedTags={props.predictedTags}
                                    answer={props.answer}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                    {isEddySignal && showExplanationContext && (
                        <React.Fragment>
                            <Divider />
                            {store.eddyEffectP2Data && (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    className={clsx(
                                        classes.shownExpContainer,
                                        classes.item,
                                    )}
                                    key={"explanation" + props.activeTag.id}
                                >
                                    <WFRAIExplanationEddyContext
                                        tagId={props.activeTag.id}
                                    />
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        );
    },
);

export default AIExplanationCard;
