import { sortBy } from "lodash";
import BaseService from "services/BaseService";
import { v4 } from "uuid";
import {
    AnswerType,
    Module, ModuleCategory,
    NewModule,
    Question, QuestionCategory,
} from "../../types/Module.type";

const translateQuestionsForUI = (questions: Question[]) => {
    return sortBy(questions || [], "order").map((q) => {
        const newQ: Question = {
            ...q,
            $uiKey: v4(),
            variation: q.answerType,
        };

        if (newQ.questions) {
            newQ.questions = translateQuestionsForUI(newQ.questions);
        }
        return newQ;
    });
};

const translateQuestionsForServer = (q: Question, i: number) => {
    const newQ: any = {
        ...q,
        answerTypeId: q.variation?.id || q.answerType?.id,
        order: i + 1,
    };
    delete newQ.answerType;
    delete newQ.variation;
    delete newQ.$uiKey;
    if (newQ.questions?.length) {
        newQ.questions = newQ.questions.map(translateQuestionsForServer);
    }
    return newQ;
};

class ModuleService extends BaseService {
    protected urlBase: string = "api/Modules";

    public async getAnswerTypes(): Promise<AnswerType[]> {
        const answerTypes = await this.get(
            new URLSearchParams({}),
            "GetAnswerTypes",
        );
        const answerTypesNoVariationHash = answerTypes
            .filter((type) => !type.variation)
            .reduce((obj, type) => {
                obj[type.answerTypeName] = type;
                return obj;
            }, {});
        const answerTypesWithVariation = answerTypes.filter(
            (type) => type.variation,
        );
        const answerTypesHash = answerTypesWithVariation.reduce((obj, type) => {
            const name = type.answerTypeName;
            if (obj[name]) {
                obj[name].variations = obj[name].variations || [
                    JSON.parse(
                        JSON.stringify({
                            ...obj[name],
                            variation: "No variation",
                        }),
                    ),
                ];
                obj[name].variations.push(type);
            }
            return obj;
        }, answerTypesNoVariationHash);
        const answerTypesReturn: AnswerType[] = Object.values(answerTypesHash);
        return answerTypesReturn;
    }
    
    public async getQuestionCategories(): Promise<QuestionCategory[]> {
        return this.get(new URLSearchParams({}), "GetQuestionCategories");
    }
    
    public async getModuleCategories(): Promise<ModuleCategory[]> {
        return this.get(new URLSearchParams({}), "GetModuleCategories");
    }

    public async getAcxModules(): Promise<any[]> {
        return this.get(new URLSearchParams({}), "GetModules");
    }

    public async getModules(organizationId: string): Promise<Module[]> {
        const body = JSON.stringify({ organizationId });

        const res = await this.post(
            body,
            new URLSearchParams({}),
            "GetLicensedModulesForOrganization",
        );

        return sortBy(
            res.map((module) => ({
                ...module,
                questions: sortBy(module?.questions || [], "order"),
            })) || [],
            "order",
        );
    }

    public async getModule(licensedModuleId: string): Promise<Module> {
        const res = await this.get(
            new URLSearchParams({ licensedModuleId }),
            "GetLicensedModule",
        );
        return {
            ...res,
            questions: translateQuestionsForUI(res.questions),
        };
    }

    public async createModule(module: Module | NewModule): Promise<Module> {
        const params = new URLSearchParams({});
        return this.post(
            JSON.stringify(module),
            params,
            "CreateLicensedModule",
            {
                returnJson: true,
            },
        );
    }

    public async updateModule(module: Module): Promise<Module> {
        const params = new URLSearchParams({});
        return this.put(
            params,
            "UpdateLicensedModule",
            JSON.stringify(module),
            {
                returnJson: true,
            },
        );
    }

    public async saveModule(module: Module): Promise<Module> {
        const formattedModule = {
            ...module,
            questions: module.questions.map(translateQuestionsForServer),
        };

        return module.id
            ? this.updateModule(formattedModule)
            : this.createModule(formattedModule);
    }

    public async toggleModule(module: Module): Promise<Module> {
        const params = new URLSearchParams({});
        return this.put(
            params,
            "ToggleLicensedModule",
            JSON.stringify({ id: module.id }),
            {
                returnJson: true,
            },
        );
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ModuleService();
