import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const LicenseSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="17px"
            height="17px"
            viewBox="0 0 17 17"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>AF6BCA98-4164-4AFC-B89E-60245935161F</title>
            <g
                id="Admin"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.5}
            >
                <g
                    id="Admin---Organization---Users"
                    transform="translate(-35.000000, -247.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Left-Nav" transform="translate(0.000000, 64.000000)">
                        <g
                            id="Group-10"
                            transform="translate(24.000000, 104.000000)"
                        >
                            <g
                                id="Group-3"
                                transform="translate(0.000000, 32.000000)"
                            >
                                <g
                                    id="Group-4-Copy-4"
                                    transform="translate(0.000000, 40.000000)"
                                >
                                    <g
                                        id="Icon/Building"
                                        transform="translate(11.000000, 7.000000)"
                                    >
                                        <path
                                            d="M16.7048125,9.3001875 L8.6998125,1.2951875 C8.4996875,1.0950625 8.2995625,0.995 7.999375,0.995 L1.995625,0.995 C1.39525,0.995 0.995,1.39525 0.995,1.995625 L0.995,7.999375 C0.995,8.2995625 1.0950625,8.4996875 1.2951875,8.6998125 L9.3001875,16.7048125 C9.5003125,16.9049375 9.7004375,17.005 10.000625,17.005 C10.3008125,17.005 10.5009375,16.9049375 10.7010625,16.7048125 L16.7048125,10.7010625 C17.1050625,10.3008125 17.1050625,9.7004375 16.7048125,9.3001875 Z M4.9975,5.998125 C4.397125,5.998125 3.996875,5.597875 3.996875,4.9975 C3.996875,4.397125 4.397125,3.996875 4.9975,3.996875 C5.597875,3.996875 5.998125,4.397125 5.998125,4.9975 C5.998125,5.597875 5.597875,5.998125 4.9975,5.998125 Z"
                                            id="16px_tag"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default LicenseSvg;
