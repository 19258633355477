import { Grid } from "@mui/material";
import Page from "components/Navigation/Page";
import { observer } from "mobx-react";
import React from "react";
import { useLocation } from "react-router";
import { useStore } from "utils/useStore";
import WorkflowsNavigationStore, {
    WorkflowsPage,
} from "../Stores/WorkflowsNavigationStore";
import WorkflowStore from "../Stores/WorkflowStore";
import AgingWorkflows from "./AgingWorkflows/AgingWorkflows";

type Props = {};

const WorkflowsRoutes: React.FC<Props> = observer(() => {
    const navStore = useStore(WorkflowsNavigationStore);
    const store = useStore(WorkflowStore);
    const location = useLocation();

    React.useEffect(() => {
        const pathComponents = location.pathname.split("/");
        const lastComponent = pathComponents[pathComponents.length - 1];
        navStore.setCurrentPage(lastComponent);
    }, [location.pathname, navStore]);

    return (
        <Grid container item xs={12}>
            {navStore.currentPage === WorkflowsPage["workflows"] && (
                <Page title="Aging Workflows | Authenticx">
                    <AgingWorkflows store={store} />
                </Page>
            )}
        </Grid>
    );
});

export default WorkflowsRoutes;
