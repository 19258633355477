import { toJS } from "mobx";
import Evaluation from "../../../models/Evaluation";
import LocalStorage from "../../../stores/LocalStorage";
import { IRootStore } from "../../../stores/RootStore";

const LastFailureKey: string = "LastFailure";

export class EvalWorkRestorer {
    private readonly storage: LocalForage;
    private evalGraph?: Evaluation;

    constructor(rootStore: IRootStore) {
        this.storage = rootStore
            .getStore(LocalStorage)
            .getLocalStore("EvalWorkRestore");
    }

    public createRestorePoint(evalGraph: Evaluation) {
        this.evalGraph = evalGraph;
    }

    public onSaveFailure = async () => {
        if (this.evalGraph) {
            const serializedEvalGraph = JSON.stringify(toJS(this.evalGraph));
            await this.storage.setItem(
                `${LastFailureKey}|${this.evalGraph.id}`,
                serializedEvalGraph,
            );
        }
    };

    public restore = async (evalId: string) => {
        if (evalId) {
            const restored = await this.storage.getItem<string>(
                `${LastFailureKey}|${evalId}`,
            );
            if (restored) {
                return {
                    evaluation: JSON.parse(restored) as Evaluation,
                    workflowMetadata: {} as any,
                };
            }
        }
        return null;
    };

    public async clearRestorePoint() {
        if (this.evalGraph) {
            await this.storage.setItem(
                `${LastFailureKey}|${this.evalGraph.id}`,
                null,
            );
            this.evalGraph = undefined;
        }
    }
}
