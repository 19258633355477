import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import {
    Close,
    ContentCopyRounded,
    ThumbDownOutlined,
    ThumbUpOutlined,
} from "@mui/icons-material";
import { FeedbackType } from "components/Signals/Store/AISummariesStore";
import { alpha, Grid, Typography, useTheme } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxButton from "components/UI/AcxButton";
import { useStore } from "utils/useStore";
import AgentChatStore from "../Stores/AgentChatStore";

interface MessageGroupActionsProps {
    onCopy?(): void;
}

export const MessageGroupActions = observer(
    (props: MessageGroupActionsProps) => {
        const store = useStore(AgentChatStore);
        const theme = useTheme();

        const [feedbackType, setFeedbackType] = useState<
            FeedbackType | undefined
        >();
        const [showFeedbackInput, setShowFeedbackInput] = useState(false);
        const [feedbackText, setFeedbackText] = useState("");
        const [isFeedbackTextSubmitted, setIsFeedbackTextSubmitted] =
            useState(false);

        const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);

        const isLiked = feedbackType === FeedbackType.Like;
        const isDisliked = feedbackType === FeedbackType.Dislike;

        const provideFeedback = useCallback(
            async (feedbackType?: FeedbackType, feedback?: string) => {
                try {
                    setIsFeedbackLoading(true);
                    await store.acxAgentService.provideFeedback(
                        store.thread.id,
                        feedbackType ?? null,
                        feedback ?? null,
                    );
                } catch (e: unknown) {
                } finally {
                    setIsFeedbackLoading(false);
                }
            },
            [store],
        );

        const getThumbColor = useCallback(
            (active: boolean) =>
                active ? theme.palette.primary.main : theme.palette.gray[300],
            [theme],
        );
        const getThumbHoverColor = useCallback(
            (active: boolean) =>
                active
                    ? alpha(theme.palette.primary.main, 0.9)
                    : theme.palette.gray[400],
            [theme],
        );

        function handleThumbClick(thumb: FeedbackType) {
            if (isFeedbackLoading) return;
            let newFeedback: FeedbackType | undefined = undefined;
            if (feedbackType !== thumb) {
                newFeedback = thumb;
                setShowFeedbackInput(true);
            } else {
                setShowFeedbackInput(false);
            }

            setFeedbackText("");
            setIsFeedbackTextSubmitted(false);
            setFeedbackType(newFeedback);
            provideFeedback(newFeedback);
        }

        return (
            <Grid
                container
                item
                direction="column"
                rowGap={1 / 2}
                sx={{ paddingLeft: "36px" }}
            >
                <Grid
                    container
                    item
                    columnGap={1}
                    alignSelf="flex-end"
                    sx={{ width: "fit-content" }}
                >
                    {store.thread.hasUserMessaged && (
                        <React.Fragment>
                            <Grid item>
                                <ContentCopyRounded
                                    sx={{
                                        fontSize: 16,
                                        cursor: "pointer",
                                        color: theme.palette.gray[300],
                                        ":hover": {
                                            color: theme.palette.gray[400],
                                        },
                                    }}
                                    onClick={() => props.onCopy?.()}
                                />
                            </Grid>
                            <Grid item>
                                <ThumbUpOutlined
                                    sx={{
                                        fontSize: 16,
                                        color: getThumbColor(isLiked),
                                        cursor: "pointer",
                                        ":hover": {
                                            color: getThumbHoverColor(isLiked),
                                        },
                                    }}
                                    onClick={() =>
                                        handleThumbClick(FeedbackType.Like)
                                    }
                                    id="ava-thumbs-up"
                                />
                            </Grid>
                            <Grid item>
                                <ThumbDownOutlined
                                    sx={{
                                        fontSize: 16,
                                        color: getThumbColor(isDisliked),
                                        cursor: "pointer",
                                        ":hover": {
                                            color: getThumbHoverColor(
                                                isDisliked,
                                            ),
                                        },
                                    }}
                                    onClick={() =>
                                        handleThumbClick(FeedbackType.Dislike)
                                    }
                                    id="ava-thumbs-down"
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
                {showFeedbackInput && (
                    <Grid
                        container
                        item
                        direction="column"
                        rowGap={1}
                        sx={(theme) => ({
                            borderRadius: theme.spacing(1 / 2),
                            paddingInline: theme.spacing(2),
                            paddingBlock: theme.spacing(1),
                            backgroundColor: theme.palette.primary[50],
                            color: theme.palette.gray[600],
                        })}
                    >
                        <Grid
                            container
                            item
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <Typography
                                    sx={{
                                        color: "#52525B",
                                        fontSize: "13px",
                                        fontWeight: 500,
                                        lineHeight: "20px",
                                    }}
                                >
                                    Why was this a {isLiked ? "good" : "bad"}{" "}
                                    response?
                                </Typography>
                            </Grid>
                            <Grid item sx={{ height: "16px" }}>
                                <Close
                                    sx={(theme) => ({
                                        fontSize: "16px",
                                        cursor: "pointer",
                                        color: theme.palette.gray[400],
                                    })}
                                    onClick={() => setShowFeedbackInput(false)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item direction="column" width="100%">
                            <Grid item>
                                <AcxMainTextField
                                    id="agent-message-feedback"
                                    textareaRootStyles={{ margin: 0 }}
                                    value={feedbackText}
                                    onChange={(event) =>
                                        setFeedbackText(event.target.value)
                                    }
                                    placeholderText="Enter feedback..."
                                    autoComplete="off"
                                    isDisabled={isFeedbackTextSubmitted}
                                />
                            </Grid>
                            {isFeedbackTextSubmitted && (
                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: "#52525B",
                                            fontSize: "11px",
                                            fontWeight: 500,
                                            lineHeight: "20px",
                                            fontStyle: "italic",
                                        }}
                                    >
                                        Feedback recorded
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item alignSelf="flex-end">
                            <AcxButton
                                fullWidth={false}
                                disabled={
                                    !feedbackText || isFeedbackTextSubmitted
                                }
                                loading={isFeedbackLoading}
                                sx={(theme) => ({
                                    borderRadius: theme.spacing(1 / 2),
                                    paddingInline: theme.spacing(1),
                                    paddingBlock: theme.spacing(1 / 4),
                                    border: "1px solid",
                                    fontWeight: 600,
                                    lineHeight: "20px",
                                    fontSize: "12px",
                                    height: "24px",
                                    color: theme.palette.gray[700],
                                    backgroundColor: "transparent",
                                    borderColor: theme.palette.gray[700],
                                    ":hover": {
                                        opacity: "75%",
                                        backgroundColor: "transparent",
                                    },
                                    ":disabled": {
                                        color: theme.palette.gray[400],
                                        backgroundColor: "transparent",
                                        borderColor: theme.palette.gray[400],
                                    },
                                })}
                                onClick={async () => {
                                    await provideFeedback(
                                        feedbackType,
                                        feedbackText,
                                    );
                                    setIsFeedbackTextSubmitted(true);
                                }}
                            >
                                Submit
                            </AcxButton>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    },
);
