import Navigation from "Layouts/SideNavigation/Navigation";
import { Observer, observer } from "mobx-react";
import React from "react";
import { Route, Routes } from "react-router";
import { useStore } from "utils/useStore";
import ConfigStore from "./stores/ConfigStore";

export default function ConfigNavigation() {
    const store = useStore(ConfigStore);

    return (
        <Observer>
            {() => {
                return <Navigation store={store.navigationStore} />;
            }}
        </Observer>
    );
}

export const ConfigRoutes = observer(() => {
    return (
        <Routes>
            <Route path="/config">Config route</Route>
        </Routes>
    );
});
