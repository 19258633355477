import { InputLabel, SxProps, Theme, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import clsx from "clsx";
import React, { FunctionComponent } from "react";
import useStyles from "../../Styles/Styles";
import { CSSProperties, useTheme } from "@mui/styles";

const styles = (theme: Theme) =>
    createStyles({
        labels: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            userSelect: "none",
            fontSize: "12px",
            lineHeight: "16px",

            color: theme.palette.text.secondary,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold as any,
        },

        focused: {
            "&$focused": {
                // increase the specificity for the pseudo class
                //"#4DB6AC"
                // color: "#4DB6AC"
                color: theme.palette.primary.main,
            },
        },
        labelContainer: {
            marginBottom: theme.spacing(0.5),
        },
        allErrors: {
            whiteSpace: "break-spaces",
        },
    });

interface OwnProps {
    labelText?: string;
    className?: string;
    styles?: CSSProperties;
    id?: string;
    htmlFor?: string;
    shrink?: boolean;
    disabled?: boolean;
    error?: boolean;
    focused?: boolean;
    margin?: "dense";
    required?: boolean;
    variant?: "standard" | "outlined" | "filled";
    infoText?: React.ReactNode;
    showAllErrors?: boolean;
    children?: React.ReactNode;
    sx?: SxProps<Theme>;
}

type Props = OwnProps;

const AcxInputLabel: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = (props) => {
    const classes = useStyles(styles);
    const theme = useTheme();

    return (
        <InputLabel
            className={clsx(
                classes.labelContainer,
                props.className,
                props.showAllErrors ? classes.allErrors : "",
            )}
            shrink={props.shrink}
            htmlFor={props.htmlFor}
            id={props.id}
            disabled={props.disabled}
            error={props.error}
            focused={props.focused}
            margin={props.margin}
            required={props.required}
            classes={{ focused: classes.focused, root: classes.labels }}
            variant={props.variant}
            sx={[
                {
                    "&.Mui-focused": {
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                    },
                },
                ...(props.sx
                    ? Array.isArray(props.sx)
                        ? props.sx
                        : [props.sx]
                    : []),
            ]}
        >
            <>
                {props.children ?? props.labelText}
                {props.infoText ? (
                    <Tooltip title={props.infoText} placement={"top-start"}>
                        <HelpOutlineIcon
                            sx={(theme) => ({
                                fontSize: "12px !important",
                                marginLeft: theme.spacing(1),
                                cursor: "help",
                            })}
                        />
                    </Tooltip>
                ) : null}
            </>
        </InputLabel>
    );
};

export default AcxInputLabel;
