import { SvgIcon, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import React from "react";
import useStyles from "Styles/Styles";
// import hexToRGB from "utils/hexToRGB";

const styles = (theme: Theme) => {
    return createStyles({
        root: {
            color: theme.palette.white.main,
            fontSize: "16px",
        },
        button: {
            borderRadius: "0 4px 4px 0",
            margin: 0,
            backgroundColor: theme.palette.primary.main,
            border: "none",
            width: "32px",
            height: "32px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
            },
        },
    });
};
interface AcxCalendarButtonProps {
    className?: string;
    onClick: () => void;
    disabled?: boolean;
}
export default function AcxCalendarButton(props: AcxCalendarButtonProps) {
    const classes = useStyles(styles);
    const handleVectorEvents = (e: React.MouseEvent) => {
        // props.onClick();
        // e.stopPropagation();
    };
    const buttonClick = (e: React.MouseEvent) => {
        // debugger;
        props.onClick();
        e.stopPropagation();
        e.preventDefault();
        return false;
    };
    function CalendarIcon(props) {
        return (
            <SvgIcon
                onClick={handleVectorEvents}
                {...props}
                className={classes.root}
            >
                <path
                    onClick={handleVectorEvents}
                    fill="currentColor"
                    d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z"
                />
            </SvgIcon>
        );
    }
    return (
        <button
            disabled={props.disabled}
            className={classes.button}
            onClick={buttonClick}
        >
            <CalendarIcon fontSize={"small"} />
        </button>
    );
}
