import { Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { SamplerStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/SamplerStore";
import ConversationsStore, {
    loadConversation,
} from "components/Conversations/Stores/ConversationsStore";
import ConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import AudioMetadataModel from "models/AudioMetadataModel";
import React from "react";
import { useStore } from "utils/useStore";

const useStyles = makeStyles((theme: Theme) => ({
    evaluateConversationDialogTitle: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#3F3F46",
    },
    evaluateConversationDialogHierachy: {
        color: "#3F3F46",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
    },
    evaluateConversationDialogContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        alignSelf: "stretch",
    },
}));

interface IEvaluateConversationDialog {
    onConfirmCallback?: () => void;
}

const EvaluateConversationDialog = observer(
    (props: IEvaluateConversationDialog) => {
        const classes = useStyles();
        const conversationStore = useStore(ConversationsStore);
        const samplerStore = useStore(SamplerStore);

        const evaluateConversation = () => {
            const item = {
                fileName: conversationStore.selectedConversation?.mediaUrl,
                id: conversationStore.selectedConversation?.conversationId,
            } as AudioMetadataModel;

            samplerStore.handleSample(
                [item],
                conversationStore?.hierarchyLevels,
            );
        };

        // Assemble the Hierachy String for the Evaluation Confirmation Dialog
        const hierachyLevelNamesString =
            conversationStore.hierarchyLevels?.reduce(
                (levelNamesString, current) => {
                    if (levelNamesString !== "") {
                        levelNamesString += " > ";
                    }
                    levelNamesString += `${current.name}`;
                    return levelNamesString;
                },
                "",
            );

        return (
            <ConfirmationDialog
                title={"Evaluate this Conversation"}
                confirmButtonText={"Evaluate Conversation"}
                isOpen={conversationStore.evaluateConversationDialogOpen}
                onClose={conversationStore.toggleEvaluateConversationDialogOpen}
                onConfirm={() => {
                    evaluateConversation();
                    props.onConfirmCallback?.();
                    conversationStore.toggleEvaluateConversationDialogOpen();
                }}
                content={
                    conversationStore.getTaskLoading(loadConversation) ? (
                        <AcxLoadingIndicator size={75} alternate="PuffLoader" />
                    ) : (
                        <div
                            className={
                                classes.evaluateConversationDialogContent
                            }
                        >
                            <Typography
                                className={
                                    classes.evaluateConversationDialogTitle
                                }
                            >
                                This conversation will be sampled to the
                                following hierarchy to be AI Evaluated:
                            </Typography>
                            <Typography
                                className={
                                    classes.evaluateConversationDialogHierachy
                                }
                            >
                                {hierachyLevelNamesString}
                            </Typography>
                        </div>
                    )
                }
                isLoading={conversationStore.getTaskLoading(loadConversation)}
            />
        );
    },
);

export default EvaluateConversationDialog;
