import {
    Divider,
    Grid,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Menu,
    MenuItem,
    MenuItemProps,
    Tooltip,
    Typography,
} from "@mui/material";
import {
    PopoverOrigin,
    PopoverPosition,
    PopoverReference,
} from "@mui/material/Popover/Popover";
import withStyles from "@mui/styles/withStyles";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import theme from "../../../Theme/AppTheme";
import { isStringType } from "../../../utils/TypeGuards";

const defaultAnchorOrigin: PopoverOrigin = {
    vertical: "bottom",
    horizontal: "right",
};

const defaultTransformOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
};

export const StyledMenuLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    "&:hover, &.Mui-selected:hover": {
        color: theme.palette.neutral[600],
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
            color: theme.palette.neutral[600],
        },
    },
    "&:focus, &:active, &.Mui-selected": {
        color: theme.palette.primary[500],
        "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
            color: theme.palette.primary[500],
        },
    },
}));

const StyledListItemContainer = withStyles({
    root: {
        display: "inline-flex",
        color: theme.palette.text.primary,
        "&:hover, &.Mui-selected:hover": {
            color: theme.palette.neutral[600],
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.neutral[600],
            },
        },
        "&:focus, &:active, &.Mui-selected": {
            color: theme.palette.primary[500],
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: theme.palette.primary[500],
            },
        },
    },
})(Grid);

export type AcxMenuItemProps = {
    id: string;
    label: React.ReactNode;
    secondaryLabel?: string;
    icon?: React.ReactNode;
    props?: Omit<MenuItemProps, "button">;
    action?: React.ReactNode;
    hidden?: boolean;
    toolTipText?: string;
    lastItemOfSection?: boolean;
};
interface IOwnProps {
    anchorElement?: HTMLElement | null;
    menuItems: Array<AcxMenuItemProps | "Divider">;
    onMenuClose: (evt) => void;
    open?: boolean;
    anchorPosition?: PopoverPosition;
    anchorReference?: PopoverReference;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    rootStyle?: React.CSSProperties;
}

const AcxMenu: FunctionComponent<IOwnProps> = observer((props) => {
    const menuIcon = (value: AcxMenuItemProps) => {
        if (value.icon) {
            return (
                <StyledListItemContainer style={props.rootStyle}>
                    <ListItemIcon>{value.icon}</ListItemIcon>

                    <ListItemText
                        disableTypography={!isStringType(value.label)}
                        style={{
                            marginRight: Boolean(value.action) ? "24px" : 0,
                        }}
                        primary={value.label}
                        secondary={value.secondaryLabel}
                    />
                </StyledListItemContainer>
            );
        } else {
            return (
                <StyledListItemContainer>
                    <ListItemText
                        disableTypography={!isStringType(value.label)}
                        style={{
                            marginRight: Boolean(value.action) ? "24px" : 0,
                        }}
                        primary={value.label}
                        secondary={value.secondaryLabel}
                    />
                </StyledListItemContainer>
            );
        }
    };

    const itemContent = (value: AcxMenuItemProps) => {
        if (value.toolTipText) {
            return (
                <Tooltip title={value.toolTipText}>{menuIcon(value)}</Tooltip>
            );
        } else {
            return menuIcon(value);
        }
    };

    return (
        <>
            {props.menuItems.length > 0 && (
                <Menu
                    anchorOrigin={props.anchorOrigin ?? defaultAnchorOrigin}
                    transformOrigin={
                        props.transformOrigin ?? defaultTransformOrigin
                    }
                    anchorEl={props.anchorElement}
                    anchorPosition={props.anchorPosition}
                    anchorReference={props.anchorReference ?? "anchorEl"}
                    keepMounted
                    open={props.open || Boolean(props.anchorElement)}
                    onClose={props.onMenuClose}
                    sx={{
                        "& .MuiPaper-root": {
                            border: `1px solid ${theme.palette.lightgrayBorder.main}`,
                            borderRadius: theme.shape.borderRadius,
                            // padding: theme.spacing(1),
                        },
                    }}
                >
                    {props.menuItems
                        .filter((value) => {
                            if (value === "Divider") {
                                return true;
                            } else {
                                return !value.hidden;
                            }
                        })
                        .map((value, i) => {
                            return value === "Divider" ? (
                                <Divider
                                    key={`acx-menu-item-key-divider-` + i}
                                />
                            ) : (
                                <MenuItem
                                    key={`acx-menu-item-key ${value.id}`}
                                    {...value.props}
                                    divider={value.lastItemOfSection}
                                    disableGutters={false}
                                    dense={true}
                                    id={value.id}
                                    sx={{
                                        ...(value.lastItemOfSection && {
                                            margin: 0,
                                        }),
                                        paddingLeft: "16px",
                                        minWidth: "190px",
                                        minHeight: "37px",
                                        borderRadius: "2px",
                                        color: theme.palette.text.primary,
                                        "&:hover": {
                                            backgroundColor:
                                                theme.palette.primary[50],
                                            color: theme.palette.neutral[600],
                                            "& .MuiListItemIcon-root, & .MuiListItemText-primary":
                                                {
                                                    color: theme.palette
                                                        .neutral[600],
                                                },
                                        },
                                    }}
                                >
                                    {itemContent(value)}
                                    {Boolean(value.action) && (
                                        <ListItemSecondaryAction>
                                            {value.action}
                                        </ListItemSecondaryAction>
                                    )}
                                </MenuItem>
                            );
                        })}
                </Menu>
            )}
        </>
    );
});

export default AcxMenu;
