import Page from "components/Navigation/Page";
import { observer } from "mobx-react";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import { useStore } from "../../../utils/useStore";
import {
    AnalystManagerNavigationStore,
    AnalystManagerPages,
    urlComponentToPageEnum,
} from "../Stores/AnalystManagerNavigationStore";
import ManagerInteractionsStore from "../Stores/ManagerInteractionsStore";
import AssignedInteractions from "./AssignedInteractions/AssignedInteractions";
import AnalystManagementDashboard from "./Dashboard/AnalystManagementDashboard";
import OnDemandSample from "./OnDemandSample/OnDemandSample";
import AssignInteractionToHierarchyDialog from "./UnassignedInteractions/AssignInteractionToHierarchyDialog";
import UnassignedInteractions from "./UnassignedInteractions/UnassignedInteractions";

interface OwnProps {}

type Props = OwnProps;

const ManagerInteractionRoutes: React.FC<Props> = observer((props) => {
    const navStore = useStore(AnalystManagerNavigationStore);
    const store = useStore(ManagerInteractionsStore);
    const location = useLocation();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        elementHeight: mainHeight,
        elementWidth: mainWidth,
        theRef: mainRef,
    } = useResizeObserver<HTMLDivElement>({
        name: "Analyst Management Main",
        debounceWait: 33,
    });

    React.useEffect(() => {
        const pathComponents = location.pathname.split("/");
        const lastComponent = pathComponents[pathComponents.length - 1];
        const lastComponentAsEnum = urlComponentToPageEnum(lastComponent);
        navStore.setCurrentPage(lastComponentAsEnum);
    }, [location.pathname, location, navStore]);

    return (
        <>
            <AssignInteractionToHierarchyDialog
                onConfirm={store.assignInteractions}
                orgId={store.orgSelectStore.orgId}
            />

            <div style={{ width: "100%", height: "100%" }} ref={mainRef}>
                <Routes>
                    <Route
                        path={AnalystManagerPages.DASHBOARD}
                        element={
                            <Page title="Interactions | Authenticx">
                                <AnalystManagementDashboard
                                    height={mainHeight ?? 0}
                                    width={mainWidth ?? 0}
                                />
                            </Page>
                        }
                    />
                    <Route
                        path={AnalystManagerPages.ASSIGNED}
                        element={
                            <Page title="Assigned Interactions | Authenticx">
                                <AssignedInteractions />
                            </Page>
                        }
                    />
                    <Route
                        path={AnalystManagerPages.UNASSIGNED}
                        element={
                            <Page title="Unassigned Interactions | Authenticx">
                                <UnassignedInteractions />
                            </Page>
                        }
                    />
                    {navStore.displayOnDemand && (
                        <Route
                            path={AnalystManagerPages.SAMPLING}
                            element={
                                <Page title="On-Demand Sample | Authenticx">
                                    <OnDemandSample />
                                </Page>
                            }
                        />
                    )}
                    {/* <Route
                        path="/*"
                        element={
                            <Page title="Interactions | Authenticx">
                                <AnalystManagementDashboard
                                    height={mainHeight ?? 0}
                                    width={mainWidth ?? 0}
                                />
                            </Page>
                        }
                    /> */}
                </Routes>
                {/*                
                {navStore.currentPage === AnalystManagerPages.DASHBOARD && (
                    <Page title="Interactions | Authenticx">
                        <AnalystManagementDashboard
                            height={mainHeight ?? 0}
                            width={mainWidth ?? 0}
                        />
                    </Page>
                )}

                {navStore.currentPage === AnalystManagerPages.ASSIGNED && (
                    <Page title="Assigned Interactions | Authenticx">
                        <AssignedInteractions />
                    </Page>
                )}

                {navStore.currentPage === AnalystManagerPages.UNASSIGNED && (
                    <Page title="Unassigned Interactions | Authenticx">
                        <UnassignedInteractions />
                    </Page>
                )}

                {navStore.currentPage === AnalystManagerPages.SAMPLING &&
                    navStore.displayOnDemand && (
                        <Page title="On-Demand Sample | Authenticx">
                            <OnDemandSample />
                        </Page>
                    )}
                     */}
            </div>
        </>
    );
});

export default ManagerInteractionRoutes;
