import { ButtonBase, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";
import * as _ from "lodash";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import hexToRGB from "../../utils/hexToRGB";
import AcxMainTextField from "./AcxMainTextField";

const useStylesListItem = makeStyles((theme: Theme) => ({
    rootListItem: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular as any,
    },
    selected: {
        "&$selected": {
            // increase the specificity for the pseudo class
            backgroundColor: hexToRGB(theme.palette.secondary.main, 0.12),
            color: theme.palette.text.primary,
            fontWeight: "bold",
            "&:hover": {
                backgroundColor: hexToRGB(theme.palette.secondary.main, 0.12),
                color: theme.palette.text.primary,
                fontWeight: "bold",
            },
        },
    },
}));

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    paper: (props: Props) => ({
        // minWidth: "300px",
        boxShadow:
            " 0 0 4px 2px rgba(0,0,0,0.02), 0 2px 24px 0 rgba(0,0,0,0.02)",
        borderRadius: theme.shape.borderRadius,
        height: props.height ?? "auto",
        overflow: "auto",
    }),
    button: {
        margin: theme.spacing(0.5, 0),
        // fontWeight:theme.typography.fontWeightBold,
        fontSize: "28px",
        color: theme.palette.white.main,
        backgroundColor: theme.palette.secondary.main,
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: theme.shape.borderRadius,
    },
    listItemText: {
        fontWeight: "inherit",
        color: "inherit",
        fontSize: "inherit",
        fontFamily: "inherit",
    },
    titleText: {
        letterSpacing: 0,
        lineHeight: "20px",
        fontSize: "13px",
        paddingBottom: theme.spacing(0.5),
    },
    moveButtonDisabled: {
        backgroundColor: hexToRGB(theme.palette.secondary.main, 0.2),
    },
    fullHeight: {
        height: "100%",
        overscrollBehavior: "contain",
    },
    required: {
        color: theme.palette.red.main,
        // content: "\" *\" !important"
    },
    searchContainer: {
        padding: "4px",
    },

    searchIcon: {
        color: hexToRGB(theme.palette.gray.main, 0.25),
    },
}));

function difference(a: any[], b: any[], idField: string) {
    return _.differenceBy(a, b, idField);
}

function intersection(a: any[], b: any[], idField: string) {
    return _.intersectionBy(a, b, idField);
}

interface OwnProps {
    availableListTitle?: string;
    selectedListTitle?: string;

    availableItems: any[];
    selectedItems: any[];

    idField: string;
    labelField?: string;
    labelFieldSelector?: (arg: any) => string;
    onSelect?: (args: any[]) => void;

    height?: number | string | "auto";

    onSelectedItemClick?: (item: any) => void;

    isItemActionRequired?: (item: any) => void;

    reorderSelected?: boolean;
}

type Props = OwnProps;

const AcxTransferList: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const classes = useStyles(props);
    const listClasses = useStylesListItem();

    const initLeft = useMemo(
        () =>
            difference(
                props.availableItems,
                intersection(
                    props.availableItems,
                    props.selectedItems,
                    props.idField,
                ),
                props.idField,
            ).sort((a, b) => {
                let left: string, right: string;
                if (props.labelField) {
                    left = a[props.labelField];
                    right = b[props.labelField];
                } else {
                    left = props.labelFieldSelector?.(a) ?? "";
                    right = props.labelFieldSelector?.(b) ?? "";
                }
                return left > right ? 1 : -1;
            }),
        [props],
    );
    //[props.availableItems, props.selectedItems, props.idField, props.labelField, props.labelFieldSelector]);

    const [searchStr, setSearchStr] = React.useState<string>("");

    const [leftSelected, setLeftSelected] = React.useState<any[]>([]);
    const [rightSelected, setRightSelected] = React.useState<any[]>([]);

    const [left, setLeft] = React.useState<any[]>(initLeft);
    const [leftFiltered, setLeftFiltered] = React.useState<any[] | undefined>(
        undefined,
    );
    const [right, setRight] = React.useState<any[]>(
        props.selectedItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)),
    );

    useEffect(() => {
        const initLeft = difference(
            props.availableItems,
            intersection(
                props.availableItems,
                props.selectedItems,
                props.idField,
            ),
            props.idField,
        ).sort((a, b) => {
            let left: string, right: string;
            if (props.labelField) {
                left = a[props.labelField];
                right = b[props.labelField];
            } else {
                left = props.labelFieldSelector?.(a) ?? "";
                right = props.labelFieldSelector?.(b) ?? "";
            }
            return left > right ? 1 : -1;
        });

        setLeft(initLeft);
        setRight(
            props.selectedItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)),
        );
    }, [
        props.selectedItems,
        props.availableItems,
        props.idField,
        props.labelField,
        props.labelFieldSelector,
        props,
    ]);

    useEffect(() => {
        if (!searchStr) {
            setLeftFiltered(undefined);
        }

        const filtered = left.filter((value) => {
            const displayText = props.labelField
                ? value[props.labelField]
                : props.labelFieldSelector?.(value);
            return (displayText as string)?.toLowerCase()?.includes(searchStr);
        });
        setLeftFiltered(filtered);
    }, [left, props, props.labelField, props.labelFieldSelector, searchStr]);

    useEffect(() => {
        if (!props.reorderSelected) return;
        props.onSelect?.(right);
    }, [props, right]);

    function handleLeftToggle(value, index) {
        const currentIndex = leftSelected.findIndex(
            (value1) => value1[props.idField] === value[props.idField],
        );
        const newChecked = [...leftSelected];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setLeftSelected(newChecked);
    }

    function handleRightToggle(value, index) {
        const currentIndex = rightSelected.findIndex(
            (value1) => value1[props.idField] === value[props.idField],
        );
        let newChecked;

        if (currentIndex === -1) {
            newChecked = [value];
            props.onSelectedItemClick?.(value);
        } else {
            newChecked = [];
            props.onSelectedItemClick?.(undefined);
        }

        setRightSelected(newChecked);
    }

    function handleCheckedRight() {
        leftSelected.forEach((value) => {
            value.order = 99;
        });

        const selectedItems = right
            .concat(leftSelected)
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        selectedItems.forEach((value, index) => {
            value.order = index;
        });
        setRight(selectedItems);
        setLeft(difference(left, leftSelected, props.idField));
        setLeftSelected([]);
        setSearchStr("");
        // console.log(selectedItems);
        props.onSelect?.(selectedItems);
        // props.onSelectedItemClick?.(undefined);
    }
    function handleCheckedLeft() {
        setLeft(left.concat(rightSelected));
        rightSelected.forEach((value) => {
            value.order = undefined;
        });

        const selectedItems = difference(right, rightSelected, props.idField);
        right
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
            .forEach((value, index) => {
                if (rightSelected.length > 0) {
                    if (
                        value[props.idField] === rightSelected[0][props.idField]
                    ) {
                        right.slice(index + 1).forEach((v1, indx1) => {
                            v1.order = index + indx1;
                        });
                    }
                }
            });

        setRight(selectedItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)));
        setRightSelected([]);
        setSearchStr("");

        props.onSelect?.(selectedItems);
        props.onSelectedItemClick?.(undefined);
    }

    function searchLeft(search: string) {
        setSearchStr(search);
    }

    function moveItemUp(index) {
        if (index >= right.length - 1) return;
        setRight((prev) => {
            const newArr = [...prev];
            const currentItem = newArr[index];
            currentItem.order = index + 1;
            const nextItem = newArr[index + 1];
            nextItem.order = index;
            newArr[index] = nextItem;
            newArr[index + 1] = currentItem;
            return newArr;
        });
    }

    function moveItemDown(index) {
        if (index === 0) return;
        setRight((prev) => {
            const newArr = [...prev];
            const currentItem = newArr[index];
            currentItem.order = index - 1;
            const prevItem = newArr[index - 1];
            prevItem.order = index;
            newArr[index] = prevItem;
            newArr[index - 1] = currentItem;
            return newArr;
        });
    }

    const customList = (
        items: any[],
        toggler: (value: any, index: number) => void,
        checkedList: any[],
        location: "left" | "right",
    ) => (
        <Paper className={classes.paper}>
            <List
                dense
                component="div"
                role="list"
                className={classes.fullHeight}
            >
                <Scrollbars>
                    {items.map((value: any, index: number) => {
                        const labelId = `${value}-label`;
                        const displayText = props.labelField
                            ? value[props.labelField]
                            : props.labelFieldSelector?.(value);

                        const actionRequired =
                            location === "right" &&
                            props.isItemActionRequired?.(value);
                        // console.log(value);
                        // console.log(props.idField);
                        return (
                            <div
                                style={{ display: "flex" }}
                                key={value[props.idField] + location}
                            >
                                <ListItem
                                    classes={{ selected: listClasses.selected }}
                                    role="listitem"
                                    button
                                    onClick={() => toggler(value, index)}
                                    selected={
                                        checkedList.findIndex(
                                            (value1) =>
                                                value1[props.idField] ===
                                                value[props.idField],
                                        ) > -1
                                    }
                                >
                                    <ListItemText
                                        primaryTypographyProps={{
                                            className: clsx(
                                                classes.listItemText,
                                            ),
                                        }}
                                        disableTypography
                                        className={clsx(classes.listItemText)}
                                        id={labelId}
                                        primary={
                                            <Typography
                                                className={classes.listItemText}
                                            >
                                                {displayText}{" "}
                                                {actionRequired && (
                                                    <span
                                                        className={
                                                            classes.required
                                                        }
                                                    >
                                                        *
                                                    </span>
                                                )}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                {props.reorderSelected && location === "right" && (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginRight: "0.25rem",
                                        }}
                                    >
                                        <ArrowDropDownIcon
                                            onClick={() => {
                                                moveItemUp(index);
                                            }}
                                            cursor="pointer"
                                        />
                                        <ArrowDropUpIcon
                                            onClick={() => {
                                                moveItemDown(index);
                                            }}
                                            cursor="pointer"
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </Scrollbars>
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.root}
            wrap={"nowrap"}
        >
            <Grid item xs>
                <AcxMainTextField
                    key="table-fields-search-box"
                    id="table-fields-search-box"
                    containerClass={classes.searchContainer}
                    value={searchStr}
                    labelText="Search"
                    startAdornment={
                        <SearchIcon className={classes.searchIcon} />
                    }
                    onChange={(evt) => searchLeft(evt.currentTarget.value)}
                />

                <Typography className={classes.titleText}>
                    {props.availableListTitle}
                </Typography>
                {customList(
                    leftFiltered ?? left,
                    handleLeftToggle,
                    leftSelected,
                    "left",
                )}
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <ButtonBase
                        classes={{ disabled: classes.moveButtonDisabled }}
                        className={classes.button}
                        onClick={handleCheckedRight}
                        disabled={leftSelected.length === 0}
                    >
                        <KeyboardArrowRightIcon
                            fontSize={"inherit"}
                            color={"inherit"}
                        />
                    </ButtonBase>
                    <ButtonBase
                        classes={{ disabled: classes.moveButtonDisabled }}
                        className={classes.button}
                        onClick={handleCheckedLeft}
                        disabled={rightSelected.length === 0}
                    >
                        <KeyboardArrowLeftIcon
                            fontSize={"inherit"}
                            color={"inherit"}
                        />
                    </ButtonBase>
                </Grid>
            </Grid>
            <Grid item xs>
                <div style={{ height: "57px" }} />
                <Typography className={classes.titleText}>
                    {props.selectedListTitle}
                </Typography>
                {customList(right, handleRightToggle, rightSelected, "right")}
            </Grid>
        </Grid>
    );
};

export default AcxTransferList;
