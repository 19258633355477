import BaseEntity from "./BaseEntity";

export enum DataProcessingOptions {
    Transcription = "Transcription",
    Classification = "Classification",
    HumanAnalysis = "HumanAnalysis",
    ClassifierValidation = "ClassifierValidation",
    Redaction = "Redaction",
    MiddleOfTheFunnel = "MiddleOfTheFunnel"
}

export enum PipelineProcessTypes {
    Automatic = 0,
    OnDemand = 1,
}

export class DataProcessingOption extends BaseEntity {
    processingOption: string;
}

export class LicensedDataProcessing extends BaseEntity {
    organizationId: string;
    dataProcessingOptionId: string;
    option: DataProcessingOption;
}
