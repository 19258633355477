import { Grid, SxProps, Theme } from "@mui/material";
import AgentLoading from "animations/AgentLoading";
import React from "react";
import { AgentAvatar } from "../Branding/AgentAvatar";
import { observer } from "mobx-react";
import { useStore } from "utils/useStore";
import AgentChatStore from "../Stores/AgentChatStore";
import { MessageAuthor } from "../Models/MessageAuthor";
import { MessageGroup } from "../MessageGroup/MessageGroup";
import { ThreadMessage } from "../Models/ThreadMessage";
import { ResponseTime } from "../Models/ResponseTime";
import { AgentState } from "../Models/AgentState";

/**
 * If the agent is in a loading state, the proper loading indicator is rendered
 * based on the total time the agent has been waiting.
 */
export const MessageLoading = observer(() => {
    const store = useStore(AgentChatStore);

    if (store.state !== AgentState.Waiting) return null;

    const responseTime = store.responseTime;
    if (!responseTime) return null;

    const isMostRecentMessageAgents =
        store.thread.recentAgentMessageGroup ===
        store.thread.messageGroups.at(-1);

    // Covers the state where the agent sends a message, such as the agent coaching disclaimer,
    // but then we still have a loading state. There will be two different message groups and a gap
    // is put between them, this basically undoes that. Other approaches will require a bit of refactoring.
    let extraSx: SxProps<Theme> | undefined;
    if (isMostRecentMessageAgents) {
        extraSx = (theme) => ({
            marginTop: theme.spacing(-3),
        });
    }

    if (responseTime === ResponseTime.Short)
        return (
            <Grid container alignItems="center" sx={extraSx}>
                <AgentAvatar />
                <Grid item sx={{ width: "80px", height: "40px", padding: 0 }}>
                    <AgentLoading />
                </Grid>
            </Grid>
        );

    if (responseTime > ResponseTime.Short) {
        const messages: ThreadMessage[] = [];

        if (responseTime >= ResponseTime.Medium) {
            messages.push(
                new ThreadMessage(
                    "This is taking a little bit longer, I'm working on it, please hold!",
                ),
            );
        }

        if (responseTime >= ResponseTime.Long) {
            messages.push(
                new ThreadMessage(
                    "Don't worry, still working on it! May take up to a minute",
                ),
            );
        }

        return (
            <MessageGroup
                id="agent-thread-loading"
                author={MessageAuthor.Agent}
                isMostRecent
                cancelledStreaming={false}
                messages={messages}
                sx={extraSx}
            />
        );
    }

    return null;
});
