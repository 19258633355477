import theme from "Theme/AppTheme";
import moment from "moment";

export const formatHmsToSeconds = (hms: string): number => {
    const segmentLength = hms.split(":").length;
    return moment.duration(segmentLength > 2 ? hms : `00:${hms}`).asSeconds();
};

export const formatSecondsToHms = (seconds?: number) => {
    if (!seconds || seconds < 0) return "00:00";
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor((seconds % 3600) % 60);

    var hDisplay = h > 0 ? h + ":" : "";
    var mDisplay = m < 10 ? "0" + m + ":" : m + ":";
    var sDisplay = s < 10 ? "0" + s : s;
    return hDisplay + mDisplay + sDisplay;
};

export const validateHmsFormat = (hms: string | number): boolean => {
    if (typeof hms === "number") return false;
    const segments = hms.split(":");
    const validSegments = segments.length >= 2 && segments.length <= 3;
    return (
        validSegments &&
        segments.every(
            (segment) => segment.length === 2 && !isNaN(Number(segment)),
        )
    );
};

export const getColorFromClipIndex = (
    idx: number,
    defaultClipColor?: boolean,
): string => {
    if (defaultClipColor) {
        return theme.palette.secondary[800];
    }
    const colorIdx = idx % 7;

    switch (colorIdx) {
        case 0:
            return theme.palette.secondary[800];
        case 1:
            return theme.palette.primary[400];
        case 2:
            return theme.palette.highlight.gold.main;
        case 3:
            return theme.palette.error[500];
        case 4:
            return theme.palette.highlight.purple.main;
        case 5:
            return theme.palette.warning[500];
        case 6:
            return theme.palette.success[500];
        case 7:
            return theme.palette.error[700];
        default:
            return theme.palette.secondary[800];
    }
};
