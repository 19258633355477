import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import { Grid, Stack } from "@mui/material";
import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { EvaluationType } from "stores/ApplicationFilters/Filters/EvaluationType";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const useStyles = makeStyles(() => ({
    checkboxContainer: {
        maxHeight: "28px",
    },
}));

export const EvaluationTypesFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const classes = useStyles();

    return (
        <Stack>
            <FormControlLabel
                label={
                    <Grid container alignItems="center">
                        No Evaluation
                    </Grid>
                }
                control={
                    <Checkbox
                        id="application-filters-eval-type-no-evaluation"
                        onChange={() => {
                            props.store.toggleEvaluationType(
                                EvaluationType.NotEvaluated,
                            );
                        }}
                        checked={props.store.evaluationTypes.includes(
                            EvaluationType.NotEvaluated,
                        )}
                    />
                }
                className={classes.checkboxContainer}
            />
            <FormControlLabel
                label={
                    <Grid container alignItems="center">
                        AI Evaluated
                        <Grid item style={{ paddingLeft: "4px" }}>
                            <CheckCircleOutlineIcon
                                color={"primary"}
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    float: "right",
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                control={
                    <Checkbox
                        id="application-filters-eval-type-AI-evaluated"
                        onChange={() => {
                            props.store.toggleEvaluationType(
                                EvaluationType.AIEvaluated,
                            );
                        }}
                        checked={props.store.evaluationTypes.includes(
                            EvaluationType.AIEvaluated,
                        )}
                    />
                }
                className={classes.checkboxContainer}
                disabled={props.store.evaluationTypes.includes(
                    EvaluationType.NotEvaluated,
                )}
            />
            <FormControlLabel
                label={
                    <Grid container alignItems="center">
                        Human Evaluated
                        <Grid item style={{ paddingLeft: "4px" }}>
                            <CheckCircleOutlineIcon
                                color={"secondary"}
                                style={{
                                    width: "16px",
                                    height: "16px",
                                    float: "right",
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                control={
                    <Checkbox
                        id="application-filters-eval-type-human-evaluated"
                        onChange={() => {
                            props.store.toggleEvaluationType(
                                EvaluationType.HumanEvaluated,
                            );
                        }}
                        checked={props.store.evaluationTypes.includes(
                            EvaluationType.HumanEvaluated,
                        )}
                    />
                }
                className={classes.checkboxContainer}
                disabled={props.store.evaluationTypes.includes(
                    EvaluationType.NotEvaluated,
                )}
            />
        </Stack>
    );
});
