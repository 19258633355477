import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import NavigationStore, {
    INavigationGroup,
} from "Layouts/SideNavigation/NavigationStore";
import { makeObservable, observable, reaction } from "mobx";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { AuthStore } from "../../../stores/AuthStore";
import { BaseStore } from "../../../stores/BaseStore";
import ReportsStore from "../Reports/Stores/ReportsStore";
import AISummariesStore from "./AISummariesStore";
import { getChartPermissionRequirements } from "../Models/SignalsModels";

@AcxStore
class SignalsStore extends BaseStore {
    readonly authStore: AuthStore;

    readonly messageStore: MessageStore;

    readonly navStore: NavigationStore;
    readonly userDashboardNavStore: NavigationStore;
    readonly orgDashboardNavStore: NavigationStore;

    readonly aiSummariesStore: AISummariesStore;

    readonly reportsStore: ReportsStore;

    orgSelectorStore = new OrgSelectorComponentStore();

    @observable
    signalsNavItems: INavigationGroup[] = [];

    @observable showReports: boolean = false;

    public constructor(rootStore: IRootStore) {
        super("Signals Store");
        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.messageStore = rootStore.getStore(MessageStore);

        this.reportsStore = rootStore.getStore(ReportsStore);

        this.navStore = new NavigationStore(
            rootStore,
            "SignalsNavStore",
            [],
            this.orgSelectorStore,
        );

        this.userDashboardNavStore = new NavigationStore(
            rootStore,
            "SignalsUserDashboardNavStore",
            [],
            this.orgSelectorStore,
        );

        this.orgDashboardNavStore = new NavigationStore(
            rootStore,
            "SignalsOrgDashboardNavStore",
            [],
            this.orgSelectorStore,
        );

        // This will override the users selected reference option,
        // applicationFiltersStore will default to interaction date
        // this.applicationFiltersStore.setDateReferenceOption(
        //     DateReferenceOption.InteractionDate,
        // );

        // if (
        //     this.authStore.rootStore.activeLocation.location.includes("trends")
        // ) {
        //     this.datePickerStore.setBeginDate(moment().subtract(12, "w"));
        // } else {
        //     this.datePickerStore.setBeginDate(moment().subtract(1, "M"));
        // }

        // keep signals nav store in sync with reports nav on filter
        reaction(
            () => this.reportsStore.navStore.itemGroups,
            () => {
                if (this.showReports) {
                    this.navStore.setNavItems(
                        this.reportsStore.navStore.itemGroups,
                    );
                }
            },
        );
    }

    canViewChart(chartId: string) {
        const requirements = getChartPermissionRequirements(chartId);
        if (requirements.length === 0) return true;
        return requirements.every((requirement) =>
            requirement.action === "View"
                ? this.authStore.canUserView(requirement.name)
                : this.authStore.canUserEdit(requirement.name),
        );
    }
}

export default SignalsStore;
