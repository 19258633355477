import { Grid, Typography, useTheme } from "@mui/material";
import ColumnMap from "components/Admin/AdminUIComponents/MetadataTemplate/MetaInput/ColumnMap";
import AcxButton from "components/UI/AcxButton";
import Gutters from "Layouts/Responsive/Gutters";
import { Observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../utils/useStore";
import MetadataView from "./MetadataView";
import FileExtView from "./MetaInput/FileExtView";
import FileFieldToggle from "./MetaInput/FileFieldToggle";
import { TemplateStore } from "./TemplateStore";

interface IMetadataSpec {}
const gridStyle = { paddingTop: "24px" };

function MetadataSpecMainView(props: IMetadataSpec) {
    const theme = useTheme();
    const navigate = useNavigate();
    const templateStore = useStore(TemplateStore);

    function onComplete() {
        templateStore.onSpecFinished?.(templateStore.model.spec);

        navigate(-1);
    }

    function onColumnChange(item) {
        templateStore.addFieldToSpec(item);
    }
    function onToggle(toggle) {
        if (toggle === "filename") {
            templateStore.updateModelProp("metaSource", toggle);
        } else if (toggle === "file") {
            templateStore.updateModelProp("metaSource", toggle);
        }
    }
    function getFieldValues(fieldname: string) {
        const fld = templateStore.model.spec?.fields.find(
            (f) => f.fieldName === fieldname,
        );

        return fld;
    }
    return (
        <Observer>
            {() => (
                <Gutters>
                    <Grid container spacing={3} style={gridStyle}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid
                                container
                                item
                                xs={3}
                                zeroMinWidth
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="h3">
                                    Define file extension handling. This is used
                                    to match file names. The result should match
                                    an id field in the metadata file.
                                </Typography>
                            </Grid>

                            <Grid container item xs={9}>
                                <Grid item container xs={12}>
                                    <FileExtView />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={1}>
                            <Grid
                                container
                                item
                                xs={3}
                                zeroMinWidth
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="h3">
                                    Where can we find your metadata? In a
                                    filename or separate metadata file?
                                </Typography>
                            </Grid>

                            <Grid container item xs={9}>
                                <Grid item container xs={12}>
                                    <FileFieldToggle
                                        whichSource={
                                            templateStore.model.metaSource
                                        }
                                        onToggle={onToggle}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid
                                container
                                item
                                xs={3}
                                zeroMinWidth
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="h3">
                                    Enter an appropriate delimiter to separate
                                    the fields. When a delimiter is entered the
                                    example string will be separated into
                                    columns.
                                </Typography>
                            </Grid>

                            <Grid container item xs={9}>
                                <Grid item container xs={12}>
                                    <MetadataView />
                                </Grid>
                            </Grid>
                        </Grid>

                        {templateStore.model.filename && (
                            <Grid container item xs={12} spacing={1}>
                                <Grid container item xs={12} spacing={2}>
                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            Select the{" "}
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                file name
                                            </span>{" "}
                                            field. This will be used as client
                                            call id
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        {templateStore.model.filename && (
                                            <ColumnMap
                                                onChange={onColumnChange}
                                                columnOptions={
                                                    templateStore.model
                                                        .fieldOptions
                                                }
                                                defaultValue={getFieldValues(
                                                    "Filename",
                                                )}
                                                rowLabel="Filename"
                                            />
                                        )}
                                    </Grid>

                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            Select the{" "}
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                agent name
                                            </span>{" "}
                                            field. Several variants are
                                            supported. Open select menu to view
                                            supported formats.
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        {templateStore.model.filename && (
                                            <ColumnMap
                                                onChange={onColumnChange}
                                                columnOptions={
                                                    templateStore.model
                                                        .fieldOptions
                                                }
                                                defaultValue={getFieldValues(
                                                    "Agent Name",
                                                )}
                                                rowLabel="Agent Name"
                                            />
                                        )}
                                    </Grid>

                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            Select the{" "}
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                interaction date
                                            </span>{" "}
                                            field. Please double check the
                                            format by clicking the plus icon.
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        {templateStore.model.filename && (
                                            <ColumnMap
                                                onChange={onColumnChange}
                                                columnOptions={
                                                    templateStore.model
                                                        .fieldOptions
                                                }
                                                defaultValue={getFieldValues(
                                                    "Interaction Date",
                                                )}
                                                rowLabel="Interaction Date"
                                            />
                                        )}
                                    </Grid>

                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            Select the{" "}
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                call direction
                                            </span>{" "}
                                            field.
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        {templateStore.model.filename && (
                                            <ColumnMap
                                                onChange={onColumnChange}
                                                columnOptions={
                                                    templateStore.model
                                                        .fieldOptions
                                                }
                                                defaultValue={getFieldValues(
                                                    "Call Direction",
                                                )}
                                                rowLabel="Call Direction"
                                            />
                                        )}
                                    </Grid>

                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                Misc. metadata
                                            </span>{" "}
                                            field
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        <ColumnMap
                                            onChange={onColumnChange}
                                            columnOptions={
                                                templateStore.model.fieldOptions
                                            }
                                            defaultValue={getFieldValues(
                                                "Meta 1",
                                            )}
                                            rowLabel="Meta 1"
                                        />
                                    </Grid>

                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                Misc. metadata
                                            </span>{" "}
                                            field
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        <ColumnMap
                                            onChange={onColumnChange}
                                            columnOptions={
                                                templateStore.model.fieldOptions
                                            }
                                            defaultValue={getFieldValues(
                                                "Meta 2",
                                            )}
                                            rowLabel="Meta 2"
                                        />
                                    </Grid>

                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                Misc. metadata
                                            </span>{" "}
                                            field
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        <ColumnMap
                                            onChange={onColumnChange}
                                            columnOptions={
                                                templateStore.model.fieldOptions
                                            }
                                            defaultValue={getFieldValues(
                                                "Meta 3",
                                            )}
                                            rowLabel="Meta 3"
                                        />
                                    </Grid>

                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                Misc. metadata
                                            </span>{" "}
                                            field
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        <ColumnMap
                                            onChange={onColumnChange}
                                            columnOptions={
                                                templateStore.model.fieldOptions
                                            }
                                            defaultValue={getFieldValues(
                                                "Meta 4",
                                            )}
                                            rowLabel="Meta 4"
                                        />
                                    </Grid>

                                    <Grid item container xs={3}>
                                        <Typography variant="h3">
                                            <span
                                                style={{
                                                    color: theme.palette.info
                                                        .main,
                                                }}
                                            >
                                                Misc. metadata
                                            </span>{" "}
                                            field
                                        </Typography>
                                    </Grid>

                                    <Grid item container xs={9}>
                                        <ColumnMap
                                            onChange={onColumnChange}
                                            columnOptions={
                                                templateStore.model.fieldOptions
                                            }
                                            defaultValue={getFieldValues(
                                                "Meta 5",
                                            )}
                                            rowLabel="Meta 5"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        <Grid
                            container
                            item
                            xs={4}
                            zeroMinWidth
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography variant="h3">
                                Save the Metadata specification
                            </Typography>
                        </Grid>

                        <Grid container item xs={8}>
                            <Grid item xs={3}>
                                <AcxButton fullWidth onClick={onComplete}>
                                    Build Configuration
                                </AcxButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Gutters>
            )}
        </Observer>
    );
}

export default MetadataSpecMainView;
