import { observer } from "mobx-react";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { durationDisplay } from "components/SoundClipEditor/Controls/Player";
import React from "react";
import { IMultiLanguageTranscriptionPayload } from "services/TranscriptionService";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import AcxMainTextField from "../../AcxMainTextFieldGrid";
import { AcxTranscriptionStore } from "../Stores/AcxTranscriptionStore";
import { styles } from "../styles";
import parser from "html-react-parser";
import { escape } from "lodash";

interface AcxTranscriptionSidebarProps {
    showTranslationUI?: boolean;
    handleChecked: (checked: boolean) => void;
    selectedLanguageCode?: "en" | "es";
    onChangeLanguage?: (
        languageCode: IMultiLanguageTranscriptionPayload["languageCode"],
    ) => void;
}

export const AcxTranscriptionSidebar: React.FC<AcxTranscriptionSidebarProps> =
    observer(
        ({
            showTranslationUI,
            selectedLanguageCode,
            onChangeLanguage,
            handleChecked,
        }) => {
            const store = useStore(AcxTranscriptionStore);
            const classes = useStyles(styles);

            const searchResults = store.getSearchResults();

            return (
                <>
                    <Grid
                        item
                        xs={12}
                        style={{ paddingRight: "10px" }}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "10%",
                            paddingLeft: "15px",
                            paddingTop: "10px",
                        }}
                    >
                        <AcxMainTextField
                            labelText="Search"
                            id="Transcription-text-search"
                            value={
                                !store.isCheckedEddyEffectP2
                                    ? store.searchString
                                    : ""
                            }
                            placeholderText="Search by keyword"
                            onChange={(e) => {
                                store.selectIndex = -1;
                                store.searchString = e.target.value;
                            }}
                            disabled={store.isCheckedEddyEffectP2}
                            endAdornment={
                                store.searchString !== "" && (
                                    <IconButton
                                        size="small"
                                        onClick={() =>
                                            (store.searchString = "")
                                        }
                                    >
                                        <ClearIcon fontSize="small" />
                                    </IconButton>
                                )
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "10%",
                            paddingLeft: "15px",
                            paddingTop: "10px",
                        }}
                    >
                        <span
                            style={{
                                color: "#3664D5",
                                fontWeight: "bold",
                            }}
                        ></span>
                        {!!searchResults?.length && (
                            <Typography variant="h2" gutterBottom={true}>
                                {`${searchResults.length} results for '${store.searchString}'`}
                            </Typography>
                        )}
                        {searchResults &&
                            searchResults.map((resultsObj, i) => (
                                <Grid
                                    key={i}
                                    container
                                    item
                                    xs={12}
                                    onClick={() => {
                                        if (!resultsObj.phraseStartTime) return;
                                        const index = store.blurbs.findIndex(
                                            ({ timeInterval }) =>
                                                resultsObj.phraseStartTime <=
                                                timeInterval[0],
                                        );
                                        store.setScrollIndex(
                                            index !== -1 ? index : null,
                                        );
                                    }}
                                    className={classes.resultsContainer}
                                >
                                    <Grid item xs={9}>
                                        <span
                                            style={{
                                                whiteSpace: "normal",
                                            }}
                                            className="pendo-ignore"
                                        >
                                            {parser(
                                                store.formatTranscriptForHighlight(
                                                    escape(resultsObj.stmt),
                                                ),
                                            )}
                                        </span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <span>
                                            {durationDisplay(
                                                resultsObj.startTime,
                                            )}
                                        </span>
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                    {showTranslationUI && (
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                minWidth: "10%",
                                paddingLeft: "15px",
                                paddingTop: "10px",
                            }}
                            style={{ paddingRight: "10px" }}
                        >
                            <Typography variant="h2" gutterBottom={true}>
                                Transcript Language
                            </Typography>
                            <FormControl component="fieldset">
                                <RadioGroup
                                    aria-label="language"
                                    value={selectedLanguageCode}
                                    name="radio-buttons-group"
                                    onChange={(event) => {
                                        onChangeLanguage?.(
                                            event.target
                                                .value as IMultiLanguageTranscriptionPayload["languageCode"],
                                        );
                                    }}
                                    defaultValue={selectedLanguageCode}
                                >
                                    <FormControlLabel
                                        value="en"
                                        control={<Radio />}
                                        label="English"
                                    />
                                    <FormControlLabel
                                        value="es"
                                        control={<Radio />}
                                        label="Spanish (Original)"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "10%",
                            paddingLeft: "15px",
                            paddingTop: "10px",
                        }}
                    >
                        <Typography variant="h2" gutterBottom={true}>
                            Models
                        </Typography>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={store.isCheckedEddyEffectP2}
                                    onChange={(e) => {
                                        handleChecked(e.target.checked);
                                    }}
                                    name="SampleToMiddleOfTheFunnel"
                                />
                            }
                            label={
                                <Typography
                                    color={"textSecondary"}
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                    }}
                                    variant={"subtitle2"}
                                >
                                    Eddy Effect Signal
                                </Typography>
                            }
                        />
                    </Grid>
                </>
            );
        },
    );
