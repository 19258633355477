import React from "react";
import { FormFieldErrors } from "shared/types/FormErrors.type";
import ServiceSetupRow from "./ServiceSetupRow";

const lineColors = ["#fa7750", "#3464d5", "#30dbb9"];

type ServiceSetupRowsProps = {
    items: string[];
    removeRow: (index: number) => void;
    updateRow: (index: number, val: string) => void;
    fieldErrors?: FormFieldErrors;
    disabled?: boolean;
};

const ServiceSetupRows: React.FC<React.PropsWithChildren<React.PropsWithChildren<ServiceSetupRowsProps>>> = ({
    items,
    removeRow,
    updateRow,
    fieldErrors,
    disabled,
}) => {
    return (
        <>
            {items.map((item, i) => (
                <div key={i} style={{ paddingLeft: 16 * i + 16 * (i - 1) }}>
                    <div
                        style={
                            i > 0
                                ? {
                                      paddingLeft: 16,
                                      borderLeft: `4px solid ${
                                          lineColors[i % 3]
                                      }`,
                                  }
                                : {}
                        }
                    >
                        <ServiceSetupRow
                            item={item}
                            updateRow={updateRow.bind(null, i)}
                            removeRow={removeRow.bind(null, i)}
                            error={fieldErrors?.[`ServiceSetup[${i}]`]}
                            disabled={disabled}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export default ServiceSetupRows;
