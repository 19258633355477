import { Grid, IconButton, Paper } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveIcon from "@mui/icons-material/Remove";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import { MetadataComparisonOperators } from "models/OrganizationModels/OrganizationMetadataConfiguration";
import React, { FunctionComponent } from "react";
import { Campaign } from "services/CampaignService";
import useStyles from "Styles/Styles";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import {
    LOAD_CONFIG,
    OrganizationMetadataStore,
} from "./Store/OrganizationMetadataStore";

const styles = () =>
    createStyles({
        fieldSpacing: {
            margin: "0.25rem 0",
            padding: "0 0 0 0.125rem",
        },
        metadataContainer: {
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
        },
        inputSpacing: { paddingLeft: ".125rem" },
    });

interface MetadataCampaignRowProps {
    configIndex: number;
    id: string;
    currentIndex: number;
    store: OrganizationMetadataStore;
}

const MetadataCampaignRow: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<MetadataCampaignRowProps>>> =
    observer((props) => {
        const { configIndex, store, currentIndex, id } = props;

        const classes = useStyles(styles);

        const onFieldPathChange = React.useCallback(
            (event: string, index: number) => {
                store.handleCampaignFieldPathUpdates(configIndex, event, index);
            },
            [configIndex, store],
        );

        const onCampaignChange = React.useCallback(
            (event: Campaign, index: number) => {
                store.handleCampaignUpdates(configIndex, event.id, index);
            },
            [configIndex, store],
        );

        const onComparisonOperatorChange = React.useCallback(
            (event: string, index: number) => {
                store.handleCampaignComparisonUpdates(
                    configIndex,
                    Number(event),
                    index,
                );
            },
            [configIndex, store],
        );

        const onIndicatorStringChange = React.useCallback(
            (event: string, index: number, stringIndex: number) => {
                store.handleCampaignIndicatorStringUpdates(
                    configIndex,
                    event,
                    index,
                    stringIndex,
                );
            },
            [configIndex, store],
        );

        const campaignIndicatorArray =
            store.organizationMetadataConfiguration[configIndex]
                ?.configuration?.campaignIndicatorDefinitions ?? [];

        const currentCampaignRow = campaignIndicatorArray[currentIndex];

        const comparisonChoices = EnumToDropdownOptionConversion(
            MetadataComparisonOperators,
        );

        return !!currentCampaignRow && (
            <Paper className={classes.metadataContainer}>
                <Grid
                    container
                    item
                    xs={12}
                    className={classes.fieldSpacing}
                >
                    <Grid container item xs={12}>
                        <Grid item xs={3} className={classes.inputSpacing}>
                            <AcxSelect
                                inputLabel="Campaign"
                                fullWidth
                                defaultValue={store.orgCampaigns.find(
                                    (option) =>
                                        option.id ===
                                        currentCampaignRow?.campaignId,
                                )}
                                id={`field_${currentIndex}`}
                                labelField="externalName"
                                valueField="id"
                                options={store.orgCampaigns ?? []}
                                onChange={(event) =>
                                    onCampaignChange(event, currentIndex)
                                }
                                placeholder="Campaign"
                            />
                        </Grid>

                        <Grid item xs={6} className={classes.inputSpacing}>
                            <AcxMainTextField
                                fullWidth
                                skeleton={store.getTaskLoading(LOAD_CONFIG)}
                                labelText="Field Path"
                                id="fieldPath"
                                value={currentCampaignRow?.fieldPath}
                                onChange={(event) =>
                                    onFieldPathChange(
                                        event.target.value,
                                        currentIndex,
                                    )
                                }
                                showAllErrors={true}
                            />
                        </Grid>

                        <Grid item xs={2} className={classes.inputSpacing}>
                            <AcxSelect
                                inputLabel="Comparison Operator"
                                fullWidth
                                defaultValue={comparisonChoices.find(
                                    (item) =>
                                        item.value ===
                                        currentCampaignRow.comparisonOperator,
                                )}
                                id={`campaignComparisonOperator_${currentIndex}`}
                                options={comparisonChoices}
                                onChange={(event) =>
                                    onComparisonOperatorChange(
                                        event.value,
                                        currentIndex,
                                    )
                                }
                                placeholder="Comparison Operator"
                            />
                        </Grid>

                        <Grid
                            container
                            item
                            xs={1}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <IconButton
                                onClick={() =>
                                    store.removeFieldConfiguration(
                                        "campaignIndicatorDefinitions",
                                        id,
                                        configIndex,
                                    )
                                }
                                size="large">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        item
                        xs={12}
                        className={classes.fieldSpacing}
                    >
                        <Grid item container xs={10}>
                            {!!currentCampaignRow.campaignIndicatorStrings
                                .length &&
                                currentCampaignRow.campaignIndicatorStrings.map(
                                    (
                                        campaignIndicatorString,
                                        stringIndex,
                                    ) => (
                                        <Grid
                                            item
                                            xs={3}
                                            className={classes.inputSpacing}
                                        >
                                            <AcxMainTextField
                                                fullWidth
                                                skeleton={store.getTaskLoading(
                                                    LOAD_CONFIG,
                                                )}
                                                labelText="Campaign Indicator String"
                                                id="campaignIndicatorString"
                                                value={
                                                    campaignIndicatorString
                                                }
                                                onChange={(event) =>
                                                    onIndicatorStringChange(
                                                        event.target.value,
                                                        currentIndex,
                                                        stringIndex,
                                                    )
                                                }
                                                showAllErrors={true}
                                            />
                                        </Grid>
                                    ),
                                )}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={2}
                            justifyContent="flex-end"
                        >
                            <IconButton
                                onClick={() => {
                                    store.addCampaignIndicatorString(
                                        configIndex,
                                        currentIndex,
                                    );
                                }}
                                size="large">
                                <AddIcon />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    currentCampaignRow.campaignIndicatorStrings.pop();
                                }}
                                size="large">
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        );
    });

export default MetadataCampaignRow;
