import UserRole from "models/Permission/UserRole";
import { useEffect, useState } from "react";
import UserService from "../OrganizationDetail/OrganizationUsers/User.service";

const useRoles = (prop: { orgId: string }) => {
    const [roles, setRoles] = useState<UserRole[] | null>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let destroyed = false;
        const runFn = async () => {
            try {
                setIsLoading(true);
                const res = await UserService.getRoles();
                if (!destroyed) {
                    setRoles(res);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };
        runFn();
        return () => {
            destroyed = true;
            setRoles(null);
        };
    }, [prop.orgId]);

    return {
        roles,
        isLoading,
    };
};

export default useRoles;
