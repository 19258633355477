import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import React, { useEffect, useState } from "react";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { ReportsNavigation, ReportsRoutes } from "./ReportsNavigation";
import ReportsStore from "./Stores/ReportsStore";

const styles = (theme: Theme) =>
    createStyles({
        sideControlMain: {
            padding: 0,
        },
    });
function ReportsRv2() {
    const store = useStore(ReportsStore);
    const classes = useStyles(styles);
    const [drawerClosed, setDrawerClosed] = useState(true);
    useEffect(() => {
        store.drawerClosed = drawerClosed;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawerClosed]);

    return (
        <SideControlsPage
            mainContent={<ReportsRoutes />}
            enableMinimize
            containerOffsetPixels={64}
            drawerContent={<ReportsNavigation />}
            drawerLocation="left"
            mainGridAlignItems="stretch"
            mainGridJustify="flex-start"
            mainGridSpacing={0}
            mainContentClasses={classes.sideControlMain}
            onDrawerChange={(closed) => {
                setDrawerClosed(closed);
            }}
        />
    );
}

export default ReportsRv2;
