import React from "react";
import { GoogleChartWrapper, GoogleViz } from "react-google-charts/dist/types";
import { VizType } from "../../../../models/Reporting/ReportModel";
import AcxDataGrid from "../../../UI/AcxDataGrid/AcxDataGrid";
import AcxDataGridStore from "../../../UI/AcxDataGrid/AcxDataGridStore";
import AcxHistogram from "../../../UI/Visualization/AcxHistogram";
import AcxHorizontalBarGraph from "../../../UI/Visualization/AcxHorizontalBarGraph";
import AcxLineGraph from "../../../UI/Visualization/AcxLineGraph";
import AcxPieGraph from "../../../UI/Visualization/AcxPieGraph";
import AcxScatterPlot from "../../../UI/Visualization/AcxScatterPlotGraph";
import AcxVerticalBarGraph from "../../../UI/Visualization/AcxVerticalBarGraph";
import EmptyReportIndicator from "../EmptyReportIndicator";

export function buildReportVizType(
    vizType: VizType | undefined,
    emptyData: boolean,
    vizFormattedData: any[] | undefined,
    tableFormattedData: AcxDataGridStore | undefined,
    xAxisTitle: string | undefined,
    yAxisTitle: string | undefined,
    tableVizOverride: boolean | undefined,
    onDrillDownClick?: (
        series: string | null,
        x: string | number | null,
        y: string | number | null | any,
    ) => void,
    cardHeight?: number,
    controlsHeight?: number,
    asWidget?: boolean,
    vizOptions?: any,
    onChartReady?: (
        chartWrapper: GoogleChartWrapper,
        google: GoogleViz,
    ) => void,
) {
    let chartBody: React.ReactNode = null;
    if (
        vizType === undefined ||
        (!vizFormattedData && !tableFormattedData) ||
        (emptyData && vizType !== VizType.Table)
    ) {
        return (
            <>
                {Boolean(cardHeight) && (
                    <>
                        <EmptyReportIndicator
                            parentHeightPx={
                                (cardHeight ?? 0) - (controlsHeight ?? 0)
                            }
                            vizType={vizType}
                        />
                    </>
                )}
            </>
        );
    }
    if (!tableVizOverride && vizType === VizType.Scatter) {
        chartBody = (
            <>
                {vizFormattedData && vizFormattedData.length > 0 && (
                    <AcxScatterPlot
                        showGrid
                        onSelect={onDrillDownClick}
                        asWidget={asWidget}
                        data={vizFormattedData}
                        yTitle={yAxisTitle}
                        xTitle={xAxisTitle}
                        onChartReady={onChartReady}
                        legendPosition={"top"}
                        {...(vizOptions ?? {})}
                    />
                )}
            </>
        );
    }
    if (!tableVizOverride && vizType === VizType.Bar) {
        chartBody = (
            <>
                {vizFormattedData && vizFormattedData.length > 0 && (
                    <AcxVerticalBarGraph
                        showGrid
                        onSelect={onDrillDownClick}
                        asWidget={asWidget}
                        data={vizFormattedData}
                        yTitle={yAxisTitle}
                        xTitle={xAxisTitle}
                        onChartReady={onChartReady}
                        legendPosition={"top"}
                        {...(vizOptions ?? {})}
                    />
                )}
            </>
        );
    } else if (!tableVizOverride && vizType === VizType.HorizontalBar) {
        chartBody = (
            <>
                {vizFormattedData && vizFormattedData.length > 0 && (
                    <AcxHorizontalBarGraph
                        showGrid
                        onSelect={onDrillDownClick}
                        asWidget={asWidget}
                        // chartAreaLeftOffset={"9%"}
                        data={vizFormattedData}
                        textPosition={undefined}
                        yTitle={xAxisTitle}
                        xTitle={yAxisTitle}
                        onChartReady={onChartReady}
                        legendPosition={"top"}
                        {...(vizOptions ?? {})}
                    />
                )}
            </>
        );
    } else if (!tableVizOverride && vizType === VizType.Line) {
        chartBody = (
            <>
                {vizFormattedData && vizFormattedData.length > 0 && (
                    <AcxLineGraph
                        showGrid
                        onSelect={onDrillDownClick}
                        asWidget={asWidget}
                        data={vizFormattedData}
                        yTitle={yAxisTitle}
                        xTitle={xAxisTitle}
                        onChartReady={onChartReady}
                        legendPosition={"top"}
                        {...(vizOptions ?? {})}
                    />
                )}
            </>
        );
    } else if (!tableVizOverride && vizType === VizType.Pie) {
        chartBody = (
            <>
                {vizFormattedData && vizFormattedData.length > 0 && (
                    <AcxPieGraph
                        onSelect={onDrillDownClick}
                        asWidget={asWidget}
                        data={vizFormattedData}
                        onChartReady={onChartReady}
                        legendPosition={"right"}
                        {...(vizOptions ?? {})}
                    />
                )}
            </>
        );
    } else if (!tableVizOverride && vizType === VizType.Histogram) {
        chartBody = (
            <>
                {vizFormattedData && vizFormattedData.length > 0 && (
                    <AcxHistogram
                        showGrid
                        onSelect={onDrillDownClick}
                        asWidget={asWidget}
                        data={vizFormattedData}
                        yTitle={xAxisTitle}
                        xTitle={yAxisTitle}
                        onChartReady={onChartReady}
                        legendPosition={"top"}
                        {...(vizOptions ?? {})}
                    />
                )}
            </>
        );
    } else if (tableVizOverride || vizType === VizType.Table) {
        const data = tableFormattedData as any;
        chartBody = (
            <div style={{ height: "calc(100vh - 350px)" }}>
                {data && <AcxDataGrid dataGridStore={data} />}
            </div>
        );
    }

    return chartBody;
}
