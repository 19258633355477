import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import ReportEditorRv2 from "components/ReportsRv2/Editor/ReportEditorRv2";
import React from "react";
import { Route, Routes } from "react-router-dom";
import theme from "Theme/AppTheme";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function Focus() {
    return (
        <>
            <CssBaseline />
            <Routes>
                <Route
                    path="reporteditor/:orgId/"
                    element={<ReportEditorRv2 />}
                />
                <Route
                    path="reporteditor/:orgId/:reportId"
                    element={<ReportEditorRv2 />}
                />
            </Routes>
        </>
    );
}

export default function FocusContainerRv2(props) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Focus />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
