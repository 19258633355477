import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const ServiceHierarchySvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="25px"
            height="25px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Admin"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.5}
            >
                <g
                    id="Admin---Organization---Modules-View"
                    transform="translate(-35.000000, -327.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Left-Nav" transform="translate(0.000000, 64.000000)">
                        <g
                            id="Group-10"
                            transform="translate(24.000000, 104.000000)"
                        >
                            <g
                                id="Group-3"
                                transform="translate(0.000000, 32.000000)"
                            >
                                <g
                                    id="Group-4-Copy-6"
                                    transform="translate(0.000000, 120.000000)"
                                >
                                    <g
                                        id="Icon/Building"
                                        transform="translate(11.000000, 7.000000)"
                                    >
                                        <path
                                            d="M11.00125,13.0025 L11.00125,17.005 L6.99875,17.005 L6.99875,13.0025 L11.00125,13.0025 Z M17.005,13.0025 L17.005,17.005 L13.0025,17.005 L13.0025,13.0025 L17.005,13.0025 Z M4.9975,13.0025 L4.9975,17.005 L0.995,17.005 L0.995,13.0025 L4.9975,13.0025 Z M10.000625,5.998125 L10.000625,7.999375 L15.00375,7.999375 C15.604125,7.999375 16.004375,8.399625 16.004375,9 L16.004375,9 L16.004375,12.001875 L14.003125,12.001875 L14.003125,10.000625 L10.000625,10.000625 L10.000625,12.001875 L7.999375,12.001875 L7.999375,10.000625 L3.996875,10.000625 L3.996875,12.001875 L1.995625,12.001875 L1.995625,9 C1.995625,8.399625 2.395875,7.999375 2.99625,7.999375 L2.99625,7.999375 L7.999375,7.999375 L7.999375,5.998125 L10.000625,5.998125 Z M11.00125,0.995 L11.00125,4.9975 L6.99875,4.9975 L6.99875,0.995 L11.00125,0.995 Z"
                                            id="Combined-Shape"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ServiceHierarchySvg;
