import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React from "react";
import { EvaluationStatus } from "../../../../models/Evaluation";
import AlertSvg from "../../../../SvgIcons/AlertSvg";

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    messageText: (props: Props) => ({
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    }),
    infoSVG: {
        marginRight: theme.spacing(2.7),
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        color: "red",
    },
}));

interface OwnProps {
    hasDisputedAnswers?: boolean;
    evaluationStatus?: EvaluationStatus;
    canChangeDisputedAnswers?: boolean;
    isEvalEditable?: boolean;
}

type Props = OwnProps;

const ModuleDisputedMessage = observer((props: Props) => {
    const classes = useStyles(props);
    let message = "";

    if (props.evaluationStatus === EvaluationStatus.Escalated) {
        message =
            "This module's score has fallen below the specified escalation threshold.";
    } else {
        message = "This module contains one or more disputed answers.";
        if (!props.canChangeDisputedAnswers) {
            message += " However, you are not allowed to change these answers.";
        }
    }

    return (
        <>
            {props.hasDisputedAnswers && props.isEvalEditable && (
                <Grid
                    item
                    xs={12}
                    style={{
                        paddingLeft: "16px",
                        paddingRight: "16px",
                    }}
                    container
                    direction={"column"}
                    justifyContent={"flex-start"}
                    alignItems="stretch"
                >
                    <Grid
                        item
                        xs={12}
                        style={{
                            border: "1px solid red",
                            borderRadius: "5px",
                            backgroundColor: "#FEEEEE",
                            paddingLeft: "5px",
                            marginBottom: "24px",
                        }}
                        container
                        direction={"row"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        wrap={"nowrap"}
                    >
                        <Grid item className={classes.infoSVG}>
                            <AlertSvg color="red" opacity={0.75} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.messageText}>
                                {message}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
});

export default ModuleDisputedMessage;
