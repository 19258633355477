import { AddSharp } from "@mui/icons-material";
import { Grid, Link } from "@mui/material";
import { GridCellParams, GridRenderCellParams } from "@mui/x-data-grid-pro";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { dateTimeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { Observer, observer } from "mobx-react";
import React, { useCallback, useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import {
    GET_LOGIN_HISTORY,
    OrganizationUserStore,
} from "./stores/OrganizationUserStore";

type Props = {
    orgId: string;
    AgentUsers?: any;
};

const OrganizationUsersList: React.FC<Props> = observer(({ orgId }) => {
    const userStore = useStore(OrganizationUserStore);
    const authStore = useStore(AuthStore);
    const permStore = authStore.permStore;
    const navigate = useNavigate();

    const exportUsers = useCallback(() => {
        var userList = [] as Object[];

        const allUsers = userStore.dgStore.rows;

        if (allUsers.length) {
            for (let i = 0; i < allUsers.length; i++) {
                userList.push({
                    userName: allUsers[i].userName ? allUsers[i].userName : "",
                    firstName: allUsers[i].firstName
                        ? allUsers[i].firstName
                        : "",
                    lastName: allUsers[i].lastName ? allUsers[i].lastName : "",
                    rolePermission: allUsers[i].roleName
                        ? allUsers[i].roleName
                        : "",
                });
            }
        }

        var json = JSON.stringify(userList);
        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/json;charset=UTF-8," + encodeURIComponent(json),
        );
        element.setAttribute("download", "userList.json");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }, [userStore.dgStore.rows]);

    useEffect(() => {
        userStore.loadUsers(orgId);
    }, [orgId, userStore]);
    useEffect(() => {
        const columns: IColDef[] = [
            {
                headerName: "User Name",
                field: "userName",
                width: 250,
                renderCell: (params: GridCellParams) => (
                    <Grid item>
                        <Link
                            component={RouterLink}
                            to={`/admin/organizations/${orgId}/users/${params.row.id}`}
                            color="primary"
                            style={{ fontWeight: "bold" }}
                            className="pendo-ignore"
                            underline="none"
                        >
                            {params.value as React.ReactNode}
                        </Link>
                    </Grid>
                ),
            },
            {
                headerName: "First Name",
                field: "firstName",
                width: 150,
            },
            {
                headerName: "Last Name",
                field: "lastName",
                width: 150,
            },
            {
                headerName: "Role",
                field: "roleName",
                width: 150,
            },
            {
                field: "disabled",
                headerName: "Disabled",
                renderCell: (params: GridCellParams) =>
                    params.row.disabled ? "True" : " ",
            },
            {
                field: "createdOn",
                headerName: "Created",
                ...dateTimeColumnType,
            },
            {
                field: "lastLogin",
                headerName: "Last Login",
                ...dateTimeColumnType,
            },
            {
                field: "emailConfirmed",
                headerName: "Email Confirmed",
                type: "boolean",
                width: 150,
            },
            {
                field: "samlProviderUserCode",
                headerName: "SAML User Code",
                width: 150,
            },
            {
                field: "id",
                headerName: "User Login History",
                type: "string",
                display: "flex",
                renderCell: (params: GridRenderCellParams) => (
                    <Grid item>
                        <AcxButton
                            onClick={() =>
                                userStore.getUserLoginHistory(
                                    params.value as string,
                                    params.api.getRow(params.id).userName,
                                )
                            }
                            color="primary"
                            loading={userStore.getTaskLoading(
                                GET_LOGIN_HISTORY,
                            )}
                            disabled={userStore.getTaskLoading(
                                GET_LOGIN_HISTORY,
                            )}
                        >
                            View
                        </AcxButton>
                    </Grid>
                ),
                filterable: false,
                sortable: false,
            },
        ];
        const getCurrentDisplay = function () {
            if (userStore.AgentUsers) {
                userStore.AgentUsers = false;
                userStore.displayUsers();
                userStore.AgentUsersLabel = "Show Agent Users";
            } else {
                userStore.AgentUsers = true;
                userStore.displayUsers();
                userStore.AgentUsersLabel = "Show Users";
            }
        };
        const loginHistoryColums: IColDef[] = [
            {
                field: "loginDate",
                headerName: "Login Date",
                flex: 1,
                ...dateTimeColumnType,
            },
        ];

        userStore.orgId = orgId;
        userStore.dgStoreLogin.setColumns(loginHistoryColums);
        userStore.dgStore.setColumns(columns);
        userStore.dgStore.removeHeight = "65px";
        userStore.dgStore.controlsColumnStyle = {
            justifyContent: "flex-end",
            marginTop: "0.5rem",
        };
        userStore.dgStore.controls = [
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            fullWidth
                            color="primary"
                            onClick={getCurrentDisplay}
                            style={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                        >
                            {userStore.AgentUsersLabel}
                        </AcxButton>
                    </div>
                )}
            </Observer>,
            <AcxButton
                fullWidth
                disabled={!authStore.canUserEdit("Users")}
                color="primary"
                startIcon={<AddSharp />}
                onClick={() => {
                    navigate(`/admin/organizations/${orgId}/users/add`);
                }}
                style={{
                    padding: "1rem",
                    minWidth: "max-content",
                }}
            >
                Add
            </AcxButton>,

            permStore.isElevated ? (
                <AcxButton
                    fullWidth
                    color="primary"
                    disabled={userStore.dgStore.isLoading}
                    loading={userStore.dgStore.isLoading}
                    onClick={exportUsers}
                    style={{
                        padding: "1rem",
                        minWidth: "max-content",
                    }}
                >
                    Export All Users
                </AcxButton>
            ) : (
                <></>
            ),
        ];
    }, [
        exportUsers,
        navigate,
        orgId,
        permStore,
        userStore,
        authStore,
        userStore.dgStore.isLoading,
    ]);

    return (
        <>
            <div style={{ height: "90vh", width: "70vw" }}>
                <AcxDataGrid dataGridStore={userStore.dgStore} />
            </div>
        </>
    );
});

export default OrganizationUsersList;
