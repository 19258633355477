import React, { FunctionComponent } from "react";
import icon from "./PaperAirplane.svg";

const PaperAirplaneSvg: FunctionComponent<
    React.ComponentPropsWithoutRef<"img">
> = (props) => {
    return <img {...props} src={icon} alt="paper-airplane" />;
};

export default PaperAirplaneSvg;
