import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import AcxToast from "components/UI/AcxToast";
import { EvalStore } from "components/Evaluation/Stores/EvalStore";

const redactionWarningStyles = makeStyles((theme: Theme) => ({
    redactionWarningContainer: {
        position: "relative",
        margin: "0.4rem auto 0.4rem auto",
        padding: "0px 32px",
    },
}));

const NoRedactionWarning: React.FunctionComponent = observer(() => {
    const convoStore = useStore(ConversationsStore);
    const evalStore = useStore(EvalStore);
    const classes = redactionWarningStyles();

    // This is no good. This component should know nothing about the the stores
    const determineOnCloseTarget = () => {
        const evalDomain = window.location.href.includes("evaluation");
        const convoDomain = window.location.href.includes("conversation");
        if (convoDomain) {
            convoStore.setShowNotRedactedWarning(false);
        } else if (evalDomain) {
            evalStore.setShowNotRedactedWarning(false);
        }
    };

    return (
        <Grid
            item
            container
            className={classes.redactionWarningContainer}
            id={"toast--no-redaction-error"}
        >
            <span style={{ width: "100%" }}>
                <AcxToast
                    fullWidth
                    severity="infoV2"
                    message={
                        "This conversation is not redacted because it was processed before redaction was enabled for this organization."
                    }
                    title={""}
                    onClose={() => determineOnCloseTarget()}
                    fullWidthMessage={true}
                />
            </span>
        </Grid>
    );
});

export default NoRedactionWarning;
