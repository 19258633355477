import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { Action } from "../../../../../../models/Actions/Action";
import { EvalReviewActionMetadata } from "../../../../../../models/Actions/Evaluation/EvalReviewActionMetadata";
import ResponseDetails from "../ResponseDetails";

export const RenderActionResponses = observer(
    (props: {
        responses: Array<Action<EvalReviewActionMetadata>> | undefined | null;
        parentId: string;
        level: number;
        enableComments: boolean;
        totalResponses: number;
    }) => {
        const res = useMemo(
            () => RenderActionResponsesInternal(props),
            [props],
        );

        return <>{res}</>;
    },
);
const RenderActionResponsesInternal = (props: {
    responses: Array<Action<EvalReviewActionMetadata>> | undefined | null;
    parentId: string;
    enableComments: boolean;
    level: number;
    totalResponses: number;
}) => {
    if (!props.responses) {
        return [<React.Fragment key={`empty-level-${props.parentId}`} />];
    }

    const components: React.ReactElement[] = [];
    props.responses
        .sort((a, b) => ((a.createdOn ?? "") < (b.createdOn ?? "") ? -1 : 1))
        .forEach((response, index) => {
            if (response) {
                components.push(
                    <ResponseDetails
                        enableComments={props.enableComments}
                        key={response.id}
                        response={response}
                        totalResponses={props.totalResponses}
                        responseIndex={index}
                        level={props.level}
                    />,
                );
            }
            if (response.actionResponses) {
                components.push(
                    ...RenderActionResponsesInternal({
                        responses: response.actionResponses,
                        parentId: response.id,
                        level: props.level + 1,
                        enableComments: props.enableComments,
                        totalResponses: response.actionResponses.length,
                    }),
                );
            }
        });

    return components;
};
