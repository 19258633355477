import BaseEntity from "./BaseEntity";
import ClassifierCategory from "./ClassifierCategoryId";
import ClassifierGroup from "./ClassifierGroup";
import ClassifierImplementation from "./ClassifierImplementationModel";

export default interface Classifier extends BaseEntity {
    name: string;
    description: string;
    classifierIdentifier: number;
    organizationId: string;
    classifierImplementations: ClassifierImplementation[];
    isPublished: boolean;
    expiresOn: string;
    classifierTypeName: ClassifierType;
    classifierOutputs: any;
    classifierCategory: ClassifierCategory;
    classifierGroup: ClassifierGroup;
}

export interface ClassifierForLibrary {
    id: string;
    name: string;
    description: string;
    groupId: string;
    groupName: string;
    existsInTenant: boolean;
}

export type ClassifierLibrary = Record<string, ClassifierForLibrary[]>;

export interface AddClassifierGroupResult {
    successfulClassifierNames: string[];
    failedClassifierNames: string[];
}

export interface CreateClassifierReturn {
    classifier: Classifier;
    classifierId: string;
    createdBy: string;
    createdOn: string;
    modifiedBy: string;
    modifiedOn: string;
    id: string;
    isActive: boolean;
    queryString: string;
    threshold: number;
    versionNumber: number;
}

export enum ResultType {
    StringType,
    NumberType,
}

export enum ClassifierType {
    Lucene = "Lucene",
    Metadata = "Metadata",
    ExtendedMetadata = "ExtendedMetadata",
    Tensorflow = "Tensorflow",
    Database = "Database",
}

export class ClassifierUtils {
    // converts a lucene search string to a list of search terms
    static searchStringToTerms(searchString?: string) {
        if (!searchString) {
            return [];
        }
        var split = searchString.match(/(?:[^\s"]+|"[^"]*")+/g);
        if (!split) return [];
        return split?.map((s) => s.replace(/"/g, ""));
    }
}
