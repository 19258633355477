import { useCallback } from "react";
import { addSearch } from "shared/functions/addSearch";
import useList from "shared/hooks/useList";
import RoleService from "../OrganizationDetail/OrganizationRoles/Role.service";

const useOrganizationRoles = (orgId: string) => {
    const getData = useCallback(async () => {
        const res = await RoleService.getRoles(orgId);
        return addSearch(res, ["name"]);
    }, [orgId]);

    const {
        isLoading,
        data: roles,
        refetch,
    } = useList<any>({
        get: getData,
    });

    const save = (val: any) => {
        return RoleService.saveRole(val);
    };

    return {
        roles,
        isLoading,
        refetch,
        save,
    };
};

export default useOrganizationRoles;
