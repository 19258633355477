import { Box, Divider, Grid, IconButton, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import React from "react";
import { Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import WordsPhraseStore, { IWordsPhrasesModel } from "./WordsPhrasesStore";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxButton from "components/UI/AcxButton";
import theme from "Theme/AppTheme";
import InfoIcon from "@mui/icons-material/Info";
import { SAID_WITHIN_MAX } from "constants/FullTextSearch";

interface Props {
    item: IWordsPhrasesModel;
    index: number;
    store: WordsPhraseStore;
}

const useWordsPhrasesItemStyles = makeStyles((theme: Theme) => ({
    itemContainer: {
        padding: "8px",
        backgroundColor: theme.palette.lightgray.main,
        borderRadius: "4px",
        width: "100%",
    },
    andOrContainer: {
        marginTop: "12px",
        marginBottom: "12px",
        height: "28px",
        position: "relative",
    },
    andOrInner: {
        color: "#1E40AF",
        backgroundColor: theme.palette.lightBlue.background,
        borderRadius: "4px",
        padding: "5px 10px 5px 10px",
        position: "absolute",
        cursor: "pointer",
    },
    orDivider: {
        width: "100%",
        backgroundColor: theme.palette.lightBlue.background,
        height: "2px",
    },
    closeContainer: {
        "&:hover $closeButton": {
            visibility: "visible",
        },
    },
    closeButton: {
        visibility: "hidden",
    },
    paddingTop1: {
        paddingTop: theme.spacing(1),
    },
    marginRight1: {
        marginRight: theme.spacing(1),
    },
    infoIcon: {
        height: "16px",
        width: "16px",
        marginLeft: theme.spacing(0.5),
    },
    addConditionButtonContainer: { width: "70px" },
}));

const WordsPhraseItem: React.FunctionComponent<Props> = observer(
    ({ item, index, store }) => {
        const classes = useWordsPhrasesItemStyles();

        const [saidWithinInputVisible, setSaidWithinInputVisible] =
            React.useState(false);
        const [saidWithinEnabled, setSaidWithinEnabled] = React.useState(false);

        React.useEffect(() => {
            if (item.searchPhrase.includes(" ") && item.value === "Contains") {
                setSaidWithinEnabled(true);
            } else {
                setSaidWithinEnabled(false);
            }
        }, [index, item.searchPhrase, item.value]);

        const listLength = store.wordsPhrasesItems.length;
        const lastItemIndex = listLength - 1;

        const nextItem =
            index !== lastItemIndex
                ? store.wordsPhrasesItems[index + 1]
                : undefined;

        const showAndOrChip = listLength !== 1 && index !== lastItemIndex;

        const isAnd = item.filterGroupId === nextItem?.filterGroupId;

        const selectOptions = [
            { label: "Contains", value: "Contains" },
            {
                label: "Does not contain",
                value: "Does not contain",
            },
            {
                label: "Starts with",
                value: "Starts with",
            },
        ];

        const hasErrorAtIndex =
            store.hasError && store.errorIndices.includes(index);

        const saidWithinError =
            !!item.saidWithin &&
            (item.saidWithin < 0 || item.saidWithin > SAID_WITHIN_MAX);

        return (
            <>
                <Grid className={classes.itemContainer}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.closeContainer}
                    >
                        <AcxSelect
                            id={"words-phrases-contains-select-" + index}
                            options={selectOptions}
                            defaultValue={selectOptions.find(
                                (i) => i.value === item.value,
                            )}
                            customStyle={{
                                container: (provided, state) => ({
                                    ...provided,
                                    backgroundColor:
                                        theme.palette.lightgray.main,
                                    // cant find a better way to adjust this width
                                    width:
                                        item.value === "Contains"
                                            ? "100px"
                                            : item.value === "Starts with"
                                            ? "115px"
                                            : "155px",
                                }),
                            }}
                            onChange={(item) => {
                                store.updateRBCItemContains(index, item.value);
                            }}
                        />

                        {index !== 0 && (
                            <IconButton
                                onClick={() =>
                                    store.removeWordsPhrasesItem(index)
                                }
                                size="small"
                                className={classes.closeButton}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <AcxMainTextField
                        id={"words-phrases-text-field-" + index}
                        value={item.searchPhrase}
                        onChange={(e) => {
                            store.setWordsPhrasesItemSearchPhrase(
                                index,
                                e.target.value,
                            );
                        }}
                        textareaRootStyles={{ paddingRight: "0.3rem" }}
                        showAllErrors
                        error={hasErrorAtIndex}
                        errorHelper={
                            hasErrorAtIndex &&
                            store.hasStandardSyntaxError(item.searchPhrase)
                                ? "Only Letters, Numbers, and Spaces are supported characters in this search"
                                : saidWithinError
                                ? '"Said Within" must be between 0 and 15'
                                : "'Starts with' only supports a single word and 'Said within' only applies to phrases or word groups of 2 or more for 'Contains'"
                        }
                    />
                    {saidWithinInputVisible && (
                        <Grid item xs={12} className={classes.paddingTop1}>
                            <AcxMainTextField
                                labelText="Said Within"
                                id={
                                    "words-phrases-said-within-text-field-" +
                                    index
                                }
                                value={item.saidWithin}
                                fullWidth
                                type="number"
                                placeholderText="# of words"
                                onChange={(e) => {
                                    store.setRuleSaidWithin(
                                        index,
                                        Number(e.target.value),
                                    );
                                }}
                                isDisabled={!saidWithinEnabled}
                                error={saidWithinError}
                                errorHelper={`Must be between 0 and ${SAID_WITHIN_MAX}`}
                            />
                        </Grid>
                    )}
                    <Grid container item xs={12}>
                        <Grid item width={"100px"}>
                            <AcxButton
                                fullWidth
                                id="words-phrases-filter-add-condition-button"
                                onClick={() => {
                                    store.setRuleSaidWithin(index, 0);
                                    setSaidWithinInputVisible((prev) => !prev);
                                }}
                                style={{
                                    backgroundColor:
                                        theme.palette.lightgray.main,
                                    color: theme.palette.blue.main,
                                    width: "100px",
                                }}
                                disabled={
                                    !saidWithinInputVisible &&
                                    !saidWithinEnabled
                                }
                            >
                                {saidWithinInputVisible ? "-" : "+"} Condition
                            </AcxButton>
                        </Grid>
                        <Tooltip
                            title={`Conditions are allowed for 'Contains' inputs with multiple words. 
                                    'Said Within' must be between 0 and 15 and only applies to phrases or word groups of 2 or more. 
                                    If you'd like to search for exact phrases do not use a condition.`}
                        >
                            <Grid container item xs={1} alignItems="center">
                                <InfoIcon
                                    sx={{
                                        height: "16px",
                                        width: "16px",
                                        marginLeft: theme.spacing(0.5),
                                    }}
                                    color="primary"
                                />
                            </Grid>
                        </Tooltip>
                    </Grid>
                </Grid>
                {showAndOrChip && (
                    <Grid
                        container
                        className={classes.andOrContainer}
                        justifyContent={isAnd ? "flex-start" : "center"}
                        alignItems="center"
                    >
                        {!isAnd && <Divider className={classes.orDivider} />}
                        <Stack
                            direction="row"
                            className={classes.andOrInner}
                            alignItems={"center"}
                            onClick={() => {
                                store.changeCombinator(index, !isAnd ? 0 : 1);
                            }}
                        >
                            <Box fontWeight={"bold"}>
                                {isAnd ? "AND" : "OR"}
                            </Box>
                        </Stack>
                    </Grid>
                )}
            </>
        );
    },
);

export default WordsPhraseItem;
