import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControlLabel,
    Grid,
    IconButton,
    Switch,
    Theme,
    Tooltip,
    Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import SettingsIcon from "@mui/icons-material/Settings";
import WarningIcon from "@mui/icons-material/Warning";
import {
    AnswerType,
    Question,
    QuestionCategory,
    Tag,
} from "components/Admin/Organizations/types/Module.type";
import AcxExpansion from "components/UI/AcxExpansion";
import AcxMainTextField from "components/UI/AcxMainTextField";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useForm} from "shared/hooks/useForm";
import {FormFieldErrors} from "shared/types/FormErrors.type";
import {LayoutDrawerStore} from "stores/Layout/LayoutDrawerStore";
import useStyles from "Styles/Styles";
import {useStore} from "utils/useStore";
import {
    AnswerTypeNames,
    AnswerTypeVariations,
} from "../../../../../../models/AnswerType";
import {answerTypeOptions, requiredOptions} from "../modules-constants";
import OrganizationModuleAutoScoringForm from "./OrganizationModuleAutoScoringForm";
import OrganizationModuleDetailsSelections from "./OrganizationModuleDetailsSelections";
import OrganizationModuleQuestionDependencies from "./OrganizationModuleQuestionDependencies";
import OrganizationModuleQuestionMetadataForm from "./OrganizationModuleQuestionMetadataForm";
import OrganizationModuleQuestions from "./OrganizationModuleQuestions";
import {AuthStore} from "stores/AuthStore";

const smartPredictTooltipInfo = `With SmartPredict, the higher score takes precedence.
        E.g., if SmartPredict identifies classifiers suggesting both a 
        Partially Meets (score = 0.5) and a Does Not Meet (score = 0), 
        the question will be scored as Partially Meets.`;

const answerTypesNeedingAdditionalInfo = [
    AnswerTypeNames.ScaledResponse,
    AnswerTypeNames.StarRating,
];

const styles = (theme: Theme) => {
    return createStyles({
        pageContainer: {
            width: "100%",
        },
        questionTypeIcon: {
            width: theme.typography.pxToRem(18),
            height: theme.typography.pxToRem(18),
            marginRight: theme.typography.pxToRem(4),
        },
        formContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
        clickable: {
            cursor: "pointer",
        },
        error: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.error.main,
            fontSize: "12px",
            lineHeight: "16px",
        },
        questionContainer: {
            paddingTop: 12,
            paddingBottom: 24,
            display: "flex",
            borderTop: "1px solid #e5e5e5",
        },
        actionIcons: {
            // flexGrow: 1,
            paddingBottom: theme.spacing(1),
        },
        trashIcon: {
            cursor: "pointer",
            color: theme.palette.blue.main,
            "&:hover": {
                transform: "scale(1.03)",
            },
        },
        questionSettingsNavItemWrapper: {
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
        },
        addButton: {
            textTransform: "none",
            fontWeight: "bold",
            marginTop: "15px",
        },
        contentSummary: {width: "100%"},
    });
};

type Props = {
    initialValue: Question,
    index: number,
    onChangeQuestion: (val: Question) => void,
    moveQuestion: (previousIndex: number, newIndex: number) => void,
    numberOfQuestions: number,
    duplicateQuestion: (index: number) => void,
    answerTypesIsLoading: boolean,
    answerTypes?: AnswerType[],
    errors?: FormFieldErrors,
    addTagsToDelete: (id: string | string[]) => void,
    errorPath: string,
    showInactive: boolean,
    removeQuestion: (initialVal: Question) => void,
    organizationId: string,
    dependencyQuestions: Question[],
    startItemIndex: number,
    itemsPerPage: number,
    questionCategories?: QuestionCategory[] | undefined
};

const OrganizationModuleQuestion: React.FC<Props> = ({
                                                         organizationId,
                                                         initialValue,
                                                         index,
                                                         onChangeQuestion,
                                                         moveQuestion,
                                                         numberOfQuestions,
                                                         duplicateQuestion,
                                                         answerTypesIsLoading,
                                                         answerTypes,
                                                         errors,
                                                         addTagsToDelete,
                                                         errorPath,
                                                         showInactive,
                                                         removeQuestion,
                                                         dependencyQuestions,
                                                         startItemIndex,
                                                         itemsPerPage,
                                                         questionCategories
                                                     }) => {
    const drawerStore = useStore(LayoutDrawerStore);
    const authStore = useStore(AuthStore);
    const classes = useStyles(styles);
    const [variationTypes, setVariationTypes] = useState<AnswerType[]>([]);
    const [expanded, setExpanded] = useState<boolean>(false);
    const {
        value,
        onChange,
        // errors,
        initialValue: questionInitialValue,
    } = useForm<Question>(initialValue, {});

    useEffect(() => {
        const answerType = answerTypes?.find(
            (type) =>
                type.answerTypeName === initialValue.answerType.answerTypeName,
        );
        setVariationTypes(answerType?.variations || []);
    }, [answerTypes, initialValue]);

    useEffect(() => {
        onChangeQuestion(value);
    }, [onChangeQuestion, value]);

    useEffect(() => {
        questionInitialValue.tags?.sort((a, b) => {
            return a.order - b.order;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setExpanded(false);
    }, [showInactive, startItemIndex]);

    const onChangeQuestions = (val: Question[]) => {
        onChange("questions", val);
    };

    const onChangeTags = (val: Tag[]) => {
        onChange("tags", val);
    };

    const hasError =
        !!errors?.[`${errorPath}.QuestionText`]?.length ||
        !!errors?.[`${errorPath}.ShortQuestionText`]?.length ||
        !!errors?.[`${errorPath}.Category`]?.length ||
        !!errors?.[`${errorPath}.Description`]?.length ||
        !!errors?.[`${errorPath}.Required`]?.length ||
        !!errors?.[`${errorPath}.Weight`]?.length ||
        !!errors?.[`${errorPath}.AnswerType`]?.length ||
        !!errors?.[`${errorPath}.Variation`]?.length ||
        !!errors?.[`${errorPath}.MaxLength`]?.length ||
        !!errors?.[`${errorPath}.Tags`]?.length ||
        !!errors?.[`${errorPath}.Dependencies`]?.length;

    const openAutoScoring = (question: Question) => {
        const answerName = question.answerType
            .answerTypeName as AnswerTypeNames;
        const variationTypeSingle =
            question.answerType.variation === AnswerTypeVariations.Single;
        let additionalInfoForExpansion = {};

        // conditionally add additional info if the answer type is one that needs it
        if (
            answerTypesNeedingAdditionalInfo.includes(answerName) ||
            (answerName === "Scored Tag Response" && variationTypeSingle)
        ) {
            additionalInfoForExpansion = {
                additionalInformation: smartPredictTooltipInfo,
            };
        }
        drawerStore.closeAndResetDrawer();
        drawerStore.restoreDefaults();
        drawerStore.setAnchor("left");
        drawerStore.setOffsetPixels(1);
        drawerStore.setOffsetPosition("Top");
        drawerStore.setContentFactory(() => (
            <>
                <VerticalDrawerContentTemplate
                    width="350px"
                    title="Question Settings"
                    subTitle={
                        question.shortQuestionText ?? question.questionText
                    }
                    content={
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                className={
                                    classes.questionSettingsNavItemWrapper
                                }
                            >
                                <AcxExpansion
                                    expanded={false}
                                    header="Smart Predict"
                                    body={
                                        <OrganizationModuleAutoScoringForm
                                            tags={question.tags}
                                            question={question}
                                            organizationId={organizationId}
                                        />
                                    }
                                    {...additionalInfoForExpansion}
                                />
                                <AcxExpansion
                                    expanded={false}
                                    header="Metadata"
                                    body={
                                        <OrganizationModuleQuestionMetadataForm
                                            question={question}
                                            organizationId={organizationId}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    }
                    onClose={() => drawerStore.closeAndResetDrawer()}
                />
            </>
        ));

        drawerStore.openDrawer();
    };

    const removeAllTags = () => {
        const tags: string[] =
            value.tags?.reduce((arr: string[], t) => {
                if (t?.id) {
                    arr.push(t.id);
                }
                return arr;
            }, []) || [];
        if (tags?.length) {
            addTagsToDelete(tags);
        }
        onChange("tags", undefined);
    };

    const controlType =
        initialValue?.isNew === true ? (
            <DeleteIcon
                onClick={() => removeQuestion(initialValue)}
                titleAccess="Remove Question"
                className={classes.trashIcon}
            />
        ) : (
            <Switch
                checked={initialValue.isActive}
                onChange={() => onChange("isActive", !initialValue.isActive)}
                sx={{minWidth: "40px"}}
            />
        );

    const questionIsRendered =
        index >= startItemIndex && index < startItemIndex + itemsPerPage;

    return questionIsRendered ? (
        <Accordion
            key={`${initialValue}_${index}`}
            TransitionProps={{unmountOnExit: true}}
            style={
                !initialValue.isActive && !showInactive
                    ? {display: "none"}
                    : {}
            }
            expanded={expanded}
            onChange={() => {
                setExpanded((prev) => !prev);
            }}
        >
            <AccordionSummary
                style={
                    initialValue.isActive
                        ? {}
                        : {
                            backgroundColor: "#c7c7c7",
                        }
                }
                classes={{content: classes.contentSummary}}
            >
                <Grid
                    container
                    item
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{height: "36px"}}
                    xs={12}
                >
                    <Grid
                        container
                        item
                        justifyContent="center"
                        alignItems="center"
                        xs={1}
                        style={{maxWidth: "30px"}}
                    >
                        {expanded ? (
                            <KeyboardArrowDownIcon/>
                        ) : (
                            <KeyboardArrowRightIcon/>
                        )}
                    </Grid>
                    <Grid
                        container
                        item
                        alignItems="center"
                        xs={10}
                        // style={{ fontWeight: "bold" }}
                    >
                        <Typography noWrap style={{fontWeight: "bold"}}>
                            {!questionInitialValue.shortQuestionText ||
                            questionInitialValue.shortQuestionText === ""
                                ? "(New Question)"
                                : questionInitialValue.shortQuestionText}
                        </Typography>
                    </Grid>
                    <Grid item container xs={1} justifyContent="flex-end">
                        {hasError && <WarningIcon color="error"/>}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.questionContainer}>
                    <Grid container spacing={1} className={classes.actionIcons}>
                        <Grid
                            item
                            container
                            xs={12}
                            justifyContent="space-between"
                        >
                            <Grid
                                container
                                item
                                xs={3}
                                style={{maxHeight: "36px"}}
                                justifyContent="flex-start"
                            >
                                <Grid item>
                                    <Tooltip title="Move Question Up">
                                        <span>
                                            <IconButton
                                                onClick={() =>
                                                    moveQuestion(
                                                        index,
                                                        index - 1,
                                                    )
                                                }
                                                size="small"
                                                disabled={
                                                    !index ||
                                                    initialValue.isDefault
                                                }
                                                color="secondary"
                                            >
                                                <ArrowUpwardIcon/>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Tooltip title="Move Question Down">
                                        <span>
                                            <IconButton
                                                onClick={() =>
                                                    moveQuestion(
                                                        index,
                                                        index + 1,
                                                    )
                                                }
                                                size="small"
                                                disabled={
                                                    index ===
                                                    numberOfQuestions - 1 ||
                                                    initialValue.isDefault
                                                }
                                                color="secondary"
                                            >
                                                <ArrowDownwardIcon/>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            {(showInactive || initialValue?.isNew === true) &&
                                !initialValue.isDefault && (
                                    <FormControlLabel
                                        control={controlType}
                                        label={
                                            initialValue?.isNew !== true
                                                ? initialValue.isActive
                                                    ? "Active"
                                                    : "Inactive"
                                                : ""
                                        }
                                        style={{marginBottom: 15}}
                                    />
                                )}
                            <Grid
                                container
                                item
                                xs={2}
                                justifyContent="flex-end"
                            >
                                {!value.isNew && !initialValue.isDefault && (
                                    <Tooltip title="Configure Question Settings">
                                        <IconButton
                                            onClick={() =>
                                                openAutoScoring(value)
                                            }
                                            size="small"
                                        >
                                            <SettingsIcon htmlColor="#bebebe"/>
                                        </IconButton>
                                    </Tooltip>
                                )}

                                <Tooltip title="Duplicate Question">
                                    <IconButton
                                        onClick={duplicateQuestion.bind(
                                            null,
                                            index,
                                        )}
                                        size="small"
                                    >
                                        <ControlPointDuplicateIcon color="secondary"/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <AcxMainTextField
                                value={questionInitialValue.questionText}
                                labelText="Question title"
                                onBlur={(val) => onChange("questionText", val)}
                                id={`questionText-${index}`}
                                error={
                                    !!errors?.[`${errorPath}.QuestionText`]
                                        ?.length
                                }
                                helperText={
                                    errors?.[`${errorPath}.QuestionText`]
                                }
                                required
                                isDisabled={initialValue.isDefault}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AcxMainTextField
                                value={questionInitialValue.shortQuestionText}
                                labelText="Short question text"
                                onBlur={(val) =>
                                    onChange("shortQuestionText", val)
                                }
                                id={`shortQuestionText-${index}`}
                                error={
                                    !!errors?.[`${errorPath}.ShortQuestionText`]
                                        ?.length
                                }
                                helperText={
                                    errors?.[`${errorPath}.ShortQuestionText`]
                                }
                                required
                                isDisabled={initialValue.isDefault}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AcxMainTextField
                                value={questionInitialValue.category}
                                labelText="Category"
                                onBlur={(val) => onChange("category", val)}
                                id={`category-${index}`}
                                error={
                                    !!errors?.[`${errorPath}.Category`]?.length
                                }
                                helperText={errors?.[`${errorPath}.Category`]}
                                isDisabled={initialValue.isDefault}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AcxSelectSingle
                                inputLabel="Question Category"
                                id={`questionCategory-${index}`}
                                valueField="id"
                                labelField="name"
                                options={questionCategories || []}
                                defaultValue={questionCategories?.find(
                                    (category) =>
                                        category.id === questionInitialValue.questionCategoryId,
                                )}
                                onChange={(val) => {
                                    onChange("questionCategoryId", val.id);
                                }}
                                error={
                                    !!errors?.[`${errorPath}.QuestionCategoryId`]?.length
                                }
                                fullWidth
                                helperText={errors?.[`${errorPath}.QuestionCategoryId`]}
                                isDisabled={initialValue.isDefault}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AcxMainTextField
                                value={questionInitialValue.helperText}
                                labelText={"Description"}
                                onBlur={(val) => onChange("helperText", val)}
                                id={`helperText-${index}`}
                                error={
                                    !!errors?.[`${errorPath}.Description`]
                                        ?.length
                                }
                                helperText={
                                    errors?.[`${errorPath}.Description`]
                                }
                                isDisabled={initialValue.isDefault}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AcxSelectSingle
                                inputLabel="Required"
                                id={`required-${index}`}
                                valueField="value"
                                labelField="label"
                                options={Object.values(requiredOptions)}
                                defaultValue={
                                    requiredOptions[
                                        String(questionInitialValue.required)
                                        ]
                                }
                                onChange={(val) =>
                                    onChange("required", val.value)
                                }
                                error={
                                    !!errors?.[`${errorPath}.Required`]?.length
                                }
                                helperText={errors?.[`${errorPath}.Required`]}
                                minWidth="60px"
                                isDisabled={initialValue.isDefault}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <AcxMainTextField
                                value={questionInitialValue.weight}
                                type="number"
                                labelText="Weight"
                                onBlur={(val) => onChange("weight", val)}
                                id={`weight-${index}`}
                                error={
                                    !!errors?.[`${errorPath}.Weight`]?.length
                                }
                                helperText={errors?.[`${errorPath}.Weight`]}
                                isDisabled={initialValue.isDefault}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Tooltip
                                title={
                                    value.isNew || authStore.isUserUltra()
                                        ? ""
                                        : "Saved Answer Types/Variations cannot be altered."
                                }
                            >
                                <span>
                                    <AcxSelectSingle
                                        inputLabel="Answer type"
                                        id={`answerType-${index}`}
                                        valueField="id"
                                        labelField="answerTypeName"
                                        isLoading={answerTypesIsLoading}
                                        options={answerTypes || []}
                                        defaultValue={answerTypes?.find(
                                            (type) =>
                                                type.answerTypeName ===
                                                value?.answerType
                                                    ?.answerTypeName,
                                        )}
                                        isDisabled={
                                            (!authStore.isUserUltra() &&
                                                !value.isNew) ||
                                            initialValue.isDefault
                                        }
                                        onChange={(val) => {
                                            onChange("answerType", val);
                                            onChange("variation", val);
                                            setTimeout(() => {
                                                removeAllTags();
                                            }, 1);
                                        }}
                                        error={
                                            !!errors?.[
                                                `${errorPath}.AnswerType`
                                                ]?.length
                                        }
                                        helperText={
                                            errors?.[`${errorPath}.AnswerType`]
                                        }
                                    />
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={4} style={{paddingRight: "11px"}}>
                            {!!variationTypes?.length &&
                                !initialValue.isDefault && (
                                    <AcxSelectSingle
                                        inputLabel="Variation"
                                        id={`variation-${index}`}
                                        valueField="id"
                                        labelField="variation"
                                        isLoading={answerTypesIsLoading}
                                        options={variationTypes}
                                        defaultValue={value?.variation}
                                        onChange={(val) => {
                                            onChange("variation", val);
                                        }}
                                        error={
                                            !!errors?.[`${errorPath}.Variation`]
                                                ?.length
                                        }
                                        helperText={
                                            errors?.[`${errorPath}.Variation`]
                                        }
                                        isDisabled={
                                            (!authStore.isUserUltra() &&
                                                !value.isNew) ||
                                            initialValue.isDefault
                                        }
                                    />
                                )}

                            {value.answerType?.answerTypeName ===
                                AnswerTypeNames.TextResponse && (
                                    <AcxMainTextField
                                        value={questionInitialValue.maxLength}
                                        type="number"
                                        labelText="Max Response Length"
                                        onBlur={(val) => onChange("maxLength", val)}
                                        id={`maxLength-${index}`}
                                        error={
                                            !!errors?.[`${errorPath}.MaxLength`]
                                                ?.length
                                        }
                                        helperText={
                                            errors?.[`${errorPath}.MaxLength`]
                                        }
                                        insertBlankIfNumber={true}
                                        isDisabled={initialValue.isDefault}
                                    />
                                )}
                        </Grid>
                        {questionInitialValue.answerType?.answerTypeName !==
                            AnswerTypeNames.QuestionGrouping &&
                            !questionInitialValue.answerType
                                ?.isFillInAnswer && (
                                <OrganizationModuleDetailsSelections
                                    key="module_details_selections"
                                    value={questionInitialValue.tags || []}
                                    onChange={onChangeTags}
                                    tagsLabel={
                                        questionInitialValue.answerType
                                            ? answerTypeOptions[
                                                questionInitialValue
                                                    .answerType.answerTypeName
                                                ]?.tagsLabel
                                            : "Selections"
                                    }
                                    errors={errors}
                                    error={
                                        !!errors?.[`${errorPath}.Tags`]?.length
                                    }
                                    helperText={errors?.[`${errorPath}.Tags`]}
                                    questionIndex={index}
                                    addTagsToDelete={addTagsToDelete}
                                    errorPath={errorPath}
                                    showInactive={showInactive}
                                    isNew={initialValue?.isNew}
                                    isScored={
                                        answerTypes?.find(
                                            (type) =>
                                                type.answerTypeName ===
                                                value?.answerType
                                                    ?.answerTypeName,
                                        )?.isScored
                                    }
                                    isDefault={value.isDefault}
                                />
                            )}

                        {questionInitialValue.answerType?.answerTypeName ===
                            AnswerTypeNames.QuestionGrouping && (
                                <div
                                    style={{
                                        padding: "8px 6px 8px 8px",
                                        width: "100%",
                                        margin: "12px 0 0 6px",
                                        borderLeft: "5px solid #3664d5",
                                    }}
                                >
                                    <OrganizationModuleQuestions
                                        organizationId={organizationId}
                                        initialQuestions={
                                            questionInitialValue.questions
                                        }
                                        thingsAreLoading={answerTypesIsLoading}
                                        answerTypes={answerTypes}
                                        errors={errors}
                                        onChange={onChangeQuestions}
                                        addTagsToDelete={addTagsToDelete}
                                        nested
                                        parentWeight={value.weight}
                                        errorPath={errorPath}
                                    />
                                </div>
                            )}
                        <OrganizationModuleQuestionDependencies
                            value={value}
                            onChange={onChange}
                            index={index}
                            placement={[]}
                            dependencyQuestions={dependencyQuestions}
                            errors={errors}
                            errorPath={errorPath}
                        />
                    </Grid>
                </div>
            </AccordionDetails>
        </Accordion>
    ) : null;
};

export default React.memo(OrganizationModuleQuestion, areEqual);

function areEqual(prevProps: Props, nextProps: Props) {
    return (
        _.isEqual(prevProps.initialValue, nextProps.initialValue) &&
        _.isEqual(prevProps.errors, nextProps.errors) &&
        _.isEqual(prevProps.answerTypes, nextProps.answerTypes) &&
        prevProps.index === nextProps.index &&
        prevProps.answerTypesIsLoading === nextProps.answerTypesIsLoading &&
        prevProps.addTagsToDelete === nextProps.addTagsToDelete &&
        prevProps.showInactive === nextProps.showInactive &&
        prevProps.numberOfQuestions === nextProps.numberOfQuestions &&
        prevProps.startItemIndex === nextProps.startItemIndex &&
        prevProps.itemsPerPage === nextProps.itemsPerPage
    );
}
