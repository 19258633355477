import { Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { DialogComponentStore } from "../../../../stores/ComponentStores/DialogComponentStore";
import ConfirmationDialog from "../../../UI/AcxConfirmationDialog";
import { EvalStore } from "../../Stores/EvalStore";

interface OwnProps {
    dialogStore: DialogComponentStore<EvalStore>;
}

type Props = OwnProps;

const NoActionThreadsFoundDialog: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer(
    (props) => {
        function onClose() {
            props.dialogStore.close();
            props.dialogStore.resetDialog();
            props.dialogStore.hostingStore?.clearLastTaskError();
        }

        const content = (
            <Typography color={"textSecondary"} variant={"subtitle2"}>
                {props.dialogStore.errorMessage}
            </Typography>
        );

        return (
            <ConfirmationDialog
                isOpen={props.dialogStore.isOpen}
                confirmButtonText={"Ok"}
                content={content}
                title={props.dialogStore.title}
                noCancel
                subTitle={props.dialogStore.subTitle}
                onConfirm={onClose}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={20}
                onClose={onClose}
            />
        );
    },
);

export default NoActionThreadsFoundDialog;
