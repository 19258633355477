import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Observer, useLocalStore } from "mobx-react";
import React, { FunctionComponent, useEffect } from "react";
import BaseEntity from "../../../../models/BaseEntity";
import { TableComponentStore } from "../../../../stores/ComponentStores/TableComponentStore";
import ChromeTabs, { ChromeTab } from "../../SubTabs/ChromeTabs";
import AcxTable, { IAcxTableColumn, Props as TableProps } from "../AcxTable";
import { TabTableViewModel } from "./ViewModel/TabTableViewModel";

const useStyles = makeStyles((theme: Theme) => ({
    fullHeight: {
        height: "100%",
    },
}));

export interface TabTableData<T extends BaseEntity> extends ChromeTab {
    columns: IAcxTableColumn[];
    tableComponentStore: TableComponentStore<T>;
    enableCheck?: boolean;
}

interface OwnProps
    extends Omit<
        TableProps,
        "rows" | "columns" | "enableCheck" | "onSelecteditems" | "selectedItems"
    > {
    tabs: TabTableData<any>[];
    actionLabel?: string;
    onActionClick?: () => void;
}

type Props = OwnProps;

const AcxTabTable: FunctionComponent<Props> = (props) => {
    const viewModel = useLocalStore(
        (args) => new TabTableViewModel(args.tabs),
        { tabs: props.tabs },
    );
    const classes = useStyles();
    const { tabs, actionLabel, onActionClick, ...tableProps } = props;

    useEffect(() => {
        viewModel.setTabData(tabs);
    }, [tabs, viewModel]);

    return (
        <Observer>
            {() => (
                <div className={classes.fullHeight}>
                    <ChromeTabs
                        noGutter
                        activeTabIndex={viewModel.currentTabIndex}
                        onChange={viewModel.onChangeTab}
                        actionLabel={actionLabel}
                        onActionClick={onActionClick}
                        tabs={tabs}
                    />

                    {viewModel.tableColumns && (
                        <AcxTable
                            removeHeightInPx={"48px"}
                            {...tableProps}
                            {...viewModel.tableProps}
                        />
                    )}
                </div>
            )}
        </Observer>
    );
};

export default AcxTabTable;
