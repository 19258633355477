import AudioMetadataModel from "../models/AudioMetadataModel";
import DirectoryInfoModel from "../models/DirectoryInfoModel";
import { isUndefinedType } from "../utils/TypeGuards";
import BaseService from "./BaseService";

export class SourceFilesService extends BaseService {
    protected urlBase: string = "api/AudioFiles";

    public async getSourceDirectories(
        folderPath: string | null,
        account: string | undefined,
        startDate: string,
        endDate: string,
        minUnsampled?: number,
    ) {
        const urlSearchParams = new URLSearchParams({
            startDate,
            endDate,
        });
        if (!isUndefinedType(minUnsampled)) {
            urlSearchParams.set("minUnsampled", minUnsampled.toString(10));
        }

        return await this.get<DirectoryInfoModel[]>(
            urlSearchParams,
            "ExpandDirectory",
        );
    }

    public async getSampleFilesFromDirectory(
        directoryId: string,
        startDate: string,
        endDate: string,
        sampleSize: number,
        onlyReadyToSample: boolean = false,
    ) {
        const urlSearchParams = new URLSearchParams({
            directoryId,
            startDate,
            endDate,
        });
        urlSearchParams.set("onlyReadyToSample", `${onlyReadyToSample}`);

        if (!isUndefinedType(sampleSize)) {
            urlSearchParams.set("sampleSize", sampleSize.toString(10));
        }

        return await this.get<AudioMetadataModel[]>(
            urlSearchParams,
            "RandomSampleFromDirectory",
        );
    }
}
