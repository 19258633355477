import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { User } from "oidc-client";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { DashboardDomain } from "../../../../models/Dashboard/DashboardDefinition";
import {
    WidgetContentType,
    WidgetType,
} from "../../../../models/Dashboard/Widget";
import {
    ReportDataField,
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "../../../../models/Reporting/ReportDataField";
import {
    FieldUse,
    ReportField,
} from "../../../../models/Reporting/ReportField";
import {
    Comparator,
    ReportFilter,
} from "../../../../models/Reporting/ReportFilter";
import { AuthStore } from "../../../../stores/AuthStore";
import { BaseStore } from "../../../../stores/BaseStore";
import { DateReferenceOption } from "../../../../stores/ComponentStores/DatePickerComponentStore";
import {
    DashboardStore,
    IDashboardItem,
} from "../../../../stores/Layout/DashboardStore";
import { DynamicTimeSpan } from "../../../../utils/reportingTimeHelpers";

@AcxStore
export class AgentDashboardStore extends BaseStore {
    readonly dashboardStore: DashboardStore;

    @observable private user?: User | null;
    @observable private orgId?: string;
    @observable private agentId?: string;
    @observable agentName: string;
    constructor(private rootStore: IRootStore) {
        super("Agent Dashboard");

        makeObservable(this);

        this.dashboardStore = new DashboardStore(
            rootStore,
            DashboardDomain.Agent,
        );

        rootStore
            .getStore(AuthStore)
            .getUserObject()
            .then((value) => {
                runInAction(() => {
                    this.user = value;
                });
            })
            .catch((reason) =>
                console.error(`Failed to retrieve User Profile ${reason}`),
            );

        reaction(
            (r) => ({
                orgId: this.orgId,
                user: this.user,
                agentId: this.agentId,
                agentName: this.agentName,
                activeLocation: this.rootStore.activeLocation,
            }),
            (arg) => {
                if (
                    arg.activeLocation &&
                    !arg.activeLocation.location.includes(
                        "/app/agentcoaching/dashboard/",
                    )
                ) {
                    return;
                }

                if (arg.orgId && arg.user && arg.agentId && arg.agentName) {
                    this.dashboardStore.initializeStoreParameters(
                        arg.orgId,
                        arg.user,
                        this.buildImmutableWidgets(arg.agentId, arg.orgId),
                        this.buildDashboardDynamicFilters(
                            arg.agentName,
                            arg.agentId,
                            arg.orgId,
                        ),
                        true,
                    );
                }
            },
            { fireImmediately: true, delay: 0 },
        );
    }

    private buildDashboardDynamicFilters(
        agentName: string,
        agentId: string,
        orgId: string,
    ) {
        const filterMap = new Map<string, ReportFilter>();

        const agentNameFilter = new ReportFilter();
        agentNameFilter.value = decodeURIComponent(agentName);
        agentNameFilter.comparator = Comparator.EQ;
        agentNameFilter.reportField = new ReportField(
            undefined,
            undefined,
            undefined,
            undefined,
        );
        agentNameFilter.reportField.fieldUse = FieldUse.QuickFilter;
        agentNameFilter.reportField.reportDataField = new ReportDataField();
        agentNameFilter.reportField.reportDataField.variation =
            ReportDataFieldVariation.Concrete;
        agentNameFilter.reportField.reportDataField.datatype =
            ReportFieldDataTypes.Text;
        agentNameFilter.reportField.reportDataField.fieldName = "AgentName";
        agentNameFilter.reportField.reportDataField.value = "AgentName";

        filterMap.set("AgentName", agentNameFilter);

        const agentIdFilter = new ReportFilter();
        agentIdFilter.value = agentId;
        agentIdFilter.comparator = Comparator.EQ;
        agentIdFilter.reportField = new ReportField(
            undefined,
            undefined,
            undefined,
            undefined,
        );
        agentIdFilter.reportField.fieldUse = FieldUse.QuickFilter;
        agentIdFilter.reportField.reportDataField = new ReportDataField();
        agentIdFilter.reportField.reportDataField.variation =
            ReportDataFieldVariation.Concrete;
        agentIdFilter.reportField.reportDataField.datatype =
            ReportFieldDataTypes.Guid;
        agentIdFilter.reportField.reportDataField.fieldName = "AgentId";
        agentIdFilter.reportField.reportDataField.value = "AgentId";

        filterMap.set("AgentId", agentIdFilter);

        return filterMap;
    }

    private buildImmutableWidgets(
        agentId: string,
        orgId: string,
    ): Array<IDashboardItem> {
        const key = "eval-coaching-table";
        return [
            {
                key: key,
                widgetType: WidgetType.EvaluationCoachingTable,
                name: "Evaluations",
                args: { isTable: true, agentId: agentId, orgId: orgId },
                organizationId: orgId,
                layout: {
                    i: key,
                    x: 0,
                    y: 0,
                    static: true,
                    w: 12,
                    h: 4,
                    minH: 4,
                },
                contentType: WidgetContentType.IMMUTABLE,
                dateReference: DateReferenceOption.EvaluationCompleteDate,
                timeSpan: DynamicTimeSpan.TENYEAR,
            },
        ];
    }

    @action
    setAgentAndOrg(orgId: string, agentId: string, agentName: string) {
        this.orgId = orgId;
        this.agentId = agentId;
        this.agentName = agentName;
    }
}
