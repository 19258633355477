import App from "app";

import { IFrameApp } from "../src/RenewalIframe/iframe";

/*
 * The application entry point
 */
if (window.top === window.self) {
    // console.log("executing the app")
    // Run the main app
    const app = App;
    app.execute();
} else {
    console.log("executing the iframe");
    // If our SPA is running on an iframe, handle token renewal responses
    const app = new IFrameApp();
    app.execute();
}
