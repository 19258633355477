import { makeObservable, observable } from "mobx";
import Agentv2 from "./Agentv2";
import BaseEntity from "./BaseEntity";
import DirectoryModel from "./DirectoryModel";
import Interaction from "./Interaction";
import { InteractionType } from "./InteractionType";
import { ITranscriptionEntity } from "./TranscriptionEntity";

export default class AudioMetadataModel extends BaseEntity {
    @observable blobFileKey?: string = "";

    @observable origFileKey: string = "";
    @observable acxIdentifier?: string = "";

    @observable fileName: string = "";

    @observable containerName?: string = "";

    @observable directoryPath?: string = "";

    @observable origDirectoryPath?: string = "";
    @observable interactionAssignmentId?: string = "";
    @observable timestamp?: Date | string;
    @observable timezone?: string = "";

    @observable email?: string = "";

    @observable phone?: string = "";
    @observable name?: string = "";
    @observable agentName?: string = "";
    @observable callDirection?: string = "";
    @observable callDurationMillis?: number;
    @observable fileSizeKiloBytes?: number;
    @observable origDirectoryId?: string;
    @observable clientCallId?: string;

    @observable arrivedOn?: Date | string;

    status: AudioMetadataStatus | UISamplingStatus | string;
    mediaType?: InteractionType;
    directory?: DirectoryModel;
    origDirectory?: DirectoryModel;

    interaction?: Interaction;
    transcriptions?: ITranscriptionEntity[];
    agent: Agentv2;
    agentId?: string;

    @observable meta1?: string;
    @observable meta2?: string;
    @observable meta3?: string;
    @observable meta4?: string;
    @observable meta5?: string;

    @observable extendedMetadata?: any;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }
}

export type UISamplingStatus = {
    status: AudioMetadataSamplingStatus;
    message: string;
};

export enum AudioMetadataSamplingStatus {
    SamplingError = -999,
}

export enum AudioMetadataStatus {
    Processing,
    Sampled,
    Transcribing,
    ReadyToSample,
    OnDemandProcessing,
    FailedToProcess,
    NotProcessed,
}

export interface ISourceFile {
    id: string;
    directoryId?: string;
    sourceFolder?: string;
    fileName?: string;
}
