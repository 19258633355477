import BaseEntity from "models/BaseEntity";
import { Tag } from "models/Tag";
import { WorkflowMessagingMetadata } from "./WorkflowMessagingMetadata";
import {
    WorkflowConditionType,
    WorkflowDefinition,
} from "../../components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import Question from "../Question";

export class WorkflowCondition extends BaseEntity {
    implementationType?: WorkflowConditionType;
    organizationId: string;
    questionId?: string;
    question?: Question;
    tagId?: string;
    tag?: Tag;
    name?: string;
    workflowIdentifier?: string;
    highPriority: boolean;
    workflowDefinitionId?: string;
    workflowDefinition?: WorkflowDefinition;
    licensedModuleId?: string;
    scoreThreshold?: number;
    workflowMessagingMetadata: WorkflowMessagingMetadata[];
}
