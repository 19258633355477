import React from "react";
import { Typography, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { extractWordsAndFragmentsFromHighlight } from "./utils";
import AcxChipList from "components/UI/AcxChipList";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
    speakerText: {
        fontWeight: 700,
        fontSize: "13px",
    },
    highlightText: {
        "& b": {
            color: theme.palette.blue.main,
            fontWeight: 600,
        },
    },
}));

interface HighlightItemProps {
    highlight: string;
    speaker: string;
    disableChips?: boolean;
}

function HighlightItem(props: HighlightItemProps) {
    const classes = useStyles();

    const { fragments, words } = extractWordsAndFragmentsFromHighlight(
        props.highlight,
    );

    return (
        <Typography>
            <Typography component="span" className={classes.speakerText}>
                {props.speaker}:{" "}
            </Typography>
            <span
                className={clsx(classes.highlightText, "pendo-ignore")}
                dangerouslySetInnerHTML={{
                    __html: fragments.join(" ... "),
                }}
            />
            {!props.disableChips && (
                <AcxChipList size="small" color="gray" values={words} />
            )}
        </Typography>
    );
}

export default HighlightItem;
