import { IconButton, Paper, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import theme from "Theme/AppTheme";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { AmdTimelineWithGraph } from "components/UI/Timeline/AmdTimelineWithGraph";
import { observer } from "mobx-react";
import React, { useState } from "react";

const FileTimeline = observer(() => {
    const [amdId, setAmdId] = useState("");

    const endAdornment = (
        <IconButton size="small">
            <Search fontSize="small" />
        </IconButton>
    );

    return (
        <Paper
            style={{
                height: "100%",
                overflow: "auto",
                background: theme.palette.lightgray.main,
                position: "relative",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        minHeight: "70px",
                        alignItems: "center",
                        background: "white",
                        boxShadow:
                            "0 0 3px 0 rgb(0 0 0 / 5%), 0 1px 1px 0 rgb(0 0 0 / 5%)",
                    }}
                >
                    <div
                        style={{
                            marginLeft: "12px",
                            marginRight: "auto",
                        }}
                    >
                        <Typography variant="h5">
                            File Processing Timeline
                        </Typography>
                    </div>
                    <div
                        style={{
                            marginLeft: "auto",
                            marginRight: "12px",
                        }}
                    >
                        <AcxMainTextField
                            id="amdSearch"
                            placeholderText="Find File by AMD ID"
                            type="text"
                            value={amdId}
                            onChange={(e) => {
                                setAmdId(e.target.value);
                            }}
                            endAdornment={endAdornment}
                        />
                    </div>
                </div>
                <AmdTimelineWithGraph amdId={amdId} />
            </div>
        </Paper>
    );
});

export default FileTimeline;
