import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import React from "react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";

export const DefaultFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    return (
        <AcxMainTextField
            id={props.id ?? props.filterKey}
            value={props.store[props.filterKey]}
            onChange={(e) =>
                // @ts-expect-error
                props.store.setByKey(props.filterKey, e.target.value)
            }
            fullWidth
        />
    );
});
