import React, { FunctionComponent } from "react";
import icon from "./GreenCheck.svg";

interface IGreenCheckSvg {
    style?: React.CSSProperties;
    className?: string;
}

const DownChevronSvg: FunctionComponent<IGreenCheckSvg> = (props) => {
    return (
        <img
            src={icon}
            className={props.className}
            alt="success"
            style={props.style}
        />
    );
};

export default DownChevronSvg;
