import React from "react";

interface AgentLogoProps {
    gradient?: boolean;
}

export function AgentLogo({
    gradient = false,
    ...props
}: Omit<React.ComponentPropsWithoutRef<"svg">, "xmlns" | "viewBox"> &
    AgentLogoProps) {
    return (
        <svg
            height="14px"
            fill="none"
            {...props}
            viewBox="0 0 16 14"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.9"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.363 1.67223L10.0837 1.19057C9.65624 0.544437 9.02707 0.000286762 7.96595 0.000286762C7.0974 -0.013088 6.28948 0.442228 5.85388 1.19057L5.57457 1.67223H10.363ZM3.7875 4.75398L4.75763 3.08103H6.03274C6.49471 3.08103 6.86922 3.45553 6.86922 3.9175C6.86922 4.37947 6.49471 4.75398 6.03274 4.75398H3.7875ZM2.00042 7.83573L2.97055 6.16278H8.41009C8.87206 6.16278 9.24656 6.53728 9.24656 6.99925C9.24656 7.46122 8.87206 7.83573 8.41009 7.83573H2.00042ZM0.278542 10.9175C0.331142 10.7481 0.403784 10.5847 0.495459 10.431L1.18347 9.24453H11.2277C11.6897 9.24453 12.0642 9.61903 12.0642 10.081C12.0642 10.543 11.6897 10.9175 11.2277 10.9175H0.278542ZM2.50127 13.9992C1.36577 13.9723 0.59597 13.1963 0.305913 12.3263H6.29689C6.75886 12.3263 7.13337 12.7008 7.13337 13.1628C7.13337 13.6247 6.75886 13.9992 6.29689 13.9992H2.50127ZM15.6287 12.3263C15.3189 13.2019 14.4925 13.9724 13.4342 13.9992H9.37864C8.91667 13.9992 8.54216 13.6247 8.54216 13.1628C8.54216 12.7008 8.91667 12.3263 9.37864 12.3263H15.6287ZM14.7536 9.24453L15.4415 10.431C15.5377 10.5837 15.6131 10.7473 15.6665 10.9175H14.3094C13.8475 10.9175 13.473 10.543 13.473 10.081C13.473 9.61903 13.8475 9.24453 14.3094 9.24453H14.7536ZM12.9667 6.16278L13.9367 7.83573H11.4918C11.0299 7.83573 10.6554 7.46122 10.6554 6.99925C10.6554 6.53728 11.0299 6.16278 11.4918 6.16278H12.9667ZM11.1799 3.08103L12.1499 4.75398H9.11449C8.65252 4.75398 8.27802 4.37947 8.27802 3.9175C8.27802 3.45553 8.65252 3.08103 9.11449 3.08103H11.1799Z"
                fill={
                    gradient
                        ? "url(#paint0_linear_15057_42474)"
                        : "currentColor"
                }
            />
            {gradient && (
                <defs>
                    <linearGradient
                        id="paint0_linear_15057_42474"
                        x1="0.830509"
                        y1="1.10698"
                        x2="29.7275"
                        y2="26.3149"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#32D1B7" />
                        <stop offset="0.695" stopColor="#349BC7" />
                        <stop offset="1.0001" stopColor="#3664D6" />
                    </linearGradient>
                </defs>
            )}
        </svg>
    );
}
