import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import Classifier from "models/ClassifierModel";
import { ClassificationService } from "services/ClassificationService";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import NotificationStore, {
    INotificationTransformFactory,
} from "stores/Notifications/NotificationStore";
import {
    NotificationArgument,
    NotificationDetails,
    NotificationType,
} from "stores/RealTimeMessages/MessageStoreBase";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { uuidv4 } from "utils/helpers";
import { AuthStore } from "stores/AuthStore";
import ClassifierService from "services/ClassifierService";
import { BaseStore } from "stores/BaseStore";
import { ClassifierBuilderV2Store } from "./ClassifierBuilderV2Store";

const classificationService = new ClassificationService();

class ReclassifyNotificationArg {
    totalCount: number;
    completedCount: number;
    type: NotificationType;
    timestamp: string;
    message: string;
    title: string;
    id: string;
    addtlDetails?: NotificationDetails;
}

@AcxStore
class ClassifierBuilderV2ReclassifyDialogStore extends BaseStore {
    private readonly classifierService = new ClassifierService();

    resetDialog = () => {
        this.loadingAffected = false;
        this.succeeded = -1;
        this.failed = -1;
    };
    notificationStore: NotificationStore;

    dialog = new DialogComponentStore(this.resetDialog);
    confirmDialog = new DialogComponentStore(this.resetDialog);

    datePickerStore = new DatePickerComponentStore();
    orgSelectStore: OrgSelectorComponentStore;
    public readonly authStore: AuthStore;

    @observable
    loadingAffected = false;

    @observable
    numAffectedInteractions: number;

    @observable
    totalCount: number = 0;

    @observable
    completedCount: number = 0;

    @observable
    succeeded: number = -1;

    @observable
    failed: number = -1;

    @observable
    reclassifySessionInProgress: boolean = false;

    @observable
    selectedClassifiers: Classifier[] = [];

    @observable
    hierarchyIds: string[] = [];

    @observable
    hierarchiesApplied: boolean = false;

    constructor(public rootStore: IRootStore) {
        super("ClassifierBuilderReclassifyDialogStore");

        makeObservable(this);
        this.authStore = this.rootStore.getStore(AuthStore);
        this.orgSelectStore = rootStore.getStore(
            ClassifierBuilderV2Store,
        ).orgSelectStore;
        this.notificationStore = rootStore.getStore(NotificationStore);

        reaction(
            (r) => ({
                orgId: this.authStore.orgStore.selectedOrganization?.id,
            }),
            () => this.setHierarchyIds([]),
            { fireImmediately: true },
        );

        reaction(() => {
            return {
                arg1: [
                    this.datePickerStore.beginDate,
                    this.datePickerStore.endDate,
                ],
                arg3: this.datePickerStore.referenceOption,
                hierarchiesApplied: this.hierarchiesApplied,
                classifierIds: this.classifierIds,
            };
        }, this.checkReclassificationAction);
    }

    @action
    checkReclassificationAction = this.debounceEffect(async () => {
        if (!this.orgSelectStore.orgId) {
            return;
        }

        if (
            this.datePickerStore.beginDate.isAfter(this.datePickerStore.endDate)
        ) {
            return;
        }
        if (
            this.datePickerStore.endDate.isBefore(
                this.datePickerStore.beginDate,
            )
        ) {
            return;
        }

        this.loadingAffected = true;
        const payload = await classificationService.CheckReclassification(
            this.orgSelectStore.orgId,
            this.datePickerStore.beginDate,
            this.datePickerStore.endDate,
            this.datePickerStore.referenceOption,
            this.hierarchyIds,
            this.classifierIds,
        );
        runInAction(() => {
            this.numAffectedInteractions = payload.numRecords;
            this.loadingAffected = false;
        });
    }, 200);

    @action
    setHierarchiesApplied = (applied: boolean) => {
        this.hierarchiesApplied = applied;
    };

    @action
    setSelectedClassifiers = (classifiers?: Classifier[]) => {
        this.selectedClassifiers = classifiers ?? [];
    };

    @computed
    get selectedPublishedClassifiers() {
        return this.selectedClassifiers.filter(
            (classifier) => classifier.isPublished,
        );
    }

    @computed
    get classifierIds() {
        return this.selectedClassifiers
            ? this.selectedClassifiers.map((classifier) => classifier.id)
            : [];
    }

    @computed
    get publishedClassifierIds() {
        const res = this.selectedClassifiers
            ? this.selectedClassifiers
                  .filter((classifier) => classifier.isPublished)
                  .map((classifier) => classifier.id)
            : [];
        return res;
    }

    @action
    setHierarchyIds = (selectedBranchIds: string[]) => {
        this.hierarchyIds = selectedBranchIds;
    };

    @action
    setReclassifySessionInProgress = (inProgress: boolean) => {
        this.reclassifySessionInProgress = inProgress;
    };

    @action
    reclassify = async () => {
        this.dialog.setLoading();
        this.dialog.setErrorMessage("");

        try {
            var sessionId = uuidv4() as string;
            this.setReclassifySessionInProgress(true);

            await classificationService.Reclassify(
                this.orgSelectStore.orgId as string,
                sessionId,
                this.datePickerStore.beginDate,
                this.datePickerStore.endDate,
                this.datePickerStore.referenceOption,
                this.hierarchyIds,
                this.classifierIds,
            );

            runInAction(() => {
                this.succeeded = 1;
                this.dialog.setNotLoading();
            });
        } catch (exception: unknown) {
            if (typeof exception === "object" && exception !== null) {
                const exceptionMsg = (exception as Error).message;
                const isEmptyJson: boolean = !exceptionMsg.includes(
                    "Unexpected end of JSON input",
                );
                isEmptyJson ??
                    this.dialog.setErrorMessage("Reclassification Failed");
            }
            this.dialog.setNotLoading();
        }
    };

    /**
     * Not Used; just here for reference since this is currently that only notification flow that needs to
     * maintain state
     *
     * sample implementation of using closure for notification-transformer so that you could have
     * multiple running at sametime and wouldn't need to worry about clobbering state in the store
     * since the closure maintains its own localEnv (in this case, the closure gives us completedCount,totalCount
     * @param completedCount
     * @param totalCount
     */
    buildNotificationTransformer: INotificationTransformFactory<ReclassifyNotificationArg> =
        (completedCount: number, totalCount: number) => {
            return (arg: ReclassifyNotificationArg) => {
                const notificationArg = { ...arg } as NotificationArgument;
                if (arg.completedCount == null || arg.totalCount == null) {
                    notificationArg.message = "Reclassify In Progress";
                    notificationArg.type = NotificationType.InProgress;
                } else {
                    completedCount += arg.completedCount;
                    if (arg.totalCount > 0) {
                        totalCount = arg.totalCount;
                    }
                    if (completedCount === totalCount) {
                        notificationArg.type = NotificationType.Success;
                        notificationArg.message = "Reclassify Complete";
                        totalCount = 0;
                        completedCount = 0;
                    } else {
                        notificationArg.type = NotificationType.InProgress;
                        notificationArg.message = `Reclassify Progress: ${(
                            (completedCount / totalCount) *
                            100
                        ).toPrecision(3)}%`;
                    }
                }

                return notificationArg;
            };
        };
}

export default ClassifierBuilderV2ReclassifyDialogStore;
