import { Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { useStore } from "../../../../../utils/useStore";
import { EvalStore } from "../../../Stores/EvalStore";
import WorkflowInstanceLevel from "./WorkflowInstanceLevel";

const useStyles = makeStyles((theme: Theme) => ({
    evalTitle: {
        fontSize: "18px",
        fontWeight: theme.typography.fontWeightBold as any,
        color: theme.palette.black.main,
        whiteSpace: "nowrap",
    },
    status: {
        fontSize: "16px",
        color: theme.palette.gray.main,
        whiteSpace: "nowrap",
    },
}));

interface OwnProps {}

type Props = OwnProps;

/**
 * A list of instance sections
 * title of each section is {moduleName} - {questionName}
 * each instance section will render list of its instanceLevels show previously completed levels
 *
 */
const WorkflowInstanceList: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles(props);
    const evalStore = useStore(EvalStore);

    return (
        <>
            {evalStore.workflowStore.workflowInstances.map((instance) => {
                return (
                    instance.title && (
                        <section
                            key={
                                instance.name +
                                "|" +
                                instance.subTitle +
                                "|" +
                                instance.title
                            }
                        >
                            <Typography
                                component={"span"}
                                className={classes.status}
                            >
                                {instance.title}
                            </Typography>
                            <Typography
                                component={"span"}
                                className={classes.status}
                            >
                                {instance.subTitle}
                            </Typography>
                            <section>
                                {instance.levels.map((level) => {
                                    return (
                                        <WorkflowInstanceLevel
                                            key={level.level + level.instanceId}
                                            {...level}
                                        />
                                    );
                                })}
                            </section>
                        </section>
                    )
                );
            })}
        </>
    );
});

export default WorkflowInstanceList;
