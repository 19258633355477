import { action, observable, makeObservable } from "mobx";
import { BaseStore } from "../../../../../stores/BaseStore";
import { AcxStore } from "../../../../../stores/RootStore";

@AcxStore
export class StepperStore extends BaseStore {
    private readonly stepCount = 3;
    @observable stepIndex: number = 0;
    @observable disableStepping = false;

    onResetCallbacks: Array<() => void> = [];

    constructor() {
        super("Stepper Store");
        makeObservable(this);
    }

    @action
    addResetCallback(callback: (() => void) | undefined, stepIndex: number) {
        if (callback === undefined) {
            this.onResetCallbacks.splice(stepIndex, 1);
        } else {
            this.onResetCallbacks[stepIndex] = callback;
        }
    }

    @action
    setStepIndex = (stepIndex: number) => {
        this.stepIndex = stepIndex;
    };

    @action
    disableSteps = () => {
        this.disableStepping = true;
    };
    @action
    enableSteps = () => {
        this.disableStepping = false;
    };
    @action
    nextStep = () => {
        const nextStep = (this.stepIndex + 1) % this.stepCount;
        if (nextStep === 0 && this.stepIndex === 2) {
            // resetting back to step 0
            console.log("StepperSTore rolling over step indices");
            this.disableStepping = false;
            this.onResetCallbacks?.forEach((value) => value?.());
        }
        this.stepIndex = nextStep;
    };
    @action
    previousStep = () => {
        this.stepIndex--;
    };
}
