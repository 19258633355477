import { LicensedDataProcessing } from "models/DataProcesses";

export enum Saml2BindingType {
    HttpRedirect = 1,
    HttpPost = 2,
    Artifact = 4,
}

export const Saml2BindingTypeHash = {
    1: {
        value: Saml2BindingType.HttpRedirect,
        label: "HttpRedirect",
    },
    2: {
        value: Saml2BindingType.HttpPost,
        label: "HttpPost",
    },
    4: {
        value: Saml2BindingType.Artifact,
        label: "Artifact",
    },
};

export const saml2BindingTypes = [
    {
        value: Saml2BindingType.HttpRedirect,
        label: "HttpRedirect",
    },
    {
        value: Saml2BindingType.HttpPost,
        label: "HttpPost",
    },
    {
        value: Saml2BindingType.Artifact,
        label: "Artifact",
    },
];

export type Organization = {
    id?: string;
    name?: string;
    qbAppId?: string;
    blobContainer?: string;
    fileInStageAccount?: string;
    fileInAccount?: string;
    fileMainAccount?: string;
    montageFileAccount?: string;
    pgpKeyName?: string;
    middleOfTheFunnelAnalystName?: string;
    defaultTimeZoneId?: string;
    superOrganization?: boolean;
    isActive?: boolean;
    databaseConnectionString?: string;
    databaseVersion?: number;
    usesCoreDatabase?: boolean;
    enhancedEvalStatus?: boolean;
    enhancedPermissionStatus?: boolean;
    licensedData?: LicensedDataProcessing[];
    dataProcessingOptions?: string[];
    pipelineProcessingOption?: number;
    serviceSetup?: string[];
    $search?: string;
    EnableSamlSso?: boolean;
    saml2AuthenticxDomain?: string;
    saml2BindingType?: Saml2BindingType;
    saml2CertificateInPemFormat?: string;
    saml2EntityId?: string;
    saml2LoginButtonText?: string;
    saml2ResponseEmailPropertyName?: string;
    saml2ResponseFirstNamePropertyName?: string;
    saml2ResponseLastNamePropertyName?: string;
    saml2SingleSignOnServiceUrl?: string;
    saml2SingleSignOutServiceUrl?: string;
    timeoutDuration?: number;
    voiceRedactionFactor?: number;
    searchService?: string;
    searchServiceIndex?: string;
    eddyHourlyRate?: number;
    organizationConfigurations?: {
        evalAcknowledgeDays?: string;
        weeklyInteractionsMin?: string;
        weeklyInteractionsMax?: string;
    };
    evalAcknowledgeDays?: number;
    weeklyInteractionsMin?: number;
    weeklyInteractionsMax?: number;
    customEmailFooterHtml?: string;
    customEmailFooterPlainText?: string;
    weeklyInteractionAlertUserIds?: string[];
};

export type SAMLDataRequest = {
    organizationId?: string;
    enableSamlSso?: boolean;
    authenticxDomain?: string;
    bindingType?: Saml2BindingType;
    certificateInPemFormat?: string;
    entityId?: string;
    loginButtonText?: string;
    samlResponseEmailPropertyName?: string;
    samlResponseFirstNamePropertyName?: string;
    samlResponseLastNamePropertyName?: string;
    singleSignOnServiceUrl?: string;
    singleSignOutServiceUrl?: string;
};
