import React, { useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Collapse,
    IconButton,
    Tooltip,
    Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { observer } from "mobx-react";
import { useStore } from "utils/useStore";
import {
    ModulesLoading,
    WorkflowReviewStore,
} from "../Stores/WorkflowReviewStore";
import WFRQuestionAnswerList from "./WFRQuestionAnswerList/WFRQuestionAnswerList";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import AcxToast from "components/UI/AcxToast";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import WFRModuleCardFooter from "./WFRModuleCard/WFRModuleCardFooter";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { Divider } from "@mui/material";
import { isNotNull } from "utils/helpers";
import theme from "Theme/AppTheme";

const WFRTriggerCard: React.FC = observer(() => {
    const [open, setOpen] = useState(true);
    const store = useStore(WorkflowReviewStore);

    const modules = store.evaluationModules
        .map((evaluationModule) =>
            store.evalLicensedModules.find(
                (evalLicensedModule) =>
                    evalLicensedModule.id === evaluationModule.licensedModuleId,
            ),
        )
        .filter(isNotNull)
        .sort((a, b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        });

    if (!store.workflowReviewerInfo) return null;

    return (
        <Box sx={{ margin: theme.spacing(2, 8, 2, 8) }}>
            <Card>
                <CardHeader
                    title={
                        <Typography
                            style={{ fontWeight: 600, fontSize: "20px" }}
                        >
                            Workflow Trigger
                        </Typography>
                    }
                    subheader={
                        store.workflowReviewerInfo?.workflowType ===
                            WorkflowConditionType.ScoreEscalation &&
                        store.workflowReviewerInfo.workflowInfo.status ===
                            WorkflowStatus.Closed && (
                            <AcxToast
                                severity={
                                    store.thresholdMet ? "success" : "info"
                                }
                                title={
                                    store.thresholdMet
                                        ? `Score Threshold Met.`
                                        : `Score Threshold Not Met.`
                                }
                                message="Workflow marked as complete."
                            />
                        )
                    }
                    avatar={
                        <Tooltip title={open ? "Collapse" : "Expand"}>
                            <IconButton
                                onClick={() => {
                                    setOpen(!open);
                                }}
                                size="large"
                                disabled={store.getTaskLoadingV2(
                                    ModulesLoading,
                                )}
                            >
                                {open ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        </Tooltip>
                    }
                />
                <CardContent
                    sx={{
                        margin: theme.spacing(1),
                        maxHeight: "55vh",
                        overflowY: open ? "scroll" : "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#C0C0C0 white",
                    }}
                >
                    {store.getTaskLoadingV2(ModulesLoading) ? (
                        <AcxLoadingIndicator
                            color={"secondary"}
                            alternate={"PuffLoader"}
                            size={75}
                        />
                    ) : (
                        <Collapse in={open}>
                            {modules.map((module, idx) => (
                                <Box padding={1}>
                                    {!!module?.name && (
                                        <Box
                                            sx={{
                                                paddingLeft: theme.spacing(3),
                                                paddingTop:
                                                    idx === 0
                                                        ? theme.spacing(0)
                                                        : theme.spacing(3),
                                                paddingBottom: theme.spacing(1),
                                                marginBottom: "16px",
                                            }}
                                        >
                                            <Typography variant="h1">
                                                {module?.name}
                                            </Typography>
                                        </Box>
                                    )}
                                    <WFRQuestionAnswerList
                                        module={module}
                                        disputeHasStarted={false}
                                        hideHiddenAnswers
                                        isEditable={
                                            store.triggerModuleIsEditable
                                        }
                                        isNoteEditable={
                                            store.triggerNoteIsEditable
                                        }
                                    />
                                    <WFRModuleCardFooter module={module} />
                                    <Divider variant="middle" />
                                </Box>
                            ))}
                        </Collapse>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
});

export default WFRTriggerCard;
