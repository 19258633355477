import BaseService from "./BaseService";

export interface Campaign {
    createdBy: string;
    createdOn: string;
    deletedOn: string | null;
    description: string;
    externalName: string;
    id: string;
    isActive: boolean;
    modifiedBy: string;
    modifiedOn: string;
    name: string;
    organization: string | null;
    organizationId: string;
}

export class CampaignService extends BaseService {
    protected urlBase: string = "api/Campaign";

    public async getCampaigns() {
        return await this.get<Campaign[]>(new URLSearchParams({}), "");
    }
}
