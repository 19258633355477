import { camelCase } from "lodash";
import { useCallback, useState } from "react";
import { isEmpty } from "shared/functions/isEmpty";
import { isValid } from "shared/functions/isValid";
import { FormErrors, FormFieldErrors } from "shared/types/FormErrors.type";

type ReturnType<T> = {
    value: T;
    registerSubmit: (
        fnSubmit: (values: T) => any,
        // We don't know what the response looks like...we could send in a type, but not sure it is worth the effort?
        {
            onSuccess,
            onFail,
        }: {
            onSuccess: (response: any) => void;
            onFail?: (errors: any) => void;
        },
    ) => () => Promise<void>;
    isDirty: boolean;
    isSubmitting: boolean;
    setValue: (values: T, dirty?: boolean) => void;
    patchValue: (values: Partial<T>) => void;
    reset: () => void;
    errors: FormErrors;
    onChange: (key: keyof T, val: any) => void;
    initialValue: T;
};

type FieldValidationRule<T> = {
    required?: { message: string };
    pattern?: {
        value: RegExp;
        message: string;
        opposite?: boolean;
    }[];
    maxLength?: { value: number; message: string };
    minLength?: { value: number; message: string };
    function?: { value: (values: T) => any };
};

export type FieldValidationRules<T> = {
    [K in keyof T]?: FieldValidationRule<T>;
};

export function useForm<T, RT = void>(
    initialValue: T,
    fieldValidationRules?: FieldValidationRules<T>,
): ReturnType<T> {
    const [formValue, setFormValue] = useState<T>(initialValue);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [errors, setErrors] = useState<FormErrors>({});

    const updateErrors = useCallback(
        (newValues: T) => {
            if (fieldValidationRules) {
                const validation = Object.entries(fieldValidationRules) as [
                    keyof T,
                    FieldValidationRule<T>,
                ][];
                const newErrors = validation.reduce(
                    (obj: FormFieldErrors, [key, rule]) => {
                        const field = key as string;
                        const val = newValues[key];

                        // Required
                        if (
                            rule.required &&
                            (isEmpty(val) ||
                                (Array.isArray(val) && !val?.length))
                        ) {
                            const err =
                                rule.required.message ||
                                `This field is required`;
                            obj[field] = obj[field]
                                ? `${obj[field]}\n${err}`
                                : err;
                        }

                        // Min length
                        if (
                            isValid(val) &&
                            rule.minLength &&
                            rule.minLength.value > String(val).length
                        ) {
                            const err =
                                rule.minLength.message ||
                                `This field must be at least ${rule.minLength.value} characters.`;
                            obj[field] = obj[field]
                                ? `${obj[field]}\n${err}`
                                : err;
                        }

                        // Max length
                        if (
                            isValid(val) &&
                            rule.maxLength &&
                            rule.maxLength.value < String(val).length
                        ) {
                            const err =
                                rule.maxLength.message ||
                                `This field must be at most ${rule.maxLength.value} characters.`;
                            obj[field] = obj[field]
                                ? `${obj[field]}\n${err}`
                                : err;
                        }

                        // Regex
                        if (isValid(val) && rule.pattern?.length) {
                            for (let pattern of rule.pattern) {
                                const testVal = pattern.value.test(String(val));
                                if (pattern.opposite ? testVal : !testVal) {
                                    const err =
                                        pattern.message ||
                                        "This field has criteria that have not been met.";
                                    obj[field] = obj[field]
                                        ? `${obj[field]}\n${err}`
                                        : err;
                                }
                            }
                        }

                        // Function
                        if (rule.function?.value) {
                            const fnVal = rule.function.value(newValues);
                            if (fnVal) {
                                const err = fnVal || "Something went wrong.";
                                obj[field] = obj[field]
                                    ? `${obj[field]}\n${err}`
                                    : err;
                            }
                        }

                        return obj;
                    },
                    {},
                );
                setErrors({ fieldErrors: newErrors });
                return newErrors;
            }
            return {};
        },
        [fieldValidationRules],
    );

    const patchValue = useCallback((values: Partial<T>) => {
        setFormValue((prev) => ({
            ...prev,
            ...values,
        }));
        markAsDirty();
    }, []);

    const onChange = useCallback((key: keyof T, val: any) => {
        setFormValue((prev) => {
            if (JSON.stringify(prev[key]) !== JSON.stringify(val)) {
                const values = { ...prev };
                values[key] = val;
                return values;
            } else {
                return prev;
            }
        });
        markAsDirty();
    }, []);

    const markAsDirty = () => {
        setIsDirty(true);
    };

    const setValue = useCallback((newValues: T, dirty = true) => {
        setFormValue(newValues);
        if (dirty) {
            markAsDirty();
        }
    }, []);

    const reset = () => {
        setFormValue(initialValue);
    };

    const registerSubmit = (
        fnSubmit: (values: T) => Promise<any>,
        {
            onSuccess,
            onFail,
        }: {
            onSuccess: (
                response: T | Partial<RT> | undefined | Response,
            ) => void;
            onFail?: (errors?: any) => void;
        },
    ) => {
        return async () => {
            const fieldErrors = updateErrors(formValue);
            if (!fieldErrors || !Object.keys(fieldErrors).length) {
                try {
                    setIsSubmitting(true);
                    setErrors({});
                    const res = await fnSubmit(formValue);
                    onSuccess(res);
                } catch (err: any) {
                    //@ts-ignore
                    if (err?.errorJson) {
                        //@ts-ignore
                        if (typeof err?.errorJson === "string") {
                            console.log(1);
                            const errorsToUse = {
                                //@ts-ignore
                                form: [err?.errorJson],
                                fieldErrors,
                            };
                            setErrors(errorsToUse);
                            //@ts-ignore
                        } else if (Array.isArray(err.errorJson)) {
                            console.log(2);
                            const errorsToUse = {
                                //@ts-ignore
                                form: err.errorJson.map((e) =>
                                    JSON.stringify(e),
                                ),
                                fieldErrors,
                            };
                            setErrors(errorsToUse);
                            //@ts-ignore
                        } else if (Object.keys(err.errorJson)?.length) {
                            console.log(3);
                            //@ts-ignore
                            let { form, ...other } = err.errorJson;
                            form = form || [];
                            const fieldErrors = Object.keys(other || {}).reduce(
                                (obj, prop) => {
                                    const isArrayError = prop.includes("[");

                                    if (isArrayError) {
                                        const [propToUse] = prop
                                            .replace(/]/g, "")
                                            .split("[");
                                        const camelProp = camelCase(propToUse);
                                        if (
                                            Object.prototype.hasOwnProperty.call(
                                                formValue,
                                                camelProp,
                                            )
                                        ) {
                                            // If the field exists, put the error on the field
                                            // obj[camelProp] = obj[camelProp] || [];
                                            // obj[camelProp][prop] = other[prop].join("\n");

                                            obj[prop] = other[prop].join("\n");
                                        } else {
                                            // If the field doesn't exist, put the error on the form
                                            form.push(
                                                JSON.stringify(other[prop]),
                                            );
                                        }
                                    } else {
                                        const camelProp = camelCase(prop);
                                        if (
                                            Object.prototype.hasOwnProperty.call(
                                                formValue,
                                                camelProp,
                                            )
                                        ) {
                                            // If the field exists, put the error on the field
                                            obj[camelProp] =
                                                other[prop].join("\n");
                                        } else {
                                            // If the field doesn't exist, put the error on the form
                                            form.push(
                                                JSON.stringify(other[prop]),
                                            );
                                        }
                                    }
                                    return obj;
                                },
                                {},
                            );

                            const errorsToUse = {
                                form: form.map((err) =>
                                    JSON.stringify(err, null, 2),
                                ),
                                fieldErrors,
                            };
                            setErrors(errorsToUse);
                        } else {
                            console.log(4);
                            const errorsToUse = {
                                form: [JSON.stringify(err.errorJson)],
                                fieldErrors,
                            };
                            setErrors(errorsToUse);
                        }
                    } else {
                        const errorsToUse = {
                            form: [JSON.stringify(err)],
                            fieldErrors,
                        };
                        setErrors(errorsToUse);
                    }
                    onFail?.();
                }
                setIsSubmitting(false);
            } else {
                onFail?.();
            }
        };
    };

    return {
        value: formValue,
        registerSubmit,
        isSubmitting,
        isDirty,
        setValue,
        patchValue,
        reset,
        errors,
        onChange,
        initialValue,
    };
}
