import { useEffect, useState } from "react";
import UserService from "../OrganizationDetail/OrganizationUsers/User.service";
import { User } from "../types/User.type";

type Props = {
    id?: string;
    organizationId: string;
};

const useOrganizationUser = ({ id, organizationId }: Props) => {
    const [user, setUser] = useState<User | null>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!id || id === "add") {
            return;
        }

        let destroyed = false;
        const runFn = async () => {
            try {
                setIsLoading(true);
                const res = await UserService.getUser(id);
                if (!destroyed) {
                    setUser(res);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };
        runFn();
        return () => {
            destroyed = true;
            setUser(null);
        };
    }, [id]);

    const saveUser = async (val: User) => {
        const res = await UserService.saveUser({ ...val, organizationId });
        setUser(res);
        return res;
    };

    const changePassword = async (newPassword: string) => {
        if (!user?.id) {
            return;
        }
        return UserService.changePassword(user?.id, newPassword);
    };

    return {
        user,
        saveUser,
        isLoading,
        changePassword,
    };
};

export default useOrganizationUser;
