import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const AgentsSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            opacity={props.opacity ?? 0.5}
        >
            <path
                d="M0.400024 1.5999C0.400024 1.28164 0.526453 0.976418 0.751496 0.751374C0.97654 0.52633 1.28176 0.399902 1.60002 0.399902H4.18362C4.46767 0.400033 4.74246 0.500919 4.95912 0.684614C5.17577 0.868309 5.32024 1.1229 5.36682 1.4031L6.25482 6.7251C6.29725 6.97856 6.25719 7.23895 6.14054 7.46794C6.02389 7.69693 5.8368 7.88241 5.60682 7.9971L3.74922 8.9247C4.41535 10.5755 5.40742 12.075 6.66615 13.3338C7.92489 14.5925 9.42444 15.5846 11.0752 16.2507L12.004 14.3931C12.1186 14.1633 12.3039 13.9764 12.5327 13.8598C12.7614 13.7431 13.0215 13.7029 13.2748 13.7451L18.5968 14.6331C18.877 14.6797 19.1316 14.8242 19.3153 15.0408C19.499 15.2575 19.5999 15.5323 19.6 15.8163V18.3999C19.6 18.7182 19.4736 19.0234 19.2486 19.2484C19.0235 19.4735 18.7183 19.5999 18.4 19.5999H16C7.38402 19.5999 0.400024 12.6159 0.400024 3.9999V1.5999Z"
                fill={props.color ?? "#1F1F1F"}
            />
        </svg>
    );
};

export default AgentsSvg;
