import { observer } from "mobx-react";
import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthStatus, AuthStore } from "../../../stores/AuthStore";
import { useStore } from "../../../utils/useStore";
import {
    ApplicationPaths,
    QueryParameterNames,
} from "../api-authorization/ApiAuthorizationConstants";
import LoginCallback from "../LoginCallback";
import LocalStorage from "stores/LocalStorage";

const AuthorizeRouteV6 = observer(({ children }: { children: JSX.Element }) => {
    const authStore = useStore(AuthStore);
    const localStore = useStore(LocalStorage);
    const connectLocal = localStore.getLocalStore("Connect");
    const location = useLocation();
    const [authorizationURI, setAuthorizationURI] = useState("");
    const [loadingAuthorizationURI, setLoadingAuthorizationURI] =
        useState(true);

    React.useEffect(() => {
        if (location) {
            authStore.setCurrentPage(location.pathname);
        }
    }, [authStore, location, location.pathname]);

    React.useEffect(() => {
        async function checkForConnect() {
            const authorization_uri = (await connectLocal.getItem(
                "authorizationURI",
            )) as string;
            setAuthorizationURI(authorization_uri);
            setLoadingAuthorizationURI(false);
        }

        checkForConnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectUrl = `${ApplicationPaths.Login}?${
        QueryParameterNames.ReturnUrl
    }=${encodeURIComponent(window.location.href)}`;

    const status: AuthStatus = authStore.status;
    const authenticated: boolean = authStore._isAuthenticated;
    // const authStoreStatus = authStore.currentState;

    if (loadingAuthorizationURI) {
        return <LoginCallback />;
    }

    if (authenticated && status === AuthStatus.Initializing) {
        // authenticated but still loading perm and org store
        // console.log("auth is initializing");
        return <LoginCallback />;
    } else if (authenticated && status === AuthStatus.Initialized) {
        // everything is good to go
        // authStore.canUserViewAdminApp();

        // If an authorizationURI is saved, we are in the connect flow
        // which means we need to move the user to the consent page
        if (!!authorizationURI) {
            return <Navigate to={"/connect/consent"} />;
        }
        if (location.pathname.includes("/admin")) {
            if (!authStore.canUserViewAdminApp()) {
                return <Navigate to={"/app"} />;
            }
        }
        return children;
    } else if (!authenticated && status === AuthStatus.Initializing) {
        // console.log("not authed & initializing");
        // everything is still loading
        return <Navigate to={redirectUrl} replace />;
        // return <LoginCallback />;
    } else if (!authenticated) {
        return <LoginCallback />;
    }
    return <></>;
});

export default AuthorizeRouteV6;
