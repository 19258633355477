import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import React, { useEffect, useMemo, useRef } from "react";
import Chart from "react-google-charts";
import { GoogleChartWrapper, GoogleViz } from "react-google-charts/dist/types";
import { getVAxisWidth } from "utils/helpers";
import theme from "../../../Theme/AppTheme";
import { defaultColorScheme } from "./GraphColorSchemes";

type AxesScaleTypes = "log" | "mirrorLog" | null;
type GoogleDataFormat = any[][];

interface IndividualSeriesOptions {
    annotations?: Record<string, unknown>;
    color?: string;
    curveType?: string;
    labelInLegend?: string;
    lineDashStyle?: string;
    lineWidth?: number;
    pointShape?: string;
    pointSize?: number;
    pointsVisible?: boolean;
    targetAxisIndex?: number;
    visibleInLegend?: boolean;
}

type SeriesOptions =
    | Record<number, IndividualSeriesOptions>
    | IndividualSeriesOptions[];

// see: https://developers.google.com/chart/interactive/docs/customizing_axes#number-formats
type NumberFormatPreset =
    | "none"
    | "decimal"
    | "scientific"
    | "currency"
    | "percent"
    | "short"
    | "long";

interface OwnProps {
    data?: GoogleDataFormat;
    colorScheme?: string[];
    dataOpacity?: number;

    legendPosition: "none" | "bottom" | "in" | "right" | "top";
    legendAlignment?: "start" | "center" | "end";
    showGrid?: boolean;
    yTitle?: string;
    xTitle?: string;
    vAxisFormat?: NumberFormatPreset;

    onSelect?: (
        series: string | null,
        x: string | number | null,
        y: string | number | null | any,
        chartId?: string,
        metadata?: any[],
    ) => void;

    chartAreaLeftOffset?: number | string;
    chartAreaTopOffset?: number | string;
    chartAreaHeight?: number | string;
    chartAreaWidth?: number | string;
    slantedTextAngle?: number;
    verticalScaleType?: AxesScaleTypes;

    showDemoData?: boolean;
    asWidget?: boolean;
    asAreaChart?: boolean;
    fixedHeight?: boolean;
    vAxisFontSize?: string;
    hAxisFontSize?: string;
    vAxisTitleStyle?: Record<string, unknown>;
    vAxisTextStyle?: Record<string, unknown>;

    onChartReady?: (
        chartWrapper: GoogleChartWrapper,
        google: GoogleViz,
    ) => void;

    metadata?: Map<string, any[]>;
    chartId?: string;
    chartHeight?: string;
    hideDataLabels?: boolean;
    hideDataMarkers?: boolean;

    usesHtmlTooltips?: boolean;
    series?: SeriesOptions;
}

type Props = OwnProps;

const AcxLineGraph: React.FC<Props> = (props) => {
    const wrapper = useRef<null | any>();
    useEffect(() => {
        return () => {
            wrapper.current?.getChart()?.clearChart();
        };
    }, []);

    const data = Boolean(props.showDemoData) ? fake_data : props.data;

    const showLegend = (data?.[0].length ?? 0) > 2;

    const options = useMemo(() => {
        const res = {
            interpolateNulls: true,
            fontName: "Inter",
            dataOpacity: props.dataOpacity ?? 0.65,
            animation: {
                startup: true,
                duration: 500,
                easing: "out",
            },
            colors: props.colorScheme ?? defaultColorScheme,
            tooltip: {
                textStyle: { fontName: "Inter" },
                isHtml: !!props.usesHtmlTooltips,
            },
            legend: {
                position: showLegend ? props.legendPosition : "none",
                alignment: props.legendAlignment ?? "start",
                textStyle: {
                    fontName: "Inter",
                },
                maxLines: 5,
            },
            chart: {},
            chartArea: {
                left: props.vAxisFontSize
                    ? getVAxisWidth(props.vAxisFontSize)
                    : props.chartAreaLeftOffset ??
                      (props.asWidget ? "8%" : "4%"),
                top: props.chartAreaTopOffset ?? "5%",
                width: props.chartAreaWidth ?? (props.asWidget ? "88%" : "90%"),
                height: props.chartAreaHeight
                    ? `${props.chartAreaHeight.toString().replace("%", "")}%`
                    : props.asWidget
                    ? "77%"
                    : "90%",
            },
            hAxis: {
                title: props.xTitle,
                titleTextStyle: {
                    color: theme.palette.gray[400],

                    fontSize: props.asWidget ? 13 : 16,
                    fontName: "Inter Bold",
                },
                textStyle: {
                    color: theme.palette.grey[300],
                    fontSize: props.asWidget ? 10 : props.hAxisFontSize ?? 9,
                    fontName: "Inter Bold",
                },
                gridlines: {
                    color: props.showGrid ? "E7E7E7" : "none",
                },
            },
            pointSize: props.hideDataMarkers ? 0 : 5,
            vAxis: {
                scaleType: null as AxesScaleTypes,
                title: props.yTitle,
                titleTextStyle: {
                    color: theme.palette.gray[400],
                    italic: false,
                    fontSize: props.asWidget ? 13 : 16,
                    fontName: "Inter Bold",
                    ...props.vAxisTitleStyle,
                },
                textStyle: {
                    color: theme.palette.gray[400],
                    ...props.vAxisTextStyle,
                    fontSize: props.asWidget ? 10 : props.vAxisFontSize ?? 13,
                    fontName: "Inter Bold",
                },
                gridlines: {
                    color: props.showGrid ? "E7E7E7" : "none",
                },
                minValue: 0,
                format: props.vAxisFormat,
            },
            annotations: props.hideDataLabels
                ? {
                      textStyle: {
                          fontSize: 0, // Set font size to 0 to hide labels
                      },
                  }
                : { textStyle: { color: "black" } },

            series: props.series,
            enableInteractivity: true,
        };
        res.vAxis.scaleType = props.verticalScaleType ?? null;

        return res;
    }, [
        props.asWidget,
        props.chartAreaHeight,
        props.chartAreaLeftOffset,
        props.chartAreaTopOffset,
        props.chartAreaWidth,
        props.colorScheme,
        props.dataOpacity,
        props.hAxisFontSize,
        props.hideDataLabels,
        props.hideDataMarkers,
        props.legendAlignment,
        props.legendPosition,
        props.showGrid,
        props.vAxisFontSize,
        props.verticalScaleType,
        props.xTitle,
        props.yTitle,
        props.usesHtmlTooltips,
        props.vAxisTitleStyle,
        props.vAxisTextStyle,
        props.vAxisFormat,
        props.series,
        showLegend,
    ]);

    return (
        <>
            {Boolean(data) && (
                <Chart
                    width={`100%`}
                    height={
                        props.chartHeight
                            ? props.chartHeight
                            : props.asWidget
                            ? props.fixedHeight
                                ? "300px"
                                : "97%"
                            : "100%"
                    }
                    chartType={props.asAreaChart ? "AreaChart" : "LineChart"}
                    loader={
                        <AcxLoadingIndicator
                            color="secondary"
                            alternate="PuffLoader"
                            size={96}
                            style={
                                props.chartHeight
                                    ? {
                                          height: props.chartHeight,
                                          zIndex: 1098,
                                      }
                                    : {}
                            }
                        />
                    }
                    data={data}
                    options={options}
                    getChartWrapper={(w, g) => {
                        wrapper.current = w;
                        props.onChartReady?.(w, g);
                    }}
                    chartEvents={[
                        {
                            eventName: "select",
                            callback: ({ chartWrapper }) => {
                                const chart = chartWrapper.getChart();
                                const selection = chart.getSelection();
                                if (selection.length === 1) {
                                    const [selectedItem] = selection;
                                    const dataTable =
                                        chartWrapper.getDataTable();
                                    const { row, column } = selectedItem;

                                    if (row !== null && column !== null) {
                                        const rowLabel = data?.[row + 1]?.[0];

                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            rowLabel,
                                            dataTable?.getValue(row, column) ??
                                                null,
                                            props.chartId,
                                            props.metadata?.get(rowLabel),
                                        );
                                    } else if (row !== null) {
                                        const rowLabel = data?.[row + 1]?.[0];

                                        props.onSelect?.(
                                            null,
                                            rowLabel,
                                            null,
                                            props.chartId,
                                            props.metadata?.get(rowLabel),
                                        );
                                    } else if (column !== null) {
                                        props.onSelect?.(
                                            dataTable?.getColumnLabel(column) ??
                                                null,
                                            null,
                                            null,
                                        );
                                    }
                                }
                            },
                        },
                    ]}
                />
            )}
        </>
    );
};

const fake_data = [
    ["x", "Insights", "Quality", "Compliance"],
    [0, 0, 0, 2],
    [1, 10, 5, 12],
    [2, 23, 15, 34],
    [3, 17, 9, 5],
    [4, 18, 10, 22],
    [5, 9, 5, 3],
    [6, 11, 3, 3],
    [7, 27, 19, 50],
];

export default AcxLineGraph;
