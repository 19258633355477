import { Grid, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import { CombinatorRuleType } from "models/RuleCombinator";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import AcxButton from "components/UI/AcxButton";
import RBCFilterItem from "./RBCFilterItem";
import RBCFilterStore from "./RBCFilterStore";
import { blueButtonWhiteBackgroundStyle } from "utils/Style";

const useRBCStyles = makeStyles((theme: Theme) => ({
    labelText: { fontWeight: "bold" },
    addIcon: {
        border: "1px solid #E4E4E7",
        borderRadius: "4px",
    },
    containerPadding: { paddingBottom: theme.spacing(1) },
    icon: {
        height: "16px",
        width: "16px",
        marginLeft: "4px",
    },
}));

interface Props {
    store: RBCFilterStore;
}

const RBCFilterInput: React.FunctionComponent<Props> = observer(({ store }) => {
    const classes = useRBCStyles();

    const [isAddingItem, setIsAddingItem] = useState(false);

    const handleAdd = (combinator: CombinatorRuleType) => {
        store.addRBCFilterItem(combinator);
        setIsAddingItem(false);
    };

    const renderLabel = (
        <Grid item container alignItems="center" xs={10}>
            <Typography className={classes.labelText} component="span">
                Classifiers
            </Typography>
            <span>
                <Tooltip title="If a classifier is new, we may not know if it's present in historical conversations. Those conversations will not be included in the results.">
                    <Grid container alignItems="center">
                        <InfoIcon
                            sx={{
                                height: "16px",
                                width: "16px",
                                marginLeft: "4px",
                            }}
                            color="primary"
                        />
                    </Grid>
                </Tooltip>
            </span>
        </Grid>
    );

    const renderAddBlockButton = (
        <Grid item>
            <IconButton
                size="small"
                onClick={() => {
                    if (store.RBCFilterItems.length === 0) {
                        store.addRBCFilterItem(0);
                    } else {
                        setIsAddingItem(true);
                    }
                }}
            >
                <AddIcon className={classes.addIcon} />
            </IconButton>
        </Grid>
    );

    const renderAddAndOrButtons = (
        <Grid container style={{ marginTop: "8px" }}>
            <Grid item style={{ marginRight: "10px" }}>
                <AcxButton
                    fullWidth
                    id="RBC-filter-input-add-and-button"
                    onClick={() => handleAdd(CombinatorRuleType.And)}
                    style={blueButtonWhiteBackgroundStyle}
                >
                    + AND
                </AcxButton>
            </Grid>
            <Grid item>
                <AcxButton
                    fullWidth
                    id="RBC-filter-input-add-or-button"
                    onClick={() => handleAdd(CombinatorRuleType.Or)}
                    style={blueButtonWhiteBackgroundStyle}
                >
                    + OR
                </AcxButton>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Grid
                container
                item
                justifyContent="space-between"
                alignItems="center"
                className={classes.containerPadding}
                xs={12}
            >
                {renderLabel}
                {renderAddBlockButton}
            </Grid>
            {store.RBCFilterItems.map((item, index) => (
                <RBCFilterItem
                    item={item}
                    index={index}
                    key={index}
                    store={store}
                />
            ))}
            {isAddingItem && renderAddAndOrButtons}
        </>
    );
});

export default RBCFilterInput;
