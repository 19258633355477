import {
    Button,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    Input,
    Slider,
    Theme,
    Typography,
    Zoom,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import TuneIcon from "@mui/icons-material/Tune";
import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React from "react";
import { FieldUse } from "../../models/Reporting/ReportField";
import { VizType } from "../../models/Reporting/ReportModel";
import { capitalizeFirstChar } from "../../utils/helpers";
import hexToRGB from "../../utils/hexToRGB";
import { isNumeric } from "../../utils/StringUtils";
import VerticalDrawerContentTemplate from "../UI/Drawer/VerticalDrawerContentTemplate";
import AcxSelectSingle from "../UI/Select/BaseSelectComponents/AcxSelectSingle";
import ReportsStore, { IReport } from "./Stores/ReportsStore";

const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
const IOSSlider = withStyles({
    root: {
        color: "#3880ff",
        height: 2,
        padding: "15px 0",
        marginTop: "20px",
    },
    thumb: {
        height: 28,
        width: 28,
        backgroundColor: "#fff",
        boxShadow: iOSBoxShadow,
        marginTop: -14,
        marginLeft: -14,
        "&:focus, &:hover, &$active": {
            boxShadow:
                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 12px)",
        top: -22,
        "& *": {
            background: "transparent",
            color: "#000",
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: "#bfbfbf",
    },
    mark: {
        backgroundColor: "#bfbfbf",
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: "currentColor",
    },
})(Slider);

const useStyles = makeStyles((theme: Theme) => ({
    bottomMargin: {
        marginBottom: theme.spacing(2.45),
    },
    noOptionsText: {
        color: "#1F1F1F",
        opacity: 0.25,
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "bold",
        letterSpacing: 0,
        lineHeight: "20px",
        textAlign: "center",
    },
    noOptionsIcon: {
        fontSize: "160px",
        color: hexToRGB(theme.palette.gray.main, 0.2),
    },
    noOptionsTextContainer: {
        marginTop: theme.spacing(2.5),
    },
    mainContent: {
        marginTop: theme.spacing(0.25),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
    },
    infoText: {
        // paddingRight: theme.spacing(.5),
        // paddingLeft: theme.spacing(1),
        marginBottom: theme.spacing(0.55),
        // color: theme.palette.text.primary,
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        width: "100%",
    },
    infoTitle: {
        fontWeight: "bold",
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
    },
    contentPadding: {
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
    },
}));

const bucketingAlgs = [
    { value: "sqrt", label: "Square Root" },
    { value: "sturges", label: "Sturges" },
    { value: "rice", label: "Rice" },
] as Array<{ value: "sqrt" | "sturges" | "rice"; label: string }>;

const logAxisTypes = [
    { value: "log", label: "Log Scaling" },
    { value: "mirrorLog", label: "MirrorLog Axis" },
] as Array<{ value: "log" | "mirrorLog"; label: string }>;

function logAxisTypeToSelectObj(axis: string) {
    return logAxisTypes.find((value) => value.value === axis);
}

function bucketAlgToSelectObj(alg: string) {
    return (
        bucketingAlgs.find((value) => value.value === alg) ?? bucketingAlgs[0]
    );
}

interface ISelectorProps {
    valueToSelectorObj: (arg: any) => any;
    enableNoSelection?: boolean;
    selectOptions: any[];
}

interface ISliderProps {
    steps: number;
    min: number;
    max: number;
}

interface IInputProps {
    inputHelpText?: string;
}

interface IVizControlProps extends Props {
    option: string;
    title: string;
    infoText: string;
    defaultValue: string | number;
}

const SliderVizControl: React.FC<IVizControlProps & ISliderProps> = observer(
    (props) => {
        const classes = useStyles();

        function handleSliderChange(event, value) {
            props.report.vizOptions[props.option] = value;
        }

        return (
            <React.Fragment key={`${props.title}-${props.option}`}>
                <Grid
                    zeroMinWidth
                    item
                    xs={12}
                    className={classes.bottomMargin}
                >
                    <Typography
                        color={"textSecondary"}
                        className={classes.infoText}
                        gutterBottom
                    >
                        <span className={classes.infoTitle}>{props.title}</span>{" "}
                        - {props.infoText}
                    </Typography>

                    <div className={classes.contentPadding}>
                        <IOSSlider
                            onChange={handleSliderChange}
                            value={
                                props.report.vizOptions[props.option] ??
                                props.defaultValue
                            }
                            valueLabelDisplay="on"
                            step={props.steps}
                            // marks
                            min={props.min}
                            max={props.max}
                        />
                    </div>
                </Grid>
            </React.Fragment>
        );
    },
);

const InputVizControl: React.FC<IVizControlProps & IInputProps> = observer(
    (props) => {
        const classes = useStyles();

        function handleInputChange(event) {
            const value: string = event.target.value;
            if (isNumeric(value)) {
                props.report.vizOptions[props.option] = value;
            } else {
                props.report.vizOptions[props.option] = props.defaultValue;
            }
        }

        return (
            <React.Fragment key={`${props.title}-${props.option}`}>
                <Grid
                    zeroMinWidth
                    item
                    xs={12}
                    className={classes.bottomMargin}
                >
                    <Typography
                        color={"textSecondary"}
                        className={classes.infoText}
                        gutterBottom
                    >
                        <span className={classes.infoTitle}>{props.title}</span>{" "}
                        - {props.infoText}
                    </Typography>
                    <Input
                        fullWidth
                        color={"secondary"}
                        placeholder={`${props.defaultValue}`}
                        value={
                            props.report.vizOptions[props.option] ??
                            props.defaultValue
                        }
                        onChange={handleInputChange}
                        type={"number"}
                        inputProps={{
                            min: 0,
                            inputMode: "numeric",
                        }}
                    />
                    {props.inputHelpText && (
                        <FormHelperText>{props.inputHelpText}</FormHelperText>
                    )}
                </Grid>
            </React.Fragment>
        );
    },
);

const SingleSelectVizControl: React.FC<IVizControlProps & ISelectorProps> =
    observer((props) => {
        const classes = useStyles();

        function handleSelectChange(arg?: { value: any; label: string }) {
            if (arg?.value === undefined) {
                delete props.report.vizOptions[props.option];
                return;
            }
            props.report.vizOptions[props.option] = arg.value;
        }

        return (
            <React.Fragment key={`${props.title}-${props.option}`}>
                <Grid
                    zeroMinWidth
                    item
                    xs={12}
                    className={classes.bottomMargin}
                >
                    <Typography
                        color={"textSecondary"}
                        className={classes.infoText}
                        gutterBottom
                    >
                        <span className={classes.infoTitle}>{props.title}</span>{" "}
                        - {props.infoText}
                    </Typography>
                    <AcxSelectSingle
                        options={props.selectOptions}
                        id={`viz-control-component-${props.title}-${props.option}`}
                        fullWidth
                        enableNoSelection={props.enableNoSelection}
                        valueField={"value"}
                        labelField={"label"}
                        defaultValue={props.valueToSelectorObj(
                            props.report.vizOptions[props.option],
                        )}
                        onChange={handleSelectChange}
                    />
                </Grid>
            </React.Fragment>
        );
    });

const CheckboxVizControl: React.FC<IVizControlProps> = observer((props) => {
    const classes = useStyles();

    function handleCheckChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (
            !event.target.checked &&
            props.report.report?.vizOptions?.[props.option] == null
        ) {
            delete props.report.vizOptions[props.option];
            return;
        }

        props.report.vizOptions[props.option] = event.target.checked;
    }

    return (
        <React.Fragment key={`${props.title}-${props.option}`}>
            <Grid zeroMinWidth item xs={12} className={classes.bottomMargin}>
                <Typography
                    color={"textSecondary"}
                    className={classes.infoText}
                    gutterBottom
                >
                    <span className={classes.infoTitle}>{props.title}</span> -{" "}
                    {props.infoText}
                </Typography>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={
                                props.report.vizOptions[props.option] ?? false
                            }
                            onChange={handleCheckChange}
                            name={props.title}
                        />
                    }
                    label={props.title}
                />
            </Grid>
        </React.Fragment>
    );
});

interface OwnProps {
    report: IReport;
    store: ReportsStore;
}

type Props = OwnProps;

const VizOptions: React.FC<Props> = observer((props) => {
    const classes = useStyles();

    const defaultContent: JSX.Element = (
        <React.Fragment key={"empty-viz-controls"}>
            <Grid
                container
                item
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
                className={classes.noOptionsTextContainer}
            >
                <Grid item xs={12} container justifyContent={"center"}>
                    <TuneIcon className={classes.noOptionsIcon} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent={"center"}
                    style={{ marginTop: "9px" }}
                >
                    <Typography
                        variant={"h2"}
                        className={classes.noOptionsText}
                    >
                        No options available
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );

    let controls = [] as any[];

    const enablePercentFormatting = (
        <CheckboxVizControl
            option={"enablePercentFormat"}
            key={"viz-cntrl-checkbox-show-as-percent"}
            title={"Show Scores as Percentages"}
            infoText={"Renders fractional scores as percentages"}
            defaultValue={""}
            {...props}
        />
    );

    const chartHeightControl = (
        <InputVizControl
            key={"chart-height-control"}
            option={
                props.report.vizType !== VizType.HorizontalBar
                    ? "chartAreaHeight"
                    : "chartAreaWidth"
            }
            title={"Chart Height"}
            infoText={`change ${
                props.report.vizType !== VizType.HorizontalBar
                    ? "height"
                    : "width"
            } of chart to give primary-axis labels more room`}
            defaultValue={
                props.report.vizType !== VizType.HorizontalBar ? "90" : "95"
            }
            {...props}
        />
    );

    const slantedAxisTextControl = (
        <SliderVizControl
            key={"chart-slanted-axis-labels-control"}
            option={"slantedTextAngle"}
            title={"Axis Label Slant"}
            infoText={"The angle of the primary-axis text"}
            defaultValue={15}
            steps={0.1}
            min={15}
            max={90}
            {...props}
        />
    );

    if (props.report.vizType === VizType.Histogram) {
        controls = [
            <SliderVizControl
                key={"viz-cntrl-slider-1"}
                option={"lastBucketPercentile"}
                title={"Last percentile"}
                infoText={
                    "changes the computation of bucket widths to ignore the values that are higher or lower than the remaining values by the percentage you specify. The values are still included in the histogram, but do not affect how they're bucketed. This is useful when you don't want outliers to land in their own buckets; they will be grouped with the first or last buckets instead. Defaults to 0"
                }
                defaultValue={0}
                steps={0.1}
                min={0}
                max={30}
                {...props}
            />,
            <InputVizControl
                key={"viz-cntrl-input-1"}
                option={"bucketSize"}
                title={"Bucket Width"}
                inputHelpText={"fractional and integer values allowed"}
                infoText={
                    "overrides the bucketing algorithm and sets the bucket width. Defaults to 'auto'."
                }
                defaultValue={"auto"}
                {...props}
            />,
            <SingleSelectVizControl
                key={"viz-cntrl-select-1"}
                selectOptions={bucketingAlgs}
                option={"numBucketsRule"}
                title={"Bucket Algorithm"}
                infoText={
                    "determines bucket width from data. use 'Bucket Width' option to override. Defaults to 'Square Root'"
                }
                defaultValue={"Square Root"}
                valueToSelectorObj={bucketAlgToSelectObj}
                {...props}
            />,

            <SingleSelectVizControl
                key={"viz-cntrl-select-2"}
                selectOptions={logAxisTypes}
                option={"verticalScaleType"}
                title={"Vertical Log Axis Scale"}
                infoText={
                    "makes the vertical axis a logarithmic scale. Can be one of the following: Log: Negative and zero values are not plotted or MirrorLog: Logarithmic scaling in which negative and zero values are plotted. The plotted value of a negative number is the negative of the log of the absolute value. Values close to 0 are plotted on a linear scale. Default is no log-scaling"
                }
                defaultValue={""}
                enableNoSelection
                valueToSelectorObj={logAxisTypeToSelectObj}
                {...props}
            />,
        ];
    } else if (
        props.report.vizType === VizType.Bar ||
        props.report.vizType === VizType.HorizontalBar
    ) {
        controls = [
            enablePercentFormatting,
            <SingleSelectVizControl
                key={"viz-cntrl-slider-1"}
                selectOptions={logAxisTypes}
                option={
                    props.report.vizType === VizType.Bar
                        ? "verticalScaleType"
                        : "horizontalScaleType"
                }
                title={`${
                    props.report.vizType === VizType.Bar
                        ? "Vertical"
                        : "Horizontal"
                } Log Axis Scale`}
                infoText={
                    "logarithmic axis scale. Can be one of the following: Log: Negative and zero values are not plotted or MirrorLog: Logarithmic scaling in which negative and zero values are plotted. The plotted value of a negative number is the negative of the log of the absolute value. Values close to 0 are plotted on a linear scale. Default is no log-scaling"
                }
                defaultValue={""}
                enableNoSelection
                valueToSelectorObj={logAxisTypeToSelectObj}
                {...props}
            />,
        ];
        if (
            props.report.report?.reportFields?.find(
                (value) => value.fieldUse === FieldUse.VizGroup,
            )
        ) {
            controls = controls.concat(
                <CheckboxVizControl
                    option={"stacked"}
                    key={"viz-cntrl-checkbox-1"}
                    title={"Stack Series"}
                    infoText={
                        "stacks the elements for all series at each domain value. Only applies to reports with series grouping"
                    }
                    defaultValue={""}
                    {...props}
                />,
            );
        }

        if (props.report.vizType === VizType.Bar) {
            controls = controls.concat(
                ...[chartHeightControl, slantedAxisTextControl],
            );
        }
    } else if (props.report.vizType === VizType.Line) {
        controls = [
            <SingleSelectVizControl
                key={"viz-cntrl-select-1"}
                selectOptions={logAxisTypes}
                option={"verticalScaleType"}
                title={"Vertical Log Axis Scale"}
                infoText={
                    "makes the vertical axis a logarithmic scale. Can be one of the following: Log: Negative and zero values are not plotted or MirrorLog: Logarithmic scaling in which negative and zero values are plotted. The plotted value of a negative number is the negative of the log of the absolute value. Values close to 0 are plotted on a linear scale. Default is no log-scaling"
                }
                defaultValue={""}
                enableNoSelection
                valueToSelectorObj={logAxisTypeToSelectObj}
                {...props}
            />,
        ].concat(...[chartHeightControl]);
    } else if (props.report.vizType === VizType.Pie) {
        controls = [];
    } else if (props.report.vizType === VizType.Table) {
        controls = [enablePercentFormatting];
    }
    const showSave = !isEqual(
        props.report.vizOptions,
        props.report.report?.vizOptions,
    );

    let vizControlsContent;
    if (controls.length) {
        vizControlsContent = (
            <div key={"saveable-viz-controls"}>
                <div>
                    <Grid
                        style={{ marginBottom: "8px" }}
                        container
                        item
                        xs={12}
                        justifyContent={"flex-end"}
                        alignItems={"flex-start"}
                    >
                        <Zoom in={showSave}>
                            <Button
                                variant={"outlined"}
                                style={{
                                    textTransform: "none",
                                    fontWeight: "bold",
                                }}
                                disableElevation
                                size={"small"}
                                color={"primary"}
                                onClick={() =>
                                    props.store.updateReportVizOptions(
                                        props.report.vizOptions,
                                    )
                                }
                            >
                                Save Options
                            </Button>
                        </Zoom>
                    </Grid>
                </div>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="baseline"
                >
                    {controls}
                </Grid>
            </div>
        );
    }

    vizControlsContent = vizControlsContent ?? defaultContent;

    return (
        <VerticalDrawerContentTemplate
            content={vizControlsContent}
            mainContentClass={classes.mainContent}
            loading={props.store.getTaskLoading("Update Visualization Options")}
            title={`${capitalizeFirstChar(
                VizType[props.report.vizType!],
            )} Options`}
            subTitle={props.report.name}
        />
    );
});

export default VizOptions;
