import { BranchDataProps } from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";

// The implementation of a RecursiveTree component assumes that each branch will contain its own unique id, name, and a property called `isLeaf` to indicate the presence of child branches. Furthermore, each branch should contain a property that houses its children, which will be indicated via its store. See interface below.

export enum EMockOrgSetup {
    "CEO" = 0,
    "Director" = 1,
    "Manager" = 2,
    "Employee" = 3,
}

export interface IFakeRecursiveTier extends BranchDataProps {
    dummyTiers?: IFakeRecursiveTier[]; // This is the key set to recursiveTreeStore.setTopLevelDataKey() <--- pass "dummyTiers" in here.
}

export const branchOneChildOneChildren: IFakeRecursiveTier[] = [
    {
        id: "b1c1c1",
        name: "Michael",
        branchDepth: 2,
        isLeaf: true, // This states that the branch does not have any available child branches (will affect appearances of the chevron in the UI).
    },
    {
        id: "b1c1c2",
        name: "Weitzer",
        branchDepth: 2,
        isLeaf: true,
    },
    {
        id: "b1c1c3",
        name: "Parker",
        branchDepth: 2,
        isLeaf: true,
    },
];

export const branchOneChildren: IFakeRecursiveTier[] = [
    {
        id: "b1c1",
        name: "Child Branch 1",
        branchDepth: 1,
        isLeaf: false, // This states that child branches are available, which presents the user with an interactive chevron to populate them.
        dummyTiers: branchOneChildOneChildren, // The data which is read when the getBranch() method is fired (passed into constructor of RecursiveTreeStore).
    },
    {
        id: "b1c2",
        name: "Child Branch 2",
        branchDepth: 1,
        isLeaf: true,
    },
    {
        id: "b1c3",
        name: "Child Branch 3",
        branchDepth: 1,
        isLeaf: true,
    },
];

export const branchTwoChildren: IFakeRecursiveTier[] = [
    {
        id: "b2c1",
        name: "Child Branch 1",
        branchDepth: 1,
        isLeaf: true,
    },
    {
        id: "b2c2",
        name: "Child Branch 2",
        branchDepth: 1,
        isLeaf: true,
    },
    {
        id: "b2c3",
        name: "Child Branch 3",
        branchDepth: 1,
        isLeaf: true,
    },
];

export const branchOne: IFakeRecursiveTier = {
    id: "b1",
    name: "Branch 1",
    branchDepth: 0,
    isLeaf: false,
    dummyTiers: branchOneChildren,
};

export const branchTwo: IFakeRecursiveTier = {
    id: "b2",
    name: "Branch 2",
    branchDepth: 0,
    isLeaf: false,
    dummyTiers: branchTwoChildren,
};

export const topTierBranches = [branchOne, branchTwo];

export const allRecursiveTestBranches: IFakeRecursiveTier[] = [
    ...branchOneChildOneChildren,
    ...branchOneChildren,
    branchOne,
    ...branchTwoChildren,
    branchTwo,
];
