import {
    // GridColDef,
    GridComparatorFn,
    GridRenderCellParams,
    // GridFilterInputValueProps,
    // GridFilterItem,
    // GridFilterOperator,
    GridSortCellParams,
} from "@mui/x-data-grid-pro";
// import AcxCalendarPopup from "components/UI/Calendar/AcxCalendarPopup";
import _ from "lodash";
// import moment, { Moment } from "moment";
// import React from "react";
import {
    parseFromISO,
    standardDateFormat,
    // standardDateTimeFormat,
} from "utils/DateTimeUtils";

// export const DateTimeFormatter = (params: GridValueFormatterParams) => {
//     const value = _.get(params.row, params.field)?.toString();
//     return value ? standardDateTimeFormat(parseFromISO(value, false)) : "";
// };

// export const DateFormatter = (params: GridValueFormatterParams) => {
//     const value = _.get(params.row, params.field)?.toString();
//     return value ? standardDateFormat(parseFromISO(value, false)) : "";
// };

export const DateFormatterIgnoreTime = (param: GridRenderCellParams) => {
    return param.value
        ? standardDateFormat(parseFromISO(param.value, true))
        : "";
};

export const DateFormatterIgnoreTimeGetter = (_, row, column) => {
    const value = row[column.field];
    return value instanceof Date ? value : new Date(value);
};

export const DateComparator: GridComparatorFn = (
    v1,
    v2,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    const p1 = _.get(row1, param1.field)?.toString();
    const p2 = _.get(row2, param2.field)?.toString();

    if (!p1) return 1;
    if (!p2) return -1;
    return p1.localeCompare(p2);
};

// export const DateFilter = (props: GridFilterInputValueProps) => {
//     const { item, applyValue } = props;
//     const [dt, setDt] = React.useState(moment(item.value) ?? moment());

//     const handleFilterChange = (d: Moment) => {
//         setDt(d);
//         applyValue({
//             ...item,
//             value: standardDateFormat(parseFromISO(d.toISOString(), false)),
//         });
//     };

//     return (
//         <AcxCalendarPopup
//             inputLabel="Select Date"
//             id="data-grid-date-filter"
//             currentDate={dt}
//             onSelect={handleFilterChange}
//             popperPlacement="left"
//         />
//     );
// };

// export const getDateFormatterOperators: (
//     filterField: string,
// ) => GridFilterOperator[] = (filterField: string) => [
//     {
//         label: "On",
//         value: "on",
//         getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//             if (
//                 !filterItem.columnField ||
//                 !filterItem.value ||
//                 !filterItem.operatorValue
//             ) {
//                 return null;
//             }

//             return (params): boolean => {
//                 const rowValue = _.get(params.row, filterField);
//                 const filterValue = filterItem.value;

//                 if (rowValue === null || rowValue === "") {
//                     return false;
//                 }

//                 return (
//                     new Date(rowValue.split("T")[0]).getTime() ===
//                     new Date(filterValue).getTime()
//                 );
//             };
//         },
//         InputComponent: DateFilter,
//     },
//     {
//         label: "Before",
//         value: "before",
//         getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//             if (
//                 !filterItem.columnField ||
//                 !filterItem.value ||
//                 !filterItem.operatorValue
//             ) {
//                 return null;
//             }

//             return (params): boolean => {
//                 const rowValue = _.get(params.row, filterField);
//                 const filterValue = filterItem.value;

//                 if (rowValue === null || rowValue === "") {
//                     return false;
//                 }

//                 return (
//                     new Date(rowValue.split("T")[0]).getTime() <
//                     new Date(filterValue).getTime()
//                 );
//             };
//         },
//         InputComponent: DateFilter,
//     },
//     {
//         label: "After",
//         value: "after",
//         getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//             if (
//                 !filterItem.columnField ||
//                 !filterItem.value ||
//                 !filterItem.operatorValue
//             ) {
//                 return null;
//             }

//             return (params): boolean => {
//                 const rowValue = _.get(params.row, filterField);
//                 const filterValue = filterItem.value;

//                 if (rowValue === null || rowValue === "") {
//                     return false;
//                 }

//                 return (
//                     new Date(rowValue.split("T")[0]).getTime() >
//                     new Date(filterValue).getTime()
//                 );
//             };
//         },
//         InputComponent: DateFilter,
//     },
//     {
//         label: "On or Before",
//         value: "onOrBefore",
//         getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//             if (
//                 !filterItem.columnField ||
//                 !filterItem.value ||
//                 !filterItem.operatorValue
//             ) {
//                 return null;
//             }

//             return (params): boolean => {
//                 const rowValue = _.get(params.row, filterField);
//                 const filterValue = filterItem.value;

//                 if (rowValue === null || rowValue === "") {
//                     return false;
//                 }

//                 return (
//                     new Date(rowValue.split("T")[0]).getTime() <=
//                     new Date(filterValue).getTime()
//                 );
//             };
//         },
//         InputComponent: DateFilter,
//     },
//     {
//         label: "On or After",
//         value: "onOrAfter",
//         getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//             if (
//                 !filterItem.columnField ||
//                 !filterItem.value ||
//                 !filterItem.operatorValue
//             ) {
//                 return null;
//             }

//             return (params): boolean => {
//                 const rowValue = _.get(params.row, filterField);
//                 const filterValue = filterItem.value;

//                 if (rowValue === null || rowValue === "") {
//                     return false;
//                 }

//                 return (
//                     new Date(rowValue.split("T")[0]).getTime() >=
//                     new Date(filterValue).getTime()
//                 );
//             };
//         },
//         InputComponent: DateFilter,
//     },
// ];
