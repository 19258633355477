import BaseEntity from "models/BaseEntity";
import { AppDomains } from "./AppDomains";

export default class ApplicationPermission extends BaseEntity {
    domain: AppDomains;
    action: string;
    resource: string;
    parentPermission: string;
    description: string;
}
