import { Checkbox, TableCell, TableRow } from "@mui/material";
import { get } from "lodash";
import { Observer } from "mobx-react";
import React from "react";
import { IAcxTableColumn, IAcxTableRow } from "./AcxTable";

interface IAcxTableRowProps {
    row: IAcxTableRow;
    r: number;
    columns: IAcxTableColumn[];
    enableCheck: boolean;
    selected: boolean;
    onSelectChange?: (selected: boolean, selectedKey: string) => void;
    onShiftClick?: (selectedKey: string) => void;
    keyField: string;
    cellClass: string;
    rowClass: string;
    cellSize?: "medium" | "dense";
    enableHover?: boolean;
    waypoint?: React.ReactNode;
    disabled?: boolean;
}
function AcxTableRow(props: IAcxTableRowProps) {
    const [selected, setSelected] = React.useState(false);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(!selected);
        event.stopPropagation();
        event.preventDefault();

        if (props.onSelectChange) {
            props.onSelectChange(!selected, props.row[props.keyField]);
        }
    };
    const onRowClick = (
        e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    ) => {
        if (e.shiftKey) {
            console.log("shift click");
            e.stopPropagation();
            e.preventDefault();
            props.onShiftClick?.(props.row[props.keyField]);
        }
    };

    React.useEffect(() => {
        setSelected(props.selected);
    }, [props.selected]);

    return (
        <Observer>
            {() => (
                <TableRow
                    key={`table_row_${props.r}`}
                    className={props.rowClass}
                    onClick={(e) => {
                        onRowClick(e);
                    }}
                >
                    {props.enableCheck && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={selected}
                                onChange={onChange}
                                disabled={props.disabled}
                            />
                        </TableCell>
                    )}
                    {props.columns.map((column, c) => {
                        let val = get(props.row, column.dataKey);
                        if (typeof val === "boolean") {
                            val = val.toString();
                        }

                        let formatted = column.formatter
                            ? column.formatter(val, props.row)
                            : val;
                        if (Array.isArray(formatted)) {
                            formatted = ((formatted ?? []) as any[]).join(", ");
                        }

                        return (
                            <TableCell
                                key={`table_cell_${props.r}_${c}`}
                                className={props.cellClass}
                            >
                                {props.waypoint !== undefined &&
                                    c === 0 &&
                                    props.waypoint}
                                {formatted}
                            </TableCell>
                        );
                    })}
                </TableRow>
            )}
        </Observer>
    );
}

AcxTableRow.defaultProps = {
    enableCheck: false,
    selected: false,
};
export default AcxTableRow;
