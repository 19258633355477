import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Pagination } from "@mui/material";
import conversationsSvg from "conversations.svg";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "utils/useStore";
import ConversationsStore from "../../../Stores/ConversationsStore";
import UnfocusedConversationList from "./UnfocusedConversationList";

const useConversationsMainStyles = makeStyles((theme: Theme) => ({
    header: {
        maxHeight: "50px",
    },
    footer: {
        maxHeight: "50px",
        margin: "20px 0px",
    },
    noResultsContainer: {
        width: "100%",
        height: "100%",
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    imageContainer: {
        width: "300px",
    },
    noResultsHeaderFont: {
        fontSize: "32px",
    },
    noResultsSubHeaderFont: {
        fontSize: "18px",
        color: "grey",
        fontWeight: "normal",
    },
}));

type Props = {};

const UnfocusedConversationWrapper: React.FC<Props> = observer(() => {
    const store = useStore(ConversationsStore);
    const haveResults =
        !!store?.conversationsList?.length || store.pageNumber !== 1;
    const classes = useConversationsMainStyles();

    const location = useLocation();

    useEffect(() => {
        if (store.selectedConversationId) {
            store.setSelectedConversationId(undefined);
        }
    }, [location, store]);

    const totalPages = Math.ceil((store.estimatedConversationCount ?? 0) / 10);

    const conversationsPagination = (
        <Grid container item xs={12} className={classes.footer}>
            <Grid
                container
                item
                xs={12}
                justifyContent="flex-end"
                alignItems="center"
            >
                <Grid item>
                    <Pagination
                        variant="outlined"
                        color="primary"
                        count={totalPages}
                        page={store.pageNumber}
                        onChange={(evt, val) => {
                            store.setPageNumberAndGetConversations(val);
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
    const noResultsView = (
        <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.noResultsContainer}
        >
            <Grid item className={classes.marginBottom}>
                <img
                    alt="conversations icon"
                    src={conversationsSvg}
                    className={classes.imageContainer}
                />
            </Grid>
            <Grid item className={classes.marginBottom}>
                <Typography
                    variant="h1"
                    className={classes.noResultsHeaderFont}
                >
                    No Search Results Yet
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    variant="h2"
                    sx={{
                        fontSize: "18px",
                        color: "grey",
                        fontWeight: "normal",
                    }}
                >
                    Try adjusting your search or filter to find what you're
                    looking for.
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <Grid container alignContent={haveResults ? "flex-start" : "center"}>
            {
                <Grid container item xs={12} direction="column">
                    {store?.estimatedConversationCount ? (
                        <UnfocusedConversationList />
                    ) : (
                        noResultsView
                    )}
                </Grid>
            }
            {haveResults && conversationsPagination}
        </Grid>
    );
});

export default UnfocusedConversationWrapper;
