import React from "react";
import moment from "moment";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import useStyles from "Styles/Styles";

const styles = (theme: Theme) => {
    return createStyles({
        headerCell: {
            color: theme.palette.text.primary,
            width: "32px",
            height: "16px",
            margin: 0,
            padding: 0,
            backgroundColor: "transparent",
            textAlign: "center",
        },
        headerText: {
            height: "14px",
            width: "32px",
            opacity: "0.25",
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontSize: "10px",
            fontWeight: "bold",
            lineHeight: "14px",
            textAlign: "center",
        },
    });
};

interface IWeekDayHeaderProps {
    shortest?: boolean;
}

export default function WeekDayHeader(props: IWeekDayHeaderProps) {
    const classes = useStyles(styles);
    const weekdayshort = props.shortest
        ? moment.weekdaysMin()
        : moment.weekdaysShort();
    return (
        <>
            {weekdayshort.map((day) => (
                <th key={day}>
                    <div className={classes.headerText}>{day}</div>
                </th>
            ))}
        </>
    );
}
