import AcxSelectMultiAsync from "components/UI/Select/BaseSelectComponents/AcxSelectMultiAsync";
import _ from "lodash";
import { observer } from "mobx-react";
import Agentv2 from "models/Agentv2";
import React from "react";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { useStore } from "utils/useStore";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";

export function getAgentOptionLabel(data) {
    let lbl = "";
    if (_.has(data, "fullName")) {
        if (data?.fullName !== " ") {
            lbl = data.fullName;
        } else {
            lbl = data.agentEmail;
        }
        if (data.isActive) {
            lbl = lbl + " - Active";
        } else {
            lbl = lbl + " - Inactive";
        }
    } else {
        lbl = data.label;
    }
    return lbl;
}

export const AgentsFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const valuesStore = useStore(ApplicationFilterValuesStore);


    return (
        <AcxSelectMultiAsync
            id="application-filters-agents"
            defaultValue={props.store.detailedAgents}
            onChange={(agents: Agentv2[]) => {
                props.store.setAgentIds(agents.map((agent) => agent.id));
                props.store.setDetailedAgents(agents);
            }}
            valueField="id"
            labelField={getAgentOptionLabel}
            isClearable={true}
            value={props.store.detailedAgents}
            loadOptions={async (searchValue) => {
                return await valuesStore.searchAgent(searchValue);
            }}
            noOptionMessage={
                "Please enter First, Last, Agent Code, or Email"
            }
        />
    );
});
