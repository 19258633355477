import { IconButton, TablePagination, Theme, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import useStyles from "Styles/Styles";
import AcxTableViewModel from "./AcxTableViewModel";

const useStyles1 = (theme: Theme) =>
    createStyles({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
        footerRow: {
            height: "52.5px",
        },
    });

// interface TablePaginationActionsProps {
//     count: number;
//     page: number;
//     rowsPerPage: number;
//     onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
//   }

const AcxTableActions: FunctionComponent<
    React.PropsWithChildren<
        React.PropsWithChildren<TablePaginationActionsProps>
    >
> = (props: TablePaginationActionsProps) => {
    const classes = useStyles(useStyles1);
    const theme = useTheme();

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        props.onPageChange(event, 0);
    };

    const handleBackButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        props.onPageChange(event, props.page - 1);
    };

    const handleNextButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        // debugger;
        props.onPageChange(event, props.page + 1);
    };

    const handleLastPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        props.onPageChange(
            event,
            Math.max(0, Math.ceil(props.count / props.rowsPerPage) - 1),
        );
    };
    return (
        <Observer>
            {() => (
                <div className={classes.root}>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={props.page === 0}
                        aria-label="first page"
                        size="large"
                    >
                        {theme.direction === "rtl" ? (
                            <LastPageIcon />
                        ) : (
                            <FirstPageIcon />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={props.page === 0}
                        aria-label="previous page"
                        size="large"
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRightIcon />
                        ) : (
                            <KeyboardArrowLeftIcon />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={
                            props.page >=
                            Math.ceil(props.count / props.rowsPerPage) - 1
                        }
                        aria-label="next page"
                        size="large"
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowLeftIcon />
                        ) : (
                            <KeyboardArrowRightIcon />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={
                            props.page >=
                            Math.ceil(props.count / props.rowsPerPage) - 1
                        }
                        aria-label="last page"
                        size="large"
                    >
                        {theme.direction === "rtl" ? (
                            <FirstPageIcon />
                        ) : (
                            <LastPageIcon />
                        )}
                    </IconButton>
                </div>
            )}
        </Observer>
    );
};

interface IAcxTablePageFooter {
    viewModel: AcxTableViewModel;
}
export default function AcxTablePageFooter(props: IAcxTablePageFooter) {
    const classes = useStyles(useStyles1);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        // debugger;
        props.viewModel.page = newPage;
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        props.viewModel.rowsPerPage = parseInt(event.target.value, 10);
        props.viewModel.page = 0;
    };
    return (
        <Observer>
            {() => (
                <tfoot>
                    <tr className={classes.footerRow}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            colSpan={12}
                            count={props.viewModel.count}
                            rowsPerPage={props.viewModel.rowsPerPage}
                            page={props.viewModel.page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={AcxTableActions}
                        />
                    </tr>
                </tfoot>
            )}
        </Observer>
    );
}
