import React from "react";
import Lottie from "lottie-react";
import animationData from "./ContentLoading.json"; // Adjust the path as necessary

const ContentLoading = () => {
    return (
        <Lottie
            animationData={animationData}
            style={{
                width: "100%",
                height: "100%",
            }}
        />
    );
};

export default ContentLoading;
