import { ClickAwayListener, Popper, Theme, useTheme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import AcxDatePicker from "components/UI/Calendar/AcxDatePicker";
import { Moment } from "moment";
import React, { useCallback, useState } from "react";
import useStyles from "../../../Styles/Styles";
import AcxInputLabel from "../AcxInputLabel";
import Calendar from "./Calendar";

const styles = (theme: Theme) => {
    return createStyles({
        textLabel: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        popper: {
            zIndex: 1,
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: "-0.09em",
                //   minWidth
                width: "3em",
                height: "1em",
                "&::before": {
                    borderWidth: "0 1em 1em 1em",
                    borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
                },
            },
            '&[x-placement*="top"] $arrow': {
                bottom: 0,
                left: 0,
                marginBottom: "-0.9em",
                width: "3em",
                height: "1em",
                "&::before": {
                    borderWidth: "1em 1em 0 1em",
                    borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
                },
            },
            '&[x-placement*="right"] $arrow': {
                left: 0,
                marginLeft: "-0.9em",
                height: "3em",
                width: "1em",
                "&::before": {
                    borderWidth: "1em 1em 1em 0",
                    borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
                },
            },
            '&[x-placement*="left"] $arrow': {
                right: 0,
                marginRight: "-0.9em",
                height: "3em",
                width: "1em",
                "&::before": {
                    borderWidth: "1em 0 1em 1em",
                    borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
                },
            },
        },
        arrow: {
            position: "absolute",
            fontSize: 9,
            width: "3em",
            height: "3em",
            "&::before": {
                content: '""',
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                borderStyle: "solid",
            },
        },
    });
};

interface IAcxCalendarPopup {
    onSelect?: (selectedDate: moment.Moment) => void;
    currentDate?: Moment;
    inputLabel?: string;
    labelClassName?: string;
    id: string;
    shrink?: boolean;
    disabled?: boolean;
    required?: boolean;
    popperPlacement?: "left";
    containerStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
}

export default function AcxCalendarPopup(props: IAcxCalendarPopup) {
    const theme = useTheme();
    const classes = useStyles(styles);
    const [openPopover, setOpenPopover] = React.useState(false);
    const popRef = React.useRef<HTMLDivElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [selectedDate, setSelectedDate] = React.useState<
        Moment | undefined
    >();
    const [arrowRef, setArrowRef] = React.useState<HTMLSpanElement | null>(
        null,
    );
    const [labelFocus, setLabelFocus] = useState<boolean | undefined>();

    const dateSelect = useCallback(
        (dt: moment.Moment) => {
            setSelectedDate(dt);
            if (props.onSelect) {
                props.onSelect(dt);
            }
            setOpenPopover(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onSelect],
    );

    function handleClickAway(event) {
        setOpenPopover(false);
    }

    React.useEffect(() => {
        setSelectedDate(props.currentDate);
    }, [props.currentDate]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div style={props.containerStyle}>
                {props.inputLabel && (
                    <AcxInputLabel
                        shrink={props.shrink}
                        focused={labelFocus}
                        className={clsx(
                            props.labelClassName,
                            classes.textLabel,
                        )}
                        id={props.id + "date-picker-label"}
                    >
                        {props.inputLabel}{" "}
                        {props.required && !props.currentDate && (
                            <span style={{ color: theme.palette.error.main }}>
                                *
                            </span>
                        )}
                    </AcxInputLabel>
                )}
                <div ref={containerRef}>
                    <AcxDatePicker
                        id={props.id}
                        onFocus={() => setLabelFocus(true)}
                        onBlur={() => setLabelFocus(false)}
                        disabled={props.disabled}
                        selectedDate={selectedDate}
                        onDateChange={dateSelect}
                        onCalendarClick={() => {
                            setOpenPopover(!openPopover);
                        }}
                        inputStyle={props.inputStyle}
                    />
                </div>
                <div style={{ position: "relative" }} ref={popRef}></div>

                <Popper
                    style={{ zIndex: 10000 }}
                    anchorEl={containerRef.current}
                    placement={props.popperPlacement ?? "bottom-start"}
                    open={openPopover}
                    disablePortal={false}
                    className={classes.popper}
                    modifiers={[
                        {
                            name: "flip",
                            enabled: false,
                        },
                        {
                            name: "preventOverflow",
                            enabled: true,
                            options: {
                                boundariesElement: "window", // Note: 'boundariesElement' has been replaced with 'boundary' in Popper.js v2
                            },
                        },
                        {
                            name: "arrow",
                            enabled: true,
                            options: {
                                element: arrowRef,
                            },
                        },
                    ]}
                >
                    <span className={classes.arrow} ref={setArrowRef} />
                    <div
                        style={{
                            boxShadow: theme.shadows[1],
                            backgroundColor: theme.palette.white.main,
                            padding: theme.spacing(1),
                        }}
                    >
                        <Calendar onSelection={dateSelect} />
                    </div>
                </Popper>
            </div>
        </ClickAwayListener>
    );
}

AcxCalendarPopup.defaultProps = {
    shrink: false,
};
