import React from "react";
import theme from "Theme/AppTheme";

interface Props {
    color?: string;
    width?: string;
    height?: string;
}

const ScissorsSvg = ({
    color = theme.palette.blackFont.main,
    width = "24",
    height = "25",
}: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 25"
            fill="none"
        >
            <path
                d="M14.1208 14.621L18.9998 19.5M11.9998 12.5L18.9998 5.50001M11.9998 12.5L9.12078 15.379M11.9998 12.5L9.12078 9.62101M9.12078 15.379C8.55812 14.8163 7.79499 14.5003 6.99928 14.5003C6.20356 14.5003 5.44043 14.8163 4.87778 15.379C4.31512 15.9417 3.99902 16.7048 3.99902 17.5005C3.99902 18.2962 4.31512 19.0593 4.87778 19.622C5.44043 20.1847 6.20356 20.5008 6.99928 20.5008C7.79499 20.5008 8.55812 20.1847 9.12078 19.622C9.68343 19.0593 9.99953 18.2962 9.99953 17.5005C9.99953 16.7048 9.68343 15.9417 9.12078 15.379ZM9.12078 9.62101C9.68343 9.05835 9.99953 8.29522 9.99953 7.49951C9.99953 6.70379 9.68343 5.94066 9.12078 5.37801C8.55812 4.81535 7.79499 4.49925 6.99928 4.49925C6.20356 4.49925 5.44043 4.81535 4.87778 5.37801C4.31512 5.94066 3.99902 6.70379 3.99902 7.49951C3.99902 8.29522 4.31512 9.05835 4.87778 9.62101C5.44043 10.1837 6.20356 10.4998 6.99928 10.4998C7.79499 10.4998 8.55812 10.1837 9.12078 9.62101Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ScissorsSvg;
