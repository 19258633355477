import { Button, Grid, Link, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AcxTextField from "components/UI/AcxTextField";
import React from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            "&:hover": {
                backgroundColor: theme.palette.secondary.light,
            },
            "&:disabled": {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.white.main,
            },
        },
        disabled: {
            backgroundColor: theme.palette.secondary.light,
        },
        forgotLink: {
            margin: theme.spacing(2),
        },
    });

let search = window.location.search;
// console.log(search);
let params = new URLSearchParams(search);
// console.log(params.get("ReturnUrl"));
// https://localhost:5001/Identity/Account/Login?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dauthsandbox%26redirect_uri%3Dhttps%253A%252F%252Flocalhost%253A5001%252Fauthentication%252Flogin-callback%26response_type%3Dcode%26scope%3DauthsandboxAPI%2520openid%2520profile%26state%3D9a19acf7000d4902b138d59eabfcbdf0%26code_challenge%3DkCaTlNCAkDoD6a7J20zb1qwRO4hi9idN_ZuzaW8P55A%26code_challenge_method%3DS256%26response_mode%3Dquery

export default function ChangePassword() {
    const classes = useStyles(styles);
    const [userName, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [password2, setPassword2] = React.useState("");
    const [buttonStatus, setButtonStatus] = React.useState(true);
    const [userNameError, setUserNameError] = React.useState(false);
    const [userNameHelper, setUserNameHelper] = React.useState("");
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordHelper, setPasswordHelper] = React.useState("");

    const resetErr = () => {
        setPasswordError(false);
        setUserNameError(false);
        setPasswordHelper("");
        setUserNameHelper("");
    };
    const token = params.get("token");
    const updatePassword = async () => {
        resetErr();
        if (password !== password2) {
            setPasswordError(true);
            setPasswordHelper("Passwords do not match");
            return;
        }
        let retUrl = params.get("ReturnUrl");
        try {
            var response = await fetch(`api/Authenticate/UpdatePassword`, {
                method: "Post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    UserName: userName,
                    Password: password,
                    ReturnUrl: retUrl,
                    Token: token,
                }),
            });
            const data = await response.json();
            if (response.status === 200) {
                const origin = window.location.origin;
                window.location.href = `/authentication/login?returnUrl=${encodeURIComponent(
                    origin,
                )}/app`;
            } else if (response.status === 400) {
                const keys = Object.keys(data);
                if (keys.indexOf("UserName") !== -1) {
                    setUserNameError(true);
                    setUserNameHelper(data["UserName"].join(" "));
                }
                if (keys.indexOf("Password") !== -1) {
                    setPasswordError(true);
                    setPasswordHelper(data["Password"].join(" "));
                }
                if (data.otherDetails === "UserName") {
                    setUserNameError(true);
                    setUserNameHelper(data.data.message);
                } else if (data.otherDetails === "Password") {
                    setPasswordError(true);
                    setPasswordHelper(data.message);
                    setUserNameError(false);
                    setUserNameHelper("");
                }
            } else {
                setUserNameError(true);
                setUserNameHelper(
                    "A general error occurred. Please contact the system administrator.",
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    function handleUserChange(e) {
        setUserName(e.target.value);
        if (e.target.value !== "") {
            setButtonStatus(false);
        } else {
            setButtonStatus(true);
        }
    }
    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }
    function handlePasswordChange2(e) {
        setPassword2(e.target.value);
    }
    // function handleResetLink(){

    // };
    return (
        // <FocusPage title="Please Login">
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
            direction="row"
        >
            <Grid item xs={12}>
                <form>
                    <div>
                        <AcxTextField
                            id="InputEmail"
                            type="email"
                            label="Email"
                            placeholder="Email"
                            required={false}
                            error={userNameError}
                            onChange={handleUserChange}
                            fullWidth={true}
                            helperText={userNameHelper}
                            other={{}}
                        />
                    </div>
                    <div>
                        <AcxTextField
                            id="InputPassword"
                            type="password"
                            label="Password"
                            placeholder="Password"
                            required={false}
                            error={passwordError}
                            onChange={handlePasswordChange}
                            fullWidth={true}
                            helperText={passwordHelper}
                            other={{}}
                        />
                    </div>
                    <div>
                        <AcxTextField
                            id="InputPassword2"
                            type="password"
                            label="Re-enter Password"
                            placeholder="Password"
                            required={false}
                            error={passwordError}
                            onChange={handlePasswordChange2}
                            fullWidth={true}
                            other={{}}
                        />
                    </div>
                    <Button
                        onClick={updatePassword}
                        variant="contained"
                        disableElevation
                        color="secondary"
                        disabled={buttonStatus}
                        className={classes.root}
                    >
                        Submit
                    </Button>
                </form>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
                <Typography className={classes.forgotLink}>
                    <Link href="/reset" color="primary">
                        Forgot Password?
                    </Link>
                </Typography>
            </Grid>
        </Grid>
        // </FocusPage>
    );
}
