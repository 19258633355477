import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import { OrganizationStructureLevel } from "models/OrganizationModels/OrganizationStructureLevel";
import { OrganizationStructureMember } from "models/OrganizationModels/OrganizationStructureMember";
import { OrganizationService } from "services/OrganizationService";
import { BaseStore } from "stores/BaseStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

export const LOAD_HIERARCHY = "Get Internal Structure Hierarchy";
const orgService = new OrganizationService();

@AcxStore
export default class AssignInteractionToHierarchyDialogStore extends BaseStore {
    readonly dialogStore = new DialogComponentStore();

    @observable orgId: string;
    @observable levels: OrganizationStructureLevel[] = [];
    @observable members: OrganizationStructureMember[] = [];
    @observable hierarchySelections: OrganizationStructureMember[] = [];

    constructor(public rootStore: IRootStore) {
        super("AssignInteractionToHierarchyDialogStore");
        makeObservable(this);

        reaction(
            (r) => this.orgId,
            (orgId) => {
                if (orgId) {
                    this.setupAsyncTask(LOAD_HIERARCHY, async () => {
                        await this.retrieveHierarchy();
                    });
                }
            },
        );
    }

    @action
    setOrgId = (newOrgId: string) => {
        this.orgId = newOrgId;
    };

    @action
    setHierarchySelection = (
        index: number,
        newValue: OrganizationStructureMember,
    ) => {
        this.hierarchySelections = this.hierarchySelections
            .slice(0, index)
            .concat([newValue])
            .filter((value) => value.name !== "No Selection");
    };

    @action
    retrieveHierarchy = async () => {
        if (!this.orgId) return;

        this.dialogStore.setLoading();
        const payload = await orgService.getInternalStructureHierarchy();
        runInAction(() => {
            this.levels = payload.levels;
            this.members = payload.members;
            this.hierarchySelections = [];
            this.dialogStore.setNotLoading();
        });
    };
}
