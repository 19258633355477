export enum MediaType {
    Audio,
    Chat,
    Email,
    ProcessedChat,
}

export namespace MediaType {
    export function toString(mediaType: MediaType): string {
        switch (mediaType) {
            case MediaType.Audio:
                return "Calls";
            case MediaType.Chat:
                return "Chats";
            case MediaType.Email:
                return "Emails";
            case MediaType.ProcessedChat:
                return "Chats";
        }
    }
}
