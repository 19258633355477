import { Grid, IconButton, Tooltip } from "@mui/material";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStore } from "utils/useStore";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import { Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxDeleteDialog from "components/UI/AcxDeleteDialog";

const ConversationFilterManagement: React.FunctionComponent = observer(() => {
    const store = useStore(ConversationsStore);

    const [editIndex, setEditIndex] = useState(-1);
    const [deleteIndex, setDeleteIndex] = useState(-1);

    return (
        <>
            {store.unsavedFilters.map((item, index) => (
                <Grid
                    container
                    sx={{
                        borderRadius: "8px",
                        border: `0.5px solid ${theme.palette.gray[200]}`,
                        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
                        padding: "16px",
                        marginBottom: "8px",
                        backgroundColor: theme.palette.gray[50],
                    }}
                    key={index}
                >
                    <Grid container item xs={6} alignItems="center">
                        {editIndex === index ? (
                            <AcxMainTextField
                                id={"conversations-edit-filter-input" + index}
                                value={item.name}
                                onChange={(e) => {
                                    store.unsavedFilters[index] = {
                                        ...store.unsavedFilters[index],
                                        name: e.currentTarget.value,
                                    };
                                }}
                                endAdornment={
                                    <IconButton
                                        size="small"
                                        onClick={() => setEditIndex(-1)}
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                }
                            />
                        ) : (
                            <Typography
                                style={{
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                }}
                            >
                                {item.name}
                            </Typography>
                        )}
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        {editIndex !== index && (
                            <Tooltip
                                title={
                                    editIndex !== -1
                                        ? "Can only edit 1 filter name at a time"
                                        : "Edit filter name"
                                }
                            >
                                <span>
                                    <IconButton
                                        size="small"
                                        onClick={() => setEditIndex(index)}
                                        disabled={editIndex !== -1}
                                    >
                                        <EditOutlinedIcon
                                            color={
                                                editIndex !== -1
                                                    ? "disabled"
                                                    : "primary"
                                            }
                                        />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        )}
                        <Tooltip title="Delete filter">
                            <span>
                                <IconButton
                                    onClick={() => {
                                        setDeleteIndex(index);
                                    }}
                                    size="small"
                                    sx={{ marginLeft: "24px" }}
                                >
                                    <DeleteOutlineIcon color="action" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            ))}
            <DeleteFilterDialog
                deleteIndex={deleteIndex}
                onCancel={() => {
                    setEditIndex(-1);
                    setDeleteIndex(-1);
                }}
                onDelete={() => {
                    setEditIndex(-1);
                    store.setUnsavedFilters(
                        store.unsavedFilters.filter(
                            (item, i) => i !== deleteIndex,
                        ),
                    );
                    setDeleteIndex(-1);
                }}
            />
        </>
    );
});

interface DeleteFilterDialogProps {
    deleteIndex: number;
    onCancel: () => void;
    onDelete: () => void;
}

const DeleteFilterDialog: React.FC<DeleteFilterDialogProps> = ({
    deleteIndex,
    onDelete,
    onCancel,
}) => (
    <AcxDeleteDialog
        isOpen={deleteIndex !== -1}
        onCancel={onCancel}
        onDelete={onDelete}
        text={{
            title: "Delete Filter",
            content:
                "Deleting this filter is immediate and permanant. Are you sure you want to delete?",
            cancel: "Cancel",
            confirm: "Delete Filter",
        }}
        hideAlertIcon
    />
);

export default ConversationFilterManagement;
