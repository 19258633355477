import {
    LmType,
    Module,
} from "components/Admin/Organizations/types/Module.type";
import { useCallback } from "react";
import useOne from "shared/hooks/useOne";
import ModuleService from "../Module.service";

const useOrganizationModule = (
    organizationId: string,
    licensedModuleId: string,
) => {
    const getData = useCallback(async () => {
        if (licensedModuleId === "add") {
            return {
                id: "",
                renderPlacement: null,
                organizationId: organizationId,
                organizationStructureMemberIds: [],
                questions: [],
                lmType: LmType.Always,
                name: "",
                questionsToDelete: [],
                isActive: true,
            };
        }
        return ModuleService.getModule(licensedModuleId);
    }, [licensedModuleId, organizationId]);

    const {
        isLoading,
        data: module,
        refetch,
    } = useOne<Module | undefined>({
        get: getData,
    });

    const updateModule = async (module: Module) => {
        module.organizationId = organizationId;
        return ModuleService.saveModule(module);
    };

    return {
        module,
        isLoading,
        refetch,
        updateModule,
    };
};

export default useOrganizationModule;
