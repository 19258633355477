import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { useStore } from "utils/useStore";
import { Grid } from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { Suggestion } from "../Suggestions/Suggestion";
import AgentChatStore from "../Stores/AgentChatStore";

export const SelectModulesAction = observer(({args}) => {
    const store = useStore(AgentChatStore);
    const valuesStore = useStore(ApplicationFilterValuesStore);

    const [modules, setModules] = useState<
        { id: string; title: string }[] | undefined
    >(undefined);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (!dialogOpen) return;
        valuesStore.loadModules();
    }, [dialogOpen, valuesStore]);

    return (
        <React.Fragment>
            <AcxDialog
                isOpen={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                title="Modules"
                maxWidth="lg"
                dialogContentChildren={
                    <AcxSelectMulti
                        id="ava-modules-multi-select"
                        fullWidth
                        isLoading={valuesStore.getTaskLoading(
                            ApplicationFilterValuesStore.Tasks
                                .LOAD_LICENSED_MODULES,
                        )}
                        options={valuesStore.licensedModules ?? []}
                        defaultValue={modules}
                        onChange={(options) => {
                            setModules(!!options ? options : []);
                        }}
                        valueField="id"
                        labelField="title"
                        containerHeight="auto"
                        maxContainerHeight="96px"
                        alignControls="flex-start"
                        width="24rem"
                        isClearable={true}
                    />
                }
            >
                <AcxButton
                    fullWidth={false}
                    onClick={() => {
                        if (!modules || modules.length === 0) return;
                        store.streamingSubmitInput({
                            manualInput: JSON.stringify({
                                moduleIds: modules.map((m) => m.id),
                                moduleTitles: modules.map((m) => m.title),
                            }),
                            userMessage: modules
                                .map((module) => module.title)
                                .join(",\n"),
                        });
                    }}
                    disabled={!modules || modules.length === 0}
                >
                    Use selected modules
                </AcxButton>
            </AcxDialog>
            <Grid item>
                <Suggestion onClick={() => setDialogOpen((open) => !open)}>
                    {args?.buttonText || "Select Modules"}
                </Suggestion>
            </Grid>
        </React.Fragment>
    );
});
