import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { styled, useTheme } from "@mui/styles";
import ContentLoading from "animations/ContentLoading";
import React, { useState } from "react";


type Prop = {
    imageUri: string;
    imageName: string;
    highlighted: boolean;
    onClickHandler?: (logoClicked: string) => void;
    onDeleteHandler?: (logoClicked: string) => void;
};

const AcxImageTile: React.FC<Prop> = (props) => {
    const {
        onClickHandler = (logoClicked: string) => {},
        onDeleteHandler = (logoClicked: string) => {},
    } = props;

    const theme = useTheme();
    const [isLoading, setisLoading] = useState(true);
    const [isHovered, setIsHovered] = useState(false);

    const Container = styled("div")({
        backgroundColor: theme.palette.gray[50],
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px", // Add padding for spacing
        textAlign: "center", // Center text horizontally
        wordWrap: "break-word", // Break long words
        whiteSpace: "normal", // Allow text to wrap onto the next line
        overflow: "auto",
        borderRadius: "4px",
        border: props.highlighted
            ? `2px solid ${theme.palette.primary[500]}`
            : `2px dashed ${theme.palette.gray[300]}`,
        position: "relative",
    });

    const Img = styled("img")({
        height: 104,
        display: isLoading ? "none" : "block", // Hide the image while loading
    });

    const HoverIconButton = styled(IconButton)({
        position: "absolute",
        visibility: isHovered ? "visible" : "hidden", // Show icon on hover
        background: "rgba(0, 0, 0, 0.40)",
        backgroundColor: theme.palette.black[900], // Slight background to make it visible over the image
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.07)",
        "&.MuiIconButton-root": {
            position: "absolute",
            background: "rgba(0, 0, 0, 0.40)",
        },
        "&.MuiIconButton-root:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.40)", // Keep the same background on hover
            boxShadow: "none", // Disable any shadow change on hover if necessary
        },
        "&.MuiIconButton-root:focus": {
            backgroundColor: "rgba(0, 0, 0, 0.40)", // Keep the same background on focus
            boxShadow: "none", // Disable any focus shadow if necessary
        },
        "&.MuiIconButton-root:active": {
            backgroundColor: "rgba(0, 0, 0, 0.40)", // Keep the same background on active
        },
    });

    return (
        <section
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <Container
                style={{
                    cursor: "pointer",
                }}
                onClick={() => {
                    onClickHandler(props.imageName);
                }}
            >
                {isLoading && <ContentLoading />}

                <Img
                    src={props.imageUri}
                    alt=""
                    onLoad={(e) => {
                        setisLoading(false);
                    }}
                />

                <HoverIconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onDeleteHandler(props.imageName);
                    }}
                >
                    <DeleteIcon style={{ color: "white" }} />
                </HoverIconButton>
            </Container>
        </section>
    );
};

export default React.memo(AcxImageTile, (prevProps, nextProps) => {
    return (
        prevProps.highlighted === nextProps.highlighted &&
        prevProps.imageUri === nextProps.imageUri &&
        prevProps.imageName === nextProps.imageName
    );
});
