import { Grid, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";
import {
    BeatLoader,
    BounceLoader,
    FadeLoader,
    GridLoader,
    PuffLoader,
    PulseLoader,
    SyncLoader,
} from "react-spinners";
import useStyles from "Styles/Styles";
import { ThemeColors } from "../../Theme/AppTheme";

const styles = (theme: Theme) => {
    return createStyles({
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: theme.zIndex.modal,
        },
        indicator: {
            width: "100%",
            height: "100%",
        },
        circProgress: {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    });
};

export type AcxSpinnerTypes =
    | "PuffLoader"
    | "PulseLoader"
    | "GridLoader"
    | "SyncLoader"
    | "BounceLoader"
    | "FadeLoader"
    | "BeatLoader";
type Props = {
    size: number;
    color?: "primary" | "secondary" | "inherit";
    loadingProgress?: number;
    alternate?: AcxSpinnerTypes;
    style?: React.CSSProperties;
};

function alternateSpinners(props: Props, theme: Theme) {
    const color =
        props.color === "primary" ? ThemeColors.green : ThemeColors.blue;

    switch (props.alternate) {
        case "PuffLoader":
            return (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <PuffLoader size={props.size} color={color} />
                    </Grid>
                </Grid>
            );
        case "PulseLoader":
            return (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <PulseLoader
                            size={props.size}
                            margin={5}
                            color={color}
                        />
                    </Grid>
                </Grid>
            );
        case "GridLoader":
            return (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <GridLoader
                            size={props.size}
                            margin={5}
                            color={color}
                        />
                    </Grid>
                </Grid>
            );

        case "SyncLoader":
            return (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <SyncLoader size={props.size} color={color} />
                    </Grid>
                </Grid>
            );

        case "BounceLoader":
            return (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <BounceLoader size={props.size} color={color} />
                    </Grid>
                </Grid>
            );

        case "FadeLoader":
            return (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <FadeLoader
                            height={props.size}
                            margin={props.size / 2}
                            radius={props.size / 5}
                            width={props.size / 10}
                            color={color}
                        />
                    </Grid>
                </Grid>
            );

        case "BeatLoader":
            return (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <BeatLoader
                            size={props.size}
                            margin={5}
                            color={color}
                        />
                    </Grid>
                </Grid>
            );
        default:
            return (
                <Grid container alignItems={"center"} justifyContent={"center"}>
                    <Grid item>
                        <PuffLoader
                            size={props.size}
                            color={props.color ?? "secondary"}
                        />
                    </Grid>
                </Grid>
            );
    }
}

function AcxLoadingIndicator(props: Props) {
    const classes = useStyles(styles);
    const theme = useTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const spinner = useMemo(() => alternateSpinners(props, theme), [props]);

    return (
        <div style={props.style ?? {}} className={classes.container}>
            {spinner}
        </div>
    );
}

export default AcxLoadingIndicator;
