import { Divider, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "../../../../utils/useStore";
import { EvalLicensedModule, EvalStore } from "../../Stores/EvalStore";

const useStyles = makeStyles((theme: Theme) => ({
    padding3: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },

    root: {
        marginTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
}));

interface OwnProps {
    module?: EvalLicensedModule;
}

type Props = OwnProps;

const ModuleCardFooter: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const classes = useStyles();
    const store = useStore(EvalStore);
    const authStore = useStore(AuthStore);

    const moduleUIModel = store.getModuleUIModel(
        props.module?.evaluationModuleId,
    );
    let headerContent: React.ReactNode = null;

    const moduleScore = moduleUIModel?.moduleScore?.toFixed(2);

    if (
        moduleScore !== null &&
        moduleScore !== undefined &&
        authStore.canUserView("Module Scores")
    ) {
        headerContent = (
            <Typography variant={"h5"}>
                Overall {props.module?.name} Score:{" "}
                <span style={{ fontWeight: "normal" }}>{moduleScore}%</span>
            </Typography>
        );
    }

    return (
        <>
            {Boolean(headerContent) && (
                <>
                    <Divider
                        variant={"middle"}
                        style={{ marginBottom: "8px" }}
                    />
                    <Grid
                        item
                        xs={12}
                        direction={"row"}
                        wrap={"nowrap"}
                        container
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                        className={clsx(classes.padding3, classes.root)}
                    >
                        <Grid item xs={4}>
                            {headerContent}
                        </Grid>
                        <Grid
                            item
                            xs={8}
                            container
                            justifyContent={"flex-start"}
                            alignItems={"baseline"}
                            wrap={"wrap"}
                        ></Grid>
                    </Grid>
                </>
            )}
        </>
    );
});

export default ModuleCardFooter;
