import { Container, Grid } from "@mui/material";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import IAmdTimeline from "models/IAmdTimeline";
import IAmdTimelineEvent from "models/IAmdTimelineEvent";
import React from "react";
import { PipelineService } from "services/PipelineService";
import { AuthStore } from "stores/AuthStore";
import theme from "Theme/AppTheme";
import { extractGuid } from "utils/StringUtils";
import { useStore } from "utils/useStore";
import AcxLoadingIndicator from "../AcxLoadingIndicator";
import AcxHorizontalBarGraph from "../Visualization/AcxHorizontalBarGraph";
import AmdTimeline from "./AmdTimeline";

type MicroserviceChartEntry = [string, number, string];

function computeMicroserviceTimeData(
    events: IAmdTimelineEvent[],
): MicroserviceChartEntry[] {
    const chronological = events.slice().reverse();
    const data: MicroserviceChartEntry[] = [];

    let microserviceStartTime;
    for (let i = 0; i < chronological.length; i++) {
        const currentEvent = chronological[i];

        // start of microservice
        if (currentEvent.isStartOfMicroservice) {
            const lastEvent = chronological[i - 1];
            if (lastEvent) {
                const queueTime =
                    (new Date(currentEvent.time).getTime() -
                        new Date(lastEvent.time).getTime()) /
                    1000;
                data.push([
                    `${currentEvent.microservice} (Queue)`,
                    queueTime,
                    `color: ${theme.palette.blue.main}`,
                ]);
            }

            microserviceStartTime = currentEvent.time;
            continue;
        }

        // end of microservice
        if (!currentEvent.isStartOfMicroservice && !currentEvent.event) {
            const totalTime =
                (new Date(currentEvent.time).getTime() -
                    new Date(microserviceStartTime).getTime()) /
                1000;

            const wasSuccess = currentEvent.success;

            data.push([
                `${currentEvent.microservice} (${
                    wasSuccess ? "Succeeded" : "Failed"
                })`,
                totalTime,
                `color: ${
                    wasSuccess
                        ? theme.palette.primary.main
                        : theme.palette.red.main
                }`,
            ]);
        }
    }

    return data.reverse();
}

export function AmdTimelineWithGraph({ amdId }: { amdId: string }) {
    const messageStore = useStore(MessageStore);
    const authStore = useStore(AuthStore);
    const pipelineService = React.useMemo(() => new PipelineService(), []);

    const [timeline, setTimeline] = React.useState<IAmdTimeline | undefined>();
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        // Dont want to perform fetches on empty amdId or non guid amdId
        if (!amdId || amdId === "" || extractGuid(amdId) === null) return;
        (async function () {
            const orgId = authStore.orgStore.selectedOrganization?.id;
            if (!orgId) return;

            setIsLoading(true);
            try {
                const timeline = await pipelineService.getTimelineByAMD(
                    orgId,
                    amdId,
                );
                setTimeline(timeline);
            } catch (error) {
                messageStore.logError(
                    "Unable to fetch timeline for this AMD. Please retry.",
                );
            }
            setIsLoading(false);
        })();
    }, [amdId, authStore, pipelineService, messageStore]);

    const displayLoading = !timeline && isLoading;

    return (
        <Container
            maxWidth="xl"
            style={{
                display: "grid",
                gridTemplateColumns: displayLoading ? "1fr" : "1fr 2fr",
                alignItems: "start",
            }}
        >
            {displayLoading && (
                <AcxLoadingIndicator size={64} alternate="PuffLoader" />
            )}
            {timeline && !isLoading && (
                <>
                    <Grid
                        style={{
                            height: "75dvh",
                            position: "sticky",
                            top: "1rem",
                        }}
                    >
                        <AcxHorizontalBarGraph
                            legendPosition="none"
                            xTitle="Time Spent (s)"
                            chartAreaWidth="100%"
                            horizontalScaleType="log"
                            data={[
                                [
                                    "Microservice",
                                    "Time Spent (s)",
                                    { role: "style" },
                                ],
                                ...computeMicroserviceTimeData(
                                    timeline.timelineEvents,
                                ),
                            ]}
                        />
                    </Grid>
                    <Grid>
                        <AmdTimeline amdId={""} timeline={timeline} />
                    </Grid>
                </>
            )}
        </Container>
    );
}
