import BaseService from "services/BaseService";
import { AddRoleRequestV2, EditRoleRequestV2 } from "./OrganizationRolesDetail";

class RoleService extends BaseService {
    protected urlBase: string = "api/Roles";

    public async getPermissions(): Promise<any[]> {
        return this.get(new URLSearchParams({}), "GetAllPermissions");
    }

    public async getRoles(id: string): Promise<any[]> {
        return await this.post(
            JSON.stringify({ organizationId: id }),
            new URLSearchParams(),
            "GetRolesForOrganization",
        );
    }

    public async createRole(
        val: any,
        addRequestV2Object: AddRoleRequestV2 | null = null,
    ): Promise<any> {
        const params = new URLSearchParams({});
        const body = addRequestV2Object === null ? val : addRequestV2Object;
        return this.post(JSON.stringify(body), params, "CreateRole", {
            returnJson: true,
        });
    }

    public async updateRole(
        val: any,
        editRequestV2Object: EditRoleRequestV2 | null = null,
    ): Promise<any> {
        const params = new URLSearchParams({});
        const body = editRequestV2Object === null ? val : editRequestV2Object;
        return this.put(params, "UpdateRole", JSON.stringify(body), {
            returnJson: true,
        });
    }

    public async saveRole(val: any): Promise<any> {
        return val.id ? this.updateRole(val) : this.createRole(val);
    }

    public async deleteRole(id: string): Promise<any> {
        return await this.delete(new URLSearchParams({ id: id }), "DeleteRole");
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new RoleService();
