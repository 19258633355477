import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import { makeObservable } from "mobx";
import {
    ClassificationService,
    ReclassifyJobResult,
} from "services/ClassificationService";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import {
    getReclassifyHistoryControls,
    reclassifyHistoryColumns,
} from "./ReclassifyHistoryColumns";

@AcxStore
export default class ReclassifyHistoryStore extends BaseStore {
    public static readonly Tasks = {
        LOAD_RECLASSIFY_RESULTS: "LoadReclassifyResults",
    };

    private readonly messageStore: MessageStore;

    private readonly classificationService: ClassificationService;

    public readonly dataGrid: AcxDataGridStore;

    constructor(private rootStore: IRootStore) {
        super("ReclassifyHistory");
        makeObservable(this);

        this.messageStore = rootStore.getStore(MessageStore);

        this.classificationService = new ClassificationService();

        this.dataGrid = new AcxDataGridStore("ReclassifyHistoryDataGridStore");
        this.dataGrid.checkboxSelection = false;
        this.dataGrid.setColumns(reclassifyHistoryColumns);
        this.dataGrid.title = "Activity Log";
        this.dataGrid.controls = getReclassifyHistoryControls(this);
    }

    private async getReclassifyResults(): Promise<
        ReclassifyJobResult[] | undefined
    > {
        try {
            const results =
                await this.classificationService.reclassifyResults();
            return results;
        } catch (error: unknown) {
            this.messageStore.logError(
                "Failed to retrieve reclassify history. Please try again.",
            );
        }
    }

    public async loadReclassifyResults() {
        this.setupAsyncTask(
            ReclassifyHistoryStore.Tasks.LOAD_RECLASSIFY_RESULTS,
            async () => {
                this.dataGrid.setLoading(true);
                this.dataGrid.rows = [];
                const results = await this.getReclassifyResults();
                this.dataGrid.setLoading(false);
                if (!results) return;
                this.dataGrid.rows = results;
                // this.dataGrid.rowCount = results.count;
            },
        );
    }
}
