import { Collapse, Grid, IconButton, Theme, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import { Observer } from "mobx-react-lite";
import React from "react";
import useStyles from "Styles/Styles";
import DownChevronSvg from "./Select/BaseSelectComponents/DownChevronSvg";
import InfoIcon from "@mui/icons-material/Info";

const styles = (theme: Theme) =>
    createStyles({
        bodyShow: {
            display: "block",
        },
        bodyHide: {
            // display: 'none'
        },
        headerMenu: {
            marginRight: theme.spacing(1),
        },
        headerClosed: {
            backgroundColor: theme.palette.white.main,
            color: theme.palette.white.contrastText,
            fontWeight: "bold",
        },
        headerExpanded: {
            backgroundColor: theme.palette.white.main,
            color: theme.palette.white.contrastText,
            fontWeight: "bold",
        },

        header: {
            userSelect: "none",
            height: "24px",
            lineHeight: "24px",
            // borderRadius: theme.spacing(.5),
            // boxShadow: "0 0 3px 0 rgba(0,0,0,0.05), 0 1px 1px 0 rgba(0,0,0,0.05)"
        },
        chevronExpanded: {
            color: theme.palette.white.contrastText,
        },
    });

interface IAcxExpansion {
    expanded?: boolean;
    onExpandChange?: (expanded: boolean, name: string) => void;
    header: React.ReactNode;
    headerClass?: string;
    headerCloseClass?: string;
    headerExpandedClass?: string;
    chevronCloseClass?: string;
    chevronExpandedClass?: string;
    name?: string;
    body?: React.ReactNode;
    bodyClass?: string;
    rootClass?: string;
    rootStyle?: React.CSSProperties;
    disableTooltip?: boolean;
    unmountOnExit?: boolean;
    conversationVariant?: boolean;
    additionalInformation?: string;
}

export default function AcxExpansion(props: IAcxExpansion) {
    const classes = useStyles(styles);
    const [isExpanded, setIsExpanded] = React.useState(props.expanded);

    React.useEffect(() => {
        setIsExpanded(props.expanded);
    }, [props.expanded]);

    const expand = () => {
        if (props.onExpandChange) {
            props.onExpandChange(!isExpanded, props.name ? props.name : "");
        }
        setIsExpanded(!isExpanded);
    };

    const expandIcon = (
        <Grid item>
            {isExpanded ? (
                <Tooltip
                    disableFocusListener
                    title={props.disableTooltip ? "" : "Collapse"}
                    placement={"top-start"}
                >
                    <IconButton
                        data-testid="acx-expansion-button"
                        size="small"
                        onClick={expand}
                        className={
                            props.chevronExpandedClass ??
                            classes.chevronExpanded
                        }
                    >
                        {props.conversationVariant ? (
                            <DownChevronSvg />
                        ) : (
                            <ExpandMoreIcon fontSize="small" />
                        )}
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip
                    disableFocusListener
                    title={props.disableTooltip ? "" : "Expand"}
                    placement={"top-start"}
                >
                    <IconButton
                        data-testid="acx-expansion-button"
                        size="small"
                        onClick={expand}
                        color="primary"
                        className={props.chevronCloseClass}
                    >
                        {props.conversationVariant ? (
                            <DownChevronSvg />
                        ) : (
                            <ChevronRightIcon fontSize="small" />
                        )}
                    </IconButton>
                </Tooltip>
            )}
        </Grid>
    );

    return (
        <Observer>
            {() => (
                <div className={props.rootClass} style={props.rootStyle}>
                    <Grid
                        container
                        wrap="nowrap"
                        justifyContent="space-between"
                        alignItems="center"
                        className={clsx(
                            props.headerClass ?? classes.header,
                            !isExpanded &&
                                (props.headerCloseClass ??
                                    classes.headerClosed),
                            isExpanded &&
                                (props.headerExpandedClass ??
                                    classes.headerExpanded),
                        )}
                    >
                        <Grid
                            container
                            item
                            alignItems="center"
                            alignContent={"flex-end"}
                            wrap="nowrap"
                        >
                            {!props.conversationVariant && expandIcon}
                            <Grid container item xs alignContent="center">
                                {props.header}
                                {!!props.additionalInformation && (
                                    <Tooltip
                                        title={props.additionalInformation}
                                        placement="right"
                                        style={{ marginLeft: 5 }}
                                    >
                                        <InfoIcon color="secondary" />
                                    </Tooltip>
                                )}
                            </Grid>
                            {props.conversationVariant && expandIcon}
                        </Grid>
                    </Grid>

                    <Collapse
                        in={isExpanded}
                        unmountOnExit={props.unmountOnExit}
                    >
                        <div
                            data-testid="acx-expansion-body"
                            className={clsx(
                                {
                                    [classes.bodyShow]: isExpanded,
                                    [classes.bodyHide]: !isExpanded,
                                },
                                props.bodyClass,
                            )}
                        >
                            {props.body}
                        </div>
                    </Collapse>
                </div>
            )}
        </Observer>
    );
}
