import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AcxChip from "components/UI/AcxChip";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import { observer } from "mobx-react";
import React from "react";
import { EvaluationStatus } from "../../../../models/Evaluation";
import hexToRGB from "../../../../utils/hexToRGB";
import { EvalStore, UpdateEvaluationTask } from "../../Stores/EvalStore";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        width: "100%",
        overflowY: "scroll",
    },
    chipListWrapper: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    chipContainer: {
        flexShrink: 1,
        overflow: "hidden",
        width: "100%",
    },
    justificationContainer: {
        overflowY: "scroll",
        width: "100%",
        marginBottom: "1rem",
        marginTop: "0.5rem",
    },
    labelBox: {
        marginBottom: theme.spacing(2.25),
        padding: theme.spacing(0.5),
        borderRadius: "4px",
        backgroundColor: hexToRGB("#3564D5", 0.1),
    },
    interactionNameText: {
        color: "#3564D5",
    },
    labelText: {
        fontSize: "13px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    },
    selectorContainer: {
        marginBottom: theme.spacing(2.25),
    },
    noteContainer: {
        marginBottom: theme.spacing(1),
    },
}));

interface IRequestDisputeDialogProps {
    evalStore: EvalStore;
}

const defaultConfirmButtonText = "Submit Dispute";
const RequestDisputeDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<IRequestDisputeDialogProps>>> = observer(
    ({ evalStore }) => {
        const classes = useStyles();
        const [confirmButton, setConfirmButton] = React.useState<string>(
            defaultConfirmButtonText,
        );

        const onJustificationChange = (id: string, justification: string) => {
            evalStore.currentEval?.disputedAnswers.forEach((value) => {
                if (value.question.id === id) {
                    value.disputeJustification = justification;
                }
            });
        };

        let content = (
            <div className={classes.root}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={10}>
                        <Typography
                            component="p"
                            style={{ marginBottom: "1rem" }}
                        >
                            You are about to submit a dispute for the selected
                            answers. Please confirm this is correct. If you have
                            selected an answer by mistake you may remove it now.
                            If you wish to select other answers to dispute,
                            please click Cancel and select all other answers you
                            wish to dispute. You will not be able to dispute
                            this evaluation again once the dispute has been
                            submitted.
                        </Typography>
                    </Grid>
                </Grid>

                <div className={classes.chipListWrapper}>
                    {evalStore.currentEval?.disputedAnswers.map((value) => (
                        <React.Fragment key={"jst" + value.question.id}>
                            <div className={classes.chipContainer}>
                                <AcxChip
                                    style={{
                                        maxWidth: "100%",
                                    }}
                                    key={value.question.id}
                                    label={value.question.questionText}
                                    color="blue"
                                    size="small"
                                    clickable={false}
                                    showToolTip={true}
                                    onDelete={() => (value.isDisputed = false)}
                                    wrapTooltip={true}
                                />
                            </div>
                            <div className={classes.justificationContainer}>
                                <AcxMainTextField
                                    key={value.question.id}
                                    onChange={(evt) => {
                                        onJustificationChange(
                                            value.question.id,
                                            evt.currentTarget.value,
                                        );
                                    }}
                                    labelText={""}
                                    multiLine
                                    placeholderText={
                                        "Provide the justification for disputing this answer."
                                    }
                                    rows={5}
                                    rowsMax={6}
                                    id="request-dispute-justification"
                                    value={value.disputeJustification}
                                />
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );

        const onClose = () => {
            evalStore.currentEval?.disputedAnswers.forEach((answer) => {
                answer.disputeJustification = "";
            });
            evalStore.requestDisputeDialogStore.close();
            setConfirmButton(defaultConfirmButtonText);
        };

        const onSubmitRequest = async () => {
            if (!evalStore.currentEval) {
                return;
            }

            evalStore.requestDisputeDialogStore.setLoading();
            evalStore.requestDisputeDialogStore.setSubTitle(
                "Generating Dispute ...",
            );

            const success = await evalStore.generateEvaluationDisputeAction();

            if (success === true) {
                const evaluation = evalStore.currentEval;

                evaluation.isDisputable = false;
                evaluation.evaluationStatus = EvaluationStatus.Disputed;
                evalStore.disputeHasStarted = false;

                //close dialog
                evalStore.requestDisputeDialogStore.close();
            }
        };

        React.useEffect(() => {
            setConfirmButton(defaultConfirmButtonText);

            return () => {
                setConfirmButton(defaultConfirmButtonText);
            };
        }, []);

        return (
            <AcxConfirmationDialog
                onClose={onClose}
                onConfirm={onSubmitRequest}
                isLoading={
                    evalStore.requestDisputeDialogStore.isLoading ||
                    evalStore.getTaskLoading(UpdateEvaluationTask) ||
                    evalStore.getTaskLoading("Generating dispute request")
                }
                buttonsDisabled={
                    evalStore.requestDisputeDialogStore.isLoading ||
                    evalStore.currentEval?.disputedAnswers?.length === 0
                }
                onCancel={onClose}
                title={evalStore.requestDisputeDialogStore.title}
                subTitle={evalStore.requestDisputeDialogStore.subTitle}
                cancelButtonText={"Cancel"}
                confirmButtonText={confirmButton}
                content={content}
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={20}
                isOpen={evalStore.requestDisputeDialogStore.isOpen}
            />
        );
    },
);

export default RequestDisputeDialog;
