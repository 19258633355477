import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import FocusControls from "Layouts/Focus/FocusControls";
import { observer } from "mobx-react";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import useStyles from "Styles/Styles";
import { useStore } from "../../../../utils/useStore";
import { RuleBuildStore } from "./RuleBuildStore";
import MainRuleBuilder from "./Views/MainRuleBuilder";

const styles = (_theme: Theme) =>
    createStyles({
        controlHeader: {
            marginBottom: _theme.spacing(10),
        },
    });

const RuleBuilder = observer(() => {
    const classes = useStyles(styles);

    const store = useStore(RuleBuildStore);

    return (
        // @ts-expect-error: error coming from outdated react-dnd package prop types
        <DndProvider backend={HTML5Backend}>
            <FocusControls
                title={"Rule Builder"}
                controlHeaderClass={classes.controlHeader}
                loading={store.anyTaskLoading}
                controlHeader={null}
                onCancel={() => {
                    store.resetStore();
                }}
                mainContent={<MainRuleBuilder />}
                showSave={store.isSavable}
                onSave={() => store.saveToServer()}
            />
        </DndProvider>
    );
});

export default RuleBuilder;
