import {useCallback} from "react";
import ModuleService from "../Module.service";
import useList from "../../../../../../shared/hooks/useList";
import {ModuleCategory} from "../../../types/Module.type";

export const useModuleCategories = () => {
    const getData = useCallback(async () => {
        return ModuleService.getModuleCategories();
    }, []);

    const {
        isLoading,
        data: moduleCategories,
        refetch,
    } = useList<ModuleCategory>({
        get: getData,
    });

    return {
        moduleCategories,
        isLoading,
        refetch,
    };
};