import { Grid, Link } from "@mui/material";
import {
    GridRenderCellParams
} from "@mui/x-data-grid-pro";
import { Routes } from "components/Navigation/Routes";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const evalNameFromBlobFileKey = (blobFileKey?: string) => {
    if (!blobFileKey) {
        return "";
    }

    let endPos = Math.min(
        Math.max(
            blobFileKey.lastIndexOf(".acxMedia"),
            blobFileKey.lastIndexOf(".audio"),
        ),
        blobFileKey.lastIndexOf(".__ACX"),
    );
    if (endPos < 1) {
        endPos = blobFileKey.length + 1;
    }

    const name = blobFileKey.substring(
        blobFileKey.lastIndexOf("/") + 1,
        endPos,
    );
    return name;
};

export const LinkFormatter = (obj: {
    id: string;
    blobfilekey?: string;
    name?: string;
    orgId?: string;
    orgIdValue?: string;
    evalId: string;
}) => {
    const fn = (params: GridRenderCellParams) => {
        const flds = obj;

        const id = params.row[flds.id]?.toString();
        const orgId = flds.orgId
            ? params.row[flds.orgId]?.toString()
            : flds.orgIdValue;
        const evalId = params.row[flds.evalId]?.toString();
        const evalNumber = params.row?.evaluationNumber;

        let evalName;
        if (flds.blobfilekey) {
            const blobFileKey = params.row[flds.blobfilekey!]?.toString();
            evalName = evalNameFromBlobFileKey(blobFileKey);
        } else {
            evalName = params.row[flds.name!]?.toString();
        }

        if (
            evalName === "" ||
            evalName === " " ||
            evalName === undefined ||
            evalName === null
        ) {
            evalName = evalNumber ?? "Link to Evaluation";
        }

        const res = (
            <Grid
                container
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
            >
                <Grid item>
                    <Link
                        variant="body1"
                        key={`report-item-${id}`}
                        sx={(theme) => ({
                            "&:hover": {
                                fontWeight: "bolder",
                            },
                            textAlign: "left",
                            color: theme.palette.neutral[600],
                            textDecoration: "underline",
                            textUnderlineOffset: theme.spacing(0.5),
                        })}
                        color="textPrimary"
                        component={RouterLink}
                        to={Routes.makeEvaluationRoute(
                            orgId ?? "",
                            evalId ?? "",
                        )}
                    >
                        {evalName}
                    </Link>
                </Grid>
            </Grid>
        );

        return res;
    };
    return fn;
};
