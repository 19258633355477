import { observable, makeObservable } from "mobx";
import BaseEntity from "./BaseEntity";
import DirectoryModel from "./DirectoryModel";
import MontageSoundClip from "./MontageSoundClip";

export default class MontageModel extends BaseEntity {
    @observable name: string;
    @observable fileName: string;
    @observable organizationId: string;
    @observable directoryId: string;
    directory: DirectoryModel;
    montageSoundClips: Array<MontageSoundClip>;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }
}
