import { styled } from "@mui/material";
import { MessageBody } from "./MessageBody";

export const UserMessage = styled(MessageBody)(({ theme }) => ({
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.white.main,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "8px",
}));
