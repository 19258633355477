import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import clsx from "classnames";
import moment, { Moment } from "moment";
import React from "react";
import useStyles from "Styles/Styles";
import WeekDayHeader from "../WeekDayHeader";

const styles = (theme: Theme) => {
    return createStyles({
        calendarTable: {
            width: "100%",
            margin: 0,
            padding: 0,
            borderSpacing: "0px",
            borderCollapse: "collapse",
        },
        calendarCell: {
            color: theme.palette.text.primary,
            margin: 0,
            padding: 0,
            cursor: "pointer",
        },

        calendarInner: {
            width: "34px",
            height: "34px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        today: {
            backgroundColor: theme.palette.lightgray.main,
            borderRadius: "18px",
        },
        selectedStartDate: {
            backgroundColor: theme.palette.blue.background,
            borderBottomLeftRadius: "18px",
            borderTopLeftRadius: "18px",
            color: theme.palette.white.main,
        },
        selectedEndDate: {
            backgroundColor: theme.palette.blue.background,
            color: theme.palette.white.main,
            borderBottomRightRadius: "18px",
            borderTopRightRadius: "18px",
        },
        interveningDay: {
            backgroundColor: theme.palette.blue.background,
        },
        singleDaySelected: {
            backgroundColor: theme.palette.blue.main,
            borderRadius: "18px",
            color: theme.palette.white.main,
        },
        selectedBackground: {
            borderRadius: "18px",
            backgroundColor: theme.palette.blue.main,
            padding: "4px",
        },
    });
};

type CalDayInterface = {
    className: string;
    text: string;
    class2: string;
};

interface CalRowProps {
    row: Array<CalDayInterface>;
}

interface CalBodyProps {
    month: number;
    year: number;
    dayClick: (dayInfo: moment.Moment) => void;
    selectedStartDate?: moment.Moment;
    selectedEndDate?: moment.Moment;
    choosingBegin?: boolean;
    hoverDay?: Moment;
    setHoverDay: (any) => void;
    isRangeSelect?: boolean;
}
export default function CalendarBodyDouble(props: CalBodyProps) {
    const classes = useStyles(styles);

    const mo: Moment = moment().year(props.year).month(props.month);
    const firstDayOfMonth: number = Number(mo.startOf("month").format("d"));
    const daysInMonth: number = mo.daysInMonth();
    // const [hoverDay, setHoverDay] = useState<Moment>();

    const getBlanks = React.useMemo(() => {
        let blanks = Array<CalDayInterface>();
        for (let i = 0; i < firstDayOfMonth; i++) {
            blanks.push({ className: "empty", text: "", class2: "" });
        }
        return blanks;
    }, [firstDayOfMonth]);

    const getCalendarDays = () => {
        // console.log("get cal days");
        let calDays = Array<CalDayInterface>();
        const currentDay: Moment = moment();

        for (let d = 1; d <= daysInMonth; d++) {
            let curClass = "";
            let class2 = "";
            const momentOfCurrentCalendarDay = moment(mo).date(d);

            // highlight today
            if (
                momentOfCurrentCalendarDay?.format("MM-DD-YYYY") ===
                currentDay.format("MM-DD-YYYY")
            ) {
                curClass = classes.today;
            }

            // highlight intervening on hover
            if (!props.choosingBegin) {
                if (
                    props.hoverDay &&
                    props.selectedStartDate &&
                    props.selectedStartDate < momentOfCurrentCalendarDay &&
                    momentOfCurrentCalendarDay < props.hoverDay
                ) {
                    curClass = classes.interveningDay;
                }
            }
            // highlight intervening on load
            if (props.choosingBegin) {
                if (
                    props.selectedStartDate &&
                    props.selectedEndDate &&
                    momentOfCurrentCalendarDay > props.selectedStartDate &&
                    momentOfCurrentCalendarDay < props.selectedEndDate
                ) {
                    curClass = classes.interveningDay;
                }
            }

            // highlight hovered day
            if (
                momentOfCurrentCalendarDay.format("MM-DD-YYYY") ===
                props.hoverDay?.format("MM-DD-YYYY")
            ) {
                if (props.choosingBegin) {
                    curClass = classes.selectedStartDate;
                    class2 = classes.selectedBackground;
                } else {
                    curClass = classes.selectedEndDate;
                    class2 = classes.selectedBackground;
                }
                if (!props.isRangeSelect) {
                    curClass = classes.singleDaySelected;
                }
            }
            // highlight start
            if (
                momentOfCurrentCalendarDay.format("MM-DD-YYYY") ===
                props.selectedStartDate?.format("MM-DD-YYYY")
            ) {
                curClass = classes.selectedStartDate;
                class2 = classes.selectedBackground;
            }
            // highlight end
            if (
                props.choosingBegin &&
                momentOfCurrentCalendarDay.format("MM-DD-YYYY") ===
                    props.selectedEndDate?.format("MM-DD-YYYY")
            ) {
                curClass = classes.selectedEndDate;
                class2 = classes.selectedBackground;
            }

            // handle edge case when dates are same day
            if (
                props.choosingBegin &&
                momentOfCurrentCalendarDay.format("MM-DD-YYYY") ===
                    props.selectedEndDate?.format("MM-DD-YYYY") &&
                momentOfCurrentCalendarDay.format("MM-DD-YYYY") ===
                    props.selectedStartDate?.format("MM-DD-YYYY")
            ) {
                curClass = classes.singleDaySelected;
            }

            calDays.push({ className: curClass, text: d.toString(), class2 });
        }
        return calDays;
    };

    let totalSlots = [...getBlanks, ...getCalendarDays()];
    let rows = Array<any>();
    let cells = Array<any>();
    totalSlots.forEach((day, i) => {
        if (i === 0 || i % 7 !== 0) {
            cells.push(day);
        } else {
            rows.push(cells);
            cells = new Array<any>();
            cells.push(day);
        }
        if (i === totalSlots.length - 1) {
            rows.push(cells);
        }
    });

    function onDayClick(obj) {
        console.log(obj);
        let m = moment()
            .year(obj.year)
            .month(obj.month)
            .date(obj.day)
            .set("hour", 0)
            .set("minute", 0)
            .set("seconds", 0);
        props.dayClick(m);
    }

    function CalendarCell(parms: CalDayInterface) {
        const currentCellMo: Moment = moment()
            .year(props.year)
            .month(props.month)
            .date(Number(parms.text));
        return (
            <td className={clsx(classes.calendarCell)}>
                <div
                    onClick={() =>
                        onDayClick({
                            month: props.month,
                            year: props.year,
                            day: Number(parms.text),
                        })
                    }
                    onMouseEnter={() => {
                        if (props.hoverDay?.date() !== currentCellMo.date()) {
                            props.setHoverDay(currentCellMo);
                        }
                    }}
                    className={clsx(parms.className, classes.calendarInner)}
                >
                    <span
                        className={parms.class2}
                        style={{ width: "28px", textAlign: "center" }}
                    >
                        {parms.text}
                    </span>
                </div>
            </td>
        );
    }

    function CalendarRow(parms: CalRowProps) {
        return (
            <tr>
                {parms.row.map((cell, i) => {
                    return (
                        <CalendarCell
                            key={"rowcell" + i}
                            text={cell.text}
                            className={cell.className}
                            class2={cell.class2}
                        />
                    );
                })}
            </tr>
        );
    }

    return (
        <table className={classes.calendarTable}>
            <thead>
                <tr>
                    <WeekDayHeader />
                </tr>
            </thead>
            <tbody onMouseLeave={() => props.setHoverDay(undefined)}>
                {rows.map((d, i) => (
                    <CalendarRow key={"bodyrow" + i} row={d} />
                ))}
            </tbody>
        </table>
    );
}
