import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React from "react";
import { VizType } from "../../../../../models/Reporting/ReportModel";
import { useStore } from "../../../../../utils/useStore";
import AcxSelectSingle from "../../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import { ReportEditorStore } from "../../../Stores/ReportEditorStore";
import TableDataSection from "./TableDataSection";
import VizDataSection from "./VizDataSection";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
    selectedQuickFiltersText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    mainSectionTitle: {
        marginTop: theme.spacing(1.5),
    },
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    mainSectionSubtitleText: {
        fontFamily: "Inter",
        opacity: "0.5",
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
        maxWidth: "300px",
    },
    bulletedList: {
        // listStyle: "circle",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
}));

interface OwnProps {}

type Props = OwnProps;

const ReportDataSection: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);

    return (
        <>
            <Typography className={classes.sectionTitle}>
                Primary Datapoint
            </Typography>
            <Grid item xs={12}>
                <AcxSelectSingle
                    options={store.reportDataViews ?? []}
                    id={"report-data-selector"}
                    onChange={store.setCurrentReportDataView}
                    defaultValue={store.currentReport?.dataView}
                    valueField={"id"}
                    labelField={"displayName"}
                    fullWidth
                    inputLabel={"Primary Data"}
                />
            </Grid>
            <Grid item xs={12} className={classes.vertSpace} container>
                {isGraphicalReportType(
                    store.currentReport?.vizType ?? VizType.Bar,
                ) ? (
                    <VizDataSection />
                ) : (
                    <TableDataSection />
                )}
            </Grid>
        </>
    );
});

export default ReportDataSection;

function isGraphicalReportType(vizType: VizType) {
    return (
        vizType === VizType.Bar ||
        vizType === VizType.Line ||
        vizType === VizType.Pie ||
        vizType === VizType.Histogram ||
        vizType === VizType.HorizontalBar
    );
}
