import {
    FormControlLabel,
    Grid,
    Paper,
    Switch,
    Theme,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { Observer } from "mobx-react-lite";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";
import UserStore from "./UserStore";
import OrgSelectV2 from "components/UI/Select/OrgSelectV2";

const useStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        padding: "2rem",
        height: "calc(100% - 4rem)",
        overflowY: "auto",
    },
    textControl: {
        width: "90%",
    },
    formButton: {
        marginLeft: "0px",
    },
}));

function UserProfile() {
    const classes = useStyles();
    const store = useStore(UserStore);
    const orgStore = useStore(AuthStore).orgStore;

    return (
        <Observer>
            {() => (
                <Paper elevation={1} className={classes.formContainer}>
                    <div>
                        <Typography variant="h5">Personal Details</Typography>
                        <Typography variant="caption">
                            Update name and email address
                        </Typography>
                        <Grid
                            container
                            spacing={1}
                            style={{ paddingTop: "12px" }}
                        >
                            <Grid container item spacing={1}>
                                <Grid item xs={4}>
                                    <AcxMainTextField
                                        labelText="First Name"
                                        id="user-first-name"
                                        value={store.appUser?.firstName}
                                        onChange={(val) =>
                                            (store.appUser.firstName =
                                                val.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AcxMainTextField
                                        labelText="Last Name"
                                        id="user-last-name"
                                        value={store.appUser?.lastName}
                                        onChange={(val) =>
                                            (store.appUser.lastName =
                                                val.target.value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs={8}>
                                    <AcxMainTextField
                                        labelText="Email Address"
                                        id="user-email"
                                        type="email"
                                        value={store.appUser?.userName}
                                        onChange={(val) =>
                                            (store.appUser.userName =
                                                val.target.value)
                                        }
                                    />
                                </Grid>
                            </Grid>

                            <Grid container item>
                                <Grid item xs={3}>
                                    <AcxButton
                                        fullWidth
                                        color="primary"
                                        onClick={store.updateUserProfile}
                                    >
                                        Save Changes
                                    </AcxButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div style={{ marginTop: "28px" }}>
                        <Typography variant="h5">Change Password</Typography>

                        <Grid
                            container
                            spacing={1}
                            style={{ paddingTop: "12px" }}
                        >
                            <Grid container item spacing={1}>
                                <Grid item xs={4}>
                                    <AcxMainTextField
                                        type="password"
                                        labelText="Current Password"
                                        id="user-current-password"
                                        onChange={(val) =>
                                            (store.appUser.password =
                                                val.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AcxMainTextField
                                        type="password"
                                        labelText="New Password"
                                        id="user-new-password"
                                        onChange={(val) =>
                                            (store.appUser.newPassword =
                                                val.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AcxMainTextField
                                        type="password"
                                        error={Boolean(store.passwordError)}
                                        helperText={
                                            Boolean(store.passwordError)
                                                ? "New password must match"
                                                : ""
                                        }
                                        labelText="Confirm Password"
                                        id="user-confirm-password"
                                        onChange={(val) =>
                                            store.validatePasswordMatch(
                                                val.target.value,
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs={8}>
                                    <Typography variant="caption">
                                        Must be at least 8 characters and
                                        include a number (0-9), an uppercase
                                        letter (A-Z) and a symbol (!@#$)
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item>
                                <Grid item xs={3}>
                                    <AcxButton
                                        fullWidth
                                        color="primary"
                                        onClick={store.updatePassword}
                                    >
                                        Save Password
                                    </AcxButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    {(orgStore.organizations?.length ?? 0) > 1 && (
                        <div style={{ marginTop: "28px" }}>
                            <Grid
                                container
                                item
                                wrap="nowrap"
                                justifyContent="flex-start"
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        paddingTop: "0.5rem",
                                        marginRight: "1rem",
                                    }}
                                >
                                    Tenant Selection
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={
                                                orgStore.showInactiveTenants
                                            }
                                            onClick={() =>
                                                orgStore.setShowInactiveTenants(
                                                    !orgStore.showInactiveTenants,
                                                )
                                            }
                                        />
                                    }
                                    label="Display Inactive"
                                />
                            </Grid>
                            <Typography variant="caption">
                                Designate the desired Organization to reference
                                throughout the application
                            </Typography>

                            <OrgSelectV2
                                id={"UserProfileOrganizationSelector"}
                                label={"Select Organization"}
                                customRootStyles={{ marginTop: "0.5rem" }}
                            />
                        </div>
                    )}
                </Paper>
            )}
        </Observer>
    );
}

export default UserProfile;
