import { Box, Grid, Paper, Tab, Tabs, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ConversationsStore, {
    loadConversationSoundClips,
    loadCreateClip,
    loadCurrentEval,
    loadDeleteClip,
} from "components/Conversations/Stores/ConversationsStore";
import { observer } from "mobx-react";
import clsx from "clsx";
import React from "react";
import { useStore } from "utils/useStore";
import { AcxTranscription } from "components/UI/AcxTranscription";
import ConversationClipList from "./components/ConversationClipList";
import AcxToggleButton from "components/UI/AcxToggleButton";
import AcxChatViewer from "components/UI/Chat/AcxChatViewer";
import NoRedactionWarning from "../../../../UI/Chat/Views/NoRedactionWarning";
import ConversationSummaryHighlights from "../ConversationSummaryHighlights/ConversationSummaryHighlights";

const useFocusedConversationTabsStyles = makeStyles((theme: Theme) => ({
    container: {
        width: "100%",
        borderRadius: "8px",
    },
    tab: {
        minWidth: "min-content",
        textTransform: "none",
        fontWeight: 600,
        fontSize: "0.85rem",
        paddingRight: "20px",
    },
    tabContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginTop: "10px",
        borderBottom: `1px solid ${theme.palette.lightgray.main}`,
    },
    chatWrapper: {
        height: "calc(100vh - 330px)",
    },
    translatedTag: {
        marginLeft: "30px",
        color: theme.palette.gray.main,
    },
    padding2: {
        padding: theme.spacing(2),
    },
    paddingTop: {
        padding: theme.spacing(3),
    },
}));

const FocusedConversationTabs: React.FunctionComponent = observer(() => {
    const store = useStore(ConversationsStore);
    const classes = useFocusedConversationTabsStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const conversationTabs = [
        { label: "Highlights", disabled: false, tooltip: "" },
        {
            label: "Transcription",
            disabled: !store.authStore.canUserView("Transcription"),
            tooltip: "",
        },
        {
            label: "Clips",
            disabled: !store.selectedConversation?.hasEval,
            tooltip: !store.selectedConversation?.hasEval
                ? "Must have eval for clips"
                : "",
        },
        { label: "Montages", disabled: true, tooltip: "Coming Soon!" },
    ];

    const toggleButtonLanguageItems = [
        { value: "en", element: "English" },
        { value: "es", element: "Spanish" },
    ];

    const languageUI = (
        <Box>
            <Grid container item xs={12} justifyContent={"flex-end"}>
                <AcxToggleButton
                    exclusive
                    id="focused-conversation-transcript-lang-toggle"
                    items={toggleButtonLanguageItems}
                    defaultValue={store.selectedConversation?.languageCode}
                    onChange={(value) => {
                        if (value) {
                            store.changeTranscriptionLanguage(value);
                        }
                    }}
                    style={{
                        marginRight: "32px",
                        marginTop: "8px",
                    }}
                />
            </Grid>
            {store.spanishTranscript &&
                store.selectedConversation?.languageCode === "en" && (
                    <Grid container item xs={12}>
                        <Box
                            fontStyle={"italic"}
                            fontWeight={"bold"}
                            className={classes.translatedTag}
                        >
                            Translated transcript
                        </Box>
                    </Grid>
                )}
        </Box>
    );

    return (
        <Grid container>
            <Paper square className={classes.container}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    className={classes.tabContainer}
                >
                    {conversationTabs.map((tab, index) => (
                        <Tab
                            key={`${tab.label}_${index}`}
                            id={`focused-conversation-tab-${tab.label}_${index}`}
                            wrapped
                            label={
                                <Tooltip title={tab.tooltip}>
                                    {<span>{tab.label}</span>}
                                </Tooltip>
                            }
                            sx={{
                                minWidth: "min-content",
                                textTransform: "none",
                                fontWeight: 600,
                                fontSize: "0.85rem",
                                paddingRight: "20px",
                            }}
                            disabled={tab.disabled}
                            style={{ pointerEvents: "auto" }}
                        />
                    ))}
                </Tabs>
                <TabPanel
                    value={value}
                    index={0}
                    className={clsx(classes.paddingTop, "pendo-ignore")}
                >
                    <ConversationSummaryHighlights />
                </TabPanel>
                <TabPanel value={value} index={1} className={classes.padding2}>
                    {store.selectedConversation &&
                        (store.selectedConversation.isChat ? (
                            <Box className={classes.chatWrapper}>
                                <AcxChatViewer
                                    records={
                                        store.selectedConversation
                                            .chatTranscription
                                    }
                                    setFocusClip={store.setActiveClip}
                                    mediaClips={store.activeSoundClips}
                                    onClip={store.onTextMediaClipCreated}
                                    eddyEffectP2Data={
                                        store.selectedConversation.eddyData
                                    }
                                    loading={
                                        store.getTaskLoading(loadCreateClip) ||
                                        store.getTaskLoading(
                                            loadConversationSoundClips,
                                        ) ||
                                        store.getTaskLoading(loadDeleteClip) ||
                                        store.getTaskLoading(loadCurrentEval)
                                    }
                                    showNoRedactionWarning={
                                        store.showNotRedactedWarning
                                    }
                                />
                            </Box>
                        ) : (
                            <Box>
                                {store.spanishTranscript && languageUI}
                                {store.showNotRedactedWarning === true && (
                                    <NoRedactionWarning />
                                )}
                                <Box height={"45vh"}>
                                    <AcxTranscription
                                        hasEvaluation={
                                            store.selectedConversation?.hasEval
                                        }
                                        audioClip={store.audioClip}
                                        onSelectedClip={store.playBlurb}
                                        onClip={store.onAudioClipCreated}
                                        transcriptWords={
                                            store.selectedConversation
                                                .audioTranscription ?? []
                                        }
                                        eddyEffectP2Data={
                                            store.selectedConversation?.eddyData
                                        }
                                        minimal
                                        amdId={store.selectedConversationId}
                                        speakerStore={store.speakerStore}
                                    />
                                </Box>
                            </Box>
                        ))}
                </TabPanel>
                <TabPanel value={value} index={2} className={classes.padding2}>
                    {store.selectedConversation && <ConversationClipList />}
                </TabPanel>
            </Paper>
        </Grid>
    );
});

interface TabPanelProps {
    index: any;
    value: any;
    children: React.ReactNode;
    style?: React.CSSProperties | undefined;
    className?: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

export default FocusedConversationTabs;
