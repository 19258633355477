import { Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { AddSharp } from "@mui/icons-material";
import AcxButton from "components/UI/AcxButton";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LocalStorage from "stores/LocalStorage";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import useOrganizationAnalystGroups from "../../hooks/useOrganizationAnalystGroups";
import useOrganizationUsers from "../../hooks/useOrganizationUsers";
import { AnalystGroup } from "../../types/AnalystGroup";
import OrganizationAnalystGroupsDetail from "./OrganizationAnalystGroupsDetail";
import OrganizationAnalystGroupsListCards from "./OrganizationAnalystGroupsListCards";
import OrganizationAnalystGroupsListSearch from "./OrganizationAnalystGroupsListSearch";
import OrganizationAnalystGroupsListTable from "./OrganizationAnalystGroupsListTable";
import OrganizationAnalystGroupsListViewSelect from "./OrganizationAnalystGroupsListViewSelect";

const lsViewTypeKey = "AnalystGroupsListViewType";

export enum ViewType {
    cardView = "cardView",
    listView = "listView",
}

const styles = (theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "0px",
            position: "relative",
            width: 424,
            height: 400,
            filter: "drop-shadow(0px 25px 50px rgba(0, 0, 0, 0.25))",
            borderRadius: "8px",
        },
        titleContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            position: "static",
            width: "424px",
            height: "136px",
            left: "0px",
            top: "0px",
            background: "#FFFFFF",
            flex: "none",
            order: 0,
            flexGrow: 0,
            margin: "0px 0px",
        },
        actions: {
            display: "flex",
            alignItems: "flex-end",
            padding: "24px",
            width: "100%",
        },
        title: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "32px",
            color: theme.palette.blackFont.main,
        },
        subtitle: {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#71717A",
        },
        closeButton: {
            position: "absolute",
            top: 16,
            color: "#A1A1AA",
            right: 8,
        },
        closeIcon: {
            height: 20,
            width: 20,
        },
        error: {
            fontSize: "13px",
            color: theme.palette.error.main,
            textAlign: "center",
            padding: theme.spacing(1),
        },
        actionButton: {
            width: "inherit",
        },
        cancel: {
            color: theme.palette.blackFont.main,
            height: "30px",
            overflow: "hidden",
            fontSize: "13px",
            textAlign: "center",
            fontFamily: '"Inter", sans-serif',
            fontWeight: "bold",
            lineHeight: "20px",
            marginLeft: "0",
            whiteSpace: "nowrap",
            marginRight: "0",
            textOverflow: "ellipsis",
            letterSpacing: "0",
            textTransform: "none",
            background: "#FFFFFF",
            border: "1px solid #E4E4E7",
            "&:hover": {
                backgroundColor: theme.palette.white.main,
            },
        },
        fullWidth: {
            width: "100%",
        },
        required: {
            "&:after": {
                color: theme.palette.red.main,
                content: '" *" !important',
            },
        },
        controlSection: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            alignItems: "center",
        },
    });

type Props = {
    orgId: string;
};

const OrganizationAnalystGroupsList: React.FC<Props> = ({ orgId }) => {
    const classes = useStyles(styles);
    // const { search } = useLocation();
    const location = useLocation();
    const { getLocalStore } = useStore(LocalStorage);
    const ls = getLocalStore("AcxDefault");
    const { users, isLoading: isLoadingUsers } = useOrganizationUsers(orgId);
    const { analystGroups, isLoading, refetch } =
        useOrganizationAnalystGroups(orgId);
    const [filter, setFilter] = useState<string>("");
    const navigate = useNavigate();
    const [filteredAnalystGroups, setFilteredAnalystGroups] = useState<
        AnalystGroup[]
    >([]);
    const [detailData, setDetailData] = useState<AnalystGroup>({
        groupName: "",
        description: "",
        organizationStructureMemberIds: [],
        members: [],
    });
    const [view, setView] = useState<ViewType>();
    // const { id } = qs.parse(search);
    const params = new URLSearchParams(location.search);
    const id = params.get("id");

    useEffect(() => {
        let destroyed = false;
        const getLsData = async () => {
            const viewType: ViewType | null = await ls.getItem(lsViewTypeKey);
            console.log({ viewType });
            if (!destroyed) {
                setView(viewType || ViewType.cardView);
            }
        };
        getLsData();
        return () => {
            destroyed = true;
        };
    }, [ls]);

    useEffect(() => {
        if (!isLoadingUsers) {
            const analystGroup = analystGroups?.find(
                (group) => group.id === id,
            );
            const analystGroupToUse = {
                ...analystGroup,
                members: analystGroup?.members?.map((member) => {
                    const foundUser = users?.find(
                        (user) => user.id === member.userId,
                    );
                    return {
                        ...member,
                        name: foundUser?.userName || "",
                    };
                }),
            } as AnalystGroup;
            setDetailData({
                ...{
                    groupName: "",
                    description: "",
                    organizationStructureMemberIds: [],
                    members: [],
                },
                ...analystGroupToUse,
            });
        }
    }, [id, analystGroups, isLoadingUsers, users]);

    useEffect(() => {
        const filtered = analystGroups?.filter((group) =>
            group.$search?.includes(filter),
        );
        setFilteredAnalystGroups(filtered ? [...filtered] : []);
    }, [analystGroups, filter]);

    const changeView = async (view: ViewType) => {
        setView(view);
        await ls.setItem(lsViewTypeKey, view);
    };

    const onCloseDetail = () => {
        navigate(`/admin/organizations/${orgId}/analyst-groups`);
    };

    return (
        <>
            {view ? (
                <div className={classes.appContent}>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems={"flex-end"}
                        className={classes.controlSection}
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">
                                {!!filteredAnalystGroups.length && (
                                    <>{filteredAnalystGroups.length} </>
                                )}
                                Analyst Groups
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            container
                            spacing={1}
                            justifyContent="flex-end"
                            alignItems={"flex-end"}
                            alignContent="flex-end"
                            style={{ margin: 0 }}
                        >
                            <div style={{ display: "flex" }}>
                                <OrganizationAnalystGroupsListSearch
                                    setFilter={setFilter}
                                />
                                <OrganizationAnalystGroupsListViewSelect
                                    view={view}
                                    changeView={changeView}
                                />
                                <AcxButton
                                    fullWidth
                                    color="primary"
                                    startIcon={<AddSharp />}
                                    onClick={() => {
                                        navigate(
                                            `/admin/organizations/${orgId}/analyst-groups?id=add`,
                                        );
                                    }}
                                >
                                    New Group
                                </AcxButton>
                            </div>
                        </Grid>
                    </Grid>
                    {view === ViewType.cardView ? (
                        <OrganizationAnalystGroupsListCards
                            orgId={orgId}
                            data={filteredAnalystGroups}
                            isLoading={isLoading}
                        />
                    ) : (
                        <OrganizationAnalystGroupsListTable
                            orgId={orgId}
                            data={filteredAnalystGroups}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            ) : (
                <></>
            )}

            {((id && detailData) || (id && id === "add")) && !isLoadingUsers && (
                <OrganizationAnalystGroupsDetail
                    orgId={orgId}
                    id={id}
                    close={onCloseDetail}
                    refetch={refetch}
                    data={detailData}
                    memberOptions={users?.map((user) => ({
                        type: "Manager",
                        userId: user.id || "",
                        name: user.userName,
                    }))}
                    isLoadingUsers={isLoadingUsers}
                />
            )}
        </>
    );
};

export default OrganizationAnalystGroupsList;
