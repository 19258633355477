import { Observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import WorkflowInstanceList from "./WorkflowInstanceList";

interface OwnProps {
    navigateBack: () => void;
}

type Props = OwnProps;

const CapaModuleCard: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <Observer>
            {() => {
                return <WorkflowInstanceList />;
            }}
        </Observer>
    );
};

export default CapaModuleCard;
