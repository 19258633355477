import {
    Button,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import Step from "@mui/material/Step";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { useStore } from "../../../../../utils/useStore";
import { StepperStore } from "../Stores/StepperStore";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },

        contentText: {
            color: theme.palette.text.secondary,
            "& span": {
                color: theme.palette.info.main,
            },
        },
    }),
);

interface OwnProps {}

type Props = OwnProps;

const steps = [
    "Source Files",
    "Sampling Configuration",
    "Build Recommendation",
];

function getStepContent(step: number) {
    switch (step) {
        case 0:
            return <>Choose source directories for sampling</>;
        case 1:
            return (
                <>
                    Configuration for RuleSets and Metadata Specs. Metadata
                    Specs are optional if the records already have{" "}
                    <span>AgentName</span>, <span>InteractionDate</span> and{" "}
                    <span>CallDirection</span> metadata fields populated.
                </>
            );
        case 2:
            return (
                <>
                    Examine generated recommendation with ability to remove
                    records from the sample by unselecting the row. View
                    statistics on the recommended sample to verify Ruleset
                    constraints.
                </>
            );
        default:
            return <></>;
    }
}

const SamplerSteps: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const classes = useStyles();
    const store = useStore(StepperStore);

    return (
        <Stepper
            activeStep={store.stepIndex}
            orientation="vertical"
            color={"secondary"}
            style={{ padding: "12px" }}
        >
            {steps.map((label, index) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent
                        style={{ paddingLeft: "12px", paddingRight: "0px" }}
                    >
                        <Typography
                            color={"textSecondary"}
                            classes={{ root: classes.contentText }}
                        >
                            {getStepContent(index)}
                        </Typography>
                        <div className={classes.actionsContainer}>
                            <div>
                                <Button
                                    variant={"outlined"}
                                    color={"primary"}
                                    size={"small"}
                                    disabled={
                                        store.stepIndex === 0 ||
                                        store.disableStepping
                                    }
                                    onClick={store.previousStep}
                                    className={classes.button}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant={"outlined"}
                                    color={"secondary"}
                                    size={"small"}
                                    onClick={store.nextStep}
                                    disabled={store.disableStepping}
                                    className={classes.button}
                                >
                                    {store.stepIndex === steps.length - 1
                                        ? "Finish"
                                        : "Next"}
                                </Button>
                            </div>
                        </div>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
});

export default SamplerSteps;
