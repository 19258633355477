import type {
    ClassifierWorkflowInfo,
    WorkflowLevelInfo,
} from "components/WorkflowReview/types";
import { action, computed, makeObservable, observable } from "mobx";
import { Answer } from "models/Answer";
import LicensedModule from "models/LicensedModule";
import Question from "models/Question";
import { Tag } from "models/Tag";
import { WorkflowService } from "services/WorkflowService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import { ConvoModuleUIModel } from "../questionComponents/ConvoModuleUIModel";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import { ModuleService } from "services/ModuleService";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";

export interface ConvoQuestion extends Question {
    conversationModuleId: string;
}

@AcxStore
export default class ConvoWorkflowStore extends BaseStore {
    readonly authStore: AuthStore;
    readonly workflowService: WorkflowService;
    readonly moduleService: ModuleService;
    messageStore: MessageStore;

    dialogStore: DialogComponentStore = new DialogComponentStore();

    @observable
    answers: Answer[] = [];

    @observable
    audioMetadataId?: string;

    @observable
    currentWorkflowRes: ClassifierWorkflowInfo;

    @observable
    licensedModules: LicensedModule[] = [];

    @observable
    uiModels: ConvoModuleUIModel[] = [];

    constructor(public rootStore: IRootStore) {
        super("ConvoWorkflowStore");
        makeObservable(this);
        this.authStore = rootStore.getStore(AuthStore);
        this.workflowService = new WorkflowService();
        this.moduleService = new ModuleService();
        this.messageStore = rootStore.getStore(MessageStore);
    }

    @action
    setAnswerForQuestion = (
        question: Question,
        tags: Tag[],
        answer: Answer | undefined,
        conversationModuleId?: string,
    ) => {
        if (answer === undefined) {
            const newAnswer = new Answer(
                undefined,
                question.id,
                question,
                undefined,
                this.authStore._user.profile.email,
                this.authStore._user.profile.email,
            );
            this.updateAnswer(
                newAnswer,
                question.id,
                newAnswer.conversationModuleId ?? conversationModuleId,
            );
            answer = newAnswer;
        } else {
            this.updateAnswer(
                answer,
                question.id,
                answer.conversationModuleId ?? conversationModuleId,
            );
        }
        answer.setAnswerTags(tags);

        return answer;
    };

    @action
    updateAnswer(
        answer: Answer,
        questionId: string | undefined,
        conversationModuleId: string | undefined,
    ) {
        if (questionId === undefined) {
            return;
        }

        answer.conversationModuleId = conversationModuleId;

        let curAns = this.answers.find(
            (a) => a.questionId === answer.questionId,
        );

        if (curAns) {
            curAns = answer;
        } else {
            this.answers = [...this.answers, answer];
        }
    }

    getAnswerForQuestion = (
        question: Question,
        conversationModuleId: string | undefined,
    ): Answer | undefined => {
        let ans: Answer | undefined = undefined;
        const answers = this.answers.filter(
            (value) =>
                value.questionId === question.id &&
                (!value.conversationModuleId ||
                    value.conversationModuleId === conversationModuleId),
        );

        if (answers.length > 0) {
            ans = answers[0];
        }

        ans?.setQuestion(question);
        return ans;
    };

    @action
    setFillInAnswerForQuestion = (
        question: Question,
        fillInAnswer: string,
        answer: Answer | undefined,
        conversationModuleId?: string,
    ) => {
        if (answer === undefined) {
            const newAnswer = new Answer(
                undefined,
                question.id,
                question,
                undefined,
                this.authStore._user.profile.email,
                this.authStore._user.profile.email,
                fillInAnswer,
            );

            this.updateAnswer(
                newAnswer,
                question.id,
                newAnswer.conversationModuleId ?? conversationModuleId,
            );
            answer = newAnswer;
        } else {
            answer.fillInAnswerValue = fillInAnswer;
            this.updateAnswer(
                answer,
                question.id,
                answer.conversationModuleId ?? conversationModuleId,
            );
        }

        return answer;
    };

    @action
    getClassifierWorkflowInfo = async (
        workflowInstanceIds: string[],
        audioMetadataId?: string,
    ) => {
        this.setupAsyncTask("GetWorkflowInfo", async () => {
            this.audioMetadataId = audioMetadataId;

            const workflowsRes =
                await this.workflowService.getClassifierWorkflowInfo(
                    workflowInstanceIds,
                );

            this.currentWorkflowRes = workflowsRes[0];

            for (
                let i = 0;
                i < this.currentWorkflowRes.workflowLevelInfos.length;
                i++
            ) {
                const curInfo = this.currentWorkflowRes.workflowLevelInfos[i];
                if (
                    !curInfo.conversationModule?.licensedModuleId ||
                    !!this.licensedModules.find(
                        (lm) =>
                            lm.id ===
                            curInfo.conversationModule?.licensedModuleId,
                    )
                ) {
                    continue;
                }

                const moduleRes = await this.moduleService.getLicensedModule(
                    curInfo.conversationModule?.licensedModuleId,
                );
                // this.licensedModules.push(LicensedModule.fromJson(moduleRes));
                this.licensedModules = [
                    ...this.licensedModules,
                    LicensedModule.fromJson(moduleRes),
                ];

                this.uiModels.push(
                    new ConvoModuleUIModel(
                        moduleRes.questions,
                        moduleRes,
                        [],
                        curInfo.conversationModule?.id,
                    ),
                );
            }

            this.answers =
                this.currentWorkflowRes?.answers?.map((a) => {
                    const formattedAns = Answer.fromJson(a);

                    formattedAns.answerTags = formattedAns.answerTags.map(
                        (t) => {
                            const curQ = this.questions.find((q) => {
                                return q.id === formattedAns.questionId;
                            });

                            const tag = curQ?.tags.find(
                                (tt) => tt.id === t.tagId,
                            );
                            t.tag = tag;

                            return t;
                        },
                    );
                    return formattedAns;
                }) ?? [];
        });
    };

    @action
    advanceOrCloseClassifierWorkflow = async (isClose: boolean) => {
        this.setupAsyncTask("UpdateClassifierWorkflow", async () => {
            const isLastLevel =
                this.currentWorkflowLevel ===
                this.workflowLevelNames.length - 1;

            const currentLevelNote =
                this.currentWorkflowRes?.workflowLevelInfos[
                    this.currentWorkflowLevel
                ].note ?? "";
            try {
                await this.workflowService.updateClassifierWorkflow({
                    audioMetadataId: this.audioMetadataId ?? "",
                    workflowInstanceId:
                        this.currentWorkflowRes.workflowInstanceId,
                    workflowInstanceLevel: {
                        workflowInstanceId:
                            this.currentWorkflowRes.workflowInstanceId,
                        result: isClose
                            ? isLastLevel
                                ? "complete"
                                : "reject"
                            : "approve",
                        note: currentLevelNote,
                        workflowMessagingMetadataId:
                            this.currentWorkflowRes?.workflowLevelInfos[
                                this.currentWorkflowLevel
                            ].workflowMessagingMetadataId,
                        userId: this.authStore._user.profile.sub,
                        conversationModuleId:
                            this.currentWorkflowRes?.workflowLevelInfos[
                                this.currentWorkflowLevel
                            ].conversationModule?.id,
                        conversationModule:
                            this.currentWorkflowRes?.workflowLevelInfos[
                                this.currentWorkflowLevel
                            ].conversationModule,
                        evaluationModule: null,
                        createdBy: this.authStore._user.profile.email,
                    },
                    answers: this.answers,
                });
                this.messageStore.logMessage(
                    "Workflow successfully updated",
                    "success",
                );
            } catch (e) {
                this.messageStore.logError("Failed to update workflow");
            }
            this.getClassifierWorkflowInfo(
                [this.currentWorkflowRes.workflowInstanceId],
                this.audioMetadataId,
            );
        });
    };

    @computed
    get workflowLevelNames(): string[] {
        return this.currentWorkflowRes.levelNames;
    }

    @computed
    get currentWorkflowLevel(): number {
        return this.currentWorkflowRes.currentLevel;
    }
    @computed
    get currentWorkflowStatus(): WorkflowStatus {
        return this.currentWorkflowRes.status;
    }

    @computed
    get isLastLevel(): boolean {
        return this.currentWorkflowLevel === this.workflowLevelNames.length - 1;
    }

    @computed
    get workflowLevelInfos(): WorkflowLevelInfo[] {
        return this.currentWorkflowRes?.workflowLevelInfos;
    }

    @computed
    get questions(): Question[] {
        return this.licensedModules.flatMap((l) => l.questions);
    }

    isMissingRequiredAnswer(renderedQuestions: Question[]): boolean {
        let isMissing = false;
        if (this.currentWorkflowRes && renderedQuestions.length) {
            const requiredQuestions = renderedQuestions.filter(
                (q) => q.required,
            );

            const requiredAnswers = this.answers.filter((a) =>
                requiredQuestions.map((q) => q.id).includes(a.questionId),
            );

            requiredQuestions.forEach((q) => {
                const foundAns = requiredAnswers.find(
                    (a) => a.questionId === q.id,
                );
                if (!foundAns) {
                    // no answer for required questionId
                    isMissing = true;
                } else {
                    if (
                        q.answerType.isFillInAnswer &&
                        !foundAns.fillInAnswerValue
                    ) {
                        isMissing = true;
                    } else if (
                        !foundAns.answerTags.map((t) => t.isActive).length
                    ) {
                        isMissing = true;
                    }
                }
            });
        }

        return isMissing;
    }
}
