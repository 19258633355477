import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Observer, observer } from "mobx-react";
import React, { useRef } from "react";
import { Theme } from "@mui/material";
import appTheme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import { useStore } from "utils/useStore";
import { ClassifierBuilderV2Store } from "../../Stores/ClassifierBuilderV2Store";
import ConversationHighlights from "components/Conversations/Views/Main/Highlights/ConversationHighlights";
import clsx from "clsx";
import { durationDisplay } from "components/SoundClipEditor/Controls/Player";
import { AcxExpandableText } from "components/UI/AcxExpandableText";
import { ConversationData } from "services/ConversationService";

const useStyles = makeStyles((theme: Theme) => ({
    convoItemContainer: {
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(3),
        borderBottom: "0.5px solid var(--neutral-100, #E5E7EB)",
    },
    goToConvoBtn: {
        display: "flex",
        justifyContent: "flex-end",
        backgroundColor: "white",
        height: "100%",
        margin: "0px",
        color: appTheme.palette.blackFont.main,
        border: "1px solid #E4E4E7",
        padding: "4px 10px",
        width: "100%",
        minWidth: "150px",
        maxWidth: "150px",
        "&:hover": {
            color: "white",
        },
        [theme.breakpoints.down(1300)]: {
            textWrap: "wrap",
            minWidth: "100px",
        },
    },
    highlightedSearchText: {
        color: "#3564D5",
    },
    convoItemDate: {
        paddingBottom: theme.spacing(1),
    },
    truncatedSummary: {
        display: "-webkit-box",
        "-webkit-line-clamp": 3,
        lineHeight: "normal",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
    },
    convoItemMore: {},
    moreTextBtn: {
        cursor: "pointer",
        color: "#3564D5",
        marginTop: theme.spacing(1),
    },
    summaryTitle: {
        fontWeight: theme.typography.fontWeightBold as any,
    },
    convoDetailContainer: {
        maxWidth: "90%",
    },
}));

const ConversationsRbcResultsList: React.FC = observer(() => {
    const topOfList = useRef<HTMLDivElement>(null);
    const classes = useStyles();
    const store = useStore(ClassifierBuilderV2Store);

    React.useEffect(() => {
        if (topOfList.current) {
            topOfList.current.scrollIntoView({ behavior: "smooth" });
            const scrollPosition =
                window.scrollY || document.documentElement.scrollTop;
            window.scrollTo(0, scrollPosition - 20);
        }
    }, [store.pageNumber]);

    const RbcConversationItem: React.FC<{
        res: ConversationData;
        index: number;
    }> = observer((props) => {
        const itemNumber: number =
            (store.pageNumber - 1) * 10 + (props.index + 1);

        const [date] = props.res.timestamp.split(" ");

        return (
            <Grid
                item
                container
                direction="column"
                className={clsx("pendo-ignore", classes.convoItemContainer)}
            >
                <Grid
                    item
                    container
                    direction="row"
                    wrap="nowrap"
                    justifyContent="flex-start"
                    className={classes.convoItemDate}
                >
                    <Typography
                        style={{
                            color: "#2C3F92",
                            fontWeight: "bold",
                            marginRight: "8px",
                        }}
                    >
                        #{itemNumber}{" "}
                    </Typography>
                    <Typography style={{ color: "#9CA3AF" }}>
                        {durationDisplay(props.res.callDurationMillis / 1000)} |{" "}
                        {date}
                    </Typography>
                </Grid>

                <Grid item container direction="row">
                    <Grid item xs={9}>
                        <Box
                            className={clsx(
                                "pendo-ignore",
                                classes.convoDetailContainer,
                            )}
                        >
                            <AcxExpandableText pendoIgnore>
                                <Typography
                                    component="span"
                                    className={classes.summaryTitle}
                                >
                                    Summary:{" "}
                                </Typography>
                                {props.res.summary}
                            </AcxExpandableText>
                            {props.res.highlights && (
                                <Box marginTop={1}>
                                    <ConversationHighlights
                                        highlights={props.res.highlights}
                                        conversationClassifiers={
                                            props.res.conversationClassifiers
                                        }
                                        disableChips
                                        isChat={
                                            props.res.mediaType === 6 &&
                                            props.res.callDurationMillis === 0
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        xs={3}
                    >
                        <AcxButton
                            fullWidth
                            color="primary"
                            className={classes.goToConvoBtn}
                            onClick={() => {
                                window.open(
                                    `${window.location.origin}/app/conversations/${props.res.conversationId}`,
                                    "_blank",
                                );
                            }}
                            containerStyle={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            Go To Conversation
                        </AcxButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    });

    return (
        <Observer>
            {() => (
                <>
                    <div ref={topOfList}></div>
                    {store.rbcConversationResults.map((res, index) => (
                        <RbcConversationItem
                            key={`${res.conversationId}-${index}`}
                            res={res}
                            index={index}
                        />
                    ))}
                </>
            )}
        </Observer>
    );
});

export default ConversationsRbcResultsList;
