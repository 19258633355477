import { IDashboardDefinition } from "../models/Dashboard/DashboardDefinition";
import BaseService from "./BaseService";

export class DashboardService extends BaseService {
    protected urlBase: string = "api/Dashboard";

    public async updateDashboard(
        domain: string,
        organizationId: string,
        userId: string | null | undefined,
        dashboardDefinition: IDashboardDefinition,
    ) {
        const urlSearchParams = new URLSearchParams({ domain, organizationId });
        if (userId) {
            urlSearchParams.set("userId", userId);
        }

        const res = await this.post<IDashboardDefinition>(
            JSON.stringify(dashboardDefinition),
            urlSearchParams,
            "DashboardDefinition",
        );
        if (res.items) {
            res.items = JSON.parse(res.items as unknown as string);
        }
        return res;
    }

    public async getDashboard(
        domain: string,
        organizationId: string,
        userId: string | null,
    ): Promise<IDashboardDefinition> {
        const urlSearchParams = new URLSearchParams({ domain });

        const res = await this.post<IDashboardDefinition>(
            JSON.stringify({ organizationId, userId }),
            urlSearchParams,
            "GetDashboardDefinition",
        );
        if (res.items) {
            res.items = JSON.parse(res.items as unknown as string);
        }
        return res;
    }

    public async resetDashboard(
        domain: string,
        organizationId: string,
        userId: string | null,
    ): Promise<IDashboardDefinition> {
        const urlSearchParams = new URLSearchParams({ domain });

        const res = await this.post<IDashboardDefinition>(
            JSON.stringify({ organizationId, userId }),
            urlSearchParams,
            "Reset",
        );
        if (res.items) {
            res.items = JSON.parse(res.items as unknown as string);
        }
        return res;
    }

    public async deleteDashboard(
        domain: string,
        organizationId: string,
        userId: string | null,
    ): Promise<void> {
        const urlSearchParams = new URLSearchParams({ domain, organizationId });
        if (userId) {
            urlSearchParams.set("userId", userId);
        }

        await this.delete(urlSearchParams, "DashboardDefinition");
        return;
    }
}
