import {
    Grid,
    Paper,
    Theme,
    Typography,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import OrganizationWorkflowEmailStore, {
    EMAIL_TEMPLATE_DELIVERY_CADENCES,
    LOAD_EMAILS,
} from "../Stores/OrganizationWorkflowEmailStore";
import OrganizationWorkflowsStore, {
    LOAD_CONDITIONS,
    LOAD_CONTACT_TYPES,
    LOAD_LICENSED_MODULES,
    LOAD_RECIPIENTS,
    UPDATE_CONDITION,
} from "../Stores/OrganizationWorkflowsStore";
import {
    UserContact,
    WorkflowConditionType,
    WorkflowTemplate,
    WorkflowTemplateDeliveryCadence,
} from "../Types/WorkflowModels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            margin: "0.5rem 0",
        },
        paper: {
            width: "100%",
            padding: "1rem",
            margin: "1rem 0",
        },
        level: {
            color: theme.palette.blue.main,
        },
    }),
);

const defaultTemplateOption = {
    templateName: "Default Template",
    id: undefined,
} as unknown as WorkflowTemplate;

type Props = {
    store: OrganizationWorkflowsStore;
    level: number;
};

const OrganizationWorkflowsLevel: React.FC<Props> = observer(
    ({ store, level }) => {
        const emailStore = useStore(OrganizationWorkflowEmailStore);
        const classes = useStyles();

        const isSEDefaultLevel =
            store.inProgressCondition?.implementationType ===
                WorkflowConditionType.ClassifierResult && level === 0;

        return (
            <Grid container item xs={12}>
                <Paper className={classes.paper}>
                    <Grid
                        container
                        item
                        xs={12}
                        justifyContent="flex-end"
                        alignContent="center"
                    >
                        <Typography
                            align="right"
                            variant="caption"
                            className={classes.level}
                        >
                            Level: {level + 1}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} className={classes.container}>
                        <AcxMainTextField
                            textareaRootStyles={{ margin: "0" }}
                            infoText="Name of this Workflow Level."
                            placeholderText="Name this Level..."
                            skeleton={
                                store.getTaskLoading(LOAD_CONDITIONS) ||
                                store.getTaskLoading(LOAD_LICENSED_MODULES)
                            }
                            labelText="Workflow Level Name (optional)"
                            id="workflowLevelName"
                            value={
                                store.inProgressCondition
                                    ?.workflowMessagingMetadata?.[level]?.name
                            }
                            onChange={(e) =>
                                store.handleWorkflowLevelNameUpdate(
                                    e.target.value,
                                    level,
                                )
                            }
                            error={
                                (store.inProgressCondition
                                    ?.workflowMessagingMetadata?.[level]?.name
                                    ?.length ?? 0) > 42
                            }
                            showAllErrors
                            errorHelper="Maximum of 42 Characters Allowed"
                        />
                    </Grid>

                    <Grid container item xs={12} className={classes.container}>
                        <AcxSelectSingle
                            id="workflowLicensedModule"
                            infoText={
                                isSEDefaultLevel
                                    ? "For Safety Event Workflows, the Safety Event Licensed Module is required on Level 1. Subsequent levels may have any Workflow or Safety Event Licensed Module."
                                    : "A Boss-Level Module is required for the last level. If only one Level is created, this option must be filled."
                            }
                            inputLabel={
                                isSEDefaultLevel
                                    ? "Select a Licensed Module"
                                    : "Select a Licensed Module (optional)"
                            }
                            required={isSEDefaultLevel}
                            menuPortalTarget={document.body}
                            enableNoSelection
                            fullWidth
                            defaultValue={
                                isSEDefaultLevel
                                    ? store.availableSEModules.find(
                                          (module) =>
                                              store.inProgressCondition
                                                  ?.workflowMessagingMetadata?.[
                                                  level
                                              ]?.licensedModuleId === module.id,
                                      )
                                    : store.availableLicensedModules.find(
                                          (module) =>
                                              store.inProgressCondition
                                                  ?.workflowMessagingMetadata?.[
                                                  level
                                              ]?.licensedModuleId === module.id,
                                      )
                            }
                            labelField="displayName"
                            valueField="id"
                            options={
                                isSEDefaultLevel
                                    ? store.availableSEModules
                                    : store.availableLicensedModules
                            }
                            onChange={(e) =>
                                store.handleLicensedModuleSelection(e, level)
                            }
                            isDisabled={store.getTaskLoading(
                                LOAD_LICENSED_MODULES,
                            )}
                            placeholder={
                                store.getTaskLoading(LOAD_LICENSED_MODULES)
                                    ? "Loading"
                                    : "Select a Licensed Module..."
                            }
                        />
                    </Grid>

                    <Grid container item xs={12} className={classes.container}>
                        <AcxSelectSingle
                            required
                            infoText="The Recipient Type will control the available recipients for this level."
                            inputLabel="Select a Recipient Type"
                            menuPortalTarget={document.body}
                            fullWidth
                            defaultValue={store.recipientTypes.find(
                                (type) =>
                                    type.value ===
                                    store.selectedRecipientTypes[level],
                            )}
                            id="workflowRecipientType"
                            labelField="label"
                            valueField="value"
                            options={store.recipientTypes ?? []}
                            onChange={(e) =>
                                store.handleRecipientTypeSelect(e, level)
                            }
                            isDisabled={store.getTaskLoading(
                                LOAD_CONTACT_TYPES,
                            )}
                            placeholder={
                                store.getTaskLoading(LOAD_CONTACT_TYPES)
                                    ? "Loading"
                                    : "Select a Hierarchy Contact Type..."
                            }
                        />
                    </Grid>

                    {store.selectedRecipientTypes?.length > level &&
                        store.selectedRecipientTypes?.[level] ===
                            "userContacts" && (
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.container}
                            >
                                <AcxSelectMulti
                                    id="workflowRecipients"
                                    options={store.availableRecipients ?? []}
                                    valueField="id"
                                    labelField="userName"
                                    inputLabel="Select User Contact(s)"
                                    infoText="Please select the User Contact(s) to be notified at this Level."
                                    skeleton={store.getTaskLoading(
                                        LOAD_RECIPIENTS,
                                    )}
                                    defaultValue={store.inProgressCondition?.workflowMessagingMetadata?.[
                                        level
                                    ]?.workflowRecipients?.map(
                                        (recipient) =>
                                            ({
                                                id: recipient.userId,
                                                userName:
                                                    recipient.user?.userName,
                                                organizationId:
                                                    store.organizationId,
                                                domain: recipient.domain,
                                                contactType:
                                                    recipient.contactType,
                                            } as UserContact),
                                    )}
                                    onChange={(e) => {
                                        if (e)
                                            store.handleRecipientSelection(
                                                e,
                                                level,
                                            );
                                    }}
                                    fullWidth
                                    required
                                    containerHeight="auto"
                                />
                            </Grid>
                        )}

                    {store.selectedRecipientTypes?.length > level &&
                        store.selectedRecipientTypes?.[level] ===
                            "contactType" && (
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.container}
                            >
                                <AcxSelectSingle
                                    required
                                    infoText="Please select the Hierarcy Contact Type associated with this Level."
                                    inputLabel="Select a Hierarchy Contact Type"
                                    menuPortalTarget={document.body}
                                    fullWidth
                                    defaultValue={store.getContactType(level)}
                                    id="workflowConditionContactType"
                                    labelField="name"
                                    valueField="id"
                                    options={store.availableContactTypes ?? []}
                                    onChange={(e) =>
                                        store.handleContactTypeSelection(
                                            e,
                                            level,
                                        )
                                    }
                                    isDisabled={store.getTaskLoading(
                                        LOAD_CONTACT_TYPES,
                                    )}
                                    placeholder={
                                        store.getTaskLoading(LOAD_CONTACT_TYPES)
                                            ? "Loading"
                                            : "Select a Hierarchy Contact Type..."
                                    }
                                />
                            </Grid>
                        )}

                    {store.selectedRecipientTypes?.length > 0 &&
                        store.inProgressCondition?.implementationType !==
                            WorkflowConditionType.ClassifierResult && (
                            <Grid
                                container
                                item
                                xs={12}
                                className={classes.container}
                            >
                                <AcxSelectSingle
                                    required
                                    infoText="Please select an email template to send to the selected recipients."
                                    inputLabel="Select an Email Template"
                                    menuPortalTarget={document.body}
                                    fullWidth
                                    defaultValue={
                                        [
                                            defaultTemplateOption,
                                            ...emailStore.workflowEmails,
                                        ].find(
                                            (email) =>
                                                email.id ===
                                                store.inProgressCondition
                                                    ?.workflowMessagingMetadata[
                                                    level
                                                ]?.workflowEmailTemplateId,
                                        ) ?? defaultTemplateOption
                                    }
                                    id="workflowConditionTemplate"
                                    labelField="templateName"
                                    valueField="id"
                                    options={
                                        [
                                            defaultTemplateOption,
                                            ...emailStore.workflowEmails,
                                        ] ?? []
                                    }
                                    onChange={(e) =>
                                        store.handleEmailTemplateSelection(
                                            e,
                                            level,
                                        )
                                    }
                                    isDisabled={store.getTaskLoading(
                                        LOAD_EMAILS,
                                    )}
                                    placeholder={
                                        store.getTaskLoading(LOAD_EMAILS)
                                            ? "Loading"
                                            : "Select an Email Template..."
                                    }
                                />
                            </Grid>
                        )}

                    {store.selectedRecipientTypes?.length > 0 && (
                        <Grid
                            container
                            item
                            xs={12}
                            className={classes.container}
                        >
                            <AcxSelectMulti
                                id="workflowEmailCadence"
                                options={
                                    store.inProgressCondition
                                        ?.implementationType ===
                                    WorkflowConditionType.ClassifierResult
                                        ? EMAIL_TEMPLATE_DELIVERY_CADENCES.filter(
                                              (v) => v.label !== "Immediately",
                                          )
                                        : EMAIL_TEMPLATE_DELIVERY_CADENCES
                                }
                                valueField="value"
                                labelField="label"
                                inputLabel="Select Email Delivery Cadence(s)"
                                infoText="Please select one or many cadences to which emails are sent for this Level."
                                skeleton={store.getTaskLoading(LOAD_RECIPIENTS)}
                                defaultValue={
                                    store.inProgressCondition?.workflowMessagingMetadata?.[
                                        level
                                    ]?.emailCadences?.map((cadence) => ({
                                        label: WorkflowTemplateDeliveryCadence[
                                            cadence
                                        ],
                                        value: cadence,
                                    })) ?? undefined
                                }
                                onChange={(e) => {
                                    if (e)
                                        store.handleEmailSendCadenceSelection(
                                            e,
                                            level,
                                        );
                                }}
                                fullWidth
                                containerHeight="auto"
                                required={false}
                            />
                        </Grid>
                    )}

                    <Grid container item xs={12} className={classes.container}>
                        <AcxSelectSingle
                            infoText={
                                isSEDefaultLevel
                                    ? "Due date will reflect in the Workflows Domain. For Safety Event Workflows, no due date emails will be triggered, due to volume."
                                    : "Weekends and standard U.S. Holidays will not be counted as business days"
                            }
                            inputLabel="Select a Overdue Date Type (optional)"
                            menuPortalTarget={document.body}
                            fullWidth
                            enableNoSelection
                            defaultValue={store.getDueDateType(level)}
                            id="dueDateType"
                            labelField="label"
                            valueField="value"
                            options={store.dueDateTypes ?? []}
                            onChange={(e) =>
                                store.handleDueDateTypeSelect(e?.value, level)
                            }
                        />
                    </Grid>

                    {store.inProgressCondition?.workflowMessagingMetadata?.[
                        level
                    ]?.dueDateType !== null &&
                        store.inProgressCondition?.workflowMessagingMetadata?.[
                            level
                        ]?.dueDateType !== undefined && (
                            <Grid item xs={12} className={classes.container}>
                                <Grid
                                    style={{
                                        width: "35%",
                                        marginRight: "5%",
                                        display: "inline-block",
                                    }}
                                >
                                    <AcxMainTextField
                                        type="number"
                                        infoText={
                                            isSEDefaultLevel
                                                ? "Due date will reflect in the Workflows Domain. For Safety Event Workflows, no due date emails will be triggered, due to volume."
                                                : "# of days to pass before level becomes Overdue"
                                        }
                                        labelText="Due Date Days"
                                        id="dueDateDays"
                                        value={
                                            store.inProgressCondition
                                                ?.workflowMessagingMetadata?.[
                                                level
                                            ]?.dueDateDays ?? 1
                                        }
                                        onChange={(e) =>
                                            store.handleWorkflowLevelDueDateDaysUpdate(
                                                e.target.value,
                                                level,
                                            )
                                        }
                                        error={
                                            (store.inProgressCondition
                                                ?.workflowMessagingMetadata?.[
                                                level
                                            ]?.dueDateDays ?? 1) < 1
                                        }
                                        showAllErrors
                                        errorHelper="# of days can not be less than 1"
                                    />
                                </Grid>

                                {!isSEDefaultLevel && (
                                    <Grid
                                        style={{
                                            width: "55%",
                                            display: "inline-block",
                                        }}
                                    >
                                        <Tooltip title="If checked, the Workflow Reviewer will receive a reminder email 1 day before due date, day of due date, and every 1 day after due date, until Workflow level is resolved.">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={(e) => {
                                                            store.handleReminderEmailsCheckbox(
                                                                level,
                                                                e.target
                                                                    .checked,
                                                            );
                                                        }}
                                                        id="dueDateReminders"
                                                        name={
                                                            "dueDateReminders"
                                                        }
                                                        checked={
                                                            store
                                                                .inProgressCondition
                                                                ?.workflowMessagingMetadata?.[
                                                                level
                                                            ]
                                                                .overdueReminders ??
                                                            false
                                                        }
                                                        disabled={store.getTaskLoading(
                                                            UPDATE_CONDITION,
                                                        )}
                                                    />
                                                }
                                                label="Enable Due Date Reminder Emails"
                                            />
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                </Paper>
            </Grid>
        );
    },
);

export default OrganizationWorkflowsLevel;
