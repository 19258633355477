import EditIcon from "@mui/icons-material/Edit";
import { action, observable, reaction } from "mobx";
import { Observer } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import { DateReferenceOption } from "../../../../../stores/ComponentStores/DatePickerComponentStore";
import {
    DashboardStore,
    IDashboardItem,
} from "../../../../../stores/Layout/DashboardStore";
import {
    CustomDynamicTimeSpan,
    DynamicTimeSpan,
    RefreshInterval,
    timeSpanToMoments,
} from "../../../../../utils/reportingTimeHelpers";
import { BaseWidgetDefinition } from "../../../../Widgets/Definitions/BaseWidgetDefinition";
import WidgetError from "../../../../Widgets/Views/WidgetError";
import EvalCoachingTable from "./EvalCoachingTable";
import { EvalCoachingTableViewModel } from "./EvalCoachingTableViewModel";
import { StyledMenuLabel } from "components/UI/Menu/AcxMenu";

export class EvalCoachingTableWidgetDefinition extends BaseWidgetDefinition {
    viewModel: EvalCoachingTableViewModel;

    @observable agentId: string;
    @observable orgId: string;

    constructor(
        dashboardItem: IDashboardItem,
        globalDateRef: DateReferenceOption,
        globalRefresh: RefreshInterval,
        globalTimeSpan: DynamicTimeSpan | CustomDynamicTimeSpan,
        dashboardStore: () => DashboardStore,
        hierarchyIds?: string[],
    ) {
        super(
            dashboardItem,
            globalRefresh,
            globalTimeSpan,
            globalDateRef,
            dashboardStore,
            hierarchyIds,
        );

        this.agentId = dashboardItem.args.agentId;
        this.orgId = dashboardItem.args.orgId;

        this.initialize(
            this.dateReferenceOption,
            ...timeSpanToMoments(this.timeSpan),
            hierarchyIds,
        );
    }

    // @action  ToDo: base entity already decorates
    updateViewModelParams(args: any) {
        if (args.orgId && args.agentId) {
            this.viewModel.updateOrgAndAgent(args.orgId, args.agentId);
        }
    }

    private initialize(
        dateReferenceOption: DateReferenceOption,
        startDate: Moment,
        endDate: Moment,
        hierarchyIds: string[] | undefined,
    ) {
        this.viewModel = new EvalCoachingTableViewModel(
            this.orgId,
            this.agentId,
            startDate,
            endDate,
            dateReferenceOption,
            hierarchyIds,
        );
        this.buildWidgetMenuItems();

        this.disposers.push(
            reaction(
                (r) => this.viewModel.nextTaskError,
                (arg) => {
                    if (arg?.message) {
                        this.error = arg?.message;
                    }
                    this.viewModel.clearTaskErrors();
                },
                { delay: 0, fireImmediately: true },
            ),
            reaction(
                (r) => this.viewModel.anyTaskLoading,
                (arg) => {
                    this.loading = arg;
                },
                { delay: 0, fireImmediately: true },
            ),
        );
    }

    @action
    private buildWidgetMenuItems() {
        this.menuItemsBuilder = (history, dashboardStore, close) =>
            dashboardStore?.authStore.isLoggedInUserAgent() &&
            !dashboardStore.authStore.isUserUltra()
                ? []
                : [
                      {
                          id: `${this.agentId}-open-widget-config-drawer`,
                          label: <StyledMenuLabel>Edit Widget</StyledMenuLabel>,
                          icon: <EditIcon />,
                          props: {
                              onClick: () => {
                                  dashboardStore?.editWidget(this);

                                  close?.();
                              },
                          },
                      },
                  ];
    }

    // @action.bind(this)
    refresh(
        dateReferenceOption: DateReferenceOption,
        startDate: Moment,
        endDate: Moment,
        hierarchyIds: string[] | undefined,
    ) {
        super.refresh();
        console.log(arguments);
        this.viewModel.refresh(
            startDate,
            endDate,
            dateReferenceOption,
            hierarchyIds,
        );
    }

    render = (cardHeight?: number, controlsHeight?: number) => {
        return (
            <Observer>
                {() => {
                    if (this.error) {
                        return (
                            <>
                                <WidgetError
                                    parentHeightPx={
                                        (cardHeight ?? 0) -
                                        (controlsHeight ?? 0)
                                    }
                                    errorText={this.error}
                                />
                            </>
                        );
                    }

                    return (
                        <>
                            <EvalCoachingTable
                                viewModel={this.viewModel}
                                tableStores={this.viewModel.evalTableStores}
                            />
                        </>
                    );
                }}
            </Observer>
        );
    };
}
