import { makeStyles } from "@mui/styles";
import theme from "Theme/AppTheme";

export const useInputSearchTranscriptionStyles = makeStyles({
    container: {
        position: "relative",
        width: "280px",
        margin: "20px 0 20px 0",
    },
    transcriptionLeftPanel: {
        backgroundColor: theme.palette.lightgrayHover.main,
    },
    resultsContainer: {
        backgroundColor: "#fcfcfc",
        padding: "10px",
        position: "absolute",
        top: "35px",
        border: "2px solid #fafafa",
        borderRadius: "5px",
        maxHeight: "250px",
        overflow: "scroll",
        boxShadow: "#0b0a0a 0px 0px 4px",
        zIndex: 1000,
    },
    resultsItem: {
        transition: "background-color 0.2s ease",
        listStyleType: "none",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        border: "2px solid transparent",
        "&:hover": {
            cursor: "pointer",
            border: "2px solid #c3bebe",
            backgroundColor: "#F1F3F5",
        },
    },
});
