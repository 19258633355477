import { Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import Topic from "models/Topic";
import React, { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import TopicPreviewCard from "../SharedComponents/TopicPreviewCard";
import TopicPreviewCardSkeleton from "../SharedComponents/TopicPreviewCardSkeleton";
import { TopicStore } from "../stores/TopicStore";

const styles = () => {
    return createStyles({
        PageWrapper: {
            width: "80%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        PageHeader: {
            marginTop: "8px",
            marginBottom: "8px",
        },
        TopicList: {
            listStyle: "none",
            padding: 0,
        },
    });
};

type Props = {
    orgId: string;
};

const IdentifiedTopicsList: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer(({ orgId }) => {
    const classes = useStyles(styles);
    const navigate = useNavigate();

    const topicStore = useStore(TopicStore);

    const topicsLoading = topicStore.getTaskLoading(
        "getIdentifiedTopicsByOrganization",
    );

    const OpenTopic = (topic: Topic) => {
        topicStore.setSelectedTopic(topic);
        let path = generatePath("/admin/organizations/:orgId/topics/:topicId", {
            orgId: orgId,
            topicId: topic.id,
        });
        navigate(path);
    };

    let IdentifiedTopics;

    if (topicStore.identifiedTopics) {
        IdentifiedTopics = topicStore.identifiedTopics.map((topic) => (
            <li key={topic.id}>
                <TopicPreviewCard Topic={topic} OpenTopic={OpenTopic} />
            </li>
        ));
    }

    useEffect(() => {
        topicStore.orgId = orgId;
        topicStore.getIdentifiedTopics();
    }, [topicStore, orgId]);

    return (
        <>
            <div className={classes.PageWrapper}>
                <div className={classes.PageHeader}>
                    <Typography variant="subtitle1">Labeled Topics</Typography>
                </div>
                {topicsLoading ? (
                    Array.from(Array(5).keys()).map((i) => (
                        <TopicPreviewCardSkeleton key={i} />
                    ))
                ) : (
                    <ul className={classes.TopicList}>{IdentifiedTopics}</ul>
                )}
            </div>
        </>
    );
});

export default IdentifiedTopicsList;
