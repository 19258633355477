import { Theme, Typography, useTheme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import moment from "moment";
import React, { FunctionComponent } from "react";
import useStyles from "../../../../../Styles/Styles";
import ConfirmationDialog from "../../../../UI/AcxConfirmationDialog";
import { ConfigurationStepStore } from "../../AudioFileSamplerV2/Stores/ConfigurationStepStore";

const styles = (theme: Theme) =>
    createStyles({
        content: {
            color: theme.palette.text.secondary,
            "& span": {
                color: theme.palette.info.main,
            },
        },
    });
interface OwnProps {
    store: ConfigurationStepStore;
}

type Props = OwnProps;

const DeleteRuleSetDialog: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const classes = useStyles(styles);
    const theme = useTheme();
    return (
        <ConfirmationDialog
            isOpen={props.store.deleteRuleSetOpen}
            onClose={() => (props.store.deleteRuleSetOpen = false)}
            title={`Delete RuleSet`}
            content={
                <Typography className={classes.content} component={"div"}>
                    Are you sure that you would like to delete RuleSet{" "}
                    <span
                        style={{
                            fontWeight: "bold",
                            fontSize: "1.2em",
                        }}
                    >
                        {props.store.activeRuleSet?.name}
                    </span>{" "}
                    ?<br />
                    <>
                        <p
                            style={{
                                fontWeight: "bold",
                                color: theme.palette.text.primary,
                                fontSize: "1.2em",
                                marginBottom: "4px",
                            }}
                        >
                            Details:
                        </p>
                        <span
                            style={{
                                fontWeight: "bold",
                                color: theme.palette.text.primary,
                                marginRight: "8px",
                            }}
                        >
                            Created:
                        </span>{" "}
                        {props.store.activeRuleSet?.createdBy}{" "}
                        {moment(props.store.activeRuleSet?.createdOn).format(
                            "L",
                        )}
                        <br />
                    </>
                </Typography>
            }
            onConfirm={props.store.deleteRuleSet}
            buttonsDisabled={props.store.getTaskLoading("Delete RuleSet")}
        />
    );
});

export default DeleteRuleSetDialog;
