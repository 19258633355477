import { Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import CallIcon from "@mui/icons-material/Call";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EmailIcon from "@mui/icons-material/Email";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import clsx from "clsx";
import { observer } from "mobx-react";
import React, { CSSProperties, FunctionComponent } from "react";
import theme from "Theme/FocusTheme";
import { AnalystEvalHistory } from "../../../../models/EvalMetricModels";
import useStyles from "../../../../Styles/Styles";
import { standardDateFormat } from "../../../../utils/DateTimeUtils";
import { useStore } from "../../../../utils/useStore";
import AcxCard from "../../../UI/Cards/AcxCard";
import {
    AnalystDashboardStore,
    LoadAnalystHistoryTask,
} from "../Stores/AnalystDashboardStore";

const styles = (theme: Theme) =>
    createStyles({
        breakdownIcon: {
            opacity: "0.25",
        },
        breakdownText: {
            fontSize: "16px",
            letterSpacing: 0,
            lineHeight: "24px",
            userSelect: "none",
        },
        paddingLeft: {
            paddingLeft: theme.spacing(1),
        },
        bigSize: {
            fontSize: "74px",
            userSelect: "none",
        },
        bigIcon: {
            color: theme.palette.success.main,
        },
    });

interface OwnProps {
    analystHistory?: AnalystEvalHistory;
    rootCardStyle?: CSSProperties;
}

type Props = OwnProps;

const AnalystHistoryCard: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles(styles);
    const store = useStore(AnalystDashboardStore);
    const mediumDownMatch = useMediaQuery(theme.breakpoints.down("lg"));

    const content = (
        <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
        >
            {Boolean(props.analystHistory) && (
                <>
                    <Grid item xs={6} container justifyContent={"center"}>
                        <CheckCircleOutlineIcon
                            sx={{ fontSize: "74px", userSelect: "none" }}
                            className={clsx(classes.bigIcon)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography
                            variant={"h2"}
                            sx={{ fontSize: "74px", userSelect: "none" }}
                        >
                            {props.analystHistory?.completedEvaluations}
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    );

    const bottom = (
        <Grid
            key={"analyst-history-breakdown"}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <Grid item xs={3} container justifyContent={"center"}>
                <Grid item>
                    <EmojiPeopleIcon className={classes.breakdownIcon} />
                </Grid>
                <Grid item className={classes.paddingLeft}>
                    <Typography className={classes.breakdownText}>
                        {props.analystHistory?.liveInteractions}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} container justifyContent={"center"}>
                <Grid item>
                    <CallIcon className={classes.breakdownIcon} />
                </Grid>
                <Grid item className={classes.paddingLeft}>
                    <Typography className={classes.breakdownText}>
                        {props.analystHistory?.audioInteractions}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} container justifyContent={"center"}>
                <Grid item>
                    <EmailIcon className={classes.breakdownIcon} />
                </Grid>
                <Grid item className={classes.paddingLeft}>
                    <Typography className={classes.breakdownText}>
                        {props.analystHistory?.emailInteractions}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} container justifyContent={"center"}>
                <Grid item>
                    <ChatBubbleIcon className={classes.breakdownIcon} />
                </Grid>
                <Grid item className={classes.paddingLeft}>
                    <Typography className={classes.breakdownText}>
                        {props.analystHistory?.chatInteractions}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );

    const dateRange = (
        <div>
            <Typography component={"span"} variant={"subtitle2"}>
                {standardDateFormat(
                    store.datePickerStore.beginDate,
                    "M/D/YYYY",
                )}
            </Typography>
            &nbsp;<b>-</b>&nbsp;
            <Typography variant={"subtitle2"} component={"span"}>
                {standardDateFormat(store.datePickerStore.endDate, "M/D/YYYY")}
            </Typography>
        </div>
    );

    return (
        <AcxCard
            rootStyle={{
                ...{ minHeight: "200px", userSelect: "none" },
                ...props.rootCardStyle,
                ...{
                    maxWidth: mediumDownMatch ? "400px" : "unset",
                },
            }}
            loading={store.getTaskLoading(LoadAnalystHistoryTask)}
            title={"Total Evaluations Completed"}
            subTitle={dateRange}
            mainContent={content}
            actions={[bottom]}
        />
    );
});

export default AnalystHistoryCard;
