import { UnarchiveRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { ConfigurationStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/ConfigurationStepStore";
import { SourceFilesStepStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/SourceFilesStepStore";
import { StepperStore } from "components/Admin/AdminUIComponents/AudioFileSamplerV2/Stores/StepperStore";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import { useStore } from "utils/useStore";

interface ISamplerButton {
    showIcon: boolean;
}

const SamplerButton = observer((props: ISamplerButton) => {
    let navigate = useNavigate();

    const authStore = useStore(AuthStore);
    const stepperStore = useStore(StepperStore);
    const configStepStore = useStore(ConfigurationStepStore);
    const sourceFilesStepStore = useStore(SourceFilesStepStore);

    const handleMenuItemClick = (action: string) => {
        if (action === "samplerV2") {
            stepperStore.setStepIndex(0);
            configStepStore.setRulesetHidden(false);
            sourceFilesStepStore.isFromOutsideSampler = false;
            sourceFilesStepStore.orgSelectStore.selectOrg(
                authStore.orgStore.selectedOrganization,
            );

            navigate("/focus/audiofilesamplerv2");
        }
    };

    return (
        props.showIcon && (
            <Tooltip title="Smart Sampler" placement="bottom">
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleMenuItemClick("samplerV2")}
                >
                    <UnarchiveRounded />
                </IconButton>
            </Tooltip>
        )
    );
});

export default SamplerButton;
