import React from "react";

interface SparklingSvgProps {
    gradient?: boolean;
}

const SparklingSvg: React.FC<
    Omit<React.ComponentPropsWithoutRef<"svg">, "viewBox" | "xmlns"> &
        SparklingSvgProps
> = ({ gradient = true, ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            fill="none"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path
                d="M5 3V7V3ZM3 5H7H3ZM6 17V21V17ZM4 19H8H4ZM13 3L15.286 9.857L21 12L15.286 14.143L13 21L10.714 14.143L5 12L10.714 9.857L13 3Z"
                fill={
                    gradient
                        ? "url(#paint0_linear_11547_158649)"
                        : "currentColor"
                }
                stroke={
                    gradient
                        ? "url(#paint1_linear_11547_158649)"
                        : "currentColor"
                }
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            {gradient && (
                <defs>
                    <linearGradient
                        id="paint0_linear_11547_158649"
                        x1="3.5339"
                        y1="3.71163"
                        x2="22.1106"
                        y2="19.9167"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#32D1B7" />
                        <stop offset="0.695" stop-color="#349BC7" />
                        <stop offset="1.0001" stop-color="#3664D6" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_11547_158649"
                        x1="3.5339"
                        y1="3.71163"
                        x2="22.1106"
                        y2="19.9167"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#32D1B7" />
                        <stop offset="0.695" stop-color="#349BC7" />
                        <stop offset="1.0001" stop-color="#3664D6" />
                    </linearGradient>
                </defs>
            )}
        </svg>
    );
};

export default SparklingSvg;
