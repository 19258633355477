import { P2Results } from "models/P2Results";
import { ITranscriptionSegment } from "services/TranscriptionService";
import theme from "Theme/AppTheme";
import { useState, useLayoutEffect } from "react";
import { Blurb } from "./Components/BlurbBox/Blurb";

export const convertTranscriptToBlurbs = (
    transcriptWords: ITranscriptionSegment[],
    p2Data?: P2Results[],
    p2Variant?: "eddy" | "adverse",
): Blurb[] => {
    const newBlurbs: Blurb[] = [];
    if (!transcriptWords || transcriptWords[0]?.channel === undefined) {
        return newBlurbs;
    }

    let currentChannel = transcriptWords[0].channel;
    let blurbText = "";
    let wordArray: ITranscriptionSegment[] = [];
    let foundEddyWord: boolean = false;

    for (let i = 0; i < transcriptWords.length; i++) {
        const currentWord = transcriptWords[i];

        foundEddyWord =
            p2Data?.some((item: P2Results) => {
                return item.startWordIndex <= i && item.endWordIndex > i;
            }) ?? false;

        if (currentWord.channel === currentChannel) {
            if (foundEddyWord) {
                blurbText += `<span style="color:${
                    p2Variant === "adverse"
                        ? theme.palette.error[900]
                        : theme.palette.blue.main
                };font-weight:bold">${currentWord.text} </span>`;
            } else {
                blurbText += `${currentWord.text} `;
            }
            wordArray.push(currentWord);
        } else {
            newBlurbs.push(
                new Blurb(
                    blurbText,
                    currentChannel,
                    [
                        wordArray[0]?.startTime,
                        wordArray[wordArray.length - 1]?.endTime,
                    ],
                    blurbText.includes('span style="color:'),
                ),
            );
            if (foundEddyWord) {
                blurbText = `<span style="color:${
                    p2Variant === "adverse"
                        ? theme.palette.error[900]
                        : theme.palette.blue.main
                };font-weight:bold">${currentWord.text} </span>`;
            } else {
                blurbText = `${currentWord.text} `;
            }
            currentChannel = currentWord.channel;
            wordArray = [currentWord];
        }
    }

    if (wordArray.length > 0) {
        newBlurbs.push(
            new Blurb(
                blurbText,
                currentChannel,
                [
                    wordArray[0]?.startTime,
                    wordArray[wordArray.length - 1]?.endTime,
                ],
                blurbText.includes('span style="color:'),
            ),
        );
    }

    return newBlurbs;
};

export const useWindowResize = () => {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return size;
};
