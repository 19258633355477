import { Divider, Grid, Paper, Switch, Theme, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import React, { useState } from "react";
import theme from "Theme/AppTheme";
import InfoIcon from "@mui/icons-material/Info";
import { useStore } from "utils/useStore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useModelComputeOptions from "../../hooks/useModelComputeOptions";
import {
    ModelComputeFrequency,
    ModelComputeOptionEnabled,
} from "models/ModelCompute";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import OrganizationService from "../../Organization.service";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import ServiceHierarchySelect from "../OrganizationModules/fields/ServiceHierarchySelect";
import { AuthStore } from "stores/AuthStore";

const useStyles = makeStyles((theme: Theme) => ({
    pageContainer: { width: "95%", padding: theme.spacing(2) },
    headerText: {
        fontWeight: 600,
        color: theme.palette.gray[400],
    },
    boldHeaderText: {
        fontWeight: "bold",
        paddingLeft: "4px",
        color: theme.palette.black.main,
    },
    bigHeaderText: { fontWeight: 600, fontSize: "24px" },
    paperContainer: {
        // padding: theme.spacing(1),
        // padding: theme.spacing(1),
        width: "100%",
    },
    seModelHeader: {
        padding: theme.spacing(2),
        paddingBottom: 0,
        fontWeight: 600,
        fontSize: "16px",
    },
    subHeaderText: {
        padding: theme.spacing(2),
        paddingTop: 0,
        color: theme.palette.gray[500],
    },
    secondaryTextLabel: {
        fontWeight: 400,
        color: theme.palette.gray[700],
    },
    modelHeaderText: {
        padding: theme.spacing(2),
        fontWeight: 600,
        fontSize: "14px",
    },
    modelDescText: {
        fontWeight: 400,
        fontSize: "14px",
        color: theme.palette.gray.main,
        padding: theme.spacing(2),
    },
    // dividerPadding: {
    //     marginLeft: theme.spacing(1),
    //     marginRight: theme.spacing(1),
    // },
    nonBoldSecondaryText: {
        fontWeight: 400,
        color: theme.palette.gray[500],
    },
}));

type Props = {
    id?: string;
};

const OrganizationModelControls: React.FC<Props> = observer(({ id }) => {
    const { data: modelOptions, isLoading: modelOptionsIsLoading } =
        useModelComputeOptions({ id: id! });

    const [orgModelComputeOptions, setOrgModelComputeOptions] = useState<
        ModelComputeOptionEnabled[] | undefined | null
    >(modelOptions);

    const SEOption = orgModelComputeOptions?.find(
        (i) => i.modelComputeOption.modelOption === "SafetyEvents",
    );

    const SEEnabled = !!SEOption?.enabled;

    const SEDefaultFreq = SEOption?.modelComputeOption.quotaFrequency;
    const SEDefaultQuota = SEOption?.modelComputeOption.modelQuota;
    const SEDefaultOSMIds =
        SEOption?.modelComputeOption.modelComputeStructureMembers ?? [];

    const [isAll, setIsAll] = useState<boolean>(
        !(SEDefaultFreq || SEDefaultQuota || !!SEDefaultOSMIds.length),
    );

    const messageStore = useStore(MessageStore);
    const authStore = useStore(AuthStore);

    const classes = useStyles();

    const orgService = React.useMemo(() => {
        return OrganizationService;
    }, []);

    React.useEffect(() => {
        setOrgModelComputeOptions(modelOptions);
    }, [modelOptions]);

    React.useEffect(() => {
        if (isAll) {
            handleUpdateSEModelOSMIds(null);
            handleUpdateSEModelQuota(undefined);
            handleUpdateSEModelFreq(undefined);
        }
    }, [isAll]);

    React.useEffect(() => {
        if (!SEEnabled) {
            handleUpdateSEModelOSMIds(null);
            handleUpdateSEModelQuota(undefined);
            handleUpdateSEModelFreq(undefined);
        }
    }, [SEEnabled]);

    React.useEffect(() => {
        if (SEDefaultFreq || SEDefaultQuota || !!SEDefaultOSMIds.length) {
            setIsAll(false);
        }
    }, [SEDefaultFreq, SEDefaultOSMIds.length, SEDefaultQuota, SEEnabled]);

    const handleModelOptionToggle = (e, checked, modelOptionName) => {
        setOrgModelComputeOptions((prev) => {
            const option = prev?.find(
                (i) => i.modelComputeOption.modelOption === modelOptionName,
            );
            if (!prev || !option) return;
            if (checked) {
                option.enabled = true;
            } else {
                option.enabled = false;
            }
            return [...prev];
        });
    };

    const handleUpdateSEModelOSMIds = (ids: string[] | null) => {
        // setOsmIds(ids);
        setOrgModelComputeOptions((prev) => {
            const seOption = prev?.find(
                (i) => i.modelComputeOption.modelOption === "SafetyEvents",
            );
            if (!prev || !seOption) return;
            seOption.modelComputeOption.modelComputeStructureMembers =
                ids?.map((i) => ({ organizationStructureMemberId: i })) ?? [];
            return [...prev];
        });
    };
    const handleUpdateSEModelQuota = (quota?: number) => {
        // setOsmIds(ids);
        setOrgModelComputeOptions((prev) => {
            const seOption = prev?.find(
                (i) => i.modelComputeOption.modelOption === "SafetyEvents",
            );
            if (!prev || !seOption) return;
            seOption.modelComputeOption.modelQuota = quota;
            return [...prev];
        });
    };
    const handleUpdateSEModelFreq = (freq?: ModelComputeFrequency) => {
        // setOsmIds(ids);
        setOrgModelComputeOptions((prev) => {
            const seOption = prev?.find(
                (i) => i.modelComputeOption.modelOption === "SafetyEvents",
            );
            if (!prev || !seOption) return;
            seOption.modelComputeOption.quotaFrequency = freq;
            return [...prev];
        });
    };

    const freqOptions = [
        {
            label: "Day",
            value: 1,
        },
        {
            label: "Week",
            value: 2,
        },
    ];

    const SEConvoNum = orgModelComputeOptions?.find(
        (i) => i.modelComputeOption.modelOption === "SafetyEvents",
    )?.modelComputeOption.modelQuota;

    const missingSomething = !SEDefaultFreq || !SEDefaultQuota;

    // save should be disabled if isAll is false and we are missing something
    const saveButtonDisabled =
        SEEnabled &&
        !isAll &&
        ((!SEDefaultOSMIds.length && missingSomething) ||
            (SEConvoNum ?? 1) <= 0);

    const modelControlPage = (
        <Grid
            container
            className={classes.pageContainer}
            alignContent={"flex-start"}
            gap={3}
        >
            <Grid container item xs={12} className={classes.headerText}>
                Admin {">"} Organization Setup {">"}
                <span className={classes.boldHeaderText}>Model Control</span>
            </Grid>
            <Grid container item xs={12} justifyContent="space-between">
                <Grid item className={classes.bigHeaderText}>
                    Model Control
                </Grid>
                <Grid item>
                    <AcxButton
                        fullWidth
                        onClick={async () => {
                            if (!id || !orgModelComputeOptions) return;

                            try {
                                await orgService.updateModelComputeOptions(
                                    id,
                                    orgModelComputeOptions.map((i) => ({
                                        modelOption:
                                            i.modelComputeOption.modelOption,
                                        enabled: i.enabled,
                                        organizationStructureMemberIds:
                                            i.modelComputeOption.modelComputeStructureMembers?.map(
                                                (i) =>
                                                    i.organizationStructureMemberId,
                                            ) ?? [],
                                        quotaFrequency:
                                            i.modelComputeOption.quotaFrequency,
                                        modelQuota:
                                            i.modelComputeOption.modelQuota,
                                    })),
                                );
                                messageStore.logMessage(
                                    "Model compute options saved",
                                    "success",
                                );
                            } catch (e) {
                                messageStore.logMessage(
                                    "Failed to save model compute options",
                                    "error",
                                );
                            }
                        }}
                        disabled={saveButtonDisabled}
                        tooltip={
                            saveButtonDisabled
                                ? "You must specify valid volume controls for the Safety Events model before saving."
                                : ""
                        }
                    >
                        Save model control settings
                    </AcxButton>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Paper className={classes.paperContainer}>
                    <Grid container item xs={12} direction="column">
                        <Grid item xs={12} className={classes.seModelHeader}>
                            Model Compute Management
                        </Grid>
                        <Grid item xs={12} className={classes.subHeaderText}>
                            Choose which models to run
                        </Grid>
                        <Divider />
                        <Grid container>
                            <Grid
                                container
                                item
                                xs={12}
                                style={{
                                    fontWeight: 700,
                                    color: theme.palette.gray[400],
                                    fontSize: "12px",
                                }}
                            >
                                <Grid
                                    item
                                    xs={2}
                                    style={{ padding: theme.spacing(2) }}
                                >
                                    Model
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    style={{ padding: theme.spacing(2) }}
                                >
                                    Description
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    style={{ padding: theme.spacing(2) }}
                                >
                                    Enable
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    Safety Events
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    A family of models that flags Safety Events
                                    (product quality complaints, adverse events,
                                    and special situations), along with
                                    identifying where in the interaction the
                                    Safety Event was flagged.
                                    {SEEnabled && (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            style={{
                                                padding: theme.spacing(1),
                                            }}
                                            alignItems="center"
                                        >
                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                alignItems={"center"}
                                                className={
                                                    classes.secondaryTextLabel
                                                }
                                            >
                                                <Switch
                                                    sx={{ width: 40 }}
                                                    onChange={() => {
                                                        setIsAll(
                                                            (prev) => !prev,
                                                        );
                                                    }}
                                                    checked={isAll}
                                                />
                                                Run the Safety Event Model on
                                                all conversations
                                            </Grid>
                                            {!isAll && (
                                                <>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className={
                                                            classes.nonBoldSecondaryText
                                                        }
                                                    >
                                                        - or -
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        // ref={ref}
                                                    >
                                                        <ServiceHierarchySelect
                                                            orgId={id!}
                                                            value={
                                                                orgModelComputeOptions
                                                                    ?.find(
                                                                        (i) =>
                                                                            i
                                                                                .modelComputeOption
                                                                                .modelOption ===
                                                                            "SafetyEvents",
                                                                    )
                                                                    ?.modelComputeOption.modelComputeStructureMembers?.map(
                                                                        (i) =>
                                                                            i.organizationStructureMemberId,
                                                                    ) ?? []
                                                            }
                                                            onChange={
                                                                handleUpdateSEModelOSMIds
                                                            }
                                                            fullWidth
                                                            label=" "
                                                            placeholder="Select Hierarchies to run SE model on"
                                                        />
                                                        {/* <AcxHierarchySelector
                                                            userId={
                                                                authStore._user
                                                                    .profile.sub
                                                            }
                                                            orgId={id ?? ""}
                                                            displayType="inputDialog"
                                                            setHierarchyIds={
                                                                handleUpdateSEModelOSMIds
                                                            }
                                                            onSaveUpdateWithHierarchies={() => {}}
                                                            placeholder="Select hierarchies to run SE model on"
                                                            fullHeight
                                                            labelText=" "
                                                            initSelectedHierarchyIds={
                                                                orgModelComputeOptions
                                                                    ?.find(
                                                                        (i) =>
                                                                            i
                                                                                .modelComputeOption
                                                                                .modelOption ===
                                                                            "SafetyEvents",
                                                                    )
                                                                    ?.modelComputeOption.modelComputeStructureMembers?.map(
                                                                        (i) =>
                                                                            i.organizationStructureMemberId,
                                                                    ) ?? []
                                                            }
                                                        /> */}
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        className={
                                                            classes.secondaryTextLabel
                                                        }
                                                        style={{
                                                            paddingTop:
                                                                theme.spacing(
                                                                    1,
                                                                ),
                                                        }}
                                                        alignItems={"center"}
                                                        container
                                                    >
                                                        Run the model on up to{" "}
                                                        <Tooltip title="Model will be run within 5% of defined volume">
                                                            <InfoIcon
                                                                color="primary"
                                                                fontSize="small"
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        item
                                                        xs={9}
                                                        alignItems="center"
                                                        style={{
                                                            paddingTop:
                                                                theme.spacing(
                                                                    1,
                                                                ),
                                                        }}
                                                    >
                                                        <Grid item xs={3}>
                                                            <AcxMainTextField
                                                                id="num-conversations-model-control-input"
                                                                placeholderText="Enter # of conversations..."
                                                                onChange={(
                                                                    e,
                                                                ) => {
                                                                    handleUpdateSEModelQuota(
                                                                        Number(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        ),
                                                                    );
                                                                }}
                                                                type="number"
                                                                insertBlankIfNumber
                                                                value={
                                                                    SEConvoNum
                                                                }
                                                                isDisabled={
                                                                    isAll
                                                                }
                                                                error={
                                                                    (SEConvoNum ??
                                                                        1) <= 0
                                                                }
                                                                errorHelper="Value must be greater than 0"
                                                                // helpPopperText="Enter # of conversations."
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            item
                                                            xs={4}
                                                            justifyContent="center"
                                                            className={
                                                                classes.secondaryTextLabel
                                                            }
                                                        >
                                                            conversations per
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <AcxSelect
                                                                id="time-period-select-model-control"
                                                                options={
                                                                    freqOptions
                                                                }
                                                                labelField="label"
                                                                valueField="value"
                                                                placeholder="Select Time"
                                                                onChange={(
                                                                    i,
                                                                ) => {
                                                                    handleUpdateSEModelFreq(
                                                                        i.value ??
                                                                            0,
                                                                    );
                                                                }}
                                                                defaultValue={freqOptions.find(
                                                                    (i) =>
                                                                        i.value ===
                                                                        SEDefaultFreq,
                                                                )}
                                                                isDisabled={
                                                                    isAll
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    )}
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={3}
                                    alignItems={"flex-start"}
                                >
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={SEEnabled}
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "SafetyEvents",
                                            )
                                        }
                                    />
                                    <Grid
                                        item
                                        container
                                        style={{
                                            color: theme.palette.primary.main,
                                            fontWeight: 600,
                                        }}
                                        alignContent={"center"}
                                        xs={6}
                                    >
                                        <Grid container alignItems={"center"}>
                                            <KeyboardArrowDownIcon
                                                style={{
                                                    color: theme.palette.primary
                                                        .main,
                                                }}
                                            />
                                            Volume Control
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    Eddy Effect
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Surfaces when a desired or expected customer
                                    experience is disrupted by an obstacle,
                                    along with identifying where in the
                                    interaction the friction point was
                                    experienced.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption ===
                                                    "EddyEffect",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "EddyEffect",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    Sentiment
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Labels the starting and ending sentiments of
                                    a conversation as Positive, Neutral, or
                                    Negative.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption ===
                                                    "Sentiment",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "Sentiment",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    Summary
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Summarizes the main points of a
                                    conversation.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption ===
                                                    "Summary",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "Summary",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    TopicLLM
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Surfaces up to 3 Topics of conversation for
                                    every interaction, by grouping individual,
                                    closely-related words into Topics.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption ===
                                                    "TopicLLM",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "TopicLLM",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    Contact Type
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Automatically identifies the primary persona
                                    present on every interaction as one of the
                                    following 6 contact types: Caregiver,
                                    Patient/Member, HCP, Payer, Internal,
                                    Pharmacy.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption ===
                                                    "ContactType",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "ContactType",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    HIPAA
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Flags whether the agent confirms name and 2
                                    pieces of PII before disclosing HIPAA
                                    information.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption ===
                                                    "Hipaa",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "Hipaa",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    Voicemail
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Detects interactions that are solely
                                    voicemail responses, and filters these
                                    conversations out.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption ===
                                                    "Voicemail",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "Voicemail",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    IVR
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Detects interactions that are solely
                                    interactive voice response (automated phone
                                    system tool used by call centers), and
                                    filters these conversations out.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption === "IVR",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "IVR",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12}>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.modelHeaderText}
                                >
                                    Speaker Identification
                                </Grid>
                                <Grid
                                    item
                                    xs={7}
                                    className={classes.modelDescText}
                                >
                                    Labels the speakers in each conversation as
                                    Agent or Customer.
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        sx={{ width: 40 }}
                                        checked={
                                            !!orgModelComputeOptions?.find(
                                                (i) =>
                                                    i.modelComputeOption
                                                        .modelOption ===
                                                    "SpeakerIdentification",
                                            )?.enabled
                                        }
                                        onChange={(e, checked) =>
                                            handleModelOptionToggle(
                                                e,
                                                checked,
                                                "SpeakerIdentification",
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );

    if (!authStore.canUserEdit("Model Controls")) {
        return <>No Access</>;
    }
    return modelOptionsIsLoading ? (
        <AcxLoadingIndicator size={75} alternate="PuffLoader" />
    ) : (
        modelControlPage
    );
});

export default OrganizationModelControls;
