import { Grid, Typography } from "@mui/material";
import { LoginDate } from "components/Admin/Organizations/types/LoginDate.type";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import RecursiveTreeStore from "components/UI/AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import AcxHierarchyDetailsFabStore from "components/UI/HierarchyDetailsFab/AcxHierarchyDetailsFabStore";
import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import React from "react";
import { OrganizationService } from "services/OrganizationService";
import { BaseStore } from "stores/BaseStore";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import {
    HierarchyPermissions,
    HierarchyTypes,
} from "../OrganizationUserDetails/Components/Types";
import type { IHierarchyAssignmentTier } from "../OrganizationUserDetails/Components/Types";
import UserService from "../User.service";

export const GET_LOGIN_HISTORY = "Get User Login History";
export const UPDATE_CHILDREN = "Propagate Assignments to Children";
export const UPDATE_ALL = "Update All Hierarchy Assignments";

@AcxStore
export class OrganizationUserStore extends BaseStore {
    private readonly userService: typeof UserService = UserService;
    private readonly orgService: OrganizationService =
        new OrganizationService();

    readonly fabStore: AcxHierarchyDetailsFabStore;
    readonly messageStore: MessageStore;
    readonly dgStore: AcxDataGridStore;
    @observable AgentUsers?: any = false;
    @observable AgentUsersLabel?: string = "Show Agent Users";

    readonly dgStoreLogin: AcxDataGridStore;
    readonly drawerStore: LayoutDrawerStore;

    @observable.ref
    orgId?: string;

    @observable users?: any;

    @observable.ref
    orgServiceSetup: string[] = [];

    @observable
    userLoginHistory?: LoginDate[];

    @observable
    selectedUserName?: string;

    @observable
    activeTier?: IHierarchyAssignmentTier;

    @observable
    activeUserId?: string;

    @observable
    propagateDialogIsOpen: boolean = false;

    @observable
    updateAllDialogIsOpen: boolean = false;

    treeStore: RecursiveTreeStore;
    hierarchyPermissions: string[];
    hierarchyTypes: string[];
    getUsers: string;
    topLevelHierarchies: any;

    constructor(private rootStore: IRootStore) {
        super("OrganizationUserStore");

        makeObservable(this);

        this.drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        this.messageStore = this.rootStore.getStore(MessageStore);

        this.dgStore = new AcxDataGridStore(
            "OrganizationUserDataStoreGrid",
            "OrgUsers",
        );

        this.dgStoreLogin = new AcxDataGridStore(
            "OrganizationUserLoginHistoryDataStoreGrid",
            "OrgUsers",
        );

        this.dgStore.checkboxSelection = false;
        this.dgStoreLogin.checkboxSelection = false;
        this.dgStoreLogin.setHideVertIcon(true);
        this.fabStore = new AcxHierarchyDetailsFabStore(this.rootStore);

        reaction(
            () => ({
                orgId: this.orgId,
                activeLocation: this.rootStore.activeLocation,
            }),
            (args) => {
                if (
                    args.activeLocation &&
                    !(
                        args.activeLocation.location.includes(
                            "admin/organizations",
                        ) && args.activeLocation.location.includes("/users")
                    )
                ) {
                    return;
                }

                if (!args.orgId) return;

                if (args.orgId) {
                    this.setupAsyncTask("Get Org Data", async () => {
                        const orgResponse =
                            await this.orgService.getOrganization(
                                args.orgId ?? "",
                            );

                        this.orgServiceSetup = orgResponse.serviceSetup ?? [];
                    });

                    this.setupAsyncTask("Load Users", () =>
                        this.loadUsers(args.orgId as string),
                    );
                }
            },
            { fireImmediately: true },
        );

        reaction(
            () => this.anyTaskLoading,
            (task) => {
                this.dgStore.setLoading(task);
            },
            { fireImmediately: true },
        );

        reaction(
            (r) => this.dgStore.rows.length > 0,
            (hasRows) => {
                this.dgStore.vertIconMenuItemsBuilder = () => {
                    return [
                        this.dgStore.buildVertMenuOptionForCsvExport(hasRows),
                    ];
                };
            },
        );
    }

    @action
    setActiveTier = (tier: IHierarchyAssignmentTier) => {
        this.activeTier = tier;
    };

    @action
    setTierPerms = (perms?: number[] | string, types?: number[] | string) => {
        if (this.activeTier) {
            if (Array.isArray(perms)) {
                this.activeTier.permissions = perms;
            } else if (perms) {
                if (this.activeTier.permissions) {
                    this.activeTier.permissions.push(
                        HierarchyPermissions[perms],
                    );
                } else {
                    this.activeTier.permissions = [HierarchyPermissions[perms]];
                }
            }

            if (Array.isArray(types)) {
                this.activeTier.types = types;
            } else if (types) {
                if (this.activeTier.types) {
                    this.activeTier.types.push(HierarchyTypes[types]);
                } else {
                    this.activeTier.types = [HierarchyTypes[types]];
                }
            }
        }
    };

    @action
    setUserLoginHistory = (loginHistory: LoginDate[]) => {
        this.userLoginHistory = loginHistory;
    };

    @action
    setSelectedUserName = (userName: string) => {
        this.selectedUserName = userName;
    };

    @action
    setOrgId = (orgId: string) => {
        this.orgId = orgId;
    };

    @action
    setActiveUserId = (userId: string) => {
        this.activeUserId = userId;
    };

    @action
    setPropagateDialogIsOpen = (isOpen: boolean) => {
        this.propagateDialogIsOpen = isOpen;
    };

    @action
    setAllDialogIsOpen = (isOpen: boolean) => {
        this.updateAllDialogIsOpen = isOpen;
    };

    @action
    toggleLoginHistory = () => {
        this.drawerStore.closeAndResetDrawer();
        this.drawerStore.restoreDefaults();
        this.drawerStore.setContentFactory(() => (
            <Grid container item xs={12} style={{ height: "100%" }}>
                <VerticalDrawerContentTemplate
                    title={"User Login History"}
                    subTitle={this.selectedUserName}
                    content={
                        <div style={{ height: "calc(100% - 2rem)" }}>
                            {this.userLoginHistory &&
                            this.userLoginHistory.length > 0 ? (
                                <div style={{ height: "100%" }}>
                                    <AcxDataGrid
                                        dataGridStore={this.dgStoreLogin}
                                    />
                                </div>
                            ) : (
                                <Typography color="secondary">
                                    <br />
                                    No Recorded Login History Available
                                </Typography>
                            )}
                        </div>
                    }
                    onClose={() => {}}
                />
            </Grid>
        ));

        this.drawerStore.setOpen(true);
    };
    public loadUsers = async (orgId: string) => {
        this.users = await this.userService.getUsers(orgId);
        runInAction(() => {
            this.displayUsers();
        });
    };

    public displayUsers = async () => {
        if (this.AgentUsers) {
            this.dgStore.rows = this.users.filter(
                (agent) => agent.agentId !== null,
            ) as any;
        } else {
            this.dgStore.rows = this.users.filter(
                (agent) => agent.agentId === null,
            ) as any;
        }
    };

    public getUserLoginHistory = (userId: string, userName: string) => {
        this.setupAsyncTask(GET_LOGIN_HISTORY, async () => {
            const loginHistory = await this.userService.getUserLoginHistory(
                userId,
            );

            this.dgStoreLogin.rows = loginHistory as any;
            this.setSelectedUserName(userName);
            this.setUserLoginHistory(loginHistory);
            this.toggleLoginHistory();
        });
    };

    public getTopLevelServiceHierarchiesForOrganization = async (
        userId: string,
        orgId: string,
        showInactive?: boolean,
    ) => {
        this.topLevelHierarchies =
            await this.userService.getTopLevelOsmAssignmentTiers(userId, orgId);

        let finalTopLevelHiers = { ...this.topLevelHierarchies };
        if (!showInactive) {
            finalTopLevelHiers.tiers = finalTopLevelHiers.tiers.filter(
                (tier) => tier.isActive,
            );
        }

        return finalTopLevelHiers;
    };

    public getOsmAssignmentTier = async (
        orgId: string,
        parentId: string,
        showInactive: boolean,
    ) => {
        if (this.activeUserId) {
            const tiers = await this.userService.getOsmAssignmentChildrenTiers(
                this.activeUserId,
                orgId,
                parentId,
                showInactive,
            );

            const result: IHierarchyAssignmentTier[] = tiers.map((tier) => {
                return {
                    id: tier.id,
                    name: tier.name,
                    branchDepth: 0, // will be set in the recursive tree branch component
                    isLeaf: tier.isLeaf,
                    permissions: tier.permissions,
                    types: tier.types,
                    isActive: tier.isActive,
                };
            });

            return result;
        }

        return;
    };

    public updateOsmAssignmentTier = async (orgId: string) => {
        if (orgId && this.activeUserId && this.activeTier) {
            await this.userService.editHierarchyAssignmentsTier(
                orgId,
                this.activeUserId,
                this.activeTier,
            );

            // Update Hierarchies button
            await this.fabStore.populateUserHierarchies();

            return this.activeTier;
        }
    };

    public updateAllTiersOfParent = (
        orgId: string,
        userId: string,
        showInactive?: boolean,
    ) => {
        this.setupAsyncTask(UPDATE_CHILDREN, async () => {
            if (orgId && userId && this.activeTier) {
                // Clear Types if applicable
                if (!this.activeTier.types) {
                    this.activeTier.types = [];
                }

                // Clear Permissions if applicable
                if (!this.activeTier.permissions) {
                    this.activeTier.permissions = [];
                }

                // Close Branch if applicable (will force a GET request to display new perms)
                if (
                    this.treeStore.openedBranchIds.includes(this.activeTier.id)
                ) {
                    this.treeStore.openedBranchIds =
                        this.treeStore.openedBranchIds.filter(
                            (id) => id !== this.activeTier!.id,
                        );
                }

                await this.userService.updateAllTiersOfParent(
                    orgId,
                    userId,
                    this.activeTier,
                );

                // Re-load the topLevel branches after propagating
                const topLevelHiers =
                    await this.getTopLevelServiceHierarchiesForOrganization(
                        userId,
                        orgId,
                        showInactive,
                    );

                // TODO: find better way to refresh topLevel
                this.treeStore.setTopLevelBranches([]);
                this.treeStore.setTopLevelBranches(topLevelHiers.tiers);

                // Update Hierarchies button
                await this.fabStore.populateUserHierarchies();

                this.setPropagateDialogIsOpen(false);

                this.messageStore.logMessage(
                    "Successfully updated all child tiers.",
                    "success",
                );
            }
        });
    };

    public updateAllTiers = (
        orgId: string,
        userId: string,
        hierarchyIds: string[],
        permissions?: number[],
        types?: number[],
        showInactive?: boolean,
    ) => {
        this.setupAsyncTask(UPDATE_ALL, async () => {
            if (orgId && userId && this.activeTier) {
                // Clear Types if applicable
                if (!this.activeTier.types) {
                    this.activeTier.types = [];
                }

                // Clear Permissions if applicable
                if (!this.activeTier.permissions) {
                    this.activeTier.permissions = [];
                }

                // Close Branch if applicable (will force a GET request to display new perms)
                if (
                    this.treeStore.openedBranchIds.includes(this.activeTier.id)
                ) {
                    this.treeStore.openedBranchIds =
                        this.treeStore.openedBranchIds.filter(
                            (id) => id !== this.activeTier!.id,
                        );
                }

                await this.userService.updateAllTiers(
                    orgId,
                    userId,
                    hierarchyIds,
                    permissions,
                    types,
                );

                // Re-load the topLevel branches after propagating
                const topLevelHiers =
                    this.getTopLevelServiceHierarchiesForOrganization(
                        userId,
                        orgId,
                        showInactive,
                    );

                // TODO: find better way to refresh topLevel
                this.treeStore.setTopLevelBranches([]);
                // passing in the promise seems to reload the tree
                this.treeStore.getTopLevelBranches(topLevelHiers);

                // Update Hierarchies button
                await this.fabStore.populateUserHierarchies();

                this.setAllDialogIsOpen(false);

                this.messageStore.logMessage(
                    "Successfully updated all tiers.",
                    "success",
                );
            }
        });
    };
}
