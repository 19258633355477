import { Button, Grid, Switch, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import React from "react";
import AcxDataGridStore from "../AcxDataGridStore";
import MenuIcon from "@mui/icons-material/Menu";
import AcxChip from "components/UI/AcxChip";

const useStyles = makeStyles((theme: Theme) => ({
    rootContainer: {
        overflowX: "hidden",
    },
    bodyWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "1.25rem",
    },
    toggleContainer: (props: IHideColumnPanel) => ({
        // maxHeight: props.maxHeight - 150,
        overflowY: "scroll",
    }),
    switchItem: {
        "&:hover": {
            backgroundColor: theme.palette.gray[50],
        },
        "&:hover .MuiSvgIcon-root": { color: theme.palette.gray[600] },
    },
    menuItem: {
        color: theme.palette.gray[300],
    },
}));

interface IHideColumnPanel {
    store: AcxDataGridStore;
}

const HideColumnPanel = observer((props: IHideColumnPanel) => {
    const classes = useStyles(props);

    return (
        <Grid
            container
            spacing={1}
            key="grid-hide-column"
            className={classes.rootContainer}
            sx={{ overflowX: "hidden" }}
        >
            <Grid container sx={{ display: "flex", flexDirection: "column" }}>
                <Grid
                    item
                    sx={{
                        maxHeight: "50vh",
                        overflowY: "auto",
                        width: "400px",
                    }}
                >
                    {props.store.columns.map((col, i) => (
                        <Grid
                            container
                            key={i}
                            className={classes.switchItem}
                            alignItems="center"
                        >
                            <Grid item paddingTop={0.5} paddingLeft={1}>
                                <MenuIcon className={classes.menuItem} />
                            </Grid>
                            <Grid item>
                                <Switch
                                    sx={{ width: 40 }}
                                    onChange={(e) => {
                                        props.store.columns =
                                            props.store.columns.map(
                                                (storeCol) => {
                                                    if (
                                                        storeCol.field ===
                                                        col.field
                                                    ) {
                                                        storeCol.hide =
                                                            !storeCol.hide;
                                                    }
                                                    return storeCol;
                                                },
                                            );
                                        props.store.updateLocalStorage(
                                            props.store.columns,
                                        );
                                    }}
                                    checked={!col.hide}
                                />
                            </Grid>
                            <Grid item>{col.headerName}</Grid>
                        </Grid>
                    ))}
                </Grid>
                <Grid container padding={1}>
                    <Grid container item alignItems="center">
                        <Grid item>
                            <Button
                                onClick={() => {
                                    props.store.columns =
                                        props.store.columns.map((storeCol) => {
                                            storeCol.hide = true;

                                            return storeCol;
                                        });
                                    props.store.updateLocalStorage(
                                        props.store.columns,
                                    );
                                }}
                                color="primary"
                                disabled={props.store.columns.every(
                                    (column) => column.hide,
                                )}
                                size="small"
                            >
                                Hide All
                            </Button>
                        </Grid>
                        <Grid item>/</Grid>
                        <Grid item>
                            <Button
                                onClick={() => {
                                    props.store.columns =
                                        props.store.columns.map((storeCol) => {
                                            storeCol.hide = false;
                                            return storeCol;
                                        });
                                    props.store.updateLocalStorage(
                                        props.store.columns,
                                    );
                                }}
                                color="primary"
                                disabled={props.store.columns.every(
                                    (column) => !column.hide,
                                )}
                                size="small"
                            >
                                Select All
                            </Button>
                        </Grid>
                        <Grid item style={{ marginLeft: "auto" }}>
                            <AcxChip
                                size="small"
                                color="gray"
                                label={`${
                                    props.store.columns.filter(
                                        ({ hide }) => !hide,
                                    ).length
                                } selected`}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

export default HideColumnPanel;
