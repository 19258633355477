import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import SideControlsPage from "Layouts/SideControls/SideControlsPage";
import React from "react";
import useStyles from "Styles/Styles";
import ConfigNavigation, { ConfigRoutes } from "./ConfigNavigation";

const styles = (theme: Theme) =>
    createStyles({
        sideControlMain: {
            padding: 0,
        },
    });

export default function Config() {
    const classes = useStyles(styles);
    return (
        <SideControlsPage
            mainContent={<ConfigRoutes />}
            enableMinimize
            containerOffsetPixels={64}
            drawerContent={<ConfigNavigation />}
            drawerLocation="left"
            mainGridAlignItems="stretch"
            mainGridJustify="flex-start"
            mainGridSpacing={0}
            mainContentClasses={classes.sideControlMain}
        />
    );
}
