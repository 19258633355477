import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const ModulesSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="17px"
            height="15px"
            viewBox="0 0 17 15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Admin"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.5}
            >
                <g
                    id="Admin---Organization---Users"
                    transform="translate(-35.000000, -289.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Left-Nav" transform="translate(0.000000, 64.000000)">
                        <g
                            id="Group-10"
                            transform="translate(24.000000, 104.000000)"
                        >
                            <g
                                id="Group-3"
                                transform="translate(0.000000, 32.000000)"
                            >
                                <g
                                    id="Group-4-Copy-5"
                                    transform="translate(0.000000, 80.000000)"
                                >
                                    <g
                                        id="Icon/Building"
                                        transform="translate(11.000000, 9.000000)"
                                    >
                                        <path
                                            d="M5.998125,5.999375 L5.998125,14.004375 L1.995625,14.004375 C1.44328,14.004375 0.995,13.556095 0.995,13.00375 L0.995,5.999375 L5.998125,5.999375 Z M17.005,5.999375 L17.005,13.00375 C17.005,13.556095 16.55672,14.004375 16.004375,14.004375 L7.999375,14.004375 L7.999375,5.999375 L17.005,5.999375 Z M16.004375,-0.004375 C16.55672,-0.004375 17.005,0.443905 17.005,0.99625 L17.005,3.998125 L0.995,3.998125 L0.995,0.99625 C0.995,0.443905 1.44328,-0.004375 1.995625,-0.004375 L16.004375,-0.004375 Z"
                                            id="Combined-Shape"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ModulesSvg;
