import { Box, Typography } from "@mui/material";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import React from "react";
import { useStyles } from "./styles";

interface SummarySectionProps {
    store: ConversationsStore;
}

const SummarySection: React.FC<SummarySectionProps> = ({ store }) => {
    const classes = useStyles({});
    return (
        <Box marginBottom="16px" className={classes.width85}>
            <Typography className={classes.summaryTitle}>
                {`${
                    store.selectedConversation?.isChat ? "Chat " : "Call "
                }Summary`}
            </Typography>
            {store.selectedConversation &&
                (store.selectedConversation.summary ?? "No Summary")}
        </Box>
    );
};

export default SummarySection;
