import createStyles from '@mui/styles/createStyles';
import { Theme } from "@mui/material/styles";
import React from "react";
import AcxTable from "components/UI/AcxTable/AcxTable";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) => createStyles({});

function AcxTableTest() {
    const classes = useStyles(styles);
    const [isLoading, setIsLoading] = React.useState(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);

    function formatStatus(status: string) {
        var className = "";
        if (status === "Good") className = classes.fontColorSuccess;
        if (status === "Bad") className = classes.fontColorError;
        if (status === "Ok") className = classes.fontColorWarning;
        return <span className={className}>{status}</span>;
    }

    const rows = [
        { name: "Name1", description: "Description1", status: "Good" },
        { name: "Name2", description: "Description2", status: "Bad" },
        { name: "Name3", description: "Description3", status: "Bad" },
        { name: "Name4", description: "Description4", status: "Ok" },
        { name: "Name5", description: "Description5", status: "Ok" },
        { name: "Name6", description: "Description6", status: "Ok" },
    ];
    const rows2 = [
        { name: "Name10", description: "Description1", status: "Good" },
        { name: "Name20", description: "Description2", status: "Bad" },
        { name: "Name30", description: "Description3", status: "Bad" },
        { name: "Name40", description: "Description4", status: "Ok" },
        { name: "Name50", description: "Description5", status: "Ok" },
        { name: "Name60", description: "Description6", status: "Ok" },
    ];
    const manyRows = () => {
        const r: any[] = [];
        for (let i = 0; i < 2000; i++) {
            r.push({
                name: `Name ${i}`,
                description: `Description ${i}`,
                status: "Good",
            });
        }
        for (let i = 0; i < 2000; i++) {
            r.push({
                name: `Name ${2000 + i}`,
                description: `Description ${2000 + i}`,
                status: "Bad",
            });
        }
        return r;
    };
    const columns = [
        { headerLabel: "Name", dataKey: "name" },
        { headerLabel: "Description", dataKey: "description" },
        { headerLabel: "Status", dataKey: "status", formatter: formatStatus },
    ];
    setTimeout(() => {
        setIsLoading(false);
    }, 5000);
    const onPageChange = (pg) => {
        // debugger;
        setPage(pg);
    };
    const onRowPerPage = (cnt) => {
        setRowsPerPage(cnt);
    };
    return (
        <>
            <div style={{ height: "500px" }}>
                <AcxTable
                    {...{ columns }}
                    rows={manyRows()}
                    virtualize={true}
                    enableSort={true}
                    // isLoading={isLoading}
                    enableCheck={true}
                    keyField="name"
                    showPaging={true}
                    rowCount={rows.length}
                    cellDense={true}
                />
            </div>
            <AcxTable {...{ rows, columns }} keyField="name" />
            <AcxTable
                {...{ columns }}
                rows={
                    rowsPerPage > 0
                        ? rows2.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                          )
                        : rows2
                }
                onPageChange={onPageChange}
                rowsPerPage={rowsPerPage}
                enableSort={true}
                isLoading={isLoading}
                enableCheck={true}
                keyField="name"
                showPaging={true}
                rowCount={rows2.length}
                onRowsPerPageChange={onRowPerPage}
            />
        </>
    );
}

export default AcxTableTest;
