import { Observer, observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import BaseDashboard from "../../../Layouts/Dashboard/BaseDashboard";
import { useResizeObserver } from "../../../utils/useResizeObserver";
import { useStore } from "../../../utils/useStore";
import { LandingDashboardStore } from "./Stores/LandingDashboardStore";

interface OwnProps {}

type Props = OwnProps;

const LandingDashboard: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const store = useStore(LandingDashboardStore);
    const { elementHeight, elementWidth, theRef } =
        useResizeObserver<HTMLDivElement>();

    return (
        <Observer>
            {() => (
                <div
                    style={{
                        height: "100%",
                        position: "relative",
                        marginLeft: store.dashboardStore.isWidgetsDrawerOpen
                            ? "340px"
                            : "0px",
                        width: `calc(100% - ${
                            store.dashboardStore.isWidgetsDrawerOpen
                                ? "340px"
                                : "0px"
                        }`,
                    }}
                    ref={theRef}
                >
                    {Boolean(elementWidth && elementHeight) && (
                        <BaseDashboard
                            width={elementWidth ?? 0}
                            height={elementHeight ?? 0}
                            dashboardStore={store.dashboardStore}
                        />
                    )}
                </div>
            )}
        </Observer>
    );
});

export default LandingDashboard;
