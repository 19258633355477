import { BranchDataProps } from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";

export enum HierarchyPermissions {
    "View" = 0,
    "Edit" = 1,
    "Manage" = 2,
}

export enum HierarchyTypes {
    "Audio" = 0,
    "Email" = 1,
    "Chat" = 2,
    "Video" = 3,
    "Live" = 4,
}

export interface IHierarchyAssignmentTier extends BranchDataProps {
    tiers?: IHierarchyAssignmentTier[];
    permissions?: number[];
    types?: number[];
    isActive?: boolean;
}
