import { runInAction } from "mobx";
import {
    ClipTag,
    GroupedTags,
} from "../components/Evaluation/Stores/EvalStore";
import SoundClip from "../models/SoundClip";
import { isType } from "./TypeGuards";

export function parseStringTags(
    universalTags: ClipTag[] | GroupedTags[],
    soundClip: SoundClip,
) {
    if (!soundClip.tags) {
        return;
    }

    const parsedTags: ClipTag[] = [];

    if (isType(universalTags, "label")) {
        const uTags: GroupedTags[] = universalTags as GroupedTags[];
        const flat: ClipTag[] = [];

        uTags.forEach((element) => {
            flat.push(...element.options);
        });

        universalTags = flat;
    }

    const cTags: ClipTag[] = universalTags as ClipTag[];
    const stringTags = soundClip.tags.split(",,");

    for (const stringTag of stringTags) {
        const ansTag = cTags.find((value) => value.displayName === stringTag);

        if (ansTag) {
            console.log(
                `ParseStringTag: ${soundClip.segmentName} MATCH ${stringTag} to ${ansTag.displayName}`,
            );

            parsedTags.push(ansTag);
        } else {
            console.log(
                `ParseStringTag: ${soundClip.segmentName} NO-MATCH ${stringTag}`,
            );
        }
    }

    runInAction(() => {
        soundClip._tags = parsedTags;
    });
}
