import {
    VolumeDownOutlined,
    VolumeMuteOutlined,
    VolumeUpOutlined,
} from "@mui/icons-material";
import {
    Box,
    Grid,
    IconButton,
    Popover,
    Slider,
    Typography,
} from "@mui/material";
import { observer } from "mobx-react";

import React, { useState } from "react";
import theme from "Theme/AppTheme";

interface VolumeSliderProps {
    onVolumeChange: (level: number) => void;
    onChannel1Change?: (level: number) => void;
    onChannel2Change?: (level: number) => void;
    multiChannel?: boolean;
}

const VolumeSlider: React.FC<VolumeSliderProps> = observer(
    ({ onVolumeChange, onChannel1Change, onChannel2Change, multiChannel }) => {
        const [mainVolumeLevel, setMainVolumeLevel] = useState<number>(100);
        const [channel1VolumeLevel, setChannel1VolumeLevel] =
            useState<number>(100);
        const [channel2VolumeLevel, setChannel2VolumeLevel] =
            useState<number>(100);
        const [expanded, setExpanded] = useState(false);
        const [anchorEl, setAnchorEl] =
            React.useState<HTMLButtonElement | null>(null);

        const handleMainSliderChange = (
            event: any,
            newValue: number | number[],
        ) => {
            setMainVolumeLevel(newValue as number);
            onVolumeChange((newValue as number) / 100);
        };

        const handleChannel1SliderChange = (
            event: any,
            newValue: number | number[],
        ) => {
            setChannel1VolumeLevel(newValue as number);
            onChannel1Change?.((newValue as number) / 100);
        };

        const handleChannel2SliderChange = (
            event: any,
            newValue: number | number[],
        ) => {
            setChannel2VolumeLevel(newValue as number);
            onChannel2Change?.((newValue as number) / 100);
        };

        return (
            <Box component="span" display="flex" flexDirection="row">
                <IconButton
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        setExpanded(true)
                    }}
                    size="large"
                >
                    {mainVolumeLevel > 50 ? (
                        <VolumeUpOutlined color="primary" />
                    ) : mainVolumeLevel === 0 ? (
                        <VolumeMuteOutlined color="primary" />
                    ) : (
                        <VolumeDownOutlined color="primary" />
                    )}
                </IconButton>
                <Popover
                    open={expanded}
                    onClose={() => {
                        setExpanded(false);
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    sx={{ borderRadius: theme.shape.borderRadius }}
                >
                    <Grid
                        container
                        width={"200px"}
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                        padding={1}
                    >
                        <SliderElement
                            handleSliderChange={handleMainSliderChange}
                            volumeLevel={mainVolumeLevel}
                            title="Main"
                        />
                        {multiChannel && (
                            <>
                                <SliderElement
                                    handleSliderChange={
                                        handleChannel1SliderChange
                                    }
                                    volumeLevel={channel1VolumeLevel}
                                    title="Channel 1"
                                />
                                <SliderElement
                                    handleSliderChange={
                                        handleChannel2SliderChange
                                    }
                                    volumeLevel={channel2VolumeLevel}
                                    title="Channel 2"
                                />
                            </>
                        )}
                    </Grid>
                </Popover>
            </Box>
        );
    },
);

interface SliderElementProps {
    handleSliderChange: (event: any, newValue: number | number[]) => void;
    volumeLevel: number;
    title: string;
}

const SliderElement: React.FC<SliderElementProps> = observer(
    ({ handleSliderChange, volumeLevel, title }) => {
        return (
            <Grid
                container
                direction="column"
                sx={{
                    "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    padding: 0.75,
                    borderRadius: theme.shape.borderRadius,
                }}
            >
                <Grid item xs={12}>
                    <Typography fontSize="11px">{title}</Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    alignItems="center"
                    direction="row"
                >
                    <Grid item xs={2} alignItems="center">
                        {volumeLevel > 50 ? (
                            <VolumeUpOutlined
                                color="primary"
                                fontSize="small"
                            />
                        ) : volumeLevel === 0 ? (
                            <VolumeMuteOutlined
                                color="primary"
                                fontSize="small"
                            />
                        ) : (
                            <VolumeDownOutlined
                                color="primary"
                                fontSize="small"
                            />
                        )}
                    </Grid>
                    <Grid item xs={9}>
                        <Slider
                            value={
                                typeof volumeLevel === "number"
                                    ? volumeLevel
                                    : 0
                            }
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            orientation="horizontal"
                            style={{ width: "100%" }}
                            size="small"
                            sx={{ color: theme.palette.primary.main }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    },
);

export default VolumeSlider;
