import { action, makeObservable, observable, reaction } from "mobx";
import React from "react";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { DrawerStore } from "../../components/UI/Drawer/AcxDrawer";
import { BaseStore } from "../BaseStore";

@AcxStore
export class LayoutDrawerStore extends BaseStore implements DrawerStore {
    @observable isOpen: boolean = false;
    @observable anchor: "left" | "right" | "top" | "bottom" = "right";
    @observable variant: "permanent" | "persistent" | "temporary" = "permanent";
    @observable collapseSize: "mini" | "full" = "full";
    @observable canOpen = false;
    @observable offsetPx?: number = 64;
    @observable offsetPosition?: "Left" | "Top" | "Right" | "Bottom" = "Top";
    @observable customRootStyles?: React.CSSProperties;
    @observable transitionDuration?: number;

    @observable.ref contentInjector?: () => React.ReactNode;

    constructor(private rootStore: IRootStore) {
        super("FocusPageDrawerStore");

        makeObservable(this);

        reaction(
            (r) => this.contentInjector,
            (arg) => {
                this.canOpen = Boolean(arg);
                if (!this.canOpen) {
                    this.setOpen(false);
                }
            },
            { fireImmediately: true },
        );
    }

    @action
    setContentFactory(factory?: () => React.ReactNode) {
        this.contentInjector = factory;
    }

    @action
    setOffsetPixels(px?: number) {
        this.offsetPx = px;
    }

    @action
    setOffsetPosition(position?: "Left" | "Top" | "Right" | "Bottom") {
        this.offsetPosition = position;
    }

    @action
    setCustomRootStyles(styles: React.CSSProperties) {
        this.customRootStyles = styles;
    }

    @action
    setVariant(variant: "permanent" | "persistent" | "temporary") {
        this.variant = variant;
    }

    @action
    setCollapseSize(size: "mini" | "full") {
        this.collapseSize = size;
    }
    @action
    setAnchor(anchor: "left" | "right" | "top" | "bottom") {
        this.anchor = anchor;
    }

    @action
    setTransitionDuration(duration?: number) {
        this.transitionDuration = duration;
    }

    @action
    setOpen(isOpen: boolean) {
        this.isOpen = isOpen;
    }

    @action
    reset = (keepContent?: boolean) => {
        this.setOpen(false);
        if (!keepContent) {
            this.contentInjector = undefined;
        }
    };

    @action
    closeDrawer = () => {
        this.isOpen = false;
    };

    @action
    closeAndResetDrawer = (keepContent?: boolean) => {
        this.closeDrawer();
        this.reset(keepContent);
    };

    @action
    openDrawer = () => {
        this.isOpen = Boolean(this.contentInjector);
    };

    @action
    restoreDefaults() {
        this.anchor = "right";
        this.variant = "permanent";
        this.collapseSize = "full";
        this.offsetPx = 64;
        this.offsetPosition = "Top";
        this.customRootStyles = undefined;
        this.transitionDuration = undefined;
    }
}
