import { action, makeObservable, observable, reaction } from "mobx";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import { CombinatorRuleType } from "models/RuleCombinator";

export interface IStandardClassifierGroup {
    filterGroupId: number;
    classifierRules: IStandardClassifierRule[];
}

export interface IStandardClassifierRule {
    value: "Contains" | "Does not contain" | "Starts with";
    searchString: string;
    filterGroupId: number;
    saidWithin?: number;
}

@AcxStore
export default class StandardClassifierBuilderStore extends BaseStore {
    @observable
    onQueryStringChange: (queryString: string) => void;

    @observable
    classifierGroups: IStandardClassifierGroup[] = [];

    @observable
    queryString: string = "";

    @observable
    hasError: boolean = false;

    constructor() {
        super("StandardClassifierBuilderStore");
        makeObservable(this);

        reaction(
            () => ({
                searchString: this.queryString,
            }),
            () => {
                this.onQueryStringChange(this.queryString);
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                items: this.classifierGroups.length,
            }),
            () => {
                const newSearchString = this.getStringFromGroups();
                this.queryString = newSearchString;
                this.onQueryStringChange(newSearchString);
            },
            { fireImmediately: true },
        );
    }

    @action
    setRuleSearchString = (
        index: number,
        groupIndex: number,
        searchString: string,
    ) => {
        this.classifierGroups[groupIndex].classifierRules[index].searchString =
            searchString;
        // need to rerender here to ensure reaction runs
        this.classifierGroups = [...this.classifierGroups];
    };

    @action
    setRuleSaidWithin = (
        index: number,
        groupIndex: number,
        saidWithinCount: number,
    ) => {
        this.classifierGroups[groupIndex].classifierRules[index].saidWithin =
            saidWithinCount;
        // need to rerender here to ensure reaction runs
        this.classifierGroups = [...this.classifierGroups];
    };

    @action
    updateRBCItemContains = (
        index: number,
        groupIndex: number,
        value: IStandardClassifierRule["value"],
    ) => {
        this.classifierGroups[groupIndex].classifierRules[index].value = value;
        // need to rerender here to ensure reaction runs
        this.classifierGroups = [...this.classifierGroups];
    };

    @action
    addClassifierBuilderGroup = (combinator: CombinatorRuleType) => {
        const newItem: IStandardClassifierGroup = {
            filterGroupId: 0,
            classifierRules: [
                {
                    value: "Contains",
                    searchString: "",
                    filterGroupId: 0,
                },
            ],
        };

        if (this.classifierGroups.length) {
            const lastItemFilterGroupId =
                this.classifierGroups[this.classifierGroups.length - 1]
                    ?.filterGroupId;

            newItem.filterGroupId =
                combinator === CombinatorRuleType.And
                    ? lastItemFilterGroupId
                    : lastItemFilterGroupId + 1;
        }

        this.classifierGroups.push(newItem);
    };

    @action
    addClassifierBuilderRule = (
        combinator: CombinatorRuleType,
        groupIndex: number,
    ) => {
        const newItem: IStandardClassifierRule = {
            value: "Contains",
            filterGroupId: 0,
            searchString: "",
        };

        const currentGroup = this.classifierGroups[groupIndex];

        const currentClassifierRulesList = currentGroup.classifierRules;

        if (currentClassifierRulesList.length) {
            const lastItemFilterGroupId =
                currentClassifierRulesList[
                    currentClassifierRulesList.length - 1
                ]?.filterGroupId;

            newItem.filterGroupId =
                combinator === CombinatorRuleType.And
                    ? lastItemFilterGroupId
                    : lastItemFilterGroupId + 1;
        }

        this.classifierGroups[groupIndex].classifierRules.push(newItem);
    };

    @action
    removeItem = (index: number, groupIndex: number) => {
        this.classifierGroups[groupIndex].classifierRules =
            this.classifierGroups[groupIndex].classifierRules.filter(
                (item, i) => i !== index,
            );

        // remove groups with no rules left
        this.classifierGroups = [
            ...this.classifierGroups.filter(
                (group) => group.classifierRules.length,
            ),
        ];
    };

    @action
    changeGroupCombinator = (groupIndex: number, input: CombinatorRuleType) => {
        const thisItem = this.classifierGroups[groupIndex];
        const nextItem = this.classifierGroups[groupIndex + 1];
        const itemCount = this.classifierGroups.length;
        const newFilterId =
            input === CombinatorRuleType.Or
                ? thisItem.filterGroupId + 1
                : thisItem.filterGroupId;

        // thisItem.combinator = input;
        // nextItem.combinator = input;
        nextItem.filterGroupId = newFilterId;

        // For all items after changed item
        for (let i = groupIndex + 2; i < itemCount; i++) {
            const currentItem = this.classifierGroups[i];
            if (input === CombinatorRuleType.And) {
                currentItem.filterGroupId--;
            } else {
                currentItem.filterGroupId++;
            }
        }
        // need to rerender here to ensure reaction runs
        this.classifierGroups = [...this.classifierGroups];
    };

    @action
    changeRuleCombinator = (
        index: number,
        groupIndex: number,
        input: CombinatorRuleType,
    ) => {
        const thisItem =
            this.classifierGroups[groupIndex].classifierRules[index];
        const nextItem =
            this.classifierGroups[groupIndex].classifierRules[index + 1];
        const itemCount =
            this.classifierGroups[groupIndex].classifierRules.length;

        const newFilterId =
            input === CombinatorRuleType.Or
                ? thisItem.filterGroupId + 1
                : thisItem.filterGroupId;

        // thisItem.combinator = input;
        // nextItem.combinator = input;
        nextItem.filterGroupId = newFilterId;

        // For all items after changed item
        for (let i = groupIndex + 2; i < itemCount; i++) {
            const currentItem =
                this.classifierGroups[groupIndex].classifierRules[i];
            if (input === CombinatorRuleType.And) {
                currentItem.filterGroupId--;
            } else {
                currentItem.filterGroupId++;
            }
        }
        // need to rerender here to ensure reaction runs
        this.classifierGroups = [...this.classifierGroups];
    };

    @action
    reset = () => {
        this.classifierGroups = [];
    };

    getStringFromRules(classifierRules: IStandardClassifierRule[]): string {
        return classifierRules
            .map((item, index) => {
                let formattedPhrase = item.searchString?.trim() || "";

                if (formattedPhrase) {
                    if (formattedPhrase.includes(" ")) {
                        formattedPhrase = `"${formattedPhrase}"`;
                    }
                    if (item.value === "Starts with") {
                        formattedPhrase = `${formattedPhrase}*`;
                    } else if (item.value === "Does not contain") {
                        formattedPhrase = `!${formattedPhrase}`;
                    }
                    if (item.saidWithin) {
                        formattedPhrase = `${formattedPhrase}~${item.saidWithin}`;
                    }
                    // basic error checking
                    if (item.saidWithin) {
                        if (
                            item.value !== "Contains" ||
                            !item.searchString.includes(" ") ||
                            item.saidWithin < 0 ||
                            item.saidWithin > 15
                        )
                            this.hasError = true;
                    }
                }

                let combinatorSymbol = "";

                if (index !== 0) {
                    const prevItemFilterGroupId =
                        classifierRules[index - 1].filterGroupId;

                    combinatorSymbol =
                        item.filterGroupId === prevItemFilterGroupId
                            ? " & "
                            : " | ";
                }

                return combinatorSymbol + formattedPhrase;
            })
            .join("");
    }

    getStringFromGroups() {
        this.hasError = false;
        return this.classifierGroups
            .map((group, index) => {
                let comboString = "";
                // if we arent at first item, determine combo
                if (index !== 0) {
                    const curId = group.filterGroupId;
                    const prevId =
                        this.classifierGroups[index - 1].filterGroupId;
                    comboString = curId === prevId ? " & " : " | ";
                }
                const ruleString = this.getStringFromRules(
                    group.classifierRules,
                );
                if (this.classifierGroups.length === 1) {
                    return comboString + ruleString;
                } else {
                    if (ruleString.includes("|") || ruleString.includes("&")) {
                        return comboString + "(" + ruleString + ")";
                    } else {
                        return comboString + ruleString;
                    }
                }
            })
            .join("");
    }
}
