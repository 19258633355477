import { Grid, Tooltip } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { SamplingTargetFormData } from "components/Admin/Organizations/types/SamplingTargetFormData.type";
import { SamplingTargetTimePeriod } from "components/Admin/Organizations/types/SamplingTargetTimePeriod";
import { SamplingTargetUnitOfMeasure } from "components/Admin/Organizations/types/SamplingTargetUnitOfMeasure";
import { Tier } from "components/Admin/Organizations/types/Tier.type";
import AcxInputLabel from "components/UI/AcxInputLabel";
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import { OrganizationServiceHierarchyStore } from "../stores/OrganizationServiceHierarchyStore";

interface SamplingTargetLabeledMetricsProps {
    tier: Tier;
    osmStore: OrganizationServiceHierarchyStore;
}

const styles = () => {
    return createStyles({
        monitoredByUser: {
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: "15px",
            whiteSpace: "nowrap",
            lineHeight: "100%",
        },
        labelContainer: {
            padding: "0.25rem",
        },
    });
};

const SamplingTargetLabeledMetrics: React.FC<React.PropsWithChildren<React.PropsWithChildren<SamplingTargetLabeledMetricsProps>>> =
    observer(({ tier, osmStore }) => {
        const classes = useStyles(styles);
        const samplingTarget = tier.samplingTarget;

        const formattedSamplingTarget: SamplingTargetFormData = {
            id: tier.id,
            timePeriod: samplingTarget?.timePeriod
                ? SamplingTargetTimePeriod[samplingTarget.timePeriod]
                : "",
            unitOfMeasure: samplingTarget?.unitOfMeasure
                ? SamplingTargetUnitOfMeasure[samplingTarget.unitOfMeasure]
                : "",
            value: samplingTarget?.value ? samplingTarget.value : 0,
            monitoredByUserName: "",
        };

        const monitoredByUserName = osmStore.orgUsers.find(
            (user) => user.id === tier?.samplingTarget?.monitoredByUserId,
        )?.userName;

        const unitOfMeasureLabel = `${formattedSamplingTarget?.value}${
            formattedSamplingTarget?.unitOfMeasure === "Percentage"
                ? "% of"
                : ""
        } calls ${formattedSamplingTarget?.timePeriod}`;

        return (
            <>
                {formattedSamplingTarget?.value ? (
                    <>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className={classes.labelContainer}
                        >
                            <AcxInputLabel labelText="Sampling Target" />

                            <Tooltip title={unitOfMeasureLabel ?? ""}>
                                <Grid className={classes.monitoredByUser}>
                                    {unitOfMeasureLabel}
                                </Grid>
                            </Tooltip>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className={classes.labelContainer}
                        >
                            <AcxInputLabel labelText="Monitored By" />

                            <Tooltip title={monitoredByUserName ?? ""}>
                                <Grid className={classes.monitoredByUser}>
                                    {monitoredByUserName}
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className={classes.labelContainer}
                        >
                            <AcxInputLabel labelText="Sampling Target" />
                            N/A
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            lg={6}
                            className={classes.labelContainer}
                        >
                            <AcxInputLabel labelText="Monitored By" />
                            N/A
                        </Grid>
                    </>
                )}
            </>
        );
    });

export default SamplingTargetLabeledMetrics;
