import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Blurb } from "./Blurb";
import { getSpeakerColorByChannelOrPersona } from "Theme/utils";

interface UseBlurbBoxStylesProps {
    blurb: Blurb;
    speakerNameDisabled?: boolean;
}

export const useStyles = makeStyles<Theme, UseBlurbBoxStylesProps>((theme) => ({
    container: {
        position: "relative",
        padding: theme.spacing(2.5, 0),
        "&:hover": {
            backgroundColor: "#F9FAFB",
            "& $hiddenActions": {
                visibility: "visible",
            },
        },
    },
    title: {
        width: "100px",
        minWidth: "100px",
    },
    channel: (props) => ({
        alignItems: "center",
        fontWeight: "bold",
        color: getSpeakerColorByChannelOrPersona(props.blurb.channel),
        padding: theme.spacing(0.25),
        paddingRight: "4px",
        paddingLeft: "4px",
        "&:hover": props.speakerNameDisabled
            ? {}
            : {
                  border: `2px ${theme.palette.gray[200]} solid`,
                  paddingLeft: "2px",
                  cursor: "default",
                  borderRadius: theme.shape.borderRadius,
                  backgroundColor: theme.palette.white.main,
              },
        "& .hidden-edit-icon": {
            visibility: "hidden",
        },
        "&:hover .hidden-edit-icon": {
            visibility: props.speakerNameDisabled ? "hidden" : "visible",
        },
    }),

    separator: {
        paddingLeft: "4px",
        paddingRight: "4px",
        color: "gray",
    },
    dateTime: {
        color: "gray",
        padding: theme.spacing(0.25),
        paddingLeft: "4px",
    },
    content: (props) => ({
        paddingRight: "90px",
        paddingLeft: theme.spacing(1),
        borderLeft: props.blurb.foundEddy
            ? `2px solid ${theme.palette.blue.main}`
            : "",
    }),
    hiddenActions: {
        position: "absolute",
        right: "8px",
        top: "20px",
        display: "flex",
        visibility: "hidden",
    },
    speakerContainer: {
        cursor: "default",
        padding: theme.spacing(1),
        "&:hover": {
            backgroundColor: theme.palette.secondary[50],
        },
    },
}));

interface UseOverrideStylesProps {
    speakerNameDisabled?: boolean;
}

export const useOverrideStyles = makeStyles<Theme, UseOverrideStylesProps>(
    (theme) => ({
        selected: () => ({
            border: `2px ${theme.palette.gray[200]} solid`,
            paddingLeft: "2px",
            cursor: "default",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.white.main,
            "& .hidden-edit-icon": {
                visibility: "visible",
            },
        }),
    }),
);
