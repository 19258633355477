import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import SideControlsPage from "../../Layouts/SideControls/SideControlsPage";
import { useStore } from "../../utils/useStore";
import { ManagerInteractionsDashboardStore } from "./Stores/ManagerInteractionsDashboardStore";
import ManagerInteractionNavigation from "./Views/ManagerInteractionNavigation";
import ManagerInteractionRoutes from "./Views/ManagerInteractionRoutes";

const useStyles = makeStyles((theme: Theme) => ({
    mainContent: {},
}));

interface OwnProps {}

type Props = OwnProps;

const ManagerInteractions: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles();
    const dashboardStore = useStore(ManagerInteractionsDashboardStore);

    return (
        <SideControlsPage
            mainContent={<ManagerInteractionRoutes />}
            enableMinimize={!dashboardStore.isEditMode}
            drawerLocation={"left"}
            drawerContent={<ManagerInteractionNavigation />}
            mainContentClasses={classes.mainContent}
            mainGridSpacing={0}
            mainGridJustify={"flex-start"}
            mainGridAlignItems={"stretch"}
            containerOffsetPixels={64}
            drawerVerticalOffsetPixels={0}
        />
    );
});

export default ManagerInteractions;
