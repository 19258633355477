import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { ReportGroup } from "models/Reporting/ReportGroup";
import { ReportModel, VizType } from "models/Reporting/ReportModel";
import moment from "moment/moment";
import { User } from "oidc-client";
import React from "react";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { ApplicationUser } from "../../../models/Permission/ApplicationUser";
import { ReportAccessType } from "../../../models/Reporting/ReportAccessControls";
import {
    ReportDataField,
    ReportDataFieldVariation,
    ReportFieldDataTypes,
} from "../../../models/Reporting/ReportDataField";
import { ReportDataView } from "../../../models/Reporting/ReportDataView";
import { FieldUse } from "../../../models/Reporting/ReportField";
import { CombinatorFilterType } from "../../../models/Reporting/ReportFilter";
import { OrganizationService } from "../../../services/OrganizationService";
import { ReportsService } from "../../../services/ReportsService";
import { UserService } from "../../../services/UserService";
import { AuthStore } from "../../../stores/AuthStore";
import { AsyncTaskStatus, BaseStore } from "../../../stores/BaseStore";
import { LayoutDrawerStore } from "../../../stores/Layout/LayoutDrawerStore";
import { serializeToUtc } from "../../../utils/DateTimeUtils";
import MessageStore from "../../ManagerInteractions/Stores/MessageStore";
import { QueryFilterOptions, QuickFilterOptions } from "../Editor/Views/Editor";
import ReportAccessControlList from "../Editor/Views/ReportAccessControlList";

export interface OrderedItem {
    order: number;
}

@AcxStore
export class ReportEditorStore extends BaseStore {
    private readonly reportService: ReportsService = new ReportsService();
    private readonly userService: UserService = new UserService();
    private readonly orgService: OrganizationService =
        new OrganizationService();

    @observable currentReport?: ReportModel;
    @observable organizationId?: string;
    @observable reportId?: string;
    @observable reportDataViews?: ReportDataView[];

    @observable queryFilterOption: QueryFilterOptions = QueryFilterOptions.None;
    @observable quickFilterOption: QuickFilterOptions = QuickFilterOptions.STD;

    @observable globalFilterCombinator: CombinatorFilterType =
        CombinatorFilterType.AND;

    @observable currentReportDataFields?: ReportDataField[];
    @observable currentReportDataView?: ReportDataView;

    @observable reportGroupOptions: ReportGroup[] = [];
    @observable createGroupOpen: boolean = false;
    @observable newGroupName: string;

    private invalidReportMessage: string | undefined;
    private unmodifiedReportName: string | undefined;

    @observable user: User | null = null;

    @observable.ref orgUsers: ApplicationUser[] = [];
    @observable.ref orgHierarchies: { label: string; id: string }[] = [];

    @observable isNew = true;
    @observable isEditable = false;

    constructor(private rootStore: IRootStore) {
        super("ReportEditor Store");

        makeObservable(this);

        reaction(
            (r) => ({
                orgId: this.organizationId,
                activeLocation: this.rootStore.activeLocation,
            }),
            async (args) => {
                if (
                    args.activeLocation &&
                    !args.activeLocation.location.includes("report")
                ) {
                    return;
                }

                if (args.orgId) {
                    this.setupAsyncTask("Access Control Details", async () => {
                        this.orgUsers = await this.userService.getUserNames(
                            args.orgId as string,
                        );
                        this.orgHierarchies =
                            await this.orgService.getFlattenedServiceHierarchy(
                                args.orgId as string,
                            );
                    });
                }
            },
            { delay: 1000, fireImmediately: true },
        );

        reaction(
            (r) => ({
                report: this.currentReport,
                dataviews: this.reportDataViews,
            }),
            (arg) => {
                if (arg.report?.id) {
                    const reportDataView = arg.report.reportSourceDataId;
                    const currentDataView = arg.dataviews?.find(
                        (d) => d.id === reportDataView,
                    );
                    if (currentDataView) {
                        this.setCurrentReportDataView(currentDataView);
                    }
                }
            },
            { delay: 0, fireImmediately: true },
        );

        reaction(
            (r) => this.nextTaskError,
            (arg) => {
                if (arg && arg.message) {
                    this.rootStore.getStore(MessageStore).logError(arg.message);
                    this.clearLastTaskError();
                }
            },
            { delay: 0 },
        );
    }

    @computed
    get availableTableReportDataFields() {
        return this.currentReportDataFields
            ?.filter(
                (value) =>
                    value.fieldName !== "QuestionText" &&
                    value.fieldName !== "TagParent" &&
                    value.fieldName !== "Value",
            )
            .slice()
            .map((value) => ({ ...value, order: 0 }))
            .sort((a, b) =>
                (a.displayName?.trim() || a.fieldName) >
                (b.displayName?.trim() || b.fieldName)
                    ? 1
                    : -1,
            ) as (ReportDataField & OrderedItem)[];
    }

    @computed
    get availableReportQuickFilterFields() {
        const isTableViz = this.currentReport?.vizType === VizType.Table;
        let quickFilterFields = this.currentReportDataFields
            ?.filter(
                (f) =>
                    f.datatype !== ReportFieldDataTypes.Guid &&
                    f.datatype !== ReportFieldDataTypes.Date &&
                    f.datatype !== ReportFieldDataTypes.Text &&
                    f.datatype !== ReportFieldDataTypes.Number &&
                    f.fieldName !== "TagParent",
            )
            ?.filter((value) =>
                isTableViz
                    ? value.fieldName !== "Value"
                    : value.variation !== ReportDataFieldVariation.Virtual,
            );
        return quickFilterFields
            ?.slice()
            .map((value) => ({ ...value, order: 0 }))
            .sort((a, b) =>
                (a.displayName?.trim() || a.fieldName) >
                (b.displayName?.trim() || b.fieldName)
                    ? 1
                    : -1,
            ) as (ReportDataField & OrderedItem)[];
    }

    @computed
    get availableReportQueryFilterFields() {
        const isTableViz = this.currentReport?.vizType === VizType.Table;
        let queryFilterFields = this.currentReportDataFields
            ?.filter(
                (f) =>
                    f.datatype !== ReportFieldDataTypes.Guid &&
                    f.datatype !== ReportFieldDataTypes.Date,
            )
            ?.filter((value) => value.fieldName !== "TagParent")
            ?.filter((value) =>
                isTableViz
                    ? value.fieldName !== "Value"
                    : value.variation !== ReportDataFieldVariation.Virtual,
            );
        return queryFilterFields
            ?.slice()
            .sort((a, b) =>
                (a.displayName?.trim() || a.fieldName) >
                (b.displayName?.trim() || b.fieldName)
                    ? 1
                    : -1,
            ) as (ReportDataField & OrderedItem)[];
    }

    @computed
    get availableReportAxisFields() {
        return this.currentReportDataFields
            ?.filter(
                (value) => value.variation !== ReportDataFieldVariation.Virtual,
            )
            ?.filter(
                (value) =>
                    value.value !== "TagParent" &&
                    value.value !== "AnswerNote" &&
                    value.value !== "InteractionSummary",
            )
            .slice()
            .sort((a, b) =>
                (a.displayName?.trim() || a.fieldName) >
                (b.displayName?.trim() || b.fieldName)
                    ? 1
                    : -1,
            );
    }

    @action
    setQueryFilterOption = (option?: QueryFilterOptions) => {
        this.queryFilterOption = option ?? QueryFilterOptions.None;
    };

    @action
    setQuickFilterOption = (option?: QuickFilterOptions) => {
        this.quickFilterOption = option ?? QuickFilterOptions.STD;
    };

    @action
    public initializeFromReportStore(
        user: User,
        orgId?: string,
        reportId?: string,
    ) {
        this.organizationId = orgId;
        this.user = user;

        this.setupAsyncTask("Load Report Group Options", () =>
            this.loadReportGroupOptions(orgId),
        );
        this.setupAsyncTask("Load Report Data Views", () =>
            this.getReportDataViews(orgId),
        );

        if (reportId) {
            this.isNew = false;
            this.setupAsyncTask(`Load report`, () =>
                this.loadExistingReport(reportId),
            );

            reaction(
                (r) => ({
                    hierarchies:
                        this.rootStore.getStore(AuthStore).permStore
                            .hierarchyMembers,
                    report: this.currentReport,
                }),
                (arg, prev, r) => {
                    if (arg.hierarchies && arg.report) {
                        this.isEditable = arg.report.canEdit(
                            user.profile.sub,
                            arg.hierarchies,
                        );

                        r.dispose();
                    }
                },
                { fireImmediately: true },
            );
        } else {
            this.isNew = true;
            this.initNewReport(user, orgId);
        }
    }

    @action
    onOpenAccessControls = (accessType: ReportAccessType) => {
        if (this.currentReport) {
            const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
            drawerStore.closeAndResetDrawer();
            drawerStore.restoreDefaults();

            let title;
            if (accessType === ReportAccessType.Edit) {
                title = "Edit Access Controls";
            } else {
                title = "View Access Controls";
            }

            drawerStore.setContentFactory(() => (
                <ReportAccessControlList
                    title={title}
                    store={this}
                    accessType={accessType}
                />
            ));

            drawerStore.setOpen(true);
        }
    };

    @action
    reset = () => {
        this.unmodifiedReportName = undefined;
        this.currentReport = undefined;
        this.organizationId = undefined;
        this.globalFilterCombinator = CombinatorFilterType.AND;
        this.currentReportDataFields = undefined;
        this.currentReportDataView = undefined;
        this.quickFilterOption = QuickFilterOptions.STD;
        this.queryFilterOption = QueryFilterOptions.None;
    };

    @action
    private initNewReport(user: User, orgId?: string) {
        const report = new ReportModel(
            undefined,
            user.profile.email,
            user.profile.email,
            orgId,
        );
        report.creatorId = user.profile.sub;
        this.currentReport = report;
    }

    @action
    private async loadExistingReport(reportId: string) {
        const report = ReportModel.fromJson(
            await this.reportService.getReport(reportId),
        );
        this.unmodifiedReportName = report.name;
        runInAction(() => {
            const globalCombinator = report.reportFields
                .filter((value) => value.fieldUse === FieldUse.Filter)
                .flatMap((value) => value.reportFilters)?.[0]?.combinator;

            this.globalFilterCombinator =
                globalCombinator ?? CombinatorFilterType.AND;

            this.queryFilterOption =
                (report.reportFields?.filter(
                    (value) =>
                        value.isActive && value.fieldUse === FieldUse.Filter,
                )?.length ?? 0) > 0
                    ? QueryFilterOptions.Custom
                    : QueryFilterOptions.None;

            this.quickFilterOption =
                (report.reportFields?.filter(
                    (value) =>
                        value.isActive &&
                        value.fieldUse === FieldUse.QuickFilter,
                )?.length ?? 0) > 0
                    ? QuickFilterOptions.Custom
                    : QuickFilterOptions.STD;

            this.currentReport = report;
        });
    }

    @action
    private async getReportDataViews(orgId?: string) {
        const res = await this.reportService.getReportDataViews(orgId);
        runInAction(() => {
            this.reportDataViews = res;
        });
    }

    @action
    private loadReportGroupOptions = async (orgId?: string) => {
        const res = await this.reportService.getReportGroups(orgId);
        runInAction(() => {
            this.reportGroupOptions = res;
        });
    };
    @action
    initCreateGroup = async (val) => {
        this.newGroupName = val;
        this.createGroupOpen = true;
    };
    @action
    saveNewGroup = async (obj: ReportGroup) => {
        const grps = await this.reportService.createGroup(obj);
        this.reportGroupOptions = grps;
    };

    @action
    setCurrentReportDataView = (dataView: ReportDataView) => {
        if (
            dataView.id !== this.currentReportDataView?.id &&
            this.currentReportDataView?.id
        ) {
            if (this.currentReport) {
                this.currentReport.reportFields = [];
            }
        }
        this.currentReportDataView = dataView;
        this.currentReportDataFields = dataView.reportDataFields;
        if (this.currentReport) {
            this.currentReport.reportSourceDataId = dataView.id;
        }
    };

    @action
    setGlobalFilterCombinator = (arg: {
        id: CombinatorFilterType;
        value: string;
    }) => {
        this.globalFilterCombinator = arg.id;
    };

    @action saveReportAs = async () => {
        try {
            return await this.setupAsyncTask(`Save Report As`, () =>
                this.saveReportInternal(true),
            ).then(async (value) => {
                if (value && value !== AsyncTaskStatus.Error) {
                    setTimeout(() => this.reset(), 500);
                }
                return value && value !== AsyncTaskStatus.Error;
            });
        } catch (err) {
            return false;
        }
    };

    @action saveReport = async () => {
        try {
            return await this.setupAsyncTask(`Save Report`, () =>
                this.saveReportInternal(),
            ).then(async (value) => {
                if (value && value !== AsyncTaskStatus.Error) {
                    setTimeout(() => this.reset(), 500);
                }
                return value && value !== AsyncTaskStatus.Error;
            });
        } catch (err) {
            return false;
        }
    };

    @action
    private validateReportModel(
        reportModel: ReportModel,
        isSaveAs: boolean | undefined,
    ) {
        this.invalidReportMessage = undefined;

        if (isSaveAs && this.unmodifiedReportName === reportModel.name) {
            this.invalidReportMessage = `Save-As requires a different report name than the original report`;
            return;
        }

        if (!reportModel.groupId) {
            this.invalidReportMessage = `Report group must be chosen`;
        } else if (!reportModel.name) {
            this.invalidReportMessage = `Report name is empty`;
        } else if (reportModel.vizType === undefined) {
            this.invalidReportMessage = `Report type must be chosen`;
        } else if (!reportModel.reportSourceDataId) {
            this.invalidReportMessage = `Report data source must be chosen`;
        } else {
            switch (reportModel.vizType) {
                case VizType.Bar:
                case VizType.Line:
                case VizType.Histogram:
                case VizType.HorizontalBar:
                    const xField = reportModel.reportFields.find(
                        (value) =>
                            value.fieldUse === FieldUse.X && value.isActive,
                    );
                    const yField = reportModel.reportFields.find(
                        (value) =>
                            value.fieldUse === FieldUse.Y && value.isActive,
                    );

                    if (!xField) {
                        this.invalidReportMessage = `Report missing X axis`;
                    } else if (!yField) {
                        this.invalidReportMessage = `Report missing Y axis`;
                    }

                    if (
                        xField?.reportDataField?.datatype ===
                        ReportFieldDataTypes.Date
                    ) {
                        // requires Date groupby selected
                        if (xField.dateAggregation === undefined) {
                            this.invalidReportMessage = `X Axis field ${
                                xField.displayName?.trim() ||
                                xField.name?.trim() ||
                                xField.reportDataField.fieldName?.trim() ||
                                ""
                            } of type Date require a Date grouping`;
                        }
                    }

                    // if (yField?.reportDataField?.datatype === ReportFieldDataTypes.ScoreAggregation) {
                    // requires aggregate function selected
                    if (yField?.aggregateFunction === undefined) {
                        this.invalidReportMessage = `Y Axis field ${
                            yField?.displayName?.trim() ||
                            yField?.name?.trim() ||
                            yField?.reportDataField?.fieldName?.trim() ||
                            ""
                        } requires an aggregate function`;
                    }
                    // }

                    break;
                case VizType.Pie:
                    const vizGrouping = reportModel.reportFields.find(
                        (value) =>
                            value.fieldUse === FieldUse.VizGroup &&
                            value.isActive,
                    );
                    if (vizGrouping !== undefined) {
                        this.invalidReportMessage = `Pie graphs do not support series grouping`;
                    }
                    break;
                case VizType.Table:
                    const tableFields = reportModel.reportFields.filter(
                        (value) =>
                            (value.fieldUse === FieldUse.X ||
                                value.fieldUse === FieldUse.VirtualScored ||
                                value.fieldUse === FieldUse.VirtualValue) &&
                            value.isActive,
                    );
                    for (const tableField of tableFields) {
                        if (
                            tableField.reportDataField?.datatype ===
                                ReportFieldDataTypes.Number &&
                            tableField.reportDataField.variation ===
                                ReportDataFieldVariation.Virtual
                        ) {
                            if (tableField.aggregateFunction === undefined) {
                                this.invalidReportMessage = `table field ${
                                    tableField.displayName?.trim() ||
                                    tableField.name?.trim() ||
                                    tableField.reportDataField?.fieldName?.trim() ||
                                    ""
                                }  requires an aggregate function`;
                            }
                        } else if (
                            tableField.reportDataField?.datatype ===
                            ReportFieldDataTypes.Date
                        ) {
                            if (tableField.dateAggregation === undefined) {
                                this.invalidReportMessage = `table field ${
                                    tableField.displayName?.trim() ||
                                    tableField.name?.trim() ||
                                    tableField.reportDataField.fieldName?.trim() ||
                                    ""
                                } of type Date require a Date grouping`;
                            }
                        }
                    }

                    break;
            }
        }

        if (this.queryFilterOption !== QueryFilterOptions.None) {
            const reportFilters = reportModel.reportFields.filter(
                (value) => value.fieldUse === FieldUse.Filter && value.isActive,
            );
            for (const reportFieldFilter of reportFilters) {
                if (!reportFieldFilter?.reportDataField) {
                    // added filter without selecting reportDataField
                    this.invalidReportMessage = `All Report Filers require a Data Field to be chosen`;
                }

                if (
                    reportFieldFilter?.reportFilters?.[0]?.comparator ===
                    undefined
                ) {
                    // filters require comparator
                    this.invalidReportMessage = `All Report Filers require a comparator operator to be chosen`;
                }
                if (!Boolean(reportFieldFilter?.reportFilters?.[0]?.value)) {
                    // filters require non-empty value
                    this.invalidReportMessage = `All Report Filers require a non-empty value`;
                }
            }
        }
    }

    @action saveReportInternal = async (isSaveAs?: boolean) => {
        if (this.currentReport) {
            const report = toJS(this.currentReport);

            this.validateReportModel(report, isSaveAs);

            if (this.invalidReportMessage) {
                throw new Error(
                    `Invalid report definition: ${this.invalidReportMessage}`,
                );
            }

            this.prepareForSerialization(report, isSaveAs);

            // @ts-ignore
            report.group = undefined;
            report.dataView = undefined;
            report.organization = undefined;

            if (report.id && !isSaveAs) {
                await this.reportService.updateReport(
                    report,
                    this.organizationId,
                );
            } else {
                await this.reportService.createReport(
                    report,
                    this.organizationId,
                );
            }

            return true;
        }
    };

    private prepareForSerialization(report: ReportModel, isSaveAs?: boolean) {
        report.userAccessControls = report.userAccessControls.filter(
            (value) => value.isActive || value.id,
        );
        report.groupAccessControls = report.groupAccessControls.filter(
            (value) => value.isActive || value.id,
        );

        if (isSaveAs) {
            // @ts-ignore
            report.id = undefined;
            report.createdOn = report.modifiedOn = serializeToUtc(
                moment().local(),
            );
            report.createdBy = report.modifiedBy = this.user?.profile.email;

            report.userAccessControls = report.userAccessControls.filter(
                (value) => value.userId !== this.user?.profile.sub,
            );

            report.userAccessControls.forEach((value) => {
                // @ts-ignore
                delete value.reportId;
                // @ts-ignore
                delete value.id;
            });

            report.groupAccessControls.forEach((value) => {
                // @ts-ignore
                delete value.reportId;
                // @ts-ignore
                delete value.id;
            });
        }

        for (let i = 0; i < report.reportFields.length; i++) {
            const reportField = report.reportFields[i];

            if (isSaveAs) {
                // @ts-ignore
                reportField.reportId = undefined;
            }

            if (
                reportField.fieldUse === FieldUse.X ||
                reportField.fieldUse === FieldUse.VirtualValue ||
                reportField.fieldUse === FieldUse.VirtualScored
            ) {
                if (
                    reportField.fieldUse === FieldUse.VirtualValue ||
                    reportField.fieldUse === FieldUse.VirtualScored
                ) {
                    delete reportField.reportDataField;
                    delete reportField.reportDataFieldId;
                }
            }

            if (reportField.id?.startsWith("local-") || isSaveAs) {
                // @ts-ignore
                delete reportField.id;
            }

            if (reportField.fieldUse === FieldUse.QuickFilter) {
                if (this.quickFilterOption === QuickFilterOptions.STD) {
                    if (
                        reportField.id &&
                        !reportField.id.startsWith("local-") &&
                        !isSaveAs
                    ) {
                        reportField.isActive = false;
                    } else {
                        // @ts-ignore
                        report.reportFields[i] = undefined;
                    }
                }
            }

            if (
                reportField.fieldUse === FieldUse.Filter &&
                (!reportField.reportFilters ||
                    reportField.reportFilters.length === 0)
            ) {
                // @ts-ignore
                report.reportFields[i] = undefined;
            }

            for (let j = 0; j < (reportField.reportFilters ?? []).length; j++) {
                if (
                    reportField.reportDataField?.variation ===
                    ReportDataFieldVariation.Virtual
                ) {
                    delete reportField.reportDataFieldId;
                }

                let reportFilter = reportField.reportFilters[j];

                delete reportFilter.reportField;

                if (reportFilter.id?.startsWith("local-") || isSaveAs) {
                    // @ts-ignore
                    delete reportFilter.id;
                }
                if (
                    reportFilter.reportFieldId?.startsWith("local-") ||
                    isSaveAs
                ) {
                    delete reportFilter.reportFieldId;
                }

                if (reportField.fieldUse === FieldUse.Filter) {
                    reportFilter.combinator = this.globalFilterCombinator;

                    if (
                        this.queryFilterOption === QueryFilterOptions.None ||
                        reportField.isActive === false
                    ) {
                        if (
                            reportFilter.id &&
                            !reportFilter.id?.startsWith("local-") &&
                            !isSaveAs
                        ) {
                            reportFilter.isActive = false;
                        } else {
                            // @ts-ignore
                            reportField.reportFilters[j] = undefined;
                        }
                    }
                }
            }
            delete reportField.reportDataField;
        }

        report.reportFields
            .filter((value) => Boolean(value))
            .forEach((value) => {
                value.reportFilters = value.reportFilters.filter((value1) =>
                    Boolean(value1),
                );
            });

        for (let i = 0; i < report.reportFields.length; i++) {
            const field = report.reportFields[i];
            if (!field) {
                continue;
            }
            if (
                field.fieldUse === FieldUse.Filter &&
                (!field.reportFilters?.length ||
                    this.queryFilterOption === QueryFilterOptions.None)
            ) {
                if (field.id && !field.id.startsWith("local-") && !isSaveAs) {
                    field.isActive = false;
                } else {
                    // @ts-ignore
                    report.reportFields[i] = undefined;
                }
            }
        }
        report.reportFields = report.reportFields.filter((value) =>
            Boolean(value),
        );
    }
}
