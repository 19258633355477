import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ChromeTabs, { ChromeTab } from "components/UI/SubTabs/ChromeTabs";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import Requeue from "./Requeue/Requeue";
import FileTimeline from "./Timeline/FileTimeline";
import Ops from "./Ops/Ops";
import RbcToTsQuery from "./RbcToTsQuery/RbcToTsQuery";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        height: "calc(100% - 100px)",
        overflow: "hidden",
    },
}));

const PipelineToolsNav = observer(() => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState<number>();

    const tabs: Array<ChromeTab> = [
        {
            label: "Re-Queue",
            route: "/admin/pipeline/requeue",
        },
        {
            label: "Timeline",
            route: "/admin/pipeline/timeline",
        },
        {
            label: "Ops Dashboard",
            route: "/admin/pipeline/opsdashboard",
        },
        {
            label: "RBC To TSQuery",
            route: "/admin/pipeline/rbc",
        },
    ];

    const updateTab = (index: number) => {
        setActiveTab(index);
        navigate({
            pathname: tabs[index].route!,
            search: `${location.search}`,
        });
    };

    useEffect(() => {
        if (activeTab === undefined) {
            let index = tabs.findIndex((tab) =>
                tab.route?.includes(location.pathname),
            );
            updateTab(Math.max(index, 0));
        }
    });

    return (
        <>
            <Grid container>
                <Grid item container>
                    <ChromeTabs
                        tabs={tabs}
                        activeTabIndex={activeTab}
                        onChange={updateTab}
                    />
                </Grid>
            </Grid>
            <Routes>
                <Route
                    path="requeue"
                    element={
                        <>
                            <div className={classes.root}>
                                <Requeue />
                            </div>
                        </>
                    }
                />
                <Route
                    path="timeline"
                    element={
                        <>
                            <div className={classes.root}>
                                <FileTimeline />
                            </div>
                        </>
                    }
                />
                <Route
                    path="opsdashboard"
                    element={
                        <>
                            <div className={classes.root}>
                                <Ops />
                            </div>
                        </>
                    }
                />
                <Route
                    path="rbc"
                    element={
                        <>
                            <div className={classes.root}>
                                <RbcToTsQuery />
                            </div>
                        </>
                    }
                />
            </Routes>
        </>
    );
});

export default PipelineToolsNav;
