import { WidgetType } from "../../models/Dashboard/Widget";
import { EvalCoachingTableWidgetDefinition } from "../Agents/Dashboard/Views/EvalCoachingTable/EvalCoachingTableWidgetDefinition";
import { ReportWidgetDefinition } from "./Definitions/ReportWidgetDefinition";

export function getWidgetDefinition(widgetType: WidgetType) {
    switch (widgetType) {
        case WidgetType.EvaluationCoachingTable:
            return EvalCoachingTableWidgetDefinition;
        case WidgetType.Report:
            return ReportWidgetDefinition;
        default:
            throw new Error("");
    }
}
