import { debounce } from "lodash";
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import ResizeObserver from "resize-observer-polyfill";

type OnResize = (width: number, height: number) => void;

type ObserverArgs<T> = {
    onResize?: OnResize;
    ref?: MutableRefObject<T>;
    debounceWait?: number;
    pixelDelta?: number;
    name?: string;
    enableLogging?: boolean;
};

export function useResizeObserver<T extends HTMLElement>(
    opts: ObserverArgs<T> = {},
) {
    const elementRef = useRef<T | null>(null);
    const [elementWidth, setElementWidth] = useState<number | undefined>(0);
    const [elementHeight, setElementHeight] = useState<number | undefined>(0);
    const prevElementWidth = useRef<number>(0);
    const prevElementHeight = useRef<number>(0);
    const handlerRef = useRef<OnResize | undefined>(opts.onResize);
    const [resizeObserverRef, setResizeObserverInternal] = useState<
        ResizeObserver | undefined
    >();

    // const resizeObserverRef = useRef<ResizeObserver | undefined>();

    const setResizeObserver = useCallback((node: any) => {
        setResizeObserverInternal(node);
    }, []);

    useEffect(() => {
        handlerRef.current = opts.onResize;
    }, [opts.onResize]);

    const debouncedSizeUpdate = useMemo(
        () =>
            debounce(
                (width: number, height: number) => {
                    setElementWidth(width);
                    setElementHeight(height);

                    try {
                        handlerRef.current?.(width, height);
                    } finally {
                    }
                },
                opts.debounceWait ?? 10,
                { trailing: true },
            ),
        [opts.debounceWait],
    );

    const theRef = opts.ref ?? elementRef;
    useEffect(() => {
        if (!resizeObserverRef) {
            setResizeObserver(
                new ResizeObserver((entries) => {
                    if (!entries.length) {
                        return;
                    }

                    const entry = entries[0];
                    const newWidth = Math.round(entry.contentRect.width ?? 0);
                    const newHeight = Math.round(entry.contentRect.height ?? 0);

                    if (
                        Math.abs(prevElementHeight.current - newHeight) >
                            (opts.pixelDelta ?? 6) ||
                        Math.abs(prevElementWidth.current - newWidth) >
                            (opts.pixelDelta ?? 6)
                    ) {
                        if (opts.name && opts.enableLogging) {
                            console.log(
                                `Resizing ${opts.name}: W=${newWidth}, H=${newHeight}`,
                            );
                        }

                        prevElementWidth.current = newWidth;
                        prevElementHeight.current = newHeight;

                        debouncedSizeUpdate(newWidth, newHeight);
                    }
                }),
            );
        }

        return () => {
            debouncedSizeUpdate.cancel();
            resizeObserverRef?.disconnect();
            // resizeObserverRef.current = undefined;
            // debouncedSizeUpdate.cancel();
            // setResizeObserver(undefined);
        };
    }, [
        debouncedSizeUpdate,
        opts.enableLogging,
        opts.name,
        opts.pixelDelta,
        resizeObserverRef,
        setResizeObserver,
    ]);

    const currentElement = theRef.current;
    useEffect(() => {
        if (!currentElement || !(currentElement instanceof HTMLElement)) {
            return;
        }
        const element = currentElement;
        resizeObserverRef?.observe(element);

        return () => {
            resizeObserverRef?.unobserve(element);
        };
    }, [currentElement, resizeObserverRef]);

    return useMemo(
        () => ({ theRef, elementWidth, elementHeight }),
        [theRef, elementWidth, elementHeight],
    );

    // return {theRef, elementWidth, elementHeight};
}
