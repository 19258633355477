import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Stack } from "@mui/material";
import React from "react";
import { allowedDateRangeLabels } from "stores/ComponentStores/DatePickerComponentStore";

interface iCalendarSideBar {
    handleSelectedDateOptionChange?: (inputVal: any) => void;
}

const dateIntervalOptions: allowedDateRangeLabels[] = [
    "Any Time",
    "This Quarter",
    "This Month",
    "This Week",
    "Today",
    "Yesterday",
    "Last 30 Days",
];

const useStyles = makeStyles((theme: Theme) => ({
    intervalOption: {
        padding: "10px 14px",
        textWrap: "nowrap",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.lightBlue.background,
        },
    },
}));

export default function CalendarSideBar(props: iCalendarSideBar) {
    const classes = useStyles();
    return (
        <Stack>
            {dateIntervalOptions.map((option, index) => {
                return (
                    <Grid
                        container
                        key={index}
                        id="date-option"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        className={classes.intervalOption}
                        onClick={() => {
                            props.handleSelectedDateOptionChange?.(option);
                        }}
                    >
                        {" "}
                        {option}{" "}
                    </Grid>
                );
            })}
        </Stack>
    );
}
