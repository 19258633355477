import {
    CssBaseline,
    ThemeProvider,
    Theme,
    StyledEngineProvider,
} from "@mui/material";
import { Observer } from "mobx-react";
import Organization from "models/Organization";
import React, { useCallback, useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import theme from "Theme/AppTheme";
import { AuthStore } from "../../stores/AuthStore";
import { LayoutDrawerStore } from "../../stores/Layout/LayoutDrawerStore";
import { useStore } from "../../utils/useStore";
import { AnalystDashboardStore } from "../Dashboard/Analyst/Stores/AnalystDashboardStore";
import { Routes } from "../Navigation/Routes";
import AcxDrawer from "../UI/Drawer/AcxDrawer";
import { EvalStore } from "./Stores/EvalStore";
import EvalPage from "./Views/EvalPage";
import RootStore from "stores/RootStore";
import LocalStorage from "stores/LocalStorage";
import { AgentDrawer } from "components/Agent/AgentDrawer";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const EvalMain = () => {
    const store = useStore(EvalStore);
    const drawerStore = useStore(LayoutDrawerStore);
    const navigate = useNavigate();
    const navigateType = useNavigationType();
    const location = useLocation();
    const orgStore = useStore(AuthStore).orgStore;
    const local = RootStore().getStore(LocalStorage);

    useLayoutEffect(() => {
        if (location.pathname) {
            store.forceEvalReload();
        }
    }, [location.pathname, store]);

    const navBack = useCallback(() => {
        if (
            (navigateType === "POP" || navigateType === "PUSH") &&
            !location.search.includes("instanceId")
        ) {
            store.rootStore.getStore(AnalystDashboardStore).refresh();
            navigate(-1);
        } else {
            navigate(Routes.HOME, { replace: true });
        }
    }, [location.search, navigate, navigateType, store.rootStore]);

    useEffect(() => {
        const path = location.pathname;
        const segs = path.split("/");
        const orgId = segs[2];
        const evalId = segs[3];

        // Bump user to dashboard if eval orgId differs from selected orgId
        const tempLocal = local.getLocalStore();
        tempLocal.getItem<string | null>("selectedOrgID").then((id) => {
            if (id && id !== orgId) {
                orgStore?.onActiveOrgIdChange(id);
                orgStore?.setGlobalOrganization(
                    orgStore.organizations?.find(
                        (org) => org.id === id,
                    )! as Organization,
                );
                navigate(Routes.HOME, { replace: true });
            }
        });

        if (orgId && evalId) {
            //Relying on callbacks here will allow us to reject access to the eval
            //Without needing to check user hierarchy assignments, as the server will do that work and return a 403 if access is not permitted.
            //Additionally, the success callback prevents breaking errors, resulting from a global organization attempting to be set that the user
            //May not have access to.
            store.initializeEvalStore(
                evalId,
                orgId,
                () => {
                    orgStore?.onActiveOrgIdChange(orgId);
                    orgStore?.setGlobalOrganization(
                        orgStore.organizations?.find(
                            (org) => org.id === orgId,
                        )! as Organization,
                    );
                },
                (error) => {
                    if (error.response.status === 403) {
                        navigate(Routes.HOME, { replace: true });
                    }
                },
            );

            if (orgStore.organizations) {
                const userOrganizationId = orgStore.usersOrganizationId;
                const usersPrimaryOrganization = orgStore.organizations.find(
                    (organization) => organization.id === userOrganizationId,
                );
                // For Authcx users only: when navigating to a new eval belonging to another tenant,
                // Set the user's tenant to match the eval tenant using the orgId segment from the URL
                const orgFromUrl = orgStore.organizations.find(
                    (organization) => organization.id === orgId,
                );
                if (
                    userOrganizationId &&
                    usersPrimaryOrganization?.name === "Authcx"
                ) {
                    orgStore.onActiveOrgIdChange(orgId);
                    orgStore.setGlobalOrganization(orgFromUrl as Organization);
                }
            }
        } else {
            navigate(Routes.HOME);
        }
    }, [store, orgStore, location, navigate, local]);

    return (
        <Observer>
            {() => (
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <EvalPage navigateBack={navBack} />
                        <div>
                            <AgentDrawer
                                PaperProps={{ style: { marginTop: "64px" } }}
                            />
                            <AcxDrawer
                                customRootStyles={drawerStore.customRootStyles}
                                anchor={drawerStore.anchor}
                                open={drawerStore.isOpen}
                                collapsible={drawerStore.collapseSize}
                                variant={drawerStore.variant}
                                offsetPx={drawerStore.offsetPx}
                                offsetPosition={drawerStore.offsetPosition}
                                onDrawerClose={() => drawerStore.setOpen(false)}
                                drawerStore={drawerStore}
                                onDrawerOpen={() => drawerStore.setOpen(true)}
                                content={drawerStore.contentInjector?.()}
                            />
                        </div>
                    </ThemeProvider>
                </StyledEngineProvider>
            )}
        </Observer>
    );
};

export default EvalMain;
