import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { autorun } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { ApplicationUser } from "../../../../models/Permission/ApplicationUser";
import { ReportAccessType } from "../../../../models/Reporting/ReportAccessControls";
import VerticalDrawerContentTemplate from "../../../UI/Drawer/VerticalDrawerContentTemplate";
import AcxSelectMulti from "../../../UI/Select/BaseSelectComponents/AcxSelectMulti";
import { ReportEditorStore } from "../../Stores/ReportEditorStore";

const useStyles = makeStyles((theme: Theme) => ({
    bottomMargin: {
        marginBottom: theme.spacing(2.45),
    },
    contentPadding: {
        paddingLeft: theme.spacing(1.25),
        paddingRight: theme.spacing(1.25),
    },
    infoText: {
        marginBottom: theme.spacing(0.55),
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        width: "100%",
    },
    infoTitle: {
        fontWeight: "bold",
        color: theme.palette.text.primary,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
    },
}));

interface IOwnProps {
    title: string;
    store: ReportEditorStore;
    accessType: ReportAccessType;
}

const ReportAccessControlList: React.FC<React.PropsWithChildren<React.PropsWithChildren<IOwnProps>>> = observer((props) => {
    const classes = useStyles();
    const [selectedUsers, setSelectedUsers] = React.useState<ApplicationUser[]>(
        [],
    );

    const [selectedGroups, setSelectedGroups] = React.useState<
        { label: string; id: string }[]
    >([]);
    const [selectedRoles, setSelectedRoles] = React.useState<
        { name: string; id: string }[]
    >([]);
    function onUserListChange(users?: ApplicationUser[]) {
        props.store.currentReport?.changeUserACL(users ?? [], props.accessType);
    }
    function onRoleListChange(roles?: { name: string; id: string }[]) {
        props.store.currentReport?.changeRolesACL(
            roles ?? [],
            props.accessType,
        );
    }

    function onGroupListChange(groups?: { label: string; id: string }[]) {
        props.store.currentReport?.changeGroupACL(
            groups ?? [],
            props.accessType,
        );
    }

    const content = (
        <Grid
            container
            className={classes.contentPadding}
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >
            <Grid zeroMinWidth item xs={12} className={classes.bottomMargin}>
                <Typography
                    color={"textSecondary"}
                    className={classes.infoText}
                    gutterBottom
                >
                    <span className={classes.infoTitle}>Roles</span> - only the
                    selected roles will have{" "}
                    {ReportAccessType[props.accessType]} access{" "}
                </Typography>

                <AcxSelectMulti
                    options={props.store.roleList ?? []}
                    id={`role-access-selector`}
                    fullWidth
                    containerHeight={"auto"}
                    isClearable
                    isLoading={props.store.getTaskLoading(
                        "Access Control Details",
                    )}
                    valueField={"name"}
                    labelField={"name"}
                    defaultValue={selectedRoles}
                    onChange={onRoleListChange}
                />
            </Grid>
            <Grid zeroMinWidth item xs={12} className={classes.bottomMargin}>
                <Typography
                    color={"textSecondary"}
                    className={classes.infoText}
                    gutterBottom
                >
                    <span className={classes.infoTitle}>Users</span> - only the
                    selected users will have{" "}
                    {ReportAccessType[props.accessType]} access{" "}
                </Typography>

                <AcxSelectMulti
                    options={props.store.orgUsers ?? []}
                    id={`user-access-selector`}
                    fullWidth
                    containerHeight={"auto"}
                    isClearable
                    isLoading={props.store.getTaskLoading(
                        "Access Control Details",
                    )}
                    valueField={"id"}
                    labelField={"userName"}
                    defaultValue={selectedUsers}
                    onChange={onUserListChange}
                />
            </Grid>

            <Grid zeroMinWidth item xs={12} className={classes.bottomMargin}>
                <Typography
                    color={"textSecondary"}
                    className={classes.infoText}
                    gutterBottom
                >
                    <span className={classes.infoTitle}>Groups</span> - all
                    users associated with a selected service hierarchy will have{" "}
                    {ReportAccessType[props.accessType]} access
                </Typography>

                <AcxSelectMulti
                    options={props.store.orgHierarchies ?? []}
                    id={`group-access-selector`}
                    fullWidth
                    isLoading={props.store.getTaskLoading(
                        "Access Control Details",
                    )}
                    isClearable
                    containerHeight={"auto"}
                    valueField={"id"}
                    labelField={"label"}
                    defaultValue={selectedGroups}
                    onChange={onGroupListChange}
                />
            </Grid>
        </Grid>
    );

    React.useEffect(() => {
        const disposer = autorun((r) => {
            const availGroups = props.store.orgHierarchies ?? [];
            const currentGroupAcl = (
                props.store.currentReport?.groupACL ?? []
            ).filter((value) => value.accessType === props.accessType);

            setSelectedGroups(
                availGroups.filter((value) =>
                    currentGroupAcl.some(
                        (value1) =>
                            value1.organizationStructureMemberId === value.id,
                    ),
                ),
            );
            const availRoles = props.store.roleList ?? [];
            const currentRolesAcl = (
                props.store.currentReport?.rolesACL ?? []
            ).filter((value) => value.accessType === props.accessType);

            setSelectedRoles(
                availRoles.filter((value) =>
                    currentRolesAcl.some(
                        (value1) => value1.roleId === value.id,
                    ),
                ),
            );
            const availUsers = props.store.orgUsers ?? [];
            const currentUserAcl = (
                props.store.currentReport?.userACL ?? []
            ).filter((value) => value.accessType === props.accessType);

            setSelectedUsers(
                availUsers.filter((value) =>
                    currentUserAcl.some((value1) => value1.userId === value.id),
                ),
            );
        });

        return disposer;
    }, [
        props.accessType,
        props.store.currentReport?.groupACL,
        props.store.currentReport?.userACL,
        props.store.currentReport?.rolesACL,
        props.store.roleList,
        props.store.orgHierarchies,
        props.store.orgUsers,
        props.store.organizationId,
    ]);

    return (
        <VerticalDrawerContentTemplate
            width={"380px"}
            title={props.title}
            subTitle={`Give users and groups ${
                ReportAccessType[props.accessType]
            } access`}
            content={content}
        />
    );
});

export default ReportAccessControlList;
