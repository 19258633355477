import BaseEntity from "../models/BaseEntity";

export function copyBaseFields(source: BaseEntity, target: BaseEntity) {
    target.id = emptyGuidToNull(source.id) ?? undefined;
    target.modifiedBy = source.modifiedBy;
    target.createdBy = source.createdBy;
    target.modifiedOn = source.modifiedOn;
    target.createdOn = source.createdOn;
    target.isActive = source.isActive;
    target.deletedOn = source.deletedOn;
}

function emptyGuidToNull(id?: string): string {
    if (id === "00000000-0000-0000-0000-000000000000") {
        return undefined as unknown as string;
    }
    return id as string;

}