import { ButtonBase, Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from "@mui/icons-material/Add";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { v4 as uuid } from "uuid";
import { ReportField } from "../../../../models/Reporting/ReportField";
import {
    CombinatorFilterType,
    Comparator,
} from "../../../../models/Reporting/ReportFilter";
import hexToRGB from "../../../../utils/hexToRGB";
import { useStore } from "../../../../utils/useStore";
import AcxMainTextField from "../../../UI/AcxMainTextFieldGrid";
import AcxSelectSingle from "../../../UI/Select/BaseSelectComponents/AcxSelectSingle";
import { ReportEditorStore } from "../../Stores/ReportEditorStore";

const useStyles = makeStyles((theme: Theme) => ({
    addButton: {
        textTransform: "none",
        color: theme.palette.blue.main,
        marginTop: theme.spacing(3),
    },
    addButtonText: {
        color: theme.palette.blue.main,
        fontSize: "13px",
        fontWeight: theme.typography.fontWeightBold as any,
        letterSpacing: 0,
        lineHeight: "20px",
        marginLeft: theme.spacing(1),
    },
    addButtonIcon: {
        width: "16px",
        height: "16px",
        backgroundColor: hexToRGB(theme.palette.blue.main, 0.2),
        borderRadius: "50%",
    },
    removeButton: {
        fontWeight: theme.typography.fontWeightBold as any,
        textTransform: "none",
        backgroundColor: theme.palette.red.main,
        width: "17px",
        height: "17px",
        color: theme.palette.white.main,
        borderRadius: "50%",
        marginTop: theme.spacing(1.2),
        paddingBottom: "2px",
    },
    vertSpace: {
        marginTop: theme.spacing(1.5),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },

    buttonIcon: {
        width: "10px",
        height: "10px",
        marginTop: "2px",
        textAlign: "center",
    },

    globalCombinatorText: {
        fontSize: "16px",
        letterSpacing: 0,
        lineHeight: "24px",
    },

    root: {
        marginTop: theme.spacing(3),
    },
}));

const comparatorFunctions = [
    { id: Comparator.EQ, value: "Is Equal To" },
    { id: Comparator.NEQ, value: "Is Not Equal To" },
    { id: Comparator.GT, value: "Is Greater Than" },
    { id: Comparator.LT, value: "Is Less Than" },
    { id: Comparator.IN, value: "Is Member Of" },
];

const combinators = [
    { id: CombinatorFilterType.OR, value: "Any of these conditions" },
    { id: CombinatorFilterType.AND, value: "All of these conditions" },
];

function combinatorSelector(combinator?: CombinatorFilterType) {
    switch (combinator) {
        case CombinatorFilterType.AND:
            return { value: "All of these conditions", id: combinator };
        case CombinatorFilterType.OR:
            return { value: "Any of these conditions", id: combinator };
        default:
            return undefined;
    }
}

function comparatorSelector(comp?: Comparator) {
    switch (comp) {
        case Comparator.IN:
            return { value: "Is Member Of", id: comp };
        case Comparator.EQ:
            return { value: "Is Equal To", id: comp };
        case Comparator.NEQ:
            return { value: "Is Not Equal To", id: comp };
        case Comparator.GT:
            return { value: "Is Greater Than", id: comp };
        case Comparator.LT:
            return { value: "Is Less Than", id: comp };
        default:
            return undefined;
    }
}

interface OwnProps {}

type Props = OwnProps;

const FilterRules: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);

    const reportFields = store.currentReport?.activeReportQueryFilterFields;

    function handleUpdateFilterValue(
        evt: React.ChangeEvent<HTMLInputElement>,
        reportField: ReportField,
    ) {
        let reportFilter = reportField.reportFilter;
        if (!reportFilter) {
            reportFilter = reportField.addReportFilter();
        }
        let value = evt.currentTarget.value;
        reportFilter.setValue(value);
    }

    function handleUpdateComparator(
        comparator: Comparator,
        reportField: ReportField,
    ) {
        let reportFilter = reportField.reportFilter;
        if (!reportFilter) {
            reportFilter = reportField.addReportFilter();
        }
        reportFilter.setComparator(comparator);
    }

    return (
        <>
            <Grid
                container
                item
                xs={12}
                alignItems={"center"}
                spacing={2}
                className={classes.root}
            >
                <Grid item xs={4}>
                    <AcxSelectSingle
                        options={combinators}
                        defaultValue={combinatorSelector(
                            store.globalFilterCombinator,
                        )}
                        valueField={"id"}
                        labelField={"value"}
                        onChange={store.setGlobalFilterCombinator}
                        id={`report-global-filter-combinator-selector${uuid()}`}
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Typography className={classes.globalCombinatorText}>
                        are true
                    </Typography>
                </Grid>
            </Grid>

            <Grid
                container
                item
                xs={12}
                alignItems={"flex-end"}
                spacing={2}
                className={classes.vertSpace}
            >
                {reportFields?.map((reportField, index) => {
                    return (
                        <React.Fragment
                            key={`report-filters-${reportField.id ?? index}`}
                        >
                            <Grid item xs={4}>
                                <AcxSelectSingle
                                    options={
                                        store.availableReportQueryFilterFields ??
                                        []
                                    }
                                    valueField={"displayName"}
                                    labelField={"displayName"}
                                    placeholder={"Data Field"}
                                    onChange={reportField.setReportDataField}
                                    defaultValue={reportField.reportDataField}
                                    id={`report-filter-rule-datafield-${
                                        reportField.id ?? index
                                    }`}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AcxSelectSingle
                                    options={comparatorFunctions}
                                    onChange={(arg0) =>
                                        handleUpdateComparator(
                                            arg0.id,
                                            reportField,
                                        )
                                    }
                                    placeholder={"Comparison Function"}
                                    defaultValue={comparatorSelector(
                                        reportField.reportFilter?.comparator,
                                    )}
                                    id={`report-filter-rule-comparator-${
                                        reportField.id ?? index
                                    }`}
                                    fullWidth
                                    valueField={"id"}
                                    labelField={"value"}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                container
                                wrap={"nowrap"}
                                spacing={1}
                                alignItems={"flex-start"}
                            >
                                <Grid item xs={11}>
                                    <AcxMainTextField
                                        value={reportField.reportFilter?.value}
                                        onChange={(evt) =>
                                            handleUpdateFilterValue(
                                                evt,
                                                reportField,
                                            )
                                        }
                                        id={`report-filter-rule-value-${
                                            reportField.id ?? index
                                        }`}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <ButtonBase
                                        onClick={() =>
                                            store.currentReport?.removeReportField(
                                                reportField,
                                            )
                                        }
                                        className={classes.removeButton}
                                    >
                                        x
                                    </ButtonBase>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>

            <ButtonBase
                className={classes.addButton}
                onClick={store.currentReport?.addFilterReportField}
            >
                <div className={classes.addButtonIcon}>
                    <AddIcon className={classes.buttonIcon} />
                </div>
                <Typography className={classes.addButtonText}>
                    Add Condition
                </Typography>
            </ButtonBase>
        </>
    );
});

export default FilterRules;
