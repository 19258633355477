import { DataProcessingOption } from "models/DataProcesses";
import BaseService from "services/BaseService";
import { Organization, SAMLDataRequest } from "./types/Organization.type";
import { Tier } from "./types/Tier.type";
import {
    ModelComputeFrequency,
    ModelComputeOptionEnabled,
} from "../../../models/ModelCompute";

interface ModelComputeOptionsEditRequest {
    modelOption: string;
    enabled: boolean;
    modelQuota?: number;
    quotaFrequency?: ModelComputeFrequency;
    organizationStructureMemberIds: string[] | null;
}

class OrganizationService extends BaseService {
    protected urlBase: string = "api/Organizations";

    public async getAllOrganizations(
        page = 0,
        rowsPerPage = 100,
    ): Promise<Organization[]> {
        return this.get(
            new URLSearchParams({
                page: page.toString(10),
                rowsPerPage: rowsPerPage.toString(10),
            }),
            "GetPagedList",
        );
    }

    public async getOrganization(
        organizationId: string,
    ): Promise<Organization> {
        const res: Organization = await this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "GetOrganizationById",
        );

        res.EnableSamlSso = !!res.saml2EntityId;
        return res;
    }

    public async createOrganization(organization: Organization) {
        const params = new URLSearchParams({});
        return this.post(
            JSON.stringify(organization),
            params,
            "CreateOrganization",
            { returnJson: true },
        );
    }

    public async getCheckDbConnection(
        databaseConnectionString: string,
        databaseVersion: number,
    ): Promise<Organization> {
        const res: Organization = await this.get(
            new URLSearchParams({
                ConnectionString: databaseConnectionString,
                DatabaseVersion: databaseVersion.toString(),
            }),
            "CheckDbConnection",
        );
        return res;
    }

    public async updateOrganization(organization: Organization) {
        const params = new URLSearchParams({});
        delete organization.licensedData;

        const formattedOrg = {
            ...organization,
            organizationConfigurations: {
                ...organization.organizationConfigurations,
                evalAcknowledgeDays:
                    organization.evalAcknowledgeDays?.toString(),
                weeklyInteractionsMin:
                    organization.weeklyInteractionsMin?.toString(),
                weeklyInteractionsMax:
                    organization.weeklyInteractionsMax?.toString(),
            },
        };
        return this.put(
            params,
            "UpdateOrganization",
            JSON.stringify(formattedOrg),
            { returnJson: true },
        );
    }

    public async saveOrganization(organization: Organization) {
        return organization.id
            ? this.updateOrganization(organization)
            : this.createOrganization(organization);
    }

    public async deleteOrganization(id: string) {
        if (!id) {
            throw new Error(`Can't delete Organization without valid ID`);
        }
        return this.delete(new URLSearchParams({ id }), "DeleteOrganization");
    }

    public async getHierarchy(organizationId: string): Promise<Tier[]> {
        const res = await this.post(
            JSON.stringify({ organizationId }),
            new URLSearchParams({}),
            "GetServiceHierarchyForOrganization",
        );

        return res?.tiers;
    }

    public async saveHierarchy(
        id: string,
        hierarchy: Tier[],
        tiersToRemove: string[] = [],
    ) {
        const params = new URLSearchParams({});
        const res = await this.post(
            JSON.stringify({
                organizationId: id,
                tiers: hierarchy,
                tiersToRemove,
            }),
            params,
            "SaveServiceHierarchyForOrganization",
        );

        return res;
    }

    public async getDataProcessingOptions(): Promise<DataProcessingOption[]> {
        return this.get(
            new URLSearchParams({ page: "0" }),
            "GetDataProcessing",
        );
    }

    public async getModelComputeOptions(
        id: string,
    ): Promise<ModelComputeOptionEnabled[]> {
        return this.post(
            JSON.stringify({ organizationId: id }),
            new URLSearchParams({}),
            "GetModelComputeOptions",
        );
    }

    public async updateModelComputeOptions(
        id: string,
        modelComputeOptions: ModelComputeOptionsEditRequest[],
    ) {
        const body = JSON.stringify({
            orgId: id,
            editModelComputeOptions: modelComputeOptions,
        });
        return this.put(
            new URLSearchParams({}),
            "UpdateModelComputeOptions",
            body,
        );
    }

    public async saveSAMLOptions(data: SAMLDataRequest): Promise<Tier[]> {
        const params = new URLSearchParams({});
        return this.post(
            JSON.stringify(data),
            params,
            "UpdateSamlSettingsForOrganization",
            { returnJson: true },
        );
    }

    public async getLogsForOrganization(
        id: string,
        offset: number,
        items: number,
    ): Promise<any[]> {
        const body = JSON.stringify({
            organizationId: id,
            offset: offset.toString(),
            items: items.toString(),
        });
        return this.post(
            body,
            new URLSearchParams({}),
            "GetLogsForOrganization",
        );
    }
    public async getSamplingTargetForOrganizationStructureMember(
        id: string,
        historyLimit: number = 10,
        historyOffset: number = 0,
    ) {
        const res = await this.get(
            new URLSearchParams({
                OrganizationStructureMemberId: id,
                HistoryLimit: JSON.stringify(historyLimit),
                HistoryOffset: JSON.stringify(historyOffset),
            }),
            "GetSamplingTargetForOrganizationStructureMember",
        );
        return res;
    }

    public async updateSamplingTarget(
        organizationStructureMemberId: string,
        samplingTargetTimePeriod: string,
        samplingTargetUnitOfMeasure: string,
        value: number,
        monitoredByUserId: string,
        hierarchyCodes?: string[],
    ) {
        const res = await this.post(
            JSON.stringify({
                OrganizationStructureMemberId: organizationStructureMemberId,
                TimePeriod: samplingTargetTimePeriod,
                UnitOfMeasure: samplingTargetUnitOfMeasure,
                Value: value,
                MonitoredByUserId: monitoredByUserId,
                HierarchyCodes: hierarchyCodes,
            }),
            new URLSearchParams({}),
            "UpdateSamplingTarget",
        );
        return res;
    }

    public async getTopLevelServiceHierarchiesForOrganization(
        orgId: string,
    ): Promise<{ organizationId: string; tiers: Tier[] }> {
        const res = await this.get(
            new URLSearchParams({
                orgId,
            }),
            "GetTopLevelServiceHierarchiesForOrganization",
        );
        return res;
    }

    public async getServiceHierarchyLeaf(osmId: string): Promise<Tier[]> {
        const res = await this.get(
            new URLSearchParams({
                osmId,
            }),
            "GetServiceHierarchyLeaf",
        );
        return res;
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new OrganizationService();
