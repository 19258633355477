import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import ChatIcon from "@mui/icons-material/Chat";
import EditIcon from "@mui/icons-material/Edit";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React from "react";
import {
    HierarchyPermissions,
    HierarchyTypes,
    IHierarchyAssignmentTier,
} from "./Types";

// These will help validate our toggles
export const permissionToggleEnabled = (
    hasAdminPerms: boolean,
    permission: string,
    branch: IHierarchyAssignmentTier,
) =>
    hasAdminPerms &&
    (permission === HierarchyPermissions[0]
        ? true
        : permission === HierarchyPermissions[1] &&
          branch.permissions &&
          branch.permissions.includes(HierarchyPermissions["View"])
        ? true
        : permission === HierarchyPermissions[2] &&
          branch.permissions &&
          branch.permissions.includes(HierarchyPermissions["View"]) &&
          branch.permissions.includes(HierarchyPermissions["Edit"])
        ? true
        : false);

export const typeToggleEnabled = (
    hasAdminPerms: boolean,
    branch: IHierarchyAssignmentTier,
) =>
    (hasAdminPerms &&
        branch.permissions &&
        branch.permissions.includes(HierarchyPermissions["View"])) ??
    false;

// Opacity of 0.8 takes the sharpness out of the Icon Color. As these will render on every row, it is important to include.
export const PERMISSION_ICONS = {
    [HierarchyPermissions[0]]: (
        <VisibilityIcon
            fontSize="small"
            color="primary"
            style={{ opacity: 0.8 }}
        />
    ),
    [HierarchyPermissions[1]]: (
        <EditIcon fontSize="small" color="primary" style={{ opacity: 0.8 }} />
    ),
    [HierarchyPermissions[2]]: (
        <SupervisedUserCircleIcon
            fontSize="small"
            color="primary"
            style={{ opacity: 0.8 }}
        />
    ),
};

export const TYPE_ICONS = {
    [HierarchyTypes[0]]: (
        <AudiotrackIcon
            fontSize="small"
            color="primary"
            style={{ opacity: 0.8 }}
        />
    ),
    [HierarchyTypes[1]]: (
        <MailOutlineIcon
            fontSize="small"
            color="primary"
            style={{ opacity: 0.8 }}
        />
    ),
    [HierarchyTypes[2]]: (
        <ChatIcon fontSize="small" color="primary" style={{ opacity: 0.8 }} />
    ),
    [HierarchyTypes[3]]: (
        <VideoCallIcon
            fontSize="small"
            color="primary"
            style={{ opacity: 0.8 }}
        />
    ),
    [HierarchyTypes[4]]: (
        <LiveHelpIcon
            fontSize="small"
            color="primary"
            style={{ opacity: 0.8 }}
        />
    ),
};
