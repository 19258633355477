import {
    GridColTypeDef,
    GridComparatorFn,
    GridSortCellParams
} from "@mui/x-data-grid-pro";
import { get } from "lodash";
import moment from "moment";
import {
    parseFromISO,
    standardDateFormat,
    standardDateTimeFormat,
} from "utils/DateTimeUtils";

export const dateComparatorMoment: GridComparatorFn = (
    v1,
    v2,
    param1: GridSortCellParams,
    param2: GridSortCellParams,
): number => {
    const row1 = param1.api.getRow(param1.id);
    const row2 = param2.api.getRow(param2.id);

    const p1 = moment(get(row1, param1.field)?.toString());
    const p2 = moment(get(row2, param2.field)?.toString());

    if (!p1.isValid()) return 1;
    if (!p2.isValid()) return -1;
    if (p1.isSame(p2)) return 0;
    return p1.isBefore(p2) ? -1 : 1;
};

export const dateTimeColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        const value = row[column.field];
        return value ? standardDateTimeFormat(parseFromISO(value, false)) : "";
    },
    type: "date",
    valueGetter: (_, row, column) => {
        const value = row[column.field];
        return value instanceof Date ? value : new Date(value);
    },
};

export const dateColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        const value = row[column.field];
        return value ? standardDateFormat(parseFromISO(value, false)) : "";
    },
    type: "date",
    valueGetter: (_, row, column) => {
        const value = row[column.field];
        return value instanceof Date ? value : new Date(value);
    },
};

export const dateIgnoreTimeColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        const value = apiRef.current.getCellValue(row.id, column.field);
        return standardDateFormat(parseFromISO(value, false));
    },

    type: "date",
    valueGetter: (_, row, column) => {
        const value = row[column.field];
        return value instanceof Date ? value : new Date(value);
    },
};

export const interactionDateColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        let value = get(row, column.field);
        value = interactionDateHelper(value);
        return value ? standardDateFormat(parseFromISO(value, true)) : "";
    },

    type: "date",
    valueGetter: (_, row, column) => {
        const value = get(row, column.field);
        return value instanceof Date ? value : new Date(value);
    },
};

export const interactionDateTimeColumnType: GridColTypeDef = {
    valueFormatter: (_, row, column, apiRef) => {
        let value = get(row, column.field);
        value = interactionDateHelper(value);
        return value ? standardDateTimeFormat(parseFromISO(value, false)) : "";
    },
    type: "date",
    valueGetter: (_, row, column) => {
        const value = row[column.field];
        return value instanceof Date ? value : new Date(value);
    },
};

export const interactionDateHelper = (
    value?: string | moment.Moment | Date,
): string | moment.Moment | undefined => {
    if (value) {
        value = moment.utc(value);

        // check if timestamp is 00:00:00 and set to noon to avoid day change on local conversion
        if (
            value.hours() === 0 &&
            value.minutes() === 0 &&
            value.seconds() === 0
        ) {
            value.set("hour", 12);
        }
        value = value.local();
    }
    return value;
};
