import { Container, Popover, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import theme from "Theme/AppTheme";
import { Speaker } from "../../Stores/SpeakerStore/constants";
import { useStyles } from "./styles";
import { getTranscriptionSpeakerColor } from "../../Stores/SpeakerStore/utils";
import { Blurb } from "./Blurb";

interface SpeakerPopoverProps {
    open: boolean;
    anchorEl: HTMLSpanElement | null;
    onClose: () => void;
    onSetSpeaker: (speaker: Speaker) => void;
    blurb: Blurb;
}

export const SpeakerPopover: React.FC<SpeakerPopoverProps> = observer(
    ({ open, anchorEl, onClose, blurb, onSetSpeaker }) => {
        const classes = useStyles({ blurb });
        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: 1,
                }}
                PaperProps={{
                    style: {
                        padding: theme.spacing(1, 0),
                        minWidth: theme.spacing(16),
                    },
                }}
            >
                {Object.entries(Speaker).map(([_, speaker], idx) => (
                    <Container
                        key={`select-${speaker}-${idx}`}
                        onClick={() => {
                            onSetSpeaker(speaker);
                        }}
                        className={classes.speakerContainer}
                        style={{
                            color: getTranscriptionSpeakerColor(speaker),
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <FiberManualRecordIcon
                            color="inherit"
                            fontSize="inherit"
                            style={{ marginRight: theme.spacing(0.5) }}
                        />
                        <Typography component="span">{speaker}</Typography>
                    </Container>
                ))}
            </Popover>
        );
    },
);
