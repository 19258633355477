import WidgetsIcon from "@mui/icons-material/Widgets";
import { Grid, Typography } from "@mui/material";
import { Observer } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import { BeatLoader } from "react-spinners";
import theme from "../../Theme/AppTheme";
import { DashboardStore } from "../../stores/Layout/DashboardStore";

interface OwnProps {
    parentHeightPx: number;
    dashboardStore?: DashboardStore;
}

type Props = OwnProps;

const EmptyDashboard: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = (props) => {
    return (
        <Observer>
            {() => (
                <>
                    {props.parentHeightPx > 0 && (
                        <div
                            style={{
                                position: "relative",
                                top: `20%`,
                                cursor: "pointer",
                            }}
                            onClick={props.dashboardStore?.editDashboard}
                        >
                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="subtitle2"
                                        color="primary"
                                    >
                                        Click to Begin Building Your Dashboard
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <WidgetsIcon
                                        sx={{
                                            color: theme.palette.text.disabled,
                                            opacity: "0.35",
                                            fontSize: `${
                                                props.parentHeightPx * 0.42
                                            }px`,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <BeatLoader
                                        size={16}
                                        color={theme.palette.primary.main}
                                        loading={
                                            props.dashboardStore
                                                ?.dashboardLoading
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    )}
                </>
            )}
        </Observer>
    );
};

export default EmptyDashboard;
