import { isObject } from "lodash";
import { action, makeObservable, observable, reaction } from "mobx";
import type { MetadataSpec } from "models/MetadataSpec";
import Papa from "papaparse";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { TemplateModel } from "./Types/TemplateModel";

@AcxStore
export class TemplateStore {
    @observable model: TemplateModel;

    onSpecFinished?: (spec: MetadataSpec.ISpecType | undefined) => void;

    constructor(public rootStore: IRootStore) {
        makeObservable(this);
        this.model = new TemplateModel();

        reaction(
            () => ({
                fil: this.model.filename,
                char: this.model.removeChars,
                extH: this.model.extHandle,
            }),
            (arg) => {
                if (arg.fil) {
                    this.updateProcessedFilename();
                    this.buildSpec();
                }
            },
            { delay: 500, fireImmediately: true },
        );

        reaction(
            () => ({
                metaSource: this.model.metaSource,
                delim: this.model.delimiter,
            }),
            (arg0) => {
                if (arg0.metaSource) {
                    this.buildSpec();
                }
            },
            { fireImmediately: true },
        );
    }

    @action
    buildSpec() {
        const typ = this.model.metaSource;
        const fixed = false;
        const delim = this.model.delimiter;
        const flds = this.model.spec?.fields;
        const spec: MetadataSpec.ISpecType = {
            parse: { type: typ, fixed: fixed, delimiter: delim },
            fields: [],
            fileHandle: {
                action: this.model.extHandle,
                value: this.model.removeChars,
            },
        };
        if (flds) {
            spec.fields = flds;
        }

        this.model.spec = spec;
        const opt: any = Papa.parse(this.model.sampleString, {
            quoteChar: '"',
            delimiter: delim,
        });

        this.model.fieldOptions = opt.data[0]?.map((val, index) => {
            return { label: val, value: index.toString() };
        });
    }

    @action
    addFieldToSpec(fld: MetadataSpec.FieldType) {
        const flds = this.model.spec?.fields;
        const currFld =
            flds?.findIndex((f) => f.fieldName === fld.fieldName) ?? -1;
        if (isObject(fld.format)) {
            const fmt = fld.format["id"];
            fld.format = fmt;
        }
        if (currFld > -1) {
            flds?.splice(currFld, 1, fld);
        } else {
            this.model.spec?.fields.push(fld);
        }
        console.log(JSON.stringify(fld));
    }

    @action
    async updateProcessedFilename() {
        if (this.model.extHandle === "removedot") {
            this.updateModelProp(
                "processedFilename",
                this.model.filename.slice(
                    0,
                    this.model.filename.lastIndexOf("."),
                ),
            );

            if (this.model.metaSource === "filename") {
                this.updateModelProp(
                    "sampleString",
                    this.model.filename.slice(
                        0,
                        this.model.filename.lastIndexOf("."),
                    ),
                );
            }
        } else if (
            this.model.extHandle === "removestring" &&
            this.model.filename.lastIndexOf(this.model.removeChars) > -1
        ) {
            this.updateModelProp(
                "processedFilename",
                this.model.filename.slice(
                    0,
                    this.model.filename.lastIndexOf(this.model.removeChars),
                ),
            );
            if (this.model.metaSource === "filename") {
                this.updateModelProp(
                    "sampleString",
                    this.model.filename.slice(
                        0,
                        this.model.filename.lastIndexOf(this.model.removeChars),
                    ),
                );
            }
        } else {
            this.updateModelProp("processedFilename", this.model.filename);
            if (this.model.metaSource === "filename") {
                this.updateModelProp("sampleString", this.model.filename);
            }
        }
    }

    @action
    initModelFromExisting(spec?: MetadataSpec.ISpecType) {
        if (!spec) {
            return;
        }

        this.model.spec = spec;
        this.model.removeChars = spec.fileHandle.value;
        this.model.metaSource = spec.parse.type;
        this.model.delimiter = spec.parse.delimiter ?? ",";
        this.model.extHandle = spec.fileHandle.action;

        this.buildSpec();
        this.updateProcessedFilename();
    }

    @action
    updateModelProp<k extends keyof TemplateModel, v extends TemplateModel[k]>(
        field: k,
        value: v,
    ) {
        this.model[field] = value;
    }

    @action
    reset() {
        this.model = new TemplateModel();
        this.onSpecFinished = undefined;
    }
}
