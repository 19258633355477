import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import FocusControls from "../../../../Layouts/Focus/FocusControls";
import SideControlsPage from "../../../../Layouts/SideControls/SideControlsPage";
import SamplerSteps from "./Views/SamplerSteps";
import StepViewer from "./Views/StepViewer";
import { useStore } from "utils/useStore";
import { SourceFilesStepStore } from "./Stores/SourceFilesStepStore";
import { ConfigurationStepStore } from "./Stores/ConfigurationStepStore";
import { RecommendationStepStore } from "./Stores/RecommendationStepStore";
// import { useStore } from "utils/useStore";
// import { AuthStore } from "stores/AuthStore";

const useStyles = makeStyles((theme: Theme) => ({
    drawer: {},
    main: {
        width: "100%",

        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
}));

interface OwnProps {}

type Props = OwnProps;

const AudioFile = (props: Props) => {
    const classes = useStyles();
    // const auth = useStore(AuthStore);

    const sourceFileStepStore = useStore(SourceFilesStepStore);
    const configurationStepStore = useStore(ConfigurationStepStore);
    const recommendationStepStore = useStore(RecommendationStepStore);

    return (
        <FocusControls
            title={"Smart Sampler"}
            loading={false}
            controlHeader={null}
            onCancel={() => {
                sourceFileStepStore.reset();
                sourceFileStepStore.tableStore.reset();
                configurationStepStore.reset();
                recommendationStepStore.reset();
                // console.log("leaving sampler");
                // auth.orgStore.temporarySelectOrganization = undefined;
                // auth.orgStore
                // auth.setActiveOrgId(auth.orgStore.selectedOrganization?.id);
                // // console.log(auth.orgStore.temporarySelectOrganization);
                // console.log("active org", auth.activeOrgId);
                // console.log(
                //     "selected org",
                //     auth.orgStore.selectedOrganization?.id,
                // );
                // orgStore.temporarySelectOrganization = undefined;
            }}
            mainContent={
                <SideControlsPage
                    drawerContent={<SamplerSteps />}
                    mainContent={<StepViewer />}
                    mainGridSpacing={0}
                    drawerContentClasses={classes.drawer}
                    mainGridAlignItems={"stretch"}
                    mainContentClasses={classes.main}
                    mainGridJustify={"flex-start"}
                    drawerLocation={"left"}
                    drawerVerticalOffsetPixels={0}
                    drawerMinWidth={300}
                    containerOffsetPixels={0}
                />
            }
            showSave={false}
        />
    );
};

export default AudioFile;
