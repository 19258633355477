import BaseService from "./BaseService";

export default class AgentService extends BaseService {
    protected urlBase: string = "api/TenantAuthorizations";

    async getTenantAuthorizations(userId: string): Promise<any> {
        return await this.post(
            JSON.stringify({ userId }),
            new URLSearchParams({}),
            "GetTenantAuthorizationsForUser",
        );
    }

    async createTenantAuthorization(orgId: string, userId: string) {
        await this.post(
            JSON.stringify({
                OrganizationId: orgId,
                ApplicationUserId: userId,
            }),
            new URLSearchParams(),
            "AddTenantAuthorizationForUser",
        );
    }

    async deleteTenantAuthorization(id: string) {
        await this.delete(
            new URLSearchParams({ id }),
            "DeleteTenantAuthorizationForUser",
        );
    }
}
