import { EvaluationStatus } from "models/Evaluation";
import moment from "moment";
import React from "react";
import theme from "Theme/AppTheme";
import {
    parseFromISO,
    standardDateFormat,
    standardDateTimeFormat,
} from "../../utils/DateTimeUtils";
import { isNullableType } from "../../utils/TypeGuards";

export function durationFormatter(millis: string | number | undefined) {
    if (isNullableType(millis)) {
        return "";
    }
    const pieces: string[] = [];
    const duration = moment.duration(millis);
    const [hours, minutes, seconds] = [
        duration.hours(),
        duration.minutes(),
        duration.seconds(),
    ];
    pieces.push(hours.toString().padStart(2, "0"));
    pieces.push(minutes.toString().padStart(2, "0"));
    pieces.push(seconds.toString().padStart(2, "0"));

    return pieces.join(":");
}

export function defaultDateTimeFormatter(
    dateTime?: string,
    isDateOnly?: boolean,
) {
    if (isDateOnly) {
        return dateTime
            ? standardDateFormat(parseFromISO(dateTime, isDateOnly))
            : "";
    } else {
        return dateTime
            ? standardDateTimeFormat(parseFromISO(dateTime, isDateOnly))
            : "";
    }
}

export function defaultDurationFormatter(
    duration: number | string | undefined,
    unit: moment.unitOfTime.Base = "milliseconds",
) {
    function durationDisplay(val: number | string) {
        const dur = moment.duration(val, unit);

        // const res = `${dur.hours()}:${`${dur.minutes()}`.padStart(2, "0")}:${`${dur.seconds()}`.padStart(2, "0")}`;

        return `${dur.hours()}:${`${dur.minutes()}`.padStart(
            2,
            "0",
        )}:${`${dur.seconds()}`.padStart(2, "0")}`;
    }

    return !isNullableType(duration) ? durationDisplay(duration) : "";
}

export function defaultTimeFromNowFormatter(dateTime?: string) {
    if (dateTime) {
        return parseFromISO(dateTime).fromNow();
    }

    return "";
}

export function statusFormatter(status: EvaluationStatus) {
    let color, text;
    if (typeof status === "string") {
        status = parseInt(status, 10);
    }
    if (status === EvaluationStatus.Completed) {
        color = theme.palette.success.main;
        text = "Completed";
    } else if (status === EvaluationStatus.InProgress) {
        color = theme.palette.warning.main;
        text = "In Progress";
    } else if (status === EvaluationStatus.NotStarted) {
        color = theme.palette.black.main;
        text = "Not Started";
    } else if (status === EvaluationStatus.NoValue) {
        color = theme.palette.error.main;
        text = "No Value";
    } else if (status === EvaluationStatus.PendingReview) {
        color = theme.palette.info.main;
        text = "Pending Review";
    } else if (status === EvaluationStatus.ReviewComplete) {
        color = theme.palette.info.main;
        text = "Review Complete";
    } else if (status === EvaluationStatus.Disputed) {
        color = theme.palette.warning.main;
        text = "Disputed";
    } else if (status === EvaluationStatus.Escalated) {
        color = theme.palette.error.main;
        text = "Escalated";
    } else if (status === EvaluationStatus.Enriching) {
        color = theme.palette.gray[500];
        text = "Enriching";
    }

    return <span style={{ color: color, fontWeight: "bold" }}>{text}</span>;
}
