import { Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import LoopIcon from "@mui/icons-material/Loop";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import WarningIcon from "@mui/icons-material/Warning";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxChip from "components/UI/AcxChip";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import React from "react";
import { MotfAutomationHistoryService } from "services/MotfAutomationHistoryService";
import { MotfAutomationService } from "services/MotfAutomationService";
import type { UpsertMotfDefinition } from "services/MotfAutomationService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import * as RootStore from "stores/RootStore";
import { OrganizationServiceHierarchyStore } from "../OrganizationServiceHierarchy/stores/OrganizationServiceHierarchyStore";
import { dateTimeColumnType } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import { motfExecutionValuesAndLabelsMap } from "./MotfDefinitionForm";

const definitionsColumns: IColDef[] = [
    {
        field: "id",
        headerName: "Id",
        hide: true,
    },
    {
        field: "name",
        headerName: "Name",
        flex: 1,
    },
    {
        field: "createdBy",
        headerName: "Created By",
        flex: 1,
    },
    {
        field: "quotaType",
        headerName: "Quota Type",
        flex: 1,
        // valueGetter: (value, row, column, apiRef) => {},
    },
    {
        field: "mediaTypes",
        headerName: "Media Types",
        flex: 1,
        renderCell(params) {
            const mediaTypes: string[] = params.value as string[];
            if (!mediaTypes.length) return [];
            // chip list doesnt work well here but individual chips do
            return mediaTypes
                .sort()
                .map((mediaType, idx) => (
                    <AcxChip
                        size="small"
                        color="blue"
                        key={idx}
                        label={mediaType}
                    />
                ));
        },
    },
    {
        field: "lastExecuted",
        headerName: "Last Executed",
        flex: 1,
        ...dateTimeColumnType,
    },
    {
        field: "isActive",
        headerName: "Status",
        flex: 1,
        valueGetter(value, row, column, apiRef) {
            if (row.isActive) return "Active";
            else return "Disabled";
        },
    },
    {
        field: "executionFrequency",
        headerName: "Execution Frequency",
        flex: 1,
        hide: true,
        valueGetter(value, row, column, apiRef) {
            return motfExecutionValuesAndLabelsMap[row.executionFrequency]?.label ?? row.executionFrequency
        }
    },
];

const historyColumns: IColDef[] = [
    {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell(params) {
            let icon = <></>;
            switch (params.value) {
                case "NotStarted":
                    icon = <MoreHorizIcon />;
                    break;
                case "Completed":
                    icon = <DoneIcon htmlColor="#4BB543" />;
                    break;
                case "NothingToProcess":
                    icon = <SkipNextIcon htmlColor="#D3D3D3" />;
                    break;
                case "InProgress":
                    icon = <LoopIcon />;
                    break;
                case "Cancelled":
                    icon = <NotInterestedIcon />;
                    break;
                case "CompletedWithErrors":
                    icon = <ErrorIcon color="error" />;
                    break;
                case "CompletedWithWarnings":
                    icon = <WarningIcon htmlColor="#FFCC00" />;
                    break;
            }
            return (
                <Tooltip title={params.value?.toString() ?? ""}>{icon}</Tooltip>
            );
        },
    },
    {
        field: "createdBy",
        headerName: "Created By",
    },
    {
        field: "failedCount",
        headerName: "Failed Count",
        flex: 1,
    },
    {
        field: "isMiddleOfTheFunnel",
        headerName: "MOTF",
        flex: 1,
    },
    {
        field: "isOnDemandProcessing",
        headerName: "On Demand",
        flex: 1,
    },
    {
        field: "numberOfExecutions",
        headerName: "Execution Count",
        flex: 1,
    },
    {
        field: "processed",
        headerName: "Processed",
        flex: 1,
    },
    {
        field: "ruleId",
        headerName: "Rule Id",
        flex: 1,
    },
    {
        field: "ruleName",
        headerName: "Rule Name",
        flex: 1,
    },
    {
        field: "skipCount",
        headerName: "Skip Count",
        flex: 1,
    },
    {
        field: "createdOn",
        headerName: "Timestamp",
        flex: 1,
        type: "date",
        ...dateTimeColumnType,
    },
    {
        field: "successCount",
        headerName: "Success Count",
        flex: 1,
    },
    {
        field: "totalItems",
        headerName: "Total Items",
        flex: 1,
    },
    {
        field: "warningCount",
        headerName: "Warning Count",
        flex: 1,
    },
];

@RootStore.AcxStore
export class OrganizationMotfStore extends BaseStore {
    authStore: AuthStore;
    private messageStore: MessageStore;
    private osmStore: OrganizationServiceHierarchyStore;

    public definitionsDataGridStore: AcxDataGridStore = new AcxDataGridStore();
    public historyDataGridStore: AcxDataGridStore = new AcxDataGridStore();

    private motfService: MotfAutomationService = new MotfAutomationService();
    private motfHistoryService: MotfAutomationHistoryService =
        new MotfAutomationHistoryService();

    @observable
    public canUserEdit: boolean;

    @observable
    public orgId?: string;

    @observable
    public isHistoryOpen: boolean = false;

    @observable
    public editingDefinition?: UpsertMotfDefinition;

    @observable
    public editingDefinitionId?: string;

    @observable
    public showInactiveDefinitions: boolean = false;

    constructor(private rootStore: RootStore.IRootStore) {
        super("Organization Motf Store");
        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.messageStore = rootStore.getStore(MessageStore);
        this.osmStore = rootStore.getStore(OrganizationServiceHierarchyStore);

        this.canUserEdit = this.authStore.canUserEdit("Sample Automation");

        this.definitionsDataGridStore.setColumns(definitionsColumns);
        this.definitionsDataGridStore.checkboxSelection = this.canUserEdit;
        this.definitionsDataGridStore.removeHeight = "100px";
        this.definitionsDataGridStore.controlsAlignProperty = "center";

        this.historyDataGridStore.title = "Sampling Execution History";
        this.historyDataGridStore.setColumns(historyColumns);
        this.historyDataGridStore.checkboxSelection = false;
        this.historyDataGridStore.removeHeight = "50px";

        reaction(
            () => this.orgId,
            (id) => {
                if (id !== undefined) {
                    this.loadDefinitions();
                }
            },
        );

        reaction(
            () => this.showInactiveDefinitions,
            () => {
                this.loadDefinitions();
            },
        );
    }

    public async loadDefinitions() {
        if (!this.orgId) return;

        this.definitionsDataGridStore.isLoading = true;
        try {
            this.definitionsDataGridStore.rows = [];
            const definitions =
                await this.motfService.getMotfAutomationDefinitions(
                    this.showInactiveDefinitions,
                );
            this.definitionsDataGridStore.rows = definitions;
            this.definitionsDataGridStore.clearSelected();
        } catch (error) {
            this.messageStore.logError("Couldn't load MOTF definitions");
        }
        this.definitionsDataGridStore.isLoading = false;
    }

    public async loadHistory() {
        if (!this.orgId) return;

        this.historyDataGridStore.isLoading = true;
        try {
            let res = await this.motfHistoryService.getMotfAutomationHistory(
                this.orgId,
            );

            // id required for acxDataGrid rows
            const formattedHistoryRows = res.map((item, i) => {
                item.id = i.toString();
                return item;
            });
            this.historyDataGridStore.rows = formattedHistoryRows;
        } catch (error: any) {
            this.messageStore.logError(
                `Error loading history: ` + error.toString(),
            );
        } finally {
            this.historyDataGridStore.isLoading = false;
        }
    }

    @action
    public setOrgId(id: string) {
        this.orgId = id;
    }

    @action
    public setIsHistoryOpen(open: boolean) {
        if (open) this.loadHistory();
        this.isHistoryOpen = open;
    }

    @action
    public async deactivateSelected() {
        if (!this.canDeactivateSelected || !this.orgId) return;

        this.definitionsDataGridStore.isLoading = true;
        try {
            const definitions = [...this.definitionsDataGridStore.selectedRows];
            for (let i = 0; i < definitions.length; i++) {
                await this.motfService.deactivateMotfAutomationDefinition(
                    definitions[i].id,
                    this.orgId,
                );
            }
            await this.loadDefinitions();

            this.messageStore.logInfo(
                `Deactivated ${definitions.length} definition${
                    definitions.length !== 1 ? "s" : ""
                }`,
            );
        } catch (error) {
            this.messageStore.logError(`Error deactiving definitions`);
        }
        this.definitionsDataGridStore.isLoading = false;
    }

    @action
    public async setEditingDefinitionById(id: string) {
        if (!this.orgId) return;
        try {
            const editMotf = await this.motfService.getMotfAutomationDefinition(
                id,
                this.orgId,
            );
            this.editingDefinition = editMotf;
            this.editingDefinitionId = id;
        } catch (error) {
            this.messageStore.logError(
                "Error loading individual definition for editing",
            );
        }
    }

    @action
    public setShowInactiveDefinitions(show: boolean) {
        this.showInactiveDefinitions = show;
    }

    @action
    public async deleteEditingDefinition() {
        if (!this.editingDefinitionId || !this.orgId) return;

        await this.motfService.deleteMotfAutomationDefinition(
            this.editingDefinitionId,
            this.orgId,
        );
        this.editingDefinition = undefined;
        this.editingDefinitionId = undefined;
    }

    @action
    public async deactivateEditingDefinition() {
        if (!this.editingDefinitionId || !this.orgId) return;

        await this.motfService.deactivateMotfAutomationDefinition(
            this.editingDefinitionId,
            this.orgId,
        );
        this.editingDefinition = undefined;
        this.editingDefinitionId = undefined;
    }

    @action
    public async activateEditingDefinition() {
        if (!this.editingDefinitionId || !this.editingDefinition || !this.orgId)
            return;

        await this.motfService.updateMotfAutomationDefinition(
            this.editingDefinitionId,
            this.orgId,
            { ...this.editingDefinition, isActive: true },
        );
    }

    @computed
    get canDeactivateSelected() {
        return (
            this.definitionsDataGridStore.selectedRows.length >= 1 &&
            this.canUserEdit &&
            !this.definitionsDataGridStore.isLoading
        );
    }

    @computed
    get canEditSelected() {
        return (
            this.definitionsDataGridStore.selectedRows.length === 1 &&
            this.canUserEdit &&
            !this.definitionsDataGridStore.isLoading
        );
    }
}
