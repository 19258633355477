import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
    width?: string;
    height?: string;
}

type Props = OwnProps;

const QuoteSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width={props.width ?? "25px"}
            height={props.height ?? "25px"}
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Evaluation"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.25}
            >
                <g
                    id="Interaction---Analyst-Evaluate-"
                    transform="translate(-1052.000000, -437.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Content" transform="translate(0.000000, 56.000000)">
                        <g
                            id="Middle"
                            transform="translate(344.000000, 24.000000)"
                        >
                            <g
                                id="Quality-Copy"
                                transform="translate(0.000000, 252.000000)"
                            >
                                <g
                                    id="Stacked-Group"
                                    transform="translate(24.000000, 76.000000)"
                                >
                                    <g id="Field-Copy-22">
                                        <g id="Stacked-Group">
                                            <g
                                                id="Group-3"
                                                transform="translate(0.000000, 28.000000)"
                                            >
                                                <g
                                                    id="Group-14"
                                                    transform="translate(612.000000, 1.000000)"
                                                >
                                                    <g
                                                        id="Group-13"
                                                        transform="translate(72.000000, 0.000000)"
                                                    >
                                                        <g id="Icon/Quote">
                                                            <path
                                                                d="M5.85301667,7.13385833 C4.86545,8.36830833 4.72295833,9.61244167 5.013125,10.4829417 C6.1097,9.61213333 7.63368333,9.794475 8.56168333,10.6614167 C9.49910833,11.537175 9.57976667,13.0766 8.98163333,14.0734917 C8.478475,14.9120917 7.59486667,15.417325 6.60893333,15.417325 C4.36514167,15.417325 3.33333333,13.4383417 3.33333333,11.21785 C3.33333333,9.776025 3.70078333,8.48469167 4.43569167,7.34383333 C5.1706,6.202975 6.27995833,5.28259167 7.763775,4.582675 L8.16273333,5.35958333 C7.26684167,5.73753333 6.49694167,6.32895833 5.85301667,7.13385833 Z M13.1601083,7.13385833 C12.17255,8.36830833 12.03005,9.61244167 12.3202167,10.4829417 C12.8101583,10.1049917 13.3420917,9.91600833 13.9160167,9.91600833 C15.4257167,9.91600833 16.6666667,10.9214917 16.6666667,12.6666667 C16.6666667,14.2714333 15.435125,15.417325 13.9160083,15.417325 C11.672225,15.417325 10.6404167,13.4383417 10.6404167,11.21785 C10.6404167,9.77603333 11.0078667,8.48469167 11.742775,7.34383333 C12.4776833,6.202975 13.5870417,5.28259167 15.0708583,4.582675 L15.4698083,5.359575 C14.573925,5.73753333 13.804025,6.32895833 13.1601083,7.13385833 Z"
                                                                id="Shape"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default QuoteSvg;
