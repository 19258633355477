import { DataProcessingOption } from "models/DataProcesses";
import DirectoryModel from "models/DirectoryModel";
import MontageModel from "models/MontageModel";
import MontageSoundClip from "models/MontageSoundClip";
import moment from "moment";
import BaseService from "./BaseService";

export class MontageService extends BaseService {
    protected urlBase: string = "api/Montage";

    public async getDirectory(directoryId: string): Promise<DirectoryModel> {
        const params = new URLSearchParams({
            directoryId: directoryId,
        });
        const dir = await this.get<DirectoryModel>(
            new URLSearchParams(params),
            "GetMontageDirectory",
        );

        return dir;
    }
    async getMontageSoundClips(montageId: string) {
        const parms = new URLSearchParams({ montageId });
        const res = await this.get<MontageSoundClip[]>(
            parms,
            "GetSoundClipsByMontageId",
        );
        return res;
    }

    public async listMontages(
        beginDate?: moment.Moment,
        endDate?: moment.Moment,
    ): Promise<MontageModel[]> {
        const params = new URLSearchParams({
            beginDate: beginDate?.format() || "",
            endDate: endDate?.format() || "",
        });
        const rv = await this.get<MontageModel[]>(
            new URLSearchParams(params),
            "List",
        );

        return rv;
    }

    public async downloadMontage(montageId: string): Promise<Blob> {
        const params = new URLSearchParams({ montageId });
        const rv = await this.getBlob(params, "Download");
        return rv;
    }

    public async createMontage(
        orgId: string,
        name: string,
        directoryName: string,
        montageAudio: Blob,
        clipOrder: string[],
        tones: any[],
    ): Promise<MontageModel> {
        const params = new URLSearchParams({ orgId, name, directoryName });

        for (let clipId of clipOrder) {
            params.append("clipOrder", clipId);
        }
        for (let tone of tones) {
            params.append("tones", JSON.stringify(tone));
        }

        var res = await this.post(montageAudio, params, "");
        return res;
    }

    public async deleteMontages(montageIds: string[]) {
        const urlSearchParams: URLSearchParams = new URLSearchParams();
        for (let montageId of montageIds) {
            urlSearchParams.append("montageIds", montageId);
        }
        const req = await this.delete(urlSearchParams, "DeleteMontages", null);
        return req;
    }

    public async listRedactionServices() {
        const params = new URLSearchParams();

        var res = await this.get(params, "ListRedactionServices");
        return res;
    }

    public async streamRedactedSoundClip(
        voiceRedactionFactor: number,
        orgId: string,
        soundClipId: string,
        dataProcessingOptions: DataProcessingOption[],
    ) {
        const params = new URLSearchParams({ orgId, soundClipId });

        params.append("voiceRedactionFactor", voiceRedactionFactor.toString());

        for (var option of dataProcessingOptions) {
            params.append("dataProcessingOptions", option.processingOption);
        }

        const res = await this.getArrayBuffer(
            params,
            "StreamRedactedSoundClip",
        );
        return res;
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new MontageService();
