import {
    CssBaseline,
    Grid,
    StyledEngineProvider,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { default as AppTheme } from "Theme/AppTheme";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import AuthPage from "./AuthPage";
import { QueryParameterNames } from "./api-authorization/ApiAuthorizationConstants";
import { ConnectionService } from "services/ConnectionService";

function SaveConnection() {
    const [isLoading, setIsLoading] = useState(true);
    const [saveStatus, setSaveStatus] = useState("Connection Failed");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _] = useSearchParams();

    const code = searchParams.get(`${QueryParameterNames.Code}`);

    useEffect(() => {
        async function saveCode() {
            if (!code) {
                setIsLoading(false);
                return;
            }

            const connectionService = new ConnectionService();
            connectionService
                .saveConnectionCode(code)
                .then(() => {
                    setSaveStatus("Connection Saved");
                })
                .catch(() => console.error("Failed to Save Connection"))
                .finally(() => setIsLoading(false));

            return;
        }

        saveCode();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={AppTheme}>
                <CssBaseline />
                <AuthPage isLoading={isLoading} title={saveStatus}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignContent="center"
                        direction="column"
                    >
                        <Typography>Please close this page.</Typography>
                    </Grid>
                </AuthPage>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default SaveConnection;
