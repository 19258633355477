import { action, computed, observable, makeObservable } from "mobx";
import { MediaClip } from "../../../../models/SoundClip";
import { range } from "../../../../utils/helpers";
import {
    ChatPersona,
    IAugmentedChatRecord,
    IChatRecord,
} from "../AcxChatViewer";
import { EddyEffectP2Results } from "models/EddyEffectP2Results";
import { addEddyToChatRecords, parseChatToWords } from "../utils";
// import * as Regex from "regex";

const colorList: Array<string> = ["#2EC674", "#1D2960", "#FB8457"];

export class ChatViewModel {
    clippedMsgRefs: Map<string, HTMLDivElement | null> = new Map<
        string,
        HTMLDivElement | null
    >();

    @observable records: IChatRecord[] = [];
    @observable eddyEffectP2Data: EddyEffectP2Results[] = [];

    onFocusClip?: (clipId?: string) => void;
    onChatClip?: (text: string, start: number, end: number) => void;

    @observable activeClips?: MediaClip[];
    @observable clipSet: Set<number> = new Set<number>();

    @observable selectedPersona: "all" | ChatPersona = "all";

    constructor() {
        makeObservable(this);
    }

    @computed
    get augmentedRecords() {
        const activeClips = this.activeClips;
        let augmentedRecords: IAugmentedChatRecord[] = addEddyToChatRecords({
            records: this.records,
            eddyEffectP2Data: this.eddyEffectP2Data,
        });

        if (!activeClips?.length) {
            return augmentedRecords;
        }

        let clipIndex = 0;
        let colorIndex = 0;

        return augmentedRecords.map((record, index) => {
            const currClip =
                clipIndex < (activeClips.length ?? 0)
                    ? activeClips[clipIndex]
                    : undefined;

            if (
                currClip &&
                currClip.startTime != null &&
                currClip.startTime <= index &&
                currClip &&
                currClip.endTime != null &&
                currClip.endTime >= index
            ) {
                const modifiedRecord = {
                    ...record,
                    clipId: currClip?.id,
                    color: colorList[colorIndex],
                    startIndex: currClip.startTime,
                } as IAugmentedChatRecord;

                if (index === currClip.endTime) {
                    colorIndex = (colorIndex + 1) % colorList.length;
                    clipIndex++;
                }
                return modifiedRecord;
            }
            return record;
        });
    }

    setClipMessageRef(key: string, ref: HTMLDivElement | null) {
        this.clippedMsgRefs.set(key, ref);
    }

    setOnClipCallback(
        onChatClip?: (text: string, start: number, end: number) => void,
    ) {
        this.onChatClip = onChatClip;
    }

    setOnFocusClip(onFocusClip?: (clipId?: string) => void) {
        this.onFocusClip = onFocusClip;
    }

    @action
    setActiveMediaClips(activeClips?: MediaClip[]) {
        this.activeClips = activeClips?.sort(
            (a, b) => a.startTime - b.startTime,
        );
    }

    @action
    setRecords(records?: IChatRecord[]) {
        this.records = records ?? [];
    }

    @action
    setEddyEffectP2Results(eddy: EddyEffectP2Results[]) {
        this.eddyEffectP2Data = eddy ?? [];
    }

    @computed
    get isClipping() {
        return this.clipSet.size > 0;
    }

    @computed
    get chatWords() {
        return parseChatToWords(this.records);
    }

    @action
    toggleRecordToClipSet(recordIndex: number) {
        if (this.clipSet.has(recordIndex)) {
            this.clipSet.delete(recordIndex);
        } else {
            this.clipSet.add(recordIndex);
        }
    }

    @action
    clipRecordsInSet = () => {
        const recordIndices = [...this.clipSet.keys()].sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        });

        const startIndex = recordIndices[0];
        const lastIndex = recordIndices[recordIndices.length - 1];

        const recordMessages = range(startIndex, lastIndex + 1).map((value) => {
            const rec = this.augmentedRecords[value];
            const msgTxt = rec.text ?? "";
            return !msgTxt.endsWith(".") ? `${msgTxt}.` : msgTxt;
        });

        this.onChatClip?.(recordMessages.join(" "), startIndex, lastIndex);
        this.clipSet.clear();
    };
}
