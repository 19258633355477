import { Theme, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Rating } from '@mui/material';
import React, { FunctionComponent, useState } from "react";
import AcxInputLabel from "./AcxInputLabel";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    labelText: {
        fontSize: "13px",
        letterSpacing: 0,
        lineHeight: "20px",
        userSelect: "none",
    },
    ratingLabelText: {
        color: theme.palette.text.secondary,
        fontSize: "12px",
        letterSpacing: "0",
        lineHeight: "20px",
        userSelect: "none",
    },
}));

interface OwnProps {
    id: string; // THIS MUST BE UNIQUE (not sure why but it will break if not)
    value?: number | null;
    ratingLabels?: Record<number, string>;
    onChange?: (newRating: number | null) => void;
    label?: string;
    max: number;
    precision?: number;
    disabled?: boolean;
    size?: "large" | "medium" | "small";

    iconFilledColor?: string;
    iconHoverColor?: string;

    infoText?: string;
}

type Props = OwnProps;

const AcxStarRating: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const classes = useStyles();
    const [focused, setFocused] = useState<boolean | undefined>();
    const [hover, setHover] = React.useState<number | undefined>(undefined);

    const StyledRating = withStyles((theme: Theme) => ({
        iconFilled: {
            color: props.iconFilledColor ?? theme.palette.secondary.main,
        },
        iconHover: {
            color: props.iconHoverColor ?? theme.palette.secondary.light,
        },
    }))(Rating);

    return (
        <div className={classes.root} onMouseLeave={() => setHover(undefined)}>
            <div>
                {props.label && (
                    <AcxInputLabel
                        id={`StarRating-${props.label}`}
                        infoText={props.infoText}
                        focused={focused}
                        labelText={props.label}
                        disabled={props.disabled}
                        className={classes.labelText}
                    />
                )}
                <StyledRating
                    disabled={props.disabled}
                    size={props.size}
                    onMouseOver={() => setFocused(true)}
                    onMouseLeave={() => setFocused(false)}
                    onChangeActive={(event, newHover) => {
                        setHover(newHover);
                    }}
                    name={"StarRating-" + props.id}
                    max={props.max}
                    precision={props.precision ?? 1}
                    value={props.value}
                    emptyIcon={<StarBorderIcon fontSize="inherit" />}
                    onChange={(event, newValue) => {
                        props.onChange?.(newValue);
                    }}
                />
            </div>
            {props.ratingLabels && hover !== undefined && (
                <div style={{ marginLeft: 1, paddingTop: 0 }}>
                    <Typography className={classes.ratingLabelText}>
                        {props.ratingLabels[hover]}
                    </Typography>
                </div>
            )}
        </div>
    );
};

export default AcxStarRating;
