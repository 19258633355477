import { Grid } from "@mui/material";
import { MontageSoundClipStore } from "components/Montage/Stores/MontageSoundClipStore";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxMainTextField from "components/UI/AcxMainTextField";
import { observer } from "mobx-react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    formatHmsToSeconds,
    formatSecondsToHms,
    validateHmsFormat,
} from "./utils";

import theme from "Theme/AppTheme";
import IncrementDecrement from "./Components/IncrementDecrement";
import SoundClipEditorStore from "./Stores/SoundClipEditorStore";

interface RefineClipDialogProps {
    viewModel: SoundClipEditorStore;
    montageStore: MontageSoundClipStore;
    refineSegmentDialogOpen: boolean;
    setRefineSegmentDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const RefineClipDialog: React.FC<RefineClipDialogProps> = observer(
    ({
        viewModel,
        montageStore,
        refineSegmentDialogOpen,
        setRefineSegmentDialogOpen,
    }) => {
        const { startTime, endTime } = viewModel.latestSegment ?? {};
        const [startTimeHms, setStartTimeHms] = useState<string>(
            formatSecondsToHms(startTime) ?? "00:00",
        );
        const [endTimeHms, setEndTimeHms] = useState<string>(
            formatSecondsToHms(endTime) ?? "00:00",
        );
        const [timeError, setTimeError] = useState({
            start: false,
            end: false,
        });

        const initiateRefinedSegmentRemoval = () => {
            viewModel.removeSegment(
                montageStore.pushUndoStack,
                viewModel.latestSegment?.startTime,
                viewModel.latestSegment?.endTime,
            );
            viewModel.setLatestSegmentStartTime(undefined);
            viewModel.setLatestSegmentEndTime(undefined);
            setRefineSegmentDialogOpen(false);
            viewModel.closeSegmentMenu();
        };

        const onStartChange = (time: string) => {
            setStartTimeHms(time);
            const isBeforeEndTime =
                formatHmsToSeconds(time) < formatHmsToSeconds(endTimeHms);
            if (!validateHmsFormat(time) || !isBeforeEndTime) {
                setTimeError({ ...timeError, start: true });
            } else {
                viewModel.setLatestSegmentStartTime(formatHmsToSeconds(time));
                setTimeError({ ...timeError, start: false });
            }
        };

        const onEndChange = (time: string) => {
            setEndTimeHms(time);
            const isAfterStartTime =
                formatHmsToSeconds(startTimeHms) < formatHmsToSeconds(time);
            const isLessThanTotalDuration =
                viewModel.clipAudioBuff &&
                formatHmsToSeconds(time) <= viewModel.clipAudioBuff.duration;
            if (
                !validateHmsFormat(time) ||
                !isAfterStartTime ||
                !isLessThanTotalDuration
            ) {
                setTimeError({ ...timeError, end: true });
            } else {
                viewModel.setLatestSegmentEndTime(formatHmsToSeconds(time));
                setTimeError({ ...timeError, end: false });
            }
        };

        const onStartKeyDown: (
            evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => void = (e) => {
            if (!validateHmsFormat(startTimeHms)) return;
            if (e.key === "ArrowUp") {
                e.preventDefault();
                increment("start");
            }
            if (e.key === "ArrowDown") {
                e.preventDefault();
                decrement("start");
            }
        };

        const onEndKeyDown: (
            evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => void = (e) => {
            if (!validateHmsFormat(endTimeHms)) return;
            if (e.key === "ArrowUp") {
                e.preventDefault();
                increment("end");
            }
            if (e.key === "ArrowDown") {
                e.preventDefault();
                decrement("end");
            }
        };

        const increment = (field: "start" | "end") => {
            const seconds = formatHmsToSeconds(
                field === "start" ? startTimeHms : endTimeHms,
            );
            if (
                field === "start" &&
                seconds + 1 < formatHmsToSeconds(endTimeHms)
            ) {
                onStartChange(formatSecondsToHms(seconds + 1));
            } else if (field === "end" && viewModel.clipAudioBuff) {
                onEndChange(
                    formatSecondsToHms(
                        seconds + 1 <= viewModel.clipAudioBuff.duration
                            ? seconds + 1
                            : viewModel.clipAudioBuff.duration,
                    ),
                );
            }
        };

        const decrement = (field: "start" | "end") => {
            const seconds = formatHmsToSeconds(
                field === "start" ? startTimeHms : endTimeHms,
            );
            if (field === "start" && seconds - 1 >= 0) {
                onStartChange(formatSecondsToHms(seconds - 1));
            } else if (
                field === "end" &&
                seconds - 1 > formatHmsToSeconds(startTimeHms)
            ) {
                onEndChange(formatSecondsToHms(seconds - 1));
            }
        };

        useEffect(() => {
            if (startTime) {
                setStartTimeHms(formatSecondsToHms(startTime));
            }
            if (endTime) {
                setEndTimeHms(formatSecondsToHms(endTime));
            }
        }, [endTime, startTime]);

        const disabled = timeError.start || timeError.end;

        return (
            <AcxConfirmationDialog
                isOpen={refineSegmentDialogOpen}
                onClose={() => setRefineSegmentDialogOpen(false)}
                title="Edit Segment"
                subTitle="Indicate both the start and end time for the segment you want to remove."
                content={
                    <Grid container item xs={12}>
                        <Grid item xs={6}>
                            <AcxMainTextField
                                type="text"
                                labelText={"Start Time"}
                                inputProps={{
                                    min: 0,
                                    max:
                                        Math.floor(
                                            viewModel.latestSegment?.endTime ??
                                                0,
                                        ) - 1,
                                }}
                                onKeyDown={onStartKeyDown}
                                id="segmentRemovalStartTimeInput"
                                value={startTimeHms}
                                onChange={(e) => {
                                    onStartChange(e.target.value);
                                }}
                                error={timeError.start}
                                autoComplete="off"
                                endAdornment={
                                    <IncrementDecrement
                                        onIncrement={() => {
                                            increment("start");
                                        }}
                                        onDecrement={() => {
                                            decrement("start");
                                        }}
                                        style={{
                                            marginRight: theme.spacing(0.5),
                                        }}
                                    />
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AcxMainTextField
                                type="text"
                                labelText={"End Time"}
                                inputProps={{
                                    min:
                                        Math.floor(
                                            viewModel.latestSegment
                                                ?.startTime ?? 0,
                                        ) + 1,
                                    max: viewModel.clipAudioBuff?.duration ?? 0,
                                }}
                                id="segmentRemovalEndTimeInput"
                                value={endTimeHms}
                                onChange={(e) => {
                                    onEndChange(e.target.value);
                                }}
                                onKeyDown={onEndKeyDown}
                                error={timeError.end}
                                autoComplete="off"
                                endAdornment={
                                    <IncrementDecrement
                                        onIncrement={() => {
                                            increment("end");
                                        }}
                                        onDecrement={() => {
                                            decrement("end");
                                        }}
                                        style={{
                                            marginRight: theme.spacing(0.5),
                                        }}
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                }
                confirmButtonText="Remove"
                cancelButtonText="Cancel"
                alternateSpinnerType={"BeatLoader"}
                spinnerSize={12}
                onConfirm={initiateRefinedSegmentRemoval}
                confirmButtonDisabled={disabled}
            />
        );
    },
);

export default RefineClipDialog;
