import NavigationStore, {
    INavigation,
} from "Layouts/SideNavigation/NavigationStore";
import { action, makeObservable, observable } from "mobx";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";

export enum WorkflowsPage {
    WORKFLOWS_AGING = "aging",
}

@AcxStore
export default class WorkflowsNavigationStore extends BaseStore {
    readonly navStore: NavigationStore;
    readonly WORKFLOWS_ROUTE_PREFIX = "/app/workflows/";

    @observable.ref
    navItems: INavigation[];

    @observable
    currentPage: WorkflowsPage;

    constructor(private rootStore: IRootStore) {
        super("WorkflowsNavigationStore");

        makeObservable(this);

        this.navStore = new NavigationStore(
            this.rootStore,
            "WorkflowsNavigation",
            this.navItems,
            undefined,
        );
    }

    @action
    setCurrentPage = (page: string) => {
        this.currentPage = WorkflowsPage[page];
    };

    @action
    setNavItems = (items: INavigation[]) => {
        this.navItems = items;
    };
}
