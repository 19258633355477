import { Button, Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import VerticalDrawerContentTemplate from "../../components/UI/Drawer/VerticalDrawerContentTemplate";
import { BaseWidgetDefinition } from "../../components/Widgets/Definitions/BaseWidgetDefinition";
import { DashboardStore } from "../../stores/Layout/DashboardStore";
import DashboardControls from "./DashboardControls";

const useStyles = makeStyles((theme: Theme) => ({
    widgetName: {
        color: theme.palette.text.secondary,
        fontSize: "15px",

        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontFamily: theme.typography.fontFamily,
        width: "100%",

        // fontStyle: "italic",
        // fontSize: "23px",
    },
    saveControls: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
    },
    drawerTitle: {
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontFamily: theme.typography.fontFamily,
        width: "100%",
    },
}));

interface OwnProps {
    item: BaseWidgetDefinition;
    dashboardStore?: DashboardStore;
    onClose?: () => void;
    width: string;
}

type Props = OwnProps;

const WidgetEditor: FunctionComponent<Props> = observer((props) => {
    const classes = useStyles();

    function saveUserCustom() {
        props.dashboardStore?.saveChanges?.();
        props.dashboardStore?.closeEditWidgetDrawer?.();
    }

    function saveDefault() {
        props.dashboardStore?.saveChangesAsDefault?.();
        props.dashboardStore?.closeEditWidgetDrawer?.();
    }

    function onClose() {
        props.dashboardStore?.cancelEditing?.();
        props.onClose?.();
    }

    return (
        <VerticalDrawerContentTemplate
            width={props.width}
            onClose={onClose}
            title={
                <Typography
                    className={classes.drawerTitle}
                    noWrap
                    variant={"h1"}
                >
                    Widget Customizer
                </Typography>
            }
            subTitle={
                <span className={classes.widgetName}>{props.item.title}</span>
            }
            content={
                <>
                    <Grid
                        sx={(theme) => ({
                            marginTop: theme.spacing(1.5),
                            marginBottom: theme.spacing(1.5),
                            paddingLeft: theme.spacing(0.5),
                            paddingRight: theme.spacing(0.5),
                        })}
                        spacing={2}
                        container
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                    >
                        {Boolean(
                            props.dashboardStore?.pendingWidgetControlChanges,
                        ) && (
                            <Grid item>
                                <Button
                                    variant={"outlined"}
                                    style={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                    }}
                                    disableElevation
                                    size={"small"}
                                    color={"secondary"}
                                    onClick={saveUserCustom}
                                >
                                    Save
                                </Button>
                            </Grid>
                        )}
                        {Boolean(
                            props.dashboardStore?.canBuildDefaultDashboard &&
                                props.dashboardStore
                                    ?.pendingWidgetControlChanges,
                        ) && (
                            <Grid item>
                                <Button
                                    variant={"outlined"}
                                    style={{
                                        textTransform: "none",
                                        fontWeight: "bold",
                                    }}
                                    disableElevation
                                    size={"small"}
                                    color={"primary"}
                                    onClick={saveDefault}
                                >
                                    Save Default
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    {props.dashboardStore && (
                        <DashboardControls
                            forceEditMode
                            dashboardStore={props.dashboardStore}
                            widgetDefinition={props.item}
                            orientation={"vertical"}
                        />
                    )}
                </>
            }
        />
    );
});

export default WidgetEditor;
