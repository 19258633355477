import React from "react";
import { Route, Routes } from "react-router-dom";
import OrganizationDetailContainer from "./OrganizationDetail/OrganizationDetailContainer";
import OrganizationsList from "./OrganizationsList";

const OrganizationsContainer = () => {
    // const { path } = useRouteMatch();

    return (
        <Routes>
            <Route
                path=":organizationId/*"
                element={<OrganizationDetailContainer />}
            />
            <Route path="organizationList" element={<OrganizationsList />} />
            {/* <Route path={path} >
                <OrganizationsList />
            </Route>
            <Route path={`${path}/:organizationId`}>
                <OrganizationDetailContainer />
            </Route> */}
        </Routes>
    );
};

export default OrganizationsContainer;
