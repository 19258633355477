import React from "react";
import EditSvg from "SvgIcons/EditSvg";
import QuoteSvg from "SvgIcons/QuoteSvg";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton } from "@mui/material";

type WFRQuoteNoteButtonContainerProps = {
    toggleNote: () => void;
    showNote: boolean;
    toggleQuote: () => void;
    showQuote: boolean;
    toggleAnswerAlert: () => void;
    flagActive: boolean;
    interactionType?: number;
    restrictMedia?: boolean;
    isDisabled?: boolean;
    hideNoteButton?: boolean;
};

interface QuoteNoteButtonProps {
    onClick: () => void;
    isActive?: boolean;
    IconComponent: React.ElementType;
    iconProps: object;
    iconSize: { width: string; height: string };
}

const useQuoteNoteStyles = makeStyles((theme) => ({
    buttonContainer: {
        textAlign: "center",
    },
    iconButton: {
        marginBottom: "0.5rem",
        padding: "8px",
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        "&:hover": { backgroundColor: theme.palette.primary[300] },
    },
    inactive: {
        backgroundColor: "#e6e6e6",
    },
    activeIcon: {
        color: "white",
    },
    inactiveIcon: {
        color: "#1F1F1F",
        opacity: 0.25,
    },
}));

const QuoteNoteButton: React.FC<QuoteNoteButtonProps> = ({
    onClick,
    isActive,
    IconComponent,
    iconProps,
    iconSize,
}) => {
    const classes = useQuoteNoteStyles();
    return (
        <Grid item className={classes.buttonContainer}>
            <IconButton
                onClick={onClick}
                className={`${classes.iconButton} ${
                    isActive ? classes.active : classes.inactive
                }`}
                size="large"
            >
                <IconComponent
                    {...iconSize}
                    className={
                        isActive ? classes.activeIcon : classes.inactiveIcon
                    }
                    {...iconProps}
                />
            </IconButton>
        </Grid>
    );
};

const WFRQuoteNoteButtonContainer: React.FC<WFRQuoteNoteButtonContainerProps> =
    ({
        toggleNote,
        showNote,
        toggleQuote,
        showQuote,
        interactionType,
        restrictMedia,
        hideNoteButton,
    }) => {
        const iconSize = { width: "20px", height: "20px" };

        return (
            <>
                {!hideNoteButton && (
                    <QuoteNoteButton
                        onClick={toggleNote}
                        isActive={showNote}
                        IconComponent={EditSvg}
                        iconProps={{
                            width: "20px",
                            height: "20px",
                        }}
                        iconSize={iconSize}
                    />
                )}
                {interactionType !== 4 && !restrictMedia && false && (
                    <QuoteNoteButton
                        onClick={toggleQuote}
                        isActive={showQuote}
                        IconComponent={QuoteSvg}
                        iconProps={{
                            width: "20px",
                            height: "20px",
                        }}
                        iconSize={iconSize}
                    />
                )}
            </>
        );
    };

export default WFRQuoteNoteButtonContainer;
