import { observer } from "mobx-react";
import React from "react";
import {
    ConversationClassifier,
    Highlight,
} from "services/ConversationService";

import RBCSearchHighlight from "./RBCSearchHighlight";
import { filterHighlightData } from "./utils";
import { FTSHighlights } from "./FTSHighlights";
import { Grid } from "@mui/material";

interface Props {
    highlights: Highlight;
    conversationClassifiers: ConversationClassifier[];
    isChat: boolean;
    disableChips?: boolean;
}

const ConversationHighlights: React.FC<Props> = observer(
    ({ highlights, conversationClassifiers, disableChips, isChat }) => {
        const filteredHighlights = filterHighlightData(highlights);
        if (!filteredHighlights) {
            return null;
        }
        return (
            <Grid container gap={1} direction="column" className="pendo-ignore">
                <Grid item>
                    <FTSHighlights
                        highlights={filteredHighlights}
                        disableAllChips={disableChips}
                        isChat={isChat}
                    />
                </Grid>
                {filteredHighlights?.rbcHighlightResults.map((result, idx) => {
                    return (
                        <Grid item>
                            <RBCSearchHighlight
                                key={`${result.classifierId} ${idx}`}
                                RBCHighlight={result}
                                conversationClassifiers={
                                    conversationClassifiers
                                }
                                disableChips={disableChips}
                                isChat={isChat}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    },
);

export default ConversationHighlights;
