import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import clsx from "clsx";
import React from "react";
import useStyles from "Styles/Styles";
import AcxInputLabel from "./AcxInputLabel";

const styles = (theme: Theme) => {
    return createStyles({
        helperText: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
        helperTextError: {
            color: `${theme.palette.error.main} !important`,
        },
    });
};

type Props = {
    errors?: string | string[];
};

const AcxErrorMessage: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ errors }) => {
    const classes = useStyles(styles);

    if (!errors) {
        return <></>;
    }

    return (
        <AcxInputLabel
            id={"AcxText-BottomLabel"}
            className={clsx({
                [classes.helperText]: true,
                [classes.helperTextError]: true,
            })}
            showAllErrors={true}
        >
            {Array.isArray(errors)
                ? errors.join("\n")
                : typeof errors === "string"
                ? errors
                : JSON.stringify(errors)}
        </AcxInputLabel>
    );
};

export default AcxErrorMessage;
