import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Organization } from "./types/Organization.type";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    org: Organization;
    toggleOrganization: (org: Organization) => Promise<boolean>;
};

const OrganizationArchiveConfirmationDialog: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    isOpen,
    onClose,
    org,
    toggleOrganization,
}) => {
    const [isArchiving, setIsArchiving] = useState<boolean>(false);
    const [verb, setVerb] = useState<string>();

    useEffect(() => {
        setVerb(org.isActive ? "Archive" : "Restore");
    }, [org.isActive]);

    const confirm = async () => {
        setIsArchiving(true);
        const res = await toggleOrganization(org);
        setIsArchiving(false);
        if (res) {
            onClose();
        }
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>{verb} organization?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure that you would like to {verb?.toLowerCase()}{" "}
                    the organization,
                    {org.name}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isArchiving}>
                    Cancel
                </Button>
                <Button
                    onClick={confirm}
                    color="secondary"
                    autoFocus
                    disabled={isArchiving}
                >
                    {verb}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrganizationArchiveConfirmationDialog;
