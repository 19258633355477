import { observer } from "mobx-react";
import Organization from "models/Organization";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import FocusControls from "../../../Layouts/Focus/FocusControls";
import { useStore } from "../../../utils/useStore";
import { ReportEditorStore } from "../Stores/ReportEditorStore";
import ReportsStore from "../Stores/ReportsStore";
import Editor from "./Views/Editor";

// interface MyParams {
//     orgId: string;
//     reportId: string;
// }

const ReportEditor = observer(() => {
    const store = useStore(ReportEditorStore);
    const reportStore = useStore(ReportsStore);
    const authStore = useStore(AuthStore);
    const navigate = useNavigate();
    const params = useParams();

    React.useEffect(() => {
        if (authStore.orgStore.selectedOrganization?.id !== params.orgId) {
            authStore.orgStore?.onActiveOrgIdChange(params.orgId);
            authStore.orgStore?.setGlobalOrganization(
                authStore.orgStore.organizations?.find(
                    (org) => org.id === params.orgId,
                )! as Organization,
            );
        }

        if (authStore._user) {
            store.initializeFromReportStore(
                authStore._user,
                params.orgId,
                params.reportId,
            );
        }
    }, [
        authStore._user,
        authStore.orgStore,
        params.orgId,
        params.reportId,
        store,
    ]);

    async function onSave() {
        const isSuccess = await store.saveReport();
        if (isSuccess) {
            reportStore.quickFilterStore.reset();
            reportStore.refreshReports();
            navigate(-1);
        }
    }

    function onCancel() {
        setTimeout(() => {
            store.reset();
        }, 500);
    }

    return (
        <FocusControls
            title={"Report Editor"}
            loading={store.anyTaskLoading}
            onSave={onSave}
            onCancel={onCancel}
            saveText={store.currentReport?.id ? "Update" : "Save"}
            controlHeader={null}
            cancelText={"Cancel"}
            mainContent={<Editor />}
            showSave={store.isNew || store.isEditable}
        />
    );
});

export default ReportEditor;
