import { Box } from "@mui/material";
import ConversationsStore from "components/Conversations/Stores/ConversationsStore";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import AdverseEventsSection from "./AdverseEventsSection";
import EddySection from "./EddySection";
import TopicsContainer from "../TopicsContainer";
import SummarySection from "./SummarySection";

const ConversationSummaryHighlights: React.FC = observer(() => {
    const store = useStore(ConversationsStore);

    return (
        <Box>
            <SummarySection store={store} />
            <TopicsContainer topics={store.topTopics} />
            {store.selectedConversation?.hasEddyEffect.result && (
                <EddySection store={store} />
            )}
            {store.selectedConversation?.hasAdverseEvent.result && (
                <AdverseEventsSection store={store} />
            )}
        </Box>
    );
});

export default ConversationSummaryHighlights;
