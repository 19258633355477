import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import React, { CSSProperties, FunctionComponent } from "react";
import AcxLoadingIndicator from "../AcxLoadingIndicator";

interface OwnProps {
    title?: React.ReactNode;
    subTitle?: React.ReactNode;
    headerStyle?: CSSProperties;

    mainContent?: React.ReactNode;
    mainContentStyle?: CSSProperties;

    mediaImageUrl?: string;
    mediaTitle?: string;

    actions?: React.ReactNode[];

    rootStyle?: CSSProperties;

    loading?: boolean;
    loadingSize?: number;
}

type Props = OwnProps;

const AcxCard: FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
    return (
        <Card
            sx={{
                maxWidth: "400px",
                width: "100%",
                display: "flex",
                alignItems: "stretch",
                flexDirection: "column",
                justifyContent: "flex-start",
            }}
            style={props.rootStyle}
        >
            <CardHeader
                title={props.title}
                subheader={props.subTitle}
                sx={{ flex: "0 1 auto", justifyContent: "center" }}
                style={props.headerStyle}
            />
            <CardContent
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flex: "2 0 auto",
                }}
                style={props.mainContentStyle}
            >
                {props.loading ? (
                    <AcxLoadingIndicator
                        color="secondary"
                        alternate="PuffLoader"
                        size={props.loadingSize ?? 125}
                    />
                ) : (
                    props.mainContent
                )}
            </CardContent>

            {Boolean(props.actions) && (
                <CardActions
                    disableSpacing
                    sx={{
                        flex: "1 1 0",
                        display: "flex",
                        alignItems: "flex-end",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignContent: "space-evenly",
                        justifyContent: "flex-end",
                        padding: 1, // 8px
                    }}
                >
                    {props.actions}
                </CardActions>
            )}
        </Card>
    );
};

export default AcxCard;
