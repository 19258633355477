import {
    Divider,
    Paper,
    Typography,
    Grid,
    styled,
    IconButton,
} from "@mui/material";
import AcxButton from "components/UI/AcxButton";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { ApplicationFilterItemsStore } from "stores/ApplicationFilters/ApplicationFilterItemsStore";
import type { IApplicationFilterItem } from "stores/ApplicationFilters/ApplicationFilterItemsStore";
import {
    ApplicationFiltersStore,
    SavedFilter,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import { useStore } from "utils/useStore";
import ApplicationFilterItem from "./Filters/ApplicationFilterItem";
import SaveFiltersButton from "./SaveFiltersButton";
import { ApplicationFiltersInstanceStore } from "stores/ApplicationFilters/ApplicationFiltersInstanceStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { SavedFilterSettings } from "./SavedFilterSettings";
import { AddOutlined, Close } from "@mui/icons-material";
import SignalsReportStore from "components/Signals/Store/SignalsReportStore";
import { ApplicationFilterDomain } from "services/ApplicationFiltersService";

const SectionContainer = styled(Grid)(({ theme }) => ({
    paddingInline: theme.spacing(3),
    paddingBlock: theme.spacing(2.5),
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.grey[700],
    fontSize: "20px",
}));

const SubtitleTypography = styled(Typography)(({ theme }) => ({
    color: "#334155",
    fontSize: "14px",
}));

const FilterItemPaper = styled(Paper)(({ theme }) => ({
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: "0px 1px 2px 0px #00000012",
    transition: "opacity 0.3s ease",
    '& [id^="remove-filter"]': {
        opacity: "0",
    },
    '&:hover [id^="remove-filter"]': {
        opacity: "1",
    },
}));

const FiltersBarContainer = styled(Grid)(({ theme }) => ({
    backgroundColor: theme.palette.grey[50],
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.grey[300]}`,
}));

interface ManageApplicationFiltersProps {
    title?: string;
    subtitle?: string;
    onCancel?(): void;
    onApply?(instanceStore: ApplicationFiltersInstanceStore): void;
}

const ManageApplicationFilters = observer(function (
    props: ManageApplicationFiltersProps,
) {
    const messageStore = useStore(MessageStore);
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const applicationFilterItemsStore = useStore(ApplicationFilterItemsStore);
    const signalsReportStore = useStore(SignalsReportStore);

    const [isSelectFilterVisible, setIsSelectFilterVisible] = useState(false);

    const [updatedFilter, setUpdatedFilter] = useState<
        SavedFilter | undefined
    >();

    const instanceStore = useMemo(() => {
        applicationFilterItemsStore.updateSelectedFilterItems(
            applicationFiltersStore,
        );
        const instanceStore = applicationFiltersStore.toInstance();
        instanceStore.saveCurrentFilterState("Update");
        return instanceStore;
    }, [applicationFilterItemsStore, applicationFiltersStore]);

    const filterLabel = useMemo(() => {
        const appliedFilterCount = instanceStore.appliedFilterCount ?? 0;

        if (appliedFilterCount === 1) return "1 Filter Applied";
        return `${appliedFilterCount} Filters Applied`;
    }, [instanceStore.appliedFilterCount]);

    const isFilterPinnedToAnyDashboards =
        signalsReportStore.allDashboardsUserCanView.some(
            (d) =>
                d.pinnedApplicationFilterId ===
                applicationFiltersStore.selectedSavedFilter?.id,
        );
    const isFilterSharedAndPinnedToAnyDashboards =
        applicationFiltersStore.selectedSavedFilter?.domain ===
            ApplicationFilterDomain.Organization &&
        isFilterPinnedToAnyDashboards;

    return (
        <Grid
            container
            direction="column"
            sx={{ height: "100%" }}
            flexWrap="nowrap"
        >
            <SectionContainer
                container
                item
                direction="column"
                flexWrap="nowrap"
            >
                <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <TitleTypography>
                            {props.title ?? "Filters"}
                        </TitleTypography>
                    </Grid>
                    <Grid item>
                        <IconButton size="small" onClick={props.onCancel}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item>
                    <SubtitleTypography>
                        {props.subtitle ??
                            `Define the criteria that will determine which
                        conversations are included or excluded in Signals
                        reporting.`}
                    </SubtitleTypography>
                </Grid>
            </SectionContainer>
            <SectionContainer
                container
                item
                direction="row"
                columnGap={3}
                flexGrow={1}
                flexWrap="nowrap"
                sx={(theme) => ({
                    overflowY: "auto",
                    backgroundColor: theme.palette.grey[50],
                })}
            >
                {!!applicationFiltersStore.selectedSavedFilter && (
                    <Grid
                        container
                        item
                        sx={{
                            width: "fit-content",
                            position: "sticky",
                            top: 0,
                        }}
                    >
                        <FilterItemPaper sx={{ height: "fit-content" }}>
                            <SavedFilterSettings
                                defaultSavedFilter={
                                    applicationFiltersStore.selectedSavedFilter
                                }
                                onSavedFilterChange={(filter) =>
                                    setUpdatedFilter(filter)
                                }
                                disabled={
                                    !applicationFiltersStore.isSelectedSavedFilterEditable
                                }
                                isPrivatable={
                                    !isFilterSharedAndPinnedToAnyDashboards
                                }
                                isDeletable={!isFilterPinnedToAnyDashboards}
                            />
                        </FilterItemPaper>
                    </Grid>
                )}
                <Grid
                    container
                    item
                    direction="column"
                    flexWrap="nowrap"
                    rowGap={2}
                >
                    <FiltersBarContainer
                        container
                        item
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap="nowrap"
                        width="100%"
                    >
                        <Grid item>
                            <Typography>{filterLabel}</Typography>
                        </Grid>
                        <Grid
                            container
                            item
                            columnSpacing={1}
                            alignItems="center"
                            width="fit-content"
                        >
                            <Grid item>
                                <AcxButton
                                    id="signals-filters-clear-builder"
                                    color="primary"
                                    variant="text"
                                    onClick={() => {
                                        instanceStore.resetFilters();
                                        applicationFilterItemsStore.setSelectedFilterItems(
                                            [],
                                        );
                                        applicationFiltersStore.setSelectedSavedFilters();
                                    }}
                                    sx={(theme) => ({
                                        color: theme.palette.primary.main,
                                        ":hover": {
                                            backgroundColor:
                                                theme.palette.white.main,
                                        },
                                    })}
                                >
                                    Clear
                                </AcxButton>
                            </Grid>
                        </Grid>
                    </FiltersBarContainer>
                    {applicationFilterItemsStore.selectedFilterItems.map(
                        (item, i) => {
                            let store = instanceStore;
                            if (!applicationFiltersStore.selectedSavedFilter) {
                                if (
                                    item.filterKey === "dateRange" ||
                                    item.filterKey === "dateReferenceOption" ||
                                    item.filterKey === "hierarchyIds"
                                )
                                    store =
                                        applicationFiltersStore.quickFiltersStore;
                            }

                            return (
                                <React.Fragment key={item.filterKey}>
                                    {i !== 0 && (
                                        <Grid
                                            container
                                            item
                                            direction="row"
                                            spacing={1}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Typography
                                                    variant="body1"
                                                    color="textSecondary"
                                                    sx={(theme) => ({
                                                        fontWeight: "bold",
                                                        color: theme.palette
                                                            .gray[400],
                                                        userSelect: "none",
                                                    })}
                                                >
                                                    AND
                                                </Typography>
                                            </Grid>
                                            <Grid item flexGrow={1}>
                                                <Divider />
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid item flexShrink={1}>
                                        <FilterItemPaper>
                                            <ApplicationFilterItem
                                                {...item}
                                                store={store}
                                                instanceStore={instanceStore}
                                            />
                                        </FilterItemPaper>
                                    </Grid>
                                </React.Fragment>
                            );
                        },
                    )}

                    {applicationFilterItemsStore.selectedFilterItems.length >
                        0 && (
                        <Grid item>
                            <Divider />
                        </Grid>
                    )}

                    {isSelectFilterVisible && (
                        <Grid item>
                            <FilterItemPaper>
                                <AcxSelectSingle
                                    id="add-filter-select"
                                    options={applicationFilterItemsStore.availableFilterGroups.map(
                                        (group) => ({
                                            label: group.title,
                                            options: group.items,
                                        }),
                                    )}
                                    labelField="title"
                                    valueField=""
                                    noValue
                                    onChange={(
                                        option: IApplicationFilterItem,
                                    ) => {
                                        applicationFilterItemsStore.addSelectedFilterItem(
                                            option,
                                        );
                                        setIsSelectFilterVisible(false);
                                    }}
                                    placeholder="Filter by..."
                                />
                            </FilterItemPaper>
                        </Grid>
                    )}

                    <Grid
                        item
                        sx={(theme) => ({ paddingBottom: theme.spacing(2.5) })}
                    >
                        <AcxButton
                            color="primary"
                            onClick={() => setIsSelectFilterVisible(true)}
                            sx={(theme) => ({
                                color: theme.palette.blue.main,
                                backgroundColor: theme.palette.white.main,
                                paddingInline: theme.spacing(2),
                                paddingBlock: theme.spacing(1),
                                borderRadius: theme.spacing(0.5),
                                border: `1px solid ${theme.palette.gray[200]}`,
                                fontSize: "14px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                ":hover": {
                                    backgroundColor: theme.palette.white.main,
                                    opacity: "80%",
                                },
                            })}
                            startIcon={
                                <AddOutlined sx={{ fontSize: "20px" }} />
                            }
                        >
                            Filter
                        </AcxButton>
                    </Grid>
                </Grid>
            </SectionContainer>
            <SectionContainer
                container
                item
                sx={{
                    justifyContent: "flex-end",
                }}
                columnSpacing={1}
            >
                {/* "Secondary" button */}
                <Grid item>
                    {!applicationFiltersStore.selectedSavedFilter && (
                        <AcxButton
                            color="white"
                            onClick={() => props.onApply?.(instanceStore)}
                        >
                            Apply
                        </AcxButton>
                    )}
                    {applicationFiltersStore.selectedSavedFilter && (
                        <SaveFiltersButton
                            store={instanceStore}
                            color="white"
                            variant="dialog"
                            showVisibilityToggle
                            defaultFilter={updatedFilter}
                            onSave={() => props.onApply?.(instanceStore)}
                        >
                            Save as New
                        </SaveFiltersButton>
                    )}
                </Grid>
                {/* "Primary" button */}
                <Grid item>
                    {/* Casse 1: No saved filter selected */}
                    {!applicationFiltersStore.selectedSavedFilter && (
                        <SaveFiltersButton
                            id="signals-filters-save-and-apply"
                            store={instanceStore}
                            color="primary"
                            variant="dialog"
                            showVisibilityToggle
                            onSave={() => props.onApply?.(instanceStore)}
                        >
                            Save and Apply
                        </SaveFiltersButton>
                    )}
                    {/* Case 2: Saved filter selected and they are the owner of it */}
                    {!!applicationFiltersStore.selectedSavedFilter &&
                        applicationFiltersStore.isSelectedSavedFilterEditable && (
                            <AcxButton
                                id="signals-filters-update-save-and-apply"
                                color="primary"
                                disabled={
                                    (!instanceStore.hasChangedSinceLastUpdate &&
                                        !updatedFilter) ||
                                    (isFilterSharedAndPinnedToAnyDashboards &&
                                        updatedFilter?.domain ===
                                            ApplicationFilterDomain.User)
                                }
                                loading={applicationFiltersStore.getTaskLoading(
                                    ApplicationFiltersStore.Tasks.UPDATE_FILTER,
                                )}
                                onClick={async () => {
                                    if (
                                        !applicationFiltersStore.isSelectedSavedFilterEditable
                                    )
                                        return;

                                    const success =
                                        await applicationFiltersStore.updateSelectedSavedFilterWith(
                                            instanceStore,
                                            updatedFilter?.name,
                                            updatedFilter?.domain,
                                        );

                                    if (!success)
                                        return messageStore.logError(
                                            "Failed to update filter. Please try again.",
                                        );

                                    messageStore.logInfo(
                                        `'${
                                            applicationFiltersStore.selectedSavedFilter!
                                                .name
                                        }' successfully updated.`,
                                    );

                                    props.onApply?.(instanceStore);
                                }}
                            >
                                Save and Apply
                            </AcxButton>
                        )}
                    {/* Case 3: Saved filter selected and they are NOT the owner of it */}
                    {!!applicationFiltersStore.selectedSavedFilter &&
                        !applicationFiltersStore.isSelectedSavedFilterEditable && (
                            <AcxButton
                                color="primary"
                                onClick={() => props.onApply?.(instanceStore)}
                            >
                                Apply
                            </AcxButton>
                        )}
                </Grid>
            </SectionContainer>
        </Grid>
    );
});

export default ManageApplicationFilters;
