import { observer } from "mobx-react";
import React from "react";
import WorkflowInstanceList from "./WFRInstanceList";
import { Box, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import {
    ModulesLoading,
    WorkflowReviewStore,
} from "../Stores/WorkflowReviewStore";
import { useStore } from "utils/useStore";

const useStyles = makeStyles<Theme>((theme) => ({
    containerMargin: { margin: theme.spacing(2, 8, 2, 8) },
}));

interface WFRCapaModuleCardProps {
    navigateBack: () => void;
}

const WFRCapaModuleCard: React.FC<WFRCapaModuleCardProps> = observer(() => {
    const classes = useStyles();
    const store = useStore(WorkflowReviewStore);

    return (
        !store.getTaskLoadingV2(ModulesLoading) && (
            <Box className={classes.containerMargin}>
                <WorkflowInstanceList />
            </Box>
        )
    );
});
export default WFRCapaModuleCard;
