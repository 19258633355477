import { Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import DirectoryInfoModel from "../../../../../../models/DirectoryInfoModel";
import { OrganizationStructureMember } from "../../../../../../models/OrganizationModels/OrganizationStructureMember";
import useStyles from "../../../../../../Styles/Styles";
import ConfirmationDialog from "../../../../../UI/AcxConfirmationDialog";
import AcxServiceHierarchySelector from "../../../../../UI/Select/AcxServiceHierarchySelector";
import { SourceFilesStepStore } from "../../Stores/SourceFilesStepStore";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            width: "100%",
        },
        inputField: {
            // marginBottom: theme.spacing(2)
            width: "100%",
            height: "100%",
        },
    });

interface OwnProps {
    store: SourceFilesStepStore;
    directories: DirectoryInfoModel[];
    existingHierarchy?: OrganizationStructureMember[];
    containerRef?: HTMLElement | null | undefined;
}

type Props = OwnProps;

const DirectoryServiceHierarchyDialog: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer(
    (props) => {
        const classes = useStyles(styles);
        const [loading, setLoading] = useState(false);
        const [err, setErr] = useState<string | undefined>();
        const [hierarchySelections, setHierarchySelection] = useState<
            OrganizationStructureMember[]
        >(props.existingHierarchy ?? []);

        useEffect(() => {
            setHierarchySelection(props.existingHierarchy ?? []);
        }, [props.existingHierarchy]);

        function setSelection(
            index: number,
            newValue: OrganizationStructureMember,
        ) {
            setHierarchySelection((prevState) =>
                prevState
                    .slice(0, index)
                    .concat([newValue])
                    .filter((value) => value.name !== "No Selection"),
            );
        }

        async function addHierarchyToDirectories() {
            setErr(undefined);
            setLoading(true);

            try {
                await props.store.addHierarchyToDirectories(
                    hierarchySelections,
                    props.directories,
                );

                setLoading(false);
                setHierarchySelection([]);
                props.store.closeDirectoryHierarchyDialog();
                props.store.messageStore.logMessage(
                    `Successfully updated director${
                        props.directories.length > 1 ? "ies" : "y"
                    }.`,
                    "success",
                );

                props.store.tableStore.clearSelectedItems();
            } catch (err) {
                props.store.messageStore.logError(
                    `Failed to update director${
                        props.directories.length > 1 ? "ies" : "y"
                    }. Please try again.`,
                );

                setLoading(false);
                setErr(err as string);
            }
        }

        const content = () => {
            const hierarchyAvailable =
                props.store.levels.length > 0 && props.store.members.length > 0;
            return (
                <Grid
                    container
                    className={classes.root}
                    alignItems="center"
                    alignContent="center"
                >
                    <Grid item style={{ marginBottom: "0.5rem" }}>
                        {hierarchyAvailable ? (
                            <Typography variant={"subtitle1"}>
                                Assign Service Hierarchy
                            </Typography>
                        ) : (
                            <Typography
                                variant={"subtitle2"}
                                color={"textSecondary"}
                            >
                                No service hierarchies available for your
                                organization
                            </Typography>
                        )}
                    </Grid>
                    {hierarchyAvailable && (
                        <Grid
                            item
                            xs={12}
                            className={classes.inputField}
                            style={{ marginBottom: "24px" }}
                        >
                            <AcxServiceHierarchySelector
                                levels={props.store.levels}
                                fullWidth
                                members={props.store.members}
                                hierarchySelections={hierarchySelections}
                                onSelectMember={setSelection}
                                containerRef={props.containerRef}
                            />
                        </Grid>
                    )}
                </Grid>
            );
        };

        return (
            <ConfirmationDialog
                onClose={props.store.closeDirectoryHierarchyDialog}
                title={"Directory Service Hierarchy"}
                onConfirm={addHierarchyToDirectories}
                content={content()}
                isLoading={loading}
                confirmButtonDisabled={!hierarchySelections}
                errorMessage={err}
                buttonsDisabled={loading}
                isOpen={props.store.showDirectoryHierarchyDialog}
            />
        );
    },
);

export default DirectoryServiceHierarchyDialog;
