import React from "react";
import ListIcon from "@mui/icons-material/List";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import useStyles from "Styles/Styles";
// import hexToRGB from "utils/hexToRGB";

const styles = (theme: Theme) => {
    return createStyles({
        root: {
            color: theme.palette.white.main,
            fontSize: "16px",
        },
        button: {
            borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
            margin: 0,
            backgroundColor: theme.palette.secondary.main,
            border: "none",
            width: "32px",
            height: "32px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: theme.palette.secondary.light,
            },
        },
    });
};
interface AcxFileButtonProps {
    className?: string;
    onClick: () => void;
}
export default function AcxFileButton(props: AcxFileButtonProps) {
    const classes = useStyles(styles);
    const handleVectorEvents = (e: React.MouseEvent) => {
        if (props.onClick) {
            props?.onClick();
        }
        e.stopPropagation();
    };
    function FileListIcon(props) {
        return (
            <ListIcon
                onClick={handleVectorEvents}
                {...props}
                className={classes.root}
            />
        );
    }
    return (
        <button className={classes.button} onClick={props?.onClick}>
            <FileListIcon fontSize={"small"} />
        </button>
    );
}
