import React from "react";
import { GridPagination } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { TablePaginationProps } from "@mui/material/TablePagination";
import { PaginationItem } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxDataGridStore from "../AcxDataGridStore";

function AcxDataGridPagination(props: { store: AcxDataGridStore }) {
    const store = props.store;

    return (
        <GridPagination
            {...props}
            ActionsComponent={({
                page,
                onPageChange,
                className,
                ...props
            }: Pick<
                TablePaginationProps,
                "page" | "onPageChange" | "className"
            >) => (
                <Pagination
                    {...props}
                    size="small"
                    className={className}
                    count={Math.ceil(
                        (store.rowCount ?? 0) / store.paginationModel.pageSize,
                    )}
                    page={page + 1}
                    onChange={(event, newPage) => {
                        onPageChange(event as any, newPage - 1);
                    }}
                    variant="outlined"
                    renderItem={(params) => {
                        return (
                            <PaginationItem
                                {...params}
                                variant={
                                    params.type === "previous" ||
                                    params.type === "next"
                                        ? "outlined"
                                        : "text"
                                }
                                style={{
                                    color: theme.palette.gray[500],
                                    backgroundColor: params.selected
                                        ? theme.palette.primary[200]
                                        : "rgba(0,0,0,0)",
                                }}
                            />
                        );
                    }}
                />
            )}
        />
    );
}

export default AcxDataGridPagination;
