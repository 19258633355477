import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const StorageSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="17px"
            height="17px"
            viewBox="0 0 17 17"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>47F3016A-FE67-4981-83F5-9394373DACEB</title>
            <g
                id="Admin"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.5}
            >
                <g
                    id="Admin---Organization---Users"
                    transform="translate(-35.000000, -487.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Left-Nav" transform="translate(0.000000, 64.000000)">
                        <g
                            id="Group-10"
                            transform="translate(24.000000, 104.000000)"
                        >
                            <g
                                id="Group-9"
                                transform="translate(0.000000, 232.000000)"
                            >
                                <g
                                    id="Group-4-Copy-2"
                                    transform="translate(0.000000, 80.000000)"
                                >
                                    <g
                                        id="Icon/Building"
                                        transform="translate(11.000000, 7.000000)"
                                    >
                                        <path
                                            d="M7.999375,0.995 L7.999375,17.005 L1.995625,17.005 C1.44299507,17.005 0.995,16.5570049 0.995,16.004375 L0.995,16.004375 L0.995,1.995625 C0.995,1.44299507 1.44299507,0.995 1.995625,0.995 L1.995625,0.995 L7.999375,0.995 Z M16.004375,0.995 C16.5570049,0.995 17.005,1.44299507 17.005,1.995625 L17.005,1.995625 L17.005,16.004375 C17.005,16.5570049 16.5570049,17.005 16.004375,17.005 L16.004375,17.005 L10.000625,17.005 L10.000625,0.995 Z M5.998125,7.999375 L2.99625,7.999375 L2.99625,10.000625 L5.998125,10.000625 L5.998125,7.999375 Z M15.00375,7.999375 L12.001875,7.999375 L12.001875,10.000625 L15.00375,10.000625 L15.00375,7.999375 Z M5.998125,3.996875 L2.99625,3.996875 L2.99625,5.998125 L5.998125,5.998125 L5.998125,3.996875 Z M15.00375,3.996875 L12.001875,3.996875 L12.001875,5.998125 L15.00375,5.998125 L15.00375,3.996875 Z"
                                            id="Combined-Shape"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default StorageSvg;
