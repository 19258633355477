import { Grid, Paper, Theme, Tooltip, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import OrganizationServiceHierarchyDrawer from "components/Admin/Organizations/OrganizationDetail/OrganizationServiceHierarchy/OrganizationServiceHierarchyDetails/OrganizationServiceHierarchyDrawer";
import OrganizationServiceHierarchyTier from "components/Admin/Organizations/OrganizationDetail/OrganizationServiceHierarchy/OrganizationServiceHierarchyTier";
import { OrganizationServiceHierarchyStore } from "components/Admin/Organizations/OrganizationDetail/OrganizationServiceHierarchy/stores/OrganizationServiceHierarchyStore";
import { Tier } from "components/Admin/Organizations/types/Tier.type";
import AcxButton from "components/UI/AcxButton";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxRecursiveTree from "components/UI/AcxRecursiveTree/AcxRecursiveTree";
import {
    BranchDataProps,
    HandleBranchProps,
} from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import RecursiveTreeStore, {
    LOAD_TOP_LEVEL,
} from "components/UI/AcxRecursiveTree/Stores/AcxRecursiveTreeStore";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { observer } from "mobx-react";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";

const styles = (theme: Theme) =>
    createStyles({
        recursiveTreeWrapper: {
            width: "100%",
        },
        addTopLevelTierButtonContainer: {
            margin: "0.5rem 0",
        },
        pageContainer: {
            width: "calc(100% - 3rem)",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 20,
        },
        formContainer: {
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            width: "100%",
            overflowY: "auto",
        },
    });

interface OrganizationServiceHierarchyProps {
    id: string;
}

const OrganizationServiceHierarchy: React.FC<OrganizationServiceHierarchyProps> =
    observer(({ id }) => {
        const classes = useStyles(styles);
        const osmStore = useStore(OrganizationServiceHierarchyStore);
        const authStore = useStore(AuthStore);
        const permStore = authStore.permStore;
        const [recursiveTree, setRecursiveTree] = React.useState<any>();
        const [newTierName, setNewTierName] = React.useState<string>();
        const [loading, setLoading] = React.useState<boolean>(false);
        const [selectedParent, setSelectedParent] = React.useState<Tier>();
        const [availableParents, setAvailableParents] =
            React.useState<Tier[]>();

        const hasEditPerms = authStore.canUserEdit("Service Hierarchy");

        const handleParentSelection = (value: Tier) => {
            setSelectedParent(value);
        };

        const handleTierReassignment = async () => {
            if (id && osmStore.activeTier) {
                setLoading(true);

                if (selectedParent) {
                    osmStore.reassignOsmTier(
                        id,
                        selectedParent.id,
                        osmStore.activeTier.id,
                    );
                }

                osmStore.setShowReassignTierDialog(false);
                setAvailableParents([]);
                setSelectedParent(undefined);
                setLoading(false);
            }
        };

        const handleAddTierConfirmation = (name?: string) => {
            if (!name) {
                osmStore.messageStore.logError("Name cannot be empty.");
                return;
            }

            if (id && osmStore.activeTier) {
                osmStore.setActiveTierAttribute("name", name);
                osmStore.setEndpointFired(true, "Add");
                osmStore.setShowAddTierDialog(false);
                setNewTierName("");
            }
        };

        const handleTopLevelTierCreation = async () => {
            if (!newTierName) {
                osmStore.messageStore.logError("Name cannot be empty.");
                return;
            }

            if (id) {
                setLoading(true);

                await osmStore.createTopLevelTier(id, {
                    name: newTierName,
                } as unknown as Tier);

                setNewTierName("");
                osmStore.setShowAddTopLevelTierDialog(false);

                setLoading(false);
            }
        };

        // Grab available parents to populate the select drop-down in Reassign dialog.
        React.useEffect(() => {
            async function getAvailableParentsForReassignment() {
                if (
                    id &&
                    osmStore.showReassignTierDialog && // Make sure dialog is open
                    osmStore.activeTier
                ) {
                    setLoading(true);

                    if (osmStore.activeTier.parentId) {
                        const parents = await osmStore.getAvailableParents(
                            id,
                            osmStore.activeTier.parentId,
                            osmStore.activeTier.branchDepth,
                        );

                        setAvailableParents(parents);
                    }

                    setLoading(false);
                }
            }

            getAvailableParentsForReassignment();
        }, [
            id,
            osmStore,
            osmStore.activeTier,
            osmStore.showReassignTierDialog,
        ]);

        React.useEffect(() => {
            if (!id) {
                return;
            }

            if (osmStore.triggerReassign) {
                osmStore.setTriggerReassign(false);
            }

            const treeStore: RecursiveTreeStore = new RecursiveTreeStore({
                getBranch: async (orgId, parentId) => {
                    const res = await osmStore.getOsmTier(parentId);
                    return { children: res, rowCount: 0 };
                },
                createBranch: osmStore.createOsmTier,
                deleteBranch: osmStore.deleteOsmTier,
                updateBranch: osmStore.updateOsmTier,
            });

            osmStore.treeStore = treeStore;
            osmStore.setOrganizationId(id);

            treeStore.setTopLevelDataKey("tiers");
            treeStore.setOrganizationId(id);

            treeStore.getTopLevelBranches(
                osmStore.getTopLevelServiceHierarchiesForOrganization(),
            );

            const branchChildrenContent = (
                currentBranch: BranchDataProps,
                handleBranch: HandleBranchProps,
            ) => {
                return (
                    <OrganizationServiceHierarchyTier
                        branch={currentBranch}
                        handleTier={handleBranch}
                        store={osmStore}
                        authStore={authStore}
                    />
                );
            };

            treeStore.setBranchContent(branchChildrenContent);

            setRecursiveTree(<AcxRecursiveTree store={treeStore} />);
        }, [id, osmStore, permStore, authStore, osmStore.triggerReassign]);

        return (
            <Grid className={classes.pageContainer}>
                <Paper elevation={1} className={classes.formContainer}>
                    <OrganizationServiceHierarchyDrawer
                        osmStore={osmStore}
                        authStore={authStore}
                    />

                    {hasEditPerms && (
                        <>
                            <AcxDialog
                                isOpen={osmStore.showReassignTierDialog}
                                onClose={() => {
                                    osmStore.setShowReassignTierDialog(false);
                                }}
                                subTitle="Confirm Tier Reassignment"
                                title="Tier Reassignment"
                                text="Select the desired parent tier you wish to assign."
                                children={
                                    <AcxButton
                                        loading={loading}
                                        disabled={loading || !selectedParent}
                                        color="primary"
                                        onClick={handleTierReassignment}
                                    >
                                        Confirm
                                    </AcxButton>
                                }
                                dialogContentChildren={
                                    <>
                                        <Tooltip title="The tier that will be reassigned.">
                                            <Typography
                                                color="secondary"
                                                variant="subtitle2"
                                            >
                                                Reassign{" "}
                                                {osmStore.activeTier?.name ??
                                                    "Unselected"}{" "}
                                                to
                                            </Typography>
                                        </Tooltip>

                                        <AcxSelectSingle
                                            id="possible-parent-tiers"
                                            infoText="Select the desired parent tier."
                                            fullWidth
                                            options={availableParents ?? []}
                                            defaultValue={selectedParent}
                                            onChange={handleParentSelection}
                                            labelField="name"
                                            valueField="id"
                                            isLoading={!availableParents}
                                            isDisabled={loading}
                                            required
                                        />
                                    </>
                                }
                            />

                            <AcxDialog
                                isOpen={osmStore.showAddTopLevelTierDialog}
                                onClose={() => {
                                    setNewTierName("");
                                    osmStore.setShowAddTopLevelTierDialog(
                                        false,
                                    );
                                }}
                                subTitle="Confirm Top Level Tier Addition"
                                title="Top Level Tier"
                                text="The newly named tier will appear at the highest level."
                                children={
                                    <AcxButton
                                        loading={loading}
                                        disabled={loading}
                                        color="primary"
                                        onClick={handleTopLevelTierCreation}
                                    >
                                        Confirm
                                    </AcxButton>
                                }
                                dialogContentChildren={
                                    <AcxMainTextField
                                        focus
                                        id="name"
                                        value={newTierName}
                                        onChange={(e) =>
                                            setNewTierName(e.target.value)
                                        }
                                        labelText={
                                            osmStore.orgServiceSetup?.[0] ?? ""
                                        }
                                    />
                                }
                            />

                            <AcxDialog
                                isOpen={osmStore.showAddTierDialog}
                                onClose={() => {
                                    setNewTierName("");
                                    osmStore.setShowAddTierDialog(false);
                                }}
                                subTitle="Confirm Tier Addition"
                                title={
                                    osmStore.activeTier
                                        ? osmStore.activeTier.name
                                        : ""
                                }
                                text="The newly named tier will appear below its parent."
                                children={
                                    <AcxButton
                                        color="primary"
                                        onClick={() =>
                                            handleAddTierConfirmation(
                                                newTierName,
                                            )
                                        }
                                    >
                                        Confirm
                                    </AcxButton>
                                }
                                dialogContentChildren={
                                    <AcxMainTextField
                                        focus
                                        id="name"
                                        value={newTierName}
                                        onChange={(e) =>
                                            setNewTierName(e.target.value)
                                        }
                                        labelText={
                                            osmStore.activeTier
                                                ? osmStore.orgServiceSetup?.[
                                                      osmStore.activeTier
                                                          .branchDepth + 1
                                                  ]
                                                : ""
                                        }
                                    />
                                }
                            />

                            <AcxDialog
                                isOpen={osmStore.showDeleteDialog}
                                onClose={() => {
                                    osmStore.setShowDeleteDialog(false);
                                }}
                                subTitle="Confirm Removal"
                                title={
                                    osmStore.activeTier
                                        ? osmStore.activeTier.name
                                        : ""
                                }
                                text="Once deleted, you will not be able to retrieve this tier."
                                children={
                                    <AcxButton
                                        color="primary"
                                        onClick={osmStore.confirmRemoveTier}
                                    >
                                        Confirm
                                    </AcxButton>
                                }
                            />
                        </>
                    )}

                    <Grid container item xs={12}>
                        {hasEditPerms &&
                            !osmStore.treeStore?.getTaskLoading(
                                LOAD_TOP_LEVEL,
                            ) && (
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="flex-start"
                                    className={
                                        classes.addTopLevelTierButtonContainer
                                    }
                                >
                                    <AcxButton
                                        color="primary"
                                        disabled={loading}
                                        loading={loading}
                                        onClick={() =>
                                            osmStore.setShowAddTopLevelTierDialog(
                                                true,
                                            )
                                        }
                                    >
                                        Add Top Level Tier
                                    </AcxButton>
                                </Grid>
                            )}

                        {recursiveTree}
                    </Grid>
                </Paper>
            </Grid>
        );
    });

export default OrganizationServiceHierarchy;
