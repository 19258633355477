import React from "react";

interface AgentLogoWithNameProps {
    variant?: "default" | "inverse-icon" | "wordmark-inverse";
}

export function AgentLogoWithName({
    variant = "default",
    ...props
}: Omit<React.ComponentPropsWithoutRef<"svg">, "xmlns" | "viewBox"> &
    AgentLogoWithNameProps) {
    const useGradient = variant !== "inverse-icon";
    const textFill = variant === "wordmark-inverse" ? "#374151" : "white";

    return (
        <svg
            width="62"
            height="18"
            fill="none"
            color="white"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 62 18"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1858 2.56218L11.848 1.97961C11.3314 1.19887 10.5712 0.541362 9.289 0.541362C8.23953 0.525201 7.2633 1.07537 6.73696 1.97961L6.39913 2.56218H12.1858ZM4.23977 6.28591L5.41199 4.26446H6.95305C7.51126 4.26446 7.96378 4.71698 7.96378 5.27518C7.96378 5.83339 7.51126 6.28591 6.95305 6.28591H4.23977ZM2.08042 10.0096L3.25264 7.98819H9.82565C10.3839 7.98819 10.8364 8.44071 10.8364 8.99891C10.8364 9.55712 10.3839 10.0096 9.82565 10.0096H2.08042ZM0 13.7334C0.0635716 13.5285 0.151408 13.3309 0.262286 13.1449L1.09329 11.7119H13.2302C13.7884 11.7119 14.2409 12.1644 14.2409 12.7226C14.2409 13.2809 13.7884 13.7334 13.2302 13.7334H0ZM2.71729 17.4571C1.32832 17.4382 0.386745 16.4946 0.0334496 15.4356H7.27223C7.83044 15.4356 8.28296 15.8882 8.28296 16.4464C8.28296 17.0046 7.83044 17.4571 7.27223 17.4571H2.71729ZM18.5478 15.4356C18.1705 16.5015 17.1596 17.4383 15.8671 17.4571H10.996C10.4378 17.4571 9.98523 17.0046 9.98523 16.4464C9.98523 15.8882 10.4378 15.4356 10.996 15.4356H18.5478ZM17.491 11.7119L18.3219 13.1449C18.4382 13.3297 18.5293 13.5275 18.5939 13.7334H16.9539C16.3957 13.7334 15.9432 13.2809 15.9432 12.7226C15.9432 12.1644 16.3957 11.7119 16.9539 11.7119H17.491ZM15.3319 7.98819L16.504 10.0096H13.5494C12.9912 10.0096 12.5386 9.55712 12.5386 8.99891C12.5386 8.44071 12.9912 7.98819 13.5494 7.98819H15.3319ZM13.1728 4.26446L14.3449 6.28591H10.6768C10.1186 6.28591 9.66606 5.83339 9.66606 5.27518C9.66606 4.71698 10.1186 4.26446 10.6768 4.26446H13.1728Z"
                fill={
                    useGradient
                        ? "url(#paint0_linear_15057_42427)"
                        : "currentColor"
                }
            />
            <path
                d="M55.3669 17.4574C54.5087 17.4574 53.7959 17.312 53.2286 17.021C52.6758 16.7301 52.2685 16.3374 52.0067 15.8428C51.7449 15.3482 51.614 14.8027 51.614 14.2063C51.614 13.5372 51.7812 12.9481 52.1158 12.439C52.4649 11.9298 52.9886 11.5371 53.6868 11.2607C54.385 10.9698 55.2651 10.8243 56.327 10.8243H59.0544C59.0544 10.2861 58.9744 9.84245 58.8144 9.49334C58.6689 9.14423 58.4362 8.8824 58.1161 8.70784C57.8107 8.53329 57.4179 8.44601 56.9379 8.44601C56.3706 8.44601 55.8833 8.57692 55.476 8.83876C55.0832 9.08605 54.836 9.4788 54.7341 10.017H51.8758C51.9631 9.21696 52.2249 8.52601 52.6613 7.94416C53.1122 7.36231 53.7013 6.91137 54.4287 6.59136C55.1705 6.27134 56.0069 6.11133 56.9379 6.11133C57.9852 6.11133 58.8871 6.28588 59.6435 6.63499C60.3999 6.96956 60.9818 7.47141 61.3891 8.14054C61.7964 8.79512 62 9.59517 62 10.5407V17.1956H59.5562L59.2508 15.5155H59.1635C58.9307 15.8355 58.6762 16.1192 58.3998 16.3665C58.138 16.6138 57.847 16.8174 57.527 16.9774C57.207 17.1374 56.8652 17.2538 56.5015 17.3265C56.1378 17.4138 55.7596 17.4574 55.3669 17.4574ZM56.327 15.1882C56.7343 15.1882 57.0834 15.1228 57.3743 14.9918C57.6798 14.8464 57.9416 14.6573 58.1598 14.4245C58.378 14.1918 58.5453 13.9227 58.6616 13.6172C58.7926 13.2972 58.8798 12.9699 58.9235 12.6353H56.6542C56.2033 12.6353 55.8324 12.6935 55.5415 12.8099C55.2651 12.9117 55.0614 13.0645 54.9305 13.2681C54.7996 13.4572 54.7341 13.6827 54.7341 13.9445C54.7341 14.2063 54.7996 14.4318 54.9305 14.6209C55.0614 14.81 55.2505 14.9555 55.4978 15.0573C55.7451 15.1446 56.0215 15.1882 56.327 15.1882Z"
                fill={textFill}
            />
            <path
                d="M42.9253 17.1955L39.4341 6.37305H42.5325L45.0854 15.2536H45.1945L47.7692 6.37305H50.8457L47.3546 17.1955H42.9253Z"
                fill={textFill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.6273 10.4841L25.4988 17.1955H28.5753L29.9172 12.8098H35.5531L36.8667 17.1955H39.9869L35.1648 1.92188H30.3427L28.1673 8.78134H31.1497L32.721 3.64561H32.8083L34.8669 10.5187H30.6181L30.6287 10.4841H27.6273Z"
                fill={textFill}
            />
            {useGradient && (
                <defs>
                    <linearGradient
                        id="paint0_linear_15057_42427"
                        x1="0.551515"
                        y1="1.20979"
                        x2="18.1571"
                        y2="18.0911"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#32D1B7" />
                        <stop offset="0.695" stopColor="#349BC7" />
                        <stop offset="1.0001" stopColor="#3664D6" />
                    </linearGradient>
                </defs>
            )}
        </svg>
    );
}
