import { Error } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import * as React from "react";
import useStyles from "Styles/Styles";
import AcxButton from "./AcxButton";

const styles = (theme: Theme) =>
    createStyles({
        container: {
            minHeight: "max-content",
            minWidth: "500px",
            borderRadius: "8px",
        },
        actions: {
            height: "64px",
        },
        closeButton: {
            color: theme.palette.black.main,
        },
        content: {
            fontSize: "13px",
            color: theme.palette.black.main,
            height: "100%",
        },
        error: {
            fontSize: "13px",
            color: theme.palette.error.main,
            textAlign: "center",
            padding: theme.spacing(1),
        },
    });

type Props = {
    isOpen?: boolean;
    title?: string;
    subTitle?: string;
    confirmButtonText?: string;
    onClose: () => void;
    onConfirm?: any;
    fullWidth?: boolean;
    controlStyles?: React.CSSProperties;
};

function ErrorDialog(props: Props): React.ReactElement {
    const classes = useStyles(styles);
    const theme = useTheme();

    return (
        <Dialog
            classes={{ paper: classes.container }}
            scroll={"paper"}
            open={props.isOpen || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={props.fullWidth ?? false}
        >
            <DialogTitle id="alert-dialog-title" sx={{ opacity: 1 }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item xs={10}>
                        <Error
                            style={{
                                color: theme.palette.error.main,
                                height: "40px",
                                width: "40px",
                            }}
                        />
                    </Grid>

                    <Grid container item xs={2} justifyContent="flex-end">
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={props.onClose}
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>

                    <Grid item xs={10} style={{ paddingTop: "8px" }}>
                        <Typography
                            component="p"
                            variant="h1"
                            style={{ width: "90%" }}
                        >
                            {props.title || "Please Confirm..."}
                        </Typography>
                        {Boolean(props.subTitle) && (
                            <Typography
                                variant="subtitle2"
                                style={{ marginTop: "1rem" }}
                                color={"textSecondary"}
                            >
                                {props.subTitle}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogActions
                className={classes.actions}
                style={props.controlStyles}
            >
                {props.onConfirm && (
                    <AcxButton
                        style={{ marginRight: "28px" }}
                        onClick={props.onConfirm}
                        color="primary"
                    >
                        {props.confirmButtonText || "Ok"}
                    </AcxButton>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default ErrorDialog;
