import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React, { useState } from "react";
import useStyles from "../../../Styles/Styles";
import AcxInputLabel from "../AcxInputLabel";
import AcxFileButton from "./AcxFileButton";
import AcxRemoveFileButton from "./AcxRemoveFileButton";

const styles = (theme: Theme) => {
    // debugger;
    return createStyles({
        inputText: {
            // border: "1px solid",
            // borderColor: theme.palette.black.light,
            // backgroundColor: theme.palette.white.main,
            backgroundColor: "none",
            border: "none",
            flex: "1 1 auto",
            minWidth: "20px",
            fontSize: "14px",
            lineHeight: "24px",
            fontFamily: theme.typography.fontFamily,
            borderRadius: theme.shape.borderRadius,
        },
        textContainer: {
            borderRadius: theme.shape.borderRadius,
            border: "1px solid",
            borderColor: theme.palette.black.light,
            backgroundColor: theme.palette.white.main,
            display: "flex",
            height: "32px",
            alignItems: "center",
        },
        textLabel: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            fontSize: "12px",
            lineHeight: "16px",
        },
    });
};

interface AcxFilePickerProps {
    name: string;
    onChange: (files: File[]) => void;
    defaultValue?: File[];
    accept: string;
    shrink?: boolean;
    infoText?: string;
}

function AcxFilePicker(props: AcxFilePickerProps) {
    const classes = useStyles(styles);
    const [files, setFiles] = useState<File[]>(props.defaultValue ?? []);
    const [labelFocus, setLabelFocus] = useState<boolean | undefined>();

    function onFileChange(event: any) {
        // CR: I absolutely could not get this to work any other way than "any"
        const fils = event.target.files || new FileList();
        setFiles(Array.from(fils));
        props.onChange(Array.from(fils));
    }

    const onFileClick = () => {
        fileInputRef.current?.click();
    };
    const onRemoveClick = () => {
        const curFile = fileInputRef.current;
        if (curFile) {
            curFile.value = "";
        }
        setFiles([]);
        props.onChange([]);
    };
    const fileInputRef = React.createRef<HTMLInputElement>();
    return (
        <div>
            <AcxInputLabel
                id={"filepicker-label"}
                focused={labelFocus}
                htmlFor={`file-picker-${props.name}`}
                shrink={props.shrink}
                labelText={`Select ${props.name}`}
                className={classes.textLabel}
                infoText={props.infoText}
            />
            <input
                // accept={props.accept}
                accept="*"
                onChange={onFileChange}
                style={{ display: "none" }}
                id={"file-picker-" + props.name}
                multiple
                type="file"
                ref={fileInputRef}
            />
            <div className={classes.textContainer}>
                <input
                    onBlur={() => setLabelFocus(false)}
                    onFocus={() => setLabelFocus(true)}
                    type="text"
                    onChange={() => {}}
                    value={files ? files.map((f) => f.name).join(", ") : ""}
                    className={classes.inputText}
                />
                <AcxRemoveFileButton
                    onClick={onRemoveClick}
                ></AcxRemoveFileButton>
                <AcxFileButton onClick={onFileClick} />
            </div>
        </div>
    );
}
AcxFilePicker.defaultProps = {
    shrink: false,
};

export default AcxFilePicker;
