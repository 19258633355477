import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EddyIconSvg from "SvgIcons/EddyIconSvg";
import AcxChip from "components/UI/AcxChip";
import { observer } from "mobx-react";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
    eddyChipText: {
        fontSize: "12px",
        fontWeight: 600,
        color: theme.palette.highlight.eddy.main,
    },
}));

type EddyChipProps = {
    altText?: string;
    containerStyle?: React.CSSProperties;
};

const EddyChip: React.FC<EddyChipProps> = observer(
    ({ altText, containerStyle }) => {
        const classes = useStyles();
        return (
            <div style={containerStyle}>
                <AcxChip
                    label={
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <EddyIconSvg
                                    style={{
                                        width: "16px",
                                        height: "16px",
                                        float: "left",
                                    }}
                                />
                            </Grid>
                            <Grid item className={classes.eddyChipText}>
                                {altText || "Eddy Effect"}
                            </Grid>
                        </Grid>
                    }
                    size="medium"
                    style={{ backgroundColor: "#EBF6FD" }}
                    color="blue"
                />
            </div>
        );
    },
);

export default EddyChip;
