import { Grid, Link, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import React from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            "&:hover": {
                backgroundColor: theme.palette.secondary.light,
            },
            "&:disabled": {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.white.main,
            },
        },
        forgotLink: {
            margin: theme.spacing(4),
        },
    });

// const origin = window.location.origin;

export default function SessionExpired() {
    const classes = useStyles(styles);

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
            direction="row"
        >
            <Grid item container justifyContent="center" xs={12}>
                <Typography className={classes.forgotLink}>
                    <Link
                        // href={`/authentication/login?returnUrl=${encodeURI(
                        //     origin,
                        // )}/app`}
                        href={`/app`}
                        color="secondary"
                    >
                        Back to Log In
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
}
