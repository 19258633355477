import React, { FunctionComponent } from "react";

interface OwnProps {
    index: number;
    activeStepIndex: number;
    children?: React.ReactNode;
}

type Props = OwnProps;

const StepSelector: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return <>{props.activeStepIndex === props.index && props.children}</>;
};

export default StepSelector;
