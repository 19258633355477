import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import {
    dateColumnType,
    interactionDateColumnType,
} from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import IColDef from "components/UI/AcxDataGrid/IColDef";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import moment from "moment";
import { WorkflowService } from "services/WorkflowService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import {
    DatePickerComponentStore,
    DateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { toIsoByDateReference } from "utils/DateTimeUtils";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { StatusCell, highPriorityRenderTrue } from "./utils";
import { Button, Grid, Tooltip, styled } from "@mui/material";
import React from "react";
import theme from "Theme/AppTheme";
import { Routes } from "components/Navigation/Routes";
import { Link } from "react-router-dom";
import {
    TagFormatter,
    ChipComparator,
} from "components/UI/AcxDataGrid/Formatters/TagFormatter";

export const LOAD_AGING_DATA = "LOAD WORKFLOW AGING DATA";
export const CANCEL_WORKFLOWS = "CANCEL WORKFLOWS";
export const REOPEN_WORKFLOW = "REOPEN WORKFLOW";

export enum AgingView {
    "My Organization" = 0,
    "Me" = 1,
}
const WorkflowButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
}));

@AcxStore
export default class WorkflowStore extends BaseStore {
    public readonly messageStore: MessageStore;
    public readonly authStore: AuthStore;

    public readonly datePickerStore = new DatePickerComponentStore();

    private readonly workflowService: WorkflowService;

    @observable
    orgId: string;

    @observable
    dgStore: AcxDataGridStore;

    @observable
    selectedAgingView: AgingView = AgingView["Me"];

    @observable
    hideClosedWorkflows: boolean = true;

    @observable
    hierarchyIds: string[] = [];

    @observable
    selectedReopenLevel?: number;

    @observable
    validWorkflowReopenLevelOptions: number[];

    constructor(private rootStore: IRootStore) {
        super("WorkflowsStore");

        makeObservable(this);

        this.messageStore = this.rootStore.getStore(MessageStore);
        this.authStore = this.rootStore.getStore(AuthStore);
        this.workflowService = new WorkflowService();
        this.dgStore = new AcxDataGridStore(
            "newAgingWorkflowTable",
            "AgingWorkflow",
        );

        this.datePickerStore.setReferenceOption(
            DateReferenceOption.CreatedOnDate,
        );
        this.datePickerStore.beginDate = moment()
            .startOf("date")
            .subtract(60, "days");

        reaction(
            (r) => ({
                storeError: this.nextTaskError,
            }),
            (arg) => {
                if (arg && arg.storeError?.message) {
                    const msg = arg.storeError?.message;
                    this.messageStore.logError(msg);
                    this.clearLastTaskError();
                }
            },
            { delay: 0 },
        );

        reaction(
            (r) => ({
                orgId: this.authStore.orgStore.selectedOrganization?.id,
                beginDate: this.datePickerStore.beginDate,
                endDate: this.datePickerStore.endDate,
                dateReference: this.datePickerStore.referenceOption,
                agingView: this.selectedAgingView,
                activeLocation: this.rootStore.activeLocation,
                hideClosedWorkflows: this.hideClosedWorkflows,
            }),
            this.debounceEffect((params) => {
                if (
                    params.activeLocation &&
                    params.activeLocation.location !== "/app/workflows/aging"
                ) {
                    return;
                }
                if (params.orgId) {
                    this.getAgingWorkflows(
                        params.orgId,
                        params.beginDate,
                        params.endDate,
                        params.agingView,
                        params.dateReference,
                        this.hierarchyIds,
                    );
                }
            }, 1700),
            { fireImmediately: true },
        );

        reaction(
            (r) => ({
                orgId: this.authStore.orgStore.selectedOrganization?.id,
            }),
            () => this.setHierarchyIds([]),
            { fireImmediately: true },
        );
    }

    @action
    handleAgingViewChange = (view: AgingView) => {
        this.selectedAgingView = view;
    };

    @action
    setHierarchyIds = (selectedBranchIds: string[]) => {
        this.hierarchyIds = selectedBranchIds;
    };

    @action
    setReopenLevel = (value?: number) => {
        this.selectedReopenLevel = value;
    };

    @action
    toggleHideClosedWorkflows = () => {
        this.hideClosedWorkflows = !this.hideClosedWorkflows;
    };

    @action
    initializeDataGrid = (orgId: string) => {
        this.dgStore.reset();

        const columns: IColDef[] = [
            {
                headerName: "Workflow",
                field: "workflowPage",
                flex: 1,
                align: "left",
                valueGetter() {
                    return "";
                },
                renderCell: (params: GridRenderCellParams) => {
                    return params.row.userAssignedToFile ||
                        (!params.row.userAssignedToFile &&
                            params.row.workflowDefinitionName !==
                                "SafetyEvent") ? (
                        <Link
                            to={
                                params.row.workflowDefinitionName !==
                                "SafetyEvent"
                                    ? Routes.WORKFLOW_REVIEW.replace(
                                          ":orgId",
                                          orgId,
                                      ).replace(":workflowId", params.row.id)
                                    : Routes.CONVERSATIONS_WORKFLOW.replace(
                                          ":amdId",
                                          params.row.amdId,
                                      )
                            }
                            style={{ textDecoration: "none" }}
                        >
                            <WorkflowButton
                                variant={"outlined"}
                                color={"primary"}
                                size={"small"}
                            >
                                View
                            </WorkflowButton>
                        </Link>
                    ) : (
                        <Tooltip
                            title={
                                params.row.serviceHierarchy === null
                                    ? "Workflow has no assigned hierarchy, so cannot be viewed"
                                    : "Workflow has no assigned hierarchy for this user, so cannot be viewed"
                            }
                            placement="top-end"
                        >
                            <span>
                                <WorkflowButton
                                    variant={"outlined"}
                                    color={"primary"}
                                    size={"small"}
                                    disabled
                                >
                                    View
                                </WorkflowButton>
                            </span>
                        </Tooltip>
                    );
                },
                sortable: false,
                filterable: false,
            },
            {
                headerName: "Evaluation",
                field: "evaluationId",
                flex: 1,
                valueGetter: (_, row) => row.evaluationNumber,
                renderCell: (params: GridRenderCellParams) => {
                    const orgId =
                        this.authStore.orgStore.selectedOrganization?.id;
                    const evalId = params.row.evaluationId;

                    return (
                        <Link
                            to={Routes.makeEvaluationRoute(
                                orgId ?? "",
                                evalId ?? "",
                            )}
                        >
                            {params.row.evaluationNumber}
                        </Link>
                    );
                },
            },
            {
                headerName: "Status",
                field: "currentStatus",
                type: "string",
                flex: 1,
                renderCell: StatusCell,
                valueGetter(_, row) {
                    const value = row.currentStatus;
                    switch (value) {
                        case "Open":
                            return "In Progress";
                        default:
                            return value;
                    }
                },
            },
            {
                headerName: "High Priority",
                field: "highPriority",
                type: "boolean",
                flex: 1,
                renderCell: ({ value }) => {
                    return value ? highPriorityRenderTrue : null;
                },
            },
            {
                headerName: "Question Category",
                field: "questionCategory",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Workflow Recipients",
                field: "workflowRecipients",
                renderCell: (params) => {
                    return TagFormatter({ delimiter: "," })(params);
                },
                sortComparator: ChipComparator,
                flex: 1,
            },
            {
                headerName: "Due Date",
                field: "currentLevelDueDate",
                flex: 1,
                ...dateColumnType,
                renderCell: (params: GridRenderCellParams) => {
                    const dueDate = moment(params.row.currentLevelDueDate);
                    let textColor = theme.palette.blackFont.main;

                    if (dueDate < moment()) {
                        textColor = theme.palette.red.main;
                    } else if (dueDate.diff(moment(), "hours") < 24) {
                        textColor = theme.palette.orange.main;
                    }

                    return (
                        <Grid sx={{ color: textColor }}>
                            {params.formattedValue?.toString()}
                        </Grid>
                    );
                },
            },
            {
                headerName: "Business Days Since Last Activity",
                field: "daysSinceActivity",
                type: "number",
                flex: 1,
                hide: true,
            },
            {
                headerName: "Service Hierarchy",
                field: "serviceHierarchy",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Interaction Date",
                field: "interactionDate",
                flex: 1,
                ...interactionDateColumnType,
            },
            {
                headerName: "Agent",
                field: "agentName",
                type: "string",
                flex: 1,
            },
            {
                headerName: "Level Name",
                field: "customLevelName",
                valueGetter: (_, row) => {
                    return row.workflowLevelName === ""
                        ? "Level " + row.currentStep
                        : row.workflowLevelName;
                },
            },
            {
                headerName: "Current Level",
                field: "currentStep",
                type: "string",
                hide: true,
                flex: 1,
            },
            {
                headerName: "Created On",
                field: "startDate",
                hide: true,
                flex: 1,
                ...dateColumnType,
            },
            {
                headerName: "Definition",
                field: "workflowDefinitionName",
                hide: true,
                type: "string",
                flex: 1,
            },
            {
                headerName: "Last Step Completed By",
                field: "completedBy",
                type: "string",
                hide: true,
                renderCell: (params) => {
                    return TagFormatter({ delimiter: "," })(params);
                },
                sortComparator: ChipComparator,
                flex: 1,
            },
            {
                headerName: "Result",
                field: "result",
                type: "string",
                hide: true,
                flex: 1,
            },
        ];

        this.dgStore.setColumns(columns);
    };

    @action
    onSaveUpdateWithHierarchies = () => {
        this.getAgingWorkflows(
            this.orgId,
            this.datePickerStore.beginDate,
            this.datePickerStore.endDate,
            this.selectedAgingView,
            this.datePickerStore.referenceOption,
            this.hierarchyIds,
        );
    };

    @action
    getAgingWorkflows = (
        orgId: string,
        startDate: moment.Moment,
        endDate: moment.Moment,
        agingView: AgingView,
        dateReference: DateReferenceOption,
        hierarchyIds?: string[],
    ) => {
        this.setupAsyncTask(LOAD_AGING_DATA, async () => {
            runInAction(() => {
                this.dgStore.setLoading(true);
            });
            const dates = toIsoByDateReference(
                startDate,
                endDate,
                dateReference,
            );
            const agingData = await this.workflowService.getAgingWorkflowData(
                agingView === AgingView["Me"],
                this.hideClosedWorkflows,
                dates.beginDate,
                dates.endDate,
                dateReference,
                hierarchyIds,
            );

            runInAction(() => {
                this.orgId = orgId;
                this.initializeDataGrid(orgId);
                this.dgStore.rows = agingData;
                this.dgStore.vertIconMenuItemsBuilder = () => {
                    return [
                        this.dgStore.buildVertMenuOptionForCsvExport(
                            agingData.length > 0,
                        ),
                    ];
                };
                this.dgStore.setLoading(false);
                if (!this.authStore.permStore.isElevated) {
                    this.dgStore.checkboxSelection = false;
                }
            });
        });
    };

    @action
    refreshTable = () => {
        if (!this.orgId && this.authStore.orgStore.selectedOrganization?.id) {
            this.orgId = this.authStore.orgStore.selectedOrganization.id;
        }
        if (this.orgId) {
            this.getAgingWorkflows(
                this.orgId,
                this.datePickerStore.beginDate,
                this.datePickerStore.endDate,
                this.selectedAgingView,
                this.datePickerStore.referenceOption,
            );
        }
    };

    @action
    cancelWorkflows = () => {
        this.setupAsyncTask(CANCEL_WORKFLOWS, async () => {
            const workflows = this.dgStore
                .selectedRows
                .map((workflow) => ({
                    workflowInstanceId: workflow.id,
                    currentStatus: workflow.currentStatus,
                }));

            await this.workflowService.cancelWorkflows(this.orgId, workflows);
        }).then(async () => {
            if (this.orgId) {
                this.dgStore.clearSelected();
                this.refreshTable();
            }
        });
    };

    @action
    reopenWorkflow = () => {
        this.setupAsyncTask(REOPEN_WORKFLOW, async () => {
            if (!this.selectedReopenLevel) return;
            const workflow = this.dgStore.selectedRows[0];

            await this.workflowService.reopenWorkflow(
                workflow.id,
                this.selectedReopenLevel,
            );
        }).then(async () => {
            if (this.orgId) {
                this.dgStore.clearSelected();
                this.refreshTable();
            }
        });
    };

    @action
    getValidWorkflowReopenLevelOptions = async () => {
        this.setupAsyncTask("GET WORKFLOW LEVELS", async () => {
            try {
                const res =
                    await this.workflowService.getValidWorkflowReopenLevelOptions(
                        this.dgStore.selectedRows[0].id,
                    );
                this.validWorkflowReopenLevelOptions = res;
                return res;
            } catch (e: any) {
                this.messageStore.logError(
                    "Error getting workflow levels: " + e.toString(),
                );
            }
        });
    };

    @computed
    get rowIsSelected() {
        return this.dgStore.selectedRows.length > 0;
    }

    @computed
    get multipleRowsSelected() {
        return this.dgStore.selectedRows.length > 1;
    }

    @computed
    get selectedStatusIsClosed() {
        return this.dgStore.selectedRows[0].currentStatus === "Closed";
    }

    @computed
    get selectedStatusIsAPT() {
        return this.dgStore.selectedRows[0].workflowDefinitionName === "APT";
    }
}
