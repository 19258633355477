import React, { FunctionComponent } from "react";

interface OwnProps {
    title?: string;
    width?: string;
    height?: string;
}

type Props = OwnProps;

const InfoSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <div>
            <svg
                width={props.width ?? "18px"}
                height={props.width ?? "18px"}
                viewBox="0 0 16 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
            >
                <title>{props.title}</title>
                <g
                    id="Evaluation"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    opacity="0.25"
                >
                    <g
                        id="Interaction---Analyst-Evaluate-"
                        transform="translate(-368.000000, -410.000000)"
                        fill="#1F1F1F"
                        fillRule="nonzero"
                    >
                        <g
                            id="Content"
                            transform="translate(0.000000, 56.000000)"
                        >
                            <g
                                id="Middle"
                                transform="translate(344.000000, 24.000000)"
                            >
                                <g
                                    id="Quality-Copy"
                                    transform="translate(0.000000, 252.000000)"
                                >
                                    <g
                                        id="Stacked-Group"
                                        transform="translate(24.000000, 76.000000)"
                                    >
                                        <g id="Field-Copy-22">
                                            <g id="Stacked-Group">
                                                <g id="Title">
                                                    <g
                                                        id="Icon/Info"
                                                        transform="translate(0.000000, 2.000000)"
                                                    >
                                                        <path
                                                            d="M8,2.66666667 C5.06666667,2.66666667 2.66666667,5.06666667 2.66666667,8 C2.66666667,10.9333333 5.06666667,13.3333333 8,13.3333333 C10.9333333,13.3333333 13.3333333,10.9333333 13.3333333,8 C13.3333333,5.06666667 10.9333333,2.66666667 8,2.66666667 Z M8.66666667,10.6666667 L7.33333333,10.6666667 L7.33333333,7.33333333 L8.66666667,7.33333333 L8.66666667,10.6666667 Z M8,6.66666667 C7.6,6.66666667 7.33333333,6.4 7.33333333,6 C7.33333333,5.6 7.6,5.33333333 8,5.33333333 C8.4,5.33333333 8.66666667,5.6 8.66666667,6 C8.66666667,6.4 8.4,6.66666667 8,6.66666667 Z"
                                                            id="Fill"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default InfoSvg;
