import {
    DialogContent,
    Grid,
    IconButton,
    Slider,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles } from "@mui/styles";
import AcxButton from "components/UI/AcxButton";
import AcxDrawer from "components/UI/Drawer/AcxDrawer";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import { observer } from "mobx-react-lite";
import { DataProcessingOption } from "models/DataProcesses";
import React, { useEffect } from "react";
import { LayoutDrawerStore } from "stores/Layout/LayoutDrawerStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { MontageSoundClipStore } from "./Stores/MontageSoundClipStore";
import useDataProcessingRedactionOptions from "./useDataProcessingRedactionOptions";

type Props = {
    montageStore: MontageSoundClipStore;
};

const styles = () =>
    createStyles({
        closeButton: {
            position: "absolute",
            top: 16,
            color: "#A1A1AA",
            right: 8,
        },
        closeIcon: {
            height: 20,
            width: 20,
        },
        titleContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            paddingBottom: "0.25rem",
            position: "static",
            width: "424px",
            left: "0px",
            top: "0px",
            background: "#FFFFFF",
            flex: "none",
            order: 0,
            flexGrow: 0,
            margin: "0px 0px",
        },
        soundClipInfoContainer: {
            paddingBottom: "1rem",
        },
        fullWidth: {
            width: "100%",
        },
        valueLabel: {
            "& *": {
                background: "transparent",
            },
        },
        voiceRedactionFactorLabel: {
            marginTop: "20px",
        },
    });

const MontageClipRedactionDrawer: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ montageStore }) => {
    const classes = useStyles(styles);
    const drawerStore = useStore(LayoutDrawerStore);

    const { data: dpOptions, isLoading: dpIsLoading } =
        useDataProcessingRedactionOptions();

    const [voiceRedactionFactorValue, setVoiceRedactionFactorValue] =
        React.useState<number | undefined>(
            montageStore.getVoiceRedactionFactorForSoundClip(),
        );

    const [dataOptions, setDataOptions] = React.useState<
        DataProcessingOption[]
    >(montageStore.getRedactOptionsForSoundClip());

    const [sliderDisabled, setSliderDisabled] = React.useState<boolean>(true);

    const handleVoiceRedactionFactorValueChange = (
        event: any,
        newValue: number | number[],
    ) => {
        setVoiceRedactionFactorValue(newValue as number);
        montageStore.onVoiceRedactionFactorChange(newValue as number);
    };

    const handleDataOptionsChange = (
        options: DataProcessingOption[] | undefined,
    ) => {
        setDataOptions(options as DataProcessingOption[]);
        montageStore.onSoundClipRedactOptionsChange(
            options as DataProcessingOption[],
        );
    };

    useEffect(() => {
        setVoiceRedactionFactorValue(
            montageStore.getVoiceRedactionFactorForSoundClip(),
        );
        setDataOptions(montageStore.getRedactOptionsForSoundClip());
    }, [montageStore, montageStore.activeSoundClip]);

    useEffect(() => {
        setSliderDisabled(true);

        for (const option of dataOptions) {
            if (option.processingOption === "VoiceRedaction") {
                setSliderDisabled(false);
                break;
            }
        }
    }, [dataOptions]);

    return (
        <AcxDrawer
            anchor={drawerStore.anchor}
            open={montageStore.redactDrawerOpen}
            collapsible={drawerStore.collapseSize}
            content={
                <div style={{ width: 400 }}>
                    <div
                        id="alert-dialog-title"
                        className={classes.titleContainer}
                    >
                        <Typography
                            variant="h2"
                            color="secondary"
                            component="span"
                            className={classes.title}
                        >
                            Redact Montage Sound Clip
                        </Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={() =>
                                montageStore.setShowRedactDrawer(false)
                            }
                            size="large"
                        >
                            <CloseIcon className={classes.closeIcon} />
                        </IconButton>
                    </div>
                    <DialogContent className={classes.fullWidth}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                {montageStore.activeSoundClip && (
                                    <div
                                        className={
                                            classes.soundClipInfoContainer
                                        }
                                    >
                                        <Typography>
                                            Evaluation Number:{" "}
                                            {
                                                montageStore.activeSoundClip
                                                    .evaluationQbId
                                            }
                                        </Typography>
                                        <Typography>
                                            {
                                                montageStore.activeSoundClip
                                                    .segmentName
                                            }
                                        </Typography>
                                    </div>
                                )}

                                <AcxSelectMulti
                                    id="dataProcessingOptions"
                                    options={dpOptions ? dpOptions : []}
                                    valueField="id"
                                    labelField="processingOption"
                                    inputLabel="Data Processing Options"
                                    containerHeight="auto"
                                    isLoading={dpIsLoading}
                                    onChange={handleDataOptionsChange}
                                    defaultValue={dataOptions}
                                />

                                <Grid>
                                    <Typography
                                        color={
                                            sliderDisabled
                                                ? "textSecondary"
                                                : "textPrimary"
                                        }
                                        className={
                                            classes.voiceRedactionFactorLabel
                                        }
                                        gutterBottom
                                    >
                                        Voice Redaction Factor
                                    </Typography>
                                    <Slider
                                        defaultValue={voiceRedactionFactorValue}
                                        value={voiceRedactionFactorValue}
                                        onChange={
                                            handleVoiceRedactionFactorValueChange
                                        }
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                        step={0.01}
                                        min={-0.1}
                                        max={0.1}
                                        color="secondary"
                                        classes={{
                                            valueLabel: classes.valueLabel,
                                        }}
                                        disabled={sliderDisabled}
                                        style={{ marginTop: "20px" }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent="flex-end"
                            alignContent="center"
                            style={{ marginTop: "20px" }}
                        >
                            <AcxButton
                                color="primary"
                                size="large"
                                onClick={() => {
                                    montageStore.setShowRedactDrawer(false);
                                    montageStore.loadRedactedClip();
                                }}
                                disabled={!(dataOptions.length > 0)}
                            >
                                Redact Clip
                            </AcxButton>
                        </Grid>
                    </DialogContent>
                </div>
            }
        />
    );
});

export default MontageClipRedactionDrawer;
