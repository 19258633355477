import { Radio, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import AcxMainTextField from "./AcxMainTextField";
import { IAcxTextFieldProps } from "./AcxMainTextFieldGrid";

const ControlContainer = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "1fr 3fr 3fr",
    alignItems: "center",
    margin: "0 0 0.5rem 0",
    padding: "0px",
    gap: "0.5rem",
}));

const Label = styled("label")(({ theme }) => ({
    userSelect: "none",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold as any,
}));

const ItemContainer = styled("section")(({ theme }) => ({
    display: "flex",
    gap: "0.25rem",
    alignItems: "center",
}));

interface IAcxLabeledInputToggle
    extends Omit<IAcxTextFieldProps, "value"> {
    readonly fieldIdentifierLabel?: string;
    setRequired?: boolean;
    readonly fieldName: string;
    firstValue: string | number;
    secondValue: string | number;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const AcxLabeledInputToggle = (props: IAcxLabeledInputToggle) => {
    const [checked, setChecked] = useState("");
    const [valueOne, setValueOne] = useState(props.firstValue);
    const [valueTwo, setValueTwo] = useState(props.secondValue);

    useEffect(() => {
        if (props.firstValue) {
            setChecked(`firstRadioField_${props.fieldName}`);
            props.onChange?.({ target: { value: props.firstValue } } as any);
        } else if (!props.firstValue && props.secondValue) {
            setChecked(`secondRadioField_${props.fieldName}`);
            props.onChange?.({ target: { value: props.secondValue } } as any);
        } else {
            setChecked(`firstRadioField_${props.fieldName}`);
            props.onChange?.({ target: { value: props.firstValue } } as any);
        }
    }, [
        props,
        props.fieldName,
        props.firstValue,
        props.onChange,
        props.secondValue,
    ]);

    const handleInputUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.target.id === `firstTextField_${props.fieldName}`
            ? setValueOne(event.target.value)
            : setValueTwo(event.target.value);
    };

    const handleRadioUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) props.onChange(event);
        setChecked(event.target.id);
    };

    return (
        <ControlContainer>
            <Label title={props.fieldIdentifierLabel}>
                {props.fieldIdentifierLabel}
            </Label>

            <ItemContainer>
                <Radio
                    key={`firstRadioField_${props.fieldName}`}
                    id={`firstRadioField_${props.fieldName}`}
                    checked={
                        checked === `firstRadioField_${props.fieldName}`
                            ? true
                            : false
                    }
                    onChange={handleRadioUpdate}
                    name={props.fieldName}
                    value={valueOne ? valueOne : ""}
                    title={`Select ${valueOne}.`}
                />

                <AcxMainTextField
                    key={`firstTextField_${props.fieldName}`}
                    id={`firstTextField_${props.fieldName}`}
                    placeholderText={props.setRequired ? "Required field" : ""}
                    value={valueOne ? valueOne : ""}
                    onChange={handleInputUpdate}
                    containerStyle={{ width: "100%" }}
                    required={props.setRequired ? props.setRequired : false}
                    textareaRootStyles={{ pointerEvents: "none" }}
                />
            </ItemContainer>

            <ItemContainer>
                <Radio
                    key={`secondRadioField_${props.fieldName}`}
                    id={`secondRadioField_${props.fieldName}`}
                    checked={
                        checked === `secondRadioField_${props.fieldName}`
                            ? true
                            : false
                    }
                    onChange={handleRadioUpdate}
                    name={props.fieldName}
                    value={valueTwo ? valueTwo : ""}
                    title={`Select ${valueTwo}.`}
                />

                <AcxMainTextField
                    key={`secondTextField_${props.fieldName}`}
                    id={`secondTextField_${props.fieldName}`}
                    placeholderText={props.setRequired ? "Required field" : ""}
                    value={valueTwo ? valueTwo : ""}
                    onChange={handleInputUpdate}
                    containerStyle={{ width: "100%" }}
                    required={props.setRequired ? props.setRequired : false}
                    textareaRootStyles={{ pointerEvents: "none" }}
                />
            </ItemContainer>
        </ControlContainer>
    );
};

export default AcxLabeledInputToggle;
