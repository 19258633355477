import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import { EvalLicensedModule } from "components/Evaluation/Stores/EvalStore";
import { uniq } from "lodash";
import Evaluation from "models/Evaluation";
import { WorkflowStatus } from "models/Workflows/WorkflowInstance";
import { isNotNull } from "utils/helpers";

export const getStatusText = (status: WorkflowStatus) => {
    switch (status) {
        case WorkflowStatus.Open:
            return "In Progress";
        case WorkflowStatus.Closed:
            return "Closed";
        case WorkflowStatus.Overdue:
            return "Overdue";
    }
};
export const getImplementationTypeText = (status: WorkflowConditionType) => {
    switch (status) {
        case WorkflowConditionType.APT:
            return "APT";
        case WorkflowConditionType.ScoreEscalation:
            return "Score Escalation";
        case WorkflowConditionType.EvaluationDispute:
            return "Evaluation Dispute";
    }
};
export const getDisputeModuleNames = (
    evaluation?: Evaluation,
    licensedModules?: EvalLicensedModule[],
) => {
    if (!evaluation || !licensedModules) {
        return [];
    }
    const disputedAnswerModuleIds = uniq(
        evaluation.disputedAnswers.map(
            (answer) => answer.question.licensedModuleId,
        ),
    );

    const modules = licensedModules.filter((module) =>
        disputedAnswerModuleIds.includes(module.id),
    );

    return modules.map((module) => module.name).filter(isNotNull);
};
export const findModuleName = (
    licensedModuleId?: string,
    licensedModules?: EvalLicensedModule[],
) => {
    if (!licensedModuleId || !licensedModules) {
        return;
    }
    const moduleName = licensedModules?.find(
        (module) => module.id === licensedModuleId,
    );

    return moduleName?.name;
};
