import {
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    Popover,
    Radio,
    RadioGroup,
    Theme,
    Typography,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import clsx from "clsx";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import { AcxConfirmationDialogWrapper } from "components/UI/AcxConfirmationDialog";
import AcxInputLabel from "components/UI/AcxInputLabel";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxSelectMulti from "components/UI/Select/BaseSelectComponents/AcxSelectMulti";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import IMotfAutomationDefinition from "models/IMotfAutomationDefinition";
import React from "react";
import { useNavigate } from "react-router";
import {
    MotfAutomationService,
    UpsertMotfDefinition,
} from "services/MotfAutomationService";
import { FieldValidationRules, useForm } from "shared/hooks/useForm";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { OrganizationMotfStore } from "./OrganizationMotfStore";
import AcxHierarchySelector from "components/UI/AcxHierarchySelector/AcxHierarchySelector";

const styles = (theme: Theme) => {
    return {
        root: {
            padding: theme.spacing(8),
            paddingTop: theme.spacing(4),
        },
        popover: {
            "padding-inline": theme.spacing(2),
            "padding-block": theme.spacing(1),
        },
        formContainer: {
            padding: theme.spacing(4),
            width: "100%",
            overflowY: "auto" as any,
        },
        vmarginmd: {
            "margin-block": theme.spacing(1),
        },
        wfit: {
            "max-width": "fit-content",
        },
        gapxl: {
            gap: theme.spacing(3),
        },
        gaplg: {
            gap: theme.spacing(2),
        },
        gapmd: {
            gap: theme.spacing(1),
        },
        fullWidth: {
            width: "100%",
        },
        noSelect: {
            "user-select": "none",
        },
        noMargin: {
            margin: 0,
        },
        error: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.error.main,
            fontSize: "12px",
            lineHeight: "16px",
        },
    };
};

const validators: FieldValidationRules<UpsertMotfDefinition> = {
    name: {
        required: {
            message: "Definition name is required",
        },
        maxLength: {
            value: 25,
            message: "Definition name cannot exceed 25 characters",
        },
        pattern: [
            {
                value: /[^A-Za-z0-9\s\-,.:!?]/g,
                message:
                    "Definition name may only include alphanumeric characters and - , . : ! ?",
                opposite: true,
            },
        ],
    },
    mediaTypes: {
        minLength: {
            value: 1,
            message: "Must select at least 1 media type to sample from",
        },
    },
    quotaVolume: {
        function: {
            value({ quotaVolume }) {
                if (quotaVolume <= 0)
                    return "Quota volume must be greater than 0";
                return false;
            },
        },
    },
    audioInteractionDurationMinutes: {
        function: {
            value({ audioInteractionDurationMinutes: duration, mediaTypes }) {
                if (!mediaTypes.includes("Audio")) return false;
                if (duration.maximum <= 0)
                    return "Audio interaction duration maximum must be greater than 0";
                else if (duration.minimum < 0)
                    return "Audio interaction duration minimum must be greater than or equal to 0";
                else if (duration.maximum <= duration.minimum)
                    return "Audio interaction duration maximum must be greater than the minimum";
                return false;
            },
        },
    },
    chatMessageCountRange: {
        function: {
            value({ chatMessageCountRange: count, mediaTypes }) {
                if (!mediaTypes.includes("ProcessedChat")) return false;
                if (count.maximum <= 0)
                    return "Chat message count maximum must be greater than 0";
                else if (count.minimum < 0)
                    return "Chat message count minimum must be greater than or equal to 0";
                else if (count.maximum <= count.minimum)
                    return "Chat message count maximum must be greater than the minimum";
                return false;
            },
        },
    },
};

const defaultAutomationDefinition: UpsertMotfDefinition = {
    name: "",
    mediaTypes: [],
    quotaType: "Agent",
    quotaVolume: 0,
    isActive: true,
    audioInteractionDurationMinutes: {
        minimum: 0,
        maximum: 0,
    },
    chatMessageCountRange: {
        minimum: 0,
        maximum: 0,
    },
    hierarchyIds: [],
    executionFrequency: "weekly",
};

export const motfExecutionValuesAndLabelsMap = {
    weekly: { value: "weekly", label: "Weekly" },
    daily: { value: "daily", label: "Daily" },
};

interface MotfDefinitionFormProps {
    /**
     * If provided, will treat this as an editing form
     */
    initialDefinition?: UpsertMotfDefinition & { id: string };
    orgId: string;
}

export default function MotfDefinitionForm({
    initialDefinition,
    orgId,
}: MotfDefinitionFormProps) {
    const navigate = useNavigate();

    const classes = useStyles(styles);
    const messageStore = useStore(MessageStore);

    const {
        value: definition,
        errors,
        onChange,
        registerSubmit,
        isSubmitting,
    } = useForm<UpsertMotfDefinition>(
        initialDefinition ?? defaultAutomationDefinition,
        validators,
    );

    const motfStore = useStore(OrganizationMotfStore);
    const isEditing = initialDefinition !== undefined;

    React.useEffect(() => {
        motfStore.setOrgId(orgId);
    }, [motfStore, orgId]);

    async function submitDefinition(data: UpsertMotfDefinition) {
        let motfRule;
        const motfService = new MotfAutomationService();
        if (isEditing) {
            motfRule = await motfService.updateMotfAutomationDefinition(
                motfStore.editingDefinitionId!,
                motfStore.orgId!,
                // saving changes will always re-activate
                { ...data, isActive: true },
            );
            onChange("isActive", true);
            messageStore.logInfo(
                `Definition "${data.name}" was successfully updated`,
            );
        } else {
            motfRule = await motfService.createMotfAutomationDefinition(
                motfStore.orgId!,
                data,
            );
            messageStore.logInfo(
                `Definition "${data.name}" was successfully created`,
            );
        }
        return motfRule;
    }

    function onSubmitSuccess(definition: IMotfAutomationDefinition) {
        if (!isEditing) return navigate(`../definitions`);
    }

    function onSubmitFail() {
        messageStore.logError(
            `You have 1 or more errors preventing you from submitting`,
        );
    }

    const [kebabAnchorEl, setKebabAnchorEl] =
        React.useState<HTMLButtonElement | null>(null);

    const confirmDeletionDialogStore = React.useMemo(
        () => new DialogComponentStore(),
        [],
    );

    async function onConfirmDelete() {
        if (!isEditing) return;
        try {
            confirmDeletionDialogStore.isLoading = true;
            await motfStore.deleteEditingDefinition();
            confirmDeletionDialogStore.isLoading = false;
            messageStore.logInfo(`Deleted "${definition.name}"`);
            return navigate("../definitions");
        } catch (error) {
            messageStore.logError(`Error deleting "${definition.name}"`);
            confirmDeletionDialogStore.isOpen = false;
        }
    }

    const confirmDeactivationDialogStore = React.useMemo(
        () => new DialogComponentStore(),
        [],
    );

    async function onConfirmDeactivate() {
        if (!isEditing) return;
        try {
            confirmDeactivationDialogStore.isLoading = true;
            await motfStore.deactivateEditingDefinition();
            confirmDeactivationDialogStore.isLoading = false;
            messageStore.logInfo(`Deactivated "${definition.name}"`);
            return navigate("../definitions");
        } catch (error) {
            messageStore.logError(`Error deactivating "${definition.name}"`);
            confirmDeactivationDialogStore.isOpen = false;
        }
    }

    const confirmReactivationDialogStore = React.useMemo(
        () => new DialogComponentStore(),
        [],
    );

    async function onConfirmReactivate() {
        if (!isEditing) return;
        try {
            confirmReactivationDialogStore.isLoading = true;
            await motfStore.activateEditingDefinition();
            confirmReactivationDialogStore.isLoading = false;
            messageStore.logInfo(`Reactivated "${definition.name}"`);
            onChange("isActive", true);
            confirmReactivationDialogStore.isOpen = false;
        } catch (error) {
            messageStore.logError(`Error reactivating "${definition.name}"`);
            confirmReactivationDialogStore.isOpen = false;
        }
    }

    return (
        <Container className={classes.root} maxWidth="xl">
            <Grid container direction="column" className={classes.gapxl}>
                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid
                        container
                        item
                        direction="column"
                        className={clsx(classes.gapmd, classes.wfit)}
                    >
                        <Typography variant="h4">
                            Set up a configuration for Interaction Sampling
                        </Typography>
                        <Typography variant="h3">
                            Name and define how calls will be sampled into
                            Authenticx for evaluation
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        className={clsx(classes.gaplg, classes.wfit)}
                        style={{
                            flexWrap: "nowrap",
                        }}
                        alignItems="center"
                    >
                        {isEditing && (
                            <>
                                <IconButton
                                    size="small"
                                    disabled={isSubmitting}
                                    onClick={(event) =>
                                        setKebabAnchorEl(event.currentTarget)
                                    }
                                >
                                    <MoreVert />
                                </IconButton>
                                <Popover
                                    open={Boolean(kebabAnchorEl)}
                                    onClose={() => setKebabAnchorEl(null)}
                                    anchorEl={kebabAnchorEl}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        className={clsx(
                                            classes.popover,
                                            classes.gapmd,
                                        )}
                                    >
                                        {definition.isActive && (
                                            <AcxButton
                                                fullWidth
                                                color="primary"
                                                onClick={() => {
                                                    confirmDeactivationDialogStore.isOpen =
                                                        true;
                                                }}
                                            >
                                                Deactivate
                                            </AcxButton>
                                        )}
                                        <AcxConfirmationDialogWrapper
                                            dialogStore={
                                                confirmDeactivationDialogStore
                                            }
                                            title="Confirm Deactivation"
                                            content={`Deactivating "${definition.name}" will stop all automated sampling from happening in the future.`}
                                            onConfirm={onConfirmDeactivate}
                                        />
                                        {!definition.isActive && (
                                            <AcxButton
                                                fullWidth
                                                color="primary"
                                                onClick={() => {
                                                    confirmReactivationDialogStore.isOpen =
                                                        true;
                                                }}
                                            >
                                                Reactivate
                                            </AcxButton>
                                        )}
                                        <AcxConfirmationDialogWrapper
                                            dialogStore={
                                                confirmReactivationDialogStore
                                            }
                                            title="Confirm Reactivation"
                                            content={`Reactivating "${definition.name}" will begin automated sampling again.`}
                                            onConfirm={onConfirmReactivate}
                                        />
                                        <AcxButton
                                            fullWidth
                                            color="primary"
                                            // We dont support a hard delete currently
                                            disabled
                                            tooltip="This functionality is still work in progress"
                                            onClick={() => {
                                                confirmDeletionDialogStore.isOpen =
                                                    true;
                                            }}
                                        >
                                            Delete
                                        </AcxButton>
                                        <AcxConfirmationDialogWrapper
                                            dialogStore={
                                                confirmDeletionDialogStore
                                            }
                                            title="Confirm Deletion"
                                            content={`Deleting "${definition.name}" cannot be undone. Are you sure you want to delete it?`}
                                            onConfirm={onConfirmDelete}
                                        />
                                    </Grid>
                                </Popover>
                            </>
                        )}
                        <AcxButton
                            fullWidth
                            color="primary"
                            loading={isSubmitting}
                            onClick={registerSubmit(submitDefinition, {
                                onSuccess: onSubmitSuccess,
                                onFail: onSubmitFail,
                            })}
                        >
                            {isEditing && definition.isActive && "Save Changes"}
                            {isEditing &&
                                !definition.isActive &&
                                "Save & Activate"}
                            {!isEditing && "Create Definition"}
                        </AcxButton>
                    </Grid>
                </Grid>
                <Paper className={clsx(classes.formContainer)}>
                    <Typography variant="h5">Setup Definition</Typography>
                    <Grid
                        container
                        direction="column"
                        className={clsx(classes.vmarginmd, classes.gaplg)}
                    >
                        <Grid item xs={4}>
                            <AcxMainTextField
                                id="name"
                                labelText="Name your definition"
                                placeholderText="Authenticx - 1,000 Calls Per Week"
                                required={true}
                                value={definition.name}
                                error={!!errors?.fieldErrors?.name}
                                helperText={errors?.fieldErrors?.name}
                                showAllErrors={true}
                                onChange={(event) =>
                                    onChange("name", event.target.value)
                                }
                                isDisabled={isSubmitting}
                                disableTooltip
                            />
                        </Grid>
                        <Grid item>
                            <FormControl disabled={isSubmitting}>
                                <FormLabel>
                                    {/* 
                                        Couldn't put an acx input label here was bugging with the radio group,
                                        so I am attempting to mimic the label text
                                    */}
                                    <Typography
                                        variant="h6"
                                        color="textSecondary"
                                        style={{
                                            fontWeight: "bold",
                                            opacity: 1,
                                        }}
                                    >
                                        How frequently will the interactions be
                                        sampled?
                                    </Typography>
                                </FormLabel>
                                <AcxSelectSingle
                                    defaultValue={
                                        motfExecutionValuesAndLabelsMap[
                                            definition?.executionFrequency ??
                                                "weekly"
                                        ]
                                    }
                                    options={Object.values(
                                        motfExecutionValuesAndLabelsMap,
                                    )}
                                    id={"motf-execution-frequency"}
                                    valueField={"value"}
                                    labelField={"label"}
                                    onChange={(executionFrequencyOption) => {
                                        definition.executionFrequency =
                                            executionFrequencyOption.value;
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.formContainer}>
                    <Typography variant="h5">Definition Rules</Typography>
                    <Grid
                        container
                        direction="column"
                        className={clsx(classes.vmarginmd, classes.gaplg)}
                    >
                        <Grid
                            container
                            direction="column"
                            item
                            xs={6}
                            className={classes.gapmd}
                        >
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                style={{
                                    fontWeight: "bold",
                                    opacity: 1,
                                }}
                            >
                                Which hierarchies should interactions be sampled
                                from? (Defaults to all hierarchies)
                            </Typography>
                            <AcxHierarchySelector
                                displayType="baseOnly"
                                userId={motfStore.authStore._user.profile.sub}
                                orgId={motfStore.orgId ?? ""}
                                setHierarchyIds={(ids) => {
                                    onChange("hierarchyIds", ids);
                                }}
                                onSaveUpdateWithHierarchies={() => {}}
                                width="500px"
                                initSelectedHierarchyIds={
                                    initialDefinition?.hierarchyIds
                                }
                            />
                        </Grid>
                        <Grid item direction="column" spacing={1}>
                            <FormControl
                                className={classes.fullWidth}
                                disabled={isSubmitting}
                            >
                                <FormLabel>
                                    {/* Couldn't put an acx input label here was bugging with the radio group */}
                                    <Typography
                                        variant="h6"
                                        color="textSecondary"
                                        style={{
                                            fontWeight: "bold",
                                            opacity: 1,
                                        }}
                                    >
                                        How many calls would you like to sample
                                        in?
                                    </Typography>
                                </FormLabel>
                                <RadioGroup>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        className={classes.gapmd}
                                    >
                                        <FormControlLabel
                                            value="automated"
                                            className={classes.noMargin}
                                            control={<Radio checked />}
                                            label="Sample target: Sample up to"
                                        />
                                        <AcxMainTextField
                                            id="quotaVolume"
                                            fullWidth={false}
                                            containerStyle={{
                                                width: "100px",
                                            }}
                                            textareaRootStyles={{
                                                margin: 0,
                                            }}
                                            type="number"
                                            isDisabled={isSubmitting}
                                            required={true}
                                            value={definition.quotaVolume}
                                            error={
                                                !!errors?.fieldErrors
                                                    ?.quotaVolume
                                            }
                                            showAllErrors={true}
                                            onChange={(event) =>
                                                onChange(
                                                    "quotaVolume",
                                                    event.target.valueAsNumber,
                                                )
                                            }
                                            insertBlankIfNumber
                                        />
                                        <span>interactions</span>
                                        <AcxSelectSingle
                                            id="select-quota-type"
                                            valueField="value"
                                            labelField="option"
                                            isDisabled={isSubmitting}
                                            defaultValue={{
                                                value: definition.quotaType,
                                                option:
                                                    definition.quotaType ===
                                                    "Agent"
                                                        ? "per agent"
                                                        : "in total",
                                            }}
                                            options={[
                                                {
                                                    option: "per agent",
                                                    value: "Agent",
                                                },
                                                {
                                                    option: "in total",
                                                    value: "Volume",
                                                },
                                            ]}
                                            onChange={(quotaType) =>
                                                onChange(
                                                    "quotaType",
                                                    quotaType.value,
                                                )
                                            }
                                        />
                                    </Grid>
                                    {/* <FormControlLabel
                                        value="ondemand"
                                        disabled
                                        control={<Radio />}
                                        label="Everything currently unsampled (coming soon)"
                                    /> */}
                                </RadioGroup>
                            </FormControl>
                            {errors.fieldErrors?.quotaVolume && (
                                <AcxInputLabel
                                    error={!!errors.fieldErrors.quotaVolume}
                                    labelText={errors.fieldErrors.quotaVolume}
                                    showAllErrors={true}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            <AcxSelectMulti
                                id="mediaTypes"
                                valueField="type"
                                labelField="type"
                                inputLabel="What media types should be sampled?"
                                isDisabled={isSubmitting}
                                defaultValue={definition.mediaTypes.map(
                                    (type) => ({
                                        type,
                                    }),
                                )}
                                showAllErrors={true}
                                error={!!errors.fieldErrors?.mediaTypes}
                                helperText={errors.fieldErrors?.mediaTypes}
                                onChange={(types) =>
                                    onChange(
                                        "mediaTypes",
                                        types
                                            ? types.map(({ type }) => type)
                                            : [],
                                    )
                                }
                                options={[
                                    { type: "Audio" },
                                    { type: "ProcessedChat" },
                                ]}
                            />
                        </Grid>
                        <Grid
                            container
                            item
                            direction="column"
                            className={classes.gapmd}
                        >
                            {definition.mediaTypes.includes("Audio") && (
                                <Grid container item direction="column">
                                    <AcxInputLabel labelText="What audio call duration would you like to sample?" />
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        alignItems="center"
                                        className={classes.gapmd}
                                    >
                                        <AcxMainTextField
                                            id=""
                                            fullWidth={false}
                                            containerStyle={{
                                                width: "100px",
                                            }}
                                            textareaRootStyles={{
                                                margin: 0,
                                            }}
                                            type="number"
                                            isDisabled={isSubmitting}
                                            value={
                                                definition
                                                    .audioInteractionDurationMinutes
                                                    .minimum
                                            }
                                            onChange={(event) =>
                                                onChange(
                                                    "audioInteractionDurationMinutes",
                                                    {
                                                        ...definition.audioInteractionDurationMinutes,
                                                        minimum:
                                                            event.target
                                                                .valueAsNumber,
                                                    },
                                                )
                                            }
                                            insertBlankIfNumber
                                        />
                                        <span>to</span>
                                        <AcxMainTextField
                                            id=""
                                            fullWidth={false}
                                            containerStyle={{
                                                width: "100px",
                                            }}
                                            textareaRootStyles={{
                                                margin: 0,
                                            }}
                                            type="number"
                                            isDisabled={isSubmitting}
                                            value={
                                                definition
                                                    .audioInteractionDurationMinutes
                                                    .maximum
                                            }
                                            onChange={(event) =>
                                                onChange(
                                                    "audioInteractionDurationMinutes",
                                                    {
                                                        ...definition.audioInteractionDurationMinutes,
                                                        maximum:
                                                            event.target
                                                                .valueAsNumber,
                                                    },
                                                )
                                            }
                                            insertBlankIfNumber
                                        />
                                        <span>minutes</span>
                                    </Grid>
                                    {errors.fieldErrors
                                        ?.audioInteractionDurationMinutes && (
                                        <AcxInputLabel
                                            error={
                                                !!errors.fieldErrors
                                                    .audioInteractionDurationMinutes
                                            }
                                            labelText={
                                                errors.fieldErrors
                                                    ?.audioInteractionDurationMinutes
                                            }
                                            showAllErrors={true}
                                        />
                                    )}
                                </Grid>
                            )}
                            {definition.mediaTypes.includes(
                                "ProcessedChat",
                            ) && (
                                <Grid container item direction="column">
                                    <AcxInputLabel labelText="What message count exchange for chats would you like to sample?" />
                                    <Grid
                                        container
                                        item
                                        direction="row"
                                        alignItems="center"
                                        className={classes.gapmd}
                                    >
                                        <AcxMainTextField
                                            id=""
                                            fullWidth={false}
                                            containerStyle={{
                                                width: "100px",
                                            }}
                                            textareaRootStyles={{
                                                margin: 0,
                                            }}
                                            type="number"
                                            isDisabled={isSubmitting}
                                            value={
                                                definition.chatMessageCountRange
                                                    .minimum
                                            }
                                            onChange={(event) =>
                                                onChange(
                                                    "chatMessageCountRange",
                                                    {
                                                        ...definition.chatMessageCountRange,
                                                        minimum:
                                                            event.target
                                                                .valueAsNumber,
                                                    },
                                                )
                                            }
                                            insertBlankIfNumber
                                        />
                                        <span>to</span>
                                        <AcxMainTextField
                                            id=""
                                            fullWidth={false}
                                            containerStyle={{
                                                width: "100px",
                                            }}
                                            textareaRootStyles={{
                                                margin: 0,
                                            }}
                                            type="number"
                                            isDisabled={isSubmitting}
                                            value={
                                                definition.chatMessageCountRange
                                                    .maximum
                                            }
                                            onChange={(event) =>
                                                onChange(
                                                    "chatMessageCountRange",
                                                    {
                                                        ...definition.chatMessageCountRange,
                                                        maximum:
                                                            event.target
                                                                .valueAsNumber,
                                                    },
                                                )
                                            }
                                            insertBlankIfNumber
                                        />
                                        <span>messages exchanged in chat</span>
                                    </Grid>
                                    {errors.fieldErrors
                                        ?.chatMessageCountRange && (
                                        <AcxInputLabel
                                            error={
                                                !!errors.fieldErrors
                                                    .chatMessageCountRange
                                            }
                                            labelText={
                                                errors.fieldErrors
                                                    ?.chatMessageCountRange
                                            }
                                            showAllErrors={true}
                                        />
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Container>
    );
}
