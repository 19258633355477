import { Grid } from "@mui/material";
import { Observer, observer } from "mobx-react";
import { Moment } from "moment";
import React from "react";
import {
    DatePickerComponentStore,
    DateReferenceOption, InteractionDateReferenceOption,
} from "stores/ComponentStores/DatePickerComponentStore";
import AcxSelectSingle from "../../Select/BaseSelectComponents/AcxSelectSingle";
import AcxDateRangeInput from "./AcxDateRangeInput";

type sizeType =
    | boolean
    | "auto"
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | undefined;

export type CustomDateInputStyles = {
    containerWrapper?: React.CSSProperties;
    dateInputWrapper?: React.CSSProperties;
    dateInnerStyles?: React.CSSProperties;
    dateRefWrapper?: React.CSSProperties;
};

interface Props {
    datePickerStore: DatePickerComponentStore;
    menuPortalTarget?: HTMLElement | null | undefined;
    dateReferenceOptions: DateReferenceOption[];
    dateRangeStyles?: CustomDateInputStyles;
    lg?: sizeType;
    sm?: sizeType;
    xs?: sizeType;
    disabled?: boolean;
    label?: string;
    fullWidth?: boolean;
}

const allOptions = {
    [DateReferenceOption.ArrivalDate]: {
        label: "Arrival Date",
        value: DateReferenceOption.ArrivalDate,
    },
    [DateReferenceOption.InteractionDate]: {
        label: "Interaction Date",
        value: DateReferenceOption.InteractionDate,
    },
    [DateReferenceOption.EvaluationCompleteDate]: {
        label: "Evaluation Complete Date",
        value: DateReferenceOption.EvaluationCompleteDate,
    },
    [InteractionDateReferenceOption.EvaluationModifiedDate]: {
        label: "Evaluation Modified Date",
        value: InteractionDateReferenceOption.EvaluationModifiedDate,
    }, 
    [DateReferenceOption.EvaluationStartDate]: {
        label: "Evaluation Start Date",
        value: DateReferenceOption.EvaluationStartDate,
    },
};

const AcxDateRangeInputSelect = observer((props: Props) => {
    const store = props.datePickerStore;

    const enabledReferenceOptions = props.dateReferenceOptions.map(
        (o) => allOptions[o],
    );
    return (
        <Grid container style={props.dateRangeStyles?.containerWrapper ?? {}}>
            <Grid
                item
                xs={8}
                style={props.dateRangeStyles?.dateInputWrapper ?? {}}
            >
                <Observer>
                    {() => (
                        <AcxDateRangeInput
                            labelText={props.label ?? "Select Date"}
                            defaultStartDate={store.beginDate}
                            defaultEndDate={store.endDate}
                            onSelect={(start: Moment, end: Moment) => {
                                store.setBeginDate(start);
                                store.setEndDate(end);
                            }}
                            wrapperStyle={
                                props.fullWidth ? { maxWidth: "100%" } : {}
                            }
                            inputStyle={
                                props.fullWidth ? { width: "100%" } : {}
                            }
                            innerStyles={
                                props.dateRangeStyles?.dateInnerStyles ?? {}
                            }
                        />
                    )}
                </Observer>
            </Grid>
            {props.dateReferenceOptions.length > 0 && (
                <Grid
                    item
                    xs={4}
                    style={
                        props.dateRangeStyles
                            ? props.dateRangeStyles.dateRefWrapper
                            : { minWidth: "120px", paddingLeft: "4px" }
                    }
                >
                    <AcxSelectSingle
                        inputLabel="Date by"
                        isDisabled={props.disabled}
                        defaultValue={allOptions[store.referenceOption]}
                        id="referenceSelector"
                        options={enabledReferenceOptions}
                        labelField="label"
                        valueField="value"
                        onChange={(opt) => store.setReferenceOption(opt.value)}
                        menuPortalTarget={props.menuPortalTarget}
                        fullWidth={props.fullWidth}
                    />
                </Grid>
            )}
        </Grid>
    );
});

export default AcxDateRangeInputSelect;
