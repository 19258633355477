import { action, makeObservable, observable } from "mobx";
import { copyBaseFields } from "../../utils/BaseEntityUtils";
import BaseEntity from "../BaseEntity";
import { ReportField } from "./ReportField";

export enum CombinatorFilterType {
    OR,
    AND,
}

export enum Comparator {
    GT,
    LT,
    EQ,
    IN,
    LIKE,
    NEQ,
    ARRAYIN, // backend only
    NOTIN, // backend only
}

export class ReportFilter extends BaseEntity {
    @observable value: string;
    @observable comparator: Comparator;
    @observable reportField?: ReportField;
    @observable reportFieldId?: string;
    @observable combinator?: CombinatorFilterType;
    @observable filterGroupId?: number;
    @action
    setValue = (value: string) => {
        this.value = value;
    };
    @action
    setfilterGroupId = (value?: number) => {
        this.filterGroupId = value;
    };

    @action
    setFilterCombinator = (combinatorFilterType?: CombinatorFilterType) => {
        this.combinator = combinatorFilterType;
    };

    @action
    setComparator = (comparator: Comparator) => {
        this.comparator = comparator;
    };

    @action
    setReportField = (reportField?: ReportField) => {
        this.reportField = reportField;
        this.reportFieldId = reportField?.id;
    };

    constructor(
        id?: string,
        createdBy?: string,
        modifiedBy?: string,
        createdOn?: string,
        modifiedOn?: string,
    ) {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super(id, createdBy, modifiedBy, createdOn, modifiedOn);

        makeObservable(this);
    }

    static fromJson(json: ReportFilter) {
        const cls = new ReportFilter(
            json.id,
            json.createdBy,
            json.modifiedBy,
            json.createdOn,
            json.modifiedOn,
        );
        copyBaseFields(json, cls);
        cls.value = json.value;
        cls.combinator = json.combinator;
        cls.comparator = json.comparator;
        cls.reportFieldId = json.reportFieldId;
        cls.filterGroupId = json.filterGroupId;
        if (json.reportField) {
            cls.reportField = ReportField.fromJson(json.reportField);
        }

        return cls;
    }
}
