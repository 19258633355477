import { Divider, Grid, Paper, Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import { observer } from "mobx-react";
import React from "react";
import { contactTypes } from "stores/ApplicationFilters/Filters/ContactTypes";
import { customerTypesTypographyStyles } from "../OrganizationCustomerTypes";
import OrganizationCustomerTypeMapping from "./OrganizationCustomerTypeMapping";

const OrganizationCustomerTypesMappings = observer(() => {
    return (
        <Paper sx={{ padding: "1rem", borderRadius: "0.5rem" }}>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography style={customerTypesTypographyStyles.title}>
                        Contact Type Naming
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid container item direction="row" spacing={1}>
                    <Grid container item xs={3} direction="column">
                        <Grid item>
                            <Typography
                                style={
                                    customerTypesTypographyStyles.explanationTitle
                                }
                            >
                                Enter Contact Type Names
                            </Typography>
                        </Grid>
                        <Grid container item spacing={1} direction="column">
                            <Grid item>
                                <Typography
                                    style={
                                        customerTypesTypographyStyles.explanationText
                                    }
                                >
                                    Our Contact Type ML model will identify each
                                    conversation as one of these six contact
                                    types. Decide what you would like to call
                                    each type at your organization.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    style={
                                        customerTypesTypographyStyles.explanationText
                                    }
                                >
                                    If you decide to use a name you have already
                                    defined as a contact type, then those
                                    conversations will be aggregated for
                                    reporting and filtering.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <Paper
                            elevation={0}
                            sx={{
                                backgroundColor:
                                    theme.palette.lightgray.background,
                                padding: "1rem",
                                borderRadius: "0.5rem",
                            }}
                        >
                            <Grid container direction="column" spacing={1}>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    flexWrap="nowrap"
                                >
                                    <Grid item xs={3}>
                                        <Typography
                                            style={
                                                customerTypesTypographyStyles.subtitle
                                            }
                                        >
                                            Contact Type
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Typography
                                            style={
                                                customerTypesTypographyStyles.subtitle
                                            }
                                        >
                                            Display Label
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {contactTypes.map((contactType) => (
                                    <Grid item key={contactType}>
                                        <OrganizationCustomerTypeMapping
                                            contactType={contactType}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default OrganizationCustomerTypesMappings;
