import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, makeObservable, observable, reaction } from "mobx";
import {
    CampaignIndicatorDefinition,
    ChatFileSchemaHandlers,
    LanguageIndicatorDefinition,
    MetadataExtendedAssociationField,
    MetadataExtendedField,
    MetadataField,
    OrganizationMetadataConfiguration,
} from "models/OrganizationModels/OrganizationMetadataConfiguration";
import { OrganizationMetadataField } from "models/OrganizationModels/OrganizationMetadataField";
import { OrganizationMetadataFileType } from "models/OrganizationModels/OrganizationMetadataFileType";
import { OrganizationMetadataFileTypeExtension } from "models/OrganizationModels/OrganizationMetadataFileTypeExtension";
import { Campaign, CampaignService } from "services/CampaignService";
import { OrganizationService } from "services/OrganizationService";
import { BaseStore } from "stores/BaseStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { parseFromISO, standardDateFormat } from "utils/DateTimeUtils";
import { EnumToDropdownOptionConversion } from "utils/EnumToDropdownOptionConversion";
import { uuidv4 } from "utils/helpers";
import { DropdownOption } from "../MetadataFieldRow";

export const LOAD_CONFIG = "Load Organization Configuration";
export const SET_CONFIG = "Set Organization Configuration";

@AcxStore
export class OrganizationMetadataStore extends BaseStore {
    // Metadata Configuration
    @observable
    orgId: string;

    @observable
    organizationMetadataFields: DropdownOption<OrganizationMetadataField>[] = [];

    @observable
    organizationMetadataFileTypes: DropdownOption<OrganizationMetadataFileType>[] =
        [];

    @observable
    organizationMetadataFileTypeExtensions: DropdownOption<OrganizationMetadataFileTypeExtension>[] =
        [];

    @observable
    orgCampaigns: Campaign[] = [];

    @observable
    organizationMetadataConfiguration: OrganizationMetadataConfiguration[] = observable.array(
        [],
    );

    @observable
    configErrors: any = {};

    private readonly orgService: OrganizationService =
        new OrganizationService();

    private readonly campaignService: CampaignService = new CampaignService();

    private readonly messageStore: MessageStore;

    constructor(public rootStore: IRootStore) {
        super("OrganizationStore");

        makeObservable(this);

        this.updateOrganizationMetadataDropdowns(
            EnumToDropdownOptionConversion(OrganizationMetadataField),
            EnumToDropdownOptionConversion(OrganizationMetadataFileType),
            EnumToDropdownOptionConversion(
                OrganizationMetadataFileTypeExtension,
            ),
        );
        this.messageStore = rootStore.getStore(MessageStore);

        reaction(
            (r) => ({
                orgId: this.orgId,
                activeLocation: this.rootStore.activeLocation,
            }),
            (args) => {
                if (
                    args.activeLocation &&
                    args.activeLocation.location.includes("admin/organizations")
                ) {
                    this.setupAsyncTask(LOAD_CONFIG, async () => {
                        this.organizationMetadataConfiguration = [];

                        if (args.orgId) {
                            const config =
                                await this.orgService.getOrganizationMetadataConfiguration();
                            if (config.length > 0) {
                                this.organizationMetadataConfiguration = config;
                            }

                            this.orgCampaigns =
                                await this.campaignService.getCampaigns();
                        }
                    });
                }
            },
        );
    }

    @action
    handleFileAdd = () => {
        this.organizationMetadataConfiguration = [
            ...this.organizationMetadataConfiguration,
            {
                configuration: {
                    fields: [],
                    prefix: "",
                    nameSpace: "",
                    metaDataFileType: undefined,
                    campaignIndicatorDefinitions: [],
                    languageIndicatorDefinitions: [],
                    extendedFields: [],
                    metaDataExtendedAssociationFields: [],
                    chatFileSchemaHandler: undefined,
                    mediaSegments: null,
                    agentCodeRequired: true,
                    customDateFormatPatterns: [],
                },
                createdBy: "",
                createdOn: standardDateFormat(
                    parseFromISO(new Date().toISOString()),
                ),
                isActive: true,
                modifiedBy: "",
                organizationId: this.orgId,
                id: uuidv4(),
            },
        ];
    };

    @action
    handleFileRemove = (id?: string) => {
        if (id) {
            this.organizationMetadataConfiguration = [
                ...this.organizationMetadataConfiguration.filter(
                    (item) => item.id !== id,
                ),
            ];
        }
    };

    @action
    addMetadataField = (configIndex: number) => {
        if (
            !this.organizationMetadataConfiguration[configIndex].configuration
                .fields
        ) {
            this.organizationMetadataConfiguration[
                configIndex
            ].configuration.fields = [];
        }
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.fields = [
            ...this.organizationMetadataConfiguration[configIndex].configuration
                .fields,
            new MetadataField(),
        ];
    };

    @action
    addCampaignIndicatorDefinition = (configIndex: number) => {
        let storeData =
            this.organizationMetadataConfiguration[configIndex].configuration
                .campaignIndicatorDefinitions;

        if (!storeData) {
            storeData = [];
        }
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.campaignIndicatorDefinitions = [
            ...storeData,
            new CampaignIndicatorDefinition(),
        ];
    };

    @action
    addLanguageIndicatorDefinition = (configIndex: number) => {
        let storeData =
            this.organizationMetadataConfiguration[configIndex].configuration
                .languageIndicatorDefinitions;

        if (!storeData) {
            storeData = [];
        }
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.languageIndicatorDefinitions = [
            ...storeData,
            new LanguageIndicatorDefinition(),
        ];
    };

    @action
    addExtendedField = (configIndex: number) => {
        let storeData =
            this.organizationMetadataConfiguration[configIndex].configuration
                .extendedFields;

        if (!storeData) {
            storeData = [];
        }
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.extendedFields = [
            ...storeData,
            new MetadataExtendedField(),
        ];
    };

    @action
    addExtendedAssociationField = (configIndex: number) => {
        let storeData =
            this.organizationMetadataConfiguration[configIndex].configuration
                .metaDataExtendedAssociationFields;

        if (!storeData) {
            storeData = [];
        }
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.metaDataExtendedAssociationFields = [
            ...storeData,
            new MetadataExtendedAssociationField(),
        ];
    };

    @action
    addMediaSegments = (configIndex: number) => {
        let storeData =
            this.organizationMetadataConfiguration[configIndex].configuration
                .mediaSegments;

        if (!storeData) {
            storeData = {
                start: {
                    id: uuidv4(),
                    isRequired: true,
                    valuePath: "",
                },
                end: {
                    id: uuidv4(),
                    primary: {
                        id: uuidv4(),
                        isRequired: true,
                        valuePath: "",
                    },
                    alternate: {
                        id: uuidv4(),
                        isRequired: true,
                        valuePath: "",
                    },
                },
                agentName: {
                    id: uuidv4(),
                    isRequired: true,
                    valuePath: "",
                },
                agentId: {
                    id: uuidv4(),
                    isRequired: true,
                    valuePath: "",
                },
                id: uuidv4(),
                rootPath: "",
            };
        }
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.mediaSegments = storeData;
    };

    @action
    handleRemoveMediaSegments = (configIndex: number) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.mediaSegments = null;
    };

    @action
    removeFieldConfiguration = (
        key:
            | "fields"
            | "campaignIndicatorDefinitions"
            | "languageIndicatorDefinitions"
            | "extendedFields"
            | "metaDataExtendedAssociationFields",
        configId: string,
        configIndex: number,
    ) => {
        let storeData = this.organizationMetadataConfiguration[configIndex]
            .configuration[key] as any;

        const newData = storeData?.filter((item) => item.id !== configId);

        this.organizationMetadataConfiguration[configIndex].configuration[key] =
            newData;
    };

    @action
    setOrgId = (orgId: string) => {
        this.orgId = orgId;
    };

    @action
    updateOrganizationMetadataDropdowns = (
        fields: DropdownOption<OrganizationMetadataField>[],
        fileTypes: DropdownOption<OrganizationMetadataFileType>[],
        fileTypeExtensions: DropdownOption<OrganizationMetadataFileTypeExtension>[],
    ) => {
        this.organizationMetadataFields = fields;
        this.organizationMetadataFileTypes = fileTypes;
        this.organizationMetadataFileTypeExtensions = fileTypeExtensions;
    };

    // File Handlers
    @action
    handleMetadataFileTypeUpdates = (
        configIndex: number,
        value: OrganizationMetadataFileType | undefined,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.metaDataFileType = value;
    };

    @action
    handleMetadataFileTypeExtensionUpdates = (
        configIndex: number,
        value: OrganizationMetadataFileTypeExtension | undefined,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.metaDataFileTypeExtension = value;
    };

    @action
    handleNameSpaceUpdates = (configIndex: number, value: string) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.nameSpace = value;
    };

    @action
    handleChatFileSchemaHandlerUpdates = (
        configIndex: number,
        value: ChatFileSchemaHandlers | undefined,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.chatFileSchemaHandler = value;
    };

    @action
    handlePrefixUpdates = (configIndex: number, value: string) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.prefix = value;
    };

    @action
    handleNameUpdates = (configIndex: number, value: string) => {
        this.organizationMetadataConfiguration[configIndex].configuration.name =
            value;
    };

    @action
    handleIsDebugUpdates = (configIndex: number, value: boolean) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.isDebug = value;
    };

    @action
    handleAgentRequiredUpdates = (configIndex: number, value: boolean) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.agentCodeRequired = value;
    };

    // Field Handlers
    @action
    handleFieldUpdates = (
        configIndex: number,
        field: number,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.fields[fieldIndex].field = field;
    };

    @action
    handleFieldIsRequiredUpdates = (
        configIndex: number,
        required: boolean,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.fields[fieldIndex].isRequired = required;
        // TODO: find better way to re-render
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.fields[fieldIndex] = {
            ...this.organizationMetadataConfiguration[configIndex].configuration
                .fields[fieldIndex],
        };
    };

    @action
    handleXPathUpdate = (
        configIndex: number,
        xPath: string,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.fields[fieldIndex].xPathString = xPath;
    };

    // Extended Field Handlers
    @action
    handleExtendedFieldIsRequiredUpdates = (
        configIndex: number,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.extendedFields![fieldIndex].isRequired =
            !this.organizationMetadataConfiguration[configIndex].configuration
                .extendedFields![fieldIndex].isRequired;
        //TODO find better solution
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.extendedFields![fieldIndex] = {
            ...this.organizationMetadataConfiguration[configIndex].configuration
                .extendedFields![fieldIndex],
        };
    };

    @action
    handleExtractionPatternUpdate = (
        configIndex: number,
        extractionPattern: string,
        fieldIndex: number,
        fieldType: "MetadataField" | "MetadataExtendedField",
    ) => {
        if (fieldType === "MetadataField") {
            this.organizationMetadataConfiguration[
                configIndex
            ].configuration.fields[fieldIndex].extractionPattern =
                extractionPattern;
        } else if (fieldType === "MetadataExtendedField") {
            this.organizationMetadataConfiguration[
                configIndex
            ].configuration.extendedFields![fieldIndex].extractionPattern =
                extractionPattern;
        }
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.fields[fieldIndex] = {
            ...this.organizationMetadataConfiguration[configIndex].configuration
                .fields[fieldIndex],
        };
    };

    @action
    handledIsIndexedUpdates = (configIndex: number, fieldIndex: number) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.extendedFields![fieldIndex].isIndexed =
            !this.organizationMetadataConfiguration[configIndex].configuration
                .extendedFields![fieldIndex].isIndexed;
        //TODO find better solution
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.extendedFields![fieldIndex] = {
            ...this.organizationMetadataConfiguration[configIndex].configuration
                .extendedFields![fieldIndex],
        };
    };

    @action
    handleExtendedFieldPathUpdates = (
        configIndex: number,
        fieldPath: string,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.extendedFields![fieldIndex].fieldPath = fieldPath;
    };

    @action
    handleExtendedFieldNameUpdates = (
        configIndex: number,
        fieldName: string,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.extendedFields![fieldIndex].fieldName = fieldName;
    };

    @action
    handleJsonDataTypeUpdate = (
        configIndex: number,
        jsonDataType: number,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.extendedFields![fieldIndex].jsonDataType = jsonDataType;
    };

    // Extended Assoc. Handlers
    @action
    handleMetadataExtendedAssociationFieldNameUpdates = (
        configIndex: number,
        fieldName: string,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.metaDataExtendedAssociationFields![
            fieldIndex
        ].extendedMetadataFieldName = fieldName;
    };

    @action
    handleMetadataExtendedAssociationFieldUpdates = (
        configIndex: number,
        data: number,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.metaDataExtendedAssociationFields![
            fieldIndex
        ].metaDataField = data;
    };

    // Campaign Handlers
    @action
    handleCampaignFieldPathUpdates = (
        configIndex: number,
        fieldPath: string,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.campaignIndicatorDefinitions![fieldIndex].fieldPath =
            fieldPath;
    };

    @action
    handleCampaignUpdates = (
        configIndex: number,
        campaignId: string,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.campaignIndicatorDefinitions![fieldIndex].campaignId =
            campaignId;
    };

    @action
    handleCampaignIndicatorStringUpdates = (
        configIndex: number,
        value: string,
        fieldIndex: number,
        stringIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.campaignIndicatorDefinitions![
            fieldIndex
        ].campaignIndicatorStrings[stringIndex] = value;
    };

    @action
    handleCampaignComparisonUpdates = (
        configIndex: number,
        value: number,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.campaignIndicatorDefinitions![
            fieldIndex
        ].comparisonOperator = value;
    };

    @action
    addCampaignIndicatorString = (
        configIndex: number,
        currentIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.campaignIndicatorDefinitions![
            currentIndex
        ].campaignIndicatorStrings.push("");

        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.campaignIndicatorDefinitions![currentIndex] = {
            ...this.organizationMetadataConfiguration[configIndex].configuration
                .campaignIndicatorDefinitions![currentIndex],
        };
    };

    // Language Handlers
    @action
    handleLanguageFieldPathUpdates = (
        configIndex: number,
        fieldPath: string,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.languageIndicatorDefinitions![fieldIndex].fieldPath =
            fieldPath;
    };

    @action
    handleLanguageLocaleIdUpdates = (
        configIndex: number,
        localeId: number,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.languageIndicatorDefinitions![fieldIndex].localeId =
            localeId;
    };

    @action
    handleLanguageIndicatorStringUpdates = (
        configIndex: number,
        value: string,
        fieldIndex: number,
        stringIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.languageIndicatorDefinitions![
            fieldIndex
        ].languageIndicatorStrings[stringIndex] = value;
    };

    @action
    handleLanguageComparisonUpdates = (
        configIndex: number,
        value: number,
        fieldIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.languageIndicatorDefinitions![
            fieldIndex
        ].comparisonOperator = value;
    };

    @action
    addLanguageIndicatorString = (
        configIndex: number,
        currentIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.languageIndicatorDefinitions![
            currentIndex
        ].languageIndicatorStrings.push("");

        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.languageIndicatorDefinitions![currentIndex] = {
            ...this.organizationMetadataConfiguration[configIndex].configuration
                .languageIndicatorDefinitions![currentIndex],
        };
    };

    @action
    handleDateFormatPatternUpdates = (
        configIndex: number,
        value: string,
        stringIndex: number,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.customDateFormatPatterns![stringIndex] = value;
    };

    @action
    addDateFormatPattern = (configIndex: number) => {
        let currentArray =
            this.organizationMetadataConfiguration[configIndex].configuration
                .customDateFormatPatterns;
        if (!currentArray) {
            currentArray = [];
        }
        currentArray.push("");

        this.organizationMetadataConfiguration[
            configIndex
        ].configuration.customDateFormatPatterns = [...currentArray];
    };

    // Media Segments change handlers
    @action
    handleMediaSegmentsUpdates = (
        configIndex: number,
        value: string,
        key: "start" | "agentName" | "agentId",
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration!.mediaSegments![key].valuePath = value;
    };

    @action
    handleMediaSegmentsRootPathUpdates = (
        configIndex: number,
        value: string,
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration!.mediaSegments!.rootPath = value;
    };

    @action
    handleMediaSegmentsEndUpdates = (
        configIndex: number,
        value: string,
        key: "primary" | "alternate",
    ) => {
        this.organizationMetadataConfiguration[
            configIndex
        ].configuration!.mediaSegments!.end[key].valuePath = value;
    };

    @action
    public setOrganizationMetadataConfiguration = () => {
        this.setupAsyncTask(SET_CONFIG, async () => {
            this.organizationMetadataConfiguration.forEach(
                this.validateConfiguration,
            );

            if (Object.keys(this.configErrors).length) {
                this.messageStore.logError(
                    "Failed to save configuration, check media segments fields for errors",
                );

                return;
            }

            try {
                const res =
                    await this.orgService.setOrganizationMetadataConfiguration(
                        this.orgId,
                        this.organizationMetadataConfiguration,
                    );

                this.organizationMetadataConfiguration = res;
                this.messageStore.logMessage(
                    `Metadata Configuration update successful.`,
                    "success",
                );
            } catch {
                this.messageStore.logMessage(
                    `Metadata Configuration update failed.`,
                    "error",
                );
            }
        });
    };

    validateConfiguration = (
        config: OrganizationMetadataConfiguration,
        configIndex: number,
    ) => {
        // TODO: clean up validation
        if (config.configuration.mediaSegments) {
            this.configErrors[configIndex] = [];
            if (config.configuration.mediaSegments.rootPath === "") {
                this.configErrors[configIndex].push("rootPath");
            } else if (
                config.configuration.mediaSegments.start.valuePath === ""
            ) {
                this.configErrors[configIndex].push("start.valuePath");
            } else if (
                config.configuration.mediaSegments.end.primary.valuePath === ""
            ) {
                this.configErrors[configIndex].push("end.primary.valuePath");
            } else if (
                config.configuration.mediaSegments.agentName.valuePath === ""
            ) {
                this.configErrors[configIndex].push("agentName.valuePath");
            } else if (
                config.configuration.mediaSegments.agentId.valuePath === ""
            ) {
                this.configErrors[configIndex].push("agentId.valuePath");
            }
            if (this.configErrors[configIndex].length === 0) {
                delete this.configErrors[configIndex];
            }
        } else if (
            this.configErrors[configIndex] &&
            !config.configuration.mediaSegments
        ) {
            delete this.configErrors[configIndex];
        }
        if (config.configuration.customDateFormatPatterns) {
            config.configuration.customDateFormatPatterns =
                config.configuration.customDateFormatPatterns.filter(
                    (item) => !!item,
                );
        }
    };
}
