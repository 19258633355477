import { Observer } from "mobx-react";
import { AssignedWorkflowInstance } from "models/Workflows/WorkflowInstance";
import React from "react";
import { Answer } from "../../../../models/Answer";
import { AnswerTypeNames } from "../../../../models/AnswerType";
import {
    QuestionConditionalGroupType,
    QuestionDependencyCondition,
    QuestionDependencyQuestionAnswerOperator,
    QuestionDependencyType,
} from "../../../../models/Question";
import { ThemeColors } from "../../../../Theme/AppTheme";
import hexToRGB from "../../../../utils/hexToRGB";
import {
    EvalLicensedModule,
    EvalQuestion,
    EvalStore,
} from "../../Stores/EvalStore";
import ScaledResponseAnswer from "./ScaledResponseAnswer";
import StarRatingAnswer from "./StarRatingAnswer";
import TagOrFillInResponseAnswer from "./TagOrFillInResponseAnswer";

interface OwnProps {
    module?: EvalLicensedModule;
    store: EvalStore;
    standalone?: boolean;
    isEditable?: boolean;
}

type Props = OwnProps;

function isConditionMet(
    condition: QuestionDependencyCondition,
    answers: Answer[],
    evaluationModuleId: string,
): boolean {
    switch (condition.type) {
        case QuestionDependencyType.QuestionAnswer:
            const value = condition.value;
            const answer = answers.find(
                (a) =>
                    a.questionId === condition.dependsOnQuestionId &&
                    a.evaluationModuleId === evaluationModuleId,
            );

            if (!answer) {
                return false;
            }

            switch (condition.op) {
                case QuestionDependencyQuestionAnswerOperator.Contains:
                    return answer.activeAnswerTags.some(
                        (t) => t.tag?.value === value,
                    );
                case QuestionDependencyQuestionAnswerOperator.IsAnswered:
                    return !!answer.activeAnswerTags.length;
            }

            break;
        case QuestionDependencyType.ConditionalGroup:
            switch (condition.conditionalGroupType) {
                case QuestionConditionalGroupType.And:
                    return condition.conditions.every((c) =>
                        isConditionMet(c, answers, evaluationModuleId),
                    );
                case QuestionConditionalGroupType.Or:
                    return condition.conditions.some((c) =>
                        isConditionMet(c, answers, evaluationModuleId),
                    );
            }
    }
}

export function shouldRender(
    answers: Answer[] | undefined,
    question: EvalQuestion,
) {
    if (!question.dependencies) {
        return true;
    }
    if (!question.dependencies.conditions) {
        return true;
    }
    if (!question.dependencies.conditions.length) {
        return true;
    }
    if (!answers) {
        return false;
    }
    if (!answers.length) {
        return false;
    }

    return question.dependencies.conditions.every((d) =>
        isConditionMet(d, answers, question.evaluationModuleId),
    );
}

const QuestionAnswerList = (props: Props) => {
    return (
        <Observer>
            {() => {
                const questionsWithDependencies =
                    props.module?.questions.filter(
                        (r) => r.dependencies?.conditions.length,
                    );

                const sortedQuestions = (
                    props.module?.sortedQuestion ?? []
                ).filter((q) => {
                    var evalQuestion = q as EvalQuestion;
                    evalQuestion.evaluationModuleId = props.module
                        ?.evaluationModuleId as string;
                    return shouldRender(
                        props.store.currentEval?.answers,
                        evalQuestion,
                    );
                });

                const moduleUIModel = props.store.getModuleUIModel(
                    props.module?.evaluationModuleId,
                );

                return (
                    <>
                        {props.module?.questions && (
                            <>
                                {sortedQuestions?.map((value, index) => {
                                    let isEditable =
                                        !props.store.hasInProgressWorkflows && props.store.currentEval?.isEditable;

                                    if (props.module?.isWorkflowModule) {
                                        const assignedToUser =
                                            props.store?.currentEval?.workflowInstances?.some(
                                                (instance) =>
                                                    (
                                                        instance as AssignedWorkflowInstance
                                                    ).assignedToUser &&
                                                    instance.isActive,
                                            );
                                        isEditable = assignedToUser;
                                    }

                                    const answer =
                                        props.store.currentEval?.getAnswerForQuestion(
                                            value,
                                            moduleUIModel?.licensedModule
                                                .evaluationModuleId,
                                        );
                                    if (answer?.isDisputed) {
                                        if (
                                            !props.store.currentEval
                                                ?.canChangeDisputedAnswers
                                        ) {
                                            //answer is disputed, but current user cannot change disputed question
                                            //force answer to be readonly / not editable
                                            isEditable = false;
                                        }
                                    }

                                    if (value.isActive) {
                                        const leftBorder =
                                            value.parentId &&
                                            questionsWithDependencies?.includes(
                                                value,
                                            )
                                                ? hexToRGB(
                                                      ThemeColors.purple,
                                                      1,
                                                  )
                                                : questionsWithDependencies?.includes(
                                                      value,
                                                  )
                                                ? hexToRGB(
                                                      ThemeColors.red,
                                                      0.75,
                                                  )
                                                : value.parentId
                                                ? hexToRGB(
                                                      ThemeColors.blue,
                                                      0.5,
                                                  )
                                                : undefined;

                                        if (
                                            value.answerType.answerTypeName ===
                                                AnswerTypeNames.ScaledResponse ||
                                            value.answerType.answerTypeName ===
                                                AnswerTypeNames.ScaledBooleanResponse ||
                                            value.answerType.answerTypeName ===
                                                AnswerTypeNames.QuestionGrouping
                                        ) {
                                            return (
                                                <ScaledResponseAnswer
                                                    moduleUIModel={
                                                        moduleUIModel
                                                    }
                                                    answerDisputes={
                                                        answer?.answerDisputes
                                                    }
                                                    question={value}
                                                    standalone={
                                                        props.standalone
                                                    }
                                                    leftBorderColor={leftBorder}
                                                    key={value.id}
                                                    isDisabled={
                                                        props.isEditable !==
                                                        undefined
                                                            ? !props.isEditable
                                                            : !isEditable
                                                    }
                                                    showDisputeCheckbox={
                                                        props.store
                                                            .disputeHasStarted
                                                    }
                                                />
                                            );
                                        } else if (
                                            value.answerType.answerTypeName ===
                                                AnswerTypeNames.TagResponse ||
                                            value.answerType.answerTypeName ===
                                                AnswerTypeNames.ScoredTagResponse ||
                                            value.answerType.answerTypeName ===
                                                AnswerTypeNames.TextResponse ||
                                            value.answerType.answerTypeName ===
                                                AnswerTypeNames.DateResponse
                                        ) {
                                            return (
                                                <TagOrFillInResponseAnswer
                                                    key={value.id}
                                                    standalone={
                                                        props.standalone
                                                    }
                                                    leftBorderColor={leftBorder}
                                                    moduleUIModel={
                                                        moduleUIModel
                                                    }
                                                    question={value}
                                                    isDisabled={
                                                        props.isEditable !==
                                                        undefined
                                                            ? !props.isEditable
                                                            : !isEditable
                                                    }
                                                />
                                            );
                                        } else if (
                                            value.answerType.answerTypeName ===
                                            AnswerTypeNames.StarRating
                                        ) {
                                            return (
                                                <StarRatingAnswer
                                                    key={value.id}
                                                    standalone={
                                                        props.standalone
                                                    }
                                                    moduleUIModel={
                                                        moduleUIModel
                                                    }
                                                    question={value}
                                                    leftBorderColor={leftBorder}
                                                    isDisabled={
                                                        props.isEditable !==
                                                        undefined
                                                            ? !props.isEditable
                                                            : !isEditable
                                                    }
                                                    showDisputeCheckbox={
                                                        props.store
                                                            .disputeHasStarted
                                                    }
                                                />
                                            );
                                        } else {
                                            return "";
                                        }
                                    } else {
                                        return "";
                                    }
                                })}
                            </>
                        )}
                    </>
                );
            }}
        </Observer>
    );
};

export default QuestionAnswerList;
