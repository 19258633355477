import createStyles from '@mui/styles/createStyles';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from "react";
import useStyles from "Styles/Styles";
import { ViewType } from "./OrganizationAnalystGroupsList";

const styles = () =>
    createStyles({
        toggleButtonGroup: {
            marginLeft: 12,
            marginRight: 12,
            backgroundColor: "#ffffff",
        },
        toggleButton: {
            width: 31,
            height: 31,
            padding: 4,
            border: "none",
            "&.Mui-selected": {
                backgroundColor: "#daeafe",
                color: "#3464d5",
            },
        },
    });

type Props = {
    changeView: any;
    view: ViewType;
};

const OrganizationAnalystGroupsListViewSelect: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({
    changeView,
    view,
}) => {
    const classes = useStyles(styles);

    return (
        <ToggleButtonGroup
            value={view}
            exclusive
            onChange={(
                e: React.MouseEvent<HTMLElement, MouseEvent>,
                value: ViewType,
            ) => {
                if (value) {
                    changeView(value);
                }
            }}
            aria-label="text alignment"
            className={classes.toggleButtonGroup}
        >
            <ToggleButton
                value="listView"
                aria-label="List view"
                className={classes.toggleButton}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clipRule="evenodd"
                    />
                </svg>
            </ToggleButton>
            <ToggleButton
                value="cardView"
                aria-label="Card view"
                className={classes.toggleButton}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default OrganizationAnalystGroupsListViewSelect;
