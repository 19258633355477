import { Button, ButtonProps, CircularProgress, Tooltip } from "@mui/material";
import React from "react";

interface IAcxButtonPropsBase extends ButtonProps {
    tooltip?: string;
    loading?: boolean;
    containerStyle?: Object;
    acxTheme?: "primary" | "secondary" | "tertiary";
}

export type IAcxButtonProps = Omit<IAcxButtonPropsBase, "color"> & {
    color?:
        | Exclude<
              ButtonProps["color"],
              "secondary" | "success" | "info" | "warning"
          >
        | "white"
        | "primary";
};

const AcxButton = React.forwardRef<
    React.ComponentRef<typeof Button>,
    IAcxButtonProps
>(({ tooltip, loading, containerStyle, disabled, color, ...props }) => (
    <Tooltip title={tooltip || (disabled ? "Disabled" : "")} arrow>
        <span
            style={{
                position: "relative",
                display: "flex",
                ...containerStyle,
                ...(props.fullWidth ? { width: "100%" } : {}),
            }}
        >
            <Button
                disableElevation
                {...props}
                color={color === "white" ? "secondary" : color}
                disabled={disabled || loading}
            >
                {props.children}
                {loading && (
                    <CircularProgress
                        color={"primary"}
                        size={20}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: -10,
                            marginLeft: -10,
                        }}
                    />
                )}
            </Button>
        </span>
    </Tooltip>
));
export default AcxButton;

AcxButton.defaultProps = {
    color: "primary",
    children: "Button",
    variant: "contained",
};
