import { Alert } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import clsx from "clsx";
import ChromeTabs from "components/UI/SubTabs/ChromeTabs";
import Gutters from "Layouts/Responsive/Gutters";
import { autorun, runInAction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { useStore } from "utils/useStore";
import { MontageSoundClipStore } from "./Stores/MontageSoundClipStore";
import MontageClipEditor from "./Views/MontageClipEditor";
import MontageClipEditorPage from "./Views/MontageClipEditorPage";
import MontageList from "./Views/MontageList";
import SoundClipList from "./Views/SoundClipList";

const styles = () =>
    createStyles({
        hidden: {
            display: "none",
            width: "100%",
            height: "0",
        },
        visible: {
            width: "100%",
            height: "calc(100% - 0px)",
            overflow: "auto",
        },
        filterBar: {
            height: "60px",
        },
        // content: {
        //     width: "96%%",
        //     marginLeft: "2%",
        //     marginRight: "2%",
        //     marginTop: "2rem",
        //     zIndex: 5,
        // },
    });

const MontageLibrary = observer(() => {
    const classes = useStyles(styles);
    const store = useStore(MontageSoundClipStore);
    const authStore = useStore(AuthStore);
    const orgStore = authStore.orgStore;
    const [warning, triggerWarning] = React.useState<boolean>(false);

    function getClass(tabValue: number) {
        if (tabValue === store.tabIndex) {
            return classes.visible;
        } else {
            return classes.hidden;
        }
    }

    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgId = orgStore.selectedOrganization.id;
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    React.useEffect(() => {
        if (authStore.canUserEdit("Montage Library")) {
            if (store.tabs.length < 3) {
                runInAction(() => {
                    store.tabs.push(
                        { label: "Audio Clips" },
                        { label: "Montage Builder" },
                        { label: "Montage List" },
                    );
                });
            }
        } else {
            runInAction(() => {
                if (store.tabs.length > 0) {
                    store.tabs = store.tabs.filter(
                        (tab) => tab.label === "Montage List",
                    );
                } else {
                    store.tabs.push({ label: "Montage List" });
                }

                store.tabIndex = 2;
            });
        }
    }, [authStore.permStore, authStore, store]);

    React.useEffect(() => {
        const dispose = autorun(() => {
            const clips = store.soundClipDgStore.selectedRows;

            if (clips) {
                let totalDuration = 0; // In Seconds
                for (let clip of clips) {
                    totalDuration += clip.endTime - clip.startTime;
                }

                // Convert total seconds to milliseconds and compare
                totalDuration * 1000 >= 20 * 60 * 1000 // 20 Minutes
                    ? triggerWarning(true)
                    : triggerWarning(false);
            } else {
                triggerWarning(false);
            }
        });

        return dispose;
    }, [store.soundClipDgStore.selectedRows, store.soundClips]);

    return (
        // @ts-expect-error: error coming from outdated react-dnd package prop types
        <DndProvider backend={HTML5Backend}>
            {warning && (
                <Alert severity="warning">
                    Montages at, or exceeding, 20 minutes in duration may
                    experience issues in performance.
                </Alert>
            )}

            <ChromeTabs
                tabs={store.tabs}
                activeTabIndex={store.tabIndex}
                onChange={store.onTabChange}
            />

            {authStore.canUserEdit("Montage Library") && (
                <>
                    <div className={clsx("pendo-ignore", getClass(0))}>
                        <Gutters justifyContent="center" height="100%">
                            <SoundClipList />
                        </Gutters>
                    </div>

                    <div className={getClass(1)}>
                        <MontageClipEditorPage store={store}>
                            <MontageClipEditor
                                store={store}
                                authStore={authStore}
                            />
                        </MontageClipEditorPage>
                    </div>
                </>
            )}

            <div id="montage-list-parent" className={getClass(2)}>
                <Gutters justifyContent="center" height="100%">
                    <MontageList orgId={store.orgId} />
                </Gutters>
            </div>
        </DndProvider>
    );
});

export default MontageLibrary;
