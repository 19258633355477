import theme from "Theme/AppTheme";
import { Speaker } from "./constants";

export const getTranscriptionSpeakerColor = (speaker: string) => {
    switch (speaker) {
        case Speaker.Agent_1:
            return theme.palette.primary.main;
        case Speaker.Customer_1:
            return theme.palette.secondary[800];
        case Speaker.Agent_2:
            return theme.palette.primary[800];
        case Speaker.Customer_2:
            return theme.palette.secondary[400];
        case Speaker.Agent_3:
            return theme.palette.primary[300];
        case Speaker.Customer_3:
            return theme.palette.success[300];
        case Speaker.IVR:
            return theme.palette.gray[400];
        default:
            return "";
    }
};
