import AddIcon from "@mui/icons-material/Add";
import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";

import { ReportEditorStore } from "../../Stores/ReportEditorStore";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { ReportField } from "models/Reporting/ReportField";
import { Comparator } from "models/Reporting/ReportFilter";
import hexToRGB from "utils/hexToRGB";
import { useStore } from "utils/useStore";

const comparatorFunctions = [
    { id: Comparator.EQ, value: "Is Equal To" },
    { id: Comparator.NEQ, value: "Is Not Equal To" },
    { id: Comparator.GT, value: "Is Greater Than" },
    { id: Comparator.LT, value: "Is Less Than" },
    { id: Comparator.IN, value: "Is Member Of" },
];

function comparatorSelector(comp?: Comparator) {
    switch (comp) {
        case Comparator.IN:
            return { value: "Is Member Of", id: comp };
        case Comparator.EQ:
            return { value: "Is Equal To", id: comp };
        case Comparator.NEQ:
            return { value: "Is Not Equal To", id: comp };
        case Comparator.GT:
            return { value: "Is Greater Than", id: comp };
        case Comparator.LT:
            return { value: "Is Less Than", id: comp };
        default:
            return undefined;
    }
}

interface OwnProps {
    displayId: number;
}

type Props = OwnProps;

const FilterRules: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const store = useStore(ReportEditorStore);

    const reportFields = store.currentReport?.activeReportQueryFilterFields;

    function reportSectionInit(reportFields) {
        let returnStatus = false;
        reportFields?.forEach((reportField: any) => {
            if (
                reportField?.reportFilters[0].filterGroupId ===
                    props.displayId ||
                reportField?.reportFilters[0].filterGroupId === undefined ||
                reportField?.reportFilters[0].filterGroupId === null
            ) {
                returnStatus = true;
            }
        });
        return returnStatus;
    }
    if (reportFields?.length === 0 || !reportSectionInit(reportFields)) {
        store.currentReport?.addFilterReportFieldV2(props.displayId);
    }

    function handleUpdateFilterValue(
        evt: React.ChangeEvent<HTMLInputElement>,
        reportField: ReportField,
    ) {
        let reportFilter = reportField.reportFilter;
        if (!reportFilter) {
            reportFilter = reportField.addReportFilter();
        }
        let value = evt.currentTarget.value;
        reportFilter.setValue(value);
    }

    function handleUpdateComparator(
        comparator: Comparator,
        reportField: ReportField,
    ) {
        let reportFilter = reportField.reportFilter;
        if (!reportFilter) {
            reportFilter = reportField.addReportFilter();
        }
        reportFilter.setComparator(comparator);
    }
    function displayReportField(reportField) {
        return (
            reportField?.reportFilters[0].filterGroupId === props.displayId ||
            reportField?.reportFilters[0].filterGroupId === undefined ||
            reportField?.reportFilters[0].filterGroupId === null
        );
    }

    return (
        <>
            <Grid
                container
                item
                xs={12}
                alignItems={"flex-end"}
                spacing={2}
                sx={(theme) => ({ marginTop: theme.spacing(1.5) })}
            >
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            letterSpacing: 0,
                            lineHeight: "2px",
                        }}
                    >
                        Only include records where:
                    </Typography>
                    <ButtonBase
                        onClick={() =>
                            store.currentReport?.removeReportFieldSection(
                                props.displayId,
                            )
                        }
                        sx={(theme) => ({
                            fontWeight: theme.typography.fontWeightBold as any,
                            textTransform: "none",
                            backgroundColor: theme.palette.black.main,
                            width: "17px",
                            height: "17px",
                            color: theme.palette.white.main,
                            borderRadius: "50%",
                            marginLeft: "103%",
                            marginTop: "-10% !important",
                            paddingBottom: "2px",
                        })}
                    >
                        x
                    </ButtonBase>
                </Grid>

                {reportFields?.map((reportField, index) => {
                    if (displayReportField(reportField)) {
                        return (
                            <React.Fragment
                                key={`report-filters-${
                                    reportField.id ?? index
                                }`}
                            >
                                <Grid item xs={4}>
                                    <AcxSelectSingle
                                        options={
                                            store.availableReportQueryFilterFields ??
                                            []
                                        }
                                        valueField={"displayName"}
                                        labelField={"displayName"}
                                        placeholder={"Data Field"}
                                        onChange={
                                            reportField.setReportDataField
                                        }
                                        defaultValue={
                                            reportField.reportDataField
                                        }
                                        id={`report-filter-rule-datafield-${
                                            reportField.id ?? index
                                        }`}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AcxSelectSingle
                                        options={comparatorFunctions}
                                        onChange={(arg0) =>
                                            handleUpdateComparator(
                                                arg0.id,
                                                reportField,
                                            )
                                        }
                                        placeholder={"Comparison Function"}
                                        defaultValue={comparatorSelector(
                                            reportField.reportFilter
                                                ?.comparator,
                                        )}
                                        id={`report-filter-rule-comparator-${
                                            reportField.id ?? index
                                        }`}
                                        fullWidth
                                        valueField={"id"}
                                        labelField={"value"}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    container
                                    wrap={"nowrap"}
                                    spacing={1}
                                    alignItems={"flex-start"}
                                >
                                    <Grid item xs={11}>
                                        <AcxMainTextField
                                            value={
                                                reportField.reportFilter?.value
                                            }
                                            onChange={(evt) =>
                                                handleUpdateFilterValue(
                                                    evt,
                                                    reportField,
                                                )
                                            }
                                            id={`report-filter-rule-value-${
                                                reportField.id ?? index
                                            }`}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ButtonBase
                                            onClick={() =>
                                                store.currentReport?.removeReportField(
                                                    reportField,
                                                )
                                            }
                                            sx={(theme) => ({
                                                fontWeight: theme.typography
                                                    .fontWeightBold as any,
                                                textTransform: "none",
                                                backgroundColor:
                                                    theme.palette.red.main,
                                                width: "17px",
                                                height: "17px",
                                                color: theme.palette.white.main,
                                                borderRadius: "50%",
                                                marginTop: theme.spacing(.75),
                                                paddingBottom: "2px",
                                            })}
                                        >
                                            x
                                        </ButtonBase>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        );
                    }
                    return true;
                })}
            </Grid>

            <ButtonBase
                sx={(theme) => ({
                    textTransform: "none",
                    color: theme.palette.blue.main,
                    marginTop: theme.spacing(3),
                })}
                onClick={() => {
                    store.currentReport?.addFilterReportFieldV2(
                        props.displayId,
                    );
                }}
            >
                <Box
                    sx={(theme) => ({
                        width: "16px",
                        height: "16px",
                        backgroundColor: hexToRGB(theme.palette.blue.main, 0.2),
                        borderRadius: "50%",
                    })}
                >
                    <AddIcon
                        sx={{
                            width: "10px",
                            height: "10px",
                            marginTop: "2px",
                            textAlign: "center",
                        }}
                    />
                </Box>
                <Typography
                    sx={(theme) => ({
                        color: theme.palette.blue.main,
                        fontSize: "13px",
                        fontWeight: theme.typography.fontWeightBold as any,
                        letterSpacing: 0,
                        lineHeight: "20px",
                        marginLeft: theme.spacing(1),
                    })}
                >
                    Add AND Condition
                </Typography>
            </ButtonBase>
        </>
    );
});

export default FilterRules;
