import UserService from "components/Admin/Organizations/OrganizationDetail/OrganizationUsers/User.service";
import * as _ from "lodash";
import { debounce, isEqual } from "lodash";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
    toJS,
} from "mobx";
import { OrganizationStructureLevel } from "models/OrganizationModels/OrganizationStructureLevel";
import type { OrganizationStructureMember } from "models/OrganizationModels/OrganizationStructureMember";
import { AppDomains } from "models/Permission/AppDomains";
import { User } from "oidc-client";
import React from "react";
import ReactGridLayout, { Layout, Layouts } from "react-grid-layout";
import { OrganizationService } from "services/OrganizationService";
import { BaseWidgetDefinition } from "../../components/Widgets/Definitions/BaseWidgetDefinition";
import { getWidgetDefinition } from "../../components/Widgets/WidgetDefinitionProvider";
import PublishedWidgetsList from "../../Layouts/Dashboard/PublishedWidgetsList";
import WidgetEditor from "../../Layouts/Dashboard/WidgetEditor";
import type {
    DashboardDomain,
    IDashboardDefinition,
} from "../../models/Dashboard/DashboardDefinition";
import { WidgetContentType, WidgetType } from "../../models/Dashboard/Widget";
import type { IWidget } from "../../models/Dashboard/Widget";
import { ReportFilter } from "../../models/Reporting/ReportFilter";
import { DashboardService } from "../../services/DashboardService";
import {
    CustomDynamicTimeSpan,
    DynamicTimeSpan,
    isCustomDynamicTypeSpan,
    RefreshInterval,
} from "../../utils/reportingTimeHelpers";
import { symmetricDifferenceBy } from "../../utils/SetAlgebraUtils";
import { tryParse } from "../../utils/StringUtils";
import { AuthStore } from "../AuthStore";
import { BaseStore } from "../BaseStore";
import { DateReferenceOption } from "../ComponentStores/DatePickerComponentStore";
import { PublishedWidgetStore } from "../PublishedWidgetStore";
import { IRootStore } from "../RootStore";
import { LayoutDrawerStore } from "./LayoutDrawerStore";

export interface IDashboardItem extends IWidget {
    layout: ReactGridLayout.Layout;
    timeSpan?: DynamicTimeSpan | CustomDynamicTimeSpan | null;
    refresh?: RefreshInterval | null;
    dateReference?: DateReferenceOption | null;
    hierarchyIds?: string[];
}

interface IDashboardViewModelItem extends IDashboardItem {
    widgetDefinition: BaseWidgetDefinition;
}

const allowedOptions = [
    DateReferenceOption.ArrivalDate,
    DateReferenceOption.InteractionDate,
    DateReferenceOption.EvaluationCompleteDate,
    DateReferenceOption.EvaluationStartDate,
];

export class DashboardStore extends BaseStore {
    private readonly dashboardService: DashboardService =
        new DashboardService();
    public readonly orgService: OrganizationService = new OrganizationService();
    public readonly userService: typeof UserService = UserService;
    private readonly publishedWidgetStore: PublishedWidgetStore;
    public readonly authStore: AuthStore;

    @observable dashboardItems?: IDashboardViewModelItem[];

    @observable.ref private immutableWidgets?: IDashboardItem[];
    @observable.ref availableReportFilters?: Map<string, ReportFilter>;

    @observable isWidgetsDrawerOpen = false;
    @observable isCustomUserDashboard = false;
    @observable isDragging = false;
    @observable layoutColumnCount: number = 4;

    @observable globalTimeSpan: DynamicTimeSpan | CustomDynamicTimeSpan =
        DynamicTimeSpan.YEAR;
    @observable globalRefreshInterval: RefreshInterval = RefreshInterval.HOURLY;
    @observable globalDateReference: DateReferenceOption =
        DateReferenceOption.EvaluationCompleteDate;

    @observable.shallow levels: OrganizationStructureLevel[] = [];
    @observable.shallow members: OrganizationStructureMember[] = [];
    @observable hierarchySelections: string[] = [];
    @observable flattenedUserHierarchies: { id: string; label: string }[] = [];

    @observable pendingDashboardControlChanges = false;
    @observable pendingWidgetControlChanges = false;
    @observable pendingDashboardChanges = false;
    @observable addingWidget = false;
    @observable editMode: boolean = false;
    @observable isAgentAsUser: boolean = false;

    @observable private user?: User | null;
    @observable orgId?: string;
    @observable private cacheItemChangeIndicator = 0;

    @observable initialWidgetWidth: string = "4";

    private dashboardItemsCache?: IDashboardViewModelItem[];
    dashboardDef?: IDashboardDefinition;

    constructor(
        private rootStore: IRootStore,
        private dashboardName: DashboardDomain,
    ) {
        super(`${dashboardName} Dashboard Store`);

        makeObservable(this);

        this.authStore = rootStore.getStore(AuthStore);
        this.publishedWidgetStore = rootStore.getStore(PublishedWidgetStore);

        reaction(
            (r) => ({ orgId: this.orgId, user: this.user }),
            (arg) => {
                if (arg.orgId) {
                    this.publishedWidgetStore.setOrgId(arg.orgId);
                    this.retrieveHierarchy();

                    if (arg.user) {
                        const userId = arg.user.profile.sub;
                        const orgId = arg.orgId;
                        const immutableWidgets = this.immutableWidgets;
                        this.setupAsyncTask(`Loading Dashboard`, () =>
                            this.loadDashboard(userId, orgId, immutableWidgets),
                        );
                    }
                }
                if (
                    this.authStore.isLoggedInUserAgent() &&
                    !this.authStore.isUserUltra()
                ) {
                    this.isAgentAsUser = true;
                }
            },
            { fireImmediately: true, delay: 0 },
        );

        reaction(
            (r) => ({
                globalTime: this.globalTimeSpan,
                globalRefresh: this.globalRefreshInterval,
                globalDateRef: this.globalDateReference,
                hierarchyIds: this.hierarchySelections,
                changeIndicator: this.cacheItemChangeIndicator,
                items: JSON.stringify(
                    this.dashboardItems?.map((value) => ({
                        x: value.layout.x,
                        y: value.layout.y,
                        h: value.layout.h,
                        w: value.layout.w,
                        key: value.key,
                    })),
                ),
            }),
            (arg) => {
                const cachedItems = JSON.stringify(
                    this.dashboardItemsCache?.map((value) => ({
                        x: value.layout.x,
                        y: value.layout.y,
                        h: value.layout.h,
                        w: value.layout.w,
                        key: value.key,
                    })),
                );

                this.pendingDashboardChanges =
                    arg.items !== cachedItems ||
                    this.pendingDashboardControlChanges;
            },
            { delay: 60 },
        );

        reaction(
            (r) => ({
                changeIndicator: this.cacheItemChangeIndicator,
                items: JSON.stringify(
                    this.dashboardItems?.map((value) =>
                        _.pick(
                            value,
                            "key",
                            "timeSpan",
                            "refresh",
                            "dateReference",
                            "hierarchyIds",
                            ["args"],
                        ),
                    ),
                ),
            }),
            (arg) => {
                const cachedItems = JSON.stringify(
                    this.dashboardItemsCache?.map((value) =>
                        _.pick(
                            value,
                            "key",
                            "timeSpan",
                            "refresh",
                            "dateReference",
                            "hierarchyIds",
                            ["args"],
                        ),
                    ),
                );

                this.pendingWidgetControlChanges = arg.items !== cachedItems;
            },
            { delay: 60 },
        );
    }

    @action
    refreshPublishedWidgets = () => {
        this.publishedWidgetStore.refreshWidgets();
    };

    @action
    markCacheChange() {
        this.cacheItemChangeIndicator =
            (this.cacheItemChangeIndicator + 1) % 100;
    }

    @computed
    get widgetListLoading() {
        return this.publishedWidgetStore.anyTaskLoading;
    }

    @action
    toggleStaticLayout(isStatic: boolean) {
        this.dashboardItems?.forEach((value) => {
            value.layout.static = isStatic;
        });
    }

    @computed
    get canBuildDefaultDashboard() {
        return this.authStore.canUserEdit("Default Dashboard");
    }

    @computed
    get canEditDashboard() {
        return (
            this.authStore.permStore.canPerformOp(
                AppDomains.MyDashboard,
                "Edit",
            ) || this.authStore.canUserEdit("My Dashboard")
        );
    }

    @computed
    get widgetKeys() {
        return this.dashboardItems?.map((value) => value.key);
    }

    @action
    removeWidget(widget?: IWidget) {
        if (!widget) {
            return;
        }
        const foundIndx = this.dashboardItems?.findIndex(
            (value) => value.key === widget.key,
        );
        if (foundIndx !== undefined && foundIndx > -1) {
            this.dashboardItems?.splice(foundIndx, 1);
        }
    }

    @computed
    get dashboardLoading() {
        return this.anyTaskLoading || !this.orgId;
    }

    private layoutForWidgetType = (widget: IWidget): Layout => {
        const dashboardItemsCount = (this.dashboardItems?.length ?? 0) * 4;
        const initialWidgetWidth = this.initialWidgetWidth
            ? Number(this.initialWidgetWidth)
            : 4;
        if (widget.widgetType === WidgetType.Report) {
            if (widget.args.isTable) {
                return {
                    i: widget.key,
                    x: dashboardItemsCount % this.layoutColumnCount,
                    y: 9999,
                    static: true,
                    w: initialWidgetWidth,
                    h: 5,
                    minH: 4,
                };
            } else {
                return {
                    i: widget.key,
                    x: dashboardItemsCount % this.layoutColumnCount,
                    y: 9999,
                    static: true,
                    w: initialWidgetWidth,
                    h: 5,
                    minH: 3,
                };
            }
        } else if (widget.widgetType === WidgetType.EvaluationCoachingTable) {
            return {
                i: widget.key,
                x: dashboardItemsCount % this.layoutColumnCount,
                y: 0,
                static: true,
                w: initialWidgetWidth,
                h: 4,
                minH: 4,
            };
        } else {
            return {
                i: widget.key,
                x: dashboardItemsCount % this.layoutColumnCount,
                y: 9999,
                static: true,
                w: initialWidgetWidth,
                h: 5,
                minH: 3,
            };
        }
    };

    @action
    addWidget = (widget: IWidget, isImmutable?: boolean) => {
        this.addingWidget = true;
        this.toggleStaticLayout(true);

        setTimeout(() => {
            const layout = {
                ...this.layoutForWidgetType(widget),
            };

            const dashboardItem: IDashboardViewModelItem = {
                ...widget,
                layout: layout,
            } as any;

            const clz = getWidgetDefinition(widget.widgetType);

            let item = dashboardItem as IDashboardViewModelItem;
            item.widgetDefinition = new clz(
                dashboardItem,
                this.globalDateReference,
                this.globalRefreshInterval,
                this.globalTimeSpan,
                () => this,
                this.hierarchySelections,
            );

            runInAction(() => {
                if (this.dashboardItems) {
                    this.dashboardItems.push(item);
                } else {
                    this.dashboardItems = [item];
                }
            });

            if (isImmutable) {
                if (this.dashboardItemsCache) {
                    this.dashboardItemsCache.push({ ...item });
                } else {
                    this.dashboardItemsCache = [{ ...item }];
                }
                this.markCacheChange();
            }

            setTimeout(() => {
                this.toggleStaticLayout(false);
                setTimeout(() => {
                    runInAction(() => {
                        this.addingWidget = false;
                    });

                    if (isImmutable) {
                        setTimeout(() => {
                            this.endEditing();
                        }, 100);
                    }
                }, 75);
            }, 150);
        }, 150);
    };

    @computed
    get availableWidgets() {
        return this.publishedWidgetStore.availableWidgets;
    }

    @action
    cancelEditing = () => {
        this.endEditing();
        runInAction(() => {
            this.pendingDashboardControlChanges = false;

            if (this.globalRefreshInterval !== this.dashboardDef?.refresh) {
                this.updateGlobalRefreshInterval(
                    this.dashboardDef?.refresh ?? RefreshInterval.OFF,
                    true,
                );
            }
            if (this.globalDateReference !== this.dashboardDef?.dateReference) {
                this.updateGlobalDateReference(
                    this.dashboardDef?.dateReference ??
                        DateReferenceOption.InteractionDate,
                    true,
                );
            }

            let origTimeSpan = this.dashboardDef?.timeSpan;
            let parsedTimeSpan;
            if (
                (parsedTimeSpan = tryParse<CustomDynamicTimeSpan>(
                    this.dashboardDef?.timeSpan,
                ))
            ) {
                origTimeSpan = parsedTimeSpan;
            }
            if (!isEqual(this.globalTimeSpan, origTimeSpan)) {
                this.updateGlobalTimeSpan(
                    origTimeSpan ?? DynamicTimeSpan.YEAR,
                    true,
                );
            }

            this.dashboardItems?.forEach((i) => {
                const unchangedItem =
                    this.dashboardDef?.items?.find?.(
                        (dItem) => dItem.key === i.key,
                    ) ??
                    this.immutableWidgets?.find?.(
                        (dItem) => dItem.key === i.key,
                    );
                if (unchangedItem) {
                    if (i.refresh !== unchangedItem.refresh) {
                        i.widgetDefinition.setItemRefreshInterval(
                            unchangedItem.refresh,
                        );
                    }

                    if (i.dateReference !== unchangedItem.dateReference) {
                        i.widgetDefinition.setItemDateReference(
                            unchangedItem.dateReference,
                        );
                    }

                    let origTimeSpan = unchangedItem.timeSpan;
                    let parsedTimeSpan;
                    if (
                        (parsedTimeSpan = tryParse<CustomDynamicTimeSpan>(
                            origTimeSpan as string,
                        ))
                    ) {
                        origTimeSpan = parsedTimeSpan;
                    }
                    if (!isEqual(i.timeSpan, origTimeSpan)) {
                        i.widgetDefinition.setItemTimespan(origTimeSpan);
                    }

                    if (
                        i.args?.["dashboardFilterKey"] !==
                        unchangedItem.args?.["dashboardFilterKey"]
                    ) {
                        i.widgetDefinition.setItemCustomArgs(
                            "dashboardFilterKey",
                            unchangedItem.args?.["dashboardFilterKey"],
                        );
                    }
                }
            });

            this.pendingWidgetControlChanges = false;
            this.dashboardItems = this.dashboardItemsCache
                ? [...this.dashboardItemsCache.map((value) => ({ ...value }))]
                : undefined;

            this.toggleStaticLayout(true);
        });
    };

    @action
    restoreDefaultDashboard = () => {
        this.endEditing();
        if (this.orgId && this.user?.profile.sub) {
            const orgId = this.orgId;
            const userId = this.user.profile.sub;

            this.setupAsyncTask("Reset Default Dashboard", async () => {
                const defaultDashboard =
                    await this.dashboardService.resetDashboard(
                        this.dashboardName,
                        orgId,
                        userId,
                    );

                this.dashboardDef = defaultDashboard;
                this.buildDashboardFromDefinition(
                    defaultDashboard,
                    this.immutableWidgets,
                );
                runInAction(() => {
                    this.pendingWidgetControlChanges = false;
                    this.pendingDashboardControlChanges = false;
                    this.isCustomUserDashboard = false;
                });
            });
        }
    };

    @action
    closeEditWidgetDrawer = () => {
        const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        drawerStore.closeAndResetDrawer();
        drawerStore.restoreDefaults();
    };

    @action
    endEditing = () => {
        const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        drawerStore.closeAndResetDrawer();
        this.isWidgetsDrawerOpen = false;
        this.toggleStaticLayout(true);
        this.editMode = false;
    };

    @action
    saveChanges = () => {
        this.endEditing();
        this.setupAsyncTask("Save Dashboard", () => this.saveDashboard(false));
    };

    @action
    saveChangesAsDefault = () => {
        this.endEditing();
        this.setupAsyncTask("Save Dashboard", () => this.saveDashboard(true));
    };

    @action
    editWidget = (item: BaseWidgetDefinition) => {
        const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        drawerStore.closeAndResetDrawer();
        drawerStore.restoreDefaults();
        drawerStore.setAnchor("right");
        drawerStore.setContentFactory(() => (
            <>
                <WidgetEditor
                    dashboardStore={this}
                    item={item}
                    width={"340px"}
                />
            </>
        ));
        drawerStore.openDrawer();
    };

    @action
    editDashboard = () => {
        this.editMode = true;
        const drawerStore = this.rootStore.getStore(LayoutDrawerStore);
        drawerStore.closeAndResetDrawer();
        drawerStore.restoreDefaults();
        drawerStore.setAnchor("left");

        drawerStore.setContentFactory(() => (
            <PublishedWidgetsList
                width={"340px"}
                onClose={() => {
                    runInAction(() => (this.isWidgetsDrawerOpen = false));
                }}
                dashboardStore={this}
            />
        ));

        drawerStore.openDrawer();
        this.isWidgetsDrawerOpen = true;

        this.toggleStaticLayout(false);
    };

    @action
    initializeStoreParameters(
        orgId: string,
        user: User | null,
        immutableWidgets?: IDashboardItem[],
        dashboardReportFilters?: Map<string, ReportFilter>,
        forceRefresh?: boolean,
    ) {
        this.immutableWidgets = immutableWidgets;
        this.availableReportFilters = dashboardReportFilters;

        if (
            this.orgId &&
            this.orgId === orgId &&
            this.user &&
            this.user.profile.sub === user?.profile.sub
        ) {
            this.handleImmutableWidgets(this.immutableWidgets);
            if (forceRefresh) {
                this.refreshAll();
            }
        } else {
            this.orgId = orgId;
            this.user = user;
        }
    }

    @action
    refreshAll = debounce(
        () => {
            this.dashboardItems?.forEach((value) => {
                value.widgetDefinition.setGlobalTimespan(
                    value.widgetDefinition.timeSpan,
                );
            });
        },
        1000,
        { leading: true },
    );

    @computed
    get anyWidgetsLoading() {
        const loadingStatuses = this.dashboardItems?.map(
            (value) => value.widgetDefinition.loading,
        );
        return loadingStatuses?.some((value) => value);
    }

    @computed
    get layouts() {
        const layouts: Layouts = {};
        const items = this.dashboardItems?.flatMap((value) =>
            toJS(value.layout),
        );
        for (let dashboardItem of items ?? []) {
            const itemLayout = dashboardItem;
            if ("xl" in layouts) {
                layouts["xl"].push(itemLayout);
            } else {
                layouts["xl"] = [itemLayout];
            }
        }
        return layouts;
    }

    @action
    private buildDashboard(dashboardDefinition: IDashboardItem[]) {
        for (let dashboardItem of dashboardDefinition) {
            let item = dashboardItem as IDashboardViewModelItem;
            let parsedTimespan;
            if (
                (parsedTimespan = tryParse<CustomDynamicTimeSpan>(
                    item.timeSpan as string,
                ))
            ) {
                item.timeSpan = parsedTimespan;
            }

            const clz = getWidgetDefinition(dashboardItem.widgetType);

            item.widgetDefinition = new clz(
                dashboardItem,
                this.globalDateReference,
                this.globalRefreshInterval,
                this.globalTimeSpan,
                () => this,
                this.hierarchySelections,
            );

            if (this.dashboardItems) {
                if (this.dashboardItemsCache) {
                    this.dashboardItemsCache.push({ ...item });
                } else {
                    this.dashboardItemsCache = [{ ...item }];
                }

                this.dashboardItems.push(item);
            } else {
                if (this.dashboardItemsCache) {
                    this.dashboardItemsCache.push({ ...item });
                } else {
                    this.dashboardItemsCache = [{ ...item }];
                }
                this.dashboardItems = [item];
            }
        }
    }

    @action
    private loadDashboard = async (
        userId: string,
        orgId: string,
        immutableWidgets?: IDashboardItem[],
    ) => {
        const res = await this.dashboardService.getDashboard(
            this.dashboardName,
            orgId,
            userId,
        );
        this.dashboardDef = res;
        runInAction(() => {
            this.isCustomUserDashboard = Boolean(res.userId);
        });
        this.buildDashboardFromDefinition(res, immutableWidgets);
    };

    @action
    private buildDashboardFromDefinition(
        res: IDashboardDefinition,
        immutableWidgets?: IDashboardItem[],
    ) {
        this.disposeAllWidgetDefinitions();
        this.dashboardItemsCache = [];
        this.dashboardItems = [];

        if (!_.isEmpty(res)) {
            runInAction(() => {
                let parsedTimeSpan;
                if (
                    (parsedTimeSpan = tryParse<CustomDynamicTimeSpan>(
                        res.timeSpan,
                    ))
                ) {
                    this.globalTimeSpan = parsedTimeSpan;
                } else {
                    this.globalTimeSpan = res.timeSpan ?? DynamicTimeSpan.YEAR;
                }

                this.globalRefreshInterval = res.refresh ?? RefreshInterval.OFF;
                this.globalDateReference =
                    res.dateReference ?? DateReferenceOption.InteractionDate;

                this.updateGlobalHierarchyIds(res.hierarchyIds);
            });
            if (res.items) {
                this.buildDashboard(res.items);
            }
        } else {
            runInAction(() => {
                this.globalTimeSpan = DynamicTimeSpan.YEAR;
                this.globalRefreshInterval = RefreshInterval.OFF;
                this.globalDateReference = DateReferenceOption.InteractionDate;
                this.dashboardItems = undefined;
                this.dashboardItemsCache = undefined;
                this.hierarchySelections = [];
            });
        }
        this.handleImmutableWidgets(immutableWidgets);
    }

    @action
    private disposeAllWidgetDefinitions() {
        this.dashboardItems?.forEach((v) => {
            try {
                v.widgetDefinition.dispose();
            } catch (e) {
                console.error(e);
            }
        });
        this.dashboardItemsCache?.forEach((v) => {
            try {
                v.widgetDefinition.dispose();
            } catch (e) {
                console.error(e);
            }
        });
    }

    @action
    private handleImmutableWidgets(
        immutableWidgets: IDashboardItem[] | undefined,
    ) {
        if (immutableWidgets && immutableWidgets.length > 0) {
            this.globalTimeSpan = this.globalTimeSpan ?? DynamicTimeSpan.YEAR;
            this.globalRefreshInterval =
                this.globalRefreshInterval ?? RefreshInterval.OFF;
            this.globalDateReference =
                this.globalDateReference ??
                DateReferenceOption.EvaluationCompleteDate;

            immutableWidgets.forEach((value) => {
                const existingImmutableIndex = this.dashboardItems?.findIndex(
                    (dashboardItem) => dashboardItem.key === value.key,
                );
                if (
                    existingImmutableIndex === undefined ||
                    existingImmutableIndex === -1
                ) {
                    this.toggleStaticLayout(false);
                    this.addWidget(value, true);
                } else {
                    const existingImmutable =
                        this.dashboardItems?.[existingImmutableIndex];

                    if (this.dashboardItems && existingImmutable) {
                        this.dashboardItems.splice(existingImmutableIndex, 1, {
                            ...existingImmutable,
                            args: value.args,
                            layout: existingImmutable.layout,
                        });

                        this.dashboardItems?.[
                            existingImmutableIndex
                        ]?.widgetDefinition.updateViewModelParams(value.args);

                        this.dashboardItemsCache = this.dashboardItems
                            ? [
                                  ...this.dashboardItems.map((value) => ({
                                      ...value,
                                  })),
                              ]
                            : undefined;

                        this.markCacheChange();
                    }
                }
            });
        }
    }

    @action
    updateGlobalDateReference = (
        dateReferenceOption: DateReferenceOption,
        resetting?: boolean,
    ) => {
        if (allowedOptions.includes(dateReferenceOption)) {
            this.globalDateReference = dateReferenceOption;
            this.dashboardItems?.forEach((value) => {
                value.widgetDefinition.setGlobalDateReference(
                    this.globalDateReference,
                );
            });
            if (!resetting) {
                this.pendingDashboardControlChanges = true;
            }
        } else {
            console.warn("Invalid DateReferenceOption: ", dateReferenceOption);
        }
    };

    @action
    updateGlobalRefreshInterval = (
        interval: RefreshInterval,
        resetting?: boolean,
    ) => {
        this.globalRefreshInterval = interval;
        this.dashboardItems?.forEach((value) => {
            value.widgetDefinition.setGlobalRefreshInterval(
                this.globalRefreshInterval,
            );
        });
        if (!resetting) {
            this.pendingDashboardControlChanges = true;
        }
    };

    @action
    updateGlobalTimeSpan = (
        timeSpan: DynamicTimeSpan | CustomDynamicTimeSpan,
        resetting?: boolean,
    ) => {
        this.globalTimeSpan = timeSpan;
        this.dashboardItems?.forEach((value) => {
            value.widgetDefinition.setGlobalTimespan(this.globalTimeSpan);
        });
        if (!resetting) {
            this.pendingDashboardControlChanges = true;
        }
    };

    @action
    updateGlobalHierarchyIds(input: string[], resetting?: boolean) {
        this.hierarchySelections = input;

        this.dashboardItems?.forEach((value) => {
            value.widgetDefinition.setGlobalHierarchyIds(
                this.hierarchySelections,
            );
        });
        if (!resetting) {
            this.pendingDashboardControlChanges = true;
        }
    }

    @action
    updateItemCustomArgs = (argKey: string, argValue: any, key: string) => {
        const item = this.dashboardItems?.find((v) => v.key === key);
        if (item) {
            if (item.args) {
                item.args = { ...item.args, [argKey]: argValue };
            } else {
                item.args = { [argKey]: argValue };
            }
        }
    };
    @action
    updateItemDateReference = (
        dateReferenceOption: DateReferenceOption | undefined | null,
        key: string,
    ) => {
        const item = this.dashboardItems?.find((v) => v.key === key);
        if (
            item &&
            (dateReferenceOption === null ||
                dateReferenceOption === undefined ||
                allowedOptions.includes(dateReferenceOption))
        ) {
            item.dateReference = dateReferenceOption;
            // this.pendingWidgetControlChanges = true;
        }
    };

    @action
    updateItemRefreshInterval = (
        interval: RefreshInterval | undefined | null,
        key: string,
    ) => {
        const item = this.dashboardItems?.find((v) => v.key === key);
        if (item) {
            item.refresh = interval;
            // this.pendingWidgetControlChanges = true;
        }
    };

    @action
    updateItemTimeSpan = (
        timeSpan: DynamicTimeSpan | CustomDynamicTimeSpan | undefined | null,
        key: string,
    ) => {
        const item = this.dashboardItems?.find((v) => v.key === key);
        if (item) {
            item.timeSpan = timeSpan;
        }
    };

    @action
    updateItemHierarchyIds = (
        hierarchyIds: string[] | undefined,
        key: string,
    ) => {
        const item = this.dashboardItems?.find((v) => v.key === key);

        if (item && hierarchyIds) {
            item.hierarchyIds = [...hierarchyIds];
        }
    };

    @action
    private async saveDashboard(isDefault: boolean) {
        if (!this.orgId) {
            console.warn("Can't save dashboard with empty organizationId");
            return;
        }

        const items = toJS(this.dashboardItems);

        items?.forEach((value) => {
            // @ts-ignore
            delete value.widgetDefinition;
            if (isCustomDynamicTypeSpan(value.timeSpan)) {
                value.timeSpan = JSON.stringify(value.timeSpan) as any;
            }
        });
        items
            ?.filter(
                (value) => value.contentType === WidgetContentType.IMMUTABLE,
            )
            .forEach((value) => {
                Object.keys(value.args ?? {}).forEach((argKey) => {
                    if (argKey.toLowerCase().endsWith("id")) {
                        delete value.args[argKey];
                    }
                });
            });

        let timespan = this.globalTimeSpan;
        if (isCustomDynamicTypeSpan(timespan)) {
            timespan = JSON.stringify(timespan) as any;
        }

        const dashboardDefinition: IDashboardDefinition = {
            dateReference: this.globalDateReference,
            timeSpan: timespan,
            refresh: this.globalRefreshInterval,
            domain: this.dashboardName,
            userId: isDefault ? null : this.user?.profile.sub,
            organizationId: this.orgId,
            items: JSON.stringify(items),
            hierarchyIds: this.hierarchySelections,
        } as any;

        this.dashboardDef = await this.dashboardService.updateDashboard(
            this.dashboardName,
            this.orgId,
            isDefault ? null : this.user?.profile.sub,
            dashboardDefinition,
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [newlyAdded, intersection, removed] = symmetricDifferenceBy(
            this.dashboardItemsCache ?? [],
            this.dashboardItems ?? [],
            (arg) => arg.key,
            (arg) => arg.key,
        );

        removed.forEach((value) => {
            this.dashboardItemsCache
                ?.find((v) => v.key === value)
                ?.widgetDefinition.dispose();
        });
        // update cached dashboard to latest saved dashboard
        runInAction(() => {
            this.pendingDashboardControlChanges = false;
            this.pendingWidgetControlChanges = false;
            this.dashboardItemsCache = this.dashboardItems
                ? [...this.dashboardItems.map((value) => ({ ...value }))]
                : undefined;
            this.isCustomUserDashboard = !isDefault;
        });
    }

    @action
    onLayoutChange = (currentLayout: Layout[], allLayouts: Layouts) => {
        for (let layout of currentLayout) {
            const key = layout.i;
            const item = this.dashboardItems?.find(
                (value) => value.key === key,
            );
            if (item) {
                item.layout = layout;
            }
        }
    };

    @action
    onBreakpointChange = (newBreakpoint: string, newCols: number) => {
        this.layoutColumnCount = newCols;
    };

    @action
    onDragStart = () => {
        this.isDragging = true;
    };
    @action
    onDragEnd = () => {
        this.isDragging = false;
    };

    @action
    private async retrieveHierarchy() {
        const payload = await this.orgService.getInternalStructureHierarchy();
        runInAction(() => {
            this.levels = payload.levels;
            this.members = payload.members;
        });
    }

    @action
    setFlattenedUserHierarchies = (
        flattenedHierarchies: { id: string; label: string }[],
    ) => {
        this.flattenedUserHierarchies = flattenedHierarchies;
    };
}
