import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";
import { DateReferenceOption } from "stores/ComponentStores/DatePickerComponentStore";

export const DateReferenceChip = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    return (
        <AppliedFilterChip
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={() => {
                applicationFiltersStore.quickFiltersStore.setDateReferenceOption(
                    DateReferenceOption.InteractionDate,
                );
                applicationFiltersStore.setDateReferenceOption(
                    DateReferenceOption.InteractionDate,
                );
            }}
        >
            {applicationFiltersStore.quickFiltersStore.dateReferenceOption}
        </AppliedFilterChip>
    );
});
