export enum Sentiment {
    Positive,
    Neutral,
    Negative,
}

export namespace Sentiment {
    export function toString(sentiment: Sentiment): string {
        switch (sentiment) {
            case Sentiment.Positive:
                return "Positive";
            case Sentiment.Neutral:
                return "Neutral";
            case Sentiment.Negative:
                return "Negative";
        }
    }
}
