import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Chip, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { CSSProperties } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import { styled } from "@mui/system";
import useStyles from "Styles/Styles";
import EddyIconSvg from "SvgIcons/EddyIconSvg";
import React from "react";
import hexToRGB from "utils/hexToRGB";

const SMALL_FONT_SIZE = 12;
const MED_FONT_SIZE = 16;

const styles = (theme: Theme) =>
    createStyles({
        chip: {
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
        },
        small: {
            height: 24,
            fontSize: SMALL_FONT_SIZE,
            fontWeight: "bold",
        },
        medium: {
            height: 28,
            fontSize: MED_FONT_SIZE,
            fontWeight: "bold",
        },
        green: {
            color: theme.palette.green.main,
            backgroundColor: theme.palette.green.background,
        },
        red: {
            color: theme.palette.red.main,
            backgroundColor: hexToRGB(theme.palette.red.main, 0.1),
        },
        blue: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.blue.background,
        },
        lightBlue: {
            color: theme.palette.secondary[500],
            backgroundColor: theme.palette.secondary[100],
        },
        gray: {
            color: theme.palette.gray.main,
            backgroundColor: theme.palette.gray.background,
        },
        darkGray: {
            backgroundColor: theme.palette.neutral[200],
        },
        lightGray: {
            backgroundColor: theme.palette.neutral[100],
        },
        yellow: {
            color: theme.palette.yellow.main,
            backgroundColor: theme.palette.yellow.background,
        },
        orange: {
            color: theme.palette.orange.main,
            backgroundColor: hexToRGB(theme.palette.orange.main, 0.25),
        },
        darkGreen: {
            color: "#14532D",
            backgroundColor: "#DCFCE7",
        },
        primary800: {
            color: theme.palette.primary[800],
            backgroundColor: theme.palette.primary[200],
        },
        secondary800: {
            color: theme.palette.secondary[800],
            backgroundColor: theme.palette.secondary[100],
        },
        white: {
            color: theme.palette.black.main,
            backgroundColor: theme.palette.white.main,
        },
        transparent: {
            color: "#3564D5",
            backgroundColor: "transparent",
        },
        adverse: {
            color: theme.palette.error[900],
            backgroundColor: theme.palette.error[100],
        },
        eddy: {
            color: theme.palette.highlight.eddy[500],
            backgroundColor: theme.palette.highlight.eddy[50],
        },
        contact: {
            backgroundColor: theme.palette.secondary[200],
        },
        paddingOverride: {
            paddingLeft: "0 !important",
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
        },
    });

const ChipLabel = styled(Typography)(() => ({
    color: "inherit",
    fontWeight: "inherit",
    width: "100%",
    fontSize: "inherit",
    textOverflow: "ellipsis",
    overflow: "hidden",
}));

export type AcxChipPropsIcon = "plus" | "minus" | "eddy";

type Props = {
    size: "medium" | "small";
    color:
        | "green"
        | "red"
        | "blue"
        | "lightBlue"
        | "gray"
        | "darkGray"
        | "lightGray"
        | "yellow"
        | "darkGreen"
        | "white"
        | "transparent"
        | "eddy"
        | "adverse"
        | "primary800"
        | "secondary800"
        | "orange"
        | "contact";
    label: React.ReactNode;
    onDelete?: () => void;
    onClick?: (event) => void;
    icon?: AcxChipPropsIcon;
    clickable?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    showToolTip?: boolean;
    multiToolTip?: boolean;
    wrapTooltip?: boolean;
    customTooltip?: React.ReactNode;
    id?: string;
    removeLeftLabelPadding?: boolean;
};

function AcxChip(props: Props) {
    const classes = useStyles(styles);

    const theme = useTheme();

    const getChipStyles = () => {
        const styles: CSSProperties = {
            borderRadius: theme.shape.borderRadius,
        };

        if (props.size === "small") {
            styles.height = 24;
            styles.fontSize = SMALL_FONT_SIZE;
            styles.fontWeight = "bold";
        } else if (props.size === "medium") {
            styles.height = 28;
            styles.fontSize = MED_FONT_SIZE;
            styles.fontWeight = "bold";
        }

        if (props.color === "green") {
            styles.color = theme.palette.green.main;
            styles.backgroundColor = theme.palette.green.background;
        } else if (props.color === "red") {
            styles.color = theme.palette.error[700];
            styles.backgroundColor = theme.palette.error[100];
        } else if (props.color === "blue") {
            styles.color = theme.palette.primary.main;
            styles.backgroundColor = theme.palette.blue.background;
        } else if (props.color === "lightBlue") {
            styles.color = theme.palette.primary[500];
            styles.backgroundColor = theme.palette.primary[100];
        } else if (props.color === "gray") {
            styles.color = theme.palette.gray.main;
            styles.backgroundColor = theme.palette.gray.background;
        } else if (props.color === "darkGray") {
            styles.backgroundColor = theme.palette.neutral[200];
        } else if (props.color === "lightGray") {
            styles.backgroundColor = theme.palette.neutral[100];
        } else if (props.color === "yellow") {
            styles.color = theme.palette.yellow.main;
            styles.backgroundColor = theme.palette.yellow.background;
        } else if (props.color === "darkGreen") {
            styles.color = "#14532D";
            styles.backgroundColor = "#DCFCE7";
        } else if (props.color === "white") {
            styles.color = theme.palette.black.main;
            styles.backgroundColor = theme.palette.white.main;
        } else if (props.color === "transparent") {
            styles.color = "#3564D5";
            styles.backgroundColor = "transparent";
        } else if (props.color === "adverse") {
            styles.color = theme.palette.error[900];
            styles.backgroundColor = theme.palette.error[100];
        } else if (props.color === "eddy") {
            styles.color = theme.palette.highlight.eddy.main;
            styles.backgroundColor = theme.palette.highlight.eddy.light;
        } else if (props.color === "contact") {
            styles.backgroundColor = theme.palette.primary[200];
        } else if (props.color === "primary800") {
            styles.color = theme.palette.primary[800];
            styles.backgroundColor = theme.palette.primary[200];
        } else if (props.color === "secondary800") {
            styles.color = theme.palette.secondary[800];
            styles.backgroundColor = theme.palette.secondary[100];
        } else if (props.color === "orange") {
            styles.color = theme.palette.orange.main;
            styles.backgroundColor = hexToRGB(theme.palette.orange.main, 0.25);
        }

        return styles;
    };

    let label;

    if (props.multiToolTip === true && typeof props.label === "string") {
        // label formatting for individual tooltips on acx chips, see tags in eval coaching table vm
        label = <ChipLabel>{props.label}</ChipLabel>;
    } else if (typeof props.label === "string" && props.wrapTooltip === true) {
        // label formatting for wrapped tooltip text, ellipsis for acx chip label, see request dispute dialog
        label = <ChipLabel>{props.label}</ChipLabel>;
    } else if (typeof props.label === "string") {
        // label formatting for no wrap, ellipsis for both
        label = <ChipLabel noWrap>{props.label}</ChipLabel>;
    } else {
        label = props.label;
    }

    var icon: React.ReactElement | undefined;

    if (props.icon === "plus") {
        icon = (
            <AddCircleOutlineIcon
                className={classes[props.color]}
                style={{
                    fontSize:
                        props.size === "small"
                            ? SMALL_FONT_SIZE
                            : MED_FONT_SIZE,
                }}
            />
        );
    }

    if (props.icon === "minus") {
        icon = (
            <RemoveCircleOutlineIcon
                className={classes[props.color]}
                style={{
                    fontSize:
                        props.size === "small"
                            ? SMALL_FONT_SIZE
                            : MED_FONT_SIZE,
                }}
            />
        );
    }

    if (props.icon === "eddy") {
        icon = (
            <EddyIconSvg
                style={{
                    height: props.size === "small" ? "16px" : "20px",
                    width: props.size === "small" ? "16px" : "20px",
                }}
            />
        );
    }

    let showToolTip;
    !props.showToolTip === undefined || props.showToolTip === null
        ? (showToolTip = true)
        : (showToolTip = props.showToolTip);

    return (
        <Tooltip title={showToolTip ? props.customTooltip ?? label : ""}>
            <Chip
                id={props.id}
                style={props.style}
                sx={(theme) => ({
                    ...getChipStyles(),
                    ...props.style,
                    marginBottom: 0.5,
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderBottomLeftRadius: theme.shape.borderRadius,
                    borderTopRightRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius,
                })}
                classes={
                    props.removeLeftLabelPadding
                        ? { label: classes.paddingOverride }
                        : {}
                }
                size={props.size}
                label={label}
                disabled={props.disabled}
                onDelete={props.onDelete}
                onClick={props.onClick}
                clickable={props.clickable}
                deleteIcon={
                    <CancelIcon
                        style={{
                            fontSize:
                                props.size === "small"
                                    ? SMALL_FONT_SIZE
                                    : MED_FONT_SIZE,
                        }}
                    />
                }
                icon={icon}
            />
        </Tooltip>
    );
}

export default AcxChip;
