import { Box, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import ConversationsStore, {
    loadConversationSoundClips,
    loadCreateClip,
    loadDeleteClip,
} from "components/Conversations/Stores/ConversationsStore";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useStore } from "utils/useStore";
import EmptyClipList from "components/Evaluation/Views/Clips/EmptyClipList";
import clsx from "clsx";
import ConversationClipView from "./ConversationClipView";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import SoundClip from "models/SoundClip";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";

const useConversationClipListStyles = makeStyles((theme: Theme) => ({
    clipList: (props: Props) => ({
        overflowX: "hidden",
        height: "53vh",
        paddingRight: theme.spacing(2),
    }),
}));

type Props = {};

const ConversationClipList: React.FunctionComponent<Props> = observer(
    (props) => {
        const store = useStore(ConversationsStore);
        const classes = useConversationClipListStyles(props);

        const [dialogIsOpen, setDialogIsOpen] = useState(false);

        const onDeleteButton = (soundClip: SoundClip) => {
            store.stageDeleteClip = soundClip;
            setDialogIsOpen(true);
        };

        const onDelete = () => {
            if (store.stageDeleteClip) {
                store.deleteClip(store.stageDeleteClip);
            }
            onDialogClose();
        };

        const onDialogClose = () => {
            setDialogIsOpen(false);
        };

        const onExit = () => {
            store.stageDeleteClip = null;
        };

        return (
            <Box className={clsx(classes.clipList)}>
                {store.getTaskLoading(loadCreateClip) ||
                store.getTaskLoading(loadConversationSoundClips) ||
                store.getTaskLoading(loadDeleteClip) ? (
                    <AcxLoadingIndicator size={24} alternate="PuffLoader" />
                ) : (store.conversationSoundClips?.length ?? 0) > 0 ? (
                    store.conversationSoundClips?.map(
                        (conversationSoundClip, index) => {
                            return (
                                <ConversationClipView
                                    key={`${conversationSoundClip.soundClip.id} ${index}`}
                                    soundClip={conversationSoundClip.soundClip}
                                    index={index}
                                    title={
                                        conversationSoundClip.soundClip
                                            .segmentName
                                    }
                                    onDeleteButton={() => {
                                        onDeleteButton(
                                            conversationSoundClip.soundClip,
                                        );
                                    }}
                                    montageIds={
                                        conversationSoundClip.montageIds
                                    }
                                    mediaUrl={conversationSoundClip.mediaUrl}
                                />
                            );
                        },
                    )
                ) : (
                    <EmptyClipList />
                )}
                <AcxConfirmationDialog
                    isOpen={dialogIsOpen}
                    fullWidth
                    onClose={onDialogClose}
                    onCancel={onDialogClose}
                    onConfirm={onDelete}
                    title={`Are you sure you want to delete ${store.stageDeleteClip?.segmentName}?`}
                    onTransitionExit={onExit}
                />
            </Box>
        );
    },
);

export default ConversationClipList;
