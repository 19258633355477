import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const ThumbsUpSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="25px"
            height="25px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Evaluation"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.25}
            >
                <g
                    id="Interaction---Analyst-Evaluate-"
                    transform="translate(-980.000000, -437.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Content" transform="translate(0.000000, 56.000000)">
                        <g
                            id="Middle"
                            transform="translate(344.000000, 24.000000)"
                        >
                            <g
                                id="Quality-Copy"
                                transform="translate(0.000000, 252.000000)"
                            >
                                <g
                                    id="Stacked-Group"
                                    transform="translate(24.000000, 76.000000)"
                                >
                                    <g id="Field-Copy-22">
                                        <g id="Stacked-Group">
                                            <g
                                                id="Group-3"
                                                transform="translate(0.000000, 28.000000)"
                                            >
                                                <g
                                                    id="Group-14"
                                                    transform="translate(612.000000, 1.000000)"
                                                >
                                                    <g id="Group-12">
                                                        <g id="Icon/Quote-Copy-2">
                                                            <path
                                                                d="M9.16666667,3.33333333 C10.1666667,3.33333333 10.8333333,3.75 10.8333333,5 L10.8333333,5 L10.8333333,8.33333333 L14.4166667,8.33333333 C15.0833333,8.33333333 15.75,8.58333333 16.1666667,9.16666667 C16.5833333,9.58333333 16.75,10.25 16.6666667,10.9166667 L16.6666667,10.9166667 L16,14.75 C15.8333333,15.8333333 14.9166667,16.6666667 13.8333333,16.6666667 L13.8333333,16.6666667 L6.66666667,16.6666667 L6.66666667,10 C6.75,10 8.66666667,3.75 8.66666667,3.75 C8.66666667,3.5 8.91666667,3.33333333 9.16666667,3.33333333 Z M5,10 L5,16.6666667 L3.33333333,16.6666667 L3.33333333,10 L5,10 Z"
                                                                id="Fill"
                                                            ></path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default ThumbsUpSvg;
