import { observer } from "mobx-react";
import React, { FunctionComponent } from "react";
import { DialogComponentStore } from "../../../../stores/ComponentStores/DialogComponentStore";
import ConfirmationDialog from "../../../UI/AcxConfirmationDialog";
import { EvalStore, UpdateEvaluationTask } from "../../Stores/EvalStore";

interface OwnProps {
    dialogStore: DialogComponentStore<EvalStore>;
}

type Props = OwnProps;

const SaveEvaluationDialog: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = observer((props) => {
    function onClose() {
        props.dialogStore.close();
        props.dialogStore.hostingStore?.clearLastTaskError();
    }

    return (
        <ConfirmationDialog
            isOpen={props.dialogStore.isOpen}
            title={props.dialogStore.title}
            subTitle={props.dialogStore.subTitle}
            noControls
            alternateSpinnerType={"BeatLoader"}
            spinnerSize={20}
            buttonsDisabled={props.dialogStore.hostingStore?.getTaskLoading(
                UpdateEvaluationTask,
            )}
            errorMessage={
                props.dialogStore.hostingStore?.getTaskError(
                    UpdateEvaluationTask,
                )?.message
            }
            isLoading={props.dialogStore.hostingStore?.getTaskLoading(
                UpdateEvaluationTask,
            )}
            onClose={onClose}
        />
    );
});

export default SaveEvaluationDialog;
