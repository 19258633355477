import { ApplicationUser } from "../models/Permission/ApplicationUser";
import Evaluation from "../models/Evaluation";
import Interaction from "../models/Interaction";
import SoundClip from "../models/SoundClip";
import BaseService from "./BaseService";

export enum QuotePeriod {
    Week = "Week",
    Year = "Year",
}

export class InteractionQuote {
    soundClip: SoundClip;
    interaction: Interaction;
    evaluation: Evaluation;
    analyst: ApplicationUser;
}

export class QuoteOfThePeriodService extends BaseService {
    protected urlBase: string = "api/QuoteOfThePeriod";

    public async getQuote(
        orgId: string,
        period: QuotePeriod = QuotePeriod.Week,
        debounceKey?: string,
    ) {
        const res = await this.get<InteractionQuote>(
            new URLSearchParams({ orgId }),
            "",
            undefined,
            debounceKey,
        );
        return res;
    }
}
