import { TextField } from "@mui/material";
import { GridFilterInputValueProps } from "@mui/x-data-grid-pro";
// import _ from "lodash";
import moment from "moment";
import React from "react";

export const formatValueToDuration = (value) => {
    if (!value) {
        return "";
    }

    const pieces: string[] = [];
    const duration = moment.duration(value);
    const [hours, minutes, seconds] = [
        duration.hours(),
        duration.minutes(),
        duration.seconds(),
    ];

    pieces.push(hours.toString().padStart(2, "0"));
    pieces.push(minutes.toString().padStart(2, "0"));
    pieces.push(seconds.toString().padStart(2, "0"));

    return pieces.join(":");
};

// export const DurationFormatter = (params: GridValueFormatterParams) => {
//     const value = _.get(params.row, params.field)?.toString();

//     return formatValueToDuration(value);
// };

export const DurationFilter = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;
    const [inputValue, setInputValue] = React.useState<string>(
        formatValueToDuration(item.value),
    );
    const handleFilterChange = (e) => {
        const value = e.target.value.split(":");
        setInputValue(e.target.value);

        let durationToMilliseconds = 0;

        if (value.length === 3) {
            durationToMilliseconds =
                (value[0] ?? 0) * 1000 * 60 * 60 +
                (value[1] ?? 0) * 1000 * 60 +
                (value[2] ?? 0) * 1000;
        } else if (value.length === 2) {
            durationToMilliseconds =
                (value[0] ?? 0) * 1000 * 60 + (value[1] ?? 0) * 1000;
        } else if (value.length === 1) {
            durationToMilliseconds = value * 1000;
        }

        applyValue({ ...item, value: durationToMilliseconds });
    };

    return (
        <TextField
            {...props}
            value={inputValue}
            label="Input Duration"
            id="durationFilter"
            onChange={handleFilterChange}
            type="text"
            focused
            // style={{ height: "16px" }}
        />
    );
};

// export const getDurationFormatterOperators: (
//     filterField: string,
// ) => GridFilterOperator[] = (filterField: string) => [
//     {
//         label: "contains",
//         value: "contains",
//         getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//             if (
//                 !filterItem.columnField ||
//                 !filterItem.value ||
//                 !filterItem.operatorValue
//             ) {
//                 return null;
//             }

//             const filterRegex = new RegExp(filterItem.value, "i");
//             return (params): boolean => {
//                 const rowValue = _.get(params.row, filterField);
//                 return filterRegex.test(rowValue?.toString() || "");
//             };
//         },
//         InputComponent: DurationFilter,
//     },
//     {
//         label: "equals",
//         value: "equals",
//         getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//             if (
//                 !filterItem.columnField ||
//                 !filterItem.value ||
//                 !filterItem.operatorValue
//             ) {
//                 return null;
//             }
//             return (params): boolean => {
//                 const rowValue = _.get(params.row, filterField);
//                 return rowValue?.toString() === filterItem.value?.toString();
//             };
//         },
//         InputComponent: DurationFilter,
//     },
// ];

// export const DurationComparator: GridComparatorFn = (
//     v1: GridCellValue,
//     v2: GridCellValue,
//     param1: GridSortCellParams,
//     param2: GridSortCellParams,
// ): number => {
//     const row1 = param1.api.getRow(param1.id);
//     const row2 = param2.api.getRow(param2.id);

//     const p1 = formatValueToDuration(_.get(row1, param1.field)?.toString());
//     const p2 = formatValueToDuration(_.get(row2, param2.field)?.toString());

//     return p1.localeCompare(p2);
// };
