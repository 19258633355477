import { Grid, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { observer } from "mobx-react";
import React from "react";
import WorkflowsNavigation from "./Views/WorkflowsNavigation";
import WorkflowsRoutes from "./Views/WorkflowsRoutes";

const useStyles = makeStyles((theme: Theme) => ({
    wrapper: {
        padding: "2rem",
        height: "calc(100% - 20px)",
    },
}));

type Props = {};

const Workflows: React.FC<Props> = observer(() => {
    const classes = useStyles();
    return (
        <Grid container className={classes.wrapper}>
            <WorkflowsRoutes />
            <WorkflowsNavigation />
        </Grid>
    );
});

export default Workflows;
