import { observer } from "mobx-react";
import { IApplicationFilterComponent } from "../Filters/IApplicationFilterComponent";
import { CallDirection } from "stores/ApplicationFilters/Filters/CallDirection";
import { Stack } from "@mui/material";
import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
    checkboxContainer: {
        maxHeight: "28px",
    },
}));

export const CallDirectionsFilter = observer(function (
    props: IApplicationFilterComponent,
) {
    const classes = useStyles();

    return (
        <Stack>
            <FormControlLabel
                label="Inbound"
                control={
                    <Checkbox
                        id="application-filters-call-direction-inbound"
                        onChange={() => {
                            props.store.toggleCallDirection(
                                CallDirection.Inbound,
                            );
                        }}
                        checked={props.store.callDirections?.includes(
                            CallDirection.Inbound,
                        )}
                    />
                }
                className={classes.checkboxContainer}
            />
            <FormControlLabel
                label="Outbound"
                control={
                    <Checkbox
                        id="application-filters-call-direction-outbound"
                        onChange={() => {
                            props.store.toggleCallDirection(
                                CallDirection.Outbound,
                            );
                        }}
                        checked={props.store.callDirections?.includes(
                            CallDirection.Outbound,
                        )}
                    />
                }
                className={classes.checkboxContainer}
            />
            <FormControlLabel
                label="Transfer"
                control={
                    <Checkbox
                        id="application-filters-call-direction-transfer"
                        onChange={() => {
                            props.store.toggleCallDirection(
                                CallDirection.Transfer,
                            );
                        }}
                        checked={props.store.callDirections?.includes(
                            CallDirection.Transfer,
                        )}
                    />
                }
                className={classes.checkboxContainer}
            />
        </Stack>
    );
});
