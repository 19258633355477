import { Divider, Grid, Paper, Typography } from "@mui/material";
import theme from "Theme/AppTheme";
import AcxButton from "components/UI/AcxButton";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import { observer } from "mobx-react";
import React from "react";
import { customerTypesTypographyStyles } from "../OrganizationCustomerTypes";
import { useStore } from "utils/useStore";
import { OrganizationCustomerTypeStore } from "../stores/OrganizationCustomerTypeStore";
import OrganizationAdditionalCustomerType from "./OrganizationAdditionalCustomerType";

const OrganizationAdditionalCustomerTypes = observer(() => {
    const store = useStore(OrganizationCustomerTypeStore);

    const isCustomerTypesLoading = store.getTaskLoading(
        OrganizationCustomerTypeStore.Tasks.LOAD_CUSTOMER_TYPES,
    );

    return (
        <Paper sx={{ padding: "1rem", borderRadius: "0.5rem" }}>
            <Grid container direction="column" spacing={1}>
                <Grid item>
                    <Typography style={customerTypesTypographyStyles.title}>
                        Custom Contact Types
                    </Typography>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid container item direction="row" spacing={1}>
                    <Grid container item xs={3} direction="column">
                        <Grid item>
                            <Typography
                                style={
                                    customerTypesTypographyStyles.explanationTitle
                                }
                            >
                                Enter Additional Contact Types
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                style={
                                    customerTypesTypographyStyles.explanationText
                                }
                            >
                                Our Contact Type ML model will not identify
                                custom contact types, but you can still assign
                                them to conversations manually.
                            </Typography>
                        </Grid>
                    </Grid>
                    {isCustomerTypesLoading && (
                        <Grid container item justifyContent="center" xs={9}>
                            <Grid item>
                                <AcxLoadingIndicator
                                    alternate="PuffLoader"
                                    size={64}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {!isCustomerTypesLoading && (
                        <Grid item xs={9}>
                            <Paper
                                elevation={0}
                                sx={{
                                    backgroundColor:
                                        theme.palette.lightgray.background,
                                    padding: "1rem",
                                    borderRadius: "0.5rem",
                                }}
                            >
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Typography
                                            style={
                                                customerTypesTypographyStyles.subtitle
                                            }
                                        >
                                            Additional Contact Types
                                        </Typography>
                                    </Grid>
                                    {store.unMappedCustomerTypes.map(
                                        (customerType) => (
                                            <Grid item key={customerType.id}>
                                                <OrganizationAdditionalCustomerType
                                                    customerType={customerType}
                                                />
                                            </Grid>
                                        ),
                                    )}
                                    <Grid item>
                                        <AcxButton
                                            color="primary"
                                            onClick={() =>
                                                store.addNewCustomerType()
                                            }
                                        >
                                            + Add Contact Type
                                        </AcxButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
});

export default OrganizationAdditionalCustomerTypes;
