import React from "react";
import { observer } from "mobx-react";
import { useStore } from "utils/useStore";
import ReclassifyHistoryStore from "../Stores/ReclassifyHistoryStore";
import { useEffect } from "react";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";

const primaryPagePadding = 16;

const ReclassifyHistory = observer(() => {
    const store = useStore(ReclassifyHistoryStore);

    useEffect(() => {
        store.loadReclassifyResults();
    }, [store]);

    return (
        <Grid container direction="column" rowGap={1}>
            <Grid
                container
                item
                sx={(theme) => ({
                    backgroundColor: theme.palette.white.main,
                    borderBottom: `0.5px solid ${theme.palette.neutral[100]}`,
                    paddingInline: theme.spacing(primaryPagePadding),
                    paddingBlock: theme.spacing(2),
                    position: "relative",
                })}
                alignItems="center"
            >
                <Grid item>
                    <Typography
                        display="block"
                        align="left"
                        sx={(theme) => ({
                            fontSize: "18px",
                            lineHeight: "28px",
                            fontWeight: "bold",
                            color: theme.palette.gray[700],
                        })}
                    >
                        Reclassify History
                    </Typography>
                </Grid>
                <Grid
                    item
                    sx={(theme) => ({
                        position: "absolute",
                        left: theme.spacing(primaryPagePadding - 5),
                    })}
                >
                    <Link to="../">
                        <IconButton>
                            <ArrowBack />
                        </IconButton>
                    </Link>
                </Grid>
            </Grid>
            <Grid
                item
                width="100%"
                height="80%"
                sx={(theme) => ({
                    padding: theme.spacing(
                        0,
                        primaryPagePadding,
                        10,
                        primaryPagePadding,
                    ),
                })}
            >
                <AcxDataGrid dataGridStore={store.dataGrid} />
            </Grid>
        </Grid>
        // <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        //     <code>{JSON.stringify(store.reclassifyResults)}</code>
        // </pre>
    );
});

export default ReclassifyHistory;
