import { Theme, Grid, Typography, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useStore } from "utils/useStore";
import { ClassifierBuilderV2Store } from "./Stores/ClassifierBuilderV2Store";
import { AuthStore } from "stores/AuthStore";
import ClassifierBuilderTableV2 from "./Views/Table/ClassifierBuilderTableV2";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
    viewReclassifyHistoryLink: {
        color: theme.palette.gray[600],
    },
}));

const ClassifierBuilderV2 = observer(() => {
    const classes = useStyles();
    const theme = useTheme();
    const store = useStore(ClassifierBuilderV2Store);
    const authStore = useStore(AuthStore);
    const orgStore = authStore.orgStore;

    useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectStore.selectOrg(orgStore.selectedOrganization);
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    useEffect(() => {
        store.setTestClassifierError("");
        store.clearSelectedClassifier();
        store.setRbcSearchTermStr("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            sx={(theme) => ({
                height: "100%",
                padding: theme.spacing(5, 10, 10, 10),
            })}
        >
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography
                        display="block"
                        align="left"
                        sx={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            color: theme.palette.blackFont.main,
                            // marginTop: theme.spacing(5),
                        }}
                    >
                        Classifiers
                    </Typography>
                </Grid>
                <Grid item>
                    <Link
                        to="reclassify-history"
                        className={classes.viewReclassifyHistoryLink}
                    >
                        View Reclassify History
                    </Link>
                </Grid>
            </Grid>
            <ClassifierBuilderTableV2 />
        </Grid>
    );
});

export default ClassifierBuilderV2;
