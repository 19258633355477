import { Grid, Link, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AcxButton from "components/UI/AcxButton";
import AcxTextField from "components/UI/AcxTextField";
import React, { useEffect } from "react";
import { delay } from "utils/helpers";
import authService from "./api-authorization/AuthorizeService";

const useStyles = makeStyles((theme: Theme) => ({
    disabled: {
        backgroundColor: theme.palette.secondary.light,
    },
    forgotLink: {
        margin: theme.spacing(4),
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    errorMessage: {
        fontWeight: "bold",
        border: "solid 2px #f55656",
        padding: "12px",
        backgroundColor: "#8e1504",
        color: "white",
    },
}));

let search = window.location.search;
let params = new URLSearchParams(search);

interface ILogin {
    setLoading: (loading: boolean) => void;
}

export default function Login(props: ILogin) {
    const classes = useStyles();
    const [userName, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [buttonStatus, setButtonStatus] = React.useState(true);
    const [loginError, setLoginError] = React.useState(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [samlLoginProvider, setSamlLoginProvider] = React.useState<any>(null);
    const [accountLocked, setAccountLocked] = React.useState(false);

    const resetErr = () => {
        setLoginError(false);
    };

    useEffect(() => {
        const clearUser = async () => {
            localStorage.clear();

            if (!authService.userManager) {
                await authService.ensureUserManagerInitialized();
            }
            authService.userManager?.removeUser();
            authService.userManager?.clearStaleState();
        };
        clearUser();

        async function check() {
            const response = await fetch(
                "api/Authenticate/CheckForSamlLoginOption",
            );
            const data = await response.json();
            if (data.samlLoginProviderExists === true) {
                setSamlLoginProvider(data);
            }
            setLoading(false);
        }
        check();
    }, []);

    const login = async () => {
        setLoading(true);
        props.setLoading(true);
        resetErr();
        let retUrl = params.get("ReturnUrl");

        try {
            var response = await fetch(`api/Authenticate/Login`, {
                method: "Post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    UserName: userName,
                    Password: password,
                    ReturnUrl: retUrl,
                }),
            });
            const data = await response.json();
            setLoading(false);

            if (response.status === 200) {
                if (retUrl) {
                    await delay(1000);
                    window.location.replace(retUrl!);

                    props.setLoading(false);
                }
            } else {
                if (data.otherDetails === "LoginFail") {
                    setLoginError(true);
                } else if (data.message === "Account locked") {
                    setAccountLocked(true);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
            props.setLoading(false);
        }
    };

    function handleUserChange(e) {
        setUserName(e.target.value);
        if (e.target.value) {
            setButtonStatus(false);
        } else {
            setButtonStatus(true);
        }
    }
    function handlePasswordChange(e) {
        setPassword(e.target.value);
    }

    function handleKeyPress(event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            if (userName.length > 0 && password.length > 0) {
                login();
            }
        }
    }

    let samlUrl = "/api/Saml/InitiateSingleSignOn";
    let returnUrl = params.get("ReturnUrl");
    if (returnUrl) {
        samlUrl = samlUrl + "?returnUrl=" + encodeURIComponent(returnUrl);
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
            direction="row"
        >
            {!samlLoginProvider && !accountLocked && (
                <>
                    <Grid item xs={12}>
                        <form onKeyUp={handleKeyPress}>
                            <div>
                                <AcxTextField
                                    autoFocus
                                    id="InputUsername"
                                    type="email"
                                    label="Username"
                                    placeholder="Username"
                                    required={false}
                                    error={loginError}
                                    onChange={handleUserChange}
                                    fullWidth={true}
                                    other={{}}
                                />
                            </div>

                            <div>
                                <AcxTextField
                                    id="InputPassword"
                                    type="password"
                                    label="Password"
                                    placeholder="Password"
                                    required={false}
                                    error={loginError}
                                    onChange={handlePasswordChange}
                                    fullWidth={true}
                                    other={{}}
                                    autoComplete="off"
                                />
                            </div>
                            {loginError && (
                                <div>
                                    <p className={classes.errorMessage}>
                                        Username or Password Incorrect
                                    </p>
                                </div>
                            )}

                            <AcxButton
                                fullWidth
                                sx={{
                                    marginInline: 0,
                                    marginBlock: "12px",
                                    paddingBlock: "12px",
                                    height: "36px !important",
                                }}
                                onClick={login}
                                color="primary"
                                disabled={buttonStatus}
                                loading={loading ?? false}
                            >
                                Submit
                            </AcxButton>
                        </form>
                    </Grid>

                    <Grid item container justifyContent="center" xs={12}>
                        <Typography className={classes.forgotLink}>
                            <Link href="/reset" color="primary">
                                Forgot Password?
                            </Link>
                        </Typography>
                    </Grid>
                </>
            )}
            {accountLocked && (
                <Grid item container justifyContent="center" xs={12}>
                    <Typography>
                        Your account has been locked. Account reset information
                        has been sent to the email on file.
                    </Typography>
                </Grid>
            )}
            {samlLoginProvider && (
                <Grid item container justifyContent="center" xs={12}>
                    <AcxButton
                        fullWidth
                        sx={{
                            marginInline: 0,
                            marginBlock: "12px",
                            paddingBlock: "12px",
                            height: "36px !important",
                        }}
                        onClick={login}
                        color="primary"
                        loading={loading ?? false}
                        href={samlUrl}
                    >
                        {samlLoginProvider!.loginButtonText ??
                            "Authorize with SAML Provider"}
                    </AcxButton>
                </Grid>
            )}
        </Grid>
    );
}
