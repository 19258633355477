import { styled } from "@mui/material";
import AcxButton from "components/UI/AcxButton";

const Suggestion = styled(AcxButton)(({ theme }) => ({
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.primary[500],
    color: theme.palette.primary[500],
    backgroundColor: theme.palette.white.main,
    borderRadius: "9999px",
    height: "min-content",
    textWrap: "wrap",
    textAlign: "start",
    ":hover": {
        backgroundColor: theme.palette.primary[100],
    },
    ":disabled": {
        color: theme.palette.primary[500],
        backgroundColor: theme.palette.white.main,
        opacity: "75%",
    },
}));

Suggestion.defaultProps = {
    fullWidth: false,
    id: "ava-suggested-response",
};

export { Suggestion };
