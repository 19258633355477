import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChip from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";

export const TopicsChips = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    return applicationFiltersStore.topics.map((topic) => (
        <AppliedFilterChip
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={() =>
                applicationFiltersStore.setTopics(
                    applicationFiltersStore.topics.filter(
                        (t) => t.id !== topic.id,
                    ),
                )
            }
        >
            Theme: {topic.topicLabel}
        </AppliedFilterChip>
    ));
});
