import { observable, makeObservable } from "mobx";
import AudioMetadataUiModel from "./AudioMetadataUiModel";
import BaseEntity from "./BaseEntity";

export default class DirectoryInfoModel extends BaseEntity {
    @observable subDirectories?: DirectoryInfoModel[];
    @observable numUnsampledFiles?: number;
    @observable numSampledFiles?: number;
    @observable currDirectory?: string;
    @observable currDirectorySegment?: string;
    @observable currDirectoryId?: string;
    @observable organizationStructureMemberId?: string;

    @observable numUnsampledFilesSubDirs: number;
    @observable numSampledFilesSubDirs: number;

    @observable.deep children: AudioMetadataUiModel[] = [];

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }
}
