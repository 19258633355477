import React, { CSSProperties } from "react";
import AcxSelectSingle from "./BaseSelectComponents/AcxSelectSingle";
import Organization from "models/Organization";
import { Observer } from "mobx-react-lite";
import LocalStorage from "stores/LocalStorage";
import RootStore from "stores/RootStore";
import { AuthStore } from "../../../stores/AuthStore";
import { useStore } from "../../../utils/useStore";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminRoutes } from "components/Admin/AdminNavigation/AdminRoutes";
import { Routes } from "components/Navigation/Routes";

interface IOrgSelectV2 {
    id: string;
    onLoading?: (isLoading: boolean) => void;
    showLoading?: boolean;
    onError?: (errorMessage?: string) => void;
    onSelect?: (org: Organization) => void;
    onDisplay?: (isVisible: boolean) => void;
    label?: string;
    fullWidth?: boolean;
    menuPortalTarget?: HTMLElement | null | undefined;
    disabled?: boolean;
    autoSelectUsersOrg?: boolean;
    defaultValue?: any;
    customStyle?: any;
    orgId?: string;
    customRootStyles?: CSSProperties;
    disableGlobalOrgChange?: boolean;
}

function OrgSelectV2(props: IOrgSelectV2) {
    const authStore = useStore(AuthStore);
    const navigate = useNavigate();
    const location = useLocation();
    const previousPath = location.state.previousPath;

    const local = RootStore().getStore(LocalStorage);
    const handleChange = async (val) => {
        const tempLocal = local.getLocalStore();
        await authStore.orgStore.setGlobalOrganization(val);
        await tempLocal.setItem("selectedOrgID", val?.id);
        authStore.orgStore.onActiveOrgIdChange(val?.id);

        if (!previousPath) {
            window.location.reload();
            return;
        }

        if (previousPath.includes("admin")) {
            const selectedOrgId = val.id;

            const orgDetailsRoute = `${AdminRoutes.routePrefix}${AdminRoutes.ORGANIZATIONS}/${selectedOrgId}`;
            navigate(orgDetailsRoute, { replace: true });
            return;
        }

        if (
            previousPath.includes("eval") ||
            previousPath.includes("conversation")
        ) {
            navigate(previousPath, { replace: true });
            return;
        }

        navigate(Routes.HOME, { replace: true });
        window.location.reload();
    };

    return (
        <Observer>
            {() => (
                <div
                    style={{
                        ...{
                            display:
                                (authStore.orgStore?.organizations?.length ||
                                    0) > 1 ?? false
                                    ? "block"
                                    : "none",
                        },
                        ...props.customRootStyles,
                    }}
                >
                    <AcxSelectSingle
                        inputLabel={props.label}
                        fullWidth={props.fullWidth}
                        isDisabled={props.disabled}
                        defaultValue={
                            props.defaultValue ||
                            authStore.orgStore?.selectedOrganization
                        }
                        id={`org-selector-${props.id}`}
                        labelField="name"
                        valueField="id"
                        options={authStore.orgStore?.organizations ?? []}
                        onChange={handleChange}
                        placeholder={
                            props.showLoading &&
                            authStore.orgStore.anyTaskLoading
                                ? "Loading.."
                                : "Select Organization"
                        }
                        menuPortalTarget={props.menuPortalTarget}
                        customStyle={props.customStyle}
                    />
                </div>
            )}
        </Observer>
    );
}

export default OrgSelectV2;
