import { EddyEffectP2Results } from "models/EddyEffectP2Results";
import { IAugmentedChatRecord, IChatRecord } from "./AcxChatViewer";

type TAddEddyToChatRecords = {
    records: IChatRecord[];
    eddyEffectP2Data: EddyEffectP2Results[];
};

export const addEddyToChatRecords = ({
    records,
    eddyEffectP2Data,
}: TAddEddyToChatRecords): IAugmentedChatRecord[] => {
    const chatWords = parseChatToWords(records);
    const recordsCopy: IAugmentedChatRecord[] = records
        .filter((record) => !!record.text)
        .map(({ text, from, timestamp }) => {
            const splitText = text?.trim().split(/\s+/);
            return {
                text,
                words:
                    splitText?.map((word) => ({
                        word,
                    })) ?? [],
                from,
                timestamp,
            };
        });

    if (chatWords?.length === 0 || eddyEffectP2Data.length === 0) {
        return recordsCopy;
    }
    let recordIdx = 0;
    let recordWordIdx = 0;

    chatWords.forEach((word, i) => {
        let setViewed = -1;
        recordsCopy[recordIdx]?.words.find((record, idx) => {
            setViewed = idx;
            return record.word === word && !record.viewed;
        });

        if (recordsCopy[recordIdx]?.words && setViewed >= 0) {
            recordsCopy[recordIdx].words[setViewed].viewed = true;
        }

        const eddyRule = eddyEffectP2Data.find(
            ({ startWordIndex, endWordIndex }) =>
                i >= startWordIndex && i < endWordIndex,
        );

        if (eddyRule && recordsCopy[recordIdx]?.words) {
            recordsCopy[recordIdx].words[setViewed].viewed = true;
        }
        if (
            eddyRule &&
            recordsCopy[recordIdx] &&
            word === recordsCopy[recordIdx]?.words[setViewed].word
        ) {
            if (!recordsCopy[recordIdx].eddy) {
                recordsCopy[recordIdx].eddy = true;
            }
            recordsCopy[recordIdx].words[setViewed].eddy = true;
        }

        if (recordsCopy[recordIdx]?.words?.[recordWordIdx + 1]) {
            recordWordIdx++;
        } else {
            recordIdx++;
            recordWordIdx = 0;
        }
    });

    return recordsCopy;
};

export const parseChatToWords = (records: IChatRecord[]): string[] => {
    const chatText = records.map((c) => c.text as string);
    const chatStr = chatText.join(" ").replace(/[\n\t\r\v]{1,}|\s{2,}/g, " ");
    return chatStr.split(" ");
};
