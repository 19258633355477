import {
    Button,
    FormControlLabel,
    Switch,
    Tooltip,
    styled,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import AcxChipList from "components/UI/AcxChipList";
import { observer } from "mobx-react";
import React, { FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import { TableComponentStore } from "stores/ComponentStores/TableComponentStore";
import { evaluationNameFromBlobFileKey } from "utils/Utils";
import Evaluation from "../../../../../models/Evaluation";
import Interaction from "../../../../../models/Interaction";
import { AgentCoachingData } from "../../../../../services/AgentCoachingService";
import { useStore } from "../../../../../utils/useStore";
import { defaultDateTimeFormatter } from "../../../../ManagerInteractions/Util";
import { Routes } from "../../../../Navigation/Routes";
import AcxTabTable from "../../../../UI/AcxTable/TabTable/AcxTabTable";
import { EvalCoachingTableViewModel } from "./EvalCoachingTableViewModel";
import { interactionDateHelper } from "components/UI/AcxDataGrid/ColumnTypes/DateColTypes";
import moment from "moment";
import { Grid, Typography } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxButton from "components/UI/AcxButton";

const LinkButton = styled(Button)(({ theme }) => ({
    fontSize: "12px",
    textAlign: "left",
    "&:hover": {
        fontWeight: "bolder",
        background: "none",
        textDecoration: "underline",
    },
    color: theme.palette.primary.main,
    textDecoration: "underline",
    textUnderlineOffset: theme.spacing(0.5),
}));

const DisabledLinkButton = styled(Button)(({ theme }) => ({
    fontSize: "12px",
    textAlign: "left",
    "&:hover": {
        background: "none",
    },
    color: theme.palette.neutral[600],
    cursor: "default",
}));

const ReviewButton = styled(Button)(({ theme }) => ({
    textTransform: "none",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
}));

const NotesColumn = styled("div")(() => ({
    minWidth: "250px",
    maxWidth: "350px",
    wordWrap: "break-word",
}));

interface IEvalCoachingTable {
    viewModel: EvalCoachingTableViewModel;
    tableStores: Map<string, TableComponentStore<AgentCoachingData>>;
}

const EvalCoachingTable: FunctionComponent<IEvalCoachingTable> = observer(
    (props) => {
        const navigate = useNavigate();
        const authStore = useStore(AuthStore);

        function goToMultiEvalReview() {
            props.viewModel.reviewManyEvaluations();
            navigate("/app/agentcoaching/evaluationreview");
        }

        useEffect(() => {
            function goToEvalReview(evalId: string, isCoached: boolean) {
                props.viewModel.reviewSingleEvaluation(evalId, isCoached);
                navigate("/app/agentcoaching/evaluationreview");
            }

            function goToEvalPage(evalId: string) {
                const organizationId = props.viewModel.orgId;

                if (!evalId || !organizationId) {
                    throw new Error("EvalId or OrgId empty");
                }
                navigate(Routes.makeEvaluationRoute(organizationId, evalId));
            }

            function nameFormatter(
                interaction: Interaction,
                evaluation: Evaluation,
            ) {
                const blobFileKey =
                    evaluation.interaction?.audioMetadata?.blobFileKey;
                const evalName = evaluationNameFromBlobFileKey(blobFileKey);
                const res = (
                    <LinkButton
                        color="primary"
                        onClick={() => goToEvalPage(evaluation.id)}
                    >
                        {evalName}
                    </LinkButton>
                );

                return res;
            }

            function evalNumberFormatter(
                interaction: Interaction,
                evaluation: Evaluation,
            ) {
                const evalName = evaluation.evaluationQbId;
                const res = (
                    <LinkButton
                        color="primary"
                        onClick={() => goToEvalPage(evaluation.id)}
                    >
                        {evalName}
                    </LinkButton>
                );

                return res;
            }

            function evalNumberDisplay(_: void, row: AgentCoachingData) {
                return (
                    <Tooltip
                        title={
                            <Grid container direction="column">
                                <Grid item>
                                    <Typography
                                        variant="h2"
                                        sx={{ color: "white " }}
                                    >
                                        Review Already Started
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant="body1"
                                        sx={{ color: "white " }}
                                    >
                                        This evaluation is currently in progress
                                        as a draft and cannot be initiated.
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                    >
                        {/* use button to match the same padding/spacings as evalNumberFormatter */}
                        <DisabledLinkButton color="secondary" disableRipple>
                            {row.evaluationQbId}
                        </DisabledLinkButton>
                    </Tooltip>
                );
            }

            function dateTimeFormatter(dateTime: string) {
                return !dateTime
                    ? null
                    : defaultDateTimeFormatter(dateTime, false);
            }

            function interactionDateTimeFormatter(dateTime: string) {
                return !dateTime
                    ? null
                    : moment(interactionDateHelper(dateTime)).format(
                          "YYYY-MM-DD h:mm A",
                      );
            }

            function goToEvalButton(arg, agentCoachingData: AgentCoachingData) {
                return (
                    <ReviewButton
                        variant={"outlined"}
                        color={"primary"}
                        size={"small"}
                        onClick={() =>
                            goToEvalReview(agentCoachingData.id, false)
                        }
                        disableElevation
                    >
                        Review
                    </ReviewButton>
                );
            }

            function goToAcknowledgeButton(
                arg,
                agentCoachingData: AgentCoachingData,
            ) {
                if (agentCoachingData.acknowledged) {
                    return <></>;
                }
                return (
                    <ReviewButton
                        variant={"outlined"}
                        color={"secondary"}
                        size={"small"}
                        onClick={() =>
                            goToEvalReview(agentCoachingData.id, true)
                        }
                        disableElevation
                    >
                        Acknowledge
                    </ReviewButton>
                );
            }

            function notesFormatter(arg) {
                return (
                    <NotesColumn>
                        <Tooltip title={arg}>
                            <div>{arg}</div>
                        </Tooltip>
                    </NotesColumn>
                );
            }

            function coachedBooleanFormatter(arg) {
                return !!arg ? (
                    <CheckIcon color="primary" />
                ) : (
                    <ClearIcon color="error" />
                );
            }

            function coachedAckBooleanFormatter(
                arg,
                agentCoachingData: AgentCoachingData,
            ) {
                return agentCoachingData?.acknowledged === true ? (
                    <CheckIcon color="primary" />
                ) : (
                    <ClearIcon color="error" />
                );
            }

            function agentAckBooleanFormatter(
                arg,
                agentCoachingData: AgentCoachingData,
            ) {
                return agentCoachingData?.evalAcknowledged === true ? (
                    <CheckIcon color="primary" />
                ) : (
                    <ClearIcon color="error" />
                );
            }

            function scoresFormatter(arg) {
                return (
                    <AcxChipList
                        size={"small"}
                        color={"blue"}
                        values={arg?.map(
                            (score) =>
                                `${score.moduleName}: ${(
                                    score.avgScore * 100
                                ).toFixed(0)}%`,
                        )}
                    />
                );
            }

            const formatters = {
                name: nameFormatter,
                dateTime: dateTimeFormatter,
                evalNavButton: goToEvalButton,
                acknowledgeNavButton: goToAcknowledgeButton,
                evalNumberFormatter: evalNumberFormatter,
                evalNumberDisplay,
                notes: notesFormatter,
                coachedBoolean: coachedBooleanFormatter,
                agentAckBoolean: agentAckBooleanFormatter,
                coachedAckBoolean: coachedAckBooleanFormatter,
                scoresFormatter: scoresFormatter,
                interactionDateTime: interactionDateTimeFormatter,
            };

            props.viewModel.buildTableTabs(formatters);
        }, [
            props.viewModel,
            props.viewModel.orgId,
            props.tableStores,
            navigate,
            authStore,
        ]);

        function renderMachineEvalsSwitch() {
            return (
                <FormControlLabel
                    control={
                        <Switch
                            sx={{ minWidth: "40px" }}
                            onChange={props.viewModel.toggleShowMachineEvals}
                            checked={props.viewModel.showMachineEvals}
                        />
                    }
                    label="Show AI Scored Evaluations"
                />
            );
        }

        return (
            <>
                {props.viewModel.tabTableData && (
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            flexWrap="nowrap"
                        >
                            <Grid
                                container
                                item
                                direction="row"
                                columnSpacing={1}
                                alignItems="center"
                            >
                                <Grid item>
                                    <AcxMainTextField
                                        id="evalSearch"
                                        placeholderText="Find Eval by #"
                                        type="text"
                                        onChange={(e) =>
                                            props.viewModel.evalTableStores.forEach(
                                                (table) =>
                                                    table.setSearchString(
                                                        e.target.value,
                                                    ),
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item>{renderMachineEvalsSwitch()}</Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                direction="row"
                                columnSpacing={1}
                                alignItems="center"
                                justifyContent="flex-end"
                                paddingTop="20px"
                            >
                                {!!props.viewModel.evalTableStores.get(
                                    props.viewModel.myDraftsTab,
                                ) && (
                                    <Grid item paddingBottom="15px">
                                        <AcxButton
                                            color="primary"
                                            disabled={
                                                !props.viewModel
                                                    .hasSelectedDrafts
                                            }
                                            loading={props.viewModel.getTaskLoading(
                                                "Delete Drafts",
                                            )}
                                            onClick={() =>
                                                props.viewModel.deleteSelectedDrafts()
                                            }
                                        >
                                            Delete Selected Drafts
                                        </AcxButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <AcxTabTable
                            tabs={props.viewModel.tabTableData}
                            onActionClick={goToMultiEvalReview}
                            keyField={"id"}
                            virtualize
                            actionLabel={props.viewModel.reviewAllAction}
                            isLoading={props.viewModel.getTaskLoading(
                                "Load Evaluations",
                            )}
                        />
                    </>
                )}
            </>
        );
    },
);

export default EvalCoachingTable;
