import UserTrackedObject from "models/UserTrackedObject";
import BaseService from "./BaseService";
import UserTrackedGroup from "models/UserTrackedGroup";

export class UserObjectTrackingService extends BaseService {
    protected urlBase: string = "api/UserObjectTracking";

    public async trackObjectForUser(request: {
        objectId: string;
        objectType: string;
        userTrackedGroupId: string;
    }) {
        const apiPath = "AddObjectForUser";
        return this.post<UserTrackedObject>(
            JSON.stringify(request),
            new URLSearchParams(),
            apiPath,
        );
    }

    public async unTrackObjectForUser(request: {
        objectId: string;
        objectType: string;
        userTrackedGroupId: string;
    }) {
        const apiPath = "RemoveObjectForUser";
        return this.delete(
            new URLSearchParams({}),
            apiPath,
            JSON.stringify(request),
        );
    }

    public async getUserTrackedGroupByName(userTrackedGroupName: string) {
        const apiPath = "UserTrackedGroupByName";
        return this.get<UserTrackedGroup>(
            new URLSearchParams({ userTrackedGroupName }),
            apiPath,
        );
    }

    public async getObjectTrackedStatus(request: {
        objectIds: string[];
        userTrackedGroupId: string;
    }) {
        const apiPath = "GetObjectTrackedStatus";
        return this.post(
            JSON.stringify(request),
            new URLSearchParams(),
            apiPath,
        );
    }
}
