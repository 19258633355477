import { Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ModuleUIModel } from "components/Evaluation/Models/ModuleUIModel";
import AcxStatusSticker from "components/UI/AcxStatusSticker";
import { observer } from "mobx-react";
import Question from "models/Question";
import React from "react";
import HelpSvg from "SvgIcons/HelpSvg";
import { useStore } from "utils/useStore";
import { WorkflowReviewStore } from "../Stores/WorkflowReviewStore";

const useStyles = makeStyles((theme: Theme) => ({
    sPContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
    },
    aiExpToggle: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
}));

interface WFRSmartPredictToggleProps {
    question: Question;
    moduleUIModel?: ModuleUIModel;
}

const WFRSmartPredictToggle: React.FC<WFRSmartPredictToggleProps> = observer(
    (props) => {
        const classes = useStyles(props);
        const store = useStore(WorkflowReviewStore);

        const answer = store.getAnswerForQuestion(
            props.question,
            props.moduleUIModel?.licensedModule.evaluationModuleId,
        );
        const isSmartPredicted = answer?.hasAccuratePredictions;

        const hasMlModel: boolean = !!answer?.predictedTags.find((item) => {
            // There's some risk to showing the other models and getting it wrong.
            // We should restrict all the other ML models but leave Eddy.
            return (
                item.predictedBy.startsWith("ML") &&
                !["ML-EddyEffect", "ML-EddyEffectP2"].includes(item.predictedBy)
            );
        });

        return (
            <>
                {isSmartPredicted && (
                    <Grid item className={classes.sPContainer}>
                        <AcxStatusSticker
                            title={"Smart Predict"}
                            customRootStyles={{
                                width: "max-content",
                            }}
                        />
                        {store.authStore.canUserView(
                            "AI Scoring Explanations",
                        ) &&
                            !hasMlModel && (
                                <Grid
                                    item
                                    className={classes.aiExpToggle}
                                    onClick={() => {
                                        store.setShowAIExplanation(
                                            props.question.id,
                                            !store.showAIExplanation[
                                                props.question.id
                                            ],
                                        );
                                    }}
                                    id="ai-explanation-icon"
                                >
                                    <HelpSvg />
                                </Grid>
                            )}
                    </Grid>
                )}
            </>
        );
    },
);

export default WFRSmartPredictToggle;
