import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../utils/useStore";
import AcxButton from "../../../UI/AcxButton";
import { ReportEditorStore } from "../../Stores/ReportEditorStore";
import ReportsStore from "../../Stores/ReportsStore";
import QueryFilterSection from "./EditorSections/QueryFilterSection";
import QuickFilterSection from "./EditorSections/QuickFilterSection";
import ReportDataSection from "./EditorSections/ReportDataSection";
import ReportDetailSection from "./EditorSections/ReportDetailSection";

const useStyles = makeStyles((theme: Theme) => ({
    sectionTitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        paddingBottom: theme.spacing(1),
    },
    vertSpace: {
        marginTop: theme.spacing(3),
    },

    sectionSpace: {
        marginTop: theme.spacing(6),
    },
    selectedQuickFiltersText: {
        marginTop: theme.spacing(3),
        fontSize: "16px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "24px",
        marginBottom: theme.spacing(1.5),
    },
    mainSectionTitle: {
        paddingTop: theme.spacing(5),
    },
    mainSectionTitleText: {
        fontFamily: "Inter",
        fontSize: "20px",
        paddingRight: theme.spacing(1),
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "28px",
    },
    mainSectionSubtitleText: {
        fontFamily: "Inter",
        opacity: "0.5",
        color: "#1F1F1F",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
        maxWidth: "290px",
        paddingRight: theme.spacing(1.4),
    },
    bulletedList: {
        // listStyle: "circle",
        fontFamily: "Inter",
        fontSize: "16px",
        letterSpacing: "0",
        lineHeight: "24px",
    },
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    },
    savAsBtn: {
        marginTop: theme.spacing(2.5),
    },
}));

export enum QueryFilterOptions {
    None,
    Custom,
}

export enum QuickFilterOptions {
    STD,
    Custom,
}

export const ToggleFilterOptions = [
    {
        element: `Standard Quick Filters`,
        value: QuickFilterOptions.STD,
    },
    {
        element: `Custom Quick Filters`,
        value: QuickFilterOptions.Custom,
    },
];

interface OwnProps {}

type Props = OwnProps;

const Editor: React.FC<Props> = observer((props) => {
    const classes = useStyles();
    const store = useStore(ReportEditorStore);
    const reportStore = useStore(ReportsStore);
    const navigate = useNavigate();

    function onSaveAs() {
        store.saveReportAs().then((value) => {
            if (value) {
                reportStore.quickFilterStore.reset();
                reportStore.refreshReports();
                navigate(-1);
            }
        });
    }

    return (
        <div>
            <Grid container justifyContent={"center"} className={classes.root}>
                {/*Report Details section*/}
                <Grid
                    container
                    item
                    xl={7}
                    lg={8}
                    md={10}
                    sm={11}
                    xs={12}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={3} className={classes.mainSectionTitle}>
                        <Typography className={classes.mainSectionTitleText}>
                            Report Details
                        </Typography>
                    </Grid>
                    <Grid item xs={9} container>
                        <ReportDetailSection />
                    </Grid>
                </Grid>

                {/*Report Data section*/}
                <Grid
                    container
                    item
                    xl={7}
                    lg={8}
                    md={10}
                    sm={11}
                    xs={12}
                    className={classes.sectionSpace}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={3} className={classes.mainSectionTitle}>
                        <Typography className={classes.mainSectionTitleText}>
                            Report Data
                        </Typography>
                    </Grid>
                    <Grid item xs={9} container>
                        <ReportDataSection />
                    </Grid>
                </Grid>

                {/*Report QueryFilter section*/}
                <Grid
                    container
                    item
                    xl={7}
                    lg={8}
                    md={10}
                    sm={11}
                    xs={12}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={3} className={classes.mainSectionTitle}>
                        <Typography className={classes.mainSectionTitleText}>
                            Filter Report
                        </Typography>
                    </Grid>
                    <Grid item xs={9} container>
                        <QueryFilterSection />
                    </Grid>
                </Grid>

                {/*Report QuickFilter section*/}
                <Grid
                    container
                    item
                    xl={7}
                    lg={8}
                    md={10}
                    sm={11}
                    xs={12}
                    direction="row"
                    wrap={"nowrap"}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item xs={3} className={classes.mainSectionTitle}>
                        <Typography className={classes.mainSectionTitleText}>
                            Quick Filters
                        </Typography>
                        <Typography className={classes.mainSectionSubtitleText}>
                            Quick filters appear on the report page and provide
                            an additional method of filtering your report on the
                            fly.
                        </Typography>
                    </Grid>
                    <Grid item xs={9} container>
                        <QuickFilterSection />

                        {Boolean(store.currentReport?.id) && (
                            <AcxButton
                                className={classes.savAsBtn}
                                tooltip={"Save new report from existing"}
                                color={"primary"}
                                size={"large"}
                                onClick={onSaveAs}
                            >
                                Save As
                            </AcxButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
});

export default Editor;
