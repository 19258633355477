import React, { FunctionComponent } from "react";

interface IGhostGrapghSvg {
    opacity?: number;
    width?: number;
    height?: number;
    imageStyle: any;
    style?: React.CSSProperties;
}

const GhostGrapghSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<IGhostGrapghSvg>>> = (props) => {
    return (
        // <svg xmlns="http://www.w3.org/2000/svg">
        // </svg>
        (<img
            src={props.imageStyle}
            alt=""
            // style={{ maxWidth: "29px", maxHeight: "29px" }}
            style={props.style}
            // style={{
            //     width: "24px",
            //     height: "24px",
            //     float: "left",
            // }}
        />)
    );
};

export default GhostGrapghSvg;
