import { Grid } from "@mui/material";
import React from "react";
import HighlightItem from "./HighlightItem";
import { defaultCallSpeakerNames, defaultChatSpeakerNames } from "./utils";

interface FTSHighlightsData {
    fullTextSearchHighlightsSpeaker0?: string | null;
    fullTextSearchHighlightsSpeaker1?: string | null;
    fullTextSearchHighlightsSpeaker2?: string | null;
    fullTextSearchHighlightsSpeaker3?: string | null;
}

// Use index 0 for speaker 0,
// index 1 for speaker 1 etc.
type SpeakerNames = [
    string | undefined,
    string | undefined,
    string | undefined,
    string | undefined,
];

interface FTSHighlightsProps {
    highlights: FTSHighlightsData;
    isChat: boolean;
    speakerNames?: SpeakerNames;
    disableAllChips?: boolean;
}

export function FTSHighlights(props: FTSHighlightsProps) {
    return (
        <Grid container direction="column" gap={1}>
            {props.highlights.fullTextSearchHighlightsSpeaker0 && (
                <Grid item>
                    <HighlightItem
                        highlight={
                            props.highlights.fullTextSearchHighlightsSpeaker0
                        }
                        speaker={
                            props.speakerNames?.[0] ??
                            (props.isChat
                                ? defaultChatSpeakerNames
                                : defaultCallSpeakerNames)[0]
                        }
                        disableChips={props.disableAllChips}
                    />
                </Grid>
            )}
            {props.highlights.fullTextSearchHighlightsSpeaker1 && (
                <Grid item>
                    <HighlightItem
                        highlight={
                            props.highlights.fullTextSearchHighlightsSpeaker1
                        }
                        speaker={
                            props.speakerNames?.[1] ??
                            (props.isChat
                                ? defaultChatSpeakerNames
                                : defaultCallSpeakerNames)[1]
                        }
                        disableChips={props.disableAllChips}
                    />
                </Grid>
            )}
            {props.highlights.fullTextSearchHighlightsSpeaker2 && (
                <Grid item>
                    <HighlightItem
                        highlight={
                            props.highlights.fullTextSearchHighlightsSpeaker2
                        }
                        speaker={
                            props.speakerNames?.[2] ??
                            (props.isChat
                                ? defaultChatSpeakerNames
                                : defaultCallSpeakerNames)[2]
                        }
                        disableChips={props.disableAllChips}
                    />
                </Grid>
            )}
            {props.highlights.fullTextSearchHighlightsSpeaker3 && (
                <Grid item>
                    <HighlightItem
                        highlight={
                            props.highlights.fullTextSearchHighlightsSpeaker3
                        }
                        speaker={
                            props.speakerNames?.[3] ??
                            (props.isChat
                                ? defaultChatSpeakerNames
                                : defaultCallSpeakerNames)[3]
                        }
                        disableChips={props.disableAllChips}
                    />
                </Grid>
            )}
        </Grid>
    );
}
