import { DropdownOption } from "components/Admin/Organizations/OrganizationDetail/OrganizationDetails/MetadataFileConfiguration/MetadataFieldRow";

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

export function EnumToDropdownOptionConversion<Type>(arg: Enum<Type>): any {
    const options: DropdownOption<Type>[] = Object.entries(arg)
        .filter((entry) => typeof entry[1] === "number")
        .map((option) => {
            const dropdownOption = {
                label: (option[0][0].toUpperCase() +
                    option[0].slice(1)) as unknown as Type,
                value: option[1] as unknown as number,
            };

            return dropdownOption;
        });

    return options;
}
