import { observer } from "mobx-react";
import { IAppliedFilterChipComponent } from "../Chips/IAppliedFilterChipComponent";
import AppliedFilterChipBase from "../Chips/AppliedFilterChipBase";
import { useStore } from "utils/useStore";
import { ApplicationFiltersStore } from "stores/ApplicationFilters/ApplicationFiltersStore";
import React from "react";

export const HierarchiesChips = observer(function (
    props: IAppliedFilterChipComponent,
) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);

    const hierarchyCount = applicationFiltersStore.hierarchyIds.length;
    const hierarchyLabel = hierarchyCount === 1 ? "Hierarchy" : "Hierarchies";

    return (
        <AppliedFilterChipBase
            disabled={props.disabled}
            isApplicable={props.isApplicable}
            onRemove={() => applicationFiltersStore.setHierarchyIds([])}
        >
            {hierarchyCount} {hierarchyLabel}
        </AppliedFilterChipBase>
    );
});
