import { Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles } from "@mui/styles";
import AcxExpansion from "components/UI/AcxExpansion";
import AcxDrawer from "components/UI/Drawer/AcxDrawer";
import { observer } from "mobx-react-lite";
import React from "react";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import { OrganizationServiceHierarchyStore } from "../stores/OrganizationServiceHierarchyStore";
import OsmPropertiesLayout from "./OsmPropertiesLayout";
import OsmSamplingTargetLayout from "./OsmSamplingTargetLayout";

type Props = {
    osmStore: OrganizationServiceHierarchyStore;
    authStore: AuthStore;
};

const styles = () =>
    createStyles({
        closeButton: {
            position: "absolute",
            top: 16,
            color: "#A1A1AA",
            right: 8,
        },
        closeIcon: {
            height: 20,
            width: 20,
        },
        drawerContainer: {
            padding: "2rem",
            paddingTop: "calc(2rem + 16px)",
            width: "625px",
        },
    });

const OrganizationServiceHierarchyDrawer: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ osmStore, authStore }) => {
    const classes = useStyles(styles);

    React.useEffect(() => {
        if (osmStore.showEditTierDrawer) {
            osmStore.getSamplingTargetHistory(
                osmStore.samplingHistoryPageNumber,
            );
        }
    }, [
        osmStore,
        osmStore.samplingHistoryPageNumber,
        osmStore.showEditTierDrawer,
    ]);

    return (
        <AcxDrawer
            offsetPx={30}
            collapsible="full"
            anchor="right"
            open={osmStore.showEditTierDrawer}
            content={
                <Grid
                    sx={{
                        padding: "2rem",
                        paddingTop: "calc(2rem + 16px)",
                        width: "625px",
                    }}
                >
                    <IconButton
                        aria-label="close"
                        sx={{
                            position: "absolute",
                            top: 70,
                            color: "#A1A1AA",
                            right: 8,
                        }}
                        onClick={() => osmStore.setShowEditTierDrawer(false)}
                        size="large"
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>

                    {osmStore.activeTier && osmStore.activeTier.isLeaf && (
                        <Grid item xs={12}>
                            <AcxExpansion
                                expanded={true}
                                header="Tier Properties"
                                body={
                                    <OsmPropertiesLayout
                                        osmStore={osmStore}
                                        authStore={authStore}
                                    />
                                }
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <AcxExpansion
                            expanded={true}
                            header="Sampling Target Metrics"
                            body={
                                <OsmSamplingTargetLayout
                                    osmStore={osmStore}
                                    authStore={authStore}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            }
        />
    );
});

export default OrganizationServiceHierarchyDrawer;
