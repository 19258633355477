import {
    action,
    computed,
    IReactionPublic,
    isFlowCancellationError,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import { computedFn } from "mobx-utils";
import { CancellablePromise } from "mobx/dist/api/flow";
import { ServiceError } from "../services/BaseService";
import { delay } from "../utils/helpers";
import { isStringType, isType, isUndefinedType } from "../utils/TypeGuards";

export type StoreError =
    | {
          message: string | undefined;
          operationName: string;
          task?: () => Promise<any> | CancellablePromise<any>;
      }
    | undefined;

export enum AsyncTaskStatus {
    Error = "__Acx__BaseStore__Error",
}

export abstract class BaseStore {
    @observable protected taskErrors: Map<string, StoreError> =
        observable.map();
    @observable protected taskLoadingMap: Map<string, boolean> =
        observable.map();

    private readonly longRunningTaskNames: Set<String> = new Set<String>();
    private readonly taskPromiseMap: Map<
        string,
        CancellablePromise<any> | Promise<any>
    > = new Map<string, CancellablePromise<any> | Promise<any>>();
    private readonly storeName: string;

    protected constructor(name: string | undefined) {
        makeObservable(this);
        this.storeName = name ?? "BaseStore";
    }

    debounceEffect<T>(
        effect: (arg: T, prev: T | undefined, r?: IReactionPublic) => void,
        debounceMs: number,
    ) {
        let timer: NodeJS.Timeout;
        return (arg: T, prev: T | undefined, r: IReactionPublic) => {
            clearTimeout(timer);
            timer = setTimeout(() => effect(arg, prev, r), debounceMs);
        };
    }

    @action
    public setTaskError(
        taskName?: string,
        value?: string | Error,
        task?: () => Promise<any> | CancellablePromise<any>,
        errorFormatter?: (arg: any) => string,
    ) {
        if (isUndefinedType(taskName)) {
            return;
        }

        if (isUndefinedType(value)) {
            this.taskErrors.delete(taskName);
            return;
        }

        let msg: string = "";

        if (errorFormatter) {
            msg = errorFormatter(value);
        } else {
            msg = `${taskName} failed.`;

            if (value instanceof ServiceError) {
                msg += ` ${
                    value.errorMessage?.substr(0, 300) ??
                    value.message.substr(0, 300)
                }`;
            } else if (value instanceof Error) {
                msg += ` ${value.message.substr(0, 300)}`;
            } else if (isStringType(value)) {
                msg += ` ${value.substr(0, 300)}`;
            } else {
                msg += ` Something went wrong`;
            }
        }

        this.taskErrors.set(taskName, {
            message: msg,
            operationName: taskName,
            task,
        });
    }

    @action
    setLongRunningTasks(...taskNames: string[]) {
        taskNames.forEach((value) => {
            this.longRunningTaskNames.add(value);
        });
    }

    @computed
    public get anyTaskLoading() {
        let filter = [...this.taskLoadingMap.entries()].filter(
            (value) => value[1] && !this.longRunningTaskNames.has(value[0]),
        );
        return filter.length > 0;
    }

    @computed
    public get nextTaskError(): StoreError | undefined {
        const errors = [...this.taskErrors.entries()]
            .filter((value) => value[1])
            .map((value) => value[1]);
        if (errors.length > 0) {
            return errors[0];
        }
        return undefined;
    }

    @action
    clearTaskErrors = () => {
        if (!this.taskErrors.size) {
            return;
        }

        this.taskErrors.clear();
    };

    @action
    clearLastTaskError = () => {
        if (!this.taskErrors.size) {
            return;
        }
        this.taskErrors.delete([...this.taskErrors.keys()][0]);
    };

    @computed
    public get anyTaskErrors() {
        const filter = [...this.taskErrors.entries()].filter(
            (value) => value[1],
        );
        return filter.length > 0;
    }

    public getTaskLoading = computedFn(function (
        this: BaseStore,
        taskName: string,
    ) {
        return this.taskLoadingMap.get(taskName) ?? false;
    });

    public getTaskLoadingV2 = computedFn(function (
        this: BaseStore,
        taskName: string,
    ) {
        return this.taskLoadingMap.get(taskName);
    });

    @action
    protected clearError(taskName: string) {
        this.taskErrors.delete(taskName);
    }

    @action
    protected setTaskLoading(taskName: string, value: boolean) {
        this.taskLoadingMap.set(taskName, value);
    }

    @action
    protected clearTaskLoading(taskName: string) {
        this.taskLoadingMap.delete(taskName);
    }

    protected cancelAllTasks() {
        for (let [taskName] of this.taskPromiseMap.entries()) {
            this.attemptToCancelTask(taskName);
            this.taskPromiseMap.delete(taskName);
        }
    }

    protected attemptToCancelTask(taskName: string, runningAgain?: boolean) {
        const stillRunningPromise = this.taskPromiseMap.get(taskName);
        if (isCancellablePromise(stillRunningPromise)) {
            stillRunningPromise.cancel();
        }
        runInAction(() => {
            if (!runningAgain) {
                this.taskErrors.delete(taskName);
                this.setTaskLoading(taskName, false);
            }
        });
    }

    protected isTaskActive(taskName: string) {
        return this.taskPromiseMap.has(taskName);
    }

    @action
    protected setupAsyncTask: <T>(
        baseName: string,
        taskFactory: () => Promise<T> | CancellablePromise<T>,
        noRetry?: boolean,
        errorFormatter?: (arg: Error) => string,
    ) => Promise<T | AsyncTaskStatus.Error> = async <T>(
        baseName: string,
        taskFactory: () => Promise<T> | CancellablePromise<T>,
        noRetry?: boolean,
        errorFormatter?: (arg: Error) => string,
    ) => {
        let task:
            | Promise<T | AsyncTaskStatus.Error>
            | CancellablePromise<T | AsyncTaskStatus.Error>;

        this.setTaskLoading(baseName, true);
        this.taskErrors.delete(baseName);

        this.taskPromiseMap.set(
            baseName,
            (task = taskFactory()
                .then((value: T) => {
                    runInAction(() => {
                        this.taskErrors.delete(baseName);
                        this.setTaskLoading(baseName, false);
                    });

                    return value;
                })
                .catch((reason) => {
                    if (
                        !isFlowCancellationError(reason) &&
                        !isAbortError(reason)
                    ) {
                        console.error(
                            `${this.storeName}::${baseName} failed ${reason}`,
                        );

                        if (
                            reason instanceof Error &&
                            (reason as any).errorJson &&
                            Object.keys((reason as any).errorJson).length > 0
                        ) {
                            this.setTaskError(
                                baseName,
                                (reason as any).errorJson[
                                    Object.keys((reason as any).errorJson)[0]
                                ][0],
                                noRetry
                                    ? undefined
                                    : () =>
                                          this.setupAsyncTask(
                                              baseName,
                                              taskFactory,
                                          ),
                            );
                        } else {
                            runInAction(() => {
                                this.setTaskLoading(baseName, false);
                                this.setTaskError(
                                    baseName,
                                    reason,
                                    noRetry
                                        ? undefined
                                        : () =>
                                              this.setupAsyncTask(
                                                  baseName,
                                                  taskFactory,
                                              ),
                                    errorFormatter,
                                );
                            });
                        }
                    }

                    // NOTE returns AsyncTaskStatus.Error on error if you await the result of setupAsyncTask.. while that is necessary
                    // sometimes, its generally preferred to have all your logic inside the taskFactory so that taskFactory
                    // returns void and hence setupAsyncTask return AsyncTaskStatus.Error|Void
                    return AsyncTaskStatus.Error;
                })
                .finally(async () => {
                    await delay(100);
                    this.taskPromiseMap.get(baseName)?.finally(() =>
                        runInAction(() => {
                            this.clearTaskLoading(baseName);
                            this.taskPromiseMap.delete(baseName);
                        }),
                    );
                })),
        );

        return task;
    };

    public getTaskError = computedFn(function (
        this: BaseStore,
        taskName: string,
    ) {
        return this.taskErrors.get(taskName);
    });
}

function isAbortError(error: Error | any) {
    return error?.name && error.name === "AbortError";
}

function isCancellablePromise(
    task?: Promise<any> | CancellablePromise<any>,
): task is CancellablePromise<any> {
    return isType<CancellablePromise<any>>(task, "cancel");
}
