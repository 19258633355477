import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { useEffect, useState } from "react";
import { useStore } from "utils/useStore";
import OrganizationService from "../Organization.service";
import { Tier } from "../types/Tier.type";

const createHash = (tiers: Tier[]) => {
    return tiers.reduce((obj: { [key: string]: string }, item) => {
        if (item.id) {
            obj[item.id] = item.name;
            if (item.tiers?.length) {
                obj = { ...obj, ...createHash(item.tiers) };
            }
        }
        return obj;
    }, {});
};

type Props = {
    id: string;
};

const useOrganizationHierarchy = ({ id }: Props) => {
    const messageStore = useStore(MessageStore);

    const [hierarchy, setHierarchy] = useState<Tier[] | null>();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<any>({});
    const [tiersToRemove, setTiersToRemove] = useState<string[]>([]);
    const [hierarchyHash, setHierarchyHash] = useState<{
        [key: string]: string;
    }>({});

    useEffect(() => {
        if (!id) {
            return;
        }

        let destroyed = false;
        const runFn = async () => {
            try {
                setIsLoading(true);

                const res = await OrganizationService.getHierarchy(id);

                if (!destroyed) {
                    setHierarchy(res);
                    setHierarchyHash(createHash(res));
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };

        runFn();

        return () => {
            destroyed = true;
            setHierarchy(null);
        };
    }, [id]);

    const saveHierarchy = async (val: any) => {
        setIsSaving(true);

        try {
            await OrganizationService.saveHierarchy(id, val, tiersToRemove);

            setTiersToRemove([]);
            setIsSaving(false);

            messageStore.logMessage(
                `Successfully saved service hierarchy`,
                "success",
            );
        } catch (error) {
            //@ts-ignore
            console.log(error);
            setIsSaving(false);

            //@ts-ignore
            const errors = error.response?.json
                ? //@ts-ignore
                  await error.response.json()
                : {};

            if (Object.keys(errors).length) {
                messageStore.logMessage(
                    `Failed to save service hierarchy: ${Object.keys(errors)
                        .reduce(
                            (arr, errProp) => arr.concat(errors[errProp]),
                            [],
                        )
                        .join("\n")}`,
                    "error",
                );
            }

            setValidationErrors(errors);
        }
    };

    return {
        hierarchy,
        saveHierarchy,
        isSaving,
        isLoading,
        validationErrors,
        setTiersToRemove,
        hierarchyHash,
    };
};

export default useOrganizationHierarchy;
