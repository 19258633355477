import { Grid, IconButton, IconButtonProps, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { observer } from "mobx-react";
import React from "react";
import SoundClipEditorStore from "../Stores/SoundClipEditorStore";
import PlaybackRate from "./PlaybackRate";
import Zoom from "./Zoom";

const useStyles = makeStyles((theme: Theme) => ({
    playIcon: {
        color: theme.palette.blue.main,
    },
    playbackRateContainer: {
        backgroundColor: theme.palette.blue.main,
        borderRadius: "0.25rem",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
    },
    zoomButtonContainer: {
        borderRadius: "0.25rem",
        display: "flex",
        alignContent: "center",
    },
    container: {
        backgroundColor: "#FAFCFF",
        minWidth: "70px",
        maxWidth: "70px",
        maxHeight: "84px",
    },
    iconColor: {
        color: theme.palette.blue.main,
    },
}));

interface SoundClipVisualizerV2Props {
    viewModel: SoundClipEditorStore;
    enablePlaybackRate?: boolean;
    minimize?: boolean;
    enableZoom?: boolean;
    iconButton?: React.ReactNode;
    iconButtonProps?: Partial<IconButtonProps>;
}

export const MinimalPlayer: React.FC<SoundClipVisualizerV2Props> = observer(
    ({
        viewModel,
        enablePlaybackRate,
        minimize,
        enableZoom,
        iconButton,
        iconButtonProps = {},
    }) => {
        const classes = useStyles();
        return (
            <Grid
                container
                item
                xs={3}
                sx={{
                    backgroundColor: "#FAFCFF",
                    minWidth: "70px",
                    maxWidth: "70px !important",
                    maxHeight: "84px",
                }}
            >
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-around"
                    style={{
                        marginLeft: enableZoom ? "10px" : "17.5px",
                    }}
                >
                    {iconButton && (
                        <Grid container item xs={6} justifyContent="center">
                            <IconButton
                                className={classes.iconColor}
                                {...iconButtonProps}
                                size="large"
                            >
                                {iconButton}
                            </IconButton>
                        </Grid>
                    )}
                    <Grid
                        container
                        item
                        xs={!!iconButton ? 6 : 12}
                        justifyContent="center"
                    >
                        <IconButton
                            onClick={viewModel.onPlayClick}
                            size="small"
                        >
                            {viewModel.isPlaying ? (
                                <PauseCircleFilledIcon
                                    fontSize={
                                        minimize || !!iconButton
                                            ? "small"
                                            : "large"
                                    }
                                    className={classes.playIcon}
                                />
                            ) : (
                                <PlayCircleFilledIcon
                                    fontSize={
                                        minimize || !!iconButton
                                            ? "small"
                                            : "large"
                                    }
                                    className={classes.playIcon}
                                />
                            )}
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-around"
                    style={{
                        marginLeft: enableZoom ? "10px" : "17.5px",
                    }}
                >
                    {enablePlaybackRate && (
                        <Grid
                            item
                            xs={6}
                            container
                            justifyContent="space-around"
                        >
                            <PlaybackRate viewModel={viewModel} />
                        </Grid>
                    )}

                    {enableZoom && (
                        <Grid
                            item
                            display="flex"
                            borderRadius="0.25rem"
                            alignContent="center"
                            xs={6}
                        >
                            <Zoom viewModel={viewModel} minimal />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    },
);
