import React, { FunctionComponent } from "react";

interface OwnProps {
    color?: string;
    opacity?: number;
}

type Props = OwnProps;

const AnalystSvg: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    return (
        <svg
            width="17px"
            height="17px"
            viewBox="0 0 17 17"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>E6CE1AF4-AA7C-4523-866D-9519DBE8C2BB</title>
            <g
                id="Admin"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity={props.opacity ?? 0.5}
            >
                <g
                    id="Admin---Organization---Users"
                    transform="translate(-35.000000, -447.000000)"
                    fill={props.color ?? "#1F1F1F"}
                    fillRule="nonzero"
                >
                    <g id="Left-Nav" transform="translate(0.000000, 64.000000)">
                        <g
                            id="Group-10"
                            transform="translate(24.000000, 104.000000)"
                        >
                            <g
                                id="Group-9"
                                transform="translate(0.000000, 232.000000)"
                            >
                                <g
                                    id="Group-4-Copy-7"
                                    transform="translate(0.000000, 40.000000)"
                                >
                                    <g
                                        id="Icon/Building"
                                        transform="translate(11.000000, 7.000000)"
                                    >
                                        <path
                                            d="M9,0.995 C4.58624312,0.995 0.995,4.58624312 0.995,9 L0.995,14.003125 C0.995,15.6581587 2.34184125,17.005 3.996875,17.005 L4.9975,17.005 C5.55084562,17.005 5.998125,16.55672 5.998125,16.004375 L5.998125,11.00125 C5.998125,10.448905 5.55084562,10.000625 4.9975,10.000625 L2.99625,10.000625 L2.99625,9 C2.99625,5.68893187 5.68893187,2.99625 9,2.99625 C12.3110681,2.99625 15.00375,5.68893187 15.00375,9 L15.00375,10.000625 L13.0025,10.000625 C12.4491544,10.000625 12.001875,10.448905 12.001875,11.00125 L12.001875,16.004375 C12.001875,16.55672 12.4491544,17.005 13.0025,17.005 L14.003125,17.005 C15.6581587,17.005 17.005,15.6581587 17.005,14.003125 L17.005,9 C17.005,4.58624312 13.4137569,0.995 9,0.995 Z"
                                            id="16px_headphones-3"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default AnalystSvg;
