import React from "react";
import { Navigate } from "react-router";
import { useStore } from "utils/useStore";
import MotfDefinitionForm from "./MotfDefinitionForm";
import { OrganizationMotfStore } from "./OrganizationMotfStore";

export default function OrganizationMotfEdit({ id }: { id: string }) {
    const motfStore = useStore(OrganizationMotfStore);

    return (
        <>
            {!motfStore.editingDefinition && !motfStore.editingDefinitionId && (
                <Navigate to="../definitions" />
            )}
            {!!motfStore.editingDefinition && !!motfStore.editingDefinitionId && (
                <MotfDefinitionForm
                    initialDefinition={{
                        ...motfStore.editingDefinition!,
                        id: motfStore.editingDefinitionId!,
                    }}
                    orgId={id}
                />
            )}
        </>
    );
}
