import FlagIcon from "@mui/icons-material/Flag";
import { Grid, IconButton, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FunctionComponent } from "react";
import EditSvg from "SvgIcons/EditSvg";
import QuoteSvg from "SvgIcons/QuoteSvg";

type Props = {
    toggleNote: () => void;
    showNote: boolean;
    toggleQuote: () => void;
    showQuote: boolean;
    toggleAnswerAlert: () => void;
    flagActive: boolean;
    interactionType?: number;
    restrictMedia?: boolean;
    isDisabled?: boolean;
};

const useQuoteNoteStyles = makeStyles(() => ({
    buttonContainer: {
        textAlign: "center",
    },
    iconButton: {
        marginBottom: "0.5rem",
        padding: "8px",
    },
    active: {
        // backgroundColor: "#3564D5",
        color: "primary",
    },
    inactive: {
        // backgroundColor: "#e6e6e6",
    },
    activeIcon: {
        color: "red !important",
    },
    inactiveIcon: {
        color: "#1F1F1F",
        opacity: 0.25,
    },
}));

const QuoteNoteButtonContainer: FunctionComponent<Props> = ({
    toggleNote,
    showNote,
    toggleQuote,
    showQuote,
    toggleAnswerAlert,
    flagActive,
    interactionType,
    restrictMedia,
}) => {
    const classes = useQuoteNoteStyles();
    const theme = useTheme();

    const iconSize = { width: "20px", height: "20px" };

    const renderIconButton = (
        onClick: () => void,
        isActive: boolean,
        IconComponent: React.ElementType,
        iconProps: object = {},
        activeColor: string = "primary",
    ) => (
        <Grid item className={classes.buttonContainer}>
            <IconButton onClick={onClick} size="large">
                <IconComponent
                    {...iconSize}
                    {...iconProps}
                    {...{
                        color: isActive ? activeColor : "#1F1F1F",
                        opacity: isActive ? 1 : 0.25,
                    }}
                />
            </IconButton>
        </Grid>
    );
    return (
        <>
            {renderIconButton(
                toggleNote,
                showNote,
                EditSvg,
                {
                    width: "20px",
                    height: "20px",
                },
                theme.palette.primary.main,
            )}

            {interactionType !== 4 &&
                !restrictMedia &&
                renderIconButton(
                    toggleQuote,
                    showQuote,
                    QuoteSvg,
                    {
                        width: "20px",
                        height: "20px",
                    },
                    theme.palette.primary.main,
                )}

            {renderIconButton(toggleAnswerAlert, flagActive, FlagIcon, {
                fontSize: "small",
            })}
        </>
    );
};

export default QuoteNoteButtonContainer;
