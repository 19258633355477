import { Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AcxButton from "components/UI/AcxButton";
import AcxDataGrid from "components/UI/AcxDataGrid/AcxDataGrid";
import { Observer, observer } from "mobx-react";
import React from "react";
import OrganizationWorkflowsStore from "./Stores/OrganizationWorkflowsStore";

type Props = {
    store: OrganizationWorkflowsStore;
};

const OrganizationWorkflowsListConditions: React.FC<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer(({ store }) => {
    React.useEffect(() => {
        store.dataGridStore.controls = [
            <Observer>
                {() => (
                    <div>
                        <AcxButton
                            fullWidth
                            disabled={store.isEditDisabled}
                            color="primary"
                            style={{
                                padding: "1rem",
                                minWidth: "max-content",
                            }}
                            startIcon={<EditIcon />}
                            onClick={() =>
                                store.handleDrawerOpen(
                                    store.dataGridStore.selectedRows[0]
                                        .condition,
                                )
                            }
                        >
                            Edit
                        </AcxButton>
                    </div>
                )}
            </Observer>,
        ];

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.organizationId]);

    return (
        <Grid item xs={12} style={{ height: "65vh" }}>
            <AcxDataGrid dataGridStore={store.dataGridStore} />
        </Grid>
    );
});

export default OrganizationWorkflowsListConditions;
