import { Grid } from "@mui/material";
import AgentChatStore from "components/Agent/Stores/AgentChatStore";
import AcxDialog from "components/UI/Dialog/AcxDialog";
import React, { useEffect, useState } from "react";
import { useStore } from "utils/useStore";
import { Suggestion } from "../Suggestions/Suggestion";
import AcxButton from "components/UI/AcxButton";
import { ApplicationFilterValuesStore } from "stores/ApplicationFilters/ApplicationFilterValuesStore";
import { observer } from "mobx-react";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import Classifier from "models/ClassifierModel";

export const SelectClassifierAction = observer(({args}) => {
    const store = useStore(AgentChatStore);
    const valuesStore = useStore(ApplicationFilterValuesStore);
    const [classifier, setClassifier] = useState<Classifier | undefined>();
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (!dialogOpen) return;
        if (!!valuesStore.userClassifiers) return;
        valuesStore.loadUserClassifiers();
    }, [dialogOpen, valuesStore]);

    return (
        <React.Fragment>
            <AcxDialog
                isOpen={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                title="Classifiers"
                maxWidth="lg"
                dialogContentChildren={
                    <AcxSelectSingle
                        id="ava-select-classifier"
                        options={valuesStore.userClassifiers ?? []}
                        labelField="name"
                        valueField="id"
                        containerHeight="auto"
                        minWidth="24rem"
                        onChange={(c) => setClassifier(c)}
                    />
                }
            >
                <AcxButton
                    fullWidth={false}
                    onClick={() => {
                        if (!classifier) return;
                        store.streamingSubmitInput({
                            userMessage: classifier.name,
                            manualInput: `name: ${classifier.name}\nid: ${classifier.id}`,
                        });
                    }}
                    disabled={!classifier}
                >
                    Use selected classifier
                </AcxButton>
            </AcxDialog>
            <Grid item>
                <Suggestion onClick={() => setDialogOpen((open) => !open)}>
                    {args?.buttonText || "Select Classifier"}
                </Suggestion>
            </Grid>
        </React.Fragment>
    );
});
