import React, { useState } from "react";
import ConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxToggleButton from "components/UI/AcxToggleButton";
import { ReportEditorStore } from "components/Reports/Stores/ReportEditorStore";
import { useStore } from "utils/useStore";
import { observer } from "mobx-react";
import { AccessLevel, ReportGroup } from "models/Reporting/ReportGroup";
import { Grid } from "@mui/material";

const options: any[] = [
    { value: "user", element: "Only visible to me" },
    { value: "org", element: "Available to my organization" },
];

const CreateGroup = observer(() => {
    const store = useStore(ReportEditorStore);
    const [level, setLevel] = useState<AccessLevel>(AccessLevel.User);
    const [isSaving, setIsSaving] = useState(false);

    const onClose = () => {
        store.createGroupOpen = false;
    };
    const onChange = (item) => {
        let k = item;

        if (k === "user") {
            setLevel(AccessLevel.User);
        } else if (k === "org") {
            setLevel(AccessLevel.Organization);
        }
    };
    const getDefault = (lvl) => {
        if (lvl === AccessLevel.User) {
            return options[0].value;
        } else {
            return options[1].value;
        }
    };

    const saveGroup = async () => {
        setIsSaving(true);
        const grp = new ReportGroup();
        grp.accessLevel = level ?? AccessLevel.User;
        grp.organizationId = store.organizationId;
        grp.groupName = store.newGroupName;
        grp.createdBy = store.user?.profile.email;

        await store.saveNewGroup(grp);
        setIsSaving(false);
        store.createGroupOpen = false;
    };
    return (
        <ConfirmationDialog
            onClose={onClose}
            isOpen={store.createGroupOpen}
            isLoading={isSaving}
            content={
                <Grid container item justifyContent="center">
                    <AcxToggleButton
                        id="create-group-publish"
                        items={options}
                        exclusive
                        defaultValue={getDefault(level)}
                        onChange={onChange}
                        style={{ display: "inline-block" }}
                    />
                </Grid>
            }
            onConfirm={saveGroup}
        />
    );
});

export default CreateGroup;
