import AcxDataGridStore from "components/UI/AcxDataGrid/AcxDataGridStore";
import {
    action,
    computed,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import MontageModel from "models/MontageModel";
import SoundClip from "models/SoundClip";
import moment from "moment";
import { MontageService } from "services/MontageService";
import { BaseStore } from "stores/BaseStore";
import { DatePickerComponentStore } from "stores/ComponentStores/DatePickerComponentStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { symmetricDifferenceBy } from "utils/SetAlgebraUtils";
import AudioContextProvider from "../../../providers/AudioContextProvider";
import MessageStore from "../../ManagerInteractions/Stores/MessageStore";
import { MontageSoundClipStore } from "./MontageSoundClipStore";

@AcxStore
export class MontageListStore extends BaseStore {
    dialogStore = new DialogComponentStore();
    @observable currentSoundClip: SoundClip;
    currentBlob: Blob;
    ac = new AudioContextProvider();

    readonly dgStore: AcxDataGridStore;
    readonly datePickerStore = new DatePickerComponentStore();

    @observable searchString = "";

    @observable montages: MontageModel[] = [];

    _montageService = new MontageService();

    _soundClipStore: MontageSoundClipStore;

    constructor(public rootStore: IRootStore) {
        super("MontageListStore");
        makeObservable(this);
        this._soundClipStore = rootStore.getStore(MontageSoundClipStore);
        this.dgStore = new AcxDataGridStore(
            "MontageListDataStoreGrid",
            "Montage",
        );
        this.dgStore.checkboxSelection = true;

        reaction(
            () => {
                return {
                    orgid: this.orgId,
                    beginDate: this.datePickerStore.getOffsetBeginDate(),
                    endDate: this.datePickerStore.getOffsetEndDate(),
                    activeLocation: this.rootStore.activeLocation,
                };
            },
            async (params) => {
                if (
                    params.activeLocation &&
                    params.activeLocation.location !== "/app/montagelibrary"
                ) {
                    return;
                }

                await this.getAllMontages(
                    params.beginDate,
                    params.endDate,
                );
            },
        );

        reaction(
            (r) => this.anyTaskLoading,
            (arg) => {
                this.dgStore.setLoading(arg);
            },
        );

        reaction(
            (r) => this.nextTaskError,
            (arg) => {
                if (arg?.message) {
                    rootStore.getStore(MessageStore).logError(arg.message);
                    this.clearLastTaskError();
                }
            },
        );
    }

    @action
    fetchCurrentMontageAudio = async (id) => {
        // @ts-ignore
        delete this.currentBlob;
        const b = await this._montageService.downloadMontage(id);
        this.currentBlob = b;
        const buffer = await b.arrayBuffer();
        return buffer;
    };

    @action
    loadCurrentSoundClip = async (montage: MontageModel) => {
        const dir = await this._montageService.getDirectory(
            montage.directoryId,
        );

        const sc = new SoundClip();
        const url = `https://${dir.account}.blob.core.windows.net/${
            dir.thisPath + montage.fileName
        }`;
        sc.filePath = url;
        sc.id = montage.id;
        sc.segmentName = montage.name;
        sc.directoryId = montage.directoryId;
        sc.fileName = montage.fileName;
        sc.startTime = 0;
        this.currentSoundClip = sc;
    };

    @computed get orgId() {
        return this._soundClipStore.orgId;
    }

    @computed get filteredMontages() {
        const result: MontageModel[] = [];
        for (const montage of this.montages) {
            const searchString = this.searchString.toLowerCase();
            const filePath = montage.directory.thisPath + montage.fileName;
            if (
                filePath.toLowerCase().includes(searchString) ||
                montage.name.toLowerCase().includes(searchString)
            ) {
                result.push(montage);
            }
        }
        return result;
    }

    @action setSearchString(newValue: string) {
        this.searchString = newValue;
    }

    @action download = () => {
        this.ac.download(
            this.currentBlob,
            (this.currentSoundClip.segmentName ?? "MontageFile") + ".mp3",
            false,
        );

        this.dialogStore.close();
    };

    @action
    batchDownload = () => {
        if (this.dgStore.selectedRows.length > 0) {
            this.dgStore.selectedRows.forEach(async (media) => {
                const blob = await this._montageService.downloadMontage(
                    `${media.id}`,
                );
                this.ac.download(
                    blob,
                    (media.name ?? "MontageFile") + ".mp3",
                    false,
                );
            });
        }
    };

    @action
    editMontage = async () => {
        const montage = this.dgStore.selectedRows[0] as MontageModel;
        const mscStore = this.rootStore.getStore(MontageSoundClipStore);
        mscStore.resetMontage();
        mscStore.clearAudioBufferAndToneSets();

        const montageSoundClips =
            await this._montageService.getMontageSoundClips(montage.id);
        // const currAr =
        // montage.montageSoundClips
        const currAr =
            montageSoundClips
                ?.slice()
                ?.sort((a, b) => a.clipOrder - b.clipOrder)
                ?.map((value) => value.soundClip) ?? [];

        if (mscStore.soundClipDgStore.rows?.length > 0) {
            const [newlyAdded] = symmetricDifferenceBy(
                mscStore.soundClipDgStore.rows,
                currAr,
                (arg) => arg.id,
                (arg2) => arg2.id,
            );
            mscStore.soundClipDgStore.rows.push(
                ...currAr.filter((val) => newlyAdded.has(val.id)),
            );
        } else {
            mscStore.soundClipDgStore.rows.push(...currAr);
        }

        mscStore.soundClipDgStore.handleSelectionChange(
            currAr.map((val) => val.id),
        );
        montageSoundClips.forEach((value) => {
            const tonebreaks = value.toneBreak?.[0];

            if (tonebreaks?.includes(",")) {
                tonebreaks?.split(",")?.forEach((value1) => {
                    if (value1) {
                        mscStore.insertTone(value.soundClipId, value1 as any);
                    }
                });
            } else {
                value.toneBreak?.forEach((value1) => {
                    if (value1) {
                        mscStore.insertTone(value.soundClipId, value1 as any);
                    }
                });
            }
        });

        mscStore.onTabChange(1);
    };

    @action
    async getAllMontages(
        beginDate?: moment.Moment,
        endDate?: moment.Moment,
        newMontageId?: string,
    ): Promise<void> {
        beginDate = beginDate ?? this.datePickerStore.offsetBeginDate;
        endDate = endDate ?? this.datePickerStore.offsetEndDate;

        this.setupAsyncTask("Load Montages", async () => {
            const montageResults = await this._montageService.listMontages(
                beginDate,
                endDate,
            );

            runInAction(() => {
                this.montages = montageResults;
                this.dgStore.rows = montageResults;
                if (newMontageId) {
                    this.dgStore.handleSelectionChange([newMontageId]);
                }
            });
        });
    }

    @action
    handlePlayerClick = async () => {
        await this.loadCurrentSoundClip(
            this.dgStore.selectedRows[0] as MontageModel,
        );

        this.dialogStore.open();
    };

    @action
    deleteMontage = () => {
        this.setupAsyncTask("Delete Montage", async () => {
            const montageIds =
                this.dgStore.selectedRows.map((montage) => montage.id) ??
                ([] as MontageModel[]);
            await this._montageService.deleteMontages(montageIds);

            await this.getAllMontages(
                this.datePickerStore.offsetBeginDate,
                this.datePickerStore.offsetEndDate,
            );
            this.dgStore.removeAllSelectedRows();
        });
    };

    @computed
    get singleClickEnabled() {
        return this.dgStore.selectedRows.length === 1;
    }

    @computed
    get multiClickEnabled() {
        return this.dgStore.selectedRows.length > 0;
    }
}
