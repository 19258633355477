import React from "react";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import logo from "../../../authcx-spinner.svg";

interface IAcxLogoLoader {
    height: number | string;
    isSpinning: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    // "@keyframes spinning": {
    //     from: { transform: "rotate(0deg)" },
    //     to: { transform: "rotate(360deg)" }
    // },
    "@keyframes blinking": {
        "50%": {
            opacity: "0",
        },
    },
    spinner: {
        animationName: "$blinking",
        // animationName: "$spinning",
        animationDuration: "2s",
        animationIterationCount: (props: IAcxLogoLoader) =>
            props.isSpinning ? "infinite" : "0",
        animationTimingFunction: "linear",
        height: (props: IAcxLogoLoader) => props.height,
    },
}));

export default function AcxLogoLoader(props: IAcxLogoLoader) {
    const classes = useStyles(props);
    return <img className={classes.spinner} alt="" src={logo}></img>;
}
