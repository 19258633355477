import { TableBody, TableCell, TableRow, Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { Skeleton } from '@mui/material';
import clsx from "clsx";
import Color from "color";
import { Observer } from "mobx-react";
import React, { useCallback } from "react";
import { Waypoint } from "react-waypoint";
import useStyles from "Styles/Styles";
import AcxTableEmptyRow from "./AcxTableEmptyRow";
import AcxTableRow from "./AcxTableRow";
import AcxTableViewModel from "./AcxTableViewModel";

const styles = (theme: Theme) =>
    createStyles({
        cellDense: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            fontSize: "13px",
        },
        cellMedium: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            fontSize: "13px",
        },
        row: {
            "&:hover": {
                backgroundColor:
                    Color(theme.palette.secondary.main)
                        .fade(0.9)
                        .hsl()
                        .string() + " !important",
            },
        },
        rowStripe: {
            "&:nth-of-type(even)": {
                backgroundColor: Color(theme.palette.gray.main)
                    .fade(0.9)
                    .hsl()
                    .string(),
            },
        },
    });

interface Props {
    viewMod: AcxTableViewModel;
}

function AcxTableBody(props: Props) {
    const classes = useStyles(styles);

    const cellClass = useCallback(() => {
        return clsx({
            [classes.cellDense]: props.viewMod.enableDense,
            [classes.cellMedium]: !props.viewMod.enableDense,
        });
    }, [classes.cellDense, classes.cellMedium, props.viewMod.enableDense]);

    const rowClass = useCallback(() => {
        return clsx({
            [classes.row]: props.viewMod.enableHover,
            [classes.rowStripe]: props.viewMod.enableStripes,
        });
    }, [
        classes.row,
        classes.rowStripe,
        props.viewMod.enableHover,
        props.viewMod.enableStripes,
    ]);

    const onSelectChange = useCallback(
        (selected: boolean, key: string) => {
            props.viewMod.selectionChange(selected, key);
        },
        [props.viewMod],
    );

    const isSelected = useCallback(
        (key: string) => {
            return (
                props.viewMod.selectedItems.findIndex((k) => k === key) !== -1
            );
        },
        [props.viewMod.selectedItems],
    );

    const isDisabled = useCallback(
        (key: string) => {
            return (
                props.viewMod.disabledItems.findIndex((k) => k === key) !== -1
            );
        },
        [props.viewMod.disabledItems],
    );

    return (
        <Observer>
            {() => (
                <TableBody>
                    {props.viewMod.isLoading && props.viewMod.skeletonRows ? (
                        <>
                            {Array.from(Array(props.viewMod.skeletonRows)).map(
                                (row, rowI) => (
                                    <TableRow key={`row_${rowI}`}>
                                        {props.viewMod.tableCols.map(
                                            (col, colI) => (
                                                <TableCell
                                                    key={`row_${rowI}_col_${colI}`}
                                                >
                                                    <Skeleton
                                                        style={{
                                                            maxWidth: "none",
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </Skeleton>
                                                </TableCell>
                                            ),
                                        )}
                                    </TableRow>
                                ),
                            )}
                        </>
                    ) : (
                        <>
                            {props.viewMod.renderRows &&
                            props.viewMod.renderRows.length === 0 ? (
                                <AcxTableEmptyRow
                                    columns={props.viewMod.tableCols}
                                    enableCheck={props.viewMod.enableCheck}
                                    rowClass={props.viewMod.rowClass}
                                />
                            ) : (
                                props.viewMod.renderRows.map((row, rowNum) => {
                                    if (
                                        rowNum >=
                                            props.viewMod._virtualBeginRow &&
                                        rowNum <= props.viewMod._virtualEndRow
                                    ) {
                                        return (
                                            <AcxTableRow
                                                key={`${props.viewMod.id}-table_row_${rowNum}`}
                                                row={row}
                                                columns={
                                                    props.viewMod.tableCols
                                                }
                                                r={rowNum}
                                                enableCheck={
                                                    props.viewMod.enableCheck
                                                }
                                                onSelectChange={onSelectChange}
                                                keyField={
                                                    props.viewMod.keyField
                                                }
                                                selected={isSelected(
                                                    row[props.viewMod.keyField],
                                                )}
                                                disabled={isDisabled(
                                                    row[props.viewMod.keyField],
                                                )}
                                                cellClass={cellClass()}
                                                rowClass={rowClass()}
                                                onShiftClick={
                                                    props.viewMod
                                                        .onRowShiftClick
                                                }
                                                waypoint={
                                                    props.viewMod.shouldRenderWaypoint(
                                                        rowNum,
                                                    ) ? (
                                                        <Waypoint
                                                            key={`${props.viewMod.id}-${rowNum}`}
                                                            onPositionChange={
                                                                props.viewMod
                                                                    .onWaypointEnter
                                                            }
                                                        />
                                                    ) : undefined
                                                }
                                            />
                                        );
                                    } else {
                                        // return <React.Fragment key={`${props.viewMod.id}-table_row_${rowNum}`}></React.Fragment>;
                                        return null;
                                    }
                                })
                            )}
                        </>
                    )}
                </TableBody>
            )}
        </Observer>
    );
}

export default AcxTableBody;
