import { Radio, styled } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { IAcxTextFieldProps } from "./AcxMainTextFieldGrid";
import AcxCalendarPopup from "./Calendar/AcxCalendarPopup";
import moment from "moment";
import theme from "Theme/AppTheme";

const ControlContainer = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "1fr 3fr 3fr",
    alignItems: "center",
    margin: "0 0 0.5rem 0",
    padding: "0px",
    gap: "0.5rem",
}));

const Label = styled("label")(({ theme }) => ({
    userSelect: "none",
    fontSize: "12px",
    lineHeight: "16px",
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold as any,
}));

const ItemContainer = styled("section")(({ theme }) => ({
    display: "flex",
    gap: "0.25rem",
    alignItems: "center",
}));

interface IAcxLabeledDateToggle
    extends Omit<IAcxTextFieldProps, "value"> {
    readonly fieldIdentifierLabel?: string;
    setRequired?: boolean;
    readonly fieldName: string;
    firstValue: moment.Moment | string;
    secondValue: moment.Moment | string;
    onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

const AcxLabeledDateToggle = (props: IAcxLabeledDateToggle) => {
    const [checked, setChecked] = useState("");
    const [valueOne, setValueOne] = useState(props.firstValue);
    const [valueTwo, setValueTwo] = useState(props.secondValue);

    const [firstDateId, secondDateId] = useMemo(
        () => [
            `firstDateField_${props.fieldName}`,
            `secondDateField_${props.fieldName}`,
        ],
        [props.fieldName],
    );
    const [firstRadioId, secondRadioId] = useMemo(
        () => [
            `firstRadioField_${props.fieldName}`,
            `secondRadioField_${props.fieldName}`,
        ],
        [props.fieldName],
    );

    useEffect(() => {
        if (props.firstValue) {
            setChecked(firstRadioId);
            props.onChange?.({ target: { value: props.firstValue } } as any);
        } else if (!props.firstValue && props.secondValue) {
            setChecked(secondRadioId);
            props.onChange?.({ target: { value: props.secondValue } } as any);
        } else {
            setChecked(firstRadioId);
            props.onChange?.({ target: { value: props.firstValue } } as any);
        }
    }, [props, firstRadioId, secondRadioId]);

    const handleRadioUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event);
        }
        setChecked(event.target.id);
    };

    return (
        <ControlContainer>
            <Label title={props.fieldIdentifierLabel}>
                {props.fieldIdentifierLabel}
            </Label>

            <ItemContainer>
                <Radio
                    key={firstRadioId}
                    id={firstRadioId}
                    checked={checked === firstRadioId ? true : false}
                    onChange={handleRadioUpdate}
                    name={props.fieldName}
                    value={valueOne ? valueOne : undefined}
                    title={`Select ${valueOne}.`}
                />

                <AcxCalendarPopup
                    id={firstDateId}
                    currentDate={valueOne ? moment(valueOne) : undefined}
                    onSelect={(selectedDate) => setValueOne(selectedDate)}
                    containerStyle={{ width: "100%", pointerEvents: "none" }}
                    inputStyle={{
                        borderColor: theme.palette.lightgrayBorder.main,
                        borderTopLeftRadius: "3px",
                        borderBottomLeftRadius: "3px",
                    }}
                />
            </ItemContainer>

            <ItemContainer>
                <Radio
                    key={secondRadioId}
                    id={secondRadioId}
                    checked={checked === secondRadioId ? true : false}
                    onChange={handleRadioUpdate}
                    name={props.fieldName}
                    value={valueTwo ? valueTwo : ""}
                    title={`Select ${valueTwo}.`}
                />

                <AcxCalendarPopup
                    id={secondDateId}
                    currentDate={valueTwo ? moment(valueTwo) : undefined}
                    onSelect={(selectedDate) => setValueTwo(selectedDate)}
                    containerStyle={{ width: "100%", pointerEvents: "none" }}
                    inputStyle={{
                        borderColor: theme.palette.lightgrayBorder.main,
                        borderTopLeftRadius: "3px",
                        borderBottomLeftRadius: "3px",
                    }}
                />
            </ItemContainer>
        </ControlContainer>
    );
};

export default AcxLabeledDateToggle;
