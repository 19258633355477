import { Action } from "../../models/Actions/Action";
import { EvalReviewActionMetadata } from "../../models/Actions/Evaluation/EvalReviewActionMetadata";
import BaseService from "../BaseService";

export class EvaluationActionService extends BaseService {
    protected urlBase: string = "api/EvalActions";

    async createRequestReviewAction(
        organizationId: string,
        evaluationId: string,
        parentActionId: string | undefined,
        recipientIds: string[],
        metadata: EvalReviewActionMetadata,
    ) {
        const params = new URLSearchParams({ organizationId, evaluationId });
        if (parentActionId) {
            params.set("parentActionId", parentActionId);
        }
        for (let recipientId of recipientIds) {
            params.append("recipientIds", recipientId);
        }

        const res = await this.post<Action<EvalReviewActionMetadata>>(
            JSON.stringify(metadata),
            params,
            "RequestEvalReviewAction",
        );
        return res;
    }

    async createCompleteReviewAction(
        organizationId: string,
        evaluationId: string,
        parentActionId: string,
        recipientId: string,
        metadata: EvalReviewActionMetadata,
    ) {
        const params = new URLSearchParams({
            organizationId,
            evaluationId,
            parentActionId,
            recipientId,
        });

        const res = await this.post<Action<EvalReviewActionMetadata>>(
            JSON.stringify(metadata),
            params,
            "CompleteEvalReviewAction",
        );
        return res;
    }

    async refreshActionThread(organizationId: string, evaluationId: string) {
        const params = new URLSearchParams({ evaluationId });

        const res = await this.post<Action<EvalReviewActionMetadata>>(
            JSON.stringify({ organizationId }),
            params,
            "RefreshActionThread",
        );
        return res;
    }

    async retrieveAllActionThreads(
        organizationId: string,
        evaluationId: string,
    ) {
        const params = new URLSearchParams({ evaluationId });

        const res = await this.post<Action<EvalReviewActionMetadata>[]>(
            JSON.stringify({ organizationId }),
            params,
            "AllActionThreads",
        );
        return res;
    }

    async replyToCompletedReviewAction(
        organizationId: string,
        evaluationId: string,
        parentActionId: string,
        recipientId: string,
        metadata: EvalReviewActionMetadata,
    ) {
        const params = new URLSearchParams({
            organizationId,
            evaluationId,
            parentActionId,
            recipientId,
        });

        const res = await this.post<Action<EvalReviewActionMetadata>>(
            JSON.stringify(metadata),
            params,
            "ResponseComment",
        );
        return res;
    }
}
