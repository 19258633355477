import { useEffect, useState } from "react";
import UserService from "../OrganizationDetail/OrganizationUsers/User.service";
import { ApplicationUser } from "models/Permission/ApplicationUser";

const useAllUserNames = (orgId: string) => {
    const [users, setUserNames] = useState<ApplicationUser[] | null>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!orgId) {
            return;
        }
        let destroyed = false;
        const runFn = async () => {
            try {
                setIsLoading(true);
                const res = await UserService.getUserNames(orgId);
                if (!destroyed) {
                    setUserNames(res);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };
        runFn();
        return () => {
            destroyed = true;
            setUserNames(null);
        };
    }, [orgId]);

    return {
        users,
        isLoading,
    };
};

export default useAllUserNames;
