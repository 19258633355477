export default interface IntegrationConfiguration {
    id?: string;
    organizationId?: string;
    url?: string;
    apiKey?: string;
    apiSecret?: string;
    type?: IntegrationType;
    lastUpdate?: string | null;
    isActive?: boolean;
    configuration: {
        minimumCallLengthInSeconds: number | null;
        maximumCallLengthInSeconds: number | null;
        noCallsPriorToDateTimeUtc?: string;
        customConfiguration?: {
            queueIds: string[];
            agentGroupingOptions: AgentGroupingOptions;
        };
    } | null;
}

export enum IntegrationType {
    NiceIn = "NiceIn",
    GenesysCloud = "GenesysCloud",
    SalesForce = "SalesForce",
}

export enum AgentGroupingOptions {
    "Group Agent Segments By Agent" = 1,
    "Separate Agent Segments When Transferred" = 2,
}
