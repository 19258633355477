// import {Badge} from "@mui/material";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import NavigationStore, {
    INavigation,
} from "Layouts/SideNavigation/NavigationStore";
import {
    action,
    makeObservable,
    observable,
    reaction,
    runInAction,
} from "mobx";
import React from "react";
import { OrganizationService } from "services/OrganizationService";
import { AuthStore } from "stores/AuthStore";
import type { IRootStore } from "stores/RootStore";
import { AcxStore } from "stores/RootStore";
import hexToRGB from "utils/hexToRGB";
import { BaseStore } from "../../../stores/BaseStore";
import theme from "../../../Theme/AppTheme";
import ManagerInteractionsStore from "./ManagerInteractionsStore";

export enum AnalystManagerPages {
    DASHBOARD = "Dashboard",
    ASSIGNED = "AssignedInteractions",
    UNASSIGNED = "UnassignedInteractions",
    SAMPLING = "On-DemandSample",
}

export function urlComponentToPageEnum(component: string) {
    if (component === "Dashboard") {
        return AnalystManagerPages.DASHBOARD;
    } else if (component === "AssignedInteractions") {
        return AnalystManagerPages.ASSIGNED;
    } else if (component === "UnassignedInteractions") {
        return AnalystManagerPages.UNASSIGNED;
    } else if (component === "On-DemandSample") {
        return AnalystManagerPages.SAMPLING;
    } else {
        return AnalystManagerPages.DASHBOARD;
    }
}

@AcxStore
export class AnalystManagerNavigationStore extends BaseStore {
    private readonly interactionStore: ManagerInteractionsStore;
    private readonly authStore: AuthStore;
    private readonly orgService: OrganizationService;

    public readonly navStore: NavigationStore;
    public readonly AnalystManagerRoutePrefix = "/app/interactions/";

    @observable
    navItems: INavigation[];

    @observable
    currentPage: AnalystManagerPages;

    @observable
    displayOnDemand: boolean = false;

    constructor(private rootStore: IRootStore) {
        super("Analyst Manager Navigation Store");
        makeObservable(this);

        this.interactionStore = this.rootStore.getStore(
            ManagerInteractionsStore,
        );

        this.authStore = this.rootStore.getStore(AuthStore);

        this.orgService = new OrganizationService();

        this.navStore = new NavigationStore(
            this.rootStore,
            "AnalystManager",
            this.navItems,
            this.interactionStore.orgSelectStore,
        );

        // Handle Presentation of On-Demand Sample
        reaction(
            () => this.authStore.orgStore.selectedOrganization?.id,
            (orgId) => {
                if (orgId) {
                    const getOption = async () => {
                        try {
                            const option =
                                await this.orgService.getPipelineProcessingOption(
                                    orgId,
                                );

                            const onDemandEnums =
                                await this.orgService.getPipelineProcessingOptions();

                            runInAction(() => {
                                if (
                                    option &&
                                    onDemandEnums[option.processType] ===
                                        "OnDemand"
                                ) {
                                    this.displayOnDemand = true;
                                } else {
                                    this.displayOnDemand = false;
                                }
                            });
                        } catch (error) {
                            runInAction(() => {
                                this.displayOnDemand = false;
                            });
                        }
                    };

                    getOption();
                }
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                numUnassigned:
                    this.interactionStore.unassignedDgStore.rows.length,
                displayOnDemand: this.displayOnDemand,
            }),
            ({ numUnassigned, displayOnDemand }) => {
                const items: INavigation[] = [
                    {
                        title: "Assigned Interactions",
                        link:
                            this.AnalystManagerRoutePrefix +
                            AnalystManagerPages.ASSIGNED,
                        icon: (
                            <AssignmentTurnedInIcon
                                htmlColor={hexToRGB(
                                    theme.palette.black.main,
                                    0.8,
                                )}
                            />
                        ),
                        selectedIcon: (
                            <AssignmentTurnedInIcon color="primary" />
                        ),
                        defaultRoute: true,
                    },
                ];

                if (this.authStore.canUserEdit("Interactions")) {
                    items.push({
                        title: "Unassigned Interactions",
                        badgeContent: numUnassigned.toString(),
                        link:
                            this.AnalystManagerRoutePrefix +
                            AnalystManagerPages.UNASSIGNED,
                        icon: (
                            <ViewAgendaIcon
                                htmlColor={hexToRGB(
                                    theme.palette.black.main,
                                    0.8,
                                )}
                            />
                        ),
                        selectedIcon: <ViewAgendaIcon color="primary" />,
                    });

                    if (displayOnDemand) {
                        items.push({
                            title: "On-Demand Sample",
                            link:
                                this.AnalystManagerRoutePrefix +
                                AnalystManagerPages.SAMPLING,
                            icon: (
                                <UnarchiveIcon
                                    htmlColor={hexToRGB(
                                        theme.palette.black.main,
                                        0.8,
                                    )}
                                />
                            ),
                            selectedIcon: <UnarchiveIcon color="secondary" />,
                        });
                    }
                }

                this.navStore.setNavItems(items);
            },
            { fireImmediately: true },
        );
    }

    @action
    setCurrentPage(page: AnalystManagerPages) {
        this.currentPage = page;
    }
}
