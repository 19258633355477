import { Button, Grid, Theme, Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import SearchIcon from "@mui/icons-material/Search";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import Navigation from "Layouts/SideNavigation/Navigation";
import { observer } from "mobx-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthStore } from "stores/AuthStore";
import useStyles from "Styles/Styles";
import hexToRGB from "utils/hexToRGB";
import { useStore } from "utils/useStore";
import ReportView from "./ReportView";
import ReportsStore from "./Stores/ReportsStore";
// import AspectRatioIcon from '@mui/icons-material/AspectRatio';

const styles = (theme: Theme) =>
    createStyles({
        reportNavigationBody: {
            // marginLeft: "20px"
            paddingRight: theme.spacing(0.5),
            paddingLeft: theme.spacing(1.75),
        },
        reportNavigationRoot: {
            // margin: theme.spacing(2)
            paddingRight: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.5),
        },
        reportLinkContainer: {
            paddingLeft: "6px",
            marginLeft: "16px",
        },
        curReportLinkContainer: {
            borderRadius: "3px",
            backgroundColor: hexToRGB("#3564D5", 0.1),
        },
        curReportLink: {
            color: "#3564D5",
            fontWeight: "bold",
            textAlign: "left",
        },
        groupHeader: {
            fontSize: "16px",
            fontFamily: "Inter",
            fontWeight: "bold",
            color: theme.palette.black.main,
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
        },
        drawerHeader: {
            // marginLeft: "16px", marginTop: "16px", marginRight: "16px"
            marginBottom: theme.spacing(2),
            paddingRight: theme.spacing(1.35),
            paddingLeft: theme.spacing(1.35),
        },
        orgSelect: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        searchField: {
            marginTop: theme.spacing(1),
        },
        searchIconColor: {
            color: "#1F1F1F",
            opacity: 0.25,
            marginRight: theme.spacing(1),
        },
        editorButtons: {
            textTransform: "none",
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightBold as any,
        },
        root: {
            height: "100%",
            paddingTop: theme.spacing(1.75),
        },
    });

export const ReportsNavigation = observer(() => {
    const store = useStore(ReportsStore);
    const navigate = useNavigate();
    const classes = useStyles(styles);
    const orgStore = useStore(AuthStore).orgStore;

    React.useEffect(() => {
        if (orgStore && orgStore.selectedOrganization) {
            store.orgSelectorStore.selectOrg(orgStore.selectedOrganization);
        }
    }, [orgStore, orgStore?.selectedOrganization, store]);

    function onCreateNewReport() {
        store.initializeReportEditorForNew();

        if (orgStore.selectedOrganization?.id) {
            navigate(
                `/focus/reporteditor/${orgStore.selectedOrganization.id}/`,
            );
        }
    }

    return (
        <Navigation
            isLoading={store.getTaskLoading("Load Reports")}
            store={store.navStore}
            controls={
                <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems={"baseline"}
                    className={classes.drawerHeader}
                >
                    <Grid item>
                        <Typography variant="h1">All Reports</Typography>
                    </Grid>

                    <Grid item>
                        <Button
                            className={classes.editorButtons}
                            variant={"contained"}
                            color={"secondary"}
                            size={"small"}
                            onClick={onCreateNewReport}
                            disabled={!store.canEditReports}
                            disableElevation
                        >
                            Create New
                        </Button>
                    </Grid>

                    <Grid item xs={12} className={classes.searchField}>
                        <AcxMainTextField
                            placeholderText="Search"
                            onChange={(evt) =>
                                (store.searchReportList =
                                    evt.currentTarget.value)
                            }
                            containerClass={classes.drawerHeader}
                            id="report-list-search-box"
                            startAdornment={
                                <SearchIcon
                                    className={classes.searchIconColor}
                                />
                            }
                            value={store.searchReportList}
                        />
                    </Grid>
                </Grid>
            }
            itemOptions={{ linkFontSize: "13px" }}
        />
    );
});

export const ReportsRoutes = observer(() => {
    const location = useLocation();
    const store = useStore(ReportsStore);

    React.useEffect(() => {
        const getOrgIdLocation = () => {
            const pathArray = location.pathname.split("/");
            const groupIndex =
                pathArray.findIndex((value) => value === "reports") + 1;
            return pathArray[groupIndex];
        };
        const getReportId = () => {
            const pathArray = location.pathname.split("/");
            const groupIndex =
                pathArray.findIndex((value) => value === "reports") + 2;
            return pathArray[groupIndex];
        };

        const orgId = getOrgIdLocation();
        const reportId = getReportId();
        const curRpt = store.reports.find(
            (r) => r.report?.organizationId === orgId && r.id === reportId,
        );

        // console.log("running the pathname effect in report nav");
        if (curRpt === undefined) {
            store.currentReport = null;
            store.clearDrillDownState();
            store.prepareReportSwitch();
        }

        if (curRpt !== undefined && curRpt.id !== store.currentReport?.id) {
            store.clearDrillDownState();
            store.prepareReportSwitch();

            store.currentReport = curRpt;
        }

        store.currentGroup = orgId;
    }, [
        location.pathname,
        location,
        store.reports,
        store.navStore.currentNavItem,
        store.currentReport,
        store,
        store.orgStore.selectedOrganization,
    ]);

    return <ReportView hideTitle={true} />;
});
