import { Grid, IconButton, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import clsx from "clsx";
import { observer } from "mobx-react";
import React from "react";
import { BeatLoader } from "react-spinners";
import { LayoutDrawerStore } from "../../../stores/Layout/LayoutDrawerStore";
import theme from "../../../Theme/AppTheme";
import { isStringType } from "../../../utils/TypeGuards";
import { useStore } from "../../../utils/useStore";
import DoubleChevronSvg from "SvgIcons/DoubleChevronSvg";

const useStyles = makeStyles((theme: Theme) => ({
    root: (props: Props) => ({
        width: props.width ?? "500px",
        height: "100%",
        marginTop: props.customMarginTop ?? "",
        display: "flex",
        flexDirection: "column",
    }),
    leftPadding: {
        padding: "0.25rem 0rem 0.25rem 1.25rem",
    },
    mainContent: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: "100%",
    },
}));

interface OwnProps {
    width?: string; // default width 500px
    title: React.ReactNode;
    subTitle?: React.ReactNode;
    titleIcon?: React.ReactNode;
    titleClass?: string;
    subtitleClass?: string;
    mainContentClass?: string;
    loading?: boolean;
    content: React.ReactNode;
    onClose?: () => void;
    anchor?: "left" | "right" | "top" | "bottom";
    contentStyles?: React.CSSProperties;
    titleStyles?: React.CSSProperties;
    titleTextStyle?: React.CSSProperties;
    subTitleStyles?: React.CSSProperties;
    subtitleTextStyle?: React.CSSProperties;
    keepContent?: boolean;
    customMarginTop?: string;
    wrapSubtitle?: boolean;
    arrowCloseIcon?: boolean;
    newDrawerHeaderStyle?: boolean; // Placeholder until we update the entire component
}

type Props = OwnProps;

const VerticalDrawerContentTemplate: React.FC<Props> = observer((props) => {
    const drawerStore = useStore(LayoutDrawerStore);
    const classes = useStyles({ ...props, anchor: drawerStore.anchor });

    function onClose() {
        drawerStore.closeAndResetDrawer(props.keepContent);
        props.onClose?.();
    }

    function titleElement(
        title: React.ReactNode,
        textStyle?: React.CSSProperties,
        subtitle?: boolean,
    ) {
        if (isStringType(title)) {
            return (
                <Typography
                    component={"div"}
                    className={
                        subtitle ? props.subtitleClass : props.titleClass
                    }
                    variant={subtitle ? "subtitle2" : "h1"}
                    noWrap={subtitle && props.wrapSubtitle ? false : true}
                    sx={textStyle ?? {}}
                >
                    {title}
                </Typography>
            );
        } else {
            return <>{title}</>;
        }
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                justifyContent={"flex-start"}
                borderBottom={`0.25px solid ${theme.palette.gray[300]}`}
            >
                <Grid item xs={12} container>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        wrap={"nowrap"}
                        className={props.titleIcon ? classes.leftPadding : ""}
                        style={
                            props.titleStyles ?? {
                                padding: !props.titleIcon
                                    ? "0.5rem 0.25rem 0.5rem 0.75rem"
                                    : "",
                            }
                        }
                    >
                        <Grid container item xs alignItems={"center"}>
                            {props.titleIcon && (
                                <Grid item>{props.titleIcon}</Grid>
                            )}

                            <Grid item xs zeroMinWidth>
                                {titleElement(
                                    props.title,
                                    props.titleTextStyle,
                                )}
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            style={{
                                alignContent: "center",
                                marginTop: props.newDrawerHeaderStyle ? "16px" : "0px",
                            }}
                        >
                            <IconButton
                                onClick={onClose}
                                size="large"
                                style={{ padding: 0, marginRight: "16px" }} // This is modifying all templates, double check this
                            >
                                {props.arrowCloseIcon ? (
                                    <DoubleChevronSvg />
                                ) : (
                                    <CloseIcon fontSize={"medium"} />
                                )}
                            </IconButton>
                        </Grid>
                    </Grid>

                    {Boolean(props.subTitle || props.loading) && (
                        <Grid
                            item
                            xs={12}
                            style={
                                props.subTitleStyles ?? {
                                    padding: "0.5rem 0.25rem 0.5rem 0.75rem",
                                }
                            }
                            zeroMinWidth
                        >
                            {titleElement(
                                props.subTitle,
                                props.subtitleTextStyle,
                                true,
                            )}

                            <>
                                {props.loading && (
                                    <BeatLoader
                                        size={6}
                                        color={theme.palette.secondary.main}
                                        loading={props.loading}
                                    />
                                )}
                            </>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <div
                className={clsx(classes.mainContent, props.mainContentClass)}
                style={
                    props.contentStyles ?? {
                        padding: "0.5rem 0.25rem 0.5rem 0.75rem",
                        height: "85%",
                    }
                }
            >
                {props.content}
            </div>
        </div>
    );
});

export default VerticalDrawerContentTemplate;
