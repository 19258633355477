import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import AcxMainTextField from "components/UI/AcxMainTextField";
import React from "react";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        serviceRowWrapper: {
            width: "100%",
            borderRadius: "4px",
            backgroundColor: "#f4f4f4",
            marginTop: "12px",
        },
        textfieldAndDeleteContainer: {
            display: "flex",
            alignItems: "center",
            lineHeight: "0px",
        },
        textFieldContainer: {
            paddingRight: "12px",
            flexGrow: 1,
        },
        deleteContainer: {
            paddingRight: "8px",
        },
    });

type ServiceSetupRowProps = {
    item: string;
    updateRow: (val: string) => void;
    removeRow: () => void;
    error?: string;
    disabled?: boolean;
};

const ServiceSetupRow: React.FC<React.PropsWithChildren<React.PropsWithChildren<ServiceSetupRowProps>>> = ({
    item,
    removeRow,
    updateRow,
    error,
    disabled,
}) => {
    const classes = useStyles(styles);

    return (
        <div className={classes.serviceRowWrapper}>
            <div style={{ padding: 8 }}>
                <div className={classes.textfieldAndDeleteContainer}>
                    <div className={classes.textFieldContainer}>
                        <AcxMainTextField
                            id="name"
                            value={item}
                            onChange={(e) => updateRow(e.target.value)}
                            error={!!error}
                            helperText={error}
                            isDisabled={disabled}
                            showAllErrors={true}
                        />
                    </div>
                    {!disabled && (
                        <div className={classes.deleteContainer}>
                            <DeleteIcon
                                htmlColor="#bebebe"
                                onClick={removeRow}
                                cursor="pointer"
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ServiceSetupRow;
