import { Grid, Link, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import React, { FunctionComponent, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { EvaluationStatus } from "../../../../models/Evaluation";
import {
    EvalActionNotificationDetails,
    EvaluationActionNotificationArg,
} from "../../../../stores/Notifications/Models/EvalActionNotification";
import hexToRGB from "../../../../utils/hexToRGB";
import AcxInputLabel from "../../../UI/AcxInputLabel";

const useStyles = makeStyles((theme: Theme) => ({
    timestampText: {
        fontSize: "12px",
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(0.35),
    },
    breakableText: {
        wordWrap: "break-word",
        whiteSpace: "pre-wrap",
        overflowWrap: "break-word",
        fontFamily: theme.typography.fontFamily,
        width: "100%",
    },

    padding: {
        // padding: theme.spacing(0.50),
    },
    section: {
        marginTop: theme.spacing(1),
        // padding: theme.spacing(0.50),
    },

    labelBox: {
        // backgroundColor: hexToRGB("#3564D5", 0.1)
        // opacity: "0.1",
        // padding: theme.spacing(0.50),
        // borderRadius: "4px",
        // backgroundColor: hexToRGB("#3564D5", 0.1)
    },
    interactionNameText: {
        color: "#3564D5",
    },
    labelText: {
        // fontFamily: "Inter",
        fontSize: "13px",
        fontWeight: "bold",
        letterSpacing: "0",
        lineHeight: "20px",
    },
    responseContainer: {
        paddingLeft: theme.spacing(0.6),
        paddingRight: theme.spacing(0.25),
        paddingTop: theme.spacing(0.7),
        paddingBottom: theme.spacing(0.7),

        backgroundColor: hexToRGB(theme.palette.gray.main, 0.12),
        ///* top-left | top-right | bottom-right | bottom-left */
        // border-radius: 1px 0 3px 4px;
        borderRadius: "0 10px 16px 5px",
    },
}));

interface OwnProps {
    actionNotification: EvaluationActionNotificationArg;
    appPath?: string;
    status: EvaluationStatus;
}

type Props = OwnProps;

const NoteTruncateLength = 233;

function ReviewCompleteDetails(props: {
    actionNotification: EvaluationActionNotificationArg;
    reviewDetails: EvalActionNotificationDetails[];
}) {
    const classes = useStyles();

    const reviewDetails = useMemo(
        () => props.reviewDetails.slice(0, 4),
        [props.reviewDetails],
    );

    return (
        <>
            {reviewDetails.map((value) => {
                return (
                    <Grid
                        key={`${value.fromUserName}-${value.notesLength}`}
                        item
                        xs={12}
                        className={clsx(classes.section, classes.padding)}
                    >
                        <AcxInputLabel
                            className={clsx(
                                classes.section,
                                classes.breakableText,
                            )}
                            labelText={
                                reviewDetails.length === 1
                                    ? `Response`
                                    : `${value.fromUserName} Response`
                            }
                        />
                        <div className={classes.responseContainer}>
                            <Typography
                                className={classes.breakableText}
                                color={"textPrimary"}
                            >
                                {value.actionNotes?.substr(
                                    0,
                                    NoteTruncateLength,
                                )}{" "}
                                {value.notesLength > NoteTruncateLength ? (
                                    <>...</>
                                ) : (
                                    <></>
                                )}
                            </Typography>
                            <Typography
                                className={clsx(
                                    classes.timestampText,
                                    classes.breakableText,
                                )}
                            >
                                {value.createdOn}
                            </Typography>
                        </div>
                    </Grid>
                );
            })}
        </>
    );
}

const EvalActionNotification: FunctionComponent<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (props) => {
    const classes = useStyles();

    if (
        props.actionNotification.highestPrecedenceActionType === "RequestReview"
    ) {
        const { notes, notesLength } =
            props.actionNotification.getNotesForRequestReview();
        return (
            <Grid container>
                <Grid item xs={12} className={classes.padding}>
                    <AcxInputLabel labelText={"Requested By"} />
                    <Typography
                        component={"div"}
                        variant={"subtitle2"}
                        color={"textPrimary"}
                    >
                        {props.actionNotification.getFromUserForRequestReview()}{" "}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={clsx(classes.section, classes.labelBox)}
                >
                    <AcxInputLabel labelText={"Interaction"} />
                    <Link
                        key={props.actionNotification.interactionName}
                        variant="body1"
                        component={props.appPath ? RouterLink : "button"}
                        to={`${props.appPath}`}
                        color="textPrimary"
                        style={{
                            textAlign: "left",
                            color: "#3564D5",
                            fontWeight: "bold",
                            pointerEvents: !props.appPath ? "none" : "auto",
                        }}
                    >
                        <Typography
                            className={clsx(
                                classes.interactionNameText,
                                classes.breakableText,
                            )}
                        >
                            {props.actionNotification.interactionName}
                        </Typography>
                    </Link>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={clsx(classes.section, classes.padding)}
                >
                    <AcxInputLabel labelText={"Reason"} />
                    <Typography color={"textPrimary"}>
                        {props.actionNotification.getReasonForRequestReview()}{" "}
                        Module
                    </Typography>

                    <AcxInputLabel
                        className={classes.section}
                        labelText={"Notes"}
                    />
                    <div className={classes.responseContainer}>
                        <Typography
                            className={classes.breakableText}
                            color={"textPrimary"}
                        >
                            {notes?.substr(0, NoteTruncateLength)}{" "}
                            {notesLength > NoteTruncateLength ? (
                                <>...</>
                            ) : (
                                <></>
                            )}
                        </Typography>
                        <Typography
                            className={clsx(
                                classes.timestampText,
                                classes.breakableText,
                            )}
                        >
                            {props.actionNotification.getCreatedOnForRequestReview()}{" "}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        );
    } else if (
        props.actionNotification.highestPrecedenceActionType ===
        "CompleteReview"
    ) {
        return (
            <Grid container>
                <Grid item xs={12} className={classes.padding}>
                    <AcxInputLabel
                        labelText={
                            props.actionNotification
                                .completedReviewActionsCnt === 1
                                ? `New Review Completed By`
                                : `${props.actionNotification.completedReviewActionsCnt} New Reviews Completed By`
                        }
                    />
                    <Typography
                        component={"div"}
                        variant={"subtitle2"}
                        color={"textPrimary"}
                    >
                        {props.actionNotification
                            .getFromUserForCompleteReview()
                            .join(", ")}{" "}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    className={clsx(classes.section, classes.labelBox)}
                >
                    <AcxInputLabel labelText={"Interaction"} />
                    <Link
                        key={props.actionNotification.interactionName}
                        variant="body1"
                        component={props.appPath ? RouterLink : "button"}
                        to={`${props.appPath}`}
                        color="textPrimary"
                        style={{
                            textAlign: "left",
                            color: "#3564D5",
                            fontWeight: "bold",
                            pointerEvents: !props.appPath ? "none" : "auto",
                        }}
                    >
                        <Typography
                            className={clsx(
                                classes.interactionNameText,
                                classes.breakableText,
                            )}
                        >
                            {props.actionNotification.interactionName}
                        </Typography>
                    </Link>
                </Grid>
                <ReviewCompleteDetails
                    actionNotification={props.actionNotification}
                    reviewDetails={props.actionNotification.details.filter(
                        (value) => value.actionType === "CompleteReview",
                    )}
                />
            </Grid>
        );
    } else if (props.actionNotification.reviewCommentActionsCnt > 0) {
        // let mainText = (<Typography component={"div"} variant={"subtitle2"} className={classes.breakableText}
        //                             color={"textPrimary"}>{props.actionNotification.fromUserName} commented
        //     on {props.actionNotification.initiatorUserName} review</Typography>);
        // if (props.actionNotification.recipientUserName === props.actionNotification.initiatorUserName) {
        //     mainText = (<Typography component={"div"} variant={"subtitle2"} className={classes.breakableText}
        //                             color={"textPrimary"}>{props.actionNotification.fromUserName} commented
        //         on your review</Typography>);
        // }
        //
        // return (<Grid container>
        //     <Grid item xs={12} className={classes.padding}>
        //         {mainText}
        //     </Grid>
        //     <Grid item xs={12} className={clsx(classes.section, classes.labelBox)}>
        //         <AcxInputLabel labelText={"Interaction"}/>
        //         <Link key={props.actionNotification.interactionName}
        //               variant="body1"
        //               component={props.appPath ? RouterLink : "button"}
        //               to={`${props.appPath}`}
        //               color="textPrimary"
        //               style={{
        //                   textAlign: "left",
        //                   color: "#3564D5",
        //                   fontWeight: "bold",
        //                   pointerEvents: !props.appPath ?
        //                                  "none" :
        //                                  "auto"
        //               }}
        //         ><Typography
        //             className={clsx(classes.interactionNameText,
        //                             classes.breakableText)}>{props.actionNotification.interactionName}</Typography></Link>
        // </Grid> <Grid item xs={12} className={clsx(classes.section, classes.padding)}> <AcxInputLabel
        // className={classes.section} labelText={"Comment"}/> <Typography className={classes.breakableText}
        // color={"textPrimary"}>{props.actionNotification.actionNotes?.substr(0, NoteTruncateLength)} {notesLength >
        // NoteTruncateLength ? <>...</> : <></>}</Typography> </Grid> </Grid>);
        return <></>;
    } else {
        return <></>;
    }
};

export default EvalActionNotification;
