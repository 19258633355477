import {
    Button,
    IconButton,
    Slide,
    SlideProps,
    Snackbar,
    Theme,
} from "@mui/material";
import { SnackbarOrigin } from "@mui/material/Snackbar/Snackbar";
import makeStyles from "@mui/styles/makeStyles";

import CloseIcon from "@mui/icons-material/Close";
import { observer } from "mobx-react";
import { CancellablePromise } from "mobx/dist/api/flow";
import React, { FunctionComponent, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { StoreError } from "../../stores/BaseStore";

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

const useStyles = makeStyles((theme: Theme) => ({
    retryButton: {
        color: theme.palette.info.main,
        fontWeight: "bold",
        marginLeft: theme.spacing(3),
    },
    closeButton: {
        marginLeft: theme.spacing(1),
    },
}));

interface ErrorStore {
    clearLastTaskError: () => void;
    anyTaskErrors: boolean;
    nextTaskError: StoreError;
}

const defaultAnchorOrigin: SnackbarOrigin = {
    vertical: "bottom",
    horizontal: "center",
};

interface OwnProps {
    store: ErrorStore;
    onClose: (event?: any) => void;
    onRetryClick?: (
        event?: any,
    ) => Promise<any> | CancellablePromise<any> | undefined;
    anchorOrigin?: SnackbarOrigin;
    exludeErrorNames?: string[];
}

type Props = OwnProps;

const ErrorSnackBar: FunctionComponent<
    React.PropsWithChildren<React.PropsWithChildren<Props>>
> = observer((props) => {
    const classes = useStyles(props);
    const [open, setOpen] = useState<boolean>(false);

    const taskError = props.store.nextTaskError;

    useEffect(() => {
        if (taskError) {
            if (props.exludeErrorNames && props.exludeErrorNames.length > 0) {
                setOpen(
                    !props.exludeErrorNames.includes(
                        taskError?.operationName ??
                            `${uuid()}-unused-error-name`,
                    ) && Boolean(taskError.message),
                );
            } else {
                setOpen(Boolean(taskError.message));
            }
        } else {
            setOpen(false);
        }
    }, [taskError, props.exludeErrorNames]);

    function onRetry(evt) {
        if (props.onRetryClick) {
            props.onRetryClick();
        } else {
            taskError?.task?.();
        }
    }

    return (
        <Snackbar
            anchorOrigin={defaultAnchorOrigin ?? props.anchorOrigin}
            open={open}
            onClose={props.onClose}
            TransitionComponent={SlideTransition}
            message={taskError?.message}
            action={
                <React.Fragment>
                    {(props.onRetryClick || taskError?.task) && (
                        <Button
                            size="small"
                            aria-label="retry"
                            className={classes.retryButton}
                            onClick={onRetry}
                        >
                            RETRY
                        </Button>
                    )}

                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        className={classes.closeButton}
                        onClick={props.onClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    );
});

export default ErrorSnackBar;
