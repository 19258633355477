import Navigation from "Layouts/SideNavigation/Navigation";
import { observer } from "mobx-react";
import React from "react";
import { IItemOptions } from "../../../Layouts/SideNavigation/NavigationStore";
import { useStore } from "../../../utils/useStore";
import { AnalystManagerNavigationStore } from "../Stores/AnalystManagerNavigationStore";

const itemOptions: IItemOptions = {
    itemSpacing: "spread",
    autoSelectOrg: true,
};

const ManagerInteractionNavigation = observer(() => {
    const navStore = useStore(AnalystManagerNavigationStore);

    return <Navigation store={navStore.navStore} itemOptions={itemOptions} />;
});

export default ManagerInteractionNavigation;
