import RuleSet from "../models/RuleSet";
import BaseService from "./BaseService";

export class RuleSetService extends BaseService {
    protected urlBase: string = "api/RuleSet";

    async getRuleSets() {
        const res = await this.get<RuleSet[]>(
            new URLSearchParams({}),
            "RuleSets",
        );

        return res;
    }

    async createRuleSet(orgId: string, ruleSet: RuleSet) {
        const res = await this.post<RuleSet>(
            JSON.stringify(ruleSet),
            new URLSearchParams({ orgId }),
            "",
        );
        return res;
    }

    async deleteRuleSet(orgId: string, ruleSetId: string) {
        const params = new URLSearchParams({
            orgId: orgId,
            ruleSetId: ruleSetId,
        });
        const res = await this.delete(params, "");
        return res;
    }
}
