import { Theme, Typography, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { BarChart } from "@mui/icons-material";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import {
    EEOverTimeID,
    ISignalsSelectionTypes,
    TrendingTopicsID,
} from "../Models/SignalsModels";
import { ChartPlaceholderContainer } from "./ChartPlaceholderContainer";
import { useStore } from "utils/useStore";
import AISummariesStore from "../Store/AISummariesStore";
import logo from "../../../authenticx-green.png";

const useStyles = makeStyles((theme: Theme) => ({
    unavailableIconContainer: {
        padding: theme.spacing(1),
        outline: "1px solid",
        outlineColor: alpha(theme.palette.primary.main, 0.8),
        borderRadius: "9999rem",
        display: "flex",
        justifyContent: "center",
        width: "fit-content",
        aspectRatio: "1 / 1",
        position: "relative",
        margin: theme.spacing(2),
        "&::before": {
            content: "''",
            outline: "1px solid",
            outlineColor: alpha(theme.palette.primary.main, 0.5),
            borderRadius: "9999px",
            position: "absolute",
            inset: theme.spacing(-1),
        },
        "&::after": {
            content: "''",
            outline: "1px solid",
            outlineColor: alpha(theme.palette.primary.main, 0.2),
            borderRadius: "9999px",
            position: "absolute",
            inset: theme.spacing(-2),
        },
    },
    unavailableIcon: {
        fontSize: "144px",
        aspectRation: "1 / 1",
    },
    dashboardAcxIconContainer: {
        height: "144px",
        width: "144px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

type Props = {
    headerText: string;
    chartId?: string;
    selectionType?: ISignalsSelectionTypes;
    subheaderText?: string;
    dashboardVariant?: boolean;
    dataEndpoint?: string;
};

const NoData = observer((props: Props) => {
    const classes = useStyles();
    const aiSummariesStore = useStore(AISummariesStore);

    const customText =
        props.chartId === EEOverTimeID ||
        props.dataEndpoint === TrendingTopicsID
            ? "Try changing your filters."
            : props.selectionType === "Classifiers"
            ? "Try selecting a different classifier or changing your filters."
            : props.selectionType === "Topics"
            ? "Try selecting a different theme or changing your filters."
            : "Try selecting a different question or changing your filters.";

    useEffect(() => {
        if (!props.chartId) return;
        aiSummariesStore.setSummaryLoading(props.chartId, false);
        aiSummariesStore.aiSummariesByChartId[props.chartId] =
            "No data was present when Spotlight was generated. Refresh to generate a new Spotlight for your current data.";
    }, [aiSummariesStore, props.chartId]);

    return (
        <ChartPlaceholderContainer direction="column" gap={2}>
            <span className={classes.unavailableIconContainer}>
                {props.dashboardVariant ? (
                    <div className={classes.dashboardAcxIconContainer}>
                        <img src={logo} alt="" style={{ height: "32px" }} />
                    </div>
                ) : (
                    <BarChart
                        className={classes.unavailableIcon}
                        color="primary"
                    />
                )}
            </span>
            <Typography variant="h2">{props.headerText}</Typography>
            <Typography variant="body1">
                {props.subheaderText ?? customText}
            </Typography>
        </ChartPlaceholderContainer>
    );
});

export default NoData;
