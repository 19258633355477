import { Box, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { EvalStore } from "components/Evaluation/Stores/EvalStore";
import AcxConfirmationDialog from "components/UI/AcxConfirmationDialog";
import AcxSelect from "components/UI/Select/BaseSelectComponents/AcxSelect";
import { observer } from "mobx-react";
import { ApplicationUser } from "models/Permission/ApplicationUser";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

type EvalOptions = "full" | "condensed";

const useStyles = makeStyles(() => ({
    analystSelect: {
        marginLeft: "28px",
    },
}));

type Props = {
    evalStore: EvalStore;
};

const ConvertToHumanEvalDialog = observer(({ evalStore }: Props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [condensedEval, setCondensedEval] =
        useState<EvalOptions>("condensed");
    const [analyst, setAnalyst] = useState<ApplicationUser>();
    const [shouldRefresh, setShouldRefresh] = useState(false);
    const [updateAnalystLoading, setUpdateEvalAnalystLoading] = useState(false);

    const handleConvertToAnalystEvaluation = async () => {
        const minimal = condensedEval === "condensed";
        setUpdateEvalAnalystLoading(true);
        await evalStore
            .convertToAnalystEvaluation(minimal, analyst)
            .then(() => {
                setUpdateEvalAnalystLoading(false);
                setShouldRefresh(true);
            });
    };

    useEffect(() => {
        const userAnalyst = evalStore.analystOptions.find(
            (a) => a.id === evalStore.user?.profile.sub,
        );
        if (userAnalyst) {
            setAnalyst(userAnalyst);
        }
    }, [evalStore.analystOptions, evalStore.user?.profile.sub]);

    useEffect(() => {
        if (shouldRefresh && !updateAnalystLoading) {
            navigate(0);
        }
    }, [navigate, shouldRefresh, updateAnalystLoading]);

    return (
        <AcxConfirmationDialog
            isOpen={evalStore.convertToHumanModalOpen}
            onClose={() => {
                evalStore.setConvertToHumanModalOpen(false);
            }}
            title={"How would you like to change this evaluation?"}
            content={
                <Box id="convert-to-human-eval-dialog-content">
                    <RadioGroup
                        defaultValue="condensed"
                        name="full-condensed-radio-group"
                        onChange={(e) => {
                            setCondensedEval(e.target.value as EvalOptions);
                        }}
                        value={condensedEval}
                        id="convert-to-human-eval-dialog-radio-group"
                    >
                        <FormControlLabel
                            value={"condensed"}
                            control={<Radio />}
                            label="I want to update a few answers and save the rest of the evaluation as-is"
                            id="convert-to-human-eval-dialog-condensed-radio"
                        />
                        <FormControlLabel
                            value={"full"}
                            control={<Radio />}
                            label="I want a human to complete the whole evaluation:"
                            id="convert-to-human-eval-dialog-full-radio"
                        />
                        <Box className={classes.analystSelect}>
                            <AcxSelect
                                isMulti={false}
                                id={"reassign-analyst-select"}
                                options={evalStore.analystOptions}
                                onChange={(a) => {
                                    setAnalyst(a);
                                }}
                                labelField={"userName"}
                                valueField={"id"}
                                isClearable
                                placeholder="Select Analyst"
                                defaultValue={analyst}
                                isDisabled={condensedEval !== "full"}
                            />
                        </Box>
                    </RadioGroup>
                </Box>
            }
            onConfirm={handleConvertToAnalystEvaluation}
            buttonsDisabled={
                !(
                    (analyst && condensedEval === "full") ||
                    condensedEval === "condensed"
                ) || shouldRefresh
            }
            isLoading={shouldRefresh || updateAnalystLoading}
        />
    );
});

export default ConvertToHumanEvalDialog;
