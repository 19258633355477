import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import conversationsSvg from "conversations.svg";
import { observer } from "mobx-react";
import React from "react";
import { useStore } from "utils/useStore";
import { ClassifierBuilderV2Store } from "../../Stores/ClassifierBuilderV2Store";
import ClassifierBuilderV2ResultsList from "./ClassifierBuilderV2ResultsList";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";

const useConversationsMainStyles = makeStyles((theme: Theme) => ({
    noResultsContainer: {
        width: "100%",
        height: "100%",
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
    },
    imageContainer: {
        width: "300px",
    },
    noResultsHeaderFont: {
        fontSize: "32px",
    },
    noResultsSubHeaderFont: {
        fontSize: "18px",
        color: "grey",
    },
}));

type Props = {};

const ClassifierBuilderV2ResultsListWrapper: React.FC<Props> = observer(() => {
    const store = useStore(ClassifierBuilderV2Store);
    const classes = useConversationsMainStyles();

    const noResultsView = (
        <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            direction="column"
            className={classes.noResultsContainer}
        >
            <Grid item className={classes.marginBottom}>
                <img
                    alt="conversations icon"
                    src={conversationsSvg}
                    className={classes.imageContainer}
                />
            </Grid>
            <Grid item className={classes.marginBottom}>
                <Typography
                    variant="h1"
                    className={classes.noResultsHeaderFont}
                >
                    No Search Results Yet
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    variant="h1"
                    className={classes.noResultsSubHeaderFont}
                >
                    Try adjusting your search terms to find what you're looking
                    for.
                </Typography>
            </Grid>
        </Grid>
    );

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignContent="center"
        >
            <Grid container item xs={12} direction="column" alignItems="center">
                {store.resultsLoading ? (
                    <AcxLoadingIndicator
                        size={50}
                        color="secondary"
                        alternate="PuffLoader"
                    />
                ) : store?.estimatedTotalResCount ? (
                    <ClassifierBuilderV2ResultsList />
                ) : (
                    noResultsView
                )}
            </Grid>
        </Grid>
    );
});

export default ClassifierBuilderV2ResultsListWrapper;
