import CloseIcon from "@mui/icons-material/Close";
import {
    Divider,
    Grid,
    IconButton,
    keyframes,
    styled,
    Typography,
    useTheme,
} from "@mui/material";
import PaperAirplaneSvg from "SvgIcons/PaperAirplaneSvg";
import theme from "Theme/AppTheme";
import AcxMainTextField from "components/UI/AcxMainTextFieldGrid";
import { observer } from "mobx-react";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useStore } from "utils/useStore";
import AgentChatStore from "./Stores/AgentChatStore";
import { MessageAuthor } from "./Models/MessageAuthor";
import { MessageLoading } from "./Messages/MessageLoading";
import { AgentLogoWithName } from "./Branding/AgentLogoWithName";
import { MessageGroup } from "./MessageGroup/MessageGroup";
import AcxButton from "components/UI/AcxButton";
import { AgentState } from "./Models/AgentState";
import { StopCircleRounded } from "@mui/icons-material";
import { ThreadMessage } from "./Models/ThreadMessage";
import AcxChip from "components/UI/AcxChip";
import AcxSelectSingle from "components/UI/Select/BaseSelectComponents/AcxSelectSingle";
import { UserSelectedContext } from "./Models/UserSelectedContext";
import { ActiveDomain } from "stores/RootStore";

type Props = {
    close: () => void;
};

const inputExpand = keyframes`
    from {
        width: 0%
    }
    to {
        width: 100%
    }
`;

const inputStyleProps = {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    color: theme.palette.gray[500],
};

const Grow = styled(Grid)(() => ({
    animationDelay: "300ms",
    animation: `${inputExpand} 700ms forwards`,
}));

const NewChatButton = styled(AcxButton)(({ theme }) => ({
    backgroundColor: "transparent",
    color: theme.palette.black.main,
    ":hover": {
        backgroundColor: "transparent",
        opacity: "75%",
    },
}));

function SwitchContext() {
    const theme = useTheme();
    const store = useStore(AgentChatStore);

    return (
        <AcxSelectSingle
            id="ava-context"
            options={[
                UserSelectedContext.AllConversations,
                UserSelectedContext.AppliedFilters,
            ]}
            defaultValue={store.userSelectedContext}
            onChange={(ctx) => {
                store.setUserSelectedContext(ctx);
            }}
            valueField="type"
            labelField="label"
            containerHeight="24px"
            isSearchable={false}
            width="fit-content"
            customStyle={{
                valueContainer: (provided) => ({
                    ...provided,
                    height: "24px",
                    width: "164px",
                }),
                control: () => ({
                    height: "24px",
                    display: "flex",
                    cursor: "pointer",
                    backgroundColor: theme.palette.gray[100],
                }),
                indicatorsContainer: () => ({ height: "24px" }),
                dropdownIndicator: () => ({
                    display: "flex",
                    alignItems: "center",
                    height: "24px",
                    paddingInline: "4px",
                }),
            }}
            dropdownIndicator={
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M12.6666 6L7.99998 10.6667L3.33331 6"
                        stroke="#374151"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            }
            components={{
                SingleValue: (props) => {
                    return (
                        <Grid
                            container
                            item
                            direction="row"
                            columnGap={1 / 2}
                            flexWrap="nowrap"
                            alignItems="center"
                            sx={(theme) => ({
                                position: "absolute",
                                bottom: theme.spacing(1 / 2),
                                width: "fit-content",
                            })}
                        >
                            <Grid item>
                                <Typography
                                    sx={(theme) => ({
                                        fontWeight: 600,
                                        fontSize: "12px",
                                        color: theme.palette.gray[700],
                                        lineHeight: "16px",
                                    })}
                                >
                                    Context:
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={(theme) => ({
                                        fontSize: "12px",
                                        color: theme.palette.gray[700],
                                        lineHeight: "16px",
                                    })}
                                >
                                    {props.children}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                },
            }}
        />
    );
}

const AgentChat: React.FC<Props> = observer(({ close }) => {
    const store = useStore(AgentChatStore);

    const isViewingConversationsOrSignals = useMemo(() => {
        const activeDomain = store.rootStore.activeLocation.domain;
        if (!activeDomain) return false;
        return (
            activeDomain === ActiveDomain.Conversations ||
            activeDomain === ActiveDomain.Signals
        );
    }, [store.rootStore.activeLocation.domain]);

    const scrollableChatRef = useRef<HTMLDivElement>(null);
    const chatEndRef = useRef<HTMLDivElement>(null);

    const [isAutoScrolling, setIsAutoScrolling] = useState(false);

    const submit = useCallback(async () => {
        if (store.state !== AgentState.Idle) return;
        await store.streamingSubmitInput();
    }, [store]);

    /**
     * This effect handles enabling/disabling auto scroll when the user scrolls
     * to/away from the bottom of the agent chat. When auto
     */
    useEffect(() => {
        if (!scrollableChatRef.current || !chatEndRef.current) return;
        const scrollRef = scrollableChatRef.current;
        const chatRef = chatEndRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setIsAutoScrolling(true);
                }
            },
            { threshold: 1 },
        );

        function onUserScroll(event: WheelEvent) {
            // deltaY > 0 indicates the user scrolled down and we can exit early,
            // this helps auto scroll stay enabled if the user
            // is scrolling down even if they're already at the very bottom.
            // Scrolling up still disables auto scroll as intended and is never
            // re-enabled till they scroll all the way down again.
            if (event.deltaY > 0) return;
            setIsAutoScrolling(false);
        }

        observer.observe(chatRef);
        scrollRef.addEventListener("wheel", onUserScroll);

        return () => {
            observer.unobserve(chatRef);
            scrollRef.removeEventListener("wheel", onUserScroll);
        };
    }, []);

    /**
     * This effect handles scrolling down when auto scrolling is active
     * and new dom elements are rendered such as suggestions or actions.
     */
    useEffect(() => {
        if (!chatEndRef.current || !scrollableChatRef.current) return;

        const observer = new MutationObserver((mutations) => {
            if (!isAutoScrolling || !chatEndRef.current) return;
            for (const mutation of mutations) {
                if (mutation.type !== "childList") continue;

                chatEndRef.current.scrollIntoView({ behavior: "smooth" });
                break;
            }
        });

        observer.observe(scrollableChatRef.current, {
            childList: true,
            subtree: true,
        });

        return () => observer.disconnect();
    }, [isAutoScrolling]);

    /**
     * This effect handles scrolling to the bottom of the chat and enabling
     * auto scrolling when a user sends a message.
     */
    useEffect(() => {
        if (!chatEndRef.current) return;
        const recentMessageGroup = store.thread.recentMessageGroup;
        if (
            recentMessageGroup &&
            recentMessageGroup.author === MessageAuthor.User
        ) {
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });
            setIsAutoScrolling(true);
        }
    }, [store.thread, store.thread.recentMessageGroup]);

    return (
        <Grid
            container
            direction="column"
            flexWrap="nowrap"
            height="90vh"
            width="372px"
            sx={(theme) => ({
                rowGap: theme.spacing(5),
            })}
        >
            <Grid
                container
                item
                alignItems="center"
                justifyContent="space-between"
                flexWrap="nowrap"
                paddingTop={1}
                paddingInline={2}
            >
                <Grid
                    container
                    item
                    alignItems="center"
                    columnGap={1}
                    sx={{ width: "fit-content" }}
                >
                    <AgentLogoWithName variant="wordmark-inverse" />
                    <AcxChip
                        size="small"
                        color="secondary800"
                        label="Beta"
                        style={{
                            backgroundColor: "transparent",
                            border: "1px solid " + theme.palette.secondary[800],
                            margin: 0,
                        }}
                    />
                </Grid>
                <Grid
                    container
                    item
                    alignItems="center"
                    columnGap={1}
                    sx={{ width: "fit-content" }}
                >
                    <Grid item>
                        <NewChatButton
                            onClick={() => store.resetChat()}
                            fullWidth={false}
                            id="ava-new-chat"
                        >
                            New Chat
                        </NewChatButton>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={() => close()}
                            size="small"
                            id="ava-close-button"
                        >
                            <CloseIcon
                                style={{ color: theme.palette.gray[400] }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                item
                direction="column"
                flexWrap="nowrap"
                rowGap={3}
                flexGrow={1}
                paddingInline={2}
                sx={{ overflowY: "auto" }}
                ref={scrollableChatRef}
            >
                {store.thread.messageGroups.map((item, index) => {
                    const isMostRecent =
                        index === store.thread.messageGroups.length - 1;

                    const cancelledStreaming =
                        item.author === MessageAuthor.Agent &&
                        (!!item.recentMessage as unknown as ThreadMessage)
                            ?.cancelled;

                    return (
                        <MessageGroup
                            key={`agent-chat-${index}`}
                            {...item}
                            isMostRecent={isMostRecent}
                            cancelledStreaming={cancelledStreaming}
                        />
                    );
                })}
                <MessageLoading />
                <Grid ref={chatEndRef}></Grid>
            </Grid>

            <Grow
                container
                item
                direction="column"
                rowGap={1}
                sx={{ bottom: 0 }}
                alignSelf="center"
                className={"pendo-ignore"}
            >
                {isViewingConversationsOrSignals && (
                    <Grid item>
                        <Divider />
                    </Grid>
                )}
                <Grid
                    container
                    item
                    paddingInline={2}
                    direction="column"
                    rowGap={1}
                >
                    {isViewingConversationsOrSignals && (
                        <Grid item>
                            <SwitchContext />
                        </Grid>
                    )}
                    <Grid item width="100%">
                        <AcxMainTextField
                            value={store.currentInput}
                            onChange={(event) =>
                                store.onChangeHandler(event.target.value)
                            }
                            id="agent-chat-user-input"
                            placeholderText="What can I help you with?"
                            endAdornment={
                                store.state === AgentState.Idle ? (
                                    <PaperAirplaneSvg
                                        style={{
                                            margin: "8px",
                                            marginLeft: "4px",
                                            cursor: store.currentInput
                                                ? "pointer"
                                                : "default",
                                        }}
                                        onClick={submit}
                                        id="ava-send-chat"
                                    />
                                ) : (
                                    <IconButton onClick={() => store.abort()}>
                                        <StopCircleRounded />
                                    </IconButton>
                                )
                            }
                            textContainerSx={(theme) => ({
                                border: `1px solid`,
                                borderColor: theme.palette.gray[200],
                                boxShadow:
                                    "0px 0px 8px 0px rgba(0, 0, 0, 0.10)",
                                ":focus-within": {
                                    borderColor: theme.palette.gray[300],
                                },
                            })}
                            inputProps={{ style: inputStyleProps }}
                            autoComplete="off"
                            onEnterPressed={submit}
                            autoFocus
                        />
                    </Grid>
                </Grid>
            </Grow>
        </Grid>
    );
});

export default AgentChat;
