import {
    GridColDef,
    GridComparatorFn,
    GridFilterItem,
    GridFilterOperator,
    GridRenderCellParams,
    GridSortCellParams,
} from "@mui/x-data-grid-pro";
import { DefaultFilter } from "./DefaultFormatters";
import { isNullableType } from "utils/TypeGuards";

function ToPercent(value: unknown, format: boolean = true): string {
    if (IsNumber(value)) {
        const res = value * 100;
        return format ? `${res.toFixed(2)}%` : `${res}`;
    }
    return "";
}

export const PercentFormatter = (params: GridRenderCellParams) => {
    const value = params.value;
    return ToPercent(value);
};

export const PercentComparator: GridComparatorFn = (
    v1,
    v2,
    cellParams1: GridSortCellParams,
    cellParams2: GridSortCellParams,
): number => {
    const x1 = v1 as number;
    const x2 = v2 as number;

    // Will always sort undefineds/nulls to the bottom
    if (isNullableType(x1) && !isNullableType(x2)) return -1;
    else if (!isNullableType(x1) && isNullableType(x2)) return 1;

    if (x1 > x2 || isNullableType(x2)) return 1;
    if (x1 <= x2 || isNullableType(x1)) return -1;

    return x1 - x2;
};

export const getPercentFilterOperators: (
    filterField: string,
) => GridFilterOperator[] = (filterField: string) => [
    {
        label: "=",
        value: "=",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }

            return (params): boolean => {
                const rowValue = ToPercent(params, false);

                const res =
                    filterItem.value
                        ?.toString()
                        .localeCompare(rowValue?.toString() || "", undefined, {
                            sensitivity: "base",
                            numeric: true,
                        }) === 0;

                return res;
            };
        },
        InputComponent: DefaultFilter,
    },
    {
        label: "!=",
        value: "!=",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }

            return (params): boolean => {
                const rowValue = ToPercent(params, false);

                const res =
                    filterItem.value
                        ?.toString()
                        .localeCompare(rowValue?.toString() || "", undefined, {
                            sensitivity: "base",
                            numeric: true,
                        }) !== 0;

                return res;
            };
        },
        InputComponent: DefaultFilter,
    },

    {
        label: ">=",
        value: ">=",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            // debugger;
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }

            return (params): boolean => {
                debugger;
                const rowValue = ToPercent(params, false);

                const res =
                    filterItem.value
                        ?.toString()
                        .localeCompare(rowValue?.toString() || "", undefined, {
                            sensitivity: "base",
                            numeric: true,
                        }) <= 0;

                return res;
            };
        },
        InputComponent: DefaultFilter,
    },

    {
        label: "<=",
        value: "<=",
        getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
            if (
                !filterItem.field ||
                !filterItem.value ||
                !filterItem.operator
            ) {
                return null;
            }
            return (params): boolean => {
                const rowValue = ToPercent(params, false);

                const res =
                    filterItem.value
                        ?.toString()
                        .localeCompare(rowValue?.toString() || "", undefined, {
                            sensitivity: "base",
                            numeric: true,
                        }) >= 0;

                return res;
            };
        },
        InputComponent: DefaultFilter,
    },
];

function IsNumber(arg): arg is number {
    return !Number.isNaN(arg) && arg != null;
}
