import {AnswerType} from "components/Admin/Organizations/types/Module.type";
import {useCallback} from "react";
import useList from "shared/hooks/useList";
import ModuleService from "../Module.service";

export const useAnswerTypes = () => {
    const getData = useCallback(async () => {
        return ModuleService.getAnswerTypes();
    }, []);

    const {
        isLoading,
        data: answerTypes,
        refetch,
    } = useList<AnswerType>({
        get: getData,
    });

    return {
        answerTypes,
        isLoading,
        refetch,
    };
};

