import { Grid, Link, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import AcxButton from "components/UI/AcxButton";
import AcxTextField from "components/UI/AcxTextField";
import React from "react";
import { useNavigate } from "react-router";
import useStyles from "Styles/Styles";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            "&:hover": {
                backgroundColor: theme.palette.secondary.light,
            },
            "&:disabled": {
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.white.main,
            },
        },
        forgotLink: {
            margin: theme.spacing(4),
        },
    });

const origin = window.location.origin;

export default function ForgotPassword() {
    const classes = useStyles(styles);
    const [userName, setUserName] = React.useState("");
    const [buttonStatus, setButtonStatus] = React.useState(true);
    const [userNameError, setUserNameError] = React.useState(false);
    const [userNameHelper, setUserNameHelper] = React.useState("");
    let navigate = useNavigate();

    const sendReset = async () => {
        try {
            var response = await fetch(
                `api/Authenticate/ResetPasswordRequest`,
                {
                    method: "Post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        UserName: userName,
                        ChangePasswordUrl: `${origin}/changepassword`,
                    }),
                },
            );

            // debugger;
            if (response.status === 200) {
                const location = {
                    pathname: "/resetmessage",
                    state: { local: true },
                };
                navigate(location, { replace: true });
                // history.replace(location);
            } else {
                const data = await response.json();
                console.log(response);
                if (data.otherDetails === "UserName") {
                    setUserNameError(true);
                    setUserNameHelper(data.message);
                } else if (data.otherDetails === "Password") {
                    setUserNameError(false);
                    setUserNameHelper("");
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    function handleUserChange(e) {
        setUserName(e.target.value);
        if (e.target.value !== "") {
            setButtonStatus(false);
        } else {
            setButtonStatus(true);
        }
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ height: "100%" }}
            direction="row"
        >
            <Grid item xs={12}>
                <form>
                    <div>
                        <AcxTextField
                            id="InputEmail"
                            type="email"
                            label="Email"
                            placeholder="Email"
                            required={false}
                            error={userNameError}
                            onChange={handleUserChange}
                            fullWidth={true}
                            helperText={userNameHelper}
                            other={{}}
                        />
                    </div>
                    <AcxButton
                        fullWidth
                        color="primary"
                        onClick={sendReset}
                        disabled={buttonStatus}
                    >
                        Send Reset Link
                    </AcxButton>
                </form>
            </Grid>
            <Grid item container justifyContent="center" xs={12}>
                <Typography className={classes.forgotLink}>
                    <Link
                        href={`/authentication/login?returnUrl=${encodeURI(
                            origin,
                        )}/app`}
                        color="secondary"
                    >
                        Back to Log In
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
}
