import { Switch, Tooltip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import { Grid } from "@mui/material";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import { OrganizationUserStore } from "../../stores/OrganizationUserStore";
import {
    HierarchyPermissions,
    HierarchyTypes,
    IHierarchyAssignmentTier,
} from "./Types";
import { PERMISSION_ICONS, TYPE_ICONS } from "./Utilities";

const styles = () =>
    createStyles({
        switch: {
            margin: "0 1rem 0 0.5rem",
        },
    });

interface IHierarchyAssignmentToggleProps {
    permissionValidator: (
        hasAdminPerms: boolean,
        permission: string,
        branch: IHierarchyAssignmentTier,
    ) => boolean;
    typeValidator: (
        hasAdminPerms: boolean,
        branch: IHierarchyAssignmentTier,
    ) => boolean;
    onChangeHandler: (event: any, checked: boolean) => void;
    branch: IHierarchyAssignmentTier;
    store: OrganizationUserStore;
    hasAdminPerms: boolean;
    fullSize?: boolean;
}

const HierarchyAssignmentToggles: React.FC<
    React.PropsWithChildren<
        React.PropsWithChildren<IHierarchyAssignmentToggleProps>
    >
> = observer(
    ({
        permissionValidator,
        typeValidator,
        onChangeHandler,
        branch,
        store,
        hasAdminPerms,
        fullSize,
    }) => {
        const classes = useStyles(styles);

        return (
            <Grid
                container
                item
                xs={fullSize ? 12 : 9}
                rowSpacing={1}
                justifyContent="flex-end"
            >
                <Tooltip title="Toggle All">
                    <Grid
                        container
                        item
                        alignItems="center"
                        xs={2}
                        style={{ minWidth: "80px" }}
                    >
                        <AllInclusiveIcon fontSize="small" color="primary" />

                        <Switch
                            value="master"
                            id="toggle-all-switch"
                            disabled={!hasAdminPerms}
                            className={classes.switch}
                            sx={{ minWidth: "40px" }}
                            checked={
                                (branch.permissions?.length ?? 0) >=
                                    store.hierarchyPermissions.length &&
                                (branch.types?.length ?? 0) >=
                                    store.hierarchyTypes.length
                            }
                            onChange={onChangeHandler}
                        />
                    </Grid>
                </Tooltip>

                {store.hierarchyPermissions?.map((perm) => (
                    <Tooltip key={perm} title={perm}>
                        <Grid
                            container
                            item
                            alignItems="center"
                            xs={2}
                            style={{ minWidth: "80px" }}
                        >
                            {PERMISSION_ICONS[perm]}

                            <Switch
                                value={perm}
                                disabled={
                                    !permissionValidator(
                                        hasAdminPerms,
                                        perm,
                                        branch,
                                    )
                                }
                                className={classes.switch}
                                sx={{ minWidth: "40px" }}
                                checked={
                                    branch.permissions?.includes(
                                        HierarchyPermissions[perm],
                                    ) ?? false
                                }
                                onChange={onChangeHandler}
                            />
                        </Grid>
                    </Tooltip>
                ))}

                {store.hierarchyTypes?.map((type) => (
                    <Tooltip key={type} title={type}>
                        <Grid
                            container
                            item
                            alignItems="center"
                            xs={2}
                            style={{ minWidth: "80px" }}
                        >
                            {TYPE_ICONS[type]}

                            <Switch
                                value={type}
                                disabled={!typeValidator(hasAdminPerms, branch)}
                                className={classes.switch}
                                sx={{ minWidth: "40px" }}
                                checked={
                                    type === "Chat"
                                        ? (branch.types?.includes(
                                              HierarchyTypes[type],
                                          ) ||
                                              // Note: 6 is the type for processed chats and we want to show this as checked if user has processed chat perms
                                              branch.types?.includes(6)) ??
                                          false
                                        : branch.types?.includes(
                                              HierarchyTypes[type],
                                          ) ?? false
                                }
                                onChange={onChangeHandler}
                            />
                        </Grid>
                    </Tooltip>
                ))}
            </Grid>
        );
    },
);

export default HierarchyAssignmentToggles;
