import { Box, Step, StepLabel, Stepper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
    WorkflowLoading,
    WorkflowReviewStore,
} from "../Stores/WorkflowReviewStore";
import { useStore } from "utils/useStore";
import theme from "Theme/AppTheme";
import { Observer, observer } from "mobx-react";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    stepper: {
        display: "flex",
        justifyContent: "space-between",
        padding: 0,
        background: "none",
        margin: theme.spacing(5, 0, 3, 0),
    },
    step: {
        width: "172px",
        height: "38px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: theme.spacing(0),
    },
    stepSingle: {
        borderRadius: "19px 19px 19px 19px",
        padding: theme.spacing(0),
    },
    stepTextContainer: {
        zIndex: 10,
        cursor: "default",
        color: "red",
        textOverflow: "ellipsis",
        height: "38px",
    },
    stepText: {
        height: "30px",
        overflow: "hidden",
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(1),
        lineHeight: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    stepInactive: {
        backgroundColor: theme.palette.gray[200],
        transition: "0.3s",
    },
    stepDisabled: {
        backgroundColor: "#ECEDF0",
    },
    stepInactiveHover: {
        "&:hover": {
            backgroundColor: theme.palette.gray[300],
            transition: "0.3s",
        },
    },
    stepActive: {
        backgroundColor: "white",
    },
    stepArrow: {
        "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: "27px",
            height: "27px",
            transform: "translate(90px) rotate(45deg)",
            border: "none",
        },
    },
    stepFirst: {
        borderRadius: "19px 0 0 19px",
    },
    stepLast: {
        borderRadius: "0 19px 19px 0",
    },
}));

const WFRStepper = observer(() => {
    const classes = useStyles();
    const store = useStore(WorkflowReviewStore);

    const getClassListForLevel = (idx: number): string => {
        const length = store.workflowReviewerInfo?.levelNames.length ?? -1;
        const levelCount =
            store.workflowReviewerInfo?.workflowInfo.workflowLevelInfos
                .length ?? 0;

        const isActive = idx === store.stepperLevel;
        return clsx(
            classes.step,
            isActive
                ? classes.stepActive
                : idx < levelCount
                ? classes.stepInactive
                : classes.stepDisabled,
            idx === 0 && length !== 1 && classes.stepFirst,
            idx === (store.workflowReviewerInfo?.levelNames.length ?? 0) - 1 &&
                length !== 1 &&
                classes.stepLast,
            idx !== (store.workflowReviewerInfo?.levelNames.length ?? 0) - 1 &&
                isActive &&
                classes.stepArrow,
            length === 1 && classes.stepSingle,
            idx !== store.stepperLevel &&
                idx <
                    (store.workflowReviewerInfo?.workflowInfo.workflowLevelInfos
                        .length ?? -1) &&
                classes.stepInactiveHover,
        );
    };

    if (!store.workflowReviewerInfo || store.getTaskLoadingV2(WorkflowLoading))
        return null;

    return (
        <Box
            style={{
                margin: theme.spacing(2, 8, 2, 8),
                width: "calc(100% - 128px)",
                justifyContent: "center",
                display: "flex",
            }}
        >
            <Stepper
                activeStep={store.stepperLevel}
                className={classes.stepper}
            >
                <Observer>
                    {() => (
                        <>
                            {store.workflowReviewerInfo?.levelNames.map(
                                (name, idx) => {
                                    const classList = getClassListForLevel(idx);
                                    return (
                                        <Step
                                            key={`workflow-step-${name}-${idx}`}
                                            className={classList}
                                            onClick={() => {
                                                if (
                                                    store.workflowReviewerInfo
                                                        ?.workflowInfo
                                                        .workflowLevelInfos[idx]
                                                ) {
                                                    store.setStepperLevel(idx);
                                                }
                                            }}
                                        >
                                            <StepLabel
                                                className={
                                                    classes.stepTextContainer
                                                }
                                            >
                                                <div
                                                    className={classes.stepText}
                                                >
                                                    {!!name
                                                        ? name
                                                        : `Level ${idx + 1}`}
                                                </div>
                                            </StepLabel>
                                        </Step>
                                    );
                                },
                            )}
                        </>
                    )}
                </Observer>
            </Stepper>
        </Box>
    );
});

export default WFRStepper;
