import { Grid, Typography, styled } from "@mui/material";
import { useTheme } from "@mui/styles";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import AcxButton from "components/UI/AcxButton";
import AcxDeleteDialog from "components/UI/AcxDeleteDialog";
import ErrorDialog from "components/UI/AcxErrorDialog";
import AcxImageFileDrop from "components/UI/AcxImageFileDrop";
import AcxImageTile from "components/UI/AcxImageTile";
import AcxLoadingIndicator from "components/UI/AcxLoadingIndicator";
import AcxMainTextField from "components/UI/AcxMainTextField";
import AcxToggleButton from "components/UI/AcxToggleButton";
import VerticalDrawerContentTemplate from "components/UI/Drawer/VerticalDrawerContentTemplate";
import { observer } from "mobx-react";
import React, {
    CSSProperties,
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { SignalsService } from "services/SignalsService";
import { useStore } from "utils/useStore";
import { IDashboardVisibility } from "../Models/SignalsModels";
import { SavedFilter } from "stores/ApplicationFilters/ApplicationFiltersStore";
import { ApplicationFilterDomain } from "services/ApplicationFiltersService";
import { ErrorRounded } from "@mui/icons-material";

const newDashVisItems = [
    { id: "signals-dashboard-only-me", value: "User", element: "Only Me" },
    {
        id: "signals-dashboard-my-organization",
        value: "Organization",
        element: "My Organization",
    },
];

const SectionSmallerTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.neutral.main,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
}));

interface ContainerProps {
    style?: CSSProperties;
    children?: React.ReactNode;
}

const Container = forwardRef<HTMLDivElement, ContainerProps>((props, ref) => {
    const theme = useTheme();

    const containerStyle: CSSProperties = {
        backgroundColor: theme.palette.gray[50],
        height: "375px",
        width: "100%",
        display: "grid",
        flexDirection: "column",
        padding: "10px",
        overflowY: "scroll",
        overflowX: "hidden",
        borderRadius: "4px",
        border: `1px solid ${theme.palette.gray[300]}`,
        gridTemplateColumns: "repeat(2, minmax(0, 226px))",
        gridAutoRows: "163px",
        columnGap: "8px",
        rowGap: "8px",
        ...props.style,
    };

    return (
        <div ref={ref} style={containerStyle}>
            {props.children}
        </div>
    );
});
Container.displayName = "Container";

type Prop = {
    dashboardId: string;
    currentName: string;
    currentVisibility: IDashboardVisibility;
    currentPinnedFilter?: SavedFilter;
    currentLogoName?: string;
    updateDashboard: (
        newTitle: string,
        newDashVis: IDashboardVisibility,
        logoName?: string,
    ) => Promise<void>;
    closeDrawer: () => void;
};

const CustomizeDashboard: React.FC<Prop> = observer((props: Prop) => {
    const theme = useTheme();
    const messageStore = useStore(MessageStore);

    // Values Held By Customize Dashboard Separate From Current Dashboard
    const [customizedName, setCustomizedName] = useState<string>(
        props.currentName ?? "",
    );
    const [customizedVisibility, setCustomizedVisibility] =
        useState<IDashboardVisibility>(
            (newDashVisItems.find((i) => i.value === props.currentVisibility)
                ?.value as IDashboardVisibility) ?? newDashVisItems[0].value, // default to "User" Visibilty
        );

    const [customizedLogoName, setCustomizedLogoName] = useState<
        string | undefined
    >(props.currentLogoName);
    const [editsMade, setEditsMade] = useState(false);

    const isPinnedFilterPrivate =
        props.currentPinnedFilter &&
        props.currentPinnedFilter.domain !==
            ApplicationFilterDomain.Organization;
    const isViolatingVisibility =
        isPinnedFilterPrivate && customizedVisibility === "Organization";

    // Maintain Editing Status
    useEffect(() => {
        const originalName = props.currentName;
        const originalVisibility = props.currentVisibility;
        const originalLogoName = props.currentLogoName;

        if (
            customizedName !== originalName ||
            customizedVisibility !== originalVisibility ||
            customizedLogoName !== originalLogoName
        ) {
            setEditsMade(true);
        } else {
            setEditsMade(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customizedName, customizedVisibility, customizedLogoName]);

    const [loading, setLoading] = useState(true);
    const [orgLogos, setOrgLogos] = useState<
        { blobSasUrl: string; fileName: string }[]
    >([]);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [errorDetails, setErrorDetails] = useState<{
        errorTitle: string;
        errorMessage: string;
    }>({ errorTitle: "", errorMessage: "" });
    const [stagedDeleteLogo, setStagedDeleteLogo] = useState<null | string>(
        null,
    );
    const [deleteLogoLoading, setDeleteLogoLoading] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const scrollPositionRef = useRef(0);

    const signalsService: SignalsService = new SignalsService();

    async function getLogos() {
        setOrgLogos(await signalsService.getLogos());
        setLoading(false);
    }

    useEffect(() => {
        getLogos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const SingleDropContainer = styled("div")({
        height: "150px",
        width: "100%",
        display: "grid",
        padding: "10px",
        gridTemplateColumns: "1fr",
        gridAutoRows: "163px",
    });

    // Ensure Scroll Position is maintained on renders
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const container = containerRef.current;

        const handleScroll = () => {
            if (container) {
                scrollPositionRef.current = container.scrollTop;
            }
        };

        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
                if (containerRef.current) {
                    containerRef.current.scrollTop = scrollPositionRef.current;
                }
            }
        };
    }, [orgLogos, loading, customizedLogoName]);

    async function saveLogo(file: File) {
        const newLogo = await signalsService.saveLogo(file, props.dashboardId);
        let orgLogosCopy = [...orgLogos];
        orgLogosCopy.unshift({ blobSasUrl: newLogo, fileName: file.name });
        setOrgLogos(orgLogosCopy);
        selectLogo(file.name);
    }

    const selectLogo = useCallback(
        (logoClicked: string) => {
            if (props.currentLogoName !== logoClicked) {
                setEditsMade(true);
            }
            setCustomizedLogoName(logoClicked);
        },
        [props.currentLogoName],
    );

    const deleteLogo = useCallback(
        async (logoClicked: string) => {
            const deleteCurrentLogo = logoClicked === customizedLogoName;
            setDeleteLogoLoading(true);

            // If the logo to be deleted is the current logo for the dashboard,
            // we need to remove the logo as the currently selected logo
            if (deleteCurrentLogo) {
                setCustomizedLogoName(undefined);
            }

            try {
                await signalsService.deleteLogo(logoClicked, props.dashboardId);
            } catch (e) {
                messageStore.logError("Error deleting logo");
            } finally {
                setStagedDeleteLogo(null);
                getLogos();
                props.updateDashboard(
                    customizedName,
                    customizedVisibility,
                    deleteCurrentLogo ? undefined : customizedLogoName,
                );
                setEditsMade(false);
                setDeleteLogoLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.dashboardId],
    );

    const handleFileUploadError = (
        errorTitle: string,
        errorMessage: string,
    ) => {
        setErrorDetails({ errorTitle, errorMessage });
        setErrorDialogOpen(true);
    };

    function getContent() {
        if (containerRef.current != null) {
            containerRef.current.scrollTop = scrollPositionRef.current;
        }

        if (loading) {
            return <AcxLoadingIndicator size={75} alternate="PuffLoader" />;
        }

        return (
            <div
                style={{
                    // height: "calc(100% - 2rem)",
                    height: "100%",
                    paddingLeft: "8px",
                    paddingRight: "16px",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div style={{ marginTop: "24px" }}>
                    <SectionSmallerTitle style={{ marginBottom: "8px" }}>
                        Dashboard Name
                    </SectionSmallerTitle>
                    <AcxMainTextField
                        id="customize-dashboard-name"
                        value={customizedName}
                        onChange={(e) => setCustomizedName(e.target.value)}
                    />
                </div>

                <Grid
                    container
                    sx={{ marginTop: "24px" }}
                    direction="column"
                    rowGap={1}
                >
                    <Grid item>
                        <SectionSmallerTitle>Visibility</SectionSmallerTitle>
                    </Grid>
                    <Grid item>
                        <AcxToggleButton
                            id="customize-dashboard-visibility-toggle"
                            items={newDashVisItems}
                            defaultValue={customizedVisibility}
                            onChange={(item) => {
                                if (!item) return;
                                setCustomizedVisibility(item);
                            }}
                            exclusive
                        />
                    </Grid>
                    {isViolatingVisibility && (
                        <Grid
                            container
                            item
                            columnGap={1}
                            flexWrap="nowrap"
                            alignItems="flex-start"
                            sx={(theme) => ({
                                color: theme.palette.error.main,
                            })}
                        >
                            <Grid item>
                                <ErrorRounded />
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "inherit",
                                        textWrap: "wrap",
                                    }}
                                >
                                    Pinned filter must be made public or removed
                                    to share this dashboard.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <div style={{ marginTop: "24px" }}>
                    <Typography
                        style={{
                            color: theme.palette.neutral.main,
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "16px",
                            fontWeight: 600,
                            lineHeight: "24px",
                        }}
                    >
                        {"Add Logo"}
                    </Typography>
                    <Typography
                        style={{
                            color: "#3F3F46",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            marginBottom: "24px",
                        }}
                    >
                        {"Personalize your dashboard by adding a company logo."}
                    </Typography>
                    {!!orgLogos.length ? (
                        <Container ref={containerRef}>
                            <AcxImageFileDrop
                                uploadHandler={saveLogo}
                                onError={handleFileUploadError}
                            />
                            {orgLogos.map((orgLogo) => {
                                const isHighlighted =
                                    customizedLogoName === orgLogo.fileName;

                                return (
                                    <AcxImageTile
                                        key={orgLogo.fileName}
                                        imageUri={orgLogo.blobSasUrl}
                                        imageName={orgLogo.fileName}
                                        highlighted={isHighlighted}
                                        onClickHandler={selectLogo}
                                        onDeleteHandler={(logo) => {
                                            setStagedDeleteLogo(logo);
                                        }}
                                    />
                                );
                            })}
                        </Container>
                    ) : (
                        <SingleDropContainer>
                            <AcxImageFileDrop
                                uploadHandler={saveLogo}
                                onError={handleFileUploadError}
                            />
                        </SingleDropContainer>
                    )}
                </div>
                <Grid
                    container
                    item
                    sx={{
                        justifyContent: "flex-end",
                        marginTop: "auto",
                        marginBottom: "24px",
                    }}
                    columnSpacing={1}
                >
                    <Grid item>
                        <AcxButton
                            color="white"
                            variant="outlined"
                            onClick={() => {
                                setEditsMade(false);
                                setCustomizedLogoName(undefined);
                                props.closeDrawer();
                            }}
                        >
                            Cancel
                        </AcxButton>
                    </Grid>
                    <Grid item>
                        <AcxButton
                            color="primary"
                            disabled={!editsMade || isViolatingVisibility}
                            onClick={() => {
                                props.updateDashboard(
                                    customizedName,
                                    customizedVisibility,
                                    customizedLogoName,
                                );
                                setEditsMade(false);
                                props.closeDrawer();
                            }}
                        >
                            Update Dashboard
                        </AcxButton>
                    </Grid>
                </Grid>

                <ErrorDialog
                    isOpen={errorDialogOpen}
                    onConfirm={() => setErrorDialogOpen(false)}
                    onClose={() => setErrorDialogOpen(false)}
                    title={errorDetails.errorTitle}
                    subTitle={errorDetails.errorMessage}
                />
                <AcxDeleteDialog
                    isOpen={!!stagedDeleteLogo}
                    onCancel={() => {
                        setStagedDeleteLogo(null);
                    }}
                    onDelete={() => {
                        if (stagedDeleteLogo) {
                            deleteLogo(stagedDeleteLogo);
                        }
                    }}
                    text={{
                        title: "Are you sure?",
                        content:
                            "Deleting this image is immediate and permanent. Are you sure you want to delete?",
                        confirm: "Delete Image",
                    }}
                    loading={deleteLogoLoading}
                />
            </div>
        );
    }

    return (
        <Grid container item xs={12} style={{ height: "100%" }}>
            <VerticalDrawerContentTemplate
                newDrawerHeaderStyle={true}
                title={"Edit Dashboard"}
                titleStyles={{}}
                titleTextStyle={{
                    marginTop: "16px",
                    paddingLeft: "16px",
                    color: theme.palette.neutral.main,
                    fontSize: "20px",
                    lineHeight: "32px",
                    fontWeight: 600,
                }}
                subTitle={"Edit this dashboard's settings"}
                subTitleStyles={{}}
                subtitleTextStyle={{
                    marginBottom: "16px",
                    paddingLeft: "16px",
                    color: theme.palette.gray.main,
                    fontSize: "14px",
                    lineHeight: "20px",
                    fontWeight: 400,
                }}
                contentStyles={{ flexGrow: 1 }}
                content={getContent()}
                onClose={() => {
                    setCustomizedLogoName(undefined);
                }}
            />
        </Grid>
    );
});

export default CustomizeDashboard;
