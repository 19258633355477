import { GridComparatorFn, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { ArrayToCommaSepString } from "../../../../utils/StringUtils";
import { isArrayType } from "../../../../utils/TypeGuards";
// import { StringFormatter } from "./DefaultFormatters";

export const ArrayJoinFormatter = (params: GridRenderCellParams) => {
    const value = params.value;
    if (isArrayType(value)) {
        const joinedArray = ArrayToCommaSepString(value);
        return joinedArray;
    }
    return "";
};

export const ArrayJoinComparator: GridComparatorFn = (
    v1,
    v2,
    cellParams1,
    cellParams2,
): number => {
    const x1 = ArrayToCommaSepString(v1 as any[]);
    const x2 = ArrayToCommaSepString(v2 as any[]);

    return x1 > x2 ? 1 : -1;
};
