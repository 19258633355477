import { Theme, Tooltip } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import SettingsIcon from "@mui/icons-material/Settings";
import { Tier } from "components/Admin/Organizations/types/Tier.type";
import { BranchDataProps } from "components/UI/AcxRecursiveTree/AcxRecursiveTreeBranch";
import React from "react";
import useStyles from "Styles/Styles";
import { OrganizationServiceHierarchyStore } from "../stores/OrganizationServiceHierarchyStore";

interface EditTierIconProps {
    tier: BranchDataProps;
    isLeaf?: boolean;
    osmStore: OrganizationServiceHierarchyStore;
}

const styles = (theme: Theme) => {
    return createStyles({
        icon: {
            display: "flex",
            alignItems: "center",
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            cursor: "pointer",
        },
    });
};

const EditTierIcon: React.FC<React.PropsWithChildren<React.PropsWithChildren<EditTierIconProps>>> = ({ tier, osmStore }) => {
    const classes = useStyles(styles);

    return (
        <Tooltip title={"Tier Settings"}>
            <SettingsIcon
                htmlColor="#bebebe"
                cursor="pointer"
                onClick={() => {
                    osmStore.setActiveTier(tier as Tier);
                    osmStore.openEditTierDrawer();
                }}
                className={classes.icon}
            />
        </Tooltip>
    );
};

export default EditTierIcon;
