import Immutable from "immutable";
import papa from "papaparse";
import { IMetaData, IReadFileResult } from "./UploaderUtils";

interface ICSVField {
    index: number;
    extractor: (value: string) => string;
}

interface IExtractedCell {
    original: string;
    extracted: string;
    metadataField?: keyof IMetaData;
    error?: string;
}

export type IExtractedRow = {
    fileIdentifier: string;
    fields: Immutable.List<IExtractedCell>;
    error?: string;
};

export interface IExtractedCSV {
    fileName: string;
    error?: string;
    rows?: Immutable.List<IExtractedRow>;
}

// column indices for relevant information in the CSV
export type ICSVFormat = {
    [fieldName in keyof IMetaData]: ICSVField;
};

/**
 * Converts an alphabetic column index to zero-based numeric index
 * Thanks https://stackoverflow.com/a/9906193
 *
 * @param letter The excel-style letters for a column (A, G, AZ, ZZX, etc)
 */
export function excelColumnLetterToIndex(letter: string): number {
    letter = letter.toLocaleUpperCase();
    const base = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = 0;

    for (let i = 0, j = letter.length - 1; i < letter.length; i += 1, j -= 1) {
        if (base.indexOf(letter[i]) < 0) {
            return -1;
        }
        result += Math.pow(base.length, j) * (base.indexOf(letter[i]) + 1);
    }

    return result - 1;
}

function normalizeCell(contents: string) {
    return contents.trim().replace(/\s+/g, " ");
}

function extractCSVFile(
    inputRows: string[][],
    csvFormat: ICSVFormat,
): Immutable.List<IExtractedRow> {
    // CR: I know this could be done in linear instead of N*M and I don't care
    let extractedRows = Immutable.List<IExtractedRow>();
    for (const inputRow of inputRows) {
        let fileIdentifier = "";
        let fields = Immutable.List<IExtractedCell>();
        let rowError;
        for (
            let columnIndex = 0;
            columnIndex < inputRow.length;
            columnIndex++
        ) {
            const originalValue = inputRow[columnIndex];
            let extractedValue = normalizeCell(originalValue);
            let metadataField;
            let fieldError;
            for (const [fieldName, csvField] of Object.entries(csvFormat)) {
                if (csvField.index === columnIndex) {
                    try {
                        const extractor = csvField.extractor;
                        extractedValue = extractor(extractedValue);
                        metadataField = fieldName;
                        if (fieldName === "id") {
                            fileIdentifier = extractedValue;
                        }
                    } catch (ex) {
                        //@ts-ignore
                        fieldError = ex.message as string;
                    }
                }
            }
            fields = fields.push({
                original: originalValue,
                extracted: extractedValue,
                metadataField,
                error: fieldError,
            });
        }
        const outputRow: IExtractedRow = {
            fileIdentifier,
            error: rowError,
            fields,
        };
        extractedRows = extractedRows.push(outputRow);
    }
    return extractedRows;
}

export function extractCSVFiles(
    inputFiles: IReadFileResult[],
    csvFormat: ICSVFormat,
): Immutable.List<IExtractedCSV> {
    let extractedFiles = Immutable.List<IExtractedCSV>();

    for (const inputFile of inputFiles) {
        let extractedFile: IExtractedCSV = {
            fileName: inputFile.fileName,
        };

        if (
            inputFile.contents === null ||
            inputFile.contents === undefined ||
            inputFile.contents === ""
        ) {
            extractedFile.error = "File had no contents";
        } else {
            try {
                const csvData = inputFile.contents.toString().trim();
                // const inputRows = csvParse(csvData) as string[][];
                // const inputRows = parse(csvData, {
                //     columns: true,
                // }) as string[][];
                const parse = papa.parse(csvData, { header: false });
                const inputRows = parse.data as string[][];
                const extractedRows = extractCSVFile(inputRows, csvFormat);
                extractedFile.rows = extractedRows;
            } catch (ex) {
                //@ts-ignore
                extractedFile.error = ex.message;
            }
        }

        extractedFiles = extractedFiles.push(extractedFile);
    }
    return extractedFiles;
}
