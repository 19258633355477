import { BadgeOrigin } from "@mui/material/Badge/Badge";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, computed, makeObservable, observable } from "mobx";
import React from "react";
import { BaseStore } from "stores/BaseStore";
import { OrgSelectorComponentStore } from "stores/ComponentStores/OrgSelectorComponentStore";
import { OrganizationStore } from "stores/OrganizationStore";
import { IRootStore } from "stores/RootStore";
import { AuthStore } from "../../stores/AuthStore";
import { isType } from "../../utils/TypeGuards";

type BadgeOptions = {
    anchorOrigin?: BadgeOrigin;
    overlap?: "rectangular" | "circular";
    color?: "primary" | "secondary" | "default" | "error";
    invisible?: boolean;
    max?: number;
    showZero?: boolean;
    variant?: "standard" | "dot";
};

export interface INavigation {
    title: React.ReactNode;
    link: string;
    icon?: React.ReactNode;
    selectedIcon?: React.ReactNode;
    badgeContent?: string;
    defaultRoute?: boolean;
    badgeOptions?: BadgeOptions;
    enabled?: boolean;
    onClick?: () => void;
    removeMinHeight?: boolean;
    signalsVariant?: boolean;
}
export interface INavigationGroup {
    header: React.ReactNode;
    items: INavigation[];
    expanded?: boolean;
    linkPath?: string;
}
export interface IItemOptions {
    itemSpacing?: "dense" | "spread";
    linkFontSize?: string;
    autoSelectOrg?: boolean;
}

export default class NavigationStore extends BaseStore {
    @observable orgStore?: OrganizationStore;
    @observable messageStore: MessageStore;
    orgSelectorStore: OrgSelectorComponentStore;

    ownerStoreId: string;

    @observable
    private _currentNavItem: INavigation | undefined;
    @computed
    get currentNavItem() {
        return this._currentNavItem;
    }

    set currentNavItem(value) {
        this._currentNavItem = value;
    }

    @observable defaultRoute?: INavigation;
    @observable itemGroups: INavigationGroup[];
    @observable items: INavigation[];

    public constructor(
        private rootStore: IRootStore,
        storeId: string,
        navItems?: INavigationGroup[] | INavigation[],
        orgSelectorStore?: OrgSelectorComponentStore,
    ) {
        super("Navigation Store");
        makeObservable(this);
        this.ownerStoreId = storeId;
        this.orgStore = rootStore.getStore(AuthStore).orgStore;
        this.messageStore = rootStore.getStore(MessageStore);
        this.setNavItems(navItems);
        if (orgSelectorStore) {
            this.orgSelectorStore = orgSelectorStore;
        }
    }

    @action
    setNavItems(navItems: INavigationGroup[] | INavigation[] | undefined) {
        if (navItems && navItems.length > 0) {
            if (isType<INavigationGroup>(navItems[0], "header")) {
                this.itemGroups = navItems as INavigationGroup[];
                this.defaultRoute = this.itemGroups
                    ?.flatMap((v) => v.items)
                    .find((value) => value.defaultRoute === true);
            } else {
                this.items = navItems as INavigation[];
                this.defaultRoute = this.items?.find(
                    (value) => value.defaultRoute === true,
                );
            }
        }
    }
    @action
    clearNavItems() {
        this.itemGroups = [];
        this.items = [];
    }
}
