import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { action, computed, makeObservable, observable, reaction } from "mobx";
import type CustomerType from "models/CustomerType";
import type {
    MappedCustomerType,
    UnMappedCustomerType,
} from "models/CustomerType";
import {
    isMappedCustomerType,
    isUnMappedCustomerType,
} from "models/CustomerType";
import CustomerTypesService from "services/CustomerTypesService";
import { AuthStore } from "stores/AuthStore";
import { BaseStore } from "stores/BaseStore";
import { DialogComponentStore } from "stores/ComponentStores/DialogComponentStore";
import { AcxStore } from "stores/RootStore";
import type { IRootStore } from "stores/RootStore";
import { uuidv4 } from "utils/helpers";

@AcxStore
export class OrganizationCustomerTypeStore extends BaseStore {
    public static Tasks = {
        LOAD_CUSTOMER_TYPES: "Load Customer Types",
        SAVE_CHANGES: "Save Customer Types",
        DELETE_CUSTOMER_TYPES: "Delete Customer Types",
    } as const;

    private readonly customerTypesService: CustomerTypesService =
        new CustomerTypesService();
    public readonly createContactTypesDialogStore: DialogComponentStore;
    private readonly messageStore: MessageStore;

    @observable selectedCustomerTypeIds = observable.set<string>();

    @observable organizationId: string;
    @observable customerTypeName?: string;
    @observable errorMessage?: string;

    @observable customerTypes: CustomerType[] = [];
    @observable createdCustomerTypes: CustomerType[] = [];
    @observable updatedCustomerTypes: CustomerType[] = [];

    @computed get optimisticCustomerTypes(): CustomerType[] {
        return [...this.customerTypes, ...this.createdCustomerTypes];
    }

    @computed get mappedCustomerTypes(): MappedCustomerType[] {
        return this.optimisticCustomerTypes.filter(isMappedCustomerType);
    }

    @computed get unMappedCustomerTypes(): UnMappedCustomerType[] {
        return this.optimisticCustomerTypes.filter(isUnMappedCustomerType);
    }

    constructor(private rootStore: IRootStore) {
        super("OrganizationCustomerTypeStore");
        makeObservable(this);

        this.createContactTypesDialogStore = new DialogComponentStore(
            undefined,
            this,
        );

        this.messageStore = rootStore.getStore(MessageStore);
        const authStore = rootStore.getStore(AuthStore);

        reaction(
            () => authStore.orgStore.selectedOrganization,
            (organization) => {
                if (!organization) return;
                this.organizationId = organization.id;
            },
            { fireImmediately: true },
        );

        reaction(
            () => ({
                organizationId: this.organizationId,
                activeLocation: this.rootStore.activeLocation,
            }),
            (args) => {
                if (
                    args.activeLocation &&
                    !(
                        args.activeLocation.location.includes(
                            "admin/organizations",
                        ) &&
                        args.activeLocation.location.includes("contact-types")
                    )
                ) {
                    return;
                }

                if (!args.organizationId) return;

                this.customerTypes = [];
                this.updatedCustomerTypes = [];
                this.createdCustomerTypes = [];
                this.loadCustomerTypes();
            },
            { fireImmediately: true },
        );
    }

    @action
    public onCreateDialogClose() {
        this.createdCustomerTypes = [];
        this.createContactTypesDialogStore.close();
    }

    @action
    public openCreateDialog() {
        this.addNewCustomerType();
        this.createContactTypesDialogStore.open();
    }

    @action
    private async loadCustomerTypes() {
        this.setupAsyncTask(
            OrganizationCustomerTypeStore.Tasks.LOAD_CUSTOMER_TYPES,
            async () => {
                try {
                    this.customerTypes =
                        await this.customerTypesService.getCustomerTypes(
                            this.organizationId,
                        );
                    return;
                } catch (error) {
                    this.messageStore.logError(
                        "Failed to fetch customer types.",
                    );
                }
            },
        );
    }

    @action
    onUpdateCustomerType = <T extends keyof CustomerType>(
        arg: T,
        value: any,
    ) => {
        this.customerTypeName = value;
    };

    @action
    deleteSelectedCustomerTypes() {
        if (this.selectedCustomerTypeIds.size === 0) return;

        this.setupAsyncTask(
            OrganizationCustomerTypeStore.Tasks.DELETE_CUSTOMER_TYPES,
            async () => {
                try {
                    const newCreatedCustomerTypes =
                        this.createdCustomerTypes.filter(
                            (customerType) =>
                                !this.selectedCustomerTypeIds.has(
                                    customerType.id,
                                ),
                        );

                    const newCustomerTypes = this.customerTypes.filter(
                        (customerType) =>
                            !this.selectedCustomerTypeIds.has(customerType.id),
                    );

                    await this.customerTypesService.deleteCustomerTypes(
                        Array.from(this.selectedCustomerTypeIds),
                    );

                    this.customerTypes = newCustomerTypes;
                    this.createdCustomerTypes = newCreatedCustomerTypes;
                    this.selectedCustomerTypeIds = observable.set();
                } catch (error) {
                    this.messageStore.logError(
                        "Failed to delete contact types. Please try again.",
                    );
                }
            },
        );
    }

    @action
    updateCustomerTypeName(customerType: CustomerType, name: string) {
        customerType.name = name;
        if (
            !customerType[this.isNewSymbol] &&
            !this.updatedCustomerTypes.includes(customerType)
        )
            this.updatedCustomerTypes.push(customerType);
    }

    private readonly isNewSymbol = Symbol.for("NewCustomerType");

    @action
    updateCustomerTypeSourceName(customerType: CustomerType, name?: string) {
        customerType.sourceName = name;
        if (
            !customerType[this.isNewSymbol] &&
            !this.updatedCustomerTypes.includes(customerType)
        )
            this.updatedCustomerTypes.push(customerType);
    }

    private createCustomerType() {
        return {
            // just used for tracking on FE
            id: uuidv4(),
            name: "",
            sourceName: undefined,
            organizationId: this.organizationId,
            [this.isNewSymbol]: true,
        };
    }

    @action
    addNewCustomerType() {
        this.createdCustomerTypes.push(this.createCustomerType());
    }

    @action
    toggleSelectedCustomerType(customerType: CustomerType) {
        if (this.selectedCustomerTypeIds.has(customerType.id))
            this.selectedCustomerTypeIds.delete(customerType.id);
        else this.selectedCustomerTypeIds.add(customerType.id);
    }

    @computed get duplicateCustomerTypes() {
        const customerTypes = this.optimisticCustomerTypes;
        const duplicates: Set<CustomerType> = new Set();

        for (let i = 0; i < customerTypes.length - 1; i++) {
            const customerType = customerTypes[i];
            for (let j = i + 1; j < customerTypes.length; j++) {
                const other = customerTypes[j];
                if (
                    customerType.name.trim().toLowerCase() ===
                    other.name.trim().toLowerCase()
                ) {
                    duplicates.add(customerType);
                    duplicates.add(other);
                    break;
                }
            }
        }

        return duplicates;
    }

    @computed get hasDuplicateCustomerTypes() {
        return this.duplicateCustomerTypes.size > 0;
    }

    @computed get canSaveChanges() {
        return (
            this.duplicateCustomerTypes.size === 0 &&
            !this.updatedCustomerTypes.some(
                (customerType) => customerType.name.trim() === "",
            ) &&
            (this.createdCustomerTypes.length > 0 ||
                this.updatedCustomerTypes.length > 0)
        );
    }

    getCustomerTypeError(
        customerType: CustomerType,
    ): [boolean, string | undefined] {
        // New empty customer types are not created so we only want to check
        // if a customer type was updated with an empty name
        if (
            !this.createdCustomerTypes.includes(customerType) &&
            customerType.name === ""
        )
            return [true, "Contact type name cannot be empty."];
        if (this.duplicateCustomerTypes.has(customerType))
            return [true, "Duplicate contact type name not allowed."];
        return [false, undefined];
    }

    @action
    async saveChanges() {
        const toCreate = this.createdCustomerTypes.filter(
            (customerType) => customerType.name.trim() !== "",
        );

        await this.setupAsyncTask(
            OrganizationCustomerTypeStore.Tasks.SAVE_CHANGES,
            async () => {
                try {
                    for (const customerType of this.updatedCustomerTypes)
                        await this.customerTypesService.updateCustomerType(
                            customerType,
                        );
                    for (const customerType of toCreate) {
                        await this.customerTypesService.createCustomerType(
                            customerType,
                        );
                        customerType[this.isNewSymbol] = false;
                    }

                    this.messageStore.logInfo(
                        "All changes saved successfully.",
                    );
                } catch (error: any) {
                    this.messageStore.logError(
                        "Error saving changes. " + error.message,
                    );
                } finally {
                    await this.loadCustomerTypes();
                    this.createdCustomerTypes = [];
                    this.updatedCustomerTypes = [];
                }
            },
        );
    }

    @computed
    get deleteButtonEnabled() {
        return this.selectedCustomerTypeIds.size > 0;
    }
}
