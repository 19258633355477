import createStyles from '@mui/styles/createStyles';
import theme from "Theme/AppTheme";

export const styles = () => {
    return createStyles({
        content: {
            height: "100%",
            minWidth: "141px",
        },
        scrollable: () => ({
            overflowX: "hidden",
            height: "100%",
        }),
        TranscriptContentWrapper: {
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
        },
        TranscriptContent: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "80%",
        },
        Transcriptionleftpanel: {
            display: "flex",
            flexDirection: "column",
            minWidth: "10%",
            paddingLeft: "15px",
            paddingTop: "10px",
        },
        TranscriptTopicWords: {
            width: "20%",
            position: "sticky",
            display: "flex",
            flexDirection: "column",
            borderLeft: "1px solid #E4E4E7",
            minHeight: "100%",
        },
        TranscriptTopicWordColors: {
            padding: "12px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderBottom: "1px solid #E4E4E7",
            width: "100%",
        },
        TranscriptTopicWordColor: {
            minWidth: "12px",
            minHeight: "12px",
            marginLeft: "5px",
            marginRight: "5px",
            borderRadius: "2px",
            border: "1px solid black",
        },
        WordsList: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            padding: "12px",
            justifyContent: "center",
        },
        Word: {
            background: "#dbdbdb",
            marginLeft: "2px",
            marginRight: "2px",
            marginBottom: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontSize: "14px",
        },
        TranscriptBlurbList: {
            listStyle: "none",
            paddingLeft: "0px",
            marginTop: "0px",
        },
        TranscriptBlurbCell: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            borderRadius: "3px",
            border: "3px solid transparent",
            position: "relative",
            paddingRight: "40px",
            "&:hover": {
                border: "3px solid #E4E4E7",
                "& button": {
                    visibility: "visible",
                },
            },
            "& button": {
                visibility: "hidden",
                top: "12px",
                width: "30px",
                height: "30px",
                "&:hover": {},
                "& svg": {
                    width: "30px",
                    color: "#D4D4D8",
                    transition: "color 0.3s ease",
                    "&:hover": {
                        color: "#2E8EFF",
                    },
                },
            },
        },
        BlurbCellTitle: {
            paddingTop: "12px",
            textAlign: "right",
            minWidth: "10%",
            userSelect: "none",
        },
        BlurbCellText: {
            width: "85%",
            padding: "12px",
        },
        transcriptionSection: {
            userSelect: "none",
        },
        translationString: {
            cursor: "text",
        },
        resultsContainer: {
            "&:hover": {
                backgroundColor: theme.palette.lightgrayHover.main,
                cursor: "pointer",
            },
        },
    });
};
