import { computed, makeObservable, observable } from "mobx";
import theme from "../Theme/AppTheme";
import { copyBaseFields } from "../utils/BaseEntityUtils";
import BaseEntity from "./BaseEntity";
import Question from "./Question";

enum TagType {
    // ~ Module
    Group,

    // ~ Question
    Category,

    Name,
    Topical,
}

export enum TagSentiment {
    Positive,
    Neutral,
    Negative,
}

export class Tag extends BaseEntity {
    organizationId: string;
    parentId: string;
    parent?: Tag;
    tagType: TagType;
    value: string;
    @observable sentiment: TagSentiment;
    scoredValue: number | null;
    questionId?: string;
    question?: Question;
    order: number;

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);
    }

    @computed
    get isAnswerPostiveSentiment() {
        return this.sentiment === TagSentiment.Positive;
    }

    @computed
    get isAnswerNegativeSentiment() {
        return this.sentiment === TagSentiment.Negative;
    }

    @computed
    get isAnswerNeutralSentiment() {
        return this.sentiment === TagSentiment.Neutral;
    }

    get sentimentColor() {
        return this.sentiment === TagSentiment.Negative
            ? theme.palette.red.main.toString()
            : this.sentiment === TagSentiment.Positive
            ? theme.palette.green.main.toString()
            : theme.palette.info.main.toString();
    }

    get displayName() {
        if (this.parent?.value) {
            return `${this.parent?.value}|${this.value}`;
        }
        return this.value;
    }

    static fromJson(tag: Tag) {
        const res = new Tag();
        copyBaseFields(tag, res);
        res.organizationId = tag.organizationId;
        res.scoredValue = tag.scoredValue;
        res.parent = tag.parent;
        res.parentId = tag.parentId;
        res.tagType = tag.tagType;
        res.value = tag.value;
        res.sentiment = tag.sentiment;
        res.question = tag.question;
        res.questionId = tag.questionId;
        res.order = tag.order;
        res.isActive = tag.isActive;
        return res;
    }
}
