import { Dialog, Grid, styled, Typography } from "@mui/material";
import AcxMainTextField from "components/UI/AcxMainTextField";
import React, { useEffect, useState } from "react";
import {
    ApplicationFiltersStore,
    SavedFilter,
} from "stores/ApplicationFilters/ApplicationFiltersStore";
import { VisibilityToggle } from "./VisibilityToggle";
import AcxButton from "components/UI/AcxButton";
import {
    DeleteOutline,
    ErrorRounded,
    WarningOutlined,
} from "@mui/icons-material";
import { useStore } from "utils/useStore";
import MessageStore from "components/ManagerInteractions/Stores/MessageStore";
import { ApplicationFilterDomain } from "services/ApplicationFiltersService";

interface SavedFilterSettingsProps {
    defaultSavedFilter: SavedFilter;
    onSavedFilterChange(filter: SavedFilter): void;
    disabled?: boolean;
    isPrivatable?: boolean;
    isDeletable?: boolean;
}

const DeleteButton = styled(AcxButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: "transparent",
    ":hover": {
        backgroundColor: "white",
        opacity: "80%",
    },
    ":disabled": {
        opacity: "50%",
        pointerEvents: "none",
        backgroundColor: "white",
        color: theme.palette.primary.main,
    },
}));

const DeleteTitle = styled(Typography)(({ theme }) => ({
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "32px",
    color: theme.palette.black.main,
    textAlign: "center",
}));

const DeleteText = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.gray[500],
    textAlign: "center",
}));

const ActionButton = styled(AcxButton)(({ theme }) => ({
    border: `1px solid ${theme.palette.gray[200]}`,
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.white.main,
    color: theme.palette.gray[700],
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    fontSize: "14px",
    ":hover": {
        backgroundColor: theme.palette.white.main,
        opacity: "80%",
    },
}));

export function SavedFilterSettings(props: SavedFilterSettingsProps) {
    const applicationFiltersStore = useStore(ApplicationFiltersStore);
    const messageStore = useStore(MessageStore);

    const [savedFilter, setSavedFilter] = useState(props.defaultSavedFilter);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    useEffect(
        () => setSavedFilter(props.defaultSavedFilter),
        [props.defaultSavedFilter],
    );

    return (
        <Grid container direction="column" rowGap={2}>
            <Grid item>
                <AcxMainTextField
                    id="saved-filter-name"
                    labelText="Name"
                    value={savedFilter.name}
                    labelSx={{ fontWeight: "bold" }}
                    isDisabled={props.disabled}
                    onChange={(event) => {
                        const next = {
                            ...savedFilter,
                            name: event.target.value,
                        };
                        props.onSavedFilterChange?.(next);
                        setSavedFilter(next);
                    }}
                />
            </Grid>
            {savedFilter.createdBy && (
                <Grid item>
                    <Typography fontWeight="bold">Created By</Typography>
                    <Typography>{savedFilter.createdBy}</Typography>
                </Grid>
            )}
            <Grid container item direction="column" rowGap={1}>
                <Grid item>
                    <VisibilityToggle
                        defaultValue={savedFilter.domain}
                        onChange={(visibility) => {
                            const next = {
                                ...savedFilter,
                                domain: visibility,
                            };
                            props.onSavedFilterChange?.(next);
                            setSavedFilter(next);
                        }}
                        disabled={props.disabled}
                    />
                </Grid>
                {!props.isPrivatable &&
                    savedFilter.domain === ApplicationFilterDomain.User && (
                        <Grid
                            container
                            item
                            columnGap={1}
                            flexWrap="nowrap"
                            alignItems="flex-start"
                            sx={(theme) => ({
                                color: theme.palette.error.main,
                            })}
                        >
                            <Grid item>
                                <ErrorRounded />
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "inherit",
                                        textWrap: "wrap",
                                    }}
                                >
                                    Filters cannot be made private while they
                                    are pinned to shared dashboards.
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
            </Grid>
            <Grid item sx={{ alignSelf: "flex-end" }}>
                <DeleteButton
                    startIcon={<DeleteOutline />}
                    fullWidth={false}
                    onClick={() => setIsDeleteDialogOpen((open) => !open)}
                    disabled={props.disabled || !props.isDeletable}
                    tooltip={
                        !props.isDeletable
                            ? "Filters cannot be deleted while they are pinned to dashboards."
                            : undefined
                    }
                >
                    Delete
                </DeleteButton>
            </Grid>
            <Dialog
                maxWidth="xs"
                fullWidth
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                PaperProps={{
                    sx: (theme) => ({
                        padding: theme.spacing(3),
                        borderRadius: theme.spacing(1),
                    }),
                }}
            >
                <Grid container direction="column" rowGap={6}>
                    <Grid
                        container
                        item
                        direction="column"
                        alignItems="center"
                        rowGap={2}
                    >
                        <Grid item fontSize="40px">
                            <WarningOutlined color="error" fontSize="inherit" />
                        </Grid>
                        <Grid item>
                            <DeleteTitle>Are you sure?</DeleteTitle>
                        </Grid>
                        <Grid item>
                            <DeleteText>
                                Deleting this filter is immediate and permanent.
                                Are you sure you want to delete?
                            </DeleteText>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="row"
                        justifyContent="center"
                        columnGap={1}
                    >
                        <Grid item>
                            <ActionButton
                                fullWidth={false}
                                onClick={() => setIsDeleteDialogOpen(false)}
                            >
                                Cancel
                            </ActionButton>
                        </Grid>
                        <Grid item>
                            <ActionButton
                                fullWidth={false}
                                sx={(theme) => ({
                                    color: theme.palette.error[500],
                                })}
                                loading={applicationFiltersStore.getTaskLoading(
                                    ApplicationFiltersStore.Tasks.DELETE_FILTER,
                                )}
                                onClick={async () => {
                                    const success =
                                        await applicationFiltersStore.deleteFilters(
                                            [savedFilter.id],
                                        );

                                    setIsDeleteDialogOpen(false);

                                    if (success) {
                                        return messageStore.logInfo(
                                            `Successfully deleted '${savedFilter.name}'`,
                                        );
                                    }

                                    messageStore.logError(
                                        `Failed to delete '${savedFilter.name}'`,
                                    );
                                }}
                            >
                                Delete Filter
                            </ActionButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
}
