import { DataProcessingOption } from "models/DataProcesses";
import { useEffect, useState } from "react";
import OrganizationService from "../Organization.service";

const useDataProcessingOptions = () => {
    const [data, setData] = useState<DataProcessingOption[] | null>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let destroyed = false;
        const runFn = async () => {
            try {
                setIsLoading(true);
                const res =
                    await OrganizationService.getDataProcessingOptions();
                if (!destroyed) {
                    setData(res);
                    setIsLoading(false);
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
            }
        };
        runFn();
        return () => {
            destroyed = true;
            setData(null);
        };
    }, []);

    return {
        data,
        isLoading,
    };
};

export default useDataProcessingOptions;
