import React from "react";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import theme from "Theme/AppTheme";
import App from "./AdminApp";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export default function AdminAppContainer() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
