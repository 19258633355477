import {
    CssBaseline,
    StyledEngineProvider,
    Theme,
    ThemeProvider,
} from "@mui/material";
import AudioFileSamplerV2 from "components/Admin/AdminUIComponents/AudioFileSamplerV2/AudioFileSamplerV2";
import MetadataBuildContainer from "components/Admin/AdminUIComponents/MetadataTemplate/MetadataBuildContainer";
import RuleBuilder from "components/Admin/AdminUIComponents/RuleBuilder/RuleBuilder";
import OrganizationModuleDetails from "components/Admin/Organizations/OrganizationDetail/OrganizationModules/OrganizationModuleDetails/OrganizationModuleDetails";
import UserProfileFocus from "components/Admin/UserManager/UserProfileFocus";
import ReportEditor from "components/Reports/Editor/ReportEditor";
import ReportEditorRv2 from "components/Signals/Reports/Editor/ReportEditorRv2";
import VideoPage from "components/Video/VideoPage";
import React from "react";
import { Route, Routes } from "react-router-dom";
import theme from "Theme/AppTheme";

declare module "@mui/styles/defaultTheme" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

function Focus() {
    return (
        <>
            <CssBaseline />
            <Routes>
                <Route
                    path="audiofilesamplerv2"
                    element={<AudioFileSamplerV2 />}
                ></Route>
                <Route
                    path="metadatabuilder"
                    element={<MetadataBuildContainer />}
                ></Route>
                <Route path="rulebuilder" element={<RuleBuilder />}></Route>
                <Route path="reporteditor/:orgId/" element={<ReportEditor />} />
                <Route
                    path="reporteditor/:orgId/:reportId"
                    element={<ReportEditor />}
                />
                <Route
                    path={`modules/:organizationId/:moduleId`}
                    element={<OrganizationModuleDetails />}
                ></Route>
                <Route
                    path="userprofile"
                    element={<UserProfileFocus />}
                ></Route>
                <Route path="video/:amdId" element={<VideoPage />}></Route>
                <Route
                    path="signals/reporteditor/:orgId"
                    element={<ReportEditorRv2 />}
                ></Route>
                <Route
                    path="signals/reporteditor/:orgId/:reportId"
                    element={<ReportEditorRv2 />}
                ></Route>
            </Routes>
        </>
    );
}

export default function FocusContainer(props) {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Focus />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
