import { Typography } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import { observer } from "mobx-react";
import React from "react";
import useStyles from "Styles/Styles";
import { Skeleton } from '@mui/material';

const styles = () => {
    return createStyles({
        Card: {
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            paddingLeft: "2%",
            paddingRight: "2%",
            marginBottom: "24px",
            minHeight: "144px",
        },
        Title: {
            paddingTop: "16px",
            paddingBottom: "8px",
            fontSize: "22px",
        },
        WordsList: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            paddingBottom: "16px",
        },
        Word: {
            background: "#dbdbdb",
            marginLeft: "2px",
            marginRight: "2px",
            marginBottom: "5px",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontSize: "12px",
        },
    });
};


const TopicPreviewCardSkeleton: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = observer(
    () => {

        const classes = useStyles(styles);

        const words = Array.from(Array(90).keys()).map(i => <Skeleton key={i} variant="text" className={classes.Word} width={28} />)

        return <>
            <div className={classes.Card}>
                 <Typography className={classes.Title} variant="subtitle1">{<Skeleton variant="text" width={240} height={38} className={classes.Title} />}</Typography>
                 <div className={classes.WordsList} >
                    {words}
                 </div>
            </div>
        </>
    }
)

export default TopicPreviewCardSkeleton;