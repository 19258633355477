import { observer } from "mobx-react-lite";
import { AssignedWorkflowInstance } from "models/Workflows/WorkflowInstance";
import React from "react";
import { useStore } from "utils/useStore";
import { EvalStore } from "../../Stores/EvalStore";
import { Grid, Typography } from "@mui/material";
import { isNotNull } from "utils/helpers";
import { WorkflowConditionType } from "components/Admin/Organizations/OrganizationDetail/OrganizationWorkflows/Types/WorkflowModels";
import theme from "Theme/AppTheme";
import { findModuleName, getDisputeModuleNames } from "./utils";

interface WorkflowSidebarCategoryProps {
    instance: AssignedWorkflowInstance;
}

const WorkflowSidebarCategory: React.FC<WorkflowSidebarCategoryProps> =
    observer(({ instance }) => {
        const evalStore = useStore(EvalStore);

        const moduleNames = (() => {
            switch (instance.implementationType) {
                case WorkflowConditionType.APT:
                    return [
                        `${findModuleName(
                            instance.workflowCondition.question
                                ?.licensedModuleId,
                            evalStore.licensedModulesForEval,
                        )} > ${instance.question}`,
                    ];
                case WorkflowConditionType.ScoreEscalation:
                    return [
                        findModuleName(
                            instance.workflowCondition.licensedModuleId,
                            evalStore.licensedModulesForEval,
                        ),
                    ];
                case WorkflowConditionType.EvaluationDispute:
                    return getDisputeModuleNames(
                        evalStore.currentEval,
                        evalStore.licensedModulesForEval,
                    );

                default: {
                    return [];
                }
            }
        })().filter(isNotNull);

        return moduleNames.length > 0 ? (
            <>
                <Grid item>
                    <Typography color={theme.palette.gray.main}>
                        Category
                    </Typography>
                </Grid>

                {moduleNames.map((name) => (
                    <Grid item xs={12}>
                        <Typography>{name}</Typography>
                    </Grid>
                ))}
            </>
        ) : null;
    });

export default WorkflowSidebarCategory;
